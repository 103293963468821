import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import {
  decorationTypeEnum,
  personalizationTypeEnum,
} from '@constants/enums/decorationEnums';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

class ItemCustomDecorations extends Component {
  getPersonalizationTypeText = (personalizationType) => {
    switch (personalizationType) {
      case personalizationTypeEnum.Both:
        return 'Name + Number';
      case personalizationTypeEnum.Name:
        return 'Name';
      case personalizationTypeEnum.Number:
        return 'Number';
    }
  };

  render() {
    const {
      allDecorationLocations,
      decorations,
    } = this.props;
    let size = '';

    const decorationsMapped = decorations.map((d) => {
      if (d.decorationHeight || d.decorationWidth) {
        const decorationHeightText = d.decorationHeight && `Height: ${d.decorationHeight}`;
        const decorationWidthText = d.decorationWidth && `Width: ${d.decorationWidth}`;
        const tooltipText = `${decorationHeightText || ''} ${decorationWidthText || ''}`;
        size = (
          <MaterialTooltip
            tooltipText={`This decoration has a custom size: ${tooltipText} inches`}
            placement={'top'}
          >
            <Icon
              materialIcon={'aspect_ratio'}
              classes={'cursor-pointer'}
            />
          </MaterialTooltip>
        );
      } else {
        size = '';
      }

      const decoration = allDecorationLocations.find((a) => a.code === d.decorationLocation);
      const decorationLocationDisplay = `${d.decorationLocation}${decoration ? ` - ${decoration.label}` : ''}`;

      const tooltipText = (
        <div className='flex__column'>
          <span>Fill: {d.colorCode || '-'}</span>
          {d.outlineColorCode && <span>Outline: {d.outlineColorCode || '-'}</span>}
        </div>
      );

      let outlineElement = '';
      if (d.outlineColorCode && d.outlineColorHexValue) {
        outlineElement = (
          <span
            className='color--outline'
            style={{ borderColor: d.outlineColorHexValuehexValue }}
          />
        );
      }

      return (
        <div
          key={d.id}
          className='decorationList__preview-item'
        >
          {
            d.type === decorationTypeEnum.Logo
              ? (
                <div className='decorationList__preview-item--logo'>
                  <img src={`${lockerMgrS3Logos}/${d.logoImage}`} />
                </div>
              )
              : (
                <div className='decorationList__preview-item--personalization'>
                  <div className='type'>
                    {this.getPersonalizationTypeText(d.personalizationType)}
                  </div>
                  <MaterialTooltip
                    tooltipText={tooltipText}
                    placement={'right'}
                    contentClasses={'color'}
                  >
                    <div
                      className='w-100 h-100'
                      style={{ backgroundColor: d.colorHexValue }}
                    >
                      {outlineElement}
                    </div>
                  </MaterialTooltip>
                </div>
              )}
          <div className='decorationList__preview-location align__center'>
            {decorationLocationDisplay}
            {size}
          </div>
        </div>
      );
    });

    return decorationsMapped;
  }
}

ItemCustomDecorations.propTypes = {
  allDecorationLocations: PropTypes.array.isRequired,
  decorations: PropTypes.arrayOf(PropTypes.object),
};

export default ItemCustomDecorations;
