import React from 'react';
import { sportsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const SportsLink = React.memo(() => (
  <MenuTile
    title={'Sports'}
    url={sportsUrl}
    icon={'sports_soccer'}
  />
));

export default SportsLink;
