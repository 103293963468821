import React, { useMemo } from 'react';
import Tabs from '@sharedComponents/Tabs/Tabs';
import { tabSizesEnum } from '@constants/enums/commonEnums';
import { PrintAssemblyTabsEnum } from './PrintAssemblyTabsEnum';
import { HagPrintAssemblyOrderDetailsDto } from '@api/fulfillment/models';

interface OwnProps {
  orderNumber: number;
  selectTab: (newSelectedTab: PrintAssemblyTabsEnum) => void;
  selectedTab: PrintAssemblyTabsEnum;
  orderDetails: HagPrintAssemblyOrderDetailsDto | undefined;
}

type Props = OwnProps;

const PrintAssemblyTabs = React.memo<Props>(({
  selectTab,
  selectedTab,
  orderDetails,
}) => {
  const tabs = useMemo(() => ([
    {
      title: <strong>{`TO BE PRINT ASSEMBLED (${orderDetails?.toBePrintAssemblied ?? 0})`}</strong>,
      name: PrintAssemblyTabsEnum.ToBePrintAssembled,
    },
    {
      title: <strong>{`DONE (${orderDetails?.done ?? 0})`}</strong>,
      name: PrintAssemblyTabsEnum.Done,
    },
  ]
  ), [orderDetails]);

  return (
    <Tabs
      tabs={tabs}
      selectedTab={selectedTab}
      selectTab={selectTab as (newSelectedTab: string) => void}
      size={tabSizesEnum.Large}
    />
  );
});

export default PrintAssemblyTabs;
