import { HagPrintRoomOrderAssemblyPagedListDto } from '@api/fulfillment/models';
import Button from '@components/shared/Buttons/Button';
import React, { useCallback } from 'react';

interface OwnProps {
  item: HagPrintRoomOrderAssemblyPagedListDto;
  onClick: (item: HagPrintRoomOrderAssemblyPagedListDto) => void;
}

type Props = OwnProps;

const DoneButton = React.memo<Props>(({
  item,
  onClick,
}) => {
  const handleClickEvent = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick(item);
  }, [
    item,
    onClick,
  ]);

  return (
    <Button
      type={'primary'}
      text={'Done'}
      onClick={handleClickEvent}
      classes='button button--color-gossamer button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase ml-10'
    />
  );
});

export default DoneButton;
