import React from 'react';
import PropTypes from 'prop-types';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import ListCell from '@sharedComponents/Table/TableCells/ListCell';

const ItemPersonalizationCell = React.memo(({ item }) => {
  if (item.isLockerItem) {
    const personalizations = item.decorations && item.decorations
      .filter((decoration) => decoration.type === decorationTypeEnum.Personalization)
      .map((decoration) => decoration.decorationLocation);

    return personalizations.length > 0
      ? <ListCell items={personalizations} />
      : '-';
  }

  return item.personalization_locations.length > 0
    ? <ListCell items={item.personalization_locations} />
    : '-';
});

ItemPersonalizationCell.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ItemPersonalizationCell;
