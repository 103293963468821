import React from 'react';
import { parseDateNumeric } from '@util/dateHandler';
import { ProductVendorQuantityDto } from '@api/productCatalog/models';

interface OwnProps{
  productVendorQuantity: ProductVendorQuantityDto;
  isPrimary: boolean;
}

type Props = OwnProps;

const ItemInventoryProductVendorQuantityInfo = React.memo<Props>(({
  productVendorQuantity,
  isPrimary = false,
}) => (
  <>
    {!isPrimary && <div>-----</div>}
    <div>{productVendorQuantity?.vendorId}</div>
    <div className='pre-line'>
      {`Last Updated: ${productVendorQuantity?.updated && parseDateNumeric(productVendorQuantity.updated) || '-'}`}
    </div>
    <div className='pre-line'>
      {`Last Document Date: ${productVendorQuantity?.date && parseDateNumeric(productVendorQuantity.date) || '-'}`}
    </div>
    <div className='pre-line'>
      {`Quantity: ${productVendorQuantity?.quantity}`}
    </div>
  </>
));

export default ItemInventoryProductVendorQuantityInfo;
