import { Dispatch } from 'redux';
import {
  fulfillmentApi,
  slServicesApi,
} from '../../sqdApis';
import * as fulfillmentSupport from '@api/fulfillment/support';
import * as productCatalogUtility from '@api/productCatalog/utility';
import * as squadlockerServicesSupport from '@api/squadlockerServices/support';
import * as financialServices from '@api/financialServices/support-homefield';
import * as ordersServices from '@api/orders/utility';
import {
  makeApiCallWithErrorModal,
  makeApiCall,
} from '@util/apiHelper';
import supportUrls from '@constants/sqdApiUrls/supportUrls';

import * as actionTypes from './types';
import { ApiResponse } from '@models/common/ApiResponse';

type UpdateFulfillmentVersionAction = {
  type: typeof actionTypes.FULFILLMENT_VERSION;
  payload: any;
};

export const updateFulfillmentVersion = (data: any): UpdateFulfillmentVersionAction => ({
  type: actionTypes.FULFILLMENT_VERSION,
  payload: data,
});

type UpdateCatalogVersionAction = {
  type: typeof actionTypes.CATALOG_VERSION;
  payload: any;
};

export const updateCatalogVersion = (data: any): UpdateCatalogVersionAction => ({
  type: actionTypes.CATALOG_VERSION,
  payload: data,
});

type UpdateFinancialVersionAction = {
  type: typeof actionTypes.FINANCIAL_VERSION;
  payload: any;
};

export const updateFinancialVersion = (data: any): UpdateFinancialVersionAction => ({
  type: actionTypes.FINANCIAL_VERSION,
  payload: data,
});

type UpdateSlServicesVersionAction = {
  type: typeof actionTypes.SL_SERVICES_VERSION;
  payload: any;
};

export const updateSlServicesVersion = (data: any): UpdateSlServicesVersionAction => ({
  type: actionTypes.SL_SERVICES_VERSION,
  payload: data,
});

type UpdateOrdersVersionAction = {
  type: typeof actionTypes.ORDERS_VERSION;
  payload: any;
};

export const updateOrdersVersion = (data: any): UpdateOrdersVersionAction => ({
  type: actionTypes.ORDERS_VERSION,
  payload: data,
});

type UpdateShippingOptionsAction = {
  type: typeof actionTypes.SHIPPING_OPTIONS;
  payload: any;
};

export const updateShippingOptions = (data: any): UpdateShippingOptionsAction => ({
  type: actionTypes.SHIPPING_OPTIONS,
  payload: data,
});

type UpdateTeamColorsAction = {
  type: typeof actionTypes.TEAM_COLORS;
  payload: any;
};

export const updateTeamColors = (data: any) => ({
  type: actionTypes.TEAM_COLORS,
  payload: data,
});

type UpdateColorsAction = {
  type: typeof actionTypes.COLORS;
  payload: any;
};

export const updateColors = (data: any): UpdateColorsAction => ({
  type: actionTypes.COLORS,
  payload: data,
});

type UpdateApplicationSettingsAction = {
  type: typeof actionTypes.APPLICATION_SETTINGS;
  payload: any;
};

export const updateApplicationSettings = (data: any): UpdateApplicationSettingsAction => ({
  type: actionTypes.APPLICATION_SETTINGS,
  payload: data,
});

type UpdateSettingsAction = {
  type: typeof actionTypes.SETTINGS;
  payload: any;
};

export const updateSettings = (data: any): UpdateSettingsAction => ({
  type: actionTypes.SETTINGS,
  payload: data,
});

type UpdateDtgStationsAction = {
  type: typeof actionTypes.UPDATE_DTG_STATIONS;
  data: any;
};

export const updateDtgStations = (data: any): UpdateDtgStationsAction => ({
  type: actionTypes.UPDATE_DTG_STATIONS,
  data,
});

export const updateColoredStylesDecorationMethods = (data: any) => ({
  type: actionTypes.UPDATE_COLORED_STYLES_DECORATION_METHODS,
  payload: data,
});

export type SupportAction = UpdateFulfillmentVersionAction
  | UpdateCatalogVersionAction
  | UpdateFinancialVersionAction
  | UpdateSlServicesVersionAction
  | UpdateTeamColorsAction
  | UpdateColorsAction
  | UpdateApplicationSettingsAction
  | UpdateSettingsAction
  | UpdateDtgStationsAction;

export const fetchFulfillmentVersion = () => async (dispatch: Dispatch): Promise<void> => {
  const call = fulfillmentSupport.getHomefieldApiSupportVersion();
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateFulfillmentVersion(res));
  }
};

export const fetchCatalogVersion = () => async (dispatch: Dispatch): Promise<void> => {
  const call = productCatalogUtility.getHomefieldApiVersion();
  const res: any = await makeApiCall(call);

  if (res) {
    dispatch(updateCatalogVersion(res.message));
  }
};

export const fetchSlServicesVersion = () => async (dispatch: Dispatch): Promise<void> => {
  const call = squadlockerServicesSupport.getVersion();
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateSlServicesVersion(res.message));
  }
};

export const fetchFinancialVersion = () => async (dispatch: Dispatch): Promise<void> => {
  const call = financialServices.getHomefieldApiSupportVersion();
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateFinancialVersion(res.message));
  }
};

export const fetchOrdersVersion = () => async (dispatch: Dispatch): Promise<void> => {
  const call = ordersServices.getHomefieldApiUtilityVersion();
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateOrdersVersion((res as ApiResponse).message));
  }
};

export const fetchShippingOptions = () => async (dispatch: Dispatch): Promise<void> => {
  const call = slServicesApi.get(supportUrls.shippingOptions, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateShippingOptions(res));
  }
};

export const fetchTeamColors = () => async (dispatch: Dispatch): Promise<void> => {
  const call = slServicesApi.get(supportUrls.teamColors, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res && res.length) {
    const mappedTeamColors = res.map((tc: any) => ({
      id: tc.id,
      code: tc.code,
      name: tc.name,
      hexValue: tc.hex_value,
      brightness: tc.brightness,
    }));

    dispatch(updateTeamColors(mappedTeamColors));
  }
};

export const fetchColors = () => async (dispatch: Dispatch): Promise<void> => {
  const call = slServicesApi.get(supportUrls.colors, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    const colors = res.map((c: any) => ({
      id: c.id,
      code: c.code,
      displayName: c.display_name,
      brightness: c.brightness,
      hexValue: c.hex_value,
      isArtwork: c.is_artwork,
      isFlood: c.is_flood,
    }));

    dispatch(updateColors(colors));
  }
};

export const fetchSettings = () => async (dispatch: Dispatch): Promise<void> => {
  const call = slServicesApi.get(supportUrls.settings, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateSettings(res));
  }
};

export const uploadImage = async (attachedImage: any): Promise<void> => {
  if (!attachedImage) {
    throw new Error('Missing image file');
  }

  const formData = new FormData();
  formData.append('image', attachedImage, attachedImage.name);

  const call = slServicesApi.post(supportUrls.uploadImage, {
    body: formData,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const fetchApplicationSettings = () => async (dispatch: Dispatch): Promise<void> => {
  const call = fulfillmentApi.get(supportUrls.applicationSettings, { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateApplicationSettings(res));
  }

  return res;
};

export const getDtgStations = () => async (dispatch: Dispatch): Promise<void> => {
  const call = fulfillmentApi.get(supportUrls.dtgStations, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgStations(res));
  }
};

export const getColoredStylesDecorationMethods = (coloredStylesIds: number[]) => async (dispatch: Dispatch) => {
  const coloredStylesDecorationMethods: { [coloredStyleId: number]: string; } = {};

  coloredStylesIds.map(async (coloredStylesId) => {
    const call = slServicesApi.get(supportUrls.coloredStyleDecorationMethod(coloredStylesId), {
      handleBlockingLoading: false,
    });
    const res = await makeApiCallWithErrorModal(call);

    if (res) {
      coloredStylesDecorationMethods[coloredStylesId] = res;
    }
  });

  dispatch(updateColoredStylesDecorationMethods(coloredStylesDecorationMethods));
};
