import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toggleArrayMember } from '@util/componentHelper';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Button from '@sharedComponents/Buttons/Button';
import ColorSwatchDropdownItem from './ColorSwatchDropdownItem';

class LogoColorsDropdown extends Component {
  state = {
    dropIsActive: false,
    selectedColors: [],
  };

  toggleDropdown = () => {
    this.setState({ dropIsActive: !this.state.dropIsActive });
  };

  closeDropdown = () => {
    this.setState({
      dropIsActive: false,
      selectedColors: [],
    });
  };

  toggleSelectColor = (color) => {
    const { selectedColors } = this.state;

    this.setState({ selectedColors: toggleArrayMember(selectedColors, color) });
  };

  addColors = () => {
    const { addColors } = this.props;
    const { selectedColors } = this.state;

    addColors(selectedColors);
    this.closeDropdown();
  };

  render() {
    const {
      dropIsActive,
      selectedColors,
    } = this.state;

    const { colors } = this.props;

    const options = colors
      .map((c, index) => (
        <ColorSwatchDropdownItem
          key={index}
          color={c.code}
          colorDisplayName={c.displayName}
          hexValue={c.hexValue}
          handleSelect={this.toggleSelectColor}
          isSelected={selectedColors.includes(c.code)}
        />
      ));

    return (
      <OutsideClickWrapper onClick={this.closeDropdown}>
        <div className='color-dropdown__select'>
          <div onClick={this.toggleDropdown}>
            Add SquadLocker Colors
            <div className='color-dropdown__arrow' />
          </div>
          <div className={`color-dropdown__options ${dropIsActive ? 'is-active' : ''}`}>
            <ul className='color-dropdown__options--list custom-scrollbar'>
              {options}
            </ul>
            <div className='w-100 p-5'>
              <Button
                type={'primary'}
                text={'Add'}
                onClick={this.addColors}
                classes={'w-100'}
              />
            </div>
          </div>
        </div>
      </OutsideClickWrapper>
    );
  }
}

LogoColorsDropdown.propTypes = {
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      hexValue: PropTypes.string,
    })
  ).isRequired,
  addColors: PropTypes.func.isRequired,
};

export default LogoColorsDropdown;
