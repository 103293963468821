import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '@sharedComponents/Form/Input';

const RbiUpcFormInput = React.memo(({
  memberName,
  disabled,
}) => (
  <>
    <label className='redux-form__label'>
      RBI UPC
    </label>
    <Field
      name={`${memberName}.rbiUpc`}
      placeholder={'Enter RBI UPC'}
      component={Input}
      disabled={disabled}
    />
  </>
));

RbiUpcFormInput.propTypes = {
  memberName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default RbiUpcFormInput;
