const ID = 'ORDER_MANAGEMENT' as const;

export const SEARCH_ORDER = `${ID}/SEARCH_ORDER` as const;
export const UPDATE_ORDER = `${ID}/UPDATE_ORDER` as const;
export const UPDATE_ORDER_ARTWORK = `${ID}/UPDATE_ORDER_ARTWORK` as const;
export const CANCEL_ORDER = `${ID}/CANCEL_ORDER` as const;
export const BACKORDER_ORDER = `${ID}/BACKORDER_ORDER` as const;
export const CANCEL_ORDER_ITEM = `${ID}/CANCEL_ORDER_ITEM` as const;
export const BACKORDER_ORDER_ITEM = `${ID}/BACKORDER_ORDER_ITEM` as const;
export const REPLACE_ORDER_ITEM = `${ID}/REPLACE_ORDER_ITEM` as const;
export const UPDATE_CURRENT_QUEUE = `${ID}/UPDATE_CURRENT_QUEUE` as const;
export const UPDATE_ORDER_NOTES = `${ID}/UPDATE_ORDER_NOTES` as const;
export const UPDATE_ACCOUNTING_SUBMIT_RESULTS = `${ID}/UPDATE_ACCOUNTING_SUBMIT_RESULTS` as const;
export const UPDATE_ORDER_STATUS_HISTORY = `${ID}/UPDATE_ORDER_STATUS_HISTORY` as const;
export const UPDATE_ORDER_SHIPMENTS = `${ID}/UPDATE_ORDER_SHIPMENTS` as const;
export const UPDATE_ORDER_CHANGE_LOGS = `${ID}/UPDATE_ORDER_CHANGE_LOGS` as const;
export const UPDATE_ORDER_MANAGEMENT_TABLE_STATE = `${ID}/UPDATE_ORDER_MANAGEMENT_TABLE_STATE` as const;
export const UPDATE_SAVE_TEMP_ORDER = `${ID}/UPDATE_SAVE_TEMP_ORDER` as const;
export const UPDATE_SAVE_TEMP_ITEMS = `${ID}/UPDATE_SAVE_TEMP_ITEMS` as const;
export const DISCARD_TEMP_ORDER = `${ID}/DISCARD_TEMP_ORDER` as const;
export const RESET_ORDER_MANAGEMENT_TABLE_STATE = `${ID}/RESET_ORDER_MANAGEMENT_TABLE_STATE` as const;
export const UPDATE_ORDER_FLAGS = `${ID}/UPDATE_ORDER_FLAGS` as const;
export const UPDATE_ORDER_COUPON_DETAILS = `${ID}/UPDATE_ORDER_COUPON_DETAILS` as const;

export default ID;
