import organizationsUrls from '@constants/sqdApiUrls/organizationsUrls';
import { slServicesApi } from '../../sqdApis';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';

export const setOrganizationGroupFeatured = async (groupId, featured) => {
  const body = JSON.stringify({ featured });

  const call = slServicesApi.put(organizationsUrls.setOrganizationGroupFeatured(groupId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCall(call);

  return res;
};

export const removeOrganizationGroup = async (groupId) => {
  const call = slServicesApi.delete(organizationsUrls.organizationGroup(groupId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateFeaturedLockersForOrganization = (organizationId, lockers) => async () => {
  const body = JSON.stringify({
    lockers: lockers.map((l) => ({
      id: l.id,
      orderPlace: l.orderPlace,
    })),
  });

  const call = slServicesApi.put(organizationsUrls.organizationFeaturedLockers(organizationId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const reorderOrganizationGroupLockers = (groupId, lockers) => async () => {
  const body = JSON.stringify({
    lockers: lockers.map((l) => ({
      id: l.id,
      orderPlace: l.orderPlace,
    })),
  });

  const call = slServicesApi.put(organizationsUrls.organizationGroupLockers(groupId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
