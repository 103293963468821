import React from 'react';
import RostersTable from '@components/RosterManager/RostersTable';

interface OwnProps {
  lockerId: number;
}

type Props = OwnProps;

const RostersTab = React.memo<Props>(({ lockerId }) => (
  <RostersTable
    lockerId={lockerId}
  />
));

export default RostersTab;
