import React from 'react';
import { merchandisingUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const MerchandisingLink = React.memo(() => (
  <MenuTile
    title={'Merchandising'}
    url={merchandisingUrl}
    icon={'checkroom'}
  />
));

export default MerchandisingLink;
