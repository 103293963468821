import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import {
  Router,
  Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import {
  useFlags,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';
import store from './store';
import DataContainer from './DataContainer';
import PrivateRoute from '@sharedComponents/Authorization/PrivateRoute';
import {
  autoLoginUrl,
  loginUrl,
  callbackUrl,
  adminsUrl,
  adminDetailsUrl,
  advancedOptionsUrl,
  applicationSettingsUrl,
  serviceManagerUrl,
  artworkTasksWithStatusUrl,
  artVectorizationTabUrl,
  backorderManagementUrl,
  brandsUrl,
  categoriesUrl,
  couponsUrl,
  designConfigurationUrl,
  dtgEnvironmentsUrl,
  dtgArtworkSettingsUrl,
  dtgPersonalizationSettingsUrl,
  dtgEntitiesUrl,
  dtgStationsUrl,
  dtgTabletProfilesUrl,
  fundraisingPayoutsUrl,
  lockerManagerUrl,
  lockerManagerAddProductsPageUrl,
  lockerDetailsUrl,
  lockerLogoBankUrl,
  logoReviewUrl,
  logoReviewTasksUrl,
  lookupEntitiesUrl,
  llSchedulingUrl,
  llSchedulingQueueDetailsUrl,
  parentCategoriesUrl,
  decorationLocationsUrl,
  decorationLocationArtworkSizesUrl,
  colorsUrl,
  sportsUrl,
  onHandInventoryUrl,
  orderManagementWithFilterUrl,
  orderDetailsUrl,
  orderDetailsNewOrder,
  orderDetailsNewOrderAddItems,
  organizationsUrl,
  organizationDetailsUrl,
  organizationGroupsUrl,
  orderAssemblyUrl,
  orderAssemblyReviewByBarcodeUrl,
  orderAssemblyReviewByOrderUrl,
  productionOverviewUrl,
  productionOverviewReviewByOrderUrl,
  productionOverviewReviewByBarcodeUrl,
  printFilesUrl,
  printAssemblyOrderUrl,
  printSheetUrl,
  productionAssemblyUrl,
  productionAssemblyDetailsUrl,
  productionAssemblyDetailsBarcodeUrl,
  productionUrl,
  productionAssemblyHagUrl,
  productionAssemblyDtgUrl,
  productionAssemblyEmbUrl,
  productionAssemblyHagReviewByOrderUrl,
  productionAssemblyDtgReviewByOrderUrl,
  productionAssemblyEmbReviewByOrderUrl,
  productionAssemblyHagReviewByBarcodeUrl,
  productionAssemblyDtgReviewByBarcodeUrl,
  productionAssemblyEmbReviewByBarcodeUrl,
  productCatalogUrl,
  rosterManagerUrl,
  rosterDetailsUrl,
  styleUrl,
  syncStyleUrl,
  skuSetupUrl,
  vendorsUrl,
  layoutsUrl,
  collectionDetailsUrl,
  reorderCollectionItemsUrl,
  vendorOrdersUrl,
  qualityCheckUrl,
  qualityCheckReviewByBarcodeUrl,
  qualityCheckReviewByOrderUrl,
  productionAssemblyPtlWallsUrl,
  warehouseSchedulingUrl,
  voucherOrdersUrl,
  addVoucherOrderUrl,
  voucherOrderDetailsUrl,
  bulkActionsUrl,
  bulkAddItemUrl,
  printRoomUrl,
  bulkAddStyleUrl,
  printRoomOrderUrl,
  printRoomOrderAssemblyUrl,
  qualityAssuranceCodesUrl,
  batchWarehouseSchedulingUrl,
  batchWarehouseSchedulingListUrl,
  merchandisingUrl,
  merchandisingEffortDetailsUrl,
  reportsUrl,
  orderItemFilesUrl,
  crossSellsUrl,
} from '@constants/clientUrls/clientUrls';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import userManager from '@util/userManager';
import history from '@util/browserHistory';
import { getQueryClientInstance } from '@util/queryClientHelper';
import Header from '@components/Header/Header';
import Drawer from '@components/Drawer/Drawer';
import DrawerOverlay from '@components/Drawer/DrawerOverlay';
import Footer from '@components/Footer/Footer';
import ProductionArtwork from '@components/ProductionArtwork/ProductionArtwork';
import MyApps from '@components/MyApps/MyApps';
import ArtworkFileTasks from '@components/ArtworkFileTasks/ArtworkFileTasks';
import OrderManagement from '@components/OrderManagement/OrderManagement';
import OrderManagementSearch from '@components/OrderManagementTable/OrderManagement';
import PrintRoom from '@components/PrintRoom/PrintRoom';
import AutoLoginPage from '@components/Authentication/AutoLoginPage';
import CallbackPage from '@components/Authentication/CallbackPage';
import PrintSheet from '@components/PrintSheet/PrintSheet';
import LoginPage from '@components/Authentication/LoginPage';
import LogoReview from '@components/LogoReview/LogoReview';
import LogoReviewTasks from '@components/LogoReview/LogoReviewTasks/LogoReviewTasks';
import LockerManager from '@components/LockerManagerTable/LockerManager';
import LockerManagerAddProductsPage from '@components/LockerManagerModals/LockerManagerAddProducts/LockerManagerAddProductsPage';
import WeedingAndMasking from '@components/WeedingAndMasking/WeedingAndMasking';
import LockerManagerDetails from '@components/LockerManagerDetails/LockerManagerDetails';
import LogoBank from '@components/LockerManagerDetails/LogoBank/LogoBank';
import ProductionAssemblyReview from '@components/ProductionAssembly/ProductionAssemblyReview/ProductionAssemblyReview';
import ProductionAssemblySearch from '@components/ProductionAssembly/ProductionAssemblySearch/ProductionAssemblySearch';
import Production from '@components/Production/Production';
import ProductionAssemblyHag from '@components/Production/ProductionAssemblyHag/ProductionAssemblyHag';
import ProductionAssemblyEmb from '@components/Production/ProductionAssemblyEmb/ProductionAssemblyEmb';
import ProductionAssemblyDtg from '@components/Production/ProductionAssemblyDtg/ProductionAssemblyDtg';
import OnHandInventory from '@components/OnHandInventory/OnHandInventory';
import LLScheduling from '@components/LLScheduling/LLScheduling';
import SchedulingQueueDetails from '@components/LLScheduling/SchedulingQueueDetails';
import WarehouseScheduling from '@components/WarehouseScheduling/WarehouseScheduling';
import Coupons from '@components/Coupons/Coupons';
import Organizations from '@components/Organizations/Organizations';
import OrganizationDetails from '@components/Organizations/OrganizationDetails/OrganizationDetails';
import OrganizationGroups from '@components/Organizations/OrganizationGroups/OrganizationGroups';
import Admins from '@components/Admins/Admins';
import AdminDetails from '@components/Admins/AdminDetails/AdminDetails';
import ProductCatalog from '@components/ProductCatalog/ProductCatalog';
import ProductCatalogDetails from '@components/ProductCatalog/ProductCatalogDetails/ProductCatalogDetails';
import SkuSetup from '@components/SkuSetup/SkuSetup';
import FundraisingPayouts from '@components/FundraisingPayouts/FundraisingPayouts';
import ApplicationSettings from '@components/ApplicationSettings/ApplicationSettings';
import AdvancedOptions from '@components/AdvancedOptions/AdvancedOptions';
import ServiceManager from '@components/ServiceManager/ServiceManager';
import BackorderManagement from '@components/BackorderManagement/BackorderManagement';
import LookupEntities from '@components/LookupEntities/LookupEntities';
import Colors from '@components/Colors/Colors';
import Sports from '@components/Sports/Sports';
import Categories from '@components/Categories/Categories';
import ParentCategories from '@components/ParentCategories/ParentCategories';
import DecorationLocations from '@components/DecorationLocations/DecorationLocations';
import DecorationLocationArtworkSizes from '@components/DecorationLocationArtworkSizes/DecorationLocationArtworkSizes';
import Brands from '@components/Brands/Brands';
import Vendors from '@components/Vendors/Vendors';
import Layouts from '@components/Layouts/Layouts';
import SyncStyleDetails from '@components/ProductCatalog/SyncStyle/SyncStyleDetails';
import CollectionDetails from '@components/Collections/CollectionDetails/CollectionDetails';
import ReorderCollectionItems from '@components/Collections/ReorderCollectionItems/ReorderCollectionItems';
import RosterManager from '@components/RosterManager/RosterManager';
import RosterDetails from '@components/RosterManager/RosterDetails/RosterDetails';
import VendorOrders from '@components/VendorOrders/VendorOrders';
import VoucherOrders from '@components/VoucherOrders/VoucherOrders';
import DtgEntities from '@components/DtgEntities/DtgEntities';
import DtgEnvironments from '@components/DtgEnvironments/DtgEnvironments';
import DtgArtworkSettings from '@components/DtgArtworkSettings/DtgArtworkSettings';
import DtgPersonalizationSettings from '@components/DtgPersonalizationSettings/DtgPersonalizationSettings';
import DtgStations from '@components/DtgStations/DtgStations';
import DtgTabletProfiles from '@components/DtgTabletProfiles/DtgTabletProfiles';
import OrderManagementNewOrder from '@components/OrderManagementTable/OrderManagementNewOrder/OrderManagementNewOrder';
import NewOrderAddItemsTable from '@components/OrderManagementTable/OrderManagementNewOrder/NewOrderAddItemsTable';
import ProductionAssemblyHagSearch from '@components/Production/ProductionAssemblyHag/ProductionAssemblyHagSearch';
import ProductionAssemblyDtgSearch from '@components/Production/ProductionAssemblyDtg/ProductionAssemblyDtgSearch';
import ProductionAssemblyEmbSearch from '@components/Production/ProductionAssemblyEmb/ProductionAssemblyEmbSearch';
import OrderAssemblyReview from '@components/Production/OrderAssembly/OrderAssemblyReview/OrderAssemblyReview';
import OrderAssemblySearch from '@components/Production/OrderAssembly/OrderAssemblySearch';
import QualityCheckReview from '@components/Production/QualityCheck/QualityCheckReview/QualityCheckReview';
import QualityCheckSearch from '@components/Production/QualityCheck/QualitCheckSearch';
import PtlWall from '@components/Ptl/PtlWall';
import AddVoucherOrder from '@components/VoucherOrders/VoucherOrderCreation/AddVoucherOrder';
import VoucherDetails from '@components/VoucherOrders/VoucherDetails/VoucherDetails';
import ProductionOverviewSearch from '@components/Production/ProductionOverview/ProductionOverviewSearch';
import ProductionOverviewReview from '@components/Production/ProductionOverview/ProductionOverviewReview/ProductionOverviewReview';
import BulkActions from '@components/BulkActions/BulkActions';
import BulkAddItem from '@components/BulkActions/BulkAddItem/BulkAddItem';
import PrintRoomNew from '@components/PrintRoomNew/PrintRoomNew';
import BulkAddStyle from '@components/BulkActions/BulkStyleCreation/BulkStyleCreation';
import OrderDetails from '@components/PrintRoomOrder/PrintRoomOrder';
import { HeaderContextProvider } from '@components/shared/Contexts/HeaderContext';
import PrintAssembly from '@components/PrintAssembly/PrintAssembly';
import QualityAssuranceCodes from '@components/QualityAssuranceCodes/QualityAssuranceCodes';
import PrintRoomNewBarcodeDetector from '@components/PrintRoomNew/PrintRoomNewBarcodeDetector';
import DesignConfiguration from '@components/DesignConfiguration/DesignConfiguration';
import BatchWarehouseScheduling from '@components/BatchWarehouseScheduling/BatchWarehouseScheduling';
import BatchWarehouseSchedulingList from '@components/BatchWarehouseScheduling/BatchWarehouseSchedulingList';
import MerchandisingEffortsTable from '@components/Merchandising/MerchandisingEffortsTable';
import MerchandisingEffortDetails from '@components/Merchandising/MerchandisingEffortDetails';
import ReportsMain from '@components/Reports/ReportsMain';
import ReportsSelections from '@components/Reports/ReportsSelections';
import ReportsRun from '@components/Reports/ReportsRun';
import OrderItemFiles from '@components/OrderItemFiles/OrderItemFiles';
import { muiTheme } from '@constants/values';
import { ThemeProvider } from '@material-ui/core/styles';
import CrossSells from '@components/CrossSells/CrossSells';

const queryClient = getQueryClientInstance();

function App(): JSX.Element {
  const {
    hfHideOldProduction,
    hfHideBulkAddStyle,
  } = useFlags();

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={muiTheme}>
          <Provider store={store}>
            <OidcProvider
              store={store}
              userManager={userManager}
            >
              <Router history={history}>
                <HeaderContextProvider>
                  <DataContainer>
                    <div id='app-container'>
                      <Header />
                      <main
                        data-toggle='drawer-off'
                        className='main main--color-gray'
                      >
                        <Route
                          exact={true}
                          path='/'
                          component={MyApps}
                        />
                        <PrivateRoute
                          path={artVectorizationTabUrl(':status?')}
                          permissions={PermissionsEnum.ProductionArtworkVisit}
                          component={ProductionArtwork}
                        />
                        <PrivateRoute
                          path={artworkTasksWithStatusUrl(':lockerId', ':status?')}
                          permissions={PermissionsEnum.ProductionArtworkVisit}
                          component={ArtworkFileTasks}
                        />
                        <PrivateRoute
                          path={printFilesUrl}
                          permissions={PermissionsEnum.PrintRoomVisit}
                          component={PrintRoom}
                        />
                        <PrivateRoute
                          exact={true}
                          path={printRoomOrderUrl(':orderNumber')}
                          permissions={PermissionsEnum.PrintRoomVisit}
                          component={OrderDetails}
                        />
                        <PrivateRoute
                          exact={true}
                          path={printRoomOrderAssemblyUrl(':orderNumber')}
                          permissions={PermissionsEnum.PrintRoomVisit}
                          component={PrintAssembly}
                        />
                        <PrivateRoute
                          path={printRoomUrl}
                          permissions={PermissionsEnum.PrintRoomVisit}
                          component={PrintRoomNew}
                          exact={true}
                        />
                        <PrivateRoute
                          path={printRoomUrl}
                          permissions={PermissionsEnum.PrintRoomVisit}
                          component={PrintRoomNewBarcodeDetector}
                        />
                        <Route
                          path={loginUrl}
                          component={LoginPage}
                        />
                        <Route
                          path={autoLoginUrl}
                          component={AutoLoginPage}
                        />
                        <Route
                          path={`${callbackUrl}*`}
                          component={CallbackPage}
                        />
                        <PrivateRoute
                          path={logoReviewUrl}
                          permissions={PermissionsEnum.LogoReviewVisit}
                          component={LogoReview}
                        />
                        <PrivateRoute
                          path={logoReviewTasksUrl}
                          permissions={PermissionsEnum.LogoReviewVisit}
                          component={LogoReviewTasks}
                        />
                        <PrivateRoute
                          exact={true}
                          path={lockerManagerUrl}
                          permissions={PermissionsEnum.LockerManagerVisit}
                          component={LockerManager}
                        />
                        <PrivateRoute
                          exact={true}
                          path={lockerManagerAddProductsPageUrl(':lockerId')}
                          permissions={PermissionsEnum.LockerManagerVisit}
                          component={LockerManagerAddProductsPage}
                        />
                        <PrivateRoute
                          exact={true}
                          path={lockerDetailsUrl(':lockerId')}
                          permissions={PermissionsEnum.LockerManagerVisit}
                          component={LockerManagerDetails}
                        />
                        <PrivateRoute
                          path={lockerLogoBankUrl(':lockerId')}
                          permissions={PermissionsEnum.LockerManagerVisit}
                          component={LogoBank}
                        />
                        <PrivateRoute
                          exact={true}
                          path={orderDetailsUrl(':orderNumber')}
                          permissions={PermissionsEnum.OrderManagementVisit}
                          component={OrderManagement}
                        />
                        <PrivateRoute
                          exact={true}
                          path={orderDetailsNewOrder}
                          permissions={PermissionsEnum.OrderManagementVisit}
                          component={OrderManagementNewOrder}
                        />
                        <PrivateRoute
                          exact={true}
                          path={orderDetailsNewOrderAddItems}
                          permissions={PermissionsEnum.OrderManagementVisit}
                          component={NewOrderAddItemsTable}
                        />
                        <PrivateRoute
                          path={orderManagementWithFilterUrl(':filter?', ':lockerIds?')}
                          permissions={PermissionsEnum.OrderManagementVisit}
                          component={OrderManagementSearch}
                        />
                        <PrivateRoute
                          path={`${printSheetUrl(':sheetId')}/:orderNumber?/:barcode?`}
                          permissions={PermissionsEnum.PrintRoomVisit}
                          component={PrintSheet}
                        />
                        <PrivateRoute
                          path={printAssemblyOrderUrl(':sheetId', ':orderNumber')}
                          permissions={PermissionsEnum.PrintRoomVisit}
                          component={WeedingAndMasking}
                        />
                        {
                          !hfHideOldProduction &&
                          <>
                            <PrivateRoute
                              exact={true}
                              path={productionAssemblyUrl}
                              permissions={PermissionsEnum.ProductionOverviewVisit}
                              component={ProductionAssemblySearch}
                            />
                            <PrivateRoute
                              exact={true}
                              path={productionAssemblyDetailsUrl(':orderNumber')}
                              permissions={PermissionsEnum.ProductionOverviewVisit}
                              component={ProductionAssemblyReview}
                            />
                            <PrivateRoute
                              exact={true}
                              path={productionAssemblyDetailsBarcodeUrl(':orderNumber', ':barcode')}
                              permissions={PermissionsEnum.ProductionOverviewVisit}
                              component={ProductionAssemblyReview}
                            />
                          </>
                        }
                        <PrivateRoute
                          exact={true}
                          path={productionUrl}
                          permissions={PermissionsEnum.ProductionVisit}
                          component={Production}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyHagUrl}
                          permissions={PermissionsEnum.ProductionAssemblyHagVisit}
                          component={ProductionAssemblyHagSearch}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyDtgUrl}
                          permissions={PermissionsEnum.ProductionAssemblyDtgVisit}
                          component={ProductionAssemblyDtgSearch}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyEmbUrl}
                          permissions={PermissionsEnum.ProductionAssemblyEmbVisit}
                          component={ProductionAssemblyEmbSearch}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyHagReviewByOrderUrl(':orderNumber')}
                          permissions={PermissionsEnum.ProductionAssemblyHagVisit}
                          component={ProductionAssemblyHag}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyDtgReviewByOrderUrl(':orderNumber')}
                          permissions={PermissionsEnum.ProductionAssemblyDtgVisit}
                          component={ProductionAssemblyDtg}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyEmbReviewByOrderUrl(':orderNumber')}
                          permissions={PermissionsEnum.ProductionAssemblyEmbVisit}
                          component={ProductionAssemblyEmb}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyHagReviewByBarcodeUrl(':orderNumber', ':barcode')}
                          permissions={PermissionsEnum.ProductionAssemblyHagVisit}
                          component={ProductionAssemblyHag}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyDtgReviewByBarcodeUrl(':orderNumber', ':barcode')}
                          permissions={PermissionsEnum.ProductionAssemblyDtgVisit}
                          component={ProductionAssemblyDtg}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionAssemblyEmbReviewByBarcodeUrl(':orderNumber', ':barcode')}
                          permissions={PermissionsEnum.ProductionAssemblyEmbVisit}
                          component={ProductionAssemblyEmb}
                        />
                        <PrivateRoute
                          path={productionAssemblyPtlWallsUrl}
                          permissions={PermissionsEnum.ProductionAssemblyDtgVisit}
                          component={PtlWall}
                        />
                        <PrivateRoute
                          exact={true}
                          path={orderAssemblyUrl}
                          permissions={PermissionsEnum.OrderAssemblyVisit}
                          component={OrderAssemblySearch}
                        />
                        <PrivateRoute
                          exact={true}
                          path={orderAssemblyReviewByOrderUrl(':orderNumber')}
                          permissions={PermissionsEnum.OrderAssemblyVisit}
                          component={OrderAssemblyReview}
                        />
                        <PrivateRoute
                          exact={true}
                          path={orderAssemblyReviewByBarcodeUrl(':orderNumber', ':barcode')}
                          permissions={PermissionsEnum.OrderAssemblyVisit}
                          component={OrderAssemblyReview}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionOverviewUrl}
                          permissions={PermissionsEnum.ProductionOverviewVisit}
                          component={ProductionOverviewSearch}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionOverviewReviewByOrderUrl(':orderNumber')}
                          permissions={PermissionsEnum.ProductionOverviewVisit}
                          component={ProductionOverviewReview}
                        />
                        <PrivateRoute
                          exact={true}
                          path={productionOverviewReviewByBarcodeUrl(':orderNumber', ':barcode')}
                          permissions={PermissionsEnum.ProductionOverviewVisit}
                          component={ProductionOverviewReview}
                        />

                        <PrivateRoute
                          exact={true}
                          path={qualityCheckUrl}
                          permissions={PermissionsEnum.QualityCheckVisit}
                          component={QualityCheckSearch}
                        />
                        <PrivateRoute
                          exact={true}
                          path={qualityCheckReviewByOrderUrl(':orderNumber')}
                          permissions={PermissionsEnum.QualityCheckVisit}
                          component={QualityCheckReview}
                        />
                        <PrivateRoute
                          exact={true}
                          path={qualityCheckReviewByBarcodeUrl(':orderNumber', ':barcode')}
                          permissions={PermissionsEnum.QualityCheckVisit}
                          component={QualityCheckReview}
                        />

                        <PrivateRoute
                          exact={true}
                          path={merchandisingUrl}
                          permissions={PermissionsEnum.MerchandisingVisit}
                          component={MerchandisingEffortsTable}
                        />

                        <PrivateRoute
                          exact={true}
                          path={merchandisingEffortDetailsUrl(':effortId')}
                          permissions={PermissionsEnum.MerchandisingVisit}
                          component={MerchandisingEffortDetails}
                        />
                        <PrivateRoute
                          exact={true}
                          path={onHandInventoryUrl}
                          permissions={PermissionsEnum.OnHandInventoryVisit}
                          component={OnHandInventory}
                        />
                        <PrivateRoute
                          path={llSchedulingQueueDetailsUrl(':queueId')}
                          permissions={PermissionsEnum.SchedulingVisit}
                          component={SchedulingQueueDetails}
                        />
                        <PrivateRoute
                          path={llSchedulingUrl}
                          permissions={PermissionsEnum.SchedulingVisit}
                          component={LLScheduling}
                        />
                        <PrivateRoute
                          path={warehouseSchedulingUrl}
                          permissions={PermissionsEnum.SchedulingVisit}
                          component={WarehouseScheduling}
                        />
                        <PrivateRoute
                          exact={true}
                          path={batchWarehouseSchedulingUrl}
                          permissions={PermissionsEnum.SchedulingVisit}
                          component={BatchWarehouseScheduling}
                        />
                        <PrivateRoute
                          path={batchWarehouseSchedulingListUrl}
                          permissions={PermissionsEnum.SchedulingVisit}
                          component={BatchWarehouseSchedulingList}
                        />
                        <PrivateRoute
                          path={couponsUrl}
                          permissions={PermissionsEnum.CouponsVisit}
                          component={Coupons}
                        />
                        <PrivateRoute
                          path={organizationGroupsUrl(':organizationId')}
                          permissions={PermissionsEnum.OrganizationsVisit}
                          component={OrganizationGroups}
                        />
                        <PrivateRoute
                          path={organizationDetailsUrl(':organizationId')}
                          permissions={PermissionsEnum.OrganizationsVisit}
                          component={OrganizationDetails}
                        />
                        <PrivateRoute
                          path={organizationsUrl}
                          permissions={PermissionsEnum.OrganizationsVisit}
                          component={Organizations}
                        />
                        <PrivateRoute
                          path={adminDetailsUrl(':adminId')}
                          permissions={PermissionsEnum.AdminsVisit}
                          component={AdminDetails}
                        />
                        <PrivateRoute
                          path={adminsUrl}
                          permissions={PermissionsEnum.AdminsVisit}
                          component={Admins}
                        />
                        <PrivateRoute
                          exact={true}
                          permissions={PermissionsEnum.ProductCatalogVisit}
                          path={productCatalogUrl}
                          component={ProductCatalog}
                        />
                        <PrivateRoute
                          exact={true}
                          path={styleUrl(':styleId')}
                          permissions={PermissionsEnum.ProductCatalogVisit}
                          component={ProductCatalogDetails}
                        />
                        <PrivateRoute
                          exact={true}
                          path={syncStyleUrl(':styleId')}
                          permissions={PermissionsEnum.ProductCatalogSyncDataVisit}
                          component={SyncStyleDetails}
                        />
                        <PrivateRoute
                          exact={true}
                          path={skuSetupUrl}
                          permissions={PermissionsEnum.SkuSetupVisit}
                          component={SkuSetup}
                        />
                        <PrivateRoute
                          exact={true}
                          path={fundraisingPayoutsUrl}
                          permissions={PermissionsEnum.FundraisingPayoutsVisit}
                          component={FundraisingPayouts}
                        />
                        <PrivateRoute
                          path={applicationSettingsUrl}
                          permissions={PermissionsEnum.ApplicationSettingsVisit}
                          component={ApplicationSettings}
                        />
                        <PrivateRoute
                          path={advancedOptionsUrl}
                          permissions={PermissionsEnum.AdvancedOptionsVisit}
                          component={AdvancedOptions}
                        />
                        <PrivateRoute
                          path={serviceManagerUrl}
                          permissions={PermissionsEnum.ServiceManagerVisit}
                          component={ServiceManager}
                        />
                        <PrivateRoute
                          path={lookupEntitiesUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={LookupEntities}
                        />
                        <PrivateRoute
                          path={colorsUrl}
                          permissions={PermissionsEnum.LookupEntitiesColorsVisit}
                          component={Colors}
                        />
                        <PrivateRoute
                          path={sportsUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={Sports}
                        />
                        <PrivateRoute
                          path={categoriesUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={Categories}
                        />
                        <PrivateRoute
                          path={parentCategoriesUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={ParentCategories}
                        />
                        <PrivateRoute
                          path={decorationLocationsUrl}
                          permissions={PermissionsEnum.LookupEntitiesDecorationLocationsVisit}
                          component={DecorationLocations}
                        />
                        <PrivateRoute
                          path={decorationLocationArtworkSizesUrl}
                          permissions={PermissionsEnum.LookupEntitiesDecorationLocationArtworkSizesVisit}
                          component={DecorationLocationArtworkSizes}
                        />
                        <PrivateRoute
                          path={brandsUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={Brands}
                        />
                        <PrivateRoute
                          path={vendorsUrl}
                          permissions={PermissionsEnum.LookupEntitiesVendorsVisit}
                          component={Vendors}
                        />
                        <PrivateRoute
                          path={layoutsUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={Layouts}
                        />
                        <PrivateRoute
                          path={backorderManagementUrl}
                          permissions={PermissionsEnum.BackorderManagementVisit}
                          component={BackorderManagement}
                        />
                        <PrivateRoute
                          exact={true}
                          path={reorderCollectionItemsUrl(':collectionId')}
                          permissions={PermissionsEnum.LockerManagerVisit}
                          component={ReorderCollectionItems}
                        />
                        <PrivateRoute
                          exact={true}
                          path={collectionDetailsUrl(':collectionId')}
                          permissions={PermissionsEnum.LockerManagerVisit}
                          component={CollectionDetails}
                        />
                        <PrivateRoute
                          exact={true}
                          path={rosterDetailsUrl(':rosterId')}
                          permissions={PermissionsEnum.RosterManagerVisit}
                          component={RosterDetails}
                        />
                        <PrivateRoute
                          path={rosterManagerUrl}
                          permissions={PermissionsEnum.RosterManagerVisit}
                          component={RosterManager}
                        />
                        <PrivateRoute
                          path={vendorOrdersUrl}
                          permissions={PermissionsEnum.VendorOrdersVisit}
                          component={VendorOrders}
                        />
                        <PrivateRoute
                          path={voucherOrdersUrl}
                          permissions={PermissionsEnum.VoucherOrdersVisit}
                          component={VoucherOrders}
                        />
                        <PrivateRoute
                          path={voucherOrderDetailsUrl(':voucherOrderId')}
                          permissions={PermissionsEnum.VoucherOrdersVisit}
                          component={VoucherDetails}
                        />
                        <PrivateRoute
                          exact={true}
                          path={addVoucherOrderUrl}
                          component={AddVoucherOrder}
                          permissions={PermissionsEnum.VoucherOrdersVisit}
                        />
                        <PrivateRoute
                          path={dtgEntitiesUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={DtgEntities}
                        />
                        <PrivateRoute
                          path={dtgEnvironmentsUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={DtgEnvironments}
                        />
                        <PrivateRoute
                          path={dtgArtworkSettingsUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={DtgArtworkSettings}
                        />
                        <PrivateRoute
                          path={dtgPersonalizationSettingsUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={DtgPersonalizationSettings}
                        />
                        <PrivateRoute
                          path={dtgStationsUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={DtgStations}
                        />
                        <PrivateRoute
                          path={dtgTabletProfilesUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={DtgTabletProfiles}
                        />
                        <PrivateRoute
                          path={bulkAddItemUrl}
                          permissions={PermissionsEnum.BulkAddItemVisit}
                          component={BulkAddItem}
                        />
                        <PrivateRoute
                          path={bulkActionsUrl}
                          permissions={PermissionsEnum.BulkActionsVisit}
                          component={BulkActions}
                        />
                        {
                          !hfHideBulkAddStyle &&
                          <>
                            <PrivateRoute
                              path={bulkAddStyleUrl}
                              permissions={PermissionsEnum.BulkAddStyleVisit}
                              component={BulkAddStyle}
                            />
                          </>
                        }
                        <PrivateRoute
                          path={qualityAssuranceCodesUrl}
                          permissions={PermissionsEnum.LookupEntitiesVisit}
                          component={QualityAssuranceCodes}
                        />
                        <PrivateRoute
                          path={designConfigurationUrl}
                          permissions={PermissionsEnum.AdminsVisit}
                          component={DesignConfiguration}
                        />
                        <PrivateRoute
                          exact={true}
                          path={reportsUrl}
                          permissions={PermissionsEnum.ReportsVisit}
                          component={ReportsMain}
                        />
                        <PrivateRoute
                          exact={true}
                          path={`${reportsUrl}/:storeName`}
                          permissions={PermissionsEnum.ReportsVisit}
                          component={ReportsSelections}
                        />
                        <PrivateRoute
                          exact={true}
                          path={`${reportsUrl}/:storeName/:reportName`}
                          permissions={PermissionsEnum.AnyUserVisit} // Any user with link can view
                          component={ReportsRun}
                        />
                        <PrivateRoute
                          exact={true}
                          path={orderItemFilesUrl}
                          permissions={PermissionsEnum.VendorOrdersVisit}
                          component={OrderItemFiles}
                        />
                        <PrivateRoute
                          exact={true}
                          path={crossSellsUrl}
                          permissions={PermissionsEnum.ProductCatalogVisit}
                          component={CrossSells}
                        />
                      </main>
                      <Drawer />
                      <DrawerOverlay />
                      <Footer />
                    </div>
                  </DataContainer>
                </HeaderContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </Router>
            </OidcProvider>
          </Provider>
        </ThemeProvider>
        <ToastContainer />
      </QueryClientProvider>
    </div>
  );
}

export default withLDProvider({ clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ENV_ID! })(App);
