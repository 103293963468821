import React from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import { fileNameFromUrl } from '@util/stringHelpers';

const LockerManagerLogo = React.memo(({
  logo,
  isSelected,
  onClick,
}) => (
  <li
    onClick={onClick.bind(null, logo)}
    className={`lockerEdit__information-change--option ${isSelected && 'is-active'}`}
  >
    <div
      title={fileNameFromUrl(logo.image)}
      className='lockerEdit__information-change--option--image'
      style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logo.image}")` }}
    />
  </li>
));

LockerManagerLogo.propTypes = {
  logo: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LockerManagerLogo;
