import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { decorationMethodsOptions } from '@constants/options/optionsValues';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { dropdownSizeS } from '@constants/values';
import {
  getBrands,
  getCategories,
  getDecorationLocations,
  getSizes,
  getColoredStyles,
  getProductsForColoredStylesDictionary,
} from '@redux/productCatalog/actions';
import { fetchTeamColors } from '@redux/support/actions';
import { isSelectedItem } from '@util/selectionHelpers';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import Pagination from '@sharedComponents/Pagination';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import LockerManagerAddProductsProduct from './LockerManagerAddProductsProduct';

class LockerManagerAddProducts extends Component {
  state = {
    pageNumber: 1,
    pageSize: 14,
    searchInput: '',
    colors: [],
    sizes: [],
    categories: [],
    brands: [],
    decorations: [],
    personalizations: [],
    recommended: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getDecorationLocations(decorationTypeEnum.Personalization));
    dispatch(getBrands());
    dispatch(getCategories());
    dispatch(fetchTeamColors());
    dispatch(getSizes());
    this.search();
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      queue,
    } = this.props;

    if (prevProps.queue !== queue) {
      const coloredStyleIds = queue.map((coloredStyle) => (coloredStyle.id));
      dispatch(getProductsForColoredStylesDictionary(coloredStyleIds));
    }
  }

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  colorChange = (colors) => {
    if (!colors) {
      return;
    }

    this.setState({
      colors,
    }, this.search);
  };

  brandChange = (brands) => {
    if (!brands) {
      return;
    }

    this.setState({
      brands,
    }, this.search);
  };

  personalizationChange = (personalizations) => {
    if (!personalizations) {
      return;
    }

    this.setState({
      personalizations,
    }, this.search);
  };

  categoryChange = (categories) => {
    if (!categories) {
      return;
    }

    this.setState({
      categories,
    }, this.search);
  };

  decorationChange = (decorations) => {
    if (!decorations) {
      return;
    }

    this.setState({
      decorations,
    }, this.search);
  };

  sizesChange = (sizes) => {
    if (!sizes) {
      return;
    }

    this.setState({
      sizes,
    }, this.search);
  };

  toggleRecommendedItems = () => {
    this.setState((prevState) => ({
      recommended: !prevState.recommended,
    }), this.search);
  };

  selectPage = (page) => {
    this.setState({ pageNumber: page }, () => {
      const { dispatch } = this.props;

      const {
        pageNumber,
        pageSize,
        brands,
        categories,
        colors,
        sizes,
        decorations,
        personalizations,
        searchInput,
        recommended,
      } = this.state;

      dispatch(getColoredStyles(
        pageNumber,
        pageSize,
        brands,
        categories,
        colors,
        sizes,
        decorations,
        personalizations,
        searchInput,
        '',
        '',
        true,
        recommended
      ));
    });
  };

  search = () => {
    const { dispatch } = this.props;

    const {
      pageSize,
      colors,
      sizes,
      categories,
      brands,
      decorations,
      personalizations,
      searchInput,
      recommended,
    } = this.state;

    dispatch(getColoredStyles(
      1,
      pageSize,
      brands,
      categories,
      colors,
      sizes,
      decorations,
      personalizations,
      searchInput,
      '',
      '',
      null,
      recommended
    ));
  };

  render() {
    const {
      queue,
      brands,
      categories,
      personalizationDecorationLocations,
      totalPages,
      pageNumber,
      teamColors,
      sizes,
      selectedProducts,
      selectProduct,
      clickProduct,
      coloredStyleProductsDictionary,
    } = this.props;

    const { recommended } = this.state;

    let tableProducts = [];

    if (queue && coloredStyleProductsDictionary) {
      tableProducts = queue.map((coloredStyle) => (
        <LockerManagerAddProductsProduct
          key={coloredStyle.id}
          product={coloredStyle}
          isSelected={isSelectedItem(selectedProducts, coloredStyle, 'id')}
          onClickProduct={clickProduct}
          onSelectProduct={selectProduct}
          coloredStyleProducts={coloredStyleProductsDictionary[coloredStyle.id]}
        />
      ));
    }

    return (
      <div>
        <div className='add-product__table'>
          <div className='add-product__table--filter'>
            <MultiSelectDropdown
              objects={categories}
              itemText={'categories'}
              updateCallback={this.categoryChange}
              textKey={'name'}
              valueKey={'id'}
              classNames={'margin-left'}
            />
            <MultiSelectDropdown
              objects={brands}
              itemText={'brands'}
              updateCallback={this.brandChange}
              textKey={'name'}
              valueKey={'id'}
              size={dropdownSizeS}
              classNames={'margin-left'}
            />
            <MultiSelectDropdown
              objects={teamColors}
              itemText={'colors'}
              updateCallback={this.colorChange}
              textKey={'name'}
              valueKey={'name'}
              size={dropdownSizeS}
              classNames={'margin-left'}
              color={true}
            />
            <MultiSelectDropdown
              objects={decorationMethodsOptions}
              itemText={'decorations'}
              updateCallback={this.decorationChange}
              textKey={'name'}
              valueKey={'value'}
              classNames={'margin-left'}
            />
            <MultiSelectDropdown
              objects={personalizationDecorationLocations}
              itemText={'personalizations'}
              updateCallback={this.personalizationChange}
              textKey={'code'}
              valueKey={'code'}
              classNames={'margin-left'}
            />
            <MultiSelectDropdown
              objects={sizes}
              itemText={'sizes'}
              updateCallback={this.sizesChange}
              classNames={'margin-left'}
            />
          </div>
          <div className='add-product__table--search'>
            {/* TO DO
            <MaterialCheckbox
              checked={availableForNewStores}
              onClick={this.toggleAvailableForNewStores}
              text={'Only Available For New Stores'}
              clickableText={false}
            />
            <MaterialCheckbox
              checked={custom}
              onClick={this.toggleCustomItems}
              text={'Only Custom Items'}
              clickableText={false}
            />
            <MaterialCheckbox
              checked={selected}
              onClick={this.toggleSelectedItems}
              text={'Only Selected Items'}
              clickableText={false}
            /> */}
            <MaterialCheckbox
              checked={recommended}
              onClick={this.toggleRecommendedItems}
              text={'Only Recommended Items'}
              clickableText={false}
            />
            <SearchFilter
              search={this.filterKey}
              classes={'ml-15'}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='add-product__table--products custom-scrollbar'>
            {tableProducts}
          </div>
          <div className='add-product__table--paging'>
            <Pagination
              totalPages={totalPages}
              pageNumber={pageNumber}
              selectPage={this.selectPage}
              range={5}
            />
          </div>
        </div>
      </div>
    );
  }
}

LockerManagerAddProducts.propTypes = {
  totalPages: PropTypes.number,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  queue: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  personalizationDecorationLocations: PropTypes.array.isRequired,
  teamColors: PropTypes.arrayOf(PropTypes.object).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedProducts: PropTypes.array,
  selectProduct: PropTypes.func.isRequired,
  clickProduct: PropTypes.func.isRequired,
  coloredStyleProductsDictionary: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({
  productCatalog,
  support,
}) => ({
  totalPages: productCatalog.coloredStylesQueue.totalPages,
  hasPreviousPage: productCatalog.coloredStylesQueue.hasPreviousPage,
  hasNextPage: productCatalog.coloredStylesQueue.hasNextPage,
  pageNumber: productCatalog.coloredStylesQueue.pageNumber,
  pageSize: productCatalog.coloredStylesQueue.pageSize,
  queue: productCatalog.coloredStylesQueue.queue,
  categories: productCatalog.categories,
  brands: productCatalog.brands,
  personalizationDecorationLocations: productCatalog.personalizationDecorationLocations,
  teamColors: support.teamColors,
  sizes: productCatalog.sizes,
  coloredStyleProductsDictionary: productCatalog.coloredStyleProductsDictionary,
});

export default connect(mapStateToProps)(LockerManagerAddProducts);
