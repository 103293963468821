import { catalogApi } from '../../sqdApis';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { mapParentCategoryErrors } from '@util/componentHelpers/parentCategoriesHelper';

export const addParentCategory = async (addParentCategoryForm) => {
  const call = catalogApi.post(productCatalogUtilityUrls.parentCategories, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(addParentCategoryForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapParentCategoryErrors);

  return res;
};

export const editParentCategory = async (editParentCategoryForm) => {
  const call = catalogApi.put(productCatalogUtilityUrls.parentCategory(editParentCategoryForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(editParentCategoryForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapParentCategoryErrors);

  return res;
};

export const deleteParentCategory = async (parentCategoryId) => {
  const call = catalogApi.delete(productCatalogUtilityUrls.parentCategory(parentCategoryId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
