/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  ApiResponse,
  CreateStyleCrossSellCommand,
  StyleCrossSellDtoPagedList,
  GetHomefieldApiStylecrosssellsAccessoriesParams
} from './models'
import { catalogApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postHomefieldApiStylecrosssells = <TData = ApiResponse>(
    createStyleCrossSellCommand: CreateStyleCrossSellCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/style-cross-sells`, method: 'post',
      data: createStyleCrossSellCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiStylecrosssells = <TData = AsyncReturnType<typeof postHomefieldApiStylecrosssells,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateStyleCrossSellCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateStyleCrossSellCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiStylecrosssells<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiStylecrosssellsId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/style-cross-sells/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiStylecrosssellsId = <TData = AsyncReturnType<typeof deleteHomefieldApiStylecrosssellsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiStylecrosssellsId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiStylecrosssellsAccessories = <TData = StyleCrossSellDtoPagedList>(
    params?: GetHomefieldApiStylecrosssellsAccessoriesParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/style-cross-sells/accessories`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiStylecrosssellsAccessoriesQueryKey = (params?: GetHomefieldApiStylecrosssellsAccessoriesParams,) => [`/homefield/api/style-cross-sells/accessories`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiStylecrosssellsAccessories = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiStylecrosssellsAccessories, StyleCrossSellDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiStylecrosssellsAccessoriesParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiStylecrosssellsAccessoriesQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiStylecrosssellsAccessories<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

