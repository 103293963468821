import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InfoField from '@sharedComponents/Display/InfoField';
import Input from '@sharedComponents/Form/Input';

const CustomNameFormInput = React.memo(({
  styleName,
  disabled,
}) => (
  <InfoField
    fieldClass={'mb-0'}
    label={'Custom Name'}
    value={(
      <Field
        name={'customName'}
        placeholder={styleName}
        component={Input}
        type={'text'}
        className={'w-100'}
        disabled={disabled.customName}
      />
    )}
  />
));

CustomNameFormInput.propTypes = {
  styleName: PropTypes.string,
};

export default CustomNameFormInput;
