import * as actionTypes from './types';

const initialState = {
  schedulingQueues: [],
  schedulingQueue: {},
  readyItems: [],
  inProgressItems: [],
  schedulingQueueTooltip: {},
  schedulingQueueInProgressTooltip: [],
};

export default function llScheduling(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SCHEDULING_QUEUES:
      return {
        ...state,
        schedulingQueues: action.data,
      };
    case actionTypes.UPDATE_SCHEDULING_QUEUE:
      return {
        ...state,
        schedulingQueue: action.data,
      };
    case actionTypes.UPDATE_READY_SCHEDULING_QUEUE_ITEMS:
      return {
        ...state,
        readyItems: action.data,
      };
    case actionTypes.UPDATE_IN_PROGRESS_SCHEDULING_QUEUE_ITEMS:
      return {
        ...state,
        inProgressItems: action.data,
      };
    case actionTypes.GET_SCHEDULING_QUEUE_TOOLTIP:
      return {
        ...state,
        schedulingQueueTooltip: {
          ...state.schedulingQueueTooltip,
          [action.id]: action.data,
        },
      };
    case actionTypes.GET_SCHEDULING_QUEUE_IN_PROGRESS_TOOLTIP:
      return {
        ...state,
        schedulingQueueInProgressTooltip: {
          ...state.schedulingQueueInProgressTooltip,
          [action.id]: action.data,
        },
      };
    default:
      return state;
  }
}
