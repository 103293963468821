import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  uploadLogoToAdminLogos,
  requeueLogoForReview,
} from '@APICalls/lockerManager/actions';
import { updateLogo } from '@APICalls/logos/actions';
import { selectionTypeEnum } from '@constants/enums/decorationEnums';
import {
  fetchManagerLogos,
  changeLogoApprovedStatus,
} from '@redux/lockerManager/actions';
import { materialSwal } from '@util/componentHelper';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import LogoBankEditAssignment from './LogoBankEditAssignment';
import LogoDetailsModal from './Logos/DetailedLogo/LogoDetailsModal';
import DetailedUsedLogo from './Logos/DetailedLogo/DetailedUsedLogo';

const defaultMessage = 'Upload & assign new logo';

class LogoBankUsedLogos extends PureComponent {
  state = {
    isExpanded: true,
    loading: false,
    attachedFile: null,
    openEditAssignment: false,
    selectedLogo: null,
    selectedLogoIndex: null,
    logoDetailsModalIsOpen: false,
    logoId: null,
    modalIsOpen: false,
    logo: null,
    message: {
      type: 'default',
      body: defaultMessage,
    },
    logoData: '',
  };

  componentDidMount() {
    this.updateLogos();
  }

  componentDidUpdate(prevProps) {
    const { logos } = this.props;

    if (prevProps.logos !== logos) {
      this.updateLogos();
    }
  }

  updateLogos = () => {
    const {
      logos,
      lockerId,
      openModalWithImage,
      refreshLogos,
    } = this.props;

    if (!logos) {
      this.setState(() => ({ logoData: '' }));

      return;
    }

    const logoData = logos.map((logo) => (
      <DetailedUsedLogo
        key={logo.id}
        replaceLogoModal={this.openModal}
        logo={logo}
        lockerId={lockerId}
        openModalWithImage={openModalWithImage}
        openLogoDetailsModal={this.openLogoDetailsModal}
        toggleApprovedStatus={this.toggleLogoApprovedStatus}
        refreshLogos={refreshLogos}
        requeueForLogoReview={this.requeueForLogoReview}
      />
    ));

    this.setState(() => ({ logoData }));
  };

  closeEditAssignmentModal = () => {
    this.setState(() => ({ openEditAssignment: false }));
  };

  openModal = (logo) => {
    this.setState(() => ({
      logo,
      modalIsOpen: true,
    }));
  };

  closeModal = () => {
    this.setState(() => ({
      modalIsOpen: false,
      logo: null,
    }));
  };

  openLogoDetailsModal = (logo) => {
    const { logos } = this.props;
    this.setState(() => ({
      logoDetailsModalIsOpen: true,
      selectedLogo: logo,
      selectedLogoIndex: logos.findIndex((l) => l.id === logo.id),
    }));
  };

  closeLogoDetailsModal = () => {
    this.setState(() => ({
      logoDetailsModalIsOpen: false,
      selectedLogo: null,
      selectedLogoIndex: null,
    }));
  };

  slideToPrevious = () => {
    const { selectedLogoIndex } = this.state;
    const { logos } = this.props;

    if (selectedLogoIndex > 0) {
      const newIndex = selectedLogoIndex - 1;
      this.setState(() => ({
        selectedLogo: logos[newIndex],
        selectedLogoIndex: newIndex,
      }));
    }
  };

  slideToNext = () => {
    const { selectedLogoIndex } = this.state;
    const { logos } = this.props;

    if (selectedLogoIndex > -1 && selectedLogoIndex < (logos.length - 1)) {
      const newIndex = selectedLogoIndex + 1;
      this.setState(() => ({
        selectedLogo: logos[newIndex],
        selectedLogoIndex: newIndex,
      }));
    }
  };

  hasPrevious = (logo) => {
    const { logos } = this.props;

    const index = logos.findIndex((l) => l.id === logo.id);

    return index > 0;
  };

  hasNext = (logo) => {
    const { logos } = this.props;

    const index = logos.findIndex((l) => l.id === logo.id);

    return index > -1 && index < (logos.length - 1);
  };

  toggleLogoApprovedStatus = async (logoId, isApproved) => {
    const { dispatch } = this.props;
    const res = await updateLogo(logoId, selectionTypeEnum.UserOnly, null, null, isApproved);
    if (res?.success) {
      await dispatch(changeLogoApprovedStatus({
        logoId,
        isApproved,
      }));
    }
  };

  uploadLogo = async () => {
    const {
      dispatch, lockerId,
    } = this.props;
    const { attachedFile } = this.state;

    const res = await uploadLogoToAdminLogos(lockerId, attachedFile);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.clearFileFromState();
      dispatch(fetchManagerLogos(lockerId));
      this.setState(() => ({
        logoId: res.result,
        openEditAssignment: true,
      }));
    }
  };

  changeExpand = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const destroyFiles = () => {
      for (const file of acceptedFiles.concat(rejectedFiles)) {
        window.URL.revokeObjectURL(file.preview);
      }
    };

    const callback = () => {
      destroyFiles();
      this.uploadLogo();
    };

    this.setState(() => ({
      message: {
        body: `File ${acceptedFiles[0].name} has been attached.`,
        type: 'default',
      },
      attachedFile: acceptedFiles,
    }), callback);
  };

  clearFileFromState = () => {
    this.setState(() => ({
      attachedFile: null,
      message: {
        type: 'default',
        body: defaultMessage,
      },
    }));
  };

  requeueForLogoReview = async (logo) => {
    const { refreshLogos } = this.props;
    const res = await requeueLogoForReview(logo.id);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refreshLogos();
    }
  };

  render() {
    const {
      lockerId,
      enableMultipleLogos,
      managerLogos,
    } = this.props;

    const {
      logoId,
      openEditAssignment,
      logoDetailsModalIsOpen,
      selectedLogo,
      logoData,
      loading,
      message,
    } = this.state;

    let logoImage = '';

    if (logoId) {
      const uploadedLogo = managerLogos.find((l) => l.id === logoId);
      if (uploadedLogo) {
        logoImage = uploadedLogo.image;
      }
    }

    const dropzoneContent = loading
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : (
        <label className={`button message-${message.type}`}>
          <Icon materialIcon={'add'} />
          {message.body}
        </label>
      );

    const uploadAndAssignLogo = enableMultipleLogos
      ? (
        <div className='logo-bank__used--logo-container'>
          <div className='logo-bank__used-upload'>
            <DropZoneWrapper
              onDrop={this.onDrop}
              className={'block-drop-zone'}
              multiple={false}
            >
              {dropzoneContent}
            </DropZoneWrapper>
          </div>
        </div>
      )
      : null;

    return (
      <div className='logo-bank__container logo-bank__container-full'>
        <LogoDetailsModal
          isOpen={logoDetailsModalIsOpen}
          closeModal={this.closeLogoDetailsModal}
          logo={selectedLogo}
          hasPrevious={this.hasPrevious}
          hasNext={this.hasNext}
          slideToPrevious={this.slideToPrevious}
          slideToNext={this.slideToNext}
        />
        {
          logoId &&
          <LogoBankEditAssignment
            logoId={logoId}
            modalIsOpen={openEditAssignment}
            closeModal={this.closeEditAssignmentModal}
            logoImage={logoImage}
            lockerId={lockerId}
          />
        }
        <div className='header'>
          <span>
            Logos in use
          </span>
        </div>
        <div className='logo-bank__used--logos'>
          {logoData}
          {uploadAndAssignLogo}
        </div>
      </div>
    );
  }
}

LogoBankUsedLogos.propTypes = {
  logos: PropTypes.array,
  lockerId: PropTypes.number,
  managerLogos: PropTypes.array,
  openModalWithImage: PropTypes.func,
  enableMultipleLogos: PropTypes.bool,
  refreshLogos: PropTypes.func.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  managerLogos: lockerManager.managerLogos,
  enableMultipleLogos: lockerManager.lockerInfo.enableMultipleLogos,
});

export default connect(mapStateToProps)(LogoBankUsedLogos);
