import React, {
  useCallback, useEffect,
} from 'react';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import {
  OverrideOrderItemStatusCommand, ProductionAssemblyItemGroupDto,
} from '@api/fulfillment/models';
import { stopSubmit } from 'redux-form';
import {
  connect, ConnectedProps,
} from 'react-redux';
import { editOrderItemStatusForm } from '@constants/reduxForms';
import EditOrderItemStatusForm from './ReviewItemContentForms/EditOrderItemStatusForm';
import { ProductDetailDto } from '@api/productCatalog/models';
import { toast } from 'react-toastify';
import { usePutHomefieldApiProductionassemblynewOrderitemstatusoverride } from '@api/fulfillment/production-assembly-new';
import { EditOrderItemStatusFormData } from '@models/forms/Production/EditOrderItemStatusFormData';
import { getQueryClientInstance } from '@util/queryClientHelper';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  item: ProductionAssemblyItemGroupDto;
  product: ProductDetailDto;
}

const mapDispatchToProps = {
  stopFormSubmit: stopSubmit,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditOrderItemStatusModal = React.memo<Props>(({
  isOpen,
  closeModal,
  item,
  product,
  stopFormSubmit,
}) => {
  const queryClient = getQueryClientInstance();

  const {
    data: overrideItemStatusResponse,
    mutateAsync: overrideItemRequest,
  } = usePutHomefieldApiProductionassemblynewOrderitemstatusoverride();

  const handleCloseModal = useCallback(async () => {
    closeModal();
    await stopFormSubmit(editOrderItemStatusForm, {});
  }, [
    closeModal,
    stopFormSubmit,
  ]);

  const handleConfirm = useCallback(async (formData: EditOrderItemStatusFormData) => {
    const data = {
      orderItemIds: formData.itemIds,
      orderItemStatus: formData.status,
    } as OverrideOrderItemStatusCommand;

    await overrideItemRequest({ data });
    closeModal();
  }, [
    closeModal,
    overrideItemRequest,
  ]);

  useEffect(() => {
    if (overrideItemStatusResponse?.success) {
      toast.info('Order Status Updated Successully');
      queryClient.refetchQueries({ queryKey: 'POReviewOrderItemGroups' });
    }
  }, [
    overrideItemStatusResponse,
    queryClient,
  ]);

  return (
    <Modal
      title={'Edit Order Item Staus'}
      modalSize={'l'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Confirm'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={editOrderItemStatusForm}
        />
      )}
    >
      <EditOrderItemStatusForm
        item={item}
        product={product}
        onSubmit={handleConfirm}
      />
    </Modal>
  );
});

export default connector(EditOrderItemStatusModal);
