import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import DtgEnvironmentForm from '../DtgEnvironmentForm';
import { dtgEnvironmentForm } from '@constants/reduxForms';

const DtgEnvironmentEditModal = React.memo((props) => {
  const {
    isOpen,
    initialValues,
    closeModal,
    onSubmit,
  } = props;

  return (
    <Modal
      title={'Edit DTG Environment'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Update'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={dtgEnvironmentForm}
        />
      )}
    >
      <DtgEnvironmentForm
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </Modal>
  );
});

DtgEnvironmentEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
};

export default DtgEnvironmentEditModal;
