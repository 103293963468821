export const validateColor = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = ['Code is required.'];
  }

  if (!values.cmykValue) {
    errors.cmykValue = ['CMYK is required.'];
  }

  if (!values.dtgCmykValue) {
    errors.dtgCmykValue = ['DTG CMYK is required.'];
  }

  return errors;
};
