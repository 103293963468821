import React from 'react';
import PropTypes from 'prop-types';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import { formatDollarAmount } from '@util/numberHelpers';
import Link from '@sharedComponents/Navigation/Link';
import InfoField from '@sharedComponents/Display/InfoField';

const FundraisingPayoutsLockers = React.memo(({ lockers }) => {
  if (lockers.lenght <= 0) return null;

  const totalPayoutAmount = lockers.reduce((total, currentLocker) => total + currentLocker.payoutAmount, 0);

  const mappedLockers = lockers.map((locker) => (
    <div
      key={locker.id}
      className='fundraising-payouts__locker-row'
    >
      <div className='fundraising-payouts__locker'>
        <div className='fundraising-payouts__locker--image'>
          <img src={locker.logoImage} />
        </div>
        <div className='fundraising-payouts__locker--text'>
          <div className='fundraising-payouts__locker--text-number'>{locker.id}</div>
          <div className='fundraising-payouts__locker--text-name'>
            <Link
              url={lockerDetailsUrl(locker.id)}
              text={locker.teamName}
            />
          </div>
          <div className='fundraising-payouts__locker--text-org'>{locker.organizationName}</div>
        </div>
      </div>
      <div className='fundraising-payouts__locker-funds'>
        {formatDollarAmount(locker.payoutAmount)}
      </div>
    </div>
  ));

  return (
    <div>
      <div className='fundraising-payouts__lockers'>
        {mappedLockers}
      </div>
      <InfoField
        label={'Total:'}
        value={formatDollarAmount(totalPayoutAmount)}
        fieldClass={'align__right p-20'}
        valueClass={'ml-10'}
      />
    </div>
  );
});

FundraisingPayoutsLockers.propTypes = {
  lockers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FundraisingPayoutsLockers;
