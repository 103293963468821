import React, {
  useState,
  useEffect,
} from 'react';
import {
  useGetHomefieldApiProductionassemblynewOrdersheaderssearch,
  useGetHomefieldApiProductionassemblynewOrderitemssearchbydecoration,
} from '@api/fulfillment/production-assembly-new';
import {
  productionAssemblyHagReviewByOrderUrl,
  productionAssemblyDtgReviewByOrderUrl,
  productionAssemblyEmbReviewByOrderUrl,
  productionAssemblyHagReviewByBarcodeUrl,
  productionAssemblyDtgReviewByBarcodeUrl,
  productionAssemblyEmbReviewByBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import OrderSearchInput from './OrderSearchInput';
import { navigateToPage } from '@util/componentHelper';
import { DecorationMethodEnum } from '@api/productCatalog/models';

interface OwnProps {
  decorationMethod: keyof typeof DecorationMethodEnum;
  reviewByOrderUrl: typeof productionAssemblyHagReviewByOrderUrl
  | typeof productionAssemblyDtgReviewByOrderUrl
  | typeof productionAssemblyEmbReviewByOrderUrl;
  reviewByBarcodeUrl: typeof productionAssemblyHagReviewByBarcodeUrl
  | typeof productionAssemblyDtgReviewByBarcodeUrl
  | typeof productionAssemblyEmbReviewByBarcodeUrl;
}

type Props = OwnProps;

const ProductionAssemblySearch = React.memo<Props>(({
  reviewByOrderUrl,
  reviewByBarcodeUrl,
  decorationMethod,
}) => {
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>('');
  const [
    lookupOrderNumber,
    setLookupOrderNumber,
  ] = useState<string>('');

  const {
    data: order,
    refetch: fetchOrder,
  } = useGetHomefieldApiProductionassemblynewOrdersheaderssearch(
    { searchQuery: lookupOrderNumber },
    {
      request: { showErrorModal: true },
    }
  );

  const {
    data: orderItemGroups,
    refetch: fetchOrderItemGroups,
  } = useGetHomefieldApiProductionassemblynewOrderitemssearchbydecoration(
    {
      searchQuery: lookupOrderNumber,
      decorationMethod,
    },
    {
      request: { showErrorModal: true },
    }
  );

  useEffect(() => {
    if (order && orderItemGroups) {
      const barcodes = orderItemGroups.map((i) => Object.values(i.orderItemIdsWithBarcode!)).flat();

      if (barcodes.includes(searchInput)) {
        navigateToPage(reviewByBarcodeUrl(order.orderNumber!, searchInput));
      } else {
        navigateToPage(reviewByOrderUrl(order.orderNumber!));
      }
    }
  }, [
    reviewByBarcodeUrl,
    reviewByOrderUrl,
    order,
    searchInput,
    orderItemGroups,
  ]);

  useEffect(() => {
    if (!lookupOrderNumber) return;

    fetchOrder();
    fetchOrderItemGroups();
  }, [
    lookupOrderNumber,
    fetchOrder,
    fetchOrderItemGroups,
  ]);

  return (
    <OrderSearchInput
      searchInput={searchInput}
      updateSearchInput={setSearchInput}
      updateLookupOrderNumber={setLookupOrderNumber}
    />
  );
});

export default ProductionAssemblySearch;
