import React, { useCallback } from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Personalization from '../OrderManagementDetails/Personalization';
import ItemEditOnMouseOver from '@sharedComponents/ItemEditOnMouseOver';
import { PersonalizationItemModel } from '@api/fulfillment/models';

interface Color {
  id: number;
  code: string;
  dtgCode: Nullable<string>;
  cmykValue: string;
  dtgCmykValue: string;
  hexValue: Nullable<string>;
  threadValue: Nullable<string>;
  brightness: string;
}

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  personalizations: Array<PersonalizationItemModel>;
  colorsDictionary: Record<number, Color>;
  personalizationOnClick: (personalization: PersonalizationItemModel) => void;
}

type Props = OwnProps;

const OrderManagementChoosePersonalizationToRemoveModal = React.memo<Props>(({
  isOpen,
  closeModal,
  personalizations,
  colorsDictionary,
  personalizationOnClick,
}) => {
  const renderPersonalization = useCallback((personalization: PersonalizationItemModel, index: number) => (
    <div
      key={index}
      className='ml-10 mb-10'
    >
      <ItemEditOnMouseOver>
        <Personalization
          personalization={personalization}
          colorsDictionary={colorsDictionary}
          personalizationOnClick={personalizationOnClick}
        />
      </ItemEditOnMouseOver>
    </div>
  ), [
    colorsDictionary,
    personalizationOnClick,
  ]);

  return (
    <Modal
      title={'Choose Personalization to Remove'}
      isOpen={isOpen}
      modalHeight={'l'}
      modalWidth={'s'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          displayConfirmBtn={false}
        />
      )}
    >
      <div className='flex__wrap order__choose-personalization'>
        {
          personalizations?.map(renderPersonalization)
        }
      </div>
    </Modal>
  );
});

export default OrderManagementChoosePersonalizationToRemoveModal;
