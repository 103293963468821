import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Expander from '@sharedComponents/Buttons/Expander';

class ScheduleOrdersModalOrderGroup extends PureComponent {
  state = {
    isExpanded: true,
  };

  toggleExpanded = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  render() {
    const { isExpanded } = this.state;

    const {
      orderNumber,
      orderItems,
    } = this.props;

    const expanderText = `${orderItems.length} ${orderItems.length === 1 ? 'item' : 'items'}`;

    return (
      <div className='vendor-orders__schedule-modal--order'>
        <div className='mb-10 align__items justify__space-between'>
          <div><b>O{orderNumber}</b></div>
          <Expander
            isExpanded={isExpanded}
            toggle={this.toggleExpanded}
            collapseText={expanderText}
            expandText={expanderText}
            classes={'expandable-group__expander'}
          />
        </div>
        {
          isExpanded && orderItems.map((item) => (
            <div
              className='ml-10 mb-5'
              key={item.sku}
            >
              <span className='mr-10'>&bull;</span>{item.sku} - {item.styleName}
            </div>
          ))
        }
      </div>
    );
  }
}
ScheduleOrdersModalOrderGroup.propTypes = {
  orderNumber: PropTypes.number.isRequired,
  orderItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ScheduleOrdersModalOrderGroup;
