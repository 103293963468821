import React, {
  useMemo,
  useEffect,
} from 'react';
import {
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { VoucherOrderDetailDto } from '@api/financialServices/models';
import { editVoucherOrderForm } from '@constants/reduxForms';
import { maxCustomSenderNameLength } from '@constants/values';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import VoucherOrderSettings from './VoucherFormsContent/VoucherOrderSettings';
import NotificationSettings from './VoucherFormsContent/NotificationSettings';
import { VoucherNotificationSettingsFormData } from '@models/forms/Vouchers/VoucherNotificationSettingsFormData';

export interface VoucherOrderFormData extends VoucherNotificationSettingsFormData {
  voucherOrderId: number;
  startDate: string;
  endDate: Nullable<string>;
  lockerManagerId: number;
  discountPercent: number;
}

const schema = yup.object({
  voucherOrderId: yup.number().required('Voucher order ID is required'),
  startDate: yup.string().required('Valid From is required'),
  endDate: yup.string().nullable(),
  lockerManagerId: yup.number().required('Admin is required'),
  notificationSettings: yup.object().shape({
    sendDate: yup.string().required('Send date is required'),
    linkDestination: yup.string().required('Link destination is required'),
    senderName: yup.string().required('Sender name is required'),
    customSenderName: yup.string()
      .max(maxCustomSenderNameLength, `Custom sender name exceeds the character limit of ${maxCustomSenderNameLength}`)
      .nullable(),
  })
    .required(),
  discountPercent: yup.number()
    .min(0, 'Enter a discount between 0 and 100%')
    .max(100, 'Enter a discount between 0 and 100%'),
});

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  onSubmit: (voucherOrder: VoucherOrderFormData) => Promise<void>;
}

type Props = OwnProps;

const VoucherOrderForm = React.memo<Props>(({
  voucherOrder,
  onSubmit,
}) => {
  const formMethods: UseFormReturn<VoucherOrderFormData> = useForm<VoucherOrderFormData>({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => (
      {
        voucherOrderId: voucherOrder.id!,
        startDate: voucherOrder.startDate!,
        endDate: voucherOrder.endDate ?? null,
        lockerManagerId: voucherOrder.lockerManagerId!,
        notificationSettings: {
          sendDate: voucherOrder.notificationSettings?.sendDate,
          linkDestination: voucherOrder.notificationSettings?.linkDestination,
          senderName: voucherOrder.notificationSettings?.senderName,
          customSenderName: voucherOrder.notificationSettings?.customSenderName,
        },
        discountPercent: voucherOrder.discountPercent ? voucherOrder.discountPercent * 100.0 : 0,
      }
    ), [voucherOrder]),
  });

  // Set the initial values for the edit form
  useEffect(() => {
    formMethods.reset({
      voucherOrderId: voucherOrder.id!,
      startDate: voucherOrder.startDate!,
      endDate: voucherOrder.endDate ?? null,
      lockerManagerId: voucherOrder.lockerManagerId!,
      notificationSettings: {
        sendDate: voucherOrder.notificationSettings?.sendDate,
        linkDestination: voucherOrder.notificationSettings?.linkDestination,
        senderName: voucherOrder.notificationSettings?.senderName,
        customSenderName: voucherOrder.notificationSettings?.customSenderName,
      },
      discountPercent: voucherOrder.discountPercent ? voucherOrder.discountPercent * 100.0 : 0,
    });
  }, [
    voucherOrder,
    formMethods,
  ]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = formMethods;

  const senderName = watch('notificationSettings.senderName');
  const discountPercent = watch('discountPercent');

  const notificationSettingsFormInitialValues: VoucherNotificationSettingsFormData = {
    notificationSettings: {
      sendDate: voucherOrder?.notificationSettings?.sendDate ?? null,
      linkDestination: voucherOrder?.notificationSettings?.linkDestination ?? null,
      senderName: voucherOrder?.notificationSettings?.senderName ?? null,
      customSenderName: voucherOrder?.notificationSettings?.customSenderName ?? null,
    },
  };

  return (
    <form
      id={editVoucherOrderForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='voucherOrders__edit-form w-100 p-10'>
        <Grid
          container={true}
          xs={12}
          className={'pb-10'}
          spacing={1}
        >
          <Grid
            container={true}
            item={true}
            xs={6}
          >
            <Grid
              container={true}
              item={true}
              xs={12}
              className={'voucherOrders__edit-form__section'}
            >
              <VoucherOrderSettings
                voucherOrder={voucherOrder}
                register={register}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
            <Grid
              container={true}
              item={true}
              xs={12}
              className={'voucherOrders__edit-form__section'}
            >
              <NotificationSettings
                initialValues={notificationSettingsFormInitialValues}
                register={register}
                setValue={setValue}
                errors={errors}
                senderName={senderName}
                organizationId={voucherOrder?.organizationId}
              />
            </Grid>
          </Grid>
          {
            /*
             *TODO: uncomment when the backend side is implemented
             *
             *<Grid
             *container={true}
             *item={true}
             *xs={12}
             *md={6}
             *className={'voucherOrders__edit-form__section'}
             *>
             *<Grid
             *  container={true}
             *  item={true}
             *  xs={6}
             *  md={12}
             *>
             *  <DiscountSettings
             *    voucherOrder={voucherOrder}
             *    register={register}
             *    errors={errors}
             *    discountPercent={discountPercent}
             *  />
             *</Grid>
             *</Grid>
             */
          }
        </Grid>
      </div>
    </form>
  );
});

export default VoucherOrderForm;
