import React, { useCallback } from 'react';
import { RosterTeamBaseViewModel } from '@api/squadlockerServices/models';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';

interface OwnProps {
  collectionId: number;
  rosterTeamIds: number[];
  rosters: RosterTeamBaseViewModel[];
  change: (name: string, value: any) => void;
  disabled?: boolean;
}

type Props = OwnProps;

const AssociatedRosterFormDropdown = React.memo<Props>(({
  collectionId,
  rosterTeamIds,
  rosters,
  change,
  disabled,
}) => {
  const rostersList = rosters.map((roster) => ({
    ...roster,
    disabled: roster.disabled || roster.hasActiveVouchers
      // Disable a roster if it belongs to another collection
      || !!roster.collection
        && (!collectionId || (!!collectionId && roster.collection.id !== collectionId)),
  }));

  const onChangeRosters = useCallback((rosterIds: number[]) => {
    change('rosterTeamIds', rosterIds);
  }, []);

  return (
    <div>
      <label className='redux-form__label'>
        Associated Roster
      </label>
      <MultiSelectDropdown<RosterTeamBaseViewModel, 'id'>
        objects={rostersList}
        selectedObjects={rosterTeamIds}
        itemText={'rosters'}
        textKey={'teamName'}
        valueKey={'id'}
        updateCallback={onChangeRosters}
        classNames={'margin-left'}
        disabledItemTooltipText={'Roster is disabled, has an active voucher or belongs to another collection'}
        disabled={disabled}
      />
    </div>
  );
});

export default AssociatedRosterFormDropdown;
