import React from 'react';
import PropTypes from 'prop-types';
import { categoryForm } from '@constants/reduxForms';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import CategoryForm from '../CategoryForm';

const CategoryEditModal = (props) => {
  const {
    isOpen,
    onSubmit,
    initialValues,
    closeModal,
  } = props;

  return (
    <Modal
      title={'Edit Category'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Edit'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={categoryForm}
        />
      )}
    >
      <CategoryForm
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </Modal>
  );
};

CategoryEditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default CategoryEditModal;
