import React from 'react';
import { VoucherCollectionDetailDto } from '@api/financialServices/models';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import { groupBy } from '@util/arrayHelper';
import Link from '@sharedComponents/Navigation/Link';
import ExpandableGroup from '@components/shared/Display/Containers/Group/ExpandableGroup';
import CollectionsTabExpandableGroupItem from './CollectionsTabExpandableGroupItem';

interface OwnProps {
  voucherOrderCollections: VoucherCollectionDetailDto[];
  collapseAll: boolean;
}

type Props = OwnProps;

const CollectionsTab = React.memo<Props>(({
  voucherOrderCollections,
  collapseAll,
}) => {
  const renderCollectionsByLocker = () => {
    const collectionsByLocker = groupBy(voucherOrderCollections, 'lockerId');
    const sortedLockerIds = Object.keys(collectionsByLocker).sort();

    const itemsGroups = [];
    for (const lockerId of sortedLockerIds) {
      const collectionsGroup = collectionsByLocker[lockerId];

      itemsGroups.push(
        <ExpandableGroup<VoucherCollectionDetailDto>
          key={lockerId}
          title={(
            <Link
              url={lockerDetailsUrl(lockerId)}
              text={(
                <div>L{lockerId} - {collectionsGroup[0].lockerName}</div>
              )}
            />
          )}
          items={collectionsGroup}
          expandableGroupItemComponent={CollectionsTabExpandableGroupItem}
          classes={'voucherOrders__details__list__expandable-group'}
          headerClasses={'voucherOrders__details__list__expandable-group__header--collections-tab-header'}
          headerTitleClasses={'voucherOrders__details__list__expandable-group__header--title'}
          allExpanded={!collapseAll}
        />
      );
    }

    return itemsGroups;
  };

  return (
    <div className='voucherOrders__details__list__items'>
      {renderCollectionsByLocker()}
    </div>
  );
});

export default CollectionsTab;
