import React, { useCallback } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { stopSubmit } from 'redux-form';
import { RosterTeamMemberViewModel } from '@api/squadlockerServices/models';
import { teamMemberForm } from '@constants/reduxForms';
import { RosterMemberFormData } from '@models/forms/RosterManager/RosterMemberFormData';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import TeamMemberForm from '../TeamMemberForm';

interface OwnProps {
  isOpen: boolean;
  onSubmit: (rosterMemberForm: RosterMemberFormData) => void;
  closeModal: () => void;
  initialValues: Nullable<Partial<RosterTeamMemberViewModel>>;
}

const mapDispatchToProps = {
  stopFormSubmit: stopSubmit,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const TeamMemberAddModal = React.memo<Props>(({
  isOpen,
  onSubmit,
  closeModal,
  stopFormSubmit,
  initialValues,
}) => {
  const handleCloseModal = useCallback(() => {
    closeModal();
    stopFormSubmit(teamMemberForm, {});
  }, [
    closeModal,
    stopFormSubmit,
  ]);

  if (!isOpen || !initialValues) return null;

  return (
    <Modal
      title={'Add Team Member'}
      modalHeight={'xl'}
      modalWidth={'xl'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Save'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={teamMemberForm}
        />
      )}
    >
      <TeamMemberForm
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </Modal>
  );
});

export default connector(TeamMemberAddModal);
