import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Expander from '@sharedComponents/Buttons/Expander';
import ArchivedLogo from './Logos/SimpleLogo/ArchivedLogo';
import Pagination from '@components/shared/Pagination';

const PAGE_SIZE = 25;

class LogoBankArchive extends PureComponent {
  state = {
    isExpanded: true,
    pageNumber: 1,
    logoData: '',
  };

  componentDidMount() {
    this.updateLogos();
  }

  componentDidUpdate(prevProps) {
    const { logos } = this.props;

    if (prevProps.logos !== logos && logos) {
      this.updateLogos();
    }
  }

  changeExpand = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }), this.updateLogos);
  };

  handleChangePage = (pn) => {
    this.setState({ pageNumber: pn }, this.updateLogos);
  };

  updateLogos = () => {
    const {
      pageNumber, isExpanded,
    } = this.state;

    const {
      logos,
      lockerId,
    } = this.props;

    if (!isExpanded) {
      this.setState({ logoData: '' });

      return;
    }

    if (!logos) return;

    const start = (pageNumber - 1) * PAGE_SIZE;
    const end = pageNumber * PAGE_SIZE;

    logos.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    });

    const logoData = logos.slice(start, end).map((logo, key) => (
      <ArchivedLogo
        key={key}
        logo={logo}
        lockerId={lockerId}
      />
    ));

    this.setState({ logoData });
  };

  render() {
    const {
      isExpanded, pageNumber, logoData,
    } = this.state;
    const { logos } = this.props;

    return (
      <div className='logo-bank__container logo-bank__container-half'>
        <div className='header'>
          <span>
            Archived logos
          </span>

          <Expander
            isExpanded={isExpanded}
            toggle={this.changeExpand}
          />
        </div>
        <div className='logo-bank__archive--logos'>
          {logoData}
        </div>
        {!!logoData &&
          <div className='pagination'>
            <Pagination
              totalPages={Math.ceil(logos.length / PAGE_SIZE)}
              pageNumber={pageNumber}
              selectPage={this.handleChangePage}
              range={5}
            />
          </div>}
      </div>
    );
  }
}

LogoBankArchive.propTypes = {
  logos: PropTypes.array.isRequired,
  lockerId: PropTypes.number.isRequired,
};

export default LogoBankArchive;
