import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '@sharedComponents/Form/Input';
import { decorationLocationForm } from '@constants/reduxForms';

const DecorationLocationForm = (props) => {
  const { onSubmit } = props;

  return (
    <form
      className='redux-form'
      onSubmit={onSubmit}
      id={decorationLocationForm}
    >
      <div>
        <div className='redux-form__section pb-5'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-xl'>
              <label className='redux-form__label'>
                Code
                <span className='required'>*</span>
              </label>
              <Field
                name={'code'}
                placeholder={'Enter Code'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-xl'>
              <label className='redux-form__label'>
                Label
                <span className='required'>*</span>
              </label>
              <Field
                name={'label'}
                placeholder={'Enter label'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

DecorationLocationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default DecorationLocationForm;
