import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { skuSetupStatusEnum } from '@constants/enums/productCatalogEnums';
import { styleUrl } from '@constants/clientUrls/clientUrls';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

class StyleFinalizeActions extends PureComponent {
  state = {
    confirmationModalIsOpen: false,
  };

  openConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: true }));
  };

  closeConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: false }));
  };

  handleModalConfirm = () => {
    const {
      style,
      changeStyleStatus,
    } = this.props;

    changeStyleStatus(style.id, skuSetupStatusEnum.Completed);
    this.closeConfirmationModal();
  };

  render() {
    const {
      style,
      openEditStyleModal,
    } = this.props;

    const { confirmationModalIsOpen } = this.state;

    return (
      <>
        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.handleModalConfirm}
          closeModal={this.closeConfirmationModal}
          title={'Mark Style Completed'}
          confirmationBody={`Are you sure you want to mark style ${style.code} - ${style.name} as completed?`}
        />
        <div className='sku-setup__table-header'>
          <div className='flex'>
            <div
              onClick={openEditStyleModal.bind(null, style)}
              className='sku-setup__edit-action'
            >
              <div className='sku-setup__link mr-10'>
                <Icon materialIcon={'edit'} />
                <span>Edit</span>
              </div>
            </div>

            <a
              className='sku-setup__link'
              href={styleUrl(style.id)}
              target='_blank'
              rel='noreferrer'
            >
              <Icon materialIcon={'launch'} />
              <span>Details</span>
            </a>

            {
              style.status === skuSetupStatusEnum.QualityCheck
                ? (
                  <Button
                    type={'primary'}
                    text={'Finalize'}
                    onClick={this.openConfirmationModal}
                    size={'s'}
                    classes={'ml-10'}
                  />
                )
                : <div className='sku-setup__status'>{style.status}</div>
            }
          </div>
        </div>
      </>
    );
  }
}

StyleFinalizeActions.propTypes = {
  style: PropTypes.object.isRequired,
  openEditStyleModal: PropTypes.func.isRequired,
  changeStyleStatus: PropTypes.func.isRequired,
};

export default StyleFinalizeActions;
