import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import { parseDateNumeric } from '@util/dateHandler';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import InfoField from '@sharedComponents/Display/InfoField';
import Tabs from '@sharedComponents/Tabs/Tabs';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import BackorderManagementOrderItems from './BackorderManagementOrderItems/BackorderManagementOrderItems';
import BackorderManagementNotificationHistory from './BackorderManagementNotificationHistory/BackorderManagementNotificationHistory';

const backorderManagementQuickViewTabEnum = {
  BackorderedItems: 'BackorderedItems',
  NotificationHistory: 'NotificationHistory',
};

class BackorderManagementQuickView extends PureComponent {
  state = {
    selectedTab: backorderManagementQuickViewTabEnum.BackorderedItems,
  };

  getTabs = (
    order,
    automatedNotificationsEnabled,
    manualNotificationsEnabled,
    previouslyNotified,
    discontinued
  ) => {
    const tabs = [
      {
        title: 'Backordered Items',
        name: backorderManagementQuickViewTabEnum.BackorderedItems,
        content: (
          <div className='backorder-management__sidebar__tab-content'>
            <BackorderManagementOrderItems
              order={order}
              previouslyNotified={previouslyNotified}
              discontinued={discontinued}
              automatedNotificationsEnabled={automatedNotificationsEnabled}
              manualNotificationsEnabled={manualNotificationsEnabled}
            />
          </div>
        ),
      },
      {
        title: 'Notification History',
        name: backorderManagementQuickViewTabEnum.NotificationHistory,
        content: (
          <div className='backorder-management__sidebar__tab-content'>
            <BackorderManagementNotificationHistory
              order={order}
            />
          </div>
        ),
      },
    ];

    return tabs;
  };

  selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  render() {
    const { selectedTab } = this.state;

    const {
      order,
      closeDetails,
      previouslyNotified,
      discontinued,
      automatedNotificationsEnabled = true,
      manualNotificationsEnabled = false,
    } = this.props;

    return (
      <TableQuickViewContainer
        noPadding={true}
        size={TableQuickViewSizeEnum.FitContent}
      >
        <TableQuickViewHeader
          title={<span>O{order.orderNumber}</span>}
          onClose={closeDetails}
        />
        <div className='tableView__details--content'>
          <div className='tableView__details--content-container no-border pt-0'>
            <div className='flex'>
              <InfoField
                label={'Order Status'}
                value={order.status && order.status.label}
                fieldClass={'order__shipping__column--long'}
              />
              <InfoField
                label={'Ship Date'}
                value={parseDateNumeric(order.shipDate)}
                fieldClass={'order__shipping__column--short'}
              />
            </div>
            <div className='flex'>
              <InfoField
                label={'Ship To'}
                value={order.shippingInfo && order.shippingInfo.name}
                fieldClass={'order__shipping__column--short'}
              />
              <InfoField
                label={'E-mail'}
                value={order.shippingInfo && order.shippingInfo.email}
                fieldClass={'order__shipping__column--long'}
              />
            </div>
          </div>
          <Tabs
            tabs={this.getTabs(
              order,
              automatedNotificationsEnabled,
              manualNotificationsEnabled,
              previouslyNotified,
              discontinued
            )}
            selectedTab={selectedTab}
            selectTab={this.selectTab}
          />
        </div>
      </TableQuickViewContainer>
    );
  }
}

BackorderManagementQuickView.propTypes = {
  order: PropTypes.shape({
    orderNumber: PropTypes.number.isRequired,
    backorderedItemIds: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    shipDate: PropTypes.string,
    pricePaid: PropTypes.number,
    refundAmount: PropTypes.number,
    chargeAmount: PropTypes.number,
    status: PropTypes.shape({
      label: PropTypes.string,
    }),
    shippingInfo: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  closeDetails: PropTypes.func.isRequired,
  previouslyNotified: PropTypes.bool,
  discontinued: PropTypes.bool,
  automatedNotificationsEnabled: PropTypes.bool,
  manualNotificationsEnabled: PropTypes.bool,
};

export default BackorderManagementQuickView;
