import React from 'react';
import PropTypes from 'prop-types';
import iconEye from '@assets/icon_hidden.svg';
import iconGray from '@assets/icon_eye_gray.svg';
import { skuSetupStatusEnum } from '@constants/enums/productCatalogEnums';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';

const ProductCatalogQuickViewHeader = React.memo(({
  closeDetails,
  style,
  coloredStyles,
}) => {
  const disabledColoredStyles = coloredStyles.filter((x) => x.disabled);
  const discontinuedColoredStyles = coloredStyles.filter((x) => x.discontinued);

  const anyColoredStyleDisabled = disabledColoredStyles.length > 0 ? true : false;
  const anyColoredStyleDiscontinued = discontinuedColoredStyles.length > 0 ? true : false;
  const allColoredStylesDisabled = disabledColoredStyles.length === coloredStyles.length ? true : false;
  const allColoredStylesDiscontinued = discontinuedColoredStyles.length === coloredStyles.length ? true : false;

  const csDisabledText = `${!allColoredStylesDisabled ? 'Some' : ''} Colored styles are disabled`;
  const csDiscontinuedText = `${!allColoredStylesDiscontinued ? 'Some' : ''} Colored styles are discontinued`;

  const showDisabledIcon = style.disabled || anyColoredStyleDisabled || allColoredStylesDisabled;
  const showDiscontinuedIcon = style.discontinued || allColoredStylesDiscontinued || anyColoredStyleDiscontinued;

  return (
    <div className='tableView__details--header'>
      <div className='tableView__details--header--title'>
        <span>Quick Style Preview</span>
        {
          coloredStyles.some((coloredStyle) => coloredStyle.status !== skuSetupStatusEnum.Completed) &&
          <div className='product-catalog__status-label'>
            <Icon materialIcon={'build'} />
            <span>In SKU Setup</span>
          </div>
        }
        {
          coloredStyles.length > 0 &&
          <div className='product-catalog__style-icons'>
            {
              showDisabledIcon &&
              <MaterialTooltip
                tooltipText={(
                  <>
                    {
                      style.disabled &&
                      <div className='pre-line'>Style is disabled</div>
                    }
                    {
                      (allColoredStylesDisabled || anyColoredStyleDisabled) &&
                      <div className='pre-line'>{csDisabledText}</div>
                    }
                  </>
                )}
                placement={'top'}
                contentClasses={'align__center'}
              >
                <i
                  className='product-catalog__hidden'
                  style={{ backgroundImage: `url("${(style.disabled || allColoredStylesDisabled) ? iconEye : iconGray}")` }}
                />
              </MaterialTooltip>
            }
            {
              showDiscontinuedIcon &&
              <MaterialTooltip
                tooltipText={(
                  <>
                    {
                      style.discontinued &&
                      <div className='pre-line'>Style is discontinued</div>
                    }
                    {
                      (allColoredStylesDiscontinued || anyColoredStyleDiscontinued) &&
                      <div className='pre-line'>{csDiscontinuedText}</div>
                    }
                  </>
                )}
                placement={'top'}
                contentClasses={'align__center'}
              >
                <Icon
                  materialIcon={'block'}
                  classes={(allColoredStylesDiscontinued || style.discontinued) ? 'active' : 'disabled'}
                />
              </MaterialTooltip>
            }
          </div>
        }
      </div>
      <div
        className='tableView__details--header--close'
        onClick={closeDetails}
      >
        <Icon materialIcon={'close'} />
        <span className='uppercase'>Close</span>
      </div>
    </div>
  );
});

ProductCatalogQuickViewHeader.propTypes = {
  closeDetails: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object),
};

export default ProductCatalogQuickViewHeader;
