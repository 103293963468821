import * as actionTypes from './types';

const initialState = {
  currentQueue: {
    queueType: 'PrintReady',
    totalPages: 1,
    totalCount: 0,
    sortBy: '',
    sortDirection: '',
    logoCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    pageNumber: 1,
    claimedBy: null,
    queue: [],
    pageSize: 10,
  },
  printSheetUsers: [],
  // Currently affects only print sheets
  printSheetArtworkTask: [],
  logoCounts: {
    printReady: 0,
    printing: 0,
    done: 0,
    priority: 0,
  },
};

export default function printFiles(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_QUEUE:
      return {
        ...state,
        currentQueue: action.payload,
      };
    case actionTypes.CLEAR_PRINT_FILES:
      return {
        ...state,
        currentQueue: initialState.currentQueue,
      };
    case actionTypes.UPDATE_LOGO_COUNTS:
      return {
        ...state,
        logoCounts: action.payload,
      };
    case actionTypes.CLEAR_NONESSENTIAL_QUERY_PARAMS:
      return {
        ...state,
        currentQueue: {
          ...initialState.currentQueue,
          queueType: state.currentQueue.queueType,
          claimedBy: state.currentQueue.claimedBy,
        },
      };
    case actionTypes.GET_PRINT_SHEET_USERS:
      return {
        ...state,
        printSheetUsers: action.payload,
      };

    // Currently affects only print sheets
    case actionTypes.UPDATE_PRINT_SHEET_ARTWORKS:
      return {
        ...state,
        printSheetArtworkTask: action.payload,
      };
    case actionTypes.CLEAR_PRINT_SHEET_ARTWORKS:
      return {
        ...state,
        printSheetArtworkTask: initialState.printSheetArtworkTask,
      };
    default:
      return state;
  }
}
