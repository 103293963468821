import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  addLogoToLockerLogos,
  archiveLogo,
} from '@APICalls/lockerManager/actions';
import {
  beginBlockingRequest,
  endBlockingRequest,
} from '@redux/api/actions';
import {
  archiveTasks,
  fetchArtworkFileTasks,
} from '@redux/artworkFileTasks/actions';
import {
  materialSwal,
  downloadFile,
  navigateToPage,
} from '@util/componentHelper';
import { artworkTaskStatusEnum } from '@constants/enums/artworkEnums';
import { lockerArtworkTasksUrl } from '@constants/clientUrls/clientUrls';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';
import AdminLogoArchiveModal from './AdminLogoArchiveModal';

class AdminLogoActions extends PureComponent {
  state = {
    archiveLogoModalIsOpen: false,
  };

  moveToLockerLogos = async (e) => {
    const {
      logoId,
      lockerId,
      refreshLogos,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    const res = await addLogoToLockerLogos(lockerId, logoId);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refreshLogos();
    }
  };

  archiveArtworkTasks = async (tasks) => {
    const {
      dispatch,
      refreshLogos,
    } = this.props;
    const tasksToArchive = [];

    tasks.items.forEach((task) => {
      if (task.status.label === artworkTaskStatusEnum.Completed
        && !task.artworkTaskArchivation) {
        tasksToArchive.push(task);
      }
    });

    let isArchivingSuccessful = true;
    if (tasksToArchive.length > 0) {
      const res = await dispatch(archiveTasks(tasksToArchive));
      isArchivingSuccessful = res?.success;
    }

    if (isArchivingSuccessful) {
      materialSwal('Success', 'Logo and artwork tasks successfully archived!', 'success');
    }
    this.closeArchiveLogoModal();
    refreshLogos();
    dispatch(endBlockingRequest());
  };

  archiveLogo = async (e) => {
    const {
      logoId,
      dispatch,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    dispatch(beginBlockingRequest());

    const res = await archiveLogo(logoId);

    if (res?.success) {
      dispatch(fetchArtworkFileTasks(null, logoId, this.archiveArtworkTasks));
    } else {
      dispatch(endBlockingRequest());
    }

    this.closeArchiveLogoModal();
  };

  openArchiveLogoModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ archiveLogoModalIsOpen: true });
  };

  closeArchiveLogoModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ archiveLogoModalIsOpen: false });
  };

  goToProductionArtworkTasks = () => {
    const { lockerId } = this.props;

    navigateToPage({
      pathname: lockerArtworkTasksUrl(lockerId),
      state: { prevPath: location.pathname },
    });
  };

  render() {
    const {
      logoImage,
      enableMultipleLogos,
      image,
      scrollTop,
      openEditLogoAssignmentModal,
      openAddLogoToBlanksModal,
    } = this.props;

    const { archiveLogoModalIsOpen } = this.state;

    return (
      <div className='logo-bank__manager--logo-actions'>
        <ShowMoreActionsButton
          scrollTop={scrollTop}
          closeOnScroll={true}
          actions={[
            {
              text: 'Edit Logo Assignment',
              action: openEditLogoAssignmentModal,
              isVisible: enableMultipleLogos,
            },
            {
              text: 'Assign Logo to Items',
              action: openAddLogoToBlanksModal,
              isVisible: enableMultipleLogos,
            },
            {
              text: 'Move to Locker Logos',
              action: this.moveToLockerLogos,
              isVisible: true,
            },
            {
              text: 'Archive',
              action: this.openArchiveLogoModal,
              isVisible: true,
            },
            {
              text: 'Open Production Artwork Tasks',
              action: this.goToProductionArtworkTasks,
              isVisible: true,
            },
            {
              text: 'Download Logo',
              action: downloadFile(image, logoImage),
              isVisible: true,
            },
          ]}
        />
        <AdminLogoArchiveModal
          isOpen={archiveLogoModalIsOpen}
          closeModal={this.closeArchiveLogoModal}
          archiveLogo={this.archiveLogo}
          logoImage={image}
        />
      </div>
    );
  }
}

AdminLogoActions.propTypes = {
  logoId: PropTypes.number.isRequired,
  logoImage: PropTypes.string.isRequired,
  lockerId: PropTypes.number.isRequired,
  enableMultipleLogos: PropTypes.bool,
  image: PropTypes.string.isRequired,
  refreshLogos: PropTypes.func.isRequired,
  scrollTop: PropTypes.number,
  openEditLogoAssignmentModal: PropTypes.func.isRequired,
  openAddLogoToBlanksModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  enableMultipleLogos: lockerManager.lockerInfo.enableMultipleLogos,
});

export default connect(mapStateToProps)(AdminLogoActions);
