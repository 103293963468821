/* eslint react/no-multi-comp: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import { parseDateTimeNumeric } from '@util/dateHandler';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';

const PrintSheetHeaderSteps = React.memo(({ steps }) => {
  const completeIcon = <Icon materialIcon={'check'} />;
  const activeIcon = <Icon materialIcon={'fiber_manual_record'} />;

  let readyToPrintStat = {
    icon: activeIcon,
    status: 'is-active',
  };
  let inPrintingStat = {
    icon: '',
    status: '',
  };
  let printedStat = {
    icon: '',
    status: '',
  };
  let inWeedingAndMaskingStat = {
    icon: '',
    status: '',
  };
  let doneStat = {
    icon: '',
    status: '',
  };

  for (let i = steps.statusHistory.length - 1; i >= 0; i--) {
    const status = steps.statusHistory[i];

    switch (status.status) {
      case printStatusEnum.PrintReady:
        readyToPrintStat = {
          ...readyToPrintStat,
          icon: activeIcon,
          status: 'is-active',
          created: status.created,
          user: status.user,
        };
        break;
      case printStatusEnum.Printing:
        readyToPrintStat = {
          ...readyToPrintStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        inPrintingStat = {
          ...inPrintingStat,
          icon: activeIcon,
          status: 'is-active',
          created: status.created,
          user: status.user,
        };
        break;
      case 'Printed':
        readyToPrintStat = {
          ...readyToPrintStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        inPrintingStat = {
          ...inPrintingStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        printedStat = {
          ...printedStat,
          icon: activeIcon,
          status: 'is-active',
          created: status.created,
          user: status.user,
        };
        break;
      case 'PrintAssembly':
        readyToPrintStat = {
          ...readyToPrintStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        inPrintingStat = {
          ...inPrintingStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        printedStat = {
          ...printedStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        inWeedingAndMaskingStat = {
          ...inWeedingAndMaskingStat,
          icon: activeIcon,
          status: 'is-active',
          created: status.created,
          user: status.user,
        };
        break;
      case printStatusEnum.Done:
        readyToPrintStat = {
          ...readyToPrintStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        inPrintingStat = {
          ...inPrintingStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        printedStat = {
          ...printedStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        inWeedingAndMaskingStat = {
          ...inWeedingAndMaskingStat,
          icon: completeIcon,
          status: 'is-complete',
        };
        doneStat = {
          ...doneStat,
          icon: activeIcon,
          status: 'is-active',
          created: status.created,
          user: status.user,
        };
        break;
    }
  }

  const tooltipText = (created, user) => (
    (created || user)
      ? (
        <div>
          {created ? <div>{`${parseDateTimeNumeric(created)}`}</div> : '' }
          {user ? <div>{user}</div> : '' }
        </div>
      )
      : ''
  );

  return (
    <ul className='header__bar-steps'>
      <li>
        <MaterialTooltip
          tooltipText={tooltipText(readyToPrintStat.created, readyToPrintStat.user)}
          placement={'bottom'}
        >
          <div className={readyToPrintStat.status}>
            <span className='header__bar-steps-circle margin margin--right-s'>
              {readyToPrintStat.icon}
            </span>
            Ready to Print
          </div>
        </MaterialTooltip>
      </li>
      <li>
        <MaterialTooltip
          tooltipText={tooltipText(inPrintingStat.created, inPrintingStat.user)}
          placement={'bottom'}
        >
          <div className={inPrintingStat.status}>
            <span className='header__bar-steps-circle margin margin--right-s'>
              {inPrintingStat.icon}
            </span>
            In Printing
          </div>
        </MaterialTooltip>
      </li>
      <li>
        <MaterialTooltip
          tooltipText={tooltipText(printedStat.created, printedStat.user)}
          placement={'bottom'}
        >
          <div className={printedStat.status}>
            <span className='header__bar-steps-circle margin margin--right-s'>
              {printedStat.icon}
            </span>
            Printed
          </div>
        </MaterialTooltip>
      </li>
      <li>
        <MaterialTooltip
          tooltipText={tooltipText(inWeedingAndMaskingStat.created, inWeedingAndMaskingStat.user)}
          placement={'bottom'}
        >
          <div className={inWeedingAndMaskingStat.status}>
            <span className='header__bar-steps-circle margin margin--right-s'>
              {inWeedingAndMaskingStat.icon}
            </span>
            In Print Assembly
          </div>
        </MaterialTooltip>
      </li>
      <li>
        <MaterialTooltip
          tooltipText={tooltipText(doneStat.created, doneStat.user)}
          placement={'bottom'}
        >
          <div className={doneStat.status}>
            <span className='header__bar-steps-circle margin margin--right-s'>
              {doneStat.icon}
            </span>
            Done
          </div>
        </MaterialTooltip>
      </li>
    </ul>
  );
});

PrintSheetHeaderSteps.propTypes = {
  steps: PropTypes.object,
};

export default PrintSheetHeaderSteps;
