import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import artwork from '../artwork/reducers';
import artworkFileTasks from '../artworkFileTasks/reducers';
import alert from '../alert/reducers';
import printRoomArt from '../printRoomArt/reducers';
import printFiles from '../printFiles/reducers';
import logoReview from '../logoReview/reducers';
import lockerManager from '../lockerManager/reducers';
import orderManagement, { OrderManagementState } from '../orderManagement/reducers';
import weedingAndMaskingRoom from '../weedingAndMaskingRoom/reducers';
import productionAssembly from '../productionAssembly/reducers';
import support from '../support/reducers';
import logos from '../logos/reducers';
import onHandInventory from '../onHandInventory/reducers';
import api from '../api/reducers';
import llScheduling from '../llScheduling/reducers';
import coupons from '../coupons/reducers';
import organizations from '../organizations/reducers';
import admins, { AdminsState } from '../admins/reducers';
import productCatalog from '../productCatalog/reducers';
import fundraisingPayouts from '../fundraisingPayouts/reducers';
import advancedOptions from '../advancedOptions/reducers';
import backorderManagement from '../backorderManagement/reducers';
import layouts from '../layouts/reducers';
import collections from '../collections/reducers';
import rosters from '../rosters/reducers';
import vendorOrders, { VendorOrdersState } from '../vendorOrders/reducers';
import emailCampaigns from '../emailCampaigns/reducers';
import tableManager, { TableManagerState } from '../tableManager/reducers';
import core, { CoreState } from '../core/reducers';
import vouchers, { VouchersState } from '../vouchers/reducers';

export interface RootState {
  admins: AdminsState;
  advancedOptions: any;
  alert: any;
  api: any;
  artwork: any;
  artworkFileTasks: any;
  backorderManagement: any;
  collections: any;
  core: CoreState;
  coupons: any;
  emailCampaigns: any;
  form: any;
  fundraisingPayouts: any;
  layouts: any;
  lockerManager: any;
  logoReview: any;
  logos: any;
  oidc: any;
  onHandInventory: any;
  orderManagement: OrderManagementState;
  organizations: any;
  printRoomArt: any;
  printFiles: any;
  productCatalog: any;
  productionAssembly: any;
  rosters: any;
  router: any;
  llScheduling: any;
  support: any;
  tableManager: TableManagerState;
  vendorOrders: VendorOrdersState;
  vouchers: VouchersState;
  weedingAndMaskingRoom: any;
}

const rootReducer = combineReducers({
  admins,
  advancedOptions,
  alert,
  api,
  artwork,
  artworkFileTasks,
  backorderManagement,
  collections,
  core,
  coupons,
  emailCampaigns,
  form: formReducer,
  fundraisingPayouts,
  layouts,
  lockerManager,
  logoReview,
  logos,
  oidc: oidcReducer,
  onHandInventory,
  orderManagement,
  organizations,
  printRoomArt,
  printFiles,
  productCatalog,
  productionAssembly,
  rosters,
  router: routerReducer,
  llScheduling,
  support,
  tableManager,
  vendorOrders,
  vouchers,
  weedingAndMaskingRoom,
});

export default rootReducer;
