import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { orderItemPersonalizationForm } from '@constants/reduxForms';
import OrderManagementPersonalizationFormNew from './OrderManagementPersonalizationForm';
import { OrderItemDetailsDto } from '@api/fulfillment/models';
import {
  DecorationLocationDto,
  ProductDetailDto,
} from '@api/productCatalog/models';
import { PersonalizationFormData } from '@models/forms/OrderManagement/PersonalizationFormData';
import { QueriesVariationDto } from '@api/orders/models';

interface Color {
  id: number;
  code: string;
  dtgCode: Nullable<string>;
  cmykValue: string;
  dtgCmykValue: string;
  hexValue: string;
  threadValue: string;
  brightness: string;
  isArtwork: Nullable<boolean>;
  isFlood: Nullable<boolean>;
}

interface OwnProps {
  isOpen: boolean;
  onSubmit: (form: PersonalizationFormData) => void;
  initialValues: {
    decorationLocation: string | null | undefined;
    number: string;
    text: string;
    colorId: number | null | undefined;
    quantity: number | undefined;
    personalizationBasePrice: number | null | undefined;
  } | {
    quantity: number | undefined;
  };
  closeModal: () => void;
  orderItem: OrderItemDetailsDto;
  product: ProductDetailDto;
  decorationLocations: Array<DecorationLocationDto>;
  colors: Array<Color>;
  showQuantityField: boolean;
  canUpdatePersonalizationPrice: boolean;
  variation: Nullable<QueriesVariationDto>;
  orderExistsOnCore: boolean;
  disableCharge: boolean;
}

type Props = OwnProps;

const OrderManagementAddPersonalizationModal = React.memo<Props>(({
  isOpen,
  onSubmit,
  initialValues,
  closeModal,
  orderItem,
  product,
  decorationLocations,
  colors,
  showQuantityField,
  canUpdatePersonalizationPrice,
  variation,
  orderExistsOnCore,
  disableCharge,
}) => (
  <Modal
    title={'Add Personalization'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Next'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={orderItemPersonalizationForm}
      />
    )}
  >
    <OrderManagementPersonalizationFormNew
      isOpen={isOpen}
      onSubmit={onSubmit}
      initialValues={initialValues}
      orderItem={orderItem}
      decorationLocations={decorationLocations}
      product={product}
      colors={colors}
      showQuantityField={showQuantityField}
      canUpdatePersonalizationPrice={canUpdatePersonalizationPrice}
      variation={variation}
      orderExistsOnCore={orderExistsOnCore}
      disableCharge={disableCharge}
    />
  </Modal>
));

export default OrderManagementAddPersonalizationModal;
