import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { decorationMethodsOptions } from '@constants/options/optionsValues';
import { productTypeEnum } from '@constants/enums/lockerEnums';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import {
  fetchLockerManagerProducts,
  fetchLockerColors,
  fetchLockerLogos,
} from '@redux/lockerManager/actions';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import ImageModal from '@sharedComponents/Modal/ImageModal';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import LogoDropdown from '@sharedComponents/LogoDisplay/LogoDropdown/LogoDropdown';
import ItemLocationAndSizeCell from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemLocationAndSizeCell';
import IconCell from './CellActions/IconCell';
import LogoAssignmentActionsCell from './CellActions/LogoAssignmentActionsCell';

const ProductsTable = Table();

class LogoBankEditAssignmentTable extends PureComponent {
  state = {
    icon: 'check_box_outline_blank',
    imageModalIsOpen: false,
    imageUrl: '',
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
    sortByState: '',
    sortOrderState: '',
    selectedLogos: [],
    colorFilter: [],
    decorationFilter: '',
    lockerColors: this.props.teamColors &&
      this.props.teamColors.filter((c) => (this.props.lockerColorCodes || []).includes(c.code)),
  };

  componentDidMount() {
    const {
      dispatch,
      lockerId,
    } = this.props;

    dispatch(fetchLockerColors(lockerId));
    dispatch(fetchLockerLogos(lockerId));
  }

  componentDidUpdate(prevProps) {
    const {
      teamColors,
      lockerColorCodes,
    } = this.props;

    if (teamColors
      && lockerColorCodes
      && (prevProps.lockerColorCodes !== lockerColorCodes
      || prevProps.teamColors !== teamColors)) {
      const lockerColors = teamColors.filter((c) => lockerColorCodes.includes(c.code));
      this.setState(() => ({ lockerColors }));
    }
  }

  openImageModal = (imageUrl) => {
    this.setState({
      imageModalIsOpen: true,
      imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      imageModalIsOpen: false,
      imageUrl: '',
    });
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrderState,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrderState);

    this.setState({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }, this.search);
  };

  search = () => {
    const {
      dispatch,
      lockerId,
    } = this.props;

    const {
      pageNumber,
      pageSize,
      sortByState,
      sortOrderState,
      searchInput,
      colorFilter,
      decorationFilter,
      selectedLogos,
    } = this.state;

    return dispatch(fetchLockerManagerProducts(
      pageNumber,
      pageSize,
      lockerId,
      sortByState,
      sortOrderState,
      colorFilter,
      decorationFilter,
      searchInput,
      selectedLogos,
      productTypeEnum.Css,
      undefined,
      true
    ));
  };

  isSelected = (key) => (this.state.selection.includes(key));

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  colorChange = (colors) => {
    if (!colors) {
      return;
    }

    this.setState({
      colorFilter: colors,
    }, this.search);
  };

  logosSelectOnChange = (logos) => {
    this.setState({
      selectedLogos: logos,
    }, this.search);
  };

  decorationChange = (decorations) => {
    if (!decorations) {
      return;
    }

    this.setState({
      decorationFilter: decorations,
    }, this.search);
  };

  getColumns = () => {
    const {
      logoId,
      productsToAddLogo,
      productsToRemoveLogo,
      selectProduct,
      selectAllProducts,
      selectProductWithLogo,
      selectAll,
    } = this.props;

    const columns = [
      {
        Header: () => (
          <SelectAllCell
            isSelected={selectAll}
            selectAllItems={selectAllProducts}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <IconCell
            productsToAddLogo={productsToAddLogo}
            product={cellProps.value}
            logoId={logoId}
            selectProduct={selectProduct}
          />
        ),
      },
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        width: 120,
        Cell: (cellProps) => (
          <ImagePreviewCell
            imageUrl={cellProps.value}
            openModalWithUrl={this.openImageModal}
          />
        ),
      },
      {
        Header: 'Code & Description',
        accessor: '',
        width: 200,
        Cell: (cellProps) => (
          <div className='logo-bank__assignment--code'>
            <TitleDescriptionCell item={cellProps.value} />
          </div>
        ),
      },
      {
        Header: 'Color',
        accessor: 'color',
        width: 120,
      },
      {
        Header: 'Decoration',
        accessor: 'decoration_method',
        width: 80,
      },
      {
        Header: 'Location & Size',
        accessor: '',
        width: 120,
        Cell: (cellProps) => <ItemLocationAndSizeCell item={cellProps.value} />,
      },
      {
        Header: '',
        minWidth: 120,
        accessor: '',
        Cell: (cellProps) => (
          <LogoAssignmentActionsCell
            productsToRemoveLogo={productsToRemoveLogo}
            product={cellProps.value}
            logoId={logoId}
            selectProductWithLogo={selectProductWithLogo}
          />
        ),
      },
    ];

    return columns;
  };

  render() {
    const {
      imageUrl,
      imageModalIsOpen,
      lockerColors,
    } = this.state;

    const {
      queue,
      lockerLogos,
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    const columns = this.getColumns();

    return (
      <div className='sheet'>
        <ImageModal
          isOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />
        <div className='flex'>
          <LogoDropdown
            logos={lockerLogos}
            updateCallback={this.logosSelectOnChange}
          />
          <MultiSelectDropdown
            objects={lockerColors}
            itemText={'colors'}
            updateCallback={this.colorChange}
            textKey={'code'}
            valueKey={'code'}
            classNames={'margin-left'}
            color={true}
          />
          <MultiSelectDropdown
            objects={decorationMethodsOptions}
            itemText={'decorations'}
            updateCallback={this.decorationChange}
            textKey={'name'}
            valueKey={'value'}
            classNames={'margin-left'}
          />
          <SearchFilter
            search={this.filterKey}
            classes={'ml-15'}
            clearSearch={this.clearSearch}
          />
        </div>
        <ProductsTable
          data={queue}
          columns={columns}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          totalPages={totalPages}
          onFetchData={this.fetchData}
        />
      </div>
    );
  }
}

LogoBankEditAssignmentTable.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  lockerId: PropTypes.number.isRequired,
  lockerLogos: PropTypes.array.isRequired,
  lockerColorCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  logoId: PropTypes.number.isRequired,
  selectProduct: PropTypes.func.isRequired,
  selectAllProducts: PropTypes.func.isRequired,
  selectProductWithLogo: PropTypes.func.isRequired,
  productsToAddLogo: PropTypes.array.isRequired,
  productsToRemoveLogo: PropTypes.array.isRequired,
  selectAll: PropTypes.bool.isRequired,
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({
  lockerManager,
  support,
}) => ({
  queue: lockerManager.currentProductQueue.queue,
  totalPages: lockerManager.currentProductQueue.totalPages,
  hasPreviousPage: lockerManager.currentProductQueue.hasPreviousPage,
  hasNextPage: lockerManager.currentProductQueue.hasNextPage,
  pageNumber: lockerManager.currentProductQueue.pageNumber,
  pageSize: lockerManager.currentProductQueue.pageSize,
  lockerLogos: lockerManager.lockerLogos,
  lockerColorCodes: lockerManager.lockerColors,
  teamColors: support.teamColors,
});

export default connect(mapStateToProps)(LogoBankEditAssignmentTable);
