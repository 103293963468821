import React from 'react';
import PropTypes from 'prop-types';
import PrintSheetFileDownloadCard from './PrintSheetFileDownloadCard';
import PrintSheetItemQuantityCountCard from './PrintSheetItemQuantityCountCard';
import PrintSheetFileDtfPrint from './PrintSheetFileDtfPrint';
import { getItemQuantityCount } from '@util/componentHelpers/sheetDetailsHelper';

const PrintSheetFileDownload = React.memo(({ sheet }) => {
  const itemQuantityCount = getItemQuantityCount(sheet);

  return (
    <div className='sheet__files'>
      <PrintSheetFileDtfPrint
        sheetId={sheet.id}
        title={'Print DTF'}
        enabled={true}
      />
      <PrintSheetItemQuantityCountCard
        title='Logos'
        count={itemQuantityCount.logoCount}
      />
      <PrintSheetItemQuantityCountCard
        title='Personalizations'
        count={itemQuantityCount.personalizationCount}
      />
      <PrintSheetFileDownloadCard
        jobs={sheet.logoPrintJobs}
        title='Print Files'
      />
      <PrintSheetFileDownloadCard
        jobs={sheet.logoCutJobs}
        title='Cut Files'
      />
      <PrintSheetFileDownloadCard
        jobs={sheet.personalizationJobs}
        title='Personalization Files'
      />
    </div>
  );
});

PrintSheetFileDownload.propTypes = {
  sheet: PropTypes.object.isRequired,
};

export default PrintSheetFileDownload;
