import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { RootState } from '@redux/index/reducers';
import { validateOrderItemRemovePersonalization } from '@redux/orderManagement/validations';
import { generalOptions } from '@util/optionsMap';
import { parseNumber } from '@util/numberHelpers';
import { canRefundOrderItem } from '@util/paymentHelper';
import { orderItemRemovePersonalizationForm } from '@constants/reduxForms';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import { featureFlags } from '@constants/common';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Select from '@sharedComponents/Form/Select';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import Input from '@sharedComponents/Form/Input';
import Personalization from '../OrderManagementDetails/Personalization';
import { OrderItemRemovePersonalizationFormData } from '@models/forms/OrderManagement/OrderItemRemovePersonalizationFormData';
import { OrderItemDetailsDto } from '@api/fulfillment/models';

interface Color {
  id: number;
  code: string;
  dtgCode: Nullable<string>;
  cmykValue: string;
  dtgCmykValue: string;
  hexValue: Nullable<string>;
  threadValue: Nullable<string>;
  brightness: string;
}
interface OwnProps {
  orderItem: OrderItemDetailsDto;
  canRefundSubtotal: boolean;
  isOpen: boolean;
  closeModal: () => void;
  handleSubmit: () => void;
  paymentType: string;
  colorsDictionary: Record<number, Color>;
  canUpdatePersonalizationPrice: boolean;
  orderExistsOnCore: boolean;
}

const selector = formValueSelector(orderItemRemovePersonalizationForm);
const mapStateToProps = (state: RootState) => ({
  paymentType: selector(state, 'paymentType'),
  refundAmount: selector(state, 'refundAmount'),
  colorsDictionary: state.productCatalog.colorsDictionary,
});

const connector = connect(mapStateToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps &
  InjectedFormProps<OrderItemRemovePersonalizationFormData, FormProps, string[]>;

const OrderManagementRemovePersonalizationModal = React.memo<Props>(({
  orderItem,
  canRefundSubtotal,
  isOpen,
  closeModal,
  handleSubmit,
  paymentType,
  change,
  refundAmount,
  colorsDictionary,
  canUpdatePersonalizationPrice,
  orderExistsOnCore,
}) => {
  const [
    quantityOptions,
    setQuantityOptions,
  ] = useState<Array<{ key: number; name: number; value: number; }>>([]);
  const [
    quantity,
    setQuantity,
  ] = useState<number>(1);

  useEffect(() => {
    if (orderItem) {
      const options = [];
      for (let i = 1; i <= orderItem.quantity!; i++) {
        const option = {
          key: i,
          value: i,
          name: i,
        };
        options.push(option);
      }
      setQuantityOptions(options);
      setQuantity(orderItem.quantity!);
    }
  }, [orderItem?.quantity]);

  const onClose = useCallback(() => {
    change('refundAmount', null);
    closeModal();
  }, [closeModal]);

  const quantityOptionsMapped = generalOptions(quantityOptions);
  const personalization = orderItem?.personalizations?.[0];

  return (
    <div>
      {
        !(!orderItem || orderItem?.personalizations?.length === 0) &&
        <Modal
          isOpen={isOpen}
          title={'Remove Personalization'}
          modalHeight={'m'}
          modalWidth={'m'}
          closeModal={onClose}
          buttons={(
            <ModalButtons
              onClose={onClose}
              confirmBtnText={paymentType === accountingActionTypeEnum.NoChange ? 'Remove Personalization' : 'Next'}
              cancelBtnText={'Cancel'}
              isDangerousAction={paymentType === accountingActionTypeEnum.NoChange}
              formSubmission={true}
              formId={orderItemRemovePersonalizationForm}
            />
          )}
        >
          <div>
            {
              isOpen &&
                <div>
                  <form
                    className='flex__column'
                    id={orderItemRemovePersonalizationForm}
                    onSubmit={handleSubmit}
                  >
                    <div className='flex mt-40 mb-10'>
                      <Personalization
                        personalization={personalization}
                        colorsDictionary={colorsDictionary}
                      />
                      {
                        !orderExistsOnCore &&
                        <div className='w-50 ml-50 flex_column w-100'>
                          <label className='order__item-actions--replace-label mr-sm-2'>
                            Quantity
                          </label>
                          <Field
                            name={'quantity'}
                            component={Select as any}
                            parse={parseNumber}
                          >
                            {quantityOptionsMapped}
                          </Field>
                        </div>
                      }
                    </div>
                    <div>
                      {
                        featureFlags.personalizationPriceUpdateEnabled
                        && canUpdatePersonalizationPrice &&
                        <Field
                          name={'paymentType'}
                          component={RadioGroup as any}
                          groupClassName={'order__additional-payment-selection w-100 mt-15'}
                          radioItems={[
                            {
                              value: accountingActionTypeEnum.NoChange,
                              label: 'No Refund',
                            },
                            {
                              value: accountingActionTypeEnum.PersonalizationRefund,
                              className: 'with-input mr-15',
                              disabled: !!orderItem.voucherId
                              || !canRefundOrderItem(!canRefundSubtotal, orderItem?.isCompleteRefund
                                ? orderItem.isCompleteRefund
                                : false),
                              hasInput: true,
                              rootClassName: 'with-input__radio',
                              inputNode: (
                                <div className='with-input__label'>
                                  <span>Refund: $</span>
                                  <div className='text-field'>
                                    <Field
                                      name={'refundAmount'}
                                      component={Input}
                                      type={'number'}
                                      parse={parseNumber}
                                      placeholder={'Refund Amount'}
                                      disabled={paymentType !== accountingActionTypeEnum.PersonalizationRefund}
                                    />
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                      }
                    </div>
                  </form>
                </div>
            }
          </div>
        </Modal>
      }
    </div>
  );
});

export default connector(reduxForm<OrderItemRemovePersonalizationFormData, any, Props>({
  form: orderItemRemovePersonalizationForm,
  validate: validateOrderItemRemovePersonalization,
  enableReinitialize: true,
})(OrderManagementRemovePersonalizationModal));
