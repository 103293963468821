import React, { useCallback } from 'react';

interface OwnProps<TItem> {
  item: TItem;
  isSelected: boolean;
  isDisabled?: boolean;
  select: (item: TItem, isSelected: boolean) => void;
}

type Props<TItem> = OwnProps<TItem>;

const SelectIcon = <TItem, >({
  item,
  isSelected,
  isDisabled = false,
  select,
}: Props<TItem>) => {
  const selectOnClick = useCallback((e: React.MouseEvent) => {
    select(item, isSelected);

    e.preventDefault();
    e.stopPropagation();
  }, [
    select,
    item,
    isSelected,
  ]);

  if (isDisabled) {
    return (
      <i className='select-icon--disabled material-icons align__center logo-review__table__icon--disabled'>lock</i>
    );
  }

  if (isSelected) {
    return (
      <div
        onClick={selectOnClick}
        className='select-icon--selected material-icons align__center button logo-bank__assignment--green'
      >
          check_box
      </div>
    );
  }

  return (
    <div
      onClick={selectOnClick}
      className='select-icon--unselected material-icons align__center button logo-bank__assignment--square'
    >
        check_box_outline_blank
    </div>
  );
};

export default React.memo(SelectIcon) as typeof SelectIcon;
