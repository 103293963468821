import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as printRoomUrls from '@constants/sqdApiUrls/printRoomUrls';
import {
  printStatusEnum,
  printStepEnum,
} from '@constants/enums/printRoomEnums';
import {
  markInPrinting,
  markPrinted,
  startWeedingAndMasking,
} from '@redux/printRoomArt/actions';
import { downloadPdf } from '@util/componentHelper';
import Icon from '@sharedComponents/Icons/Icon';

class PrintSheetContentHeader extends Component {
  markPrintedOnClick = () => {
    const {
      steps,
      dispatch,
    } = this.props;

    dispatch(markPrinted(steps.id));
  };

  markStartWeedingAndMaskingOnClick = () => {
    const {
      steps,
      dispatch,
    } = this.props;

    dispatch(startWeedingAndMasking(steps.id));
  };

  markInPrintingOnClick = () => {
    const {
      steps,
      dispatch,
    } = this.props;

    dispatch(markInPrinting(steps.id));
  };

  downloadPrintLabels = () => {
    const { sheetId } = this.props;

    return downloadPdf(printRoomUrls.printLabels(sheetId), `S${sheetId}-labels.pdf`);
  };

  render() {
    const {
      steps,
      openRejectModal,
      createPrintableSheet,
    } = this.props;

    let button = (
      <a
        onClick={this.markInPrintingOnClick}
        className='button button--color-gossamer button--size-l button--style-default button--radius-square button--shadow-m button--transform-uppercase'
        data-html2canvas-ignore={true}
      >
        Mark Sheet In Printing
      </a>
    );
    if (steps.status === printStepEnum.Printing) {
      button = (
        <li>
          <a
            onClick={this.markPrintedOnClick}
            className='button button--color-gossamer button--size-l button--style-default button--radius-square button--shadow-m button--transform-uppercase'
            data-html2canvas-ignore={true}
          >
            Mark Sheet as Printed
          </a>
        </li>
      );
    }

    if (steps.status === printStepEnum.Printed) {
      button = (
        <li>
          <a
            onClick={this.markStartWeedingAndMaskingOnClick}
            className='button button--color-gossamer button--size-l button--style-default button--radius-square button--shadow-m button--transform-uppercase'
            data-html2canvas-ignore={true}
          >
            Start Print Assembly
          </a>
        </li>
      );
    }

    if (steps.status === printStatusEnum.Done || steps.status === printStepEnum.PrintAssembly) {
      button = '';
    }

    return (
      <div
        className='sheet__header'
        data-html2canvas-ignore={true}
      >
        <div className='sheet__header-left'>
          <Link
            to='/print-files'
            className='button button--link'
          >
            <span className='margin margin--right-s'>
              <Icon materialIcon={'keyboard_arrow_left'} />
            </span>
            Print Room
          </Link>
        </div>
        <ul className='sheet__header-right'>
          <li>
            <a
              onClick={createPrintableSheet}
              className='button button--link'
            >
              <span className='margin margin--right-s'>
                <Icon materialIcon={'print'} />
              </span>
              Print Version
            </a>
          </li>
          <li>
            <a
              onClick={this.downloadPrintLabels}
              className='button button--link'
            >
              <span className='margin margin--right-s'>
                <Icon materialIcon={'print'} />
              </span>
              Print Labels
            </a>
          </li>
          <li>
            <a
              onClick={openRejectModal}
              className='button button--link'
            >
              <span className='margin margin--right-s'>
                <Icon materialIcon={'clear'} />
              </span>
              Rework Artwork
            </a>
          </li>
          {button}
        </ul>
      </div>
    );
  }
}

PrintSheetContentHeader.propTypes = {
  steps: PropTypes.object,
  openRejectModal: PropTypes.func.isRequired,
  createPrintableSheet: PropTypes.func.isRequired,
  sheetId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ printRoomArt }) => ({
  sheetId: printRoomArt.sheet.id,
});

export default connect(mapStateToProps)(PrintSheetContentHeader);
