import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const LayoutBulkDeleteModal = React.memo(({
  isOpen,
  closeModal,
  layouts,
  deleteLayouts,
}) => (
  <Modal
    title={'Delete Location Layouts'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteLayouts.bind(null, layouts)}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to remove these layouts from the list?</h3>
    <div>
      {layouts.map((layout) => <div key={layout.id}>{layout.name}</div>)}
    </div>
  </Modal>
));

LayoutBulkDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  layouts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  deleteLayouts: PropTypes.func.isRequired,
};

export default LayoutBulkDeleteModal;
