import React, { useMemo } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import { RootState } from '@redux/index/reducers';
import Tabs from '@sharedComponents/Tabs/Tabs';
import { tabSizesEnum } from '@constants/enums/commonEnums';

interface OwnProps {
  selectTab: (newSelectedTab: (typeof printStatusEnum)[keyof typeof printStatusEnum]) => void;
  selectedTab: (typeof printStatusEnum)[keyof typeof printStatusEnum];
}

const mapStateToProps = ({ printFiles }: RootState) => ({
  logoCounts: printFiles.logoCounts,
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const PrintRoomTableTabs = React.memo<Props>(({
  logoCounts,
  selectTab,
  selectedTab,
}) => {
  const tabs = useMemo(() => ([
    {
      title: <strong>{`Print Queue (${logoCounts.printReady})`}</strong>,
      name: printStatusEnum.PrintReady,
    },
    {
      title: <strong>{`In Print (${logoCounts.printing})`}</strong>,
      name: printStatusEnum.Printing,
    },
    {
      title: <strong>{`Recently Printed (${logoCounts.done})`}</strong>,
      name: printStatusEnum.Done,
    },
    {
      title: <strong>{`Priority (${logoCounts.priority})`}</strong>,
      name: printStatusEnum.Priority,
    },
  ]
  ), [logoCounts]);

  return (
    <Tabs
      tabs={tabs}
      selectedTab={selectedTab}
      selectTab={selectTab as (newSelectedTab: string) => void}
      size={tabSizesEnum.Large}
    />
  );
});

export default connector(PrintRoomTableTabs);
