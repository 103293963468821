import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { generalOptions } from '@util/optionsMap';
import { mapToOptionsList } from '@util/mappingHelper';
import InfoField from '@sharedComponents/Display/InfoField';
import Select from '@sharedComponents/Form/Select';

const getVendorTimelineName = (timeline) => `${timeline.description} (${timeline.timelineDays} business days)`;

const VendorTimelineFormDropdown = React.memo(({
  updateTimeline,
  vendorTimelines,
}) => {
  const timelineOptionsList = mapToOptionsList({
    list: vendorTimelines,
    key: 'uuid',
    value: 'uuid',
    name: getVendorTimelineName,
    emptyOption: {
      name: 'Choose a Timeline',
    },
  });

  const timelineOptions = generalOptions(timelineOptionsList);

  return (
    <InfoField
      fieldClass={'mb-0'}
      label={'Vendor Timeline'}
      value={(
        <Field
          name={'timelineUuid'}
          component={Select}
          onChange={updateTimeline}
        >
          {timelineOptions}
        </Field>
      )}
    />
  );
});

VendorTimelineFormDropdown.propTypes = {
  vendorTimelines: PropTypes.arrayOf(PropTypes.shape({
    vendorId: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    timelineDays: PropTypes.number.isRequired,
  })),
  updateTimeline: PropTypes.func.isRequired,
};

export default VendorTimelineFormDropdown;
