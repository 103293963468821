import React, { useCallback } from 'react';
import { usePostHomefieldApiSupportQualityassurancecodes } from '@api/fulfillment/support';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { CreateQualityAssuranceCodeCommand } from '@api/fulfillment/models';
import { materialSwal } from '@util/componentHelper';
import FormInput from '@components/shared/Form/FormInput';
import QualityAssuranceCodeForm, { QualityAssuranceCodeFormModel } from './QualityAssuranceCodeForm';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onAdd: () => void;
}

type Props = OwnProps;

const formId = 'create-qa-code-form';

const AddQualityAssuranceCodeModal = React.memo(({
  isOpen,
  closeModal,
  onAdd,
}: Props) => {
  const { mutateAsync: addCodesAsync } = usePostHomefieldApiSupportQualityassurancecodes();

  const handleAddCode = useCallback(
    async (command: CreateQualityAssuranceCodeCommand) => {
      const variables = {
        data: command,
      };

      const res = await addCodesAsync(variables);

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        closeModal();
        onAdd();
      } else {
        materialSwal('Failure', res?.message ?? 'Failure occurred while adding code', 'error');
      }
    },
    [
      addCodesAsync,
      closeModal,
      onAdd,
    ]
  );

  return (
    <Modal
      title={'Add QA Code'}
      isOpen={isOpen}
      modalHeight={'xl'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add QA Code'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={formId}
        />
      )}
    >
      <QualityAssuranceCodeForm
        needsReset={!isOpen}
        formId={formId}
        onSubmit={handleAddCode}
      />
    </Modal>
  );
});

export default AddQualityAssuranceCodeModal;
