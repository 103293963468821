import React from 'react';

interface OwnProps {
  label: string | JSX.Element;
  value: string | number | boolean | JSX.Element;
  defaultValue?: string;
  labelClass?: string;
  valueClass?: string;
}

type Props = OwnProps;

const DescriptionField = React.memo(({
  label,
  value,
  defaultValue = '-',
  labelClass = '',
  valueClass = '',
}: Props) => (
  <dl>
    <dt className={`order__total-price--label ${labelClass}`}>
      {label}
    </dt>
    <dd className={`order__total-price--value  ${valueClass}`}>
      {value || defaultValue}
    </dd>
  </dl>
));

export default DescriptionField;
