import React, { useCallback } from 'react';
import ItemSelectionIcon from '@components/shared/Icons/ItemSelectionIcon';
import Image from '@sharedComponents/Display/Image';
import { LockerLogoDto } from '@api/fulfillment/models';

interface OwnProps {
  artwork: LockerLogoDto;
  isSelected: boolean;
  selectLogo: (logoId: number) => void;
  decorationMethods: Array<string>;
}

type Props = OwnProps;

const OrderManagementChangeArtworkLogoImage = React.memo<Props>(({
  artwork,
  isSelected,
  selectLogo,
  decorationMethods,
}) => {
  const spanMethod = (method: string, index: number) => (
    <span
      key={index}
      className='order__change-artwork--decoration-method'
    >
      {method}
    </span>
  );

  const onSelectLogo = useCallback(() => {
    selectLogo(artwork.id!);
  }, [
    selectLogo,
    artwork,
  ]);

  return (
    <div
      className={`order__change-artwork--logo-container add-product__available--container ${isSelected && 'isSelected'}`}
      onClick={onSelectLogo}
    >
      <div className='add-product__available--product'>
        <Image
          url={artwork.image}
          classes='order__change-artwork--logo-image'
        />
        <ItemSelectionIcon
          isSelected={isSelected}
          isDisabled={false}
        />
      </div>
      <div className='order__change-artwork--decoration-methods-container'>
        {
          decorationMethods?.map(spanMethod)
        }
      </div>
    </div>
  );
});

export default OrderManagementChangeArtworkLogoImage;
