import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { withRouter } from 'react-router-dom';
import { navigateToPage } from '@util/componentHelper';
import userManager from '@util/userManager';

class CallbackPage extends Component {
  successCallback = () => {
    sessionStorage.setItem('oidc', JSON.stringify(this.props.oidc.user));
    sessionStorage.setItem('userLoading', false);

    const oldUrl = sessionStorage.getItem('oldUrl');
    if (oldUrl && oldUrl !== '') {
      sessionStorage.setItem('oldUrl', '');
      navigateToPage(oldUrl);
    } else {
      navigateToPage('/');
    }
  };

  render() {
    // Just redirect to '/' in both cases
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.successCallback}
      >
        <div>
          Redirecting...
        </div>
      </CallbackComponent>
    );
  }
}

CallbackPage.propTypes = {
  oidc: PropTypes.object,
};

const mapStateToProps = ({ oidc }) => ({
  oidc,
});

export default withRouter(connect(mapStateToProps)(CallbackPage));
