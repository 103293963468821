import { buildQueryString } from '@util/stringHelpers';

export const vendorOrders = 'homefield/api/vendor-orders';
export const notScheduledOrders = `${vendorOrders}/not-scheduled`;

export const vendorOrder = (vendorOrderId: number): string => `${vendorOrders}/${vendorOrderId}`;
export const notScheduledOrder = (orderNumber: number): string => `${notScheduledOrders}/${orderNumber}`;

export const vendorsFilter = `${vendorOrders}/vendors-filter`;

export const notScheduledOrdersTable = (
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: string,
  search: string,
  vendorId: string | null
): string => `${notScheduledOrders}${buildQueryString({
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  search,
  vendorId,
}, true)}`;

export const vendorOrdersTable = (
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: string,
  search: string,
  vendorId: string | null,
  submitStatus: string,
  canceled: boolean
): string => `${vendorOrders}${buildQueryString({
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  search,
  vendorId,
  submitStatus,
  canceled,
}, true)}`;

export const notScheduledOrderItems = (
  orderNumber: number,
  vendorId: string | null
): string => `${notScheduledOrder(orderNumber)}/items${buildQueryString({
  vendorId,
}, true)}`;

export const vendorOrderItems = (vendorOrderId: number): string => `${vendorOrder(vendorOrderId)}/items`;
export const vendorOrderSubmitResults = (vendorOrderId: number): string => `${vendorOrder(vendorOrderId)}/submit-results`;
export const resubmitVendorOrder = (vendorOrderId: number): string => `${vendorOrder(vendorOrderId)}.resubmit`;
export const cancelVendorOrder = (vendorOrderId: number): string => `${vendorOrder(vendorOrderId)}.cancel`;

export const availableSkusForVendorDesignId = (
  vendorCode: string,
  vendorDesignId: string
): string => `${vendorOrders}/vendors/${vendorCode}/design-ids/${encodeURIComponent(vendorDesignId)}/available-skus`;

export default {
  vendorOrders,
  vendorOrder,
  notScheduledOrders,
  notScheduledOrder,
  notScheduledOrdersTable,
  vendorOrdersTable,
  notScheduledOrderItems,
  vendorOrderItems,
  vendorsFilter,
  vendorOrderSubmitResults,
  resubmitVendorOrder,
  cancelVendorOrder,
  availableSkusForVendorDesignId,
};
