import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import Input from '@sharedComponents/Form/Input';
import { parentCategoryForm } from '@constants/reduxForms';
import { validateParentCategory } from '@redux/parentCategories/validations';

let ParentCategoryForm = (props) => {
  const { handleSubmit } = props;

  return (
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      id={parentCategoryForm}
    >
      <div>
        <div className='redux-form__section pb-5'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-xl'>
              <label className='redux-form__label'>
                Code
                <span className='required'>*</span>
              </label>
              <Field
                name={'code'}
                placeholder={'Enter Code'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-xl'>
              <label className='redux-form__label'>
                Name
                <span className='required'>*</span>
              </label>
              <Field
                name={'name'}
                placeholder={'Enter name'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

ParentCategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

ParentCategoryForm = (reduxForm({
  form: parentCategoryForm,
  validate: validateParentCategory,
  enableReinitialize: true,
  shouldError: () => true,
})(ParentCategoryForm));

export default ParentCategoryForm;
