import React from 'react';
import PropTypes from 'prop-types';
import ArtworkTaskNote from './ArtworkTaskNote';

const ArtworkTaskNotes = React.memo(({
  notes,
  lockerId,
  loggedInUser,
  taskId,
}) => {
  if (notes && notes.length > 0) {
    const notesDiv = notes.map((note, i) => (
      <ArtworkTaskNote
        key={i}
        taskId={taskId}
        loggedInUser={loggedInUser}
        lockerId={lockerId}
        note={note}
      />
    ));

    return (
      <ul className='notes custom-scrollbar'>
        {notesDiv}
      </ul>
    );
  }

  return <div />;
});

ArtworkTaskNotes.propTypes = {
  notes: PropTypes.any,
  lockerId: PropTypes.number.isRequired,
  loggedInUser: PropTypes.string,
  taskId: PropTypes.number.isRequired,
};

export default ArtworkTaskNotes;
