import React from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import iconEye from '@assets/icon_hidden.svg';
import iconGray from '@assets/icon_eye_gray.svg';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import Icon from '@sharedComponents/Icons/Icon';

const ColoredStyleNameCell = React.memo(({
  coloredStyle,
  styleProducts,
  style,
  teamColorsByCode,
}) => {
  const products = styleProducts.filter((x) => x.coloredStyleId === coloredStyle.id);
  const disabledProducts = products.filter((x) => x.disabled);
  const discontinuedProducts = products.filter((x) => x.discontinued);

  const anyProductDisabled = disabledProducts.length > 0 ? true : false;
  const anyProductDiscontinued = discontinuedProducts.length > 0 ? true : false;
  const allProductsDisabled = disabledProducts.length === products.length ? true : false;
  const allProductsDiscontinued = discontinuedProducts.length === products.length ? true : false;

  const skuDisabledText = `${!allProductsDisabled ? 'Some' : ''} SKUs are disabled`;
  const skuDiscontinuedText = `${!allProductsDiscontinued ? 'Some' : ''} SKUs are discontinued`;

  const showDisabledIcon = style.disabled
    || coloredStyle.disabled
    || anyProductDisabled
    || allProductsDisabled;
  const showDiscontinuedIcon = style.discontinued
    || coloredStyle.discontinued
    || anyProductDiscontinued
    || allProductsDiscontinued;

  const coloredStyleTeamColor = teamColorsByCode[coloredStyle.colorGroup];

  return (
    <div>
      <div className='catalog-details__name'>
        <ColorDisplay
          colorName={coloredStyle.colorGroup}
          displayName={false}
          size={'size--small'}
          hideEmptySwatch={true}
          hexValue={coloredStyleTeamColor && coloredStyleTeamColor.hexValue}
        />
        <div className='text-uppercase'>{coloredStyle.colorGroup}</div>
        {
          showDisabledIcon &&
          <MaterialTooltip
            tooltipText={(
              <>
                {
                  coloredStyle.disabled &&
                  <div className='pre-line'>Colored style is disabled</div>
                }
                {
                  style.disabled &&
                  <div className='pre-line'>Style is disabled</div>
                }
                {
                  (allProductsDisabled || anyProductDisabled) &&
                  <div className='pre-line'>{skuDisabledText}</div>
                }
              </>
            )}
            placement={'top'}
            contentClasses={'align__center'}
          >
            <i
              className='product-catalog__hidden'
              style={{ backgroundImage: `url("${(coloredStyle.disabled || allProductsDisabled || style.disabled) ? iconEye : iconGray}")` }}
            />
          </MaterialTooltip>
        }
        {
          showDiscontinuedIcon &&
          <MaterialTooltip
            tooltipText={(
              <>
                {
                  coloredStyle.discontinued &&
                  <div className='pre-line'>Colored style is discontinued</div>
                }
                {
                  style.discontinued &&
                  <div className='pre-line'>Style is discontinued</div>
                }
                {
                  (allProductsDiscontinued || anyProductDiscontinued) &&
                  <div className='pre-line'>{skuDiscontinuedText}</div>
                }
              </>
            )}
            placement={'top'}
          >
            <Icon
              materialIcon={'block'}
              classes={`${(allProductsDiscontinued || coloredStyle.discontinued || style.discontinued) ? 'active' : 'disabled'}`}
            />
          </MaterialTooltip>
        }
      </div>
      <div className='table__cell--details'>{coloredStyle.code}</div>
    </div>
  );
});

ColoredStyleNameCell.propTypes = {
  coloredStyle: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  styleProducts: PropTypes.arrayOf(PropTypes.object),
  teamColorsByCode: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.string,
  })).isRequired,
};

export default ColoredStyleNameCell;
