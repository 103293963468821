import React, { useCallback } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import * as reduxFormActions from 'redux-form';
import { SpreadsheetItemDto } from '@api/orders/models';
import { TeamOrganizerFormData } from '@models/forms/TeamOrganizerFormData';
import { orderManagementTeamOrganizerForm } from '@constants/reduxForms';
import { OrderManagementTempOrder } from '@redux/orderManagement/models';
import { materialSwal } from '@util/componentHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import TeamOrganizerForm from '../OrderManagementForms/TeamOrganizerForm';

interface OwnProps {
  isOpen: boolean;
  addItems: (items: SpreadsheetItemDto[]) => void;
  closeModal: () => void;
  order: Nullable<OrderManagementTempOrder>;
}

const mapDispatchToProps = {
  stopSubmit: reduxFormActions.stopSubmit,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const UploadTeamOrganizerModal = React.memo<Props>(({
  isOpen,
  addItems,
  closeModal,
  stopSubmit,
  order,
}) => {
  const handleCloseModal = useCallback(() => {
    closeModal();
    stopSubmit(orderManagementTeamOrganizerForm, {});
  }, [
    closeModal,
    stopSubmit,
  ]);

  const handleAddItems = useCallback(async (form: TeamOrganizerFormData) => {
    if (!form.items || form.items.length === 0) {
      materialSwal('Error', 'No items attached', 'error');
    } else {
      await addItems(form.items);
    }
  }, [addItems]);

  if (!isOpen || !order) return null;

  return (
    <Modal
      title={'Upload Team Organizer'}
      modalSize={'l'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add Items'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={orderManagementTeamOrganizerForm}
        />
      )}
    >
      <TeamOrganizerForm
        onSubmit={handleAddItems}
        salesChannelId={order.lockerId}
        organizationId={order.organizationId}
      />
    </Modal>
  );
});

export default connector(UploadTeamOrganizerModal);
