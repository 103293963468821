import React from 'react';
import { saveAs } from 'file-saver';
import Swal, { SweetAlertResult } from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { fulfillmentRoot } from '@constants/urls/configUrls';
import { highlightTextDuration } from '@constants/values';
import { errorSeverityEnum } from '@constants/enums/commonEnums';
import Api from './api';
import history from './browserHistory';

export const downloadFile = (file: string, fileName: string) => (event: React.MouseEvent): void => {
  event.preventDefault();
  event.stopPropagation();
  // Fix for AWS issue --
  fetch(`${file}?origin=homefield`)
    .then((img) => img.blob())
    .then((blob) => {
      saveAs(blob, fileName ? fileName : file);
    })
    .catch((err) => console.error(err));
};

export const downloadFromLink = (res: string, fileName: string, type: string) => {
  const blob = new Blob([res], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
};

export const convertToCsv = <T extends Record<string, string | number | null>>(rows: T[]) => {
  const keys = Object.keys(rows[0] ?? []);

  return keys.join(',')
  + '\n'
  + rows.map(
    (row) => keys.map((k) => {
      let cell = row[k as keyof T] === null
      || row[k as keyof T] === undefined
        ? ''
        : row[k as keyof T];
      if (cell) {
        cell = cell.toString().replace(/"/g, '""');
        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
      }

      return cell;
    }).join(',')
  ).join('\n');
};

export const convert2dArrayToCsv = (
  columnNames: string[],
  rows: string[][]
) => {
  const processRow = (row: string[]) => {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      const innerValue = !row[j] ? '' : row[j];
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }

    return finalVal + '\n';
  };

  let csvFile = processRow(columnNames);
  for (const row of rows) {
    csvFile += processRow(row);
  }

  return csvFile;
};

export const downloadPdf = (url: string, fileName: string): Promise<void> => Api.fetchFile(`${fulfillmentRoot}/${url}`, {
  headers: {
    'Content-Type': 'application/pdf',
  },
})
  .then((res) => downloadFromLink(res, fileName, 'application/pdf'))
  .then((blob) => {
    window.URL.revokeObjectURL(blob as any);
  });

export const materialSwal = (
  titleText: string,
  text: string | null | undefined = undefined,
  icon: (keyof typeof errorSeverityEnum) | 'success' | undefined = undefined
): Promise<SweetAlertResult> => Swal.fire({
  titleText,
  text: text ?? undefined,
  icon,
  customClass: {
    confirmButton: 'modal__btn btn-primary btn-primary--green',
  },
  buttonsStyling: false,
});

export const swalAlert = (msg: string): Promise<SweetAlertResult> => Swal.fire({
  animation: false,
  html: `<span class="custom-swal-message">${msg}</span>`,
  customClass: {
    confirmButton: 'modal__btn btn-primary btn-primary--green',
  },
  buttonsStyling: false,
});

export const swalChoose = (text: {
  title: string;
  text: string;
  confirm: string;
  cancel: string;
}): Promise<SweetAlertResult> => Swal.fire({
  title: text.title,
  text: text.text,
  icon: 'question',
  showCancelButton: true,
  confirmButtonText: text.confirm,
  cancelButtonText: text.cancel,
  customClass: {
    confirmButton: 'button button--color-orange-peel button--size-l button--style-default ',
    cancelButton: 'button button--color-bali-hai button--size-l button--style-default margin-left',
  },
  buttonsStyling: false,
});

export const parseLockerIdSearch = (search: string): string => {
  let xid;
  if (search[0] === 'L' || search[0] === 'l') {
    xid = search.substring(1, search.length);
  } else {
    xid = search;
  }

  return xid;
};

export const toggleArrayMember = <T, >(
  array: T[],
  member: T
): T[] => array.includes(member) ? array.filter((m) => m !== member) : [
    ...array,
    member,
  ];

export const toggleObjectArrayMemeber = <T, >(
  array: T[],
  member: T,
  key: keyof T
): T[] => {
  const memberExists = array.some((m) => m[key] === member[key]);

  if (memberExists) {
    return array.filter((m) => m[key] !== member[key]);
  }

  return [
    ...array,
    member,
  ];
};

/*
 * Expects an array of objects of the following shape:
 * value: string
 * text: string
 */
export const mapToSelectOptions = (options: { value: any; text: string; }[]): JSX.Element[] => options.map((o) => (
  <option
    key={o.value}
    value={o.value}
  >
    {o.text}
  </option>
));

export const highlightText = (text: string, pattern: string): string | JSX.Element => {
  if (!pattern) {
    return text;
  }

  const startIndex = text.search(new RegExp(`${pattern}`, 'i'));
  const endIndex = startIndex + pattern.length;

  const highlightedText = [];

  if (startIndex !== 0) {
    highlightedText.push(<span key={1}>{text.substring(0, startIndex)}</span>);
  }

  highlightedText.push(
    <span
      key={2}
      className='highlight'
    >
      {text.substring(startIndex, endIndex)}
    </span>
  );

  if (endIndex !== text.length) {
    highlightedText.push(<span key={3}>{text.substring(endIndex, text.length)}</span>);
  }

  return <span>{highlightedText}</span>;
};

export const navigateToPage = (url: string | ({
  pathname: string;
  state: { prevPath: string; };
})): void => {
  history.push(url);
};

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank');
};

export const copyToClipboard = (e: React.MouseEvent, elementId: string): void => {
  const text = document.getElementById(elementId);
  let range;
  let selection: Selection | null;

  if ((document.body as any).createTextRange) {
    range = (document.body as any).createTextRange();
    range.moveToElementText(text);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(text as Node);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  document.execCommand('copy');

  setTimeout(() => {
    selection?.removeAllRanges();
  }, highlightTextDuration);
};

export const copyUrlToClipboard = (e: React.MouseEvent, elementId: string, baseUrl: string): void => {
  const text = document.getElementById(elementId);

  if (!text) return;

  let range;
  let selection: Selection | null;

  const originalText = text!.innerText;
  text.innerText = `${baseUrl}${originalText}`;

  if ((document.body as any).createTextRange) {
    range = (document.body as any).createTextRange();
    range.moveToElementText(text);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(text as Node);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }

  document.execCommand('copy');

  text.innerText = originalText;

  setTimeout(() => {
    selection?.removeAllRanges();
  }, highlightTextDuration);
};
