import React from 'react';
import { productCatalogUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const ProductCatalogLink = React.memo(() => (
  <MenuTile
    title={'Product Catalog'}
    url={productCatalogUrl}
    icon={'collections_bookmark'}
  />
));

export default ProductCatalogLink;
