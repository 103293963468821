import { getHomefieldApiHagPrintroomorderdetails } from '@api/fulfillment/hag';
import {
  HagPrintRoomJobPagedListDto, HagPrintRoomOrderDetailsDto,
} from '@api/fulfillment/models';
import { useGetHomefieldApiColors } from '@api/productCatalog/colors';
import PrintableViewDownloadLink, { RenderProps } from '@components/PrintRoomOrder/PrintableView/PrintableViewDownloadLink';
import Modal from '@components/shared/Modal/Modal';
import Button from '@sharedComponents/Buttons/Button';
import CircularProgressBar from 'CircularProgressBar';
import React, {
  useCallback, useState,
} from 'react';

interface OwnProps {
  items: Array<HagPrintRoomJobPagedListDto>;
}

type Props = OwnProps;

const DownloadBulkPrintableViews = React.memo<Props>(({ items }) => {
  const { data: colors } = useGetHomefieldApiColors({ query: { enabled: true } });

  const [
    orders,
    setOrders,
  ] = useState<HagPrintRoomOrderDetailsDto[]>([]);

  const [
    isOpen,
    setIsOpen,
  ] = useState(false);

  const [
    isLoading,
    setIsLoading,
  ] = useState(false);

  const handleClick = useCallback(async (e) => {
    e?.preventDefault?.();
    const orderNumbers: number[] = items.map((selectedItem) => selectedItem.orderNumber!).sort();
    const detailsArray: HagPrintRoomOrderDetailsDto[] = [];
    setIsLoading(true);
    for (const orderNumber of orderNumbers) {
      let details: HagPrintRoomOrderDetailsDto;
      try {
        details = await getHomefieldApiHagPrintroomorderdetails({ orderNumber });
      } catch {
        setIsLoading(false);

        return;
      }

      if (details) {
        detailsArray.push(details);
      }
    }
    if (detailsArray.length) {
      setOrders(detailsArray);
    } else {
      setIsLoading(false);
    }
  }, [items]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    setIsLoading(false);
  }, []);

  const handleLoadingPdf = useCallback(() => setIsLoading(true), []);
  const handleLoadedPdf = useCallback(() => {
    setIsLoading(false);
    setIsOpen(true);
  }, []);
  const handleErrorPdf = useCallback(() => {
    setIsLoading(false);
    setIsOpen(true);
  }, []);

  const fileName = orders.length === 1
    ? `print-version_O${orders[0].orderNumber}.pdf`
    : 'print-version_multiple.pdf';

  const outerElement: React.FC<RenderProps> = useCallback((props) => (
    <>
      <CircularProgressBar isBlockingLoadingActive={isLoading || props.loading} />
      <Button
        type='secondary'
        text={'Generate Print Versions'}
        onClick={handleClick}
        size={'s'}
        classes={'actions-row__action--button ml-20'}
      />
      <Modal
        modalSize='m'
        title='Generate Print Versions'
        isOpen={!props.loading && isOpen}
        closeModal={handleCloseModal}
        buttons={(
          <div className='modal__footer'>
            <div className='modal__btn-container'>
              {props.linkElement}
            </div>
          </div>
        )}
      >
        <div>
          {
            props.error
              ? (
                <>
                  {props.error}
                </>
              )
              : (
                <>
                  <p>
                        Would you like to download the print version of
                    {' '}{orders.length} order{orders.length === 1 ? '' : 's'}?
                  </p>
                </>
              )
          }
        </div>
      </Modal>
    </>
  ), [
    handleClick,
    handleCloseModal,
    isLoading,
    isOpen,
    orders.length,
  ]);

  return (
    <PrintableViewDownloadLink
      orders={orders}
      colors={colors}
      fileName={fileName}
      text='Download'
      className='cursor-pointer button button--link modal__btn btn-borderless btn-borderless--green button--size-l'
      onClick={handleCloseModal}
      onLoading={handleLoadingPdf}
      onLoaded={handleLoadedPdf}
      onError={handleErrorPdf}
      render={outerElement}
    />
  );
});

export default DownloadBulkPrintableViews;
