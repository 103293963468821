import React from 'react';
import { productionAssemblyPtlWallsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from '@components/MyApps/MenuTile';

const ProductionAssemblyWallsLink = React.memo(() => (
  <MenuTile
    title={'PTL Walls'}
    url={productionAssemblyPtlWallsUrl}
    icon={'storefront'}
  />
));

export default ProductionAssemblyWallsLink;
