import {
  Permission,
  Role,
} from '@customTypes/auth';
import { RootState } from '@redux/index/reducers';
import rules from '../roleAccessRules';

export const checkPermissions = (
  roles: Role[],
  actions: Permission | readonly Permission[]
): boolean => {
  if (!actions || actions.length <= 0) {
    return false;
  }

  if (typeof actions === 'string') {
    actions = [actions];
  }

  for (const role of roles) {
    const permissions = (rules as Record<Role, readonly Permission[]>)[role];
    for (const action of actions) {
      if (permissions && permissions.length > 0 && permissions.includes(action)) {
        return true;
      }
    }
  }

  return false;
};

export const roleMapping = (oidc: RootState['oidc']): Role[] => {
  if (!oidc || !oidc.user || !oidc.user.profile || !oidc.user.profile.homefield_role) {
    return [];
  }

  const roles = oidc.user.profile.homefield_role;
  if (typeof roles === 'string') {
    return [roles] as Role[];
  }

  return roles;
};
