import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import { featureFlags } from '@constants/common';
import {
  decorationTypeEnum,
  personalizationTypeEnum,
  personalizationColorTypeEnum,
  personalizationRequirementEnum,
} from '@constants/enums/decorationEnums';
import { customItemForm } from '@constants/reduxForms';
import { createOptionsList } from '@util/optionsMap';
import { parseNumber } from '@util/numberHelpers';
import Select from '@sharedComponents/Form/Select';
import PersonalizationOptionButton from '@sharedComponents/CustomItems/PersonalizationOptionButton';
import DecorationDimensions from '@sharedComponents/CustomItems/DecorationDimensions';
import FormError from '@sharedComponents/Form/FormError';

class LayoutDecoration extends PureComponent {
  componentDidUpdate() {
    const { decoration } = this.props;

    const {
      type,
      personalizationColorType,
      required,
    } = decoration;

    if (!featureFlags.twoColorPersonalizationEnabled
      && type === decorationTypeEnum.Personalization
      && personalizationColorType === personalizationColorTypeEnum.FillAndOutline) {
      this.changePersonalizationColorType(personalizationColorTypeEnum.Fill);
    }

    if (!featureFlags.optionalPersonalizationEnabled && type === decorationTypeEnum.Personalization && !required) {
      this.changePersonalizationRequirement(true);
    }
  }

  updateDecorationProperty = (propertyName, propertyValue) => {
    const {
      input: { name },
      change,
    } = this.props;

    change(`${name}.${propertyName}`, propertyValue);
  };

  changePersonalizationType = (personalizationType) => {
    this.updateDecorationProperty('personalizationType', personalizationType);
  };

  changePersonalizationColorType = (personalizationColorType) => {
    this.updateDecorationProperty('personalizationColorType', personalizationColorType);
  };

  changePersonalizationRequirement = (required) => {
    this.updateDecorationProperty('required', required);
  };

  changeDecorationHeight = (decorationHeight) => {
    this.updateDecorationProperty('decorationHeight', decorationHeight);
  };

  changeDecorationWidth = (decorationWidth) => {
    this.updateDecorationProperty('decorationWidth', decorationWidth);
  };

  deleteDecoration = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      index,
      removeDecoration,
    } = this.props;

    removeDecoration(index);
  };

  render() {
    const {
      decoration,
      logoDecorationLocations,
      personalizationDecorationLocations,
      selectedDecorationLocations,
      decorationLocationArtworkSizes,
      change,
      meta: { error },
      input: { name },
    } = this.props;

    const {
      type,
      personalizationType,
      personalizationColorType,
      locationId,
      decorationMethod,
      required,
    } = decoration;

    const decorationLocationsForDecoration = decoration.type === decorationTypeEnum.Logo
      ? logoDecorationLocations
      : personalizationDecorationLocations;

    const decorationLocationsOptions = createOptionsList({
      list: decorationLocationsForDecoration.map((d) => ({
        ...d,
        name: `${d.code} - ${d.label}`,
        disabled: selectedDecorationLocations.includes(d.id),
      })),
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: 'Choose location',
      },
    });

    const selectedDecorationMethod = (type === decorationTypeEnum.Logo && locationId)
      ? decorationLocationsForDecoration.find((d) => d.location === locationId)
      : null;

    const decorationDimensions = selectedDecorationMethod && decorationLocationArtworkSizes
      && decorationLocationArtworkSizes[selectedDecorationMethod.id][decorationMethod];

    const decorationDimension = decorationDimensions
      && (decorationDimensions.Undefined || decorationDimensions.Regular);

    const decorationDetails = (type === decorationTypeEnum.Logo)
      ? (
        <div className='lockerManagerEdit__custom-items--decoration-details'>
          <div className='lockerManagerEdit__custom-items--decoration-details__item__title'>Logo Location</div>
          <div
            className='lockerManagerEdit__custom-items--decoration-details__item__icon--remove'
            onClick={this.deleteDecoration}
          />
        </div>
      )
      : (
        <div className='lockerManagerEdit__custom-items--decoration-details'>
          <div
            className='lockerManagerEdit__custom-items--decoration-details__item__icon--remove'
            onClick={this.deleteDecoration}
          />
          <div className='lockerManagerEdit__custom-items--decoration-details__item personalization personalization-layout'>
            <div className='title'>Personalization Type <span className='required'>*</span></div>
            <div className='personalization-types mb-10'>
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationType}
                personalizationOption={personalizationTypeEnum.Name}
                title={'Name'}
                selectPersonalizationOption={this.changePersonalizationType}
                className='w-33'
              />
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationType}
                personalizationOption={personalizationTypeEnum.Number}
                title={'Number'}
                selectPersonalizationOption={this.changePersonalizationType}
                className='w-33'
              />
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationType}
                personalizationOption={personalizationTypeEnum.Both}
                title={'Both'}
                selectPersonalizationOption={this.changePersonalizationType}
                className='w-33'
              />
            </div>

            <div className='title'>Personalization Color Options <span className='required'>*</span></div>
            <div className='personalization-types mb-10'>
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationColorType}
                personalizationOption={personalizationColorTypeEnum.Fill}
                title={'Single Color'}
                className='w-50'
                selectPersonalizationOption={this.changePersonalizationColorType}
              />
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationColorType}
                personalizationOption={personalizationColorTypeEnum.FillAndOutline}
                title={'2 Colors'}
                className='w-50'
                selectPersonalizationOption={this.changePersonalizationColorType}
                disabled={!featureFlags.twoColorPersonalizationEnabled}
              />
            </div>
          </div>
        </div>
      );

    return (
      <div className='lockerManagerEdit__custom-items--decoration'>
        {decorationDetails}
        <div className='lockerManagerEdit__custom-items--decoration__location'>
          <Field
            name={`${name}.locationId`}
            component={Select}
            className={'margin-left'}
            parse={parseNumber}
          >
            {decorationLocationsOptions}
          </Field>
        </div>

        <DecorationDimensions
          decoration={decoration}
          change={change}
          name={name}
          decorationDimension={decorationDimension}
        />

        {
          (decoration.type === decorationTypeEnum.Personalization) &&
          <div className='lockerManagerEdit__custom-items--decoration-details__item personalization personalization-requirement'>
            <div className='personalization-types mb-10'>
              <PersonalizationOptionButton
                selectedPersonalizationOption={required}
                personalizationOption={personalizationRequirementEnum.Required}
                title={'Required'}
                selectPersonalizationOption={this.changePersonalizationRequirement}
                className='w-50'
              />
              <PersonalizationOptionButton
                selectedPersonalizationOption={required}
                personalizationOption={personalizationRequirementEnum.Optional}
                title={'Optional'}
                selectPersonalizationOption={this.changePersonalizationRequirement}
                className='w-50'
                disabled={!featureFlags.optionalPersonalizationEnabled}
              />
            </div>
          </div>
        }

        <FormError
          error={error}
          enableMultiple={true}
          classes={'lockerManagerEdit__custom-items--decoration__error'}
        />
      </div>
    );
  }
}

LayoutDecoration.propTypes = {
  decoration: PropTypes.object.isRequired,
  decorationValue: PropTypes.object,
  index: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  removeDecoration: PropTypes.func.isRequired,
  logoDecorationLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  personalizationDecorationLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  selectedDecorationLocations: PropTypes.arrayOf(PropTypes.number).isRequired,
  decorationLocationArtworkSizes: PropTypes.object.isRequired,
};

const selector = formValueSelector(customItemForm);
const ConnectedLayoutDecoration = connect((state, ownProps) => ({
  decorationValue: selector(state, ownProps.input.name),
  decorationLocationArtworkSizes: state.productCatalog.decorationLocationArtworkSizes,
  logoDecorationLocations: state.productCatalog.logoDecorationLocations,
  personalizationDecorationLocations: state.productCatalog.personalizationDecorationLocations,
}))(LayoutDecoration);

export default ConnectedLayoutDecoration;
