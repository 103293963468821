import React from 'react';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';

interface ImageAction {
  action: () => void;
  isVisible: (item: any) => boolean | boolean;
  text: string;
  icon: string;
  color?: string;
  backgroundColor?: string;
}

interface OwnProps {
  item: any;
  image: string;
  imageActionsData: Array<ImageAction>;
}

type Props = OwnProps;

const ImageActions = React.memo<Props>(({
  item, image, imageActionsData,
}) => {
  const imageBodies = imageActionsData.filter((element) => {
    if (typeof element?.isVisible === 'function') {
      return element.isVisible(item);
    } else if (typeof element?.isVisible === 'boolean') {
      return element.isVisible;
    }

    // If visible is not specified, assume that it's true
    return true;
  }).map((element) => (
    element.text
      ? (
        <>
          <MaterialTooltip
            tooltipText={element.text}
            placement={'bottom'}
          >
            <Icon
              materialIcon={element.icon}
              onClick={element.action.bind(null, item)}
              color={element.color}
              backgroundColor={element.backgroundColor}
            />
          </MaterialTooltip>
        </>
      )
      : (
        <>
          <Icon
            onClick={element.action.bind(null, item)}
            materialIcon={element.icon}
            color={element.color}
            backgroundColor={element.backgroundColor}
          />
        </>
      )

  ));

  return (
    <>
      <div className='add-product__selected--item'>
        <div
          className='add-product__selected--image'
          style={{ backgroundImage: `url("${image}")` }}
        >
          {
            imageBodies
          }
        </div>
      </div>
    </>
  );
});

export default ImageActions;
