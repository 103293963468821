import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  stopSubmit,
} from 'redux-form';
import { connect } from 'react-redux';
import { organizationForm } from '@constants/reduxForms';
import { validateOrganization } from '@redux/organizations/validations';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import OrganizationForm from '../OrganizationForm';

class OrganizationEditModal extends Component {
  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(organizationForm, {}));
  };

  render() {
    const {
      isOpen,
      handleSubmit,
      error,
      initialValues,
      change,
    } = this.props;

    return (
      <Modal
        title={'Edit Organization'}
        isOpen={isOpen}
        modalHeight={'xl'}
        modalWidth={'l'}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onClose={this.handleCloseModal}
            formSubmission={true}
            formId={organizationForm}
          />
        )}
      >
        <div className='order__personalization-modal'>
          <OrganizationForm
            onSubmit={handleSubmit}
            error={error}
            initialValues={initialValues}
            change={change}
          />
        </div>
      </Modal>
    );
  }
}

OrganizationEditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  error: PropTypes.string,
  change: PropTypes.func,
};

export default connect()(reduxForm({
  form: organizationForm,
  enableReinitialize: true,
  validate: validateOrganization,
})(OrganizationEditModal));
