import React from 'react';
import PropTypes from 'prop-types';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

const DtgArtworkSettingsDeleteModal = React.memo(({
  isOpen,
  closeModal,
  selectedSetting,
  onDelete,
}) => (
  <SimpleConfirmationModal
    isOpen={isOpen}
    confirm={onDelete.bind(null, selectedSetting.id)}
    closeModal={closeModal}
    title={'Remove DTG Artwork Setting'}
    confirmationBody={`Are you sure you want to remove the DTG Artwork Setting ${selectedSetting.decorationLocationCode}/${selectedSetting.decorationSize}?`}
  />
));

DtgArtworkSettingsDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedSetting: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DtgArtworkSettingsDeleteModal;
