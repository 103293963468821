import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterTypes } from '@constants/enums/commonEnums';
import { dropdownSizeS } from '@constants/values';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import MultiSelectSearchDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectSearchDropdown';
import NumberRangeFilter from '@sharedComponents/Inputs/NumberRangeFilter';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import { vendorInventoryEnum } from '@constants/enums/vendorInvenotryEnum.ts';
import VendorQuantityFilter from './VendorQuantityFilter';
import WebsiteVisibilityFilter from './WebsiteVisibilityFilter';
import { websiteVisibilityEnum } from '@constants/enums/websiteVisibilityEnum';

class ProductCatalogFilters extends PureComponent {
  state = {
    colorGroupTeamColors:
      this.props.colors &&
      (this.props.teamColors || []).filter((c) => this.props.colors.includes(c.code)),
  };

  componentDidUpdate(prevProps) {
    const {
      teamColors,
      colors,
    } = this.props;

    if (prevProps.teamColors !== teamColors || prevProps.colors !== colors) {
      const colorGroupTeamColors = teamColors.filter((c) => colors.includes(c.code));
      this.setState(() => ({
        colorGroupTeamColors,
      }));
    }
  }

  handleCategories = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.categories);
  };

  handleBrands = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.brands);
  };

  handleVendors = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.vendors);
  };

  handleAges = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.ages);
  };

  handleGenders = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.genders);
  };

  handleSports = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.sports);
  };

  handleColors = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.colors);
  };

  handleSizes = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.sizes);
  };

  handleVendorQuantity = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.quantity);
  };

  handleWebsiteVisibility = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.visibility);
  };

  handleDecorationMethod = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.decorationMethods);
  };

  render() {
    const {
      search,
      filterKey,
      clearSearch,
      brands,
      initialBrands,
      categories,
      initialCategories,
      ages,
      initialAges,
      genders,
      initialGenders,
      sports,
      initialSports,
      initialColors,
      sizes,
      initialSizes,
      priceMin,
      priceMax,
      searchPriceValues,
      recommendedChecked,
      recommendedChange,
      availableForNewStoresChecked,
      availableForNewStoresChange,
      customOnlyChecked,
      customOnlyChange,
      initialVendorQuantity,
      initialWebsiteVisibility,
      vendorNames,
      initialVendorNames,
      initialDecorationMethods,
      decorationMethods,
    } = this.props;

    const { colorGroupTeamColors } = this.state;

    return (
      <div>
        <div className='product-catalog__select-filters'>
          <SearchFilter
            initialValue={search || ''}
            search={filterKey}
            clearSearch={clearSearch}
          />
          <MultiSelectDropdown
            objects={brands}
            selectedObjects={initialBrands}
            itemText={'brands'}
            updateCallback={this.handleBrands}
            textKey={'name'}
            valueKey={'id'}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={categories}
            selectedObjects={initialCategories}
            itemText={'categories'}
            updateCallback={this.handleCategories}
            textKey={'name'}
            valueKey={'id'}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={sports}
            selectedObjects={initialSports}
            itemText={'sports'}
            updateCallback={this.handleSports}
            textKey={'name'}
            valueKey={'id'}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={ages}
            selectedObjects={initialAges}
            itemText={'ages'}
            updateCallback={this.handleAges}
            size={dropdownSizeS}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={genders}
            selectedObjects={initialGenders}
            itemText={'genders'}
            updateCallback={this.handleGenders}
            size={dropdownSizeS}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={colorGroupTeamColors}
            selectedObjects={initialColors}
            itemText={'colors'}
            updateCallback={this.handleColors}
            textKey={'code'}
            valueKey={'code'}
            classNames={'margin-left'}
            color={true}
          />
        </div>
        <div className='product-catalog__filters'>
          <div className='product-catalog__input-filters'>
            <MultiSelectSearchDropdown
              items={sizes}
              selectedItems={initialSizes}
              itemText={'sizes'}
              updateCallback={this.handleSizes}
              displayKey={''}
              valueKey={''}
              classNames={'mr-10'}
              placeholderText={'Search sizes'}
            />
            <NumberRangeFilter
              filterText={'Price Range ($):'}
              initialMinValue={priceMin}
              initialMaxValue={priceMax}
              searchValues={searchPriceValues}
            />
            <MultiSelectDropdown
              objects={vendorNames}
              selectedObjects={initialVendorNames}
              itemText={'vendors'}
              updateCallback={this.handleVendors}
              textKey={'name'}
              valueKey={'code'}
              classNames={'margin-left'}
            />
            <VendorQuantityFilter
              filter={this.handleVendorQuantity}
              selected={initialVendorQuantity}
              defaultValue={vendorInventoryEnum.all.quantity}
              classNames={'margin-left'}
            />
            <MultiSelectDropdown
              objects={decorationMethods}
              selectedObjects={initialDecorationMethods}
              itemText={'decoration methods'}
              updateCallback={this.handleDecorationMethod}
              classNames={'margin-left'}
            />
            <WebsiteVisibilityFilter
              filter={this.handleWebsiteVisibility}
              selected={initialWebsiteVisibility}
              defaultValue={websiteVisibilityEnum.all.visibility}
              classNames={'margin-left'}
            />
          </div>
        </div>
        <div className='product-catalog__filters'>
          <div className='product-catalog__checkbox-filters'>
            <MaterialCheckbox
              checked={recommendedChecked}
              onClick={recommendedChange}
              text={'Recommended Only'}
            />
            <MaterialCheckbox
              checked={availableForNewStoresChecked}
              onClick={availableForNewStoresChange}
              text={'Available for New Stores Only'}
            />
            <MaterialCheckbox
              checked={customOnlyChecked}
              onClick={customOnlyChange}
              text={'Customizable Only'}
            />
          </div>
        </div>
      </div>
    );
  }
}

ProductCatalogFilters.propTypes = {
  search: PropTypes.string.isRequired,
  filter: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  ages: PropTypes.arrayOf(PropTypes.string).isRequired,
  genders: PropTypes.arrayOf(PropTypes.string).isRequired,
  sports: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  vendorQuantityMin: PropTypes.string.isRequired,
  websiteVisibility: PropTypes.string.isRequired,
  vendorNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  decorationMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterKey: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  initialBrands: PropTypes.array,
  initialCategories: PropTypes.array,
  initialAges: PropTypes.array,
  initialGenders: PropTypes.array,
  initialSports: PropTypes.array,
  initialColors: PropTypes.array,
  initialSizes: PropTypes.array,
  initialVendorQuantity: PropTypes.string,
  initialWebsiteVisibility: PropTypes.string,
  initialVendorNames: PropTypes.array,
  initialDecorationMethods: PropTypes.array,
  priceMin: PropTypes.string,
  priceMax: PropTypes.string,
  recommendedChange: PropTypes.func.isRequired,
  availableForNewStoresChange: PropTypes.func.isRequired,
  customOnlyChange: PropTypes.func.isRequired,
  recommendedChecked: PropTypes.bool,
  availableForNewStoresChecked: PropTypes.bool,
  customOnlyChecked: PropTypes.bool,
  searchPriceValues: PropTypes.func.isRequired,
  teamColors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      hexValue: PropTypes.string,
      brightness: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const mapStateToProps = ({
  productCatalog,
  support,
}) => ({
  initialBrands: productCatalog.styleTableState.brands,
  initialCategories: productCatalog.styleTableState.categories,
  initialAges: productCatalog.styleTableState.ages,
  initialGenders: productCatalog.styleTableState.genders,
  initialSports: productCatalog.styleTableState.sports,
  initialColors: productCatalog.styleTableState.colors,
  initialSizes: productCatalog.styleTableState.sizes,
  initialVendorQuantity: productCatalog.styleTableState.vendorQuantityMin,
  initialWebsiteVisibility: productCatalog.styleTableState.websiteVisibility,
  initialVendorNames: productCatalog.styleTableState.vendorNames,
  initialDecorationMethods: productCatalog.styleTableState.decorationMethods,
  recommended: productCatalog.styleTableState.recommended,
  availableForNewStores: productCatalog.styleTableState.availableForNewStores,
  customizable: productCatalog.styleTableState.customizable,
  priceMin: productCatalog.styleTableState.priceMin,
  priceMax: productCatalog.styleTableState.priceMax,
  search: productCatalog.styleTableState.search,
  brands: productCatalog.brands,
  categories: productCatalog.categories,
  ages: productCatalog.ages,
  genders: productCatalog.genders,
  colors: productCatalog.colorGroups,
  sports: productCatalog.sports,
  teamColors: support.teamColors,
  sizes: productCatalog.sizes,
  vendorQuantityMin: productCatalog.vendorQuantityMin,
  websiteVisibility: productCatalog.websiteVisibility,
  vendorNames: productCatalog.vendorNames,
  decorationMethods: productCatalog.decorationMethods,
});

export default connect(mapStateToProps)(ProductCatalogFilters);
