import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import ColorSwatchDropdownItem from '@sharedComponents/ColorDetectionEditSwatches/ColorSwatchDropdownItem';

class ColorsDropdown extends Component {
  state = {
    dropIsActive: false,
  };

  toggleDropdown = () => {
    this.setState({ dropIsActive: !this.state.dropIsActive });
  };

  closeDropdown = () => {
    this.setState({ dropIsActive: false });
  };

  render() {
    const { dropIsActive } = this.state;
    const {
      allColors,
      selectedColors,
      toggleSelectColor,
      text,
    } = this.props;

    const options = allColors
      .map((c, index) => (
        <ColorSwatchDropdownItem
          key={index}
          color={c.code}
          colorDisplayName={c.displayName}
          handleSelect={toggleSelectColor}
          isSelected={selectedColors.includes(c.code)}
          multiselect={true}
          hexValue={c.hexValue}
        />
      ));

    const defaultText = 'Add SquadLocker Colors';

    return (
      <OutsideClickWrapper onClick={this.closeDropdown}>
        <div className='color-dropdown__select'>
          <div onClick={this.toggleDropdown}>
            {text || defaultText}
            <div className='color-dropdown__arrow' />
          </div>
          <div className={`color-dropdown__options ${dropIsActive ? 'is-active' : ''}`}>
            <ul className='color-dropdown__options--list custom-scrollbar'>
              {options}
            </ul>
          </div>
        </div>
      </OutsideClickWrapper>
    );
  }
}

ColorsDropdown.propTypes = {
  allColors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
  })).isRequired,
  selectedColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSelectColor: PropTypes.func.isRequired,
  text: PropTypes.object,
};

export default ColorsDropdown;
