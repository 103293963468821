import React, { useMemo } from 'react';
import {
  Column,
  CellInfo,
} from '@util/tableHelpers';
import Table from '@sharedComponents/Table/Table';
import OneOrMultipleCell from '@components/shared/Table/TableCells/OneOrMultipleCell';
import PrintRoomOrderRejectImageCell from './PrintRoomOrderRejectImageCell';
import PrintRoomOrderRejectNoteCell from './PrintRoomOrderRejectNoteCell';
import { HagReworkLogoItems } from './LogoRejectionItem';

interface OwnProps {
  orderNumber: number;
  data: HagReworkLogoItems[];
  onFetchData: () => void;
  isSelected: (key: number) => boolean;
  updateFailNotes: (artworkTaskId: number, value: string) => void;
  updateFailReason: (artworkTaskId: number, value: string) => void;
  isPageSelected: boolean;
  selectedItems: HagReworkLogoItems[];
  updateSelection: (newSelectedItems: Array<HagReworkLogoItems>, newIsPageSelected: boolean) => void;
}

type Props = OwnProps;

const ItemsTable = Table<HagReworkLogoItems>();

const PrintRoomOrderRejectTable = React.memo<Props>(({
  orderNumber,
  data,
  onFetchData,
  isSelected,
  updateFailNotes,
  updateFailReason,
  isPageSelected,
  selectedItems,
  updateSelection,
}) => {
  type ReworkCellInfo<K extends keyof HagReworkLogoItems> = CellInfo<HagReworkLogoItems, K>;

  const columns: Array<Column<HagReworkLogoItems>> = useMemo(() => [
    {
      Header: 'Logo',
      accessor: 'previewUrl',
      className: 'no-padding',
      minWidth: 60,
      Cell: (cellProps: ReworkCellInfo<'previewUrl'>) => <PrintRoomOrderRejectImageCell imageUrl={cellProps.value} />,
    },
    {
      Header: 'Artwork ID',
      accessor: 'artworkTaskId',
    },
    {
      Header: 'Decoration Location',
      accessor: 'decorationLocation',
    },
    {
      Header: 'Qty',
      accessor: 'quantity',
      minWidth: 50,
    },
    {
      Header: 'Decoration Id',
      accessor: 'decorationIds',
      minWidth: 140,
      Cell: (cellProps: ReworkCellInfo<'decorationIds'>) => (
        <OneOrMultipleCell
          items={cellProps.value?.map((id) => `O${orderNumber}-${id}`)}
          multiLabel={'Multiple Decorations'}
        />
      ),
    },
    {
      Header: 'Rework Artwork',
      accessor: 'artworkTaskId',
      minWidth: 200,
      Cell: (cellProps: ReworkCellInfo<'artworkTaskId'>) => (
        <PrintRoomOrderRejectNoteCell
          item={cellProps.original}
          onFailNotesChange={updateFailNotes}
          onFailReasonChange={updateFailReason}
          isSelected={isSelected}
          key={cellProps.value}
        />
      ),
    },
  ], [
    isSelected,
    orderNumber,
    updateFailNotes,
    updateFailReason,
  ]);

  return (
    <ItemsTable
      columns={columns}
      data={data}
      onFetchData={onFetchData}
      showPagination={false}
      classNames={'reject-table scroll-table-m'}
      selectable={true}
      updateSelection={updateSelection}
      selectedData={selectedItems}
      selectPredicateOrKey={'artworkTaskId'}
      isPageSelected={isPageSelected}
    />
  );
});

export default PrintRoomOrderRejectTable;
