import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  stopSubmit,
} from 'redux-form';
import { connect } from 'react-redux';
import { validateAdmin } from '@redux/admins/validations';
import { editAdminForm } from '@constants/reduxForms';
import { fetchAccountExecutives } from '@redux/admins/actions';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import AdminForm from '../AdminForm';

class AdminEditModal extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAccountExecutives());
  }

  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(editAdminForm, {}));
  };

  render() {
    const {
      isOpen,
      handleSubmit,
      error,
      initialValues,
      accountExecutives,
      change,
    } = this.props;

    return (
      <Modal
        title={'Edit Admin'}
        modalSize={'l'}
        isOpen={isOpen}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onClose={this.handleCloseModal}
            formSubmission={true}
            formId={editAdminForm}
          />
        )}
      >
        <AdminForm
          onSubmit={handleSubmit}
          change={change}
          error={error}
          initialValues={initialValues}
          accountExecutives={accountExecutives}
        />
      </Modal>
    );
  }
}

AdminEditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  error: PropTypes.string,
  accountExecutives: PropTypes.array,
  change: PropTypes.func,
};

const mapStateToProps = ({ admins }) => ({
  accountExecutives: admins.accountExecutives,
});

export default connect(mapStateToProps)(reduxForm({
  form: editAdminForm,
  enableReinitialize: true,
  validate: validateAdmin,
})(AdminEditModal));
