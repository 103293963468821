import React from 'react';
import { VoucherOrderNotificationSettingsDto } from '@api/financialServices/models';
import InfoField from '@sharedComponents/Display/InfoField';
import {
  VoucherNotificationLinkDestinationLabel,
  VoucherNotificationSenderNameLabel,
} from '@constants/labelLookup';
import { parseDateNumeric } from '@util/dateHandler';

interface OwnProps {
  notificationSettings?: VoucherOrderNotificationSettingsDto;
}

type Props = OwnProps;

const VoucherSidebarNotificationsContent = React.memo<Props>(({ notificationSettings }) => (
  <div>
    <div className='flex'>
      <InfoField
        label={'Send Date'}
        value={notificationSettings?.sendDate ? parseDateNumeric(notificationSettings.sendDate) : '-'}
        fieldClass={'w-50'}
      />
      <InfoField
        label={'Link Destination'}
        value={notificationSettings?.linkDestination &&
          VoucherNotificationLinkDestinationLabel[notificationSettings.linkDestination]}
        fieldClass={'w-50'}
      />
    </div>
    <InfoField
      label={'Sender Name'}
      value={notificationSettings?.senderName &&
        VoucherNotificationSenderNameLabel[notificationSettings?.senderName]}
    />
    <InfoField
      label={'Custom Sender Name'}
      value={notificationSettings?.customSenderName}
    />
  </div>
));

export default VoucherSidebarNotificationsContent;
