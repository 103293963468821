import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { validatePrintAssemblyFlagItem } from '@redux/weedingAndMaskingRoom/validations';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import { printAssemblyFlagItemForm } from '@constants/reduxForms';
import { dragAndDropMessage } from '@constants/values';
import {
  getFlagReasonOptions,
  getFlagReasons,
} from '@util/flagHelper';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import Icon from '@sharedComponents/Icons/Icon';
import MarkItemAsFlaggedModalLogoDetails from './MarkItemAsFlaggedModalLogoDetails';
import MarkItemAsFlaggedModalPersonalizationDetails from './MarkItemAsFlaggedModalPersonalizationDetails';

class PrintAssemblyFlaggingModal extends PureComponent {
  state = {
    selectedOption: this.props.initialValues.flagType,
    message: {
      type: 'default',
      body: dragAndDropMessage(),
    },
    attachedFile: null,
    loading: false,
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { change } = this.props;

    this.setState({
      message: {
        body: `File ${acceptedFiles[0].name} has been attached.`,
        type: 'default',
      },
      attachedFile: acceptedFiles,
    });

    change('file', acceptedFiles);

    for (const file of acceptedFiles.concat(rejectedFiles)) {
      window.URL.revokeObjectURL(file.preview);
    }
  };

  handleOptionChange = (changeEvent) => {
    const { change } = this.props;
    const flagType = changeEvent.target.value;

    this.setState({ selectedOption: flagType });
    const reasons = getFlagReasons(flagType, decorationMethodEnum.HAG);

    change('reason', reasons[0].reason);
  };

  render() {
    const {
      item,
      closeModal,
      colorsDictionary,
      isOpen,
      handleSubmit,
    } = this.props;

    const {
      selectedOption,
      message,
      loading,
    } = this.state;

    const dropzoneContent = loading
      ? (
        <div>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </div>
      )
      : <p className={`message-${message.type}`}>{message.body}</p>;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title={'Flag Item'}
        modalSize={'l'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            confirmBtnText={'Flag'}
            isDangerousAction={true}
            formSubmission={true}
            formId={printAssemblyFlagItemForm}
          />
        )}
      >
        {
          isOpen &&
          <form
            className='flagging__form'
            onSubmit={handleSubmit}
            id={printAssemblyFlagItemForm}
          >
            <Field
              name={'flagType'}
              component={RadioGroup}
              groupClassName={'flagging__form-radio-group'}
              onChange={this.handleOptionChange}
              radioItems={[
                {
                  value: flagTypeEnum.Reprint,
                  label: 'Send to Reprint',
                  className: 'ml-n10',
                },
                {
                  value: flagTypeEnum.Rework,
                  disabled: !item.previewUrl,
                  label: 'Send to Rework',
                },
                {
                  value: flagTypeEnum.WrongArtwork,
                  label: 'Send to Artwork Change',
                },
              ]}
            />

            <div className='flagging__form-group'>
              <Field
                name={'reason'}
                component={Select}
              >
                {getFlagReasonOptions(selectedOption, decorationMethodEnum.HAG)}
              </Field>
            </div>
            <div className='flagging__form-group'>
              <div className='text-field flex'>
                <Field
                  name={'note'}
                  component={Input}
                  type={'text'}
                  placeholder={'Enter note*'}
                />
              </div>
            </div>
            <div className='flagging__form-group'>
              <div className='details__expand-dragndrop width-full'>
                <DropZoneWrapper
                  onDrop={this.onDrop}
                  className={'dropzone__full'}
                  multiple={false}
                >
                  {dropzoneContent}
                </DropZoneWrapper>
              </div>
            </div>
            {
              item.previewUrl
                ? (
                  <MarkItemAsFlaggedModalLogoDetails
                    previewUrl={item.previewUrl}
                    indicator={item.indicator}
                    orderNumber={item.orderNumber}
                    size={item.size}
                    type={item.jobType}
                    barcode={item.barcode}
                    quantity={item.quantity}
                    cutColor={item.cutColor}
                  />
                )
                : (
                  <MarkItemAsFlaggedModalPersonalizationDetails
                    personalizationNumber={item.personalizationNumber}
                    personalizationText={item.personalizationText}
                    personalizationColor={colorsDictionary[item.personalizationColorId]}
                    orderNumber={item.orderNumber}
                    size={item.size}
                    quantity={item.quantity}
                  />
                )
            }
          </form>
        }
      </Modal>
    );
  }
}

PrintAssemblyFlaggingModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default (reduxForm({
  form: printAssemblyFlagItemForm,
  validate: validatePrintAssemblyFlagItem,
  enableReinitialize: true,
})(PrintAssemblyFlaggingModal));
