import React from 'react';
import PropTypes from 'prop-types';

const SizeCell = React.memo(({
  size,
  decorationSize,
}) => {
  let text = size;
  if (decorationSize && size === 'FF' && (decorationSize.toLowerCase() === 'youth' || decorationSize.toLowerCase() === 'large')) {
    text = `${text} ${decorationSize}`;
  }

  return (
    <span>
      {text}
    </span>
  );
});

SizeCell.propTypes = {
  size: PropTypes.string,
  decorationSize: PropTypes.string,
};

export default SizeCell;
