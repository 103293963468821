import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';

class BrandsActionsColumn extends Component {
  state = {
    actionsExtended: false,
  };

  closeActions = () => {
    this.setState({ actionsExtended: false });
  };

  toggleActionsMenu = () => {
    this.setState((prevState) => ({
      actionsExtended: !prevState.actionsExtended,
    }));
  };

  handleEditClick = (brand) => {
    const { onBrandEdit } = this.props;

    this.closeActions();
    onBrandEdit(brand);
  };

  handleDeleteClick = (brand) => {
    const { onBrandDelete } = this.props;

    this.closeActions();
    onBrandDelete(brand);
  };

  getActionsMenu = (brand) => {
    const { actionsExtended } = this.state;

    const className = classNames(
      'lockerManager__item-list brands__action-item-list',
      { 'is-active': actionsExtended }
    );

    if (actionsExtended) {
      return (
        <ul className={className}>
          <li>
            <button
              onClick={this.handleEditClick.bind(null, brand)}
              type='button'
            >
              Edit
            </button>
          </li>
          <li className='lockerManager__item-list--border' />
          <li>
            <button
              onClick={this.handleDeleteClick.bind(this, brand)}
              type='button'
              className='red'
            >
              Delete
            </button>
          </li>
        </ul>
      );
    }

    return null;
  };

  render() {
    const { brand } = this.props;

    return (
      <OutsideClickWrapper onClick={this.closeActions}>
        <div className='colors__actions-container'>
          <button
            className='colors__actions-btn'
            onClick={this.toggleActionsMenu}
          >
            <Icon
              materialIcon={'more_vert'}
              classes={'button'}
            />
          </button>
          {this.getActionsMenu(brand)}
        </div>
      </OutsideClickWrapper>
    );
  }
}

BrandsActionsColumn.propTypes = {
  brand: PropTypes.object.isRequired,
  onBrandEdit: PropTypes.func.isRequired,
  onBrandDelete: PropTypes.func.isRequired,
};

export default BrandsActionsColumn;
