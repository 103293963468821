import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { VoucherOrderPreviewDto } from '@api/financialServices/models';
import { formatNumber } from '@util/numberHelpers';
import InfoField from '@sharedComponents/Display/InfoField';
import TextInput from '@sharedComponents/Inputs/TextInput';
import FormError from '@sharedComponents/Form/FormError';
import InfoIconWithTooltip from '@sharedComponents/Icons/InfoIconWithTooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Alert } from '@material-ui/lab';

interface OwnProps {
  discountPercent: number;
  updateDiscountPercent: (value: Nullable<number>) => void;
  discountPercentError: Nullable<string>;
  voucherOrderPreviewData?: VoucherOrderPreviewDto;
}

type Props = OwnProps;

const DiscountSettings = React.memo<Props>(({
  discountPercent,
  updateDiscountPercent,
  discountPercentError,
  voucherOrderPreviewData,
}) => {
  const [voucherAlreadyPaid, setVoucherAlreadyPaid] = React.useState<boolean>(false);
  const onDiscountPercentChange = useCallback((e) => {
    updateDiscountPercent(e.target.value ?? null);
    if (e.target.value === '100') setVoucherAlreadyPaid(true);
    else setVoucherAlreadyPaid(false);
  }, [updateDiscountPercent]);

  const hasGroupedCollectionItems: boolean = voucherOrderPreviewData?.collections!
    .some((collection) => collection?.items!.some((item) => item.collectionItemIds!.length > 1)) ?? false;

  function handleVoucherAlreadyPaidChange(e: React.ChangeEvent<HTMLInputElement>) {
    setVoucherAlreadyPaid(e.target.checked);

    if (e.target.checked) updateDiscountPercent(100);
    else updateDiscountPercent(0);
  }

  return (
    <>
      <Grid
        container={true}
        item={true}
        xs={6}
        className={'voucherOrders__edit-form__section-title pt-0'}
      >
        <span className='card-title'>Discount Settings</span>
      </Grid>
      <Grid
        container={true}
        item={true}
        xs={6}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={voucherAlreadyPaid}
              onChange={handleVoucherAlreadyPaidChange}
              name='checkedB'
              color='primary'
            />
          )}
          label='Voucher Already Paid In Full?'
        />
      </Grid>
      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
        className={'mt-30'}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Discount Percentage</span>
                </div>
              )}
              value={(
                <div>
                  <TextInput
                    initialValue={discountPercent}
                    placeholder={'Enter Discount Percentage'}
                    onChange={onDiscountPercentChange}
                    inputClasses={'w-100'}
                    icon={<span>%</span>}
                  />
                  {
                    discountPercentError &&
                    <FormError error={discountPercentError} />
                  }
                </div>
              )}
            />
          </div>
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                hasGroupedCollectionItems
                  ? (
                    <div className='align__center'>
                      <div>Price After Discount</div>
                      <InfoIconWithTooltip
                        tooltipText={'The voucher price reflects the higher price of any youth/adult items'}
                      />
                    </div>
                  ) : <div>Price After Discount</div>
              )}
              value={`$${formatNumber((voucherOrderPreviewData?.totalAmount ?? 0) - (voucherOrderPreviewData?.discountAmount ?? 0))}`}
            />
          </div>
        </Grid>
        {
          (voucherAlreadyPaid) &&
          <Grid
            item={true}
            xs={12}
          >
            <Alert severity='warning'>
              {'The discount percentage has been set to 100%. Please ensure that this order has already been paid in full before proceeding.'}
            </Alert>
          </Grid>
        }
      </Grid>
    </>
  );
});

export default DiscountSettings;
