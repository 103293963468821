import {
  isNumber,
  isDefined,
} from '@util/valueHelpers';

export const validateStyle = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = ['Style name is required.'];
  }

  if (!values.code) {
    errors.code = ['Parent SKU is required.'];
  }

  if (!values.description) {
    errors.description = ['Description is required.'];
  }

  if (values.discount) {
    if (!isNumber(values.discount)) {
      errors.discount = ['Discount amount must be a number.'];
    } else if (values.discount < 0) {
      errors.discount = ['Discount amount must be a positive number.'];
    }
  }

  if (!values.qtyVendorMinimum) {
    errors.qtyVendorMinimum = ['Minimum vendor quantity amount is required.'];
  }

  if (values.qtyVendorMinimum) {
    if (!isNumber(values.qtyVendorMinimum)) {
      errors.qtyVendorMinimum = ['Minimum vendor quantity amount must be a number.'];
    } else if (values.qtyVendorMinimum <= 0) {
      errors.qtyVendorMinimum = ['Minimum vendor quantity amount must be a positive number greater than 0.'];
    }
  }

  if (!values.taxCode) {
    errors.taxCode = ['Tax Code is required.'];
  }

  if (values.price) {
    if (!isNumber(values.price)) {
      errors.price = ['Price must be a number.'];
    } else if (values.price < 0) {
      errors.price = ['Price must be a positive number.'];
    }
  } else {
    errors.price = ['Price is required.'];
  }

  if (!values.disabled && values.prdOnly) {
    errors.disabled = ['PRD only style cannot be visible in Storefront.'];
    errors.prdOnly = ['PRD only style cannot be visible in Storefront.'];
  }

  return errors;
};

export const validateColoredStyle = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = ['Code is required.'];
  }

  if (!values.colorGroup) {
    errors.colorGroup = ['Color group is required.'];
  }

  if (values.qtyVendorMinimum) {
    if (!isNumber(values.qtyVendorMinimum)) {
      errors.qtyVendorMinimum = ['Minimum vendor quantity amount must be a number.'];
    } else if (values.qtyVendorMinimum <= 0) {
      errors.qtyVendorMinimum = ['Minimum vendor quantity amount must be a positive number greater than 0.'];
    }
  }

  return errors;
};

export const validateProduct = (values) => {
  const errors = {};

  if (!values.sku) {
    errors.sku = ['Sku is required.'];
  }

  if (!values.decorationSize) {
    errors.decorationSize = ['Decoration size is required.'];
  }

  if (values.qtyVendorMinimum) {
    if (!isNumber(values.qtyVendorMinimum)) {
      errors.qtyVendorMinimum = ['Minimum vendor quantity amount must be a number.'];
    } else if (values.qtyVendorMinimum <= 0) {
      errors.qtyVendorMinimum = ['Minimum vendor quantity amount must be a positive number greater than 0.'];
    }
  }

  if (values.basePrice) {
    if (!isNumber(values.basePrice)) {
      errors.basePrice = ['Base price must be a number.'];
    } else if (values.basePrice < 0) {
      errors.basePrice = ['Base price must be a positive number.'];
    }
  }

  if (values.currentCost) {
    if (!isNumber(values.currentCost)) {
      errors.currentCost = ['Current cost must be a number.'];
    } else if (values.currentCost < 0) {
      errors.currentCost = ['Current cost must be a positive number.'];
    }
  }

  if (values.standardCost) {
    if (!isNumber(values.standardCost)) {
      errors.standardCost = ['Standard cost must be a number.'];
    } else if (values.standardCost < 0) {
      errors.standardCost = ['Standard cost must be a positive number.'];
    }
  }

  if (values.weight) {
    if (!isNumber(values.weight)) {
      errors.weight = ['Weight must be a number.'];
    } else if (values.weight < 0) {
      errors.weight = ['Weight must be a positive number.'];
    }
  }

  return errors;
};

export const validateDtgArtworkSettings = (values) => {
  const errors = {};
  if (!values.decorationLocationCode) {
    errors.decorationLocationCode = ['Location is required'];
  }
  if (!values.decorationSize) {
    errors.decorationSize = ['Size is required'];
  }
  if (!isDefined(values.rotation)) {
    errors.rotation = ['Rotation is required'];
  }
  if (!isDefined(values.posX)) {
    errors.posX = ['Position X is required'];
  }
  if (!isDefined(values.posY)) {
    errors.posY = ['Position Y is required'];
  }

  return errors;
};

export const validateDtgPersonalizationSettings = (values) => {
  const errors = {};
  if (!values.decorationLocationCode) {
    errors.decorationLocationCode = ['Location is required'];
  }
  if (!values.decorationSize) {
    errors.decorationSize = ['Size is required'];
  }
  if (!isDefined(values.rotation)) {
    errors.rotation = ['Rotation is required'];
  }
  if (!isDefined(values.posX)) {
    errors.posX = ['Position X is required'];
  }
  if (!isDefined(values.posY)) {
    errors.posY = ['Position Y is required'];
  }

  return errors;
};

export const validateDtgEnvironment = (values) => {
  const errors = {};
  if (!values.environmentName) {
    errors.environmentName = ['Name is required'];
  }

  return errors;
};

export const validateDtgColoredStylesConfig = (values) => {
  const errors = {};

  if (!values.dtgEnvironmentId) {
    errors.dtgEnvironmentId = ['Environment is required'];
  }
  if (!values.dtgTabletProfileId) {
    errors.dtgTabletProfileId = ['Tablet Profile is required'];
  }
  if (!isDefined(values.dtgPretreatmentBatchId)) {
    errors.dtgPretreatmentBatchId = ['Pretreatment Batch is required'];
  }
  if (!isDefined(values.dtgDefaultPalletId)) {
    errors.dtgDefaultPalletId = ['Pallet is required'];
  }
  if (!isDefined(values.pretreatmentPercent)) {
    errors.pretreatmentPercent = ['Pretreatment Percent is required'];
  }
  if (!isDefined(values.pretreatmentPlatenFahrenheit)) {
    errors.pretreatmentPlatenFahrenheit = ['Pretreatment Platen is required'];
  }
  if (!isDefined(values.pretreatmentPsi)) {
    errors.pretreatmentPsi = ['Pretreatment PSI is required'];
  }
  if (!isDefined(values.pretreatmentDwellTimeSeconds)) {
    errors.pretreatmentDwellTimeSeconds = ['Pretreatment Dwell Time is required'];
  }
  if (!isDefined(values.postprintDryerFahrenheit)) {
    errors.postprintDryerFahrenheit = ['Postprint Dryer is required'];
  }
  if (!isDefined(values.postprintProcessTimeMinutes)) {
    errors.postprintProcessTimeMinutes = ['Postprint Process Time is required'];
  }
  if (!isDefined(values.postprintAirSpeed)) {
    errors.postprintAirSpeed = ['Postprint Airspeed is required'];
  }
  if (!isDefined(values.postprintPressFahrenheit)) {
    errors.postprintPressFahrenheit = ['Postprint Press is required'];
  }
  if (!isDefined(values.postprintPsi)) {
    errors.postprintPsi = ['Postprint PSI is required'];
  }
  if (!isDefined(values.postprintDwellTimeSeconds)) {
    errors.postprintDwellTimeSeconds = ['Postprint Dwell Time is required'];
  }

  return errors;
};
