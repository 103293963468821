import React from 'react';
import { layoutsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const LayoutsLink = React.memo(() => (
  <MenuTile
    title={'Location Layouts'}
    url={layoutsUrl}
    icon={'flip_to_front'}
  />
));

export default LayoutsLink;
