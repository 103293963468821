import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  fetchSkuSetupTable,
  getCategories,
  getBrands,
  getSports,
  getColors,
  getLayoutsForStyle,
} from '@redux/productCatalog/actions';
import { fetchTeamColors } from '@redux/support/actions';
import {
  updateColoredStyleStatus,
  updateStyle,
  updateStyleSports,
  updateColoredStyle,
  updateStyleStatus,
  updateStyleLayouts,
} from '@APICalls/productCatalog/actions';
import { materialSwal } from '@util/componentHelper';
import { getSampleImage } from '@util/componentHelpers/productCatalogHelper';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import {
  editStyleForm,
  editColoredStyleForm,
} from '@constants/reduxForms';
import { skuSetupStatusEnum } from '@constants/enums/productCatalogEnums';
import {
  keyNameEnum,
  filterTypes,
} from '@constants/enums/commonEnums';
import { minItemsPerPageInSkuSetup } from '@constants/values';
import TooltipCell from '@sharedComponents/Table/TableCells/TooltipCell';
import Table from '@sharedComponents/Table/Table';
import Tab from '@sharedComponents/Tabs/Tab';
import SkuSetupFilters from './SkuSetupFilters';
import SkuSetupStyleName from './SkuSetupStyleName';
import StyleSetupActions from './SetupTab/StyleSetupActions';
import StyleFinalizeActions from './FinalizeTab/StyleFinalizeActions';
import SkuSetupColoredStylesTable from './SkuSetupColoredStylesTable';
import EditStyleModal from '../ProductCatalog/ProductCatalogModals/EditStyleModal/EditStyleModal';
import EditColoredStyleModal from '../ProductCatalog/ProductCatalogModals/EditColoredStyleModal/EditColoredStyleModal';

const StylesTable = Table();

const skuSetupTabEnum = {
  SetupTab: 'Setup',
  FinalizeTab: 'QC & Finalize',
};

class SkuSetup extends Component {
  state = {
    selectedTab: skuSetupTabEnum.SetupTab,
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
    stylesOnlyChecked: false,
    prdOnly: false,
    expanded: {},
    status: skuSetupStatusEnum.Initial,
    categories: [],
    brands: [],
    search: '',
    activeStyleId: null,
    selectedStyle: null,
    selectedColoredStyle: null,
    editStyleModalIsOpen: false,
    editColoredStyleModalIsOpen: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getCategories());
    dispatch(getBrands());
    dispatch(getSports());
    dispatch(getColors());
    dispatch(fetchTeamColors());
  }

  componentDidUpdate(prevProps) {
    const { queue } = this.props;
    const {
      expanded,
      stylesOnlyChecked,
    } = this.state;

    if ((Object.keys(expanded).length === 0 && queue && queue.length > 0)
      || (prevProps.queue && queue && prevProps.queue.length !== queue.length)) {
      this.changeExpanded(!stylesOnlyChecked);
    }
  }

  changeExpanded = (isExpanded) => {
    const { queue } = this.props;
    const { expanded } = this.state;

    for (let i = 0; i < queue.length; i++) {
      expanded[i] = isExpanded;
    }

    this.setState({ expanded });
  };

  selectSetupTab = () => {
    this.setState({
      selectedTab: skuSetupTabEnum.SetupTab,
      status: skuSetupStatusEnum.Initial,
      activeStyleId: null,
    }, this.refreshData);
  };

  selectFinalizeTab = () => {
    this.setState({
      selectedTab: skuSetupTabEnum.FinalizeTab,
      status: skuSetupStatusEnum.QualityCheck,
      activeStyleId: null,
    }, this.refreshData);
  };

  openEditStyleModal = (style) => {
    const { dispatch } = this.props;
    dispatch(getLayoutsForStyle(style.id));

    this.setState({
      editStyleModalIsOpen: true,
      selectedStyle: style,
    });
  };

  closeEditStyleModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(editStyleForm));
    this.setState({
      editStyleModalIsOpen: false,
      selectedStyle: null,
    });
  };

  openEditColoredStyleModal = (coloredStyle) => {
    this.setState({
      editColoredStyleModalIsOpen: true,
      selectedColoredStyle: coloredStyle,
    });
  };

  closeEditColoredStyleModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(editColoredStyleForm));
    this.setState({
      editColoredStyleModalIsOpen: false,
      selectedColoredStyle: null,
    });
  };

  editColoredStyle = async (coloredStyleForm) => {
    const res = await updateColoredStyle(coloredStyleForm);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.closeEditColoredStyleModal();
      this.refreshData();
    }
  };

  editStyle = async (styleForm) => {
    const styleResponse = await updateStyle(styleForm);

    if (styleResponse && styleResponse.success) {
      const sportsResponse = await updateStyleSports(styleForm);

      if (sportsResponse && sportsResponse.success) {
        const layoutsResponse = await updateStyleLayouts(styleForm);

        if (layoutsResponse && layoutsResponse.success) {
          materialSwal('Success', styleResponse.message, 'success');
          this.closeEditStyleModal();
          this.refreshData();
        }
      }
    }
  };

  fetchData = (state, instance) => {
    const {
      page, pageSize,
    } = instance.state;

    this.setState({
      pageNumber: page + 1,
      pageSize,
    }, this.refreshData);
  };

  refreshData = async () => {
    const { dispatch } = this.props;

    const {
      categories,
      brands,
      prdOnly,
      pageNumber,
      pageSize,
      status,
      search,
    } = this.state;

    return dispatch(fetchSkuSetupTable(
      pageNumber,
      pageSize,
      brands,
      categories,
      prdOnly,
      search,
      status
    ));
  };

  changeColoredStyleStatus = async (coloredStyleIds, status) => {
    const res = await updateColoredStyleStatus(coloredStyleIds, status);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.refreshData();
    }
  };

  changeStyleStatus = async (styleId, status) => {
    const res = await updateStyleStatus(styleId, status);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.refreshData();
    }
  };

  stylesOnlyChange = () => {
    this.setState((prevState) => ({
      stylesOnlyChecked: !prevState.stylesOnlyChecked,
    }), () => {
      const { queue } = this.props;
      const { stylesOnlyChecked } = this.state;
      const expanded = {};

      for (let i = 0; i < queue.length; i++) {
        expanded[i] = stylesOnlyChecked ? false : true;
      }

      this.setState({ expanded });
    });
  };

  prdOnlyChange = () => {
    this.setState((prevState) => ({
      prdOnly: !prevState.prdOnly,
    }), () => {
      const { prdOnly } = this.state;
      this.filterTable(prdOnly, filterTypes.prdOnly);
    });
  };

  filterTable = (items, type) => {
    this.setState({
      [type]: items,
    }, this.refreshData);
  };

  clearSearch = () => {
    this.setState({
      search: '',
    }, this.refreshData);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const search = e.target.value;

    this.setState(() => ({
      search,
    }), this.refreshData);
  };

  updateActiveStyleId = (activeStyleId) => {
    this.setState({ activeStyleId });
  };

  getColumns = () => {
    const {
      coloredStylesForStylesDictionary,
      sportsForStylesDictionary,
    } = this.props;

    const { selectedTab } = this.state;

    return [
      {
        expander: true,
        resizable: false,
        width: 1,
        className: 'sku-setup__expander',
      },
      {
        Header: 'Style',
        accessor: '',
        minWidth: 300,
        Cell: (cellProps) => (
          <SkuSetupStyleName
            name={cellProps.value.name}
            code={cellProps.value.code}
            image={getSampleImage(coloredStylesForStylesDictionary[cellProps.value.id])}
          />
        ),
      },
      {
        Header: 'Sports',
        accessor: 'id',
        width: 160,
        resizable: false,
        Cell: (cellProps) => {
          const items = sportsForStylesDictionary[cellProps.value] &&
            sportsForStylesDictionary[cellProps.value].map((x) => x.name) || [];

          return (
            items.length > 0
              ? (
                <TooltipCell
                  items={items}
                  displayName={items.length === 1 ? 'Sport' : 'Sports'}
                />
              )
              : '-'
          );
        },
      },
      {
        Header: 'Logo',
        accessor: 'decorationLocations',
        width: 160,
        resizable: false,
        Cell: (cellProps) => {
          const items = cellProps.value.filter((x) => x.type === decorationTypeEnum.Logo).map((x) => x.location);

          return (
            items.length > 0
              ? (
                <TooltipCell
                  items={items}
                  displayName={items.length === 1 ? 'Location' : 'Locations'}
                />
              )
              : '-'
          );
        },
      },
      {
        Header: 'Personalization',
        accessor: 'decorationLocations',
        width: 160,
        resizable: false,
        Cell: (cellProps) => {
          const items = cellProps.value
            .filter((x) => x.type === decorationTypeEnum.Personalization)
            .map((x) => x.location);

          return (
            items.length > 0
              ? (
                <TooltipCell
                  items={items}
                  displayName={items.length === 1 ? 'Location' : 'Locations'}
                />
              )
              : '-'
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 160,
        resizable: false,
        Cell: (cellProps) => cellProps.value
          ? (
            <TooltipCell
              items={cellProps.value}
              displayName={'Description'}
            />
          )
          : '-',
      },
      {
        Header: '',
        accessor: '',
        width: 280,
        className: 'sku-setup__action-cell',
        resizable: false,
        Cell: (cellProps) => (
          selectedTab === skuSetupTabEnum.SetupTab
            ? (
              <StyleSetupActions
                style={cellProps.value}
                openEditStyleModal={this.openEditStyleModal}
                changeStyleStatus={this.changeStyleStatus}
              />
            )
            : (
              <StyleFinalizeActions
                style={cellProps.value}
                openEditStyleModal={this.openEditStyleModal}
                changeStyleStatus={this.changeStyleStatus}
              />
            )
        ),
      },
    ];
  };

  renderTableSubComponent = (cell) => {
    const { coloredStylesForStylesDictionary } = this.props;
    const {
      selectedTab,
      activeStyleId,
    } = this.state;

    return (
      <SkuSetupColoredStylesTable
        style={cell.original}
        coloredStyles={coloredStylesForStylesDictionary[cell.original.id] || []}
        selectedTab={selectedTab}
        refresh={this.refreshData}
        changeColoredStyleStatus={this.changeColoredStyleStatus}
        updateActiveStyleId={this.updateActiveStyleId}
        activeStyleId={activeStyleId}
        openEditColoredStyleModal={this.openEditColoredStyleModal}
        nextStatus={selectedTab === skuSetupTabEnum.SetupTab
          ? skuSetupStatusEnum.QualityCheck
          : skuSetupStatusEnum.Completed
        }
      />
    );
  };

  render() {
    const {
      queue,
      pageSize,
      pageNumber,
      totalPages,
      totalCount,
      styleLayouts,
      sportsForStylesDictionary,
      coloredStylesForStylesDictionary,
    } = this.props;

    const {
      selectedTab,
      expanded,
      search,
      stylesOnlyChecked,
      prdOnly,
      selectedColoredStyle,
      editStyleModalIsOpen,
      selectedStyle,
      editColoredStyleModalIsOpen,
    } = this.state;

    const columns = this.getColumns();

    return (
      <div className='container'>
        <EditStyleModal
          modalIsOpen={editStyleModalIsOpen}
          closeModal={this.closeEditStyleModal}
          initialValues={{
            ...selectedStyle,
            sportsInputList: selectedStyle && sportsForStylesDictionary[selectedStyle.id],
            layoutsInputList: styleLayouts,
          }}
          onSubmit={this.editStyle}
          sampleImage={selectedStyle && getSampleImage(coloredStylesForStylesDictionary[selectedStyle.id])}
        />

        {
          selectedColoredStyle &&
          <EditColoredStyleModal
            modalIsOpen={editColoredStyleModalIsOpen}
            closeModal={this.closeEditColoredStyleModal}
            onSubmit={this.editColoredStyle}
            initialValues={{
              ...selectedColoredStyle,
              style: queue.find((style) => style.id === selectedColoredStyle.styleId),
            }}
            refresh={this.refreshData}
          />
        }

        <SkuSetupFilters
          filter={this.filterTable}
          stylesOnlyChecked={stylesOnlyChecked}
          stylesOnlyChange={this.stylesOnlyChange}
          prdOnly={prdOnly}
          prdOnlyChange={this.prdOnlyChange}
          search={search}
          filterKey={this.filterKey}
          clearSearch={this.clearSearch}
        />

        <div className='tabs sku-setup__tabs'>
          <Tab
            title={skuSetupTabEnum.SetupTab}
            isActive={selectedTab === skuSetupTabEnum.SetupTab}
            onSelectTab={this.selectSetupTab}
            classes={'tab-item'}
          />
          <Tab
            title={skuSetupTabEnum.FinalizeTab}
            isActive={selectedTab === skuSetupTabEnum.FinalizeTab}
            onSelectTab={this.selectFinalizeTab}
            classes={'tab-item'}
          />
        </div>

        <div className='master-detail'>
          <div className='w-100'>
            <div className='sheet sku-setup__container'>
              <StylesTable
                data={queue}
                columns={columns}
                totalPages={totalPages}
                defaultPage={pageNumber - 1}
                showPagination={totalCount > minItemsPerPageInSkuSetup}
                onFetchData={this.fetchData}
                defaultPageSize={pageSize}
                subComponent={this.renderTableSubComponent}
                customProps={{ expanded }}
                stickyHeader={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SkuSetup.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  coloredStylesForStylesDictionary: PropTypes.object.isRequired,
  sportsForStylesDictionary: PropTypes.object.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  styleLayouts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.skuSetupQueue.queue,
  totalPages: productCatalog.skuSetupQueue.totalPages,
  pageNumber: productCatalog.skuSetupQueue.pageNumber,
  pageSize: productCatalog.skuSetupQueue.pageSize,
  totalCount: productCatalog.skuSetupQueue.totalCount,
  coloredStylesForStylesDictionary: productCatalog.coloredStylesForStylesDictionary,
  sportsForStylesDictionary: productCatalog.sportsForStylesDictionary,
  styleLayouts: productCatalog.styleLayouts,
});

export default connect(mapStateToProps)(SkuSetup);
