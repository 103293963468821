import React from 'react';
import { decorationLocationArtworkSizesUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const DecorationLocationArtworkSizesLink = React.memo(() => (
  <MenuTile
    title={'Artwork Sizes'}
    url={decorationLocationArtworkSizesUrl}
    icon={'format_size'}
  />
));

export default DecorationLocationArtworkSizesLink;
