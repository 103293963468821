import React from 'react';
import { editProductEssential } from '@APICalls/lockerManager/actions';
import { customItemStatusEnum } from '@constants/enums/lockerEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { materialSwal } from '@util/componentHelper';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

interface Item {
  essential: boolean;
  isCustomItem: boolean;
  isLockerItem: boolean;
  status: number;
}

interface OwnProps {
  product: Item;
  refreshAction: () => void;
  hideCustomItem: (item: Item) => Promise<any>;
  openDeleteModal: (item: Item) => void;
  openEditCustomItemModal: (item: Item) => void;
  openArtworkTasksCreationModal: (item: Item) => void;
  undecorateProduct: (item: Item) => void;
}

type Props = OwnProps;

const ItemActionColumn = React.memo<Props>(({
  product,
  refreshAction,
  hideCustomItem,
  openDeleteModal,
  openEditCustomItemModal,
  openArtworkTasksCreationModal,
  undecorateProduct,
}) => {
  const editEssential = async () => {
    await editProductEssential(product);
    refreshAction();
  };

  const onHideCustomItem = async () => {
    const res = await hideCustomItem(product);
    if (res) {
      materialSwal('Success', res.message, 'success');
    }

    refreshAction();
  };

  const onCreateArtworkTasks = () => {
    openArtworkTasksCreationModal(product);
  };

  const onDeleteItem = () => {
    openDeleteModal(product);
  };

  const onEditCustomItem = () => {
    openEditCustomItemModal(product);
  };

  const onUndecorateProduct = () => {
    undecorateProduct(product);
  };

  const essentialActionLabel = product.essential ? 'Un-mark' : 'Mark';
  const hideActionLabel = (product.isLockerItem && product.status === customItemStatusEnum.Hidden) ? 'Unhide' : 'Hide';

  return (
    <ShowMoreActionsButton
      actions={[
        {
          text: 'Delete Item(s)',
          action: onDeleteItem,
          isVisible: true,
        },
        {
          text: `${essentialActionLabel} as Essential`,
          action: editEssential,
          isVisible: true,
        },
        {
          text: 'Create Artwork Tasks',
          action: onCreateArtworkTasks,
          isVisible: !product.isLockerItem || product.isCustomItem,
        },
        {
          text: product.isCustomItem ? 'Edit Custom Item' : 'Edit Item',
          action: onEditCustomItem,
          isVisible: product.isLockerItem,
          permissions: [PermissionsEnum.LockerManagerEditCustomItems],
        },
        {
          text: `${hideActionLabel}${product.isCustomItem ? ' Custom' : ''} Item`,
          action: onHideCustomItem,
          isVisible: product.isLockerItem,
        },
        {
          text: 'Undecorate',
          action: onUndecorateProduct,
          isVisible: !product.isLockerItem,
        },
      ]}
    />
  );
});

export default ItemActionColumn;
