import * as actionTypes from './types';
import { slServicesApi } from '../../sqdApis';
import logoReviewerUrls from '@constants/sqdApiUrls/logoReviewerUrls';
import sqdLockerUrls from '@constants/sqdApiUrls/lockerUrls';
import { servicesApiDecorationMethods } from '@constants/enums/decorationEnums';
import { requestTasksEnum } from '@constants/enums/logoReviewEnums';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';

export const updateLogoReviewerTasksTable = (payload) => ({
  type: actionTypes.UPDATE_TASKS_TABLE,
  payload,
});

export const updateLogoReviewerTasksTableInfo = (payload) => ({
  type: actionTypes.UPDATE_LOGO_REVIEWER_TABLE_INFO,
  payload,
});

export const updateLogoReviewerTasksFilters = (payload) => ({
  type: actionTypes.UPDATE_TASK_FILTERS,
  payload,
});

export const updateReviewerTasks = (payload) => ({
  type: actionTypes.UPDATE_USER_TASKS,
  payload,
});

export const removeReviewerTasks = (payload) => ({
  type: actionTypes.REMOVE_REVIEWER_TASKS,
  payload,
});

export const updateReviewedLogos = (payload) => ({
  type: actionTypes.UPDATE_REVIEWED_LOGOS,
  payload,
});

export const clearReviewedLogos = (payload) => ({
  type: actionTypes.CLEAR_REVIEWED_LOGOS,
  payload,
});

export const updateLockerLogos = (lockerId, res) => {
  const mapped = res.map((value) => {
    let customColors = [];

    if (value.custom_colors && value.custom_colors.length > 0) {
      customColors = value.custom_colors.map((cc) => ({ code: cc }));
    }

    const logoColorSelection = (value.logo_color_selections || []).map((c) => ({
      code: c.code,
      id: c.id,
      hexValue: c.hex_value,
      selectionType: c.selection_type,
    }));

    return {
      id: value.id,
      image: value.image,
      isApproved: value.artwork_approved,
      decorationMethods: value.decoration_methods,
      colors: value.logo_colors,
      floodColors: value.flood_colors,
      colorSelections: customColors.length > 0
        ? [
          ...logoColorSelection,
          customColors,
        ].flat() : logoColorSelection,
      countUsed: value.count_used,
      countOrdered: value.count_ordered,
    };
  });

  return ({
    type: actionTypes.UPDATE_LOCKER_LOGOS,
    payload: {
      lockerId,
      lockerLogos: mapped,
    },
  });
};

export const fetchTableCounts = () => async (dispatch) => {
  const call = slServicesApi.get(logoReviewerUrls.taskCounts, { handleBlockingLoading: false });

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateLogoReviewerTasksTableInfo({
      notStarted: res.notStartedLogosCount,
      inReview: res.inReviewLogosCount,
      myLogosInReview: res.userLogosInReviewCount,
    }));
  }
};

export const fetchLogoReviewerTasksTable = (
  pageNumber = 1,
  pageSize = 5,
  sortBy = '',
  sortDirection = '',
  inReview = false,
  reviewer = '',
  partner = '',
  searchInput = ''
) => async (dispatch) => {
  const tasksTablePayload = (response) => ({
    totalCount: response.totalCount,
    pagesCount: response.totalPages,
    itemsPerPage: response.pageSize,
    activePage: response.pageNumber,
    items: response.items,
    hasPreviousPage: response.hasPreviousPage,
    hasNextPage: response.hasNextPage,
  });

  const call = slServicesApi.get(logoReviewerUrls.tasksTable(
    pageNumber, pageSize, sortBy, sortDirection, inReview, reviewer, partner, searchInput
  ),
  { handleBlockingLoading: false });

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateLogoReviewerTasksTable(tasksTablePayload(res)));
  }
};

export const fetchLogoReviewerTaskFilters = () => async (dispatch) => {
  const call = slServicesApi.get(logoReviewerUrls.taskFilters, { handleBlockingLoading: false });

  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    dispatch(updateLogoReviewerTasksFilters(res));
  }
};

export const sendTasksToReview = (tasks = []) => async () => {
  const body = tasks.map((t) => t.lockerId);

  const call = slServicesApi.post(logoReviewerUrls.sendToReview, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return makeApiCallWithErrorModal(call);
};

export const cancelLogoReviews = (logos = []) => async (dispatch) => {
  const body = JSON.stringify(logos.map((l) => l.id));

  const call = slServicesApi.put(logoReviewerUrls.cancelLogoReviews, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res) {
    dispatch(removeReviewerTasks(logos));
  }

  return res;
};

export const fetchLogoReviewerUserTasks = () => async (dispatch) => {
  const call = slServicesApi.get(logoReviewerUrls.reviewerTasks, { handleBlockingLoading: false });

  const res = await makeApiCall(call);
  if (res) {
    const lockers = {};
    res.lockers.forEach((l) => lockers[l.id] = l);

    dispatch(updateReviewerTasks(lockers));
  }
};

export const toggleReviewLogo = (logo) => async (dispatch) => {
  dispatch(updateReviewedLogos(logo));
};

export const submitReviewedLogos = (logos = []) => async (dispatch) => {
  const body = logos.map((l) => {
    const approvedDecorationMethods = [];
    if (l.allowedDecorationMethods.HAG) {
      approvedDecorationMethods.push(servicesApiDecorationMethods.HAG);
    }

    if (l.allowedDecorationMethods.EMB) {
      approvedDecorationMethods.push(servicesApiDecorationMethods.EMB);
    }

    if (l.allowedDecorationMethods.DTG) {
      approvedDecorationMethods.push(servicesApiDecorationMethods.DTG);
    }

    if (l.allowedDecorationMethods.DIP) {
      approvedDecorationMethods.push(servicesApiDecorationMethods.DIP);
    }

    const requestTasks = [];
    for (const requestTask of Object.keys(requestTasksEnum)) {
      if (l.requestTasks[requestTask]) {
        requestTasks.push(requestTasksEnum[requestTask]);
      }
    }

    return ({
      id: l.id,
      approvedDecorationMethods,
      requestTasks,
      approvedColors: l.colors,
      note: l.note ?? undefined,
    });
  });

  const call = slServicesApi.post(logoReviewerUrls.submitReviewedTasks, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res) {
    dispatch(clearReviewedLogos());
    dispatch(removeReviewerTasks(logos));
  }

  return res;
};

export const fetchLockerLogos = (lockerId) => async (dispatch) => {
  const call = slServicesApi.get(sqdLockerUrls.lockerLogoBankLogos(lockerId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res && Array.isArray(res)) {
    return dispatch(updateLockerLogos(lockerId, res));
  }
};
