import React from 'react';

interface OwnProps {
  children: JSX.Element;
  classes?: string;
  noPadding?: boolean;
  noSeparator?: boolean;
}

type Props = OwnProps;

const FooterSummaryContainer = React.memo<Props>(({
  children,
  classes = '',
  noPadding = false,
  noSeparator = false,
}) => (
  <div className={`footer-summary-container ${classes}`}>
    <div className={`footer-summary ${noPadding ? 'no-padding' : ''} ${noSeparator ? 'no-separator' : ''}`}>
      {children}
    </div>
  </div>
));

export default FooterSummaryContainer;
