import React from 'react';
import { LockerProductViewModel } from '@api/squadlockerServices/models';
import { LockerItemWithQuantity } from '@models/common/LockerItemWithQuantity';
import { QueriesVariationDto as VariationDto } from '@api/orders/models';

interface OwnProps {
  product: LockerProductViewModel;
  selectSize: (size: { sku: string; size: string; }, productId: number) => void;
  selectedSize: Nullable<{ sku: string; size: string; }>;
  selectedItem: Nullable<LockerItemWithQuantity | VariationDto>;
  sizes: Array<{ sku: string; size: string; }>;
  replaceOnCore: boolean;
}

type Props = OwnProps;

const OrderManagementDeatilsOrderItemListNew = React.memo<Props>(({
  product,
  selectSize,
  selectedSize,
  selectedItem,
  sizes = [],
  replaceOnCore,
}) => {
  const isSelectedItem = replaceOnCore
    ? (selectedItem as Nullable<VariationDto>)?.itemId === product.id
    : (selectedItem as Nullable<LockerItemWithQuantity>)?.id === product.id;

  const productSizes = sizes.map((ps) => (
    <div
      key={ps.sku}
      className={`
        order-item__size
        ${(selectedSize
            && isSelectedItem
            && selectedSize?.sku === ps.sku
            && selectedSize.size === ps.size)
        && 'is-active'}
        `}
      onClick={selectSize.bind(null, ps, product.id)}
    >
      {ps.size}
    </div>
  ));

  return (
    <div className='order-item__container'>
      <div className='order-item__code'>{product.code}</div>
      <div className='order-item__description'>{product.name}</div>
      <div className='order-item__sizes'>{productSizes}</div>
    </div>
  );
});

export default OrderManagementDeatilsOrderItemListNew;
