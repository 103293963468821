/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  ProductionAssemblyDetailsDto,
  GetHomefieldApiProductionassemblyOrderssearchParams,
  OrderInventoryInfoDto,
  ApiResponse,
  UpdateOrderItemBarcodeCommand,
  MarkOrderItemCheckedInCommand,
  UnmarkOrderItemCheckedInCommand,
  MarkOrderItemPreTreatedCommand,
  UnmarkOrderItemPreTreatedCommand,
  MarkOrderItemWaterproofedCommand,
  UnmarkOrderItemWaterproofedCommandObsolete,
  MarkOrderItemQualityCheckedCommand,
  UnmarkOrderItemQualityCheckedCommand,
  OrderItemStatusEnumItemStatusModel,
  MarkOrderItemDecorationCheckedInCommand,
  UnmarkOrderItemDecorationCheckedInCommand,
  MarkOrderItemDecorationDecoratedCommand,
  UnmarkOrderItemDecorationDecoratedCommand,
  UnflagOrderItemDecorationCommand,
  FlagOrderItemDecorationCommand,
  DecorationStatusEnumItemStatusModel,
  FlagDto
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiProductionassemblyOrdersOrderNumber = <TData = ProductionAssemblyDetailsDto>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblyOrdersOrderNumberQueryKey = (orderNumber: number,) => [`/homefield/api/productionassembly/orders/${orderNumber}`]

    
export const useGetHomefieldApiProductionassemblyOrdersOrderNumber = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblyOrdersOrderNumber, ProductionAssemblyDetailsDto>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblyOrdersOrderNumberQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblyOrdersOrderNumber<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiProductionassemblyOrderssearch = <TData = ProductionAssemblyDetailsDto>(
    params?: GetHomefieldApiProductionassemblyOrderssearchParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders.search`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblyOrderssearchQueryKey = (params?: GetHomefieldApiProductionassemblyOrderssearchParams,) => [`/homefield/api/productionassembly/orders.search`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiProductionassemblyOrderssearch = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblyOrderssearch, ProductionAssemblyDetailsDto>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiProductionassemblyOrderssearchParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblyOrderssearchQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblyOrderssearch<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiProductionassemblyInventorySopNumber = <TData = OrderInventoryInfoDto>(
    sopNumber: string,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/inventory/${sopNumber}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblyInventorySopNumberQueryKey = (sopNumber: string,) => [`/homefield/api/productionassembly/inventory/${sopNumber}`]

    
export const useGetHomefieldApiProductionassemblyInventorySopNumber = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblyInventorySopNumber, OrderInventoryInfoDto>, TError = ApiResponse, TData = TQueryFnData>(
 sopNumber: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblyInventorySopNumberQueryKey(sopNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblyInventorySopNumber<TQueryFnData>(sopNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiProductionassemblyOrdersstatusshippingreadyOrderNumber = <TData = ApiResponse>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders.status-shipping-ready/${orderNumber}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersstatusshippingreadyOrderNumber = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersstatusshippingreadyOrderNumber,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number}, TContext>((props) => {
        const {orderNumber} = props || {};

        return  putHomefieldApiProductionassemblyOrdersstatusshippingreadyOrderNumber<TData>(orderNumber,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsupdatebarcode = <TData = ApiResponse>(
    orderNumber: number,
    updateOrderItemBarcodeCommand: UpdateOrderItemBarcodeCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.update-barcode`, method: 'put',
      data: updateOrderItemBarcodeCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsupdatebarcode = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsupdatebarcode,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UpdateOrderItemBarcodeCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UpdateOrderItemBarcodeCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsupdatebarcode<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuscheckedin = <TData = ApiResponse>(
    orderNumber: number,
    markOrderItemCheckedInCommand: MarkOrderItemCheckedInCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.status-checked-in`, method: 'put',
      data: markOrderItemCheckedInCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuscheckedin = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuscheckedin,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: MarkOrderItemCheckedInCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: MarkOrderItemCheckedInCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuscheckedin<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuscheckedin = <TData = ApiResponse>(
    orderNumber: number,
    unmarkOrderItemCheckedInCommand: UnmarkOrderItemCheckedInCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.status-checked-in`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuscheckedin = <TData = AsyncReturnType<typeof deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuscheckedin,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UnmarkOrderItemCheckedInCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UnmarkOrderItemCheckedInCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuscheckedin<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuspretreated = <TData = ApiResponse>(
    orderNumber: number,
    markOrderItemPreTreatedCommand: MarkOrderItemPreTreatedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.status-pre-treated`, method: 'put',
      data: markOrderItemPreTreatedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuspretreated = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuspretreated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: MarkOrderItemPreTreatedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: MarkOrderItemPreTreatedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuspretreated<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuspretreated = <TData = ApiResponse>(
    orderNumber: number,
    unmarkOrderItemPreTreatedCommand: UnmarkOrderItemPreTreatedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.status-pre-treated`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuspretreated = <TData = AsyncReturnType<typeof deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuspretreated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UnmarkOrderItemPreTreatedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UnmarkOrderItemPreTreatedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatuspretreated<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsmarkwaterproofed = <TData = ApiResponse>(
    orderNumber: number,
    markOrderItemWaterproofedCommand: MarkOrderItemWaterproofedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.mark-waterproofed`, method: 'put',
      data: markOrderItemWaterproofedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsmarkwaterproofed = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsmarkwaterproofed,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: MarkOrderItemWaterproofedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: MarkOrderItemWaterproofedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsmarkwaterproofed<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsunmarkwaterproofed = <TData = ApiResponse>(
    orderNumber: number,
    unmarkOrderItemWaterproofedCommandObsolete: UnmarkOrderItemWaterproofedCommandObsolete,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.unmark-waterproofed`, method: 'put',
      data: unmarkOrderItemWaterproofedCommandObsolete
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsunmarkwaterproofed = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsunmarkwaterproofed,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UnmarkOrderItemWaterproofedCommandObsolete}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UnmarkOrderItemWaterproofedCommandObsolete}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsunmarkwaterproofed<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatusqualitychecked = <TData = ApiResponse>(
    orderNumber: number,
    markOrderItemQualityCheckedCommand: MarkOrderItemQualityCheckedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.status-quality-checked`, method: 'put',
      data: markOrderItemQualityCheckedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatusqualitychecked = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatusqualitychecked,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: MarkOrderItemQualityCheckedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: MarkOrderItemQualityCheckedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatusqualitychecked<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatusqualitychecked = <TData = ApiResponse>(
    orderNumber: number,
    unmarkOrderItemQualityCheckedCommand: UnmarkOrderItemQualityCheckedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items.status-quality-checked`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatusqualitychecked = <TData = AsyncReturnType<typeof deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatusqualitychecked,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UnmarkOrderItemQualityCheckedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UnmarkOrderItemQualityCheckedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsstatusqualitychecked<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsStatuses = <TData = OrderItemStatusEnumItemStatusModel[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-items/statuses`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsStatusesQueryKey = (orderNumber: number,) => [`/homefield/api/productionassembly/orders/${orderNumber}/order-items/statuses`]

    
export const useGetHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsStatuses = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsStatuses, OrderItemStatusEnumItemStatusModel[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsStatusesQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblyOrdersOrderNumberOrderitemsStatuses<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatuscheckedin = <TData = ApiResponse>(
    orderNumber: number,
    markOrderItemDecorationCheckedInCommand: MarkOrderItemDecorationCheckedInCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-line-items.status-checked-in`, method: 'put',
      data: markOrderItemDecorationCheckedInCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatuscheckedin = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatuscheckedin,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: MarkOrderItemDecorationCheckedInCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: MarkOrderItemDecorationCheckedInCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatuscheckedin<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatuscheckedin = <TData = ApiResponse>(
    orderNumber: number,
    unmarkOrderItemDecorationCheckedInCommand: UnmarkOrderItemDecorationCheckedInCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-line-items.status-checked-in`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatuscheckedin = <TData = AsyncReturnType<typeof deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatuscheckedin,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UnmarkOrderItemDecorationCheckedInCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UnmarkOrderItemDecorationCheckedInCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatuscheckedin<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatusdecorated = <TData = ApiResponse>(
    orderNumber: number,
    markOrderItemDecorationDecoratedCommand: MarkOrderItemDecorationDecoratedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-line-items.status-decorated`, method: 'put',
      data: markOrderItemDecorationDecoratedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatusdecorated = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatusdecorated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: MarkOrderItemDecorationDecoratedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: MarkOrderItemDecorationDecoratedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatusdecorated<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatusdecorated = <TData = ApiResponse>(
    orderNumber: number,
    unmarkOrderItemDecorationDecoratedCommand: UnmarkOrderItemDecorationDecoratedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-line-items.status-decorated`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatusdecorated = <TData = AsyncReturnType<typeof deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatusdecorated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UnmarkOrderItemDecorationDecoratedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UnmarkOrderItemDecorationDecoratedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsstatusdecorated<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsunflag = <TData = ApiResponse>(
    orderNumber: number,
    unflagOrderItemDecorationCommand: UnflagOrderItemDecorationCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-line-items.unflag`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsunflag = <TData = AsyncReturnType<typeof deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsunflag,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UnflagOrderItemDecorationCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UnflagOrderItemDecorationCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  deleteHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsunflag<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsflag = <TData = ApiResponse>(
    orderNumber: number,
    flagOrderItemDecorationCommand: FlagOrderItemDecorationCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-line-items.flag`, method: 'put',
      data: flagOrderItemDecorationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsflag = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsflag,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: FlagOrderItemDecorationCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: FlagOrderItemDecorationCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsflag<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsStatuses = <TData = DecorationStatusEnumItemStatusModel[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-line-items/statuses`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsStatusesQueryKey = (orderNumber: number,) => [`/homefield/api/productionassembly/orders/${orderNumber}/order-line-items/statuses`]

    
export const useGetHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsStatuses = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsStatuses, DecorationStatusEnumItemStatusModel[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsStatusesQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsStatuses<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsFlags = <TData = FlagDto[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/productionassembly/orders/${orderNumber}/order-line-items/flags`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsFlagsQueryKey = (orderNumber: number,) => [`/homefield/api/productionassembly/orders/${orderNumber}/order-line-items/flags`]

    
export const useGetHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsFlags = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsFlags, FlagDto[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsFlagsQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblyOrdersOrderNumberOrderlineitemsFlags<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

