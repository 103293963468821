import React from 'react';
import PropTypes from 'prop-types';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

const DtgTabletProfilesDeleteModal = React.memo(({
  isOpen,
  closeModal,
  selectedProfile,
  onDelete,
}) => (
  <SimpleConfirmationModal
    isOpen={isOpen}
    confirm={onDelete.bind(null, selectedProfile.id)}
    closeModal={closeModal}
    title={'Remove DTG Tablet Profile'}
    confirmationBody={`Are you sure you want to remove the DTG Tablet Profile ${selectedProfile.name}?`}
  />
));

DtgTabletProfilesDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedProfile: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DtgTabletProfilesDeleteModal;
