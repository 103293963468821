import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { addNewPtlWallForm } from '@constants/reduxForms';
import {
  CreatePtlWallCommand, PtlWallBinSizeEnum,
} from '@api/fulfillment/models';
import {
  usePostHomefieldApiPtlWalls,
  getGetHomefieldApiPtlWallsQueryKey,
} from '@api/fulfillment/ptl';
import { materialSwal } from '@util/componentHelper';
import {
  useForm,
  useFieldArray,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { InitialValues } from './EditPtlWallModal';
import { ptlWallRowPositionColors } from '@constants/values';
import PtlWallForm from './PtlWallForm';

const schema = yup.object({
  name: yup.string().required('Name is required'),
  binSize: yup.string().required('Bin Size is required'),
  numberOfRows: yup.string().required('Number of rows is required'),
  numberOfRowPositions: yup.string().required('Number of positions per row is required'),
  devices: yup.array().of(yup.object().shape({ text: yup.string().required('device barcode is required') })
    .uniqueProperty('text', 'Device barcode must be unique'))
    .required(),
});

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  setUpdatedWallId: Dispatch<SetStateAction<any>>;
}

type Props = OwnProps;

const initialValues: InitialValues = {
  numberOfRows: '4',
  numberOfRowPositions: '3',
};

const AddNewPtlWallModal = React.memo(({
  isOpen,
  closeModal,
  setUpdatedWallId,
}: Props) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      binSize: '',
      numberOfRows: 4,
      numberOfRowPositions: 3,
      devices: [
        { text: '' },
        { text: '' },
        { text: '' },
        { text: '' },
      ],
    },
  });

  const fieldArray = useFieldArray({
    control: methods.control,
    name: 'devices',
  });

  const numberOfRowPositions = methods.watch('numberOfRowPositions');
  const numberOfRows = methods.watch('numberOfRows');

  const deviceInputs = numberOfRows && numberOfRows > 0
    ? Array.from(Array(+numberOfRows).keys())
    : [];
  const rowPositions = useMemo(() => (numberOfRowPositions && numberOfRowPositions > 0
    ? Array.from(Array(+numberOfRowPositions).keys())
    : []), [numberOfRowPositions]);

  const queryClient = useQueryClient();
  const { mutateAsync: addPtlWall } = usePostHomefieldApiPtlWalls({
    mutation: { onSuccess: () => { queryClient.invalidateQueries(getGetHomefieldApiPtlWallsQueryKey()); } },
  });

  const handleCloseModal = useCallback(() => {
    methods.reset();
    closeModal();
  }, [
    methods,
    closeModal,
  ]);

  const addNewPtlWall = useCallback(
    async (wallParam: CreatePtlWallCommand) => {
      if (!wallParam.devices) return;
      const variables = {
        data: {
          name: wallParam.name,
          binSize: wallParam.binSize,
          numberOfRows: wallParam.numberOfRows,
          numberOfRowPositions: wallParam.numberOfRowPositions,
          devices: wallParam.devices.map((device: any) => device.text),
          colors: rowPositions.map((row, index) => ptlWallRowPositionColors[index]),
        },
      };

      const res = await addPtlWall(variables);

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        setUpdatedWallId(undefined);
        handleCloseModal();
      }
    },
    [
      addPtlWall,
      rowPositions,
      handleCloseModal,
      setUpdatedWallId,
    ]
  );

  return (
    <Modal
      title={'Add Wall'}
      isOpen={isOpen}
      modalHeight={'xl'}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add Wall'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={addNewPtlWallForm}
        />
      )}
    >
      <PtlWallForm
        methods={methods}
        onSubmit={addNewPtlWall}
        rowPositionColors={ptlWallRowPositionColors}
        rowPositions={rowPositions}
        deviceInputs={deviceInputs}
        initialValues={initialValues}
        fieldArray={fieldArray}
      />
    </Modal>
  );
});

export default AddNewPtlWallModal;
