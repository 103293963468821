import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useGetHomefieldApiRostermanagerRostermembersRosterMemberIdOrderitems as useGetRosterMemberOrderItems } from '@api/fulfillment/roster-manager';
import {
  CollectionItemViewModel,
  RosterTeamMemberViewModel,
} from '@api/squadlockerServices/models';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import InfoField from '@sharedComponents/Display/InfoField';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import Tabs from '@sharedComponents/Tabs/Tabs';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import OrderedItemsTab from './RosterTeamMemberQuickViewContent/OrderedItemsTab';
import UnorderedItemsTab from './RosterTeamMemberQuickViewContent/UnorderedItemsTab';
import ContactTab from './RosterTeamMemberQuickViewContent/ContactTab';

enum RosterTeamMemberQuickViewTabEnum {
  OrderedItems = 'OrderedItems',
  UnorderedItems = 'UnorderedItems',
  Contact = 'Contact',
}

interface OwnProps {
  rosterTeamMember: RosterTeamMemberViewModel;
  collectionItems: CollectionItemViewModel[];
  itemsToOrderCount: number;
  collectionRequired: boolean;
  closeDetails: () => void;
}

type Props = OwnProps;

const RosterTeamMemberQuickView = React.memo<Props>(({
  rosterTeamMember,
  collectionRequired,
  collectionItems,
  itemsToOrderCount,
  closeDetails,
}) => {
  const [
    selectedTab,
    setSelectedTab,
  ]
    = useState<RosterTeamMemberQuickViewTabEnum>(RosterTeamMemberQuickViewTabEnum.OrderedItems);

  const {
    data: rosterMemberOrderItems,
    refetch: fetchRosterMemberOrderItems,
  } = useGetRosterMemberOrderItems(rosterTeamMember.id);

  useEffect(() => {
    if (!rosterTeamMember) return;
    fetchRosterMemberOrderItems();
  }, [
    rosterTeamMember,
    fetchRosterMemberOrderItems,
  ]);

  const getTabs = () => {
    const tabs = [
      {
        title: 'Ordered',
        name: RosterTeamMemberQuickViewTabEnum.OrderedItems,
        content: (
          <OrderedItemsTab
            rosterMemberOrderItems={rosterMemberOrderItems ?? []}
          />
        ),
      },
      {
        title: 'Unordered',
        name: RosterTeamMemberQuickViewTabEnum.UnorderedItems,
        content: (
          <UnorderedItemsTab
            rosterTeamMember={rosterTeamMember}
            rosterMemberOrderItems={rosterMemberOrderItems ?? []}
            requiredCollectionItems={collectionRequired ? collectionItems : []}
          />
        ),
      },
      {
        title: 'Contact',
        name: RosterTeamMemberQuickViewTabEnum.Contact,
        content: (
          <ContactTab
            rosterTeamMember={rosterTeamMember}
          />
        ),
      },
    ];

    return tabs;
  };

  const selectTab = useCallback((newSelectedTab: string) => {
    setSelectedTab(newSelectedTab as RosterTeamMemberQuickViewTabEnum);
  }, []);

  const tabs = useMemo(getTabs, [getTabs]);

  return (
    <TableQuickViewContainer
      noPadding={true}
      size={TableQuickViewSizeEnum.Medium}
    >
      <>
        <TableQuickViewHeader
          title={<span>Roster Member Details</span>}
          onClose={closeDetails}
        />
        <div className='tableView__details--content-container'>
          <div className='flex'>
            <InfoField
              label={'Full Name'}
              value={(rosterTeamMember.firstName && rosterTeamMember.lastName)
                ? `${rosterTeamMember.lastName}, ${rosterTeamMember.firstName}`
                : `${rosterTeamMember.lastName || rosterTeamMember.firstName}`
              }
              fieldClass={'size-50'}
            />
            <InfoField
              label={'Required Items Ordered'}
              value={(
                <span
                  className={`roster__items-ordered-count${itemsToOrderCount !== 0 && (rosterTeamMember.orderedItemsCount || 0) === itemsToOrderCount ? '--all' : ''}`}
                >
                  {(rosterTeamMember.orderedItemsCount || 0)}/{itemsToOrderCount}
                </span>
              )}
              fieldClass={'ml-15'}
            />
          </div>
          <div className='flex'>
            <InfoField
              label={'Role'}
              value={rosterTeamMember.role}
              fieldClass={'size-50'}
            />
            <InfoField
              label={'Number'}
              value={rosterTeamMember.number}
              fieldClass={'size-50 ml-15'}
            />
          </div>
        </div>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          selectTab={selectTab}
          containerClasses={'h-100'}
          classes={'mb-0'}
        />
      </>
    </TableQuickViewContainer>
  );
});

export default RosterTeamMemberQuickView;
