import React from 'react';
import {
  Field,
  FieldArray,
} from 'redux-form';
import CollectionItem from './CollectionItem';

interface OwnProps {
  change: (name: string, value: any) => void;
}

type Props = OwnProps;

const CollectionItemsList = React.memo<Props>(({ change }) => {
  const renderItem = (member: any, index: number, fields: any) => {
    const items = fields.getAll() || [];

    return (
      <Field
        key={index}
        name={member}
        index={index}
        component={CollectionItem}
        change={change}
        item={items[index]}
      />
    );
  };

  const renderItems = ({ fields }: any) => (
    <div>
      <div className='collections-form__labels align__center mt-20 mb-5'>
        <span className='ml-20'>Product</span>
        <span className='mr-10'>Quantity</span>
      </div>
      <div className='collections-form__items custom-scrollbar'>
        {fields.map(renderItem)}
      </div>
    </div>
  );

  return (
    <FieldArray
      name={'itemGroups'}
      component={renderItems as any}
      rerenderOnEveryChange={true}
      change={change}
    />
  );
});

export default CollectionItemsList;
