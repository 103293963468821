import React, { PureComponent } from 'react';
import { formatDollarAmount } from '@util/numberHelpers';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';
import Image from '@sharedComponents/Display/Image';
import Tag from '@sharedComponents/Display/Tag';
import OrderManagementDetailsItemLabels from '@components/OrderManagement/OrderManagementDetailsItemLabels';

interface OwnProps{
  item: any;
}

type Props = OwnProps;

interface State {
  itemInfoModalIsOpen: boolean;
}

class OrderManagementQuickViewItem extends PureComponent<Props, State> {
  state = {
    itemInfoModalIsOpen: false,
  };

  openItemInfoModal = () => {
    this.setState(() => ({ itemInfoModalIsOpen: true }));
  };

  closeItemInfoModal = () => {
    this.setState(() => ({ itemInfoModalIsOpen: false }));
  };

  render() {
    const { item } = this.props;
    const {
      styleName,
      colorGroup,
      quantity,
      size,
      imageUrl,
      sku,
      pricePaid,
    } = item;

    const { itemInfoModalIsOpen } = this.state;

    return (
      <div className='order__item'>
        <ItemInfoModal
          isOpen={itemInfoModalIsOpen}
          closeModal={this.closeItemInfoModal}
          sku={item?.sku}
          lockerItemId={item?.lockerItemId}
        />
        <div className='order__item-column align__center'>
          <Image
            url={imageUrl}
            openInModal={this.openItemInfoModal}
            showImageInModal={false}
          />
        </div>
        <div className='order__item-column'>
          <div className='order__item--sku-info'>
            <div className='flex'>
              <span>{sku}</span>
              <OrderManagementDetailsItemLabels item={item} />
            </div>
          </div>
          <span className='order__item--style-name'>{styleName}</span>
          <ul className='order__item-tags'>
            <li>
              <Tag value={`x${quantity}`} />
            </li>
            <li>
              <Tag
                label={'Size'}
                value={size}
              />
            </li>
            <li>
              <Tag
                label={'Color'}
                value={colorGroup}
              />
            </li>
            <li>
              <Tag
                label={'Price paid'}
                value={formatDollarAmount(pricePaid)}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default OrderManagementQuickViewItem;
