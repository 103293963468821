const maxOrgColors = 3;

export const validateOrganization = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = ['Organization name is required.'];
  }

  if (!values.lockerManagerEmail) {
    errors.lockerManagerEmail = ['Decision Maker is required.'];
  }

  if (!values.orgType) {
    errors.orgType = ['Organization type is required.'];
  }

  if (values.colors && values.colors.length > maxOrgColors) {
    errors._error = `Maximum number of colors is ${maxOrgColors}`;
  }

  return errors;
};

export const validateOrganizationGroup = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = ['Store group name is required.'];
  }

  return errors;
};
