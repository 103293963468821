import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class EditPersonalizationColorsItem extends PureComponent {
  state = {
    alreadyInList: !!(this.props.colors.find((c) => c === this.props.color.id) || this.props.isDisabled),
  };

  componentDidUpdate(prevProps) {
    const {
      colors,
      color,
      isDisabled,
    } = this.props;

    if (colors !== prevProps.colors || color !== prevProps.color) {
      this.setState(() => ({
        alreadyInList: !!(colors.find((c) => c === color.id) || isDisabled),
      }));
    }
  }

  onClickOnColor = (e) => {
    const {
      color,
      addColor,
    } = this.props;

    const { alreadyInList } = this.state;

    e.preventDefault();
    e.stopPropagation();

    if (!alreadyInList) {
      addColor(color.id);
    }
  };

  render() {
    const { color } = this.props;
    const { alreadyInList } = this.state;

    return (
      <div
        className={`suggestions__row ${alreadyInList ? 'disabled' : ''}`}
        onClick={this.onClickOnColor}
      >
        {color.code}
      </div>
    );
  }
}

EditPersonalizationColorsItem.propTypes = {
  color: PropTypes.object.isRequired,
  colors: PropTypes.arrayOf(PropTypes.number).isRequired,
  addColor: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default EditPersonalizationColorsItem;
