import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { uploadFlyer } from '@APICalls/lockerManager/actions';
import { dragAndDropMessage } from '@constants/values';
import { materialSwal } from '@util/componentHelper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';

class UploadCustomFlyerModal extends PureComponent {
  state = {
    loading: false,
    attachedFile: null,
    uploadFlyerResponse: null,
    message: {
      type: 'default',
      body: dragAndDropMessage(),
    },
  };

  initializeState = () => {
    this.setState({
      loading: false,
      attachedFile: null,
      uploadFlyerResponse: null,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
    });
  };

  onClose = () => {
    const { closeModal } = this.props;

    this.initializeState();
    closeModal();
  };

  destroyFiles = (files) => {
    for (const file of files) {
      window.URL.revokeObjectURL(file.preview);
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      message: {
        body: `File ${acceptedFiles[0].name} has been attached.`,
        type: 'default',
      },
      attachedFile: acceptedFiles,
    }, this.destroyFiles.bind(null, acceptedFiles.concat(rejectedFiles)));
  };

  clearFileFromState = () => {
    this.setState({
      attachedFile: null,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
    });
  };

  uploadFlyer = async () => {
    const { lockerId } = this.props;
    const { attachedFile } = this.state;

    const res = await uploadFlyer(lockerId, attachedFile);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.clearFileFromState();
      this.onClose();
      this.setState({ uploadFlyerResponse: res });
    }
  };

  render() {
    const {
      loading,
      message,
    } = this.state;

    const { isOpen } = this.props;

    const dropzoneContent = loading
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${message.type}`}>{message.body}</label>;

    return (
      <Modal
        title={'Upload a Custom Flyer'}
        modalSize={'xl'}
        isOpen={isOpen}
        closeModal={this.onClose}
        buttons={(
          <ModalButtons
            confirmBtnText={'Upload Flyer'}
            cancelBtnText={'Cancel'}
            onConfirm={this.uploadFlyer}
            onClose={this.onClose}
          />
        )}
      >
        <div className='logo-bank__container'>
          <div className='upload-field '>
            <DropZoneWrapper
              onDrop={this.onDrop}
              className={'block-drop-zone'}
              multiple={false}
            >
              {dropzoneContent}
            </DropZoneWrapper>
          </div>
        </div>
      </Modal>
    );
  }
}

UploadCustomFlyerModal.propTypes = {
  lockerId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default UploadCustomFlyerModal;
