import React from 'react';
import PropTypes from 'prop-types';
import InputSpinner from '@sharedComponents/Inputs/InputSpinner';

const ProductionAssemblyPrintQueueInputSpinner = React.memo(({
  quantity,
  maxQuantity,
  name = '',
  classes = '',
  onChange,
}) => (
  <InputSpinner
    classes={`input-spinner ${classes}`}
    type={'number'}
    name={name}
    onChange={onChange}
    value={quantity}
    min={1}
    max={maxQuantity}
  />
));

ProductionAssemblyPrintQueueInputSpinner.propTypes = {
  quantity: PropTypes.number.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  classes: PropTypes.string,
};

export default ProductionAssemblyPrintQueueInputSpinner;
