import React, { useEffect } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import {
  artworkTaskStatusEnum,
  assignmentStatusEnum,
} from '@constants/enums/artworkEnums';
import { artVectorizationTabUrl } from '@constants/clientUrls/clientUrls';
import { rolesEnum } from '@constants/enums/commonEnums';
import * as artworkActions from '@redux/artwork/actions';
import { TaskCounts } from '@models/ProductionArtwork/TaskCounts';
import { RootState } from '@redux/index/reducers';
import { roleMapping } from '@util/roleCheck';
import ProductionArtworkTab from './ProductionArtworkTab';

const getTabUrl = (tabStatus: (typeof assignmentStatusEnum[keyof typeof assignmentStatusEnum])): string => (`${artVectorizationTabUrl(tabStatus)}/`);

interface ProductionArtworkTab {
  title: string;
  url: string;
  status: string;
  tasksCount?: number;
}

const ProductionArtworkTabsDict: Record<
(typeof assignmentStatusEnum[keyof typeof assignmentStatusEnum]), ProductionArtworkTab> = {
  [assignmentStatusEnum.Unassigned]: {
    title: 'Unassigned',
    url: getTabUrl(assignmentStatusEnum.Unassigned),
    status: artworkTaskStatusEnum.Unassigned.toLowerCase(),
  },
  [assignmentStatusEnum.InProgress]: {
    title: 'In Progress',
    url: getTabUrl(assignmentStatusEnum.InProgress),
    status: artworkTaskStatusEnum.Assigned.toLowerCase(),
  },
  [assignmentStatusEnum.QC]: {
    title: 'Ready for QC',
    url: getTabUrl(assignmentStatusEnum.QC),
    status: artworkTaskStatusEnum.InQC.toLowerCase(),
  },
  [assignmentStatusEnum.Rework]: {
    title: 'Rework',
    url: getTabUrl(assignmentStatusEnum.Rework),
    status: artworkTaskStatusEnum.Rework.toLowerCase(),
  },
  [assignmentStatusEnum.Production]: {
    title: 'Production',
    url: getTabUrl(assignmentStatusEnum.Production),
    status: artworkTaskStatusEnum.Completed.toLowerCase(),
  },
  [assignmentStatusEnum.Priority]: {
    title: 'Priority',
    url: getTabUrl(assignmentStatusEnum.Priority),
    status: artworkTaskStatusEnum.Priority.toLowerCase(),
  },
} as const;

interface OwnProps {
  resetTable: () => void;
}

const mapStateToProps = ({
  artwork,
  oidc,
}: RootState) => ({
  counts: artwork.artworkSummary as TaskCounts,
  roles: roleMapping(oidc),
});

const mapDispatchToProps = {
  fetchCounts: artworkActions.fetchCounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector> & RouteComponentProps;

const ProductionArtworkTabs = React.memo<Props>(({
  counts,
  location,
  roles,
  fetchCounts,
  resetTable,
}) => {
  useEffect(() => {
    fetchCounts();
  }, [fetchCounts]);

  const renderTab = (tab: ProductionArtworkTab) => (
    <ProductionArtworkTab
      key={tab.status}
      title={tab.title}
      url={tab.url}
      taskCount={tab.tasksCount ?? 0}
      status={tab.status}
      currentLocation={location?.pathname}
      resetTable={resetTable}
    />
  );

  const isVendor: boolean = roles.includes(rolesEnum.ProductionArtworkVendor);
  const tabDataArray: ProductionArtworkTab[] = [];

  if (!isVendor) {
    tabDataArray.push({
      ...ProductionArtworkTabsDict[assignmentStatusEnum.Unassigned],
      tasksCount: counts.unassigned,
    });
  }

  tabDataArray.push(
    {
      ...ProductionArtworkTabsDict[assignmentStatusEnum.InProgress],
      tasksCount: counts.assigned,
    },
    {
      ...ProductionArtworkTabsDict[assignmentStatusEnum.QC],
      tasksCount: counts.inQC,
    },
    {
      ...ProductionArtworkTabsDict[assignmentStatusEnum.Rework],
      tasksCount: counts.rework,
    }
  );

  if (!isVendor) {
    tabDataArray.push({
      ...ProductionArtworkTabsDict[assignmentStatusEnum.Production],
      tasksCount: counts.completed,
    });
  }

  tabDataArray.push({
    ...ProductionArtworkTabsDict[assignmentStatusEnum.Priority],
    tasksCount: counts.priority,
  });

  return (
    <ul className='sheet__list-tabs sheet__list-tabs--columns-auto'>
      {tabDataArray.map(renderTab)}
    </ul>
  );
});

export default withRouter(connector(ProductionArtworkTabs));
