import React from 'react';
import PropTypes from 'prop-types';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

const LayoutsActionsColumn = React.memo(({
  layout,
  onLayoutDelete,
  onLayoutEdit,
}) => (
  <ShowMoreActionsButton
    actions={[
      {
        text: 'Edit Layout',
        action: onLayoutEdit.bind(null, layout, false),
        isVisible: true,
      },
      {
        text: 'Delete Layout',
        action: onLayoutDelete.bind(null, layout),
        isDangerous: true,
        isVisible: true,
      },
    ]}
  />
));

LayoutsActionsColumn.propTypes = {
  layout: PropTypes.object.isRequired,
  onLayoutEdit: PropTypes.func.isRequired,
  onLayoutDelete: PropTypes.func.isRequired,
};

export default LayoutsActionsColumn;
