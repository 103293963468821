import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateLogo } from '@APICalls/logos/actions';
import { lockerMgrS3Logos } from '@constants/common';
import {
  decorationMethodEnum,
  colorBrightnessEnum,
  selectionTypeEnum,
} from '@constants/enums/decorationEnums';
import { fileNameFromUrlWithExtension } from '@util/stringHelpers';
import {
  downloadFile,
  materialSwal,
} from '@util/componentHelper';
import { filterAdminColors } from '@util/colorHelper';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import EditLogoColorsModal from '@sharedComponents/UploadLogoSwatchColors/EditLogoColorsModal';
import ReplaceLogo from '@sharedComponents/ReplaceLogo';
import UploadLogoSwatchColors from '@sharedComponents/UploadLogoSwatchColors/UploadLogoSwatchColors';
import LogoBankEditAssignment from '../../LogoBankEditAssignment';
import LogoBankReplaceLogoModal from '../../LogoBankReplaceLogoModal';
import LogoDetails from './LogoDetails';

class DetailedUsedLogo extends PureComponent {
  state = {
    dropdownIsOpen: false,
    openEditAssignment: false,
    openLogoReplacement: false,
    editLogoColorsModalIsOpen: false,
    logoColors: filterAdminColors(this.props.logo.colorSelections || []).map((c) => c.code),
    selectedLogoId: null,
    swatchModalIsOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { logo: { colorSelections } } = this.props;

    if (!prevProps.logo.colorSelections || prevProps.logo.colorSelections !== colorSelections) {
      this.setState(() => ({
        logoColors: filterAdminColors(colorSelections || []).map((c) => c.code),
      }));
    }
  }

  toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { dropdownIsOpen } = this.state;
    this.setState(() => ({ dropdownIsOpen: !dropdownIsOpen }));
  };

  closeDropdown = () => {
    this.setState(() => ({ dropdownIsOpen: false }));
  };

  openLogoImage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      openModalWithImage,
      logo,
    } = this.props;

    openModalWithImage(logo.image);
  };

  assignLogo = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(() => ({ openEditAssignment: true }));
  };

  replaceLogo = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(() => ({ openLogoReplacement: true }));
  };

  toggleLogoApprovedStatus = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      toggleApprovedStatus,
      logo,
    } = this.props;

    toggleApprovedStatus(logo.id, !logo.isApproved);

    this.closeDropdown();
  };

  closeLogoReplacementModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState(() => ({ openLogoReplacement: false }));
  };

  closeEditAssignmentModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState(() => ({ openEditAssignment: false }));
  };

  openEditLogoColorsModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(() => ({ editLogoColorsModalIsOpen: true }));
  };

  closeEditLogoColorsModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState(() => ({ editLogoColorsModalIsOpen: false }));
  };

  openSwatchModal = (selectedLogoId) => {
    this.setState(() => ({
      selectedLogoId,
      swatchModalIsOpen: true,
    }));
  };

  closeSwatchModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState(() => ({
      swatchModalIsOpen: false,
      selectedLogoId: null,
    }));
  };

  openLogoDetailsModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      logo,
      openLogoDetailsModal,
    } = this.props;

    openLogoDetailsModal(logo);
  };

  updateLogo = async (newColors, selectedMethods, floodColorIds) => {
    const {
      logo,
      colors,
      refreshLogos,
    } = this.props;

    const customColors = [];
    const colorIds = [];

    if (newColors && colors && colors.length > 0) {
      for (const newColor of newColors) {
        const selectedColor = colors.find((c) => c.code === newColor);
        if (selectedColor) {
          colorIds.push(selectedColor.id);
        } else {
          customColors.push(newColor);
        }
      }

      const res = await updateLogo(
        logo.id,
        selectionTypeEnum.UserOnly,
        colorIds,
        customColors,
        null,
        selectedMethods,
        floodColorIds
      );

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        this.closeEditLogoColorsModal();
        refreshLogos();
      }
    }
  };

  requeueForLogoReview = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      logo,
      requeueForLogoReview,
    } = this.props;

    requeueForLogoReview(logo);
  };

  render() {
    const {
      logo,
      lockerId,
      enableMultipleLogos,
      colors,
      artworkColors,
      allLightColors,
      allDarkColors,
      refreshLogos,
    } = this.props;

    const {
      openEditAssignment,
      dropdownIsOpen,
      openLogoReplacement,
      editLogoColorsModalIsOpen,
      logoColors,
      selectedLogoId,
      swatchModalIsOpen,
    } = this.state;

    let decorations = [];

    if (logo.decorationMethods) {
      decorations = logo.decorationMethods.map((method, index) => (
        <div
          key={index}
          className='tooltip-container'
        >
          <span className='logo-bank__used--decoration'>{method}</span>
          <div className='tooltip-text'>
            {method !== decorationMethodEnum.NA
              && method !== decorationMethodEnum.Undefined
              && `Logo can be applied using the ${method} decoration method`}
          </div>
        </div>
      ));
    }

    const image = `${lockerMgrS3Logos}/${logo.image}`;
    const approvedLightColors = logo.floodColors.filter((c) => c.brightness === colorBrightnessEnum.Light);
    const approvedDarkColors = logo.floodColors.filter((c) => c.brightness === colorBrightnessEnum.Dark);

    return (
      <div className='logo-bank__used--logo-container'>
        <UploadLogoSwatchColors
          logoId={selectedLogoId}
          cancel={this.closeSwatchModal}
          isModalOpen={swatchModalIsOpen}
          refreshLogos={refreshLogos}
        />
        <LogoBankEditAssignment
          logoId={logo.id}
          modalIsOpen={openEditAssignment}
          closeModal={this.closeEditAssignmentModal}
          logoImage={logo.image}
          lockerId={lockerId}
        />
        {
          openLogoReplacement &&
          <LogoBankReplaceLogoModal
            isOpen={openLogoReplacement}
            logo={logo}
            closeModal={this.closeLogoReplacementModal}
            lockerId={lockerId}
          />
        }
        <EditLogoColorsModal
          isOpen={editLogoColorsModalIsOpen}
          logoColors={logoColors}
          cancel={this.closeEditLogoColorsModal}
          logoId={logo && logo.id}
          logoUrl={image}
          logoName={logo && logo.image}
          saveLogoColors={this.updateLogo}
          decorationMethod={logo.decorationMethods}
          colors={colors}
          artworkColors={artworkColors}
          allDecorationsActive={false}
          approvedLightColors={approvedLightColors}
          approvedDarkColors={approvedDarkColors}
          allLightColors={allLightColors}
          allDarkColors={allDarkColors}
          refreshLogos={refreshLogos}
        />
        <div className='logo-bank__used--logo on-hover__container'>
          <div
            onClick={this.openLogoImage}
            type='button'
            className='logo-bank__used--logo--image'
            style={{ backgroundImage: `url("${image}")` }}
          >
            {
              decorations.length > 0 &&
              <div className='logo-bank__used--decorations'>{decorations}</div>
            }

            {
              !logo.isApproved &&
              <div className='logo-bank__used--logo--unapproved-label-container'>
                <span className='logo-bank__used--logo--unapproved-label'>
                  <span className='material-icons logo-bank__used--logo--unapproved-label__icon'>warning</span>Unapproved
                </span>
              </div>
            }

            <OutsideClickWrapper onClick={this.closeDropdown}>
              <div className='lockerManager__action-button right--1'>
                <i
                  onClick={this.toggleDropdown}
                  className='button material-icons'
                >
                  more_vert
                </i>
                {
                  dropdownIsOpen &&
                  <ul className='lockerManager__item-list is-active right-2'>
                    {
                      enableMultipleLogos &&
                      <li>
                        <button
                          onClick={this.assignLogo}
                          className='button'
                        >
                          Edit Logo Assignment
                        </button>
                        <li className='lockerManager__item-list--border' />
                      </li>
                    }
                    {
                      logo.countUsedOnProd > 0 &&
                      <li>
                        <button
                          onClick={this.replaceLogo}
                          className='button'
                        >
                          Change Logo on Items
                        </button>
                      </li>
                    }
                    <li className='lockerManager__item-list--border' />
                    <li>
                      <button
                        onClick={this.toggleLogoApprovedStatus}
                        className='button'
                      >
                        {logo.isApproved ? 'Unapprove Logo' : 'Approve Logo'}
                      </button>
                    </li>
                    <li className='lockerManager__item-list--border' />
                    <li>
                      <button
                        onClick={this.openEditLogoColorsModal}
                        className='logo-bank__modal-item button'
                      >
                        Edit Logo
                      </button>
                    </li>
                    <li className='lockerManager__item-list--border' />
                    <li>
                      <ReplaceLogo
                        logoId={logo.id}
                        logoOrderCount={logo.countOrderedProd}
                        refreshLogos={refreshLogos}
                        openSwatchModal={this.openSwatchModal}
                      />
                    </li>
                    <li className='lockerManager__item-list--border' />
                    <li>
                      <button
                        onClick={this.openLogoDetailsModal}
                        className='logo-bank__modal-item button'
                      >
                        Logo Details
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={downloadFile(image, logo.image)}
                        className='button'
                      >
                        Download Logo
                      </button>
                    </li>
                    {
                      (!logo.needsReview || logo.reviewedAt) &&
                      <li>
                        <button
                          onClick={this.requeueForLogoReview}
                          className='logo-bank__modal-item button'
                        >
                          Requeue for Logo Review
                        </button>
                      </li>
                    }
                  </ul>
                }
              </div>
            </OutsideClickWrapper>
          </div>
          <span className='logo-bank__used--title'>{fileNameFromUrlWithExtension(logo.image)}</span>
        </div>
        <LogoDetails
          logo={logo}
          colors={colors}
        />
      </div>
    );
  }
}

DetailedUsedLogo.propTypes = {
  logo: PropTypes.object.isRequired,
  replaceLogoModal: PropTypes.func.isRequired,
  lockerId: PropTypes.number.isRequired,
  openModalWithImage: PropTypes.func.isRequired,
  toggleApprovedStatus: PropTypes.func.isRequired,
  enableMultipleLogos: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  artworkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  refreshLogos: PropTypes.func.isRequired,
  openLogoDetailsModal: PropTypes.func.isRequired,
  requeueForLogoReview: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  lockerManager,
  support,
}) => ({
  enableMultipleLogos: lockerManager.lockerInfo.enableMultipleLogos,
  colors: support.colors,
  artworkColors: support.artworkColors,
  allLightColors: support.lightColors,
  allDarkColors: support.darkColors,
});

export default connect(mapStateToProps)(DetailedUsedLogo);
