import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ItemInfo from './ItemInfo';

class ItemInfoModal extends PureComponent {
  render() {
    const {
      isOpen,
      closeModal,
      sku,
      images,
      coloredStyleItemId,
      lockerItemId,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title={'Item Details'}
        modalHeight={'xl'}
        modalWidth={'xl'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            cancelBtnText={'Close'}
            displayConfirmBtn={false}
          />
        )}
      >
        {
          (sku || coloredStyleItemId) &&
          <ItemInfo
            sku={sku}
            coloredStyleItemId={coloredStyleItemId}
            lockerItemId={lockerItemId}
            images={images}
          />
        }
      </Modal>
    );
  }
}

ItemInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  sku: PropTypes.string,
  lockerItemId: PropTypes.number,
  coloredStyleItemId: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string),
};

export default ItemInfoModal;
