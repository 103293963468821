/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  ProductDetailDto,
  ApiResponse,
  UpdateProductCommand,
  ProductInventoryDto
} from './models'
import { catalogApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiProductsId = <TData = ProductDetailDto>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/products/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductsIdQueryKey = (id: number,) => [`/homefield/api/products/${id}`]

    
export const useGetHomefieldApiProductsId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductsId, ProductDetailDto>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductsIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductsId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiProductsSku = <TData = ProductDetailDto>(
    sku: string,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/products/${sku}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductsSkuQueryKey = (sku: string,) => [`/homefield/api/products/${sku}`]

    
export const useGetHomefieldApiProductsSku = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductsSku, ProductDetailDto>, TError = ApiResponse, TData = TQueryFnData>(
 sku: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductsSkuQueryKey(sku);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductsSku<TQueryFnData>(sku, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiProductsSku = <TData = ApiResponse>(
    sku: string,
    updateProductCommand: UpdateProductCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/products/${sku}`, method: 'put',
      data: updateProductCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductsSku = <TData = AsyncReturnType<typeof putHomefieldApiProductsSku,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{sku: string;data: UpdateProductCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {sku: string;data: UpdateProductCommand}, TContext>((props) => {
        const {sku,data} = props || {};

        return  putHomefieldApiProductsSku<TData>(sku,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiProductsSku = <TData = ApiResponse>(
    sku: string,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/products/${sku}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiProductsSku = <TData = AsyncReturnType<typeof deleteHomefieldApiProductsSku,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{sku: string}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {sku: string}, TContext>((props) => {
        const {sku} = props || {};

        return  deleteHomefieldApiProductsSku<TData>(sku,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiProductsSkuInventory = <TData = ProductInventoryDto>(
    sku: string,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/products/${sku}/inventory`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductsSkuInventoryQueryKey = (sku: string,) => [`/homefield/api/products/${sku}/inventory`]

    
export const useGetHomefieldApiProductsSkuInventory = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductsSkuInventory, ProductInventoryDto>, TError = unknown, TData = TQueryFnData>(
 sku: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductsSkuInventoryQueryKey(sku);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductsSkuInventory<TQueryFnData>(sku, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

