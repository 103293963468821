import React, { useCallback } from 'react';
import { ProgressBar } from 'react-bootstrap';
import {
  OrderWarehouseStatusEnum,
  BatchWarehouseOrderDto,
  OrderItemStatusEnum,
} from '@api/fulfillment/models';
import { useGetHomefieldApiWarehouseschedulingOrdersOrderNumber } from '@api/fulfillment/warehouse-scheduling';
import { decorationMethodDisplayNames } from '@constants/common';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import Button from '@sharedComponents/Buttons/Button';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';
import Label from '@sharedComponents/Display/Label';
import Link from '@sharedComponents/Navigation/Link';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import BatchWarehouseSchedulingDetailsItem from './BatchWarehouseSchedulingDetailsItem';

type OwnProps = {
  order: BatchWarehouseOrderDto;
  onClose: () => void;
  onDispatch: (order: BatchWarehouseOrderDto) => void;
};

type Props = OwnProps;

const Header = TableQuickViewHeader as React.FC<{ title: any; onClose: any; }>;

export const BatchWarehouseSchedulingDetails = React.memo<Props>(({
  order,
  onClose,
  onDispatch,
}) => {
  const { data: orderItems } = useGetHomefieldApiWarehouseschedulingOrdersOrderNumber(order.orderNumber!,
    { query: { enabled: true } });

  const handleDispatch = useCallback(() => onDispatch(order), [
    order,
    onDispatch,
  ]);

  const allItems = (orderItems?.items ?? []);

  const schedulingItems = allItems.filter((item) => item.status === OrderItemStatusEnum.AwaitingDispatchForPicking);
  const secondaryItems = allItems.filter((item) => item.status !== OrderItemStatusEnum.AwaitingDispatchForPicking);

  const willNeedDispatchCount = (order.readyItemCount || 0) + (order.notReadyItemCount || 0);
  const anyAvailable = orderItems?.items?.some(
    (item) => item?.status === OrderItemStatusEnum.OnHold && !!item?.isInventoryAvailable
  );

  return (
    <TableQuickViewContainer
      noPadding={true}
      size={TableQuickViewSizeEnum.Medium}
      classes={'scheduling__details'}
    >
      <>
        <Header
          title={(
            <>
              <span>
                <Link
                  openInNewTab={true}
                  url={orderDetailsUrl(order.orderNumber!)}
                  text={`Order O${order.orderNumber}`}
                />
              </span>
              {
                order.embDecorationCount! > 0 &&
                <Label
                  text={'EMB'}
                  type={'lynch'}
                />
              }
              {
                order.hagDecorationCount! > 0 &&
                <Label
                  text={'HAG'}
                  type={'lynch'}
                />
              }
            </>
          )}
          onClose={onClose}
        />
        <div className={'tableView__details--content'}>
          {
            !!schedulingItems.length &&
            <div className='scheduling__details--content__items'>
              {
                schedulingItems.map((item) => (
                  <BatchWarehouseSchedulingDetailsItem
                    item={item}
                    key={item.id}
                  />
                ))
              }
              {
                secondaryItems.map((item) => (
                  <BatchWarehouseSchedulingDetailsItem
                    item={item}
                    key={item.id}
                    isSecondary={true}
                  />
                ))
              }
            </div>
          }
          <div className='tableView__details--footer'>
            <div className='scheduling__progress scheduling__progress--available'>
              <div className=' mr-15'>
                {order.readyItemCount || 0}/{willNeedDispatchCount}
              </div>
              <ProgressBar
                className='scheduling__progress-bar big'
                variant={
                  willNeedDispatchCount !== 0 && order.readyItemCount === willNeedDispatchCount
                    ? 'success'
                    : 'warning'
                }
                now={(willNeedDispatchCount || 0) <= 0
                  ? 0
                  : (order.readyItemCount ?? 0) / (willNeedDispatchCount || 1) * 100}
              />
            </div>
            {
              anyAvailable &&
              <Button
                type={'primary'}
                text={'Disptach Available Items'}
                onClick={handleDispatch}
                disabled={!order?.readyItemCount}
              />
            }
          </div>
        </div>
      </>
    </TableQuickViewContainer>
  );
});

export default BatchWarehouseSchedulingDetails;
