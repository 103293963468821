import React from 'react';
import {
  DtgEnvironmentsLink,
  DtgArtworkLink,
  DtgPersonalizationLink,
  DtgStationsLink,
  DtgTabletProfilesLink,
} from '../MyApps/DtgEntitiesLink';

const DtgEntities = React.memo(() => (
  <div className='my-apps-tray w-100' >
    <DtgEnvironmentsLink />
    <DtgArtworkLink />
    <DtgPersonalizationLink />
    <DtgStationsLink />
    <DtgTabletProfilesLink />
  </div>
));

export default DtgEntities;
