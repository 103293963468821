import React from 'react';

interface OwnProps {
  title: string;
  subtitle?: string;
  imageUrl?: string;
  classes?: string;
  labels?: Nullable<JSX.Element>;
}

type Props = OwnProps;

const Card = React.memo(({
  title,
  subtitle = '',
  imageUrl = '',
  classes = '',
  labels = null,
}: Props) => (
  <div className={`organization__locker-card ${classes} ${subtitle ? 'with-subtitle' : ''}`}>
    {
      labels &&
      <div className='organization__locker-card--labels'>
        {labels}
      </div>
    }
    <div className='organization__locker-card--logo'>
      <div
        className='details__item-image'
        style={{ backgroundImage: `url("${imageUrl}")` }}
      />
    </div>
    <div className='organization__locker-card--text'>
      <div className='title'>{title}</div>
      {
        subtitle &&
        <div className='subtitle'>{subtitle}</div>
      }
    </div>
  </div>
));

export default Card;
