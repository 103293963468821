import React from 'react';

interface OwnProps {
  status: string;
  statusLabel: string;
}

type Props = OwnProps;

const ArtworkTaskDetailsStatus = React.memo<Props>(({
  status,
  statusLabel,
}) => (
  <span className={`task__status--${status ? status.toLowerCase() : ''}`}>{statusLabel}</span>
));

export default ArtworkTaskDetailsStatus;
