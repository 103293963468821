import React from 'react';
import iconBlocks from '@assets/icon_blocks.svg';
import { orderAssemblyUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from '@components/MyApps/MenuTile';

const OrderAssemblyLink = React.memo(() => (
  <MenuTile
    title={'Order Assembly'}
    url={orderAssemblyUrl}
    icon={iconBlocks}
    isCustomIcon={true}
  />
));

export default OrderAssemblyLink;
