/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  ProductAgeEnum,
  ProductGenderEnum,
  GetHomefieldApiSizesParams,
  StringApiResponse,
  ApiResponse,
  PostHomefieldApiFilesuploadBody
} from './models'
import { catalogApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiColorgroups = <TData = string[]>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/color-groups`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiColorgroupsQueryKey = () => [`/homefield/api/color-groups`]

    
export const useGetHomefieldApiColorgroups = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiColorgroups, string[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiColorgroupsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiColorgroups<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiAges = <TData = ProductAgeEnum[]>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/ages`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiAgesQueryKey = () => [`/homefield/api/ages`]

    
export const useGetHomefieldApiAges = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiAges, ProductAgeEnum[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiAgesQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiAges<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiGenders = <TData = ProductGenderEnum[]>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/genders`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiGendersQueryKey = () => [`/homefield/api/genders`]

    
export const useGetHomefieldApiGenders = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiGenders, ProductGenderEnum[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiGendersQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiGenders<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiSizes = <TData = string[]>(
    params?: GetHomefieldApiSizesParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/sizes`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiSizesQueryKey = (params?: GetHomefieldApiSizesParams,) => [`/homefield/api/sizes`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiSizes = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiSizes, string[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiSizesParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiSizesQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiSizes<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiFilesupload = <TData = StringApiResponse>(
    postHomefieldApiFilesuploadBody: PostHomefieldApiFilesuploadBody,
 options?: SecondParameter<typeof catalogApi>) => {const formData = new FormData();
if(postHomefieldApiFilesuploadBody.file) {
 formData.append('file', postHomefieldApiFilesuploadBody.file)
 }

      return catalogApi<TData>(
      {url: `/homefield/api/files.upload`, method: 'post',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiFilesupload = <TData = AsyncReturnType<typeof postHomefieldApiFilesupload,StringApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PostHomefieldApiFilesuploadBody}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PostHomefieldApiFilesuploadBody}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiFilesupload<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiVersion = <TData = unknown>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/version`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVersionQueryKey = () => [`/homefield/api/version`]

    
export const useGetHomefieldApiVersion = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVersion, unknown>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVersionQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVersion<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

