import React from 'react';
import PropTypes from 'prop-types';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import Icon from '@sharedComponents/Icons/Icon';

const DecorationMethods = React.memo(({
  allowedDecorationMethods,
  onChangeDecorationMethods,
  needsArtworkEdits,
}) => {
  const activeBtnClass = 'logo-review__task__options-toggle-btn--active';

  return (
    <div>
      <div className='logo-review__task__options-title mb-20'>
        Decoration Methods
      </div>
      <div>
        <button
          onClick={onChangeDecorationMethods.bind(null, decorationMethodEnum.EMB)}
          className={`logo-review__task__options-toggle-btn ${allowedDecorationMethods.EMB ? activeBtnClass : ''}`}
          disabled={needsArtworkEdits}
        >
          {decorationMethodEnum.EMB}
        </button>

        <button
          onClick={onChangeDecorationMethods.bind(null, decorationMethodEnum.HAG)}
          className={`logo-review__task__options-toggle-btn ${allowedDecorationMethods.HAG ? activeBtnClass : ''}`}
          disabled={needsArtworkEdits}
        >
          {decorationMethodEnum.HAG}
        </button>
      </div>

      <div>
        <button
          onClick={onChangeDecorationMethods.bind(null, decorationMethodEnum.DTG)}
          className={`logo-review__task__options-toggle-btn ${allowedDecorationMethods.DTG ? activeBtnClass : ''}`}
          disabled={needsArtworkEdits}
        >
          {decorationMethodEnum.DTG}
        </button>
        <button
          onClick={onChangeDecorationMethods.bind(null, decorationMethodEnum.DIP)}
          className={`logo-review__task__options-toggle-btn ${allowedDecorationMethods.DIP ? activeBtnClass : ''}`}
          disabled={needsArtworkEdits}
        >
          {decorationMethodEnum.DIP}
        </button>
      </div>

      {
        needsArtworkEdits &&
        <div className='logo-review__task__options__warning'>
          <Icon materialIcon={'warning'} />
          <div className='logo-review__task__options__warning--text'>
            <div>Warning: Logo will automatically be set to unapproved status.</div>
            <div>Unable to apply any decoration method.</div>
          </div>
        </div>
      }
    </div>
  );
});

DecorationMethods.propTypes = {
  allowedDecorationMethods: PropTypes.shape({
    HAG: PropTypes.bool,
    EMB: PropTypes.bool,
    DTG: PropTypes.bool,
    DIP: PropTypes.bool,
  }).isRequired,
  onChangeDecorationMethods: PropTypes.func.isRequired,
  needsArtworkEdits: PropTypes.bool.isRequired,
};

export default DecorationMethods;
