import React, {
  useEffect,
  useCallback,
} from 'react';
import {
  useForm,
  UseFormReturn,
  useFormState,
} from 'react-hook-form';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import {
  VoucherOrderDetailDto,
  VoucherOrderPaymentStatusEnum,
} from '@api/financialServices/models';
import { useSimpleConfirmationModal } from '@hooks/shared/Modal/useSimpleConfirmationModal';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { cancelVoucherOrderWithRefundForm } from '@constants/reduxForms';
import HookFormRadioGroup from '@components/shared/Form/HookFormRadioGroup';
import { formatDollarAmount } from '@util/numberHelpers';
import FormInput from '@sharedComponents/Form/FormInput';
import { VoucherRefundTypeEnum } from '@constants/enums/voucherEnums';

export interface VoucherOrderRefundForm {
  fullAmount: number;
  partialAmount: number;
  refundType: number;
}

interface OwnProps {
  isOpen: boolean;
  voucherOrder: VoucherOrderDetailDto | null;
  closeModal: () => void;
  onSubmit: (form: VoucherOrderRefundForm) => Promise<void>;
}

const schema = yup.object({
  refundType: yup.number(),
  fullAmount: yup.number().moreThan(0)
    .required(),
  partialAmount: yup.number()
    .when('refundType', {
      is: VoucherRefundTypeEnum.PartialRefund,
      then: (amount) => amount.moreThan(0)
        .lessThan(yup.ref('fullAmount'))
        .required('Partial amount is required')
        .typeError('Amount must be a number'),
      otherwise: (amount) => amount.nullable(true)
        .transform((_, val) => val === Number(val) ? val : null),
    }),
});

type Props = OwnProps;

const VoucherOrderRefundModal = React.memo<Props>(({
  isOpen,
  voucherOrder,
  closeModal,
  onSubmit,
}) => {
  const {
    openModal: openSendVoucherOrderRefundReceiptModal,
    closeModal: closeSendVoucherOrderRefundReceiptModal,
    isOpen: sendVoucherOrderRefundReceiptModalIsOpen,
    ConfirmationModal: VoucherOrderRefundConfirmationModal,
  } = useSimpleConfirmationModal();

  const totalAmountToRefund = voucherOrder?.totalAmountLeftForRefund ?? 0;

  const formMethods: UseFormReturn<VoucherOrderRefundForm> = useForm<VoucherOrderRefundForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      refundType: VoucherRefundTypeEnum.FullRefund,
      fullAmount: totalAmountToRefund,
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    trigger,
    control,
  } = formMethods;

  const refundType = watch('refundType');
  const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    if (refundType === VoucherRefundTypeEnum.FullRefund && dirtyFields.partialAmount) {
      trigger();
    }
  }, [
    trigger,
    refundType,
    dirtyFields,
  ]);

  const onConfirm = useCallback(() => {
    closeSendVoucherOrderRefundReceiptModal();
    closeModal();
    handleSubmit(onSubmit)();
  }, [
    closeSendVoucherOrderRefundReceiptModal,
    closeModal,
    handleSubmit,
    onSubmit,
  ]);

  const getModalBody = (): JSX.Element | null => {
    if (voucherOrder!.paymentStatus === VoucherOrderPaymentStatusEnum.Paid) {
      return (
        <div className='p-10'>
          <div>
            <span>
            Are you sure you want to refund voucher order <b>#V{voucherOrder?.id}</b>?
            This action cannot be undone.
            </span>
          </div>
          <HookFormRadioGroup<VoucherOrderRefundForm, VoucherOrderRefundForm['refundType']>
            name={'refundType'}
            register={register}
            change={setValue}
            getValue={getValues}
            groupClassName={'radio__group radio__gray-02 m__w--540 mt-10'}
            radioItems={[
              {
                value: VoucherRefundTypeEnum.FullRefund,
                label: `Full Refund (${formatDollarAmount(totalAmountToRefund)})`,
              },
              {
                value: VoucherRefundTypeEnum.PartialRefund,
                className: 'with-input mt-20',
                rootClassName: 'with-input__radio',
                hasInput: true,
                disabled: false,
                label: '',
                inputNode: (
                  <div className='with-input__label'>
                    <div className='text-field'>
                      <FormInput
                        id={'partialAmount'}
                        placeholder={'Partial Refund'}
                        type={'text'}
                        initialValue={undefined}
                        error={errors.partialAmount}
                        disabled={refundType !== VoucherRefundTypeEnum.PartialRefund}
                        register={register}
                        {...register('partialAmount')}
                      />
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      );
    }

    return null;
  };

  if (!voucherOrder) {
    return null;
  }

  return (
    <>
      <VoucherOrderRefundConfirmationModal
        isOpen={sendVoucherOrderRefundReceiptModalIsOpen}
        closeModal={closeSendVoucherOrderRefundReceiptModal}
        title={'Confirmation'}
        cancelBtnText={'Cancel'}
        confirm={onConfirm}
        confirmationBody={<span>A refund confirmation email will be sent to the admin.</span>}
      />
      <Modal
        title={'Refund Voucher Order'}
        isOpen={isOpen}
        closeModal={closeModal}
        modalHeight={'l'}
        modalWidth={'s'}
        buttons={(
          <ModalButtons
            confirmBtnText={'Confirm'}
            cancelBtnText={'Cancel'}
            onClose={closeModal}
            formSubmission={true}
            formId={cancelVoucherOrderWithRefundForm}
            isDangerousAction={true}
          />
        )}
      >
        <form
          id={cancelVoucherOrderWithRefundForm}
          onSubmit={handleSubmit(openSendVoucherOrderRefundReceiptModal)}
        >
          {getModalBody()}
        </form>
      </Modal>
    </>
  );
});

export default VoucherOrderRefundModal;
