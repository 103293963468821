import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stopSubmit } from 'redux-form';
import { editStyleForm } from '@constants/reduxForms';
import Modal from '@sharedComponents/Modal/Modal';
import StyleForm from '../../StyleForm/StyleForm';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class EditStyleModal extends PureComponent {
  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(editStyleForm, {}));
  };

  render() {
    const {
      modalIsOpen,
      initialValues,
      sampleImage,
      onSubmit,
    } = this.props;

    return (
      <Modal
        title={'Edit Style'}
        modalSize={'xl'}
        isOpen={modalIsOpen}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            onClose={this.handleCloseModal}
            formSubmission={true}
            confirmBtnText={'Save'}
            formId={editStyleForm}
          />
        )}
      >
        {
          modalIsOpen &&
          <div className='catalog-details__modal--container'>
            <StyleForm
              onSubmit={onSubmit}
              sampleImage={sampleImage}
              initialValues={initialValues}
            />
          </div>
        }
      </Modal>
    );
  }
}

EditStyleModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  modalStyle: PropTypes.object,
  sampleImage: PropTypes.string,
};

export default connect()(EditStyleModal);
