/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import type {
  ReportStoreInfoCollectionDto,
  ReportsInStoreInfo,
  ApiResponse
} from './models'
import { reportEngineApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiMetaStores = <TData = ReportStoreInfoCollectionDto>(
    
 options?: SecondParameter<typeof reportEngineApi>) => {
      return reportEngineApi<TData>(
      {url: `/homefield/api/meta/stores`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiMetaStoresQueryKey = () => [`/homefield/api/meta/stores`]

    
export const useGetHomefieldApiMetaStores = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiMetaStores, ReportStoreInfoCollectionDto>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof reportEngineApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiMetaStoresQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiMetaStores<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiMetaStoresStoreName = <TData = ReportsInStoreInfo>(
    storeName: string,
 options?: SecondParameter<typeof reportEngineApi>) => {
      return reportEngineApi<TData>(
      {url: `/homefield/api/meta/stores/${storeName}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiMetaStoresStoreNameQueryKey = (storeName: string,) => [`/homefield/api/meta/stores/${storeName}`]

    
export const useGetHomefieldApiMetaStoresStoreName = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiMetaStoresStoreName, ReportsInStoreInfo>, TError = ApiResponse, TData = TQueryFnData>(
 storeName: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof reportEngineApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiMetaStoresStoreNameQueryKey(storeName);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiMetaStoresStoreName<TQueryFnData>(storeName, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

