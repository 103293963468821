import React, { useCallback } from 'react';

interface OwnProps {
  text: string | JSX.Element;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  classes?: string;
  icon?: Nullable<JSX.Element>;
  disabled?: boolean;
}

type Props = OwnProps;

const LinkButton = React.memo(({
  text,
  onClick,
  classes = '',
  icon = null,
  disabled = false,
}: Props) => {
  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) return;

    onClick(e);
  }, [
    onClick,
    disabled,
  ]);

  return (
    <a
      className={`link ${classes}`}
      onClick={handleClick}
    >
      {icon} {text}
    </a>
  );
});

export default LinkButton;
