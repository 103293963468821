/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  WarehouseOrderPagedListDtoPagedList,
  GetHomefieldApiWarehouseschedulingOrdersParams,
  WarehouseOrderDto,
  ApiResponse,
  MoveOrdersAvailableInventoryItemsToDispatchCommand,
  MoveOrdersDispatchItemsToInPickingCommand,
  BatchWarehouseOrderGroupsDto,
  GetHomefieldApiWarehouseschedulingOrdergroupsParams,
  BatchWarehouseOrderDtoPagedList,
  GetHomefieldApiWarehouseschedulingOrdergroupsListParams
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiWarehouseschedulingOrders = <TData = WarehouseOrderPagedListDtoPagedList>(
    params?: GetHomefieldApiWarehouseschedulingOrdersParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/warehouse-scheduling/orders`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiWarehouseschedulingOrdersQueryKey = (params?: GetHomefieldApiWarehouseschedulingOrdersParams,) => [`/homefield/api/warehouse-scheduling/orders`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiWarehouseschedulingOrders = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiWarehouseschedulingOrders, WarehouseOrderPagedListDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiWarehouseschedulingOrdersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiWarehouseschedulingOrdersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiWarehouseschedulingOrders<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiWarehouseschedulingOrdersOrderNumber = <TData = WarehouseOrderDto>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/warehouse-scheduling/orders/${orderNumber}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiWarehouseschedulingOrdersOrderNumberQueryKey = (orderNumber: number,) => [`/homefield/api/warehouse-scheduling/orders/${orderNumber}`]

    
export const useGetHomefieldApiWarehouseschedulingOrdersOrderNumber = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiWarehouseschedulingOrdersOrderNumber, WarehouseOrderDto>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiWarehouseschedulingOrdersOrderNumberQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiWarehouseschedulingOrdersOrderNumber<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiWarehouseschedulingOrdersmovetodispatch = <TData = ApiResponse>(
    moveOrdersAvailableInventoryItemsToDispatchCommand: MoveOrdersAvailableInventoryItemsToDispatchCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/warehouse-scheduling/orders.move-to-dispatch`, method: 'put',
      data: moveOrdersAvailableInventoryItemsToDispatchCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiWarehouseschedulingOrdersmovetodispatch = <TData = AsyncReturnType<typeof putHomefieldApiWarehouseschedulingOrdersmovetodispatch,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: MoveOrdersAvailableInventoryItemsToDispatchCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: MoveOrdersAvailableInventoryItemsToDispatchCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiWarehouseschedulingOrdersmovetodispatch<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiWarehouseschedulingOrdersmovetopicking = <TData = ApiResponse>(
    moveOrdersDispatchItemsToInPickingCommand: MoveOrdersDispatchItemsToInPickingCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/warehouse-scheduling/orders.move-to-picking`, method: 'put',
      data: moveOrdersDispatchItemsToInPickingCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiWarehouseschedulingOrdersmovetopicking = <TData = AsyncReturnType<typeof putHomefieldApiWarehouseschedulingOrdersmovetopicking,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: MoveOrdersDispatchItemsToInPickingCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: MoveOrdersDispatchItemsToInPickingCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiWarehouseschedulingOrdersmovetopicking<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiWarehouseschedulingOrdergroups = <TData = BatchWarehouseOrderGroupsDto>(
    params?: GetHomefieldApiWarehouseschedulingOrdergroupsParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/warehouse-scheduling/order-groups`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiWarehouseschedulingOrdergroupsQueryKey = (params?: GetHomefieldApiWarehouseschedulingOrdergroupsParams,) => [`/homefield/api/warehouse-scheduling/order-groups`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiWarehouseschedulingOrdergroups = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiWarehouseschedulingOrdergroups, BatchWarehouseOrderGroupsDto>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiWarehouseschedulingOrdergroupsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiWarehouseschedulingOrdergroupsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiWarehouseschedulingOrdergroups<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiWarehouseschedulingOrdergroupsList = <TData = BatchWarehouseOrderDtoPagedList>(
    params?: GetHomefieldApiWarehouseschedulingOrdergroupsListParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/warehouse-scheduling/order-groups/list`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiWarehouseschedulingOrdergroupsListQueryKey = (params?: GetHomefieldApiWarehouseschedulingOrdergroupsListParams,) => [`/homefield/api/warehouse-scheduling/order-groups/list`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiWarehouseschedulingOrdergroupsList = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiWarehouseschedulingOrdergroupsList, BatchWarehouseOrderDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiWarehouseschedulingOrdergroupsListParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiWarehouseschedulingOrdergroupsListQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiWarehouseschedulingOrdergroupsList<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

