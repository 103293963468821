import React from 'react';
import Tag from '@sharedComponents/Display/Tag';
import RefundPrice from '@sharedComponents/Display/RefundPrice';
import Icon from '@sharedComponents/Icons/Icon';
import { OrderItemPricingDetails } from '@api/orders/models';

interface OwnProps {
  item: {
    pricePaid?: number;
  };
  openFinancialsModal?: () => void;
  itemPricingDetails?: OrderItemPricingDetails;
  orderExistsOnCore: boolean;
}

type Props = OwnProps;

const PricePaidTag = React.memo<Props>(({
  item,
  openFinancialsModal,
  itemPricingDetails,
  orderExistsOnCore,
}) => (
  <Tag
    label={'Price paid'}
    classes={'button'}
    onClick={openFinancialsModal}
    value={(
      <div className='flex'>
        <RefundPrice
          paid={orderExistsOnCore
            ? itemPricingDetails?.pricePaid ?? 0
            : item.pricePaid ?? 0}
        />
        <Icon
          materialIcon={'info_outline'}
          classes='order-financial-details__info-icon'
        />
      </div>
    )}
  />
));

export default PricePaidTag;
