import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';

const ReviewedButton = React.memo(({
  reviewed,
  onReviewBtnClick,
}) => {
  const activeClass = reviewed ? 'logo-review__task__footer__btn--reviewed--active' : '';

  return (
    <button
      className={`logo-review__task__footer__btn--reviewed ${activeClass}`}
      onClick={onReviewBtnClick}
    >
      {
        reviewed
          ? <Icon materialIcon={'check_box'} />
          : <Icon materialIcon={'check_box_outline_blank'} />
      }
      Reviewed
    </button>
  );
});

ReviewedButton.propTypes = {
  reviewed: PropTypes.bool.isRequired,
  onReviewBtnClick: PropTypes.func.isRequired,
};

export default ReviewedButton;
