import React from 'react';
import PropTypes from 'prop-types';
import ProductCatalogColoredStyleItem from './ProductCatalogColoredStyleItem';

const ProductCatalogColoredStylesList = React.memo(({ items }) => {
  const components = items.map((item) => (
    <ProductCatalogColoredStyleItem
      key={item.code}
      code={item.code}
      colorGroup={item.colorGroup}
      colorA={item.colorA}
      colorB={item.colorB}
      floodColorId={item.floodColorId}
      imageUrl={item.imageUrlFront}
      disabled={item.disabled}
      discontinued={item.discontinued}
    />
  ));

  return (
    <div className='custom-scrollbar'>
      {
        components.length > 0
          ? components
          : (
            <div className='info__field'>
              <div className='info__value'>No colored styles</div>
            </div>
          )
      }
    </div>
  );
});

ProductCatalogColoredStylesList.propTypes = {
  items: PropTypes.array,
};

export default ProductCatalogColoredStylesList;
