const ID = 'SUPPORT' as const;

export const FULFILLMENT_VERSION = `${ID}/FULFILLMENT_VERSION` as const;
export const SL_SERVICES_VERSION = `${ID}/SL_SERVICES_VERSION` as const;
export const ORDERS_VERSION = `${ID}/ORDERS_VERSION` as const;
export const CATALOG_VERSION = `${ID}/CATALOG_VERSION` as const;
export const FINANCIAL_VERSION = `${ID}/FINANCIAL_VERSION` as const;
export const SHIPPING_OPTIONS = `${ID}/SHIPPING_OPTIONS` as const;
export const TEAM_COLORS = `${ID}/TEAM_COLORS` as const;
export const COLORS = `${ID}/COLORS` as const;
export const APPLICATION_SETTINGS = `${ID}/APPLICATION_SETTINGS` as const;
export const SETTINGS = `${ID}/SETTINGS` as const;
export const UPDATE_DTG_STATIONS = `${ID}/UPDATE_DTG_STATIONS` as const;
export const UPDATE_COLORED_STYLES_DECORATION_METHODS = `${ID}/UPDATE_COLORED_STYLES_DECORATION_METHODS` as const;

export default ID;
