import React, { PureComponent } from 'react';
import Tabs from '@sharedComponents/Tabs/Tabs';
import ServiceManagerTable from './ServiceManagerTable';
import ShippingPredictionManager from './ShippingPredictionManager';

const serviceManagerTabEnum = {
  Services: 'Services',
  ShippingPrediction: 'ShippingPrediction',
};

class ServiceManager extends PureComponent {
  state = {
    selectedTab: serviceManagerTabEnum.Services,
  };

  selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  getTabs = () => {
    const tabs = [
      {
        title: 'Services',
        name: serviceManagerTabEnum.Services,
        content: <ServiceManagerTable />,
      },
      {
        title: 'Shipping Prediction',
        name: serviceManagerTabEnum.ShippingPrediction,
        content: <ShippingPredictionManager />,
      },
    ];

    return tabs;
  };

  render() {
    const { selectedTab } = this.state;

    return (
      <div className='container'>
        <Tabs
          tabs={this.getTabs()}
          selectedTab={selectedTab}
          selectTab={this.selectTab}
          classes={'tabs--size-l'}
        />
      </div>
    );
  }
}

export default ServiceManager;
