import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';

class SportsActionsColumn extends Component {
  state = {
    actionsExtended: false,
  };

  closeActions = () => {
    this.setState({ actionsExtended: false });
  };

  toggleActionsMenu = () => {
    this.setState((prevState) => ({
      actionsExtended: !prevState.actionsExtended,
    }));
  };

  handleEditClick = (sport) => {
    const { onSportEdit } = this.props;

    this.closeActions();
    onSportEdit(sport);
  };

  handleDeleteClick = (sport) => {
    const { onSportDelete } = this.props;

    this.closeActions();
    onSportDelete(sport);
  };

  getActionsMenu = (sport) => {
    const { actionsExtended } = this.state;

    const className = classNames(
      'lockerManager__item-list categories__action-item-list',
      { 'is-active': actionsExtended }
    );

    if (actionsExtended) {
      return (
        <ul className={className}>
          <li>
            <button
              onClick={this.handleEditClick.bind(null, sport)}
              type='button'
            >
              Edit
            </button>
          </li>
          <li className='lockerManager__item-list--border' />
          <li>
            <button
              onClick={this.handleDeleteClick.bind(this, sport)}
              type='button'
              className='red'
            >
              Delete
            </button>
          </li>
        </ul>
      );
    }

    return null;
  };

  render() {
    const { sport } = this.props;

    return (
      <OutsideClickWrapper onClick={this.closeActions} >
        <div className='colors__actions-container'>
          <button
            className='colors__actions-btn'
            onClick={this.toggleActionsMenu}
          >
            <i className='button material-icons'>more_vert</i>
          </button>
          {this.getActionsMenu(sport)}
        </div>
      </OutsideClickWrapper>
    );
  }
}

SportsActionsColumn.propTypes = {
  sport: PropTypes.object.isRequired,
  onSportEdit: PropTypes.func.isRequired,
  onSportDelete: PropTypes.func.isRequired,
};

export default SportsActionsColumn;
