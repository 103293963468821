import React, {
  useCallback, useState,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FundraisingPayoutsAccountingSubmitModal from './FundraisingPayoutsAccountingSubmitModal';
import { netSuiteVendorBillUrl } from '@constants/clientUrls/clientUrls';
import { submitStatusEnum } from '@constants/enums/orderEnums';
import Button from '@sharedComponents/Buttons/Button';
import { PayoutDto } from '@api/financialServices/models';

interface OwnProps {
  payout: PayoutDto;
}

type Props = OwnProps;

const FundraisingPayoutsDocumentNumber = React.memo<Props>(({
  payout,
}) => {
  const { hfNetSuiteBaseUrl } = useFlags();

  const [
    accountingModalIsOpen,
    setAccountingModalIsOpen,
  ] = useState(false);

  const openAccountingResultsModal = useCallback(() => {
    setAccountingModalIsOpen(true);
  }, []);

  const closeAccountingResultsModal = useCallback(() => {
    setAccountingModalIsOpen(false);
  }, []);

  const renderStatusDetails = () => {
    switch (payout.accountingSubmitStatus) {
      case submitStatusEnum.Done:
        if (!payout.erpPayoutId || !hfNetSuiteBaseUrl) {
          return payout.erpPayoutId;
        }

        return (
          <a
            className='link'
            href={netSuiteVendorBillUrl(hfNetSuiteBaseUrl, payout.erpPayoutId)}
            target='_blank' rel='noreferrer'
          >
            {payout.erpPayoutId}
          </a>
        );
      case submitStatusEnum.Pending:
        return 'Pending...';
      case submitStatusEnum.NotScheduled:
        return 'Not Scheduled';
      case submitStatusEnum.Failed:
        return (
          <div className='flex'>
            <div className='order__failed-text'>Failed</div>
            <Button
              type={'danger'}
              text={'Details'}
              onClick={openAccountingResultsModal}
              size={'s'}
              classes={'button--size-s ml-20'}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {
        accountingModalIsOpen &&
        <FundraisingPayoutsAccountingSubmitModal
          payout={payout}
          isOpen={accountingModalIsOpen}
          closeModal={closeAccountingResultsModal}
        />
      }
      <div className='info__label'>Document Number</div>
      <div className='info__value'>{renderStatusDetails()}</div>
    </div>
  );
});

export default FundraisingPayoutsDocumentNumber;
