import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  text?: string;
  checked?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
  classes?: string;
  clickableText?: boolean;
}

type Props = OwnProps;

const CheckButton = ({
  text,
  onClick,
  disabled,
  checked,
  classes,
  clickableText = true,
}: Props) => {
  const icon = checked
    ? <Icon materialIcon={'check_box'} />
    : <Icon materialIcon={'check_box_outline_blank'} />;

  if (clickableText) {
    return (
      <a
        className={`button btn__checkbox ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''} ${classes ? classes : ''}`}
        onClick={(onClick && !disabled) ? onClick : undefined}
      >
        {icon}
        <span>{text}</span>
      </a>
    );
  }

  return (
    <div className={`button btn__checkbox ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''} ${classes ? classes : ''}`}>
      <a onClick={(onClick && !disabled) ? onClick : undefined}>
        {icon}
      </a>
      <span>{text}</span>
    </div>
  );
};

export default React.memo(CheckButton);
