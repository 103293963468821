import React from 'react';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';

interface OwnProps {
  item: {
    name?: string | string[];
    code?: string;
    isCustomItem?: boolean;
    essential?: boolean;
    requiredItem?: boolean;
    merchandisingEffortId?: number | null;
    collectionsList: {
      id: number;
      name: string;
    }[];
  };
}

type Props = OwnProps;

const LockerItemLabels = React.memo(({ item }: Props) => (
  <LabelsLegendDisplay
    labels={[
      {
        isActive: !!(item.collectionsList && item.collectionsList.length > 0),
        text: 'Collection',
        shortText: 'C',
        type: 'gossamer',
        additionalText: (
          <div>
            {item.collectionsList?.map((collection: any) => (<div key={collection.id}>{collection.name}</div>))}
          </div>
        ),
      },
      {
        isActive: !!item.isCustomItem,
        text: 'Custom Item',
        shortText: 'C',
        type: 'status-blue',
      },
      {
        isActive: !!item.essential,
        text: 'Essential',
        shortText: 'E',
        type: 'gossamer',
      },
      {
        isActive: !!item.requiredItem,
        text: 'Required',
        shortText: 'R',
        type: 'electric-indigo',
      },
      {
        isActive: !!item.merchandisingEffortId,
        text: 'SL Merchandised',
        shortText: 'S',
        type: 'status-darkred',
      },
    ]}
  />
));

export default LockerItemLabels;
