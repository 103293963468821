import React from 'react';
import {
  SubmitStatusEnum,
  VoucherOrderDetailDto,
} from '@api/financialServices/models';
import Button from '@sharedComponents/Buttons/Button';
import InfoField from '@components/shared/Display/InfoField';
import VoucherOrderSubmitResultsModal from '@components/VoucherOrders/Modals/VoucherOrderSubmitResultsModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { netSuiteInvoiceUrl } from '@constants/clientUrls/clientUrls';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  openModal: () => void;
  closeModal: () => void;
  isSubmitResultsModalOpen: boolean;
  refreshVoucherOrder: () => void;
}

type Props = OwnProps;

const SOPNumber = React.memo<Props>(({
  voucherOrder,
  openModal,
  closeModal,
  isSubmitResultsModalOpen,
  refreshVoucherOrder,
}) => {
  const { hfNetSuiteBaseUrl } = useFlags();

  const renderStatusDetails = () => {
    switch (voucherOrder.accountingSubmitStatus) {
      case SubmitStatusEnum.Done:
        if (!voucherOrder.erpId || !hfNetSuiteBaseUrl) {
          return voucherOrder.erpName;
        }

        return (
          <a
            className='link'
            href={netSuiteInvoiceUrl(hfNetSuiteBaseUrl, voucherOrder.erpId)}
            target='_blank' rel='noreferrer'
          >
            {voucherOrder.erpName}
          </a>
        );
      case SubmitStatusEnum.Pending:
        return 'Pending...';
      case SubmitStatusEnum.NotScheduled:
        return 'Not Scheduled';
      case SubmitStatusEnum.Failed:
        return (
          <div className='flex'>
            <div className='order__failed-text'>Failed</div>
            <Button
              type={'danger'}
              text={'Details'}
              onClick={openModal}
              size={'s'}
              classes={'button--size-s ml-20'}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {
        isSubmitResultsModalOpen &&
        <VoucherOrderSubmitResultsModal
          voucherOrder={voucherOrder}
          isOpen={isSubmitResultsModalOpen}
          closeModal={closeModal}
          refreshVoucherOrder={refreshVoucherOrder}
        />
      }
      <InfoField
        label={'SOP Number'}
        value={renderStatusDetails()}
        fieldClass={'w-50'}
      />
    </>
  );
});
export default SOPNumber;
