import React, { useCallback } from 'react';
import { ObjectType } from '@customTypes/option';
import { ColorItem } from '@models/common/ColorItem';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

const genericMemo: <T>(component: T) => T = React.memo;

interface OwnProps<T extends ObjectType, ValueKey extends keyof T> {
  value: T[ValueKey];
  text: string;
  isSelected: boolean;
  onClick: (value: T[ValueKey], isSelected: boolean) => void;
  color?: T & ColorItem;
  disabled?: boolean;
  disabledTooltipText?: string;
}

type Props<T, K extends keyof T> = OwnProps<T, K>;

const MultiSelectDropdownItemObject = <T, K extends keyof T = any>({
  value,
  text,
  isSelected,
  onClick,
  color,
  disabled,
  disabledTooltipText,
}: Props<T, K>) => {
  const handleOnClick = useCallback(() => {
    onClick(value, isSelected);
  }, [
    onClick,
    value,
    isSelected,
  ]);

  const dropdownItem = (
    <li
      onClick={!disabled ? handleOnClick : undefined}
      className={`custom-dropdown__option button ${isSelected ? 'is-active' : ''} ${disabled ? 'disabled' : ''}`}
    >
      {
        disabled
          ? <Icon materialIcon={'lock'} />
          : isSelected
            ? <Icon materialIcon={'check_box'} />
            : <Icon materialIcon={'check_box_outline_blank'} />
      }
      {
        color &&
        <div
          className='color-swatch-item__color ml-10'
          style={color ? { backgroundColor: color.hexValue } : {}}
        />
      }
      <div className='custom-dropdown__option--label'>
        {text}
      </div>
    </li>
  );

  if (disabled && !!disabledTooltipText) {
    return (
      <MaterialTooltip
        tooltipText={disabledTooltipText}
        placement='bottom'
      >
        {dropdownItem}
      </MaterialTooltip>
    );
  }

  return dropdownItem;
};

export default genericMemo(MultiSelectDropdownItemObject);
