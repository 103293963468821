import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import {
  transparentBackgoundOption,
  imageBackgroundOptions,
} from '@constants/values';
import { downloadFile } from '@util/componentHelper';
import { deepMerge } from '@util/deepMerge';
import Button from '@sharedComponents/Buttons/Button';
import ImagePreview from '@sharedComponents/ImagePreview';

export default class LogoReviewImage extends Component {
  state = {
    backgroundOption: transparentBackgoundOption,
    dimensions: {
      width: null,
      height: null,
    },
    imagePreviewOption: {
      smallImage: {
        width: 450,
        height: 450,
      },
      imageStyle: {
        border: `25px solid ${transparentBackgoundOption.color}`,
      },
      enlargedImageStyle: {
        border: `50px solid ${transparentBackgoundOption.color}`,
      },
    },
  };

  componentDidMount() {
    const { logo } = this.props;
    const imgMock = new Image();
    imgMock.src = logo.logoUrl;
    imgMock.onload = () => {
      this.setState(() => ({
        dimensions: {
          width: imgMock.naturalWidth,
          height: imgMock.naturalHeight,
        },
      }));
    };
  }

  getImagePreviewOptions = (backgroundColor) => ({
    imageStyle: {
      border: '25px solid ' + backgroundColor,
    },
    enlargedImageStyle: {
      border: '50px solid ' + backgroundColor,
    },
  });

  changeBackgroundColor = (backgroundOption) => {
    const newImagePreviewOption = this.getImagePreviewOptions(backgroundOption.color);

    this.setState((prevState) => ({
      backgroundOption,
      imagePreviewOption: deepMerge(prevState.imagePreviewOption, newImagePreviewOption),
    }));
  };

  renderColorList = () => {
    const { backgroundOption } = this.state;

    const colorOptions = imageBackgroundOptions.map((option, index) => (
      <button
        key={index}
        className={`bg-color-picker__item ${option.code === backgroundOption.code ? 'is-active' : ''}`}
        onClick={this.changeBackgroundColor.bind(this, option)}
      >
        <div style={{ background: option.background }} />
      </button>
    ));

    return (
      <div className='artwork-preview__options mb-0 pb-0 pt-0'>
        <div className='bg-color-picker'>
          {colorOptions}
        </div>
      </div>
    );
  };

  render() {
    const {
      logo,
      hoveredColor,
    } = this.props;

    const {
      backgroundOption,
      dimensions,
      imagePreviewOption,
    } = this.state;

    return (
      <div>
        <ImagePreview
          filePath={`${lockerMgrS3Logos}/${logo.logoUrl}`}
          backgroundOption={backgroundOption}
          hoveredColor={hoveredColor}
          fileDimensions={dimensions}
          options={hoveredColor
            ? deepMerge(imagePreviewOption, this.getImagePreviewOptions(transparentBackgoundOption.color))
            : imagePreviewOption
          }
        />
        <div className='navigation'>
          {this.renderColorList()}
          <Button
            type={'secondary'}
            text={'Download File'}
            onClick={downloadFile(`${lockerMgrS3Logos}/${logo.logoUrl}`, logo.logoUrl)}
            classes={'mt-10'}
          />
        </div>
      </div>
    );
  }
}

LogoReviewImage.propTypes = {
  logo: PropTypes.shape({
    id: PropTypes.number,
    lockerId: PropTypes.number,
    logoUrl: PropTypes.string,
    reviewStartedAt: PropTypes.string,
    ordersCount: PropTypes.number,
  }).isRequired,
  hoveredColor: PropTypes.string,
};
