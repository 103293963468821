import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  sendNotifications: () => void;
  tabName: string;
}

type Props = OwnProps;

const SendNotificationsForAllOrdersModal = React.memo<Props>(({
  isOpen,
  closeModal,
  sendNotifications,
  tabName,
}) => (
  <Modal
    title={'Send Notification for All Orders'}
    isOpen={isOpen}
    modalSize={'m'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Send'}
        cancelBtnText={'Cancel'}
        onConfirm={sendNotifications}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to send notifications for all orders from "{tabName}"?</h3>
  </Modal>
));

export default SendNotificationsForAllOrdersModal;
