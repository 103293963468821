import React, { useCallback } from 'react';
import { VoucherOrderChangeLogItemDto } from '@api/financialServices/models';
import ChangeLogItem from '@components/shared/ChangeLog/ChangeLogItem';

interface OwnProps {
  voucherOrderChangeLog?: Array<VoucherOrderChangeLogItemDto>;
}

type Props = OwnProps;

const VoucherSidebarChangelog = React.memo<Props>(({ voucherOrderChangeLog }) => {
  const getChangeLogItems = useCallback(() => voucherOrderChangeLog?.map((logItem, key) => (
    <ChangeLogItem
      key={key}
      dateTime={logItem.created}
      message={logItem.message}
      user={logItem.user}
    />
  )), [voucherOrderChangeLog]);

  return (
    <div className='order__log-change custom-scrollbar'>
      {
        voucherOrderChangeLog && voucherOrderChangeLog.length > 0
          ? getChangeLogItems()
          : 'No changes'
      }
    </div>
  );
});

export default VoucherSidebarChangelog;
