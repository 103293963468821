import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import DtgTabletProfilesForm from '../DtgTabletProfilesForm';
import { dtgTabletProfilesForm } from '@constants/reduxForms';

const DtgTabletProfilesEditModal = React.memo(({
  isOpen,
  initialValues,
  printDirectionOptions,
  closeModal,
  onSubmit,
}) => (
  <Modal
    title={'Edit DTG Tablet Profile'}
    isOpen={isOpen}
    modalSize={'l'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Update'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={dtgTabletProfilesForm}
      />
    )}
  >
    <DtgTabletProfilesForm
      onSubmit={onSubmit}
      printDirectionOptions={printDirectionOptions}
      initialValues={initialValues}
    />
  </Modal>
));

DtgTabletProfilesEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  printDirectionOptions: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default DtgTabletProfilesEditModal;
