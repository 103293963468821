import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
  reduxForm,
} from 'redux-form';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import { connect } from 'react-redux';
import { parseNumber } from '@util/numberHelpers';
import { dtgTabletProfilesForm } from '@constants/reduxForms';
import { validateDtgTabletProfiles } from '@redux/support/validations';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import FormError from '@sharedComponents/Form/FormError';

const DtgTabletProfilesForm = React.memo(({
  error,
  handleSubmit,
  printDirectionOptions,
}) => (
  <Form
    className='redux-form'
    onSubmit={handleSubmit}
    id={dtgTabletProfilesForm}
  >
    <div>
      <GridContainer className='redux-form__section'>
        <Row className='redux-form__row'>
          <Col
            className='redux-form__column'
            md={12}
          >
            <label className='redux-form__label'>
              Name
              <span className='required'>*</span>
            </label>
            <Field
              name={'name'}
              placeholder={'Enter name'}
              component={Input}
              type={'string'}
            />
          </Col>
        </Row>
        <Row className='redux-form__row'>
          <Col
            className='redux-form__column'
            md={12}
          >
            <label className='redux-form__label'>
              Note
            </label>
            <Field
              name={'note'}
              placeholder={'Enter note'}
              component={Input}
              type={'string'}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label className='redux-form__label'>
              Print Direction Underbase
            </label>
            <Field
              name={'printDirectionUnderbase'}
              component={Select}
            >
              {printDirectionOptions}
            </Field>
          </Col>
          <Col md={6}>
            <label className='redux-form__label'>
              Print Direction Color
            </label>
            <Field
              name={'printDirectionColor'}
              component={Select}
            >
              {printDirectionOptions}
            </Field>
          </Col>
        </Row>
        <label className='redux-form__label'>
          Wait after white underpass
        </label>
        <Row>
          <Col md={4}>
            <label className='redux-form__label'>
              Minutes
            </label>
            <Field
              name={'minutes'}
              placeholder={'Minutes'}
              component={Input}
              type={'number'}
              parse={parseNumber}
            />
          </Col>
          <Col md={4}>
            <label className='redux-form__label'>
              Seconds
            </label>
            <Field
              name={'seconds'}
              placeholder={'Seconds'}
              component={Input}
              type={'number'}
              parse={parseNumber}
            />
          </Col>
          <Col md={4}>
            <label className='redux-form__label'>
              Milliseconds
            </label>
            <Field
              name={'milliseconds'}
              placeholder={'Milliseconds'}
              component={Input}
              type={'number'}
              parse={parseNumber}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label className='redux-form__label'>
              Extra undebase passes
            </label>
            <Field
              name={'passesUnderbase'}
              placeholder={'Extra underbase passes'}
              component={Input}
              type={'number'}
              parse={parseNumber}
            />
          </Col>
          <Col md={6}>
            <label className='redux-form__label'>
              Extra color passes
            </label>
            <Field
              name={'passesColor'}
              placeholder={'Extra color passes'}
              component={Input}
              type={'number'}
              parse={parseNumber}
            />
          </Col>
        </Row>
      </GridContainer>
      <FormError error={error} />
    </div>
  </Form>
));

DtgTabletProfilesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  printDirectionOptions: PropTypes.array.isRequired,
};

export default connect()(reduxForm({
  form: dtgTabletProfilesForm,
  validate: validateDtgTabletProfiles,
  enableReinitialize: true,
  shouldError: () => true,
})(DtgTabletProfilesForm));
