import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { modalStyleBig } from '@constants/values';
import {
  decorationMethodEnum,
  servicesApiDecorationMethods,
} from '@constants/enums/decorationEnums';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { toggleArrayMember } from '@util/componentHelper';
import LogoColorsDropdown from '@sharedComponents/ColorDetectionEditSwatches/LogoColorsDropdown';
import SelectableSwatchColorField from '@sharedComponents/ColorDetectionEditSwatches/SelectableSwatchColorField';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Button from '@sharedComponents/Buttons/Button';
import ColorListSelect from './ColorListSelect';

class EditLogoColorsModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedLogoColors: props.logoColors || [],
      approvedLightColors: this.calculateApprovedLightColorsValue(props.approvedLightColors, props.allLightColors),
      approvedDarkColors: this.calculateApprovedDarkColorsValue(props.approvedDarkColors, props.allDarkColors),
      newLogoColors: [],
      deletedLogoColors: [],
      isHag: props.allDecorationsActive || props.decorationMethod.includes(decorationMethodEnum.HAG),
      isEmb: props.allDecorationsActive || props.decorationMethod.includes(decorationMethodEnum.EMB),
      isDtg: props.allDecorationsActive || props.decorationMethod.includes(decorationMethodEnum.DTG),
      isDip: props.allDecorationsActive || props.decorationMethod.includes(decorationMethodEnum.DIP),
      hoveredColor: null,
      customColorsInputValue: '',
    };
  }

  componentDidUpdate(prevProps) {
    const {
      logoColors,
      allLightColors,
      allDarkColors,
      approvedLightColors,
      approvedDarkColors,
      isOpen,
    } = this.props;

    const logoColorsChanged = logoColors && logoColors !== prevProps.logoColors;
    const lightColorsChanged = allLightColors && allLightColors !== prevProps.allLightColors;
    const darkColorsChanged = allDarkColors && allDarkColors !== prevProps.allDarkColors;
    const approvedLightColorsChanged = approvedLightColors && approvedLightColors !== prevProps.approvedLightColors;
    const approvedDarkColorsChanged = approvedDarkColors && approvedDarkColors !== prevProps.approvedDarkColors;
    const isOpenChanged = isOpen && isOpen !== prevProps.isOpen;

    if (logoColorsChanged || lightColorsChanged || darkColorsChanged
      || approvedLightColorsChanged || approvedDarkColorsChanged) {
      this.setState({
        selectedLogoColors: logoColors,
        approvedLightColors: this.calculateApprovedLightColorsValue(approvedLightColors, allLightColors),
        approvedDarkColors: this.calculateApprovedDarkColorsValue(approvedDarkColors, allDarkColors),
      });
    }

    if (isOpenChanged) {
      this.setState(() => ({
        deletedLogoColors: [],
        newLogoColors: [],
      }));
    }
  }

  calculateApprovedLightColorsValue = (approvedLightColors, allLightColors) => (
    approvedLightColors ? approvedLightColors : (allLightColors || [])
  );

  calculateApprovedDarkColorsValue = (approvedDarkColors, allDarkColors) => (
    approvedDarkColors ? approvedDarkColors : (allDarkColors || [])
  );

  getUnusedArtworkColors = () => {
    const { artworkColors } = this.props;
    const { selectedLogoColors } = this.state;

    if (!artworkColors) {
      return [];
    }

    return artworkColors.filter((c) => !(selectedLogoColors || []).includes(c.code));
  };

  colorsHaveChanges = () => {
    const {
      newLogoColors,
      deletedLogoColors,
      approvedLightColors,
      approvedDarkColors,
    } = this.state;

    const oldApprovedLightColors = this.props.approvedLightColors;
    const oldApprovedDarkColors = this.props.approvedDarkColors;

    const lightColorsChanged = (!oldApprovedLightColors && approvedLightColors.length > 0)
      || (oldApprovedLightColors.length !== approvedLightColors.length);

    const darkColorsChanged = (!oldApprovedDarkColors && approvedDarkColors.length > 0)
      || (oldApprovedDarkColors.length !== approvedDarkColors.length);

    return newLogoColors.length > 0 || deletedLogoColors.length > 0 || lightColorsChanged || darkColorsChanged;
  };

  deleteColorSwatch = (color) => {
    const {
      selectedLogoColors,
      newLogoColors,
      deletedLogoColors,
    } = this.state;

    if (newLogoColors.includes(color)) {
      this.setState({
        selectedLogoColors: toggleArrayMember(selectedLogoColors, color),
        newLogoColors: toggleArrayMember(newLogoColors, color),
      });
    } else {
      this.setState({
        deletedLogoColors: toggleArrayMember(deletedLogoColors, color),
      });
    }
  };

  selectAllLightColors = () => {
    const { allLightColors } = this.props;
    this.setState({ approvedLightColors: allLightColors });
  };

  unselectAllLightColors = () => {
    this.setState({ approvedLightColors: [] });
  };

  selectAllDarkColors = () => {
    const { allDarkColors } = this.props;
    this.setState({ approvedDarkColors: allDarkColors });
  };

  unselectAllDarkColors = () => {
    this.setState({ approvedDarkColors: [] });
  };

  setHoveredColor = (color) => {
    this.setState({ hoveredColor: color });
  };

  updateLogo = () => {
    const {
      selectedLogoColors,
      deletedLogoColors,
      isHag,
      isEmb,
      isDtg,
      isDip,
      approvedLightColors,
      approvedDarkColors,
    } = this.state;

    const { saveLogoColors } = this.props;

    const floodColorIds = Array.from(new Set([
      ...approvedLightColors.map((c) => c.id),
      ...approvedDarkColors.map((c) => c.id),
    ]));

    const colors = selectedLogoColors.filter((c) => !deletedLogoColors.includes(c));
    const selectedMethods = [];
    if (isHag) {
      selectedMethods.push(servicesApiDecorationMethods.HAG);
    }
    if (isEmb) {
      selectedMethods.push(servicesApiDecorationMethods.EMB);
    }
    if (isDtg) {
      selectedMethods.push(servicesApiDecorationMethods.DTG);
    }
    if (isDip) {
      selectedMethods.push(servicesApiDecorationMethods.DIP);
    }

    saveLogoColors(colors, selectedMethods, floodColorIds);
  };

  toggleSelectColor = (listName, color) => {
    const colorList = this.state[listName];

    const isSelected = colorList.find((c) => c.id === color.id);

    if (isSelected) {
      this.setState({
        [listName]: colorList.filter((c) => c.id !== color.id),
      });
    } else {
      this.setState({
        [listName]: [
          ...colorList,
          color,
        ],
      });
    }
  };

  addColorSwatches = (colors) => {
    const {
      selectedLogoColors,
      newLogoColors,
    } = this.state;

    this.setState({
      selectedLogoColors: selectedLogoColors.concat(colors),
      newLogoColors: newLogoColors.concat(colors),
    });
  };

  toggleIsHag = () => {
    this.setState((prevState) => ({
      isHag: !prevState.isHag,
      isDtg: !prevState.isHag || prevState.isDtg,
    }));
  };

  toggleIsEmb = () => {
    this.setState((prevState) => ({
      isEmb: !prevState.isEmb,
    }));
  };

  toggleIsDtg = () => {
    this.setState((prevState) => ({
      isDtg: !prevState.isDtg,
    }));
  };

  toggleIsDip = () => {
    this.setState((prevState) => ({
      isDip: !prevState.isDip,
    }));
  };

  addCustomColors = () => {
    const {
      newLogoColors,
      selectedLogoColors,
      customColorsInputValue,
    } = this.state;

    const colors = customColorsInputValue.split(',');

    const updatedNewLogoColors = [...newLogoColors];
    const updatedSelectedLogoColors = [...selectedLogoColors];

    for (const color of colors) {
      const colorName = color.trim();
      if (colorName === '') continue;

      if (!updatedNewLogoColors.includes(colorName)) {
        updatedSelectedLogoColors.push(colorName);
        updatedNewLogoColors.push(colorName);
      }
    }

    this.setState({
      selectedLogoColors: updatedSelectedLogoColors,
      newLogoColors: updatedNewLogoColors,
      customColorsInputValue: '',
    });
  };

  handleEnterKeydown = (e) => {
    if (e.key === keyNameEnum.Enter) {
      this.addCustomColors();
    }
  };

  handleChangeCustomColorsInput = (e) => {
    const customColorsInputValue = e.target.value;
    this.setState(() => ({ customColorsInputValue }));
  };

  isAddCustomColorsBtnDisabled = () => {
    const { customColorsInputValue } = this.state;

    return customColorsInputValue.trim() === '';
  };

  render() {
    const {
      decorationMethod,
      isOpen,
      cancel,
      logoUrl,
      logoName,
      saveAlwaysEnabled,
      allLightColors,
      allDarkColors,
      modalTitle = 'Edit Logo',
      colors,
    } = this.props;

    const {
      selectedLogoColors,
      newLogoColors,
      deletedLogoColors,
      isEmb,
      isHag,
      isDtg,
      isDip,
      approvedLightColors,
      approvedDarkColors,
      hoveredColor,
      customColorsInputValue,
    } = this.state;

    const selectableSwatches = selectedLogoColors
      ? selectedLogoColors.map((c, index) => (
        <SelectableSwatchColorField
          key={index}
          colorCode={c}
          isDeleted={deletedLogoColors.includes(c)}
          isAdded={newLogoColors.includes(c)}
          handleSelect={this.deleteColorSwatch}
          method={decorationMethod}
          colors={colors}
        />
      ))
      : null;

    const existingColors = (selectedLogoColors || []).length > 0
      ? (
        <>
          <div className='color-swatches__container custom-scrollbar mb-20'>
            <div>
              {selectableSwatches}
            </div>
          </div>
          <div className='color-swatches__remove-color-info'>Click on an existing color to remove it</div>
          <hr className='color-swatches__divider' />
        </>
      )
      : null;

    return (
      <Modal
        title={modalTitle}
        modalSize={'xl'}
        isOpen={isOpen}
        closeModal={cancel}
        modalStyleProps={modalStyleBig}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onConfirm={this.updateLogo}
            onClose={cancel}
            confirmBtnDisabled={
              !saveAlwaysEnabled && !this.colorsHaveChanges() && (!isEmb && !isHag && !isDtg && !isDip)
            }
          />
        )}
      >
        <div>
          <div
            className='color-detection__logo transparent logo-bank__upload-modal--logo mb-10'
            style={hoveredColor
              ? {
                backgroundImage: 'none',
                backgroundColor: hoveredColor,
              }
              : {}
            }
          >
            <div
              className='color-detection__logo'
              style={{ backgroundImage: `url("${logoUrl}")` }}
            >
              <div className='logo-bank__upload-modal--logo__name'>{logoName}</div>
            </div>
          </div>

          <div className='logo-bank__upload-modal--colors mb-20'>
            <div className='w-50 mr-20'>
              <div className='logo-bank__upload-modal--title'>Artwork Colors</div>
              {existingColors}
              <div className='color-swatches__container'>
                <div className='color-swatches__input-container'>
                  <div className='select-field'>
                    <LogoColorsDropdown
                      colors={this.getUnusedArtworkColors()}
                      addColors={this.addColorSwatches}
                    />
                  </div>
                  <div className='text-field'>
                    <input
                      type='text'
                      className='color-swatches__input'
                      placeholder='Add Custom Color (comma separated)'
                      onKeyDown={this.handleEnterKeydown}
                      value={customColorsInputValue}
                      onChange={this.handleChangeCustomColorsInput}
                    />
                  </div>
                </div>
                <div className='color-swatches__add-btn-container'>
                  <Button
                    type={'primary'}
                    text={'Add'}
                    onClick={this.addCustomColors}
                    disabled={this.isAddCustomColorsBtnDisabled()}
                  />
                </div>
              </div>
            </div>

            <div className='w-50'>
              <div className='logo-bank__upload-modal--colors mb-20'>
                <div className='mr-20 w-50'>
                  <div className='logo-bank__upload-modal--title'>
                    <div>Approved Light Colors</div>
                    <div>
                      <span
                        className='logo-bank__upload-modal--title__btn'
                        onClick={this.selectAllLightColors}
                      >
                        All
                      </span>
                      <span
                        className='logo-bank__upload-modal--title__btn'
                        onClick={this.unselectAllLightColors}
                      >
                        None
                      </span>
                    </div>
                  </div>
                  <div>
                    <ColorListSelect
                      allColors={allLightColors}
                      selectedColors={approvedLightColors}
                      toggleSelectColor={this.toggleSelectColor.bind(null, 'approvedLightColors')}
                      setHoveredColor={this.setHoveredColor}
                    />
                  </div>
                </div>

                <div className='w-50'>
                  <div className='logo-bank__upload-modal--title'>
                    <div>Approved Dark Colors</div>
                    <div>
                      <span
                        className='logo-bank__upload-modal--title__btn'
                        onClick={this.selectAllDarkColors}
                      >
                        All
                      </span>
                      <span
                        className='logo-bank__upload-modal--title__btn'
                        onClick={this.unselectAllDarkColors}
                      >
                        None
                      </span>
                    </div>
                  </div>
                  <div>
                    <ColorListSelect
                      allColors={allDarkColors}
                      selectedColors={approvedDarkColors}
                      toggleSelectColor={this.toggleSelectColor.bind(null, 'approvedDarkColors')}
                      setHoveredColor={this.setHoveredColor}
                    />
                  </div>
                </div>
              </div>

              <div className='logo-bank__upload-modal--decoration-methods w-100'>
                <div className='logo-bank__upload-modal--title mt-20'>Approved Decoration Methods</div>
                <div className='color-swatches__checkboxes'>
                  <CheckButton
                    disabled={false}
                    text={decorationMethodEnum.HAG}
                    checked={isHag}
                    onClick={this.toggleIsHag}
                  />
                  <CheckButton
                    disabled={false}
                    text={decorationMethodEnum.EMB}
                    checked={isEmb}
                    onClick={this.toggleIsEmb}
                  />
                  <CheckButton
                    disabled={false}
                    text={decorationMethodEnum.DTG}
                    checked={isDtg}
                    onClick={this.toggleIsDtg}
                  />
                  <CheckButton
                    disabled={false}
                    text={decorationMethodEnum.DIP}
                    checked={isDip}
                    onClick={this.toggleIsDip}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

EditLogoColorsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  decorationMethod: PropTypes.arrayOf(PropTypes.string),
  logoColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  logoUrl: PropTypes.string,
  logoName: PropTypes.string,
  saveLogoColors: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  saveAlwaysEnabled: PropTypes.bool,
  allDecorationsActive: PropTypes.bool.isRequired,
  approvedLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  approvedDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  artworkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
};

export default EditLogoColorsModal;
