import React from 'react';
import {
  productionOverviewReviewByBarcodeUrl,
  productionOverviewReviewByOrderUrl,
} from '@constants/clientUrls/clientUrls';
import OrderSearch from '@sharedComponents/Production/Search/OrderSearch';

const ProductionOverviewSearch = React.memo(() => (
  <OrderSearch
    reviewByOrderUrl={productionOverviewReviewByOrderUrl}
    reviewByBarcodeUrl={productionOverviewReviewByBarcodeUrl}
  />
));

export default ProductionOverviewSearch;
