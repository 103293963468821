import React from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import Icon from '@sharedComponents/Icons/Icon';
import ReviewedButton from '../ReviewedButton';

const ReviewedLogo = ({
  logo, allowedDecorationMethods, requestTasks, onReviewBtnClick, unapproved,
}) => {
  const logoThumbnailStyle = {
    backgroundImage: `url("${lockerMgrS3Logos}/${logo.logoUrl}")`,
  };

  return (
    <div className='logo-review__task__details logo-review__task__details--reviewed'>
      <div className='logo-review__task__details--reviewed__info'>
        <div className='logo-review__task__details--reviewed__decorations'>
          {
            allowedDecorationMethods.map((d) => (
              <span
                className='logo-review__task__details--reviewed__decorations-item'
                key={d}
              >
                {d}
              </span>
            ))
          }
        </div>
        <div className='logo-review__task__details--reviewed__thumbnail-wrapper'>
          <i
            className='logo-review__task__details--reviewed__thumbnail'
            style={logoThumbnailStyle}
          />
        </div>
        <div className='logo-review__task__details--reviewed__column'>
          <div>
            <div className='logo-review__task__details--reviewed__column--title'>
              Request Tasks
            </div>
            <div className='logo-review__task__details--reviewed__column--description'>
              {requestTasks.join(', ') || 'None'}
            </div>
          </div>
        </div>
      </div>

      <div className='logo-review__task__details--reviewed__column'>
        <div className='logo-review__task__details--reviewed__row'>
          {
            unapproved &&
            <div className='logo-review__task__details--reviewed__label--unapproved mr-30'>
              <Icon materialIcon={'warning'} />
              <div className='text'>Unapproved</div>
            </div>
          }
          <ReviewedButton
            reviewed={true}
            onReviewBtnClick={onReviewBtnClick}
          />
        </div>
      </div>
    </div>
  );
};

ReviewedLogo.propTypes = {
  logo: PropTypes.shape({
    id: PropTypes.number,
    lockerId: PropTypes.number,
    logoUrl: PropTypes.string,
    reviewStartedAt: PropTypes.string,
    ordersCount: PropTypes.number,
  }).isRequired,
  requestTasks: PropTypes.array.isRequired,
  allowedDecorationMethods: PropTypes.array.isRequired,
  onReviewBtnClick: PropTypes.func.isRequired,
  unapproved: PropTypes.bool.isRequired,
};

export default ReviewedLogo;
