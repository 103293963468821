import React, { Component } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { userFound } from 'redux-oidc';
import {
  autoLoginUrl,
  lockerManagerUrl,
  loginUrl,
  orderDetailsNewOrder,
  orderDetailsNewOrderAddItems,
  orderDetailsBaseUrl,
  orderSearchUrl,
  printFilesUrl,
  printSheetBaseUrl,
  productCatalogUrl,
} from '@constants/clientUrls/clientUrls';
import * as printFilesActions from '@redux/printFiles/actions';
import { tableScopesList } from '@redux/tableManager/reducers';
import * as orderManagementActions from '@redux/orderManagement/actions';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import * as productCatalogActions from '@redux/productCatalog/actions';
import * as tableManagerActions from '@redux/tableManager/actions';
import { RootState } from '@redux/index/reducers';
import userManager from '@util/userManager';
import { navigateToPage } from '@util/componentHelper';
import CircularProgressBar from './CircularProgressBar';
import LinearProgressBar from './LinearProgressBar';

interface OwnProps {
  isLoadingActive: boolean;
  isBlockingLoadingActive: boolean;
}

const mapStateToProps = ({
  oidc,
  printFiles,
  api,
}: RootState) => ({
  oidc,
  printQueue: printFiles.currentQueue,
  isLoadingActive: api.isLoadingActive,
  isBlockingLoadingActive: api.isBlockingLoadingActive,
});

const mapDispatchToProps = {
  resetOrderManagementTableState: orderManagementActions.resetOrderManagementTableState,
  discardTempOrder: orderManagementActions.discardTempOrder,
  clearNonessentialQueryParams: printFilesActions.clearNonessentialQueryParams,
  clearPrintFiles: printFilesActions.clearPrintFiles,
  resetLockerManagerTableState: lockerManagerActions.resetLockerManagerTableState,
  resetStyleTableState: productCatalogActions.resetStyleTableState,
  isUserFound: userFound,
  resetTableState: tableManagerActions.resetTableState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector> & RouteComponentProps;

class DataContainer extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const regTest = new RegExp('login\/?');
    const oldUrl = `${window.location.pathname ?? '/'}${window.location.search ?? ''}`;
    if (sessionStorage.getItem('oidc')) {
      const user = JSON.parse(sessionStorage.getItem('oidc') || '');
      const check = sessionStorage.getItem('userLoading') === 'false';
      if (user && user.expires_at <= Date.now() / 1000 && check) {
        sessionStorage.setItem('userLoading', 'true');
        sessionStorage.setItem('oldUrl', oldUrl);
        userManager.signinRedirect();
      } else {
        const { isUserFound } = props;
        isUserFound(user);
      }
    } else if (sessionStorage.getItem('userLoading') !== 'true' && !regTest.test(window.location.href)) {
      sessionStorage.setItem('oldUrl', oldUrl);
      navigateToPage(autoLoginUrl);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      oidc,
      location,
      resetOrderManagementTableState,
      clearNonessentialQueryParams,
      clearPrintFiles,
      resetStyleTableState,
      resetLockerManagerTableState,
      discardTempOrder,
    } = this.props;
    const oldOidc = prevProps.oidc;

    if (oldOidc?.user && oidc?.user && oldOidc.user.access_token !== oidc.user.access_token) {
      sessionStorage.setItem('oidc', JSON.stringify(oidc.user));
    } else if (oldOidc?.user && !oidc?.user && sessionStorage.getItem('userLoading') !== 'true') {
      navigateToPage(loginUrl);
    }

    // Reset order management, print-files, and locker manager queues on navigation away from those apps
    if ((prevProps.location?.pathname.includes(orderDetailsBaseUrl)
          || prevProps.location?.pathname.includes(orderSearchUrl))
        && !location?.pathname.includes(orderDetailsBaseUrl)
        && !location?.pathname.includes(orderSearchUrl)) {
      resetOrderManagementTableState();
    }
    if ((prevProps.location?.pathname.includes(orderDetailsNewOrder)
        || prevProps.location?.pathname.includes(orderDetailsNewOrderAddItems))
      && !location?.pathname.includes(orderDetailsNewOrder)
      && !location?.pathname.includes(orderDetailsNewOrderAddItems)
    ) {
      discardTempOrder();
    }
    if (prevProps.location?.pathname.includes(lockerManagerUrl) && !location?.pathname.includes(lockerManagerUrl)) {
      resetLockerManagerTableState();
    }
    if (prevProps.location?.pathname.includes(productCatalogUrl) && !location?.pathname.includes(productCatalogUrl)) {
      resetStyleTableState();
    }
    if (!location?.pathname.includes(printFilesUrl)
      && !location?.pathname.includes(printSheetBaseUrl)
      && prevProps.printQueue.queueType
    ) {
      clearPrintFiles();
    } else if (prevProps.location?.pathname === printFilesUrl && location?.pathname.includes(printSheetBaseUrl)) {
      clearNonessentialQueryParams();
    }

    this.resetTableStateCheck(prevProps, this.props);
  }

  userHasLeftModule = (prevPathname: string | undefined, currPathname: string | undefined, urls: string[]) => {
    // Check if user was in some of the urls (prevPathname = one of the urls)
    const prevLocationCondition: boolean = urls.some((url) => !!prevPathname?.includes(url));

    // Check if user has left all the urls (currPathname = none of the urls)
    const currLocationCondition: boolean = urls.every((url) => !currPathname?.includes(url));

    return (prevLocationCondition && currLocationCondition);
  };

  resetTableStateCheck = (prevProps: Props, props: Props) => {
    const { resetTableState } = this.props;

    const prevPathname: string | undefined = prevProps.location?.pathname;
    const currPathname: string | undefined = props.location?.pathname;

    for (const tuple of tableScopesList) {
      const [
        tableName,
        urls,
      ] = tuple;
      if (this.userHasLeftModule(prevPathname, currPathname, urls)) {
        resetTableState(tableName);
      }
    }
  };

  render(): JSX.Element {
    const {
      children,
      isLoadingActive,
      isBlockingLoadingActive,
    } = this.props;

    return (
      <div className='data-container'>
        <LinearProgressBar isLoadingActive={isLoadingActive} />
        <CircularProgressBar isBlockingLoadingActive={isBlockingLoadingActive} />
        {children}
      </div>
    );
  }
}

export default withRouter(connector(DataContainer));
