import {
  CollectionItemsRequestModel,
  CollectionRequestModel,
  IdsRequestModel,
  ReorderCollectionItemsRequestModel,
  ReorderCollectionsRequestModel,
  UpdateCollectionRequestModel,
} from '@api/squadlockerServices/models';
import collectionsUrls from '@constants/sqdApiUrls/collectionsUrls';
import { CollectionFormData } from '@models/forms/Collections/CollectionFormData';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import { slServicesApi } from '../../sqdApis';
import { AddItemsToExistingCollectionFormData } from '@models/forms/Collections/AddItemsToExistingCollectionFormData';

const prepareCollectionItems = (form: CollectionFormData | AddItemsToExistingCollectionFormData): ({
  id: number;
  quantity: number;
  isLockerItem: boolean;
})[] => (
  form.itemGroups.map((itemGroup) => (
    itemGroup.items.map((item) => ({
      id: item.id,
      isLockerItem: item.is_locker_item,
      quantity: itemGroup.quantity,
    }))
  )).flat()
);

export const addCollection = async (addCollectionForm: CollectionFormData) => {
  const collectionItems: {
    id: number;
    quantity: number;
    isLockerItem: boolean;
  }[] = prepareCollectionItems(addCollectionForm);

  const body: CollectionRequestModel = {
    name: addCollectionForm.name,
    disabled: addCollectionForm.disabled,
    lockerId: addCollectionForm.lockerId,
    rosterTeamIds: addCollectionForm.rosterTeamIds ?? [],
    required: addCollectionForm.required,
    bundled: addCollectionForm.bundled,
    coloredStyleSelections: collectionItems
      .filter((item) => !item.isLockerItem)
      .map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    lockerItems: collectionItems
      .filter((item) => item.isLockerItem)
      .map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
  };

  const call = slServicesApi.post(collectionsUrls.collections, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const editCollection = async (editCollectionForm: CollectionFormData) => {
  const collectionItems: {
    id: number;
    quantity: number;
    isLockerItem: boolean;
  }[] = prepareCollectionItems(editCollectionForm);

  const body: UpdateCollectionRequestModel = {
    id: editCollectionForm.id,
    name: editCollectionForm.name,
    disabled: editCollectionForm.disabled,
    orderPlace: editCollectionForm.orderPlace,
    lockerId: editCollectionForm.lockerId,
    rosterTeamIds: editCollectionForm.rosterTeamIds ?? [],
    required: editCollectionForm.required,
    bundled: editCollectionForm.bundled,
    coloredStyleSelections: collectionItems
      .filter((item) => !item.isLockerItem)
      .map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    lockerItems: collectionItems
      .filter((item) => item.isLockerItem)
      .map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
  };

  const call = slServicesApi.put(collectionsUrls.collection(editCollectionForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const reorderCollections = async (lockerId: number, collections: any[]) => {
  const body: ReorderCollectionsRequestModel = {
    lockerId,
    collections: collections.map((collection) => ({
      id: collection.id,
      orderPlace: collection.orderPlace,
    })),
  };

  const call = slServicesApi.put(collectionsUrls.reorderCollections, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const deleteCollection = async (collectionId: number) => {
  const call = slServicesApi.delete(collectionsUrls.collection(collectionId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const deleteCollections = async (collections: any[]) => {
  const body: IdsRequestModel = {
    ids: collections.map((collection) => collection.id),
  };

  const call = slServicesApi.put(collectionsUrls.deleteCollections, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const addItemsToCollection = async (addItemsToCollectionForm: AddItemsToExistingCollectionFormData) => {
  const collectionItems: {
    id: number;
    quantity: number;
    isLockerItem: boolean;
  }[] = prepareCollectionItems(addItemsToCollectionForm);

  const body = {
    coloredStyleSelections: collectionItems
      .filter((item) => !item.isLockerItem)
      .map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    lockerItems: collectionItems
      .filter((item) => item.isLockerItem)
      .map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
  };

  const call = slServicesApi.post(collectionsUrls.collectionItems(addItemsToCollectionForm.collectionId), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const removeItemsFromCollection = async (collectionId: number, items: any[]) => {
  const body: CollectionItemsRequestModel = {
    coloredStyleSelectionIds: items.filter((item) => !item.isLockerItem).map((item) => item.id),
    lockerItemIds: items.filter((item) => item.isLockerItem).map((item) => item.id),
  };

  const call = slServicesApi.put(collectionsUrls.removeCollectionItems(collectionId), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const reorderCollectionItems = async (collectionId: number, items: any[]) => {
  const body: ReorderCollectionItemsRequestModel = {
    items: items.map((item) => ({
      id: item.id,
      orderPlace: item.orderPlace,
    })),
  };

  const call = slServicesApi.put(collectionsUrls.reorderCollectionItems(collectionId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
