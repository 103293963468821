import * as actionTypes from './types';
import { fulfillmentApi } from '../../sqdApis';
import printRoomUrls from '@constants/sqdApiUrls/printRoomUrls';
import weedingAndMaskingUrls from '@constants/sqdApiUrls/weedingAndMaskingUrls';
import { printSheetUrl } from '@constants/clientUrls/clientUrls';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';

export const updatePrintRoomArt = (artData) => ({
  type: actionTypes.UPDATE_PRINT_ROOM_ART,
  payload: artData,
});

export const updateSheetDetails = (sheetData) => ({
  type: actionTypes.UPDATE_SHEET_DETAILS,
  payload: sheetData,
});

export const fetchSheetDetails = (sheetId) => async (dispatch) => {
  const call = fulfillmentApi.get(printRoomUrls.sheet(sheetId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateSheetDetails(res));

    return res;
  }
};

export const changeArtworkInventoryLocation = (
  orderId,
  printSheetId,
  inventoryLocation
) => async (dispatch) => {
  const body = JSON.stringify({
    orderId,
    PrintSheetId: printSheetId,
    InventoryLocation: inventoryLocation,
  });

  const call = fulfillmentApi.put(printRoomUrls.inventoryOrder, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);
  if (res?.success) {
    return dispatch(fetchSheetDetails(printSheetId));
  }
};

export const markInPrinting = (sheetId) => async (dispatch) => {
  const call = fulfillmentApi.put(printRoomUrls.markSheetStatus(sheetId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status: printStatusEnum.Printing }),
  });

  const res = await makeApiCallWithErrorModal(call);
  if (res?.success) {
    return dispatch(fetchSheetDetails(sheetId));
  }
};

export const markPrinted = (sheetId) => async (dispatch) => {
  const call = fulfillmentApi.put(printRoomUrls.markSheetStatus(sheetId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status: printStatusEnum.Done }),
  });

  const res = await makeApiCallWithErrorModal(call);
  if (res?.success) {
    return dispatch(fetchSheetDetails(sheetId));
  }
};

export const startWeedingAndMasking = (sheetId) => async (dispatch) => {
  const call = fulfillmentApi.put(weedingAndMaskingUrls.startWeedingAndMasking(sheetId));
  const res = await makeApiCallWithErrorModal(call);

  if (res?.success) {
    return dispatch(fetchSheetDetails(sheetId));
  }
};

export const lookupPrintSheetByPrintSheetId = async (search) => {
  const call = fulfillmentApi.get(printRoomUrls.sheet(search));
  const res = await makeApiCallWithErrorModal(call);

  if (res) {
    window.location = printSheetUrl(search);
  }
};

export const lookupPrintSheet = async (search) => {
  const call = fulfillmentApi.get(printRoomUrls.searchSheet(search));
  const res = await makeApiCallWithErrorModal(call);

  if (res) {
    let location = printSheetUrl(res.sheetId);
    if (res.orderNumber) {
      location = `${location}/${res.orderNumber}`;
      if (res.artworkBarcode) {
        location = `${location}/${res.artworkBarcode.replace('/', '&')}`;
      }
    }
    window.location = location;
  }
};

export const rejectPrintSheet = async (artworkTaskReworks, rejectSheet, sheetId) => {
  const body = {
    artworkTaskReworks,
    rejectSheet,
    printQueueId: sheetId,
  };

  const call = fulfillmentApi.put(printRoomUrls.rejectPrintQueue, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
