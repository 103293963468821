const ID = 'logoReview' as const;

export const UPDATE_TASKS_TABLE = `${ID}/UPDATE_TASKS_TABLE` as const;
export const UPDATE_LOGO_REVIEWER_TABLE_INFO = `${ID}/UPDATE_LOGO_REVIEWER_TABLE_INFO` as const;
export const UPDATE_TASK_FILTERS = `${ID}/UPDATE_TASK_FILTERS` as const;
export const UPDATE_USER_TASKS = `${ID}/UPDATE_USER_TASKS` as const;
export const REMOVE_REVIEWER_TASKS = `${ID}/REMOVE_REVIEWER_TASKS` as const;
export const UPDATE_REVIEWED_LOGOS = `${ID}/UPDATE_REVIEWED_LOGOS` as const;
export const CLEAR_REVIEWED_LOGOS = `${ID}/CLEAR_REVIEWED_LOGOS` as const;
export const UPDATE_LOCKER_LOGOS = `${ID}/UPDATE_LOCKER_LOGOS` as const;

export default ID;
