import React, { useEffect, useCallback, useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';

import { getGetHomefieldApiV2MerchandisingIdQueryKey, postHomefieldApiV2Merchandising, putHomefieldApiV2Merchandising, usePostHomefieldApiV2Merchandising, usePutHomefieldApiV2Merchandising } from '@api/accounts/merchandising';
import { UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import HookformDatePicker from '@components/shared/Form/HookformDatePicker';
import HookformInput from '@components/shared/Form/HookformInput';
import NewHookformSelect from '@components/shared/Form/NewHookFormSelect';
import { isMoment } from 'moment';
import moment from 'moment-timezone';
import Modal from '@components/shared/Modal/Modal';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import { MerchandisingEffortDto, MerchandisingEffortStatus, PutHomefieldApiV2MerchandisingBody } from '@api/accounts/models';
import enumHelper from '@constants/enums/enumHelper';
import { planogramTypeEnum } from '@constants/enums/merchandisingEffortEnums';
import { createOptionsList } from '@util/optionsMap';
import { useQueryClient } from 'react-query';

const planogramTypeOptions = createOptionsList({
  list: enumHelper.getSelectOptionsList(planogramTypeEnum),
  key: 'value',
  value: 'value',
  name: 'label',
});

export interface MerchandisingEfforFormData {
  name: string;
  startDate?: string;
  endDate?: string;
  type?: string;
}

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  item?: MerchandisingEffortDto;
  isCreate?: boolean;
}

const EditMerchandisingEffortDialog = ({ isOpen, closeModal, onSubmit, item, isCreate = false }: OwnProps) => {
  const allowStartDateEdit = item?.status === MerchandisingEffortStatus.Created
    || item?.status === MerchandisingEffortStatus.Ready
    || item?.status === undefined;

  const schema = yup.object({
    name: yup.string().required('Name is required.'),
    startDate: yup.string().nullable()
      .required('Start Date is required.')
      .test('startdate--greater-than-today', 'Start Date must be equal to today or in the future', (startDate: string | null | undefined) => {
        if (!startDate || !allowStartDateEdit) return true;
        const sd = isMoment(startDate) ? (startDate as unknown) as moment.Moment : moment(startDate);

        return sd.tz('America/New_York').isSameOrAfter(moment().tz('America/New_York'), 'day');
      }),
    endDate: yup.string().nullable()
      .test('enddate-before-or-same-as-startdate', 'End Date must be after Start Date', (endDate: string | null | undefined) => {
        const sdVal = getValues('startDate');

        if (!endDate) return true;

        const sd = isMoment(sdVal) ? (sdVal as unknown) as moment.Moment : moment(sdVal);
        const ed = isMoment(endDate) ? (endDate as unknown) as moment.Moment : moment(endDate);
        const isSameOrBefore = ed.isSameOrBefore(sd);

        return !isSameOrBefore;
      }),
    type: yup.string().required('Type is required'),
  });

  const queryClient = useQueryClient();
  const { mutateAsync: updateEffort } = usePutHomefieldApiV2Merchandising({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getGetHomefieldApiV2MerchandisingIdQueryKey(item?.id ?? 0)
        );
      },
    },
  });
  const { mutateAsync: createEffort } = usePostHomefieldApiV2Merchandising();

  const defaultValues: MerchandisingEfforFormData = useMemo(() => ({
    name: item?.name ?? '',
    startDate: item?.startDate ?? '',
    endDate: item?.endDate ?? '',
    type: item?.type ?? planogramTypeEnum._displayNames[0],
  }), [item]);

  const formMethods: UseFormReturn<MerchandisingEfforFormData> = useForm<MerchandisingEfforFormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (item) {
      if (item.name) {
        setValue('name', item.name);
      }
      if (item.startDate) {
        setValue('startDate', item.startDate);
      }
      if (item.endDate) {
        setValue('endDate', item.endDate);
      }
      if (item.type) {
        setValue('type', item.type);
      }
    }
  }, [
    item,
    setValue,
  ]);

  const handleCloseModal = useCallback(() => {
    reset();
    closeModal();
  }, [closeModal, reset]);

  const formatPayload = useCallback((data: MerchandisingEfforFormData): PutHomefieldApiV2MerchandisingBody => {
    const payload: PutHomefieldApiV2MerchandisingBody = {
      id: item?.id,
      ...data,
    };

    if (payload?.startDate) {
      payload.startDate = moment(payload.startDate).format('YYYY-MM-DD');
    }

    if (payload?.endDate) {
      payload.endDate = moment(payload.endDate).format('YYYY-MM-DD');
    }

    return payload;
  }, [item?.id]);

  const handleFormSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      const payload = formatPayload(data);
      try {
        if (!isCreate) {
          await updateEffort({ data: payload });
        } else {
          await createEffort({ data: payload });
        }
        onSubmit();
        handleCloseModal();
      } catch (error) {
        console.error('error', error);
      }
    })();
  }, [
    handleCloseModal, handleSubmit, onSubmit, formatPayload, isCreate, updateEffort, createEffort,
  ]);

  return (
    <Modal
      title={`${isCreate ? 'Create' : 'Edit'} Merchandising Effort`}
      modalSize={'l'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={`${isCreate ? 'Add' : 'Save'}`}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={'edit-merchandising-effort-form'}
        />
      )}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate={true} id='edit-merchandising-effort-form'>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={11}>
            <HookformInput
              id='name'
              name='name'
              placeholder='Enter Merchandising Effort Name'
              label='Name'
              type='text'
              autoFocus={true}
              error={errors?.name?.message}
              required={true}
              control={control}
            />
          </Grid>
          <Grid item={true} xs={11}>
            <Box display='flex' justifyContent='space-between'>
              <HookformDatePicker
                control={control}
                name='startDate'
                id='startDate'
                placeholder='Enter Start Date'
                label='Start Date'
                required={true}
                disabled={!allowStartDateEdit}
                error={errors?.startDate?.message}
              />
              <HookformDatePicker
                control={control}
                name='endDate'
                id='endDate'
                placeholder='Enter End Date'
                label='End Date'
                required={false}
                error={errors?.endDate?.message}
              />
            </Box>
          </Grid>
          <Grid item={true} xs={5}>
            <NewHookformSelect
              id='type'
              name='type'
              placeholder='Choose Type'
              label='Type'
              error={errors?.type?.message}
              required={true}
              control={control}
              options={planogramTypeOptions}
            />
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default EditMerchandisingEffortDialog;
