import React, {
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { CollectionPreview } from '@api/financialServices/models';
import { useGetVoucherCreationRosters } from '@api/squadlockerServices/vouchers';
import {
  VoucherCreationRosterViewModel,
  VoucherCreationLockerViewModel as Locker,
} from '@api/squadlockerServices/models';
import { usePostHomefieldApiVouchersOrderspreview as usePostVoucherOrderPreview } from '@api/financialServices/voucher-homefield';
import { formatNumber } from '@util/numberHelpers';
import Spinner from '@sharedComponents/Display/Spinner';
import DataGrid from '@sharedComponents/DataGrid/DataGrid';
import InfoIconWithTooltip from '@components/shared/Icons/InfoIconWithTooltip';

const groupByColumns = ['lockerId'] as (keyof VoucherCreationRosterViewModelCustom)[];

export type VoucherCreationRosterViewModelCustom = VoucherCreationRosterViewModel & {
  totalAmount: number;
  hasGroupedCollectionItems: boolean;
};

interface OwnProps {
  selectedLockers: Locker[];
  selectedRosterCollections: VoucherCreationRosterViewModelCustom[];
  updateSelectedRosterCollections: (rosterCollections: VoucherCreationRosterViewModelCustom[]) => void;
}

type Props = OwnProps;

const CollectionsAndRostersStep = React.memo<Props>(({
  selectedLockers,
  selectedRosterCollections,
  updateSelectedRosterCollections,
}) => {
  const [
    rosterCustomData,
    setRosterCustomData,
  ] = useState<VoucherCreationRosterViewModelCustom[] | undefined>(undefined);
  const [
    selectedRosterIds,
    setSelectedRosterIds,
  ] = useState<number[]>(selectedRosterCollections.map((x) => x.id));

  const selectedLockerIds = useMemo(() => (
    selectedLockers.map((x) => x.id)
  ), [selectedLockers]);

  const selectedOrganizationId = useMemo(() => (
    selectedLockers[0].organizationId
  ), [selectedLockers]);

  const {
    data: rostersData,
    refetch: fetchRosters,
  } = useGetVoucherCreationRosters({
    lockerId: selectedLockerIds,
  });

  const {
    data: voucherOrderPreviewData,
    mutateAsync: createVoucherOrderPreview,
  } = usePostVoucherOrderPreview(
    { request: { isBlockingRequest: false } }
  );

  const selectedLockersRosterCollections = useMemo(() => (
    rostersData?.map((x: VoucherCreationRosterViewModel) => ({
      collectionId: x.collections[0].id,
      rosterTeamId: x.id,
    }))
  ), [rostersData]);

  const dataGridColumns = useMemo(() => {
    const rosterCollectionsTotalAmounts = selectedRosterCollections.map((r) => r.totalAmount);
    const total = rosterCollectionsTotalAmounts.reduce((previous, current) => previous + current, 0);

    return [
      {
        name: 'Locker',
        key: 'lockerId' as keyof VoucherCreationRosterViewModelCustom,
        sortable: true,
        selectionColumn: true,
        groupByDisabled: false,
        visible: true,
        groupTitleTemplate: (row: any) => (
          `${row.lockerId} - ${selectedLockers.find((x) => x.id === row.lockerId)?.name}`
        ),
        groupAdditionalInfoTemplate: (row: VoucherCreationRosterViewModelCustom): string => {
          const totalForLocker = selectedRosterCollections
            .reduce((previous, current) => {
              if (current.lockerId !== row.lockerId) {
                return previous;
              }

              return previous + current.totalAmount;
            }, 0);

          return `Locker Total: $${formatNumber(totalForLocker)}`;
        },
      },
      {
        name: 'Roster',
        key: 'teamName' as keyof VoucherCreationRosterViewModelCustom,
        sortable: true,
        selectionColumn: false,
        groupByDisabled: true,
        visible: true,
      },
      {
        name: 'Members',
        key: 'rosterTeamMembersCount' as keyof VoucherCreationRosterViewModelCustom,
        sortable: true,
        selectionColumn: false,
        groupByDisabled: true,
        visible: true,
      },
      {
        name: 'Collection',
        key: 'collections' as keyof VoucherCreationRosterViewModelCustom,
        sortable: false,
        selectionColumn: false,
        groupByDisabled: true,
        visible: true,
        template: (row: VoucherCreationRosterViewModelCustom) => (`${row.collections[0].name}`),
      },
      {
        name: `Price (Total: $${formatNumber(total)})`,
        key: 'totalAmount' as keyof VoucherCreationRosterViewModelCustom,
        sortable: false,
        selectionColumn: false,
        groupByDisabled: true,
        visible: true,
        template: (row: VoucherCreationRosterViewModelCustom) => {
          if (!row.totalAmount) {
            return <Spinner size='20px' />;
          }

          const totalAmountPreview = `$${formatNumber(row.totalAmount)}`;

          if (row.hasGroupedCollectionItems) {
            return (
              <div className='align__center'>
                <div>{totalAmountPreview}</div>
                <InfoIconWithTooltip
                  tooltipText={'The voucher price reflects the higher price of any youth/adult items'}
                />
              </div>
            );
          }

          return totalAmountPreview;
        },
      },
    ];
  }, [
    selectedLockers,
    selectedRosterCollections,
  ]);

  useEffect(() => {
    fetchRosters();
  }, [fetchRosters]);

  useEffect(() => {
    if (!selectedLockersRosterCollections || !selectedLockersRosterCollections.length) {
      return;
    }

    createVoucherOrderPreview({
      data: {
        lockerId: !!selectedOrganizationId ? null : selectedLockerIds[0],
        organizationId: selectedOrganizationId,
        collections: selectedLockersRosterCollections,
      },
    });
  }, [
    rostersData,
    selectedOrganizationId,
    selectedLockerIds,
    selectedLockersRosterCollections,
    createVoucherOrderPreview,
  ]);

  useEffect(() => {
    const voucherOrderCollections = voucherOrderPreviewData?.result?.collections;
    const rosterDataNew: VoucherCreationRosterViewModelCustom[] = [];
    if (!rostersData?.length) return;

    (rostersData!).forEach((roster) => {
      const rosterCollection: CollectionPreview | undefined = voucherOrderCollections?.find(
        (c: CollectionPreview) => c.collectionId === roster.collections[0].id && c.rosterTeamId === roster.id
      );
      const totalAmount = rosterCollection?.totalAmount ?? 0;
      const hasGroupedCollectionItems: boolean
        = rosterCollection?.items!.some((item) => item.collectionItemIds!.length > 1) ?? false;

      const rosterNew = {
        ...roster,
        totalAmount,
        hasGroupedCollectionItems,
      };
      rosterDataNew.push(rosterNew);
    });
    setRosterCustomData(rosterDataNew);
    const selectedRosterCollectionsNew = rosterDataNew.filter((x) => selectedRosterIds.includes(x.id));
    updateSelectedRosterCollections(selectedRosterCollectionsNew);
  }, [
    voucherOrderPreviewData,
    rostersData,
    selectedRosterIds,
    updateSelectedRosterCollections,
  ]);

  const onSelectionChange = useCallback((rosterTeamIds: number[]) => {
    const rosterCollections = rosterCustomData!.filter((r) => rosterTeamIds.includes(r.id));

    updateSelectedRosterCollections(rosterCollections);
    setSelectedRosterIds(rosterTeamIds);
  }, [
    rosterCustomData,
    updateSelectedRosterCollections,
  ]);

  const groupLayerProperties = useMemo(() => (
    [
      {
        number: 1,
        alwaysExpanded: false,
        height: 60,
        showCount: false,
      },
    ]
  ), []);

  return (
    <DataGrid<VoucherCreationRosterViewModelCustom, 'id'>
      rows={rosterCustomData}
      columns={dataGridColumns}

      selectable={true}
      selectionKey={'id'}
      onSelectionChange={onSelectionChange}
      selectedIds={selectedRosterIds}

      groupLayersCount={1}
      groupLayersProperties={groupLayerProperties}
      groupByColumns={groupByColumns}
      defaultGroupingOnly={true}
    />
  );
});

export default CollectionsAndRostersStep;
