import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { fileNameFromUrlWithExtension } from '@util/stringHelpers';
import { formatDollarAmount } from '@util/numberHelpers';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import Chip from '@sharedComponents/Inputs/Chips/Chip';
import InfoField from '@sharedComponents/Display/InfoField';

class HeaderDetails extends PureComponent {
  state = {
    imageModalIsOpen: false,
    imageUrl: '',
  };

  openModalWithImage = (imageUrl) => {
    this.setState(() => ({
      imageModalIsOpen: true,
      imageUrl,
    }));
  };

  closeImageModal = () => {
    this.setState(() => ({
      imageModalIsOpen: false,
      imageUrl: '',
    }));
  };

  getSportChips = (styleSports) => {
    let sports = null;
    if (styleSports && styleSports.length > 0) {
      sports = styleSports.map((sport, index) => (
        <Chip
          key={index}
          text={sport.name}
        />
      ));
    }

    return sports;
  };

  getLayoutChips = (styleLayouts) => {
    let layouts = null;
    if (styleLayouts && styleLayouts.length > 0) {
      layouts = styleLayouts.map((layout, index) => (
        <Chip
          key={index}
          text={layout.name}
        />
      ));
    }

    return layouts;
  };

  getLocationChips = (locations) => {
    const { decorationLocations } = this.props;
    let mappedLocations = null;

    if (locations && locations.length > 0) {
      mappedLocations = locations.map((l, index) => {
        let text = l.location;
        const location = decorationLocations.find((x) => x.code === l.location);
        if (location) {
          text = `${location.code} - ${location.label}`;
        }

        return (
          <Chip
            key={index}
            text={text}
          />
        );
      });
    }

    return mappedLocations;
  };

  render() {
    const {
      style,
      styleSports,
      styleLayouts,
      sampleImage,
    } = this.props;

    const {
      imageModalIsOpen,
      imageUrl,
    } = this.state;

    const sports = this.getSportChips(styleSports);
    const layouts = this.getLayoutChips(styleLayouts);
    const logoLocations = this.getLocationChips(style.logoLocations);
    const personalizationLocations = this.getLocationChips(style.personalizationLocations);

    return (
      <div className='catalog-details__container'>
        <ImagePreviewModal
          modalIsOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />
        <div className='catalog-details__info'>
          <div className='catalog-details__info--image-container'>
            <div className=''>
              <div className='catalog-details__image'>
                <ImagePreviewCell
                  imageUrl={sampleImage}
                  openModalWithUrl={this.openModalWithImage}
                  height={'148px'}
                  width={'148px'}
                />
              </div>
              <div className='flex'>
                <div className='product-catalog__details-column--first'>
                  <InfoField
                    label={'New Stores'}
                    value={style.availableForNewStores ? 'Yes' : 'No'}
                  />
                  <InfoField
                    label={'Price'}
                    value={formatDollarAmount(style.price)}
                  />
                </div>
              </div>
            </div>

            <div className='catalog-details__info--details'>
              <div className='flex'>
                <div className='product-catalog__details-column--left'>
                  <InfoField
                    label={'Parent SKU'}
                    value={style.code}
                  />
                  <InfoField
                    label={'Gender'}
                    value={style.gender}
                  />
                  <InfoField
                    label={'Recommended'}
                    value={style.recommended ? 'Yes' : 'No'}
                  />
                  <InfoField
                    label={'Discount'}
                    value={formatDollarAmount(style.discount)}
                  />
                </div>
                <div className='product-catalog__details-column--center'>
                  <InfoField
                    label={'Category'}
                    value={style.category && style.category.name}
                  />
                  <InfoField
                    label={'Age'}
                    value={style.age}
                  />
                  <InfoField
                    label={'VFI'}
                    value={style.vendorFulfilledItem ? 'Yes' : 'No'}
                  />
                  <InfoField
                    label={'Size Chart (PDF)'}
                    value={
                      style.sizeChartUrl && (
                        <a
                          href={style.sizeChartUrl}
                          className='catalog-details__link'
                        >
                          {fileNameFromUrlWithExtension(style.sizeChartUrl)}
                        </a>
                      )
                    }
                  />
                </div>
                <div className='product-catalog__details-column--center'>
                  <InfoField
                    label={'Brand'}
                    value={style.brand && style.brand.name}
                  />
                  <InfoField
                    label={'Tax Code'}
                    value={style.taxCode}
                    fieldClass={'catalog-details__info--tax-code-container'}
                  />
                  <InfoField
                    label={'Vendor ID'}
                    value={style.vendorId}
                  />
                </div>
              </div>
            </div>
          </div>

          <InfoField
            label={'Style Description'}
            value={style.description}
          />
        </div>
        <div className='catalog-details__locations'>
          <InfoField
            label={'Sports Applied for'}
            value={sports}
          />
          <div className='flex'>
            <InfoField
              label={'Decoration'}
              value={style.decorationMethod}
            />
            <InfoField
              label={'Customizable'}
              fieldClass={'ml-20'}
              value={style.customizable ? 'Yes' : 'No'}
            />
          </div>
          <InfoField
            label={'Logo Locations'}
            value={logoLocations}
          />
          <InfoField
            label={'Personalization Locations'}
            value={personalizationLocations}
          />
          <InfoField
            label={'Layouts'}
            value={layouts}
          />
        </div>
      </div>
    );
  }
}

HeaderDetails.propTypes = {
  style: PropTypes.object.isRequired,
  sampleImage: PropTypes.string,
  styleSports: PropTypes.arrayOf(PropTypes.object),
  styleLayouts: PropTypes.arrayOf(PropTypes.object),
  decorationLocations: PropTypes.arrayOf(PropTypes.object),
};

export default HeaderDetails;
