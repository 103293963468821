import React from 'react';
import { brandsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const BrandsLink = React.memo(() => (
  <MenuTile
    title={'Brands'}
    url={brandsUrl}
    icon={'branding_watermark'}
  />
));

export default BrandsLink;
