import React from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import Card from '@sharedComponents/Display/Card';

const LockerCard = React.memo(({
  locker,
  customClass = '',
}) => (
  <Card
    title={locker.teamName}
    imageUrl={`${lockerMgrS3Logos}/${locker.logoUrl}`}
    classes={customClass}
  />
));

LockerCard.propTypes = {
  locker: PropTypes.object.isRequired,
  customClass: PropTypes.string,
};

export default LockerCard;
