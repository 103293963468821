export const services = 'homefield/api/services' as const;

const servicesAction = (action: string): string => `${services}.${action}`;

export const startService = servicesAction('start');
export const stopService = servicesAction('stop');
export const restartService = servicesAction('restart');

export default {
  services,
  startService,
  stopService,
  restartService,
};
