import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  tooltipText: JSX.Element | string;
  classes?: string;
}

type Props = OwnProps;

const InfoIconWithTooltip = React.memo<Props>(({
  tooltipText,
  classes = '',
}) => (
  <MaterialTooltip
    tooltipText={tooltipText}
    placement={'top'}
    contentClasses={`align__center ${classes}`}
  >
    <Icon
      materialIcon={'info_outline'}
      classes={'icon--info cursor-pointer ml-5'}
    />
  </MaterialTooltip>
));

export default InfoIconWithTooltip;
