import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Assignee } from '@models/ProductionArtwork/Assignee';
import { mapToOptionsList } from '@util/mappingHelper';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

interface OwnProps {
  filter: (assignee: string) => void;
  assignees: Assignee[];
  status: string;
}

type Props = OwnProps;

const AssigneeFilter = React.memo<Props>(({
  assignees,
  filter,
  status,
}) => {
  const [
    selectedAssigneeId,
    setSelectedAssigneeId,
  ] = useState<string>('');

  useEffect(() => {
    setSelectedAssigneeId('');
  }, [status]);

  const updateSelection = useCallback((value: string) => {
    setSelectedAssigneeId(value);
    filter(value);
  }, [filter]);

  const getAssigneeDisplayName = (assignee: Assignee) => assignee.displayName || assignee.email;

  const options = useMemo(() => (
    mapToOptionsList<string, any>({
      list: assignees,
      key: 'id',
      value: 'id',
      name: getAssigneeDisplayName as any,
      emptyOption: {
        name: 'All assignees',
      },
    })
  ), [assignees]);

  return (
    <div className='filter-group'>
      <Dropdown
        options={options}
        defaultValue={selectedAssigneeId}
        onChange={updateSelection}
      />
    </div>
  );
});

export default AssigneeFilter;
