import { fulfillmentApi } from '../../sqdApis';
import { makeApiCallWithErrorModal } from '@util/apiHelper';
import weedingAndMaskingUrls from '@constants/sqdApiUrls/weedingAndMaskingUrls';
import printRoomUrls from '@constants/sqdApiUrls/printRoomUrls';
import { printStatusEnum } from '@constants/enums/printRoomEnums';

export const markPriorityItemInPrint = async (itemId) => {
  const call = fulfillmentApi.put(weedingAndMaskingUrls.markQueueItemInPrint(itemId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unflagQueueItem = async (itemId) => {
  const call = fulfillmentApi.put(weedingAndMaskingUrls.unflagQueueItem(itemId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markSheetStatus = async (nextStatus, id) => {
  const url = printRoomUrls.markSheetStatus(id);

  const allowedStatuses = [
    printStatusEnum.PrintReady,
    printStatusEnum.Printing,
    printStatusEnum.Done,
  ];

  const body = allowedStatuses.includes(nextStatus) ? { status: nextStatus } : null;

  if (body) {
    const call = fulfillmentApi.put(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const res = await makeApiCallWithErrorModal(call);
    if (res?.success) {
      if (nextStatus === printStatusEnum.PrintReady) return 'Print Queue';
      if (nextStatus === printStatusEnum.Printing) return 'In Print';
      if (nextStatus === printStatusEnum.Done) return 'Printed';
    }
  } else {
    return false;
  }
};
