export const getItemQuantityCount = (sheet) => {
  let logoCount = 0;
  let personalizationCount = 0;

  sheet.items.forEach((sheetItem) => {
    if (!sheetItem.isDeleted) {
      if (sheetItem.logoPrintItems && sheetItem.logoPrintItems.length > 0) {
        logoCount += sheetItem.logoPrintItems.reduce((total, logoPrintItem) => {
          if (!logoPrintItem.isCanceled) {
            return total + logoPrintItem.quantity;
          }

          return total;
        }, 0);
      }

      if (sheetItem.personalizations && sheetItem.personalizations.length > 0) {
        personalizationCount += sheetItem.personalizations.reduce((total, personalizationItem) => {
          if (!personalizationItem.isCanceled) {
            return total + personalizationItem.quantity;
          }

          return total;
        }, 0);
      }
    }
  });

  if (logoCount === 0) {
    logoCount = '-';
  }

  if (personalizationCount === 0) {
    personalizationCount = '-';
  }

  return {
    logoCount,
    personalizationCount,
  };
};
