/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import type {
  StripeChargeInfoDto,
  ApiResponse,
  GetHomefieldApiFinancialsStripechargeinfoParams
} from './models'
import { ordersApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiFinancialsStripechargeinfo = <TData = StripeChargeInfoDto>(
    params?: GetHomefieldApiFinancialsStripechargeinfoParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/financials/stripe-charge-info`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiFinancialsStripechargeinfoQueryKey = (params?: GetHomefieldApiFinancialsStripechargeinfoParams,) => [`/homefield/api/financials/stripe-charge-info`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiFinancialsStripechargeinfo = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiFinancialsStripechargeinfo, StripeChargeInfoDto>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiFinancialsStripechargeinfoParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiFinancialsStripechargeinfoQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiFinancialsStripechargeinfo<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

