import React, {
  useCallback,
  useState,
} from 'react';
import { keyNameEnum } from '@constants/enums/commonEnums';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  filter: (value: string) => void;
  initialValue?: string;
}

type Props = OwnProps;

const PartnerFilter = React.memo<Props>(({
  filter,
  initialValue,
}) => {
  const [
    filterValue,
    setFilterValue,
  ] = useState<string>('');

  const changeFilter = useCallback((event) => {
    setFilterValue(event.target.value);
  }, []);

  const filterOnClick = useCallback(() => {
    filter(filterValue);
  }, [
    filter,
    filterValue,
  ]);

  const runSearch = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    filterOnClick();
  }, [filterOnClick]);

  return (
    <div className='filter-group'>
      <div>
        <div className='text-field'>
          <input
            type='text'
            placeholder='Partner Name'
            className='has-icon'
            onKeyDown={runSearch}
            onChange={changeFilter}
            defaultValue={initialValue}
          />
          <Icon
            materialIcon={'search'}
            classes={'text-field__icon'}
          />
        </div>
      </div>
    </div>
  );
});

export default PartnerFilter;
