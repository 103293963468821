import React, { Component } from 'react';
import { connect } from 'react-redux';
import userManager from '@util/userManager';

class LoginPage extends Component {
  componentDidMount() {
    this.onLoginButtonClick();
  }

  onLoginButtonClick() {
    sessionStorage.setItem('userLoading', true);
    userManager.signinRedirect();
  }

  render() {
    return (
      <div className='login-container'>
        <header>
          <h2>Login with Identity</h2>
        </header>
      </div>
    );
  }
}

export default connect()(LoginPage);
