import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TextInput extends Component {
  state = {
    value: this.props.initialValue,
  };

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;

    if (prevProps.initialValue !== initialValue) {
      this.setState({ value: initialValue });
    }
  }

  updateValue = (e) => {
    const { onChange, controlledValue } = this.props;

    if (!controlledValue) {
      const { value } = e.target;
      this.setState(() => ({ value }));
    }
    onChange(e);
  };

  render() {
    const {
      placeholder = 'Search',
      classes = '',
      inputClasses = '',
      disabled = false,
      icon = undefined,
      controlledValue,
    } = this.props;

    const { value } = this.state;

    return (
      <div className={`text-field ${classes}`}>
        <input
          className={inputClasses}
          type='text'
          placeholder={placeholder}
          onChange={this.updateValue}
          value={controlledValue ? controlledValue : value || ''}
          disabled={disabled}
        />
        {
          icon &&
          <div className='text-field__icon--right'>{icon}</div>
        }
      </div>
    );
  }
}

TextInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  classes: PropTypes.string,
  inputClasses: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  controlledValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
