import React,
{
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchSchedulingQueue } from '@redux/llScheduling/actions';
import SchedulingQueueHeader from './SchedulingQueueHeader';
import SchedulingTabs from './SchedulingTabs';

const SchedulingQueueDetails = React.memo(({
  schedulingQueue,
  dispatch,
}) => {
  const params = useParams();
  const queueId = parseInt(params.queueId || '-1');

  useEffect(() => {
    dispatch(fetchSchedulingQueue(queueId));
  }, [queueId]);

  const getSchedulingQueue = useCallback(() => {
    dispatch(fetchSchedulingQueue(queueId));
  }, [queueId]);

  return (
    <div className='container'>
      {
        schedulingQueue && schedulingQueue.id &&
        <>
          <SchedulingQueueHeader
            capacityUpdatedCallback={getSchedulingQueue}
          />

          <SchedulingTabs
            dispatchForPickingCallback={getSchedulingQueue}
          />
        </>
      }
    </div>
  );
});

SchedulingQueueDetails.propTypes = {
  schedulingQueue: PropTypes.object.isRequired,
};

const mapStateToProps = ({ llScheduling }) => ({
  schedulingQueue: llScheduling.schedulingQueue,
});

export default connect(mapStateToProps)(SchedulingQueueDetails);
