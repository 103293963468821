const ID = 'WEEDINGANDMASKINGROOM' as const;

export const UPDATE_SHEET_AND_ORDER = `${ID}/UPDATE_SHEET_AND_ORDER` as const;
export const UPDATE_CURRENT_QUEUE = `${ID}/UPDATE_CURRENT_QUEUE` as const;
export const CLEAR_QUEUE = `${ID}/CLEAR_QUEUE` as const;
export const UPDATE_COUNTS = `${ID}/UPDATE_COUNTS` as const;

export const UPDATE_ORDERS_LIST = `${ID}/UPDATE_ORDERS_LIST` as const;
export const CLEAR_ORDERS_LIST = `${ID}/CLEAR_ORDERS_LIST` as const;

export const CHECK_INVENTORY = `${ID}/CHECK_INVENTORY` as const;

export default ID;
