import React, {
  useCallback,
  useState,
} from 'react';
import { connect } from 'react-redux';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import Icon from '@sharedComponents/Icons/Icon';

const acceptedFileType = '.png' as const;

export interface UploadFileMessage {
  type: 'default' | 'reject';
  body: string;
}

interface OwnProps {
  changeImageUrl: (
    url: Nullable<string>,
    message: UploadFileMessage,
    acceptedFile: Nullable<File>
  ) => void;
  maxWidth?: number;
  uploadIsActive: boolean;
  message: UploadFileMessage;
}

type Props = OwnProps;

const UploadImage = React.memo<Props>(({
  uploadIsActive,
  message,
  changeImageUrl,
  maxWidth,
}) => {
  const [
    loading,
    setLoading,
  ] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: File[]): void => {
    const reader = new FileReader();
    const image = new Image();
    const file = acceptedFiles[0];

    let newMessage: UploadFileMessage | undefined;

    if (rejectedFiles.length > 0) {
      newMessage = {
        type: 'reject',
        body: `${acceptedFileType} files required.`,
      };
      changeImageUrl(null, newMessage, null);
    } else {
      reader.onloadend = () => {
        const result = reader.result as string;

        image.src = result;
        image.onload = () => {
          if (maxWidth && image.width > maxWidth) {
            newMessage = {
              body: `Image width should be ${maxWidth}px or less.`,
              type: 'default',
            };
            changeImageUrl(null, newMessage, null);
          } else {
            newMessage = {
              body: `File ${acceptedFiles[0].name} has been attached.`,
              type: 'default',
            };
            changeImageUrl(result, newMessage, acceptedFiles[0]);
          }
        };
      };

      reader.readAsDataURL(file);
    }
  }, [
    changeImageUrl,
    maxWidth,
  ]);

  const dropzoneContent = loading
    ? (
      <label className='button'>
        <Icon
          fontAwesomeIcon={'spinner'}
          classes={'fa-spin'}
        />
          Uploading...
      </label>
    )
    : <label className={`button message-${message.type}`}>{message.body}</label>;

  return (
    <div className={`mb-10 p-l-16 p-r-16 lockerEdit__information-change-logos ${uploadIsActive && 'is-active'}`}>
      <div className='upload-field--horizontal m-t-16 w-100'>
        <div className='upload-field'>
          <DropZoneWrapper
            onDrop={onDrop}
            multiple={false}
            className={'block-drop-zone'}
            accept={acceptedFileType}
          >
            {dropzoneContent}
          </DropZoneWrapper>
        </div>
      </div>
    </div>
  );
});

export default connect()(UploadImage);
