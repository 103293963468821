import { buildQueryString } from '@util/stringHelpers';

export const rosters = 'homefield/api/rosters' as const;
export const rosterManager = 'homefield/api/roster-manager' as const;

export const rostersTable = (
  pageNumber: number,
  pageSize: number,
  sortColumn: string | null = null,
  sortDirection: string | null = null,
  disabled: boolean | null = null,
  search: string | null = null,
  organizationId: number | null = null,
  lockerId: number | null = null
): string => `${rosters}${buildQueryString({
  pageNumber,
  pageSize,
  sortColumn,
  sortDirection,
  disabled,
  search,
  organizationId,
  lockerId,
})}`;

export const rostersList = `${rosters}/list` as const;

export const rosterCSVFile = (
  rosterTeamId: number,
  lockerId: number,
  organizationId: number,
  empty: boolean
): string => `${rosters}/csv${buildQueryString({
  rosterTeamId,
  lockerId,
  organizationId,
  empty,
})}`;

export const parseRosterCSVFile = `${rosters}/parse-csv` as const;

export const rosterTeam = (rosterTeamId: number): string => `${rosters}/${rosterTeamId}`;

export const reimportRoster = (rosterTeamId: number): string => `${rosterTeam(rosterTeamId)}/re-import`;

export const rosterTeamMembers = (rosterTeamId: number): string => `${rosterTeam(rosterTeamId)}/members`;

export const removeTeamMembers = (rosterTeamId: number): string => `${rosterTeam(rosterTeamId)}/team-members.remove`;

export const teamMembers = `${rosters}/team-members` as const;

export const teamMember = (teamMemberId: number): string => `${teamMembers}/${teamMemberId}`;

export const removeRosters = `${rosters}/roster-teams.remove` as const;

export const toggleDisabledRosters = `${rosters}/roster-teams.toggle-disabled` as const;

export const rosterNotifications = `${rosters}/email-campaigns` as const;

export const rosterMemberOrderItems = (
  rosterMemberId: number,
  requiredItems: boolean
): string => `${rosterManager}/roster-members/${rosterMemberId}/order-items${buildQueryString({ requiredItems })}`;

export default {
  rosters,
  rostersTable,
  rostersList,
  rosterCSVFile,
  parseRosterCSVFile,
  rosterTeam,
  rosterTeamMembers,
  reimportRoster,
  removeTeamMembers,
  teamMembers,
  teamMember,
  removeRosters,
  toggleDisabledRosters,
  rosterNotifications,
  rosterMemberOrderItems,
};
