const ID = 'LOCKER_MANAGER' as const;

export const CLEAR_CURRENT_QUEUE = `${ID}/CLEAR_CURRENT_QUEUE` as const;
export const UPDATE_LOCKER = `${ID}/UPDATE_LOCKER` as const;
export const UPDATE_LOCKER_INFO = `${ID}/UPDATE_LOCKER_INFO` as const;
export const UPDATE_FULFILLMENT_LOCKER_INFO = `${ID}/UPDATE_FULFILLMENT_LOCKER_INFO` as const;
export const RESET_FULFILLMENT_LOCKER_INFO = `${ID}/RESET_FULFILLMENT_LOCKER_INFO` as const;
export const UPDATE_FULFILLMENT_LOCKERS_INFO = `${ID}/UPDATE_FULFILLMENT_LOCKERS_INFO` as const;
export const UPDATE_CSS_META_INFO = `${ID}/UPDATE_CSS_META_INFO` as const;
export const UPDATE_COLORED_STYLE_SELECTIONS = `${ID}/UPDATE_COLORED_STYLE_SELECTIONS` as const;
export const UPDATE_CACHED_IMAGE_FOR_CSS = `${ID}/UPDATE_CACHED_IMAGE_FOR_CSS` as const;
export const UPDATE_CURRENT_QUEUE = `${ID}/UPDATE_CURRENT_QUEUE` as const;
export const UPDATE_CURRENT_PRODUCT_QUEUE = `${ID}/UPDATE_CURRENT_PRODUCT_QUEUE` as const;
export const UPDATE_LOCKER_LOGOS = `${ID}/UPDATE_LOCKER_LOGOS` as const;
export const CHANGE_LOGO_APPROVED_STATUS = `${ID}/CHANGE_LOGO_APPROVED_STATUS` as const;
export const UPDATE_LOCKER_COLORS = `${ID}/UPDATE_LOCKER_COLORS` as const;
export const UPDATE_LOCKER_FLOOD_COLORS = `${ID}/UPDATE_LOCKER_FLOOD_COLORS` as const;
export const UPDATE_MANAGER_LOGOS = `${ID}/UPDATE_MANAGER_LOGOS` as const;
export const UPDATE_LOGO_BANK_LOGOS = `${ID}/UPDATE_LOGO_BANK_LOGOS` as const;
export const UPDATE_LOGO_BANK_HISTORY = `${ID}/UPDATE_LOGO_BANK_HISTORY` as const;
export const UPDATE_LOGO_BANK_ARCHIVED_LOGOS = `${ID}/UPDATE_LOGO_BANK_ARCHIVED_LOGOS` as const;
export const UPDATE_ALL_LOCKER_PRODUCTS = `${ID}/UPDATE_ALL_LOCKER_PRODUCTS` as const;
export const UPDATE_ALL_LOCKER_ROSTERS = `${ID}/UPDATE_ALL_LOCKER_ROSTERS` as const;
export const UPDATE_LOGO_BANK = `${ID}/UPDATE_LOGO_BANK` as const;
export const UPDATE_LOCKER_MANAGERS = `${ID}/UPDATE_LOCKER_MANAGERS` as const;
export const UPDATE_PARTNERS = `${ID}/UPDATE_PARTNERS` as const;
export const UPDATE_MANAGER_LOCKER_LOGOS = `${ID}/UPDATE_MANAGER_LOCKER_LOGOS` as const;
export const UPDATE_ORGANIZATIONS = `${ID}/UPDATE_ORGANIZATIONS` as const;
export const UPDATE_AVAILABLE_PRODUCTS = `${ID}/UPDATE_AVAILABLE_PRODUCTS` as const;
export const UPDATE_BLANK_PRODUCTS = `${ID}/UPDATE_BLANK_PRODUCTS` as const;
export const UPDATE_SPORT_OPTIONS = `${ID}/UPDATE_SPORT_OPTIONS` as const;
export const UPDATE_LOCKER_MANAGER_TABLE_STATE = `${ID}/UPDATE_LOCKER_MANAGER_TABLE_STATE` as const;
export const UPDATE_LOCKER_MANAGER_3_MONTH_SALES_TABLE_STATE = `${ID}/UPDATE_LOCKER_MANAGER_3_MONTH_SALES_TABLE_STATE` as const;
export const RESET_LOCKER_MANAGER_TABLE_STATE = `${ID}/RESET_LOCKER_MANAGER_TABLE_STATE` as const;
export const UPDATE_LOCKER_LOGO_BANK_LOGOS = `${ID}/UPDATE_LOCKER_LOGO_BANK_LOGOS` as const;
export const UPDATE_LOCKER_ITEMS_QUEUE = `${ID}/UPDATE_LOCKER_ITEMS_QUEUE` as const;
export const UPDATE_LOCKER_STYLE_COLORS = `${ID}/UPDATE_LOCKER_STYLE_COLORS` as const;
export const UPDATE_LOCKER_ITEM = `${ID}/UPDATE_LOCKER_ITEM` as const;
export const UPDATE_LOCKER_CHANGE_LOGS = `${ID}/UPDATE_LOCKER_CHANGE_LOGS` as const;
export const UPDATE_ALL_LOCKER_COLLECTIONS = `${ID}/UPDATE_ALL_LOCKER_COLLECTIONS` as const;
export const UPDATE_LOCKER_BUNDLED_COLLECTIONS = `${ID}/UPDATE_LOCKER_BUNDLED_COLLECTIONS` as const;

export default ID;
