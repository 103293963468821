import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';

const AllowDiscountsFormCheckbox = React.memo(({
  change,
  disabled,
}) => (
  <div className='info__field'>
    <Field
      name={'discounts'}
      text={'Allow Discounts'}
      component={ReduxFormCheckbox}
      change={change}
      disabled={disabled.discounts}
    />
  </div>
));

AllowDiscountsFormCheckbox.propTypes = {
  change: PropTypes.func.isRequired,
};

export default AllowDiscountsFormCheckbox;
