import {
  useCreateBulkColoredStyle, useGetEffectedLockers,
} from '@api/squadlockerServices/locker-manager';
import {
  ColoredStylesSearchViewModel, CreateBulkItemRequestModel, LockerColoredStyleBulkCreation,
} from '@api/squadlockerServices/models';
import { BulkAddItemFormData } from '@models/forms/BulkActions/BulkAddItemsFormData';
import {
  beginBlockingRequest, endBlockingRequest,
} from '@redux/api/actions';
import {
  downloadFromLink,
  materialSwal, swalChoose,
} from '@util/componentHelper';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import BulkAddItemForm from './BulkAddItemForm';

const BulkAddItem = React.memo(() => {
  const {
    data: addBulkItemResponse,
    mutateAsync: addBulkItemRequest,
  } = useCreateBulkColoredStyle();
  const [
    newSelectedColoredStyle,
    setNewSelectedColoredStyle,
  ] = useState<Nullable<ColoredStylesSearchViewModel>>(null);
  const [
    oldSelectedColoredStyle,
    setOldSelectedColoredStyle,
  ] = useState<Nullable<ColoredStylesSearchViewModel>>(null);

  const {
    data: effectedLockersCount,
    isFetching: fetchingEffectedLockersCount,
    refetch: fetchEffectedLockersCount,
  } = useGetEffectedLockers({
    oldColoredStyleCode: oldSelectedColoredStyle?.id ?? 0,
    newColoredStyleCode: newSelectedColoredStyle?.id ?? 0,
  });

  const handleSubmit = useCallback(async (formData: BulkAddItemFormData) => {
    if (effectedLockersCount! === 0) {
      materialSwal(`No lockers found that contain ${oldSelectedColoredStyle?.name} but don't contain ${newSelectedColoredStyle?.name}`);

      return;
    }

    const choice = await swalChoose({
      title: 'Confirm Bulk Addition',
      text: `${newSelectedColoredStyle?.code} ${newSelectedColoredStyle?.name} will be added 
      to ${effectedLockersCount} lockers. Are you sure you want to proceed?`,
      confirm: 'Confirm',
      cancel: 'Cancel',
    });

    if (choice.value === true) {
      const data = {
        oldColoredStyleId: formData.oldItemId,
        newColoredStyleId: formData.newItemId,
      } as CreateBulkItemRequestModel;

      beginBlockingRequest();
      await addBulkItemRequest({ data });
      endBlockingRequest();
    }
  }, [
    addBulkItemRequest,
    effectedLockersCount,
    newSelectedColoredStyle,
    oldSelectedColoredStyle,
  ]);

  useEffect(() => {
    if (addBulkItemResponse?.success) {
      materialSwal(`Items successfully added to ${addBulkItemResponse.result?.length} lockers.`);
      // Create and download CSV file
      const rows = addBulkItemResponse?.result as unknown as LockerColoredStyleBulkCreation[] ?? [];
      const keys = Object.keys(rows[0] ?? []);
      const csvContent =
      keys.join(',')
      + '\n'
      + rows.map(
        (row) => keys.map((k) => {
          let cell = row[k as keyof LockerColoredStyleBulkCreation] === null
          || row[k as keyof LockerColoredStyleBulkCreation] === undefined
            ? ''
            : row[k as keyof LockerColoredStyleBulkCreation];
          cell = cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }

          return cell;
        }).join(',')
      )
        .join('\n');

      downloadFromLink(csvContent, `locker-info-${Date.now()}.csv`, 'text/csv;charset=utf-8');
    }
  }, [addBulkItemResponse]);

  const changeNewSelectedColorStyle = useCallback((cs: Nullable<ColoredStylesSearchViewModel>) => {
    setNewSelectedColoredStyle(cs);
  }, []);

  const changeOldSelectedColorStyle = useCallback((cs: Nullable<ColoredStylesSearchViewModel>) => {
    setOldSelectedColoredStyle(cs);
  }, []);

  return (
    <div className='container master-detail'>
      <BulkAddItemForm
        onSubmit={handleSubmit}
        effectedLockersCount={effectedLockersCount}
        fetchingEffectedLockersCount={fetchingEffectedLockersCount}
        setOldSelectedColoredStyle={changeOldSelectedColorStyle}
        setNewSelectedColoredStyle={changeNewSelectedColorStyle}
        fetchEffectedLockersCount={fetchEffectedLockersCount}
        newSelectedColoredStyle={newSelectedColoredStyle}
        oldSelectedColoredStyle={oldSelectedColoredStyle}
      />
    </div>
  );
});

export default BulkAddItem;
