import React from 'react';
import { RosterTeamBaseViewModel } from '@api/squadlockerServices/models';
import RequiredItemFormCheckbox from '@sharedComponents/Form/Fields/RequiredItemFormCheckbox';
import AssociatedRosterFormDropdown from './CollectionFormPropertiesTabContent/AssociatedRosterFormDropdown';
import BundleCollectionCheckbox from './CollectionFormPropertiesTabContent/BundleCollectionCheckbox';

interface OwnProps {
  collectionId: number;
  rosterTeamIds: number[];
  rosters: RosterTeamBaseViewModel[];
  disabled?: {
    rosterTeamIds?: boolean;
    disabled?: boolean;
    bundled?: boolean;
    required?: boolean;
  };
  change: (name: string, value: any) => void;
  hasActiveVouchers?: boolean;
}

type Props = OwnProps;

const CollectionFormPropertiesTab = React.memo<Props>(({
  collectionId,
  rosters,
  rosterTeamIds,
  change,
  disabled,
  hasActiveVouchers = false,
}) => (
  <div className='collections-form__properties mt-10'>
    <div className='redux-form__row'>
      <div className='redux-form__column--size-m align__center mt-10'>
        <div className='redux-form__column--size-m'>
          <BundleCollectionCheckbox
            change={change}
            disabled={disabled?.bundled}
            tooltipText={
              hasActiveVouchers
                ? 'Cannot change collection\'s bundled flag because the collection has an active voucher associated with it'
                : undefined
            }
          />
        </div>
        <div className='redux-form__column--size-m mr-20'>
          <RequiredItemFormCheckbox
            name={'required'}
            change={change}
            disabled={disabled?.required}
            tooltipText={
              hasActiveVouchers
                ? 'Cannot change collection\'s required flag because the collection has an active voucher associated with it'
                : undefined
            }
          />
        </div>
      </div>
      <div className='redux-form__column--size-m'>
        <AssociatedRosterFormDropdown
          collectionId={collectionId}
          rosterTeamIds={rosterTeamIds}
          rosters={rosters}
          change={change}
          disabled={disabled?.rosterTeamIds}
        />
      </div>
    </div>
  </div>
));

export default CollectionFormPropertiesTab;
