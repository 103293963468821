import React from 'react';
import { OrderManagementTempItem } from '@redux/orderManagement/models';

interface OwnProps {
  item: Nullable<OrderManagementTempItem>;
}

type Props = OwnProps;

const ItemDetails = React.memo(({ item }: Props) => {
  const previewBackground = {
    backgroundImage: `url("${item?.image}")`,
  };

  return (
    <div className='new-order__item-details'>
      <div className='align__center'>
        <div
          className='new-order__item-details__image'
          style={previewBackground}
        />
      </div>
      <div className='new-order__item-details__description'>
        <div className='new-order__item-details__description__sku'>{item?.sku}</div>
        <div className='new-order__item-details__description__name'>{item?.name || ''}</div>
      </div>
    </div>
  );
});

export default ItemDetails;
