import React, { useCallback } from 'react';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';
import { Grid } from '@material-ui/core';
import ReviewItemImage from './ReviewItemImage';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';

const imageSizePx: string = '65px';

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  itemImage: string;
  handleOpenItemInfoModal: () => void;
  setItemImage: (imageUrl: string) => void;
  showSmallerImage?: boolean;
}

type Props = OwnProps;

const ReviewImageCarousel = React.memo<Props>(({
  item,
  itemImage,
  handleOpenItemInfoModal,
  setItemImage,
  showSmallerImage = false,
}) => {
  const setLargeImage = useCallback((imgUrl: string) => {
    setItemImage(imgUrl);
  }, [setItemImage]);

  return (
    <Grid
      container={true}
      spacing={2}
      className='carousel-width'
    >
      <Grid
        item={true}
        xs={12}
      >
        <ReviewItemImage
          item={item}
          itemImage={itemImage}
          openItemInfoModal={handleOpenItemInfoModal}
          showDecorationMethodLabel={true}
          showSmallerImage={showSmallerImage}
        />
      </Grid>
      <Grid
        item={true}
        xs={4}
      >
        {
          item?.imageUrlFront &&
          <div className='details__item-logo-container'>
            <div className='order-assembly__item--images__item'>
              <ImagePreviewCell
                imageUrl={item?.imageUrlFront}
                height={imageSizePx}
                width={imageSizePx}
                transparent={false}
                setLargeImageOnClick={setLargeImage}
              />
              <div className='location'>Front</div>
            </div>
          </div>
        }
      </Grid>
      <Grid
        item={true}
        xs={4}
      >
        {
          item?.imageUrlLeft &&
          <div className='details__item-logo-container'>
            <div className='order-assembly__item--images__item'>
              <ImagePreviewCell
                imageUrl={item?.imageUrlLeft}
                height={imageSizePx}
                width={imageSizePx}
                transparent={false}
                setLargeImageOnClick={setLargeImage}
              />
              <div className='location'>Side Left</div>
            </div>
          </div>
        }
      </Grid>
      <Grid
        item={true}
        xs={4}
      >
        {
          item?.imageUrlRight &&
          <div className='details__item-logo-container'>
            <div className='order-assembly__item--images__item'>
              <ImagePreviewCell
                imageUrl={item?.imageUrlRight}
                height={imageSizePx}
                width={imageSizePx}
                transparent={false}
                setLargeImageOnClick={setLargeImage}
              />
              <div className='location'>Side Right</div>
            </div>
          </div>
        }
      </Grid>
      <Grid
        item={true}
        xs={4}
      >
        {
          item?.imageUrlBack &&
          <div className='details__item-logo-container'>
            <div className='order-assembly__item--images__item'>
              <ImagePreviewCell
                imageUrl={item?.imageUrlBack}
                height={imageSizePx}
                width={imageSizePx}
                transparent={false}
                setLargeImageOnClick={setLargeImage}
              />
              <div className='location'>Back</div>
            </div>
          </div>
        }
      </Grid>
    </Grid>
  );
});

export default ReviewImageCarousel;
