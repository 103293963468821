/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  IHagTransmissionDto,
  GetHomefieldApiHagTransmissionsParams,
  ApiResponse,
  CreateHagTransmissionFromPrintQueueCommand,
  CreateHagTransmissionFromPrepressJobItemCommand,
  CreateHagTransmissionFromPrintRoomJobCommand,
  CreateHagTransmissionCommand,
  HagPrintRoomJobPagedListDtoPagedList,
  GetHomefieldApiHagPrintroomParams,
  HagPrintRoomCountStatusesDto,
  HagPrintRoomClaimedStatusDto,
  GetHomefieldApiHagPrintroomclaimedstatusParams,
  ProgressHagPrintRoomJobStatusCommand,
  HagPrintRoomOrderDetailsDto,
  GetHomefieldApiHagPrintroomorderdetailsParams,
  ReworkHagArtworkCommand,
  HagPrintRoomOrderAssemblyPagedListDtoPagedList,
  GetHomefieldApiHagPrintroomorderassemblyParams,
  HagPrintAssemblyOrderDetailsDto,
  GetHomefieldApiHagPrintroomorderassemblycountstatusesParams,
  ClaimHagPrintRoomJobCommand,
  UpdateHagPrintRoomJobNotesCommand
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiHagTransmissions = <TData = IHagTransmissionDto[]>(
    params?: GetHomefieldApiHagTransmissionsParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/transmissions`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiHagTransmissionsQueryKey = (params?: GetHomefieldApiHagTransmissionsParams,) => [`/homefield/api/hag/transmissions`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiHagTransmissions = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiHagTransmissions, IHagTransmissionDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiHagTransmissionsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiHagTransmissionsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiHagTransmissions<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiHagTransmissionssendprintqueue = <TData = ApiResponse>(
    createHagTransmissionFromPrintQueueCommand: CreateHagTransmissionFromPrintQueueCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/transmissions.send-print-queue`, method: 'post',
      data: createHagTransmissionFromPrintQueueCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiHagTransmissionssendprintqueue = <TData = AsyncReturnType<typeof postHomefieldApiHagTransmissionssendprintqueue,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateHagTransmissionFromPrintQueueCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateHagTransmissionFromPrintQueueCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiHagTransmissionssendprintqueue<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiHagTransmissionssendprepressjobitem = <TData = ApiResponse>(
    createHagTransmissionFromPrepressJobItemCommand: CreateHagTransmissionFromPrepressJobItemCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/transmissions.send-prepress-job-item`, method: 'post',
      data: createHagTransmissionFromPrepressJobItemCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiHagTransmissionssendprepressjobitem = <TData = AsyncReturnType<typeof postHomefieldApiHagTransmissionssendprepressjobitem,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateHagTransmissionFromPrepressJobItemCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateHagTransmissionFromPrepressJobItemCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiHagTransmissionssendprepressjobitem<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiHagTransmissionssendprintroomjob = <TData = ApiResponse>(
    createHagTransmissionFromPrintRoomJobCommand: CreateHagTransmissionFromPrintRoomJobCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/transmissions.send-print-room-job`, method: 'post',
      data: createHagTransmissionFromPrintRoomJobCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiHagTransmissionssendprintroomjob = <TData = AsyncReturnType<typeof postHomefieldApiHagTransmissionssendprintroomjob,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateHagTransmissionFromPrintRoomJobCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateHagTransmissionFromPrintRoomJobCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiHagTransmissionssendprintroomjob<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiHagTransmissionssenddecorationids = <TData = ApiResponse>(
    createHagTransmissionCommand: CreateHagTransmissionCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/transmissions.send-decoration-ids`, method: 'post',
      data: createHagTransmissionCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiHagTransmissionssenddecorationids = <TData = AsyncReturnType<typeof postHomefieldApiHagTransmissionssenddecorationids,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateHagTransmissionCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateHagTransmissionCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiHagTransmissionssenddecorationids<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiHagPrintroom = <TData = HagPrintRoomJobPagedListDtoPagedList>(
    params?: GetHomefieldApiHagPrintroomParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiHagPrintroomQueryKey = (params?: GetHomefieldApiHagPrintroomParams,) => [`/homefield/api/hag/print-room`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiHagPrintroom = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiHagPrintroom, HagPrintRoomJobPagedListDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiHagPrintroomParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiHagPrintroomQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiHagPrintroom<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiHagPrintroomcountstatuses = <TData = HagPrintRoomCountStatusesDto>(
    
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.count-statuses`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiHagPrintroomcountstatusesQueryKey = () => [`/homefield/api/hag/print-room.count-statuses`]

    
export const useGetHomefieldApiHagPrintroomcountstatuses = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiHagPrintroomcountstatuses, HagPrintRoomCountStatusesDto>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiHagPrintroomcountstatusesQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiHagPrintroomcountstatuses<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiHagPrintroomclaimedstatus = <TData = HagPrintRoomClaimedStatusDto[]>(
    params?: GetHomefieldApiHagPrintroomclaimedstatusParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.claimed-status`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiHagPrintroomclaimedstatusQueryKey = (params?: GetHomefieldApiHagPrintroomclaimedstatusParams,) => [`/homefield/api/hag/print-room.claimed-status`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiHagPrintroomclaimedstatus = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiHagPrintroomclaimedstatus, HagPrintRoomClaimedStatusDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiHagPrintroomclaimedstatusParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiHagPrintroomclaimedstatusQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiHagPrintroomclaimedstatus<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiHagPrintroomprogressjob = <TData = ApiResponse>(
    progressHagPrintRoomJobStatusCommand: ProgressHagPrintRoomJobStatusCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.progress-job`, method: 'put',
      data: progressHagPrintRoomJobStatusCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiHagPrintroomprogressjob = <TData = AsyncReturnType<typeof putHomefieldApiHagPrintroomprogressjob,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ProgressHagPrintRoomJobStatusCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ProgressHagPrintRoomJobStatusCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiHagPrintroomprogressjob<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiHagPrintroomorderdetails = <TData = HagPrintRoomOrderDetailsDto>(
    params?: GetHomefieldApiHagPrintroomorderdetailsParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.order-details`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiHagPrintroomorderdetailsQueryKey = (params?: GetHomefieldApiHagPrintroomorderdetailsParams,) => [`/homefield/api/hag/print-room.order-details`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiHagPrintroomorderdetails = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiHagPrintroomorderdetails, HagPrintRoomOrderDetailsDto>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiHagPrintroomorderdetailsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiHagPrintroomorderdetailsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiHagPrintroomorderdetails<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiHagPrintroomreworkartwork = <TData = ApiResponse>(
    reworkHagArtworkCommand: ReworkHagArtworkCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.rework-artwork`, method: 'put',
      data: reworkHagArtworkCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiHagPrintroomreworkartwork = <TData = AsyncReturnType<typeof putHomefieldApiHagPrintroomreworkartwork,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ReworkHagArtworkCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ReworkHagArtworkCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiHagPrintroomreworkartwork<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiHagPrintroomorderassembly = <TData = HagPrintRoomOrderAssemblyPagedListDtoPagedList>(
    params?: GetHomefieldApiHagPrintroomorderassemblyParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.order-assembly`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiHagPrintroomorderassemblyQueryKey = (params?: GetHomefieldApiHagPrintroomorderassemblyParams,) => [`/homefield/api/hag/print-room.order-assembly`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiHagPrintroomorderassembly = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiHagPrintroomorderassembly, HagPrintRoomOrderAssemblyPagedListDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiHagPrintroomorderassemblyParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiHagPrintroomorderassemblyQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiHagPrintroomorderassembly<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiHagPrintroomorderassemblycountstatuses = <TData = HagPrintAssemblyOrderDetailsDto>(
    params?: GetHomefieldApiHagPrintroomorderassemblycountstatusesParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.order-assembly-count-statuses`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiHagPrintroomorderassemblycountstatusesQueryKey = (params?: GetHomefieldApiHagPrintroomorderassemblycountstatusesParams,) => [`/homefield/api/hag/print-room.order-assembly-count-statuses`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiHagPrintroomorderassemblycountstatuses = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiHagPrintroomorderassemblycountstatuses, HagPrintAssemblyOrderDetailsDto>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiHagPrintroomorderassemblycountstatusesParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiHagPrintroomorderassemblycountstatusesQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiHagPrintroomorderassemblycountstatuses<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiHagPrintroomclaimorder = <TData = ApiResponse>(
    claimHagPrintRoomJobCommand: ClaimHagPrintRoomJobCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.claim-order`, method: 'put',
      data: claimHagPrintRoomJobCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiHagPrintroomclaimorder = <TData = AsyncReturnType<typeof putHomefieldApiHagPrintroomclaimorder,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ClaimHagPrintRoomJobCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ClaimHagPrintRoomJobCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiHagPrintroomclaimorder<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiHagPrintroomupdatenotes = <TData = ApiResponse>(
    updateHagPrintRoomJobNotesCommand: UpdateHagPrintRoomJobNotesCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/hag/print-room.update-notes`, method: 'put',
      data: updateHagPrintRoomJobNotesCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiHagPrintroomupdatenotes = <TData = AsyncReturnType<typeof putHomefieldApiHagPrintroomupdatenotes,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateHagPrintRoomJobNotesCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdateHagPrintRoomJobNotesCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiHagPrintroomupdatenotes<TData>(data,requestOptions)
      }, mutationOptions)
    }
    