import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import Expander from '@sharedComponents/Buttons/Expander';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';
import ProductionAssemblyOrderHeaderDetails from './ProductionAssemblyOrderHeaderDetails';

class ProductionAssemblyOrderHeader extends PureComponent {
  state = {
    isExpanded: true,
  };

  changeExpand = () => {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  };

  render() {
    const {
      order,
      toggleStatusHistory,
      isStatusHistoryShown,
    } = this.props;

    const { isExpanded } = this.state;

    return (
      <div className='order-assembly'>
        <div className='order-assembly__header--info'>
          <div className='flex__row align__center'>
            ORDER O{order.orderNumber}
            <Link
              target='_blank'
              to={orderDetailsUrl(order.orderNumber)}
            >
              <i
                title='Order Management'
                className='material-icons order-assembly__header--link'
              >
                launch
              </i>
            </Link>
            <LabelsLegendDisplay
              labels={[
                {
                  isActive: order.isRushed,
                  text: 'Rushed',
                  shortText: 'R',
                  type: 'status-blue',
                },
                {
                  isActive: order.isPaused,
                  text: 'Paused',
                  shortText: 'P',
                  type: 'status-orange',
                },
                {
                  isActive: order.isCanceled,
                  text: 'Canceled',
                  shortText: 'C',
                  type: 'status-darkred',
                },
                {
                  isActive: order.items && order.items.some((oi) => oi.requiredItem),
                  text: 'Required',
                  shortText: 'R',
                  type: 'electric-indigo',
                },
              ]}
            />
          </div>
          <Expander
            isExpanded={isExpanded}
            toggle={this.changeExpand}
          />
        </div>
        {
          isExpanded &&
          <ProductionAssemblyOrderHeaderDetails
            order={order}
            toggleStatusHistory={toggleStatusHistory}
            isStatusHistoryShown={isStatusHistoryShown}
          />
        }
      </div>
    );
  }
}

ProductionAssemblyOrderHeader.propTypes = {
  order: PropTypes.object.isRequired,
  toggleStatusHistory: PropTypes.func.isRequired,
  isStatusHistoryShown: PropTypes.bool.isRequired,
};

export default ProductionAssemblyOrderHeader;
