import React from 'react';
import PropTypes from 'prop-types';

const ToggleButton = ({
  name,
  onChange,
  disabled,
  enabled = false,
  dataTest = 'toggle-button',
}) => (
  <div className={`toggleSwitch ${disabled ? 'disabled' : ''}`}>
    <input
      className='toggleSwitch__input'
      type='checkbox'
      id={`toggleSwitch-${name}`}
      checked={enabled}
      onChange={!disabled && onChange}
      data-test={dataTest}
    />
    <label
      className='toggleSwitch__label'
      htmlFor={`toggleSwitch-${name}`}
    />
  </div>
);

ToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTest: PropTypes.string,
};

export default ToggleButton;
