import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import { infoTextTimeout } from '@constants/values';
import { getVendorsList } from '@redux/productCatalog/actions';
import { groupBy } from '@util/arrayHelper';
import { copyToClipboard } from '@util/componentHelper';
import Icon from '@sharedComponents/Icons/Icon';
import VoucherDetailsHeader from './OrderItemsContent/VoucherDetailsHeader';
import OrderManagementDetailsItem from './OrderManagementDetailsItem';

class OrderManagementDetailsItems extends Component {
  state = {
    copyText: {},
  };

  componentDidMount() {
    const { items } = this.props;

    this.fetchVendorsForItems(items);
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;

    if (prevProps.items !== items && items && items.length) {
      this.fetchVendorsForItems(items);
    }
  }

  fetchVendorsForItems = (items) => {
    const { dispatch } = this.props;

    const vendorIds = [...new Set(items.map((item) => item.vendorId))];

    if (vendorIds.length) {
      dispatch(getVendorsList(vendorIds));
    }
  };

  onCopyToClipboard = (lockerId, e) => {
    copyToClipboard(e, `dataToCopy-${lockerId}`);

    this.setState({
      copyText: {
        [lockerId]: 'Copied!',
      },
    }, () => setTimeout(() => {
      this.setState({ copyText: {} });
    }, infoTextTimeout));
  };

  renderLockerHeader = (lockerId, teamName, lck) => {
    const { copyText } = this.state;

    return (
      <div key={lockerId}>
        <div className='order__items--locker'>
          <div className='align__center'>
            <Link
              className='link'
              target='_blank'
              to={lockerDetailsUrl(lockerId)}
            >
              L<span id={`dataToCopy-${lockerId}`}>{lockerId}</span> - {teamName}
            </Link>
            <Icon
              materialIcon={'content_copy'}
              onClick={this.onCopyToClipboard.bind(null, lockerId)}
            />
            <span className='copy-text'>{copyText[lockerId]}</span>
          </div>
          <span>{lck}</span>
        </div>
      </div>
    );
  };

  renderVoucherItems = (items) => {
    const itemsByVoucher = groupBy(items, 'voucherCode');
    const content = [];

    for (const [
      voucherCode,
      voucherItems,
    ] of Object.entries(itemsByVoucher)) {
      content.push(
        <div className='order__item__voucher'>
          <VoucherDetailsHeader
            voucherCode={voucherCode}
            collectionId={voucherItems[0].collectionId}
            rosterMemberId={voucherItems[0].rosterTeamMemberId}
          />
          {this.renderItems(voucherItems)}
        </div>
      );
    }

    return <div>{content}</div>;
  };

  renderItems = (items) => {
    const {
      order,
      openReplaceItemBySkuModal,
      openReplaceItemModal,
      openPreviewModal,
      openOrderItemFinancialsModal,
      openChangeDecorationMethodModal,
      editPersonalization,
      createPersonalization,
      collapsedItemIds,
      artworksExpandedOrCollapsedCallback,
      shipmentNumbersForItems,
      isOrderPartiallyShipped,
      orderShipments,
      refundItem,
      changeArtwork,
      orderFlags,
      openRemovePersonalizationModal,
      colorsDictionary,
      openItemInfoModal,
      vendorsDictionary,
      coreOrderPricingDetails,
      orderExistsOnCore,
      coreOrder,
      orderVariations,
    } = this.props;

    return (
      items.map((item, index) => {
        const shipmentNumbers = new Set();
        const shipDates = {};

        for (const itemId of item.ids) {
          const shipmentNumber = shipmentNumbersForItems[itemId];
          if (shipmentNumber) {
            shipmentNumbers.add(shipmentNumber);
            shipDates[shipmentNumber] = orderShipments
              .find((s) => s.shipmentNumber === shipmentNumber).actualShipDate;
          }
        }

        const itemPricingDetails = coreOrderPricingDetails?.orderItemsPricingDetails?.[item.coreId];

        const itemVariation = orderExistsOnCore
          ? orderVariations?.find(
            (variation) => variation.id === coreOrder?.items?.find(
              (coi) => coi.id === item.coreId
            )?.variationId
          )
          : orderVariations?.find(
            (variation) => variation.products.map(
              (product) => product.sku
            ).includes(item.sku)
          );

        return (
          <OrderManagementDetailsItem
            order={order}
            shipmentNumbers={shipmentNumbers}
            shipDates={shipDates}
            isOrderPartiallyShipped={isOrderPartiallyShipped}
            openPreviewModal={openPreviewModal}
            openReplaceItemBySkuModal={openReplaceItemBySkuModal}
            openReplaceItemModal={openReplaceItemModal}
            openOrderItemFinancialsModal={openOrderItemFinancialsModal}
            openChangeDecorationMethodModal={openChangeDecorationMethodModal}
            key={index}
            item={item}
            editPersonalization={editPersonalization}
            createPersonalization={createPersonalization}
            openRemovePersonalizationModal={openRemovePersonalizationModal}
            isCollapsed={collapsedItemIds.includes(item.ids)}
            artworksExpandedOrCollapsedCallback={artworksExpandedOrCollapsedCallback}
            refundItem={refundItem}
            changeArtwork={changeArtwork}
            orderFlags={orderFlags}
            colorsDictionary={colorsDictionary}
            openItemInfoModal={openItemInfoModal}
            vendorsDictionary={vendorsDictionary}
            itemPricingDetails={itemPricingDetails}
            orderExistsOnCore={orderExistsOnCore}
            itemVariation={itemVariation}
          />
        );
      })
    );
  };

  render() {
    const {
      isFilteringItems,
      items,
      order,
    } = this.props;

    const content = [];

    if (order.organizationId) {
      const groupedItemsDict = groupBy(items, 'lockerId');
      for (const [
        lockerId,
        lockerItems,
      ] of Object.entries(groupedItemsDict)) {
        const itemsWithVoucher = lockerItems.filter((item) => item.voucherId);
        const itemsWithoutVoucher = lockerItems.filter((item) => !item.voucherId);

        const voucherItems = this.renderVoucherItems(itemsWithVoucher);
        const nonVoucherItems = this.renderItems(itemsWithoutVoucher);

        content.push(
          this.renderLockerHeader(lockerId, lockerItems[0].lockerTeamName, lockerItems[0].lck),
          voucherItems,
          nonVoucherItems
        );
      }
    } else {
      const itemsWithVoucher = items.filter((item) => item.voucherId);
      const itemsWithoutVoucher = items.filter((item) => !item.voucherId);

      const voucherItems = this.renderVoucherItems(itemsWithVoucher);
      const nonVoucherItems = this.renderItems(itemsWithoutVoucher);

      content.push(
        voucherItems,
        nonVoucherItems
      );
    }

    return (
      <div className={`order__items ${isFilteringItems ? 'order__items-search-mode' : ''} custom-scrollbar`}>
        {content}
      </div>
    );
  }
}

OrderManagementDetailsItems.propTypes = {
  order: PropTypes.object.isRequired,
  items: PropTypes.array,
  isFilteringItems: PropTypes.bool.isRequired,
  openReplaceItemBySkuModal: PropTypes.func.isRequired,
  openReplaceItemModal: PropTypes.func.isRequired,
  openItemInfoModal: PropTypes.func.isRequired,
  openOrderItemFinancialsModal: PropTypes.func.isRequired,
  openPreviewModal: PropTypes.func.isRequired,
  openChangeDecorationMethodModal: PropTypes.func.isRequired,
  editPersonalization: PropTypes.func.isRequired,
  createPersonalization: PropTypes.func.isRequired,
  openRemovePersonalizationModal: PropTypes.func.isRequired,
  collapsedItemIds: PropTypes.array.isRequired,
  refundItem: PropTypes.func.isRequired,
  changeArtwork: PropTypes.func.isRequired,
  artworksExpandedOrCollapsedCallback: PropTypes.func.isRequired,
  shipmentNumbersForItems: PropTypes.object.isRequired,
  isOrderPartiallyShipped: PropTypes.bool.isRequired,
  orderShipments: PropTypes.array,
  orderFlags: PropTypes.array,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  vendorsDictionary: PropTypes.object,
  coreOrderPricingDetails: PropTypes.object,
  orderExistsOnCore: PropTypes.bool,
  coreOrder: PropTypes.object,
  orderVariations: PropTypes.array,
};

const mapStateToProps = ({ productCatalog }) => ({
  colorsDictionary: productCatalog.colorsDictionary,
  vendorsDictionary: productCatalog.vendorsDictionary,
});

export default connect(mapStateToProps)(OrderManagementDetailsItems);
