import React from 'react';
import InfoField from '@components/shared/Display/InfoField';
import {
  OrderDetailsDto, ServiceTypeEnum,
} from '@api/fulfillment/models';
import { SubmitStatusEnum } from '@api/financialServices/models';
import OrderManagementDetailsOrderSubmitResultsModal from './OrderManagementDetails/OrderManagementDetailsModals/OrderManagementDetailsOrderSubmitResultsModal';
import Button from '@components/shared/Buttons/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { netSuiteSalesOrderUrl } from '@constants/clientUrls/clientUrls';

type OwnProps = {
  order: OrderDetailsDto;
  isSubmitResultsModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  fetchOrder: () => void;
};

type Props = OwnProps;

const OrderManagementNSSOAndCustomerNumber = React.memo<Props>(({
  order, isSubmitResultsModalOpen, openModal, closeModal, fetchOrder,
}) => {
  const { hfNetSuiteBaseUrl } = useFlags();

  const renderSOP = () => {
    switch (order.netSuiteAccountingSubmitStatus) {
      case SubmitStatusEnum.Done:
        if (!order.salesOrderId || !hfNetSuiteBaseUrl) {
          return order.salesOrderNumber;
        }

        return (
          <a
            className='link'
            href={netSuiteSalesOrderUrl(hfNetSuiteBaseUrl, order.salesOrderId)}
            target='_blank' rel='noreferrer'
          >
            {order.salesOrderNumber}
          </a>
        );
      case SubmitStatusEnum.Pending:
        return (
          <div className='flex'>
            <div>Pending...</div>
            <Button
              type={'primary'}
              text={'Details'}
              onClick={openModal}
              size={'s'}
              classes={'button--size-s ml-20'}
            />
          </div>
        );
      case SubmitStatusEnum.NotScheduled:
        return 'Not Scheduled';
      case SubmitStatusEnum.Failed:
        return (
          <div className='flex'>
            <div className='order__failed-text'>Failed</div>
            <Button
              type={'danger'}
              text={'Details'}
              onClick={openModal}
              size={'s'}
              classes={'button--size-s ml-20'}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='flex'>
      <div className='info__field order__shipping__column--short'>
        {
          isSubmitResultsModalOpen &&
          <OrderManagementDetailsOrderSubmitResultsModal
            order={order}
            isOpen={isSubmitResultsModalOpen}
            closeModal={closeModal}
            serviceType={ServiceTypeEnum.NetSuite}
            fetchOrder={fetchOrder}
          />
        }
        <InfoField
          label='NS SOP Number'
          value={renderSOP()}
        />
      </div>
      <InfoField
        label='NS Customer ID'
        value={order.accountingId}
      />
    </div>
  );
});

export default OrderManagementNSSOAndCustomerNumber;
