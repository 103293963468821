import React from 'react';
import { orderSearchUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const OrderManagementLink = React.memo(() => (
  <MenuTile
    title={'Order Management'}
    url={orderSearchUrl}
    icon={'search'}
    isMaterialIcon={false}
  />
));

export default OrderManagementLink;
