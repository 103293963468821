import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { catalogApi } from '../../sqdApis';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import { mapColorsErrors } from '@util/componentHelpers/colorsHelper';

export const addColor = async (addColorForm) => {
  const call = catalogApi.post(productCatalogUtilityUrls.colors, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(addColorForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapColorsErrors);

  return res;
};

export const editColor = async (editColorForm) => {
  const call = catalogApi.put(productCatalogUtilityUrls.color(editColorForm.id), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(editColorForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapColorsErrors);

  return res;
};

export const deleteColor = async (colorId) => {
  const call = catalogApi.delete(productCatalogUtilityUrls.color(colorId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
