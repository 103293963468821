import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchOrderItems } from '@redux/backorderManagement/actions';
import {
  sendOrderItemNotifications,
  markOrderItemsAsNotificationSent,
} from '@APICalls/backorderManagement/actions';
import { materialSwal } from '@util/componentHelper';
import BackorderManagementOrderItem from './BackorderManagementOrderItem';
import InfoField from '@sharedComponents/Display/InfoField';
import Button from '@sharedComponents/Buttons/Button';
import SendNotificationsForItemsModal from '../../BackorderManagementModals/SendNotificationsForItemsModal';
import MarkItemsAsNotificationSentModal from '../../BackorderManagementModals/MarkItemsAsNotificationSentModal';

class BackorderManagementOrderItems extends PureComponent {
  state = {
    sendNotificationForItemsModalIsOpen: false,
    markItemsAsNotificationSentModalIsOpen: false,
    selectedItems: [],
  };

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(prevProps) {
    const { order } = this.props;

    if (order && prevProps.order !== order) {
      this.fetchItems();
      this.resetState();
    }
  }

  fetchItems = () => {
    const {
      order,
      dispatch,
      previouslyNotified,
      discontinued,
    } = this.props;

    dispatch(fetchOrderItems(order.orderNumber, previouslyNotified, discontinued));
  };

  resetState = () => {
    this.setState({
      sendNotificationForItemsModalIsOpen: false,
      markItemsAsNotificationSentModalIsOpen: false,
      selectedItems: [],
    });
  };

  openSendNotificationForItemsModal = () => {
    this.setState({ sendNotificationForItemsModalIsOpen: true });
  };

  closeSendNotificationForItemsModal = () => {
    this.setState({ sendNotificationForItemsModalIsOpen: false });
  };

  sendNotificationForItems = async () => {
    const { selectedItems } = this.state;
    const { order } = this.props;

    const res = await sendOrderItemNotifications(order.orderNumber, selectedItems);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.resetState();
      this.fetchItems();
    }
  };

  openMarkItemsAsNotificationSentModal = () => {
    this.setState({ markItemsAsNotificationSentModalIsOpen: true });
  };

  closeMarkItemsAsNotificationSentModal = () => {
    this.setState({ markItemsAsNotificationSentModalIsOpen: false });
  };

  markItemsAsNotificationSent = async () => {
    const { selectedItems } = this.state;
    const { order } = this.props;

    const res = await markOrderItemsAsNotificationSent(order.orderNumber, selectedItems);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.resetState();
      this.fetchItems();
    }
  };

  selectItem = (item) => {
    const { selectedItems } = this.state;
    this.setState({ selectedItems: this.toggleItemSelection(selectedItems, item) });
  };

  toggleItemSelection = (selectedItems, item) => {
    if (this.isSelectedItem(selectedItems, item)) {
      return selectedItems.filter((i) => i.ids.toString() !== item.ids.toString());
    }

    return [
      ...selectedItems,
      item,
    ];
  };

  isSelectedItem = (selectedItems, item) => (
    selectedItems.find((i) => i.ids.toString() === item.ids.toString()) !== undefined
  );

  render() {
    const {
      sendNotificationForItemsModalIsOpen,
      markItemsAsNotificationSentModalIsOpen,
      selectedItems,
    } = this.state;

    const {
      order,
      items,
      automatedNotificationsEnabled,
      manualNotificationsEnabled,
    } = this.props;

    return (
      <div>
        {
          automatedNotificationsEnabled &&
          <SendNotificationsForItemsModal
            isOpen={sendNotificationForItemsModalIsOpen}
            order={order}
            items={selectedItems}
            sendNotification={this.sendNotificationForItems}
            closeModal={this.closeSendNotificationForItemsModal}
          />
        }

        {
          <MarkItemsAsNotificationSentModal
            isOpen={markItemsAsNotificationSentModalIsOpen}
            order={order}
            items={selectedItems}
            markItems={this.markItemsAsNotificationSent}
            closeModal={this.closeMarkItemsAsNotificationSentModal}
          />
        }

        <div className='order__items backorder-management__sidebar__tab-content--items custom-scrollbar'>
          {
            items.map((item) => (
              <BackorderManagementOrderItem
                key={item.ids.toString()}
                item={item}
                isSelected={this.isSelectedItem(selectedItems, item)}
                selectItem={this.selectItem}
              />
            ))
          }
        </div>
        <div className='orderManagement__details-item-details'>
          <InfoField
            label={'Total Items'}
            value={`${order.backorderedItemIds.length}/${order.totalItems}`}
            fieldClass={'mb-0'}
          />
        </div>
        <div className='tableView__details--actions'>
          {
            automatedNotificationsEnabled &&
            <Button
              type={'primary'}
              text={'Send Notification'}
              onClick={this.openSendNotificationForItemsModal}
              disabled={selectedItems.length === 0}
            />
          }
          {
            manualNotificationsEnabled &&
            <Button
              type={'primary'}
              text={'Mark as "Message Sent"'}
              onClick={this.openMarkItemsAsNotificationSentModal}
              disabled={selectedItems.length === 0}
            />
          }
        </div>
      </div>
    );
  }
}

BackorderManagementOrderItems.propTypes = {
  order: PropTypes.shape({
    orderNumber: PropTypes.number.isRequired,
    backorderedItemIds: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    shipDate: PropTypes.string,
    pricePaid: PropTypes.number,
    refundAmount: PropTypes.number,
    chargeAmount: PropTypes.number,
    status: PropTypes.shape({
      label: PropTypes.string,
    }),
    shippingInfo: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    sku: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    styleName: PropTypes.string,
    quantity: PropTypes.number,
    size: PropTypes.string,
    floodColor: PropTypes.string,
    pricePaid: PropTypes.number,
    refundAmount: PropTypes.number,
    shipDate: PropTypes.string,
    promisedDate: PropTypes.string,
  })).isRequired,
  previouslyNotified: PropTypes.bool,
  discontinued: PropTypes.bool,
  automatedNotificationsEnabled: PropTypes.bool,
  manualNotificationsEnabled: PropTypes.bool,
};

const mapStateToProps = ({ backorderManagement }) => ({
  items: backorderManagement.orderItems,
});

export default connect(mapStateToProps)(BackorderManagementOrderItems);
