import { HagPrintRoomJobStatusEnum } from '@api/fulfillment/models';
import DtfPrinterModal from '../PrintRoomNew/PrintRoomTableContent/DtfPrinterModal';
import React, {
  useCallback, useState, useMemo,
} from 'react';
import {
  postHomefieldApiHagTransmissionssendprintroomjob as moveToInPrint,
  putHomefieldApiHagPrintroomprogressjob as progressJob,
} from '@api/fulfillment/hag';
import { printRoomOrderAssemblyUrl } from '@constants/clientUrls/clientUrls';

interface OwnProps {
  printStatus: HagPrintRoomJobStatusEnum;
  hagPrintRoomJobId: number;
  itemWrapper: React.FC;
  refetch: () => Promise<void>;
  orderNumber: string;
}

type Props = OwnProps;

const PrintRoomOrderActionButtons = React.memo<Props>(({
  printStatus, hagPrintRoomJobId, refetch, itemWrapper: ItemWrapper, orderNumber,
}) => {
  const primaryBtnClassName =
    'button button--color-gossamer button--size-m button--style-default'
    + ' button--radius-square button--shadow-m button--transform-uppercase';
  const secondaryBtnClassName =
    'button button-white button--size-m button--style-default'
    + ' button--radius-square button--shadow-m button--transform-uppercase';

  const jobsArr = useMemo(() => [hagPrintRoomJobId], [hagPrintRoomJobId]);
  const [
    dtfIsOpen,
    setDtfIsOpen,
  ] = useState(false);
  const openDtfModal = useCallback((ev?: React.BaseSyntheticEvent) => {
    ev?.preventDefault?.();
    setDtfIsOpen(true);
  }, []);
  const closeDtfModal = useCallback(() => setDtfIsOpen(false), []);

  const onPrintJob =
    useCallback(async (_: number[], printerNumber: number) => {
      await moveToInPrint({
        hagPrintRoomJobId,
        printerNumber,
      }, {
        isBlockingRequest: true,
        showErrorModal: true,
      });
      await refetch();
    }, [
      refetch,
      hagPrintRoomJobId,
    ]);

  const handleMarkAsPrinted =
    useCallback(async (ev?: React.BaseSyntheticEvent) => {
      ev?.preventDefault?.();

      await progressJob({
        hagPrintRoomJobId,
        toStatus: HagPrintRoomJobStatusEnum.Printed,
      }, {
        isBlockingRequest: true,
        showErrorModal: true,
      });
      await refetch();
    }, [
      refetch,
      hagPrintRoomJobId,
    ]);

  const handleMarkAsAssembling =
      useCallback(async (ev?: React.BaseSyntheticEvent) => {
        ev?.preventDefault?.();

        await progressJob({
          hagPrintRoomJobId,
          toStatus: HagPrintRoomJobStatusEnum.Assembling,
        }, {
          isBlockingRequest: true,
          showErrorModal: true,
        });
        await refetch();
      }, [
        refetch,
        hagPrintRoomJobId,
      ]);

  return (
    <>
      <ItemWrapper>
        <button
          onClick={openDtfModal}
          className={
            printStatus === HagPrintRoomJobStatusEnum.PrintReady
              ? primaryBtnClassName : secondaryBtnClassName
          }
        >
            Print DTF
        </button>
      </ItemWrapper>
      {
        printStatus === HagPrintRoomJobStatusEnum.Printing &&
        <ItemWrapper>
          <button
            onClick={handleMarkAsPrinted}
            className={primaryBtnClassName}
          >
            Mark Order as Printed
          </button>
        </ItemWrapper>
      }
      {
        printStatus === HagPrintRoomJobStatusEnum.Printed &&
        <ItemWrapper>
          <button
            onClick={handleMarkAsAssembling}
            className={primaryBtnClassName}
          >
            Start Print Assembly
          </button>
        </ItemWrapper>
      }
      {
        [
          HagPrintRoomJobStatusEnum.Assembling,
          HagPrintRoomJobStatusEnum.Assembled,
        ].includes(printStatus) &&
        <ItemWrapper>
          <a
            href={printRoomOrderAssemblyUrl(orderNumber)}
            className={primaryBtnClassName}
          >
            Open Print Assembly
          </a>
        </ItemWrapper>
      }
      <DtfPrinterModal
        title='DTF Print'
        formId={`dtf-printer-modal-form_sheet-${hagPrintRoomJobId}`}
        jobIds={jobsArr}
        isOpen={dtfIsOpen}
        onClose={closeDtfModal}
        onPrint={onPrintJob}
      />
    </>
  );
});

export default PrintRoomOrderActionButtons;
