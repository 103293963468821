import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import BarcodeReader from 'react-barcode-reader';
import { printRoomOrderUrl } from '@constants/clientUrls/clientUrls';

const barcodeRegex = /^O(?<orderNumber>\d+)$/i;

const PrintRoomNewBarcodeDetector = React.memo(() => {
  const history = useHistory();

  const handleScan = useCallback((data: unknown) => {
    if (typeof data !== 'string') {
      return;
    }

    const barcodeScan = data.trim();
    const match = barcodeScan.match(barcodeRegex);
    if (!match || !match.groups?.orderNumber) {
      return;
    }

    const { orderNumber } = match.groups;

    history.push(printRoomOrderUrl(orderNumber));
  }, [history]);

  return (
    <BarcodeReader onScan={handleScan} />
  );
});

export default PrintRoomNewBarcodeDetector;
