import React from 'react';
import iconPrinter from '@assets/icon_printer.svg';
import { productionAssemblyDtgUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from '@components/MyApps/MenuTile';

const ProductionAssemblyDtgLink = React.memo(() => (
  <MenuTile
    title={'DTG'}
    url={productionAssemblyDtgUrl}
    icon={iconPrinter}
    isCustomIcon={true}
  />
));

export default ProductionAssemblyDtgLink;
