import React from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { Permission } from '@customTypes/auth';
import {
  roleMapping,
  checkPermissions,
} from '@util/roleCheck';
import { RootState } from '@redux/index/reducers';

interface OwnProps {
  permissions: Permission[] | Permission;
  withCondition?: Nullable<boolean>;
  children: JSX.Element;
  fallback?: Nullable<JSX.Element>;
}

const mapStateToProps = ({ oidc }: RootState) => ({
  roles: roleMapping(oidc),
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const PermissionHandler = React.memo(({
  roles,
  permissions,
  withCondition = null,
  children,
  fallback = null,
}: Props) => {
  if (withCondition !== null) {
    return withCondition && checkPermissions(roles, permissions) ? children : fallback;
  }

  return checkPermissions(roles, permissions) ? children : fallback;
});

export default connector(PermissionHandler);
