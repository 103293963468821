import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getComparableStyleObjects } from '@util/componentHelpers/productCatalogSyncHelper';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import Button from '@sharedComponents/Buttons/Button';
import Expander from '@sharedComponents/Buttons/Expander';
import SyncStyleDataTable from './SyncStyleDataTable';
import SyncStyleActionModal from './SyncStyleActionModal';

class SyncStyleDetailsHeader extends PureComponent {
  state = {
    detailsAreExpanded: true,
    isSyncModalOpen: false,
  };

  changeExpand = () => {
    this.setState((prevState) => ({
      detailsAreExpanded: !prevState.detailsAreExpanded,
    }));
  };

  openSyncModal = () => {
    this.setState(() => ({ isSyncModalOpen: true }));
  };

  closeSyncModal = () => {
    this.setState(() => ({ isSyncModalOpen: false }));
  };

  handleSyncAction = (styleId) => {
    const { syncStyle } = this.props;

    syncStyle(styleId);
    this.closeSyncModal();
  };

  render() {
    const {
      style,
      styleFromSSAPI,
      sampleImage,
      openModalWithImage,
    } = this.props;

    const {
      detailsAreExpanded,
      isSyncModalOpen,
    } = this.state;

    const {
      catalogStyleObj,
      slServicesStyleObj,
    } = getComparableStyleObjects(style, styleFromSSAPI);

    return (
      <div className='lockerManagerEdit__header'>
        <SyncStyleActionModal
          isOpen={isSyncModalOpen}
          closeModal={this.closeSyncModal}
          id={style.id}
          label={`style ${style.name}`}
          syncData={this.handleSyncAction}
        />
        <div className='lockerManagerEdit__details-header'>
          <div className='flex'>
            <ImagePreviewCell
              imageUrl={sampleImage}
              openModalWithUrl={openModalWithImage}
              height={'80px'}
              width={'80px'}
            />
            <div className='tableView__details--header--title mb-20 ml-10'>
              <span>{style.name}</span>
            </div>
          </div>
          <Expander
            isExpanded={detailsAreExpanded}
            toggle={this.changeExpand}
          />
        </div>

        {
          detailsAreExpanded &&
          <div>
            <div className='flex'>
              <div className='w-50'>
                <SyncStyleDataTable
                  data={catalogStyleObj}
                  headerLabel={'Product Catalog'}
                />
              </div>
              <div className='w-50'>
                <SyncStyleDataTable
                  data={slServicesStyleObj}
                  headerLabel={'SL Services API'}
                />
              </div>
            </div>

            <div className='sync-details__button'>
              <Button
                type={'primary'}
                text={'Sync style'}
                onClick={this.openSyncModal}
                classes={'lockerManagerEdit__button--add'}
              />
            </div>
          </div>
        }

      </div>
    );
  }
}

SyncStyleDetailsHeader.propTypes = {
  style: PropTypes.object.isRequired,
  sampleImage: PropTypes.string,
  styleFromSSAPI: PropTypes.object.isRequired,
  openModalWithImage: PropTypes.func.isRequired,
  syncStyle: PropTypes.func.isRequired,
};

export default SyncStyleDetailsHeader;
