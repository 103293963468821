/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type ProductAgeEnum = 'Undefined' | 'Any' | 'Youth' | 'Adult';


export const ProductAgeEnum = {
  Undefined: 'Undefined' as ProductAgeEnum,
  Any: 'Any' as ProductAgeEnum,
  Youth: 'Youth' as ProductAgeEnum,
  Adult: 'Adult' as ProductAgeEnum,
};
