import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defaultLockerSuggestionsLimit } from '@constants/values';
import { fetchLockerSuggestions } from '@redux/organizations/actions';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import LockerSuggestion from '@components/Organizations/OrganizationModals/LockerSuggestion';
import CustomItemsTable from './CustomItemsTable';

class ImportItemModal extends Component {
  state = {
    isInputActive: false,
    selectedLocker: null,
    selectedItems: [],
    importLogos: false,
  };

  selectLocker = (selectedLocker) => {
    const { lockerId } = this.props;
    const { importLogos } = this.state;

    const newImportLogos = selectedLocker.id === lockerId ? false : importLogos;

    this.setState({
      importLogos: newImportLogos,
      selectedLocker,
    });
  };

  updateSelectedItems = (newSelectedItems) => {
    this.setState({ selectedItems: newSelectedItems });
  };

  onCloseModal = () => {
    const { closeModal } = this.props;

    this.setState({
      selectedLocker: null,
      selectedItems: [],
      importLogos: false,
    }, closeModal);
  };

  toggleImportLogos = () => {
    const { lockerId } = this.props;
    const {
      selectedLocker,
      importLogos,
    } = this.state;

    if (selectedLocker.id === lockerId) {
      return;
    }

    this.setState({ importLogos: !importLogos });
  };

  importItems = async () => {
    const { importItems } = this.props;
    const {
      selectedItems,
      importLogos,
    } = this.state;

    const res = await importItems(selectedItems, importLogos);

    if (res?.success) {
      this.onCloseModal();
    }
  };

  fetchLockerSuggestions = (searchInput) => {
    const { dispatch } = this.props;
    dispatch(fetchLockerSuggestions(searchInput, defaultLockerSuggestionsLimit, true));
  };

  displayLocker = (locker) => (locker.teamName);

  displayLockerSuggestion = (locker, searchInput) => (
    <LockerSuggestion
      locker={locker}
      searchInput={searchInput}
    />
  );

  render() {
    const {
      isOpen,
      suggestions,
      itemsFound,
      lockerId,
    } = this.props;

    const {
      selectedLocker,
      selectedItems,
      importLogos,
    } = this.state;

    return (
      <Modal
        title={'Import Existing Items'}
        modalHeight={'xl'}
        modalWidth={selectedLocker ? 'xl' : 'l'}
        isOpen={isOpen}
        closeModal={this.onCloseModal}
        enableBodyOverflow={!selectedLocker}
        buttons={(
          <ModalButtons
            confirmBtnText={'Continue'}
            cancelBtnText={'Cancel'}
            onConfirm={this.importItems}
            onClose={this.onCloseModal}
            confirmBtnDisabled={selectedItems.length === 0}
          />
        )}
      >
        <div>
          <div className='lockerManagerEdit__importItemsModal__text align-left mb-10'>Select Source:</div>
          <div className='align__center'>
            <AutocompleteInput
              suggestions={suggestions}
              fetchSuggestions={this.fetchLockerSuggestions}
              displayItem={this.displayLocker}
              displaySuggestionText={this.displayLockerSuggestion}
              selectedItem={selectedLocker}
              selectItem={this.selectLocker}
              placeholder={'Search for Locker'}
              hasSearchIcon={true}
              classes={selectedLocker ? 'w-50' : 'w-100'}
              size={'l'}
              isDropdownFixed={true}
            />
            {
              selectedLocker &&
              <MaterialCheckbox
                text={'Import Logos'}
                checked={importLogos}
                onClick={this.toggleImportLogos}
                disabled={selectedLocker.id === lockerId}
                classes={'ml-10'}
              />
            }
          </div>

          {
            selectedLocker &&
            <div className='mt-20'>
              <div className='lockerManagerEdit__importItemsModal__text mb-10'>{itemsFound} items in this locker
                <span className='status-label enabled ml-10'>{selectedItems.length} item selected</span>
              </div>
              <CustomItemsTable
                lockerId={selectedLocker.id}
                selectedItems={selectedItems}
                updateSelectedItems={this.updateSelectedItems}
              />
            </div>
          }
        </div>
      </Modal>
    );
  }
}

ImportItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  lockerId: PropTypes.number.isRequired,
  importItems: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  itemsFound: PropTypes.number,
};

const mapStateToProps = ({
  organizations,
  lockerManager,
}) => ({
  suggestions: organizations.lockerSuggestions,
  itemsFound: lockerManager.lockerItemsQueue.totalPages,
});

export default connect(mapStateToProps)(ImportItemModal);
