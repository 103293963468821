import {
  decorationMethodEnum, servicesApiDecorationMethods,
} from '@constants/enums/decorationEnums';

export const transform = (shortDecorationMethod: string) => {
  switch (shortDecorationMethod) {
    case decorationMethodEnum.DTG: {
      return servicesApiDecorationMethods.DTG;
    }
    case decorationMethodEnum.EMB: {
      return servicesApiDecorationMethods.EMB;
    }
    case decorationMethodEnum.HAG: {
      return servicesApiDecorationMethods.HAG;
    }
    case decorationMethodEnum.SUB: {
      return servicesApiDecorationMethods.SUB;
    }
    case decorationMethodEnum.DIP: {
      return servicesApiDecorationMethods.DIP;
    }
    default: {
      return decorationMethodEnum.Undefined;
    }
  }
};

export const mapToDecorationMethod = (
  servicesApiDecorationMethod: (typeof servicesApiDecorationMethods)[keyof typeof servicesApiDecorationMethods]
): (typeof decorationMethodEnum
  )[keyof typeof decorationMethodEnum] | null => {
  switch (servicesApiDecorationMethod) {
    case servicesApiDecorationMethods.HAG:
      return decorationMethodEnum.HAG;
    case servicesApiDecorationMethods.EMB:
      return decorationMethodEnum.EMB;
    case servicesApiDecorationMethods.DTG:
      return decorationMethodEnum.DTG;
    case servicesApiDecorationMethods.SUB:
      return decorationMethodEnum.SUB;
    case servicesApiDecorationMethods.DIP:
      return decorationMethodEnum.DIP;
    default:
      return null;
  }
};

export default { transform };
