import React from 'react';
import PropTypes from 'prop-types';
import ProductionAssemblyPrintQueue from './ProductionAssemblyPrintQueue';

const ProductionAssemblyPrintQueueTab = React.memo(({
  printQueue,
  onRemoveItemFromPrintQueue,
  onRemoveItemGroupFromPrintQueue,
  onPrintQueueItemQuantityUpdate,
  onClearPrintQueue,
  dtgStations,
  totalQuantity,
  refreshInProgressTab,
  selectedStation,
  onStationChange,
  selectedGroupByValue,
  onGroupByOptionChange,
  dtgAnyActiveTransmissions,
  disabledAfterSendingToPrint,
  disableSendButtons,
}) => (
  <ProductionAssemblyPrintQueue
    printQueue={printQueue}
    onRemoveItemFromPrintQueue={onRemoveItemFromPrintQueue}
    onRemoveItemGroupFromPrintQueue={onRemoveItemGroupFromPrintQueue}
    onPrintQueueItemQuantityUpdate={onPrintQueueItemQuantityUpdate}
    onClearPrintQueue={onClearPrintQueue}
    dtgStations={dtgStations}
    totalQuantity={totalQuantity}
    refreshInProgressTab={refreshInProgressTab}
    selectedStation={selectedStation}
    onStationChange={onStationChange}
    selectedGroupByValue={selectedGroupByValue}
    onGroupByOptionChange={onGroupByOptionChange}
    dtgAnyActiveTransmissions={dtgAnyActiveTransmissions}
    disabledAfterSendingToPrint={disabledAfterSendingToPrint}
    disableSendButtons={disableSendButtons}
  />
));

ProductionAssemblyPrintQueueTab.propTypes = {
  printQueue: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemoveItemFromPrintQueue: PropTypes.func.isRequired,
  onRemoveItemGroupFromPrintQueue: PropTypes.func.isRequired,
  onPrintQueueItemQuantityUpdate: PropTypes.func.isRequired,
  onClearPrintQueue: PropTypes.func.isRequired,
  dtgStations: PropTypes.array.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  refreshInProgressTab: PropTypes.func.isRequired,
  selectedStation: PropTypes.string,
  onStationChange: PropTypes.func.isRequired,
  selectedGroupByValue: PropTypes.string.isRequired,
  onGroupByOptionChange: PropTypes.func.isRequired,
  dtgAnyActiveTransmissions: PropTypes.bool.isRequired,
  disabledAfterSendingToPrint: PropTypes.bool.isRequired,
  disableSendButtons: PropTypes.func.isRequired,
};

export default ProductionAssemblyPrintQueueTab;
