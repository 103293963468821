import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scheduleOrders } from '@APICalls/vendorOrders/actions';
import {
  pageSizeOptionsBigTable,
  defaultPageSizeBigTable,
} from '@constants/values';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import {
  fetchNotScheduledOrdersTable,
  fetcOrderItemDetailsForOrders,
} from '@redux/vendorOrders/actions';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { materialSwal } from '@util/componentHelper';
import { parseDateNumeric } from '@util/dateHandler';
import Table from '@sharedComponents/Table/Table';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Link from '@sharedComponents/Navigation/Link';
import Label from '@sharedComponents/Display/Label';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import TooltipCell from '@sharedComponents/Table/TableCells/TooltipCell';
import ScheduleOrdersModal from '../VendorOrdersModals/ScheduleOrdersModal/ScheduleOrdersModal';
import NotScheduledVendorOrdersQuickView from '../VendorOrderQuickView/NotScheduledVendorOrdersQuickView';

const OrdersTable = Table();

const bulkActionsEnum = {
  scheduleOrders: 'scheduleOrders',
};

const bulkActions = [
  {
    key: 1,
    value: bulkActionsEnum.scheduleOrders,
    name: 'Schedule Order(s)',
  },
];

class NotScheduledVendorOrdersTab extends PureComponent {
  state = {
    selectedOrder: null,
    selectedOrders: [],
    isPageSelected: false,
    sortOrder: '',
    sortBy: '',
    scheduleOrdersModalIsOpen: false,
  };

  componentDidUpdate(prevProps) {
    const {
      searchInput,
      vendorsFilter,
    } = this.props;

    if (prevProps.searchInput !== searchInput || prevProps.vendorsFilter !== vendorsFilter) {
      this.search();
    }
  }

  search = () => {
    const {
      dispatch,
      searchInput,
      vendorsFilter,
    } = this.props;

    const {
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
    } = this.state;

    dispatch(fetchNotScheduledOrdersTable(
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      searchInput,
      vendorsFilter
    ));
  };

  fetchData = (state, instance) => {
    const {
      sortBy,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortBy, sortOrder);

    this.setState({
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: page + 1,
      pageSize,
    }, this.search);
  };

  updateSelection = (newSelectedItems, newIsPageSelected) => {
    this.setState({
      selectedOrders: newSelectedItems,
      isPageSelected: newIsPageSelected,
    });
  };

  onSelectBulkAction = (selectedBulkAction) => {
    switch (selectedBulkAction) {
      case bulkActionsEnum.scheduleOrders:
        this.onBulkScheduleOrders();
        break;
    }
  };

  onBulkScheduleOrders = () => {
    this.openScheduleOrdersModal();
  };

  openScheduleOrdersModal = () => {
    const {
      dispatch,
      vendorsFilter,
    } = this.props;
    const { selectedOrders } = this.state;

    const orderNumbers = selectedOrders.map((order) => order.orderNumber);
    dispatch(fetcOrderItemDetailsForOrders(orderNumbers, vendorsFilter));

    this.setState({ scheduleOrdersModalIsOpen: true });
  };

  closeScheduleOrdersModal = () => {
    this.setState({ scheduleOrdersModalIsOpen: false });
  };

  selectOrder = (selectedOrder) => {
    this.setState({ selectedOrder });
  };

  unselectOrder = () => {
    this.setState({ selectedOrder: null });
  };

  scheduleVendorOrders = async (orderItemIds) => {
    const res = await scheduleOrders(orderItemIds);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.search();
      this.closeScheduleOrdersModal();
      this.updateSelection([], false, false);
    }
  };

  getTrProps = (state, rowInfo) => {
    const { selectedOrder } = this.state;

    return getSelectableTableRowProps(this.selectOrder, rowInfo, selectedOrder, 'orderNumber');
  };

  getColumns = () => {
    const { vendors } = this.props;

    const columns = [
      {
        Header: <HeaderCell text={'Received'} />,
        accessor: 'created',
        minWidth: 50,
        sortable: true,
        Cell: (cellProps) => cellProps.value && <span>{parseDateNumeric(cellProps.value)}</span>,
      },
      {
        Header: <HeaderCell text={'Order ID'} />,
        accessor: 'orderNumber',
        minWidth: 60,
        sortable: true,
        Cell: (cellProps) => cellProps.value && (
          <Link
            url={`${orderDetailsUrl(cellProps.value)}`}
            text={`O${cellProps.value}`}
          />
        ),
      },
      {
        Header: 'Single Locker & Organization',
        accessor: '',
        minWidth: 100,
        Cell: (cellProps) => {
          const {
            lockerId,
            lockerTeamName,
            lockers,
            organizationId,
            organizationName,
          } = cellProps.value;

          return (
            <div className='ordermanagement__details--cell'>
              {
                organizationId
                  ? (
                    <div>
                      <div>ORG{organizationId} - {organizationName}</div>
                      <MaterialTooltip
                        tooltipText={(
                          <div className='pre-line'>
                            {lockers.map((locker) => `L${locker.lockerId} - ${locker.teamName}`).join('\n')}
                          </div>
                        )}
                        placement={'top'}
                      >
                        <div className='table__cell--details'>{`${lockers.length} ${lockers.length === 1 ? 'Locker' : 'Lockers'}`}</div>
                      </MaterialTooltip>
                    </div>
                  )
                  : `L${lockerId} - ${lockerTeamName}`
              }
            </div>
          );
        },
      },
      {
        Header: 'VFIs',
        accessor: '',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value && (
          <span className='align__center'>
            <span>{cellProps.value.vfiItemIds.length}/{cellProps.value.allItemsCount}</span>
            {
              cellProps.value.vfiItemIds.length > 0
              && cellProps.value.allItemsCount
              && (cellProps.value.vfiItemIds.length === cellProps.value.allItemsCount) &&
              <Label
                text={'Full'}
                type={'status-orange'}
                classes={'ml-10'}
              />
            }
          </span>
        ),
      },
      {
        Header: 'Vendors',
        accessor: 'vendorIds',
        minWidth: 50,
        Cell: (cellProps) => {
          const vendorsList = [];

          for (const vendorCode of cellProps.value) {
            const vendor = vendors.find((x) => x.code === vendorCode);
            if (vendor) {
              vendorsList.push(vendor);
            }
          }

          const vendorNames = vendorsList.map((vendor) => vendor.name);

          return (
            vendorNames.length > 1
              ? (
                <TooltipCell
                  items={vendorNames}
                  displayName={'Vendors'}
                />
              )
              : <div>{vendorNames.length > 0 ? vendorNames[0] : ''}</div>
          );
        },
      },
    ];

    return columns;
  };

  render() {
    const {
      queue,
      hasNextPage,
      hasPreviousPage,
      totalPages,
      totalCount,
      vendorsFilter,
      vendors,
      orderItemDetailsDictionary,
    } = this.props;

    const {
      selectedOrder,
      selectedOrders,
      isPageSelected,
      scheduleOrdersModalIsOpen,
    } = this.state;

    return (
      <div className='container'>
        <ScheduleOrdersModal
          isOpen={scheduleOrdersModalIsOpen}
          closeModal={this.closeScheduleOrdersModal}
          orderItemDetailsDictionary={orderItemDetailsDictionary}
          scheduleVendorOrders={this.scheduleVendorOrders}
          vendors={vendors}
        />
        <div className='master-detail'>
          <div className='lockerManager__master'>
            <div className='sheet'>
              <OrdersTable
                data={queue}
                columns={this.getColumns()}
                onFetchData={this.fetchData}
                totalPages={totalPages}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                defaultPageSize={defaultPageSizeBigTable}
                pageSizeOptions={pageSizeOptionsBigTable}
                getTrProps={this.getTrProps}

                tableName={'Not Scheduled Orders'}
                selectable={true}
                selectPredicateOrKey={'orderNumber'}
                updateSelection={this.updateSelection}
                selectedData={selectedOrders}
                isPageSelected={isPageSelected}
                totalCount={totalCount}
                bulkActionsList={bulkActions}
                onSelectBulkAction={this.onSelectBulkAction}
              />
            </div>
          </div>
          {
            selectedOrder &&
            <NotScheduledVendorOrdersQuickView
              order={selectedOrder}
              vendorsFilter={vendorsFilter}
              closeDetails={this.unselectOrder}
              scheduleVendorOrders={this.scheduleVendorOrders}
            />
          }
        </div>
      </div>
    );
  }
}

NotScheduledVendorOrdersTab.propTypes = {
  totalPages: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.shape({
    orderNumber: PropTypes.number.isRequired,
    vfiItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    allItemsCount: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    vendorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    shippingInfo: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    lockerId: PropTypes.number.isRequired,
    teamName: PropTypes.string.isRequired,
  })).isRequired,
  searchInput: PropTypes.string,
  vendorsFilter: PropTypes.arrayOf(PropTypes.string),
  vendors: PropTypes.arrayOf(PropTypes.object),
  orderItemDetailsDictionary: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  vendorOrders,
  productCatalog,
}) => ({
  totalPages: vendorOrders.notScheduledOrdersQueue.totalPages,
  totalCount: vendorOrders.notScheduledOrdersQueue.totalCount,
  hasPreviousPage: vendorOrders.notScheduledOrdersQueue.hasPreviousPage,
  hasNextPage: vendorOrders.notScheduledOrdersQueue.hasNextPage,
  pageNumber: vendorOrders.notScheduledOrdersQueue.pageNumber,
  queue: vendorOrders.notScheduledOrdersQueue.queue,
  pageSize: vendorOrders.notScheduledOrdersQueue.pageSize,
  orderItemDetailsDictionary: vendorOrders.orderItemDetailsDictionary,
  vendors: productCatalog.vendors,
});

export default connect(mapStateToProps)(NotScheduledVendorOrdersTab);
