import React, { useCallback } from 'react';
import { ProgressBar } from 'react-bootstrap';
import Label from '@sharedComponents/Display/Label';
import Link from '@sharedComponents/Navigation/Link';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import { OrderItemFileJobDto } from '@api/fulfillment/models/orderItemFileJobDto';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import { OrderItemFileJobDetailsItem } from './OrderItemFileJobDetailsItem';
import { UploadFileHandlerType } from './OrderItemFilesSharedTypes';
import Button from '@components/shared/Buttons/Button';

type OwnProps = {
  job: OrderItemFileJobDto;
  onUpload: UploadFileHandlerType;
  onClose?: () => unknown;
  onCompletedToggle?: (job: OrderItemFileJobDto) => unknown;
};

type Props = OwnProps;

export const OrderItemFileJobDetails: React.FC<Props> = React.memo(({ job, onClose, onUpload, onCompletedToggle }) => {
  const handleCompletedToggle = useCallback(() => {
    onCompletedToggle?.(job);
  }, [onCompletedToggle, job]);

  const uploadedItemCount = job.jobItems?.filter((item) => item.uploaded?.length).length ?? 0;
  const itemCount = job.jobItems?.length ?? 0;

  return (
    <TableQuickViewContainer
      noPadding={true}
      size={TableQuickViewSizeEnum.Large}
      classes={'scheduling__details'}
    >
      <>
        <TableQuickViewHeader
          title={(
            <span>
              <Link
                openInNewTab={true}
                url={orderDetailsUrl(job.orderNumber!)}
                text={`Order O${job.orderNumber}`}
              />
            </span>
          )} onClose={onClose}
        />
        <div className={'tableView__details--content'}>
          <div className={'scheduling__details--content__items'}>
            {
              job.jobItems?.map((item) => (
                <OrderItemFileJobDetailsItem
                  key={item.orderItemId}
                  item={item}
                  jobId={job.id!}
                  orderNumber={job.orderNumber!}
                  onUpload={onUpload}
                />
              ))
            }
          </div>
        </div>
        <div className='tableView__details--footer'>
          <div className='scheduling__progress scheduling__progress--available'>
            <div className=' mr-15'>
              {uploadedItemCount}/{itemCount}
            </div>
            <ProgressBar
              className='scheduling__progress-bar big'
              variant={
                uploadedItemCount === itemCount
                  ? 'success'
                  : 'warning'
              }
              now={itemCount <= 0
                ? 0
                : (uploadedItemCount / itemCount) * 100}
            />
          </div>
          <Button
            type={'primary'}
            text={job.isCompleted ? 'Reopen Job' : 'Complete Job'}
            onClick={handleCompletedToggle}
            disabled={!job.isCompleted && uploadedItemCount < itemCount}
          />
        </div>
      </>
    </TableQuickViewContainer>
  );
});

export default OrderItemFileJobDetails;
