import React from 'react';
import PropTypes from 'prop-types';
import Image from '@sharedComponents/Display/Image';

const LayoutInfo = React.memo(({ layout }) => (
  <div className='mt-10'>
    <div className='lockerManager__details-subheader mb-15'>
      <span>
        Category: <span className='info__value'>{layout.category ? layout.category.name : '-'}</span>
      </span>
      <span className='ml-20'>
        Sport: <span className='info__value'>{layout.sport ? layout.sport.name : '-'}</span>
      </span>
    </div>
    <div className='flex mb-30'>
      <Image
        url={layout.imageUrlFront}
        classes={'layouts-table__image'}
        showNoImage={false}
      />
      <Image
        url={layout.imageUrlBack}
        classes={'layouts-table__image'}
        showNoImage={false}
      />
      <Image
        url={layout.imageUrlLeft}
        classes={'layouts-table__image'}
        showNoImage={false}
      />
      <Image
        url={layout.imageUrlRight}
        classes={'layouts-table__image'}
        showNoImage={false}
      />
    </div>
  </div>
));

LayoutInfo.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.number.isRequired,
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    sport: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string,
    imageUrlFront: PropTypes.string,
    imageUrlBack: PropTypes.string,
    imageUrlRight: PropTypes.string,
    imageUrlLeft: PropTypes.string,
  }),
};

export default LayoutInfo;
