import React from 'react';

interface OwnProps {
  text: string;
}

type Props = OwnProps;

const NoteDescription = React.memo<Props>(({ text }) => (
  <div className='note__content'>
    {text}
  </div>
));

export default NoteDescription;
