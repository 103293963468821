import * as actionTypes from './types';

export const initialState = {
  payoutsQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    totalRequestedPayouts: 0,
    totalProcessedPayouts: 0,
    queue: [],
  },
  payout: {},
  payoutLockers: [],
  orderItemsStatus: {},
  accountingSubmitResults: [],
};

export default function fundraisingPayouts(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_PAYOUTS_TABLE:
      return {
        ...state,
        payoutsQueue: action.data,
      };
    case actionTypes.UPDATE_PAYOUT:
      return {
        ...state,
        payout: action.data,
      };
    case actionTypes.UPDATE_LOCKERS_FOR_PAYOUT:
      return {
        ...state,
        payoutLockers: action.data,
      };
    case actionTypes.UPDATE_ORDER_ITEMS_STATUS:
      return {
        ...state,
        orderItemsStatus: action.data,
      };
    case actionTypes.UPDATE_ACCOUNTING_SUBMIT_RESULTS:
      return {
        ...state,
        accountingSubmitResults: action.data,
      };
    default:
      return state;
  }
}
