import React, { useCallback } from 'react';
import { navigateToPage } from '@util/componentHelper';

interface OwnProps {
  title: string;
  url: string;
  taskCount: number;
  currentLocation: string | undefined;
  status: string;
  resetTable: () => void;
}

type Props = OwnProps;

const ProductionArtworkTab = React.memo<Props>(({
  title,
  url,
  taskCount,
  currentLocation,
  status,
  resetTable,
}) => {
  const goToPage = useCallback(() => {
    navigateToPage(url);
    resetTable();
  }, [
    url,
    resetTable,
  ]);

  return (
    <li>
      <button
        type='button'
        className={url === currentLocation ? 'is-active' : ''}
        onClick={goToPage}
      >
        <strong>{title}</strong> (
        <span
          data-test={`task-count-${status}`}
          className={`task__status--${status.toLowerCase()}`}
        >
          {taskCount || 0}
        </span>
        )
      </button>
    </li>
  );
});

export default ProductionArtworkTab;
