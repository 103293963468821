import React from 'react';
import { vendorOrdersUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const VendorsOrdersLink = React.memo(() => (
  <MenuTile
    title={'Vendor Order Management'}
    url={vendorOrdersUrl}
    icon={'storefront'}
  />
));

export default VendorsOrdersLink;
