import React from 'react';
import PropTypes from 'prop-types';
import RostersTable from '@components/RosterManager/RostersTable';

const RostersTab = React.memo(({ organizationId }) => (
  <RostersTable organizationId={organizationId} />
));

RostersTab.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default RostersTab;
