import React from 'react';
import { getParentLockerOptions } from '@constants/options/options';
import { dropdownSizeL } from '@constants/values';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

const options = getParentLockerOptions();

interface OwnProps {
  filter: (value: string) => void;
}

type Props = OwnProps;

const ParentLockerFilter = React.memo<Props>(({ filter }) => (
  <div className='filter-group'>
    <Dropdown
      options={options}
      onChange={filter}
      size={dropdownSizeL}
    />
  </div>
));

export default ParentLockerFilter;
