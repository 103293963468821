import React, {
  useState,
  useCallback,
} from 'react';
import SimpleConfirmationModal, { SimpleConfirmationModalProps } from '@components/shared/Modal/SimpleConfirmationModal';

type Props = SimpleConfirmationModalProps;

export const useSimpleConfirmationModal = () => {
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const ConfirmationModal = React.memo<Props>(({ ...props }) => (
    <React.Fragment>
      {
        isOpen &&
        <SimpleConfirmationModal
          {...props}
        />
      }
    </React.Fragment>
  ));

  return {
    openModal,
    closeModal,
    isOpen,
    ConfirmationModal,
  };
};
