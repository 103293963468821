import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteNote } from '@redux/artworkFileTasks/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';

const ArtworkTaskNoteTop = React.memo(({
  user,
  date,
  id,
  createdBy,
  lockerId,
  taskId,
  loggedInUser,
  deleteNoteSend,
}) => (
  <div className='note-details'>
    <span className='note__author'>
      {user}
    </span>
    <span className='note__timestamp flex'>
      {parseDateTimeNumeric(date)}
      <div className='action__delete-container'>
        {
          createdBy === loggedInUser &&
          <Icon
            materialIcon={'delete'}
            classes={'margin-left'}
            onClick={deleteNoteSend.bind(null, lockerId, taskId, id)}
          />
        }
      </div>
    </span>
  </div>
));

ArtworkTaskNoteTop.propTypes = {
  user: PropTypes.string,
  date: PropTypes.string,
  loggedInUser: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  taskId: PropTypes.number.isRequired,
  createdBy: PropTypes.string,
  lockerId: PropTypes.number.isRequired,
  deleteNoteSend: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  deleteNoteSend: deleteNote,
};

export default connect(null, mapDispatchToProps)(ArtworkTaskNoteTop);
