import React from 'react';
import PropTypes from 'prop-types';
import { mapLayoutFormInitialValues } from '@util/componentHelpers/layoutsHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import LayoutForm from '../LayoutForm';
import { layoutForm } from '@constants/reduxForms';

const LayoutEditModal = React.memo(({
  isOpen,
  editLayout,
  initialValues,
  change,
  closeModal,
}) => (
  <Modal
    title={'Edit Location Layout'}
    isOpen={isOpen}
    modalSize={'xl'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Save'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={layoutForm}
      />
    )}
  >
    <LayoutForm
      onSubmit={editLayout}
      initialValues={mapLayoutFormInitialValues(initialValues)}
      change={change}
    />
  </Modal>
));

LayoutEditModal.propTypes = {
  editLayout: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  change: PropTypes.func,
};

export default LayoutEditModal;
