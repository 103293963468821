import React from 'react';
import ArtworkTaskDetailsId from './ArtworkTaskDetailsId';
import ArtworkTaskDetailsStatus from './ArtworkTaskDetailsStatus';
import ArtworkTaskDetailsItemIcons from './ArtworkTaskDetailsItemIcons';
import ArtworkTaskDetailsImage from './ArtworkTaskDetailsImage';
import ArtworkTaskDetailsShipDate from './ArtworkTaskDetailsShipDate';

interface OwnProps {
  logoUrl: string;
  id: number;
  decorationMethod: string;
  decorationSizeWidth: number;
  decorationSizeHeight: number;
  status: string;
  statusLabel: string;
  onClick: () => void;
  isCutLogo: boolean;
  cutColor: string;
  shipDate: string;
  isRushed: boolean;
  isCanceled: boolean;
  isPaused: boolean;
  isExpressProduction: boolean;
  isArchived: boolean;
  hasRequiredItems: boolean;
  isExistingTask: boolean;
  approvedDecorationMethods: string[] | undefined;
}

type Props = OwnProps;

const ArtworkTaskDetailsItemBackground = React.memo<Props>(({
  logoUrl,
  id,
  decorationMethod,
  decorationSizeHeight,
  decorationSizeWidth,
  status,
  statusLabel,
  cutColor,
  isCutLogo,
  onClick,
  shipDate,
  isExpressProduction,
  isPaused,
  isCanceled,
  isRushed,
  isArchived,
  hasRequiredItems,
  isExistingTask,
  approvedDecorationMethods,
}) => (
  <div onClick={onClick}>
    <div className='task__status'>
      {
        isExistingTask &&
        <ArtworkTaskDetailsId
          id={id}
          hasRequiredItems={hasRequiredItems}
        />
      }
      <ArtworkTaskDetailsStatus
        status={status}
        statusLabel={statusLabel}
      />
    </div>
    <ArtworkTaskDetailsShipDate
      shipDate={shipDate}
      isRushed={isRushed}
      isPaused={isPaused}
      isCanceled={isCanceled}
      isArchived={isArchived}
      isExpressProduction={isExpressProduction}
      isExistingTask={isExistingTask}
    />
    <ArtworkTaskDetailsImage
      logoUrl={logoUrl}
    />
    <ArtworkTaskDetailsItemIcons
      decorationMethod={decorationMethod}
      decorationSizeWidth={decorationSizeWidth}
      decorationSizeHeight={decorationSizeHeight}
      cutColor={cutColor}
      isCutLogo={isCutLogo}
      approvedDecorationMethods={approvedDecorationMethods}
    />
  </div>
));

export default ArtworkTaskDetailsItemBackground;
