import { OrderItemDetailsDto } from '@api/fulfillment/models';
import VoucherDetailsHeader from '@components/OrderManagement/OrderManagementDetails/OrderItemsContent/VoucherDetailsHeader';
import { groupBy } from '@util/arrayHelper';
import React from 'react';
import OrderManagementQuickViewItem from './OrderManagementQuickViewItem';

interface OwnProps{
  items: OrderItemDetailsDto[];
}

type Props = OwnProps;

const OrderManagementQuickViewItemsList = React.memo<Props>(({ items }) => {
  const renderItems = (itemsList: OrderItemDetailsDto[]) => (
    itemsList.map((item) => (
      <OrderManagementQuickViewItem
        key={item.ids!.toString()}
        item={item}
      />
    ))
  );

  const renderVoucherItems = (itemsInVoucher: OrderItemDetailsDto[]) => {
    const itemsByVoucher = groupBy(itemsInVoucher, 'voucherCode');
    const content = [];

    for (const [
      voucherCode,
      voucherItems,
    ] of Object.entries(itemsByVoucher)) {
      content.push(
        <div className='order__item__voucher'>
          <VoucherDetailsHeader
            voucherCode={voucherCode}
            collectionId={voucherItems[0].collectionId!}
            rosterMemberId={voucherItems[0].rosterTeamMemberId!}
            alignData={'column'}
          />
          {renderItems(voucherItems)}
        </div>
      );
    }

    return <div>{content}</div>;
  };

  const itemsWithVoucher = items.filter((item) => item.voucherId);
  const itemsWithoutVoucher = items.filter((item) => !item.voucherId);

  return (
    <div className='order__items orderManagement__details-items custom-scrollbar'>
      {renderVoucherItems(itemsWithVoucher)}
      {renderItems(itemsWithoutVoucher)}
    </div>
  );
});

export default OrderManagementQuickViewItemsList;
