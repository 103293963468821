import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';
import Chip from '@sharedComponents/Inputs/Chips/Chip';
import FormError from '@sharedComponents/Form/FormError';

class LayoutsInputErrors extends PureComponent {
  renderUnsupportedLocations = (unsupportedLocations) => {
    const {
      decorationLocations,
      addLocation,
    } = this.props;

    return (
      <div>
        {
          unsupportedLocations.map((l) => {
            const location = decorationLocations.find((dl) => dl.id === l.locationId);
            const decorationLocation = {
              ...l,
              location: location.code,
            };

            return (
              <Chip
                key={l.locationId}
                text={location.code}
                onClick={addLocation.bind(null, decorationLocation)}
                hasIcon={true}
                icon={(
                  <Icon
                    materialIcon={'add_circle'}
                    classes={'catalog-details__layouts-input__add-icon ml-5'}
                  />
                )}
              />
            );
          })
        }
      </div>
    );
  };

  renderLayoutError = (key, layoutError) => {
    const {
      layoutName,
      unsupportedLocations,
    } = layoutError;

    return (
      <div
        key={key}
        className='mt-20 mb-20'
      >
        <div className='warning-msg'>
          <Icon materialIcon={'warning'} />
          <span className='warning-msg--text'>
            {layoutName} is not fully compatible to use on this item.
            Missing (<b>{unsupportedLocations.length}</b>) locations
          </span>
        </div>
        {this.renderUnsupportedLocations(unsupportedLocations)}
      </div>
    );
  };

  render() {
    const {
      formErrors,
      validationErrors,
    } = this.props;

    const layoutErrors = Object.entries(validationErrors);

    if (layoutErrors.length > 0) {
      return (
        <div>
          {layoutErrors.map((entry) => this.renderLayoutError(entry[0], entry[1]))}
        </div>
      );
    }

    if (formErrors) {
      return (
        <div>
          <FormError
            error={formErrors}
            enableMultiple={true}
            classes={'lockerManagerEdit__custom-items--decoration__error'}
          />
        </div>
      );
    }

    return null;
  }
}

LayoutsInputErrors.propTypes = {
  formErrors: PropTypes.arrayOf(PropTypes.string),
  validationErrors: PropTypes.object,
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  addLocation: PropTypes.func.isRequired,
};

export default LayoutsInputErrors;
