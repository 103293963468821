import React from 'react';
import PropTypes from 'prop-types';

const ImagePreviewCell = React.memo(({ imageUrl }) => {
  if (!imageUrl) {
    return <div />;
  }

  return (
    <a className='details__item-thumbnail button image-preview-cell details__item-thumbnail--color-transparent'>
      <i
        className='details__item-image'
        style={{ backgroundImage: `url("${imageUrl}")` }}
      />
    </a>
  );
});

ImagePreviewCell.propTypes = {
  imageUrl: PropTypes.string,
};

export default ImagePreviewCell;
