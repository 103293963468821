import * as actionTypes from './types';

const initialState = {
  products: {
    items: [],
    tableState: {
      pageNumber: 1,
      pageSize: 10,
      totalPages: 0,
      hasPreviousPage: false,
      hasNextPage: false,
      totalCount: 0,
    },
  },
};

export default function onHandInventory(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_INVENTORY:
      return {
        ...state,
        products: {
          items: action.items,
          tableState: action.tableState,
        },
      };

    default:
      return state;
  }
}
