import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { styleUrl } from '@constants/clientUrls/clientUrls';

const ProductCatalogQuickViewActions = React.memo(({ styleId }) => (
  <div className='product-catalog__details--actions'>
    <Link
      className='button button--color-gossamer button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase'
      to={styleUrl(styleId)}
    >
      <span className='uppercase'>Style details</span>
    </Link>
  </div>
));

ProductCatalogQuickViewActions.propTypes = {
  styleId: PropTypes.number.isRequired,
};

export default ProductCatalogQuickViewActions;
