import React, { useCallback } from 'react';

interface OwnProps {
  imageUrl: string;
  imageTitle: string;
  openModal: (imageUrl: string) => void;
}

type Props = OwnProps;

const OrderManagementDetailsItemExpandImage = React.memo(({
  imageUrl,
  imageTitle,
  openModal,
}: Props) => {
  const handleOpenImageModal = useCallback(() => {
    openModal(imageUrl);
  }, [
    openModal,
    imageUrl,
  ]);

  return (
    <div
      className='order__files-item'
      onClick={handleOpenImageModal}
    >
      <div className='order__files-image'>
        <img src={imageUrl} />
      </div>
      <div className='order__files-image--location'>{imageTitle}</div>
    </div>
  );
});

export default OrderManagementDetailsItemExpandImage;
