import React from 'react';
import {
  productionAssemblyEmbUrl,
  productionAssemblyEmbReviewByOrderUrl,
  productionAssemblyEmbReviewByBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import ProductionAssemblyReview from '@components/Production/Shared/ProductionAssemblyReview/ProductionAssemblyReview';

const ProductionAssemblyEmb = React.memo(() => (
  <ProductionAssemblyReview
    decorationMethod={decorationMethodEnum.EMB}
    reviewByOrderUrl={productionAssemblyEmbReviewByOrderUrl}
    reviewByBarcodeUrl={productionAssemblyEmbReviewByBarcodeUrl}
    productionAssemblyUrl={productionAssemblyEmbUrl}
  />
));

export default ProductionAssemblyEmb;
