import { OrderPricingDetailsDtoOrderItemsPricingDetails } from '@api/orders/models';
import { OrderItemDetailsDto } from '@api/fulfillment/models';
import React from 'react';
import OrderManagementOrderRefundItem from './OrderManagementOrderRefundItem';

interface OwnProps {
  items: OrderItemDetailsDto[];
  coreOrderItemsPricing: OrderPricingDetailsDtoOrderItemsPricingDetails | undefined;
}

type Props = OwnProps;

const OrderManagementOrderRefundItems = React.memo<Props>(({
  items,
  coreOrderItemsPricing,
}) => (
  <div className='refund__items'>
    {
      items.filter(
        (item) => !item.canceled
        || (coreOrderItemsPricing
          ? coreOrderItemsPricing[item.coreId!].pricePaid! > 0
          : item.pricePaid! > 0)
      ).map((item, index) => (
        <OrderManagementOrderRefundItem
          key={index}
          item={item}
          coreItemPricing={(item && coreOrderItemsPricing?.[item.coreId!]) ?? undefined}
        />
      ))
    }
  </div>
));

export default OrderManagementOrderRefundItems;
