/* eslint react/no-multi-comp: 0 */
import React from 'react';
import { Link } from 'react-router-dom';

/*
 * ** Generates a Custom Link
 * Import and call with the name of the route.
 * Returns a function that, when passed a string or number,
 * inserts it as a param for the link.
 */
export const customLink = (route: string) => (param: string): JSX.Element => <Link to={`/${route}/${param}`}>{param}</Link>;
export const jobLink = (route: string) => (id: number, status: string): JSX.Element => <Link to={`/${route}/${id}/${status}`}>{`L${id}`}</Link>;
