import React from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import ItemSelectionIcon from '@sharedComponents/Icons/ItemSelectionIcon';

const AssignLogoCard = React.memo(({
  logo,
  toggleSelectLogo,
  isSelected,
  isDisabled = false,
}) => {
  const selectOnClick = () => {
    if (!isDisabled) {
      toggleSelectLogo(logo, isSelected);
    }
  };

  return (
    <div
      onClick={selectOnClick}
      className={`add-product__available--container ${isSelected && 'isSelected'} logo-bank__assignment--logo-card ${isDisabled ? 'disabled' : ''}`}
    >
      <div className='add-product__available--product'>
        <div
          className='add-product__available--image'
          style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logo.image}")` }}
        />
        <ItemSelectionIcon
          isSelected={isSelected}
          isDisabled={isDisabled}
        />
      </div>
      <span className='add-product__available--title logo-bank__assignment--logo-card--title'>
        {logo.image}
      </span>
    </div>
  );
});

AssignLogoCard.propTypes = {
  logo: PropTypes.object.isRequired,
  toggleSelectLogo: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export default AssignLogoCard;
