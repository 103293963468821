import React from 'react';
import iconEye from '@assets/icon_eye_green.svg';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  text?: string | JSX.Element;
  tooltipText?: string | JSX.Element;
  tooltipClassName?: string;
}

type Props = OwnProps;

const ShowMoreTooltip = React.memo<Props>(({
  text = '',
  tooltipText = '',
  tooltipClassName = '',
}) => (
  <MaterialTooltip
    tooltipText={tooltipText}
    placement={'bottom'}
    contentClasses={'align__center'}
    tooltipClassName={tooltipClassName}
  >
    <span className='align__center'>
      <i
        className='product-catalog__icon-eye'
        style={{ backgroundImage: `url("${iconEye}")` }}
      />
      <span className='see-more-tooltip--text'>{text}</span>
    </span>
  </MaterialTooltip>
));

export default ShowMoreTooltip;
