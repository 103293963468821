import * as actionTypes from './types';

export const initialState = {
  layoutsQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
    pageNumber: 1,
    pageSize: 10,
  },
  layoutDetails: null,
  layoutSports: [],
  sportsForLayoutsDictionary: {},
  layoutValidationErrors: {},
};

export default function layouts(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_LAYOUTS_TABLE:
      return {
        ...state,
        layoutsQueue: action.data,
      };
    case actionTypes.UPDATE_LAYOUT_DETAILS:
      return {
        ...state,
        layoutDetails: action.data,
      };
    case actionTypes.UPDATE_LAYOUT_VALIDATION_ERRORS:
      return {
        ...state,
        layoutValidationErrors: action.data,
      };
    case actionTypes.UPDATE_SPORTS_FOR_LAYOUT:
      return {
        ...state,
        layoutSports: action.data,
      };
    case actionTypes.UPDATE_SPORTS_FOR_LAYOUT_DICTIONARY:
      return {
        ...state,
        sportsForLayoutsDictionary: action.data,
      };
    default:
      return state;
  }
}
