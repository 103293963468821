import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submitOrderNotes } from '@APICalls/orderManagement/actions';
import { fetchOrderNotes } from '@redux/orderManagement/actions';
import { roleMapping } from '@util/roleCheck';
import { materialSwal } from '@util/componentHelper';
import { dragAndDropMessage } from '@constants/values';
import Button from '@sharedComponents/Buttons/Button';
import TextareaInput from '@sharedComponents/Inputs/TextareaInput';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';

class OrderManagementSidebarNewNote extends Component {
  state = {
    attachedFile: null,
    note: '',
    message: {
      type: 'default',
      body: dragAndDropMessage(),
    },
  };

  componentDidUpdate(oldProps) {
    const { orderNumber } = this.props;

    if (oldProps.orderNumber !== orderNumber) {
      this.clearState();
    }
  }

  clearState = () => {
    this.setState({
      note: '',
      attachedFile: null,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
    });
  };

  addFileToState = (attachedFile) => {
    this.setState(() => ({ attachedFile }));
  };

  removeFileFromState = () => {
    this.setState(() => ({ attachedFile: null }));
  };

  handleNoteChange = (e) => {
    const note = e.target.value;
    this.setState(() => ({ note }));
  };

  addNewNote = async () => {
    const {
      dispatch,
      orderNumber,
    } = this.props;

    const {
      attachedFile,
      note,
    } = this.state;

    const noteModel = {
      text: note,
      file: attachedFile,
    };

    const res = await submitOrderNotes(orderNumber, noteModel);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      dispatch(fetchOrderNotes(orderNumber));
    }

    this.clearState();
  };

  destroyFiles = (acceptedFiles, rejectedFiles) => {
    for (const file of acceptedFiles.concat(rejectedFiles)) {
      window.URL.revokeObjectURL(file.preview);
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      message: {
        body: `Add ${acceptedFiles[0].name} note?`,
        type: 'default',
      },
    }, () => {
      this.addFileToState(acceptedFiles);
      this.destroyFiles(acceptedFiles, rejectedFiles);
    });
  };

  render() {
    const { roles } = this.props;
    const {
      note,
      message,
    } = this.state;

    return (
      <div>
        <div className='text-field mb-30'>
          <TextareaInput
            disabled={roles.includes('Finalizer')}
            placeholder={'Write a note...'}
            value={note}
            onChange={this.handleNoteChange}
            inputClasses={'w-100'}
          />
        </div>
        <div className='upload-field--horizontal m-t-16'>
          <DropZoneWrapper
            onDrop={this.onDrop}
            className={'upload-field'}
          >
            <label>{message.body}</label>
          </DropZoneWrapper>
          <Button
            type={'primary'}
            text={'Add Note'}
            onClick={this.addNewNote}
            disabled={!note}
          />
        </div>
      </div>
    );
  }
}

OrderManagementSidebarNewNote.propTypes = {
  orderNumber: PropTypes.number.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ oidc }) => ({
  roles: roleMapping(oidc),
});

export default connect(mapStateToProps)(OrderManagementSidebarNewNote);
