/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type VoucherInstanceStatusEnum = 'Undefined' | 'Pending' | 'Activated' | 'Redeemed';


export const VoucherInstanceStatusEnum = {
  Undefined: 'Undefined' as VoucherInstanceStatusEnum,
  Pending: 'Pending' as VoucherInstanceStatusEnum,
  Activated: 'Activated' as VoucherInstanceStatusEnum,
  Redeemed: 'Redeemed' as VoucherInstanceStatusEnum,
};
