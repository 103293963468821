import React from 'react';
import { decorationLocationsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const DecorationLocationsLink = React.memo(() => (
  <MenuTile
    title={'Decoration Locations'}
    url={decorationLocationsUrl}
    icon={'location_searching'}
  />
));

export default DecorationLocationsLink;
