import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  scanItemsForm,
  scanDeviceCodeForm,
} from '@constants/reduxForms';
import {
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import { validateItemCodes } from '@redux/devices/validations';
import ScanItemInputs from './ScanItemInputs';
import { keyNameEnum } from '@constants/enums/commonEnums';

const ScanItemsForm = React.memo(({
  handleSubmit,
  deviceCode,
  initialize,
}) => {
  useEffect(() => {
    initialize({
      deviceAssociations: [
        {
          deviceCode,
          itemCode: '',
        },
      ],
    });
  }, []);

  const onKeyPress = (event) => {
    if (event.key === keyNameEnum.Enter) {
      event.preventDefault();
    }
  };

  return (
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      onKeyPress={onKeyPress}
      id={scanItemsForm}
    >
      <div>
        <div className='ml-15 mr-10'>
          <div>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-m'>
                <label className='redux-form__label'>
                    Item Code
                  <span className='required'>*</span>
                </label>
                <FieldArray
                  name={'deviceAssociations'}
                  placeholder={'Scan or Enter a Item Code'}
                  component={ScanItemInputs}
                  deviceCode={deviceCode}
                  rerenderOnEveryChange={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

ScanItemsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  deviceCode: PropTypes.string,
  initialize: PropTypes.func.isRequired,
};

const selector = formValueSelector(scanDeviceCodeForm);
export default connect(((state) => ({
  deviceCode: selector(state, 'deviceCode'),
})))(reduxForm({
  form: scanItemsForm,
  validate: validateItemCodes,
})(ScanItemsForm));
