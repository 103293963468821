import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  fetchLockerManagerTable,
  fetchFulfillmentLockersInfo,
} from '@redux/lockerManager/actions';
import { parseDateNumeric } from '@util/dateHandler';
import { formatDollarAmount } from '@util/numberHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Link from '@sharedComponents/Navigation/Link';
import Table from '@sharedComponents/Table/Table';

const LockersTable = Table();

class LockersTab extends Component {
  state = {
    sortOrder: this.props.sortOrder,
    sortByState: this.props.sortByState,
    pageNumber: 1,
    pageSize: 10,
    include3MonthSales: false,
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.props;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrder);

    this.setState({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }, () => this.refreshData({
      pageNumber: page + 1,
      pageSize,
    }));
  };

  refreshData = (optionsDict = {}) => {
    const {
      dispatch,
      adminId,
    } = this.props;

    const {
      sortByState,
      sortOrder,
    } = this.state;

    const args = {
      ...this.props,
      ...optionsDict,
    };

    return dispatch(fetchLockerManagerTable(
      args.pageNumber,
      args.pageSize,
      '',
      '',
      '',
      false,
      sortByState,
      sortOrder,
      '',
      adminId
    ));
  };

  getColumns = (include3MonthSales) => {
    const { lockersInfo } = this.props;

    const columns = [
      {
        Header: <HeaderCell text={'ID'} />,
        accessor: 'id',
        sortable: true,
        minWidth: 50,
        Cell: (cellProps) => (
          <Link
            url={lockerDetailsUrl(cellProps.value)}
            text={`L${cellProps.value}`}
          />
        ),
      },
      {
        Header: <HeaderCell text={'Locker Name'} />,
        accessor: 'team_name',
        sortable: true,
        minWidth: 120,
        className: 'text-bold',
      },
      {
        Header: <HeaderCell text={'Owner'} />,
        accessor: 'email',
        sortable: true,
        minWidth: 120,
      },
      {
        Header: 'Partner Name',
        accessor: 'partner_name',
        sortable: false,
        minWidth: 85,
      },
    ];

    const additionalColumns = [
      {
        Header: 'Sport',
        accessor: 'sport_name',
        sortable: false,
        minWidth: 65,
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        minWidth: 70,
        sortable: false,
        Cell: (cellProps) => cellProps.value && parseDateNumeric(cellProps.value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        minWidth: 65,
      },
    ];

    if (include3MonthSales) {
      additionalColumns.unshift({
        Header: <HeaderCell text={'3 Month Sales'} />,
        accessor: '',
        sortable: true,
        minWidth: 80,
        Cell: (cellProps) => {
          const lockerInfo = lockersInfo && lockersInfo.find((locker) => locker.id === cellProps.original.id);

          return formatDollarAmount(lockerInfo && lockerInfo.sales3Month);
        },
      });
    }

    return [
      ...columns,
      ...additionalColumns,
    ];
  };

  toggleInclude3MonthSales = () => {
    const { include3MonthSales } = this.state;
    const {
      queue,
      dispatch,
    } = this.props;

    this.setState({
      include3MonthSales: !include3MonthSales,
    }, () => {
      if (!include3MonthSales && queue && queue.length > 0) {
        dispatch(fetchFulfillmentLockersInfo(queue.map((locker) => locker.id)));
      }
    });
  };

  render() {
    const {
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    const { include3MonthSales } = this.state;

    return (
      <div className='sheet'>
        <div className='flex pt-10 pb-10'>
          <MaterialCheckbox
            text={'Include 3 Month Sales'}
            checked={include3MonthSales}
            onClick={this.toggleInclude3MonthSales}
          />
        </div>
        <LockersTable
          data={queue}
          columns={this.getColumns(include3MonthSales)}
          showPagination={hasNextPage || hasPreviousPage}
          totalPages={totalPages}
          onFetchData={this.fetchData}
        />
      </div>
    );
  }
}

LockersTab.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortOrder: PropTypes.string,
  sortByState: PropTypes.string,
  adminId: PropTypes.number.isRequired,
  lockersInfo: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    totalOrders: PropTypes.number,
    totalSales: PropTypes.number,
    sales3Month: PropTypes.number,
    totalFundraising: PropTypes.number,
  })),
};

const mapStateToProps = ({ lockerManager }) => ({
  totalPages: lockerManager.currentQueue.totalPages,
  hasPreviousPage: lockerManager.currentQueue.hasPreviousPage,
  hasNextPage: lockerManager.currentQueue.hasNextPage,
  queue: lockerManager.currentQueue.queue,
  sortOrder: lockerManager.currentQueue.sortOrder,
  sortByState: lockerManager.currentQueue.sortByState,
  lockersInfo: lockerManager.lockersInfo,
});

export default connect(mapStateToProps)(LockersTab);
