import { Dispatch } from 'redux';
import * as actionTypes from './types';
import vendorOrdersUrls from '@constants/sqdApiUrls/vendorOrdersUrls';
import { getVendorsList } from '@redux/productCatalog/actions';
import { makeApiCall } from '@util/apiHelper';
import { fulfillmentApi } from '../../sqdApis';

export const updateNotScheduledOrdersTable = (data: any) => ({
  type: actionTypes.UPDATE_NOT_SCHEDULED_ORDERS_TABLE,
  data: {
    totalPages: data.totalPages,
    hasPreviousPage: data.hasPreviousPage,
    hasNextPage: data.hasNextPage,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    queue: data.items,
    totalCount: data.totalCount,
  },
});

export const updateScheduledOrdersTable = (data: any) => ({
  type: actionTypes.UPDATE_SCHEDULED_ORDERS_TABLE,
  data: {
    totalPages: data.totalPages,
    hasPreviousPage: data.hasPreviousPage,
    hasNextPage: data.hasNextPage,
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    queue: data.items,
    totalCount: data.totalCount,
  },
});

export const updateNotScheduledOrderItems = (data: any) => ({
  type: actionTypes.UPDATE_NOT_SCHEDULED_ORDER_ITEMS,
  data,
});

export const updateOrderItemsDetailsDictionary = (data: any) => ({
  type: actionTypes.UPDATE_ORDER_ITEMS_DETAILS_DICTIONARY,
  data,
});

export const updateVendorOrderItems = (data: any) => ({
  type: actionTypes.UPDATE_VENDOR_ORDER_ITEMS,
  data,
});

export const updateVendorOrderSubmitResults = (data: any) => ({
  type: actionTypes.UPDATE_VENDOR_ORDER_SUBMIT_RESULTS,
  data,
});

export const updateAvailableSkusForVendorDesignId = (data: any) => ({
  type: actionTypes.UPDATE_AVAILABLE_SKUS_FOR_VENDOR_DESIGN_ID,
  data,
});

export const fetchVendorsFilter = () => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(vendorOrdersUrls.vendorsFilter, { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  getVendorsList(data)(dispatch);

  return data;
};

export const fetchNotScheduledOrdersTable = (
  pageNumber = 1,
  pageSize = 10,
  sortBy = '',
  sortOrder = '',
  search = '',
  vendorId = null
) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(vendorOrdersUrls.notScheduledOrdersTable(
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    search,
    vendorId
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  dispatch(updateNotScheduledOrdersTable(data));

  return data;
};

export const fetchScheduledOrdersTable = (
  pageNumber = 1,
  pageSize = 10,
  sortBy = '',
  sortOrder = '',
  search = '',
  vendorId = null,
  submitStatus = '',
  canceled = false
) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(vendorOrdersUrls.vendorOrdersTable(
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    search,
    vendorId,
    submitStatus,
    canceled
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  dispatch(updateScheduledOrdersTable(data));

  return data;
};

export const fetcOrderItemDetailsForOrders = (
  orderNumbers: number[],
  vendorId = null
) => async (dispatch: Dispatch) => {
  const orderItemDetailsDict: { [orderNumber: number]: any; } = {};

  const promises = orderNumbers.map(async (orderNumber) => {
    const call = fulfillmentApi.get(vendorOrdersUrls.notScheduledOrderItems(
      orderNumber,
      vendorId
    ), { handleBlockingLoading: false });
    const res = await makeApiCall(call);

    if (res) {
      orderItemDetailsDict[orderNumber] = res;
    }
  });

  await Promise.all(promises);

  dispatch(updateOrderItemsDetailsDictionary(orderItemDetailsDict));
};

export const fetchNotScheduledOrderItems = (
  orderNumber: number,
  vendorId = null
) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(vendorOrdersUrls.notScheduledOrderItems(
    orderNumber,
    vendorId
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  dispatch(updateNotScheduledOrderItems(data));

  return data;
};

export const fetchVendorOrderItems = (vendorOrderId: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(vendorOrdersUrls.vendorOrderItems(vendorOrderId), { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  dispatch(updateVendorOrderItems(data));

  return data;
};

export const fetchVendorOrderSubmitResults = (vendorOrderId: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(vendorOrdersUrls.vendorOrderSubmitResults(vendorOrderId), {
    handleBlockingLoading: false,
  });

  const res = await makeApiCall(call);

  if (res) {
    await dispatch(updateVendorOrderSubmitResults(res));
  }
};

export const fetchAvailableSkusForVendorDesignId = (
  vendorCode: string,
  vendorDesignId: string
) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(vendorOrdersUrls.availableSkusForVendorDesignId(vendorCode, vendorDesignId), {
    handleBlockingLoading: false,
  });

  const res = await makeApiCall(call);

  if (res && res.skus) {
    await dispatch(updateAvailableSkusForVendorDesignId(res));
  }
};
