import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lockerMgrS3Logos } from '@constants/common';
import {
  updateProductAssignment,
  fetchUsedLogos,
} from '@redux/lockerManager/actions';
import { materialSwal } from '@util/componentHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import LogoBankEditAssignmentTable from './LogoBankEditAssignmentTable';
import LogoBankEditAssignmentReview from './LogoBankEditAssignmentReview';

class LogoBankEditAssignment extends PureComponent {
  state = {
    selectedLogoTab: true,
    selectedItemsTab: false,
    selectedReviewTab: false,
    logoIcon: true,
    itemsIcon: false,
    reviewIcon: false,
    nextStep: true,
    productsToAddLogo: [],
    productsToRemoveLogo: [],
    selectAll: false,
  };

  componentDidUpdate(prevProps) {
    const oldProductQueue = prevProps.currentProductQueue;
    const {
      currentProductQueue,
      logoId,
    } = this.props;

    if (currentProductQueue && oldProductQueue !== currentProductQueue) {
      const { productsToAddLogo } = this.state;

      const allProductsWithoutLogo = currentProductQueue.filter((p) => p.logos.map((l) => l.id).indexOf(logoId) < 0);
      const addedProducts = allProductsWithoutLogo.filter((p) => productsToAddLogo.find((ptal) => ptal.id === p.id));

      if (addedProducts.length === 0) {
        this.setState(() => ({ selectAll: false }));
      } else if (addedProducts.length === allProductsWithoutLogo.length) {
        this.setState(() => ({ selectAll: true }));
      }
    }
  }

  onClose = (e) => {
    const { closeModal } = this.props;

    this.initializeState();
    closeModal(e);
  };

  initializeState = () => {
    this.setState(() => ({
      selectedLogoTab: true,
      selectedItemsTab: false,
      selectedReviewTab: false,
      logoIcon: true,
      itemsIcon: false,
      reviewIcon: false,
      nextStep: true,
      productsToAddLogo: [],
      productsToRemoveLogo: [],
      selectAll: false,
    }));
  };

  nextStep = () => {
    const {
      selectedLogoTab,
      selectedItemsTab,
      selectedReviewTab,
    } = this.state;

    if (selectedLogoTab) {
      this.setState(() => ({
        selectedLogoTab: false,
        selectedItemsTab: true,
        itemsIcon: true,
      }));
    } else if (selectedItemsTab) {
      this.setState(() => ({
        selectedItemsTab: false,
        selectedReviewTab: true,
        reviewIcon: true,
        nextStep: false,
      }));
    } else if (selectedReviewTab) {
      this.setState(() => ({ selectedReviewTab: false }));
    }
  };

  selectLogo = () => {
    this.setState(() => ({
      selectedLogoTab: true,
      selectedItemsTab: false,
      selectedReviewTab: false,
      nextStep: true,
    }));
  };

  selectItems = () => {
    this.setState(() => ({
      selectedLogoTab: false,
      selectedItemsTab: true,
      selectedReviewTab: false,
      itemsIcon: true,
      nextStep: true,
    }));
  };

  selectReview = () => {
    this.setState(() => ({
      selectedLogoTab: false,
      selectedItemsTab: false,
      selectedReviewTab: true,
      reviewIcon: true,
      nextStep: false,
    }));
  };

  selectProductWithLogo = (product) => {
    const { logoId } = this.props;
    const { productsToRemoveLogo } = this.state;
    const { id } = product;

    if (productsToRemoveLogo.filter((p) => p.id === id).length > 0) {
      this.setState(() => ({ productsToRemoveLogo: productsToRemoveLogo.filter((p) => p.id !== id) }));
    } else if (product.logos.filter((l) => l.id === logoId).length > 0) {
      this.setState(() => ({
        productsToRemoveLogo: [
          ...productsToRemoveLogo,
          product,
        ],
      }));
    }
  };

  selectAllProducts = () => {
    const {
      currentProductQueue,
      logoId,
    } = this.props;

    const {
      selectAll,
      productsToAddLogo,
    } = this.state;

    const allProductsWithoutLogo = currentProductQueue.filter((p) => p.logos.map((l) => l.id).indexOf(logoId) < 0);

    if (!selectAll) {
      const newProductsToAddLogo = [...productsToAddLogo];

      for (const prodToAdd of allProductsWithoutLogo) {
        if (!productsToAddLogo.find((ptal) => ptal.id === prodToAdd.id)) {
          newProductsToAddLogo.push(prodToAdd);
        }
      }

      this.setState(() => ({
        productsToAddLogo: newProductsToAddLogo,
        selectAll: true,
      }));
    } else {
      this.setState(() => ({
        productsToAddLogo: productsToAddLogo
          .filter((prod) => !allProductsWithoutLogo.find((obj) => obj.id === prod.id)),
        selectAll: false,
      }));
    }
  };

  selectProduct = (product) => {
    const {
      currentProductQueue,
      logoId,
    } = this.props;

    const { productsToAddLogo } = this.state;

    const allProductsWithoutLogo = currentProductQueue.filter((p) => p.logos.map((l) => l.id).indexOf(logoId) < 0);

    if (productsToAddLogo.filter((p) => p.id === product.id).length > 0) {
      this.setState(() => ({
        productsToAddLogo: productsToAddLogo.filter((p) => p.id !== product.id),
      }), () => {
        const newProducts = allProductsWithoutLogo.filter(
          (p) => this.state.productsToAddLogo.find((ptal) => ptal.id === p.id)
        );
        if (newProducts.length === 0) {
          this.setState({
            selectAll: false,
          });
        }
      });
    } else {
      this.setState((prevState) => ({
        productsToAddLogo: [
          ...prevState.productsToAddLogo,
          product,
        ],
      }), () => {
        const newProducts = allProductsWithoutLogo.filter(
          (p) => this.state.productsToAddLogo.find((ptal) => ptal.id === p.id)
        );
        if (newProducts.length === allProductsWithoutLogo.length) {
          this.setState(() => ({
            selectAll: true,
          }));
        }
      });
    }
  };

  confirm = () => {
    const {
      productsToAddLogo,
      productsToRemoveLogo,
    } = this.state;

    const {
      logoId,
      lockerId,
      closeModal,
      dispatch,
    } = this.props;

    const addCssIds = productsToAddLogo.map((p) => p.id);
    const removeCssIds = productsToRemoveLogo.map((p) => p.id);

    Promise.resolve(updateProductAssignment(lockerId, logoId, addCssIds, removeCssIds))
      .then((res) => {
        closeModal();

        return res;
      })
      .then((res) => {
        if (res.success) {
          materialSwal('Success', 'Logo assignment was successful!', 'success');
          this.initializeState();
        } else {
          materialSwal('Error', 'Something went wrong.', 'error');
        }
        dispatch(fetchUsedLogos(lockerId, '', ''));
      });
  };

  render() {
    const {
      logoImage,
      lockerId,
      modalIsOpen,
      logoId,
    } = this.props;

    const {
      selectedItemsTab,
      selectedReviewTab,
      selectedLogoTab,
      logoIcon,
      reviewIcon,
      itemsIcon,
      productsToAddLogo,
      productsToRemoveLogo,
      selectAll,
      nextStep,
    } = this.state;

    const isActiveChooseLogo = selectedLogoTab ? 'is-active' : '';
    const isActiveChooseItems = selectedItemsTab ? 'is-active' : '';
    const isActiveReview = selectedReviewTab ? 'is-active' : '';

    return (
      <Modal
        title={'Edit Logo Assignment'}
        modalSize={'xl'}
        isOpen={modalIsOpen}
        closeModal={this.onClose}
        buttons={(
          <ModalButtons
            confirmBtnText={nextStep ? 'Next Step' : 'Confirm'}
            cancelBtnText={'Cancel'}
            onConfirm={nextStep ? this.nextStep : this.confirm}
            onClose={this.onClose}
          />
        )}
      >
        <div>
          <div className='tabs'>
            <div
              className={`tab-item ${isActiveChooseLogo}`}
              onClick={this.selectLogo}
            >
              {logoIcon ? <i className='material-icons logo-bank__assignment--check'>check_circle</i> : <i className='material-icons logo-bank__assignment--circle'>panorama_fish_eye</i>}
              <span>1. Choose Logo</span>
            </div>
            <div
              className={`tab-item ${isActiveChooseItems}`}
              onClick={this.selectItems}
            >
              {itemsIcon ? <i className='material-icons logo-bank__assignment--check'>check_circle</i> : <i className='material-icons logo-bank__assignment--circle'>panorama_fish_eye</i>}
              <span>2. Choose Item(s)</span>
            </div>
            <div
              className={`tab-item ${isActiveReview}`}
              onClick={this.selectReview}
            >
              {reviewIcon ? <i className='material-icons logo-bank__assignment--check'>check_circle</i> : <i className='material-icons logo-bank__assignment--circle'>panorama_fish_eye</i>}
              <span>3. Review & Confirm</span>
            </div>
          </div>
          {
            selectedLogoTab &&
            <div
              className='logo-bank__assignment--logo--image'
              style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logoImage}")` }}
            />
          }
          {
            selectedItemsTab &&
            <LogoBankEditAssignmentTable
              lockerId={lockerId}
              logoId={logoId}
              selectProduct={this.selectProduct}
              selectAllProducts={this.selectAllProducts}
              selectProductWithLogo={this.selectProductWithLogo}
              productsToAddLogo={productsToAddLogo}
              productsToRemoveLogo={productsToRemoveLogo}
              selectAll={selectAll}
            />
          }
          {
            selectedReviewTab &&
            <LogoBankEditAssignmentReview
              productsToAddLogo={productsToAddLogo}
              productsToRemoveLogo={productsToRemoveLogo}
              logoImage={logoImage}
            />
          }
        </div>
      </Modal>
    );
  }
}

LogoBankEditAssignment.propTypes = {
  logoId: PropTypes.number.isRequired,
  logoImage: PropTypes.string.isRequired,
  lockerId: PropTypes.number.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentProductQueue: PropTypes.array.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  currentProductQueue: lockerManager.currentProductQueue.queue,
});

export default connect(mapStateToProps)(LogoBankEditAssignment);
