import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  warningMessage: string;
  title?: string;
}

type Props = OwnProps;

export type SimpleWarningModalProps = Props;

const SimpleWarningModal = React.memo<Props>(({
  isOpen,
  closeModal,
  warningMessage,
  title = 'Warning',
}) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    title={title}
    modalHeight={'l'}
    modalWidth={'m'}
    buttons={(
      <ModalButtons
        onClose={closeModal}
        cancelBtnText={'OK'}
        displayConfirmBtn={false}
      />
    )}
  >
    <h3 className='modal__description'>
      <div>{warningMessage}</div>
    </h3>
  </Modal>
));

export default SimpleWarningModal;
