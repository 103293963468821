import backorderManagementUrls from '@constants/sqdApiUrls/backorderManagementUrls';
import { makeApiCallWithErrorModal } from '@util/apiHelper';
import { fulfillmentApi } from '../../sqdApis';

export const sendOrderNotificationsForAllOrders = async (
  previouslyNotified: boolean | null = null,
  discontinued: boolean | null = null
) => {
  const body = JSON.stringify({
    previouslyNotified,
    discontinued,
  });

  const call = fulfillmentApi.post(
    backorderManagementUrls.sendAllOrderNotifications,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }
  );
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const sendOrderNotifications = async (orders: any[]) => {
  const orderItemIds = orders.map((order) => order.backorderedItemIds).flat();

  const body = JSON.stringify({
    orderItemIds,
  });

  const call = fulfillmentApi.post(backorderManagementUrls.sendOrderNotifications, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const sendOrderItemNotifications = async (orderNumber: number, orderItems: any[]) => {
  const orderItemIds = orderItems.map((item) => item.ids).flat();
  const body = JSON.stringify({
    orderNumber,
    orderItemIds,
  });

  const call = fulfillmentApi.post(backorderManagementUrls.sendOrderItemNotifications, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markAllOrdersAsNotificationSent = async (
  previouslyNotified: boolean | null = null,
  discontinued: boolean | null = null
) => {
  const body = JSON.stringify({
    previouslyNotified,
    discontinued,
  });

  const call = fulfillmentApi.post(
    backorderManagementUrls.markAllOrdersAsNotificationSent,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    }
  );
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markOrdersAsNotificationSent = async (orders: any[]) => {
  const orderItemIds = orders.map((order) => order.backorderedItemIds).flat();

  const body = JSON.stringify({
    orderItemIds,
  });

  const call = fulfillmentApi.post(backorderManagementUrls.markOrdersAsNotificationSent, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markOrderItemsAsNotificationSent = async (orderNumber: number, orderItems: any[]) => {
  const orderItemIds = orderItems.map((item) => item.ids).flat();
  const body = JSON.stringify({
    orderNumber,
    orderItemIds,
  });

  const call = fulfillmentApi.post(backorderManagementUrls.markOrderItemsAsNotificationSent, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
