import React from 'react';
import PropTypes from 'prop-types';

const FundraisingPayoutsAdminCell = React.memo(({ admin }) => {
  if (!admin) return null;

  return (
    <div className='fundraising-payouts__admin'>
      <div className='fundraising-payouts__admin--name'>
        {`${admin.firstName || ''} ${admin.lastName || ''}`}
      </div>
      <div className='fundraising-payouts__admin--email'>
        {admin.email || '-'}
      </div>
    </div>
  );
});

FundraisingPayoutsAdminCell.propTypes = {
  admin: PropTypes.object,
};

export default FundraisingPayoutsAdminCell;
