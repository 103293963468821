import * as actionTypes from './types';
import { lockerMgrS3Logos } from '@constants/common';
import { slServicesApi } from '../../sqdApis';
import { makeApiCallWithErrorModal } from '@util/apiHelper';
import logoUrls from '@constants/sqdApiUrls/logoUrls';

export const updateLogo = (data) => ({
  type: actionTypes.FETCH_LOGO,
  payload: data,
});

export const updateLogoColoredStyleColors = (data) => ({
  type: actionTypes.FETCH_COLORED_STYLE_COLORS_FOR_LOGO,
  payload: data,
});

export const updateLogosDecorationMethods = (data) => ({
  type: actionTypes.UPDATE_LOGO_DECORATION_METHODS,
  payload: data,
});

export const fetchLogo = (logoId) => async (dispatch) => {
  if (!logoId) {
    return dispatch(updateLogo(null));
  }

  const call = slServicesApi.get(logoUrls.logo(logoId), { handleBlockingLoading: false });
  const res = await makeApiCallWithErrorModal(call);

  if (res) {
    let customColors = [];
    if (res.custom_artwork_colors && res.custom_artwork_colors.length > 0) {
      customColors = res.custom_artwork_colors.map((c) => ({
        code: c,
        displayName: c,
      }));
    }

    const selectedColors = (res.selected_colors || []).map((c) => ({
      code: c.code,
      id: c.id,
      selectionType: c.selection_type,
      hexValue: c.hex_value,
    }));

    const model = {
      id: res.id,
      image: res.image,
      imageUrl: `${lockerMgrS3Logos}/${res.image}`,
      decorationMethods: res.decoration_methods,
      artworkApproved: res.artwork_approved,
      selectedColors: customColors.length > 0
        ? [
          ...selectedColors,
          customColors,
        ].flat()
        : selectedColors,
      deletedAt: res.deleted_at,
    };

    return dispatch(updateLogo(model));
  }

  return dispatch(updateLogo(null));
};

export const fetchLogoColoredStyleColors = (lockerId, logoId) => async (dispatch) => {
  const call = slServicesApi.get(logoUrls.logoColoredStyleColors(lockerId, logoId), { handleBlockingLoading: false });
  const res = await makeApiCallWithErrorModal(call);

  if (res) {
    const colors = res.map((color) => ({ colorGroup: color.color_group }));

    return dispatch(updateLogoColoredStyleColors(colors));
  }

  return dispatch(updateLogoColoredStyleColors([]));
};

export const getLockerLogosDecorationMethods = (logoIds) => async (dispatch) => {
  const lockerDecorationMethods = {};

  for (const logoId of logoIds) {
    const call = slServicesApi.get(logoUrls.singleLogo(logoId), {
      handleBlockingLoading: false,
    });
    const res = await makeApiCallWithErrorModal(call);

    if (res) {
      lockerDecorationMethods[logoId] = res.decoration_methods;
    }
  }

  dispatch(updateLogosDecorationMethods(lockerDecorationMethods));
};
