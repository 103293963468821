import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const SyncStyleActionModal = React.memo(({
  isOpen,
  closeModal,
  syncData,
  label,
  id,
}) => (
  <Modal
    title={`Sync ${label}`}
    modalHeight={'l'}
    modalWidth={'m'}
    isOpen={isOpen}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, sync'}
        cancelBtnText={'Cancel'}
        onConfirm={syncData.bind(null, id)}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <div>
      <div>
        <div className='sku-setup__modal--text bold'>
          {`Are you sure you want to sync SL Services API data with Product Catalog for ${label}?`}
        </div>
      </div>
    </div>
  </Modal>
));

SyncStyleActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  syncData: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default SyncStyleActionModal;
