import { Dispatch } from 'redux';
import { SortDirectionLong } from '@customTypes/table';
import { defaultPageSizeBigTable } from '@constants/values';
import backorderManagementUrls from '@constants/sqdApiUrls/backorderManagementUrls';
import { makeApiCall } from '@util/apiHelper';
import * as actionTypes from './types';
import { fulfillmentApi } from '../../sqdApis';

export const updateOrdersTable = (data: any) => ({
  type: actionTypes.UPDATE_ORDERS_TABLE,
  data,
});

export const updateOrderItems = (data: any) => ({
  type: actionTypes.UPDATE_ORDER_ITEMS,
  data,
});

export const updateNotificationHistory = (data: any) => ({
  type: actionTypes.UPDATE_NOTIFICATION_HISTORY,
  data,
});

export const fetchOrdersTable = (
  pageNumber: number = 1,
  pageSize: number = defaultPageSizeBigTable,
  filter: string = '',
  sortBy: string = '',
  sortOrder: SortDirectionLong = '',
  previouslyNotified: boolean | undefined = undefined,
  discontinued: boolean | undefined = undefined
) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(backorderManagementUrls.backorderManagementTable(
    pageNumber,
    pageSize,
    filter,
    sortBy,
    sortOrder,
    previouslyNotified,
    discontinued
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(call);

  if (data) {
    dispatch(updateOrdersTable({
      totalPages: data.totalPages,
      hasPreviousPage: data.hasPreviousPage,
      hasNextPage: data.hasNextPage,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
      queue: data.items,
      totalCount: data.totalCount,
    }));
  }

  return data;
};

export const fetchOrderItems = (
  orderNumber: number,
  previouslyNotified: boolean | undefined = undefined,
  discontinued: boolean | undefined = undefined
) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(
    backorderManagementUrls.orderItems(orderNumber, previouslyNotified, discontinued),
    { handleBlockingLoading: false }
  );

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrderItems(res));
  }
};

export const fetchNotificationHistory = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(
    backorderManagementUrls.notificationHistory(orderNumber),
    { handleBlockingLoading: false }
  );

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateNotificationHistory(res));
  }
};
