import React, { PureComponent } from 'react';
import LazyLoad from 'react-lazy-load';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import LogoBankEditAssignment from '../../LogoBankEditAssignment';
import AssignLogosToItems from '../../AssignLogosToItems/AssignLogosToItems';
import AdminLogoActions from './AdminLogoActions';

class AdminLogo extends PureComponent {
  state = {
    editLogoAssignmentModalIsOpen: false,
    addLogoToBlanksModalIsOpen: false,
  };

  openEditLogoAssignmentModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ editLogoAssignmentModalIsOpen: true });
  };

  closeEditAssignmentModal = () => {
    this.setState({ editLogoAssignmentModalIsOpen: false });
  };

  openAddLogoToBlanksModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ addLogoToBlanksModalIsOpen: true });
  };

  closeAddLogoToBlanksModal = () => {
    this.setState({ addLogoToBlanksModalIsOpen: false });
  };

  openLogoImage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      logo,
      openModalWithImage,
    } = this.props;

    openModalWithImage(logo.image);
  };

  render() {
    const {
      logo,
      keyProp,
      lockerId,
      refreshLogos,
      scrollTop,
    } = this.props;

    const {
      editLogoAssignmentModalIsOpen,
      addLogoToBlanksModalIsOpen,
    } = this.state;

    const image = `${lockerMgrS3Logos}/${logo.image}`;

    return (
      <LazyLoad
        height={192}
        width={192}
        key={keyProp}
      >
        <>
          <LogoBankEditAssignment
            logoId={logo.id}
            modalIsOpen={editLogoAssignmentModalIsOpen}
            closeModal={this.closeEditAssignmentModal}
            logoImage={logo.image}
            lockerId={lockerId}
          />
          <AssignLogosToItems
            modalIsOpen={addLogoToBlanksModalIsOpen}
            closeModal={this.closeAddLogoToBlanksModal}
            lockerId={lockerId}
            preselectedLogos={[
              {
                id: logo.id,
                image: logo.image,
              },
            ]}
          />
          <div
            onClick={this.openLogoImage}
            type='button'
            className='logo-bank__manager--logo on-hover__container'
            style={{ backgroundImage: `url("${image}")` }}
          >
            {
              logo.decorationMethod && logo.decorationMethod.length > 0 &&
              <span>{logo.decorationMethod}</span>
            }
          </div>
          <AdminLogoActions
            logoId={logo.id}
            image={image}
            logoImage={logo.image}
            lockerId={lockerId}
            refreshLogos={refreshLogos}
            scrollTop={scrollTop}
            openEditLogoAssignmentModal={this.openEditLogoAssignmentModal}
            openAddLogoToBlanksModal={this.openAddLogoToBlanksModal}
          />
        </>
      </LazyLoad>
    );
  }
}

AdminLogo.propTypes = {
  logo: PropTypes.object.isRequired,
  keyProp: PropTypes.number.isRequired,
  lockerId: PropTypes.number.isRequired,
  openModalWithImage: PropTypes.func.isRequired,
  refreshLogos: PropTypes.func.isRequired,
  scrollTop: PropTypes.number,
};

export default AdminLogo;
