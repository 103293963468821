import React from 'react';
import { formatDollarAmount } from '@util/numberHelpers';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ExpandablePriceTab from '@sharedComponents/Display/ExpandablePriceTab';
import { OrderDetailsDto } from '@api/fulfillment/models';
import {
  OrderPricingDetails,
  OrderPricingDetailsDto,
} from '@api/orders/models';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  updateShippingInfo: () => void;
  shippingPriceCalculation: any;
  // Cast temporarily to any, remove once proper FF models are recieved via orval
  form: OrderPricingDetails | any;
  order: OrderDetailsDto;
  coreOrderPricingDetails: OrderPricingDetailsDto | undefined;
}

type Props = OwnProps;

const UpdateShippingConfirmationModal = React.memo<Props>(({
  isOpen,
  closeModal,
  shippingPriceCalculation,
  updateShippingInfo,
  form,
  order,
  coreOrderPricingDetails,
}) => {
  let {
    shippingPretaxPrice,
    shippingTaxAmount,
    updatedShippingPretaxAmount,
    updatedShippingTaxAmount,
    pretaxAmountToChange,
    taxAmountToChange,
    canUpdateShippingPrice,
  } = shippingPriceCalculation;

  /*
   * This basically checks if response came from Core or FF
   * if undefined, response came from Core
   */
  if (updatedShippingPretaxAmount === undefined) {
    shippingPretaxPrice = coreOrderPricingDetails
      ? coreOrderPricingDetails.shippingPretaxPrice
      : order.shippingPretaxPrice;
    shippingTaxAmount = coreOrderPricingDetails
      ? coreOrderPricingDetails.shippingTaxAmount
      : order.shippingTaxAmount;
    updatedShippingPretaxAmount = shippingPriceCalculation.shippingPretaxPrice;
    updatedShippingTaxAmount = shippingPriceCalculation.shippingTaxAmount;
    pretaxAmountToChange = Math.abs(shippingPretaxPrice - updatedShippingPretaxAmount);
    taxAmountToChange = Math.abs(shippingTaxAmount - updatedShippingTaxAmount);
    canUpdateShippingPrice = true;
  }

  let paymentType = '';
  if (form) {
    if ([
      accountingActionTypeEnum.ShippingRefund,
      accountingActionTypeEnum.ShippingFullRefund,
    ].includes(form.actionType)) {
      paymentType = accountingActionTypeEnum.Refund;
    } else if (form.actionType === accountingActionTypeEnum.ShippingCharge) {
      paymentType = accountingActionTypeEnum.Charge;
    }
  }

  const isConfirmBtnDisabled = (form && form.actionType !== accountingActionTypeEnum.NoChange) &&
    !canUpdateShippingPrice;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'l'}
      modalWidth={'m'}
      isPadded={true}
      title={'Edit Shipping Information - Price Confirmation'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Save'}
          cancelBtnText={'Cancel'}
          onConfirm={updateShippingInfo}
          onClose={closeModal}
          isDangerousAction={true}
          confirmBtnDisabled={isConfirmBtnDisabled}
        />
      )}
    >
      <div>
        <ExpandablePriceTab
          label={'Shipping Price'}
          subLabel={'(before tax)'}
          disableExpand={true}
          content={(
            <div>
              <div className='order-financial-details__content'>
                <div className='justify__space-between'>
                  <span>Base price</span>
                  <div>
                    <span className='current-amount'>{formatDollarAmount(updatedShippingPretaxAmount)}</span>
                    <span className='line-through'>({formatDollarAmount(shippingPretaxPrice)})</span>
                  </div>
                </div>
              </div>
              <div className='order-financial-details__subtotal'>
                <span>{paymentType} amount</span>
                <span>{formatDollarAmount(Math.abs(pretaxAmountToChange))}</span>
              </div>
            </div>
          )}
        />

        <ExpandablePriceTab
          label={'Tax'}
          disableExpand={true}
          classes={'mt-30'}
          content={(
            <div>
              <div className='order-financial-details__content'>
                <div className='justify__space-between'>
                  <span>Tax</span>
                  <div>
                    <span className='current-amount'>{formatDollarAmount(updatedShippingTaxAmount)}</span>
                    <span className='line-through'>({formatDollarAmount(shippingTaxAmount)})</span>
                  </div>
                </div>
              </div>
              <div className='order-financial-details__subtotal'>
                <span>{paymentType} tax amount</span>
                <span>{formatDollarAmount(Math.abs(taxAmountToChange))}</span>
              </div>
            </div>
          )}
        />

        <div className='mt-30'>
          <div className='order-financial-details__total'>
            <span>{paymentType} Total Amount</span>
            <span>{formatDollarAmount(Math.abs(pretaxAmountToChange) + Math.abs(taxAmountToChange))}</span>
          </div>
        </div>

        {
          isConfirmBtnDisabled &&
        <div className='redux-form__error'>Warning: Can't update shipping price.</div>
        }
      </div>
    </Modal>
  );
});

export default UpdateShippingConfirmationModal;
