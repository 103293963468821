import { buildQueryString } from '@util/stringHelpers';

export const inventory = 'homefield/api/inventory' as const;

export const onHandInventory = (
  pageNumber: number,
  pageSize: number,
  brandId: string | null = null,
  color: string | null = null,
  size: string | null = null,
  age: string | null = null,
  gender: string | null = null,
  categoryId: string | null = null,
  search: string | null = null
): string => `${inventory}/on-hand-inventory${buildQueryString({
  pageNumber,
  pageSize,
  brandId,
  color,
  size,
  age,
  gender,
  categoryId,
  search,
}, true)}`;

export default {
  onHandInventory,
};
