import React from 'react';
import PropTypes from 'prop-types';

const ColorChip = React.memo(({
  color,
  removeColor,
}) => (
  <div
    className='chip has-icon--right'
    onClick={removeColor.bind(null, color)}
  >
    <div className='chip--body'>
      <span
        className='color-swatch-item__color size--xsmall mr-5'
        style={{ backgroundColor: color.hexValue }}
      />
      <span className='chip--text'>{color.code}</span>
      <div className='cancel-icon' />
    </div>
  </div>
));

ColorChip.propTypes = {
  color: PropTypes.shape({
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
  }).isRequired,
  displayName: PropTypes.string.isRequired,
  valueName: PropTypes.string.isRequired,
  removeColor: PropTypes.func.isRequired,
};

export default ColorChip;
