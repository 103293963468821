import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import Table from '@sharedComponents/Table/Table';
import ItemImage from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemImage';

const ItemsTable = Table();

class ChooseColorsStylesTable extends PureComponent {
  renderColor = (color, isLockerColor) => {
    const { selectedColors } = this.props;

    let isDisabled = false;
    if (!isLockerColor && selectedColors && selectedColors.length > 0) {
      if (!selectedColors.includes(color.code)) {
        isDisabled = true;
      }
    }

    return (
      <div
        key={color.code}
        className='mt-20'
      >
        <div className='tooltip-container'>
          <ColorDisplay
            colorName={color.name}
            displayName={false}
            size={'size--small'}
            isDisabled={isDisabled}
            hexValue={color.hexValue}
          />
          <div className='tooltip-text'>
            {color.name}
          </div>
        </div>
      </div>
    );
  };

  getColumns = () => {
    const { colorsByStyle } = this.props;

    const columns = [
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        minWidth: 60,
        Cell: (cellProps) => (
          <ItemImage
            item={cellProps.original}
          />
        ),
      },
      {
        Header: 'Code & Description',
        accessor: '',
        minWidth: 120,
        Cell: (cellProps) => (
          <TitleDescriptionCell
            item={cellProps.value}
            showLabels={true}
          />
        ),
      },
      {
        Header: 'Colors in Locker',
        accessor: 'styleId',
        Cell: (cellProps) => colorsByStyle[cellProps.value]
          ? <div className='flex flex__wrap'>{colorsByStyle[cellProps.value].inLocker.map((c) => this.renderColor(c, true))}</div>
          : '',
      },
      {
        Header: 'Available Colors',
        accessor: 'styleId',
        Cell: (cellProps) => colorsByStyle[cellProps.value]
          ? <div className='flex flex__wrap'>{colorsByStyle[cellProps.value].available.map((c) => this.renderColor(c, false))}</div>
          : '',
      },
    ];

    return columns;
  };

  render() {
    const { queue } = this.props;

    return (
      <div className='sheet'>
        <ItemsTable
          data={queue}
          columns={this.getColumns()}
          showPagination={false}
        />
      </div>
    );
  }
}

ChooseColorsStylesTable.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorsByStyle: PropTypes.object.isRequired,
  selectedColors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ChooseColorsStylesTable;
