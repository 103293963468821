import React from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { formValueSelector } from 'redux-form';
import { addItemsToExistingCollectionForm } from '@constants/reduxForms';
import { AddItemsToExistingCollectionFormData } from '@models/forms/Collections/AddItemsToExistingCollectionFormData';
import { LockerProduct } from '@models/LockerManager/LockerProduct';
import { groupCollectionItems } from '@util/componentHelpers/collectionsHelper';
import { RootState } from '@redux/index/reducers';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import AddItemsToExistingCollectionForm from '../AddItemsToExistingCollectionForm';

interface OwnProps {
  isOpen: boolean;
  addItems: (form: AddItemsToExistingCollectionFormData) => void;
  closeModal: () => void;
  items: LockerProduct[];
}

const selector = formValueSelector(addItemsToExistingCollectionForm);

const mapStateToProps = (state: RootState) => ({
  lockerCollections: state.collections.collectionsQueue.queue,
  collectionId: selector(state, 'collectionId'),
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const AddItemsToExistingCollectionModal = React.memo<Props>(({
  isOpen,
  addItems,
  closeModal,
  items,
  collectionId,
}) => {
  const groupedCollectionItems = groupCollectionItems(items);

  return (
    <Modal
      title={'Add Items to Existing Collection'}
      modalSize={'l'}
      isOpen={isOpen}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          confirmBtnDisabled={items.length === 0 || !collectionId}
          formSubmission={true}
          formId={addItemsToExistingCollectionForm}
        />
      )}
    >
      <div className='lockerManagerEdit__add-to-existing-collection-modal'>
        <div className='lockerManagerEdit__importItemsModal__text align-left mb-10'>Select Collection:</div>
        <AddItemsToExistingCollectionForm
          onSubmit={addItems}
          initialValues={{
            itemGroups: groupedCollectionItems,
          }}
        />
      </div>
    </Modal>
  );
});

export default connector(AddItemsToExistingCollectionModal);
