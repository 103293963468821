import React from 'react';
import { Field } from 'redux-form';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';

interface OwnProps {
  disabled?: boolean;
  change: (name: string, value: any) => void;
  tooltipText?: string;
}

type Props = OwnProps;

const BundleCollectionCheckbox = React.memo<Props>(({
  change,
  disabled = false,
  tooltipText,
}) => (
  <div className='info__field'>
    <Field
      name={'bundled'}
      text={'Bundle Collection'}
      component={ReduxFormCheckbox}
      change={change}
      disabled={disabled}
      tooltipText={tooltipText}
    />
  </div>
));

export default BundleCollectionCheckbox;
