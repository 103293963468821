import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { skuSetupStatusEnum } from '@constants/enums/productCatalogEnums';
import {
  validateColoredStyleInQC,
  validateColoredStyleInSetup,
} from '@util/componentHelpers/skuSetupHelper';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import SkuSetupStatusChangeModal from './SkuSetupStatusChangeModal';

class SkuSetupColoredStyleActions extends PureComponent {
  state = {
    changeStatusModalIsOpen: false,
  };

  openChangeStatusModal = () => {
    this.setState({ changeStatusModalIsOpen: true });
  };

  closeChangeStatusModal = () => {
    this.setState({ changeStatusModalIsOpen: false });
  };

  render() {
    const {
      style,
      coloredStyle,
      openEditColoredStyleModal,
      changeColoredStyleStatus,
      nextStatus,
    } = this.props;

    const { changeStatusModalIsOpen } = this.state;

    const setupValidationErrors = validateColoredStyleInSetup(coloredStyle, style);
    const finalizeValidationErrors = validateColoredStyleInQC(coloredStyle, style);

    const isDoneActionDisabled = setupValidationErrors.length > 0;
    const isFinalizeActionDisabled = finalizeValidationErrors.length > 0;

    return (
      <div>
        <SkuSetupStatusChangeModal
          isOpen={changeStatusModalIsOpen}
          closeModal={this.closeChangeStatusModal}
          coloredStyles={[coloredStyle]}
          styleName={style.name}
          changeStatus={changeColoredStyleStatus}
          status={nextStatus}
        />

        <div className='flex'>
          <div
            onClick={openEditColoredStyleModal.bind(null, {
              ...coloredStyle,
              customizable: style.customizable,
              style,
            })}
            className='sku-setup__edit-action'
          >
            <div className='sku-setup__link'>
              <Icon materialIcon={'edit'} />
              <span>Edit</span>
            </div>
          </div>

          {
            coloredStyle.status === skuSetupStatusEnum.Initial
              ? (
                <div className='flex'>
                  {
                    isDoneActionDisabled &&
                    <MaterialTooltip
                      tooltipText={
                        setupValidationErrors.map((e, index) => (
                          <div
                            className='pre-line'
                            key={index}
                          >
                            {e}
                          </div>
                        ))
                      }
                      placement={'top'}
                    >
                      <Icon
                        materialIcon={'warning'}
                        classes={'redux-form__icon orange button ml-10 mr-5 mt-10'}
                      />
                    </MaterialTooltip>
                  }
                  <Button
                    type={'primary'}
                    text={'Done'}
                    onClick={this.openChangeStatusModal}
                    disabled={isDoneActionDisabled}
                    classes={!isDoneActionDisabled ? 'ml-20' : ''}
                    size={'s'}
                  />
                </div>
              )
              : (
                <div className='flex'>
                  {
                    isFinalizeActionDisabled &&
                    <MaterialTooltip
                      tooltipText={
                        finalizeValidationErrors.map((e, index) => (
                          <div
                            className='pre-line'
                            key={index}
                          >
                            {e}
                          </div>
                        ))
                      }
                      placement={'top'}
                    >
                      <Icon
                        materialIcon={'warning'}
                        classes={'redux-form__icon orange button ml-10 mr-5 mt-10'}
                      />
                    </MaterialTooltip>
                  }
                  <Button
                    type={'primary'}
                    text={'Finalize'}
                    onClick={this.openChangeStatusModal}
                    disabled={isFinalizeActionDisabled}
                    size={`s ${!isFinalizeActionDisabled ? 'ml-20' : ''}`}
                  />
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

SkuSetupColoredStyleActions.propTypes = {
  coloredStyle: PropTypes.object,
  style: PropTypes.object.isRequired,
  changeColoredStyleStatus: PropTypes.func.isRequired,
  openEditColoredStyleModal: PropTypes.func.isRequired,
  nextStatus: PropTypes.string.isRequired,
};

export default SkuSetupColoredStyleActions;
