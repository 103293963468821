import React from 'react';
import PropTypes from 'prop-types';
import LogoBankHistoryChangeLogItem from './LogoBankHistoryChangeLogItem';

const LogoBankHistoryChangeLog = React.memo(({ log }) => {
  let tableData = '';

  if (log) {
    tableData = log.map((logItem, key) => (
      <LogoBankHistoryChangeLogItem
        key={key}
        logItem={logItem}
      />
    ));
  }

  return (
    <ul className='history custom-scrollbar'>
      {tableData}
    </ul>
  );
});

LogoBankHistoryChangeLog.propTypes = {
  log: PropTypes.array.isRequired,
};

export default LogoBankHistoryChangeLog;
