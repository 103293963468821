import React from 'react';
import PropTypes from 'prop-types';
import { fundraisingPayoutStatusEnum } from '@constants/enums/fundraisingPayoutsEnums';
import { parseDateNumeric } from '@util/dateHandler';

const FundraisingPayoutsCompletedCell = React.memo(({
  status,
  completed,
}) => {
  if (status === fundraisingPayoutStatusEnum.Completed) {
    return completed ? parseDateNumeric(completed) : '-';
  }
  if (status === fundraisingPayoutStatusEnum.Canceled) {
    return (
      <div className='fundraising-payouts__canceled'>
        Canceled
      </div>
    );
  }

  return '-';
});

FundraisingPayoutsCompletedCell.propTypes = {
  status: PropTypes.string.isRequired,
  completed: PropTypes.string,
};

export default FundraisingPayoutsCompletedCell;
