import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ColoredStyleCard from '@sharedComponents/CustomItems/ColoredStylesTable/ColoredStyleCard';

class ConfirmColorsTab extends PureComponent {
  render() {
    const {
      coloredStyles,
      removeColoredStyle,
      styles,
    } = this.props;

    return (
      <>
        <div>
          You are about to add following {coloredStyles.length} {coloredStyles.length > 0 ? 'items' : 'item'}:
        </div>
        <div className='flex__wrap custom-scrollbar'>
          {coloredStyles.map((cs) => (
            <ColoredStyleCard
              key={cs.id}
              coloredStyle={{
                ...cs,
                style: styles.find((s) => s.styleId === cs.styleId),
              }}
              className='mr-15 mb-10'
              showCancelAction={true}
              onCancel={removeColoredStyle}
            />
          ))
          }
        </div>
      </>
    );
  }
}

ConfirmColorsTab.propTypes = {
  coloredStyles: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeColoredStyle: PropTypes.func.isRequired,
  styles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ConfirmColorsTab;
