import React, { useEffect } from 'react';
import { CreatePtlWallCommand } from '@api/fulfillment/models';
import { addNewPtlWallForm } from '@constants/reduxForms';
import HookFormDropdown from '@sharedComponents/Form/HookFormDropdown';
import {
  getNumberOfRowsOptions,
  getNumberOfRowPositionsOptions,
  getBinSizeOptions,
} from '@constants/options/options';
import {
  getRowPositionColorLabel,
  mapRowPositionColor,
} from '@util/mappingHelper';
import FormInput from '@sharedComponents/Form/FormInput';
import { InitialValues } from './EditPtlWallModal';

const numberOfRowsOptions = getNumberOfRowsOptions();
const numberOfRowPositionsOptions = getNumberOfRowPositionsOptions();
const ptlWallBinSizeOptions = getBinSizeOptions();

interface OwnProps {
  onSubmit: (wallParam: CreatePtlWallCommand) => Promise<void>;
  initialValues: InitialValues;
  methods?: any;
  fieldArray?: any;
  rowPositionColors?: readonly string[];
  deviceInputs?: number[];
  rowPositions?: number[];
}

type Props = OwnProps;

const PtlWallForm = React.memo<Props>(({
  onSubmit,
  initialValues,
  methods,
  rowPositionColors,
  rowPositions,
  deviceInputs,
  fieldArray,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = methods;

  const {
    fields,
    append,
    remove,
  } = fieldArray;

  useEffect(() => {
    if (!deviceInputs?.length) return;
    if (deviceInputs?.length > fields.length) {
      append({ text: '' });
    } else if (deviceInputs?.length < fields.length) {
      remove(deviceInputs.length);
    }
  }, [
    fields,
    append,
    deviceInputs?.length,
    remove,
  ]);

  const renderRowPositions = (rp: number, index: number) => (
    <div
      key={index}
      className='ml-20'
    >
      <h3>Row Position {index + 1}</h3>
      <div className='color-swatch-item flex'>
        <div
          className='color-swatch-item__color'
          style={{ backgroundColor: mapRowPositionColor(rowPositionColors?.[index] || '') }}
        />
        <span className='color-swatch-item__number'>
          {getRowPositionColorLabel(rowPositionColors?.[index] || '')}
        </span>
      </div>
    </div>
  );

  const renderDeviceInputs = (field: any, index: number) => (
    <div
      key={field.id}
      className='ml-20'
    >
      <label className='redux-form__label'>
        Row {index + 1} PTL Device
        <span className='required'>*</span>
      </label>
      <div data-test='collection-status-select'>
        <FormInput
          id={`devices[${index}]`}
          placeholder={'Device Barcode'}
          type={'text'}
          initialValue={initialValues?.devices?.[index]}
          error={errors.devices?.[index]?.text}
          register={register}
          {...register(`devices[${index}].text` as const)}
        />
      </div>
    </div>
  );

  return (
    <form
      className='redux-form'
      id={addNewPtlWallForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex mb-20'>
        <div className='redux-form__column--size-m ml-20'>
          <label className='redux-form__label'>
            Wall Name
            <span className='required'>*</span>
          </label>
          <div data-test='collection-status-select'>
            <FormInput
              id='name'
              placeholder='Wall Name'
              type='text'
              initialValue={initialValues?.name}
              error={errors.name}
              register={register}
              {...register('name')}
            />
          </div>
        </div>
        <div className='redux-form__column--size-m ml-20'>
          <label className='redux-form__label'>
            Number of Rows
            <span className='required'>*</span>
          </label>
          <div data-test='collection-status-select'>
            <HookFormDropdown<any, string>
              name={'numberOfRows'}
              register={register}
              options={numberOfRowsOptions}
              change={setValue}
              initialValue={initialValues?.numberOfRows.toString()}
              error={errors.numberOfRows}
            />
          </div>
        </div>
        <div className='redux-form__column--size-m ml-20'>
          <label className='redux-form__label'>
            Number Positions Per Row
            <span className='required'>*</span>
          </label>
          <div data-test='collection-status-select'>
            <HookFormDropdown<any, string>
              name={'numberOfRowPositions'}
              register={register}
              change={setValue}
              initialValue={initialValues?.numberOfRowPositions.toString()}
              options={numberOfRowPositionsOptions}
              error={errors.numberOfRowPostions}
            />
          </div>
        </div>
        <div className='redux-form__column--size-m ml-20'>
          <label className='redux-form__label'>
            Bin Size
            <span className='required'>*</span>
          </label>
          <div data-test='collection-status-select'>
            <HookFormDropdown<any, string>
              name={'binSize'}
              register={register}
              change={setValue}
              initialValue={initialValues?.binSize}
              options={ptlWallBinSizeOptions}
              error={errors.binSize}
            />
          </div>
        </div>
      </div>
      <div className='flex mb-20'>
        {rowPositions?.map(renderRowPositions)}
      </div>
      <div className='flex__wrap'>
        {fields?.map(renderDeviceInputs)}
      </div>
    </form>

  );
});

export default PtlWallForm;
