export const normalizeCamelCase = (string: string): string => {
  if (!string) return '';

  const str = string.replace(
    /([A-Z])/g,
    (match: string) => ` ${match.toUpperCase()}`
  );

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const hyphenateSpacedString = (string: string): string => {
  if (!string) return '';

  return string.replace(/\s/g, '-');
};

export const toSnakeCase = (string: string): string => {
  if (!string) return '';
  const str = string.replace(/([A-Z])/g, (match: string) => match.toLowerCase());

  return str.replace(/\s/g, '_');
};

export const fromSnakeCase = (string: string): string => {
  if (!string) return '';
  const arr = [];
  for (const word of string.replace(/_/g, () => ' ').split(' ')) {
    arr.push(word.charAt(0).toUpperCase() + word.slice(1));
  }

  return arr.join(' ');
};

export const cleanupUrl = (url: string): string => {
  const anchorIndex = url.indexOf('#');
  const urlWithoutAnchor =
    anchorIndex < 0 ? url : url.substring(0, anchorIndex);
  const queryIndex = urlWithoutAnchor.indexOf('?');
  const path =
    queryIndex < 0
      ? urlWithoutAnchor
      : urlWithoutAnchor.substring(0, queryIndex);

  return path;
};

export const fileNameFromUrl = (url: string): string => {
  const path = cleanupUrl(url);
  const lastSlashIndex = path.lastIndexOf('/');
  const startIndex = lastSlashIndex + 1;
  const file = path.substring(startIndex);
  const lastDotIndex = file.lastIndexOf('.');
  const fileName = lastDotIndex < 0 ? file : file.substring(0, lastDotIndex);

  return fileName;
};

export const fileExtensionFromUrl = (url: string): string => {
  const lastDotIndex = url.lastIndexOf('.');
  const extension = lastDotIndex < 0 ? '' : url.substring(lastDotIndex);

  return extension;
};

export const fileColorFromFilename = (url: string): string => {
  const fileName = fileNameFromUrlWithExtension(url);
  const lastMinusIndex = fileName.lastIndexOf('-');
  const fileColor =
    lastMinusIndex < 0 || lastMinusIndex + 1 === fileName.length
      ? fileName
      : fileName.substring(lastMinusIndex + 1);

  return fileColor;
};

export const fileNameFromUrlWithExtension = (url: string): string => {
  const path = cleanupUrl(url);
  const lastSlashIndex = path.lastIndexOf('/');
  const startIndex = lastSlashIndex + 1;
  const file = path.substring(startIndex);

  return file;
};

export const fileExtensionFromName = (url: string): string => {
  const path = cleanupUrl(url);
  const lastDotIndex = path.lastIndexOf('.');
  const fileName = lastDotIndex < 0 ? '' : path.substring(lastDotIndex);

  return fileName;
};

export const shippingAddressFromShippingInfo = (shippingInfo: {
  addressLine1?: string | null | undefined;
  addressLine2?: string | null | undefined;
  city?: string | null | undefined;
  state?: string | null | undefined;
  zip?: string | null | undefined;
  country?: string | null | undefined;
}): string => {
  if (!shippingInfo) {
    return '';
  }

  const filtered = [
    shippingInfo.addressLine1,
    shippingInfo.addressLine2,
    shippingInfo.city,
    `${shippingInfo.state} ${shippingInfo.zip}`.trim(),
    shippingInfo.country,
  ].filter((el) => !!el);

  return filtered.join(', ');
};

export const buildQueryString = (
  queryParamsObject: { [key: string]: any; },
  shouldArrayParamsBeSeparated?: boolean
): string => {
  let queryString = '';
  for (const [
    name,
    value,
  ] of Object.entries(queryParamsObject)) {
    if (
      value === null
      || value === undefined
      || value === ''
      || value.length === 0
    ) {
      continue;
    }

    let queryParam = `${name}=${value}`;

    if (Array.isArray(value) && value.length > 0) {
      if (shouldArrayParamsBeSeparated) {
        queryParam = value.map((v) => `${name}=${v}`).join('&');
      } else {
        queryParam = `${name}=${value.join('%2c')}`;
      }
    }

    const suffix = queryString === '' ? '' : '&';
    queryString += `${suffix}${queryParam}`;
  }

  return queryString === '' ? '' : `?${queryString}`;
};

export const extractParameterFromPath = (
  props: { match: { params: { [key: string]: string; }; }; },
  parameterName: string,
  castTo: string | undefined | null = null
): string | number | boolean | null => {
  const { match: { params } } = props;
  const param = params[parameterName];

  if (!param) {
    return null;
  }

  if (castTo === 'number') {
    return Number(param);
  }

  if (castTo === 'bool') {
    return !!param;
  }

  return param;
};

export const pascalCaseObjectToCamelCaseObject = (pcObj: {
  [key: string]: any;
}): { [key: string]: any; } => {
  const ccObj: { [key: string]: any; } = {};
  for (const [
    pcKey,
    value,
  ] of Object.entries(pcObj)) {
    const ccKey = pcKey.length
      ? `${pcKey[0].toLowerCase()}${pcKey.substring(1)}`
      : pcKey;
    ccObj[ccKey] = value;
  }

  return ccObj;
};
