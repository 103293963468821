/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  DecorationLocationArtworkSizeDto,
  DecorationLocationArtworkSizeDtoApiResponse,
  ApiResponse,
  CreateDecorationLocationArtworkSizeCommand,
  UpdateDecorationLocationArtworkSizeCommand
} from './models'
import { catalogApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiDecorationlocationartworksizes = <TData = DecorationLocationArtworkSizeDto[]>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-location-artwork-sizes`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDecorationlocationartworksizesQueryKey = () => [`/homefield/api/decoration-location-artwork-sizes`]

    
export const useGetHomefieldApiDecorationlocationartworksizes = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDecorationlocationartworksizes, DecorationLocationArtworkSizeDto[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDecorationlocationartworksizesQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDecorationlocationartworksizes<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiDecorationlocationartworksizes = <TData = DecorationLocationArtworkSizeDtoApiResponse>(
    createDecorationLocationArtworkSizeCommand: CreateDecorationLocationArtworkSizeCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-location-artwork-sizes`, method: 'post',
      data: createDecorationLocationArtworkSizeCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiDecorationlocationartworksizes = <TData = AsyncReturnType<typeof postHomefieldApiDecorationlocationartworksizes,DecorationLocationArtworkSizeDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateDecorationLocationArtworkSizeCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateDecorationLocationArtworkSizeCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiDecorationlocationartworksizes<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDecorationlocationartworksizesId = <TData = DecorationLocationArtworkSizeDto>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-location-artwork-sizes/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDecorationlocationartworksizesIdQueryKey = (id: number,) => [`/homefield/api/decoration-location-artwork-sizes/${id}`]

    
export const useGetHomefieldApiDecorationlocationartworksizesId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDecorationlocationartworksizesId, DecorationLocationArtworkSizeDto>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDecorationlocationartworksizesIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDecorationlocationartworksizesId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiDecorationlocationartworksizesId = <TData = ApiResponse>(
    id: number,
    updateDecorationLocationArtworkSizeCommand: UpdateDecorationLocationArtworkSizeCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-location-artwork-sizes/${id}`, method: 'put',
      data: updateDecorationLocationArtworkSizeCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiDecorationlocationartworksizesId = <TData = AsyncReturnType<typeof putHomefieldApiDecorationlocationartworksizesId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: UpdateDecorationLocationArtworkSizeCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: UpdateDecorationLocationArtworkSizeCommand}, TContext>((props) => {
        const {id,data} = props || {};

        return  putHomefieldApiDecorationlocationartworksizesId<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiDecorationlocationartworksizesId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-location-artwork-sizes/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiDecorationlocationartworksizesId = <TData = AsyncReturnType<typeof deleteHomefieldApiDecorationlocationartworksizesId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiDecorationlocationartworksizesId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    