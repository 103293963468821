import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class AddLogosModal extends PureComponent {
  render() {
    const {
      isOpen,
      addLogos,
      closeModal,
      item,
      logosFromLogoBank,
      selectedLogos,
    } = this.props;

    return (
      <Modal
        title={`Add Logo(s) for Item: ${item.code}`}
        modalSize={'xl'}
        isOpen={isOpen}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Add Logo(s)'}
            cancelBtnText={'Cancel'}
            onConfirm={addLogos}
            onClose={closeModal}
            confirmBtnDisabled={selectedLogos.length === 0}
          />
        )}
      >
        <div>
          <div className='logo-bank__container custom-scrollbar'>
            {logosFromLogoBank.length > 0 ? logosFromLogoBank : 'Your logo bank is empty.'}
          </div>
        </div>
      </Modal>
    );
  }
}

AddLogosModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  addLogos: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  logosFromLogoBank: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AddLogosModal;
