import React from 'react';
import { itemStatusEnum } from '@constants/enums/orderEnums';
import { parseDateNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  item: {
    canceled: boolean;
    inventoryAvailable: boolean;
    promisedDate: string;
    status: { label: string; };
  };
}

type Props = OwnProps;

const OrderManagementDetailsItemArrivalStatus = React.memo(({ item }: Props) => {
  if (item.canceled !== null || item.status.label === itemStatusEnum.Shipped) {
    return null;
  }

  if (item.inventoryAvailable) {
    return (
      <span className='order__item-status__value--icon available'>
        <Icon materialIcon={'check'} />
      </span>
    );
  }

  let inventoryStatus = (
    <MaterialTooltip
      tooltipText={<div>Promised date unknown</div>}
      placement={'top'}
    >
      <div className='order__item-status__value--icon unknown'>
        <Icon materialIcon={'schedule'} />
      </div>
    </MaterialTooltip>
  );

  if (item.inventoryAvailable === false) {
    inventoryStatus = (
      <MaterialTooltip
        tooltipText={(
          <div>
            {
              item.promisedDate
                ? `Arriving on ${parseDateNumeric(item.promisedDate)}`
                : 'Promised date unknown'
            }
          </div>
        )}
        placement={'top'}
      >
        <div className='order__item-status__value--icon not-available'>
          <Icon materialIcon={'schedule'} />
          <div className='ml-5'>{item.promisedDate && parseDateNumeric(item.promisedDate)}</div>
        </div>
      </MaterialTooltip>
    );
  }

  return inventoryStatus;
});

export default OrderManagementDetailsItemArrivalStatus;
