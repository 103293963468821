import React,
{ useCallback } from 'react';
import {
  VoucherOrderDetailDto,
  VoucherOrderPaymentStatusEnum,
} from '@api/financialServices/models';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

interface OwnProps {
  order: VoucherOrderDetailDto;
  onEditVoucherOrder: (order: VoucherOrderDetailDto) => void;
  onResendPaymentNotification: (order: VoucherOrderDetailDto) => void;
  onCancelVoucherOrder: (order: VoucherOrderDetailDto) => void;
}

type Props = OwnProps;

const VoucherOrderActions = React.memo<Props>(({
  order,
  onEditVoucherOrder,
  onResendPaymentNotification,
  onCancelVoucherOrder,
}) => {
  const handleEditVoucherOrder = useCallback(() => {
    onEditVoucherOrder(order);
  }, [
    onEditVoucherOrder,
    order,
  ]);

  const handleResendPaymentNotification = useCallback(() => {
    onResendPaymentNotification(order);
  }, [
    onResendPaymentNotification,
    order,
  ]);

  const handleCancelVoucherOrder = useCallback(() => {
    onCancelVoucherOrder(order);
  }, [
    onCancelVoucherOrder,
    order,
  ]);

  return (
    <div>
      <ShowMoreActionsButton
        actions={[
          {
            text: 'Edit',
            action: handleEditVoucherOrder,
            isVisible: order.paymentStatus !== VoucherOrderPaymentStatusEnum.Paid,
          },
          {
            text: 'Resend Payment Notification',
            action: handleResendPaymentNotification,
          },
          {
            text: 'Cancel Order',
            action: handleCancelVoucherOrder,
            isDangerous: true,
            isVisible: !order.cancellationId,
          },
        ]}
        classes={'voucherOrders__actions'}
        size={'s'}
      />
    </div>
  );
});

export default VoucherOrderActions;
