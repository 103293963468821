import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import Icon from '@sharedComponents/Icons/Icon';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import Button from '@sharedComponents/Buttons/Button';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';

const getImages = (selectedItem: ProductionAssemblyItemGroupDto) => {
  const images = [
    selectedItem?.imageUrlFront,
    selectedItem?.imageUrlBack,
    selectedItem?.imageUrlLeft,
    selectedItem?.imageUrlRight,
  ].filter(Boolean) as string[];

  return images;
};

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  selectedItem: Nullable<ProductionAssemblyItemGroupDto>;
}

type Props = OwnProps;

const ImageDetailsModal = React.memo<Props>(({
  isOpen,
  closeModal,
  selectedItem,
}) => {
  const [
    currentImage,
    setCurrentImage,
  ] = useState<string>('');

  const images = useMemo(() => selectedItem ? getImages(selectedItem) : [], [selectedItem]);

  useEffect(() => {
    setCurrentImage(images[0]);
  }, [images]);

  const slideToNext = useCallback(() => {
    const i = images?.findIndex((img) => img === currentImage);
    setCurrentImage(images?.[i + 1]);
  }, [
    currentImage,
    images,
  ]);

  const slideToPrevious = useCallback(() => {
    const i = images?.findIndex((img) => img === currentImage);
    setCurrentImage(images?.[i - 1]);
  }, [
    currentImage,
    images,
  ]);

  const hasPrevious = useCallback((image: string) => {
    const imagesOfSelectedItem = selectedItem ? getImages(selectedItem) : [];

    const index = imagesOfSelectedItem.findIndex((img) => img === image);

    return index > 0;
  }, [selectedItem]);

  const hasNext = useCallback((image: string) => {
    const imagesOfSelectedItem = selectedItem ? getImages(selectedItem) : [];

    const index = imagesOfSelectedItem.findIndex((img) => img === image);

    return index < (imagesOfSelectedItem.length - 1);
  }, [selectedItem]);

  if (images?.length === 1) {
    return (
      <ImagePreviewModal
        modalIsOpen={isOpen}
        closeModal={closeModal}
        imageUrl={images?.[0]}
      />
    );
  }

  return (
    <Modal
      modalSize={'xl'}
      isOpen={isOpen}
      closeModal={closeModal}
      buttons={(
        <div className='justify__end'>
          <div className='image-slider'>
            {
              <Button
                type={hasPrevious(currentImage) ? 'primary' : 'secondary'}
                text=''
                onClick={hasPrevious(currentImage) ? slideToPrevious : undefined}
                icon={(
                  <Icon
                    materialIcon={'chevron_left'}
                  />
                )}
              />
            }
            {
              <Button
                type={hasNext(currentImage) ? 'primary' : 'secondary'}
                text=''
                size='m'
                onClick={hasNext(currentImage) ? slideToNext : undefined}
                classes='margin-left'
                icon={(
                  <Icon
                    materialIcon={'chevron_right'}
                  />
                )}
              />
            }
          </div>
          <ModalButtons
            cancelBtnText={'Close'}
            onClose={closeModal}
            displayConfirmBtn={false}
          />
        </div>
      )}
    >
      <div className='align__center--horizontal preview'>
        <img
          className='modal_full-image'
          src={currentImage}
        />
      </div>
    </Modal>
  );
});

export default ImageDetailsModal;
