/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type DecorationFlagTypeEnum = 'Undefined' | 'Reprint' | 'Rework' | 'WrongArtwork';


export const DecorationFlagTypeEnum = {
  Undefined: 'Undefined' as DecorationFlagTypeEnum,
  Reprint: 'Reprint' as DecorationFlagTypeEnum,
  Rework: 'Rework' as DecorationFlagTypeEnum,
  WrongArtwork: 'WrongArtwork' as DecorationFlagTypeEnum,
};
