import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useGetLockersForOrganization } from '@api/squadlockerServices/organizations';
import { QueriesVariationDto } from '@api/orders/models';
import { infoTextTimeout } from '@constants/values';
import {
  OrderManagementTempItem,
  OrderManagementTempOrder,
  OrderManagementTempItemPersonalization,
} from '@redux/orderManagement/models';
import { groupBy } from '@util/arrayHelper';
import { copyToClipboard } from '@util/componentHelper';
import OrderItem from './OrderItem';
import LockerHeader from './OrderItemsContent/LockerHeader';

interface OwnProps {
  isFilteringItems: boolean;
  items: OrderManagementTempItem[];
  order: Nullable<OrderManagementTempOrder>;
  collapsedItemIds: symbol[];
  toggleCollapseItem: (id: symbol) => void;
  openItemInfoModal: (item: OrderManagementTempItem) => void;
  openPreviewModal: (imageUrl: string) => void;
  openChangeQuantityModal: (item: OrderManagementTempItem) => void;
  openDuplicateItemModal: (item: OrderManagementTempItem) => void;
  openRemoveItemModal: (item: OrderManagementTempItem) => void;
  openAddPersonalizationModal: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  openEditPersonalizationModal: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  removePersonalization: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  colorsDictionary: any;
  updateItemVariation: (itemId: symbol, variation: QueriesVariationDto) => void;
}

type Props = OwnProps;

const OrderItems = React.memo(({
  isFilteringItems,
  items,
  order,
  collapsedItemIds,
  toggleCollapseItem,
  openItemInfoModal,
  openPreviewModal,
  openChangeQuantityModal,
  openDuplicateItemModal,
  openRemoveItemModal,
  openAddPersonalizationModal,
  openEditPersonalizationModal,
  colorsDictionary,
  removePersonalization,
  updateItemVariation,
}: Props) => {
  const [
    copyText,
    setCopyText,
  ] = useState<{ [key: string]: string; }>({});

  const { data: organizationLockers = [] } = useGetLockersForOrganization(
    order!.organizationId!,
    undefined,
    { query: { enabled: !!order?.organizationId } }
  );

  useEffect(() => {
    if (Object.keys(copyText).length === 0) return;

    // Remove copy text after the amount of time has passed
    setTimeout(() => {
      setCopyText({});
    }, infoTextTimeout);
  }, [copyText]);

  const onCopyToClipboard = useCallback((lockerId: number, e: React.MouseEvent) => {
    copyToClipboard(e, `dataToCopy-${lockerId}`);

    setCopyText({ [lockerId]: 'Copied!' });
  }, []);

  const renderItem = (item: OrderManagementTempItem, index: number) => (
    <OrderItem
      key={index}
      item={item}
      toggleCollapseItem={toggleCollapseItem}
      isCollapsed={collapsedItemIds.includes(item.id)}
      openItemInfoModal={openItemInfoModal}
      openPreviewModal={openPreviewModal}
      openChangeQuantityModal={openChangeQuantityModal}
      openDuplicateItemModal={openDuplicateItemModal}
      openRemoveItemModal={openRemoveItemModal}
      openAddPersonalizationModal={openAddPersonalizationModal}
      openEditPersonalizationModal={openEditPersonalizationModal}
      colorsDictionary={colorsDictionary}
      removePersonalization={removePersonalization}
      updateItemVariation={updateItemVariation}
    />
  );

  let content = [];

  if (order?.organizationId) {
    const groupedItemsDict: Record<string, any> = groupBy(items, 'lockerId');

    for (const lockerId of Object.keys(groupedItemsDict)) {
      const lockerItems = groupedItemsDict[lockerId];
      const organizationLocker = organizationLockers?.find((locker) => locker.id === +lockerId);
      const lockerTeamName: string = organizationLocker!.teamName;

      content.push(
        <LockerHeader
          key={lockerId}
          lockerId={+lockerId}
          teamName={lockerTeamName}
          onCopyToClipboard={onCopyToClipboard}
          copyText={copyText[lockerId]}
        />,
        lockerItems.map(renderItem)
      );
    }
  } else {
    content = items.map(renderItem);
  }

  return (
    <div className={`order__items new-order__order-items__list ${isFilteringItems ? 'order__items-search-mode' : ''} custom-scrollbar w-100`}>
      {content}
    </div>
  );
});

export default OrderItems;
