import React from 'react';
import { Option } from '@models/common/Option';
import DropdownList from './DropdownList';

interface OwnProps<T extends string | number | boolean | null = string | number | boolean> {
  readonly options: readonly Option<T>[];
  defaultValue?: T;
  onChange: (value: T) => void;
  classes?: string;
  listItemClasses?: string;
  optionsClasses?: string;
  dataTest?: string;
  size?: string;
  fixed?: boolean;
  group?: boolean;
  disabled?: boolean;
}

type Props<T extends string | number | boolean | null = string | number | boolean> = OwnProps<T>;

const Dropdown = <T extends string | number | boolean | null = string | number | boolean>({
  options,
  defaultValue,
  onChange,
  classes = '',
  listItemClasses = '',
  optionsClasses = '',
  disabled = false,
  dataTest = 'dropdown-select',
  size = '',
  fixed = false,
  group = false,
}: Props<T>) => (
    <div className={`select-field select-field__container ${classes} ${disabled ? 'select-field__container--disabled' : ''}`}>
      <DropdownList<T>
        defaultValue={defaultValue}
        onChange={onChange}
        classes={`has-icon ${listItemClasses}`}
        dataTest={dataTest}
        options={options}
        size={size}
        optionsClasses={optionsClasses}
        fixed={fixed}
        group={group}
      />
    </div>
  );

export default React.memo(Dropdown) as typeof Dropdown;
