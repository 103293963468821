import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submitOrder } from '@APICalls/orderManagement/actions';
import {
  fetchAccountingSubmitResults,
  fetchOrder as reduxFetchOrder,
} from '@redux/orderManagement/actions';
import { materialSwal } from '@util/componentHelper';
import { parseDateTimeNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import SubmitTable from '@sharedComponents/SubmitResults/SubmitTable';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import { ServiceTypeEnum } from '@api/fulfillment/models';

class OrderManagementDetailsOrderSubmitResultsModal extends Component {
  state = {
    confirmationModalIsOpen: false,
  };

  componentDidMount() {
    const {
      order,
      dispatch,
    } = this.props;

    dispatch(fetchAccountingSubmitResults(order.orderNumber));
  }

  openConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: true }));
  };

  closeConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: false }));
  };

  submitOrder = async () => {
    const {
      order,
      dispatch,
      serviceType,
      fetchOrder,
    } = this.props;

    const res = await submitOrder(order.orderNumber, serviceType);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }

    fetchOrder();

    dispatch(reduxFetchOrder(order.orderNumber));
    dispatch(fetchAccountingSubmitResults(order.orderNumber));

    this.closeConfirmationModal();
  };

  getColumns = () => [
    {
      Header: 'Date Created',
      accessor: 'dateCreatedUtc',
      minWidth: 140,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.original.dateCreatedUtc)}</span>,
    },
    {
      Header: 'Elapsed Time',
      accessor: 'elapsedTime',
      minWidth: 90,
      Cell: (cellProps) => <span>{cellProps.original.elapsedTime || 0} ms</span>,
    },
    {
      Header: 'Message',
      accessor: 'message',
      minWidth: 150,
    },
    {
      Header: 'Status Code',
      accessor: 'statusCode',
    },
    {
      Header: 'User',
      accessor: 'userId',
      Cell: (cellProps) => <span>{cellProps.original.userId || '-'}</span>,
    },
    {
      expander: true,
      Header: 'Details',
      accessor: '',
      width: 200,
      Expander: ({ isExpanded }) => (
        <div>
          {
            isExpanded
              ? (
                <div className='flex button align__center'>
                  <div className='info__label'>Collapse Details</div>
                  <Icon materialIcon={'arrow_drop_up'} />
                </div>
              )
              : (
                <div className='flex button align__center'>
                  <div className='info__label'>Expand Details</div>
                  <Icon materialIcon={'arrow_drop_down'} />
                </div>
              )
          }
        </div>
      ),
    },
  ];

  render() {
    const {
      order,
      isOpen,
      closeModal,
      accountingSubmitResults,
      serviceType,
    } = this.props;

    const { confirmationModalIsOpen } = this.state;

    const itemDoneStatusDisplayProperty =
      serviceType === ServiceTypeEnum.GreatPlains
        ? 'gpDocumentId'
        : 'salesOrderNumber';

    const itemSubmitStatusProperty =
      serviceType === ServiceTypeEnum.GreatPlains
        ? 'accountingSubmitStatus'
        : 'netSuiteAccountingSubmitStatus';

    return (
      <>
        <Modal
          isOpen={isOpen}
          closeModal={closeModal}
          title={`O${order.orderNumber} Accounting Submit Results`}
          modalSize={'xl'}
          buttons={(
            <ModalButtons
              onClose={closeModal}
              cancelBtnText={'Close'}
              displayConfirmBtn={false}
            />
          )}
        >
          <SubmitTable
            label={'Order'}
            item={order}
            submitAction={this.openConfirmationModal}
            submitResults={accountingSubmitResults.filter((r) => r.serviceType === serviceType)}
            itemDataProperty={'orderData'}
            itemDoneStatusDisplayProperty={itemDoneStatusDisplayProperty}
            itemSubmitStatusProperty={itemSubmitStatusProperty}
            columns={this.getColumns()}
          />
        </Modal>

        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.submitOrder}
          closeModal={this.closeConfirmationModal}
          title={'Submit Order'}
          confirmationBody={`Are you sure you want to submit O${order.orderNumber} to accounting?`}
        />
      </>
    );
  }
}

OrderManagementDetailsOrderSubmitResultsModal.propTypes = {
  order: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  serviceType: PropTypes.int,
  accountingSubmitResults: PropTypes.array.isRequired,
  fetchOrder: PropTypes.func.isRequired,
};

const mapStateToProps = ({ orderManagement }) => ({
  accountingSubmitResults: orderManagement.accountingSubmitResults,
});

export default connect(mapStateToProps)(OrderManagementDetailsOrderSubmitResultsModal);
