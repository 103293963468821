import React from 'react';
import { VoucherOrderDetailDto } from '@api/financialServices/models';
import { editVoucherOrderForm } from '@constants/reduxForms';
import VoucherOrderForm, { VoucherOrderFormData } from '@components/VoucherOrders/Forms/VoucherOrderForm';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  voucherOrder: Nullable<VoucherOrderDetailDto>;
  editVoucherOrder: (voucherOrderForm: VoucherOrderFormData) => Promise<void>;
  closeModal: () => void;
}

type Props = OwnProps;

const EditVoucherOrder = React.memo<Props>(({
  isOpen,
  voucherOrder,
  editVoucherOrder,
  closeModal,
}) => {
  if (!isOpen || !voucherOrder) return null;

  return (
    <Modal
      title={'Edit Voucher Order'}
      isOpen={isOpen}
      modalHeight={'xl'}
      modalWidth={'xl'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Save'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={editVoucherOrderForm}
        />
      )}
    >
      <VoucherOrderForm
        voucherOrder={voucherOrder}
        onSubmit={editVoucherOrder}
      />
    </Modal>
  );
});

export default EditVoucherOrder;
