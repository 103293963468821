export const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item));

export const deepMerge = (target, source) => {
  if ((target === undefined || target === null) && (source !== undefined || source !== null)) {
    return source;
  }

  if ((target !== undefined || target !== null) && (source === undefined || source === null)) {
    return target;
  }

  if (target === null && source === null) {
    return null;
  }

  if (target === undefined && source === undefined) {
    return undefined;
  }

  if (isObject(target) && isObject(source)) {
    const output = { ...target };
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          output[key] = deepMerge(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });

    return output;
  }

  return null;
};
