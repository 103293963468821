import { slServicesApi } from '../../sqdApis';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import coloredStyleSelectionsUrls from '@constants/sqdApiUrls/coloredStyleSelectionsUrls';
import sqdLockerUrls from '@constants/sqdApiUrls/lockerUrls';

export const refreshCachedImage = async (coloredStyleId) => {
  const call = slServicesApi.put(
    coloredStyleSelectionsUrls.refreshCachedImage(coloredStyleId), {
      handleBlockingLoading: false,
    }
  );
  const res = await makeApiCall(call);
  if (res) {
    return res.image_url;
  }
};

export const refreshCachedImagesOnLocker = async (lockerId) => {
  const call = slServicesApi.put(sqdLockerUrls.refreshCachedImages(lockerId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
