/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  VoucherOrderDetailDtoPagedList,
  GetHomefieldApiVouchersOrdersParams,
  VoucherOrderPreviewDto,
  ApiResponse,
  CreateVoucherOrderCommand,
  VoucherOrderDetailDto,
  GetHomefieldApiVouchersCollectionsCollectionIdOrdersParams,
  GetHomefieldApiVouchersRosterteamsRosterTeamIdOrdersParams,
  VoucherCollectionDetailDto,
  UpdateVoucherOrderCommand,
  VoucherOrderPreviewDtoApiResponse,
  PreviewVoucherOrderCommand,
  SendVoucherOrderInvoiceCommand,
  SendVoucherOrderRefundReceiptCommand,
  CreateVoucherOrderEmailCampaignsCommand,
  SendVoucherOrderEmailNotificationsCommand,
  SubmitVoucherOrderToAccountingCommand,
  CancelVoucherOrderCommand,
  CancelVoucherInstancesCommand,
  RefundVoucherOrderCommand,
  VoucherOrderSubmitResultDto,
  VoucherInstanceDetailsDto,
  GetHomefieldApiVouchersVoucherinstancesParams,
  VoucherOrderChangeLogItemDto
} from './models'
import { financialApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiVouchersOrders = <TData = VoucherOrderDetailDtoPagedList>(
    params?: GetHomefieldApiVouchersOrdersParams,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersOrdersQueryKey = (params?: GetHomefieldApiVouchersOrdersParams,) => [`/homefield/api/vouchers/orders`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVouchersOrders = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersOrders, VoucherOrderDetailDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiVouchersOrdersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersOrdersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersOrders<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiVouchersOrders = <TData = VoucherOrderPreviewDto>(
    createVoucherOrderCommand: CreateVoucherOrderCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders`, method: 'post',
      data: createVoucherOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVouchersOrders = <TData = AsyncReturnType<typeof postHomefieldApiVouchersOrders,VoucherOrderPreviewDto>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateVoucherOrderCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateVoucherOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVouchersOrders<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiVouchersCollectionsCollectionIdOrders = <TData = VoucherOrderDetailDto[]>(
    collectionId: number,
    params?: GetHomefieldApiVouchersCollectionsCollectionIdOrdersParams,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/collections/${collectionId}/orders`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersCollectionsCollectionIdOrdersQueryKey = (collectionId: number,
    params?: GetHomefieldApiVouchersCollectionsCollectionIdOrdersParams,) => [`/homefield/api/vouchers/collections/${collectionId}/orders`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVouchersCollectionsCollectionIdOrders = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersCollectionsCollectionIdOrders, VoucherOrderDetailDto[]>, TError = unknown, TData = TQueryFnData>(
 collectionId: number,
    params?: GetHomefieldApiVouchersCollectionsCollectionIdOrdersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersCollectionsCollectionIdOrdersQueryKey(collectionId,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersCollectionsCollectionIdOrders<TQueryFnData>(collectionId,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVouchersRosterteamsRosterTeamIdOrders = <TData = VoucherOrderDetailDto[]>(
    rosterTeamId: number,
    params?: GetHomefieldApiVouchersRosterteamsRosterTeamIdOrdersParams,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/roster-teams/${rosterTeamId}/orders`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersRosterteamsRosterTeamIdOrdersQueryKey = (rosterTeamId: number,
    params?: GetHomefieldApiVouchersRosterteamsRosterTeamIdOrdersParams,) => [`/homefield/api/vouchers/roster-teams/${rosterTeamId}/orders`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVouchersRosterteamsRosterTeamIdOrders = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersRosterteamsRosterTeamIdOrders, VoucherOrderDetailDto[]>, TError = unknown, TData = TQueryFnData>(
 rosterTeamId: number,
    params?: GetHomefieldApiVouchersRosterteamsRosterTeamIdOrdersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersRosterteamsRosterTeamIdOrdersQueryKey(rosterTeamId,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersRosterteamsRosterTeamIdOrders<TQueryFnData>(rosterTeamId,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVouchersOrdersId = <TData = VoucherOrderDetailDto>(
    id: number,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersOrdersIdQueryKey = (id: number,) => [`/homefield/api/vouchers/orders/${id}`]

    
export const useGetHomefieldApiVouchersOrdersId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersOrdersId, VoucherOrderDetailDto>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersOrdersIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersOrdersId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVouchersOrdersVoucherOrderIdCollections = <TData = VoucherCollectionDetailDto[]>(
    voucherOrderId: number,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders/${voucherOrderId}/collections`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersOrdersVoucherOrderIdCollectionsQueryKey = (voucherOrderId: number,) => [`/homefield/api/vouchers/orders/${voucherOrderId}/collections`]

    
export const useGetHomefieldApiVouchersOrdersVoucherOrderIdCollections = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersOrdersVoucherOrderIdCollections, VoucherCollectionDetailDto[]>, TError = unknown, TData = TQueryFnData>(
 voucherOrderId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersOrdersVoucherOrderIdCollectionsQueryKey(voucherOrderId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersOrdersVoucherOrderIdCollections<TQueryFnData>(voucherOrderId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVouchersCollectionsId = <TData = VoucherCollectionDetailDto>(
    id: number,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/collections/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersCollectionsIdQueryKey = (id: number,) => [`/homefield/api/vouchers/collections/${id}`]

    
export const useGetHomefieldApiVouchersCollectionsId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersCollectionsId, VoucherCollectionDetailDto>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersCollectionsIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersCollectionsId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiVouchersOrdersVoucherOrderId = <TData = ApiResponse>(
    voucherOrderId: number,
    updateVoucherOrderCommand: UpdateVoucherOrderCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders/${voucherOrderId}`, method: 'put',
      data: updateVoucherOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiVouchersOrdersVoucherOrderId = <TData = AsyncReturnType<typeof putHomefieldApiVouchersOrdersVoucherOrderId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{voucherOrderId: number;data: UpdateVoucherOrderCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {voucherOrderId: number;data: UpdateVoucherOrderCommand}, TContext>((props) => {
        const {voucherOrderId,data} = props || {};

        return  putHomefieldApiVouchersOrdersVoucherOrderId<TData>(voucherOrderId,data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiVouchersOrderspreview = <TData = VoucherOrderPreviewDtoApiResponse>(
    previewVoucherOrderCommand: PreviewVoucherOrderCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders.preview`, method: 'post',
      data: previewVoucherOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVouchersOrderspreview = <TData = AsyncReturnType<typeof postHomefieldApiVouchersOrderspreview,VoucherOrderPreviewDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PreviewVoucherOrderCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PreviewVoucherOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVouchersOrderspreview<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiVouchersOrderssendinvoice = <TData = ApiResponse>(
    sendVoucherOrderInvoiceCommand: SendVoucherOrderInvoiceCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders.send-invoice`, method: 'post',
      data: sendVoucherOrderInvoiceCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVouchersOrderssendinvoice = <TData = AsyncReturnType<typeof postHomefieldApiVouchersOrderssendinvoice,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SendVoucherOrderInvoiceCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: SendVoucherOrderInvoiceCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVouchersOrderssendinvoice<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiVouchersOrderssendrefundreceipt = <TData = ApiResponse>(
    sendVoucherOrderRefundReceiptCommand: SendVoucherOrderRefundReceiptCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders.send-refund-receipt`, method: 'post',
      data: sendVoucherOrderRefundReceiptCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVouchersOrderssendrefundreceipt = <TData = AsyncReturnType<typeof postHomefieldApiVouchersOrderssendrefundreceipt,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SendVoucherOrderRefundReceiptCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: SendVoucherOrderRefundReceiptCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVouchersOrderssendrefundreceipt<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiVouchersOrderscreateemailcampaigns = <TData = ApiResponse>(
    createVoucherOrderEmailCampaignsCommand: CreateVoucherOrderEmailCampaignsCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders.create-email-campaigns`, method: 'post',
      data: createVoucherOrderEmailCampaignsCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVouchersOrderscreateemailcampaigns = <TData = AsyncReturnType<typeof postHomefieldApiVouchersOrderscreateemailcampaigns,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateVoucherOrderEmailCampaignsCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateVoucherOrderEmailCampaignsCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVouchersOrderscreateemailcampaigns<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiVouchersOrderssendemailnotifications = <TData = ApiResponse>(
    sendVoucherOrderEmailNotificationsCommand: SendVoucherOrderEmailNotificationsCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders.send-email-notifications`, method: 'post',
      data: sendVoucherOrderEmailNotificationsCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVouchersOrderssendemailnotifications = <TData = AsyncReturnType<typeof postHomefieldApiVouchersOrderssendemailnotifications,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SendVoucherOrderEmailNotificationsCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: SendVoucherOrderEmailNotificationsCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVouchersOrderssendemailnotifications<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiVouchersOrderssubmittoaccounting = <TData = ApiResponse>(
    submitVoucherOrderToAccountingCommand: SubmitVoucherOrderToAccountingCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders.submit-to-accounting`, method: 'post',
      data: submitVoucherOrderToAccountingCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVouchersOrderssubmittoaccounting = <TData = AsyncReturnType<typeof postHomefieldApiVouchersOrderssubmittoaccounting,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SubmitVoucherOrderToAccountingCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: SubmitVoucherOrderToAccountingCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVouchersOrderssubmittoaccounting<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiVouchersOrderscancel = <TData = ApiResponse>(
    cancelVoucherOrderCommand: CancelVoucherOrderCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders.cancel`, method: 'put',
      data: cancelVoucherOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiVouchersOrderscancel = <TData = AsyncReturnType<typeof putHomefieldApiVouchersOrderscancel,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CancelVoucherOrderCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CancelVoucherOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiVouchersOrderscancel<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiVouchersInstancescancel = <TData = ApiResponse>(
    cancelVoucherInstancesCommand: CancelVoucherInstancesCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/instances.cancel`, method: 'put',
      data: cancelVoucherInstancesCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiVouchersInstancescancel = <TData = AsyncReturnType<typeof putHomefieldApiVouchersInstancescancel,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CancelVoucherInstancesCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CancelVoucherInstancesCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiVouchersInstancescancel<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiVouchersOrdersrefund = <TData = ApiResponse>(
    refundVoucherOrderCommand: RefundVoucherOrderCommand,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders.refund`, method: 'post',
      data: refundVoucherOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVouchersOrdersrefund = <TData = AsyncReturnType<typeof postHomefieldApiVouchersOrdersrefund,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RefundVoucherOrderCommand}, TContext>, request?: SecondParameter<typeof financialApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: RefundVoucherOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVouchersOrdersrefund<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiVouchersOrdersIdSubmitresults = <TData = VoucherOrderSubmitResultDto[]>(
    id: number,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders/${id}/submit-results`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersOrdersIdSubmitresultsQueryKey = (id: number,) => [`/homefield/api/vouchers/orders/${id}/submit-results`]

    
export const useGetHomefieldApiVouchersOrdersIdSubmitresults = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersOrdersIdSubmitresults, VoucherOrderSubmitResultDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersOrdersIdSubmitresultsQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersOrdersIdSubmitresults<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVouchersVoucherinstances = <TData = VoucherInstanceDetailsDto>(
    params?: GetHomefieldApiVouchersVoucherinstancesParams,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/voucher-instances`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersVoucherinstancesQueryKey = (params?: GetHomefieldApiVouchersVoucherinstancesParams,) => [`/homefield/api/vouchers/voucher-instances`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVouchersVoucherinstances = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersVoucherinstances, VoucherInstanceDetailsDto>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiVouchersVoucherinstancesParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersVoucherinstancesQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersVoucherinstances<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVouchersOrdersIdChangelog = <TData = VoucherOrderChangeLogItemDto[]>(
    id: number,
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/vouchers/orders/${id}/change-log`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVouchersOrdersIdChangelogQueryKey = (id: number,) => [`/homefield/api/vouchers/orders/${id}/change-log`]

    
export const useGetHomefieldApiVouchersOrdersIdChangelog = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVouchersOrdersIdChangelog, VoucherOrderChangeLogItemDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVouchersOrdersIdChangelogQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVouchersOrdersIdChangelog<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

