import React from 'react';
import { noneBulkAction } from '@constants/values';
import { BulkOption } from '@models/common/Option';
import { mapToOptionsList } from '@util/mappingHelper';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

interface OwnProps {
  selectedBulkAction: string;
  onSelectBulkAction: (option: string) => void;
  options: readonly BulkOption[];
  minWidth?: number;
}

type Props = OwnProps;

const BulkActionSelector = ({
  selectedBulkAction,
  onSelectBulkAction,
  options,
}: Props) => {
  const optionsList = mapToOptionsList<(typeof options)[number]['value'], (typeof options)[number]>({
    list: options as any[],
    key: 'key',
    value: 'value',
    name: 'name',
    emptyOption: {
      name: noneBulkAction.name,
    },
  });

  if (optionsList.length === 1) {
    return null;
  }

  return (
    <Dropdown<string>
      options={optionsList}
      onChange={onSelectBulkAction}
      defaultValue={selectedBulkAction}
      classes={'bulk-actions__dropdown'}
    />
  );
};

export default React.memo(BulkActionSelector);
