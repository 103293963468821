/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  PagedListViewModelLockerTableViewModel,
  ApiInternalServerError,
  GetAllLockersParams,
  LockerDetails,
  ApiNotFound,
  ApiOk,
  UpdateLockerDataRequestModel,
  AuditLogViewModel,
  PagedListViewModelLockerProductViewModel,
  GetLockerProductsParams,
  ApiBadRequest,
  DeleteProductsRequestModel,
  AddProductsRequestModel,
  LogoViewModel,
  UpdateLogoRequestModel,
  EditLogoParams,
  ApiOkLogoIdNumberImageStringOrNullChecksumString,
  LockerManagerBaseModel,
  PartnerViewModel,
  GetAllPartnersParams,
  LockerColoredStylesViewModel,
  CreateColoredStyleSelectionsRequestModel,
  UpdateLockerOwnerRequestModel,
  UpdateLockerClaimedStatusParams,
  OrganizationViewModel,
  LockerOrganizationViewModel,
  UploadLogoViewModel,
  LockerLogoViewModel,
  LogoBaseModel,
  GetAllFromLogoBankParams,
  GetLogoColoredStyleColors,
  LogoByLockerModel,
  UsedLogoForLockerViewModel,
  GetUsedLogosForLockerParams,
  UpdateLogoAssignmentRequestModel,
  ReplaceLogoRequestModel,
  LockerManagerViewModel,
  AddManagerToLockerRequestModel,
  AddManagersToLockerRequestModel,
  CollectionViewModel,
  ApiOkLockerIdNumber,
  CloneLockerParams,
  LockerManagerLockerItemViewModel,
  ApiOkNumber,
  CreateLockerItemRequestModel,
  UpdateLockerItemRequestModel,
  IdsRequestModel,
  ImportItemsToLockerParams,
  RosterTeamBaseViewModel,
  ColoredStylesSearchViewModel,
  GetColoredStylesWhereCodeContainsParams,
  GetEffectedLockersParams,
  ApiOkLockerColoredStyleBulkCreationsArray,
  CreateBulkItemRequestModel,
  StylesSearchViewModel,
  GetStylesWhereCodeContainsParams,
  GetEffectedLockersForStyleParams,
  ApiOkLockerStyleCreationsArray,
  CreateLockerStyleRequestModel
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllLockers = <TData = PagedListViewModelLockerTableViewModel>(
    params?: GetAllLockersParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllLockersQueryKey = (params?: GetAllLockersParams,) => [`/homefield/api/lockermanager/lockers`, ...(params ? [params]: [])]

    
export const useGetAllLockers = <TQueryFnData = AsyncReturnType<typeof getAllLockers, PagedListViewModelLockerTableViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetAllLockersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLockersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllLockers<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getLockerDetails = <TData = LockerDetails>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockerDetailsQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}`]

    
export const useGetLockerDetails = <TQueryFnData = AsyncReturnType<typeof getLockerDetails, LockerDetails>, TError = ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockerDetailsQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockerDetails<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const editLockerInformation = <TData = ApiOk>(
    lockerId: number,
    updateLockerDataRequestModel: UpdateLockerDataRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}`, method: 'put',
      data: updateLockerDataRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEditLockerInformation = <TData = AsyncReturnType<typeof editLockerInformation,ApiOk>,
    TError = ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: UpdateLockerDataRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: UpdateLockerDataRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  editLockerInformation<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const getLockerChangeLogs = <TData = AuditLogViewModel[]>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/change-logs`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockerChangeLogsQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/change-logs`]

    
export const useGetLockerChangeLogs = <TQueryFnData = AsyncReturnType<typeof getLockerChangeLogs, AuditLogViewModel[]>, TError = ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockerChangeLogsQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockerChangeLogs<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getLockerProducts = <TData = PagedListViewModelLockerProductViewModel>(
    lockerId: number,
    params?: GetLockerProductsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/products`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockerProductsQueryKey = (lockerId: number,
    params?: GetLockerProductsParams,) => [`/homefield/api/lockermanager/lockers/${lockerId}/products`, ...(params ? [params]: [])]

    
export const useGetLockerProducts = <TQueryFnData = AsyncReturnType<typeof getLockerProducts, PagedListViewModelLockerProductViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number,
    params?: GetLockerProductsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockerProductsQueryKey(lockerId,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockerProducts<TQueryFnData>(lockerId,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const deleteProducts = <TData = ApiOk>(
    lockerId: number,
    deleteProductsRequestModel: DeleteProductsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/products.remove`, method: 'put',
      data: deleteProductsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteProducts = <TData = AsyncReturnType<typeof deleteProducts,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: DeleteProductsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: DeleteProductsRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  deleteProducts<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const addProducts = <TData = ApiOk>(
    lockerId: number,
    addProductsRequestModel: AddProductsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/products/add`, method: 'put',
      data: addProductsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useAddProducts = <TData = AsyncReturnType<typeof addProducts,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: AddProductsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: AddProductsRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  addProducts<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteLogo = <TData = ApiOk>(
    logoId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/logos/${logoId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteLogo = <TData = AsyncReturnType<typeof deleteLogo,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{logoId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {logoId: number}, TContext>((props) => {
        const {logoId} = props || {};

        return  deleteLogo<TData>(logoId,requestOptions)
      }, mutationOptions)
    }
    export const getLogo = <TData = LogoViewModel>(
    logoId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/logos/${logoId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLogoQueryKey = (logoId: number,) => [`/homefield/api/lockermanager/logos/${logoId}`]

    
export const useGetLogo = <TQueryFnData = AsyncReturnType<typeof getLogo, LogoViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 logoId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLogoQueryKey(logoId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLogo<TQueryFnData>(logoId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const editLogo = <TData = ApiOk>(
    logoId: number,
    updateLogoRequestModel: UpdateLogoRequestModel,
    params?: EditLogoParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/logos/${logoId}`, method: 'put',
      data: updateLogoRequestModel,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEditLogo = <TData = AsyncReturnType<typeof editLogo,ApiOk>,
    TError = ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{logoId: number;data: UpdateLogoRequestModel;params?: EditLogoParams}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {logoId: number;data: UpdateLogoRequestModel;params?: EditLogoParams}, TContext>((props) => {
        const {logoId,data,params} = props || {};

        return  editLogo<TData>(logoId,data,params,requestOptions)
      }, mutationOptions)
    }
    export const replaceLogoImage = <TData = ApiOkLogoIdNumberImageStringOrNullChecksumString>(
    logoId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/logos/${logoId}/replaceImage`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useReplaceLogoImage = <TData = AsyncReturnType<typeof replaceLogoImage,ApiOkLogoIdNumberImageStringOrNullChecksumString>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{logoId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {logoId: number}, TContext>((props) => {
        const {logoId} = props || {};

        return  replaceLogoImage<TData>(logoId,requestOptions)
      }, mutationOptions)
    }
    export const restoreLogo = <TData = ApiOk>(
    logoId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/logos.restore/${logoId}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRestoreLogo = <TData = AsyncReturnType<typeof restoreLogo,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{logoId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {logoId: number}, TContext>((props) => {
        const {logoId} = props || {};

        return  restoreLogo<TData>(logoId,requestOptions)
      }, mutationOptions)
    }
    export const requeueLogoForReview = <TData = ApiOk>(
    logoId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/logos.requeue-for-review/${logoId}`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRequeueLogoForReview = <TData = AsyncReturnType<typeof requeueLogoForReview,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{logoId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {logoId: number}, TContext>((props) => {
        const {logoId} = props || {};

        return  requeueLogoForReview<TData>(logoId,requestOptions)
      }, mutationOptions)
    }
    export const getAllLockerManagers = <TData = LockerManagerBaseModel[]>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/managers`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllLockerManagersQueryKey = () => [`/homefield/api/lockermanager/managers`]

    
export const useGetAllLockerManagers = <TQueryFnData = AsyncReturnType<typeof getAllLockerManagers, LockerManagerBaseModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLockerManagersQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllLockerManagers<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getAllPartners = <TData = PartnerViewModel[]>(
    params?: GetAllPartnersParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/partners`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllPartnersQueryKey = (params?: GetAllPartnersParams,) => [`/homefield/api/lockermanager/partners`, ...(params ? [params]: [])]

    
export const useGetAllPartners = <TQueryFnData = AsyncReturnType<typeof getAllPartners, PartnerViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetAllPartnersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllPartnersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllPartners<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getAllLockerColoredStyles = <TData = LockerColoredStylesViewModel>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/colored-styles`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllLockerColoredStylesQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/colored-styles`]

    
export const useGetAllLockerColoredStyles = <TQueryFnData = AsyncReturnType<typeof getAllLockerColoredStyles, LockerColoredStylesViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLockerColoredStylesQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllLockerColoredStyles<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createColoredStyleSelectionsForLocker = <TData = ApiOk>(
    lockerId: number,
    createColoredStyleSelectionsRequestModel: CreateColoredStyleSelectionsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/colored-style-selections`, method: 'post',
      data: createColoredStyleSelectionsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateColoredStyleSelectionsForLocker = <TData = AsyncReturnType<typeof createColoredStyleSelectionsForLocker,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: CreateColoredStyleSelectionsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: CreateColoredStyleSelectionsRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  createColoredStyleSelectionsForLocker<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const updateLockerOwner = <TData = ApiOk>(
    lockerId: number,
    updateLockerOwnerRequestModel: UpdateLockerOwnerRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/owner`, method: 'put',
      data: updateLockerOwnerRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUpdateLockerOwner = <TData = AsyncReturnType<typeof updateLockerOwner,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: UpdateLockerOwnerRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: UpdateLockerOwnerRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  updateLockerOwner<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const closeLocker = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/close`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCloseLocker = <TData = AsyncReturnType<typeof closeLocker,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  closeLocker<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const toggleLockerCancelledStatus = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/cancel`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useToggleLockerCancelledStatus = <TData = AsyncReturnType<typeof toggleLockerCancelledStatus,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  toggleLockerCancelledStatus<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const updateLockerClaimedStatus = <TData = ApiOk>(
    lockerId: number,
    params?: UpdateLockerClaimedStatusParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/claim`, method: 'put',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUpdateLockerClaimedStatus = <TData = AsyncReturnType<typeof updateLockerClaimedStatus,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;params?: UpdateLockerClaimedStatusParams}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;params?: UpdateLockerClaimedStatusParams}, TContext>((props) => {
        const {lockerId,params} = props || {};

        return  updateLockerClaimedStatus<TData>(lockerId,params,requestOptions)
      }, mutationOptions)
    }
    export const restoreDeletedLocker = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/restore`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRestoreDeletedLocker = <TData = AsyncReturnType<typeof restoreDeletedLocker,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  restoreDeletedLocker<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const getAllOrganizations = <TData = OrganizationViewModel[]>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/organizations`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllOrganizationsQueryKey = () => [`/homefield/api/lockermanager/organizations`]

    
export const useGetAllOrganizations = <TQueryFnData = AsyncReturnType<typeof getAllOrganizations, OrganizationViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllOrganizationsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllOrganizations<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getLockerOrganizations = <TData = LockerOrganizationViewModel>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/locker/${lockerId}/organizations`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockerOrganizationsQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/locker/${lockerId}/organizations`]

    
export const useGetLockerOrganizations = <TQueryFnData = AsyncReturnType<typeof getLockerOrganizations, LockerOrganizationViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockerOrganizationsQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockerOrganizations<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const uploadLogoToLockerLogos = <TData = UploadLogoViewModel>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/locker-logos`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUploadLogoToLockerLogos = <TData = AsyncReturnType<typeof uploadLogoToLockerLogos,UploadLogoViewModel>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  uploadLogoToLockerLogos<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const getLockerLogos = <TData = LockerLogoViewModel[]>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/locker-logos`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockerLogosQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/locker-logos`]

    
export const useGetLockerLogos = <TQueryFnData = AsyncReturnType<typeof getLockerLogos, LockerLogoViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockerLogosQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockerLogos<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getAllFromLogoBank = <TData = LogoBaseModel[]>(
    lockerId: number,
    params?: GetAllFromLogoBankParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/managers/${lockerId}/logos`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllFromLogoBankQueryKey = (lockerId: number,
    params?: GetAllFromLogoBankParams,) => [`/homefield/api/lockermanager/managers/${lockerId}/logos`, ...(params ? [params]: [])]

    
export const useGetAllFromLogoBank = <TQueryFnData = AsyncReturnType<typeof getAllFromLogoBank, LogoBaseModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number,
    params?: GetAllFromLogoBankParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllFromLogoBankQueryKey(lockerId,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllFromLogoBank<TQueryFnData>(lockerId,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const uploadFlyer = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/store-flyer`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUploadFlyer = <TData = AsyncReturnType<typeof uploadFlyer,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  uploadFlyer<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const getLogoColoredStyleColors = <TData = GetLogoColoredStyleColors[]>(
    lockerId: number,
    logoId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/logos/${logoId}/colored-style-colors`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLogoColoredStyleColorsQueryKey = (lockerId: number,
    logoId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/logos/${logoId}/colored-style-colors`]

    
export const useGetLogoColoredStyleColors = <TQueryFnData = AsyncReturnType<typeof getLogoColoredStyleColors, GetLogoColoredStyleColors[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number,
    logoId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLogoColoredStyleColorsQueryKey(lockerId,logoId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLogoColoredStyleColors<TQueryFnData>(lockerId,logoId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const addLogoToLockerLogos = <TData = ApiOk>(
    lockerId: number,
    logoId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/locker-logos/${logoId}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useAddLogoToLockerLogos = <TData = AsyncReturnType<typeof addLogoToLockerLogos,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;logoId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;logoId: number}, TContext>((props) => {
        const {lockerId,logoId} = props || {};

        return  addLogoToLockerLogos<TData>(lockerId,logoId,requestOptions)
      }, mutationOptions)
    }
    export const removeLogoFromLockerLogos = <TData = ApiOk>(
    lockerId: number,
    logoId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/locker-logos/${logoId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRemoveLogoFromLockerLogos = <TData = AsyncReturnType<typeof removeLogoFromLockerLogos,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;logoId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;logoId: number}, TContext>((props) => {
        const {lockerId,logoId} = props || {};

        return  removeLogoFromLockerLogos<TData>(lockerId,logoId,requestOptions)
      }, mutationOptions)
    }
    export const getAllLogosForLocker = <TData = LogoByLockerModel[]>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/logos`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllLogosForLockerQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/logos`]

    
export const useGetAllLogosForLocker = <TQueryFnData = AsyncReturnType<typeof getAllLogosForLocker, LogoByLockerModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLogosForLockerQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllLogosForLocker<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getUsedLogosForLocker = <TData = UsedLogoForLockerViewModel[]>(
    lockerId: number,
    params?: GetUsedLogosForLockerParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/used-logos`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetUsedLogosForLockerQueryKey = (lockerId: number,
    params?: GetUsedLogosForLockerParams,) => [`/homefield/api/lockermanager/lockers/${lockerId}/used-logos`, ...(params ? [params]: [])]

    
export const useGetUsedLogosForLocker = <TQueryFnData = AsyncReturnType<typeof getUsedLogosForLocker, UsedLogoForLockerViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number,
    params?: GetUsedLogosForLockerParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetUsedLogosForLockerQueryKey(lockerId,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getUsedLogosForLocker<TQueryFnData>(lockerId,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getAllColorsForLocker = <TData = string[]>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/colors`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllColorsForLockerQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/colors`]

    
export const useGetAllColorsForLocker = <TQueryFnData = AsyncReturnType<typeof getAllColorsForLocker, string[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllColorsForLockerQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllColorsForLocker<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getAllFloodColorsForLocker = <TData = string[]>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/flood-colors`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllFloodColorsForLockerQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/flood-colors`]

    
export const useGetAllFloodColorsForLocker = <TQueryFnData = AsyncReturnType<typeof getAllFloodColorsForLocker, string[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllFloodColorsForLockerQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllFloodColorsForLocker<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const updateLogoAssignments = <TData = ApiOk>(
    lockerId: number,
    updateLogoAssignmentRequestModel: UpdateLogoAssignmentRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/products/editAssignment`, method: 'post',
      data: updateLogoAssignmentRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUpdateLogoAssignments = <TData = AsyncReturnType<typeof updateLogoAssignments,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: UpdateLogoAssignmentRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: UpdateLogoAssignmentRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  updateLogoAssignments<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const enableCLC = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/enable-clc`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEnableCLC = <TData = AsyncReturnType<typeof enableCLC,ApiOk>,
    TError = ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  enableCLC<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const toggleFreeShipping = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/toggle-free-shipping`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useToggleFreeShipping = <TData = AsyncReturnType<typeof toggleFreeShipping,ApiOk>,
    TError = ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  toggleFreeShipping<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const replaceLogo = <TData = ApiOk>(
    lockerId: number,
    replaceLogoRequestModel: ReplaceLogoRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/products/replaceLogo`, method: 'post',
      data: replaceLogoRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useReplaceLogo = <TData = AsyncReturnType<typeof replaceLogo,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: ReplaceLogoRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: ReplaceLogoRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  replaceLogo<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const getManagersFromLocker = <TData = LockerManagerViewModel[]>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/managers`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetManagersFromLockerQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/managers`]

    
export const useGetManagersFromLocker = <TQueryFnData = AsyncReturnType<typeof getManagersFromLocker, LockerManagerViewModel[]>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetManagersFromLockerQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getManagersFromLocker<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const assignManagerOnLocker = <TData = ApiOk>(
    lockerId: number,
    addManagerToLockerRequestModel: AddManagerToLockerRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/managers`, method: 'put',
      data: addManagerToLockerRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useAssignManagerOnLocker = <TData = AsyncReturnType<typeof assignManagerOnLocker,ApiOk>,
    TError = ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: AddManagerToLockerRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: AddManagerToLockerRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  assignManagerOnLocker<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const assignManagersToLocker = <TData = ApiOk>(
    lockerId: number,
    addManagersToLockerRequestModel: AddManagersToLockerRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/managers`, method: 'post',
      data: addManagersToLockerRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useAssignManagersToLocker = <TData = AsyncReturnType<typeof assignManagersToLocker,ApiOk>,
    TError = ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: AddManagersToLockerRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: AddManagersToLockerRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  assignManagersToLocker<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const removeManagerFromLocker = <TData = ApiOk>(
    lockerId: number,
    managerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/managers/${managerId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRemoveManagerFromLocker = <TData = AsyncReturnType<typeof removeManagerFromLocker,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;managerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;managerId: number}, TContext>((props) => {
        const {lockerId,managerId} = props || {};

        return  removeManagerFromLocker<TData>(lockerId,managerId,requestOptions)
      }, mutationOptions)
    }
    export const getAllCollectionsForLocker = <TData = CollectionViewModel[]>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/collections`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllCollectionsForLockerQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/collections`]

    
export const useGetAllCollectionsForLocker = <TQueryFnData = AsyncReturnType<typeof getAllCollectionsForLocker, CollectionViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllCollectionsForLockerQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllCollectionsForLocker<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getBundledCollectionForLocker = <TData = CollectionViewModel>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/bundled-collection`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetBundledCollectionForLockerQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/bundled-collection`]

    
export const useGetBundledCollectionForLocker = <TQueryFnData = AsyncReturnType<typeof getBundledCollectionForLocker, CollectionViewModel>, TError = ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetBundledCollectionForLockerQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getBundledCollectionForLocker<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const cloneLocker = <TData = ApiOkLockerIdNumber>(
    lockerId: number,
    params?: CloneLockerParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/clone`, method: 'post',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCloneLocker = <TData = AsyncReturnType<typeof cloneLocker,ApiOkLockerIdNumber>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;params?: CloneLockerParams}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;params?: CloneLockerParams}, TContext>((props) => {
        const {lockerId,params} = props || {};

        return  cloneLocker<TData>(lockerId,params,requestOptions)
      }, mutationOptions)
    }
    export const relaunchLocker = <TData = ApiOkLockerIdNumber>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/relaunch`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRelaunchLocker = <TData = AsyncReturnType<typeof relaunchLocker,ApiOkLockerIdNumber>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  relaunchLocker<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const getLockerItem = <TData = LockerManagerLockerItemViewModel>(
    id: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/locker-items/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockerItemQueryKey = (id: number,) => [`/homefield/api/lockermanager/locker-items/${id}`]

    
export const useGetLockerItem = <TQueryFnData = AsyncReturnType<typeof getLockerItem, LockerManagerLockerItemViewModel>, TError = ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockerItemQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockerItem<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createCustomItem = <TData = ApiOkNumber>(
    lockerId: number,
    createLockerItemRequestModel: CreateLockerItemRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/custom-items`, method: 'post',
      data: createLockerItemRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateCustomItem = <TData = AsyncReturnType<typeof createCustomItem,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: CreateLockerItemRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: CreateLockerItemRequestModel}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  createCustomItem<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const editCustomItem = <TData = ApiOkNumber>(
    lockerId: number,
    id: number,
    updateLockerItemRequestModel: UpdateLockerItemRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/custom-items/${id}`, method: 'put',
      data: updateLockerItemRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEditCustomItem = <TData = AsyncReturnType<typeof editCustomItem,ApiOkNumber>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;id: number;data: UpdateLockerItemRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;id: number;data: UpdateLockerItemRequestModel}, TContext>((props) => {
        const {lockerId,id,data} = props || {};

        return  editCustomItem<TData>(lockerId,id,data,requestOptions)
      }, mutationOptions)
    }
    export const importItemsToLocker = <TData = ApiOk>(
    lockerId: number,
    idsRequestModel: IdsRequestModel,
    params?: ImportItemsToLockerParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/items`, method: 'post',
      data: idsRequestModel,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useImportItemsToLocker = <TData = AsyncReturnType<typeof importItemsToLocker,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: IdsRequestModel;params?: ImportItemsToLockerParams}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: IdsRequestModel;params?: ImportItemsToLockerParams}, TContext>((props) => {
        const {lockerId,data,params} = props || {};

        return  importItemsToLocker<TData>(lockerId,data,params,requestOptions)
      }, mutationOptions)
    }
    export const refreshCachedImageForLocker = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/refresh-cached-image`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRefreshCachedImageForLocker = <TData = AsyncReturnType<typeof refreshCachedImageForLocker,ApiOk>,
    TError = ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  refreshCachedImageForLocker<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const getLockerRosters = <TData = RosterTeamBaseViewModel[]>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/rosters`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockerRostersQueryKey = (lockerId: number,) => [`/homefield/api/lockermanager/lockers/${lockerId}/rosters`]

    
export const useGetLockerRosters = <TQueryFnData = AsyncReturnType<typeof getLockerRosters, RosterTeamBaseViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockerRostersQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockerRosters<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getColoredStylesWhereCodeContains = <TData = ColoredStylesSearchViewModel[]>(
    params?: GetColoredStylesWhereCodeContainsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers.get-colored-style-suggestions`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetColoredStylesWhereCodeContainsQueryKey = (params?: GetColoredStylesWhereCodeContainsParams,) => [`/homefield/api/lockermanager/lockers.get-colored-style-suggestions`, ...(params ? [params]: [])]

    
export const useGetColoredStylesWhereCodeContains = <TQueryFnData = AsyncReturnType<typeof getColoredStylesWhereCodeContains, ColoredStylesSearchViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetColoredStylesWhereCodeContainsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetColoredStylesWhereCodeContainsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getColoredStylesWhereCodeContains<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getEffectedLockers = <TData = number>(
    params?: GetEffectedLockersParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers.get-colored-style-lockers-count`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetEffectedLockersQueryKey = (params?: GetEffectedLockersParams,) => [`/homefield/api/lockermanager/lockers.get-colored-style-lockers-count`, ...(params ? [params]: [])]

    
export const useGetEffectedLockers = <TQueryFnData = AsyncReturnType<typeof getEffectedLockers, number>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetEffectedLockersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetEffectedLockersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getEffectedLockers<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createBulkColoredStyle = <TData = ApiOkLockerColoredStyleBulkCreationsArray>(
    createBulkItemRequestModel: CreateBulkItemRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers.create-bulk-colored-style`, method: 'post',
      data: createBulkItemRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateBulkColoredStyle = <TData = AsyncReturnType<typeof createBulkColoredStyle,ApiOkLockerColoredStyleBulkCreationsArray>,
    TError = ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateBulkItemRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateBulkItemRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  createBulkColoredStyle<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getStylesWhereCodeContains = <TData = StylesSearchViewModel[]>(
    params?: GetStylesWhereCodeContainsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers.get-style-suggestions`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetStylesWhereCodeContainsQueryKey = (params?: GetStylesWhereCodeContainsParams,) => [`/homefield/api/lockermanager/lockers.get-style-suggestions`, ...(params ? [params]: [])]

    
export const useGetStylesWhereCodeContains = <TQueryFnData = AsyncReturnType<typeof getStylesWhereCodeContains, StylesSearchViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetStylesWhereCodeContainsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetStylesWhereCodeContainsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getStylesWhereCodeContains<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getEffectedLockersForStyle = <TData = number>(
    params?: GetEffectedLockersForStyleParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers.get-style-lockers-count`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetEffectedLockersForStyleQueryKey = (params?: GetEffectedLockersForStyleParams,) => [`/homefield/api/lockermanager/lockers.get-style-lockers-count`, ...(params ? [params]: [])]

    
export const useGetEffectedLockersForStyle = <TQueryFnData = AsyncReturnType<typeof getEffectedLockersForStyle, number>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetEffectedLockersForStyleParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetEffectedLockersForStyleQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getEffectedLockersForStyle<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createStyleInActiveLockers = <TData = ApiOkLockerStyleCreationsArray>(
    createLockerStyleRequestModel: CreateLockerStyleRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers.create-style-in-active-lockers`, method: 'post',
      data: createLockerStyleRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateStyleInActiveLockers = <TData = AsyncReturnType<typeof createStyleInActiveLockers,ApiOkLockerStyleCreationsArray>,
    TError = ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateLockerStyleRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateLockerStyleRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  createStyleInActiveLockers<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const refreshFlyer = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/lockermanager/lockers/${lockerId}/refresh-flyer`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRefreshFlyer = <TData = AsyncReturnType<typeof refreshFlyer,ApiOk>,
    TError = ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  refreshFlyer<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    