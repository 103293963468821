import * as actionTypes from './types';

const initialState = {
  couponsList: {
    totalPages: 1,
    pageNumber: 1,
    pageSize: 10,
    queue: [],
    totalCount: 0,
    hasNextPage: null,
    hasPreviousPage: null,
  },
  couponCreators: [],
  couponRestrictions: [],
};

export default function coupons(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_COUPONS_TABLE:
      return {
        ...state,
        couponsList: action.data,
      };
    case actionTypes.UPDATE_COUPON_CREATORS:
      return {
        ...state,
        couponCreators: action.data,
      };
    case actionTypes.UPDATE_COUPON_RESTRICTIONS:
      return {
        ...state,
        couponRestrictions: action.data,
      };
    default:
      return state;
  }
}
