import React from 'react';
import PropTypes from 'prop-types';
import CustomNameFormInput from './CustomItemDetailsSectionContent/CustomNameFormInput';
import CustomPriceFormInput from './CustomItemDetailsSectionContent/CustomPriceFormInput';
import FundraisingAmountFormInput from './CustomItemDetailsSectionContent/FundraisingAmountFormInput';
import CustomItemIsHiddenFormCheckbox from './CustomItemDetailsSectionContent/CustomItemIsHiddenFormCheckbox';
import AllowDiscountsFormCheckbox from './CustomItemDetailsSectionContent/AllowDiscountsFormCheckbox';
import DiscountInfoField from './CustomItemDetailsSectionContent/DiscountInfoField';
import RequiredItemFormCheckbox from '@sharedComponents/Form/Fields/RequiredItemFormCheckbox';

const CustomItemDetailsSection = React.memo(({
  isCustomItem,
  styleName,
  discount,
  change,
  unhidingItemDisabled,
  disabled,
}) => (
  <div>
    <div className='redux-form__row'>
      <div className='redux-form__column--size-m pr-20'>
        <CustomNameFormInput
          styleName={styleName}
          disabled={disabled}
        />
      </div>
    </div>

    <div className='redux-form__row'>
      <div className='redux-form__column--size-m flex'>
        <CustomPriceFormInput disabled={disabled} />
      </div>
      <div className='redux-form__column--size-m flex'>
        <FundraisingAmountFormInput disabled={disabled} />
      </div>
    </div>

    <div className='redux-form__row'>
      <div className='redux-form__column--size-m flex'>
        <DiscountInfoField
          discount={discount}
        />
        <div className='align__center mt-20'>
          <AllowDiscountsFormCheckbox
            change={change}
            disabled={disabled}
          />
        </div>
      </div>
      <div className='redux-form__column--size-m flex align__bottom'>
        <div className='redux-form__column--size-m'>
          <CustomItemIsHiddenFormCheckbox
            isCustomItem={isCustomItem}
            unhidingItemDisabled={unhidingItemDisabled}
            change={change}
            disabled={disabled}
          />
        </div>
        <div className='redux-form__column flex mt-10'>
          <RequiredItemFormCheckbox
            name={'requiredItem'}
            change={change}
            disabled={disabled.requiredItem}
          />
        </div>

      </div>
    </div>
  </div>
));

CustomItemDetailsSection.propTypes = {
  change: PropTypes.func.isRequired,
  styleName: PropTypes.string,
  discount: PropTypes.number,
  unhidingItemDisabled: PropTypes.bool.isRequired,
};

export default CustomItemDetailsSection;
