import * as actionTypes from './types';
import servicesUrls from '@constants/sqdApiUrls/servicesUrls';
import {
  fulfillmentApi,
  catalogApi,
  financialApi,
} from '../../sqdApis';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import { serviceApiLocationEnum } from '@constants/enums/servicesEnums';

const serviceMapper = (service, apiLocation) => ({
  name: service.name,
  status: service.status,
  apiLocation,
});

const getApiForService = (service) => {
  let api;
  let refreshServices;

  switch (service.apiLocation) {
    case serviceApiLocationEnum.FulfillmentApi:
      api = fulfillmentApi;
      refreshServices = fetchFulfillmentServices;
      break;
    case serviceApiLocationEnum.CatalogApi:
      api = catalogApi;
      refreshServices = fetchCatalogServices;
      break;
    case serviceApiLocationEnum.FinancialServicesApi:
      api = financialApi;
      refreshServices = fetchFinancialServices;
      break;
  }

  return ({
    api,
    refreshServices,
  });
};

export const updateFulfillmentServices = (data) => ({
  type: actionTypes.UPDATE_FULFILLMENT_SERVICES,
  data: data.map((service) => serviceMapper(service, serviceApiLocationEnum.FulfillmentApi)),
});

export const updateCatalogServices = (data) => ({
  type: actionTypes.UPDATE_CATALOG_SERVICES,
  data: data.map((service) => serviceMapper(service, serviceApiLocationEnum.CatalogApi)),
});

export const updateGPServices = (data) => ({
  type: actionTypes.UPDATE_GP_SERVICES,
  data: data.map((service) => serviceMapper(service, serviceApiLocationEnum.GPApi)),
});

export const updateFinancialServices = (data) => ({
  type: actionTypes.UPDATE_FINANCIAL_SERVICES,
  data: data.map((service) => serviceMapper(service, serviceApiLocationEnum.FinancialServicesApi)),
});

export const fetchFulfillmentServices = () => async (dispatch) => {
  const call = fulfillmentApi.get(servicesUrls.services, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    return dispatch(updateFulfillmentServices(res));
  }
};

export const fetchCatalogServices = () => async (dispatch) => {
  const call = catalogApi.get(servicesUrls.services, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    return dispatch(updateCatalogServices(res));
  }
};

export const fetchFinancialServices = () => async (dispatch) => {
  const call = financialApi.get(servicesUrls.services, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    return dispatch(updateFinancialServices(res));
  }
};

export const startService = (service) => async (dispatch) => {
  const {
    api, refreshServices,
  } = getApiForService(service);

  const call = api.post(servicesUrls.startService, {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(service),
  });

  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    return dispatch(refreshServices());
  }
};

export const stopService = (service) => async (dispatch) => {
  const {
    api, refreshServices,
  } = getApiForService(service);

  const call = api.post(servicesUrls.stopService, {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(service),
  });

  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    return dispatch(refreshServices());
  }
};

export const restartService = (service) => async (dispatch) => {
  const {
    api, refreshServices,
  } = getApiForService(service);

  const call = api.post(servicesUrls.restartService, {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(service),
  });

  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    return dispatch(refreshServices());
  }
};
