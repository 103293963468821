import React from 'react';
import { CollectionItemViewModel } from '@api/squadlockerServices/models';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ItemsList from './CollectionDetailsModalContent/ItemsList';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  lockerId: number;
  lockerTeamName: string;
  collectionItems: CollectionItemViewModel[];
}

type Props = OwnProps;

const CollectionDetailsModal = React.memo<Props>(({
  isOpen,
  closeModal,
  lockerId,
  lockerTeamName,
  collectionItems,
}) => (
  <Modal
    title={'Uniform Pack'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        displayConfirmBtn={false}
        cancelBtnText={'Close'}
        onClose={closeModal}
      />
    )}
  >
    <div className='roster-collection'>
      <div className='roster-collection__subtitle'>
        L{lockerId} - {lockerTeamName}
      </div>
      <ItemsList
        items={collectionItems}
      />
    </div>
  </Modal>
));

export default CollectionDetailsModal;
