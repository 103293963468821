import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ProductionAssemblyScanBarcodesForm from '../ProductionAssemblyScanBarcodesForm';
import { scanOrderItemsBarcodesForm } from '@constants/reduxForms';

const ProductionAssemblyAddBarcodesModal = React.memo(({
  isOpen,
  closeModal,
  onSubmit,
  itemBarcodes,
}) => (
  <Modal
    title={'Add Barcode(s)'}
    isOpen={isOpen}
    modalSize={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Add'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={scanOrderItemsBarcodesForm}
      />
    )}
  >
    <ProductionAssemblyScanBarcodesForm
      onSubmit={onSubmit}
      itemBarcodes={itemBarcodes}
    />
  </Modal>
));

ProductionAssemblyAddBarcodesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  itemBarcodes: PropTypes.arrayOf(PropTypes.shape({
    orderItemId: PropTypes.number.isRequired,
    barcode: PropTypes.string,
  })).isRequired,
};

export default ProductionAssemblyAddBarcodesModal;
