import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  decorationTypeEnum,
  personalizationTypeEnum,
} from '@constants/enums/decorationEnums';
import { parseNumber } from '@util/numberHelpers';
import Input from '@sharedComponents/Form/Input';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';

class DecorationDimensions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      customDecoration: this.isCustomDimension(),
    };
  }

  componentDidUpdate(oldProps) {
    const { decoration } = this.props;
    const oldDecoration = oldProps.decoration;
    const {
      type,
      personalizationType,
    } = decoration;

    if (!decoration) {
      this.resetDecorationDimensions();
    } else if (oldDecoration !== decoration) {
      this.setState({ customDecoration: this.isCustomDimension() });
    } else {
      const oldType = oldDecoration.type;
      const { oldPersonalizationType } = oldDecoration;
      if (oldType !== type
        || (oldPersonalizationType !== personalizationType && personalizationType === personalizationTypeEnum.Name)) {
        this.resetDecorationDimensions();
      }
    }
  }

  isCustomDimension = () => {
    const { decoration } = this.props;

    return !!(decoration && (decoration.decorationWidth || decoration.decorationHeight));
  };

  resetDecorationDimensions = () => {
    const {
      name,
      change,
      dispatch,
    } = this.props;

    dispatch(change(`${name}.decorationWidth`, null));
    dispatch(change(`${name}.decorationHeight`, null));
  };

  changeCustomDecorationDimensions = () => {
    const { customDecoration } = this.state;
    const newCustomDecoration = !customDecoration;
    // If we turn off the option to set the custom dimension we have to reset the values
    if (!newCustomDecoration) {
      this.resetDecorationDimensions();
    }
    this.setState({ customDecoration: newCustomDecoration });
  };

  render() {
    const { customDecoration } = this.state;

    const {
      decoration,
      name,
      decorationDimension,
    } = this.props;

    const {
      type,
      personalizationType,
    } = decoration;

    let customHeight = null;
    let customWidth = null;
    let checkbox = null;
    const personalizationCustomHeight = (type === decorationTypeEnum.Personalization &&
      (personalizationType === personalizationTypeEnum.Both
        || personalizationType === personalizationTypeEnum.Number));

    if (type === decorationTypeEnum.Logo || personalizationCustomHeight) {
      checkbox = (
        <MaterialCheckbox
          text={'Custom Dimensions'}
          checked={customDecoration}
          onClick={this.changeCustomDecorationDimensions}
        />
      );
    }

    if (customDecoration) {
      if (type === decorationTypeEnum.Logo) {
        customWidth = (
          <div className='align__center w-50'>
            <div className='info__label ml-10 uppercase'>W</div>
            <div className='info__value'>
              <div className='redux-form__input-wrapper lockerManagerEdit__custom-items--decoration__dimension'>
                <Field
                  name={`${name}.decorationWidth`}
                  placeholder={'inches'}
                  component={Input}
                  type={'number'}
                  parse={parseNumber}
                  className={'margin-left w-50 lockerManagerEdit__custom-items--decoration__dimension--input'}
                />
              </div>
            </div>
          </div>
        );

        customHeight = (
          <div className='align__center w-50'>
            <div className='info__value'>
              <div className='redux-form__input-wrapper lockerManagerEdit__custom-items--decoration__dimension ml-10'>
                <Field
                  name={`${name}.decorationHeight`}
                  placeholder={'inches'}
                  component={Input}
                  type={'number'}
                  parse={parseNumber}
                  className={'margin-left w-50 lockerManagerEdit__custom-items--decoration__dimension--input'}
                />
              </div>
            </div>
            <div className='info__label ml-10 uppercase'>H</div>
          </div>
        );
      }

      if (personalizationCustomHeight) {
        customHeight = (
          <div className='align__center w-50'>
            <div className='info__label ml-10 uppercase'>Height</div>
            <div className='info__value'>
              <div className='redux-form__input-wrapper lockerManagerEdit__custom-items--decoration__dimension'>
                <Field
                  name={`${name}.decorationHeight`}
                  placeholder={'inches'}
                  component={Input}
                  type={'number'}
                  parse={parseNumber}
                  className={'margin-left w-50 lockerManagerEdit__custom-items--decoration__dimension--input'}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className='lockerManagerEdit__custom-items--decoration__location'>
        {
          decorationDimension &&
          <>
            <div className={`lockerManagerEdit__custom-items--decoration__dimension--display ${customDecoration ? 'blurred' : ''}`}>
              Decoration Dimensions: <span className='value'>{decorationDimension.width}'' x {decorationDimension.height}''</span>
            </div>
            <hr className='mb-0 mt-10' />
          </>
        }
        {checkbox}
        <div className='align__center lockerManagerEdit__custom-items--decoration__dimension-container'>
          {customWidth}
          {customWidth && <span>x</span>}
          {customHeight}
        </div>
      </div>
    );
  }
}

DecorationDimensions.propTypes = {
  decoration: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  decorationDimension: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
};

export default connect()(DecorationDimensions);
