import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { categoryForm } from '@constants/reduxForms';
import { getParentCategories } from '@redux/productCatalog/actions';
import { parseNumber } from '@util/numberHelpers';
import { mapToOptionsList } from '@util/mappingHelper';
import Input from '@sharedComponents/Form/Input';
import FormDropdown from '@sharedComponents/Form/FormDropdown';
import { validateCategory } from '@redux/categories/validations';

class CategoryForm extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getParentCategories());
  }

  render() {
    const {
      handleSubmit,
      parentCategories,
      initialValues,
      change,
    } = this.props;

    const parentCategoriesOptions = mapToOptionsList({
      list: parentCategories.map((parentCategory) => ({
        ...parentCategory,
        disabled: parentCategory.disabled,
      })),
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: 'Choose a parent category',
      },
    });

    return (
      <form
        className='redux-form'
        onSubmit={handleSubmit}
        id={categoryForm}
      >
        <div>
          <div className='redux-form__section pb-5'>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-xl'>
                <label className='redux-form__label'>
                Code
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'code'}
                  placeholder={'Enter Code'}
                  component={Input}
                  type={'text'}
                />
              </div>
            </div>

            <div className='redux-form__row'>
              <div className='redux-form__column--size-xl'>
                <label className='redux-form__label'>
                Name
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'name'}
                  placeholder={'Enter name'}
                  component={Input}
                  type={'text'}
                />
              </div>
            </div>

            <div className='redux-form__row'>
              <div className='redux-form__column--size-xl'>
                <label className='redux-form__label'>
                Sort Order
                </label>
                <Field
                  name={'sortOrder'}
                  placeholder={'Enter sort order'}
                  component={Input}
                  type={'number'}
                  parse={parseNumber}
                />
              </div>
            </div>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-xl'>
                <label className='redux-form__label'>
                Parent Category
                </label>
                <Field
                  name={'parentCategoryId'}
                  component={FormDropdown}
                  initialValue={initialValues && initialValues.parentCategoryId}
                  change={change}
                  options={parentCategoriesOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

CategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  change: PropTypes.func,
};
const mapStateToProps = ({ productCatalog }) => ({
  parentCategories: productCatalog.parentCategories,
});

export default connect(mapStateToProps)(reduxForm({
  form: categoryForm,
  validate: validateCategory,
  enableReinitialize: true,
  shouldError: () => true,
})(CategoryForm));
