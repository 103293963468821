import React from 'react';
import PropTypes from 'prop-types';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

const DtgStationsDeleteModal = React.memo(({
  isOpen,
  closeModal,
  selectedStation,
  onDelete,
}) => (
  <SimpleConfirmationModal
    isOpen={isOpen}
    confirm={onDelete.bind(null, selectedStation.id)}
    closeModal={closeModal}
    title={'Remove DTG Station'}
    confirmationBody={`Are you sure you want to remove the DTG Station ${selectedStation.stationDisplayName}?`}
  />
));

DtgStationsDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedStation: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DtgStationsDeleteModal;
