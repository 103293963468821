import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cancelPayout } from '@APICalls/fundraisingPayouts/actions';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import {
  getPayoutById,
  getLockersForPayout,
} from '@redux/fundraisingPayouts/actions';
import { materialSwal } from '@util/componentHelper';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import FundraisingPayoutsQuickViewInfo from './FundraisingPayoutsQuickViewInfo';
import FundraisingPayoutsQuickViewActions from './FundraisingPayoutsQuickViewActions';

class FundraisingPayoutsQuickView extends Component {
  componentDidMount() {
    const { payoutId } = this.props;

    this.refreshPayoutInfo(payoutId);
  }

  componentDidUpdate(prevProps) {
    const { payoutId } = this.props;

    if (payoutId !== prevProps.payoutId) {
      this.refreshPayoutInfo(payoutId);
    }
  }

  refreshPayoutInfo = (payoutId) => {
    const { dispatch } = this.props;

    dispatch(getPayoutById(payoutId));
    dispatch(getLockersForPayout(payoutId));
  };

  cancelPayout = async () => {
    const {
      payoutId,
      closeDetails,
      refresh,
    } = this.props;

    const res = await cancelPayout(payoutId);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      closeDetails();
      refresh();
    }
  };

  render() {
    const {
      payoutId,
      closeDetails,
      payout,
      payoutLockers,
    } = this.props;

    return (
      <TableQuickViewContainer
        noPadding={true}
        size={TableQuickViewSizeEnum.Medium}
      >
        <TableQuickViewHeader
          title={<span>Payout Request Details</span>}
          onClose={closeDetails}
        />

        <div className='tableView__details--content size--700'>
          <FundraisingPayoutsQuickViewInfo
            payout={payout}
            lockers={payoutLockers}
          />
          {
            false && // Hide cancel action for now
            <FundraisingPayoutsQuickViewActions
              payoutId={payoutId}
              cancelPayout={this.cancelPayout}
            />
          }
        </div>
      </TableQuickViewContainer>
    );
  }
}

FundraisingPayoutsQuickView.propTypes = {
  payoutId: PropTypes.number.isRequired,
  closeDetails: PropTypes.func.isRequired,
  payout: PropTypes.object.isRequired,
  payoutLockers: PropTypes.arrayOf(PropTypes.object).isRequired,
  refresh: PropTypes.func.isRequired,
};

const mapStateToProps = ({ fundraisingPayouts }) => ({
  payout: fundraisingPayouts.payout,
  payoutLockers: fundraisingPayouts.payoutLockers,
});

export default connect(mapStateToProps)(FundraisingPayoutsQuickView);
