import moment from 'moment-timezone';
import { VoucherOrderDetailDto } from '@api/financialServices/models';

export const hasActiveVouchers = (voucherOrders: VoucherOrderDetailDto[] | undefined): boolean => {
  if (!voucherOrders || !voucherOrders.length) return false;

  const allVoucherOrdersCanceled: boolean = areAllVoucherOrdersCanceled(voucherOrders);
  if (allVoucherOrdersCanceled) return false;

  const allVouchersCanceled: boolean = areAllVouchersCanceled(voucherOrders);
  if (allVouchersCanceled) return false;

  const allVouchersRedeemed: boolean = areAllVouchersRedeemed(voucherOrders);
  if (allVouchersRedeemed) return false;

  const allVoucherOrdersExpired: boolean = areAllVoucherOrdersExpired(voucherOrders);
  if (allVoucherOrdersExpired) return false;

  return true;
};

const areAllVouchersRedeemed = (voucherOrders: VoucherOrderDetailDto[]): boolean => (
  voucherOrders.every(allVouchersInVoucherOrderRedeemed)
);

const allVouchersInVoucherOrderRedeemed = (voucherOrder: VoucherOrderDetailDto): boolean => (
  (voucherOrder.totalInstanceCount! - voucherOrder.redeemedInstanceCount!) === 0
);

const areAllVouchersCanceled = (voucherOrders: VoucherOrderDetailDto[]): boolean => (
  voucherOrders.every(areAllVouchersInVoucherOrderCanceled)
);

const areAllVouchersInVoucherOrderCanceled = (voucherOrder: VoucherOrderDetailDto): boolean => (
  (voucherOrder.totalInstanceCount! - voucherOrder.canceledInstanceCount!) === 0
);

const areAllVoucherOrdersCanceled = (voucherOrders: VoucherOrderDetailDto[]): boolean => (
  voucherOrders.every(voucherOrderCanceled)
);

const voucherOrderCanceled = (voucherOrder: VoucherOrderDetailDto): boolean => (!!voucherOrder.cancellationId);

const areAllVoucherOrdersExpired = (voucherOrders: VoucherOrderDetailDto[]): boolean => (
  voucherOrders.every(voucherOrderExpired)
);

const voucherOrderExpired = (voucherOrder: VoucherOrderDetailDto): boolean => (
  moment.utc(voucherOrder.endDate).isBefore(moment(new Date()))
);
