import React from 'react';
import { BackorderDto } from '@api/fulfillment/models';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  orders: BackorderDto[];
  markOrders: () => void;
}

type Props = OwnProps;

const MarkOrdersAsNotificationSentModal = React.memo<Props>(({
  isOpen,
  closeModal,
  orders,
  markOrders,
}) => (
  <Modal
    title={'Mark Order(s) as "Message Sent"'}
    isOpen={isOpen}
    modalSize={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Mark'}
        cancelBtnText={'Cancel'}
        onConfirm={markOrders}
        onClose={closeModal}
      />
    )}
  >
    <h3 className='modal__description'>
      {
        orders.map((order) => (
          <div
            className='ml-10 mb-5'
            key={order.orderNumber}
          >
            <b>O{order.orderNumber}</b> - {(order.shippingInfo && order.shippingInfo.email) || '' }
          </div>
        ))
      }
    </h3>
  </Modal>
));

export default MarkOrdersAsNotificationSentModal;
