import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import {
  ColorDto,
  DecorationMethodEnum,
} from '@api/productCatalog/models';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import { productionAssemblyFlagItemForm } from '@constants/reduxForms';
import { FlaggingFormData } from '@models/forms/Production/FlaggingFormData';
import {
  Task,
  LogoTask,
  PersonalizationTask,
} from '@models/Production/ProductionAssembly/Tasks';
import { validateProductionAssemblyFlagItem } from '@redux/productionAssembly/validations';
import {
  getFlagReasonOptions,
  getFlagReasons,
} from '@util/flagHelper';
import MarkItemAsFlaggedModalLogoDetails from '@components/WeedingAndMasking/Flagging/MarkItemAsFlaggedModalLogoDetails';
import MarkItemAsFlaggedModalPersonalizationDetails from '@components/WeedingAndMasking/Flagging/MarkItemAsFlaggedModalPersonalizationDetails';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import RadioGroup from '@sharedComponents/Form/RadioGroup';

interface OwnProps {
  decorationMethod?: keyof typeof DecorationMethodEnum;
  isOpen: boolean;
  onSubmit: (form: FlaggingFormData) => Promise<void>;
  closeModal: () => void;
  task: Nullable<Task>;
  orderNumber: number;
  colorsDictionary: Record<string, ColorDto>;
}

type FormProps = OwnProps;

export type Props = FormProps & InjectedFormProps<FlaggingFormData, FormProps, string[]>;

const FlaggingModal = React.memo<Props>(({
  closeModal,
  task,
  orderNumber,
  colorsDictionary,
  isOpen,
  initialValues,
  change,
  handleSubmit,
  decorationMethod,
}) => {
  const [
    selectedOption,
    setSelectedOption,
  ] = useState(initialValues.flagType);

  const handleOptionChange = useCallback((changeEvent: any) => {
    const flagType = changeEvent.target.value;

    setSelectedOption(flagType);
    const reasons = getFlagReasons(flagType, decorationMethod);

    change('reason', reasons[0].reason);
  }, [
    decorationMethod,
    change,
  ]);

  const decorationIds = useMemo(() => task?.ids?.length ? task.ids : task?.id ? [task.id] : [], [
    task?.ids,
    task?.id,
  ]);

  if (!isOpen || !task) return null;

  return (
    <Modal
      title={'Flag Item'}
      modalHeight={'l'}
      modalWidth={'m'}
      isPadded={true}
      isOpen={isOpen}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Flag'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          isDangerousAction={true}
          formSubmission={true}
          formId={productionAssemblyFlagItemForm}
        />
      )}
    >
      <form
        className='flagging__form'
        onSubmit={handleSubmit}
        id={productionAssemblyFlagItemForm}
      >
        <Field
          name={'flagType'}
          component={RadioGroup}
          groupClassName={'flagging__form-radio-group'}
          onChange={handleOptionChange}
          radioItems={[
            {
              value: flagTypeEnum.Reprint,
              disabled: decorationMethod !== DecorationMethodEnum.HAG,
              label: 'Send to Reprint',
              className: 'ml-n10',
            },
            {
              value: flagTypeEnum.Rework,
              disabled: !(task as LogoTask).previewUrl,
              label: 'Send to Rework',
            },
            {
              value: flagTypeEnum.WrongArtwork,
              label: 'Send to Artwork Change',
            },
          ]}
        />

        <div className='flagging__form-group'>
          <Field
            name={'reason'}
            component={Select}
          >
            {getFlagReasonOptions(selectedOption, decorationMethod)}
          </Field>
        </div>
        <div className='flagging__form-group'>
          <div className='text-field flex'>
            <Field
              name={'note'}
              component={Input}
              type={'text'}
              placeholder={'Enter note*'}
            />
          </div>
        </div>
        {
          (task as LogoTask).logoUrl
            ? (
              <MarkItemAsFlaggedModalLogoDetails
                orderNumber={orderNumber}
                previewUrl={(task as LogoTask).previewUrl || (task as LogoTask).logoUrl!}
                size={task.decorationLocation ?? undefined}
                barcode={task.barcode ?? undefined}
                decorationIds={decorationIds}
              />
            )
            : (
              <MarkItemAsFlaggedModalPersonalizationDetails
                orderNumber={orderNumber}
                personalizationNumber={(task as PersonalizationTask).number ?? undefined}
                personalizationText={(task as PersonalizationTask).text ?? undefined}
                personalizationColor={colorsDictionary[(task as PersonalizationTask).colorId!]}
                size={task.decorationLocation ?? undefined}
              />
            )
        }
      </form>
    </Modal>
  );
});

export default (reduxForm<FlaggingFormData, FormProps, string[]>({
  form: productionAssemblyFlagItemForm,
  validate: validateProductionAssemblyFlagItem,
  enableReinitialize: true,
})(FlaggingModal));
