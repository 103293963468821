/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  ShippingOptionViewModel,
  ApiInternalServerError,
  BrandViewModel,
  TeamColorViewModel,
  ColorViewModel,
  ApiOkString,
  ApiBadRequest,
  SettingsViewModel
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getShippingOptions = <TData = ShippingOptionViewModel[]>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/utility/shippingOptions`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetShippingOptionsQueryKey = () => [`/utility/shippingOptions`]

    
export const useGetShippingOptions = <TQueryFnData = AsyncReturnType<typeof getShippingOptions, ShippingOptionViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetShippingOptionsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getShippingOptions<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getBrands = <TData = BrandViewModel[]>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/utility/brands`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetBrandsQueryKey = () => [`/utility/brands`]

    
export const useGetBrands = <TQueryFnData = AsyncReturnType<typeof getBrands, BrandViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetBrandsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getBrands<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getTeamColors = <TData = TeamColorViewModel[]>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/team-colors`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetTeamColorsQueryKey = () => [`/homefield/api/team-colors`]

    
export const useGetTeamColors = <TQueryFnData = AsyncReturnType<typeof getTeamColors, TeamColorViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetTeamColorsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getTeamColors<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getColors = <TData = ColorViewModel[]>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/colors`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetColorsQueryKey = () => [`/homefield/api/colors`]

    
export const useGetColors = <TQueryFnData = AsyncReturnType<typeof getColors, ColorViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetColorsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getColors<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getPersonalizations = <TData = string[]>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/personalizations`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetPersonalizationsQueryKey = () => [`/homefield/api/personalizations`]

    
export const useGetPersonalizations = <TQueryFnData = AsyncReturnType<typeof getPersonalizations, string[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetPersonalizationsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getPersonalizations<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const uploadImage = <TData = ApiOkString>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/images.upload`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUploadImage = <TData = AsyncReturnType<typeof uploadImage,ApiOkString>,
    TError = ApiBadRequest | ApiInternalServerError,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,TVariables, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, TVariables, TContext>(() => {
        ;

        return  uploadImage<TData>(requestOptions)
      }, mutationOptions)
    }
    export const getSettings = <TData = SettingsViewModel>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/utility/settings`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetSettingsQueryKey = () => [`/utility/settings`]

    
export const useGetSettings = <TQueryFnData = AsyncReturnType<typeof getSettings, SettingsViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetSettingsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getSettings<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

