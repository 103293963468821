/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type BatchWarehouseListOrderByEnum = 'OrderNumber' | 'ShipDate' | 'DatePlaced';


export const BatchWarehouseListOrderByEnum = {
  OrderNumber: 'OrderNumber' as BatchWarehouseListOrderByEnum,
  ShipDate: 'ShipDate' as BatchWarehouseListOrderByEnum,
  DatePlaced: 'DatePlaced' as BatchWarehouseListOrderByEnum,
};
