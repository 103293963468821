import React from 'react';
import Note from './Note';
import { Note as NoteDto } from '@models/OrderManagement/Note';

interface OwnProps {
  notes: Array<NoteDto>;
  loggedInUser: Nullable<string>;
  orderNumber: number;
}

type Props = OwnProps;

const OrderManagementSidebarNotes = React.memo<Props>(({
  notes,
  loggedInUser,
  orderNumber,
}) => {
  if (notes && notes.length > 0) {
    return (
      <ul className='notes custom-scrollbar'>
        {
          notes.map((note, i: number) => (
            <Note
              key={i}
              orderNumber={orderNumber}
              loggedInUser={loggedInUser}
              note={note}
            />
          ))
        }
      </ul>
    );
  }

  return <div />;
});

export default OrderManagementSidebarNotes;
