import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { organizationDetailsUrl } from '@constants/clientUrls/clientUrls';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import { fetchOrganizations } from '@redux/organizations/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import Link from '@sharedComponents/Navigation/Link';

const OrganizationsTable = Table();

class OrganizationsTab extends Component {
  state = {
    sortOrder: '',
    sortByState: '',
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrder);

    this.setState({
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }, () => this.refreshData({
      pageNumber: page + 1,
      pageSize,
    }));
  };

  refreshData = (optionsDict) => {
    const {
      dispatch,
      adminId,
    } = this.props;

    const {
      sortByState,
      sortOrder,
    } = this.state;

    const args = {
      ...this.props,
      ...optionsDict,
    };

    return dispatch(fetchOrganizations(
      args.pageNumber,
      args.pageSize,
      sortByState,
      sortOrder,
      '',
      '',
      '',
      '',
      adminId
    ));
  };

  getColumns = () => {
    const columns = [
      {
        Header: <HeaderCell text={'ID'} />,
        accessor: 'id',
        minWidth: 20,
        sortable: true,
        Cell: (cellProps) => cellProps.value && (
          <Link
            url={organizationDetailsUrl(cellProps.original.id)}
            text={`ORG${cellProps.value}`}
          />
        ),
      },
      {
        Header: <HeaderCell text={'Organization Name'} />,
        accessor: 'name',
        minWidth: 60,
        sortable: true,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Type',
        accessor: 'orgType',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Size',
        accessor: 'size',
        minWidth: 30,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Decision Maker',
        accessor: 'locker_manager_id',
        minWidth: 50,
        Cell: (cellProps) => (cellProps.original.lockerManagerFirstName && cellProps.original.lockerManagerLastName)
          ? `${cellProps.original.lockerManagerFirstName} ${cellProps.original.lockerManagerLastName}`
          : '',
      },
      {
        Header: <HeaderCell text={'Date Created'} />,
        accessor: 'createdAt',
        sortable: true,
        minWidth: 60,
        Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
      },
    ];

    return columns;
  };

  render() {
    const {
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    return (
      <div className='sheet'>
        <OrganizationsTable
          data={queue}
          columns={this.getColumns()}
          showPagination={hasNextPage || hasPreviousPage}
          totalPages={totalPages}
          onFetchData={this.fetchData}
        />
      </div>
    );
  }
}

OrganizationsTab.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  adminId: PropTypes.number.isRequired,
};

const mapStateToProps = ({ organizations }) => ({
  totalPages: organizations.organizationsList.totalPages,
  hasPreviousPage: organizations.organizationsList.hasPreviousPage,
  hasNextPage: organizations.organizationsList.hasNextPage,
  pageNumber: organizations.organizationsList.pageNumber,
  queue: organizations.organizationsList.queue,
  pageSize: organizations.organizationsList.pageSize,
});

export default connect(mapStateToProps)(OrganizationsTab);
