import React from 'react';
import { artVectorizationUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const ArtVectorizationLink = React.memo(() => (
  <MenuTile
    title={'Production Artwork'}
    url={artVectorizationUrl}
    icon={'palette'}
  />
));

export default ArtVectorizationLink;
