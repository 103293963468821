import { catalogApi } from '../../sqdApis';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { mapDecorationLocationArtworkSizeErrors } from '@util/componentHelpers/decorationLocationArtworkSizesHelper';

export const addDecorationLocationArtworkSize = async (addDecorationLocationArtworkSizeForm) => {
  const call = catalogApi.post(productCatalogUtilityUrls.decorationLocationArtworkSizes, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(addDecorationLocationArtworkSizeForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapDecorationLocationArtworkSizeErrors);

  return res;
};

export const editDecorationLocationArtworkSize = async (editDecorationLocationArtworkSizeForm) => {
  const call = catalogApi.put(
    productCatalogUtilityUrls.decorationLocationArtworkSize(editDecorationLocationArtworkSizeForm.id),
    {
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(editDecorationLocationArtworkSizeForm),
    }
  );

  const res = await makeApiCallWithSubmissionError(call, mapDecorationLocationArtworkSizeErrors);

  return res;
};

export const deleteDecorationLocationArtworkSize = async (decorationLocationArtworkSizeId) => {
  const call = catalogApi.delete(
    productCatalogUtilityUrls.decorationLocationArtworkSize(decorationLocationArtworkSizeId)
  );
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
