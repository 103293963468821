import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

const ArtworkTaskDetailsNext = React.memo(() => (
  <button
    type='button'
    className='details__arrow details__arrow--next'
  >
    <Icon materialIcon={'chevron_right'} />
  </button>
));

export default ArtworkTaskDetailsNext;
