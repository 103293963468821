import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import { infoTextTimeout } from '@constants/values';
import Icon from '@sharedComponents/Icons/Icon';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import LockerItemLabels from '../LockerItemLabels';

interface OwnProps {
  item: {
    name?: string | string[];
    code?: string;
    uid?: string;
    isCustomItem?: boolean;
    essential?: boolean;
    requiredItem?: boolean;
    logos?: Array<any>;
    collectionsList: {
      id: number;
      name: string;
    }[];
  };
  showLabels?: boolean;
  showCopyUuidButton?: boolean;
}

type Props = OwnProps;

const TitleDescriptionCell = React.memo(({
  item,
  showLabels = false,
  showCopyUuidButton = false,
}: Props) => {
  const [
    tooltipText,
    setTooltipText,
  ] = useState<string>('Copy UUID');

  useEffect(() => {
    if (Object.keys(tooltipText).length === 0) return;

    // Remove copy text after the amount of time has passed
    setTimeout(() => {
      setTooltipText('Copy UUID');
    }, infoTextTimeout);
  }, [tooltipText]);

  const copyUuidToClipboard = useCallback((product) => {
    navigator.clipboard.writeText(product?.uuid);
    setTooltipText('Copied!');
  }, []);

  return (
    <div className='row'>
      {showCopyUuidButton && (
        <div className='col-md-1'>
          <MaterialTooltip
            tooltipText={tooltipText}
            placement={'bottom'}
          >
            <Icon
              materialIcon={'content_copy'}
              onClick={copyUuidToClipboard.bind(null, item)}
              color={'rgb(2, 148, 135, 0.8)'}
              backgroundColor={'#ffffff'}
            />
          </MaterialTooltip>
        </div>
      )
      }
      <div className='col-md-6'>
        <div className='align__center'>
          <h3 className='lockerManager__code'>{item.code ? item.code : '-'}</h3>
          {
            showLabels &&
            <LockerItemLabels item={item} />
          }
        </div>
        {
          Array.isArray(item.name)
            ? item.name.map((name) => (
              <div
                key={name}
                className='lockerManager__description'
              >{name}
              </div>
            ))
            : <span className='lockerManager__description'>{item.name ?? ''}</span>
        }
      </div>
    </div>
  );
});

export default TitleDescriptionCell;
