import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
  reduxForm,
} from 'redux-form';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import { parseNumber } from '@util/numberHelpers';
import { connect } from 'react-redux';
import { dtgSettingsForm } from '@constants/reduxForms';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import FormError from '@sharedComponents/Form/FormError';
import {
  generalOptions,
  createDecorationLocationOptions,
} from '@util/optionsMap';
import { sortByField } from '@util/sortingHelpers';
import { getDecorationSizeOptions } from '@constants/options/options';
import { validateDtgPersonalizationSettings } from '@redux/productCatalog/validations';

const DtgPersonalizationSettingsForm = React.memo((props) => {
  const {
    error,
    handleSubmit,
    decorationLocations,
    formMode,
  } = props;

  const decLocs = decorationLocations.sort(sortByField('code'));
  const decLocOptions = [
    <option
      key={0}
      value=''
    >
      N/A
    </option>,
    ...createDecorationLocationOptions(decLocs),
  ];
  const sizeOptions = generalOptions(getDecorationSizeOptions(true, 'N/A'));

  return (
    <Form
      className='redux-form'
      onSubmit={handleSubmit}
      id={dtgSettingsForm}
    >
      <div>
        <GridContainer className='redux-form__section'>
          <Row className='redux-form__row'>
            <Col md={6}>
              <label className='redux-form__label'>
                Decoration Location
              </label>
              <Field
                name={'decorationLocationId'}
                component={Select}
                type={'number'}
                parse={parseNumber}
                disabled={formMode === 'edit'}
              >
                {decLocOptions}
              </Field>
            </Col>
            <Col md={6}>
              <label className='redux-form__label'>
                Decoration Size
              </label>
              <Field
                name={'decorationSize'}
                component={Select}
                type={'string'}
                disabled={formMode === 'edit'}
              >
                {sizeOptions}
              </Field>
            </Col>
          </Row>
          <Row className='redux-form__row'>
            <Col md={4}>
              <label className='redux-form__label'>
                Rotation
              </label>
              <Field
                name={'rotation'}
                placeholder={'0.000'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={4}>
              <label className='redux-form__label'>
                Position X
              </label>
              <Field
                name={'posX'}
                placeholder={'0.000'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={4}>
              <label className='redux-form__label'>
                Position Y
              </label>
              <Field
                name={'posY'}
                placeholder={'0.000'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
          </Row>
          <Row className='redux-form__row'>
            <Col md={6}>
              <label className='redux-form__label'>
                Override Default Name Width
              </label>
              <Field
                name={'printerOverrideNameWidth'}
                placeholder={'0.000'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={6}>
              <label className='redux-form__label'>
                Override Default Name Height
              </label>
              <Field
                name={'printerOverrideNameHeight'}
                placeholder={'0.000'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
          </Row>
          <Row className='redux-form__row'>
            <Col md={6}>
              <label className='redux-form__label'>
                Override Default Number Height w/ Name
              </label>
              <Field
                name={'printerOverrideNumberHeightWithName'}
                placeholder={'0.000'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={6}>
              <label className='redux-form__label'>
                Override Default Height w/o Name
              </label>
              <Field
                name={'printerOverrideNumberHeightWithoutName'}
                placeholder={'0.000'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
          </Row>
        </GridContainer>
        <FormError error={error} />
      </div>
    </Form>
  );
});

DtgPersonalizationSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  decorationLocations: PropTypes.array.isRequired,
  formMode: PropTypes.string.isRequired,
};

export default connect()(reduxForm({
  form: dtgSettingsForm,
  validate: validateDtgPersonalizationSettings,
  enableReinitialize: true,
  shouldError: () => true,
})(DtgPersonalizationSettingsForm));
