import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const ColorCircularProgress = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    color: '#029487',
  },
}))(CircularProgress);

interface OwnProps {
  size?: string;
  thickness?: number;
}

type Props = OwnProps;

const Spinner = React.memo(({
  size = '70px',
  thickness = 4.5,
}: Props) => (
  <ColorCircularProgress
    size={size}
    thickness={thickness}
    color='inherit'
    disableShrink={true}
  />
));

export default Spinner;
