import {
  useGetHomefieldApiHagPrintroomorderdetails, usePutHomefieldApiHagPrintroomupdatenotes,
} from '@api/fulfillment/hag';
import { HagPrintRoomJobStatusEnum } from '@api/fulfillment/models/hagPrintRoomJobStatusEnum';
import useHeaderContext from '@components/shared/Contexts/HeaderContext';
import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  RouteComponentProps,
  useParams,
  withRouter,
} from 'react-router-dom';
import PrintRoomOrderActions from './PrintRoomOrderActions';
import PrintRoomOrderHeader from './PrintRoomOrderHeader';
import PrintRoomOrderLogos from './PrintRoomOrderLogos';
import PrintRoomOrderPersonalizations from './PrintRoomOrderPersonalizations';
import PrintRoomOrderRejectModal from './PrintRoomOrderReject/PrintRoomOrderRejectModal';
import LogoPreviewModal from '@sharedComponents/Modal/LogoPreviewModal';
import { materialSwal } from '@util/componentHelper';

const connector = connect(null);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;
type Params = { orderNumber: string; };

const printStatusList = [
  {
    id: HagPrintRoomJobStatusEnum.PrintReady,
    label: 'Ready to Print',
  },
  {
    id: HagPrintRoomJobStatusEnum.Printing,
    label: 'In Print',
  },
  {
    id: HagPrintRoomJobStatusEnum.Printed,
    label: 'Printed',
  },
  {
    id: HagPrintRoomJobStatusEnum.Assembling,
    label: 'In Print Assembly',
  },
  {
    id: HagPrintRoomJobStatusEnum.Assembled,
    label: 'Done',
  },
] as const;

const PrintRoomOrder = React.memo<Props>(() => {
  const [
    orderNumber,
    setOrderNumber,
  ] = useState<number>(0);

  const [
    isRejectModalOpened,
    setIsRejectModalOpened,
  ] = useState<boolean>(false);

  const [
    imageUrl,
    setImageUrl,
  ] = useState<string>('');

  const [
    previewModalIsOpen,
    setPreviewModalIsOpen,
  ] = useState<boolean>(false);

  const { headerMutator } = useHeaderContext<HagPrintRoomJobStatusEnum>();

  const { mutateAsync: updatePrintRoomOrderNotes }
    = usePutHomefieldApiHagPrintroomupdatenotes();

  const params = useParams<Params>();

  const {
    data: printRoomOrderDetails,
    refetch: fetchPrintRoomOrderDetails,
  } = useGetHomefieldApiHagPrintroomorderdetails({
    orderNumber,
  });

  useEffect(() => {
    fetchPrintRoomOrderDetails();
  }, [
    fetchPrintRoomOrderDetails,
    orderNumber,
  ]);

  const handleRefetch = useCallback(async () => {
    await fetchPrintRoomOrderDetails();
  }, [fetchPrintRoomOrderDetails]);

  useEffect(() => {
    if (!Number.isNaN(Number(params.orderNumber))) {
      setOrderNumber(Number(params.orderNumber));
    }
  }, [params]);

  useEffect(() => {
    if (printRoomOrderDetails?.orderNumber) {
      headerMutator.setTitle(`Order O${printRoomOrderDetails.orderNumber}`);
      headerMutator.setStatuses(printRoomOrderDetails.status!, printStatusList);
    } else {
      headerMutator.setTitle(undefined);
    }

    if (printRoomOrderDetails?.isCanceled) {
      headerMutator.setLabel({
        text: 'Canceled',
        color: 'status-darkred',
      });
    } else {
      headerMutator.setLabel(undefined);
    }
  }, [
    headerMutator,
    printRoomOrderDetails?.orderNumber,
    printRoomOrderDetails?.status,
    printRoomOrderDetails?.isCanceled,
  ]);

  const openRejectModal = useCallback(() => {
    setIsRejectModalOpened((current) => !current);
  }, []);

  const closeRejectModal = useCallback(() => {
    setIsRejectModalOpened((current) => !current);
  }, []);

  const openLogoPreviewModal = useCallback((url: string) => {
    setImageUrl(url);
    setPreviewModalIsOpen(true);
  }, []);

  const closeLogoPreviewModal = useCallback(() => {
    setImageUrl('');
    setPreviewModalIsOpen(false);
  }, []);

  const handleUpdateNotes = useCallback(async (on: number | undefined, notes: string | null | undefined) => {
    const res = await updatePrintRoomOrderNotes({
      data: {
        orderNumber: on,
        notes,
      },
    });

    if (res.success) {
      await materialSwal('Order Print Room Notes', res.message ?? 'Notes Successfuly Updated', 'success');
    } else {
      await materialSwal('Order Print Room Notes Failures', res.message ?? 'Error unknown', 'error');
    }

    handleRefetch();
  }, [
    handleRefetch,
    updatePrintRoomOrderNotes,
  ]);

  return (
    <div className='container'>
      <div className='sheet'>
        <PrintRoomOrderActions
          openRejectModal={openRejectModal}
          details={printRoomOrderDetails}
          refetch={handleRefetch}
          orderNumber={params.orderNumber}
        />
        <PrintRoomOrderHeader
          orderNumber={printRoomOrderDetails?.orderNumber}
          isExpressProduction={printRoomOrderDetails?.isExpressProduction}
          isRush={printRoomOrderDetails?.isRush}
          shipDate={printRoomOrderDetails?.shipDate}
          lockerId={printRoomOrderDetails?.lockerId}
          organizationId={printRoomOrderDetails?.organizationId}
          logoCount={printRoomOrderDetails?.logoCount}
          personalizationCount={printRoomOrderDetails?.personalizationCount}
          notes={printRoomOrderDetails?.notes}
          onUpdateNotes={handleUpdateNotes}
        />
        <PrintRoomOrderLogos
          logos={printRoomOrderDetails?.logos}
          openLogoPreviewModal={openLogoPreviewModal}
        />
        <PrintRoomOrderPersonalizations personalizations={printRoomOrderDetails?.personalizations} />
        <PrintRoomOrderRejectModal
          orderNumber={orderNumber}
          data={printRoomOrderDetails?.logos ?? []}
          isOpen={isRejectModalOpened}
          onClose={closeRejectModal}
        />
        <LogoPreviewModal
          modalIsOpen={previewModalIsOpen}
          closeModal={closeLogoPreviewModal}
          imageUrl={imageUrl}
        />
      </div>
    </div>
  );
});

export default connector(withRouter(PrintRoomOrder));
