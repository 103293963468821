import React from 'react';
import moment from 'moment';
import {
  OrderItemStatusEnumItemStatusModel,
  DecorationStatusEnumItemStatusModel,
  ProductionAssemblyItemGroupDto,
} from '@api/fulfillment/models';
import {
  itemStatusEnum,
  lineItemStatusEnum,
  flagStatusEnum,
} from '@constants/enums/orderEnums';
import { StatusHistory } from '@models/Production/ProductionAssembly/StatusHistory';
import { parseDateTimeNumeric } from '@util/dateHandler';

type Status = OrderItemStatusEnumItemStatusModel
| DecorationStatusEnumItemStatusModel;

const flagStatuses = [
  flagStatusEnum.Unflagged,
  flagStatusEnum.Flagged,
] as const;

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  statusHistory: StatusHistory;
}

type Props = OwnProps;

const ReviewItemHistory = React.memo<Props>(({
  item,
  statusHistory,
}) => {
  let itemHistory = null;

  if (statusHistory) {
    const filteredStatuses = (statusHistory as Status[])
      .filter((el, i: number, arr) => i === arr.findIndex((a) => a.status === el.status && a.created === el.created));

    const sortedStatuses = filteredStatuses.sort(
      (a, b) => moment(b.created ?? undefined).diff(moment(a.created ?? undefined))
    );

    itemHistory = sortedStatuses.map((s) => {
      let labelColor = 'green';
      let showCheck = true;
      let label: string = item.orderItemIds?.includes(s.id!)
        ? itemStatusEnum[s.status as (keyof typeof itemStatusEnum)]
        : lineItemStatusEnum[s.status as (keyof typeof lineItemStatusEnum)];

      if (flagStatuses.includes(s.status as (keyof typeof flagStatusEnum))) {
        labelColor = 'red';
        showCheck = false;
        label = s.label!;
      }

      return (
        <div
          key={s.id}
          className='history__item'
        >
          <div className={`history__item--action ${labelColor}`}>
            <span>{label}</span>
            {showCheck && <i className='material-icons'>check</i>}
          </div>
          <div className='history__item--data'>
            <span>{s.user}</span>
            <span>{parseDateTimeNumeric(s.created)}</span>
          </div>
        </div>
      );
    });
  }

  return (
    <div className='order-assembly__history'>
      <div className='order-assembly__history-title'>
        <span>Item History</span>
      </div>
      <div className='order-assembly__history-items custom-scrollbar--horizontal'>
        {itemHistory}
      </div>
    </div>
  );
});

export default ReviewItemHistory;
