import React from 'react';
import { useGetHomefieldApiQueryRunStoreNameReportName } from '@api/reportEngine/query';
import { useParams } from 'react-router-dom';
import Table from '@components/shared/Table/Table';
import Button from '@components/shared/Buttons/Button';
import { convert2dArrayToCsv, downloadFromLink } from '@util/componentHelper';
import moment from 'moment';

const ReportTable = Table<string[]>();

const ReportsRun = React.memo(() => {
  const { storeName, reportName } = useParams<{ storeName: string; reportName: string; }>();

  const {
    data,
    isLoading,
    refetch,
  } = useGetHomefieldApiQueryRunStoreNameReportName(storeName, reportName, {}, {
    query: {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  });

  const columns = React.useMemo(() => {
    if (!data?.columnInfos) {
      return [];
    }

    return data.columnInfos.map((columnInfo, i) => ({
      Header: columnInfo.columnName ?? '',
      id: '',
      accessor: '',
      minWidth: 200,
      Cell: ({ original }: { original: string[]; }) => original[i],
    }));
  }, [data]);

  const reportData = React.useMemo(() => data?.reportData ?? [], [data]);

  const handleDownload = React.useCallback(() => {
    const columnNames = columns.map((column) => column.Header);
    const csvContent = convert2dArrayToCsv(columnNames, reportData);
    const timestamp = moment().format('YYYY-MM-DD-HH-mm-ss');
    downloadFromLink(csvContent, `${storeName}_${reportName}_${timestamp}.csv`, 'text/csv');
  }, [columns, reportData, reportName, storeName]);

  return (
    <div className='container'>
      <div className='scheduling__container'>
        <div className='flex'>
          <h1>{isLoading ? 'Loading...' : `Report for ${storeName}/${reportName}`}</h1>
        </div>
        <div>
          <Button
            type={'primary'}
            text={'Download CSV'}
            onClick={handleDownload}
            disabled={!reportData.length}
            classes={'ml-10'}
          />
        </div>
      </div>
      <br />
      <div className='scheduling__container'>
        <div className='table-options w-200'>
          <div className='text-field margin-left'>
            <ReportTable
              data={reportData}
              columns={columns}
              showPagination={false}
              onFetchData={refetch}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ReportsRun;
