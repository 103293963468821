import React, { useCallback } from 'react';

interface OwnProps {
  logoUrl: string;
  openLogoPreviewModal: (url: string) => void;
}

type Props = OwnProps;

const LogoCell = React.memo<Props>((
  {
    logoUrl,
    openLogoPreviewModal,
  }
) => {
  const openPreviewModal = useCallback((e) => {
    e.preventDefault();
    openLogoPreviewModal(logoUrl);
  }, [
    openLogoPreviewModal,
    logoUrl,
  ]);

  return (
    <div>
      <a
        className='sheet__list-table-thumbnail cursor-pointer'
        onClick={openPreviewModal}
        style={{ backgroundImage: `url("${logoUrl}")` }}
      />
    </div>
  );
});

export default LogoCell;
