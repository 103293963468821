import React from 'react';
import { adminsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const AdminsLink = React.memo(() => (
  <MenuTile
    title={'Admins'}
    url={adminsUrl}
    icon={'people'}
  />
));

export default AdminsLink;
