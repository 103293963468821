import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colorBrightnessEnum } from '@constants/enums/decorationEnums';
import ColorListItem from './ColorListItem';

class ColorListSelect extends Component {
  closeDropdown = () => {
    this.setState({ dropIsActive: false });
  };

  render() {
    const {
      allColors,
      selectedColors,
      toggleSelectColor,
      setHoveredColor,
    } = this.props;

    const selectedColorIds = selectedColors.map((c) => c.id);

    const options = allColors
      .map((c, index) => (
        <ColorListItem
          key={index}
          color={c}
          handleSelect={toggleSelectColor}
          isSelected={selectedColorIds.includes(c.id)}
          isDarkColor={c.brightness === colorBrightnessEnum.Dark}
          setHoveredColor={setHoveredColor}
        />
      ));

    return (
      <div className='logo-bank__upload-modal--color-list-select'>
        <div className='color-dropdown__select'>
          <div className='color-dropdown__options is-active w-100'>
            <ul className='color-dropdown__options--list custom-scrollbar'>
              {options}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

ColorListSelect.propTypes = {
  allColors: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedColors: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleSelectColor: PropTypes.func.isRequired,
  setHoveredColor: PropTypes.func.isRequired,
};

export default ColorListSelect;
