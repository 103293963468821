import React from 'react';
import ChangeLogItem from '@components/shared/ChangeLog/ChangeLogItem';
import { ChangeLogItemDto } from '@api/fulfillment/models';

interface OwnProps {
  log: Array<ChangeLogItemDto>;
}

type Props = OwnProps;

const OrderManagementSidebarChangeLog = React.memo<Props>(({ log }) => {
  let changeLogs: string | JSX.Element[] = '';

  if (log) {
    changeLogs = log.map((logItem, key) => (
      <ChangeLogItem
        key={key}
        dateTime={logItem.date!}
        message={logItem.message}
        user={logItem.user}
      />
    ));
  }

  return (
    <div className='order__log-change custom-scrollbar'>
      {changeLogs}
    </div>
  );
});

export default OrderManagementSidebarChangeLog;
