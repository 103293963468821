import * as actionTypes from './types';

const initialState = {
  organizationsList: {
    totalPages: 1,
    pageNumber: 1,
    pageSize: 10,
    queue: [],
    totalCount: 0,
    hasNextPage: null,
    hasPreviousPage: null,
  },
  organization: {},
  organizationTotals: {},
  organizationLockers: [],
  featuredLockers: [],
  groups: [],
  groupLockers: {},
  organizationSuggestions: [],
  lockerSuggestions: [],
};

export default function organizations(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ORGANIZATIONS_TABLE:
      return {
        ...state,
        organizationsList: action.data,
      };
    case actionTypes.UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: action.data,
      };
    case actionTypes.UPDATE_ORGANIZATION_SUGGESTIONS:
      return {
        ...state,
        organizationSuggestions: action.data,
      };
    case actionTypes.UPDATE_LOCKER_SUGGESTIONS:
      return {
        ...state,
        lockerSuggestions: action.data,
      };
    case actionTypes.UPDATE_ORGANIZATION_GROUPS:
      return {
        ...state,
        groups: action.data,
      };
    case actionTypes.UPDATE_ORGANIZATION_LOCKERS:
      return {
        ...state,
        organizationLockers: action.data,
      };
    case actionTypes.UPDATE_FEATURED_LOCKERS:
      return {
        ...state,
        featuredLockers: action.data,
      };
    case actionTypes.UPDATE_ORGANIZATION_GROUP_LOCKERS:
      return {
        ...state,
        groupLockers: {
          ...state.groupLockers,
          [action.data.groupId]: action.data.lockers,
        },
      };
    case actionTypes.UPDATE_ORGANIZATION_TOTALS:
      return {
        ...state,
        organizationTotals: action.data,
      };
    default:
      return state;
  }
}
