import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchOrganizationLockers } from '@redux/organizations/actions';
import { maxOrganizationFeaturedLockers } from '@constants/values';
import { organizationLockersSortColumnsEnum } from '@constants/enums/organizationEnums';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import {
  toggleItemSelection,
  isSelectedItem,
} from '@util/selectionHelpers';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import LockerRow from '../LockerRow';
import SortColumnTitle from '../SortColumnTitle';

class EditFeaturedLockersModal extends Component {
  state = {
    featuredLockers: this.props.featuredLockers || [],
    sortColumn: '',
    sortDirection: '',
  };

  componentDidUpdate(prevProps) {
    const { featuredLockers } = this.props;

    if (!prevProps.featuredLockers || prevProps.featuredLockers !== featuredLockers) {
      this.setState({ featuredLockers });
    }
  }

  toggleSelectLocker = (locker) => {
    const { featuredLockers } = this.state;

    this.setState({ featuredLockers: toggleItemSelection(featuredLockers, locker, 'id') });
  };

  isSelectingDisabled = () => {
    const { featuredLockers } = this.state;

    return featuredLockers.length >= maxOrganizationFeaturedLockers;
  };

  onFilterLockers = async (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const {
      organizationId,
      dispatch,
    } = this.props;

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);

    await dispatch(fetchOrganizationLockers(organizationId, searchInput));
  };

  sort = async (selectedSortColumn) => {
    const {
      sortColumn,
      sortDirection,
      searchInput,
    } = this.state;

    const newSortColumn = selectedSortColumn;
    let newSortDirection = sortDirectionShortEnum.Asc;

    if (sortColumn === selectedSortColumn) {
      // Toggle sort direction if needed
      if (sortDirection === sortDirectionShortEnum.Asc) {
        newSortDirection = sortDirectionShortEnum.Desc;
      }
    }

    this.setState({
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    });

    const {
      organizationId,
      dispatch,
    } = this.props;

    await dispatch(fetchOrganizationLockers(organizationId, searchInput, newSortColumn, newSortDirection));
  };

  handleCloseModal = () => {
    this.setState({
      searchInput: '',
      sortColumn: '',
      sortDirection: '',
    });

    const { closeModal } = this.props;
    closeModal();
  };

  handleUpdateFeaturedLockers = () => {
    const { updateFeaturedLockers } = this.props;
    const { featuredLockers } = this.state;
    const availableOrderPlaces = [];

    for (let i = 1; i <= maxOrganizationFeaturedLockers; i++) {
      if (!featuredLockers.find((locker) => locker.orderPlace === i)) {
        availableOrderPlaces.push(i);
      }
    }

    for (const locker of featuredLockers) {
      if (!locker.orderPlace) {
        locker.orderPlace = availableOrderPlaces.shift();
      }
    }

    updateFeaturedLockers(featuredLockers);
  };

  render() {
    const {
      isOpen,
      allLockers,
    } = this.props;

    const {
      featuredLockers,
      sortColumn,
      sortDirection,
    } = this.state;

    return (
      <Modal
        title={'Edit Featured Stores'}
        isOpen={isOpen}
        modalSize={'l'}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onConfirm={this.handleUpdateFeaturedLockers}
            onClose={this.handleCloseModal}
          />
        )}
      >
        <div>
          {
            allLockers && allLockers.length > 0
              ? (
                <div className='order__personalization-modal'>
                  <div className='tableView__details--header--title mb-30'>
                    <span>Stores in Organization</span>
                  </div>
                  <SearchFilter
                    initialValue={''}
                    search={this.onFilterLockers}
                    placeholder={'Enter Store ID or Name'}
                    classes={'mb-20'}
                  />
                  <div className='organization__lockers-list__item title'>
                    <div className='organization__lockers-list__item--logo'>Logo</div>
                    <div className='align--center ml-30 w-20'>
                      <SortColumnTitle
                        text={'ID'}
                        columnName={organizationLockersSortColumnsEnum.id}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        sort={this.sort.bind(null, organizationLockersSortColumnsEnum.id)}
                      />
                    </div>
                    <div className='w-20'>
                      <SortColumnTitle
                        text={'Store Name'}
                        columnName={organizationLockersSortColumnsEnum.teamName}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        sort={this.sort.bind(null, organizationLockersSortColumnsEnum.teamName)}
                      />
                    </div>
                    <div className='align--right ml-40 w-30'>Feature Store ({featuredLockers.length}/{maxOrganizationFeaturedLockers})</div>
                  </div>
                  <div className='organization__lockers-list custom-scrollbar'>
                    {
                      allLockers.map((locker) => (
                        <LockerRow
                          key={locker.id}
                          locker={locker}
                          onCheck={this.toggleSelectLocker}
                          checked={isSelectedItem(featuredLockers, locker, 'id')}
                          disabled={!isSelectedItem(featuredLockers, locker, 'id') && this.isSelectingDisabled()}
                        />
                      ))
                    }
                  </div>
                </div>
              )
              : <div>No stores available</div>
          }
        </div>
      </Modal>
    );
  }
}

EditFeaturedLockersModal.propTypes = {
  updateFeaturedLockers: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  featuredLockers: PropTypes.array.isRequired,
  allLockers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      teamName: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = ({ organizations }) => ({
  allLockers: organizations.organizationLockers,
});

export default connect(mapStateToProps)(EditFeaturedLockersModal);
