import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dragAndDropMessage } from '@constants/values';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';

class FileUploader extends Component {
  state = {
    message: {
      type: 'default',
      body: dragAndDropMessage(),
    },
    loading: false,
    attachedFile: null,
  };

  destroyFiles = (files) => {
    for (const file of files) {
      window.URL.revokeObjectURL(file.preview);
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      message: {
        body: `Attach ${acceptedFiles[0].name}?`,
        type: 'default',
      },
    });
    this.addFileToState(acceptedFiles[0]);

    const files = acceptedFiles.concat(rejectedFiles);
    this.destroyFiles(files);
  };

  addFileToState = (file) => {
    this.setState({ attachedFile: file });
  };

  clearFileFromState = () => {
    this.setState({
      attachedFile: null,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
    });
  };

  onAddFile = async () => {
    const { attachedFile } = this.state;
    const { addFile } = this.props;

    const file = attachedFile || null;
    await addFile(file);

    this.clearFileFromState();
  };

  render() {
    const {
      addBtnText,
      addBtnType,
      acceptedFileTypes,
    } = this.props;

    const {
      loading,
      message,
      attachedFile,
    } = this.state;

    const accept = acceptedFileTypes || undefined;

    return (
      <div className={'mb-10 p-l-16 p-r-16 lockerEdit__information-change-logos is-active'}>
        <div className='upload-field--horizontal m-t-16 w-100'>
          <DropZoneWrapper
            onDrop={this.onDrop}
            className={'upload-field'}
            accept={accept}
          >
            {
              loading
                ? (
                  <label htmlFor='uploadFiles'>
                    <Icon
                      fontAwesomeIcon={'spinner'}
                      classes={'fa-spin'}
                    />
                    Uploading...
                  </label>
                )
                : <label htmlFor='uploadFiles'>{message.body}</label>
            }
          </DropZoneWrapper>
          <Button
            type={addBtnType}
            text={addBtnText}
            onClick={this.onAddFile}
            disabled={!attachedFile}
          />
        </div>
      </div>
    );
  }
}

FileUploader.propTypes = {
  addFile: PropTypes.func.isRequired,
  addBtnText: PropTypes.string,
  addBtnType: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
};

FileUploader.defaultProps = {
  addBtnText: 'Add',
  addBtnType: 'primary',
  acceptedFileTypes: null,
};

export default FileUploader;
