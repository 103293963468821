import { createOptionsList } from '@util/optionsMap';

export const designLocationMeasureFromOptionsRaw = [
  {
    value: 'top',
    label: 'Top',
  },
  {
    value: 'bottom',
    label: 'Bottom',
  },
  {
    value: 'center',
    label: 'Center',
  },
];

export const designLocationMeasureFromOptions = createOptionsList({
  list: designLocationMeasureFromOptionsRaw,
  key: 'value',
  value: 'value',
  name: 'label',
});
