import React from 'react';
import PropTypes from 'prop-types';
import ArtworkTaskNoteAttachment from './ArtworkTaskNoteAttachment';

const ArtworkTaskNoteBottom = React.memo(({
  fileName,
  fileUrl,
}) => (
  fileUrl
    ? (
      <ArtworkTaskNoteAttachment
        fileUrl={fileUrl}
        fileName={fileName}
      />
    )
    : ''
));

ArtworkTaskNoteBottom.propTypes = {
  fileName: PropTypes.string,
  fileUrl: PropTypes.string,
};

export default ArtworkTaskNoteBottom;
