import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchInventoryItems } from '@redux/onHandInventory/actions';
import {
  getBrands,
  getCategories,
  getSizes,
  getAges,
  getColorGroups,
  getGenders,
} from '@redux/productCatalog/actions';
import OnHandInventoryTable from './OnHandInventoryTable';

class OnHandInventory extends Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchInventoryItems());
    dispatch(getColorGroups());
    dispatch(getAges());
    dispatch(getGenders());
    dispatch(getSizes());
    dispatch(getBrands());
    dispatch(getCategories());
  }

  render() {
    const {
      items,
      sizes,
      brands,
      categories,
      colorGroups,
      ages,
      genders,
    } = this.props;

    return (
      <div className='container master-detail'>
        <OnHandInventoryTable
          items={items}
          sizes={sizes}
          brands={brands}
          categories={categories}
          colorGroups={colorGroups}
          productAges={ages}
          productGenders={genders}
        />
      </div>
    );
  }
}

OnHandInventory.propTypes = {
  items: PropTypes.array.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  ages: PropTypes.arrayOf(PropTypes.string).isRequired,
  genders: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({
  onHandInventory,
  productCatalog,
}) => ({
  items: onHandInventory.products.items,
  sizes: productCatalog.sizes,
  brands: productCatalog.brands,
  categories: productCatalog.categories,
  colorGroups: productCatalog.colorGroups,
  ages: productCatalog.ages,
  genders: productCatalog.genders,
});

export default connect(mapStateToProps)(OnHandInventory);
