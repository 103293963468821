export const productionAssembly = 'homefield/api/productionassembly' as const;

/* Orders */
export const orders = `${productionAssembly}/orders` as const;
export const ordersAction = (action: string = ''): string => action ? `${orders}.${action}` : orders;

export const orderByOrderNumber = (orderNumber: number): string => `${orders}/${orderNumber}`;
export const orderByOrderNumberAction = (orderNumber: number, action: string = ''): string => `${action ? ordersAction(action) : orders}/${orderNumber}`;

export const searchOrder = (searchQuery: string): string => `${ordersAction('search')}?searchQuery=${searchQuery}`;

export const orderStatus = (orderNumber: number, status: string): string => orderByOrderNumberAction(orderNumber, `status-${status}`);
export const orderShippingReady = (orderNumber: number): string => orderStatus(orderNumber, 'shipping-ready');

/* Order items */
export const orderItems = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/order-items`;
export const orderItemsAction = (orderNumber: number, action: string): string => `${orderByOrderNumber(orderNumber)}/order-items.${action}`;
export const orderItemsStatus = (orderNumber: number, status: string): string => `${orderItems(orderNumber)}.status-${status}`;

export const orderItemsCheckedIn = (orderNumber: number): string => orderItemsStatus(orderNumber, 'checked-in');
export const orderItemsQualityChecked = (orderNumber: number): string => orderItemsStatus(orderNumber, 'quality-checked');
export const orderItemsPreTreated = (orderNumber: number): string => orderItemsStatus(orderNumber, 'pre-treated');
export const orderItemsMarkWaterproofed = (orderNumber: number): string => orderItemsAction(orderNumber, 'mark-waterproofed');
export const orderItemsUnMarkWaterproofed = (orderNumber: number): string => orderItemsAction(orderNumber, 'unmark-waterproofed');
export const orderItemsStatuses = (orderNumber: number): string => `${orderItems(orderNumber)}/statuses`;

/* Order line items */
export const orderLineItems = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/order-line-items`;
export const orderLineItemsAction = (orderNumber: number, action: string = ''): string => action ? `${orderLineItems(orderNumber)}.${action}` : orderLineItems(orderNumber);

export const orderLineItemsStatuses = (orderNumber: number): string => `${orderLineItems(orderNumber)}/statuses`;
export const orderLineItemsFlags = (orderNumber: number): string => `${orderLineItems(orderNumber)}/flags`;

export const orderLineItemsStatus = (orderNumber: number, status: string): string => `${orderLineItems(orderNumber)}.status-${status}`;
export const orderLineItemsCheckedIn = (orderNumber: number): string => orderLineItemsStatus(orderNumber, 'checked-in');
export const orderLineItemsDecorated = (orderNumber: number): string => orderLineItemsStatus(orderNumber, 'decorated');

export const flagOrderLineItems = (orderNumber: number): string => orderLineItemsAction(orderNumber, 'flag');
export const unflagOrderLineItems = (orderNumber: number): string => orderLineItemsAction(orderNumber, 'unflag');

export const updateOrderItemBarcode = (orderNumber: number): string => orderItemsAction(orderNumber, 'update-barcode');

export default {
  searchOrder,
  orderShippingReady,
  orderItemsCheckedIn,
  orderItemsPreTreated,
  orderItemsMarkWaterproofed,
  orderItemsUnMarkWaterproofed,
  orderItemsQualityChecked,
  orderItemsStatuses,
  orderLineItemsCheckedIn,
  orderLineItemsDecorated,
  orderLineItemsStatuses,
  orderLineItemsFlags,
  flagOrderLineItems,
  unflagOrderLineItems,
  updateOrderItemBarcode,
};
