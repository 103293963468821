import React, {
  useCallback, useState,
} from 'react';
import { usePostHomefieldApiHagTransmissionssendprepressjobitem as useHagSendPrepressJobItemPOST } from '@api/fulfillment/hag';
import DtfPrinterModal from '../../PrintRoom/PrintRoomTableContent/DtfPrinterModal';
import DtfPrinterButton from '../../PrintRoom/PrintRoomTableContent/DtfPrinterButton';
import { materialSwal } from '@util/componentHelper';

interface OwnProps {
  itemId: number;
  enabled: boolean;
  barcode: string;
}

type Props = OwnProps;

const DtfReprintCell = React.memo<Props>(({
  itemId, enabled, barcode,
}) => {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const { mutateAsync: dtfSendItem } = useHagSendPrepressJobItemPOST();
  const handleSendToPrint = useCallback(async (prepressJobItemId: number, printerNumber: number) => {
    const res = await dtfSendItem({
      data: {
        prepressJobItemId,
        printerNumber,
      },
    });
    if (res?.success) {
      materialSwal('Success', `${barcode} sent to Printer ${printerNumber}. ${res.message}`, 'success');
    } else {
      materialSwal('Failure', `${barcode} failed to send. ${res?.message}`, 'warning');
    }
  }, [
    barcode,
    dtfSendItem,
  ]);

  if (!enabled) return <div />;

  return (
    <>
      <DtfPrinterButton
        id={itemId}
        onClick={openModal}
        enabled={enabled}
        label={'Reprint'}
        className={'button button--color-gossamer button--size-l button--style-default button--radius-square button--shadow-m button--transform-uppercase'}
      />
      <DtfPrinterModal
        title={`Print ${barcode}`}
        jobId={itemId}
        formId={`dtfReprintModal-${itemId}`}
        isOpen={isOpen}
        onClose={closeModal}
        onPrint={handleSendToPrint}
      />
    </>
  );
});

export default DtfReprintCell;
