import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import InputSpinner from '@sharedComponents/Inputs/InputSpinner';

interface OwnProps {
  selectedItem: any;
  selectQuantity: (newQuantity: number, item: any) => void;
  selectedQuantity: number;
  disabled?: boolean;
}

type Props = OwnProps;

const OrderItemQuantityInput = React.memo(({
  selectedItem,
  selectQuantity,
  selectedQuantity,
  disabled,
}: Props) => {
  const [
    newQuantity,
    setNewQuantity,
  ] = useState(selectedQuantity);

  useEffect(() => {
    setNewQuantity(selectedQuantity);
  }, [selectedQuantity]);

  const handleChange = useCallback((e) => {
    const newQuantityValue = e.target.value;

    setNewQuantity(newQuantityValue);
    selectQuantity(newQuantityValue, selectedItem);
  }, [
    selectedItem,
    selectQuantity,
  ]);

  return (
    <InputSpinner
      value={newQuantity}
      placeholder={(newQuantity | 0).toString()}
      onChange={handleChange}
      classes={'input-spinner'}
      min={0}
      name={'quantity'}
      disabled={disabled}
    />
  );
});

export default OrderItemQuantityInput;
