import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  addMultipleRosters,
  editRoster,
  downloadRosterCsvFile,
  deleteRosters,
  toggleDisabledRosters,
  sendRosterNotifications,
} from '@APICalls/rosters/actions';
import { rosterDetailsUrl } from '@constants/clientUrls/clientUrls';
import { getRosterStatusOptions } from '@constants/options/options';
import { getRostersBulkActions } from '@constants/options/bulkActions';
import { rostersBulkActionsEnum } from '@constants/enums/bulkActionsEnum';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { fetchRostersTable } from '@redux/rosters/actions';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { materialSwal } from '@util/componentHelper';
import { parseDateNumeric } from '@util/dateHandler';
import EditRosterModal from '@components/RosterManager/RosterManagerModals/EditRosterModal';
import AddRostersFromCSV from '@components/RosterManager/RosterManagerModals/AddRostersFromCSV';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Button from '@sharedComponents/Buttons/Button';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import Link from '@sharedComponents/Navigation/Link';
import ActivityStatus from '@sharedComponents/Display/ActivityStatus';
import DownloadFileLink from '@sharedComponents/Navigation/DownloadFileLink';
import RostersActionsColumn from './RostersActionsColumn';
import ToggleDisabledRosterModal from './RosterManagerModals/ToggleDisabledRosterModal';
import RemoveRostersModal from './RosterManagerModals/RemoveRostersModal';
import SendNotificationsModal from './RosterManagerModals/SendNotificationsModal';

const RosterTeamsTable = Table();

const rosterStatusOptions = getRosterStatusOptions();

class RostersTable extends PureComponent {
  state = {
    sortOrder: '',
    sortBy: '',
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
    searchInput: '',
    disabled: '',
    selectedRoster: null,
    importRosterFromCSVModalIsOpen: false,
    editRosterModalIsOpen: false,
    selectedRosters: [],
    toggleDisabledRostersModalIsOpen: false,
    deleteRostersModalIsOpen: false,
    sendNotificationsModalIsOpen: false,
  };

  componentDidUpdate(oldProps) {
    const {
      lockerId,
      organizationId,
    } = this.props;

    if ((lockerId !== oldProps.lockerId && oldProps.lockerId !== null)
      || (organizationId !== oldProps.organizationId && oldProps.organizationId !== null)) {
      this.search();
    }
  }

  openImportRosterFromCSVModal = () => {
    this.setState(() => ({ importRosterFromCSVModalIsOpen: true }));
  };

  closeImportRosterFromCSVModal = () => {
    this.setState(() => ({ importRosterFromCSVModalIsOpen: false }));
  };

  openEditRosterModal = (selectedRoster) => {
    this.setState(() => ({
      selectedRoster,
      editRosterModalIsOpen: true,
    }));
  };

  closeEditRosterModal = () => {
    this.setState(() => ({
      selectedRoster: null,
      editRosterModalIsOpen: false,
    }));
  };

  openSendNotificationsModal = (roster) => {
    this.setState(() => ({
      selectedRosters: [roster],
      sendNotificationsModalIsOpen: true,
    }));
  };

  closeSendNotificationsModal = () => {
    this.setState(() => ({
      sendNotificationsModalIsOpen: false,
    }));
  };

  openDeleteRosterModal = (selectedRoster) => {
    this.setState(() => ({
      selectedRosters: [selectedRoster],
      deleteRostersModalIsOpen: true,
    }));
  };

  addRosters = async (rostersForm) => {
    const res = await addMultipleRosters(rostersForm);
    this.search();
    materialSwal('Success', res.message, 'success');
    this.closeImportRosterFromCSVModal();
  };

  editRoster = async (rosterForm) => {
    const res = await editRoster(rosterForm);
    this.search();
    materialSwal('Success', res.message, 'success');
    this.closeEditRosterModal();
  };

  clearSearch = () => {
    this.setState(() => ({
      searchInput: '',
    }), this.search);
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  onStatusChange = (value) => {
    this.setState(() => ({
      disabled: value,
    }), this.search);
  };

  downloadCSVFile = () => {
    const {
      lockerId,
      organizationId,
    } = this.props;

    downloadRosterCsvFile(null, lockerId, organizationId);
  };

  fetchData = (state, instance) => {
    const {
      sortBy,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortBy, sortOrder);

    this.setState(() => ({
      pageNumber: page + 1,
      pageSize,
      sortBy: sortColumn,
      sortOrder: sortDirection,
    }), this.search);
  };

  search = () => {
    const {
      dispatch,
      organizationId,
      lockerId,
    } = this.props;

    const {
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      searchInput,
      disabled,
    } = this.state;

    dispatch(fetchRostersTable(
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      disabled,
      searchInput,
      organizationId,
      lockerId
    ));
  };

  onSelectBulkAction = (selectedBulkAction) => {
    switch (selectedBulkAction) {
      case rostersBulkActionsEnum.deleteRosters:
        this.onBulkDeleteRosters();
        break;
      case rostersBulkActionsEnum.toggleDisabledRosters:
        this.onBulkToggleDisabledRosters();
        break;
      case rostersBulkActionsEnum.sendNotifications:
        this.onBulkSendNotifications();
        break;
    }
  };

  onBulkDeleteRosters = () => {
    this.setState(() => ({ deleteRostersModalIsOpen: true }));
  };

  deleteRosters = async () => {
    const { selectedRosters } = this.state;

    const res = await deleteRosters(selectedRosters);

    if (res?.success) {
      this.search();
      materialSwal('Success', res.message, 'success');
      this.closeDeleteRostersModal();
      this.clearSelection();
    }
  };

  closeDeleteRostersModal = () => {
    this.setState(() => ({ deleteRostersModalIsOpen: false }));
  };

  onBulkToggleDisabledRosters = () => {
    this.setState(() => ({ toggleDisabledRostersModalIsOpen: true }));
  };

  onBulkSendNotifications = () => {
    this.setState(() => ({ sendNotificationsModalIsOpen: true }));
  };

  sendNotifications = async (rosterNotificationsForm) => {
    const res = await sendRosterNotifications(rosterNotificationsForm);

    if (res) {
      materialSwal('Success', 'Notifications successfully sent', 'success');
    } else {
      materialSwal('Error', 'Something went wrong. Please try again.', 'error');
    }

    this.clearSelection();
    this.search();
    this.closeSendNotificationsModal();
  };

  toggleDisabledRosters = async () => {
    const { selectedRosters } = this.state;

    const res = await toggleDisabledRosters(selectedRosters);

    if (res?.success) {
      this.search();
      materialSwal('Success', res.message, 'success');
      this.closetoggleDisabledRostersModal();
      this.clearSelection();
    }
  };

  closetoggleDisabledRostersModal = () => {
    this.setState(() => ({ toggleDisabledRostersModalIsOpen: false }));
  };

  clearSelection = () => {
    this.updateSelection([], false);
  };

  updateSelection = (newSelectedRosters, newIsPageSelected) => {
    this.setState(() => ({
      selectedRosters: newSelectedRosters,
      isPageSelected: newIsPageSelected,
    }));
  };

  openToggleDisabledRosterModal = (roster) => {
    this.setState(() => ({
      selectedRosters: [roster],
      toggleDisabledRostersModalIsOpen: true,
    }));
  };

  getColumns = () => {
    const columns = [
      {
        Header: <HeaderCell text={'Roster Team Name'} />,
        accessor: 'teamName',
        minWidth: 40,
        sortable: true,
        Cell: (cellProps) => cellProps.original.id && (
          <Link
            url={rosterDetailsUrl(cellProps.original.id)}
            text={cellProps.original.teamName}
          />
        ),
      },
      {
        Header: 'Locker ID & Name',
        accessor: 'lockerId',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value && (
          <span>
            L{cellProps.value}{cellProps.original.lockerTeamName ? ` - ${cellProps.original.lockerTeamName}` : ''}
          </span>
        ),
      },
      {
        Header: 'Organization',
        accessor: 'organizationId',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value && (
          <span>
            ORG{cellProps.value}{cellProps.original.organizationName ? ` - ${cellProps.original.organizationName}` : ''}
          </span>
        ),
      },
      {
        Header: 'Ordering Deadline',
        accessor: 'orderingDeadline',
        minWidth: 30,
        Cell: (cellProps) => cellProps.value && <span>{parseDateNumeric(cellProps.value)}</span>,
      },
      {
        Header: 'Members',
        accessor: 'membersCount',
        minWidth: 20,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Status',
        accessor: 'disabled',
        minWidth: 20,
        Cell: (cellProps) => <ActivityStatus isDisabled={cellProps.value} />,
      },
      {
        Header: '',
        accessor: '',
        minWidth: 10,
        resizable: false,
        style: { overflow: 'visible' },
        Cell: (cellProps) => (
          <RostersActionsColumn
            roster={cellProps.value}
            onRosterEdit={this.openEditRosterModal}
            onRosterToggleDisabled={this.openToggleDisabledRosterModal}
            onRosterSendNotifications={this.openSendNotificationsModal}
            onRosterDelete={this.openDeleteRosterModal}
          />
        ),
      },
    ];

    return columns;
  };

  render() {
    const {
      editRosterModalIsOpen,
      selectedRoster,
      disabled,
      importRosterFromCSVModalIsOpen,
      toggleDisabledRostersModalIsOpen,
      deleteRostersModalIsOpen,
      sendNotificationsModalIsOpen,
      selectedRosters,
    } = this.state;

    const {
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    const initialValues = !selectedRoster ? {} : selectedRoster;

    const disabledSendNotifications = selectedRosters.some((r) => r.disabled);
    const bulkActions = getRostersBulkActions(disabledSendNotifications, false);

    return (
      <div>
        <AddRostersFromCSV
          isOpen={importRosterFromCSVModalIsOpen}
          closeModal={this.closeImportRosterFromCSVModal}
          addRosters={this.addRosters}
        />
        <EditRosterModal
          isOpen={editRosterModalIsOpen}
          closeModal={this.closeEditRosterModal}
          editRoster={this.editRoster}
          initialValues={initialValues}
        />
        <RemoveRostersModal
          isOpen={deleteRostersModalIsOpen}
          closeModal={this.closeDeleteRostersModal}
          removeRosters={this.deleteRosters}
          rosters={selectedRosters}
        />
        <ToggleDisabledRosterModal
          isOpen={toggleDisabledRostersModalIsOpen}
          closeModal={this.closetoggleDisabledRostersModal}
          toggleDisabledRosters={this.toggleDisabledRosters}
          rosters={selectedRosters}
        />
        <SendNotificationsModal
          onSubmit={this.sendNotifications}
          isOpen={sendNotificationsModalIsOpen}
          closeModal={this.closeSendNotificationsModal}
          rosters={selectedRosters}
        />

        <div className='navigation mt-15'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
            <Dropdown
              options={rosterStatusOptions}
              defaultValue={disabled}
              onChange={this.onStatusChange}
              classes={'margin-left coupons-table__filter'}
            />
          </div>

          <div className='align__center mb-10'>
            <DownloadFileLink
              text={'Download CSV'}
              downloadFile={this.downloadCSVFile}
            />
            <Button
              type={'primary'}
              text={'Add Roster'}
              onClick={this.openImportRosterFromCSVModal}
              classes={'ml-20'}
            />
          </div>
        </div>
        <div className='sheet'>
          <RosterTeamsTable
            data={queue}
            columns={this.getColumns()}
            showPagination={hasNextPage || hasPreviousPage}
            totalPages={totalPages}
            onFetchData={this.fetchData}
            isBulkActionMode={selectedRosters && selectedRosters.length > 0}
            selectable={true}
            selectPredicateOrKey={'id'}
            updateSelection={this.updateSelection}
            selectedData={selectedRosters}
            bulkActionsList={bulkActions}
            onSelectBulkAction={this.onSelectBulkAction}
          />
        </div>
      </div>
    );
  }
}

RostersTable.propTypes = {
  lockerId: PropTypes.number.isRequired,
  organizationId: PropTypes.number,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  totalPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

const mapStateToProps = ({ rosters }) => ({
  queue: rosters.rostersQueue.queue,
  pageNumber: rosters.rostersQueue.pageNumber,
  pageSize: rosters.rostersQueue.pageSize,
  hasPreviousPage: rosters.rostersQueue.hasPreviousPage,
  hasNextPage: rosters.rostersQueue.hasNextPage,
  totalPages: rosters.rostersQueue.totalPages,
});

export default connect(mapStateToProps)(RostersTable);
