import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lockerMgrS3Logos } from '@constants/common';
import { arraysAreSameById } from '@util/arrayHelper';
import { materialSwal } from '@util/componentHelper';
import {
  fetchUsedLogos,
  updateProductAssignmentForLogos,
} from '@redux/lockerManager/actions';
import AssignLogosToItemsTable from './AssignLogosToItemsTable';
import AssignLogosToItemsReview from './AssignLogosToItemsReview';
import AssignLogosToItemsTabHeader from './AssignLogosToItemsTabHeader';
import ChooseLogosTab from './ChooseLogosTab';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class AssignLogosToItems extends Component {
  state = {
    selectMultipleLogosTab: this.props.multipleLogos || false,
    selectedLogoTab: !this.props.multipleLogos,
    selectedItemsTab: false,
    selectedReviewTab: false,
    logoIcon: true,
    itemsIcon: false,
    reviewIcon: false,
    nextStep: true,
    productsToAddLogo: [],
    selectAll: false,
    selectedLogos: this.props.preselectedLogos || [],
    filterBlankProducts: !this.props.enableMultipleLogosOnProduct,
  };

  componentDidUpdate(prevProps) {
    const { enableMultipleLogosOnProduct } = this.props;
    let { filterBlankProducts } = this.state;

    if (prevProps.enableMultipleLogosOnProduct !== enableMultipleLogosOnProduct) {
      filterBlankProducts = !enableMultipleLogosOnProduct;
      this.setState(() => ({ filterBlankProducts: !enableMultipleLogosOnProduct }));
    }

    let currentProductQueue;
    let oldProductQueue;
    if (filterBlankProducts) {
      const { blankProductsQueue } = this.props;
      oldProductQueue = prevProps.blankProductsQueue;
      currentProductQueue = blankProductsQueue;
    } else {
      const { allLockerProductsQueue } = this.props;
      oldProductQueue = prevProps.allLockerProductsQueue;
      currentProductQueue = allLockerProductsQueue;
    }

    if (!oldProductQueue || !currentProductQueue) {
      return;
    }

    if (!arraysAreSameById(oldProductQueue, currentProductQueue)) {
      const { productsToAddLogo } = this.state;
      const addedProducts = currentProductQueue.filter((p) => productsToAddLogo.find((ptal) => ptal.id === p.id));

      if (addedProducts.length === 0) {
        this.setState(() => ({ selectAll: false }));
      } else if (addedProducts.length === currentProductQueue.length) {
        this.setState(() => ({ selectAll: true }));
      }
    }
  }

  nextStep = () => {
    const {
      selectMultipleLogosTab,
      selectedLogoTab,
      selectedItemsTab,
      selectedReviewTab,
    } = this.state;

    if (selectMultipleLogosTab) {
      this.setState(() => ({
        selectMultipleLogosTab: false,
        itemsIcon: true,
        selectedItemsTab: true,
      }));
    } else if (selectedLogoTab) {
      this.setState(() => ({
        selectedLogoTab: false,
        itemsIcon: true,
        selectedItemsTab: true,
      }));
    } else if (selectedItemsTab) {
      this.setState(() => ({
        selectedItemsTab: false,
        selectedReviewTab: true,
        reviewIcon: true,
        nextStep: false,
      }));
    } else if (selectedReviewTab) {
      this.setState(() => ({
        selectedReviewTab: false,
      }));
    }
  };

  selectMultipleLogos = () => {
    this.setState(() => ({
      selectMultipleLogosTab: true,
      selectedLogoTab: false,
      selectedItemsTab: false,
      selectedReviewTab: false,
      nextStep: true,
    }));
  };

  selectLogo = () => {
    this.setState(() => ({
      selectMultipleLogosTab: false,
      selectedLogoTab: true,
      selectedItemsTab: false,
      selectedReviewTab: false,
      nextStep: true,
    }));
  };

  selectItems = () => {
    this.setState(() => ({
      selectMultipleLogosTab: false,
      selectedLogoTab: false,
      selectedItemsTab: true,
      selectedReviewTab: false,
      itemsIcon: true,
      nextStep: true,
    }));
  };

  selectReview = () => {
    this.setState(() => ({
      selectMultipleLogosTab: false,
      selectedLogoTab: false,
      selectedItemsTab: false,
      selectedReviewTab: true,
      reviewIcon: true,
      nextStep: false,
    }));
  };

  isLogoSelected = (logo) => {
    const { selectedLogos } = this.state;

    return selectedLogos.find((l) => l.id === logo.id) !== undefined;
  };

  isSelectingLogosEnabled = () => {
    const { selectedLogos } = this.state;
    const { enableMultipleLogosOnProduct } = this.props;

    return enableMultipleLogosOnProduct || (!enableMultipleLogosOnProduct && selectedLogos.length === 0);
  };

  toggleSelectLogo = (logo, isSelected) => {
    const { selectedLogos } = this.state;

    if (isSelected) {
      this.setState(() => ({ selectedLogos: selectedLogos.filter((l) => l.id !== logo.id) }));
    } else if (this.isSelectingLogosEnabled()) {
      this.setState(() => ({
        selectedLogos: [
          ...selectedLogos,
          logo,
        ],
      }));
    }
  };

  unselectLogo = (logo) => {
    this.toggleSelectLogo(logo, true);
  };

  removeAllLogos = () => {
    this.setState(() => ({ selectedLogos: [] }));
  };

  toggleFilterBlankLogos = () => {
    const { enableMultipleLogosOnProduct } = this.props;
    const { filterBlankProducts } = this.state;

    if (enableMultipleLogosOnProduct) {
      this.setState(() => ({
        filterBlankProducts: !filterBlankProducts,
        productsToAddLogo: [],
        selectAll: false,
      }));
    }
  };

  getCurrentProductQueue = () => {
    const { filterBlankProducts } = this.state;

    const {
      blankProductsQueue,
      allLockerProductsQueue,
    } = this.props;

    if (filterBlankProducts) {
      return blankProductsQueue;
    }

    return allLockerProductsQueue;
  };

  selectAllProducts = () => {
    const { selectAll } = this.state;
    const { productsToAddLogo } = this.state;

    const currentProductQueue = this.getCurrentProductQueue();

    if (!selectAll) {
      const newProductsToAddLogo = [...productsToAddLogo];

      for (const prodToAdd of currentProductQueue) {
        if (!productsToAddLogo.find((ptal) => ptal.id === prodToAdd.id)) {
          newProductsToAddLogo.push(prodToAdd);
        }
      }

      this.setState(() => ({
        productsToAddLogo: newProductsToAddLogo,
        selectAll: true,
      }));
    } else {
      this.setState(() => ({
        productsToAddLogo: productsToAddLogo.filter((prod) => !currentProductQueue.find((obj) => obj.id === prod.id)),
        selectAll: false,
      }));
    }
  };

  selectProduct = (product) => {
    const { productsToAddLogo } = this.state;

    const currentProductQueue = this.getCurrentProductQueue();

    if (productsToAddLogo.filter((p) => p.id === product.id).length > 0) {
      this.setState(() => ({
        productsToAddLogo: productsToAddLogo.filter((p) => p.id !== product.id),
      }), () => {
        const newProducts = currentProductQueue.filter(
          (p) => this.state.productsToAddLogo.find((ptal) => ptal.id === p.id)
        );
        if (newProducts.length === 0) {
          this.setState({
            selectAll: false,
          });
        }
      });
    } else {
      this.setState((prevState) => ({
        productsToAddLogo: [
          ...prevState.productsToAddLogo,
          product,
        ],
      }),
      () => {
        const newProducts = currentProductQueue.filter(
          (p) => this.state.productsToAddLogo.find((ptal) => ptal.id === p.id)
        );
        if (newProducts.length === currentProductQueue.length) {
          this.setState(() => ({
            selectAll: true,
          }));
        }
      });
    }
  };

  reinitialize = () => {
    const {
      preselectedLogos,
      multipleLogos,
    } = this.props;

    this.setState(() => ({
      selectedLogos: preselectedLogos || [],
      productsToAddLogo: [],
      selectMultipleLogosTab: multipleLogos || false,
      selectedLogoTab: !multipleLogos,
      selectedItemsTab: false,
      selectedReviewTab: false,
      logoIcon: true,
      itemsIcon: false,
      reviewIcon: false,
      nextStep: true,
      selectAll: false,
    }));
  };

  confirm = async () => {
    const {
      productsToAddLogo,
      selectedLogos,
    } = this.state;

    const {
      lockerId,
      closeModal,
      dispatch,
    } = this.props;

    const addCssIds = productsToAddLogo.map((p) => p.id);

    await updateProductAssignmentForLogos(lockerId, selectedLogos, addCssIds, []);
    materialSwal('Success', 'Logo assignment was successful!', 'success');
    dispatch(fetchUsedLogos(lockerId, '', ''));
    this.reinitialize();
    closeModal();
  };

  render() {
    const {
      lockerId,
      modalIsOpen,
      closeModal,
      logoId,
      logos,
      multipleLogos,
      enableMultipleLogosOnProduct,
    } = this.props;

    const {
      selectMultipleLogosTab,
      selectedLogoTab,
      selectedItemsTab,
      selectedReviewTab,
      logoIcon,
      itemsIcon,
      reviewIcon,
      productsToAddLogo,
      selectAll,
      nextStep,
      selectedLogos,
      filterBlankProducts,
    } = this.state;

    return (
      <div>
        <Modal
          title={'Add Logo to Items'}
          modalSize={'xl'}
          isOpen={modalIsOpen}
          closeModal={closeModal}
          buttons={(
            <ModalButtons
              confirmBtnText={nextStep ? 'Next Step' : 'Confirm'}
              cancelBtnText={'Cancel'}
              onConfirm={nextStep ? this.nextStep : this.confirm}
              onClose={closeModal}
            />
          )}
        >
          <div>
            <div className='tabs'>
              {
                multipleLogos
                  ? (
                    <AssignLogosToItemsTabHeader
                      title={'1. Choose Logos'}
                      isActiveTab={selectMultipleLogosTab}
                      selectTab={this.selectMultipleLogos}
                      iconChecked={logoIcon}
                    />
                  )
                  : (
                    <AssignLogosToItemsTabHeader
                      title={'1. Choose Logo'}
                      isActiveTab={selectedLogoTab}
                      selectTab={this.selectLogo}
                      iconChecked={logoIcon}
                    />
                  )
              }
              <AssignLogosToItemsTabHeader
                title={'2. Choose Item(s)'}
                isActiveTab={selectedItemsTab}
                selectTab={this.selectItems}
                iconChecked={itemsIcon}
              />
              <AssignLogosToItemsTabHeader
                title={'3. Review & Confirm'}
                isActiveTab={selectedReviewTab}
                selectTab={this.selectReview}
                iconChecked={reviewIcon}
              />
            </div>

            {
              selectedLogoTab && selectedLogos[0] &&
              <div
                className='logo-bank__assignment--logo--image'
                style={{ backgroundImage: `url("${lockerMgrS3Logos}/${selectedLogos[0].image}")` }}
              />
            }
            {
              selectMultipleLogosTab &&
              <ChooseLogosTab
                logos={logos}
                selectedLogos={selectedLogos}
                toggleSelectLogo={this.toggleSelectLogo}
                unselectLogo={this.unselectLogo}
                isLogoSelected={this.isLogoSelected}
                isSelectingLogosEnabled={this.isSelectingLogosEnabled}
                removeAllLogos={this.removeAllLogos}
              />
            }
            {
              selectedItemsTab &&
              <AssignLogosToItemsTable
                lockerId={lockerId}
                logoId={logoId}
                selectProduct={this.selectProduct}
                selectAllProducts={this.selectAllProducts}
                productsToAddLogo={productsToAddLogo}
                selectAll={selectAll}
                filterBlankProducts={filterBlankProducts}
                enableMultipleLogosOnProduct={enableMultipleLogosOnProduct}
                toggleFilterBlankLogos={this.toggleFilterBlankLogos}
              />
            }
            {
              selectedReviewTab &&
              <AssignLogosToItemsReview
                productsToAddLogo={productsToAddLogo}
                logos={selectedLogos}
              />
            }
          </div>
        </Modal>
      </div>
    );
  }
}

AssignLogosToItems.propTypes = {
  logoId: PropTypes.number,
  lockerId: PropTypes.number.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  blankProductsQueue: PropTypes.array.isRequired,
  allLockerProductsQueue: PropTypes.array.isRequired,
  multipleLogos: PropTypes.bool,
  enableMultipleLogosOnProduct: PropTypes.bool,
  logos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
  })),
  preselectedLogos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
  })),
};

const mapStateToProps = ({ lockerManager }) => ({
  blankProductsQueue: lockerManager.blankProducts,
  allLockerProductsQueue: lockerManager.allLockerProducts,
  enableMultipleLogosOnProduct: lockerManager.lockerInfo.enableMultipleLogos,
});

export default connect(mapStateToProps)(AssignLogosToItems);
