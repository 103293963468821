import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import { orderItemPersonalizationForm } from '@constants/reduxForms';
import {
  centsToDollars,
  parseNumber,
} from '@util/numberHelpers';
import {
  createOptionsList,
  generalOptions,
} from '@util/optionsMap';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import OrderManagementPersonalizationColorList from './OrderManagementPersonalizationColorList';
import featureFlags from '@constants/featureFlags';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { RootState } from 'store';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { validateOrderItemPersonalization } from '@redux/orderManagement/validations';
import { PersonalizationFormData } from '@models/forms/OrderManagement/PersonalizationFormData';
import {
  PersonalizationItemModel,
  OrderItemDetailsDto,
} from '@api/fulfillment/models';
import {
  DecorationLocationDto,
  ProductDetailDto,
  StyleDecorationLocationDto,
} from '@api/productCatalog/models';
import { mapQuantityToOptions } from '@util/mappingHelper';
import {
  ItemsQueriesPersonalization,
  QueriesVariationDto,
} from '@api/orders/models';

interface Color {
  id: number;
  code: string;
  dtgCode: Nullable<string>;
  cmykValue: string;
  dtgCmykValue: string;
  hexValue: string;
  threadValue: string;
  brightness: string;
  isArtwork: Nullable<boolean>;
  isFlood: Nullable<boolean>;
}

interface PersonalizationInitialValue {
  decorationLocation: string | null | undefined;
  number: string;
  text: string;
  colorId: number | null | undefined;
  quantity: number | undefined;
  personalizationBasePrice: number | null | undefined;
}

interface OwnProps {
  isOpen: boolean;
  handleSubmit: () => void;
  orderItem: OrderItemDetailsDto;
  canRefundSubtotal: boolean;
  personalization: PersonalizationItemModel;
  product: ProductDetailDto;
  decorationLocations: Array<DecorationLocationDto>;
  colors: Array<Color>;
  change: (formColorProperty: string, id: number) => void;
  initialValues: PersonalizationInitialValue | {
    quantity: number | undefined;
  };
  showQuantityField: boolean;
  canUpdatePersonalizationPrice: boolean;
  variation: Nullable<QueriesVariationDto>;
  orderExistsOnCore: boolean;
  isEdit: boolean;
  disableCharge: boolean;
}

const selector = formValueSelector(orderItemPersonalizationForm);
const mapStateToProps = (state: RootState) => ({
  settings: state.support.settings,
  personalizationText: selector(state, 'personalizationText'),
  personalizationNumber: selector(state, 'personalizationNumber'),
  personalizationBasePriceTouched: selector(state, 'personalizationBasePriceTouched'),
});

const connector = connect(mapStateToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps &
  InjectedFormProps<PersonalizationFormData, FormProps, string[]>;

const formColorProperty = 'colorId';
const locationDefaultText = 'Select location';

const OrderManagementPersonalizationForm = React.memo<Props>(({
  isOpen,
  handleSubmit,
  orderItem,
  personalization,
  product,
  decorationLocations,
  colors,
  change,
  initialValues,
  showQuantityField,
  canUpdatePersonalizationPrice,
  settings,
  personalizationText,
  personalizationNumber,
  personalizationBasePriceTouched,
  variation,
  orderExistsOnCore,
  isEdit,
  disableCharge,
}) => {
  const [
    quantityOptions,
    setQuantityOptions,
  ] = useState<Nullable<Array<any>>>(null);
  const [
    decorationLocationOptions,
    setDecorationLocationOptions,
  ] = useState<Nullable<Array<any>>>(null);
  const [
    productColors,
    setProductColors,
  ] = useState<Nullable<Array<Color>>>(null);
  const [
    selectedColorId,
    setSelectedColorId,
  ] = useState<Nullable<number>>(null);

  const selectColor = (colorId: number) => {
    setSelectedColorId(colorId);
    change(formColorProperty, colorId);
  };

  const basePriceChangeDisabled = !!orderItem?.voucherId || (!isEdit && disableCharge);

  const getProductDecorationLocation = (code: string) => {
    if (!product?.coloredStyle?.style) {
      return null;
    }

    return product?.coloredStyle?.style?.decorationLocations?.find((l) => l.location === code);
  };

  const getDecorationLocationOptions = useCallback((isAdd = false) => {
    if (!product?.coloredStyle?.style
      || !decorationLocations) {
      return [];
    }

    let productDecorationLocations = orderExistsOnCore
      ? variation?.item?.personalizations?.map((pers: ItemsQueriesPersonalization) => pers.decorationLocation)
      : product?.coloredStyle?.style?.decorationLocations?.filter(
        (l) => l.type === decorationTypeEnum.Personalization
      );

    if (!orderItem?.custom && !orderExistsOnCore) {
      productDecorationLocations = (productDecorationLocations as StyleDecorationLocationDto[])?.filter(
        (l) => !l.custom
      );
    }

    const itemDecorationLocations = decorationLocations.filter((l) => (
      orderExistsOnCore
        ? (productDecorationLocations as string[])?.includes(l!.code!)
          && !orderItem?.personalizations?.map(
            (per) => per.decorationLocation
          )?.includes(l!.code!)
        : (productDecorationLocations as StyleDecorationLocationDto[])?.map(
          (pdl) => pdl.locationId
        )?.includes(l.id)
    ));

    if (isEdit) {
      itemDecorationLocations.push(decorationLocations.find(
        (dl) => dl.code === personalization?.decorationLocation
      )!);
    }

    return createOptionsList({
      list: itemDecorationLocations?.map((item) => ({
        ...item,
        name: `${item?.code ? `${item?.code} - ` : ''} ${item?.label}`,
      })),
      key: 'id',
      value: 'code',
      name: 'name',
      emptyOption: isAdd && {
        disabled: true,
        name: locationDefaultText,
      },
    });
  }, [
    product?.coloredStyle?.style,
    decorationLocations,
    orderExistsOnCore,
    variation?.item?.personalizations,
    orderItem,
    isEdit,
    personalization?.decorationLocation,
  ]);

  const getQuantityOptions = useCallback(() => {
    if (orderItem?.quantity) {
      return generalOptions(mapQuantityToOptions(orderItem.quantity));
    }

    return [];
  }, [orderItem?.quantity]);

  const getProductColors = useCallback(() => {
    if (!product?.coloredStyle
      || !colors) {
      return [];
    }

    const pColors = orderItem?.custom
      ? product.coloredStyle.personalizationColors
      : product?.coloredStyle?.personalizationColors?.filter((c) => !c.custom);
    const productColorsIds = pColors?.map((c) => c.floodColorId);
    const productColorsDetails = colors.filter((c) => productColorsIds?.includes(c.id));

    return productColorsDetails;
  }, [
    colors,
    orderItem?.custom,
    product.coloredStyle,
  ]);

  const changePersonalizationBasePrice = useCallback((value: number) => {
    change('personalizationBasePrice', orderItem?.custom ? 0 : value);
  }, [change, orderItem?.custom]);

  const onTextOrNumberChanged = useCallback((number: string, text: string) => {
    const { defaultPersonalizationPrices } = settings;
    const {
      textOrNumber,
      textAndNumber,
    } = defaultPersonalizationPrices;

    if (!basePriceChangeDisabled) { // If an item has a voucher, don't apply any changes to base price
      if (!!number && !!text) {
        changePersonalizationBasePrice(centsToDollars(textAndNumber)!);
      } else {
        changePersonalizationBasePrice(centsToDollars(textOrNumber)!);
      }
    }
  }, [basePriceChangeDisabled, changePersonalizationBasePrice, settings]);

  const handleTextChange = useCallback((event) => {
    if (!settings) {
      return;
    }

    const text = event.target.value;
    onTextOrNumberChanged(personalizationNumber, text);
    change('personalizationText', text);
  }, [settings, onTextOrNumberChanged, personalizationNumber, change]);

  const handleNumberChange = useCallback((event) => {
    if (!settings) {
      return;
    }
    const number = event.target.value;
    onTextOrNumberChanged(number, personalizationText);
    change('personalizationNumber', number);
  }, [settings, onTextOrNumberChanged, personalizationText, change]);

  const onPresonalizationBasePriceChange = useCallback(() => {
    change('personalizationBasePriceTouched', true);
  }, [change]);

  /*
   * Next 3 useEffect's are used for initialization of parameters,
   * that's the reason for missing recommended dependecies
   */

  useEffect(() => {
    if (isOpen) {
      setQuantityOptions(getQuantityOptions());
      change('personalizationText', (initialValues as PersonalizationInitialValue).text);
      change('personalizationNumber', (initialValues as PersonalizationInitialValue).number as unknown as number);
      change('personalizationBasePriceTouched', false);

      change(formColorProperty, selectedColorId!);
    }
  }, [
    getQuantityOptions,
    isOpen,
    initialValues,
  ]);

  useEffect(() => {
    if (isOpen) {
      const locationOptions = getDecorationLocationOptions(!initialValues.decorationLocation);
      setDecorationLocationOptions(locationOptions);
    }
  }, [
    getDecorationLocationOptions,
    initialValues.decorationLocation,
    isOpen,
  ]);

  useEffect(() => {
    if (isOpen) {
      const color = personalization
        ? getProductColors().find((c) => c.id === personalization.colorId)
        : null;
      const selectedId = color ? color.id : null;
      setSelectedColorId(selectedId);
    }
    setProductColors(getProductColors());
  }, [
    getProductColors,
    isOpen,
  ]);

  return (
    <form
      className='redux-form'
      id={orderItemPersonalizationForm}
      onSubmit={handleSubmit}
    >
      <div>
        <div className='redux-form__section pb-5'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-l pr-30'>
              <label className='redux-form__label'>
                Text
                <span className='required'>*</span>
              </label>
              <Field
                name={'text'}
                placeholder={'Enter Name'}
                component={Input}
                type={'text'}
                onChange={handleTextChange}
              />
            </div>
            <div className='redux-form__column--size-s'>
              <label className='redux-form__label'>
                Number
                <span className='required'>*</span>
              </label>
              <Field
                name={'number'}
                placeholder={'Enter Number'}
                component={Input}
                type={'text'}
                className={'width--unset'}
                onChange={handleNumberChange}
              />
            </div>
          </div>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-l pr-30'>
              <label className='redux-form__label'>
                Personalization Location
                <span className='required'>*</span>
              </label>
              <Field
                name={'decorationLocation'}
                component={Select as any}
              >
                {decorationLocationOptions}
              </Field>
            </div>
            {
              showQuantityField && !orderExistsOnCore &&
              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Quantity
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'quantity'}
                  component={Select as any}
                  parse={parseNumber}
                >
                  {quantityOptions}
                </Field>
              </div>
            }
          </div>
          <div className='flex flex__column mt-20'>
            <div>
              <Field
                name={'colorId'}
                component={OrderManagementPersonalizationColorList as any}
                {...{
                  productColors,
                  selectedColorId,
                  selectColor,
                }}
              >
                {decorationLocationOptions}
              </Field>
            </div>

          </div>
          {
            featureFlags.personalizationPriceUpdateEnabled && canUpdatePersonalizationPrice &&
            <div className='redux-form__row mt-20'>
              <div className='redux-form__column--size-m'>
                <div className='text-field'>
                  <label className='redux-form__label'>
                    Personalization base price
                    <span className='required'>*</span>
                  </label>
                  <Field
                    component={Input}
                    className={'w-100'}
                    name={'personalizationBasePrice'}
                    type={'number'}
                    parse={parseNumber}
                    placeholder={'Personalization base price'}
                    onChange={onPresonalizationBasePriceChange}
                    disabled={basePriceChangeDisabled}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </form>
  );
});

export default connector(reduxForm<PersonalizationFormData, any, Props>({
  form: orderItemPersonalizationForm,
  validate: validateOrderItemPersonalization,
  shouldError: () => true,
  enableReinitialize: true,
})(OrderManagementPersonalizationForm));
