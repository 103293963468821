import React from 'react';
import { voucherOrderDetailsBaseUrl } from '@constants/clientUrls/clientUrls';
interface OwnProps {
  location: string;
}

type Props = OwnProps;

const VoucherOrderDetailsHeader = React.memo<Props>(({ location }) => {
  const voucherOrderId = location.split(`${voucherOrderDetailsBaseUrl}/`)[1];

  return (
    <div className='header__bar-content'>
      <div className='header__bar-title align__center'>
        <span>Voucher Order Details - V{voucherOrderId}</span>
      </div>
    </div>
  );
});

export default VoucherOrderDetailsHeader;
