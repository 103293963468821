import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resubmitVendorOrder } from '@APICalls/vendorOrders/actions';
import { fetchVendorOrderSubmitResults } from '@redux/vendorOrders/actions';
import { materialSwal } from '@util/componentHelper';
import { parseDateTimeNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import SubmitTable from '@sharedComponents/SubmitResults/SubmitTable';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

class VendorOrderSubmitResultsModal extends PureComponent {
  state = {
    confirmationModalIsOpen: false,
  };

  openConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: true }));
  };

  closeConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: false }));
  };

  handleModalConfirm = async () => {
    const {
      vendorOrder,
      dispatch,
    } = this.props;

    const res = await resubmitVendorOrder(vendorOrder.id);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }
    dispatch(fetchVendorOrderSubmitResults(vendorOrder.id));

    this.closeConfirmationModal();
  };

  componentDidMount() {
    const {
      vendorOrder,
      dispatch,
    } = this.props;

    dispatch(fetchVendorOrderSubmitResults(vendorOrder.id));
  }

  getColumns = () => [
    {
      Header: 'Date Created',
      accessor: 'dateCreatedUtc',
      minWidth: 140,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.original.dateCreatedUtc)}</span>,
    },
    {
      Header: 'Elapsed Time',
      accessor: 'elapsedTime',
      minWidth: 90,
      Cell: (cellProps) => <span>{cellProps.original.elapsedTime || 0} ms</span>,
    },
    {
      Header: 'Message',
      accessor: 'message',
      minWidth: 150,
    },
    {
      Header: 'Status Code',
      accessor: 'statusCode',
    },
    {
      Header: 'User',
      accessor: 'userId',
      Cell: (cellProps) => <span>{cellProps.original.userId || '-'}</span>,
    },
    {
      expander: true,
      Header: 'Details',
      accessor: '',
      width: 200,
      Expander: ({ isExpanded }) => (
        <div>
          {
            isExpanded
              ? (
                <div className='flex button align__center'>
                  <div className='info__label'>Collapse Details</div>
                  <Icon materialIcon={'arrow_drop_up'} />
                </div>
              )
              : (
                <div className='flex button align__center'>
                  <div className='info__label'>Expand Details</div>
                  <Icon materialIcon={'arrow_drop_down'} />
                </div>
              )
          }
        </div>
      ),
    },
  ];

  render() {
    const {
      vendorOrder,
      vendor,
      isOpen,
      closeModal,
      submitResults,
    } = this.props;

    const { confirmationModalIsOpen } = this.state;

    return (
      <>
        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.handleModalConfirm}
          closeModal={this.closeConfirmationModal}
          title={'Resubmit Vendor Order'}
          confirmationBody={`Are you sure you want to resubmit vendor order with ID ${vendorOrder.id}?`}
          modalStyleProps={{ overlay: { zIndex: 101 } }}
        />
        <Modal
          isOpen={isOpen}
          closeModal={closeModal}
          title={'Submission Results'}
          modalSize={'xl'}
          buttons={(
            <ModalButtons
              onClose={closeModal}
              cancelBtnText={'Close'}
              displayConfirmBtn={false}
            />
          )}
        >
          <SubmitTable
            label={'Vendor Order'}
            item={vendorOrder}
            submitEnabled={!vendorOrder.canceled}
            submitAction={this.openConfirmationModal}
            submitResults={submitResults}
            itemDataProperty={'payload'}
            itemDoneStatusDisplayProperty={'vendorOrderId'}
            titleInfoFields={[
              {
                label: 'Vendor',
                value: vendor.name,
              },
            ]}
            columns={this.getColumns()}
          />
        </Modal>
      </>
    );
  }
}

VendorOrderSubmitResultsModal.propTypes = {
  vendorOrder: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitResults: PropTypes.array.isRequired,
};

const mapStateToProps = ({ vendorOrders }) => ({
  submitResults: vendorOrders.vendorOrderSubmitResults,
});

export default connect(mapStateToProps)(VendorOrderSubmitResultsModal);
