export const selectionTypeEnum = {
  SystemAndUser: 0, // System detected this color, and user left it selected
  UserOnly: 1, // System didn't detect this color, but user selected it
  SystemOnly: 2, // System detected this color, but user unselected it
  InternalSelection: 3, // This color was selected by internal squadlocker artwork team
  InternalDeselection: 4, // This is the color admin selected but internal deselected
} as const;

export const decorationTypeEnum = {
  Logo: 'Logo',
  Personalization: 'Personalization',
} as const;

export const servicesApiDecorationMethods = {
  HAG: 'heat_applied',
  EMB: 'embroidered',
  DTG: 'direct_to_garment',
  DIP: 'digital_inkjet_printing',
  SUB: 'sublimation',
} as const;

export const colorBrightnessEnum = {
  Dark: 'Dark',
  Light: 'Light',
} as const;

export const decorationMethodEnum = {
  NA: 'NA',
  Undefined: 'Undefined',
  HAG: 'HAG',
  EMB: 'EMB',
  SUB: 'SUB',
  DTG: 'DTG',
  DIP: 'DIP',
} as const;

export const decorationSizeEnum = {
  Undefined: 'Undefined',
  Youth: 'Youth',
  Regular: 'Regular',
  Large: 'Large',
} as const;

export const personalizationTypeEnum = {
  Both: 'NameAndNumber',
  Name: 'Name',
  Number: 'Number',
} as const;

export const servicesApiPersonalizationTypeEnum = {
  Both: 0,
  Name: 1,
  Number: 2,
} as const;

export const personalizationColorTypeEnum = {
  Fill: 'Fill',
  FillAndOutline: 'FillAndOutline',
} as const;

export const personalizationRequirementEnum = {
  Required: true,
  Optional: false,
} as const;

export const personalizationItemTypeEnum = {
  Uniform: 'Uniform',
  Standard: 'Standard',
} as const;

export const decorationLocationEnum = {
  NA: 'NA',
  Undefined: 'Undefined',
} as const;
