import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  adminDetailsUrl,
  lockerDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import {
  sendTasksToReview,
  fetchTableCounts,
} from '@redux/logoReview/actions';
import {
  selectItem,
  selectAllItems,
  isSelectedItem,
} from '@util/selectionHelpers';
import { materialSwal } from '@util/componentHelper';
import Table from '@sharedComponents/Table/Table';
import TableBulkActions from '@sharedComponents/Table/TableComponents/TableBulkActions';
import SelectCell from '@sharedComponents/Table/TableCells/SelectCell';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import Button from '@sharedComponents/Buttons/Button';

const LogosToReviewTable = Table();

class LogoReviewerTableNotStarted extends Component {
  state = {
    selectAll: false,
    selectedTasks: [],
    sendTasksToReviewModalIsOpen: false,
  };

  selectItem = (item, isSelected) => {
    const {
      selectedTasks,
      selectAll,
    } = this.state;

    const { queue } = this.props;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectItem(queue, selectedTasks, selectAll, item, isSelected, 'lockerId');

    this.setState({
      selectedTasks: newSelectedItems,
      selectAll: newSelectAll,
    });
  };

  selectAllItems = () => {
    const { queue } = this.props;
    const {
      selectAll,
      selectedTasks,
    } = this.state;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectAllItems(queue, selectedTasks, selectAll, 'lockerId');

    this.setState({
      selectedTasks: newSelectedItems,
      selectAll: newSelectAll,
    });
  };

  clearSelection = () => {
    this.setState({
      selectedTasks: [],
      selectAll: false,
    });
  };

  sendTasksToReview = async () => {
    const {
      dispatch,
      search,
    } = this.props;

    const { selectedTasks } = this.state;

    const res = await dispatch(sendTasksToReview(selectedTasks));
    if (res?.success) {
      search();
      dispatch(fetchTableCounts());
      this.clearSelection();
      materialSwal('Success', res.message, 'success');
    }
  };

  getColumns = () => {
    const {
      selectAll,
      selectedTasks,
    } = this.state;

    const columns = [
      {
        Header: () => (
          <SelectAllCell
            isSelected={selectAll}
            selectAllItems={this.selectAllItems}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <SelectCell
            select={this.selectItem}
            item={cellProps.value}
            isSelected={isSelectedItem(selectedTasks, cellProps.value, 'lockerId')}
          />
        ),
      },
      {
        Header: 'Locker ID',
        accessor: 'lockerId',
        minWidth: 50,
        Cell: (cellProps) => <Link to={lockerDetailsUrl(cellProps.value)}>L{cellProps.value}</Link>,
      },
      {
        Header: 'Team Name',
        accessor: 'teamName',
        minWidth: 100,
      },
      {
        Header: 'Locker Manager',
        accessor: '',
        minWidth: 100,
        Cell: (cellProps) => (
          <Link to={adminDetailsUrl(cellProps.value.lockerManagerId)}>{cellProps.value.lockerManagerName}</Link>
        ),
      },
      {
        Header: 'Partner',
        accessor: 'partnerName',
        minWidth: 60,
      },
      {
        Header: 'Logos',
        accessor: 'logosCount',
        minWidth: 40,
        sortable: true,
      },
      {
        Header: 'Orders',
        accessor: 'ordersCount',
        minWidth: 40,
        sortable: true,
      },
    ];

    return columns;
  };

  getWrappedColumns = (columns) => {
    const { selectedTasks } = this.state;

    const sendToReview = (
      <Button
        type={'secondary'}
        text={'Send to Review'}
        onClick={this.sendTasksToReview}
      />
    );

    const wrappedColumns = [
      {
        Header: (
          <TableBulkActions
            selectedItems={selectedTasks}
            clearAll={this.clearSelection}
            bulkActions={sendToReview}
          />
        ),
        columns,
      },
    ];

    return wrappedColumns;
  };

  render() {
    const {
      queue,
      totalPages,
      pageSize,
      fetchData,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    const { selectedTasks } = this.state;

    const columns = this.getWrappedColumns(this.getColumns());

    return (
      <div className='sheet'>
        <LogosToReviewTable
          data={queue}
          columns={columns}
          defaultPageSize={pageSize}
          totalPages={totalPages}
          showPagination={hasNextPage || hasPreviousPage}
          onFetchData={fetchData}
          isBulkActionsMode={selectedTasks && selectedTasks.length > 0}
        />
      </div>
    );
  }
}

LogoReviewerTableNotStarted.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ logoReview }) => ({
  queue: logoReview.tasksTable.items,
  totalPages: logoReview.tasksTable.pagesCount,
  pageNumber: logoReview.tasksTable.activePage,
  hasNextPage: logoReview.tasksTable.hasNextPage,
  hasPreviousPage: logoReview.tasksTable.hasPreviousPage,
  pageSize: logoReview.tasksTable.itemsPerPage,
  totalCount: logoReview.tasksTable.totalCount,
  designers: logoReview.tasksTable.designers,
  partners: logoReview.tasksTable.partners,
});

export default connect(mapStateToProps)(LogoReviewerTableNotStarted);
