/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  ApiOkString,
  ApiBadRequest,
  ApiInternalServerError,
  PagedListViewModelOrganizationViewModel,
  GetOrganizationsParams,
  ApiOkNumber,
  UpdateOrganizationRequestModel,
  OrganizationSuggestion,
  GetOrganizationSuggestionsParams,
  OrganizationViewModel,
  ApiOk,
  OrganizationLockersRequestModel,
  OrganizationGroupLockerViewModel,
  GetLockersForOrganizationParams,
  OrganizationFeaturedLockersRequestModel,
  OrganizationGroupViewModel,
  CreateOrganizationGroupRequestModel,
  UpdateOrganizationGroupRequestModel,
  UpdateOrganizationGroupFeaturedRequestModel
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const uploadLogo = <TData = ApiOkString>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/logo-upload`, method: 'post',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUploadLogo = <TData = AsyncReturnType<typeof uploadLogo,ApiOkString>,
    TError = ApiBadRequest | ApiInternalServerError,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,TVariables, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, TVariables, TContext>(() => {
        ;

        return  uploadLogo<TData>(requestOptions)
      }, mutationOptions)
    }
    export const getOrganizations = <TData = PagedListViewModelOrganizationViewModel>(
    params?: GetOrganizationsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetOrganizationsQueryKey = (params?: GetOrganizationsParams,) => [`/homefield/api/organizations`, ...(params ? [params]: [])]

    
export const useGetOrganizations = <TQueryFnData = AsyncReturnType<typeof getOrganizations, PagedListViewModelOrganizationViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetOrganizationsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getOrganizations<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createOrganization = <TData = ApiOkNumber>(
    updateOrganizationRequestModel: UpdateOrganizationRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations`, method: 'post',
      data: updateOrganizationRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateOrganization = <TData = AsyncReturnType<typeof createOrganization,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateOrganizationRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdateOrganizationRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  createOrganization<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getOrganizationSuggestions = <TData = OrganizationSuggestion[]>(
    params?: GetOrganizationSuggestionsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/suggestions`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetOrganizationSuggestionsQueryKey = (params?: GetOrganizationSuggestionsParams,) => [`/homefield/api/organizations/suggestions`, ...(params ? [params]: [])]

    
export const useGetOrganizationSuggestions = <TQueryFnData = AsyncReturnType<typeof getOrganizationSuggestions, OrganizationSuggestion[]>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 params?: GetOrganizationSuggestionsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationSuggestionsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getOrganizationSuggestions<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getOrganization = <TData = OrganizationViewModel>(
    organizationId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${organizationId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetOrganizationQueryKey = (organizationId: number,) => [`/homefield/api/organizations/${organizationId}`]

    
export const useGetOrganization = <TQueryFnData = AsyncReturnType<typeof getOrganization, OrganizationViewModel>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 organizationId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetOrganizationQueryKey(organizationId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getOrganization<TQueryFnData>(organizationId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const updateOrganization = <TData = ApiOkNumber>(
    organizationId: number,
    updateOrganizationRequestModel: UpdateOrganizationRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${organizationId}`, method: 'put',
      data: updateOrganizationRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUpdateOrganization = <TData = AsyncReturnType<typeof updateOrganization,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{organizationId: number;data: UpdateOrganizationRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {organizationId: number;data: UpdateOrganizationRequestModel}, TContext>((props) => {
        const {organizationId,data} = props || {};

        return  updateOrganization<TData>(organizationId,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteOrganization = <TData = ApiOkNumber>(
    organizationId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${organizationId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteOrganization = <TData = AsyncReturnType<typeof deleteOrganization,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{organizationId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {organizationId: number}, TContext>((props) => {
        const {organizationId} = props || {};

        return  deleteOrganization<TData>(organizationId,requestOptions)
      }, mutationOptions)
    }
    export const assignLockersToOrganization = <TData = ApiOk>(
    id: number,
    organizationLockersRequestModel: OrganizationLockersRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${id}/lockers`, method: 'post',
      data: organizationLockersRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useAssignLockersToOrganization = <TData = AsyncReturnType<typeof assignLockersToOrganization,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: OrganizationLockersRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: OrganizationLockersRequestModel}, TContext>((props) => {
        const {id,data} = props || {};

        return  assignLockersToOrganization<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const getLockersForOrganization = <TData = OrganizationGroupLockerViewModel[]>(
    id: number,
    params?: GetLockersForOrganizationParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${id}/lockers`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockersForOrganizationQueryKey = (id: number,
    params?: GetLockersForOrganizationParams,) => [`/homefield/api/organizations/${id}/lockers`, ...(params ? [params]: [])]

    
export const useGetLockersForOrganization = <TQueryFnData = AsyncReturnType<typeof getLockersForOrganization, OrganizationGroupLockerViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 id: number,
    params?: GetLockersForOrganizationParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockersForOrganizationQueryKey(id,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockersForOrganization<TQueryFnData>(id,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const removeLockersFromOrganization = <TData = ApiOk>(
    id: number,
    organizationLockersRequestModel: OrganizationLockersRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${id}/lockers.remove`, method: 'put',
      data: organizationLockersRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRemoveLockersFromOrganization = <TData = AsyncReturnType<typeof removeLockersFromOrganization,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: OrganizationLockersRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: OrganizationLockersRequestModel}, TContext>((props) => {
        const {id,data} = props || {};

        return  removeLockersFromOrganization<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const getFeaturedLockersForOrganization = <TData = OrganizationGroupLockerViewModel[]>(
    id: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${id}/featured-lockers`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetFeaturedLockersForOrganizationQueryKey = (id: number,) => [`/homefield/api/organizations/${id}/featured-lockers`]

    
export const useGetFeaturedLockersForOrganization = <TQueryFnData = AsyncReturnType<typeof getFeaturedLockersForOrganization, OrganizationGroupLockerViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetFeaturedLockersForOrganizationQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getFeaturedLockersForOrganization<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const updateFeaturedLockersForOrganization = <TData = ApiOk>(
    id: number,
    organizationFeaturedLockersRequestModel: OrganizationFeaturedLockersRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${id}/featured-lockers`, method: 'put',
      data: organizationFeaturedLockersRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUpdateFeaturedLockersForOrganization = <TData = AsyncReturnType<typeof updateFeaturedLockersForOrganization,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: OrganizationFeaturedLockersRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: OrganizationFeaturedLockersRequestModel}, TContext>((props) => {
        const {id,data} = props || {};

        return  updateFeaturedLockersForOrganization<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const getGroups = <TData = OrganizationGroupViewModel[]>(
    id: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${id}/groups`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetGroupsQueryKey = (id: number,) => [`/homefield/api/organizations/${id}/groups`]

    
export const useGetGroups = <TQueryFnData = AsyncReturnType<typeof getGroups, OrganizationGroupViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetGroupsQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getGroups<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createGroup = <TData = ApiOkNumber>(
    id: number,
    createOrganizationGroupRequestModel: CreateOrganizationGroupRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/${id}/groups`, method: 'post',
      data: createOrganizationGroupRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateGroup = <TData = AsyncReturnType<typeof createGroup,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: CreateOrganizationGroupRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: CreateOrganizationGroupRequestModel}, TContext>((props) => {
        const {id,data} = props || {};

        return  createGroup<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const editGroup = <TData = ApiOkNumber>(
    groupId: number,
    updateOrganizationGroupRequestModel: UpdateOrganizationGroupRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/groups/${groupId}`, method: 'put',
      data: updateOrganizationGroupRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEditGroup = <TData = AsyncReturnType<typeof editGroup,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{groupId: number;data: UpdateOrganizationGroupRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {groupId: number;data: UpdateOrganizationGroupRequestModel}, TContext>((props) => {
        const {groupId,data} = props || {};

        return  editGroup<TData>(groupId,data,requestOptions)
      }, mutationOptions)
    }
    export const removeGroup = <TData = ApiOkNumber>(
    groupId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/groups/${groupId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRemoveGroup = <TData = AsyncReturnType<typeof removeGroup,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{groupId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {groupId: number}, TContext>((props) => {
        const {groupId} = props || {};

        return  removeGroup<TData>(groupId,requestOptions)
      }, mutationOptions)
    }
    export const setGroupFeatured = <TData = ApiOkNumber>(
    groupId: number,
    updateOrganizationGroupFeaturedRequestModel: UpdateOrganizationGroupFeaturedRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/groups.featured/${groupId}`, method: 'put',
      data: updateOrganizationGroupFeaturedRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useSetGroupFeatured = <TData = AsyncReturnType<typeof setGroupFeatured,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{groupId: number;data: UpdateOrganizationGroupFeaturedRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {groupId: number;data: UpdateOrganizationGroupFeaturedRequestModel}, TContext>((props) => {
        const {groupId,data} = props || {};

        return  setGroupFeatured<TData>(groupId,data,requestOptions)
      }, mutationOptions)
    }
    export const getLockersForGroup = <TData = OrganizationGroupLockerViewModel[]>(
    groupId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/groups/${groupId}/lockers`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetLockersForGroupQueryKey = (groupId: number,) => [`/homefield/api/organizations/groups/${groupId}/lockers`]

    
export const useGetLockersForGroup = <TQueryFnData = AsyncReturnType<typeof getLockersForGroup, OrganizationGroupLockerViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 groupId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLockersForGroupQueryKey(groupId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getLockersForGroup<TQueryFnData>(groupId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const addLockersToGroup = <TData = ApiOk>(
    groupId: number,
    organizationLockersRequestModel: OrganizationLockersRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/groups/${groupId}/lockers`, method: 'post',
      data: organizationLockersRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useAddLockersToGroup = <TData = AsyncReturnType<typeof addLockersToGroup,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{groupId: number;data: OrganizationLockersRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {groupId: number;data: OrganizationLockersRequestModel}, TContext>((props) => {
        const {groupId,data} = props || {};

        return  addLockersToGroup<TData>(groupId,data,requestOptions)
      }, mutationOptions)
    }
    export const reorderLockerGroup = <TData = ApiOk>(
    groupId: number,
    organizationFeaturedLockersRequestModel: OrganizationFeaturedLockersRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/groups/${groupId}/lockers`, method: 'put',
      data: organizationFeaturedLockersRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useReorderLockerGroup = <TData = AsyncReturnType<typeof reorderLockerGroup,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{groupId: number;data: OrganizationFeaturedLockersRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {groupId: number;data: OrganizationFeaturedLockersRequestModel}, TContext>((props) => {
        const {groupId,data} = props || {};

        return  reorderLockerGroup<TData>(groupId,data,requestOptions)
      }, mutationOptions)
    }
    export const removeLockersFromGroup = <TData = ApiOk>(
    groupId: number,
    organizationLockersRequestModel: OrganizationLockersRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/organizations/groups/${groupId}/lockers.remove`, method: 'put',
      data: organizationLockersRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRemoveLockersFromGroup = <TData = AsyncReturnType<typeof removeLockersFromGroup,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{groupId: number;data: OrganizationLockersRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {groupId: number;data: OrganizationLockersRequestModel}, TContext>((props) => {
        const {groupId,data} = props || {};

        return  removeLockersFromGroup<TData>(groupId,data,requestOptions)
      }, mutationOptions)
    }
    