import React from 'react';

interface OwnProps {
  logoUrl?: Nullable<string>;
}

type Props = OwnProps;

const LogoInfo = React.memo<Props>(({ logoUrl }) => (
  <div>
    {
      logoUrl &&
      <img
        src={logoUrl}
        className='flagging__details-logo-image'
      />
    }
  </div>
));

export default LogoInfo;
