import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { productionAssemblyUnFlagItemForm } from '@constants/reduxForms';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import MarkItemAsFlaggedModalLogoDetails from '../../WeedingAndMasking/Flagging/MarkItemAsFlaggedModalLogoDetails';
import MarkItemAsFlaggedModalPersonalizationDetails from '../../WeedingAndMasking/Flagging/MarkItemAsFlaggedModalPersonalizationDetails';

class ProductionAssemblyUnflagItemModal extends PureComponent {
  render() {
    const {
      item,
      closeModal,
      orderNumber,
      colorsDictionary,
      isOpen,
      handleSubmit,
    } = this.props;

    return (
      <Modal
        title={'Unflag Item'}
        modalHeight={'l'}
        modalWidth={'m'}
        isOpen={isOpen}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Unflag'}
            cancelBtnText={'Cancel'}
            onClose={closeModal}
            isDangerousAction={true}
            formSubmission={true}
            formId={productionAssemblyUnFlagItemForm}
          />
        )}
      >
        {
          isOpen &&
          <form
            className='flagging__form'
            onSubmit={handleSubmit}
            id={productionAssemblyUnFlagItemForm}
          >
            <Field
              name={'flagType'}
              component={RadioGroup}
              groupClassName={'flagging__form-radio-group'}
              radioItems={[
                {
                  value: flagTypeEnum.Reprint,
                  disabled: true,
                  label: 'Send to Reprint',
                  className: 'ml-n10',
                },
                {
                  value: flagTypeEnum.Rework,
                  disabled: true,
                  label: 'Send to Rework',
                },
                {
                  value: flagTypeEnum.WrongArtwork,
                  disabled: true,
                  label: 'Send to Artwork Change',
                },
              ]}
            />

            <div className='flagging__form-group'>
              <Field
                name={'reason'}
                component={Select}
                disabled={true}
              >
                <option value={item.flag.reason}>
                  {item.flag.reason}
                </option>
              </Field>
            </div>
            <div className='flagging__form-group'>
              <div className='text-field flex'>
                <Field
                  name={'note'}
                  component={Input}
                  type={'text'}
                  disabled={true}
                />
              </div>
            </div>
            {
              item &&
              (item.logoUrl
                ? (
                  <MarkItemAsFlaggedModalLogoDetails
                    previewUrl={item.previewUrl || item.logoUrl}
                    indicator={item.indicator}
                    orderNumber={orderNumber}
                    size={item.size || item.decorationLocation}
                    type={item.jobType}
                    barcode={item.barcode}
                    cutColor={item.color || item.colorId}
                  />
                )
                : (
                  <MarkItemAsFlaggedModalPersonalizationDetails
                    personalizationNumber={item.number}
                    personalizationText={item.text}
                    personalizationColor={colorsDictionary[item.colorId]}
                    orderNumber={orderNumber}
                    size={item.decorationLocation}
                  />
                )
              )
            }
          </form>
        }
      </Modal>
    );
  }
}

ProductionAssemblyUnflagItemModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.object,
  orderNumber: PropTypes.number,
  orderId: PropTypes.number,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default (reduxForm({
  form: productionAssemblyUnFlagItemForm,
  shouldError: () => true,
  enableReinitialize: true,
})(ProductionAssemblyUnflagItemModal));
