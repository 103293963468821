import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { printAssemblyUnflagItemForm } from '@constants/reduxForms';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import { downloadFile } from '@util/componentHelper';
import { fileNameFromUrl } from '@util/stringHelpers';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import MarkItemAsFlaggedModalLogoDetails from './MarkItemAsFlaggedModalLogoDetails';
import MarkItemAsFlaggedModalPersonalizationDetails from './MarkItemAsFlaggedModalPersonalizationDetails';

class PrintAssemblyUnflagItemModal extends PureComponent {
  render() {
    const {
      item,
      closeModal,
      colorsDictionary,
      isOpen,
      handleSubmit,
    } = this.props;

    const fileName = item && item.flag && item.flag.attachmentUrl ? fileNameFromUrl(item.flag.attachmentUrl) : '';

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title={'Unflag Item'}
        modalSize={'l'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            formSubmission={true}
            confirmBtnText={'Unflag'}
            isDangerousAction={true}
            formId={printAssemblyUnflagItemForm}
          />
        )}
      >
        {
          isOpen &&
          <form
            className='flagging__form'
            onSubmit={handleSubmit}
            id={printAssemblyUnflagItemForm}
          >
            <Field
              name={'flagType'}
              component={RadioGroup}
              groupClassName={'flagging__form-radio-group'}
              radioItems={[
                {
                  value: flagTypeEnum.Reprint,
                  disabled: true,
                  label: 'Send to Reprint',
                  className: 'ml-n10',
                },
                {
                  value: flagTypeEnum.Rework,
                  disabled: true,
                  label: 'Send to Rework',
                },
                {
                  value: flagTypeEnum.WrongArtwork,
                  disabled: true,
                  label: 'Send to Artwork Change',
                },
              ]}
            />
            <div className='flagging__form-group'>
              <Field
                name={'reason'}
                component={Select}
                disabled={true}
              >
                <option value={item.flag.reason}>
                  {item.flag.reason}
                </option>
              </Field>
            </div>
            <div className='flagging__form-group'>
              <div className='text-field flex'>
                <Field
                  name={'note'}
                  component={Input}
                  type={'text'}
                  disabled={true}
                />
              </div>
            </div>
            <div className='flagging__form-group'>
              {
                item.flag.attachmentUrl &&
                <ul className='details__expand-upload-list'>
                  <li>
                    <a
                      href={item.flag.attachmentUrl}
                      onClick={downloadFile(item.flag.attachmentUrl, fileName)}
                    >
                      {fileName}
                    </a>
                  </li>
                </ul>
              }
            </div>
            {
              item.previewUrl
                ? (
                  <MarkItemAsFlaggedModalLogoDetails
                    previewUrl={item.previewUrl}
                    indicator={item.indicator}
                    orderNumber={item.orderNumber}
                    size={item.size}
                    type={item.jobType}
                    barcode={item.barcode}
                    quantity={item.quantity}
                    cutColor={item.cutColor}
                  />
                )
                : (
                  <MarkItemAsFlaggedModalPersonalizationDetails
                    personalizationNumber={item.personalizationNumber}
                    personalizationText={item.personalizationText}
                    personalizationColor={colorsDictionary[item.personalizationColorId]}
                    orderNumber={item.orderNumber}
                    size={item.size}
                    quantity={item.quantity}
                  />
                )
            }
          </form>
        }
      </Modal>
    );
  }
}

PrintAssemblyUnflagItemModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.object,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default (reduxForm({
  form: printAssemblyUnflagItemForm,
  shouldError: () => true,
  enableReinitialize: true,
})(PrintAssemblyUnflagItemModal));
