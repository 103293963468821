import React, { useEffect } from 'react';
import { useGetCollection } from '@api/squadlockerServices/collections';
import { useGetRosterTeamMemberDetails } from '@api/squadlockerServices/rosters';
import { useGetHomefieldApiVouchersVoucherinstances as useGetVoucherinstance } from '@api/financialServices/voucher-homefield';
import { voucherOrderDetailsUrl } from '@constants/clientUrls/clientUrls';
import Link from '@components/shared/Navigation/Link';

interface OwnProps {
  voucherCode: string;
  collectionId: number;
  rosterMemberId: number;
  alignData?: 'row' | 'column';
  classes?: string;
}

type Props = OwnProps;

const VoucherDetailsHeader = React.memo(({
  voucherCode,
  collectionId,
  rosterMemberId,
  alignData = 'row',
  classes = '',
}: Props) => {
  const {
    data: collection,
    refetch: fetchCollection,
  } = useGetCollection(collectionId);

  const {
    data: rosterMember,
    refetch: fetchRosterMember,
  } = useGetRosterTeamMemberDetails(rosterMemberId);

  const {
    data: voucherInstance,
    refetch: fetchVoucherInstance,
  } = useGetVoucherinstance({
    CollectionId: collectionId,
    RosterTeamMemberId: rosterMemberId,
    Code: voucherCode,
  });

  useEffect(() => {
    if (!voucherCode) return;
    fetchVoucherInstance();
  }, [
    fetchVoucherInstance,
    voucherCode,
  ]);

  useEffect(() => {
    if (!collectionId) return;
    fetchCollection();
  }, [
    fetchCollection,
    collectionId,
  ]);

  useEffect(() => {
    if (!rosterMemberId) return;
    fetchRosterMember();
  }, [
    fetchRosterMember,
    rosterMemberId,
  ]);

  return (
    <div className={`order__item__voucher-header align--${alignData} ${classes}`}>
      {
        voucherInstance?.voucherOrderId &&
        <Link
          url={voucherOrderDetailsUrl(voucherInstance.voucherOrderId.toString())}
          text={`VOUCHER: V${voucherInstance.voucherOrderId}`}
          classes={'order__item__voucher-header--code'}
          openInNewTab={true}
        />
      }
      <div className='order__item__voucher-header--additional-data'>
        <div className='info-field'>Collection: {collection?.name ?? '-'}</div>
        <div className='info-field'>Roster Member: {rosterMember?.firstName} {rosterMember?.lastName}</div>
      </div>
    </div>
  );
});

export default VoucherDetailsHeader;
