import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import PersonalizationPriceConfirmationContent from './PersonalizationPriceConfirmationContent';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import {
  OrderItemDetailsDto, UpdateOrderItemPriceCalculationDto,
} from '@api/fulfillment/models';
import {
  OrderDetailsDto,
  OrderItemPricingDetails,
} from '@api/orders/models';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  addPersonalization: () => void;
  personalizationPriceCalculation: UpdateOrderItemPriceCalculationDto | OrderItemPricingDetails;
  orderExistsOnCore: boolean;
  orderItem: Nullable<OrderItemDetailsDto>;
  coreOrder: OrderDetailsDto | undefined;
}

type Props = OwnProps;

const OrderManagementAddPersonalizationPriceConfirmationModal = React.memo<Props>(({
  isOpen,
  closeModal,
  addPersonalization,
  personalizationPriceCalculation,
  orderExistsOnCore,
  orderItem,
  coreOrder,
}) => {
  if (!personalizationPriceCalculation) return null;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'l'}
      modalWidth={'m'}
      isPadded={true}
      title={'Add Personalization - Price Confirmation'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onConfirm={addPersonalization}
          onClose={closeModal}
          confirmBtnDisabled={!(orderExistsOnCore
              || (personalizationPriceCalculation as UpdateOrderItemPriceCalculationDto).canUpdate)}
        />
      )}
    >
      <PersonalizationPriceConfirmationContent
        personalizationPriceCalculation={personalizationPriceCalculation}
        paymentType={accountingActionTypeEnum.Charge}
        orderExistsOnCore={orderExistsOnCore}
        orderItem={orderItem}
        coreOrder={coreOrder}
        isEdit={false}
      />
    </Modal>
  );
});

export default OrderManagementAddPersonalizationPriceConfirmationModal;
