import React, {
  useCallback,
  useState,
} from 'react';
import { formatDollarAmount } from '@util/numberHelpers';
import Icon from '@sharedComponents/Icons/Icon';
import Label from '@sharedComponents/Display/Label';
import { isDefined } from '@util/valueHelpers';

interface OwnProps {
  label: string;
  price?: number | undefined;
  subLabel?: string;
  classes?: string;
  content: JSX.Element;
  disableExpand?: boolean;
}

type Props = OwnProps;

const ExpandablePriceTab = React.memo<Props>(({
  label,
  price,
  subLabel = '',
  classes,
  content,
  disableExpand,
}) => {
  const [
    contentIsExpanded,
    setContentIsExpaneded,
  ] = useState<boolean>(false);

  const changeExpand = useCallback(() => {
    setContentIsExpaneded(!contentIsExpanded);
  }, [contentIsExpanded]);

  return (
    <div>
      <div
        className={`order-financial-details__tab ${!disableExpand ? 'clickable' : ''} ${classes}`}
        onClick={!disableExpand ? changeExpand : undefined}
      >
        <div className='flex'>
          <div className='order-financial-details__tab--text'>
            <span className='text-uppercase'>{label}</span>{` ${subLabel}`}
          </div>
          {
            !disableExpand &&
            <Icon materialIcon={contentIsExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
          }
        </div>
        {
          isDefined(price) &&
          <Label
            text={formatDollarAmount(price)}
            type={'draft'}
          />
        }
      </div>
      {(disableExpand || contentIsExpanded) && content}
    </div>
  );
});

export default ExpandablePriceTab;
