import React from 'react';
import { CollectionDetailsViewModel } from '@api/squadlockerServices/models';
import { collectionForm } from '@constants/reduxForms';
import { LockerProduct } from '@models/LockerManager/LockerProduct';
import { CollectionFormData } from '@models/forms/Collections/CollectionFormData';
import { groupCollectionItems } from '@util/componentHelpers/collectionsHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import CollectionForm from '../CollectionForm';

interface OwnProps {
  isOpen: boolean;
  collection: CollectionDetailsViewModel;
  items: LockerProduct[];
  editCollection: (collectionForm: CollectionFormData) => void;
  closeModal: () => void;
  hasActiveVouchers: boolean;
}

type Props = OwnProps;

const EditCollectionModal = React.memo<Props>(({
  isOpen,
  collection,
  items = [],
  editCollection,
  closeModal,
  hasActiveVouchers,
}) => {
  const groupedCollectionItems = groupCollectionItems(items);

  return (
    <Modal
      title={'Edit Collection'}
      isOpen={isOpen}
      modalHeight={'l'}
      modalWidth={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Save Collection'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={collectionForm}
        />
      )}
    >
      <CollectionForm
        onSubmit={editCollection}
        initialValues={{
          ...collection,
          rosterTeamIds: collection.rosters.map((roster) => roster.id),
          itemGroups: groupedCollectionItems,
        }}
        disabled={{
          disabled: hasActiveVouchers,
          bundled: hasActiveVouchers,
          required: hasActiveVouchers,
        }}
        hasActiveVouchers={hasActiveVouchers}
      />
    </Modal>
  );
});

export default EditCollectionModal;
