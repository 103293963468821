import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import { materialSwal } from '@util/componentHelper';
import {
  getDtgPersonalizationSettings,
  getDecorationLocations,
} from '@redux/productCatalog/actions';
import {
  editDtgPersonalizationSettings, deleteDtgPersonalizationSettings,
} from '@APICalls/dtg/actions';
import { isDefined } from '@util/valueHelpers';
import DtgPersonalizationSettingsAddModal from './DtgPersonalizationSettingsModals/DtgPersonalizationSettingsAddModal';
import DtgPersonalizationSettingsEditModal from './DtgPersonalizationSettingsModals/DtgPersonalizationSettingsEditModal';
import DtgPersonalizationSettingsDeleteModal from './DtgPersonalizationSettingsModals/DtgPersonalizationSettingsDeleteModal';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

const DtgPersonalizationSettingsTable = Table();

class DtgPersonalizationSettings extends PureComponent {
  state = {
    addDtgPersonalizationSettingsModalIsOpen: false,
    editDtgPersonalizationSettingsModalIsOpen: false,
    deleteDtgPersonalizationSettingsModalIsOpen: false,
    selectedSetting: {},
  };

  componentDidMount() {
    this.fetchDtgPersonalizationSettings();
  }

  onEditPersonalizationSettings = async (data) => {
    const result = await editDtgPersonalizationSettings(data);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgPersonalizationSettings();
    }
  };

  onDeletePersonalizationSettings = async (id) => {
    const result = await deleteDtgPersonalizationSettings(id);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgPersonalizationSettings();
    }
  };

  fetchDtgPersonalizationSettings = () => {
    const { dispatch } = this.props;
    dispatch(getDecorationLocations());
    dispatch(getDtgPersonalizationSettings());
  };

  closeModal = () => {
    this.setState({
      addDtgPersonalizationSettingsModalIsOpen: false,
      editDtgPersonalizationSettingsModalIsOpen: false,
      deleteDtgPersonalizationSettingsModalIsOpen: false,
      selectedSetting: {},
    });
  };

  openEditModal = (selectedSetting) => {
    this.setState({
      editDtgPersonalizationSettingsModalIsOpen: true,
      selectedSetting,
    });
  };

  openAddModal = () => {
    this.setState({
      addDtgPersonalizationSettingsModalIsOpen: true,
      selectedSetting: {},
    });
  };

  openDeleteModal = (selectedSetting) => {
    this.setState({
      deleteDtgPersonalizationSettingsModalIsOpen: true,
      selectedSetting,
    });
  };

  getColumns = () => [
    {
      Header: 'Decoration Location',
      accessor: 'decorationLocationCode',
      minWidth: 70,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Decoration Size',
      accessor: 'decorationSize',
      minWidth: 70,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Rotation',
      accessor: 'rotation',
      minWidth: 70,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Name Width',
      accessor: '',
      minWidth: 70,
      Cell: ({ value }) => {
        const {
          printerOverrideNameWidth,
          defaultNameWidth,
        } = value;
        if (isDefined(printerOverrideNameWidth)) {
          return <span>{printerOverrideNameWidth} (Override)</span>;
        }
        if (isDefined(defaultNameWidth)) {
          return <span>{defaultNameWidth} (Default)</span>;
        }

        return <i>N/A</i>;
      },
    },
    {
      Header: 'Name Height',
      accessor: '',
      minWidth: 70,
      Cell: ({ value }) => {
        const {
          printerOverrideNameHeight,
          defaultNameHeight,
        } = value;
        if (isDefined(printerOverrideNameHeight)) {
          return <span>{printerOverrideNameHeight} (Override)</span>;
        }
        if (isDefined(defaultNameHeight)) {
          return <span>{defaultNameHeight} (Default)</span>;
        }

        return <i>N/A</i>;
      },
    },
    {
      Header: 'Number Height w/ Name',
      accessor: '',
      minWidth: 70,
      Cell: ({ value }) => {
        const {
          printerOverrideNumberHeightWithName,
          defaultNumberWidth,
        } = value;
        if (isDefined(printerOverrideNumberHeightWithName)) {
          return <span>{printerOverrideNumberHeightWithName} (Override)</span>;
        }
        if (isDefined(defaultNumberWidth)) {
          return <span>{defaultNumberWidth} (Default)</span>;
        }

        return <i>N/A</i>;
      },
    },
    {
      Header: 'Number Height w/o Name',
      accessor: '',
      minWidth: 70,
      Cell: ({ value }) => {
        const {
          printerOverrideNumberHeightWithoutName,
          defaultNumberWidth,
        } = value;
        if (isDefined(printerOverrideNumberHeightWithoutName)) {
          return <span>{printerOverrideNumberHeightWithoutName} (Override)</span>;
        }
        if (isDefined(defaultNumberWidth)) {
          return <span>{defaultNumberWidth} (Default)</span>;
        }

        return <i>N/A</i>;
      },
    },
    {
      Header: 'Actions',
      accessor: '',
      resizable: false,
      minWidth: 40,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <ShowMoreActionsButton
          actions={[
            {
              text: 'Edit',
              action: this.openEditModal.bind(null, cellProps.value),
              isVisible: true,
            },
            {
              text: 'Delete',
              action: this.openDeleteModal.bind(null, cellProps.value),
              isDangerous: true,
              isVisible: true,
            },
          ]}
        />
      ),
    },
  ];

  render() {
    const {
      decorationLocations,
      dtgPersonalizationSettings,
    } = this.props;
    const {
      addDtgPersonalizationSettingsModalIsOpen,
      editDtgPersonalizationSettingsModalIsOpen,
      deleteDtgPersonalizationSettingsModalIsOpen,
      selectedSetting,
    } = this.state;

    return (
      <div className='container'>
        <DtgPersonalizationSettingsAddModal
          isOpen={addDtgPersonalizationSettingsModalIsOpen}
          closeModal={this.closeModal}
          onSubmit={this.onEditPersonalizationSettings}
          decorationLocations={decorationLocations}
        />
        <DtgPersonalizationSettingsEditModal
          isOpen={editDtgPersonalizationSettingsModalIsOpen}
          closeModal={this.closeModal}
          onSubmit={this.onEditPersonalizationSettings}
          initialValues={selectedSetting}
          decorationLocations={decorationLocations}
        />
        <DtgPersonalizationSettingsDeleteModal
          isOpen={deleteDtgPersonalizationSettingsModalIsOpen}
          closeModal={this.closeModal}
          selectedSetting={selectedSetting}
          onDelete={this.onDeletePersonalizationSettings}
        />
        <div className='table-options w-100'>
          <div className='flex' />
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add New Setting'}
              onClick={this.openAddModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>
        <br />
        <div className='sheet'>
          <DtgPersonalizationSettingsTable
            data={dtgPersonalizationSettings}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

DtgPersonalizationSettings.propTypes = {
  decorationLocations: PropTypes.array.isRequired,
  dtgPersonalizationSettings: PropTypes.array.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  decorationLocations: productCatalog.decorationLocations,
  dtgPersonalizationSettings: productCatalog.dtgPersonalizationSettings,
});

export default connect(mapStateToProps)(DtgPersonalizationSettings);
