import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  getComparableColoredStyleObjects,
  getComparableProductsLists,
} from '@util/componentHelpers/productCatalogSyncHelper';
import Button from '@sharedComponents/Buttons/Button';
import SyncStyleDataTable from './SyncStyleDataTable';
import SyncStyleActionModal from './SyncStyleActionModal';

class SyncStyleColoredStyleDetails extends PureComponent {
  state = {
    isSyncColoredStyleModalOpen: false,
    isSyncProductsModalOpen: false,
  };

  openSyncColoredStyleModal = () => {
    this.setState(() => ({ isSyncColoredStyleModalOpen: true }));
  };

  closeSyncColoredStyleModal = () => {
    this.setState(() => ({ isSyncColoredStyleModalOpen: false }));
  };

  openSyncProductsModal = () => {
    this.setState(() => ({ isSyncProductsModalOpen: true }));
  };

  closeSyncProductsModal = () => {
    this.setState(() => ({ isSyncProductsModalOpen: false }));
  };

  render() {
    const {
      coloredStyle,
      coloredStyleFromSSAPI,
      products,
      productsFromSSAPI,
      syncColoredStyle,
      syncColoredStyleProducts,
    } = this.props;

    const {
      isSyncColoredStyleModalOpen,
      isSyncProductsModalOpen,
    } = this.state;

    const coloredStyleId = coloredStyle.id;

    const {
      catalogColoredStyleObj,
      slServicesColoredStyleObj,
    } = getComparableColoredStyleObjects(coloredStyle, coloredStyleFromSSAPI);

    const {
      skusFromProductCatalog,
      skusFromSlServicesApi,
    } = getComparableProductsLists(products, productsFromSSAPI);

    return (
      <div className='sync-details__container'>
        <SyncStyleActionModal
          isOpen={isSyncColoredStyleModalOpen}
          closeModal={this.closeSyncColoredStyleModal}
          id={coloredStyleId}
          label={`colored style ${coloredStyle.colorGroup}`}
          syncData={syncColoredStyle}
        />

        <SyncStyleActionModal
          isOpen={isSyncProductsModalOpen}
          closeModal={this.closeSyncProductsModal}
          id={coloredStyleId}
          label={'products'}
          syncData={syncColoredStyleProducts}
        />

        <div className='flex'>
          <div className='w-50'>
            <SyncStyleDataTable
              data={catalogColoredStyleObj}
              headerLabel={'Product Catalog'}
            />
          </div>
          <div className='w-50'>
            <SyncStyleDataTable
              data={slServicesColoredStyleObj}
              headerLabel={'SL Services API'}
            />
          </div>
        </div>

        <div className='sync-details__button mr-20'>
          <Button
            type={'primary'}
            text={'Sync colored style'}
            onClick={this.openSyncColoredStyleModal}
            classes={'lockerManagerEdit__button--add'}
          />
        </div>

        <div className='sync-details__products-container'>
          <div className='tableView__details--header--title mb-10 mt-30 ml-20'>
            <span>Products</span>
          </div>
          <div className='flex'>
            <div className='w-50'>
              <SyncStyleDataTable
                data={skusFromProductCatalog}
                headerLabel={'Product Catalog'}
                shouldWarnWhenNoData={false}
              />
            </div>
            <div className='w-50'>
              <SyncStyleDataTable
                data={skusFromSlServicesApi}
                headerLabel={'SL Services API'}
                shouldWarnWhenNoData={skusFromProductCatalog.length}
              />
            </div>
          </div>

          <div className='sync-details__button mr-20'>
            <Button
              type={'primary'}
              text={'Sync products'}
              onClick={this.openSyncProductsModal}
              classes={'lockerManagerEdit__button--add'}
            />
          </div>
        </div>
      </div>
    );
  }
}

SyncStyleColoredStyleDetails.propTypes = {
  coloredStyle: PropTypes.object.isRequired,
  coloredStyleFromSSAPI: PropTypes.object,
  products: PropTypes.array,
  productsFromSSAPI: PropTypes.array,
  syncColoredStyle: PropTypes.func.isRequired,
  syncColoredStyleProducts: PropTypes.func.isRequired,
};

export default SyncStyleColoredStyleDetails;
