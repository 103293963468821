import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import { materialSwal } from '@util/componentHelper';
import { updateLogoImage } from '@APICalls/logos/actions';

class ReplaceLogo extends Component {
  state = {
    attachedFile: null,
  };

  updateLogo = async () => {
    const {
      logoId,
      refreshLogos,
      openSwatchModal,
    } = this.props;

    const { attachedFile } = this.state;

    const updatedLogo = await updateLogoImage(logoId, attachedFile);
    if (updatedLogo && updatedLogo.result) {
      materialSwal('Success', `${attachedFile[0].name} uploaded`);
      refreshLogos();

      openSwatchModal(updatedLogo.result.logoId);
    }
  };

  onLogoReplace = (acceptedFiles, rejectedFiles) => {
    for (const file of acceptedFiles.concat(rejectedFiles)) {
      window.URL.revokeObjectURL(file.preview);
    }
    this.updateLogo();
  };

  onLogoReplacementDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      attachedFile: acceptedFiles,
    }, this.onLogoReplace.bind(null, acceptedFiles, rejectedFiles));
  };

  onLogoReplaceClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const uploadReplacementText = 'Replace Logo Image';

    return (
      this.props.logoOrderCount === 0 && (
        <button
          style={{ padding: '6px' }}
          onClick={this.onLogoReplaceClick}
        >
          <DropZoneWrapper
            onDrop={this.onLogoReplacementDrop}
            className={'block-upload-inner'}
            multiple={false}
          >
            {uploadReplacementText}
          </DropZoneWrapper>
        </button>
      )
    );
  }
}

ReplaceLogo.propTypes = {
  logoId: PropTypes.number,
  refreshLogos: PropTypes.func.isRequired,
  openSwatchModal: PropTypes.func.isRequired,
};

export default ReplaceLogo;
