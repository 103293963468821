import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const CollectionDetailsHeader = React.memo(({ collection }) => (
  <div className='bar__long'>
    <div className='header__bar-content'>
      <h2 className='header__bar-title'>{collection && collection.name}</h2>
    </div>
  </div>
));

CollectionDetailsHeader.propTypes = {
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    bundled: PropTypes.bool,
    lockerId: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ collections }) => ({
  collection: collections.collection,
});

export default connect(mapStateToProps)(CollectionDetailsHeader);
