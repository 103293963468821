import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  usePostHomefieldApiVouchersOrders as usePostVoucherOrder,
  usePostHomefieldApiVouchersOrderspreview as usePostVoucherOrderPreview,
} from '@api/financialServices/voucher-homefield';
import {
  VoucherCreationAdminViewModel as Admin,
  VoucherCreationLockerViewModel as Locker,
} from '@api/squadlockerServices/models';
import {
  voucherOrdersUrl,
  voucherOrderDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import {
  VoucherNotificationLinkDestinationEnum,
  VoucherNotificationSenderNameEnum,
} from '@api/financialServices/models';
import { VoucherOrderCreationSteps } from '@constants/enums/voucherEnums';
import { RootState } from '@redux/index/reducers';
import * as vouchersActions from '@redux/vouchers/actions';
import {
  materialSwal,
  navigateToPage,
} from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import BackLink from '@sharedComponents/Navigation/BackLink';
import TableFilters from '@sharedComponents/Display/TableFilters/TableFilters';
import TextButton from '@sharedComponents/Buttons/TextButton';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import LockersAndOrganizationsStep from './AddVoucherOrderContent/LockersAndOrganizationsStep';
import CollectionsAndRostersStep, { VoucherCreationRosterViewModelCustom } from './AddVoucherOrderContent/CollectionsAndRostersStep';
import ConfigurationStep from './AddVoucherOrderContent/ConfigurationStep';
import VoucherOrderPreviewModal from './VoucherOrderPreviewModal';
import { maxCustomSenderNameLength } from '@constants/values';

const AddVoucherOrderStepsDisplay = {
  [VoucherOrderCreationSteps.LockersAndOrganizations]: LockersAndOrganizationsStep,
  [VoucherOrderCreationSteps.CollectionsAndRosters]: CollectionsAndRostersStep,
  [VoucherOrderCreationSteps.Configuration]: ConfigurationStep,
} as const;

const backButtonDisplayedSteps: VoucherOrderCreationSteps[] = [
  VoucherOrderCreationSteps.CollectionsAndRosters,
  VoucherOrderCreationSteps.Configuration,
];

const nextButtonDisplayedSteps: VoucherOrderCreationSteps[] = [
  VoucherOrderCreationSteps.LockersAndOrganizations,
  VoucherOrderCreationSteps.CollectionsAndRosters,
];

const previewButtonDisplayedSteps: VoucherOrderCreationSteps[] = [VoucherOrderCreationSteps.Configuration];

const mapStateToProps = ({ vouchers }: RootState) => ({
  currentStep: vouchers.voucherOrderCreationCurrentStep,
});

const mapDispatchToProps = {
  updateCurrentStep: vouchersActions.updateCurrentVoucherOrderCreationStep,
  resetCurrentStep: vouchersActions.resetCurrentVoucherOrderCreationStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const AddVoucherOrder = React.memo<Props>(({
  currentStep,
  updateCurrentStep,
  resetCurrentStep,
}) => {
  const [
    selectedLockers,
    setSelectedLockers,
  ] = useState<Locker[]>([]);
  const [
    selectedRosterCollections,
    setSelectedRosterCollections,
  ] = useState<VoucherCreationRosterViewModelCustom[]>([]);
  const [
    validFromDate,
    setValidFromDate,
  ] = useState<Nullable<string>>(null);
  const [
    validToDate,
    setValidToDate,
  ] = useState<Nullable<string>>(null);
  const [
    selectedAdmin,
    setSelectedAdmin,
  ] = useState<Nullable<Admin>>(null);

  const [
    sendDate,
    setSendDate,
  ] = useState<Nullable<string>>(null);
  const [
    linkDestination,
    setLinkDestination,
  ] = useState<Nullable<VoucherNotificationLinkDestinationEnum>>(null);
  const [
    senderNameType,
    setSenderNameType,
  ] = useState<Nullable<VoucherNotificationSenderNameEnum>>(null);
  const [
    customSenderName,
    setCustomSenderName,
  ] = useState<Nullable<string>>(null);
  const [
    discountPercent,
    setDiscountPercent,
  ] = useState<number>(0);

  const [
    previewModalIsOpen,
    setPreviewModalOpen,
  ] = useState<boolean>(false);
  const [
    confirmationModalIsOpen,
    setConfirmationModalOpen,
  ] = useState<boolean>(false);

  // Reset the step on component unmount
  useEffect(() => (() => {
    resetCurrentStep();
  }), [resetCurrentStep]);

  const updateSelectedLockers = useCallback((lockers: Locker[]) => {
    setSelectedLockers(lockers);
    const lockerIds = lockers.map((l) => l.id);
    setSelectedRosterCollections(selectedRosterCollections.filter((r) => lockerIds.includes(r.lockerId)));
  }, [selectedRosterCollections]);

  const updateSelectedRosterCollections = useCallback((rosterCollections: VoucherCreationRosterViewModelCustom[]) => {
    setSelectedRosterCollections(rosterCollections);
  }, []);

  const updateValidFromDate = useCallback((value: Nullable<string>) => {
    setValidFromDate(value);
  }, []);

  const updateValidToDate = useCallback((value: Nullable<string>) => {
    setValidToDate(value);
  }, []);

  const updateSelectedAdmin = useCallback((value: Nullable<Admin>) => {
    setSelectedAdmin(value);
  }, []);

  const updateSendDate = useCallback((value: Nullable<string>) => {
    setSendDate(value);
  }, []);

  const updateLinkDestination = useCallback((value: Nullable<VoucherNotificationLinkDestinationEnum>) => {
    setLinkDestination(value);
  }, []);

  const updateSenderNameType = useCallback((value: Nullable<VoucherNotificationSenderNameEnum>) => {
    setSenderNameType(value);
  }, []);

  const updateCustomSenderName = useCallback((value: Nullable<string>) => {
    setCustomSenderName(value);
  }, []);

  const updateDiscountPercent = useCallback((value: Nullable<number>) => {
    setDiscountPercent(value ?? 0);
  }, []);

  const goToNextPage = useCallback(() => {
    if (currentStep === VoucherOrderCreationSteps.LockersAndOrganizations) {
      updateCurrentStep(VoucherOrderCreationSteps.CollectionsAndRosters);
    } else if (currentStep === VoucherOrderCreationSteps.CollectionsAndRosters) {
      updateCurrentStep(VoucherOrderCreationSteps.Configuration);
    }
  }, [
    currentStep,
    updateCurrentStep,
  ]);

  const goToPreviousPage = useCallback(() => {
    if (currentStep === VoucherOrderCreationSteps.Configuration) {
      updateCurrentStep(VoucherOrderCreationSteps.CollectionsAndRosters);
    } else if (currentStep === VoucherOrderCreationSteps.CollectionsAndRosters) {
      updateCurrentStep(VoucherOrderCreationSteps.LockersAndOrganizations);
    }
  }, [
    currentStep,
    updateCurrentStep,
  ]);

  const previewVoucherOrder = useCallback(() => {
    setPreviewModalOpen(true);
  }, []);

  const closePreviewModal = useCallback(() => {
    setPreviewModalOpen(false);
  }, []);

  const openConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const selectedLockersRosterCollections = useMemo(() => (
    selectedRosterCollections?.map((x) => ({
      collectionId: x.collections[0].id,
      rosterTeamId: x.id,
    }))
  ), [selectedRosterCollections]);
  const selectedLockerIds = useMemo(() => (selectedLockers?.map((x) => x.id)), [selectedLockers]);
  const selectedOrganizationId = useMemo(() => (selectedLockers[0]?.organizationId), [selectedLockers]);

  const {
    data: voucherOrderPreviewData,
    isLoading: voucherOrderPreviewDataLoading,
    mutateAsync: createVoucherOrderPreview,
  } = usePostVoucherOrderPreview(
    { request: { isBlockingRequest: false } }
  );

  const onVoucherOrderCreated = (response: any) => {
    materialSwal('Success', response.message, 'success');
    navigateToPage(voucherOrderDetailsUrl(response.result.id));
  };

  const { mutateAsync: createVoucherOrderPost } = usePostVoucherOrder({
    mutation: {
      onSuccess: onVoucherOrderCreated,
    },
  });

  useEffect(() => {
    if (!selectedLockersRosterCollections.length
      || currentStep !== VoucherOrderCreationSteps.Configuration) {
      return;
    }

    createVoucherOrderPreview({
      data: {
        lockerId: !!selectedOrganizationId ? null : selectedLockerIds[0],
        organizationId: selectedOrganizationId,
        collections: selectedLockersRosterCollections,
        voucherOrderDiscountPercent: (discountPercent ?? 0) / 100.0,
      },
    });
  }, [
    selectedOrganizationId,
    selectedLockerIds,
    createVoucherOrderPreview,
    selectedLockersRosterCollections,
    currentStep,
    discountPercent,
  ]);

  const createVoucherOrder = useCallback(() => {
    createVoucherOrderPost({
      data: {
        lockerId: !!selectedOrganizationId ? null : selectedLockerIds[0],
        organizationId: selectedOrganizationId,
        collections: selectedLockersRosterCollections,
        startDate: validFromDate!,
        endDate: validToDate,
        lockerManagerId: selectedAdmin!.id,
        notificationSettings: {
          sendDate: sendDate!,
          linkDestination: linkDestination as VoucherNotificationLinkDestinationEnum,
          senderName: senderNameType as VoucherNotificationSenderNameEnum,
          customSenderName,
        },
        discountPercent: (discountPercent ?? 0) / 100.0,
      },
    });
    closePreviewModal();
  }, [
    selectedOrganizationId,
    selectedLockerIds,
    selectedLockersRosterCollections,
    validFromDate,
    validToDate,
    selectedAdmin,
    sendDate,
    linkDestination,
    senderNameType,
    customSenderName,
    discountPercent,
    createVoucherOrderPost,
    closePreviewModal,
  ]);

  const isBackButtonDisplayed: boolean = backButtonDisplayedSteps.includes(currentStep);
  const isNextButtonDisplayed: boolean = nextButtonDisplayedSteps.includes(currentStep);
  const previewButtonDisplayed: boolean = previewButtonDisplayedSteps.includes(currentStep);
  const isNextButtonEnabled: boolean =
    currentStep === VoucherOrderCreationSteps.LockersAndOrganizations && selectedLockers.length > 0
    || currentStep === VoucherOrderCreationSteps.CollectionsAndRosters && selectedRosterCollections.length > 0;

  const validToDateError = !!validFromDate && !!validToDate && validToDate <= validFromDate
    ? 'Valid To date must be after Valid From date'
    : null;

  const sendDateError = !!sendDate && !!validToDate && validToDate <= sendDate
    ? 'Send Date must be before Valid To date'
    : null;

  let customSenderNameError = !!customSenderName && customSenderName.length > maxCustomSenderNameLength
    ? `Custom sender name exceeds the character limit of ${maxCustomSenderNameLength}`
    : null;

  if (!customSenderName && senderNameType === VoucherNotificationSenderNameEnum.CustomName) {
    customSenderNameError = 'Custom sender name is required';
  }

  const discountPercentError = !!discountPercent && (discountPercent < 0 || discountPercent > 100)
    ? 'Enter a discount between 0 and 100%'
    : null;

  const isPreviewButtonEnabled: boolean = !!validFromDate
    && !!selectedAdmin
    && !!sendDate
    && !!linkDestination
    && !!senderNameType
    && !validToDateError
    && !sendDateError
    && !customSenderNameError
    && !discountPercentError;

  const backLinkConfirmationRequired = currentStep === VoucherOrderCreationSteps.Configuration
    || currentStep === VoucherOrderCreationSteps.CollectionsAndRosters
    || !!selectedLockers.length;

  const CurrentStep = AddVoucherOrderStepsDisplay[currentStep];

  const selectedLocker = selectedLockers[0] ?? null;
  const organizationName = selectedLocker?.organizationName;

  return (
    <>
      <SimpleConfirmationModal
        isOpen={confirmationModalIsOpen}
        closeModal={closeConfirmationModal}
        title={'Confirmation'}
        cancelBtnText={'Back'}
        confirm={createVoucherOrder}
        confirmationBody={(
          <span>
            Confirming this action will result in a voucher payment link being sent to the selected admin
            &nbsp;<b>{selectedAdmin?.firstName} {selectedAdmin?.lastName}</b>
            &nbsp;for <b>{organizationName || selectedLocker?.name}</b>.
          </span>
        )}
      />
      <div className='container'>
        {
          currentStep === VoucherOrderCreationSteps.Configuration &&
          <VoucherOrderPreviewModal
            isOpen={previewModalIsOpen}
            closeModal={closePreviewModal}
            selectedLockers={selectedLockers}
            voucherOrderPreviewData={voucherOrderPreviewData?.result}
            voucherOrderPreviewDataLoading={voucherOrderPreviewDataLoading}
            openConfirmationModal={openConfirmationModal}
          />
        }

        <TableFilters
          leftGroup={(
            <BackLink
              text={'Voucher Orders'}
              url={voucherOrdersUrl}
              confirmationRequired={backLinkConfirmationRequired}
              confirmationTitle={'Discard Selected Items'}
              confirmationText={'Navigating back from this page will discard your item selection. Are you sure?'}
            />
          )}
          rightGroup={(
            <>
              {
                isBackButtonDisplayed &&
                <TextButton
                  text={'Back'}
                  onClick={goToPreviousPage}
                  classes={'mr-40'}
                />
              }
              {
                isNextButtonDisplayed &&
                <Button
                  type={'primary'}
                  text={'Next'}
                  onClick={goToNextPage}
                  disabled={!isNextButtonEnabled}
                />
              }
              {
                previewButtonDisplayed &&
                <Button
                  type={'primary'}
                  text={'Preview'}
                  onClick={previewVoucherOrder}
                  disabled={!isPreviewButtonEnabled}
                />
              }
            </>
          )}
          classes={'mb-20'}
        />
        <CurrentStep
          selectedOrganizationId={selectedOrganizationId}
          selectedLockers={selectedLockers}
          selectedRosterCollections={selectedRosterCollections}
          validFromDate={validFromDate}
          validToDate={validToDate}
          selectedAdminId={selectedAdmin?.id ?? null}
          sendDate={sendDate}
          linkDestination={linkDestination}
          senderNameType={senderNameType}
          customSenderName={customSenderName}
          discountPercent={discountPercent}
          updateSelectedLockers={updateSelectedLockers}
          updateSelectedRosterCollections={updateSelectedRosterCollections}
          updateValidFromDate={updateValidFromDate}
          updateValidToDate={updateValidToDate}
          updateSelectedAdmin={updateSelectedAdmin}
          updateSendDate={updateSendDate}
          updateLinkDestination={updateLinkDestination}
          updateSenderNameType={updateSenderNameType}
          updateCustomSenderName={updateCustomSenderName}
          updateDiscountPercent={updateDiscountPercent}
          validToDateError={validToDateError}
          sendDateError={sendDateError}
          customSenderNameError={customSenderNameError}
          discountPercentError={discountPercentError}
          voucherOrderPreviewData={voucherOrderPreviewData?.result}
        />
      </div>
    </>
  );
});

export default connector(AddVoucherOrder);
