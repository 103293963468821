import { useState, useMemo } from 'react';

const useMouseDown = (setXCallback, setYCallback) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const selectedBind = useMemo(
    () => ({
      onMouseDown: (event) => {
        event.preventDefault();
        event.target.focus();

        const { naturalHeight, naturalWidth } = event.nativeEvent.target;
        const { width, height } = event.target;
        const { offsetX, offsetY } = event.nativeEvent;
        const { xMultiplier, yMulitiplier } = getMultiplier(naturalWidth, naturalHeight, width, height);

        const xval = Math.floor(offsetX * xMultiplier);
        setX(xval);
        if (setXCallback) {
          setXCallback(xval);
        }

        const yval = Math.floor(offsetY * yMulitiplier);
        setY(yval);
        if (setYCallback) {
          setYCallback(yval);
        }
      },
      onContextMenu: (event) => {
        event.preventDefault();
      },
    }),
    [setXCallback, setYCallback]
  );

  const resetMouse = () => {
    setX(0);
    setY(0);
  };

  return {
    x,
    y,
    selectedBind,
    resetMouse,
  };
};

function getMultiplier(originalWidth, originalHeight, currentWidth, currentHeight) {
  return {
    xMultiplier: originalWidth / currentWidth,
    yMulitiplier: originalHeight / currentHeight,
  };
}
export default useMouseDown;
