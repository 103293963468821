import { buildQueryString } from '@util/stringHelpers';

export const adminsUrl = 'homefield/api/admins' as const;

export const admins = (
  pageNumber: number,
  pageSize: number,
  sortColumn?: string,
  sortDirection?: string,
  minDatePlaced?: string,
  maxDatePlaced?: string,
  search?: string,
  organizationId?: string,
  lockerId?: string,
  accountExecutives: string[] = []
): string => `${adminsUrl}${buildQueryString({
  pageNumber,
  pageSize,
  sortColumn,
  sortDirection,
  minDatePlaced,
  maxDatePlaced,
  organizationId,
  lockerId,
  search,
  accountExecutives,
})}`;

export const admin = (adminId: number): string => `${adminsUrl}/${adminId}`;

export const adminSuggestions = (filter: string, limit: number): string => `${adminsUrl}/suggestions${buildQueryString({
  filter: encodeURIComponent(filter),
  limit,
})}`;

export const adminsByLockerId = (
  lockerId: number,
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: string
) => `${adminsUrl}/lockers/${lockerId}${buildQueryString({
  pageNumber,
  pageSize,
  sortColumn,
  sortDirection,
})}`;

export default {
  admins,
  admin,
  adminSuggestions,
  adminsByLockerId,
};
