import React from 'react';
import PropTypes from 'prop-types';

const SelectCell = ({
  item,
  isSelected,
  select,
  isDisabled = false,
}) => {
  const selectOnClick = (e) => {
    select(item, isSelected);

    e.preventDefault();
    e.stopPropagation();
  };

  if (isDisabled) {
    return (
      <i className='material-icons logo-review__table__icon--disabled'>lock</i>
    );
  }

  if (isSelected) {
    return (
      <div
        onClick={selectOnClick}
        className='material-icons button logo-bank__assignment--green'
      >
        check_box
      </div>
    );
  }

  return (
    <div
      onClick={selectOnClick}
      className='material-icons button logo-bank__assignment--square'
    >
      check_box_outline_blank
    </div>
  );
};

SelectCell.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.object.isRequired,
  ]).isRequired,
  select: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export default SelectCell;
