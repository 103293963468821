import { buildQueryString } from '@util/stringHelpers';

export const backorderManagement = 'homefield/api/backorder-management' as const;
export const orders = `${backorderManagement}/orders` as const;

export const backorderManagementTable = (
  pageNumber: number,
  pageSize: number,
  filter: string,
  sortBy: string,
  sortDirection: string,
  previouslyNotified?: boolean,
  discontinued?: boolean
): string => `${orders}${buildQueryString({
  pageNumber,
  pageSize,
  filter,
  sortBy,
  sortDirection,
  previouslyNotified,
  discontinued,
}, true)}`;

export const orderItems = (
  orderNumber: number,
  previouslyNotified?: boolean,
  discontinued?: boolean
): string => `${orders}/${orderNumber}/items${buildQueryString({
  previouslyNotified,
  discontinued,
}, true)}`;

export const notificationHistory = (orderNumber: number): string => `${orders}/${orderNumber}/notification-history`;

export const sendAllOrderNotifications = `${orders}.send-all-notifications` as const;
export const sendOrderNotifications = `${orders}.send-notifications` as const;
export const sendOrderItemNotifications = `${backorderManagement}/order-items.send-notifications` as const;

export const markAllOrdersAsNotificationSent = `${orders}.mark-all-notifications-sent` as const;
export const markOrdersAsNotificationSent = `${orders}.mark-notifications-sent` as const;
export const markOrderItemsAsNotificationSent = `${backorderManagement}/order-items.mark-notifications-sent` as const;

export default {
  backorderManagementTable,
  orderItems,
  notificationHistory,
  sendOrderNotifications,
  sendOrderItemNotifications,
  sendAllOrderNotifications,
  markOrdersAsNotificationSent,
  markAllOrdersAsNotificationSent,
  markOrderItemsAsNotificationSent,
};
