import React from 'react';

const DrawerOverlay = React.memo(() => (
  <div
    id='overlay'
    data-toggle='drawer-off'
  />
));

export default DrawerOverlay;
