export const rosterNotificationsSenderNameTypeEnum = {
  OrganizationName: 'Organization Name',
  StoreName: 'Store Name',
  RosterName: 'Roster Name',
  CustomName: 'Custom Name',
} as const;

export const rosterNotificationsLinkDestinationEnum = {
  OrganizationLandingPage: 'Organization Landing Page',
  Storefront: 'Storefront',
  CollectionLandingPage: 'Collection Landing Page',
} as const;
