import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';

const CustomItemIsHiddenFormCheckbox = React.memo(({
  isCustomItem,
  change,
  unhidingItemDisabled,
  disabled,
}) => (
  <div className='w-100 mt-10'>
    <div className='info__field'>
      <Field
        name={'customItemIsHidden'}
        text={isCustomItem ? 'Hide Custom Item' : 'Hide Item'}
        component={ReduxFormCheckbox}
        change={change}
        disabled={unhidingItemDisabled || disabled.customItemIsHidden}
        showLockIcon={true}
        tooltipText={unhidingItemDisabled ? 'Hidden until valid Design ID is entered' : ''}
      />
    </div>
  </div>
));

CustomItemIsHiddenFormCheckbox.propTypes = {
  change: PropTypes.func.isRequired,
  unhidingItemDisabled: PropTypes.bool.isRequired,
};

export default CustomItemIsHiddenFormCheckbox;
