import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from '@constants/values';
import { Controller } from 'react-hook-form';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import FormError from './FormError';

const HookformDatePicker = ({ id, name, control, placeholder, error, required, className = '', label = '', disabled = false }) => (
  <div style={{
    width: '45%',
    marginBottom: '1rem',
  }}
  >
    <ThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Controller
          name={name}
          control={control}
          rules={{ required: required ? `${placeholder || name} is required` : false }}
          // eslint-disable-next-line react/jsx-no-bind
          render={({ field: { ref, value, ...rest } }) => (
            <div className={`lockerEdit__information-fields--column ${className}`}>
              {label && <p>{label}</p>}
              <KeyboardDatePicker
                id={id}
                inputRef={ref}
                margin='dense'
                fullWidth={true}
                variant='dialog'
                required={required}
                format='MM/DD/YYYY'
                placeholder={placeholder}
                error={!!error}
                disabled={disabled}
                // needed so that the datepicker will not show current date when the value is undefined
                value={!value ? null : value}
                KeyboardButtonProps={{
                  'aria-label': placeholder || '',
                }}
                FormHelperTextProps={{ style: { color: 'red' } }}
                {...rest}
              />
            </div>
          )}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
    {
      error &&
      <FormError
        error={error}
        fieldError={true}
      />
    }
  </div>
);

export default HookformDatePicker;
