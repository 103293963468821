export const mapVendorErrors = (error, validationErrors) => ({
  _error: error.message,
  id: validationErrors.Id,
  name: validationErrors.Name,
  rowHash: validationErrors.RowHash,
  deleted: validationErrors.Deleted,
  leadTime: validationErrors.LeadTime,
  dropshipVFIs: validationErrors.DropshipVFIs,
  vfiOrderAutoSubmit: validationErrors.VFIOrderAutoSubmit,
  vfiOrderTreshold: validationErrors.VFIOrderTreshold,
  vfiOrderTimeHours: validationErrors.VFIOrderTimeHours,
  vfiMaxPersonalizationNameLength: validationErrors.vfiMaxPersonalizationNameLength,
});

export const mapVendorTimelineErrors = (error, validationErrors) => ({
  _error: error.message,
  description: validationErrors.Description,
  timelineDays: validationErrors.TimelineDays,
  rowHash: validationErrors.RowHash,
});
