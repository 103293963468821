import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { minInputLengthForSuggestions } from '@constants/values';
import { discountTypeEnum } from '@constants/enums/couponEnums';
import { findCouponSuggestions } from '@redux/orderManagement/actions';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import InfoField from '@sharedComponents/Display/InfoField';
import Icon from '@sharedComponents/Icons/Icon';
import CouponSuggestion from '../CouponSuggestion';
import { couponTypeEnum } from '@constants/enums/orderEnums';

class OrderManagementAddCouponModal extends Component {
  state = {
    couponFound: false,
    couponFoundMessage: '',
    couponExtraMessage: '',
    couponAppliesToShippingMessage: '',
    isInputActive: false,
    showSuggestions: false,
    searchInput: '',
    throttledCall: null,
    couponSuggestions: [],
    coupons: [],
    couponCode: null,
    couponType: null,
    couponValue: null,
    couponAppliesToShipping: null,
  };

  resetState = () => {
    this.setState({
      couponFound: false,
      couponFoundMessage: '',
      couponExtraMessage: '',
      couponAppliesToShippingMessage: '',
      isInputActive: false,
      showSuggestions: false,
      searchInput: '',
      throttledCall: null,
      couponSuggestions: [],
      coupons: [],
      couponCode: null,
      couponType: null,
      couponValue: null,
      couponAppliesToShipping: null,
    });
  };

  onConfirm = () => {
    const { onConfirm } = this.props;

    const {
      couponCode,
      couponValue,
      couponType,
      couponAppliesToShipping,
    } = this.state;

    onConfirm(couponCode, couponType, couponValue, couponAppliesToShipping);
    this.resetState();
  };

  selectCoupon = async (coupon) => {
    const { coupons } = this.state;

    if (coupon !== null) {
      let discountValueMessage = '';
      let { discountAmount } = coupon;
      const {
        discountType,
        code,
        applyToShipping,
      } = coupon;

      let couponType;
      if (discountType === discountTypeEnum.DollarAmount) {
        discountAmount = discountAmount.toFixed(2) / 100.0;
        discountValueMessage = '$' + discountAmount;
        couponType = couponTypeEnum.Amount;
      } else {
        discountValueMessage = discountAmount + '%';
        couponType = couponTypeEnum.Percent;
      }
      this.searchInputData.value = coupon.code;
      this.setState({
        couponFound: true,
        couponFoundMessage: `Coupon code ${coupon.code} found.`,
        couponExtraMessage: `Discount ${discountValueMessage}`,
        couponAppliesToShippingMessage: `Coupon ${applyToShipping ? 'will' : 'will not'} be applied to shipping.`,
        isInputActive: false,
        couponSuggestions: [coupon],
        searchInput: coupon.code,
        coupons: [
          ...coupons,
          coupon,
        ],
        couponValue: discountAmount,
        couponType,
        couponCode: code,
        couponAppliesToShipping: applyToShipping,
      });
    } else {
      this.setState({
        couponFound: false,
        couponFoundMessage: 'Coupon code not found.',
        couponExtraMessage: 'Try another code.',
        isInputActive: true,
        coupons: [],
        couponValue: 0,
      });
    }

    this.onInputBlur();
  };

  onCloseModal = () => {
    const { closeModal } = this.props;

    this.resetState();
    closeModal();
  };

  onInputBlur = () => {
    this.setState({
      isInputActive: false,
      showSuggestions: false,
    });
  };

  onInputChange = () => {
    const { throttledCall } = this.state;
    const newSearchInput = this.searchInputData.value ? this.searchInputData.value.trim() : '';
    this.setState({ searchInput: newSearchInput });

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    if (newSearchInput.length < minInputLengthForSuggestions) {
      this.setState({
        showSuggestions: false,
        throttledCall: null,
      });

      return;
    }

    const newThrottle = setTimeout(this.updateSuggestions, 300);
    this.setState({
      throttledCall: newThrottle,
      showSuggestions: false,
    });
  };

  updateSuggestions = async () => {
    const {
      throttledCall,
      searchInput,
    } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    const suggestions = await findCouponSuggestions(searchInput);

    this.setState({
      couponSuggestions: suggestions,
      showSuggestions: true,
    });
  };

  onInputFocus = () => {
    const { searchInput } = this.state;

    this.setState({
      isInputActive: true,
      showSuggestions: searchInput.length > minInputLengthForSuggestions,
    });
  };

  render() {
    const { isOpen } = this.props;

    const {
      couponFound,
      isInputActive,
      showSuggestions,
      couponSuggestions,
      searchInput,
      couponFoundMessage,
      couponExtraMessage,
      couponAppliesToShippingMessage,
      coupons,
      couponType,
    } = this.state;

    return (
      <Modal
        title={'Apply Coupon to Orders'}
        modalHeight={'l'}
        modalWidth={'s'}
        isOpen={isOpen}
        closeModal={this.onCloseModal}
        enableBodyOverflow={true}
        buttons={(
          <ModalButtons
            confirmBtnText={couponType === couponTypeEnum.Percent ? 'Next' : 'Apply'}
            cancelBtnText={'Cancel'}
            onConfirm={this.onConfirm}
            onClose={this.onCloseModal}
            confirmBtnDisabled={!couponFound}
          />
        )}
      >
        {
          isOpen &&
          <div className='align align--center'>
            <div className='modal__subtitle align-left mb-30'>Search For Coupon To Add</div>
            <div>
              <div className={`chips-search has-icon ${isInputActive ? 'active' : ''}`}>
                <div>
                  <Icon materialIcon={'search'} />
                </div>
                <div className='chips'>
                  <div className='text-field mb-0'>
                    <OutsideClickWrapper onClick={this.onInputBlur}>
                      <>
                        <input
                          type='text'
                          placeholder={couponSuggestions?.length === 0 ? 'Coupon Name' : ''}
                          onChange={this.onInputChange}
                          onFocus={this.onInputFocus}
                          ref={(r) => this.searchInputData = r}
                        />
                        {
                          showSuggestions &&
                          <div className='suggestions suggestions__dropdown-fixed custom-scrollbar'>
                            {
                              couponSuggestions?.map((coupon) => (
                                <CouponSuggestion
                                  key={coupon.id}
                                  coupon={coupon}
                                  coupons={coupons}
                                  searchInput={searchInput}
                                  selectCoupon={this.selectCoupon}
                                />
                              ))
                            }
                          </div>
                        }
                      </>
                    </OutsideClickWrapper>
                  </div>
                </div>
              </div>
            </div>
            <br />
            {
              couponFoundMessage &&
              <InfoField
                value={couponFoundMessage}
                defaultValue={''}
                valueClass={`status-label ${couponFound ? 'green' : 'red'}`}
              />
            }
            {
              couponExtraMessage &&
              <InfoField
                value={couponExtraMessage || ''}
                defaultValue={''}
              />
            }
            {
              couponAppliesToShippingMessage &&
              <InfoField
                value={couponAppliesToShippingMessage || ''}
                defaultValue={''}
              />
            }
          </div>
        }
      </Modal>
    );
  }
}

OrderManagementAddCouponModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default OrderManagementAddCouponModal;
