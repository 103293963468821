import React from 'react';
import { productionUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const ProductionLink = React.memo(() => {
  const title = 'Production 2.0';

  return (
    <MenuTile
      title={title}
      url={productionUrl}
      icon={'how_to_vote'}
    />
  );
});

export default ProductionLink;
