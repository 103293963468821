import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '@sharedComponents/Form/Input';
import { sportForm } from '@constants/reduxForms';

const SportForm = (props) => {
  const {
    buttons,
    onSubmit,
  } = props;

  return (
    <form
      className='redux-form'
      onSubmit={onSubmit}
      id={sportForm}
    >
      <div>
        <div className='redux-form__section pb-5'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-xl'>
              <label className='redux-form__label'>
                Code
                <span className='required'>*</span>
              </label>
              <Field
                name={'code'}
                placeholder={'Enter Code'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-xl'>
              <label className='redux-form__label'>
                Name
                <span className='required'>*</span>
              </label>
              <Field
                name={'name'}
                placeholder={'Enter name'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        {buttons}
      </div>
    </form>
  );
};

SportForm.propTypes = {
  buttons: PropTypes.element.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SportForm;
