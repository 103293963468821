import React from 'react';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';

interface OwnProps {
  children: JSX.Element;
  size?: TableQuickViewSizeEnum;
  isActive?: boolean;
  noPadding?: boolean;
  dataTest?: string;
  classes?: string;
}

type Props = OwnProps;

const TableQuickViewContainer = React.memo<Props>(({
  children,
  size,
  isActive = true,
  noPadding = false,
  classes = '',
  dataTest = 'table-quick-view',
}) => {
  const isActiveClass = isActive ? 'is-active' : '';
  const sizeClass = size ? `size--${size}` : '';
  const noPaddingClass = noPadding ? 'no-padding' : '';

  return (
    <div
      className={`tableView__details ${isActiveClass} ${sizeClass} ${noPaddingClass} ${classes}`}
      data-test={dataTest}
    >
      {children}
    </div>
  );
});

export default TableQuickViewContainer;
