import React from 'react';

interface OwnProps {
  text: string;
}

type Props = OwnProps;

const TextHeaderTitle = React.memo(({ text }: Props) => (
  <div className='bar__long'>
    <div className='header__bar-content'>
      <h2 className='header__bar-title'>{text}</h2>
    </div>
  </div>
));

export default TextHeaderTitle;
