import React, { useCallback } from 'react';
import Button from '@sharedComponents/Buttons/Button';
import {
  DecorationFlagTypeEnum,
  HagPrintRoomOrderAssemblyPagedListDto,
} from '@api/fulfillment/models';

interface OwnProps {
  item: HagPrintRoomOrderAssemblyPagedListDto;
  openFlaggingModal: (item: HagPrintRoomOrderAssemblyPagedListDto) => void;
  openUnflaggingModal: (item: HagPrintRoomOrderAssemblyPagedListDto) => void;
}

type Props = OwnProps;

const FlagCell = React.memo<Props>((
  {
    item,
    openFlaggingModal,
    openUnflaggingModal,
  }
) => {
  const handleOpenFlagClicked = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    openFlaggingModal(item);
  }, [
    openFlaggingModal,
    item,
  ]);

  const handleOpenUnflagClicked = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    openUnflaggingModal(item);
  }, [
    openUnflaggingModal,
    item,
  ]);

  if (!item.flag) {
    return (
      <Button
        type={'danger'}
        text={'Flag'}
        onClick={handleOpenFlagClicked}
        classes='button button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase'
      />
    );
  }

  let text;
  switch (item.flag.flagType) {
    case DecorationFlagTypeEnum.Rework:
      text = 'In Rework';
      break;
    case DecorationFlagTypeEnum.Reprint:
      text = 'In Reprint';
      break;
    case DecorationFlagTypeEnum.WrongArtwork:
      text = 'Change Logo';
      break;
    default:
      text = 'Flagged';
  }

  const userText = item.flag && item.flag.userId ? <strong className='flagging__actions-user'>{item.flag.userId}</strong> : null;

  return (
    <div>
      <a
        onClick={handleOpenUnflagClicked}
        className='flagging__actions-button button'
      >
        <span>
          <div className='flagging__container'><i className='material-icons'>flag</i>{text}</div>
        </span>
      </a>
      {userText}
    </div>
  );
});

export default FlagCell;
