import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import DtgEnvironmentForm from '../DtgEnvironmentForm';
import { dtgEnvironmentForm } from '@constants/reduxForms';

const DtgEnvironmentAddModal = React.memo((props) => {
  const {
    isOpen,
    closeModal,
    onSubmit,
  } = props;

  return (
    <Modal
      title={'Add Environment'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={dtgEnvironmentForm}
        />
      )}
    >
      <DtgEnvironmentForm
        onSubmit={onSubmit}
        initialValues={{ }}
      />
    </Modal>
  );
});

DtgEnvironmentAddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DtgEnvironmentAddModal;
