import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeFileCutInfo } from '@redux/artworkFileTasks/actions';
import { swalAlert } from '@util/componentHelper';
import { createOptionsList } from '@util/optionsMap';
import Button from '@sharedComponents/Buttons/Button';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Icon from '@sharedComponents/Icons/Icon';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown';

class ArtworkTaskCut extends Component {
  state = {
    modalIsOpen: false,
    colorValue: '',
    cutValue: false,
  };

  openModal = () => {
    const {
      cutColor,
      cut,
    } = this.props;

    this.setState(() => ({
      modalIsOpen: true,
      colorValue: cutColor,
      cutValue: cut,
    }));
  };

  closeModal = () => {
    this.setState(() => ({
      modalIsOpen: false,
      colorValue: '',
      cutValue: false,
    }));
  };

  changeCutClick = async () => {
    const {
      taskId,
      lockerId,
      dispatch,
    } = this.props;

    const {
      cutValue,
      colorValue,
    } = this.state;

    if (cutValue && !colorValue) {
      swalAlert('If the file is a cut file, You have to choose a color.');

      return;
    }

    await dispatch(changeFileCutInfo(lockerId, taskId, colorValue, cutValue));
    this.closeModal();
  };

  handleColorChange = (event) => {
    const color = event.target.value;

    this.setState(() => ({ colorValue: color }));
  };

  handleCutChange = (event) => {
    const check = event.target.checked;

    this.setState(() => ({ cutValue: check }));
  };

  toggleIsCutFile = () => {
    this.setState((prevState) => ({
      cutValue: !prevState.cutValue,
    }));
  };

  render() {
    const {
      cutValue,
      modalIsOpen,
      colorValue,
    } = this.state;

    const {
      cut,
      colors,
    } = this.props;

    const cutText = cut ? 'Change Mark Cut' : 'Mark File as Cut';

    const options = createOptionsList({
      list: colors,
      key: 'code',
      value: 'code',
      name: 'code',
    });

    return (
      <div>
        <Modal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
          modalHeight={'l'}
          modalWidth={'s'}
          title={cutText}
          buttons={(
            <ModalButtons
              onClose={this.closeModal}
              onConfirm={this.changeCutClick}
              confirmBtnText={'Mark'}
            />
          )}
        >
          <div className='align align--center child-margin'>
            <div className='mb-30'>
              <label className='cut-file__checkbox mr-sm-2'>
                <Icon
                  materialIcon={cutValue ? 'check_box' : 'check_box_outline_blank'}
                  onClick={this.toggleIsCutFile}
                />
                This is a Cut File
              </label>
            </div>

            {
              cutValue &&
              <div className='mr-sm-2'>
                <div className='select-field cut-file__color-dropdown'>
                  <Icon materialIcon={'content_cut'} />
                  <Dropdown
                    options={options}
                    classes={'w-100'}
                    defaultValue={colorValue}
                    onChange={this.handleColorChange}
                  />
                </div>
              </div>
            }
          </div>
        </Modal>
        <Button
          type={'secondary'}
          text={cutText}
          onClick={this.openModal}
          classes={'mt-15'}
        />
      </div>
    );
  }
}

ArtworkTaskCut.propTypes = {
  taskId: PropTypes.number.isRequired,
  cut: PropTypes.bool.isRequired,
  lockerId: PropTypes.number.isRequired,
  cutColor: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
};

const mapStateToProps = ({ productCatalog }) => ({
  colors: productCatalog.colors,
});

export default connect(mapStateToProps)(ArtworkTaskCut);
