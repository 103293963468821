import React, { useEffect } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  ColorDto,
  DecorationMethodEnum,
} from '@api/productCatalog/models';
import {
  Task,
  LogoTask,
  PersonalizationTask,
} from '@models/Production/ProductionAssembly/Tasks';
import * as productionAssemblyActions from '@redux/productionAssembly/actions';
import { RootState } from '@redux/index/reducers';
import LogoCell from './LogoCell';
import TextCell from './TextCell';

interface OwnProps {
  task: Partial<Task>;
  decorationMethod?: keyof typeof DecorationMethodEnum;
  openModal: (logoUrl: string) => void;
  showThreadColorValue?: boolean;
  colorsDictionary: Record<string, ColorDto>;
  colors: ColorDto[] | undefined;
  logoCellSize?: string;
  regenerateButtonVisible: boolean;
}

const mapStateToProps = ({ productionAssembly }: RootState) => ({
  logoColors: productionAssembly.logoColors,
});

const mapDispatchToProps = {
  fetchLogo: productionAssemblyActions.fetchLogo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const LogoOrTextCell = React.memo<Props>(({
  fetchLogo,
  task,
  logoColors,
  openModal,
  colorsDictionary,
  decorationMethod,
  colors,
  showThreadColorValue = false,
  logoCellSize,
  regenerateButtonVisible,
}) => {
  useEffect(() => {
    if (
      task
      && (task as LogoTask).logoId
      && decorationMethod === DecorationMethodEnum.EMB
      && !logoColors[(task as LogoTask).logoId!]
    ) {
      fetchLogo((task as LogoTask).logoId);
    }
  }, [
    task,
    logoColors,
    decorationMethod,
    fetchLogo,
  ]);

  let selectedColors = [];

  if (
    (task as LogoTask).logoId
    && decorationMethod === DecorationMethodEnum.EMB
    && logoColors[(task as LogoTask).logoId!]
  ) {
    selectedColors = logoColors[(task as LogoTask).logoId!].selectedColors;
  }

  if ((task as LogoTask).logoUrl) {
    return (
      <LogoCell
        openModal={openModal}
        logoUrl={(task as LogoTask).previewUrl || (task as LogoTask).logoUrl!}
        logoColors={selectedColors}
        imageSize={logoCellSize}
        colors={colors}
      />
    );
  }

  return (
    <TextCell
      personalizationColor={colorsDictionary[(task as PersonalizationTask).colorId!]}
      personalizationText={(task as PersonalizationTask).text ?? undefined}
      personalizationNumber={(task as PersonalizationTask).number ?? undefined}
      personalizationOutlineColor={
        (task as PersonalizationTask).outlineColorId
          ? colorsDictionary[(task as PersonalizationTask).outlineColorId!]
          : undefined
      }
      showThreadColorValue={showThreadColorValue}
      orderItemDecorationId={task.id!}
      decorationMethod={decorationMethod}
      regenerateButtonVisible={regenerateButtonVisible}
    />
  );
});

export default connector(LogoOrTextCell);
