import React from 'react';
import {
  CollectionPreview,
  VoucherOrderPreviewDto,
} from '@api/financialServices/models';
import { VoucherCreationLockerViewModel as Locker } from '@api/squadlockerServices/models';
import Modal from '@sharedComponents/Modal/Modal';
import ExpandablePriceTab from '@sharedComponents/Display/ExpandablePriceTab';
import Spinner from '@sharedComponents/Display/Spinner';
import { groupBy } from '@util/arrayHelper';
import {
  formatDollarAmount, sum,
} from '@util/numberHelpers';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  selectedLockers: Locker[];
  voucherOrderPreviewData?: VoucherOrderPreviewDto;
  voucherOrderPreviewDataLoading: boolean;
  openConfirmationModal: () => void;
}

type Props = OwnProps;

const VoucherOrderPreviewModal = React.memo<Props>(({
  isOpen,
  closeModal,
  selectedLockers,
  voucherOrderPreviewData,
  voucherOrderPreviewDataLoading,
  openConfirmationModal,
}) => {
  const dataGroupedByLocker = voucherOrderPreviewData
    ? groupBy<CollectionPreview>(voucherOrderPreviewData.collections!, 'lockerId')
    : null;

  const toSubtotalAmount = (x: CollectionPreview) => x.totalAmount!;
  const toDiscountAmount = (x: CollectionPreview) => x.discountAmount!;
  const toTotalAmount = (x: CollectionPreview) => x.totalAmount! - x.discountAmount!;

  const subtotal = sum(voucherOrderPreviewData?.collections?.map(toSubtotalAmount));
  const discount = sum(voucherOrderPreviewData?.collections?.map(toDiscountAmount));
  const total = sum(voucherOrderPreviewData?.collections?.map(toTotalAmount));

  const toCollectionRow = (collection: CollectionPreview, index: number) => (
    <div
      className='order-financial-details__content mt-10'
      key={index}
    >
      <div className='justify__space-between'>
        <span>{collection.collectionName}</span>
        <span className='positive-amount'>{formatDollarAmount(collection.totalAmount)}</span>
      </div>
    </div>
  );

  const toLockerGroup = (key: string) => {
    const locker = selectedLockers.find((l) => l.id.toString() === key);

    if (!locker || !dataGroupedByLocker) {
      return null;
    }

    return (
      <div key={key}>
        <ExpandablePriceTab
          label={locker.name}
          price={sum(dataGroupedByLocker[key].map(toTotalAmount))}
          classes={'mt-20'}
          content={(
            <>
              {
                dataGroupedByLocker[key].map(toCollectionRow)
              }
            </>
          )}
        />
      </div>
    );
  };

  return (
    <Modal
      modalHeight={'l'}
      modalWidth={'m'}
      isOpen={isOpen}
      closeModal={closeModal}
      modalCloseBtnClass={'modal__close--tight'}
      title={'Preview Voucher Order'}
      buttons={(
        <ModalButtons
          onClose={closeModal}
          cancelBtnClass={'Back'}
          onConfirm={openConfirmationModal}
          confirmBtnText={'Send Voucher Invoice'}
        />
      )}
    >
      <div className='pt-20 pb-20'>
        {
          voucherOrderPreviewDataLoading &&
          <div className='flex align__center justify__center'>
            <Spinner />
          </div>
        }
        {
          dataGroupedByLocker &&
          <div>
            <div>
              {
                Object.keys(dataGroupedByLocker).map(toLockerGroup)
              }
            </div>
            <div className='mt-40'>
              <div className='order-financial-details__subtotal'>
                <span>Subtotal</span>
                <span>{formatDollarAmount(subtotal)}</span>
              </div>
              <div className='order-financial-details__subtotal'>
                <span>Discount</span>
                <span>-{formatDollarAmount(discount)} ({(voucherOrderPreviewData?.discountPercent ?? 0) * 100}%)</span>
              </div>
            </div>
            <div className='mt-30'>
              <div className='order-financial-details__subtotal--explanation'>
                (Subtotal - Discount)
              </div>
              <div className='order-financial-details__total'>
                <span>Total Amount</span>
                <span>{formatDollarAmount(total)}</span>
              </div>
            </div>
          </div>
        }
      </div>
    </Modal>
  );
});

export default VoucherOrderPreviewModal;
