import React from 'react';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  color: any;
  outlineColor: any;
}

type Props = OwnProps;

const ColorCell = React.memo<Props>((
  {
    color,
    outlineColor,
  }
) => {
  const tooltipText = (
    <div className='flex__column'>
      <span>Fill: {color?.code || '-'}</span>
      {outlineColor && <span>Outline: {outlineColor.code || '-'}</span>}
    </div>
  );

  return (
    <div className='flex'>
      <MaterialTooltip
        tooltipText={tooltipText}
        placement={'right'}
      >
        <div className='color-swatch-item size--small'>
          <span
            className='color-swatch-item__color size--small mr-5'
            style={{ backgroundColor: color?.hexValue }}
          >
            {
              outlineColor &&
              <div
                className='color-swatch-item__outline'
                style={{ borderColor: outlineColor?.hexValue }}
              />
            }
          </span>
        </div>
      </MaterialTooltip>
    </div>
  );
});

export default ColorCell;
