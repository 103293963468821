import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addColoredStylesToLocker } from '@APICalls/lockerManager/actions';
import { fetchLockerColoredStyles } from '@redux/lockerManager/actions.js';
import { fetchTeamColors } from '@redux/support/actions';
import { materialSwal } from '@util/componentHelper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ChooseColorsTab from './ChooseColorsTab';
import ConfirmColorsTab from './ConfirmColorsTab';

const addColorsToProductsTabEnum = {
  ChooseColors: 'ChooseColor',
  ConfirmColors: 'ConfirmColors',
};

class AddColorsToProductsModal extends PureComponent {
  state = {
    selectedColors: [],
    selectedTab: addColorsToProductsTabEnum.ChooseColors,
    selectedColoredStyles: this.props.coloredStyles,
    colorsByStyleDict: {},
    styles: [],
  };

  componentDidMount() {
    const {
      isOpen,
      dispatch,
      selectedItems,
      teamColorsByCode,
      colorsByStyle,
    } = this.props;

    dispatch(fetchTeamColors());

    if (!isOpen) {
      return;
    }

    if (selectedItems) {
      this.fetchColoredStyles();
      this.updateStyles(selectedItems);
    }

    if (colorsByStyle && Object.values(colorsByStyle).length > 0
      && teamColorsByCode && Object.values(teamColorsByCode).length > 0) {
      this.updateColorsByStyleDict(colorsByStyle, teamColorsByCode);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isOpen,
      selectedItems,
      coloredStyles,
      colorsByStyle,
      teamColorsByCode,
    } = this.props;

    if (isOpen && selectedItems && (isOpen !== prevProps.isOpen || selectedItems !== prevProps.selectedItems)) {
      this.fetchColoredStyles();
      this.updateStyles(selectedItems);
    }

    if (coloredStyles && coloredStyles !== prevProps.coloredStyles) {
      this.setState({ selectedColoredStyles: coloredStyles });
    }

    if ((colorsByStyle && colorsByStyle !== prevProps.colorsByStyle)
      || (teamColorsByCode && teamColorsByCode !== prevProps.teamColorsByCode)) {
      this.updateColorsByStyleDict(colorsByStyle, teamColorsByCode);
    }
  }

  updateColorsByStyleDict = (colorsByStyle, teamColorsByCode) => {
    const colorsByStyleDict = {};
    for (const [
      styleId,
      colors,
    ] of Object.entries(colorsByStyle)) {
      const available = colors.available.map((code) => mapCodeToColor(code, teamColorsByCode));
      const inLocker = colors.inLocker.map((code) => mapCodeToColor(code, teamColorsByCode));
      colorsByStyleDict[styleId] = {
        available,
        inLocker,
      };
    }
    this.setState({
      colorsByStyleDict,
    });
  };

  updateStyles = (selectedItems) => {
    // Select only one colored style per style
    const stylesDict = {};
    for (const item of selectedItems) {
      if (!stylesDict[item.styleId]) {
        stylesDict[item.styleId] = item;
      }
    }

    const styles = Object.values(stylesDict).flat();
    this.setState({
      styles,
    });
  };

  fetchColoredStyles = () => {
    const {
      selectedItems,
      dispatch,
      lockerId,
    } = this.props;

    const styleIds = [...new Set(selectedItems.map((item) => item.styleId))];
    dispatch(fetchLockerColoredStyles(lockerId, styleIds));
  };

  selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  addColor = (color) => {
    const { selectedColors } = this.state;

    const newSelectedColors = [
      ...selectedColors,
      color,
    ];
    const newSelectedColorCodes = newSelectedColors.map((c) => c.code);

    this.setState({
      selectedColors: newSelectedColors,
      selectedColoredStyles: this.updateSelectedColoredStyles(newSelectedColorCodes),
    });
  };

  removeColor = (color) => {
    const { selectedColors } = this.state;

    const newSelectedColors = selectedColors.filter((c) => c.code !== color.code);
    const newSelectedColorCodes = newSelectedColors.map((c) => c.code);

    this.setState({
      selectedColors: newSelectedColors,
      selectedColoredStyles: this.updateSelectedColoredStyles(newSelectedColorCodes),
    });
  };

  updateSelectedColoredStyles = (newSelectedColorCodes) => {
    const { coloredStyles } = this.props;

    if (newSelectedColorCodes.length === 0) {
      return coloredStyles;
    }

    return coloredStyles.filter((cs) => newSelectedColorCodes.includes(cs.colorGroup));
  };

  removeColoredStyle = (coloredStyle) => {
    this.setState((prevState) => ({
      selectedColoredStyles: prevState.selectedColoredStyles.filter((cs) => cs.id !== coloredStyle.id),
    }));
  };

  onChooseColors = () => {
    this.selectTab(addColorsToProductsTabEnum.ConfirmColors);
  };

  onConfirmColors = async () => {
    const {
      lockerId,
      refresh,
    } = this.props;

    const { selectedColoredStyles } = this.state;

    const res = await addColoredStylesToLocker(lockerId, selectedColoredStyles);
    if (res?.success) {
      this.onCloseModal();
      materialSwal('Success', res.message, 'success');
      refresh();
    }
  };

  initializeState = () => {
    this.setState({
      selectedColors: [],
      selectedTab: addColorsToProductsTabEnum.ChooseColors,
      colorsByStyleDict: {},
    });
  };

  onCloseModal = () => {
    this.initializeState();

    const { closeModal } = this.props;
    closeModal();
  };

  render() {
    const {
      selectedTab,
      selectedColors,
      selectedColoredStyles,
      colorsByStyleDict,
      styles,
    } = this.state;

    const {
      isOpen,
      allColors,
      teamColorsByCode,
    } = this.props;

    let buttons = null;
    if (selectedTab === addColorsToProductsTabEnum.ChooseColors) {
      buttons = (
        <ModalButtons
          confirmBtnText={'Continue'}
          cancelBtnText={'Cancel'}
          onConfirm={this.onChooseColors}
          onClose={this.onCloseModal}
          confirmBtnDisabled={selectedColoredStyles.length === 0}
        />
      );
    } else if (selectedTab === addColorsToProductsTabEnum.ConfirmColors) {
      buttons = (
        <ModalButtons
          confirmBtnText={'Confirm and Apply'}
          cancelBtnText={'Cancel'}
          onConfirm={this.onConfirmColors}
          onClose={this.onCloseModal}
          confirmBtnDisabled={selectedColoredStyles.length === 0}
        />
      );
    }

    return (
      <Modal
        title={'Bulk Add Colors'}
        modalSize={'l'}
        isOpen={isOpen}
        closeModal={this.onCloseModal}
        modalClass={'lockerManagerEdit__clone-modal'}
        buttons={buttons}
      >
        {
          selectedTab === addColorsToProductsTabEnum.ChooseColors &&
          <ChooseColorsTab
            addColor={this.addColor}
            removeColor={this.removeColor}
            allColors={allColors.map((code) => mapCodeToColor(code, teamColorsByCode))}
            selectedColors={selectedColors}
            styles={styles}
            colorsByStyle={colorsByStyleDict}
          />
        }
        {
          selectedTab === addColorsToProductsTabEnum.ConfirmColors &&
          <ConfirmColorsTab
            coloredStyles={selectedColoredStyles}
            removeColoredStyle={this.removeColoredStyle}
            styles={styles}
          />
        }
      </Modal>
    );
  }
}

AddColorsToProductsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object).isRequired,
  allColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorsByStyle: PropTypes.object.isRequired,
  teamColorsByCode: PropTypes.object.isRequired,
  lockerId: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
};

const mapCodeToColor = (colorCode, teamColorsByCode) => {
  const color = teamColorsByCode[colorCode];

  return color ? color : {
    name: colorCode,
    code: colorCode,
  };
};

const mapStateToProps = ({
  lockerManager,
  support,
}) => ({
  teamColorsByCode: support.teamColorsByCode,
  allColors: lockerManager.lockerStyleColors.allAvailableColors,
  colorsByStyle: lockerManager.lockerStyleColors.colorsByStyle,
  coloredStyles: lockerManager.lockerStyleColors.selectableColoredStyles,
});

export default connect(mapStateToProps)(AddColorsToProductsModal);
