import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
  reduxForm,
} from 'redux-form';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import { connect } from 'react-redux';
import { validateProductionAssemblyPrintQueueSendToPrint } from '@redux/productionAssembly/validations';
import InfoField from '@sharedComponents/Display/InfoField';
import FormError from '@sharedComponents/Form/FormError';
import { getDtgStationOptions } from '@constants/options/options';
import Select from '@sharedComponents/Form/Select';
import { productionAssemblyPrintQueueSendToPrintForm } from '@constants/reduxForms';
import { generalOptions } from '@util/optionsMap';

const ProductionAssemblyPrintQueueSendToPrintForm = React.memo(({
  error,
  handleSubmit,
  itemsCount,
  dtgStations,
  tabletProfile,
}) => {
  const itemsText = itemsCount > 1 ? 'items' : 'item';
  const dtgStationOptions = generalOptions(getDtgStationOptions(dtgStations));

  return (
    <Form
      className='redux-form'
      onSubmit={handleSubmit}
      id={productionAssemblyPrintQueueSendToPrintForm}
    >
      <div>
        <GridContainer className='redux-form__section'>
          <Row className='redux-form__row print-queue__modal__info-label'>
            <Col
              className='redux-form__column'
              md={12}
            >
              {`Please verify the Tablet Profile parameters below and confirm
                "Send to Print" action for `}
              <strong>
                {`${itemsCount} ${itemsText}`}
              </strong>
            </Col>

          </Row>
          <Row className='redux-form__row'>
            <Col
              className='redux-form__column'
              md={12}
            >
              <label className='redux-form__label'>
                Printer
              </label>
              <Field
                name={'stationIdentifier'}
                component={Select}
              >
                {dtgStationOptions}
              </Field>
            </Col>
          </Row>
          <Row className='redux-form__row'>
            <Col
              className='redux-form__column'
              md={12}
            >
              <InfoField
                label={'Tablet Profile Name'}
                value={tabletProfile.name}
              />
            </Col>
          </Row>
          <Row className='redux-form__row'>
            <Col md={4}>
              <InfoField
                label={'Print Direction Underbase'}
                value={tabletProfile.printDirectionUnderbase}
              />
            </Col>
            <Col md={4}>
              <InfoField
                label={'Print Direction Color'}
                value={tabletProfile.printDirectionColor}
              />
            </Col>
            <Col md={4}>
              <InfoField
                label={'Extra Underbase Passes'}
                value={tabletProfile.passesUnderbase}
              />
            </Col>
          </Row>
          <Row className='redux-form__row'>
            <Col md={8}>
              <InfoField
                label={'Wait After White Underpass'}
                value={tabletProfile.waitUnderbaseMillisecondsFormatted}
              />
            </Col>
            <Col md={4}>
              <InfoField
                label={'Extra Color Passes'}
                value={tabletProfile.passesColor}
              />
            </Col>
          </Row>
        </GridContainer>
        <FormError error={error} />
      </div>
    </Form>
  );
});

ProductionAssemblyPrintQueueSendToPrintForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  tabletProfile: PropTypes.object.isRequired,
  itemsCount: PropTypes.number.isRequired,
  dtgStations: PropTypes.array.isRequired,
};

export default connect()(reduxForm({
  form: productionAssemblyPrintQueueSendToPrintForm,
  validate: validateProductionAssemblyPrintQueueSendToPrint,
  enableReinitialize: true,
  shouldError: () => true,
})(ProductionAssemblyPrintQueueSendToPrintForm));
