import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '@sharedComponents/Form/Input';

const VendorIdFormInput = React.memo(({
  memberName,
  disabled,
}) => (
  <>
    <label className='redux-form__label'>
      Vendor ID
    </label>
    <Field
      name={`${memberName}.vendorId`}
      placeholder={'Enter vendor ID'}
      component={Input}
      disabled={disabled}
    />
  </>
));

VendorIdFormInput.propTypes = {
  memberName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default VendorIdFormInput;
