import React from 'react';
import {
  productionAssemblyHagReviewByOrderUrl,
  productionAssemblyHagReviewByBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import OrderSearchByDecorationMethod from '@sharedComponents/Production/Search/OrderSearchByDecorationMethod';

const ProductionAssemblyHagSearch = React.memo(() => (
  <OrderSearchByDecorationMethod
    decorationMethod={decorationMethodEnum.HAG}
    reviewByOrderUrl={productionAssemblyHagReviewByOrderUrl}
    reviewByBarcodeUrl={productionAssemblyHagReviewByBarcodeUrl}
  />
));

export default ProductionAssemblyHagSearch;
