const enumHelper = {
  getDisplayName(enumDefinition, value) {
    const valueInt = parseInt(value, 10);
    let associatedKey = '';

    for (const name of Object.keys(enumDefinition)) {
      if (enumDefinition[name] === valueInt) {
        associatedKey = name;
        break;
      }
    }

    return enumDefinition._displayNames?.[value] ? enumDefinition._displayNames[value] : associatedKey;
  },

  getSelectOptionsList(enumDefinition, defaultValue) {
    const output = [];

    for (const property in enumDefinition) {
      if (enumDefinition.hasOwnProperty(property) && !property.startsWith('_')) {
        output.push({
          value: enumDefinition[property],
          label: this.getDisplayName(enumDefinition, enumDefinition[property]),
        });
      }
    }

    if (defaultValue) {
      output.unshift({
        value: '',
        label: defaultValue,
      });
    }

    return output;
  },

  getValueFromDisplayName(enumDefinition, displayName) {
    for (const name of Object.keys(enumDefinition)) {
      if (name === displayName) {
        return enumDefinition[name];
      }
    }

    return null;
  },

  getSortComparator(enumDefinition) {
    return (a, b) => {
      const aValue = this.getValueFromDisplayName(enumDefinition, a);
      const bValue = this.getValueFromDisplayName(enumDefinition, b);

      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }

      return 0;
    };
  },
};

export default enumHelper;
