import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import { decorationLocationForm } from '@constants/reduxForms';
import { validateDecorationLocation } from '@redux/decorationLocations/validations';
import DecorationLocationForm from '../DecorationLocationForm';

const DecorationLocationEditModal = (props) => {
  const {
    isOpen,
    handleSubmit,
    initialValues,
    closeModal,
  } = props;

  return (
    <Modal
      title={'Edit Decoration Location'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Edit'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={decorationLocationForm}
        />
      )}
    >
      <DecorationLocationForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </Modal>
  );
};

DecorationLocationEditModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default (reduxForm({
  form: decorationLocationForm,
  validate: validateDecorationLocation,
  enableReinitialize: true,
})(DecorationLocationEditModal));
