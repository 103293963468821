import React, { useCallback } from 'react';
import {
  getOrderSubtotal,
  getOrderDiscountAmount,
  getOrderChargeAmount,
} from '@util/paymentHelper';
import { formatDollarAmount } from '@util/numberHelpers';
import { featureFlags } from '@constants/common';
import { orderStatusValueEnum } from '@constants/enums/orderEnums';
import Icon from '@sharedComponents/Icons/Icon';
import LinkButton from '@sharedComponents/Buttons/LinkButton';
import RefundPrice from '@sharedComponents/Display/RefundPrice';
import DescriptionField from '@sharedComponents/Display/DescriptionField';
import {
  OrderPricingDetailsDto,
  OrderDetailsDto as CoreOrderDetails,
} from '@api/orders/models';
import { OrderDetailsDto } from '@api/fulfillment/models';

interface OwnProps {
  order: Nullable<OrderDetailsDto>;
  openApplyCouponModal: () => void;
  openOrderFinancialsModal: () => void;
  openCouponDetailsModal: () => void;
  coreOrderPricingDetails: OrderPricingDetailsDto | undefined;
  orderExistsOnCore: boolean;
  coreOrder: CoreOrderDetails;
}

type Props = OwnProps;

const OrderManagementDetailsOrderSummary = React.memo(({
  order,
  coreOrderPricingDetails,
  openOrderFinancialsModal,
  openCouponDetailsModal,
  openApplyCouponModal,
  orderExistsOnCore,
  coreOrder,
}: Props) => {
  const isOrderShipped = order?.status && order.status.value === orderStatusValueEnum.Shipped;

  const orderSubtotal = getOrderSubtotal(order!);
  const orderChargeAmount = getOrderChargeAmount(order!);

  const coreOrderChargeAmount = useCallback(() => (coreOrderPricingDetails?.subtotalChargeAmount ?? 0)
      + (coreOrderPricingDetails?.subtotalChargeTaxAmount ?? 0)
      + (coreOrderPricingDetails?.shippingChargeAmount ?? 0)
      + (coreOrderPricingDetails?.shippingChargeTaxAmount ?? 0),
  [
    coreOrderPricingDetails?.subtotalChargeAmount,
    coreOrderPricingDetails?.subtotalChargeTaxAmount,
    coreOrderPricingDetails?.shippingChargeAmount,
    coreOrderPricingDetails?.shippingChargeTaxAmount,
  ]);

  const coreOrderDiscountAmount = useCallback(
    () => coreOrderPricingDetails?.orderTotalDiscount, [coreOrderPricingDetails]
  );

  let quantity = 0;
  if (order?.items?.length ?? 0 > 0) {
    quantity = order?.items?.map(
      (item) => item.quantity!
    ).reduce((prev: number, next: number) => prev + next) ?? 0;
  }

  let couponCodeMessage: string | JSX.Element = '-';
  const couponCode: string | undefined = orderExistsOnCore
    ? coreOrder?.coupon?.code ?? undefined
    : order?.couponCode ?? undefined;

  const couponApplicable = !isOrderShipped
    && (orderExistsOnCore
      ? coreOrder?.pricing?.pricePaid ?? 0
      : !order?.isCompleteRefund)
    && featureFlags.orderApplyCouponEnabled;

  if (couponCode) {
    couponCodeMessage = (
      <LinkButton
        text={couponCode}
        onClick={openCouponDetailsModal}
        classes={'font-weight-normal'}
      />
    );
  } else if (couponApplicable) {
    couponCodeMessage = (
      <LinkButton
        text={'Apply Coupon'}
        onClick={openApplyCouponModal}
        classes={'font-weight-normal'}
      />
    );
  }

  return (
    <div className='order__actions'>
      <div className='order__total-price'>
        <DescriptionField
          label={'Total Items'}
          value={quantity}
        />
        <DescriptionField
          label={'Coupon Code'}
          value={couponCodeMessage}
        />
        <DescriptionField
          label={'Discount'}
          value={formatDollarAmount(orderExistsOnCore
            ? coreOrderPricingDetails?.tradeDiscount
            : order?.tradeDiscount)}
        />
        <DescriptionField
          label={'Charge'}
          value={formatDollarAmount(orderExistsOnCore
            ? coreOrderChargeAmount()
            : orderChargeAmount)}
        />
        <DescriptionField
          label={'Order'}
          value={formatDollarAmount(orderExistsOnCore && coreOrderPricingDetails
            ? (coreOrderPricingDetails!.subtotal!
              + coreOrderPricingDetails!.taxAmount!
              - coreOrderPricingDetails!.shippingTaxAmount!)
            : orderSubtotal)}
        />
        <DescriptionField
          label={'Shipping'}
          value={formatDollarAmount(orderExistsOnCore
            ? coreOrderPricingDetails?.shippingPricePaid
            : order?.shippingPricePaid)}
        />
        <DescriptionField
          label={(
            <span className='align__center'>
              Price Paid
              <button
                className='btn-borderless order__total-price-info-button'
                onClick={openOrderFinancialsModal}
              >
                <Icon
                  materialIcon={'info_outline'}
                  classes='order__total-price-info-icon'
                />
              </button>
            </span>
          )}
          value={(
            <RefundPrice
              paid={orderExistsOnCore
                ? coreOrderPricingDetails?.pricePaid ?? 0
                : (order && order.pricePaid!) ?? 0}
              refund={orderExistsOnCore
                ? coreOrderDiscountAmount()
                : getOrderDiscountAmount(order!)}
              charge={orderExistsOnCore
                ? coreOrderChargeAmount()
                : getOrderChargeAmount(order!)}
            />
          )}
        />
      </div>
    </div>
  );
});

export default OrderManagementDetailsOrderSummary;
