/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type OrderInventoryStatusEnum = 'Undefined' | 'NoInventory' | 'Partial' | 'Available';


export const OrderInventoryStatusEnum = {
  Undefined: 'Undefined' as OrderInventoryStatusEnum,
  NoInventory: 'NoInventory' as OrderInventoryStatusEnum,
  Partial: 'Partial' as OrderInventoryStatusEnum,
  Available: 'Available' as OrderInventoryStatusEnum,
};
