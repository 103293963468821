import React from 'react';
import PropTypes from 'prop-types';
import { colorForm } from '@constants/reduxForms';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ColorForm from '../ColorForm';

const ColorAddModal = React.memo((props) => {
  const {
    isOpen,
    onSubmit,
    initialValues,
    closeModal,
  } = props;

  return (
    <Modal
      title={'Add Color'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={colorForm}
        />
      )}
    >
      <ColorForm
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </Modal>
  );
});

ColorAddModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ColorAddModal;
