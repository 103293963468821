import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { materialSwal } from '@util/componentHelper';
import { roleMapping } from '@util/roleCheck';
import { createOptionsList } from '@util/optionsMap';
import { rolesEnum } from '@constants/enums/commonEnums';
import { artworkTaskStatusEnum } from '@constants/enums/artworkEnums';
import {
  submitTaskToPrint,
  submitTaskToRework,
} from '@redux/artworkFileTasks/actions';
import Button from '@sharedComponents/Buttons/Button';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown';
import ArtworkTaskNoteUploader from './ArtworkTaskDetailsNote/ArtworkTaskNoteUploader';

class ArtworkTaskFailNote extends Component {
  state = {
    attachedFile: [],
    note: null,
    failReason: '',
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      attachFile: [],
      note: null,
      failReason: '',
    });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  btnActionFail = async () => {
    const {
      id,
      lockerId,
      dispatch,
    } = this.props;

    const {
      attachedFile,
      note,
      failReason,
    } = this.state;

    const res = await dispatch(submitTaskToRework(lockerId, id, failReason, note, attachedFile));
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.closeModal();
    }
  };

  clearFileFromState = () => {
    this.setState({ attachedFile: null });
  };

  btnActionSendToPrint = async () => {
    const {
      id,
      lockerId,
      dispatch,
    } = this.props;

    const res = await dispatch(submitTaskToPrint(lockerId, id));
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }
  };

  addFileToState = (file) => {
    this.setState({ attachedFile: file });
  };

  handleNoteChange = (e) => {
    const note = e.target.value;
    this.setState(() => ({ note }));
  };

  handleFailReasonChange = (e) => {
    const failReason = e.target.value;
    this.setState(() => ({ failReason }));
  };

  render() {
    const {
      roles,
      failReasons,
      taskStatus,
      disableSendToPrint,
    } = this.props;

    const {
      modalIsOpen,
      note,
      failReason,
    } = this.state;

    const failReasonOptions = createOptionsList({
      list: failReasons,
      key: 'id',
      value: 'reason',
      name: 'reason',
      emptyOption: {
        disabled: true,
        name: 'Select fail reason',
      },
    });

    if (!roles.includes(rolesEnum.ProductionArtworkVendor)) {
      return (
        <div className='ml-10'>
          <Modal
            isOpen={modalIsOpen}
            closeModal={this.closeModal}
            title={'Fail Task'}
            modalHeight={'l'}
            modalWidth={'m'}
            buttons={(
              <ModalButtons
                onClose={this.closeModal}
                onConfirm={this.btnActionFail}
                confirmBtnText={'Fail'}
                confirmBtnClass={'btn-borderless--red'}
                confirmBtnDisabled={!note || !failReason}
              />
            )}
          >
            <div className='w-100'>
              <label className='redux-form__label'>
                Fail Reason
                <span className='required'>*</span>
              </label>
              <Dropdown
                options={failReasonOptions}
                disabled={!failReasons}
                defaultValue={failReason}
                selectClasses={'w-100'}
                onChange={this.handleFailReasonChange}
              />
            </div>
            <div className='w-100'>
              <label className='redux-form__label'>
                Note
                <span className='required'>*</span>
              </label>
              <div className='text-field flex'>
                <input
                  className='w-100'
                  onChange={this.handleNoteChange}
                  name='note'
                  value={note || ''}
                  type='text'
                  placeholder='Add a note'
                />
              </div>
            </div>
            <div className='upload-field--horizontal'>
              <ArtworkTaskNoteUploader
                attachFile={this.addFileToState}
                removeAttachedFile={this.clearFileFromState}
              />
            </div>
          </Modal>
          <Button
            type={'danger'}
            text={'Fail'}
            onClick={this.openModal}
          />
          {
            taskStatus === artworkTaskStatusEnum.InQC &&
            <Button
              disabled={!!disableSendToPrint}
              type={'primary'}
              text={'Send to Print'}
              onClick={this.btnActionSendToPrint}
              classes={'ml-10'}
            />
          }
        </div>
      );
    }

    return (
      <div />
    );
  }
}

ArtworkTaskFailNote.propTypes = {
  id: PropTypes.number.isRequired,
  taskStatus: PropTypes.string.isRequired,
  failReasons: PropTypes.any,
  roles: PropTypes.array.isRequired,
  lockerId: PropTypes.number.isRequired,
  disableSendToPrint: PropTypes.bool,
};

const mapStateToProps = ({
  artworkFileTasks,
  oidc,
}) => ({
  id: artworkFileTasks.artworkFileTaskDetails.id,
  taskStatus: artworkFileTasks.artworkFileTaskDetails.status.status,
  failReasons: artworkFileTasks.failReasons,
  roles: roleMapping(oidc),
});

export default connect(mapStateToProps)(ArtworkTaskFailNote);
