import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reset } from 'redux-form';
import {
  getOrganizationSizeOptions,
  getOrganizationTypeOptions,
  getOrganizationLandingPageFilterOptions,
} from '@constants/options/options';
import {
  pageSizeOptionsBigTable,
  defaultPageSizeBigTable,
  dropdownSizeL,
} from '@constants/values';
import { TableEnum } from '@constants/enums/tableEnums';
import { organizationDetailsUrl } from '@constants/clientUrls/clientUrls';
import { organizationForm } from '@constants/reduxForms';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { organizationLandingPageEnum } from '@constants/enums/organizationEnums';
import { fetchAccountExecutives } from '@redux/admins/actions';
import { fetchTeamColors } from '@redux/support/actions';
import {
  fetchOrganizations,
  createOrganization,
} from '@redux/organizations/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import memoizeOne from '@util/memoHelper';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import Button from '@sharedComponents/Buttons/Button';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import OrganizationsQuickView from './OrganizationsQuickView/OrganizationsQuickView';
import OrganizationAddModal from './OrganizationModals/OrganizationAddModal';

const OrganizationsTable = Table();

const orgSizesOptions = getOrganizationSizeOptions();
const orgTypesOptions = getOrganizationTypeOptions();
const landingPageTypeOptions = getOrganizationLandingPageFilterOptions();

class Organizations extends Component {
  state = {
    pageNumber: this.props.initialPageNumber,
    pageSize: this.props.initialPageSize,
    sortColumn: this.props.initialSortColumn,
    sortDirection: this.props.initialSortDirection,
    searchInput: this.props.initialSearchInput,
    orgTypeFilter: this.props.initialOrgTypeFilter,
    sizeFilter: this.props.initialSizeFilter,
    landingPageEnabled: this.props.initialLandingPageEnabled,
    accountExecutives: this.props.initialAccountExecutives,
    selectedOrganization: null,
    addOrganizationModalIsOpen: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAccountExecutives());
    dispatch(fetchTeamColors());
  }

  fetchData = (state, instance) => {
    const {
      sortColumn,
      sortDirection,
    } = this.state;

    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortColumn, sortDirection);

    this.setState(() => ({
      pageNumber: newPageNumber + 1,
      pageSize: newPageSize,
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    }), this.search);
  };

  handleSizeFilter = (value) => {
    this.setState(() => ({
      sizeFilter: value,
    }), this.search);
  };

  handleOrgTypeFilter = (value) => {
    this.setState(() => ({
      orgTypeFilter: value,
    }), this.search);
  };

  handleLandingPageFilter = (value) => {
    this.setState(() => ({
      landingPageEnabled: value,
    }), this.search);
  };

  filterTable = () => {
    const {
      orgTypeFilter,
      sizeFilter,
      landingPageEnabled,
    } = this.state;

    const hasChanged = this.orgTypeFilter.value !== orgTypeFilter
      || this.sizeFilter.value !== sizeFilter
      || this.landingPageEnabled !== landingPageEnabled;

    if (!hasChanged) return;

    this.setState(() => ({
      orgTypeFilter: this.orgTypeFilter.value,
      sizeFilter: this.sizeFilter.value,
      landingPageEnabled: this.landingPageEnabled.value,
    }), this.search);
  };

  filterByAccountExecutives = (accountExecutives) => {
    if (accountExecutives) {
      this.setState(() => ({
        accountExecutives,
      }), this.search);
    }
  };

  clearSearch = () => {
    this.setState(() => ({
      searchInput: '',
    }), this.search);
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  search = () => {
    const { dispatch } = this.props;

    const {
      pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      orgTypeFilter,
      sizeFilter,
      searchInput,
      landingPageEnabled,
      accountExecutives,
    } = this.state;

    this.unselectOrganization();

    dispatch(fetchOrganizations(
      pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      orgTypeFilter,
      sizeFilter,
      searchInput,
      landingPageEnabled,
      null,
      accountExecutives
    ));
  };

  getColumns = () => {
    const { selectedOrganization } = this.state;

    let columns = [
      {
        Header: 'ID',
        accessor: 'id',
        minWidth: 25,
        sortable: true,
        Cell: (cellProps) => cellProps.value && (
          <Link
            className='link'
            to={organizationDetailsUrl(cellProps.original.id)}
          >
            <span>ORG{cellProps.value}</span>
          </Link>
        ),
      },
      {
        Header: <HeaderCell text={'Organization Name'} />,
        accessor: 'name',
        minWidth: 60,
        sortable: true,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Size',
        accessor: 'size',
        minWidth: 30,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Type',
        accessor: 'orgType',
        minWidth: 40,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: <HeaderCell text={'Account Executive'} />,
        accessor: 'accountManager',
        minWidth: 60,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
    ];

    if (!selectedOrganization) {
      columns = [
        ...columns,
        {
          Header: 'Decision Maker',
          accessor: 'locker_manager_id',
          minWidth: 50,
          Cell: (cellProps) => (cellProps.original.lockerManagerFirstName && cellProps.original.lockerManagerLastName)
            ? `${cellProps.original.lockerManagerFirstName} ${cellProps.original.lockerManagerLastName}`
            : '',
        },
        {
          Header: 'Landing Page',
          accessor: 'landingPageEnabled',
          minWidth: 50,
          Cell: (cellProps) => (
            <div>
              {cellProps.value
                ? <span className='status-label green'>{organizationLandingPageEnum.ON}</span>
                : <span className='status-label red'>{organizationLandingPageEnum.OFF}</span>
              }
            </div>
          ),
        },
        {
          Header: <HeaderCell text={'Date Created'} />,
          accessor: 'createdAt',
          sortable: true,
          minWidth: 60,
          Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
        },
      ];
    }

    return columns;
  };

  selectOrganization = (selectedOrganization) => {
    this.setState(() => ({ selectedOrganization }));
  };

  unselectOrganization = () => {
    this.setState(() => ({ selectedOrganization: null }));
  };

  openAddOrganizationModal = () => {
    this.setState(() => ({ addOrganizationModalIsOpen: true }));
  };

  closeAddOrganizationModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(organizationForm));
    this.setState(() => ({ addOrganizationModalIsOpen: false }));
  };

  addOrganization = async (addOrganizationForm) => {
    const { dispatch } = this.props;

    await dispatch(createOrganization(addOrganizationForm));
    await dispatch(fetchOrganizations());
    this.closeAddOrganizationModal();
  };

  getTrProps = (state, rowInfo) => {
    const { selectedOrganization } = this.state;

    return getSelectableTableRowProps(this.selectOrganization, rowInfo, selectedOrganization, 'id');
  };

  getFiltersToPreserve = memoizeOne((
    searchInput,
    orgTypeFilter,
    sizeFilter,
    landingPageEnabled,
    accountExecutives
  ) => ({
    searchInput,
    orgTypeFilter,
    sizeFilter,
    landingPageEnabled,
    accountExecutives,
  }));

  render() {
    const {
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
      allAccountExecutives,
      teamColors,
      initialPageNumber,
      initialPageSize,
      initialSortColumn,
      initialSortDirection,
    } = this.props;

    const {
      selectedOrganization,
      addOrganizationModalIsOpen,
      searchInput,
      orgTypeFilter,
      sizeFilter,
      landingPageEnabled,
      accountExecutives,
    } = this.state;

    const columns = this.getColumns();

    return (
      <div className='container'>
        <OrganizationAddModal
          isOpen={addOrganizationModalIsOpen}
          closeModal={this.closeAddOrganizationModal}
          onSubmit={this.addOrganization}
        />
        <div className='flex'>
          <div className='table-options w-100'>
            <div className='flex'>
              <SearchFilter
                search={this.onSearch}
                clearSearch={this.clearSearch}
                initialValue={searchInput}
              />
              <Dropdown
                options={orgSizesOptions}
                onChange={this.handleSizeFilter}
                classes={'margin-left coupons-table__filter'}
                defaultValue={sizeFilter}
              />
              <Dropdown
                options={orgTypesOptions}
                onChange={this.handleOrgTypeFilter}
                classes={'margin-left coupons-table__filter'}
                size={dropdownSizeL}
                defaultValue={orgTypeFilter}
              />
              <Dropdown
                options={landingPageTypeOptions}
                onChange={this.handleLandingPageFilter}
                classes={'margin-left coupons-table__filter'}
                defaultValue={landingPageEnabled}
              />
              <MultiSelectDropdown
                objects={allAccountExecutives}
                itemText={'account executives'}
                updateCallback={this.filterByAccountExecutives}
                textKey={'email'}
                valueKey={'email'}
                size={dropdownSizeL}
                classNames={'margin-left'}
                selectedObjects={accountExecutives}
              />
            </div>
            <div className='flex'>
              <Button
                type={'primary'}
                text={'Add Organization'}
                onClick={this.openAddOrganizationModal}
              />
            </div>
          </div>
        </div>

        <div className='master-detail'>
          <div className='w-100'>
            <OrganizationsTable
              data={queue}
              columns={columns}
              defaultPageSize={defaultPageSizeBigTable}
              pageSizeOptions={pageSizeOptionsBigTable}
              showPagination={hasNextPage || hasPreviousPage}
              totalPages={totalPages}
              onFetchData={this.fetchData}
              getTrProps={this.getTrProps}
              tableId={TableEnum.organizations}
              preserveState={true}
              sortDirEnum={sortDirectionShortEnum}
              initialPageNumber={initialPageNumber}
              initialPageSize={initialPageSize}
              initialSortColumn={initialSortColumn}
              initialSortDirection={initialSortDirection}
              filtersToPreserve={this.getFiltersToPreserve(
                searchInput,
                orgTypeFilter,
                sizeFilter,
                landingPageEnabled,
                accountExecutives
              )}
            />
          </div>
          {
            selectedOrganization &&
            <OrganizationsQuickView
              organization={selectedOrganization}
              closeDetails={this.unselectOrganization}
              teamColors={teamColors}
            />
          }
        </div>
      </div>
    );
  }
}

Organizations.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  allAccountExecutives: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
  })).isRequired,
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })),
};

const mapStateToProps = ({
  organizations,
  admins,
  support,
  tableManager,
}) => ({
  initialPageNumber: tableManager.organizations.pageNumber,
  initialPageSize: tableManager.organizations.pageSize,
  initialSortColumn: tableManager.organizations.sortColumn,
  initialSortDirection: tableManager.organizations.sortDirection,
  initialSearchInput: tableManager.organizations.searchInput,
  initialOrgTypeFilter: tableManager.organizations.orgTypeFilter,
  initialSizeFilter: tableManager.organizations.sizeFilter,
  initialLandingPageEnabled: tableManager.organizations.landingPageEnabled,
  initialAccountExecutives: tableManager.organizations.accountExecutives,
  totalPages: organizations.organizationsList.totalPages,
  hasPreviousPage: organizations.organizationsList.hasPreviousPage,
  hasNextPage: organizations.organizationsList.hasNextPage,
  queue: organizations.organizationsList.queue,
  allAccountExecutives: admins.accountExecutives,
  teamColors: support.teamColors,
});

export default connect(mapStateToProps)(Organizations);
