import React from 'react';
import PropTypes from 'prop-types';
import { orderStatusEnum } from '@constants/enums/orderEnums';

const OrderManagementDetailsItemStatus = React.memo(({
  item,
  orderStatus,
}) => {
  let status = item.status.label === orderStatusEnum.Initial ? orderStatus : item.status.label;

  let className = 'order__item-status';
  if (item.canceled !== null) {
    className = 'order__item-status__value-text--color-red';
    status = 'Canceled';
  }

  return (
    <div className='order__item-status'>
      <span className={`order__item-status__value-text ${className}`}>
        {status}
      </span>
    </div>
  );
});

OrderManagementDetailsItemStatus.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.shape({
      label: PropTypes.string,
    }),
    canceled: PropTypes.bool,
  }).isRequired,
  orderStatus: PropTypes.string.isRequired,
};

export default OrderManagementDetailsItemStatus;
