import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  text?: string;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  showLockIcon?: boolean;
  checked?: boolean;
  classes?: string;
}

type Props = OwnProps;

const MaterialCheckbox = React.memo(({
  text,
  onClick,
  disabled,
  showLockIcon = false,
  checked = false,
  classes = '',
}: Props) => {
  let icon = checked
    ? <Icon materialIcon={'check_box'} />
    : <Icon materialIcon={'check_box_outline_blank'} />;

  if (disabled && showLockIcon) {
    icon = <Icon materialIcon={'lock'} />;
  }

  const checkedClass = checked ? 'checked' : '';
  const disabledClass = disabled ? 'disabled' : '';
  const lockedClass = (disabled && showLockIcon) ? 'locked' : '';
  const otherClasses = classes ? classes : '';

  return (
    <a
      className={`button material-checkbox ${checkedClass} ${disabledClass} ${lockedClass} ${otherClasses}`}
      onClick={(onClick && !disabled) ? onClick : undefined}
    >
      {icon}
      <span>{text}</span>
    </a>
  );
});

export default MaterialCheckbox;
