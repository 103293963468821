import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  connect, ConnectedProps,
} from 'react-redux';
import * as orderManagementActions from '@redux/orderManagement/actions';
import * as supportActions from '@redux/support/actions';
import Tabs from '@sharedComponents/Tabs/Tabs';
import OrderManagementSidebarChangeLog from './OrderManagementSidebarChangeLog';
import OrderManagementSidebarNotes from './OrderManagementSidebarNotes';
import OrderManagementSidebarNewNote from './OrderManagementSidebarNewNote';
import OrderManagementSidebarShippingInfo from './OrderManagementSidebarShippingInfo';
import { RootState } from '@redux/index/reducers';
import { TabData } from '@models/common/TabData';
import { useGetHomefieldApiOrdermanagementOrdersgetchanges } from '@api/orders/orders';
import {
  ChangeLogItemDto, OrderDetailsDto,
} from '@api/fulfillment/models';
import {
  OrderPricingDetailsDto,
  OrderDetailsDto as CoreOrderDetails,
} from '@api/orders/models';
import { Note as NoteDto } from '@models/OrderManagement/Note';
import { useGetHomefieldApiOrdermanagementOrdersOrderNumberChangelogs } from '@api/fulfillment/order-management';

const orderManagementSidebarTabEnum = {
  Shipping: 'Shipping',
  ChangeLog: 'ChangeLog',
  Notes: 'Notes',
};

interface OwnProps {
  order: OrderDetailsDto;
  notes: Array<NoteDto>;
  loggedInUser: string;
  fetchOrder: () => void;
  coreOrderPricingDetails: Nullable<OrderPricingDetailsDto>;
  orderExistsOnCore: boolean;
  fetchCoreOrderPricingDetails: () => void;
  coreOrder: Nullable<CoreOrderDetails>;
}

const mapStateToProps = ({
  oidc,
  orderManagement,
}: RootState) => ({
  loggedInUser: (oidc?.user?.profile) ? oidc.user.profile.sub : '',
  notes: orderManagement.notes,
});

const mapDispatchToProps = {
  fetchShippingOptions: supportActions.fetchShippingOptions,
  fetchOrderNotes: orderManagementActions.fetchOrderNotes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const OrderManagementSidebarTabs = React.memo<Props>(({
  order,
  notes,
  loggedInUser,
  fetchOrder,
  coreOrderPricingDetails,
  orderExistsOnCore,
  fetchCoreOrderPricingDetails,
  coreOrder,
  fetchShippingOptions,
  fetchOrderNotes,
}) => {
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<TabData['name']>('Shipping');
  const [
    orderChanges,
    setOrderChanges,
  ] = useState<Nullable<Array<ChangeLogItemDto>>>(null);

  const {
    data: coreOrderChanges,
    refetch: fetchCoreOrderChanges,
  } = useGetHomefieldApiOrdermanagementOrdersgetchanges({ id: coreOrder?.id });

  const {
    data: ffOrderChanges,
    refetch: fetchFFOrderChanges,
  } = useGetHomefieldApiOrdermanagementOrdersOrderNumberChangelogs(order.orderNumber!);

  useEffect(() => {
    if (coreOrder) {
      fetchCoreOrderChanges();
    }
  }, [
    coreOrder,
    coreOrderPricingDetails,
    fetchCoreOrderChanges,
  ]);

  useEffect(() => {
    fetchShippingOptions();

    if (order?.orderNumber) {
      fetchOrderNotes(order.orderNumber);
      fetchFFOrderChanges();
    }
  }, [
    fetchFFOrderChanges,
    fetchOrderNotes,
    fetchShippingOptions,
    order.orderNumber,
  ]);

  useEffect(() => {
    if (ffOrderChanges) {
      setOrderChanges(
        [...ffOrderChanges!].sort((a: ChangeLogItemDto, b: ChangeLogItemDto) => a!.date! > b!.date! ? -1 : 1)
      );
    }
  }, [ffOrderChanges]);

  useEffect(() => {
    if (orderExistsOnCore
      && (coreOrderChanges?.length ?? 0) > 0
    ) {
      const changes = coreOrderChanges?.map(
        (orderChange) => ({
          user: orderChange.user,
          date: orderChange.dateTime,
          message: orderChange.orderItemSku
            ? `Item ${orderChange.orderItemSku} got refunded for ${orderChange.amount}$. Reason: ${orderChange.reason}`
            : `Order refunded for ${orderChange.amount}$. Reason: ${orderChange.reason}`,
        } as ChangeLogItemDto)
      );
      setOrderChanges([
        ...changes!,
        ...(orderChanges ?? []).filter(
          (oc) => !changes?.map(
            (c) => c.date
          ).includes(oc.date)
        ),
      ].sort((a: ChangeLogItemDto, b: ChangeLogItemDto) => a!.date! > b!.date! ? -1 : 1));
    }
  }, [coreOrderChanges]);

  const selectTab = useCallback((tabName: TabData['name']) => {
    setSelectedTab(tabName);
  }, []);

  const getTabs = useCallback(() => {
    const tabs = [
      {
        title: 'Shipping',
        name: orderManagementSidebarTabEnum.Shipping,
        content: (
          <div className='orderManagement__sidebar-container order__log-content'>
            <OrderManagementSidebarShippingInfo
              order={order}
              fetchOrder={fetchOrder}
              coreOrderPricingDetails={coreOrderPricingDetails}
              orderExistsOnCore={orderExistsOnCore}
              fetchCoreOrderPricingDetails={fetchCoreOrderPricingDetails}
              coreOrder={coreOrder}
            />
          </div>
        ),
      },
      {
        title: 'Change Log',
        name: orderManagementSidebarTabEnum.ChangeLog,
        content: (
          <div className='orderManagement__sidebar-container order__log-content'>
            <OrderManagementSidebarChangeLog log={orderChanges!} />
          </div>
        ),
      },
      {
        title: `Notes (${notes ? notes.length : 0})`,
        name: orderManagementSidebarTabEnum.Notes,
        content: (
          <div className='orderManagement__sidebar-container order__log-content'>
            <OrderManagementSidebarNotes
              orderNumber={order.orderNumber!}
              notes={notes}
              loggedInUser={loggedInUser}
            />
            <OrderManagementSidebarNewNote orderNumber={order.orderNumber!} />
          </div>
        ),
      },
    ];

    return tabs;
  }, [
    order,
    fetchOrder,
    coreOrderPricingDetails,
    orderExistsOnCore,
    fetchCoreOrderPricingDetails,
    coreOrder,
    orderChanges,
    notes,
    loggedInUser,
  ]);

  return (
    <div className='order__log'>
      <Tabs
        tabs={getTabs()}
        selectedTab={selectedTab}
        selectTab={selectTab}
      />
    </div>
  );
});

export default connector(OrderManagementSidebarTabs);
