import React from 'react';
import { colorsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const ColorsLink = React.memo(() => (
  <MenuTile
    title={'Colors'}
    url={colorsUrl}
    icon={'color_lens'}
  />
));

export default ColorsLink;
