import React, {
  useCallback,
  useState,
} from 'react';
import {
  VoucherOrderChangeLogItemDto,
  VoucherOrderDetailDto,
  VoucherOrderPaymentStatusEnum,
} from '@api/financialServices/models';
import {
  organizationDetailsUrl,
  lockerDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import { parseDateNumeric } from '@util/dateHandler';
import VoucherOrderStatusLabel from '@components/VoucherOrders/Shared/VoucherOrderStatusLabel';
import Link from '@sharedComponents/Navigation/Link';
import SectionContainer from '@sharedComponents/Display/Containers/SectionContainer';
import InfoField from '@sharedComponents/Display/InfoField';
import FooterSummaryContainer from '@sharedComponents/Display/Containers/FooterSummaryContainer';
import Button from '@sharedComponents/Buttons/Button';
import TableQuickViewTitle from '@sharedComponents/Table/TableQuickView/TableQuickViewTitle';
import SendEmailTextButton from '@sharedComponents/Buttons/SendEmailTextButton';
import SOPNumber from './SOPNumber';
import VoucherDetailsSidebarTabs from './VoucherDetailsSidebarTabs';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  voucherOrderChangeLog?: Array<VoucherOrderChangeLogItemDto>;
  openVoucherOrderEditModal: () => void;
  resendInvoice: () => void;
  refreshVoucherOrder: () => void;
}

type Props = OwnProps;

const VoucherDetailsSidebar = React.memo<Props>(({
  openVoucherOrderEditModal,
  voucherOrder,
  voucherOrderChangeLog,
  resendInvoice,
  refreshVoucherOrder,
}) => {
  const [
    isSubmitResultsModalOpen,
    setIsSubmitResultsModalOpen,
  ] = useState<boolean>(false);

  const openVoucherOrderSubmitResultsModal = useCallback(() => {
    setIsSubmitResultsModalOpen(true);
  }, []);

  const closeVoucherOrderSubmitResultsModal = useCallback(() => {
    setIsSubmitResultsModalOpen(false);
  }, []);

  return (
    <SectionContainer classes={'voucherOrders__details__sidebar'}>
      <>
        <div>
          <div className='mb-20'>
            <TableQuickViewTitle
              title={(
                <div className='align__center'>
                  <span>Voucher V{voucherOrder.id!}</span>
                  <VoucherOrderStatusLabel voucherOrder={voucherOrder} />
                </div>
              )}
            />
          </div>
          <div>
            <div className='flex'>
              <InfoField
                label={voucherOrder.organizationId ? 'Organization' : 'Locker'}
                fieldClass={'w-50'}
                value={
                  voucherOrder.organizationId
                    ? (
                      <Link
                        url={organizationDetailsUrl(voucherOrder.organizationId!)}
                        text={voucherOrder.organizationName!}
                        openInNewTab={true}
                      />
                    )
                    : (
                      <Link
                        url={lockerDetailsUrl(voucherOrder.lockerId!)}
                        text={voucherOrder.lockerName!}
                        openInNewTab={true}
                      />
                    )
                }
              />
              {
                voucherOrder.organizationId
                  ? (
                    <InfoField
                      label={'Organization ID'}
                      value={voucherOrder.erpId ? voucherOrder.organizationAccountingId : '-'}
                      fieldClass={'w-50'}
                    />
                  )
                  : (
                    <InfoField
                      label={'LCK Number'}
                      value={voucherOrder.erpId ? voucherOrder.lockerAccountingId : '-'}
                      fieldClass={'w-50'}
                    />
                  )
              }

            </div>
            <div className='flex'>
              <SOPNumber
                voucherOrder={voucherOrder}
                openModal={openVoucherOrderSubmitResultsModal}
                closeModal={closeVoucherOrderSubmitResultsModal}
                isSubmitResultsModalOpen={isSubmitResultsModalOpen}
                refreshVoucherOrder={refreshVoucherOrder}
              />
            </div>
            <div className='flex'>
              <InfoField
                label={'Valid From'}
                value={voucherOrder.startDate ? parseDateNumeric(voucherOrder.startDate) : '-'}
                fieldClass={'w-50'}
              />
              <InfoField
                label={'Valid Until'}
                value={voucherOrder.endDate ? parseDateNumeric(voucherOrder.endDate) : '-'}
                fieldClass={'w-50'}
              />
            </div>
            <div className='flex'>
              <InfoField
                label={'Admin Name'}
                value={`${voucherOrder.lockerManagerFirstName} ${voucherOrder.lockerManagerLastName}`}
                fieldClass={'w-50'}
              />
              <InfoField
                label={'Admin Phone Number'}
                value={voucherOrder.lockerManagerPhoneNumber}
                fieldClass={'w-50'}
              />
            </div>
            <InfoField
              label={(
                <div className='align__center'>
                  <span>Admin E-mail</span>
                  <SendEmailTextButton
                    text={'Resend Invoice'}
                    onClick={resendInvoice}
                  />
                </div>
              )}
              value={voucherOrder.lockerManagerEmail}
            />
          </div>
        </div>
        <div className='mb-20 mt-30'>
          <VoucherDetailsSidebarTabs
            voucherOrder={voucherOrder}
            voucherOrderChangeLog={voucherOrderChangeLog}
          />
        </div>
        {
          voucherOrder.paymentStatus !== VoucherOrderPaymentStatusEnum.Paid &&
          <FooterSummaryContainer
            noSeparator={true}
            noPadding={true}
          >
            <Button
              type={'primary'}
              text={'Edit Voucher Order'}
              onClick={openVoucherOrderEditModal}
              classes={'w-100'}
            />
          </FooterSummaryContainer>
        }
      </>
    </SectionContainer>
  );
});

export default VoucherDetailsSidebar;
