import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  reduxForm,
  formValueSelector,
} from 'redux-form';
import { customItemStatusEnum } from '@constants/enums/lockerEnums';
import { customItemForm } from '@constants/reduxForms';
import {
  getDecorationLocations,
  getColors,
  getVendor,
  getVendorTimelines,
} from '@redux/productCatalog/actions';
import { fetchManagerLogos } from '@redux/lockerManager/actions';
import { validateCustomItem } from '@redux/lockerManager/validations';
import { fetchAvailableSkusForVendorDesignId } from '@redux/vendorOrders/actions';
import { arraysAreEqual } from '@util/arrayHelper';
import FormError from '@sharedComponents/Form/FormError';
import Tabs from '@sharedComponents/Tabs/Tabs';
import ColoredStylesTable from '../ColoredStylesTable/ColoredStylesTable';
import CustomItemHeaderDetailsSection from './CustomItemHeaderDetailsSection';
import CustomizeItemPropertiesTab from './CustomizeItemPropertiesTab';
import CustomizeItemDecorationsTab from './CustomizeItemDecorationsTab';
import CustomizeItemPrintFilesTab from './CustomizeItemPrintFilesTab';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';

const customiteItemTabEnum = {
  Properties: 'Properties',
  Decoration: 'Decoration',
  PrintFiles: 'Print Files',
};

class CustomizeItem extends PureComponent {
  state = {
    selectedLayout: null,
    price: null,
    unhidingItemDisabled: false,
    selectedTab: customiteItemTabEnum.Properties,
    imageFrontAttachmentUrl: null,
    imageBackAttachmentUrl: null,
    imageLeftAttachmentUrl: null,
    imageRightAttachmentUrl: null,
    initializedFormValues: false,
  };

  componentDidMount() {
    const {
      dispatch,
      lockerId,
      selectedColoredStyle,
      vfiVendorCodes,
      styleProducts,
    } = this.props;

    if (styleProducts && styleProducts.length > 0) {
      this.initializeFormValues();
    }

    dispatch(getDecorationLocations());
    dispatch(fetchManagerLogos(lockerId));
    dispatch(getColors());

    const isVFI = selectedColoredStyle.style.vendorFulfilledItem;

    if (isVFI) {
      const { vendorDesignId } = this.props;
      const vendorCode = selectedColoredStyle.style.vendorId;

      const styleProductsLoaded = this.wereStyleProductsLoaded(styleProducts, selectedColoredStyle);

      dispatch(getVendorTimelines(selectedColoredStyle.style.vendorId));
      dispatch(getVendor(vendorCode));

      if (!!vendorDesignId && !!vendorCode && vfiVendorCodes.length > 0 && styleProductsLoaded) {
        this.validateVendorDesignId(vendorDesignId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      selectedColoredStyle,
      vfiVendorCodes,
      styleProducts,
      vendor,
      maxPersonalizationNameLength,
    } = this.props;

    const { initializedFormValues } = this.state;

    const isVFI = selectedColoredStyle.style.vendorFulfilledItem;
    const vendorCode = selectedColoredStyle.style.vendorId;
    const prevVendorCode = prevProps.selectedColoredStyle.style.vendorId;

    if (prevProps.selectedColoredStyle !== selectedColoredStyle
      && (prevProps.selectedColoredStyle.id !== selectedColoredStyle.id)) {
      this.unselectTimeline();

      if (isVFI) {
        if (prevVendorCode !== vendorCode) {
          dispatch(getVendorTimelines(vendorCode));
          dispatch(getVendor(vendorCode));
        }
      }
    }

    if (isVFI) {
      const { vendorDesignId } = this.props;

      const vendorIdChanged = prevVendorCode !== vendorCode;

      const styleProductsLoaded = this.wereStyleProductsLoaded(styleProducts, selectedColoredStyle);

      if (!!vendorDesignId && !!vendorCode && vfiVendorCodes.length > 0 && styleProductsLoaded
        && (vendorIdChanged
          || !arraysAreEqual(prevProps.vfiVendorCodes, vfiVendorCodes)
          || !arraysAreEqual(prevProps.styleProducts, styleProducts)
        )) {
        this.validateVendorDesignId(vendorDesignId);
      }

      // Use default max personalization name length for a vendor
      if (!maxPersonalizationNameLength && vendor.vfiMaxPersonalizationNameLength) {
        this.updateMaxPersonalizationNameLength(vendor.vfiMaxPersonalizationNameLength);
      }
    }

    if (styleProducts && styleProducts.length > 0 && !initializedFormValues) {
      this.initializeFormValues();
      this.setState({ initializedFormValues: true });
    }
  }

  initializeFormValues = () => {
    const {
      styleProducts,
      initialValues,
      selectedColoredStyle,
      initialize,
    } = this.props;

    const prodSizes = styleProducts.filter((sp) => sp.coloredStyleId === selectedColoredStyle.id).map((sp) => ({
      file: null,
      size: sp.size,
      imageUrl: null,
      id: null,
    }));

    if (initialValues.printFiles) {
      initialValues.printFiles.forEach((pf) => {
        const prodSize = prodSizes.find((ps) => ps.size === pf.size);

        if (prodSize) {
          prodSize.imageUrl = pf.image_url;
          prodSize.id = pf.id;
        }
      });
    }

    initialize({
      ...initialValues,
      printFiles: this.sortObjectsBySize(prodSizes),
    });
  };

  sortObjectsBySize = (arr) => {
    const order = [
      'YXS',
      'YS',
      'YM',
      'YL',
      'YXL',
      'XXS',
      'XS',
      'S',
      'SM',
      'M',
      'MD',
      'L',
      'LG',
      'XL',
      '2XL',
      '3XL',
      '4XL',
    ];

    return arr.sort((a, b) => (
      order.indexOf(a.size) - order.indexOf(b.size)));
  };

  wereStyleProductsLoaded = (styleProducts, selectedColoredStyle) => (
    styleProducts && styleProducts.length > 0 && styleProducts[0].coloredStyleId === selectedColoredStyle.id
  );

  vendorHasDesignIdValidation = () => {
    const {
      selectedColoredStyle,
      vfiVendorCodes,
    } = this.props;

    const vendorCode = selectedColoredStyle.style.vendorId;

    return vfiVendorCodes.includes(vendorCode);
  };

  validateVendorDesignId = (vendorDesignId) => {
    const {
      dispatch,
      selectedColoredStyle,
      changeSubmissionDisabled,
    } = this.props;

    const vendorCode = selectedColoredStyle.style.vendorId;

    if (this.vendorHasDesignIdValidation()) {
      changeSubmissionDisabled(true);
      dispatch(fetchAvailableSkusForVendorDesignId(vendorCode, vendorDesignId));
    }
  };

  isVendorDesignIdValid = (selectedColoredStyle, availableSkusForVendorDesignId, styleProducts) => {
    const availableSkusDesignId = availableSkusForVendorDesignId.vendorDesignId;
    const availableSkus = availableSkusForVendorDesignId.skus;
    const productSkus = (styleProducts || []).map((product) => product.sku);
    const {
      vendorDesignId,
      changeSubmissionDisabled,
    } = this.props;

    if (!this.wereStyleProductsLoaded(styleProducts, selectedColoredStyle)) return true;

    // Don't validate design ID if the validation endpoint isn't available for that vendor
    if (!this.vendorHasDesignIdValidation()) return true;

    // Vendor design ID is required
    if (!vendorDesignId) return false;

    // Don't validate design ID if available SKUs haven't been fetched yet
    if (!availableSkusDesignId || availableSkusDesignId !== vendorDesignId) return true;
    changeSubmissionDisabled(false);

    if (availableSkus.length === 0) return false;

    // All product SKUs have to be allowed
    for (const sku of productSkus) {
      if (!availableSkus.includes(sku) && !availableSkus.includes(sku.replace(/-Y/i, '-'))) {
        return false;
      }
    }

    return true;
  };

  handleVendorDesignIdValidationChanges = (vendorDesignIdValid) => {
    const {
      initialValues,
      changeSubmissionDisabled,
    } = this.props;

    if (!vendorDesignIdValid) {
      this.setState(() => ({
        unhidingItemDisabled: true,
      }), () => {
        this.updateStatus(customItemStatusEnum.Hidden);
        changeSubmissionDisabled(false);
      });

      return;
    }

    this.setState(() => ({
      unhidingItemDisabled: false,
    }), () => {
      const previousStatus = initialValues.status !== undefined ? initialValues.status : customItemStatusEnum.Hidden;
      this.updateStatus(previousStatus);
      changeSubmissionDisabled(false);
    });
  };

  updateMaxPersonalizationNameLength = (maxPersonalizationNameLength) => {
    const { change } = this.props;

    change('maxPersonalizationNameLength', maxPersonalizationNameLength);
  };

  updateStatus = (status) => {
    const { change } = this.props;

    change('status', status);
    change('customItemIsHidden', status === customItemStatusEnum.Hidden);
  };

  updateAttachedImage = (imageName, url) => {
    this.setState(() => ({ [`${imageName}AttachmentUrl`]: url }));
  };

  updateTimeline = (e) => {
    const {
      change,
      vendorTimelines,
    } = this.props;

    const timelineUuid = e.target.value;
    const timeline = timelineUuid && vendorTimelines.find((t) => t.uuid === timelineUuid);

    change('timelineDays', timeline ? timeline.timelineDays : null);
    change('timelineUuid', timeline ? timeline.uuid : null);
  };

  updateThreadColor = (e) => {
    const {
      change,
      changeSubmissionDisabled,
    } = this.props;

    const color = e.target.value;

    change('threadColor', color);
    changeSubmissionDisabled(false);
  };

  updateDesignInput = (e) => {
    const {
      change,
      changeSubmissionDisabled,
    } = this.props;

    const val = e.target.value;

    change('designId', val);
    changeSubmissionDisabled(false);
  };

  updateOptionInput = (e) => {
    const {
      change,
      changeSubmissionDisabled,
    } = this.props;

    const val = e.target.value;

    change('optionId', val);
    changeSubmissionDisabled(false);
  };

  unselectTimeline = () => {
    const { change } = this.props;
    change('timelineDays', null);
    change('timelineUuid', null);
  };

  changeColoredStyle = (coloredStyle) => {
    const {
      changeColoredStyle,
      closeChangeSkuModal,
    } = this.props;

    changeColoredStyle(coloredStyle);
    this.unselectLayout();
    closeChangeSkuModal();
  };

  selectLayout = (selectedLayout) => {
    this.setState(() => ({ selectedLayout }));
  };

  unselectLayout = () => {
    this.setState(() => ({ selectedLayout: null }));
  };

  selectTab = (selectedTab) => {
    this.setState(() => ({ selectedTab }));
  };

  getTabs = () => {
    const {
      isCustomItem,
      change,
      selectedColoredStyle,
      imageFront,
      imageBack,
      imageLeft,
      imageRight,
      personalizationItemType,
      vendor,
      vendorTimelines,
      availableSkusForVendorDesignId,
      colorsDictionary,
      allDecorationLocations,
      lockerLogos,
      decorations,
      styleProducts,
      disabledFields = {},
    } = this.props;

    const {
      selectedLayout,
      unhidingItemDisabled,
      imageFrontAttachmentUrl,
      imageBackAttachmentUrl,
      imageLeftAttachmentUrl,
      imageRightAttachmentUrl,
    } = this.state;

    const {
      name,
      discount,
    } = selectedColoredStyle.style;

    const isVFI = selectedColoredStyle.style.vendorFulfilledItem;

    const tabs = [
      {
        title: 'Properties',
        name: customiteItemTabEnum.Properties,
        content: (
          <div className='mt-20'>
            <CustomizeItemPropertiesTab
              isCustomItem={isCustomItem}
              imageFront={imageFront}
              imageBack={imageBack}
              imageLeft={imageLeft}
              imageRight={imageRight}
              styleName={name}
              vendorTimelines={vendorTimelines}
              discount={discount}
              isVFI={isVFI}
              vendor={vendor}
              change={change}
              updatePersonalizationType={this.updatePersonalizationType}
              updateTimeline={this.updateTimeline}
              updateAttachedImage={this.updateAttachedImage}
              imageFrontAttachmentUrl={imageFrontAttachmentUrl}
              imageBackAttachmentUrl={imageBackAttachmentUrl}
              imageLeftAttachmentUrl={imageLeftAttachmentUrl}
              imageRightAttachmentUrl={imageRightAttachmentUrl}
              unhidingItemDisabled={unhidingItemDisabled}
              validateVendorDesignId={this.validateVendorDesignId}
              handleVendorDesignIdValidationChanges={this.handleVendorDesignIdValidationChanges}
              isVendorDesignIdValid={
                this.isVendorDesignIdValid(selectedColoredStyle, availableSkusForVendorDesignId, styleProducts)
              }
              disabled={disabledFields}
            />
          </div>
        ),
      },
    ];

    if (isCustomItem) {
      tabs.push(
        {
          title: 'Decoration',
          name: customiteItemTabEnum.Decoration,
          content: (
            <CustomizeItemDecorationsTab
              change={change}
              colorsDictionary={colorsDictionary}
              allDecorationLocations={allDecorationLocations}
              lockerLogos={lockerLogos}
              decorations={decorations}
              selectedColoredStyle={selectedColoredStyle}
              selectedLayout={selectedLayout}
              selectLayout={this.selectLayout}
              unselectLayout={this.unselectLayout}
              personalizationItemType={personalizationItemType}
            />
          ),
        }
      );
      if (selectedColoredStyle?.style?.decorationMethod === decorationMethodEnum.SUB) {
        tabs.push(
          {
            title: 'MWW Print Files',
            name: customiteItemTabEnum.PrintFiles,
            content: (
              <CustomizeItemPrintFilesTab
                change={change}
                updateThreadColor={this.updateThreadColor}
                updateDesignInput={this.updateDesignInput}
                updateOptionInput={this.updateOptionInput}
                selectedColoredStyle={selectedColoredStyle}
                styleProducts={styleProducts}
              />
            ),
          }
        );
      }
    }

    return tabs;
  };

  onSubmit = async (form) => {
    const {
      saveCustomItem,
      selectedColoredStyle,
      availableSkusForVendorDesignId,
      styleProducts,
    } = this.props;

    await saveCustomItem({
      ...form,
      isValidDesignId: this.isVendorDesignIdValid(selectedColoredStyle, availableSkusForVendorDesignId, styleProducts),
    });
  };

  render() {
    const {
      lockerId,
      handleSubmit,
      error,
      styleProducts,
      selectedColoredStyle,
      changeSkuModalIsOpen,
      openChangeSkuModal,
      closeChangeSkuModal,
    } = this.props;

    const { selectedTab } = this.state;

    const isVFI = selectedColoredStyle.style.vendorFulfilledItem;

    return (
      <>
        {
          changeSkuModalIsOpen &&
          <ColoredStylesTable
            lockerId={lockerId}
            selectColoredStyle={this.changeColoredStyle}
            closeModal={closeChangeSkuModal}
            selectedColoredStyle={selectedColoredStyle}
          />
        }
        {
          !changeSkuModalIsOpen &&
          <CustomItemHeaderDetailsSection
            selectedColoredStyle={selectedColoredStyle}
            isVFI={isVFI}
            openChangeSku={openChangeSkuModal}
            styleProducts={styleProducts}
          />
        }
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          className={changeSkuModalIsOpen ? 'display-none' : ''} // Form needs to stay mounted to avoid re-mounting of the form's fields
          id={customItemForm}
        >
          <div>
            <div className='order__log'>
              <Tabs
                tabs={this.getTabs()}
                selectedTab={selectedTab}
                selectTab={this.selectTab}
              />
            </div>

            <FormError error={error} />
          </div>
        </form>

      </>
    );
  }
}

CustomizeItem.propTypes = {
  initialValues: PropTypes.object.isRequired,
  change: PropTypes.func,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  saveCustomItem: PropTypes.func.isRequired,
  lockerId: PropTypes.number.isRequired,
  allDecorationLocations: PropTypes.arrayOf(PropTypes.object),
  lockerLogos: PropTypes.arrayOf(PropTypes.object),
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })),
  styleProducts: PropTypes.arrayOf(PropTypes.shape({
    basePrice: PropTypes.number,
    coloredStyleId: PropTypes.number.isRequired,
  })).isRequired,
  selectedColoredStyle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrlFront: PropTypes.string,
    personalizationColors: PropTypes.arrayOf(PropTypes.shape({
      floodColorId: PropTypes.number.isRequired,
      custom: PropTypes.bool.isRequired,
    })),
    style: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      category: PropTypes.shape({
        name: PropTypes.string,
      }),
      vendorId: PropTypes.string.isRequired,
      gender: PropTypes.string,
      discount: PropTypes.number,
      vendorFulfilledItem: PropTypes.bool,
      decorationMethod: PropTypes.string,
      decorationLocations: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        location: PropTypes.string,
      })),
    }),
  }).isRequired,
  changeColoredStyle: PropTypes.func.isRequired,
  imageFront: PropTypes.string,
  imageBack: PropTypes.string,
  imageRight: PropTypes.string,
  imageLeft: PropTypes.string,
  decorations: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    decorationMethod: PropTypes.string,
    decorationLocation: PropTypes.string,
    decorationHeight: PropTypes.string,
    personalizationType: PropTypes.string,
    personalizationColorType: PropTypes.string,
    required: PropTypes.bool,
    colorId: PropTypes.number,
    outlineColorId: PropTypes.number,
  })),
  personalizationItemType: PropTypes.string,
  maxPersonalizationNameLength: PropTypes.number,
  vendor: PropTypes.shape({
    vfiMaxPersonalizationNameLength: PropTypes.number,
  }),
  vendorTimelines: PropTypes.arrayOf(PropTypes.shape({
    vendorId: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    timelineDays: PropTypes.number.isRequired,
  })),
  vfiVendorCodes: PropTypes.arrayOf(PropTypes.string),
  vendorDesignId: PropTypes.string,
  availableSkusForVendorDesignId: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.number.isRequired,
  changeSkuModalIsOpen: PropTypes.bool.isRequired,
  openChangeSkuModal: PropTypes.func.isRequired,
  closeChangeSkuModal: PropTypes.func.isRequired,
  changeSubmissionDisabled: PropTypes.func.isRequired,
};

const selector = formValueSelector(customItemForm);
const mapStateToProps = (state) => ({
  isCustomItem: selector(state, 'isCustomItem'),
  imageFront: selector(state, 'imageFront'),
  imageBack: selector(state, 'imageBack'),
  imageRight: selector(state, 'imageRight'),
  imageLeft: selector(state, 'imageLeft'),
  decorations: selector(state, 'decorations'),
  personalizationItemType: selector(state, 'personalizationItemType'),
  vendorDesignId: selector(state, 'vendorDesignId'),
  maxPersonalizationNameLength: selector(state, 'maxPersonalizationNameLength'),
  colorsDictionary: state.productCatalog.colorsDictionary,
  allDecorationLocations: state.productCatalog.decorationLocations,
  lockerLogos: state.lockerManager.lockerLogos,
  styleProducts: state.productCatalog.styleProducts,
  vendor: state.productCatalog.vendor,
  vendorTimelines: state.productCatalog.vendorTimelines,
  vfiVendorCodes: (state.productCatalog.vendors || []).map((vendor) => vendor.code),
  availableSkusForVendorDesignId: state.vendorOrders.availableSkusForVendorDesignId,
  disabled: PropTypes.object,
});

export default connect(mapStateToProps)(reduxForm({
  form: customItemForm,
  enableReinitialize: false,
  validate: validateCustomItem,
})(CustomizeItem));
