import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import {
  getStyleById,
  getColoredStylesForStyle,
  getColors,
  getDecorationLocations,
} from '@redux/productCatalog/actions';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import ProductCatalogQuickViewHeader from './ProductCatalogQuickViewHeader';
import ProductCatalogQuickViewInfo from './ProductCatalogQuickViewInfo';
import ProductCatalogQuickViewActions from './ProductCatalogQuickViewActions';

class ProductCatalogQuickView extends PureComponent {
  componentDidMount() {
    const {
      dispatch,
      styleId,
    } = this.props;

    dispatch(getStyleById(styleId));
    dispatch(getColoredStylesForStyle(styleId));
    dispatch(getColors());
    dispatch(getDecorationLocations());
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      styleId,
    } = this.props;

    if (styleId !== prevProps.styleId) {
      dispatch(getStyleById(styleId));
      dispatch(getColoredStylesForStyle(styleId));
    }
  }

  render() {
    const {
      styleId,
      closeDetails,
      style,
      coloredStyles,
      decorationLocations,
    } = this.props;

    const isActive = styleId ? 'is-active' : '';

    return (
      <TableQuickViewContainer
        noPadding={true}
        size={TableQuickViewSizeEnum.FitContent}
        isActive={isActive}
      >
        <ProductCatalogQuickViewHeader
          style={style}
          closeDetails={closeDetails}
          coloredStyles={coloredStyles}
        />
        <div className='tableView__details--content'>
          <ProductCatalogQuickViewInfo
            style={style}
            coloredStyles={coloredStyles}
            decorationLocations={decorationLocations}
          />
          <ProductCatalogQuickViewActions styleId={styleId} />
        </div>
      </TableQuickViewContainer>
    );
  }
}

ProductCatalogQuickView.propTypes = {
  styleId: PropTypes.number.isRequired,
  closeDetails: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object),
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  style: productCatalog.style,
  coloredStyles: productCatalog.coloredStyles,
  decorationLocations: productCatalog.decorationLocations,
});

export default connect(mapStateToProps)(ProductCatalogQuickView);
