import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVendorTimelines } from '@redux/productCatalog/actions';
import ItemCustomDecorations from './ItemCustomDecorations';

class ItemDecorations extends PureComponent {
  componentDidMount() {
    const {
      dispatch,
      item: {
        vendorCode,
        vendorFulfilledItem,
      },
    } = this.props;

    if (vendorFulfilledItem) {
      dispatch(getVendorTimelines(vendorCode));
    }
  }

  renderImage = (src, text) => (
    src &&
    <div className='decorationList__preview-item'>
      <div className='decorationList__preview-item--image'>
        <img src={src} />
      </div>
      <div className='decorationList__preview-location'>{text}</div>
    </div>
  );

  render() {
    const {
      allDecorationLocations,
      vendorTimelines,
      item: {
        imageFront,
        imageBack,
        imageLeft,
        imageRight,
        decorations,
        vendorFulfilledItem,
        vendorDesignId,
        timelineUuid,
      },
    } = this.props;

    let vendorTimeline = vendorTimelines
      .find((timeline) => timeline.uuid === timelineUuid);
    vendorTimeline = vendorTimeline && `${vendorTimeline.description} (${vendorTimeline.timelineDays} business days)`;

    return (
      <div>
        <div className='decorationList__container'>
          <div className='flex__direction-column'>
            <div className='flex mb-10'>
              {
                vendorFulfilledItem &&
                <div className='flex align__center'>
                  <span className='decorationList__container--vendor-label ml-20'>Vendor Timeline:</span> <span className='decorationList__container--vendor-value'>{vendorTimeline || '-'}</span>
                  <span className='decorationList__container--vendor-label ml-10'>Design ID:</span> <span className='decorationList__container--vendor-value'>{vendorDesignId || '-'}</span>
                </div>
              }
            </div>
            <div className='flex'>
              {this.renderImage(imageFront, 'Front')}
              {this.renderImage(imageRight, 'Side Right')}
              {this.renderImage(imageLeft, 'Side Left')}
              {this.renderImage(imageBack, 'Back')}
              <hr className='vertical decorationList__preview-delimiter' />
              <ItemCustomDecorations
                allDecorationLocations={allDecorationLocations}
                decorations={decorations}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ItemDecorations.propTypes = {
  allDecorationLocations: PropTypes.array.isRequired,
  vendorTimelines: PropTypes.array.isRequired,
  item: PropTypes.shape({
    imageFront: PropTypes.string,
    imageBack: PropTypes.string,
    imageLeft: PropTypes.string,
    imageRight: PropTypes.string,
    decorations: PropTypes.arrayOf(PropTypes.object).isRequired,
    vendorFulfilledItem: PropTypes.bool,
    vendorCode: PropTypes.string,
    vendorDesignId: PropTypes.number,
    timelineUuid: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  vendorTimelines: productCatalog.vendorTimelines,
});

export default connect(mapStateToProps)(ItemDecorations);
