import React from 'react';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import ApplicationSettingsLink from '../MyApps/ApplicationSettingsLink';
import ServiceManagerLink from '../MyApps/ServiceManagerLink';

const AdvancedOptions = React.memo(() => (
  <div className='my-apps-tray w-100'>
    <PermissionHandler permissions={PermissionsEnum.ApplicationSettingsVisit}>
      <ApplicationSettingsLink />
    </PermissionHandler>

    <PermissionHandler permissions={PermissionsEnum.ServiceManagerVisit}>
      <ServiceManagerLink />
    </PermissionHandler>
  </div>
));

export default AdvancedOptions;
