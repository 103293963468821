import React from 'react';
import { qualityAssuranceCodesUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const QualityAssuranceCodesLink = React.memo(() => (
  <MenuTile
    title={'QA Codes'}
    url={qualityAssuranceCodesUrl}
    icon={'check_box'}
  />
));

export default QualityAssuranceCodesLink;
