import React from 'react';

interface OwnProps {
  onClose?: () => void;
  onConfirm?: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  confirmBtnDisabled?: boolean;
  cancelBtnDisabled?: boolean;
  confirmBtnClass?: string;
  cancelBtnClass?: string;
  isDangerousAction?: boolean;
  formSubmission?: boolean;
  displayConfirmBtn?: boolean;
  displayCancelBtn?: boolean;
  dataTest?: string;
  formId?: string;
}

type Props = OwnProps;

const ModalButtons = React.memo(({
  onClose = undefined,
  onConfirm = undefined,
  confirmBtnText = 'Confirm',
  cancelBtnText = 'Cancel',
  confirmBtnDisabled = false,
  cancelBtnDisabled = false,
  confirmBtnClass = '',
  cancelBtnClass = '',
  isDangerousAction = false,
  formSubmission = false,
  displayConfirmBtn = true,
  displayCancelBtn = true,
  dataTest = 'modal',
  formId,
}: Props) => (
  <div className='modal__footer'>
    <div className='modal__btn-container'>
      {
        displayCancelBtn &&
        <button
          data-test={`${dataTest}-cancel`}
          className={`modal__btn btn-borderless btn-borderless--green button--size-l ${cancelBtnClass}`}
          onClick={onClose}
          disabled={cancelBtnDisabled}
          type='button'
        >
          <span>{cancelBtnText}</span>
        </button>
      }
      {
        displayConfirmBtn &&
        <button
          className={`modal__btn btn-borderless btn-borderless--green button--size-l ${isDangerousAction ? 'btn-borderless--red' : ''} ${confirmBtnClass}`}
          onClick={formSubmission ? undefined : onConfirm}
          disabled={confirmBtnDisabled}
          data-test={`${dataTest}-submit`}
          type={formSubmission ? 'submit' : 'button'}
          form={formSubmission && formId ? `${formId}` : ''}
        >
          <span>{confirmBtnText}</span>
        </button>
      }
    </div>
  </div>
));

export default ModalButtons;
