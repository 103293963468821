import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';

interface OwnProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  imageUrl?: string;
}

type Props = OwnProps;

const ImagePreviewModal = React.memo(({
  modalIsOpen,
  closeModal,
  imageUrl,
}: Props) => (
  <Modal
    isOpen={modalIsOpen}
    closeModal={closeModal}
    modalSize={'xl'}
  >
    <img
      className='modal_full-image'
      src={imageUrl}
    />
  </Modal>
));

export default ImagePreviewModal;
