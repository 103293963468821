export const cancelReasonEnum = {
  DuplicateOrder: 'Duplicate order',
  Other: 'Other',
} as const;

export const refundReasonEnum = {
  LateOrder: 'Late Order',
  HassleFreeReturn: 'Hassle-free Return',
  Other: 'Other',
} as const;

export const cancelFullOrderReasonEnum = {
  AddingItems: 'Adding items',
  ChangingItems: 'Changing items',
  ChangingOrAddingPersonalization: 'Changing or adding personalization',
  TurnaroundTime: 'Turnaround time',
  StockOut: 'Stock out/ Delay/ Backorder',
  CustomerError: 'Customer Error',
  WrongStore: 'Wrong store',
  WrongItems: 'Wrong items',
  DiscountNotApplied: 'Discount not applied',
  LogoAdjustments: 'Logo adjustments made',
  LogoCantBeProduced: 'Logo can\'t be produced',
  CustomerRequest: 'Customer Request',
  NoLongerWithOrg: 'No longer with org',
} as const;

export const refundItemReasonEnum = {
  LateOrder: 'Late Order',
  HassleFreeReturn: 'Hassle-free Return',
  ItemDiscontinued: 'Item Discontinued (Create Zendesk ticket)',
  Other: 'Other',
} as const;

export const cancelItemReasonEnum = {
  TurnaroundTime: 'Turnaround time',
  StockOut: 'Stock out/ Delay/ Backorder',
  CustomerError: 'Customer Error',
  WrongStore: 'Wrong store',
  WrongItems: 'Wrong items',
  LogoAdjustments: 'Logo adjustments made',
  LogoCantBeProduced: 'Logo can\'t be produced',
  CustomerRequest: 'Customer Request',
} as const;
