import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EditButton from '@sharedComponents/Display/EditButton';
import LockerManagerEditModal from '../../LockerManagerModals/LockerManagerEditModal';
import LockerStatus from './LockerStatus';

class LockerManagerQuickViewHeader extends PureComponent {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const {
      lockerId,
      status,
      closeDetails,
    } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <div className='lockerManager__details-header'>
        <LockerManagerEditModal
          isOpen={modalIsOpen}
          closeModal={this.closeModal}
        />
        <div className='lockerManager__details-header--info'>
          Locker L{lockerId}
          {
            status &&
            <LockerStatus status={status} />
          }
          <EditButton
            onClick={this.openModal}
          />
        </div>
        <a
          className='flex flagging__actions-button button'
          onClick={closeDetails}
        >
          <i className='material-icons lockerManager__details-close'>
            close
          </i>
          <span className='uppercase'>Close</span>
        </a>
      </div>
    );
  }
}

LockerManagerQuickViewHeader.propTypes = {
  lockerId: PropTypes.number.isRequired,
  status: PropTypes.string,
  closeDetails: PropTypes.func.isRequired,
};

export default LockerManagerQuickViewHeader;
