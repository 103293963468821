import * as actionTypes from './types';
import { PagedList } from '@models/common/PagedList';

export interface AdminsState {
  adminsList: PagedList<any>;
  admin: any;
  adminSuggestions: any[];
  accountExecutives: [];
}

const initialState: AdminsState = {
  adminsList: {
    totalPages: 1,
    pageNumber: 1,
    pageSize: 10,
    queue: [],
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  },
  admin: {},
  adminSuggestions: [],
  accountExecutives: [],
};

export default function admins(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_ADMINS_TABLE:
      return {
        ...state,
        adminsList: action.data,
      };
    case actionTypes.UPDATE_ADMIN:
      return {
        ...state,
        admin: action.data,
      };
    case actionTypes.UPDATE_ADMIN_SUGGESTIONS:
      return {
        ...state,
        adminSuggestions: action.data,
      };
    case actionTypes.UPDATE_ACCOUNT_EXECUTIVES:
      return {
        ...state,
        accountExecutives: action.data,
      };
    default:
      return state;
  }
}
