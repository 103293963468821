import React from 'react';
import PropTypes from 'prop-types';
import OrderManagementDetailsItemExpandPersonalization from './OrderManagementDetailsItemExpandPersonalization';

const OrderManagementDetailsItemExpandPersonalizations = React.memo(({
  personalizations,
  colorsDictionary,
}) => {
  const pers = {};
  const groups = {};
  for (let i = 0; i < personalizations.length; i += 1) {
    if (!pers[personalizations[i].colorId]) {
      pers[personalizations[i].colorId] = [];
    }
    pers[personalizations[i].colorId].push(personalizations[i]);
  }

  const enums = Object.keys(pers);

  for (let i = 0; i < enums.length; i += 1) {
    const enumPers = pers[enums[i]];
    groups[enums[i]] = [];
    for (let j = 0; j < enumPers.length; j += 3) {
      groups[enums[i]].push(enumPers.slice(j, j + 3));
    }
  }
  const items = {};

  for (let i = 0; i < enums.length; i += 1) {
    items[enums[i]] = groups[enums[i]].map((item, key) => (
      <OrderManagementDetailsItemExpandPersonalization
        key={key}
        personalizations={item}
        colorsDictionary={colorsDictionary}
      />
    ));
  }
  const components = {};

  for (let i = 0; i < enums.length; i += 1) {
    components[enums[i]] = (
      <div key={i}>
        <ul>
          {items[enums[i]]}
        </ul>
      </div>
    );
  }

  return (
    <div>
      {Object.values(components)}
    </div>
  );
});

OrderManagementDetailsItemExpandPersonalizations.propTypes = {
  personalizations: PropTypes.array,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

export default OrderManagementDetailsItemExpandPersonalizations;
