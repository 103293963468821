import { undecorate } from '@api/squadlockerServices/colored-style-selections';
import coloredStyleSelectionsUrls from '@constants/sqdApiUrls/coloredStyleSelectionsUrls';
import lockerUrls from '@constants/sqdApiUrls/lockerUrls';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  fetchLockerInfo,
  fetchFulfillmentLockerInfo,
} from '@redux/lockerManager/actions';
import {
  materialSwal,
  navigateToPage,
} from '@util/componentHelper';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import {
  slServicesApi,
  fulfillmentApi,
} from '../../sqdApis';

export const closeLocker = (lockerId) => async (dispatch) => {
  const call = slServicesApi.put(lockerUrls.closeLocker(lockerId));
  const res = await makeApiCallWithErrorModal(call);

  if (res?.success) {
    materialSwal('Success', res.message, 'success');
    dispatch(fetchLockerInfo(lockerId));
    dispatch(fetchFulfillmentLockerInfo(lockerId));
  }
};

export const cancelLocker = (lockerId) => async (dispatch) => {
  const call = slServicesApi.put(lockerUrls.cancelLocker(lockerId));
  const res = await makeApiCallWithErrorModal(call);

  if (res?.success) {
    materialSwal('Success', res.message, 'success');
    dispatch(fetchLockerInfo(lockerId));
    dispatch(fetchFulfillmentLockerInfo(lockerId));
  }
};

export const claimLocker = (lockerId, claimed) => async (dispatch) => {
  const call = slServicesApi.put(lockerUrls.claimLocker(lockerId, claimed));
  const res = await makeApiCallWithErrorModal(call);

  if (res?.success) {
    materialSwal('Success', res.message, 'success');
    dispatch(fetchLockerInfo(lockerId));
    dispatch(fetchFulfillmentLockerInfo(lockerId));
  }
};

export const editProductEssential = async (product) => {
  const call = slServicesApi.put(coloredStyleSelectionsUrls.editEssential(product.id, product.isLockerItem));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const undecorateProduct = async (product) => {
  const call = undecorate(product.id);
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const editLockerData = async (
  lockerId,
  logoId,
  partnerId,
  teamUid,
  teamName,
  organizationId,
  programLink,
  programName,
  approved,
  disableEmails,
  searchable,
  genders,
  playerCount,
  ages,
  sport,
  note,
  discount,
  markup,
  type
) => {
  const body = {
    teamName,
    approved,
    logoId: logoId ?? undefined,
    partnerId: partnerId ?? undefined,
    teamUid: teamUid ?? undefined,
    organizationId: organizationId ?? undefined,
    programLink: programLink ?? undefined,
    programName: programName ?? undefined,
    disableEmails: disableEmails ?? undefined,
    searchable: searchable ?? undefined,
    genders: genders ?? undefined,
    playerCount: playerCount ?? undefined,
    ages: ages ?? undefined,
    sport: sport ?? undefined,
    note: note ?? undefined,
    discount: discount ?? undefined,
    markup: markup ?? undefined,
    lockerType: type ?? undefined,
  };

  const call = slServicesApi.put(lockerUrls.lockerDetails(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const response = await makeApiCallWithErrorModal(call);

  return response;
};

export const editLockerProductionNote = async (lockerId, productionNote) => {
  const body = JSON.stringify({
    productionNote,
  });

  const call = fulfillmentApi.put(lockerUrls.lockerFulfillment.lockerDetails(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const response = await makeApiCallWithErrorModal(call);

  return response;
};

export const inviteUserByEmail = async (lockerId, email) => {
  const call = slServicesApi.put(lockerUrls.lockerManagers(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  const response = await makeApiCallWithErrorModal(call);

  return response;
};

export const cloneLocker = async (
  originalLockerId,
  newLockerName,
  teamType,
  sportId,
  ignoreLogos,
  ignoreCollections,
  partnerId,
  partnerTeamUid,
  lockerManagerId
) => {
  const call = slServicesApi.post(lockerUrls.cloneLocker(
    originalLockerId,
    newLockerName,
    teamType,
    sportId,
    ignoreLogos,
    ignoreCollections,
    partnerId,
    partnerTeamUid,
    lockerManagerId
  ));
  const res = await makeApiCallWithErrorModal(call);

  if (res?.success) {
    materialSwal('Success', res.message, 'success');
    navigateToPage(lockerDetailsUrl(res.result.lockerId));
  }
};

export const addLogoToLockerLogos = async (lockerId, logoId, hideModal = false) => {
  const call = slServicesApi.put(lockerUrls.logoFromLockerLogos(lockerId, logoId));
  if (hideModal) {
    return makeApiCall(call);
  }

  return makeApiCallWithErrorModal(call);
};

export const removeLogoFromLockerLogos = async (lockerId, logoId) => {
  const call = slServicesApi.delete(lockerUrls.logoFromLockerLogos(lockerId, logoId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const uploadLogoToAdminLogos = async (lockerId, attachedFile) => {
  const formData = new FormData();

  if (attachedFile) {
    for (const f of attachedFile) {
      formData.append('logo', f, f.name);
    }
  }

  const call = slServicesApi.post(lockerUrls.lockerManagerLogos(lockerId), {
    body: formData,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const uploadLogoToLockerLogos = async (lockerId, attachedFile) => {
  const formData = new FormData();

  if (attachedFile) {
    for (const f of attachedFile) {
      formData.append('logo', f, f.name);
    }
  }

  const call = slServicesApi.post(lockerUrls.lockerLogoBankLogos(lockerId), {
    body: formData,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const uploadFlyer = async (lockerId, attachedFile) => {
  const formData = new FormData();

  if (attachedFile) {
    for (const f of attachedFile) {
      formData.append('flyer', f, f.name);
    }
  }

  const call = slServicesApi.post(lockerUrls.lockerManagerStoreFlyer(lockerId), {
    body: formData,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const archiveLogo = async (logoId) => {
  const call = slServicesApi.delete(lockerUrls.archiveLogo(logoId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const restoreLogo = async (logoId) => {
  const call = slServicesApi.put(lockerUrls.restoreLogo(logoId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const requeueLogoForReview = async (logoId) => {
  const call = slServicesApi.post(lockerUrls.requeueLogoForReview(logoId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const replaceLogo = async (lockerId, logoId, newLogoId, cssIds) => {
  const body = JSON.stringify({
    lockerId,
    logoId,
    newLogoId,
    cssIds,
  });
  const call = slServicesApi.post(lockerUrls.replaceLogo(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const deleteProducts = async (items, lockerId) => {
  const body = JSON.stringify({
    products: items.map((i) => ({
      id: i.id,
      isCustomItem: i.isLockerItem,
    })),
  });
  const call = slServicesApi.put(lockerUrls.removeProducts(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const relaunchLocker = async (lockerId) => {
  const call = slServicesApi.post(lockerUrls.relaunchLocker(lockerId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const addProductsToLocker = async (lockerId, csIds) => {
  const body = JSON.stringify({ csIds });
  const call = slServicesApi.put(lockerUrls.addProducts(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const addColoredStylesToLocker = async (lockerId, coloredStyles) => {
  const coloredStyleIds = coloredStyles.map((cs) => cs.id);

  const call = slServicesApi.post(lockerUrls.lockerColoredStyleSelections(lockerId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ coloredStyleIds }),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const importItemsToLocker = async (lockerId, items, importLogos = false) => {
  const body = JSON.stringify({
    ids: items.map((i) => i.id),
  });
  const call = slServicesApi.post(lockerUrls.items(lockerId, importLogos), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
