/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type BatchWarehouseCategoryEnum = 'TotalOrders' | 'ReadyOrders' | 'ReadyHagOnlyOrders' | 'ReadyEmbOnlyOrders' | 'ReadyNoDecOrders' | 'ReadyAllDecOrders' | 'PartialReadyOrders' | 'BackorderedOrders' | 'ExpressProductionOrders' | 'PartnerOrders' | 'DispatchedOrders' | 'PickedOrders' | 'NeedPrintOrders' | 'CheckedInOrders' | 'ProducedOrders' | 'ShippedOrders';


export const BatchWarehouseCategoryEnum = {
  TotalOrders: 'TotalOrders' as BatchWarehouseCategoryEnum,
  ReadyOrders: 'ReadyOrders' as BatchWarehouseCategoryEnum,
  ReadyHagOnlyOrders: 'ReadyHagOnlyOrders' as BatchWarehouseCategoryEnum,
  ReadyEmbOnlyOrders: 'ReadyEmbOnlyOrders' as BatchWarehouseCategoryEnum,
  ReadyNoDecOrders: 'ReadyNoDecOrders' as BatchWarehouseCategoryEnum,
  ReadyAllDecOrders: 'ReadyAllDecOrders' as BatchWarehouseCategoryEnum,
  PartialReadyOrders: 'PartialReadyOrders' as BatchWarehouseCategoryEnum,
  BackorderedOrders: 'BackorderedOrders' as BatchWarehouseCategoryEnum,
  ExpressProductionOrders: 'ExpressProductionOrders' as BatchWarehouseCategoryEnum,
  PartnerOrders: 'PartnerOrders' as BatchWarehouseCategoryEnum,
  DispatchedOrders: 'DispatchedOrders' as BatchWarehouseCategoryEnum,
  PickedOrders: 'PickedOrders' as BatchWarehouseCategoryEnum,
  NeedPrintOrders: 'NeedPrintOrders' as BatchWarehouseCategoryEnum,
  CheckedInOrders: 'CheckedInOrders' as BatchWarehouseCategoryEnum,
  ProducedOrders: 'ProducedOrders' as BatchWarehouseCategoryEnum,
  ShippedOrders: 'ShippedOrders' as BatchWarehouseCategoryEnum,
};
