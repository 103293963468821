import React from 'react';
import PropTypes from 'prop-types';
import { parseDateNumeric } from '@util/dateHandler';
import InfoField from '@sharedComponents/Display/InfoField';

const HeaderDetails = React.memo(({ admin }) => (
  <div className='lockerManagerEdit__details-container admins__header'>
    <div className='admins__image--default ml-20 mr-40' />
    <div className='lockerManagerEdit__details-info w-100 mr-40'>
      <div className='lockerManagerEdit__details-column--l'>
        <InfoField
          label={'Role'}
          value={admin.role}
        />
        <div className='flex'>
          <InfoField
            label={'Account ID'}
            value={admin.id}
          />
        </div>
        <InfoField
          label={'Organization Type'}
          value={admin.organizationType}
        />
      </div>
      <div className='lockerManagerEdit__details-column--l'>
        <InfoField
          label={'Signed Up On'}
          value={admin.createdAt && parseDateNumeric(admin.createdAt)}
        />
        <InfoField
          label={'Account Executive'}
          value={admin.accountManager}
        />
      </div>
      <div className='lockerManagerEdit__details-column--l'>
        <InfoField
          label={'Phone Number'}
          value={admin.phoneNumber}
        />
        <div className='flex'>
          <InfoField
            label={'E-mail Address'}
            value={admin.email && (
              <a
                href={`mailto:${admin.email}`}
                className='link'
              >
                {admin.email}
              </a>
            )}
          />
        </div>
      </div>
      <div className='lockerManagerEdit__details-column--l w-30'>
        <InfoField
          label={'Classification'}
          value={admin.classification}
          defaultValue={'Unclassified'}
        />
        <InfoField
          label={'Notes'}
          value={admin.notes}
        />
      </div>
    </div>
  </div>
));

HeaderDetails.propTypes = {
  admin: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    accountManager: PropTypes.string,
    role: PropTypes.string,
    createdAt: PropTypes.string,
    classification: PropTypes.string,
    notes: PropTypes.string,
    organizationType: PropTypes.string,
  }).isRequired,
};

export default HeaderDetails;
