import { PrepressJobOrderIndicatorEnum } from '@api/fulfillment/models';

import blueCircle from '@assets/indicators/BlueCircle.svg';
import blueMinus from '@assets/indicators/BlueMinus.svg';
import bluePlus from '@assets/indicators/BluePlus.svg';
import blueSquare from '@assets/indicators/BlueSquare.svg';
import blueTriangle from '@assets/indicators/BlueTriangle.svg';
import greenCircle from '@assets/indicators/GreenCircle.svg';
import greenMinus from '@assets/indicators/GreenMinus.svg';
import greenPlus from '@assets/indicators/GreenPlus.svg';
import greenSquare from '@assets/indicators/GreenSquare.svg';
import greenTriangle from '@assets/indicators/GreenTriangle.svg';
import redCircle from '@assets/indicators/RedCircle.svg';
import redMinus from '@assets/indicators/RedMinus.svg';
import redPlus from '@assets/indicators/RedPlus.svg';
import redSquare from '@assets/indicators/RedSquare.svg';
import redTriangle from '@assets/indicators/RedTriangle.svg';
import yellowCircle from '@assets/indicators/YellowCircle.svg';
import yellowMinus from '@assets/indicators/YellowMinus.svg';
import yellowPlus from '@assets/indicators/YellowPlus.svg';
import yellowSquare from '@assets/indicators/YellowSquare.svg';
import yellowTriangle from '@assets/indicators/YellowTriangle.svg';

export const pathToImage = (indicator: PrepressJobOrderIndicatorEnum): string | null => {
  switch (indicator) {
    case 'BlueCircle': return blueCircle;
    case 'BlueMinus': return blueMinus;
    case 'BluePlus': return bluePlus;
    case 'BlueSquare': return blueSquare;
    case 'BlueTriangle': return blueTriangle;

    case 'GreenCircle': return greenCircle;
    case 'GreenMinus': return greenMinus;
    case 'GreenPlus': return greenPlus;
    case 'GreenSquare': return greenSquare;
    case 'GreenTriangle': return greenTriangle;

    case 'RedCircle': return redCircle;
    case 'RedMinus': return redMinus;
    case 'RedPlus': return redPlus;
    case 'RedSquare': return redSquare;
    case 'RedTriangle': return redTriangle;

    case 'YellowCircle': return yellowCircle;
    case 'YellowMinus': return yellowMinus;
    case 'YellowPlus': return yellowPlus;
    case 'YellowSquare': return yellowSquare;
    case 'YellowTriangle': return yellowTriangle;
  }

  return null;
};
