export const getImageMagnifyDefaultOptions = (filePath: string, proportion: number) => {
  const ImageMagnifyDefaultOptions = {
    smallImage: {
      src: filePath,
      isFluidWidth: true,
    },
    largeImage: {
      src: filePath,
      width: 1200,
      height: 1200,
    },
    enlargedImagePosition: 'over',
    imageClassName: 'magnify-image--small',
    enlargedImageContainerClassName: 'magnify-image--big',
  };

  if (proportion > 1) {
    ImageMagnifyDefaultOptions.largeImage.height = ImageMagnifyDefaultOptions.largeImage.height / proportion;
  } else {
    ImageMagnifyDefaultOptions.largeImage.width = ImageMagnifyDefaultOptions.largeImage.width * proportion;
  }

  return ImageMagnifyDefaultOptions;
};
