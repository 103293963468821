import Icon from '@components/shared/Icons/Icon';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import { parseDateNumeric } from '@util/dateHandler';
import React from 'react';
import rushIcon from '@assets/icon_rush.svg';
import expressIcon from '@assets/icon_express-production.svg';
import { Link } from 'react-router-dom';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';

interface OwnProps {
  orderNumber?: number;
  isRush?: boolean;
  isExpressProduction?: boolean;
  shipDate?: string;
}

type Props = OwnProps;

const OrderHeaderCard = React.memo<Props>(({
  orderNumber,
  isRush,
  isExpressProduction,
  shipDate,
}) => {
  let rush: string | JSX.Element = '';
  if (isRush) {
    rush = (
      <MaterialTooltip
        tooltipText={parseDateNumeric(shipDate)}
        placement={'top'}
      >
        <Icon
          iconUrl={rushIcon}
          width={22}
          height={15}
          marginTop={2}
        />
      </MaterialTooltip>
    );
  }

  let expressProduction: string | JSX.Element = '';
  if (isExpressProduction) {
    expressProduction = (
      <Icon
        width={22}
        height={14}
        marginLeft={5}
        marginTop={3}
        iconUrl={expressIcon}
      />
    );
  }

  return (
    <div className='sheet__files-item item-quantity-card' >
      <div className='sheet__files-title'>
        <div className='sheet__files-title-left flex'>
          Order
        </div>
      </div>
      <ul className='sheet__files-list'>
        <li>
          <div className='flex'>
            <Link
              to={orderDetailsUrl(orderNumber!)}
              className='button button--link button--underline'
              target='_blank'
            >
              {orderNumber}
            </Link>
            {rush}
            {expressProduction}
          </div>
        </li>
      </ul>
    </div>
  );
});

export default OrderHeaderCard;
