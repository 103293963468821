import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Textarea extends Component {
  componentDidMount() {
    this.resize();
  }

  componentDidUpdate() {
    this.resize();
  }

  resize = () => {
    const { id = 'textarea' } = this.props;

    const textarea = document.getElementById(id);
    textarea.style.height = 'inherit';
    textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.overflow = 'hidden';
    textarea.style.resize = 'none';
  };

  render() {
    const {
      type,
      id = 'textarea',
      placeholder = '',
      required = false,
      disabled = false,
      className = 'w-100',
      meta: {
        touched = false,
        error = '',
      },
    } = this.props;

    const inputProps = this.props.input || {};
    const hasError = touched && error && (error.length > 0);

    return (
      <div className='text-field w-100'>
        <textarea
          rows='1'
          id={id}
          type={type}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          className={`redux-form__input ${className} ${hasError ? 'error' : ''}`}
          {...inputProps}
        />
        {
          hasError &&
          <div className='redux-form__error--field'>
            {error.map((e, index) => <div key={index}>{e}</div>)}
          </div>
        }
      </div>
    );
  }
}

Textarea.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.array,
  }),
  input: PropTypes.object,
};

export default Textarea;
