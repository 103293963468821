/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  ColoredStyleDetailDtoPagedList,
  GetHomefieldApiColoredstylesParams,
  ColoredStyleDetailDto,
  ApiResponse,
  UpdateColoredStyleCommand,
  ChangeColoredStyleStatusCommand,
  ProductDto,
  ProductSkuInfoDto,
  ProductVendorQuantityDto,
  ProductInventoryDto,
  SyncEventsResultDtoApiResponse
} from './models'
import { catalogApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiColoredstyles = <TData = ColoredStyleDetailDtoPagedList>(
    params?: GetHomefieldApiColoredstylesParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiColoredstylesQueryKey = (params?: GetHomefieldApiColoredstylesParams,) => [`/homefield/api/colored-styles`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiColoredstyles = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiColoredstyles, ColoredStyleDetailDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiColoredstylesParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiColoredstylesQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiColoredstyles<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiColoredstylesId = <TData = ColoredStyleDetailDto>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiColoredstylesIdQueryKey = (id: number,) => [`/homefield/api/colored-styles/${id}`]

    
export const useGetHomefieldApiColoredstylesId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiColoredstylesId, ColoredStyleDetailDto>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiColoredstylesIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiColoredstylesId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiColoredstylesId = <TData = ApiResponse>(
    id: number,
    updateColoredStyleCommand: UpdateColoredStyleCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles/${id}`, method: 'put',
      data: updateColoredStyleCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiColoredstylesId = <TData = AsyncReturnType<typeof putHomefieldApiColoredstylesId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: UpdateColoredStyleCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: UpdateColoredStyleCommand}, TContext>((props) => {
        const {id,data} = props || {};

        return  putHomefieldApiColoredstylesId<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiColoredstylesId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiColoredstylesId = <TData = AsyncReturnType<typeof deleteHomefieldApiColoredstylesId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiColoredstylesId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiColoredstylesstatus = <TData = ApiResponse>(
    changeColoredStyleStatusCommand: ChangeColoredStyleStatusCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles.status`, method: 'put',
      data: changeColoredStyleStatusCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiColoredstylesstatus = <TData = AsyncReturnType<typeof putHomefieldApiColoredstylesstatus,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ChangeColoredStyleStatusCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ChangeColoredStyleStatusCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiColoredstylesstatus<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiColoredstylesgridpreviewId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles.grid-preview/${id}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiColoredstylesgridpreviewId = <TData = AsyncReturnType<typeof putHomefieldApiColoredstylesgridpreviewId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  putHomefieldApiColoredstylesgridpreviewId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiColoredstylesIdProducts = <TData = ProductDto[]>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles/${id}/products`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiColoredstylesIdProductsQueryKey = (id: number,) => [`/homefield/api/colored-styles/${id}/products`]

    
export const useGetHomefieldApiColoredstylesIdProducts = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiColoredstylesIdProducts, ProductDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiColoredstylesIdProductsQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiColoredstylesIdProducts<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiColoredstylesIdProductskuinfo = <TData = ProductSkuInfoDto[]>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles/${id}/product-sku-info`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiColoredstylesIdProductskuinfoQueryKey = (id: number,) => [`/homefield/api/colored-styles/${id}/product-sku-info`]

    
export const useGetHomefieldApiColoredstylesIdProductskuinfo = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiColoredstylesIdProductskuinfo, ProductSkuInfoDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiColoredstylesIdProductskuinfoQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiColoredstylesIdProductskuinfo<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiColoredstylesIdProductvendorquantities = <TData = ProductVendorQuantityDto[]>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles/${id}/product-vendor-quantities`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiColoredstylesIdProductvendorquantitiesQueryKey = (id: number,) => [`/homefield/api/colored-styles/${id}/product-vendor-quantities`]

    
export const useGetHomefieldApiColoredstylesIdProductvendorquantities = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiColoredstylesIdProductvendorquantities, ProductVendorQuantityDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiColoredstylesIdProductvendorquantitiesQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiColoredstylesIdProductvendorquantities<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiColoredstylesIdProductinventory = <TData = ProductInventoryDto[]>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles/${id}/product-inventory`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiColoredstylesIdProductinventoryQueryKey = (id: number,) => [`/homefield/api/colored-styles/${id}/product-inventory`]

    
export const useGetHomefieldApiColoredstylesIdProductinventory = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiColoredstylesIdProductinventory, ProductInventoryDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiColoredstylesIdProductinventoryQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiColoredstylesIdProductinventory<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiColoredstylessyncId = <TData = SyncEventsResultDtoApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles.sync/${id}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiColoredstylessyncId = <TData = AsyncReturnType<typeof putHomefieldApiColoredstylessyncId,SyncEventsResultDtoApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  putHomefieldApiColoredstylessyncId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiColoredstylessyncIdProducts = <TData = SyncEventsResultDtoApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/colored-styles.sync/${id}/products`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiColoredstylessyncIdProducts = <TData = AsyncReturnType<typeof putHomefieldApiColoredstylessyncIdProducts,SyncEventsResultDtoApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  putHomefieldApiColoredstylessyncIdProducts<TData>(id,requestOptions)
      }, mutationOptions)
    }
    