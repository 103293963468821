import React from 'react';
import PropTypes from 'prop-types';
import Button from '@sharedComponents/Buttons/Button';

const LogoAssignmentActionsCell = React.memo(({
  productsToRemoveLogo,
  product,
  logoId,
  selectProductWithLogo,
}) => {
  if (product.logos.filter((l) => l.id === logoId).length > 0) {
    if (productsToRemoveLogo.filter((p) => p.id === product.id).length > 0) {
      return (
        <div className='flex'>
          <span className='logo-bank__assignment--warning'>You removed selected logo from this item</span>
          <Button
            type={'primary'}
            text={'Undo'}
            onClick={selectProductWithLogo.bind(null, product)}
          />
        </div>
      );
    }

    return (
      <div className='flex'>
        <span className='logo-bank__assignment--font'>Item already contains selected logo</span>
        <Button
          type={'primary'}
          text={'Remove'}
          onClick={selectProductWithLogo.bind(null, product)}
        />
      </div>
    );
  }

  return (
    <div />
  );
});

LogoAssignmentActionsCell.propTypes = {
  productsToRemoveLogo: PropTypes.array,
  product: PropTypes.object,
  logoId: PropTypes.number,
  selectProductWithLogo: PropTypes.func,
};

export default LogoAssignmentActionsCell;
