import React, { useCallback } from 'react';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { swalAlert } from '@util/componentHelper';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Button from '@sharedComponents/Buttons/Button';

interface OwnProps {
  searchInput: string;
  updateSearchInput: (lookupOrderNumber: string) => void;
  updateLookupOrderNumber: (lookupOrderNumber: string) => void;
}

type Props = OwnProps;

const OrderSearchInput = React.memo<Props>(({
  searchInput,
  updateSearchInput,
  updateLookupOrderNumber,
}) => {
  const lookupOrder = useCallback(async (newSearchInput: string) => {
    const orderParsed = newSearchInput.toLowerCase().startsWith('o')
      ? newSearchInput.substring(1)
      : newSearchInput;

    updateLookupOrderNumber(orderParsed);
  }, [updateLookupOrderNumber]);

  const search = useCallback(() => {
    if (!searchInput) {
      swalAlert('Please fill in an Order Number first.');
    } else {
      lookupOrder(searchInput);
    }
  }, [
    lookupOrder,
    searchInput,
  ]);

  const onChangeSearchInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchInput = e.target.value;
    updateSearchInput(newSearchInput);
  }, [updateSearchInput]);

  const runSearch = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const newSearchInput: string = (e.target as any).value;
    updateSearchInput(newSearchInput);

    if (!newSearchInput) {
      swalAlert('Please fill in an Order Number first.');
    } else {
      lookupOrder(newSearchInput);
    }
  }, [
    lookupOrder,
    updateSearchInput,
  ]);

  return (
    <div className='container--small'>
      <div className='order-assembly extra-padding mt-20'>
        <div className='header'>
          Enter order ID or scan barcode to begin
        </div>
        <SearchFilter
          search={runSearch}
          onChange={onChangeSearchInput}
          placeholder={'Order ID or Barcode'}
          inputClasses={'w-100'}
        />
        <div className='align__right'>
          <Button
            type={'primary'}
            text={'Submit'}
            onClick={search}
          />
        </div>
      </div>
    </div>
  );
});

export default OrderSearchInput;
