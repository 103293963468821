import { buildQueryString } from '@util/stringHelpers';

export const artwork = 'homefield/api/artwork' as const;
export const lockerManager = 'homefield/api/lockermanager' as const;
export const slLogos = 'logos' as const;

export const tasks = `${artwork}/tasks` as const;
export const tasksAction = (action: string = ''): string => `${artwork}/tasks${action ? `.${action}` : ''}`;

export const task = (taskId: number): string => `${tasks}/${taskId}`;
export const taskAction = (taskId: number, action: string = ''): string => action ? `${tasksAction(action)}/${taskId}` : task(taskId);

export const logoLockers = `${artwork}/lockers` as const;
export const logoLocker = (lockerId: number): string => `${logoLockers}/${lockerId}`;

export const logoLockerTasks = (
  lockerId: number,
  logoId: number,
  sortBy: string,
  decorationMethod: string
): string => `${tasks}${buildQueryString({
  lockerId,
  logoId,
  sortBy,
  decorationMethod,
}, true)}`;

export const logoReplacement = (logoId: number): string => `homefield/api/lockermanager/logos/${logoId}/replaceImage`;

export const orderStatus = logoLockers;

export const taskStatus = tasksAction('status');

export const serviceWorkerFileUpload = (): string => tasksAction('upload-file');

export const slLogo = (logoId: number): string => `${slLogos}/auth/${logoId}`;
export const logo = (logoId: number): string => `${lockerManager}/${slLogos}/${logoId}`;
export const lockerLogo = (lockerId: number, logoId: number): string => `${lockerManager}/lockers/${lockerId}/${slLogos}/${logoId}`;
export const logoColoredStyleColors = (lockerId: number, logoId: number): string => `${lockerLogo(lockerId, logoId)}/colored-style-colors`;
export const singleLogo = (logoId: number): string => `${slLogos}/${logoId}`;

export default {
  artwork,
  logoLockers,
  logoReplacement,
  orderStatus,
  logoLockerTasks,
  serviceWorkerFileUpload,
  slLogo,
  logo,
  logoColoredStyleColors,
  singleLogo,
};
