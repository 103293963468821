/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  PagedListViewModelCollectionTableRowViewModel,
  ApiInternalServerError,
  GetCollectionsParams,
  ApiOkNumber,
  ApiBadRequest,
  CollectionRequestModel,
  ApiOkNumberArray,
  ApiNotFound,
  IdsRequestModel,
  ApiOkReorderCollectionsRequestModel,
  ReorderCollectionsRequestModel,
  CollectionDetailsViewModel,
  ApiOkUpdateCollectionRequestModel,
  UpdateCollectionRequestModel,
  CollectionItemViewModel,
  AddItemsToExistingCollectionRequestModel,
  CollectionItemsRequestModel,
  ApiOk,
  ReorderCollectionItemsRequestModel
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getCollections = <TData = PagedListViewModelCollectionTableRowViewModel>(
    params?: GetCollectionsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetCollectionsQueryKey = (params?: GetCollectionsParams,) => [`/homefield/api/collections`, ...(params ? [params]: [])]

    
export const useGetCollections = <TQueryFnData = AsyncReturnType<typeof getCollections, PagedListViewModelCollectionTableRowViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetCollectionsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCollectionsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getCollections<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createCollection = <TData = ApiOkNumber>(
    collectionRequestModel: CollectionRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections`, method: 'post',
      data: collectionRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateCollection = <TData = AsyncReturnType<typeof createCollection,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CollectionRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CollectionRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  createCollection<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const deleteCollections = <TData = ApiOkNumberArray>(
    idsRequestModel: IdsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections.delete`, method: 'put',
      data: idsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteCollections = <TData = AsyncReturnType<typeof deleteCollections,ApiOkNumberArray>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: IdsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: IdsRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  deleteCollections<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const reorderCollections = <TData = ApiOkReorderCollectionsRequestModel>(
    reorderCollectionsRequestModel: ReorderCollectionsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections.reorder`, method: 'put',
      data: reorderCollectionsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useReorderCollections = <TData = AsyncReturnType<typeof reorderCollections,ApiOkReorderCollectionsRequestModel>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ReorderCollectionsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ReorderCollectionsRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  reorderCollections<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getCollection = <TData = CollectionDetailsViewModel>(
    collectionId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections/${collectionId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetCollectionQueryKey = (collectionId: number,) => [`/homefield/api/collections/${collectionId}`]

    
export const useGetCollection = <TQueryFnData = AsyncReturnType<typeof getCollection, CollectionDetailsViewModel>, TError = ApiBadRequest | ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 collectionId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCollectionQueryKey(collectionId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getCollection<TQueryFnData>(collectionId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const updateCollection = <TData = ApiOkUpdateCollectionRequestModel>(
    collectionId: number,
    updateCollectionRequestModel: UpdateCollectionRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections/${collectionId}`, method: 'put',
      data: updateCollectionRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUpdateCollection = <TData = AsyncReturnType<typeof updateCollection,ApiOkUpdateCollectionRequestModel>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{collectionId: number;data: UpdateCollectionRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {collectionId: number;data: UpdateCollectionRequestModel}, TContext>((props) => {
        const {collectionId,data} = props || {};

        return  updateCollection<TData>(collectionId,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteCollection = <TData = ApiOkNumber>(
    collectionId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections/${collectionId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteCollection = <TData = AsyncReturnType<typeof deleteCollection,ApiOkNumber>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{collectionId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {collectionId: number}, TContext>((props) => {
        const {collectionId} = props || {};

        return  deleteCollection<TData>(collectionId,requestOptions)
      }, mutationOptions)
    }
    export const getAllCollectionItems = <TData = CollectionItemViewModel[]>(
    collectionId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections/${collectionId}/items`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAllCollectionItemsQueryKey = (collectionId: number,) => [`/homefield/api/collections/${collectionId}/items`]

    
export const useGetAllCollectionItems = <TQueryFnData = AsyncReturnType<typeof getAllCollectionItems, CollectionItemViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 collectionId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllCollectionItemsQueryKey(collectionId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAllCollectionItems<TQueryFnData>(collectionId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const addItemsToCollection = <TData = ApiOkNumber>(
    collectionId: number,
    addItemsToExistingCollectionRequestModel: AddItemsToExistingCollectionRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections/${collectionId}/items`, method: 'post',
      data: addItemsToExistingCollectionRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useAddItemsToCollection = <TData = AsyncReturnType<typeof addItemsToCollection,ApiOkNumber>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{collectionId: number;data: AddItemsToExistingCollectionRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {collectionId: number;data: AddItemsToExistingCollectionRequestModel}, TContext>((props) => {
        const {collectionId,data} = props || {};

        return  addItemsToCollection<TData>(collectionId,data,requestOptions)
      }, mutationOptions)
    }
    export const removeItemsFromCollection = <TData = ApiOkNumber>(
    collectionId: number,
    collectionItemsRequestModel: CollectionItemsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections/${collectionId}/items.remove`, method: 'put',
      data: collectionItemsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRemoveItemsFromCollection = <TData = AsyncReturnType<typeof removeItemsFromCollection,ApiOkNumber>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{collectionId: number;data: CollectionItemsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {collectionId: number;data: CollectionItemsRequestModel}, TContext>((props) => {
        const {collectionId,data} = props || {};

        return  removeItemsFromCollection<TData>(collectionId,data,requestOptions)
      }, mutationOptions)
    }
    export const reorderCollectionItems = <TData = ApiOk>(
    collectionId: number,
    reorderCollectionItemsRequestModel: ReorderCollectionItemsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/collections/${collectionId}/items.reorder`, method: 'put',
      data: reorderCollectionItemsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useReorderCollectionItems = <TData = AsyncReturnType<typeof reorderCollectionItems,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{collectionId: number;data: ReorderCollectionItemsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {collectionId: number;data: ReorderCollectionItemsRequestModel}, TContext>((props) => {
        const {collectionId,data} = props || {};

        return  reorderCollectionItems<TData>(collectionId,data,requestOptions)
      }, mutationOptions)
    }
    