import weedingAndMaskingUrls from '@constants/sqdApiUrls/weedingAndMaskingUrls';
import { weedingAndMaskingEnum } from '@constants/enums/artworkEnums';
import { makeApiCallWithErrorModal } from '@util/apiHelper';
import { fulfillmentApi } from '../../sqdApis';
import { printStatusEnum } from '@constants/enums/printRoomEnums';

export const markCompleted = async (id) => {
  const body = JSON.stringify({
    id,
    status: weedingAndMaskingEnum.Done,
  });

  const call = fulfillmentApi.put(weedingAndMaskingUrls.weedingAndMaskingChangeStatus, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markReadyForQC = async (id, callback) => {
  const body = JSON.stringify({
    id,
    status: 'ReadyForQC',
  });

  const call = fulfillmentApi.put(weedingAndMaskingUrls.weedingAndMaskingChangeStatus, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);
  if (res?.success) {
    callback();
  }
};

export const flagQueueItem = async (
  reason,
  note,
  flagType,
  id,
  file
) => {
  const formData = new FormData();
  formData.append('reason', reason);
  formData.append('note', note);
  formData.append('flagType', flagType);
  formData.append('printAssemblyQueueId', id);

  if (file) {
    for (const f of file) {
      formData.append('file[]', f, f.name);
    }
  }

  const call = fulfillmentApi.put(weedingAndMaskingUrls.flagQueueItem, {
    body: formData,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unflagQueueItem = async (weedMaskQueueId, reason, note, flaggingType, reprintQuantity) => {
  const body = JSON.stringify({
    reason,
    note,
    flaggingType,
    reprintQuantity,
  });

  const call = fulfillmentApi.put(weedingAndMaskingUrls.unflagQueueItem(weedMaskQueueId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const finalize = async (orderNumber, sheetId, inventory) => {
  const body = JSON.stringify({
    orderNumber,
    sheetId,
    inventoryName: inventory,
  });

  const call = fulfillmentApi.put(weedingAndMaskingUrls.finalize, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markDoneByBarcode = async (barcode) => {
  const body = JSON.stringify({
    barcode,
    status: printStatusEnum.Done,
  });

  const call = fulfillmentApi.put(weedingAndMaskingUrls.weedingAndMaskingChangeStatusByBarcode, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
