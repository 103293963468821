import React from 'react';
import {
  Task,
  PersonalizationTask,
} from '@models/Production/ProductionAssembly/Tasks';
import {
  formatDecorationLocationSize,
  formatDecorationLocationHeight,
} from '@util/numberHelpers';

interface OwnProps {
  task: Task;
}

type Props = OwnProps;

const DecorationLocationAndSizeCell = React.memo<Props>(({ task }) => (
  <div>
    <div>{task.decorationLocation}</div>
    {
      task.decorationWidth && task.decorationHeight &&
      <div>
        ({
          formatDecorationLocationSize(
            task.decorationWidth,
            task.decorationHeight
          )
        })
      </div>
    }
    {
      <div>
        {
          !task.decorationHeight
            && (task as PersonalizationTask).nameHeight && (task as PersonalizationTask).text &&
            <p>Name ({formatDecorationLocationHeight((task as PersonalizationTask).nameHeight!)})</p>
        }
        {
          !task.decorationHeight
            && (task as PersonalizationTask).numberHeight && (task as PersonalizationTask).number &&
            <p>Num. ({formatDecorationLocationHeight((task as PersonalizationTask).numberHeight!)})</p>
        }
        {
          task.decorationHeight && (task as PersonalizationTask).text &&
            <p>Name ({formatDecorationLocationHeight(task.decorationHeight)})</p>
        }
        {
          task.decorationHeight && (task as PersonalizationTask).number &&
            <p>Num. ({formatDecorationLocationHeight(task.decorationHeight)})</p>
        }
      </div>
    }
  </div>
));

export default DecorationLocationAndSizeCell;
