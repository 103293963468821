import React from 'react';
import { formatDollarAmount } from '@util/numberHelpers';
import { OrderItemDetailsDto } from '@api/fulfillment/models';
import { OrderItemPricingDetails } from '@api/orders/models';

interface OwnProps {
  item: OrderItemDetailsDto;
  coreItemPricing?: OrderItemPricingDetails | undefined;
}

type Props = OwnProps;

const OrderManagementOrderRefundItem = React.memo<Props>(({
  item,
  coreItemPricing,
}) => {
  const previewBackground = {
    backgroundImage: `url("${item?.imageUrl}")`,
  };

  return (
    <div className='refund__item-container'>
      <div className='refund__item'>
        <div className='refund__item-column'>
          <i
            className='refund__item-thumbnail'
            style={previewBackground}
          />
        </div>
        <div className='refund__item-column'>
          <div className='refund__item-sku'>{item?.sku}</div>
          <div className='refund__item-title'>{item?.styleName ?? ''}</div>
          <ul className='refund__item-tags'>
            <li>
              <span className='refund__item-tag'>x{item?.quantity}</span>
            </li>
            <li>
              <span className='refund__item-tag'>Size: {item?.size ?? '-'}</span>
            </li>
            <li>
              <span className='refund__item-tag'>Color: {item?.colorGroup ?? '-'}</span>
            </li>
          </ul>
        </div>
        <div className='refund__item-column'>
          <span className='refund__item-info'>{
            formatDollarAmount(
              coreItemPricing
                ? coreItemPricing.pricePaid!
                : item?.pricePaid
            )}
          </span>
        </div>
      </div>
    </div>
  );
});

export default OrderManagementOrderRefundItem;
