import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { QueryObserverResult } from 'react-query';
import LazyLoad from 'react-lazyload';
import { useGetHomefieldApiProductsSku } from '@api/productCatalog/products';
import { ColorDto } from '@api/productCatalog/models';
import {
  ProductionAssemblyItemGroupDto,
  ProductionAssemblyOrderHeaderDto,
} from '@api/fulfillment/models';
import { StatusHistory } from '@models/Production/ProductionAssembly/StatusHistory';
import {
  LogoTask,
  PersonalizationTask,
} from '@models/Production/OrderAssembly/Tasks';
import ReviewItemHistory from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemHistory';
import ReviewItemHeader from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemHeader';
import ReviewItemTasks from './ReviewItemContent/ReviewItemTasks';
import ReviewItemActions from './ReviewItemContent/ReviewItemActions';
import ReviewImageCarousel from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewImageCarousel';
import ReviewItemImage from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemImage';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  orderNumber: number;
  order?: any;
  checksDisabled?: boolean;
  openItemInfoModal: (item: ProductionAssemblyItemGroupDto) => void;
  openAddBarcodesModal: (
    item: ProductionAssemblyItemGroupDto,
    checkInButtonIsClicked?: boolean
  ) => void;
  openEditBarcodesModal: (
    item: ProductionAssemblyItemGroupDto
  ) => void;
  isStatusHistoryShown: boolean;
  getItemHistory: (itemIds: number[], lineItemsIds: number[]) => StatusHistory;
  logoDecorationLocations: any[];
  personalizationDecorationLocations: any[];
  decorationLocationPersonalizationSizesList: any[];
  decorationLocationArtworkSizesList: any[];
  colorsDictionary: Record<string, ColorDto>;
  colors: ColorDto[] | undefined;
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
  barcodeParam: Nullable<string>;
  itemSelected: boolean;
  barcodeSearchItemRef: React.RefObject<HTMLDivElement>;
  scrollToItem: () => void;
}

type Props = OwnProps;

const ReviewItem = React.memo(({
  item,
  barcodeParam,
  itemSelected,
  scrollToItem,
  checksDisabled,
  orderNumber,
  isStatusHistoryShown,
  getItemHistory,
  colorsDictionary,
  colors,
  refreshOrderItemGroups,
  refreshOrder,
  barcodeSearchItemRef,
  openAddBarcodesModal,
  openEditBarcodesModal,
  openItemInfoModal,
  personalizationDecorationLocations,
  decorationLocationPersonalizationSizesList,
  logoDecorationLocations,
  decorationLocationArtworkSizesList,
}: Props) => {
  const [
    itemImage,
    setItemImage,
  ] = useState('');

  const {
    data: product,
    refetch: fetchProduct,
  } = useGetHomefieldApiProductsSku(item.sku!);

  useEffect(() => {
    if (!item) return;
    fetchProduct();
  }, [
    item,
    fetchProduct,
  ]);

  const findImage = useCallback((
    selectedItem: ProductionAssemblyItemGroupDto
  ) => {
    let images: (string | undefined)[] = [];
    images.push.apply(images, [
      selectedItem.imageUrlFront!,
      selectedItem.imageUrlBack!,
      selectedItem.imageUrlLeft!,
      selectedItem.imageUrlRight!,
    ]);

    images = images.filter(Boolean);
    if (images.length > 0) {
      setItemImage(images[0]!);
    } else {
      setItemImage(product?.coloredStyle?.imageUrlFront || '');
    }
  }, [product]);

  useEffect(() => {
    if (item) {
      findImage(item);
      fetchProduct();
    }
  }, [
    findImage,
    item,
    fetchProduct,
  ]);

  useEffect(() => {
    if (barcodeParam && itemSelected) {
      scrollToItem();
    }
  }, [
    barcodeParam,
    itemSelected,
    scrollToItem,
  ]);

  const handleOpenItemInfoModal = useCallback(() => {
    openItemInfoModal({
      ...item,
      imageUrlFront: itemImage || item.imageUrlFront,
    });
  }, [
    openItemInfoModal,
    item,
    itemImage,
  ]);

  const getItemBarcodes = () => {
    const itemBarcodes: {
      orderItemId: number;
      barcode?: string;
    }[] = [];

    for (const orderItemId of Object.keys(item.orderItemIdsWithBarcode!)) {
      const barcode: string | undefined = item.orderItemIdsWithBarcode![orderItemId];
      itemBarcodes.push({
        orderItemId: Number(orderItemId),
        barcode,
      });
    }

    return itemBarcodes;
  };

  const handleOpenAddBarcodesModal = useCallback(() => {
    openAddBarcodesModal(item);
  }, [
    openAddBarcodesModal,
    item,
  ]);

  const handleOpenEditBarcodesModal = useCallback(() => {
    openEditBarcodesModal(item);
  }, [
    openEditBarcodesModal,
    item,
  ]);

  const logoTasks = useMemo((): LogoTask[] => {
    const logos: LogoTask[] = [];

    for (const logo of item.logos!) {
      const logoDecorationLocation = logoDecorationLocations.find((l) => l.code === logo.decorationLocation);
      const logoDecorationSize = logoDecorationLocation && decorationLocationArtworkSizesList
        .find((s) => s.decorationLocationId === logoDecorationLocation.id
          && s.decorationMethod === item.decorationMethod);

      const updatedLogo: LogoTask = {
        ...logo,
        ids: [],
        barcodes: [],
        decorationWidth: logo.decorationWidth || logoDecorationSize?.width,
        decorationHeight: logo.decorationHeight || logoDecorationSize?.height,
      };

      logos.push(updatedLogo);
    }

    return logos;
  }, [
    logoDecorationLocations,
    decorationLocationArtworkSizesList,
    item,
  ]);

  const personalizationTasks = useMemo((): PersonalizationTask[] => {
    const personalizations: PersonalizationTask[] = [];

    for (const personalization of item.personalizations!) {
      const updatedPersonalization: PersonalizationTask = {
        ...personalization,
        ids: [],
        barcodes: [],
      };

      if (personalizationDecorationLocations.length > 0) {
        const personalizationDecorationLocation = personalizationDecorationLocations
          .find((l) => l.code === personalization.decorationLocation);

        const personalizationDecorationSize = decorationLocationPersonalizationSizesList
          .find((s) => s.decorationLocationId === personalizationDecorationLocation?.id
            && s.decorationMethod === item.decorationMethod);

        updatedPersonalization.nameHeight = personalizationDecorationSize?.nameHeight;
        updatedPersonalization.numberHeight = personalizationDecorationSize?.numberHeight;

        personalizations.push(updatedPersonalization);
      }
    }

    return personalizations;
  }, [
    personalizationDecorationLocations,
    decorationLocationPersonalizationSizesList,
    item,
  ]);

  const decorationIds: number[] = [
    ...item.logos!.map((logo) => logo.id!),
    ...item.personalizations!.map((personalization) => personalization.id!),
  ];

  const statusHistory: StatusHistory = getItemHistory(item.orderItemIds!, decorationIds);

  const itemStatus = item.status?.status;

  const itemBarcodes = getItemBarcodes();

  const highlighted: boolean = !!barcodeParam && itemSelected;

  return (
    <div
      ref={(barcodeParam && itemSelected) ? barcodeSearchItemRef : undefined}
      className={`order-assembly order-assembly__item ${highlighted ? 'order-assembly__item--highlighted' : ''}`}
    >
      <LazyLoad
        height={400}
        once={true}
      >
        <div className='order-assembly__item--info container w-100'>
          {
            item.imageUrlFront === null
              ? (
                <ReviewItemImage
                  item={item}
                  itemImage={itemImage}
                  openItemInfoModal={handleOpenItemInfoModal}
                  showDecorationMethodLabel={true}
                />
              )
              : (
                <ReviewImageCarousel
                  item={item}
                  itemImage={itemImage}
                  handleOpenItemInfoModal={handleOpenItemInfoModal}
                  setItemImage={setItemImage}
                />
              )
          }
          <div className='order-assembly__item--info--details'>
            <div className='align__center justify__space-between w-100'>
              <ReviewItemHeader
                item={item}
                product={product}
                itemBarcodes={itemBarcodes}
                openAddBarcodesModal={handleOpenAddBarcodesModal}
                openEditBarcodesModal={handleOpenEditBarcodesModal}
                showArrivalStatus={true}
              />
              <div>
                <ReviewItemActions
                  item={item}
                  orderNumber={orderNumber}
                  checksDisabled={checksDisabled}
                  refreshOrderItemGroups={refreshOrderItemGroups}
                  refreshOrder={refreshOrder}
                />
              </div>
            </div>
            <ReviewItemTasks
              colorsDictionary={colorsDictionary}
              colors={colors}
              item={item}
              logoTasks={logoTasks}
              personalizationTasks={personalizationTasks}
              checksDisabled={checksDisabled}
              orderNumber={orderNumber}
              itemStatus={itemStatus as keyof typeof orderItemStatusEnum}
              itemBarcodes={itemBarcodes}
              refreshOrderItemGroups={refreshOrderItemGroups}
              refreshOrder={refreshOrder}
            />
            {
              isStatusHistoryShown &&
              <ReviewItemHistory
                item={item}
                statusHistory={statusHistory}
              />
            }
          </div>
        </div>
      </LazyLoad>
    </div>
  );
});

export default ReviewItem;
