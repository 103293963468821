import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
  reduxForm,
} from 'redux-form';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import { connect } from 'react-redux';
import { dtgStationsForm } from '@constants/reduxForms';
import Input from '@sharedComponents/Form/Input';
import FormError from '@sharedComponents/Form/FormError';
import { validateDtgStations } from '@redux/support/validations';

const DtgStationsForm = React.memo(({
  error,
  handleSubmit,
}) => (
  <Form
    className='redux-form'
    onSubmit={handleSubmit}
    id={dtgStationsForm}
  >
    <div>
      <GridContainer className='redux-form__section'>
        <Row className='redux-form__row'>
          <Col md={6}>
            <label className='redux-form__label'>
                Station Identifier
            </label>
            <Field
              name={'stationIdentifier'}
              component={Input}
              type={'string'}
            />
          </Col>
          <Col md={6}>
            <label className='redux-form__label'>
                Station Display Name
            </label>
            <Field
              name={'stationDisplayName'}
              component={Input}
              type={'string'}
            />
          </Col>
        </Row>
      </GridContainer>
      <FormError error={error} />
    </div>
  </Form>
));

DtgStationsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
};

export default connect()(reduxForm({
  form: dtgStationsForm,
  validate: validateDtgStations,
  enableReinitialize: true,
  shouldError: () => true,
})(DtgStationsForm));
