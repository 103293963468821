import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import {
  VoucherInstanceDto,
  VoucherOrderDetailDto,
  VoucherOrderPaymentStatusEnum,
} from '@api/financialServices/models';
import { useSimpleConfirmationModal } from '@hooks/shared/Modal/useSimpleConfirmationModal';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { cancelVoucherOrderWithRefundForm } from '@constants/reduxForms';
import HookFormRadioGroup from '@components/shared/Form/HookFormRadioGroup';
import { formatDollarAmount } from '@util/numberHelpers';

export interface VoucherOrderCancelWithRefundForm {
  amount: number;
}

interface OwnProps {
  isOpen: boolean;
  cancelAll: boolean;
  voucherOrder: VoucherOrderDetailDto | null;
  vouchersToCancel: VoucherInstanceDto[] | null;
  closeModal: () => void;
  onSubmit: (form: VoucherOrderCancelWithRefundForm) => Promise<void>;
}

const schema = yup.object({
  amount: yup.number().required('Refund Amount is required'),
});

type Props = OwnProps;

const VoucherOrderCancelWithRefundModal = React.memo<Props>(({
  isOpen,
  cancelAll,
  voucherOrder,
  vouchersToCancel,
  closeModal,
  onSubmit,
}) => {
  const {
    openModal: openSendVoucherOrderRefundReceiptModal,
    closeModal: closeSendVoucherOrderRefundReceiptModal,
    isOpen: sendVoucherOrderRefundReceiptModalIsOpen,
    ConfirmationModal: VoucherOrderRefundConfirmationModal,
  } = useSimpleConfirmationModal();

  const vouchersAmountToRefund = vouchersToCancel
    ?.reduce((total, current) => total + current.amountLeftForRefund!, 0) ?? 0;
  const orderTotalAmountToRefund = voucherOrder?.totalAmountLeftForRefund ?? 0;

  let totalAmountToRefund: number;
  let vouchersToCancelCount: number;
  if (cancelAll) {
    totalAmountToRefund = orderTotalAmountToRefund;
    vouchersToCancelCount = voucherOrder
      ?.voucherCollections
      ?.reduce((x, y) => x + y.totalInstanceCount!, 0)
      ?? 0;
  } else {
    totalAmountToRefund = Math.min(vouchersAmountToRefund!, orderTotalAmountToRefund);
    vouchersToCancelCount = vouchersToCancel?.length ?? 0;
  }

  const formMethods: UseFormReturn<VoucherOrderCancelWithRefundForm> = useForm<VoucherOrderCancelWithRefundForm>({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => (
      {
        amount: totalAmountToRefund,
      }
    ), [totalAmountToRefund]),
  });

  // Set the initial values for the edit form
  useEffect(() => {
    formMethods.reset({
      amount: totalAmountToRefund,
    });
  }, [
    totalAmountToRefund,
    formMethods,
  ]);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
  } = formMethods;

  const amount = watch('amount');

  const onConfirm = useCallback(() => {
    closeSendVoucherOrderRefundReceiptModal();
    closeModal();
    handleSubmit(onSubmit)();
  }, [
    closeSendVoucherOrderRefundReceiptModal,
    closeModal,
    handleSubmit,
    onSubmit,
  ]);

  const getModalBody = (): JSX.Element | null => {
    if (voucherOrder!.paymentStatus === VoucherOrderPaymentStatusEnum.Pending
      || voucherOrder!.paymentStatus === VoucherOrderPaymentStatusEnum.Undefined) {
      return (
        <span>
          Are you sure you want to cancel&nbsp;
          {
            vouchersToCancelCount
          }
          &nbsp;voucher(s) from order <b>#V{voucherOrder?.id}</b>?
          This action cannot be undone.
        </span>
      );
    } else if (voucherOrder!.paymentStatus === VoucherOrderPaymentStatusEnum.Paid) {
      return (
        <div className='p-10'>
          <div>
            <span>
            Are you sure you want to cancel&nbsp;
              {
                vouchersToCancelCount
              }
            &nbsp;voucher(s) from order <b>#V{voucherOrder?.id}</b>?
            This action cannot be undone.
            </span>
          </div>
          <div>
            <HookFormRadioGroup<VoucherOrderCancelWithRefundForm, VoucherOrderCancelWithRefundForm['amount'] | ''>
              name={'amount'}
              register={register}
              change={setValue}
              getValue={getValues}
              groupClassName={'radio__group radio__gray-02 m__w--540 mt-10'}
              radioItems={[
                {
                  value: 0,
                  label: 'No Refund',
                },
                {
                  value: totalAmountToRefund,
                  label: `Full Refund (${formatDollarAmount(totalAmountToRefund)})`,
                },
              ]}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  if (!voucherOrder) {
    return null;
  }

  return (
    <>
      <VoucherOrderRefundConfirmationModal
        isOpen={sendVoucherOrderRefundReceiptModalIsOpen}
        closeModal={closeSendVoucherOrderRefundReceiptModal}
        title={'Confirmation'}
        cancelBtnText={'Cancel'}
        confirm={onConfirm}
        confirmationBody={<span>A refund confirmation email will be sent to the admin.</span>}
      />
      <Modal
        title={'Cancel Voucher Order'}
        isOpen={isOpen}
        closeModal={closeModal}
        modalHeight={'l'}
        modalWidth={'s'}
        buttons={(
          <ModalButtons
            confirmBtnText={'Confirm'}
            cancelBtnText={'Cancel'}
            onClose={closeModal}
            formSubmission={true}
            formId={cancelVoucherOrderWithRefundForm}
          />
        )}
      >
        <form
          id={cancelVoucherOrderWithRefundForm}
          onSubmit={amount > 0 ? handleSubmit(openSendVoucherOrderRefundReceiptModal) : handleSubmit(onSubmit)}
        >
          {getModalBody()}
        </form>
      </Modal>
    </>
  );
});

export default VoucherOrderCancelWithRefundModal;
