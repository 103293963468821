import React from 'react';
import { advancedOptionsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const AdvancedOptionsLink = React.memo(() => (
  <MenuTile
    title={'Advanced Options'}
    url={advancedOptionsUrl}
    icon={'settings_applications'}
  />
));

export default AdvancedOptionsLink;
