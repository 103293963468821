import React, {
  useCallback,
  useState,
} from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import { formatDollarAmount } from '@util/numberHelpers';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ExpandablePriceTab from '@sharedComponents/Display/ExpandablePriceTab';
import {
  OrderItemModel,
  UpdateOrderItemPriceCalculationDto,
} from '@api/fulfillment/models';
import {
  Item,
  OrderDetailsDto,
  OrderPricingDetails,
  OrderPricingDetailsDto,
} from '@api/orders/models';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  applyCouponPriceCalculation: UpdateOrderItemPriceCalculationDto | OrderPricingDetailsDto;
  applyCoupon: () => void;
  orderExistsOnCore: boolean;
  coreOrder: OrderDetailsDto;
  couponType: string;
}

interface ItemPricingWithId {
  id: string;
  sku: string;
  pricePaid: number;
  basePrice: number;
  styleDiscountAmount: number;
  lockerDiscountAmount: number;
  subtotalCouponDiscount: number;
  personalizationBasePrice: number;
  personalizationCouponDiscount: number;
  personalizationPrice: number;
  fundraisingBaseAmount: number;
  fundraisingCouponDiscount: number;
  fundraisingAmount: number;
  taxAmount: number;
  quantity: number;
}

type Props = OwnProps;

const OrderManagementApplyCouponConfirmationModal = React.memo(({
  isOpen,
  closeModal,
  applyCouponPriceCalculation,
  applyCoupon,
  orderExistsOnCore,
  coreOrder,
  couponType,
}: Props) => {
  const [
    applyButtonDisabled,
    setApplyButtonDisabled,
  ] = useState(false);

  const onConfirm = useCallback(async () => {
    setApplyButtonDisabled(true);
    applyCoupon();
    setApplyButtonDisabled(false);
  }, [applyCoupon]);

  if (!applyCouponPriceCalculation) return null;

  const orderItems = orderExistsOnCore && coreOrder
    ? coreOrder.items!
    : (applyCouponPriceCalculation as UpdateOrderItemPriceCalculationDto)!.orderItems!;

  const updatedOrderItems = orderExistsOnCore
    ? Object.entries((applyCouponPriceCalculation as OrderPricingDetailsDto)!.orderItemsPricingDetails!).map(
      ([
        id,
        pricing,
      ]) => ({
        id,
        pricePaid: pricing.pricePaid,
        basePrice: pricing.basePrice,
        styleDiscountAmount: pricing.styleDiscountAmount,
        lockerDiscountAmount: pricing.salesChannelDiscountAmount,
        subtotalCouponDiscount: pricing.subtotalCouponDiscount,
        personalizationBasePrice: pricing.personalizationsBasePrice,
        personalizationCouponDiscount: pricing.personalizationsCouponDiscount,
        personalizationPrice: pricing.personalizationsPrice,
        fundraisingBaseAmount: pricing.fundraisingBaseAmount,
        fundraisingCouponDiscount: pricing.fundraisingCouponDiscount,
        fundraisingAmount: pricing.fundraisingAmount,
        taxAmount: pricing.taxAmount,
        quantity: (orderItems as Item[]).find(
          (oi) => oi.id === parseInt((id))
        )?.quantity,
        sku: (orderItems as Item[]).find(
          (oi) => oi.id === parseInt(id)
        )?.sku,
      } as ItemPricingWithId)
    )
    : (applyCouponPriceCalculation as UpdateOrderItemPriceCalculationDto)?.updatedOrderItems;

  const pretaxAmountToChange = orderExistsOnCore && coreOrder
    ? (applyCouponPriceCalculation as OrderPricingDetails)!.pretaxPrice! - coreOrder.pricing!.pretaxPrice!
    : (applyCouponPriceCalculation as UpdateOrderItemPriceCalculationDto)?.pretaxAmountToChange;

  const taxAmountToChange = orderExistsOnCore && coreOrder
    ? (applyCouponPriceCalculation as OrderPricingDetails)!.taxAmount! - coreOrder.pricing!.taxAmount!
    : (applyCouponPriceCalculation as UpdateOrderItemPriceCalculationDto)?.taxAmountToChange;

  const canUpdate = orderExistsOnCore && coreOrder
    ? true
    : (applyCouponPriceCalculation as UpdateOrderItemPriceCalculationDto).canUpdate;

  const paymentType = accountingActionTypeEnum.Refund;

  const totalQuantity: number = orderExistsOnCore && coreOrder
    ? (orderItems as Item[])?.reduce(
      (total, current) => total + current.quantity!, 0
    ) ?? 0
    : (orderItems as OrderItemModel[])?.reduce(
      (total, current) => total + current.quantity!, 0
    ) ?? 0;

  const renderOrderItemPriceTab = (updatedOrderItem: OrderItemModel | ItemPricingWithId, index: number) => {
    let orderItem: Item | OrderItemModel;

    if (orderExistsOnCore) {
      orderItem = (orderItems as Item[]).find((
        item
      ) => parseInt((updatedOrderItem as ItemPricingWithId)?.id) === item.id)!;
    } else {
      orderItem = (orderItems as OrderItemModel[]).find((
        item
      ) => (updatedOrderItem as OrderItemModel)?.ids?.every((id: number) => item?.ids?.includes(id)))!;
    }

    return (
      <ExpandablePriceTab
        label={updatedOrderItem!.sku!}
        subLabel={`(${updatedOrderItem.quantity})`}
        price={updatedOrderItem.pricePaid}
        classes={index > 0 ? 'mt-20' : ''}
        key={index}
        content={(
          <div className='order-financial-details__content'>
            <div className='justify__space-between'>
              <span>Base price</span>
              <div>
                <span className='current-amount'>{formatDollarAmount(updatedOrderItem.basePrice)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.basePrice
                  : (orderItem as OrderItemModel)?.basePrice)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Style discount</span>
              <div>
                <span className='negative-amount mr-5'>- {formatDollarAmount(updatedOrderItem.styleDiscountAmount)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.styleDiscountAmount
                  : (orderItem as OrderItemModel)?.styleDiscountAmount)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Locker discount</span>
              <div>
                <span className='negative-amount mr-5'>- {formatDollarAmount(updatedOrderItem.lockerDiscountAmount)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.salesChannelDiscountAmount
                  : (orderItem as OrderItemModel)?.lockerDiscountAmount)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Coupon discount</span>
              <div>
                <span className='negative-amount mr-5'>- {formatDollarAmount(updatedOrderItem.subtotalCouponDiscount)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.subtotalCouponDiscount
                  : (orderItem as OrderItemModel)?.subtotalCouponDiscount)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Personalization base price</span>
              <div>
                <span className='current-amount'>{formatDollarAmount(updatedOrderItem.personalizationBasePrice)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.personalizationsBasePrice
                  : (orderItem as OrderItemModel)?.personalizationBasePrice)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Personalization coupon discount</span>
              <div>
                <span className='negative-amount mr-5'>- {formatDollarAmount(updatedOrderItem.personalizationCouponDiscount)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.personalizationsCouponDiscount
                  : (orderItem as OrderItemModel)?.personalizationCouponDiscount)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Personalization price</span>
              <div>
                <span className='current-amount'>{formatDollarAmount(updatedOrderItem.personalizationPrice)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.personalizationsPrice
                  : (orderItem as OrderItemModel)?.personalizationPrice)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Fundraising base amount</span>
              <div>
                <span className='current-amount'>{formatDollarAmount(updatedOrderItem.fundraisingBaseAmount)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.fundraisingAmount
                  : (orderItem as OrderItemModel)?.fundraisingBaseAmount)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Fundraising coupon discount</span>
              <div>
                <span className='negative-amount mr-5'>- {formatDollarAmount(updatedOrderItem.fundraisingCouponDiscount)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.fundraisingCouponDiscount
                  : (orderItem as OrderItemModel)?.fundraisingCouponDiscount)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Fundraising amount</span>
              <div>
                <span className='current-amount'>{formatDollarAmount(updatedOrderItem.fundraisingAmount)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.fundraisingAmount
                  : (orderItem as OrderItemModel)?.fundraisingAmount)})
                </span>
              </div>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Tax</span>
              <div>
                <span className='current-amount'>{formatDollarAmount(updatedOrderItem.taxAmount)}</span>
                <span className='line-through'>({formatDollarAmount(orderExistsOnCore
                  ? (orderItem as Item)?.pricing?.taxAmount
                  : (orderItem as OrderItemModel)?.taxAmount)})
                </span>
              </div>
            </div>
          </div>
        )}
      />
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'l'}
      modalWidth={'m'}
      isPadded={true}
      title={`Apply ${couponType} Coupon - Price Confirmation`}
      buttons={(
        <ModalButtons
          confirmBtnText={'Apply'}
          cancelBtnText={'Cancel'}
          onConfirm={onConfirm}
          onClose={closeModal}
          isDangerousAction={true}
          confirmBtnDisabled={!canUpdate || applyButtonDisabled}
        />
      )}
    >
      <div>
        {
          updatedOrderItems?.map(renderOrderItemPriceTab)
        }

        <div className='order-financial-details__subtotal border-bottom mt-30'>
          <div>
            <div className='order-financial-details__subtotal--explanation'>(quantity: {totalQuantity})</div>
            <span>Coupon {paymentType} amount</span>
          </div>
          <span className='align__flex-end'>{formatDollarAmount(Math.abs(pretaxAmountToChange!))}</span>
        </div>

        <div className='order-financial-details__subtotal border-bottom mt-30'>
          <div>
            <div className='order-financial-details__subtotal--explanation'>(quantity: {totalQuantity})</div>
            <span>Coupon {paymentType} tax amount</span>
          </div>
          <span className='align__flex-end'>{formatDollarAmount(Math.abs(taxAmountToChange!))}</span>
        </div>

        <div className='mt-30'>
          <div className='order-financial-details__total--explanation'>(quantity: {totalQuantity})</div>
          <div className='order-financial-details__total'>
            <span>{paymentType} Total Amount</span>
            <span>
              {formatDollarAmount(Math.abs(pretaxAmountToChange!) + Math.abs(taxAmountToChange!))}
            </span>
          </div>
        </div>

        {
          !canUpdate &&
          <div className='redux-form__error'>Warning: Can't apply coupon.</div>
        }
      </div>
    </Modal>
  );
});

export default OrderManagementApplyCouponConfirmationModal;
