import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';

class ProductionAssemblyArtworkItemsExpander extends PureComponent {
  render() {
    const {
      barcodes,
      areArtworkItemsExpanded,
      expandArtworkItems,
      ids,
    } = this.props;

    if (!barcodes || !ids) {
      return null;
    }

    if (barcodes.length === 1) {
      return barcodes[0];
    }

    if (barcodes.length > 1 || (barcodes.length === 0 && ids && ids.length > 1)) {
      return (
        <span className='order-assembly__barcode'>
          <MaterialTooltip
            tooltipText={barcodes.join(', ')}
            placement={'top'}
          >
            <Icon materialIcon={'info'} />
          </MaterialTooltip>
          {
            areArtworkItemsExpanded
              ? (
                <div
                  className='button flex'
                  onClick={expandArtworkItems}
                >
                  <span>Collapse</span>
                  <Icon materialIcon={'expand_less'} />
                </div>
              )
              : (
                <div
                  className='button flex'
                  onClick={expandArtworkItems}
                >
                  <span>{barcodes.length > 1 ? 'Multiple barcodes' : 'Expand'}</span>
                  <Icon materialIcon={'expand_more'} />
                </div>
              )
          }
        </span>
      );
    }

    return null;
  }
}

ProductionAssemblyArtworkItemsExpander.propTypes = {
  barcodes: PropTypes.arrayOf(PropTypes.string),
  areArtworkItemsExpanded: PropTypes.bool,
  expandArtworkItems: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number),
};

export default ProductionAssemblyArtworkItemsExpander;
