import React from 'react';
import MenuTile from './MenuTile';
import { designConfigurationUrl } from '@constants/clientUrls/clientUrls';

const DesignConfigurationLink = React.memo(() => (
  <MenuTile
    title={'Design Configuration'}
    url={designConfigurationUrl}
    icon={'settings_applications'}
    isMaterialIcon={true}
  />
));

export default DesignConfigurationLink;
