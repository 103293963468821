import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { productTypeEnum } from '@constants/enums/lockerEnums';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import { fetchLockerItems } from '@redux/lockerManager/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import {
  selectItem,
  selectAllItems,
  isSelectedItem,
} from '@util/selectionHelpers';
import Icon from '@sharedComponents/Icons/Icon';
import Table from '@sharedComponents/Table/Table';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import SelectCell from '@sharedComponents/Table/TableCells/SelectCell';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import ItemImage from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemImage';
import ItemDecorations from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemDecorations';
import ItemPersonalizationCell from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemPersonalizationCell';
import ItemLocationAndSizeCell from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemLocationAndSizeCell';

const ItemsTable = Table();

class CustomItemsTable extends Component {
  state = {
    selectAll: false,
  };

  componentDidUpdate(prevProps) {
    const { lockerId } = this.props;

    if (prevProps.lockerId !== lockerId) {
      this.search();
    }
  }

  selectItem = (item, isSelected) => {
    const { selectAll } = this.state;

    const {
      queue,
      selectedItems,
      updateSelectedItems,
    } = this.props;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectItem(queue, selectedItems, selectAll, item, isSelected, 'id');

    this.setState({ selectAll: newSelectAll });
    updateSelectedItems(newSelectedItems);
  };

  selectAllItems = () => {
    const { selectAll } = this.state;

    const {
      queue,
      selectedItems,
      updateSelectedItems,
    } = this.props;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectAllItems(queue, selectedItems, selectAll, 'id');

    this.setState({ selectAll: newSelectAll });
    updateSelectedItems(newSelectedItems);
  };

  getColumns = () => {
    const { selectAll } = this.state;
    const { selectedItems } = this.props;

    const columns = [
      {
        Header: () => (
          <SelectAllCell
            isSelected={selectAll}
            selectAllItems={this.selectAllItems}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <SelectCell
            select={this.selectItem}
            item={cellProps.value}
            isSelected={isSelectedItem(selectedItems, cellProps.original, 'id')}
          />
        ),
      },
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        minWidth: 50,
        Cell: (cellProps) => (
          <ItemImage
            item={cellProps.original}
          />
        ),
      },
      {
        Header: 'Code & Description',
        accessor: '',
        minWidth: 120,
        Cell: (cellProps) => (
          <TitleDescriptionCell
            item={cellProps.value}
            showLabels={true}
          />
        ),
      },
      {
        Header: 'Color',
        accessor: 'color',
        minWidth: 75,
      },
      {
        Header: 'Method',
        accessor: 'decoration_method',
        width: 90,
      },
      {
        Header: 'Location & Size',
        accessor: '',
        minWidth: 70,
        Cell: (cellProps) => <ItemLocationAndSizeCell item={cellProps.value} />,
      },
      {
        Header: 'Personalization',
        accessor: '',
        minWidth: 80,
        Cell: (cellProps) => <ItemPersonalizationCell item={cellProps.value} />,
      },
      {
        Header: 'Orders',
        accessor: 'times_ordered',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value ? cellProps.value : '-',
      },
      {
        Header: 'Last Update',
        accessor: 'updated_at',
        minWidth: 60,
        Cell: (cellProps) => cellProps.value ? parseDateTimeNumeric(cellProps.value) : '-',
      },
      {
        expander: true,
        Header: '',
        width: 65,
        // eslint-disable-next-line
        Expander: ({ isExpanded, ...rest }) => (
          <div>
            {
              isExpanded
                ? (
                  <Icon
                    materialIcon={'arrow_drop_up'}
                    classes={'button'}
                  />
                )
                : (
                  <Icon
                    materialIcon={'arrow_drop_down'}
                    classes={'button'}
                  />
                )
            }
          </div>
        ),
      },
    ];

    return columns;
  };

  search = () => {
    const {
      dispatch,
      pageNumber,
      pageSize,
      lockerId,
    } = this.props;

    const {
      sortByState,
      sortOrderState,
    } = this.state;

    const productType = productTypeEnum.Custom;

    return dispatch(fetchLockerItems(
      pageNumber,
      pageSize,
      lockerId,
      sortByState,
      sortOrderState,
      productType
    ));
  };

  renderTableSubComponent = (cell) => {
    const { allDecorationLocations } = this.props;

    return (
      <ItemDecorations
        item={cell.original}
        allDecorationLocations={allDecorationLocations}
      />
    );
  };

  fetchData = (state, instance) => {
    const {
      dispatch,
      lockerId,
    } = this.props;

    const {
      sortByState,
      sortOrderState,
    } = this.state;

    const productType = productTypeEnum.Custom;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrderState);

    this.setState({
      sortByState: sortColumn,
      sortOrderState: sortDirection,
    }, () => (
      dispatch(fetchLockerItems(
        page + 1,
        pageSize,
        lockerId,
        sortColumn,
        sortDirection,
        productType
      ))
    ));
  };

  render() {
    const {
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    const columns = this.getColumns();

    return (
      <ItemsTable
        data={queue}
        columns={columns}
        showPagination={hasNextPage || hasPreviousPage}
        totalPages={totalPages}
        onFetchData={this.fetchData}
        subComponent={this.renderTableSubComponent}
      />
    );
  }
}

CustomItemsTable.propTypes = {
  lockerId: PropTypes.number.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSelectedItems: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortOrder: PropTypes.string,
  sortByState: PropTypes.string,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  allDecorationLocations: PropTypes.array.isRequired,
};

const mapStateToProps = ({
  lockerManager,
  productCatalog,
}) => ({
  totalPages: lockerManager.lockerItemsQueue.totalPages,
  hasPreviousPage: lockerManager.lockerItemsQueue.hasPreviousPage,
  hasNextPage: lockerManager.lockerItemsQueue.hasNextPage,
  pageNumber: lockerManager.lockerItemsQueue.pageNumber,
  queue: lockerManager.lockerItemsQueue.queue,
  pageSize: lockerManager.lockerItemsQueue.pageSize,
  allDecorationLocations: productCatalog.decorationLocations,
});

export default connect(mapStateToProps)(CustomItemsTable);
