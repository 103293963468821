import * as actionTypes from './types';
import { slServicesApi } from '../../sqdApis';
import couponsUrls from '@constants/sqdApiUrls/couponsUrls';
import {
  discountTypeEnum,
  restrictionTypeValueEnum,
} from '@constants/enums/couponEnums';
import {
  makeApiCallWithErrorModal,
  makeApiCall,
  makeApiCallWithSubmissionError,
} from '@util/apiHelper';
import { parseDateFromLocalTimezone } from '@util/dateHandler';
import { toCents } from '@util/numberHelpers';

export const updateCouponCreators = (data) => ({
  type: actionTypes.UPDATE_COUPON_CREATORS,
  data,
});

export const updateCouponsTable = (data) => ({
  type: actionTypes.UPDATE_COUPONS_TABLE,
  data,
});

export const updateCouponRestrictions = (data) => ({
  type: actionTypes.UPDATE_COUPON_RESTRICTIONS,
  data,
});

export const fetchCoupons = (
  pageNumber = 1,
  pageSize = 10,
  sortBy = '',
  sortOrder = '',
  discountType,
  reason = '',
  createdBy = '',
  valid = true,
  search = '',
  code = ''
) => async (dispatch) => {
  const call = slServicesApi.get(couponsUrls.coupons(
    pageNumber, pageSize, sortBy, sortOrder, discountType, reason, createdBy, valid, search, code
  ), { handleBlockingLoading: false });

  const response = await makeApiCall(call);

  if (response) {
    if (response.success === false) {
      throw new Error(response.message);
    }

    dispatch(updateCouponsTable({
      totalPages: response.totalPages,
      hasPreviousPage: response.hasPreviousPage,
      hasNextPage: response.hasNextPage,
      pageNumber: response.pageNumber,
      pageSize: response.pageSize,
      queue: response.items,
      totalCount: response.totalCount,
    }));
  }
};

export const fetchCouponCreators = () => async (dispatch) => {
  const call = slServicesApi.get(couponsUrls.couponCreators, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateCouponCreators(res));
  }
};

export const fetchCouponRestrictions = (couponId) => async (dispatch) => {
  const call = slServicesApi.get(couponsUrls.couponRestrictions(couponId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateCouponRestrictions(res));
  }
};

export const createCoupon = (coupon) => async () => {
  const restrictionsMapped = [];
  const { restrictions } = coupon;
  if (restrictions && restrictions.length > 0) {
    for (const restriction of restrictions) {
      if (restriction.value) {
        if (restriction.restrictionType === restrictionTypeValueEnum.Amount) {
          restriction.value = toCents(restriction.value);
        }
        restrictionsMapped.push(restriction);
      }
    }
  }

  const body = JSON.stringify({
    code: coupon.code,
    description: coupon.description,
    reason: coupon.reason,
    validFrom: parseDateFromLocalTimezone(coupon.validFrom),
    validTo: parseDateFromLocalTimezone(coupon.validTo),
    discountType: coupon.discountType,
    discountAmount: (parseInt(coupon.discountType) === discountTypeEnum.DollarAmount)
      ? toCents(coupon.discountAmount)
      : parseInt(coupon.discountAmount),
    redemptionLimit: coupon.redemptionLimit ?? undefined,
    styleCode: coupon.styleCode ?? undefined,
    organizationId: (coupon.organizationId && parseInt(coupon.organizationId)) ?? undefined,
    restrictions: restrictionsMapped,
    quantity: parseInt(coupon.quantity),
    applyToShipping: coupon.applyToShipping ? coupon.applyToShipping : false,
  });

  const call = slServicesApi.post(couponsUrls.addCoupon, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const updateCoupon = (coupon) => async () => {
  const restrictionsMapped = [];
  const { restrictions } = coupon;
  if (restrictions && restrictions.length > 0) {
    for (const restriction of restrictions) {
      if (restriction.value) {
        if (restriction.restrictionType === restrictionTypeValueEnum.Amount) {
          restriction.value = toCents(restriction.value);
        }
        restrictionsMapped.push(restriction);
      }
    }
  }

  const body = JSON.stringify({
    code: coupon.code,
    description: coupon.description,
    reason: coupon.reason,
    validFrom: coupon.validFrom,
    validTo: coupon.validTo,
    discountType: coupon.discountType,
    discountAmount: (parseInt(coupon.discountType) === discountTypeEnum.DollarAmount)
      ? toCents(coupon.discountAmount)
      : parseInt(coupon.discountAmount),
    redemptionLimit: coupon.redemptionLimit ?? undefined,
    styleCode: coupon.styleCode ?? undefined,
    organizationId: (coupon.organizationId && parseInt(coupon.organizationId)) ?? undefined,
    restrictions: restrictionsMapped,
    applyToShipping: coupon.applyToShipping ? coupon.applyToShipping : false,
  });

  const call = slServicesApi.put(couponsUrls.coupon(coupon.id), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const deleteCoupon = (couponId) => async () => {
  const call = slServicesApi.delete(couponsUrls.coupon(couponId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
