/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  UseMutationOptions
} from 'react-query'
import type {
  ApiOk,
  ApiBadRequest,
  ApiNotFound,
  ApiInternalServerError
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const clearCacheImagesByLocker = <TData = ApiOk>(
    lockerId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/logos/locker/cache/${lockerId}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useClearCacheImagesByLocker = <TData = AsyncReturnType<typeof clearCacheImagesByLocker,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number}, TContext>((props) => {
        const {lockerId} = props || {};

        return  clearCacheImagesByLocker<TData>(lockerId,requestOptions)
      }, mutationOptions)
    }
    export const clearCacheImagesByOrganization = <TData = ApiOk>(
    orgId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/logos/organization/cache/${orgId}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useClearCacheImagesByOrganization = <TData = AsyncReturnType<typeof clearCacheImagesByOrganization,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orgId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orgId: number}, TContext>((props) => {
        const {orgId} = props || {};

        return  clearCacheImagesByOrganization<TData>(orgId,requestOptions)
      }, mutationOptions)
    }
    