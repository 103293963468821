import React from 'react';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import ProductionAssemblyHagLink from './ProductionLinks/ProductionAssemblyHagLink';
import ProductionAssemblyDtgLink from './ProductionLinks/ProductionAssemblyDtgLink';
import ProductionAssemblyEmbLink from './ProductionLinks/ProductionAssemblyEmbLink';
import OrderAssemblyLink from './ProductionLinks/OrderAssemblyLink';
import ProductionOverviewLink from './ProductionLinks/ProductionOverviewLink';
import QualityCheckLink from './ProductionLinks/QualityCheckLink';
import ProductionAssemblyWallsLink from './ProductionLinks/ProductionAssemblyWallsLink';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ProductionAssembly = React.memo(() => {
  const { hfPtlWallsModule } = useFlags();

  return (
    <div className='my-apps-tray w-100' >
      <PermissionHandler permissions={PermissionsEnum.ProductionOverviewVisit}>
        <ProductionOverviewLink />
      </PermissionHandler>
      <PermissionHandler permissions={PermissionsEnum.OrderAssemblyVisit}>
        <OrderAssemblyLink />
      </PermissionHandler>
      <PermissionHandler permissions={PermissionsEnum.ProductionAssemblyEmbVisit}>
        <ProductionAssemblyEmbLink />
      </PermissionHandler>
      <PermissionHandler permissions={PermissionsEnum.ProductionAssemblyHagVisit}>
        <ProductionAssemblyHagLink />
      </PermissionHandler>
      <PermissionHandler permissions={PermissionsEnum.ProductionAssemblyDtgVisit}>
        <ProductionAssemblyDtgLink />
      </PermissionHandler>
      <PermissionHandler permissions={PermissionsEnum.QualityCheckVisit}>
        <QualityCheckLink />
      </PermissionHandler>
      {
        hfPtlWallsModule &&
        <PermissionHandler permissions={PermissionsEnum.ProductionAssemblyDtgVisit}>
          <ProductionAssemblyWallsLink />
        </PermissionHandler>
      }
    </div>
  );
});

export default ProductionAssembly;
