import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';

class CategoriesActionsColumn extends Component {
  state = {
    actionsExtended: false,
  };

  closeActions = () => {
    this.setState({ actionsExtended: false });
  };

  toggleActionsMenu = () => {
    this.setState((prevState) => ({
      actionsExtended: !prevState.actionsExtended,
    }));
  };

  handleEditClick = (category) => {
    const { onCategoryEdit } = this.props;

    this.closeActions();
    onCategoryEdit(category);
  };

  handleDeleteClick = (sport) => {
    const { onCategoryDelete } = this.props;

    this.closeActions();
    onCategoryDelete(sport);
  };

  getActionsMenu = (category) => {
    const { actionsExtended } = this.state;

    const className = classNames(
      'lockerManager__item-list categories__action-item-list',
      { 'is-active': actionsExtended }
    );

    if (actionsExtended) {
      return (
        <ul className={className}>
          <li>
            <button
              onClick={this.handleEditClick.bind(null, category)}
              type='button'
            >
              Edit
            </button>
          </li>
          <li className='lockerManager__item-list--border' />
          <li>
            <button
              onClick={this.handleDeleteClick.bind(this, category)}
              type='button'
              className='red'
            >
              Delete
            </button>
          </li>
        </ul>
      );
    }

    return null;
  };

  render() {
    const { category } = this.props;

    return (
      <OutsideClickWrapper onClick={this.closeActions}>
        <div className='colors__actions-container'>
          <button
            className='colors__actions-btn'
            onClick={this.toggleActionsMenu}
          >
            <Icon
              materialIcon={'more_vert'}
              classes={'button'}
            />
          </button>
          {this.getActionsMenu(category)}
        </div>
      </OutsideClickWrapper>
    );
  }
}

CategoriesActionsColumn.propTypes = {
  category: PropTypes.object.isRequired,
  onCategoryEdit: PropTypes.func.isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
};

export default CategoriesActionsColumn;
