import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImagePreviewCell from './ImagePreviewCell';
import SelectCell from '@sharedComponents/Table/TableCells/SelectCell';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import OneOrMultipleCell from '@sharedComponents/Table/TableCells/OneOrMultipleCell';
import PrintSheetRejectNoteCell from './PrintSheetRejectNoteCell';
import Table from '@sharedComponents/Table/Table';

const ItemsTable = Table();

class PrintSheetRejectTable extends PureComponent {
  getColumns = () => {
    const {
      toggleSelection,
      isSelected,
      updateFailReason,
      updateFailNote,
      failReasons,
      selectAll,
      toggleSelectAll,
    } = this.props;

    return [
      {
        Header: () => (
          <SelectAllCell
            isSelected={selectAll}
            selectAllItems={toggleSelectAll}
          />
        ),
        width: 60,
        accessor: 'artworkTaskId',
        Cell: (cellProps) => (
          <SelectCell
            select={toggleSelection}
            item={cellProps.value}
            isSelected={isSelected(cellProps.value)}
          />
        ),
      },
      {
        Header: 'Logo',
        accessor: 'previewUrl',
        className: 'no-padding',
        minWidth: 60,
        Cell: (cellProps) => <ImagePreviewCell imageUrl={cellProps.value} />,
      },
      {
        Header: 'Artwork ID',
        accessor: 'artworkTaskId',
      },
      {
        Header: 'Type',
        accessor: 'jobType',
      },
      {
        Header: 'Decoration Location',
        accessor: 'decorationLocation',
      },
      {
        Header: 'Qty',
        accessor: 'quantity',
        minWidth: 50,
      },
      {
        Header: 'Order Number',
        accessor: 'orderNumbers',
        Cell: (cellProps) => (
          <OneOrMultipleCell
            items={cellProps.value}
            multiLabel={'Multiple Orders'}
          />
        ),
      },
      {
        Header: 'Barcode',
        accessor: 'barcodes',
        minWidth: 140,
        Cell: (cellProps) => (
          <OneOrMultipleCell
            items={cellProps.value}
            multiLabel={'Multiple Barcodes'}
          />
        ),
      },
      {
        Header: 'Rework Artwork',
        accessor: 'artworkTaskId',
        minWidth: 200,
        Cell: (cellProps) => (
          <PrintSheetRejectNoteCell
            onFailNoteChange={updateFailNote}
            onFailReasonChange={updateFailReason}
            item={cellProps.original}
            failReasons={failReasons}
            isSelected={isSelected}
            key={cellProps.value}
          />
        ),
      },
    ];
  };

  render() {
    const {
      data,
      onFetchData,
    } = this.props;

    const columns = this.getColumns();

    return (
      <ItemsTable
        columns={columns}
        data={data}
        onFetchData={onFetchData}
        showPagination={false}
        classNames={'reject-table scroll-table-m'}
      />
    );
  }
}

PrintSheetRejectTable.propTypes = {
  data: PropTypes.array.isRequired,
  failReasons: PropTypes.array,
  onFetchData: PropTypes.func.isRequired,
  toggleSelection: PropTypes.func.isRequired,
  toggleSelectAll: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  updateFailNote: PropTypes.func.isRequired,
  updateFailReason: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
};

export default PrintSheetRejectTable;
