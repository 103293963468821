import React from 'react';
import PropTypes from 'prop-types';
import { inventoryStatusEnum } from '@constants/enums/orderEnums';
import { parseDateNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';

const ProductAssemblyReviewItemArrivalStatus = React.memo(({ item }) => {
  if (item.inventoryAvailable) {
    return (
      <span className='order-assembly__inventory--available'>
        <Icon materialIcon={'check'} />
        <span>{inventoryStatusEnum.Available}</span>
      </span>
    );
  } else if (item.inventoryAvailable === false) {
    return (
      <span className='order-assembly__inventory--not-available'>
        <Icon materialIcon={'schedule'} />
        <span>{item.promisedDate ? parseDateNumeric(item.promisedDate) : inventoryStatusEnum.NotAvailable}</span>
      </span>
    );
  }

  return <span className='order__item-status__value-text'>{inventoryStatusEnum.NoData}</span>;
});

ProductAssemblyReviewItemArrivalStatus.propTypes = {
  item: PropTypes.shape({
    canceled: PropTypes.bool,
    inventoryAvailable: PropTypes.bool,
    promisedDate: PropTypes.string,
    status: PropTypes.shape({
      label: PropTypes.string,
    }),
  }).isRequired,
};

export default ProductAssemblyReviewItemArrivalStatus;
