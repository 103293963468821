import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const DecorationLocationDeleteModal = React.memo(({
  isOpen,
  closeModal,
  decorationLocation,
  deleteDecorationLocation,
}) => (
  <Modal
    title={'Delete Decoration Location'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteDecorationLocation.bind(null, decorationLocation.id)}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to remove decoration location <i>{decorationLocation.label}</i> from the list?</h3>
  </Modal>
));

DecorationLocationDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  decorationLocation: PropTypes.object.isRequired,
  deleteDecorationLocation: PropTypes.func.isRequired,
};

export default DecorationLocationDeleteModal;
