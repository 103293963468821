import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import {
  updateStyle,
  updateStyleSports,
  updateStyleLayouts,
  updateStyleVendor,
} from '@APICalls/productCatalog/actions';
import { editStyleForm } from '@constants/reduxForms';
import { skuSetupStatusEnum } from '@constants/enums/productCatalogEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { materialSwal } from '@util/componentHelper';
import {
  roleMapping,
  checkPermissions,
} from '@util/roleCheck';
import { getSampleImage } from '@util/componentHelpers/productCatalogHelper';
import Expander from '@sharedComponents/Buttons/Expander';
import Icon from '@sharedComponents/Icons/Icon';
import EditButton from '@sharedComponents/Display/EditButton';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import EditStyleModal from '../ProductCatalogModals/EditStyleModal/EditStyleModal';
import StyleStatusIcons from './StyleStatusIcons';
import HeaderDetails from './HeaderDetails';

class Header extends PureComponent {
  state = {
    detailsAreExpanded: true,
    editStyleModalIsOpen: false,
  };

  openEditStyleModal = () => {
    this.setState(() => ({ editStyleModalIsOpen: true }));
  };

  editStyle = async (styleForm) => {
    const {
      roles,
      refresh,
      style,
    } = this.props;

    if (style.vendorId !== styleForm.vendorId) {
      await updateStyleVendor(styleForm);
    }
    const styleResponse = await updateStyle(styleForm);

    if (styleResponse && styleResponse.success) {
      if (!checkPermissions(roles, PermissionsEnum.ProductCatalogEdit)) {
        materialSwal('Success', styleResponse.message, 'success');
        this.closeEditStyleModal();
        refresh();

        return;
      }

      const sportsResponse = await updateStyleSports(styleForm);
      if (sportsResponse && sportsResponse.success) {
        const layoutsResponse = await updateStyleLayouts(styleForm);

        if (layoutsResponse && layoutsResponse.success) {
          materialSwal('Success', styleResponse.message, 'success');
          this.closeEditStyleModal();
          refresh();
        }
      }
    }
  };

  closeEditStyleModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(editStyleForm));
    this.setState(() => ({ editStyleModalIsOpen: false }));
  };

  changeExpand = () => {
    this.setState((prevState) => ({
      detailsAreExpanded: !prevState.detailsAreExpanded,
    }));
  };

  render() {
    const {
      style,
      coloredStyles,
      products,
      styleSports,
      styleLayouts,
      decorationLocations,
    } = this.props;

    const {
      detailsAreExpanded,
      editStyleModalIsOpen,
    } = this.state;

    const sampleImage = getSampleImage(coloredStyles);

    return (
      <div className='lockerManagerEdit__header'>
        <EditStyleModal
          modalIsOpen={editStyleModalIsOpen}
          closeModal={this.closeEditStyleModal}
          initialValues={{
            ...style,
            sportsInputList: styleSports,
            layoutsInputList: styleLayouts,
          }}
          onSubmit={this.editStyle}
          sampleImage={sampleImage}
        />
        <div className='lockerManagerEdit__details-header'>
          <div className='lockerManagerEdit__details-header--info'>
            {style.name || '-'}

            {
              coloredStyles.some((coloredStyle) => coloredStyle.status !== skuSetupStatusEnum.Completed) &&
              <div className='product-catalog__status-label'>
                <Icon materialIcon={'build'} />
                <span>In SKU Setup</span>
              </div>
            }

            <StyleStatusIcons
              style={style}
              coloredStyles={coloredStyles}
              products={products}
            />

            <PermissionHandler permissions={PermissionsEnum.ProductCatalogRestrictedEdit}>
              <EditButton
                onClick={this.openEditStyleModal}
              />
            </PermissionHandler>

          </div>
          <Expander
            isExpanded={detailsAreExpanded}
            toggle={this.changeExpand}
          />
        </div>
        {
          detailsAreExpanded &&
          <HeaderDetails
            style={style}
            styleSports={styleSports}
            styleLayouts={styleLayouts}
            sampleImage={sampleImage}
            decorationLocations={decorationLocations}
          />
        }
      </div>
    );
  }
}

Header.propTypes = {
  style: PropTypes.object.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object),
  styleSports: PropTypes.arrayOf(PropTypes.object),
  styleLayouts: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func.isRequired,
  decorationLocations: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.array,
  products: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({ oidc }) => ({
  roles: roleMapping(oidc),
});

export default connect(mapStateToProps)(Header);
