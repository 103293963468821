import React, {
  useCallback,
  useMemo,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { MaterialUiPickersDate } from '@material-ui/pickers';
import { VoucherNotificationSenderNameEnum } from '@api/financialServices/models/voucherNotificationSenderNameEnum';
import { VoucherNotificationLinkDestinationEnum } from '@api/financialServices/models/voucherNotificationLinkDestinationEnum';
import {
  voucherNotificationsLinkDestinations,
  voucherNotificationsSenderNameTypes,
} from '@constants/options/optionsValues';
import { dropdownSizeL } from '@constants/values';
import { Option } from '@models/common/Option';
import { mapToOptionsList } from '@util/mappingHelper';
import InfoField from '@sharedComponents/Display/InfoField';
import InfoIconWithTooltip from '@sharedComponents/Icons/InfoIconWithTooltip';
import FormError from '@sharedComponents/Form/FormError';
import TextInput from '@sharedComponents/Inputs/TextInput';
import DateInput from '@sharedComponents/Inputs/DateInput';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

interface OwnProps {
  selectedOrganizationId: number | undefined;
  sendDate: Nullable<string>;
  linkDestination: Nullable<VoucherNotificationLinkDestinationEnum>;
  senderNameType: Nullable<VoucherNotificationSenderNameEnum>;
  customSenderName: Nullable<string>;
  updateSendDate: (value: Nullable<string>) => void;
  updateLinkDestination: (value: Nullable<VoucherNotificationLinkDestinationEnum>) => void;
  updateSenderNameType: (value: Nullable<VoucherNotificationSenderNameEnum>) => void;
  updateCustomSenderName: (value: Nullable<string>) => void;
  sendDateError: Nullable<string>;
  customSenderNameError: Nullable<string>;
}

type Props = OwnProps;

const NotificationSettings = React.memo<Props>(({
  selectedOrganizationId,
  sendDate,
  linkDestination,
  senderNameType,
  customSenderName,
  updateSendDate,
  updateLinkDestination,
  updateSenderNameType,
  updateCustomSenderName,
  sendDateError,
  customSenderNameError,
}) => {
  const linkDestinationOptions = useMemo(() => mapToOptionsList<string, any>({
    list: voucherNotificationsLinkDestinations.map((option: Option<VoucherNotificationLinkDestinationEnum>) => ({
      ...option,
      disabled: option.value === VoucherNotificationLinkDestinationEnum.OrganizationLandingPage
        && !selectedOrganizationId,
    })),
    key: 'key',
    value: 'value',
    name: 'name',
    emptyOption: {
      name: 'Choose Link Destination',
      disabled: true,
    },
  }), [selectedOrganizationId]);

  const senderNameTypeOptions = useMemo(() => mapToOptionsList<string, any>({
    list: voucherNotificationsSenderNameTypes.map((option: Option<VoucherNotificationSenderNameEnum>) => ({
      ...option,
      disabled: option.value === VoucherNotificationSenderNameEnum.OrganizationName && !selectedOrganizationId,
    })),
    key: 'key',
    value: 'value',
    name: 'name',
    emptyOption: {
      name: 'Choose Sender Name',
      disabled: true,
    },
  }), [selectedOrganizationId]);

  const onSendDateChange = useCallback((date: MaterialUiPickersDate) => {
    updateSendDate(date?.toISOString() ?? null);
  }, [updateSendDate]);

  const onLinkDestinationSelected = useCallback((value: string) => {
    updateLinkDestination(value as VoucherNotificationLinkDestinationEnum);
  }, [updateLinkDestination]);

  const onSenderNameTypeSelected = useCallback((value: string) => {
    updateSenderNameType(value as VoucherNotificationSenderNameEnum);
  }, [updateSenderNameType]);

  const onCustomSenderNameChange = useCallback((e) => {
    updateCustomSenderName(e.target.value ?? null);
  }, [updateCustomSenderName]);

  return (
    <>
      <Grid
        item={true}
        xs={12}
        className={'voucherOrders__edit-form__section-title'}
      >
        <span className='card-title'>Notification Settings</span>
      </Grid>

      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div className='align__center'>
                  <span>Send Date</span>
                  <span className='required'>*</span>
                  <InfoIconWithTooltip
                    tooltipText={'Send out only if paid'}
                  />
                </div>
              )}
              value={(
                <div>
                  <DateInput
                    value={sendDate}
                    onChange={onSendDateChange}
                    placeholder={'Select Send Date'}
                    width={100}
                  />
                  {
                    sendDateError &&
                      <FormError error={sendDateError} />
                  }
                </div>
              )}
              labelClass={'mb-5'}
            />
          </div>
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Link Destination</span>
                  <span className='required'>*</span>
                </div>
              )}
              value={(
                <Dropdown<string>
                  defaultValue={linkDestination ?? undefined}
                  options={linkDestinationOptions}
                  onChange={onLinkDestinationSelected}
                  size={dropdownSizeL}
                />
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Sender Name</span>
                  <span className='required'>*</span>
                </div>
              )}
              value={(
                <Dropdown<string>
                  defaultValue={senderNameType ?? undefined}
                  options={senderNameTypeOptions}
                  onChange={onSenderNameTypeSelected}
                  size={dropdownSizeL}
                />
              )}
            />
          </div>
        </Grid>
        {
          senderNameType === VoucherNotificationSenderNameEnum.CustomName &&
            <Grid
              item={true}
              xs={6}
            >
              <div>
                <InfoField
                  label={(
                    <div>
                      <span>Custom Sender Name</span>
                      <span className='required'>*</span>
                    </div>
                  )}
                  value={(
                    <div>
                      <TextInput
                        initialValue={customSenderName}
                        placeholder={'Enter Custom Sender Name'}
                        onChange={onCustomSenderNameChange}
                        inputClasses={'w-100'}
                      />
                      {
                        customSenderNameError &&
                        <FormError error={customSenderNameError} />
                      }
                    </div>
                  )}
                />
              </div>
            </Grid>
        }
      </Grid>
    </>
  );
});

export default NotificationSettings;
