import React from 'react';

interface OwnProps {
  text: string | JSX.Element;
}

type Props = OwnProps;

const TableQuickViewListItem = React.memo<Props>(({ text }) => (
  <div className='tableView__details--list__item'>{text}</div>
));

export default TableQuickViewListItem;
