import * as actionTypes from './types';

export const initialState = {
  styleQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
  },
  skuSetupQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
    pageNumber: 1,
    pageSize: 5,
  },
  coloredStylesForStylesDictionary: {},
  sportsForStylesDictionary: {},
  styleTableState: {
    brands: [],
    categories: [],
    ages: [],
    genders: [],
    sports: [],
    colors: [],
    sizes: [],
    pageNumber: 1,
    pageSize: 12,
    recommended: null,
    availableForNewStores: null,
    sortBy: '',
    sortDirection: '',
    search: '',
    customizable: null,
    priceMin: '',
    priceMax: '',
    vendorQuantityMin: '',
    websiteVisibiltiy: '',
    vendorNames: [],
    decorationMethods: [],
  },
  coloredStylesQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
    pageNumber: 1,
  },
  vendorsQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
    pageNumber: 1,
    pageSize: 10,
  },
  style: {},
  coloredStyles: [],
  displayedColoredStyles: [],
  coloredStyleInfo: null,
  brands: [],
  categories: [],
  parentCategories: [],
  sports: [],
  styleSports: [],
  styleLayouts: [],
  styleProducts: [],
  coloredStyleProducts: [],
  decorationLocations: [],
  logoDecorationLocations: [],
  personalizationDecorationLocations: [],
  decorationLocationArtworkSizes: {},
  decorationLocationArtworkSizesList: [],
  decorationLocationPersonalizationSizes: {},
  decorationLocationPersonalizationSizesList: [],
  vendorQuantityMin: '',
  websiteVisibiltiy: '',
  vendorNames: [],
  decorationMethods: [],
  product: {},
  orderItemProduct: {},
  productVendorQuantities: {
    vendors: [],
    vendorsBySku: {},
  },
  productVendorQuantitiesForColoredStyle: {
    vendors: [],
    vendorsBySku: {},
  },
  vendors: [],
  vendorsDictionary: {},
  productVendorMappingsForSku: [],
  vendor: {},
  vendorTimelines: [],
  productInventoryForStyle: [],
  productSkuInfoForStyle: [],
  productInventoryForColoredStyle: [],
  productInventoryForColoredStyleDictionary: [],
  sizes: [],
  colorGroups: [],
  ages: [],
  genders: [],
  colors: [],
  colorsDictionary: {},
  artworkColors: [],
  floodColors: [],
  styleSuggestions: [],
  styleFromSSAPI: {},
  coloredStylesFromSSAPI: [],
  styleProductsFromSSAPI: [],
  dtgEnvironments: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    items: [],
    pageNumber: 1,
    pageSize: 10,
  },
  dtgEnvironmentOptions: [],
  dtgPalletOptions: [],
  dtgPretreatmentBatchOptions: [],
  dtgTabletProfileOptions: [],
  dtgColoredStyle: {},
  dtgArtworkSettings: [],
  dtgPersonalizationSettings: [],
  dtgTabletProfiles: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    items: [],
    pageNumber: 1,
    pageSize: 10,
  },
};

export default function productCatalog(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_STYLE_TABLE:
      return {
        ...state,
        styleQueue: action.data,
      };
    case actionTypes.UPDATE_SKU_SETUP_TABLE:
      return {
        ...state,
        skuSetupQueue: action.data,
      };
    case actionTypes.UPDATE_COLORED_STYLES_FOR_STYLES:
      return {
        ...state,
        coloredStylesForStylesDictionary: action.data,
      };
    case actionTypes.UPDATE_SPORTS_FOR_STYLES:
      return {
        ...state,
        sportsForStylesDictionary: action.data,
      };
    case actionTypes.UPDATE_STYLE_TABLE_STATE:
      return {
        ...state,
        styleTableState: action.data,
      };
    case actionTypes.RESET_STYLE_TABLE_STATE:
      return {
        ...state,
        styleTableState: initialState.styleTableState,
      };
    case actionTypes.UPDATE_COLORED_STYLES_TABLE:
      return {
        ...state,
        coloredStylesQueue: action.data,
      };
    case actionTypes.UPDATE_COLORED_STYLE:
      return {
        ...state,
        coloredStyleInfo: action.data,
      };
    case actionTypes.UPDATE_STYLE:
      return {
        ...state,
        style: action.data,
      };
    case actionTypes.UPDATE_COLORED_STYLES:
      return {
        ...state,
        coloredStyles: action.data,
      };
    case actionTypes.UPDATE_DISPLAYED_COLORED_STYLES:
      return {
        ...state,
        displayedColoredStyles: action.data,
      };
    case actionTypes.UPDATE_PRODUCTS_FOR_STYLE:
      return {
        ...state,
        styleProducts: action.data,
      };
    case actionTypes.UPDATE_PRODUCTS_FOR_COLORED_STYLE:
      return {
        ...state,
        coloredStyleProducts: action.data,
      };
    case actionTypes.UPDATE_PRODUCTS_FOR_COLORED_STYLES_DICTIONARY:
      return {
        ...state,
        coloredStyleProductsDictionary: action.data,
      };
    case actionTypes.UPDATE_SPORTS_FOR_STYLE:
      return {
        ...state,
        styleSports: action.data,
      };
    case actionTypes.UPDATE_LAYOUTS_FOR_STYLE:
      return {
        ...state,
        styleLayouts: action.data,
      };
    case actionTypes.UPDATE_BRANDS:
      return {
        ...state,
        brands: action.data,
      };
    case actionTypes.UPDATE_VENDOR_NAMES:
      return {
        ...state,
        vendorNames: action.data,
      };
    case actionTypes.UPDATE_DECORATION_METHODS:
      return {
        ...state,
        decorationMethods: action.data,
      };
    case actionTypes.UPDATE_CATEGORIES:
      return {
        ...state,
        categories: action.data,
      };
    case actionTypes.UPDATE_PARENT_CATEGORIES:
      return {
        ...state,
        parentCategories: action.data,
      };
    case actionTypes.UPDATE_SPORTS:
      return {
        ...state,
        sports: action.data,
      };
    case actionTypes.UPDATE_DECORATION_LOCATIONS:
      return {
        ...state,
        decorationLocations: action.data,
      };
    case actionTypes.UPDATE_LOGO_DECORATION_LOCATIONS:
      return {
        ...state,
        logoDecorationLocations: action.data,
      };
    case actionTypes.UPDATE_PERSONALIZATION_DECORATION_LOCATIONS:
      return {
        ...state,
        personalizationDecorationLocations: action.data,
      };
    case actionTypes.UPDATE_DECORATION_LOCATION_ARTWORK_SIZES:
      const decorationLocationArtworkSizes = {};
      for (const artworkSize of action.data) {
        const {
          decorationLocationId, decorationMethod, decorationSize, width, height,
        } = artworkSize;

        const dimension = {
          width,
          height,
        };

        if (!decorationLocationArtworkSizes[decorationLocationId]) {
          decorationLocationArtworkSizes[decorationLocationId] = {
            [decorationMethod]: { [decorationSize]: dimension },
          };
          continue;
        }

        if (!decorationLocationArtworkSizes[decorationLocationId][decorationMethod]) {
          decorationLocationArtworkSizes[decorationLocationId][decorationMethod] = { [decorationSize]: dimension };
          continue;
        }

        decorationLocationArtworkSizes[decorationLocationId][decorationMethod] = {
          ...decorationLocationArtworkSizes[decorationLocationId][decorationMethod],
          [decorationSize]: dimension,
        };
      }

      return {
        ...state,
        decorationLocationArtworkSizes,
        decorationLocationArtworkSizesList: action.data,
      };
    case actionTypes.UPDATE_DECORATION_LOCATION_PERSONALIZATION_SIZES:
      const decorationLocationPersonalizationSizes = {};
      for (const personalizationSize of action.data) {
        const {
          decorationLocationId,
          decorationMethod,
          decorationSize,
          nameWidth,
          nameHeight,
          numberHeight,
        } = personalizationSize;

        const nameDimensions = {
          width: nameWidth,
          height: nameHeight,
        };

        const numberDimensions = {
          heignt: numberHeight,
        };

        if (!decorationLocationPersonalizationSizes[decorationLocationId]) {
          decorationLocationPersonalizationSizes[decorationLocationId] = {
            [decorationMethod]: {
              [decorationSize]: {
                name: nameDimensions,
                number: numberDimensions,
              },
            },
          };
          continue;
        }

        decorationLocationPersonalizationSizes[decorationLocationId][decorationMethod] = {
          ...decorationLocationPersonalizationSizes[decorationLocationId][decorationMethod],
          [decorationSize]: {
            name: nameDimensions,
            number: numberDimensions,
          },
        };
      }

      return {
        ...state,
        decorationLocationPersonalizationSizes,
        decorationLocationPersonalizationSizesList: action.data,
      };
    case actionTypes.UPDATE_PRODUCT:
      return {
        ...state,
        product: action.data,
      };
    case actionTypes.UPDATE_ORDER_ITEM_PRODUCT:
      return {
        ...state,
        orderItemProduct: action.data,
      };
    case actionTypes.UPDATE_PRODUCT_VENDOR_QUANTITIES:
      return {
        ...state,
        productVendorQuantities: {
          vendors: action.data,
          vendorsBySku: action.dataMapped,
        },
      };
    case actionTypes.UPDATE_PRODUCT_VENDOR_QUANTITIES_FOR_COLORED_STYLE:
      return {
        ...state,
        productVendorQuantitiesForColoredStyle: {
          vendors: action.data,
          vendorsBySku: action.dataMapped,
        },
      };
    case actionTypes.UPDATE_VENDORS_TABLE:
      return {
        ...state,
        vendorsQueue: action.data,
      };
    case actionTypes.UPDATE_VENDORS:
      const vendorsDictionary = action.data.reduce((total, vendor) => {
        total[vendor.code] = vendor;

        return total;
      }, {});

      return {
        ...state,
        vendors: action.data,
        vendorsDictionary,
      };
    case actionTypes.UPDATE_VENDOR:
      return {
        ...state,
        vendor: action.data,
      };
    case actionTypes.UPDATE_VENDOR_TIMELINES:
      return {
        ...state,
        vendorTimelines: action.data,
      };
    case actionTypes.UPDATE_PRODUCT_VENDOR_MAPPINGS_FOR_SKU:
      return {
        ...state,
        productVendorMappingsForSku: action.data,
      };
    case actionTypes.UPDATE_PRODUCT_INVENTORY_FOR_STYLE:
      return {
        ...state,
        productInventoryForStyle: action.data,
      };
    case actionTypes.UPDATE_PRODUCT_SKU_INFO_FOR_STYLE:
      return {
        ...state,
        productSkuInfoForStyle: action.data,
      };
    case actionTypes.UPDATE_PRODUCT_INVENTORY_FOR_COLORED_STYLE:
      return {
        ...state,
        productInventoryForColoredStyle: action.data,
        productInventoryForColoredStyleDictionary: action.dataDictionary,
      };
    case actionTypes.UPDATE_SIZES:
      return {
        ...state,
        sizes: action.data,
      };
    case actionTypes.UPDATE_COLOR_GROUPS:
      return {
        ...state,
        colorGroups: action.data,
      };
    case actionTypes.UPDATE_AGES:
      return {
        ...state,
        ages: action.data,
      };
    case actionTypes.UPDATE_GENDERS:
      return {
        ...state,
        genders: action.data,
      };
    case actionTypes.UPDATE_COLORS:
      const dictionary = {};
      for (const color of action.data) {
        dictionary[color.id] = color;
      }

      return {
        ...state,
        colors: action.data,
        colorsDictionary: dictionary,
        artworkColors: action.data.filter((c) => c.isArtwork),
        floodColors: action.data.filter((c) => c.isFlood),
      };
    case actionTypes.UPDATE_STYLE_SUGGESTIONS:
      return {
        ...state,
        styleSuggestions: action.data,
      };
    case actionTypes.UPDATE_STYLE_FROM_SSAPI:
      return {
        ...state,
        styleFromSSAPI: action.data,
      };
    case actionTypes.UPDATE_COLORED_STYLES_FOR_STYLES_FROM_SSAPI:
      return {
        ...state,
        coloredStylesFromSSAPI: action.data,
      };
    case actionTypes.UPDATE_PRODUCTS_FOR_STYLE_FROM_SSAPI:
      return {
        ...state,
        styleProductsFromSSAPI: action.data,
      };
    case actionTypes.UPDATE_DTG_ENVIRONMENTS:
      return {
        ...state,
        dtgEnvironments: action.data,
      };
    case actionTypes.UPDATE_DTG_ENVIRONMENT_OPTIONS:
      return {
        ...state,
        dtgEnvironmentOptions: action.data,
      };
    case actionTypes.UPDATE_DTG_PALLET_OPTIONS:
      return {
        ...state,
        dtgPalletOptions: action.data,
      };
    case actionTypes.UPDATE_DTG_PRETREATMENT_BATCH_OPTIONS:
      return {
        ...state,
        dtgPretreatmentBatchOptions: action.data,
      };
    case actionTypes.UPDATE_DTG_TABLET_PROFILE_OPTIONS:
      return {
        ...state,
        dtgTabletProfileOptions: action.data,
      };
    case actionTypes.UPDATE_SINGLE_DTG_COLORED_STYLE:
      return {
        ...state,
        dtgColoredStyle: action.data,
      };
    case actionTypes.UPDATE_DTG_ARTWORK_SETTINGS:
      return {
        ...state,
        dtgArtworkSettings: action.data,
      };
    case actionTypes.UPDATE_DTG_PERSONALIZATION_SETTINGS:
      return {
        ...state,
        dtgPersonalizationSettings: action.data,
      };
    case actionTypes.UPDATE_DTG_TABLET_PROFILES:
      return {
        ...state,
        dtgTabletProfiles: action.data,
      };
    default:
      return state;
  }
}
