import React from 'react';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
}

type Props = OwnProps;

const ReviewItemLabelsLegendDisplay = React.memo<Props>(({ item }) => (
  <LabelsLegendDisplay
    labels={[
      {
        isActive: item.custom,
        text: 'Custom',
        shortText: 'C',
        type: 'status-blue',
      },
      {
        isActive: item.requiredItem,
        text: 'Required',
        shortText: 'R',
        type: 'electric-indigo',
      },
    ]}
  />
));

export default ReviewItemLabelsLegendDisplay;
