import React, {
  useCallback,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { dragAndDropMessage } from '@constants/values';
import {
  emptyImageTextEnum,
  imageLocationsEnum,
} from '@constants/enums/commonEnums';
import CustomItemImage from '@sharedComponents/CustomItems/CustomItemImage';
import UploadImage, { UploadFileMessage } from '@sharedComponents/Upload/UploadImage';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';

const defaultImageMessages = {
  [imageLocationsEnum.Front]: dragAndDropMessage(`image ${emptyImageTextEnum.Front}`),
  [imageLocationsEnum.Back]: dragAndDropMessage(`image ${emptyImageTextEnum.Back}`),
  [imageLocationsEnum.Left]: dragAndDropMessage(`image ${emptyImageTextEnum.LeftSide}`),
  [imageLocationsEnum.Right]: dragAndDropMessage(`image ${emptyImageTextEnum.RightSide}`),
};

interface OwnProps {
  imageFrontAttachmentUrl: string | undefined;
  imageBackAttachmentUrl: string | undefined;
  imageLeftAttachmentUrl: string | undefined;
  imageRightAttachmentUrl: string | undefined;
  imageFront: string | undefined;
  imageBack: string | undefined;
  imageLeft: string | undefined;
  imageRight: string | undefined;
  isCustomItem?: boolean;
  changeImage: (imageName: (keyof typeof defaultImageMessages), url: Nullable<string>, file: Nullable<File>) => void;
}

type Props = OwnProps;

const CustomItemImages = React.memo<Props>(({
  isCustomItem = true,
  changeImage,
  imageFront,
  imageBack,
  imageLeft,
  imageRight,
  imageFrontAttachmentUrl,
  imageBackAttachmentUrl,
  imageLeftAttachmentUrl,
  imageRightAttachmentUrl,
}) => {
  const [
    imageFrontUploadIsActive,
    setImageFrontUploadIsActive,
  ] = useState<boolean>(false);
  const [
    imageBackUploadIsActive,
    setImageBackUploadIsActive,
  ] = useState<boolean>(false);
  const [
    imageLeftUploadIsActive,
    setImageLeftUploadIsActive,
  ] = useState<boolean>(false);
  const [
    imageRightUploadIsActive,
    setImageRightUploadIsActive,
  ] = useState<boolean>(false);

  const [
    imageFrontAttachmentUrlState,
    setImageFrontAttachmentUrlState,
  ] = useState<Nullable<string>>(imageFrontAttachmentUrl || null);
  const [
    imageBackAttachmentUrlState,
    setImageBackAttachmentUrlState,
  ] = useState<Nullable<string>>(imageBackAttachmentUrl || null);
  const [
    imageLeftAttachmentUrlState,
    setImageLeftAttachmentUrlState,
  ] = useState<Nullable<string>>(imageLeftAttachmentUrl || null);
  const [
    imageRightAttachmentUrlState,
    setImageRightAttachmentUrlState,
  ] = useState<Nullable<string>>(imageRightAttachmentUrl || null);

  const [
    imageFrontMessage,
    setImageFrontMessage,
  ] = useState<UploadFileMessage>(
    {
      type: 'default',
      body: defaultImageMessages[imageLocationsEnum.Front],
    }
  );
  const [
    imageBackMessage,
    setImageBackMessage,
  ] = useState<UploadFileMessage>(
    {
      type: 'default',
      body: defaultImageMessages[imageLocationsEnum.Back],
    }
  );
  const [
    imageLeftMessage,
    setImageLeftMessage,
  ] = useState<UploadFileMessage>(
    {
      type: 'default',
      body: defaultImageMessages[imageLocationsEnum.Left],
    }
  );
  const [
    imageRightMessage,
    setImageRightMessage,
  ] = useState<UploadFileMessage>(
    {
      type: 'default',
      body: defaultImageMessages[imageLocationsEnum.Right],
    }
  );

  const openUploadImageContainer = (
    imageName: (typeof imageLocationsEnum)[keyof typeof imageLocationsEnum],
    e: React.MouseEvent
  ) => {
    // e.preventDefault();
    // e.stopPropagation();

    closeUploadContainers();

    switch (imageName) {
      case imageLocationsEnum.Front:
        setImageFrontUploadIsActive(true);
        break;
      case imageLocationsEnum.Back:
        setImageBackUploadIsActive(true);
        break;
      case imageLocationsEnum.Left:
        setImageLeftUploadIsActive(true);
        break;
      case imageLocationsEnum.Right:
        setImageRightUploadIsActive(true);
        break;
    }
  };

  const closeUploadContainers = useCallback(() => {
    setImageFrontUploadIsActive(false);
    setImageBackUploadIsActive(false);
    setImageLeftUploadIsActive(false);
    setImageRightUploadIsActive(false);
  }, []);

  const changeImageState = (
    imageName: (keyof typeof defaultImageMessages),
    url: Nullable<string>,
    message: UploadFileMessage
  ) => {
    switch (imageName) {
      case 'imageFront':
        setImageFrontAttachmentUrlState(url);
        setImageFrontMessage(message);
        break;
      case 'imageBack':
        setImageBackAttachmentUrlState(url);
        setImageBackMessage(message);
        break;
      case 'imageLeft':
        setImageLeftAttachmentUrlState(url);
        setImageLeftMessage(message);
        break;
      case 'imageRight':
        setImageRightAttachmentUrlState(url);
        setImageRightMessage(message);
        break;
    }
  };

  const onChangeImage = (
    imageName: (keyof typeof defaultImageMessages),
    url: Nullable<string>,
    message: UploadFileMessage,
    file: Nullable<File>
  ): void => {
    changeImageState(imageName, url, message);

    changeImage(imageName, url, file);
  };

  const removeImage = (imageName: (keyof typeof defaultImageMessages)): void => {
    changeImageState(
      imageName,
      null,
      {
        type: 'default',
        body: defaultImageMessages[imageName],
      }
    );

    changeImage(imageName, null, null);
  };

  return (
    <div>
      <div className='lockerManagerEdit__custom-items--images-setup'>
        <CustomItemImage
          isCustomItem={isCustomItem}
          title={'Front'}
          image={imageFrontAttachmentUrlState || imageFront}
          openUploadImgContainer={openUploadImageContainer.bind(null, imageLocationsEnum.Front)}
          removeImage={removeImage.bind(null, imageLocationsEnum.Front)}
        />
        <CustomItemImage
          isCustomItem={isCustomItem}
          title={'Side Left'}
          image={imageLeftAttachmentUrlState || imageLeft}
          openUploadImgContainer={openUploadImageContainer.bind(null, imageLocationsEnum.Left)}
          removeImage={removeImage.bind(null, imageLocationsEnum.Left)}
        />
        <CustomItemImage
          isCustomItem={isCustomItem}
          title={'Side Right'}
          image={imageRightAttachmentUrlState || imageRight}
          openUploadImgContainer={openUploadImageContainer.bind(null, imageLocationsEnum.Right)}
          removeImage={removeImage.bind(null, imageLocationsEnum.Right)}
        />
        <CustomItemImage
          isCustomItem={isCustomItem}
          title={'Back'}
          image={imageBackAttachmentUrlState || imageBack}
          openUploadImgContainer={openUploadImageContainer.bind(null, imageLocationsEnum.Back)}
          removeImage={removeImage.bind(null, imageLocationsEnum.Back)}
        />
      </div>
      <div className='mb-30'>
        <OutsideClickWrapper onClick={closeUploadContainers}>
          <UploadImage
            message={imageFrontMessage}
            changeImageUrl={onChangeImage.bind(null, imageLocationsEnum.Front)}
            uploadIsActive={imageFrontUploadIsActive}
          />
          <UploadImage
            message={imageLeftMessage}
            changeImageUrl={onChangeImage.bind(null, imageLocationsEnum.Left)}
            uploadIsActive={imageLeftUploadIsActive}
          />
          <UploadImage
            message={imageRightMessage}
            changeImageUrl={onChangeImage.bind(null, imageLocationsEnum.Right)}
            uploadIsActive={imageRightUploadIsActive}
          />
          <UploadImage
            message={imageBackMessage}
            changeImageUrl={onChangeImage.bind(null, imageLocationsEnum.Back)}
            uploadIsActive={imageBackUploadIsActive}
          />
        </OutsideClickWrapper>
      </div>
    </div>
  );
});

export default connect()(CustomItemImages);
