import React from 'react';

interface OwnProps {
  width: string;
  height: string;
}

type Props = OwnProps;

const SizeCell = React.memo<Props>((
  {
    width,
    height,
  }
) => (
  <span className='text'>
    {`${width ?? 0}"W x ${height ?? 0}"H`}
  </span>
));

export default SizeCell;
