import React from 'react';
import PropTypes from 'prop-types';
import { emptyImageTextEnum } from '@constants/enums/commonEnums';
import { styleUrl } from '@constants/clientUrls/clientUrls';
import {
  mapStyleDecorations,
  mapColoredStyleColors,
} from '@util/componentHelpers/productCatalogHelper';
import { formatDollarAmount } from '@util/numberHelpers';
import DecorationLocationInfoField from '@components/ProductCatalog/ProductCatalogQuickView/DecorationLocationInfoField';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import InfoField from '@sharedComponents/Display/InfoField';
import OpenNewTabLink from '@sharedComponents/Navigation/OpenNewTabLink';

const ItemInfoProductInformation = React.memo(({
  product,
  coloredStyle,
  hasSku,
  colorsDictionary,
  colors,
  openModalWithImage,
  decorationLocations,
  teamColorsByCode,
}) => {
  const mappedColoredStyle = hasSku
    ? mapColoredStyleColors(product && product.coloredStyle || {})
    : mapColoredStyleColors(coloredStyle);
  const style = hasSku
    ? mapStyleDecorations(mappedColoredStyle.style || {})
    : mapStyleDecorations(coloredStyle.style);
  const floodColor = colorsDictionary[mappedColoredStyle.floodColorId] || {};
  const personalizationColors = colors
    .filter((color) => mappedColoredStyle.personalizationColorIds?.includes(color.id));
  const colorGroupTeamColor = teamColorsByCode[mappedColoredStyle.colorGroup];

  return (
    <div>
      <div className='flex'>
        <div className='redux-form__container'>
          <ImagePreviewCell
            imageUrl={mappedColoredStyle.imageUrlFront}
            openModalWithUrl={openModalWithImage.bind(null, mappedColoredStyle.imageUrlFront)}
            height={'180px'}
            width={'180px'}
            noImageText={emptyImageTextEnum.Front}
          />
          <div className='justify__space-between mt-10'>
            <ImagePreviewCell
              imageUrl={mappedColoredStyle.imageUrlBack}
              openModalWithUrl={openModalWithImage.bind(null, mappedColoredStyle.imageUrlBack)}
              height={'84px'}
              width={'84px'}
              noImageText={emptyImageTextEnum.Back}
            />
            <ImagePreviewCell
              imageUrl={mappedColoredStyle.imageUrlSide}
              openModalWithUrl={openModalWithImage.bind(null, mappedColoredStyle.imageUrlSide)}
              height={'84px'}
              width={'84px'}
              noImageText={emptyImageTextEnum.Side}
            />
          </div>
        </div>
        <div className='flex__direction-column w-100'>
          <div className='redux-form__container w-100 ml-20'>
            <div className='supermodal--container'>
              <div className='flex__direction-column supermodal--container--left'>
                <div className='flex'>
                  <InfoField
                    label={'Product Name'}
                    value={style.name}
                  />
                  <div className='align__center mt-10'>
                    <OpenNewTabLink
                      url={styleUrl(style.id)}
                      text={'View Style'}
                    />
                  </div>
                </div>
                <div className='flex'>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Parent SKU'}
                      value={style.code}
                    />
                  </div>
                  <div className='redux-form__column--size-s mr-20'>
                    <InfoField
                      label={'Category'}
                      value={style.category && style.category.name}
                    />
                  </div>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Gender'}
                      value={style.gender}
                    />
                  </div>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Age'}
                      value={style.age}
                    />
                  </div>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'VFI'}
                      value={style.vendorFulfilledItem ? 'Yes' : 'No'}
                    />
                  </div>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Discount'}
                      value={formatDollarAmount(style.discount)}
                    />
                  </div>
                </div>
              </div>
              <div className='flex__direction-column supermodal--container--right'>
                <div className='redux-form__column--size-s'>
                  <InfoField
                    label={'Brand'}
                    value={style.brand && style.brand.name}
                  />
                </div>
                <div className='flex'>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Tax Code'}
                      value={style.taxCode}
                    />
                  </div>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Recommended'}
                      value={style.recommended ? 'Yes' : 'No'}
                    />
                  </div>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'New Stores'}
                      value={style.availableForNewStores ? 'Yes' : 'No'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mb-20 ml-20'>
            <InfoField
              label={'Style Description'}
              value={style.description}
            />
          </div>
          <div className='flex ml-10 mb-20'>
            <div className='redux-form__container w-40 mr-50 ml-10'>
              <div className='tableView__details--header--title mb-30'>
                <span>Decorations</span>
              </div>
              <div className='flex'>
                <div className='redux-form__column--size-s'>
                  <InfoField
                    label={'Decoration'}
                    value={style.decorationMethod}
                  />
                </div>
                <div className='redux-form__column--size-s'>
                  <DecorationLocationInfoField
                    label={'Logo'}
                    styleLocations={style.logoLocations}
                    decorationLocations={decorationLocations}
                  />
                </div>
                <div className='redux-form__column--size-s mr-20'>
                  <InfoField
                    label={'Customizable'}
                    value={style.customizable ? 'Yes' : 'No'}
                  />
                </div>
                <div className='redux-form__column--size-s'>
                  <DecorationLocationInfoField
                    label={'Personalization'}
                    styleLocations={style.personalizationLocations}
                    decorationLocations={decorationLocations}
                  />
                </div>
              </div>
            </div>
            {
              hasSku &&
              <div className='redux-form__container w-40'>
                <div className='tableView__details--header--title mb-30'>
                  <span>Sku</span>
                </div>
                <div className='flex'>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Vendor ID'}
                      value={product.vendorId}
                    />
                  </div>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Size'}
                      value={product.size}
                    />
                  </div>
                  <div className='redux-form__column--size-s'>
                    <InfoField
                      label={'Decoration Size'}
                      value={product.decorationSize}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
          <div className='flex ml-10'>
            <div className='redux-form__container w-40 ml-10'>
              <div className='tableView__details--header--title mb-30'>
                <span>Colored Style</span>
              </div>
              <div className='flex'>
                <div className='redux-form__column--size-s mr-15'>
                  <InfoField
                    label={'Flood Color'}
                    value={floodColor.code &&
                      <ColorDisplay
                        colorName={floodColor.code}
                        displayName={true}
                        size={'size--small'}
                        hideEmptySwatch={true}
                        hexValue={floodColor.hexValue}
                      />
                    }
                  />
                </div>
                <div className='redux-form__column--size-s mr-15'>
                  <InfoField
                    label={'Color Group'}
                    value={mappedColoredStyle.colorGroup &&
                      <ColorDisplay
                        colorName={mappedColoredStyle.colorGroup}
                        displayName={true}
                        size={'size--small'}
                        hideEmptySwatch={true}
                        hexValue={colorGroupTeamColor && colorGroupTeamColor.hexValue}
                      />
                    }
                  />
                </div>
                <div className='redux-form__column--size-m'>
                  <InfoField
                    label={'Personalization colors'}
                    value={personalizationColors.length > 0 && (
                      <div className='flex__wrap'>
                        {
                          personalizationColors.map((color, index) => (
                            <div
                              className='mr-10'
                              key={index}
                            >
                              <ColorDisplay
                                colorName={color.code}
                                displayName={true}
                                size={'size--small'}
                                hideEmptySwatch={true}
                                hexValue={color.hexValue}
                              />
                            </div>
                          ))
                        }
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

ItemInfoProductInformation.propTypes = {
  product: PropTypes.object,
  coloredStyle: PropTypes.object,
  hasSku: PropTypes.bool,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  openModalWithImage: PropTypes.func.isRequired,
  decorationLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  teamColorsByCode: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.string,
  })).isRequired,
};

export default ItemInfoProductInformation;
