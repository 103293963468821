import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import ImageModal from '@sharedComponents/Modal/ImageModal';
import Table from '@sharedComponents/Table/Table';
import Icon from '@sharedComponents/Icons/Icon';
import ItemLocationAndSizeCell from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemLocationAndSizeCell';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';

const ProductsTable = Table();

class LogoBankEditAssignmentReview extends PureComponent {
  state = {
    imageModalIsOpen: false,
    imageUrl: '',
  };

  openImageModal = (imageUrl) => {
    this.setState({
      imageModalIsOpen: true,
      imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      imageModalIsOpen: false,
      imageUrl: '',
    });
  };

  getColumns = () => {
    const columns = [
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        width: 120,
        Cell: (cellProps) => (
          <ImagePreviewCell
            imageUrl={cellProps.value}
            openModalWithUrl={this.openImageModal}
          />
        ),
      },
      {
        Header: 'Code & Description',
        accessor: '',
        width: 200,
        Cell: (cellProps) => (
          <div className='logo-bank__assignment--code'>
            <TitleDescriptionCell item={cellProps.value} />
          </div>
        ),
      },
      {
        Header: 'Color',
        accessor: 'color',
        width: 120,
      },
      {
        Header: 'Decoration',
        accessor: 'decoration_method',
        width: 80,
      },
      {
        Header: 'Location & Size',
        accessor: '',
        width: 120,
        Cell: (cellProps) => <ItemLocationAndSizeCell item={cellProps.value} />,
      },
    ];

    return columns;
  };

  render() {
    const {
      logoImage,
      productsToAddLogo,
      productsToRemoveLogo,
    } = this.props;

    const {
      imageUrl,
      imageModalIsOpen,
    } = this.state;

    return (
      <div>
        <ImageModal
          isOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />
        <div className='logo-bank__review-title'>
          <span className='uppercase'>
            Add Logo:
          </span>
        </div>
        <div className='logo-bank__review-container'>
          <div
            className='logo-bank__review-image'
            style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logoImage}")` }}
          />
          <Icon materialIcon={'arrow_forward'} />
          <div className='logo-bank__review-title'>
            <span className='uppercase'>
              To Item(s): {productsToAddLogo.length}
            </span>
          </div>
          <ProductsTable
            data={productsToAddLogo}
            columns={this.getColumns()}
            showPagination={false}
            defaultPageSize={-1}
          />
        </div>
        <div className='logo-bank__review-title'>
          <span className='uppercase'>
            Remove Logo:
          </span>
        </div>
        <div className='logo-bank__review-container'>
          <div
            className='logo-bank__review-image'
            style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logoImage}")` }}
          />
          <Icon materialIcon={'arrow_back'} />
          <div className='logo-bank__review-title'>
            <span className='uppercase'>
              From {productsToRemoveLogo.length} item{productsToRemoveLogo.length > 1 && 's'}
            </span>
          </div>
          <ProductsTable
            data={productsToRemoveLogo}
            columns={this.getColumns()}
            showPagination={false}
            defaultPageSize={-1}
          />
        </div>
      </div>
    );
  }
}

LogoBankEditAssignmentReview.propTypes = {
  productsToAddLogo: PropTypes.array.isRequired,
  productsToRemoveLogo: PropTypes.array.isRequired,
  logoImage: PropTypes.string.isRequired,
};

export default LogoBankEditAssignmentReview;
