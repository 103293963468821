import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InfoField from '@sharedComponents/Display/InfoField';
import InputWithValidation from '@sharedComponents/Form/InputWithValidation';

const minDesignIdLength = 1;

const VendorDesignIdFormInput = React.memo(({
  isVendorDesignIdValid,
  validateVendorDesignId,
  handleVendorDesignIdValidationChanges,
}) => (
  <InfoField
    fieldClass={'mb-0 pr-20'}
    label={'Design ID'}
    value={(
      <Field
        name={'vendorDesignId'}
        placeholder={'Enter Design ID'}
        component={InputWithValidation}
        type={'text'}
        className={'w-100'}
        validateInput={validateVendorDesignId}
        validationTimeout={250}
        isValid={isVendorDesignIdValid}
        handleInputValidationChange={handleVendorDesignIdValidationChanges}
        minInputLengthForValidation={minDesignIdLength}
        invalidWarningMessage={'Invalid ID'}
        invalidTooltipMessage={'Contact administrator to resolve'}
      />
    )}
  />
));

VendorDesignIdFormInput.propTypes = {
  validateVendorDesignId: PropTypes.func.isRequired,
  isVendorDesignIdValid: PropTypes.bool.isRequired,
  handleVendorDesignIdValidationChanges: PropTypes.func.isRequired,
};

export default VendorDesignIdFormInput;
