import * as actionTypes from './types';
import productCatalogUrls from '@constants/sqdApiUrls/productCatalogUrls';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import {
  decorationMethodEnum,
  decorationTypeEnum,
} from '@constants/enums/decorationEnums';
import { makeApiCall } from '@util/apiHelper';
import {
  catalogApi,
  slServicesApi,
} from '../../sqdApis';
import {
  mapStyleDecorations,
  mapColoredStyleColors,
} from '@util/componentHelpers/productCatalogHelper';

export const updateStyleTable = (data) => {
  const mappedData = {
    ...data,
    queue: data.queue.map((style) => mapStyleDecorations(style)),
  };

  return {
    type: actionTypes.UPDATE_STYLE_TABLE,
    data: mappedData,
  };
};

export const resetStyleTableState = (data) => ({
  type: actionTypes.RESET_STYLE_TABLE_STATE,
  data,
});

export const updateStyleTableState = (data) => ({
  type: actionTypes.UPDATE_STYLE_TABLE_STATE,
  data,
});

export const updateColoredStylesTable = (data) => ({
  type: actionTypes.UPDATE_COLORED_STYLES_TABLE,
  data,
});

export const updateSkuSetupTable = (data) => {
  const mappedData = {
    ...data,
    queue: data.queue.map((style) => mapStyleDecorations(style)),
  };

  return {
    type: actionTypes.UPDATE_SKU_SETUP_TABLE,
    data: mappedData,
  };
};

export const updateColoredStyles = (data) => ({
  type: actionTypes.UPDATE_COLORED_STYLES,
  data: data.map((coloredStyle) => mapColoredStyleColors(coloredStyle)),
});

export const updateDisplayedColoredStyles = (data) => ({
  type: actionTypes.UPDATE_DISPLAYED_COLORED_STYLES,
  data,
});

export const updateStyle = (data) => ({
  type: actionTypes.UPDATE_STYLE,
  data: mapStyleDecorations(data),
});

export const updateColoredStyle = (data) => ({
  type: actionTypes.UPDATE_COLORED_STYLE,
  data,
});

export const updateColoredStylesForStyles = (data) => ({
  type: actionTypes.UPDATE_COLORED_STYLES_FOR_STYLES,
  data,
});

export const updateSportsForStyles = (data) => ({
  type: actionTypes.UPDATE_SPORTS_FOR_STYLES,
  data,
});

export const updateBrands = (data) => ({
  type: actionTypes.UPDATE_BRANDS,
  data,
});

export const updateVendorNames = (data) => ({
  type: actionTypes.UPDATE_VENDOR_NAMES,
  data,
});

export const updateCategories = (data) => ({
  type: actionTypes.UPDATE_CATEGORIES,
  data,
});

export const updateParentCategories = (data) => ({
  type: actionTypes.UPDATE_PARENT_CATEGORIES,
  data,
});

export const updateSports = (data) => ({
  type: actionTypes.UPDATE_SPORTS,
  data,
});

export const updateVendors = (data) => ({
  type: actionTypes.UPDATE_VENDORS,
  data,
});

export const updateVendor = (data) => ({
  type: actionTypes.UPDATE_VENDOR,
  data,
});

export const updateSportsForStyle = (data) => ({
  type: actionTypes.UPDATE_SPORTS_FOR_STYLE,
  data,
});

export const updateLayoutsForStyle = (data) => ({
  type: actionTypes.UPDATE_LAYOUTS_FOR_STYLE,
  data,
});

export const updateProductsForStyle = (data) => ({
  type: actionTypes.UPDATE_PRODUCTS_FOR_STYLE,
  data,
});

export const updateProductsForColoredStyle = (data) => ({
  type: actionTypes.UPDATE_PRODUCTS_FOR_COLORED_STYLE,
  data,
});

export const updateProductsForColoredStylesDictionary = (data) => ({
  type: actionTypes.UPDATE_PRODUCTS_FOR_COLORED_STYLES_DICTIONARY,
  data,
});

export const updateDecorationLocations = (data) => ({
  type: actionTypes.UPDATE_DECORATION_LOCATIONS,
  data,
});

export const updateLogoDecorationLocations = (data) => ({
  type: actionTypes.UPDATE_LOGO_DECORATION_LOCATIONS,
  data,
});

export const updatePersonalizationDecorationLocations = (data) => ({
  type: actionTypes.UPDATE_PERSONALIZATION_DECORATION_LOCATIONS,
  data,
});

export const updateDecorationLocationArtworkSizes = (data) => ({
  type: actionTypes.UPDATE_DECORATION_LOCATION_ARTWORK_SIZES,
  data,
});

export const updateDecorationLocationPersonalizationSizes = (data) => ({
  type: actionTypes.UPDATE_DECORATION_LOCATION_PERSONALIZATION_SIZES,
  data,
});

export const updateProduct = (data) => ({
  type: actionTypes.UPDATE_PRODUCT,
  data,
});

export const updateOrderItemProduct = (data) => ({
  type: actionTypes.UPDATE_ORDER_ITEM_PRODUCT,
  data,
});

export const updateProductVendorQuantities = (data) => {
  const dataMapped = {};
  if (data) {
    for (const vendor of data) {
      const current = dataMapped[vendor.sku];

      if (current) {
        dataMapped[vendor.sku] = [
          ...current,
          vendor,
        ];
      } else {
        dataMapped[vendor.sku] = [vendor];
      }
    }
  }

  return {
    type: actionTypes.UPDATE_PRODUCT_VENDOR_QUANTITIES,
    data,
    dataMapped,
  };
};

export const updateProductVendorQuantitiesForColoredStyle = (data) => {
  const dataMapped = {};
  if (data) {
    for (const vendor of data) {
      const current = dataMapped[vendor.sku];

      if (current) {
        dataMapped[vendor.sku] = [
          ...current,
          vendor,
        ];
      } else {
        dataMapped[vendor.sku] = [vendor];
      }
    }
  }

  return {
    type: actionTypes.UPDATE_PRODUCT_VENDOR_QUANTITIES_FOR_COLORED_STYLE,
    data,
    dataMapped,
  };
};

export const updateProductVendorMappingsForSku = (data) => ({
  type: actionTypes.UPDATE_PRODUCT_VENDOR_MAPPINGS_FOR_SKU,
  data,
});

export const updateVendorsTable = (data) => ({
  type: actionTypes.UPDATE_VENDORS_TABLE,
  data,
});

export const updateVendorTimelines = (data) => ({
  type: actionTypes.UPDATE_VENDOR_TIMELINES,
  data,
});

export const updateProductInventoryForStyle = (data) => ({
  type: actionTypes.UPDATE_PRODUCT_INVENTORY_FOR_STYLE,
  data,
});

export const updateProductSkuInfoForStyle = (data) => ({
  type: actionTypes.UPDATE_PRODUCT_SKU_INFO_FOR_STYLE,
  data,
});

export const updateProductInventoryForColoredStyle = (data) => {
  const dataDictionary = {};
  if (data) {
    for (const el of data) {
      dataDictionary[el.sku] = el;
    }
  }

  return {
    type: actionTypes.UPDATE_PRODUCT_INVENTORY_FOR_COLORED_STYLE,
    data,
    dataDictionary,
  };
};

export const updateSizes = (data) => ({
  type: actionTypes.UPDATE_SIZES,
  data,
});

export const updateColorGroups = (data) => ({
  type: actionTypes.UPDATE_COLOR_GROUPS,
  data,
});

export const updateAges = (data) => ({
  type: actionTypes.UPDATE_AGES,
  data,
});

export const updateGenders = (data) => ({
  type: actionTypes.UPDATE_GENDERS,
  data,
});

export const updateColors = (data) => ({
  type: actionTypes.UPDATE_COLORS,
  data,
});

export const updateStyleSuggestions = (data) => ({
  type: actionTypes.UPDATE_STYLE_SUGGESTIONS,
  data,
});

export const updateStyleFromSSAPI = (data) => ({
  type: actionTypes.UPDATE_STYLE_FROM_SSAPI,
  data,
});

export const updateColoredStylesForStyleFromSSAPI = (data) => ({
  type: actionTypes.UPDATE_COLORED_STYLES_FOR_STYLES_FROM_SSAPI,
  data,
});

export const updateProductsForStyleFromSSAPI = (data) => ({
  type: actionTypes.UPDATE_PRODUCTS_FOR_STYLE_FROM_SSAPI,
  data,
});

export const updateDtgEnvironments = (data) => ({
  type: actionTypes.UPDATE_DTG_ENVIRONMENTS,
  data,
});

export const updateDtgEnvironmentOptions = (data) => ({
  type: actionTypes.UPDATE_DTG_ENVIRONMENT_OPTIONS,
  data,
});

export const updateDtgPalletOptions = (data) => ({
  type: actionTypes.UPDATE_DTG_PALLET_OPTIONS,
  data,
});

export const updateDtgPretreatmentBatchOptions = (data) => ({
  type: actionTypes.UPDATE_DTG_PRETREATMENT_BATCH_OPTIONS,
  data,
});

export const updateDtgTabletProfileOptions = (data) => ({
  type: actionTypes.UPDATE_DTG_TABLET_PROFILE_OPTIONS,
  data,
});

export const updateSingleDtgColoredStyle = (data) => ({
  type: actionTypes.UPDATE_SINGLE_DTG_COLORED_STYLE,
  data,
});

export const updateDtgArtworkSettings = (data) => ({
  type: actionTypes.UPDATE_DTG_ARTWORK_SETTINGS,
  data,
});

export const updateDtgPersonalizationSettings = (data) => ({
  type: actionTypes.UPDATE_DTG_PERSONALIZATION_SETTINGS,
  data,
});

export const updateDtgTabletProfiles = (data) => ({
  type: actionTypes.UPDATE_DTG_TABLET_PROFILES,
  data,
});

export const updateDecorationMethods = (data) => ({
  type: actionTypes.UPDATE_DECORATION_METHODS,
  data,
});

export const fetchStyleTable =
  (
    pageNumber = 1,
    pageSize = 12,
    brands = [],
    categories = [],
    ages = [],
    genders = [],
    sports = [],
    colors = [],
    sizes = [],
    recommended = '',
    availableForNewStores = '',
    sortBy = '',
    sortDirection = '',
    search = '',
    customizable = '',
    priceMin = undefined,
    priceMax = undefined,
    status = '',
    vendorQuantityMin = '',
    websiteVisibility = '',
    vendorNames = [],
    decorationMethods = [],
    prdOnly = false
  ) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.stylesTable(
        pageNumber,
        pageSize,
        brands,
        categories,
        prdOnly,
        ages,
        genders,
        sports,
        colors,
        sizes,
        recommended,
        availableForNewStores,
        sortBy,
        sortDirection,
        search,
        customizable,
        priceMin,
        priceMax,
        status,
        vendorQuantityMin,
        websiteVisibility,
        vendorNames,
        decorationMethods
      ),
      { handleBlockingLoading: false }
    );
    const data = await makeApiCall(call);

    if (data) {
      dispatch(
        updateStyleTable({
          totalPages: data.totalPages,
          hasPreviousPage: data.hasPreviousPage,
          hasNextPage: data.hasNextPage,
          pageNumber: data.pageNumber,
          pageSize: data.pageSize,
          queue: data.items,
          totalCount: data.totalCount,
        })
      );
    }

    return data;
  };

export const fetchSkuSetupTable =
  (
    pageNumber = 1,
    pageSize = 5,
    brands = [],
    categories = [],
    prdOnly = false,
    search = '',
    status = ''
  ) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.stylesTable(
        pageNumber,
        pageSize,
        brands,
        categories,
        prdOnly,
        [],
        [],
        [],
        [],
        [],
        '',
        '',
        '',
        '',
        search,
        '',
        '',
        '',
        status
      ),
      { handleBlockingLoading: false }
    );
    const data = await makeApiCall(call);

    if (data) {
      dispatch(
        updateSkuSetupTable({
          totalPages: data.totalPages,
          hasPreviousPage: data.hasPreviousPage,
          hasNextPage: data.hasNextPage,
          pageNumber: data.pageNumber,
          pageSize: data.pageSize,
          queue: data.items,
          totalCount: data.totalCount,
        })
      );

      if (data.items) {
        const coloredStylesForStylesDictionary = {};
        const sportsForStylesDictionary = {};

        for (const item of data.items) {
          const coloredStylesCall = catalogApi.get(
            productCatalogUrls.coloredStylesForStyle(
              item.id,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              status
            ),
            { handleBlockingLoading: false }
          );

          const coloredStylesData = await makeApiCall(coloredStylesCall);

          if (coloredStylesData) {
            for (let coloredStyle of coloredStylesData) {
              coloredStyle = mapColoredStyleColors(coloredStyle);
            }
          }

          coloredStylesForStylesDictionary[item.id] = coloredStylesData;
        }

        dispatch(
          updateColoredStylesForStyles(coloredStylesForStylesDictionary)
        );

        for (const item of data.items) {
          const sportsCall = catalogApi.get(
            productCatalogUrls.sportsForStyle(item.id),
            {
              handleBlockingLoading: false,
            }
          );
          const sportsData = await makeApiCall(sportsCall);
          sportsForStylesDictionary[item.id] = sportsData;
        }

        dispatch(updateSportsForStyles(sportsForStylesDictionary));
      }
    }
  };

export const fetchStyleTableWithState =
  (
    pageNumber = 1,
    pageSize = 12,
    brands = [],
    vendorNames = [],
    vendorQuantityMin = '',
    websiteVisibility = '',
    decorationMethods = [],
    categories = [],
    ages = [],
    genders = [],
    sports = [],
    colors = [],
    sizes = [],
    recommended = '',
    availableForNewStores = '',
    sortBy = '',
    sortDirection = '',
    search = '',
    customizable = '',
    priceMin = undefined,
    priceMax = undefined,
    status = ''
  ) => async (dispatch) => {
    const res = await dispatch(
      fetchStyleTable(
        pageNumber,
        pageSize,
        brands,
        categories,
        ages,
        genders,
        sports,
        colors,
        sizes,
        recommended,
        availableForNewStores,
        sortBy,
        sortDirection,
        search,
        customizable,
        priceMin,
        priceMax,
        status,
        vendorQuantityMin,
        websiteVisibility,
        vendorNames,
        decorationMethods
      )
    );

    if (res && res.pageNumber !== null && res.pageNumber !== undefined) {
      dispatch(
        updateStyleTableState({
          pageNumber: res.pageNumber,
          pageSize,
          brands,
          vendorQuantityMin,
          websiteVisibility,
          vendorNames,
          decorationMethods,
          categories,
          ages,
          genders,
          sports,
          colors,
          sizes,
          recommended,
          availableForNewStores,
          sortBy,
          sortDirection,
          search,
          customizable,
          priceMin,
          priceMax,
          status,
        })
      );
    }
  };

export const getStyleById = (styleId) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.style(styleId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateStyle(res));
  }
};

export const getColoredStyles =
  (
    pageNumber = 1,
    pageSize = 14,
    brand = '',
    category = '',
    color = '',
    size = '',
    decorationMethod = '',
    personalizationDecorationLocation = '',
    search = '',
    sortBy = '',
    sortDirection = '',
    customizable = false,
    recommended = false
  ) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.coloredStylesTable(
        pageNumber,
        pageSize,
        brand,
        category,
        color,
        size,
        decorationMethod,
        personalizationDecorationLocation,
        search,
        sortBy,
        sortDirection,
        customizable,
        recommended
      ),
      { handleBlockingLoading: false }
    );
    const data = await makeApiCall(call);

    if (data) {
      dispatch(
        updateColoredStylesTable({
          totalPages: data.totalPages,
          hasPreviousPage: data.hasPreviousPage,
          hasNextPage: data.hasNextPage,
          pageNumber: data.pageNumber,
          pageSize: data.pageSize,
          queue: data.items,
          totalCount: data.totalCount,
        })
      );
    }
  };

export const getColoredStylesForStyle = (styleId) => async (dispatch) => {
  const call = catalogApi.get(
    productCatalogUrls.coloredStylesForStyle(styleId),
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateColoredStyles(res));
    dispatch(updateDisplayedColoredStyles(res));
  }
};

export const getColoredStyle = (coloredStyleId) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.coloredStyle(coloredStyleId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateColoredStyle(res));
  }
};

export const getProductBySku = (sku) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.product(sku), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  dispatch(updateProduct(res));
};

export const getOrderItemProductBySku = (sku) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.product(sku), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  dispatch(updateOrderItemProduct(res));
};

export const getProductsForStyle = (styleId) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.productsForStyle(styleId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateProductsForStyle(res));
  }
};

export const getProductsForColoredStyle =
  (coloredStyleId) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.productsForColoredStyle(coloredStyleId),
      {
        handleBlockingLoading: false,
      }
    );
    const res = await makeApiCall(call);

    if (res) {
      dispatch(updateProductsForColoredStyle(res));
    }
  };

export const getProductsForColoredStylesDictionary =
  (coloredStyleIds) => async (dispatch) => {
    const products = {};

    const promises = coloredStyleIds.map(async (coloredStyleId) => {
      const call = catalogApi.get(
        productCatalogUrls.productsForColoredStyle(coloredStyleId),
        {
          handleBlockingLoading: false,
        }
      );
      const res = await makeApiCall(call);

      if (res) {
        products[coloredStyleId] = res;
      }
    });

    await Promise.all(promises);

    dispatch(updateProductsForColoredStylesDictionary(products));
  };

export const getProductInventoryForStyle = (styleId) => async (dispatch) => {
  const call = catalogApi.get(
    productCatalogUrls.productInventoryForStyle(styleId),
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateProductInventoryForStyle(res));
  }
};

export const getProductSkuInfoForStyle = (styleId) => async (dispatch) => {
  const call = catalogApi.get(
    productCatalogUrls.productSkuInfoForStyle(styleId),
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateProductSkuInfoForStyle(res));
  }
};

export const getProductInventoryForColoredStyle =
  (coloredStyleId) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.productInventoryForColoredStyle(coloredStyleId),
      {
        handleBlockingLoading: false,
      }
    );
    const res = await makeApiCall(call);

    if (res) {
      dispatch(updateProductInventoryForColoredStyle(res));
    }
  };

export const getSportsForStyle = (styleId) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.sportsForStyle(styleId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateSportsForStyle(res));
  }
};

export const getLayoutsForStyle = (styleId) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.layoutsForStyle(styleId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateLayoutsForStyle(res));
  }
};

export const getBrands = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.brands, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateBrands(res));
  }
};

export const getCategories = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.categories, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateCategories(res));
  }
};

export const getParentCategories = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.parentCategories, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateParentCategories(res));
  }
};

export const getSports = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.sports, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateSports(res));
  }
};

export const getDecorationLocations =
  (type = null) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUtilityUrls.decorationLocations(type),
      {
        handleBlockingLoading: false,
      }
    );
    const res = await makeApiCall(call);

    if (res) {
      if (type === decorationTypeEnum.Logo) {
        dispatch(updateLogoDecorationLocations(res));
      } else if (type === decorationTypeEnum.Personalization) {
        dispatch(updatePersonalizationDecorationLocations(res));
      } else {
        dispatch(updateDecorationLocations(res));
      }
    }
  };

export const getDecorationLocationArtworkSizes = () => async (dispatch) => {
  const call = catalogApi.get(
    productCatalogUtilityUrls.decorationLocationArtworkSizes,
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateDecorationLocationArtworkSizes(res));
  }
};

export const getDecorationLocationPersonalizationSizes =
  () => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUtilityUrls.decorationLocationPersonalizationSizes,
      { handleBlockingLoading: false }
    );
    const res = await makeApiCall(call);

    if (res) {
      dispatch(updateDecorationLocationPersonalizationSizes(res));
    }
  };

export const getProductVendorQuantities = (styleId) => async (dispatch) => {
  const call = catalogApi.get(
    productCatalogUrls.productVendorQuantitiesForStyle(styleId),
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateProductVendorQuantities(res));
  }
};

export const getProductVendorQuantitiesForColoredStyle =
  (coloredStyleId) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.productVendorQuantitiesForColoredStyle(coloredStyleId),
      { handleBlockingLoading: false }
    );
    const res = await makeApiCall(call);

    if (res) {
      dispatch(updateProductVendorQuantitiesForColoredStyle(res));
    }
  };

export const getProductVendorMappingsForSku = (sku) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.productVendorMappings(sku), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateProductVendorMappingsForSku(res));
  }
};

export const getVendorsList = (vendorIds) => async (dispatch) => {
  const vendors = [];

  const promises = vendorIds.map(async (vendorId) => {
    const call = catalogApi.get(productCatalogUtilityUrls.vendor(vendorId), {
      handleBlockingLoading: false,
    });
    const res = await makeApiCall(call);

    if (res) {
      vendors.push(res);
    }
  });

  await Promise.all(promises);

  dispatch(updateVendors(vendors));
};

export const getVendors =
  (pageNumber = 1, pageSize = 10, search = '') => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUtilityUrls.vendorsTable(pageNumber, pageSize, search),
      { handleBlockingLoading: false }
    );

    const res = await makeApiCall(call);

    if (res) {
      dispatch(
        updateVendorsTable({
          totalPages: res.totalPages,
          hasPreviousPage: res.hasPreviousPage,
          hasNextPage: res.hasNextPage,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          queue: res.items,
          totalCount: res.totalCount,
        })
      );
    }
  };

export const getAllVendors = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.vendorsAll, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateVendorNames(res));
  }
};

export const getVendor = (vendorCode) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.vendor(vendorCode), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateVendor(res));
  }
};

export const getVendorTimelines = (vendorCode) => async (dispatch) => {
  const call = catalogApi.get(
    productCatalogUtilityUrls.vendorTimelines(vendorCode),
    { handleBlockingLoading: false }
  );

  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateVendorTimelines(res));
  }
};

export const getSizes = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.sizes, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateSizes(res));
  }
};

export const getColorGroups = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.colorGroups, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateColorGroups(res));
  }
};

export const getAges = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.ages, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateAges(res));
  }
};

export const getGenders = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.genders, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateGenders(res));
  }
};

export const getColors = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUtilityUrls.colors, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateColors(res));
  }
};

export const getDecorationMethods = () => async (dispatch) => {
  const res = [];
  for (const [value] of Object.entries(decorationMethodEnum)) {
    res.push(value);
  }
  res.splice(res.indexOf('NA'), 1);

  dispatch(updateDecorationMethods(res));
};

export const fetchVendorSuggestions =
  (filter = '', limit = 10) => async (dispatch) => {
    await dispatch(getVendors(1, limit, filter));
  };

export const getStyleSuggestions =
  (filter = '', limit = 10) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.stylesTable(
        1,
        limit,
        [],
        [],
        false,
        [],
        [],
        [],
        [],
        [],
        '',
        '',
        '',
        '',
        filter
      ),
      { handleBlockingLoading: false }
    );

    const res = await makeApiCall(call);

    if (res && res.items) {
      dispatch(updateStyleSuggestions(res.items));
    }
  };

export const getStyleByIdFromSSAPI = (styleId) => async (dispatch) => {
  const call = slServicesApi.get(productCatalogUrls.style(styleId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateStyleFromSSAPI(res));
  }
};

export const getColoredStylesForStyleFromSSAPI =
  (styleId) => async (dispatch) => {
    const call = slServicesApi.get(
      productCatalogUrls.coloredStylesForStyle(styleId),
      { handleBlockingLoading: false }
    );
    const res = await makeApiCall(call);

    if (res) {
      dispatch(updateColoredStylesForStyleFromSSAPI(res));
    }
  };

export const getProductsForStyleFromSSAPI = (styleId) => async (dispatch) => {
  const call = slServicesApi.get(productCatalogUrls.productsForStyle(styleId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateProductsForStyleFromSSAPI(res));
  }
};

export const getDtgEnvironments =
  (pageNumber, pageSize, search = '') => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.dtgEnvironmentsPaged(pageNumber, pageSize, search),
      { handleBlockingLoading: false }
    );

    const res = await makeApiCall(call);

    if (res) {
      dispatch(updateDtgEnvironments(res));
    }
  };

export const getDtgEnvironmentOptions = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.dtgEnvironmentOptions, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgEnvironmentOptions(res));
  }
};

export const getDtgPalletOptions = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.dtgPalletOptions, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgPalletOptions(res));
  }
};

export const getDtgPretreatmentBatchOptions = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.dtgPretreatmentBatchOptions, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgPretreatmentBatchOptions(res));
  }
};

export const getDtgTabletProfileOptions = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.dtgTabletProfileOptions, {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgTabletProfileOptions(res));
  }
};

export const getSingleDtgColoredStyle =
  (coloredStyleId) => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.dtgColoredStyle({ coloredStyleId }),
      { handleBlockingLoading: true }
    );
    const res = await makeApiCall(call);
    const firstOrEmpty = (res && res.items && res.items[0]) || {};
    dispatch(updateSingleDtgColoredStyle(firstOrEmpty));
  };

export const getDtgArtworkSettings = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.dtgArtworkSettings(), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgArtworkSettings(res));
  }
};

export const getDtgPersonalizationSettings = () => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.dtgPersonalizationSettings(), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgPersonalizationSettings(res));
  }
};

export const getDtgTabletProfiles =
  (pageNumber, pageSize, search = '') => async (dispatch) => {
    const call = catalogApi.get(
      productCatalogUrls.dtgTabletProfilesPaged(pageNumber, pageSize, search),
      { handleBlockingLoading: false }
    );
    const res = await makeApiCall(call);
    if (res) {
      dispatch(updateDtgTabletProfiles(res));
    }
  };
