import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import {
  defaultPageSizeBigTable,
  defaultPageSizeSmallTable,
} from '@constants/values';
import {
  BackorderManagementTabEnum,
  TableEnum,
} from '@constants/enums/tableEnums';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import { assignmentStatusEnum } from '@constants/enums/artworkEnums';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import {
  adminsUrl,
  adminDetailsBaseUrl,
  artVectorizationUrl,
  artworkTasksUrl,
  backorderManagementUrl,
  lockerManagerUrl,
  organizationsUrl,
  organizationDetailsBaseUrl,
  organizationGroupsBaseUrl,
  orderDetailsBaseUrl,
  orderSearchUrl,
  printFilesUrl,
  printSheetBaseUrl,
  productCatalogUrl,
  rosterManagerUrl,
  rosterDetailsBaseUrl,
  voucherOrdersUrl,
  addVoucherOrderUrl,
  voucherOrderDetailsBaseUrl,
} from '@constants/clientUrls/clientUrls';
import {
  TableState,
  AdminsTableState,
  OrganizationTableState,
  OrderManagementTableState,
  RosterManagerTableState,
  LockerManagerTableState,
  ProductCatalogTableState,
  ProductionArtworkTableState,
  BackorderManagementTableState,
  PrintRoomTableState,
  VoucherOrdersTableState,
} from './models';
import * as actionTypes from './types';
import { TableManagerAction } from './actions';

const commonStateProps: TableState = {
  pageNumber: 1,
  pageSize: defaultPageSizeBigTable,
  sortColumn: '',
  sortDirection: '',
} as const;

type Tables = { [key in TableEnum]: TableState };
type TableScopes = { [key in TableEnum]: string[] };

const tableScopes: TableScopes = {
  [TableEnum.admins]: [
    adminsUrl,
    adminDetailsBaseUrl,
  ],
  [TableEnum.organizations]: [
    organizationsUrl,
    organizationDetailsBaseUrl,
    organizationGroupsBaseUrl,
  ],
  [TableEnum.orderManagement]: [
    orderSearchUrl,
    orderDetailsBaseUrl,
  ],
  [TableEnum.rosterManager]: [
    rosterManagerUrl,
    rosterDetailsBaseUrl,
  ],
  [TableEnum.lockerManager]: [lockerManagerUrl],
  [TableEnum.productCatalog]: [productCatalogUrl],
  [TableEnum.productionArtwork]: [
    artVectorizationUrl,
    artworkTasksUrl,
  ],
  [TableEnum.backorderManagement]: [
    backorderManagementUrl,
    orderDetailsBaseUrl,
  ],
  [TableEnum.printRoom]: [
    printFilesUrl,
    printSheetBaseUrl,
  ],
  [TableEnum.voucherOrders]: [
    voucherOrdersUrl,
    voucherOrderDetailsBaseUrl,
    addVoucherOrderUrl,
  ],
};

export const tableScopesList: [TableEnum, string[]][] = Object.entries(tableScopes)
  .map(([
    tableName,
    scope,
  ]) => [
    tableName as TableEnum,
    scope,
  ]);

export type TableManagerState = {
  admins: AdminsTableState;
  organizations: OrganizationTableState;
  orderManagement: OrderManagementTableState;
  rosterManager: RosterManagerTableState;
  lockerManager: LockerManagerTableState;
  productCatalog: ProductCatalogTableState;
  productionArtwork: ProductionArtworkTableState;
  backorderManagement: BackorderManagementTableState;
  printRoom: PrintRoomTableState;
  voucherOrders: VoucherOrdersTableState;
} & Tables;

export const initialState: TableManagerState = {
  admins: {
    ...commonStateProps,
    searchInput: '',
    dateRangeFilter: dateRangeEnum.all.value,
    accountExecutivesFilter: [],
  },
  organizations: {
    ...commonStateProps,
    searchInput: '',
    orgTypeFilter: '',
    sizeFilter: '',
    landingPageEnabled: '',
    accountExecutives: [],
  },
  orderManagement: {
    ...commonStateProps,
    pageSize: defaultPageSizeSmallTable,
    searchInput: '',
    lockerIdsFilter: '',
    dateRangeFilter: dateRangeEnum.past60Days.value,
    accountingSubmitStatusFilter: undefined,
    userIdFilter: '',
    onlyPrdOrdersFilter: false,
    onlyRequiredItemOrdersFilter: false,
  },
  rosterManager: {
    ...commonStateProps,
    pageSize: defaultPageSizeSmallTable,
    searchInput: '',
    disabledFilter: '',
  },
  lockerManager: {
    ...commonStateProps,
    pageSize: defaultPageSizeSmallTable,
    searchInput: '',
    statusesFilter: [],
    sportFilter: undefined,
    hasOrdersFilter: false,
  },
  productCatalog: {
    ...commonStateProps,
    searchInput: '',
    brandsFilter: [],
    categoriesFilter: [],
    agesFilter: [],
    gendersFilter: [],
    sportsFilter: [],
    colorsFilter: [],
    sizesFilter: [],
    recommendedFilter: null,
    availableForNewStoresFilter: null,
    customizableFilter: null,
    priceMinFilter: undefined,
    priceMaxFilter: undefined,
    vendorQuantityMinFilter: '0',
    websiteVisibiltiyFilter: undefined,
    vendorNamesFilter: [],
    decorationMethodsFilter: [],
  },
  productionArtwork: {
    ...commonStateProps,
    pageSize: defaultPageSizeSmallTable,
    sortColumn: 'shipDate',
    sortDirection: sortDirectionEnum.Asc,
    selectedTab: assignmentStatusEnum.Unassigned,
    decorationMethodFilter: '',
    resourceIdFilter: '',
    partnerNameFilter: '',
    hasParentLockerFilter: '',
  },
  backorderManagement: {
    ...commonStateProps,
    searchInput: '',
    selectedTab: BackorderManagementTabEnum.NeverNotified,
  },
  printRoom: {
    ...commonStateProps,
    pageSize: defaultPageSizeSmallTable,
    selectedTab: printStatusEnum.PrintReady,
    claimedByFilter: '',
    expressProductionFilter: null,
  },
  voucherOrders: {
    ...commonStateProps,
    pageSize: defaultPageSizeSmallTable,
    searchInput: '',
    minValidThrough: null,
    maxValidThrough: null,
  },
};

export default function tableManager(state = initialState, action: TableManagerAction): TableManagerState {
  switch (action.type) {
    case actionTypes.UPDATE_TABLE_STATE:
      const tableStateToUpdate = state[action.payload.tableName];

      return {
        ...state,
        [action.payload.tableName]: action.payload.tableState as typeof tableStateToUpdate,
      };
    case actionTypes.RESET_TABLE_STATE:
      const tableStateToReset = state[action.payload];

      return {
        ...state,
        [action.payload]: initialState[action.payload]!,
      };
    case actionTypes.UPDATE_TABLE_FILTERS:
      const tableState = state[action.payload.tableName];

      return {
        ...state,
        [action.payload.tableName]: {
          ...tableState,
          ...(action.payload.filters as typeof tableState),
          pageNumber: 1,
        },
      };
    default:
      return state;
  }
}
