import React from 'react';
import PropTypes from 'prop-types';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import { parseDateTimeNumeric } from '@util/dateHandler';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import TableQuickViewListItem from '@sharedComponents/Table/TableQuickView/TableQuickViewListItem';
import InfoField from '@sharedComponents/Display/InfoField';
import Button from '@sharedComponents/Buttons/Button';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';

const VendorQuickView = React.memo(({
  vendor,
  timelines,
  closeDetails,
  openEditVendorModal,
}) => (
  <TableQuickViewContainer
    noPadding={true}
    size={TableQuickViewSizeEnum.Medium}
  >
    <TableQuickViewHeader
      title={<span>{vendor.name || '-'}</span>}
      onClose={closeDetails}
    />
    <div className='tableView__details--content-container'>
      <div className='flex'>
        <InfoField
          label={'Code'}
          value={vendor.code}
          fieldClass={'w-50'}
        />
        <InfoField
          label={'Created'}
          value={parseDateTimeNumeric(vendor.created)}
          fieldClass={'w-50'}
        />
      </div>
      <div className='flex'>
        <InfoField
          label={'Updated'}
          value={vendor.updated && parseDateTimeNumeric(vendor.updated)}
          fieldClass={'w-50'}
        />
        <InfoField
          label={'Lead Time'}
          value={vendor.leadTime}
          fieldClass={'w-50'}
        />
      </div>
      <div className='flex'>
        <InfoField
          label={'Dropship VFIs'}
          value={vendor.dropshipVFIs ? 'Yes' : 'No'}
          fieldClass={'w-50'}
        />
        <InfoField
          label={'Personalization Name Length'}
          value={vendor.vfiMaxPersonalizationNameLength}
          fieldClass={'w-50'}
        />
      </div>
    </div>

    <TableQuickViewHeader title={<span>Automatic Order Submission</span>} />
    <div className='tableView__details--content-container'>
      <div className='flex'>
        <InfoField
          label={'Automatically Submit Orders'}
          value={vendor.vfiOrderAutoSubmit ? 'Yes' : 'No'}
        />
      </div>
      <div className='flex'>
        <InfoField
          label={'Count Threshold'}
          value={vendor.vfiOrderAutoSubmit ? vendor.vfiOrderTreshold : '-'}
          fieldClass={'w-50'}
        />
        <InfoField
          label={'Time Threshold (Hours)'}
          value={vendor.vfiOrderAutoSubmit ? vendor.vfiOrderTimeHours : '-'}
          fieldClass={'w-50 ml-15'}
        />
      </div>
    </div>

    <TableQuickViewHeader title={<span>Timeline Information</span>} />
    <div className='tableView__details--list vendors__quick-view__list'>
      {
        timelines.map((timeline) => (
          <TableQuickViewListItem
            key={timeline.description}
            text={`${timeline.description} (${timeline.timelineDays} business days)`}
          />
        ))
      }
    </div>

    <div className='align__bottom p-20'>
      <Button
        type={'primary'}
        text={'Edit Vendor'}
        onClick={openEditVendorModal.bind(this, vendor)}
        classes={'w-100'}
      />
    </div>
  </TableQuickViewContainer>
));

VendorQuickView.propTypes = {
  vendor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    updated: PropTypes.string,
    name: PropTypes.string,
    leadTime: PropTypes.number,
    dropshipVFIs: PropTypes.bool.isRequired,
    vfiOrderAutoSubmit: PropTypes.bool.isRequired,
    vfiOrderTreshold: PropTypes.number,
    vfiOrderTimeHours: PropTypes.number,
    vfiMaxPersonalizationNameLength: PropTypes.number,
  }).isRequired,
  timelines: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    timelineDays: PropTypes.number.isRequired,
  })).isRequired,
  closeDetails: PropTypes.func.isRequired,
  openEditVendorModal: PropTypes.func.isRequired,
};

export default VendorQuickView;
