import React from 'react';
import Modal from './Modal';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  imageUrl?: string;
  isCompact?: boolean;
}

type Props = OwnProps;

const ImageModal = React.memo(({
  isOpen,
  closeModal,
  imageUrl,
  isCompact = false,
}: Props) => (
  <Modal
    title={''}
    modalSize={'xl'}
    isOpen={isOpen}
    closeModal={closeModal}
    modalCloseBtnClass={'modal__close--tight'}
  >
    {
      isCompact
        ? (
          <div
            className='lockerManagerEdit__image'
            style={{ backgroundImage: `url("${imageUrl}")` }}
          />
        )
        : (
          <img
            className='modal_full-image'
            src={imageUrl}
          />
        )
    }
  </Modal>
));

export default ImageModal;
