import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { stopSubmit } from 'redux-form';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { createCustomOrderForm } from '@constants/reduxForms';
import { OrderManagementTempOrder } from '@redux/orderManagement/models';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import CustomOrderForm from '../OrderManagementForms/CustomOrderForm';
import { usePutHomefieldApiOrdersvalidateaddress } from '@api/orders/orders';
import { swalChoose } from '@util/componentHelper';
import { CustomOrderFormData } from '@models/forms/OrderManagement/CustomOrderFormData';
import { ValidateAddressQuery } from '@api/orders/models';
import { validationPassedMessage } from '@constants/values';
import { useSimpleConfirmationModal } from '@hooks/shared/Modal/useSimpleConfirmationModal';

interface OwnProps {
  isOpen: boolean;
  editOrder: () => void;
  initialValues: OrderManagementTempOrder;
  closeModal: () => void;
  setOrderForm: (form: Nullable<CustomOrderFormData>) => void;
}

const mapDispatchToProps = {
  stopFormSubmit: stopSubmit,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditCustomOrderModal = React.memo(({
  isOpen,
  editOrder,
  initialValues,
  closeModal,
  stopFormSubmit,
  setOrderForm,
}: Props) => {
  const [
    paymentIdVerified,
    setPaymentIdVerified,
  ] = useState<boolean>(true);
  const [
    sentAddressVerificationRequest,
    setSentAddressVerificationRequest,
  ] = useState<boolean>(false);

  const {
    openModal: openChooseToContinueModal,
    closeModal: closeChooseToContinueModal,
    isOpen: chooseToContinueModalIsOpen,
    ConfirmationModal: ChooseToContinueModal,
  } = useSimpleConfirmationModal();

  const {
    data: addressValidationResponse,
    mutateAsync: validateAddress,
  } = usePutHomefieldApiOrdersvalidateaddress(
    { request: { isBlockingRequest: true } }
  );

  useEffect(() => {
    if (sentAddressVerificationRequest && !!addressValidationResponse) {
      if (addressValidationResponse?.success && addressValidationResponse.message === validationPassedMessage) {
        editOrder();
        setSentAddressVerificationRequest(false);
      } else if (addressValidationResponse?.success && addressValidationResponse.message !== validationPassedMessage) {
        openChooseToContinueModal();
      }
    }
  }, [addressValidationResponse]);

  const validateAddressInput = useCallback((createOrderForm: CustomOrderFormData) => {
    const data = {
      addressLine1: createOrderForm.addressLine1,
      addressLine2: createOrderForm.addressLine2,
      city: createOrderForm.city,
      state: createOrderForm.state,
      country: createOrderForm.country,
      zipCode: createOrderForm.zip,
    } as ValidateAddressQuery;

    setOrderForm(createOrderForm);
    validateAddress({ data });
    setSentAddressVerificationRequest(true);
  }, [validateAddress]);

  const handleCloseModal = useCallback(async () => {
    closeModal();
    await stopFormSubmit(createCustomOrderForm, {});
  }, [
    stopFormSubmit,
    closeModal,
  ]);

  const setPaymentIdVerification = useCallback((verificaton: boolean) => {
    setPaymentIdVerified(verificaton);
  }, []);

  const confirmEdit = useCallback(() => {
    editOrder();
    closeChooseToContinueModal();
  }, [
    closeChooseToContinueModal,
    editOrder,
  ]);

  return (
    <div>
      <ChooseToContinueModal
        isOpen={chooseToContinueModalIsOpen}
        closeModal={closeChooseToContinueModal}
        title={'Do you want to continue?'}
        cancelBtnText={'Cancel'}
        confirm={confirmEdit}
        confirmationBody={(
          <span>
            {addressValidationResponse?.message}
          </span>
        )}
      />
      <Modal
        title={'Edit Custom Order'}
        isOpen={isOpen}
        modalSize={'l'}
        closeModal={handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onClose={handleCloseModal}
            formSubmission={true}
            formId={createCustomOrderForm}
            confirmBtnDisabled={!paymentIdVerified}
          />
        )}
      >
        <CustomOrderForm
          onSubmit={validateAddressInput}
          initialValues={initialValues}
          setPaymentIdVerification={setPaymentIdVerification}
        />
      </Modal>
    </div>

  );
});

export default connector(EditCustomOrderModal);
