import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import DropZoneWrapper from './DropZoneWrapper';
import Icon from '@sharedComponents/Icons/Icon';

const ImageDropzone = ({
  imageUrl, message, changeImage, maxWidth, acceptedFileTypes, size,
}) => {
  const [
    loading,
    setLoading,
  ] = useState(false);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    const image = new Image();
    const file = acceptedFiles[0];

    let dropMessage;
    setLoading(true);
    if (rejectedFiles.length > 0) {
      dropMessage = {
        type: 'reject',
        body: `${acceptedFileTypes} files required.`,
      };
      changeImage(null, dropMessage, null);
      setLoading(false);
    } else {
      reader.onloadend = () => {
        const { result } = reader;

        image.src = result;
        image.onload = () => {
          if (maxWidth && image.width > maxWidth) {
            dropMessage = {
              body: `Image width should be ${maxWidth}px or less.`,
              type: 'default',
            };
            changeImage(null, dropMessage, null);
            setLoading(false);
          } else {
            dropMessage = {
              body: `File ${acceptedFiles[0].name} has been attached.`,
              type: 'default',
            };
            changeImage(result, dropMessage, acceptedFiles[0], size);
            setLoading(false);
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const dropzoneContent = loading
    ? (
      <label className='button'>
        <Icon
          fontAwesomeIcon={'spinner'}
          classes={'fa-spin'}
        />
      Uploading...
      </label>
    )
    : <label className={`button message-${message.type}`}>{message.body}</label>;

  return (
    <Box>
      <Box
        border='solid'
        style={{ borderWidth: '1px' }}
        height='200px'
        width='200px'

      >
        {imageUrl &&
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <img
            src={imageUrl}
            style={{
              width: '200px',
              height: '200px',
            }}
          />
        </Box>
        }
        {!imageUrl &&
           <Box
             display='flex'
             height='100%'
             alignItems='center'
             justifyContent='center'
           >
             <DropZoneWrapper
               onDrop={onDrop}
               multiple={false}
               className={'block-drop-zone'}
               accept={'.svg'}
             >
               {dropzoneContent}
             </DropZoneWrapper>
           </Box>
        }
      </Box>
    </Box>
  );
};

export default ImageDropzone;
