import {
  OrderPricingDetailsDto,
  OrderDetailsDto as CoreOrderDetails,
} from '@api/orders/models';
import { OrderDetailsDto } from '@api/fulfillment/models';
import React from 'react';
import OrderManagementSidebarOrderInfo from './OrderManagementSidebarOrderInfo';
import OrderManagementSidebarTabs from './OrderManagementSidebarTabs';

interface OwnProps {
  order: OrderDetailsDto;
  fetchOrder: () => void;
  fetchCoreOrderPricingDetails: () => void;
  coreOrderPricingDetails: Nullable<OrderPricingDetailsDto>;
  orderExistsOnCore: boolean;
  coreOrder: CoreOrderDetails;
}

type Props = OwnProps;

const OrderManagementSidebar = React.memo<Props>(({
  fetchOrder,
  order,
  coreOrderPricingDetails,
  orderExistsOnCore,
  fetchCoreOrderPricingDetails,
  coreOrder,
}) => (
  <div className='order__sidebar'>
    <OrderManagementSidebarOrderInfo
      order={order}
      fetchOrder={fetchOrder}
      coreOrder={coreOrder}
    />
    <OrderManagementSidebarTabs
      order={order}
      fetchOrder={fetchOrder}
      coreOrderPricingDetails={coreOrderPricingDetails}
      orderExistsOnCore={orderExistsOnCore}
      fetchCoreOrderPricingDetails={fetchCoreOrderPricingDetails}
      coreOrder={coreOrder}
    />
  </div>
));

export default OrderManagementSidebar;
