import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';

class EditColoredStyleImageContainer extends PureComponent {
  render() {
    const {
      imageUrl,
      emptyImageText,
      openUploadContainer,
      removeImage,
    } = this.props;

    return (
      <div>
        {
          imageUrl
            ? (
              <div className='redux-form__column-image'>
                <div
                  style={{
                    height: '220px',
                    width: '220px',
                  }}
                  className='details__item-thumbnail details__item-thumbnail--color-transparent'
                >
                  <div
                    className='details__item-image'
                    style={{ backgroundImage: `url("${imageUrl}")` }}
                  />
                </div>
                <a
                  className='lockerManagerEdit__details-header--edit'
                  onClick={removeImage}
                >
                  <Icon materialIcon={'close'} />
                  <span className='uppercase'>Remove</span>
                </a>
              </div>
            )
            : (
              <div className='redux-form__column-image'>
                <div
                  style={{
                    height: '220px',
                    width: '220px',
                  }}
                  className='missing-image'
                >
                  <span className='missing-text'>{emptyImageText}</span>
                </div>
                <a
                  className='lockerManagerEdit__details-header--edit'
                  onClick={openUploadContainer}
                >
                  <Icon materialIcon={'swap_horiz'} />
                  <span className='uppercase'>Upload</span>
                </a>
              </div>
            )
        }
      </div>
    );
  }
}

EditColoredStyleImageContainer.propTypes = {
  imageUrl: PropTypes.string,
  emptyImageText: PropTypes.string,
  openUploadContainer: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
};

export default EditColoredStyleImageContainer;
