import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class HeaderCell extends PureComponent {
  render() {
    const { text } = this.props;

    return (
      <div className='flex__row button'>
        {text}
        <span className='margin margin--left-s'>
          <i className='material-icons sort'>arrow_upward</i>
        </span>
      </div>
    );
  }
}

HeaderCell.propTypes = {
  text: PropTypes.string.isRequired,
};

export default HeaderCell;
