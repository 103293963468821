/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  NotScheduledOrderDtoPagedList,
  GetHomefieldApiVendorordersNotscheduledParams,
  OrderItemDetailsDto,
  GetHomefieldApiVendorordersNotscheduledOrderNumberItemsParams,
  VendorOrderDtoPagedList,
  GetHomefieldApiVendorordersParams,
  ApiResponse,
  CreateVendorOrdersCommand,
  VendorOrderItemDto,
  VendorOrderSubmitResultDto,
  AddPONumberAndCancelVendorOrderCommand,
  VendorDesignIdAvailableSkusDto
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiVendorordersVendorsfilter = <TData = string[]>(
    
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/vendors-filter`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVendorordersVendorsfilterQueryKey = () => [`/homefield/api/vendor-orders/vendors-filter`]

    
export const useGetHomefieldApiVendorordersVendorsfilter = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVendorordersVendorsfilter, string[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVendorordersVendorsfilterQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVendorordersVendorsfilter<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVendorordersNotscheduled = <TData = NotScheduledOrderDtoPagedList>(
    params?: GetHomefieldApiVendorordersNotscheduledParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/not-scheduled`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVendorordersNotscheduledQueryKey = (params?: GetHomefieldApiVendorordersNotscheduledParams,) => [`/homefield/api/vendor-orders/not-scheduled`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVendorordersNotscheduled = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVendorordersNotscheduled, NotScheduledOrderDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiVendorordersNotscheduledParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVendorordersNotscheduledQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVendorordersNotscheduled<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVendorordersNotscheduledOrderNumberItems = <TData = OrderItemDetailsDto[]>(
    orderNumber: number,
    params?: GetHomefieldApiVendorordersNotscheduledOrderNumberItemsParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/not-scheduled/${orderNumber}/items`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVendorordersNotscheduledOrderNumberItemsQueryKey = (orderNumber: number,
    params?: GetHomefieldApiVendorordersNotscheduledOrderNumberItemsParams,) => [`/homefield/api/vendor-orders/not-scheduled/${orderNumber}/items`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVendorordersNotscheduledOrderNumberItems = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVendorordersNotscheduledOrderNumberItems, OrderItemDetailsDto[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number,
    params?: GetHomefieldApiVendorordersNotscheduledOrderNumberItemsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVendorordersNotscheduledOrderNumberItemsQueryKey(orderNumber,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVendorordersNotscheduledOrderNumberItems<TQueryFnData>(orderNumber,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVendororders = <TData = VendorOrderDtoPagedList>(
    params?: GetHomefieldApiVendorordersParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVendorordersQueryKey = (params?: GetHomefieldApiVendorordersParams,) => [`/homefield/api/vendor-orders`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVendororders = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVendororders, VendorOrderDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiVendorordersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVendorordersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVendororders<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiVendororders = <TData = ApiResponse>(
    createVendorOrdersCommand: CreateVendorOrdersCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders`, method: 'post',
      data: createVendorOrdersCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVendororders = <TData = AsyncReturnType<typeof postHomefieldApiVendororders,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateVendorOrdersCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateVendorOrdersCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVendororders<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiVendorordersIdItems = <TData = VendorOrderItemDto[]>(
    id: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/${id}/items`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVendorordersIdItemsQueryKey = (id: number,) => [`/homefield/api/vendor-orders/${id}/items`]

    
export const useGetHomefieldApiVendorordersIdItems = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVendorordersIdItems, VendorOrderItemDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVendorordersIdItemsQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVendorordersIdItems<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVendorordersIdSubmitresults = <TData = VendorOrderSubmitResultDto[]>(
    id: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/${id}/submit-results`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVendorordersIdSubmitresultsQueryKey = (id: number,) => [`/homefield/api/vendor-orders/${id}/submit-results`]

    
export const useGetHomefieldApiVendorordersIdSubmitresults = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVendorordersIdSubmitresults, VendorOrderSubmitResultDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVendorordersIdSubmitresultsQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVendorordersIdSubmitresults<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiVendorordersIdresubmit = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/${id}resubmit`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiVendorordersIdresubmit = <TData = AsyncReturnType<typeof putHomefieldApiVendorordersIdresubmit,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  putHomefieldApiVendorordersIdresubmit<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiVendorordersIdcancel = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/${id}cancel`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiVendorordersIdcancel = <TData = AsyncReturnType<typeof putHomefieldApiVendorordersIdcancel,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  putHomefieldApiVendorordersIdcancel<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiVendorordersAddpoandcancel = <TData = ApiResponse>(
    addPONumberAndCancelVendorOrderCommand: AddPONumberAndCancelVendorOrderCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/add-po-and-cancel`, method: 'post',
      data: addPONumberAndCancelVendorOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiVendorordersAddpoandcancel = <TData = AsyncReturnType<typeof postHomefieldApiVendorordersAddpoandcancel,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: AddPONumberAndCancelVendorOrderCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: AddPONumberAndCancelVendorOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiVendorordersAddpoandcancel<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiVendorordersVendorsVendorCodeDesignidsVendorDesignIdAvailableskus = <TData = VendorDesignIdAvailableSkusDto>(
    vendorCode: string,
    vendorDesignId: string,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/vendor-orders/vendors/${vendorCode}/design-ids/${vendorDesignId}/available-skus`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVendorordersVendorsVendorCodeDesignidsVendorDesignIdAvailableskusQueryKey = (vendorCode: string,
    vendorDesignId: string,) => [`/homefield/api/vendor-orders/vendors/${vendorCode}/design-ids/${vendorDesignId}/available-skus`]

    
export const useGetHomefieldApiVendorordersVendorsVendorCodeDesignidsVendorDesignIdAvailableskus = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVendorordersVendorsVendorCodeDesignidsVendorDesignIdAvailableskus, VendorDesignIdAvailableSkusDto>, TError = unknown, TData = TQueryFnData>(
 vendorCode: string,
    vendorDesignId: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVendorordersVendorsVendorCodeDesignidsVendorDesignIdAvailableskusQueryKey(vendorCode,vendorDesignId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVendorordersVendorsVendorCodeDesignidsVendorDesignIdAvailableskus<TQueryFnData>(vendorCode,vendorDesignId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

