/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PtlWallBinSizeEnum = 'Undefined' | 'Small' | 'Medium' | 'Large';


export const PtlWallBinSizeEnum = {
  Undefined: 'Undefined' as PtlWallBinSizeEnum,
  Small: 'Small' as PtlWallBinSizeEnum,
  Medium: 'Medium' as PtlWallBinSizeEnum,
  Large: 'Large' as PtlWallBinSizeEnum,
};
