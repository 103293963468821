import React from 'react';
import PropTypes from 'prop-types';
import ArtworkTaskFile from './ArtworkTaskFile';

const ArtworkTaskUploadedFiles = ({ files }) => {
  const filesList = files
    ? files.map((file, key) => (
      <ArtworkTaskFile
        fileName={file.fileName}
        fileUrl={file.storageUrl}
        key={key}
        keyProp={key}
      />
    ))
    : '';

  return (
    <div className='uploads__list'>
      {filesList}
    </div>
  );
};

ArtworkTaskUploadedFiles.propTypes = {
  files: PropTypes.any,
};

export default ArtworkTaskUploadedFiles;
