import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const InvalidDesignIdModal = React.memo(({
  isOpen,
  closeModal,
}) => (
  <Modal
    title={'Invalid Design ID'}
    modalHeight={'m'}
    modalWidth={'s'}
    isOpen={isOpen}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        displayConfirmBtn={false}
        cancelBtnText={'OK'}
        onClose={closeModal}
      />
    )}
  >
    <div className='lockerManager__delete-modal--content'>
      The Design ID you submitted could not be validated.
      The custom item <b>will remain hidden until a valid Design ID is entered</b>.
      All other custom item data has been saved.
    </div>
  </Modal>
));

InvalidDesignIdModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default InvalidDesignIdModal;
