import React from 'react';
import {
  PtlWallRowPositionDto,
  PtlWallRowDto,
} from '@api/fulfillment/models';
import PtlWallRowPosition from './PtlWallRowPosition';

interface OwnProps {
  row: PtlWallRowDto;
}

export const PtlWallRow = React.memo<OwnProps>(({ row }) => {
  const renderWallRowPosition = (rp: PtlWallRowPositionDto) => (
    <PtlWallRowPosition
      key={rp.id}
      rowPosition={rp}
    />
  );

  return (
    <div className='ptl-wall-row flex'>
      <div
        className='ptl-wall-row__device-card'
        style={{ borderTop: '5px solid white' }}
      >
        <div className='ptl-wall-row__device-card__edit-link'>
          <h3 className='font-weight-bold'>{row?.deviceId}</h3>
        </div>
      </div>

      {row?.ptlWallRowPositions?.map(renderWallRowPosition)}
    </div>
  );
});

export default PtlWallRow;
