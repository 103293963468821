import React from 'react';
import iconQualityCheck from '@assets/icon_quality_check.svg';
import MenuTile from '@components/MyApps/MenuTile';
import { qualityCheckUrl } from '@constants/clientUrls/clientUrls';

const QualityCheckLink = React.memo(() => (
  <MenuTile
    title={'Quality Check'}
    url={qualityCheckUrl}
    icon={iconQualityCheck}
    isCustomIcon={true}
  />
));

export default QualityCheckLink;
