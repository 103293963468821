import React from 'react';
import {
  sortDirectionEnum,
  sortDirectionShortEnum,
} from '@constants/enums/commonEnums';
import { SortDirection } from '@customTypes/table';
import {
  Column as ReactColumn, CellInfo as ReactCellInfo,
} from 'react-table-6';

export const getPagingParamsFromTable = (instance: any): {
  page: number;
  pageSize: number;
  sorted: {
    id: string;
    desc: boolean;
  }[];
} => {
  const {
    page,
    pageSize,
    sorted,
  } = instance.state;

  return ({
    page,
    pageSize,
    sorted,
  });
};

export const getSortParamsFromTable = <T extends SortDirection = SortDirection>(
  instance: any,
  sortDirectionEnumeration: (typeof sortDirectionShortEnum | typeof sortDirectionEnum) = sortDirectionEnum,
  defaultSortColumn: string = '',
  defaultSortDirection: T = ('' as T)
): {
  sortColumn: string;
  sortDirection: T;
} => {
  const { sorted } = instance.state;
  let sortColumn = defaultSortColumn;
  let sortDirection: T = defaultSortDirection;

  if (sorted[0]) {
    sortColumn = sorted[0].id;
    sortDirection = (sorted[0].desc ? sortDirectionEnumeration.Desc : sortDirectionEnumeration.Asc) as T;
  }

  return ({
    sortColumn,
    sortDirection,
  });
};

/**
 * Argument object for *Cell* callback for **React Table**.
 * **React Table** types *original* and *value* as the *any* type.
 * Add types to avoid runtime mistakes.
 * @see {@link ReactCellInfo}
 * @template T - Single DTO representing row
 * @template K - Accessor Key into DTO if defined
 */
export type CellInfo<T, K extends keyof T | undefined = undefined> = Omit<ReactCellInfo, 'original' | 'value'>
& {
  // Type as the DTO representing the entire row
  original: T;
  value: K extends keyof T
    // If K is defined, react table will provide the value as whatever is accessed by K
    ? T[K]
    // If K is not defined, react table will provide the value to be the same as T
    : T;
};

type MaybeCellType<T, K extends keyof T | undefined = undefined> = K extends keyof T
  // If an accessor key K is applied, a cell callback may not be required
  ? (
      T[K] extends React.ReactText | null | undefined
      // If the type of T[K] is displayable or empty, the cell callback is optional
      ? {
        Cell?: (cellProps: CellInfo<T, K>) => JSX.Element;
      }
      // Otherwise require a cell callback to transform the data value to be displayed
      : {
        Cell: (cellProps: CellInfo<T, K>) => JSX.Element;
      }
    )
  // Otherwise a cell callback is required
  : {
    Cell: (cellProps: CellInfo<T, K>) => JSX.Element;
  };

type AccessedColumn<T, K extends keyof T = keyof T> = {
  accessor: K;
}
& MaybeCellType<T, K>;

type UnaccessedColumn<T> = {
  accessor: '';
}
& MaybeCellType<T>;

/**
 * Typed column configuration for **React Table**.
 * **React Table** types *original* and *value* as the *any* type.
 * Add types to avoid runtime mistakes.
 * @see {@link ReactColumn}
 * @template T - Single DTO representing row
 * @template K - Accessor Keys into DTO
 */
export type Column<T, K extends keyof T = keyof T> = Omit<ReactColumn<T>, 'accessor' | 'Cell'>
& (
  AccessedColumn<T, K>
  | UnaccessedColumn<T>
  );
