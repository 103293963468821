import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  isExpanded: boolean;
  toggle: () => void;
  hideIcon?: boolean;
  collapseText?: string;
  expandText?: string;
  classes?: string;
}

type Props = OwnProps;

const Expander = React.memo(({
  isExpanded,
  toggle,
  hideIcon = false,
  collapseText = 'Collapse',
  expandText = 'Expand',
  classes = '',
}: Props) => (
  <div
    className={`lockerManagerEdit__details-header--expander ${classes}`}
    onClick={toggle}
  >
    {
      isExpanded
        ? (
          <div className='button flex align__center'>
            {
              !hideIcon &&
              <Icon materialIcon={'arrow_drop_up'} />
            }
            {collapseText}
          </div>
        )
        : (
          <div className='button flex align__center'>
            {
              !hideIcon &&
              <Icon materialIcon={'arrow_drop_down'} />
            }
            {expandText}
          </div>
        )
    }
  </div>
));

export default Expander;
