import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import InfoField from '@sharedComponents/Display/InfoField';

class ItemInfoMockupsInformation extends PureComponent {
  render() {
    const {
      images = [],
      openModalWithImage,
    } = this.props;

    return (
      <div className='orderManagement__order-item-modal--container orderManagement__modal-delimiter'>
        <div className='flex'>
          <InfoField
            label={'Images'}
            value={(
              <div className='flex mt-5'>
                {
                  images.map((i) => (
                    <div
                      className='mr-10'
                      key={i}
                    >
                      <ImagePreviewCell
                        imageUrl={i}
                        openModalWithUrl={openModalWithImage.bind(null, i)}
                        height={'200px'}
                        width={'200px'}
                      />
                    </div>
                  ))
                }
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

ItemInfoMockupsInformation.propTypes = {
  openModalWithImage: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
};

export default ItemInfoMockupsInformation;
