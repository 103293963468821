import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import { parentCategoryForm } from '@constants/reduxForms';
import ParentCategoryForm from '../ParentCategoryForm';

const ParentCategoryAddModal = React.memo((
  {
    isOpen,
    onSubmit,
    closeModal,
  }
) => (
  <Modal
    title={'Add Parent Category'}
    isOpen={isOpen}
    modalSize={'l'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Add'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={parentCategoryForm}
      />
    )}
  >
    <ParentCategoryForm
      onSubmit={onSubmit}
    />
  </Modal>
));

ParentCategoryAddModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ParentCategoryAddModal;
