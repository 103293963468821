import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  setOrganizationGroupFeatured,
  removeOrganizationGroup,
  updateFeaturedLockersForOrganization,
  reorderOrganizationGroupLockers,
} from '@APICalls/organizations/actions';
import {
  minOrganizationFeaturedLockers,
  maxOrganizationFeaturedLockers,
} from '@constants/values';
import {
  addOrganizationGroupForm,
  editOrganizationGroupForm,
} from '@constants/reduxForms';
import { organizationDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  fetchOrganization,
  fetchOrganizationGroups,
  fetchOrganizationLockers,
  fetchOrganizationGroupLockers,
  fetchOrganizationFeaturedLockers,
  createOrganizationGroup,
  editOrganizationGroup,
} from '@redux/organizations/actions';
import { materialSwal } from '@util/componentHelper';
import { extractParameterFromPath } from '@util/stringHelpers';
import BackLink from '@sharedComponents/Navigation/BackLink';
import Button from '@sharedComponents/Buttons/Button';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import AddOrganizationGroupModal from './OrganizationGroupsModals/AddOrganizationGroupModal';
import EditOrganizationGroupModal from './OrganizationGroupsModals/EditOrganizationGroupModal';
import EditFeaturedLockersModal from './OrganizationGroupsModals/EditFeaturedLockersModal';
import OrganizationFeaturedLockers from './OrganizationFeaturedLockers';
import OrganizationGroup from './OrganizationGroup';

class OrganizationGroups extends Component {
  state = {
    addGroupModalIsOpen: false,
    editGroupModalIsOpen: false,
    editFeaturedLockersModalIsOpen: false,
    selectedGroup: null,
    selectedGroupLockers: [],
    confirmationModalIsOpen: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    dispatch(fetchOrganization(organizationId));
    dispatch(fetchOrganizationLockers(organizationId));
    dispatch(fetchOrganizationFeaturedLockers(organizationId));
    dispatch(fetchOrganizationGroups(organizationId));
  }

  getOrganizationIdFromPath = () => extractParameterFromPath(this.props, 'organizationId', 'number');

  openConfirmationModal = (selectedGroup) => {
    this.setState(() => ({
      selectedGroup,
      confirmationModalIsOpen: true,
    }));
  };

  closeConfirmationModal = () => {
    this.setState(() => ({
      selectedGroup: null,
      confirmationModalIsOpen: false,
    }));
  };

  openAddGroupModal = () => {
    this.setState(() => ({ addGroupModalIsOpen: true }));
  };

  closeAddGroupModal = async () => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    await dispatch(reset(addOrganizationGroupForm));
    await dispatch(fetchOrganizationLockers(organizationId));
    this.setState(() => ({ addGroupModalIsOpen: false }));
  };

  openEditGroupModal = (selectedGroup, selectedGroupLockers) => {
    this.setState(() => ({
      selectedGroup,
      selectedGroupLockers,
      editGroupModalIsOpen: true,
    }));
  };

  closeEditGroupModal = () => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    dispatch(reset(editOrganizationGroupForm));
    dispatch(fetchOrganizationLockers(organizationId));
    this.setState(() => ({ editGroupModalIsOpen: false }));
  };

  openEditFeaturedLockersModal = () => {
    this.setState(() => ({ editFeaturedLockersModalIsOpen: true }));
  };

  closeEditFeaturedLockersModal = async () => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    this.setState(() => ({ editFeaturedLockersModalIsOpen: false }));
    await dispatch(fetchOrganizationLockers(organizationId));
  };

  addGroup = async (groupForm) => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    const res = await dispatch(createOrganizationGroup(organizationId, groupForm));
    await dispatch(fetchOrganizationGroups(organizationId));
    materialSwal('Success', res.message, 'success');
    this.closeAddGroupModal();
  };

  editGroup = async (groupForm) => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    const res = await dispatch(editOrganizationGroup(groupForm));
    await dispatch(fetchOrganizationGroups(organizationId));
    await dispatch(fetchOrganizationGroupLockers(groupForm.id));
    materialSwal('Success', res.message, 'success');
    this.closeEditGroupModal();
  };

  deleteGroup = async (group) => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    try {
      const res = await removeOrganizationGroup(group.id);
      await dispatch(fetchOrganizationGroups(organizationId));
      materialSwal('Success', res.message, 'success');
    } catch (err) {
      materialSwal('Error', err.message, 'error');
    }
  };

  updateFeaturedLockers = async (lockers) => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    const res = await dispatch(updateFeaturedLockersForOrganization(organizationId, lockers));
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }
    await dispatch(fetchOrganizationFeaturedLockers(organizationId));
    this.closeEditFeaturedLockersModal();
  };

  reorderFeaturedLockers = async (lockers) => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    await dispatch(updateFeaturedLockersForOrganization(organizationId, lockers));
    await dispatch(fetchOrganizationFeaturedLockers(organizationId));
  };

  reorderGroupedLockers = async (group, lockers) => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath();

    await dispatch(reorderOrganizationGroupLockers(group.id, lockers));
    await dispatch(fetchOrganizationGroups(organizationId));
    await dispatch(fetchOrganizationGroupLockers(group.id));
  };

  setGroupAsFeatured = async () => {
    const { dispatch } = this.props;
    const { selectedGroup } = this.state;
    const organizationId = this.getOrganizationIdFromPath();
    const successMessage = selectedGroup.featured
      ? 'Group has been hidden.'
      : 'Group has been set as featured.';

    const res = await setOrganizationGroupFeatured(selectedGroup.id, !selectedGroup.featured);
    if (res?.success) {
      materialSwal('Success', successMessage, 'success');
    }

    await dispatch(fetchOrganizationGroups(organizationId));
    this.closeConfirmationModal();
  };

  render() {
    const {
      groups,
      featuredLockers,
    } = this.props;

    const {
      addGroupModalIsOpen,
      editGroupModalIsOpen,
      editFeaturedLockersModalIsOpen,
      selectedGroup,
      selectedGroupLockers,
      confirmationModalIsOpen,
    } = this.state;

    const organizationId = this.getOrganizationIdFromPath();

    let featureConfirmationMessage = '';
    if (selectedGroup) {
      featureConfirmationMessage = selectedGroup.featured
        ? <span>Are you sure you want to hide the <b>{selectedGroup.name}</b> group?</span>
        : <span>Are you sure you want to set <b>{selectedGroup.name}</b> as a featured group?</span>;
    }

    const emptyFeaturedLockerPlaces = [];

    if (featuredLockers.length < minOrganizationFeaturedLockers) {
      const requiredLockersCount = minOrganizationFeaturedLockers - featuredLockers.length;

      for (let i = 0; i < requiredLockersCount; i++) {
        emptyFeaturedLockerPlaces.push(
          <div
            key={`r${i}`}
            onClick={this.openEditFeaturedLockersModal}
            className={`organization__locker-card featured empty ${featuredLockers.length > 0 ? 'required' : ''}`}
          >
            Empty
          </div>
        );
      }
    }

    const emptyLockersCount = maxOrganizationFeaturedLockers
      - Math.max(minOrganizationFeaturedLockers, featuredLockers.length);

    for (let i = 0; i < emptyLockersCount; i++) {
      emptyFeaturedLockerPlaces.push(
        <div
          key={`e${i}`}
          onClick={this.openEditFeaturedLockersModal}
          className='organization__locker-card featured empty'
        >
          Empty
        </div>
      );
    }

    return (
      <>
        <div className='container'>
          <AddOrganizationGroupModal
            isOpen={addGroupModalIsOpen}
            closeModal={this.closeAddGroupModal}
            onSubmit={this.addGroup}
            organizationId={organizationId}
          />
          <EditOrganizationGroupModal
            isOpen={editGroupModalIsOpen}
            closeModal={this.closeEditGroupModal}
            onSubmit={this.editGroup}
            organizationId={organizationId}
            initialValues={{
              ...selectedGroup,
              lockers: selectedGroupLockers,
            }}
          />
          <EditFeaturedLockersModal
            isOpen={editFeaturedLockersModalIsOpen}
            closeModal={this.closeEditFeaturedLockersModal}
            updateFeaturedLockers={this.updateFeaturedLockers}
            organizationId={organizationId}
            featuredLockers={featuredLockers}
          />
          <div className='navigation'>
            <BackLink
              text={'Organization Details'}
              url={organizationDetailsUrl(organizationId)}
            />
            <div className='flex'>
              <div className='lockerManagerEdit__actions'>
                <Button
                  type={'primary'}
                  text={'Add Store Group'}
                  onClick={this.openAddGroupModal}
                />
              </div>
            </div>
          </div>

          <div className='organization__featured-lockers--description mb-30 mt-5'>
            Select&nbsp;
            <b>at least two individual stores&nbsp;</b>
            to have them featured on the organization landing page (optional).
          </div>

          <div className='title-separator'>
            <hr />
            <div className='title-separator--text'>Featured Stores</div>
            <hr />
            <div
              onClick={this.openEditFeaturedLockersModal}
              className='organization-group__icon--edit ml-10'
            />
          </div>

          <div className='mb-50'>
            <OrganizationFeaturedLockers
              featuredLockers={featuredLockers}
              emptyFeaturedLockerPlaces={emptyFeaturedLockerPlaces}
              reorderFeaturedLockers={this.reorderFeaturedLockers}
            />
          </div>

          <div className='organization__featured-lockers--description mb-30'>
            Multiple groups of stores (ex. Fall Sports) can be featured on the organization landing page (optional).
          </div>

          {
            groups.map((group) => (
              <OrganizationGroup
                key={group.id}
                group={group}
                setAsFeatured={this.openConfirmationModal}
                openEditGroupModal={this.openEditGroupModal}
                deleteGroup={this.deleteGroup}
                reorderGroupedLockers={this.reorderGroupedLockers}
              />
            ))
          }

        </div>

        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.setGroupAsFeatured}
          closeModal={this.closeConfirmationModal}
          title={'Set Featured Group'}
          confirmationBody={featureConfirmationMessage}
        />
      </>
    );
  }
}

OrganizationGroups.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
    }).isRequired,
  }),
  featuredLockers: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
};

const mapStateToProps = ({ organizations }) => ({
  featuredLockers: organizations.featuredLockers,
  groups: organizations.groups,
});

export default connect(mapStateToProps)(OrganizationGroups);
