import React, { useCallback } from 'react';
import { Permission } from '@customTypes/auth';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

export interface ShowMoreActionsDropdownActionData {
  text: JSX.Element | string;
  action: (e: React.MouseEvent) => void;
  isDisabled?: boolean;
  isDangerous?: boolean;
  isVisible?: boolean;
  hasBottomBorder?: boolean;
  className?: string;
  buttonSize?: 's';
  permissions?: Permission[] | Permission;
  tooltipText?: JSX.Element | string;
}

type OwnProps = ShowMoreActionsDropdownActionData;

type Props = OwnProps;

const ShowMoreActionsDropdownAction = React.memo<Props>(({
  text,
  action,
  isDisabled = false,
  isDangerous = false,
  isVisible = true,
  hasBottomBorder = true,
  className = '',
  buttonSize = '',
  permissions,
  tooltipText,
}) => {
  const onClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isDisabled) {
      action(e);
    }
  }, [
    isDisabled,
    action,
  ]);

  const actionItem = (
    <>
      <li
        className={`show-more-actions__item ${className}`}
        onClick={onClick}
        onMouseDown={onClick}
      >
        {
          tooltipText
            ? (
              <MaterialTooltip
                tooltipText={tooltipText}
                placement='bottom'
              >
                <button
                  type='button'
                  className={`tooltip-action tooltip-action--size-${buttonSize} ${isDangerous ? 'red' : ''} ${isDisabled ? 'disabled' : ''}`}
                >
                  {text}
                </button>
              </MaterialTooltip>
            )
            : (
              <button
                type='button'
                disabled={isDisabled}
                className={`tooltip-action tooltip-action--size-${buttonSize} ${isDangerous ? 'red' : ''}`}
              >
                {text}
              </button>
            )
        }
        {
          hasBottomBorder &&
          <span className='lockerManager__item-list--border' />
        }
      </li>
    </>
  );

  return (
    isVisible
      ? permissions && permissions.length > 0
        ? (
          <PermissionHandler permissions={permissions}>
            {actionItem}
          </PermissionHandler>
        )
        : actionItem
      : null
  );
});

export default ShowMoreActionsDropdownAction;
