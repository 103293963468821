import React, { useCallback } from 'react';
import {
  OrderManagementTempItemPersonalization,
  OrderManagementTempItem,
} from '@redux/orderManagement/models';

interface OwnProps {
  item: OrderManagementTempItem;
  personalization: OrderManagementTempItemPersonalization;
  addPersonalization: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
}

type Props = OwnProps;

const PersonalizationPlaceholder = React.memo(({
  item,
  personalization,
  addPersonalization,
}: Props) => {
  const handleAddPersonalization = useCallback(() => {
    addPersonalization(item, personalization);
  }, [
    addPersonalization,
    item,
    personalization,
  ]);

  return (
    <div
      className='new-order__order-items__personalization-placeholder'
      onClick={handleAddPersonalization}
    >
      <div>Add personalization</div>
      <div>({personalization.decorationLocation})</div>
      {
        personalization.required &&
        <div>
          Required*
        </div>
      }
    </div>
  );
});

export default PersonalizationPlaceholder;
