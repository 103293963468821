import React from 'react';
import CollectionItemsList from '../CollectionItemsList';

interface OwnProps {
  change: (name: string, value: any) => void;
}

type Props = OwnProps;

const CollectionFormItemsTab = React.memo<Props>(({ change }) => (
  <CollectionItemsList
    change={change}
  />
));

export default CollectionFormItemsTab;
