import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { imageLocationsEnum } from '@constants/enums/commonEnums';
import CustomItemImages from '@sharedComponents/CustomItems/CustomItemImages';

const imageNamesMapping = {
  [imageLocationsEnum.Front]: 'imageFront',
  [imageLocationsEnum.Back]: 'imageBack',
  [imageLocationsEnum.Left]: 'imageLeft',
  [imageLocationsEnum.Right]: 'imageRight',
};

class CustomItemImagesSection extends PureComponent {
  changeImage = (imageName, url, file) => {
    const {
      change,
      updateAttachedImage,
    } = this.props;
    change(`${imageNamesMapping[imageName]}Attachment`, file);

    if (!file) {
      change(imageNamesMapping[imageName], '');
    }

    if (updateAttachedImage) {
      updateAttachedImage(imageName, url);
    }
  };

  render() {
    const {
      isCustomItem = true,
      imageFront,
      imageBack,
      imageLeft,
      imageRight,
      imageFrontAttachmentUrl,
      imageBackAttachmentUrl,
      imageLeftAttachmentUrl,
      imageRightAttachmentUrl,
    } = this.props;

    return (
      <div className='lockerManagerEdit__custom-items--images'>
        <CustomItemImages
          isCustomItem={isCustomItem}
          imageFront={imageFront}
          imageBack={imageBack}
          imageLeft={imageLeft}
          imageRight={imageRight}
          changeImage={this.changeImage}
          imageFrontAttachmentUrl={imageFrontAttachmentUrl}
          imageBackAttachmentUrl={imageBackAttachmentUrl}
          imageLeftAttachmentUrl={imageLeftAttachmentUrl}
          imageRightAttachmentUrl={imageRightAttachmentUrl}
        />
      </div>
    );
  }
}

CustomItemImagesSection.propTypes = {
  imageFront: PropTypes.string,
  imageBack: PropTypes.string,
  imageRight: PropTypes.string,
  imageLeft: PropTypes.string,
  change: PropTypes.func.isRequired,
};

export default CustomItemImagesSection;
