import React from 'react';
import PropTypes from 'prop-types';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import ItemImage from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemImage';

const CollectionItemsInfoList = React.memo(({ items }) => (
  <div className='collections-form__items custom-scrollbar'>
    {
      items.map((item) => (
        <div
          key={item.id}
          className='collections-form__item align__center'
        >
          <div className='ml-20 mr-40'>
            <ItemImage item={item} />
          </div>
          <div>
            <TitleDescriptionCell item={item} />
          </div>
        </div>
      ))
    }
  </div>
));

CollectionItemsInfoList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CollectionItemsInfoList;
