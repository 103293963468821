import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ChangeLogItem from '@components/shared/ChangeLog/ChangeLogItem';

const LockerChangeLogsModal = React.memo(({
  isOpen,
  closeModal,
  lockerChangeLogs,
}) => (
  <Modal
    title={'Change Log'}
    modalSize={'l'}
    isOpen={isOpen}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        displayConfirmBtn={false}
      />
    )}
  >
    <div>
      {
        lockerChangeLogs && lockerChangeLogs.length > 0
          ? (
            lockerChangeLogs.map((log, index) => (
              <ChangeLogItem
                key={index}
                dateTime={log.changedOn}
                message={`${log.changedBy} changed ${log.property} from ${log.oldValue} to ${log.newValue}.`}
              />
            ))
          )
          : <span>No tracked changes.</span>
      }
    </div>
  </Modal>
));

LockerChangeLogsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  lockerChangeLogs: PropTypes.arrayOf(PropTypes.shape({
    changedOn: PropTypes.string.isRequired,
    changedBy: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    oldValue: PropTypes.string.isRequired,
    newValue: PropTypes.string.isRequired,
  })),
};

export default LockerChangeLogsModal;
