import React from 'react';
import NoteAttachment from './NoteAttachment';

interface OwnProps {
  fileName: Nullable<string>;
  fileUrl: Nullable<string>;
}

type Props = OwnProps;

const NoteBottom = React.memo<Props>(({
  fileName,
  fileUrl,
}) => (
  <>
    {
      fileUrl &&
    (
      <NoteAttachment
        fileUrl={fileUrl}
        fileName={fileName!}
      />
    )
    }
  </>
));

export default NoteBottom;
