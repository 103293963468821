import React from 'react';
import PropTypes from 'prop-types';
import { fileNameFromUrlWithExtension } from '@util/stringHelpers';
import Icon from '@sharedComponents/Icons/Icon';

const PrintSheetFileDownloadItem = ({
  job, keyId,
}) => {
  if (!job.resultUrls || job.resultUrls.length === 0) {
    return <div key={keyId} />;
  }
  const warnings = job.warningMessages.map((obj) => obj.message).join('\n');
  const items = job.resultUrls.map((url, key) => {
    const fileName = fileNameFromUrlWithExtension(url);

    return (
      <li key={`${keyId}-${key}`}>
        <a
          className='sheet__files-list-link'
          href={url}
        >
          {fileName}
        </a>
        {
          warnings && (
            <div className='tooltip-container'>
              <span className='sheet__files-list-warning margin margin--left-s'>
                <Icon materialIcon={'warning'} />
              </span>
              <div className='tooltip-text'>{warnings}</div>
            </div>
          )
        }
      </li>
    );
  });

  return (
    <div>
      {items}
    </div>
  );
};

PrintSheetFileDownloadItem.propTypes = {
  job: PropTypes.object.isRequired,
  keyId: PropTypes.number.isRequired,
};

export default PrintSheetFileDownloadItem;
