import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { orderStatusEnum } from '@constants/enums/orderEnums';
import { getOrderStatusHistory } from '@redux/orderManagement/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';

class OrderManagementSidebarSteps extends Component {
  componentDidMount() {
    const {
      dispatch,
      orderNumber,
      isTempOrder,
    } = this.props;

    if (!isTempOrder && orderNumber) {
      dispatch(getOrderStatusHistory(orderNumber));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      orderNumber,
      isTempOrder,
    } = this.props;

    if (!isTempOrder && orderNumber !== prevProps.orderNumber) {
      dispatch(getOrderStatusHistory(orderNumber));
    }
  }

  render() {
    const {
      isTempOrder,
      orderNumber,
      statusHistory,
    } = this.props;

    if (!isTempOrder && !orderNumber) {
      return (
        <div />
      );
    }

    const completeIcon = <Icon materialIcon={'check'} />;
    const activeIcon = <Icon materialIcon={'fiber_manual_record'} />;

    const isCompleteStatus = 'is-complete';
    const isActiveStatus = 'is-active';
    const isInactive = 'is-complete';

    let artworkTaskProcessingStat = {
      title: 'Processing Production Artwork',
      icon: '',
      status: !isTempOrder ? '' : isInactive,
    };
    let artworkTasksCompletedStat = {
      title: 'Artwork finalized',
      icon: '',
      status: !isTempOrder ? '' : isInactive,
    };
    let productionCompletedStat = {
      title: 'Production Complete',
      icon: '',
      status: !isTempOrder ? '' : isInactive,
    };
    let shippedStat = {
      title: 'Shipping Ready',
      icon: '',
      status: !isTempOrder ? '' : isInactive,
    };

    for (let i = statusHistory.length - 1; i >= 0; i--) {
      const status = statusHistory[i];

      switch (status.status) {
        case orderStatusEnum.ArtworkTasksProcessing:
          artworkTaskProcessingStat = {
            ...artworkTaskProcessingStat,
            icon: activeIcon,
            status: isActiveStatus,
            title: status.label,
            created: status.created,
            user: status.user,

          };
          break;
        case orderStatusEnum.ArtworkTasksCompleted:
          artworkTaskProcessingStat = {
            ...artworkTaskProcessingStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          artworkTasksCompletedStat = {
            ...artworkTasksCompletedStat,
            icon: activeIcon,
            status: isActiveStatus,
            title: status.label,
            created: status.created,
            user: status.user,
          };
          break;
        case orderStatusEnum.ProductionCompleted:
          artworkTaskProcessingStat = {
            ...artworkTaskProcessingStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          artworkTasksCompletedStat = {
            ...artworkTasksCompletedStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          productionCompletedStat = {
            ...productionCompletedStat,
            icon: activeIcon,
            status: isActiveStatus,
            title: status.label,
            created: status.created,
            user: status.user,
          };
          break;
        case orderStatusEnum.ShippingReady:
          artworkTaskProcessingStat = {
            ...artworkTaskProcessingStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          artworkTasksCompletedStat = {
            ...artworkTasksCompletedStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          productionCompletedStat = {
            ...productionCompletedStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          shippedStat = {
            ...shippedStat,
            icon: activeIcon,
            status: isActiveStatus,
            title: status.label,
            created: status.created,
            user: status.user,
          };
          break;
        case orderStatusEnum.Shipped:
          artworkTaskProcessingStat = {
            ...artworkTaskProcessingStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          artworkTasksCompletedStat = {
            ...artworkTasksCompletedStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          productionCompletedStat = {
            ...productionCompletedStat,
            icon: completeIcon,
            status: isCompleteStatus,
          };
          shippedStat = {
            ...shippedStat,
            icon: completeIcon,
            status: isCompleteStatus,
            title: status.label,
            created: status.created,
          };
          break;
      }
    }

    const tooltipText = (created, user) => (
      (created || user)
        ? (
          <div>
            {created ? <div>{`${parseDateTimeNumeric(created)}`}</div> : '' }
            {user ? <div>{user}</div> : '' }
          </div>
        )
        : ''
    );

    return (
      <div className='order__steps'>
        <ul className='header__bar-steps'>
          <li>
            <MaterialTooltip
              tooltipText={tooltipText(artworkTaskProcessingStat.created, artworkTaskProcessingStat.user)}
              placement={'bottom'}
            >
              <div className={artworkTaskProcessingStat.status}>
                <span className='header__bar-steps-circle margin margin--right-s'>
                  {artworkTaskProcessingStat.icon}
                </span>
                {artworkTaskProcessingStat.title}
              </div>
            </MaterialTooltip>
          </li>
          <li>
            <MaterialTooltip
              tooltipText={tooltipText(artworkTasksCompletedStat.created, artworkTasksCompletedStat.user)}
              placement={'bottom'}
            >
              <div className={artworkTasksCompletedStat.status}>
                <span className='header__bar-steps-circle margin margin--right-s'>
                  {artworkTasksCompletedStat.icon}
                </span>
                {artworkTasksCompletedStat.title}
              </div>
            </MaterialTooltip>
          </li>
          <li>
            <MaterialTooltip
              tooltipText={tooltipText(productionCompletedStat.created, productionCompletedStat.user)}
              placement={'bottom'}
            >
              <div className={productionCompletedStat.status}>
                <span className='header__bar-steps-circle margin margin--right-s'>
                  {productionCompletedStat.icon}
                </span>
                {productionCompletedStat.title}
              </div>
            </MaterialTooltip>
          </li>
          <li className={shippedStat.status}>
            <MaterialTooltip
              tooltipText={tooltipText(shippedStat.created, shippedStat.user)}
              placement={'bottom'}
            >
              <div>
                <span className='header__bar-steps-circle margin margin--right-s'>
                  {shippedStat.icon}
                </span>
                {shippedStat.title}
              </div>
            </MaterialTooltip>
          </li>
        </ul>
      </div>
    );
  }
}

OrderManagementSidebarSteps.propTypes = {
  orderNumber: PropTypes.number,
  isTempOrder: PropTypes.bool,
  statusHistory: PropTypes.array,
};

OrderManagementSidebarSteps.defaultProps = {
  isTempOrder: false,
};

const mapStateToProps = ({ orderManagement }) => ({
  orderNumber: orderManagement.order.orderNumber,
  statusHistory: orderManagement.statusHistory,
});

export default connect(mapStateToProps)(OrderManagementSidebarSteps);
