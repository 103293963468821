import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import RadioGroupItem from './RadioGroupItem';

interface OwnProps {
  radioItems: {
    value: any;
    label: string | JSX.Element;
    rootClassName?: string;
    className?: string;
    disabled?: boolean;
    isVisible?: boolean;
    hasInput?: boolean;
    inputNode?: Nullable<JSX.Element>;
  }[];
  groupClassName?: string;
  onClickOnLabel?: (value: string | number | boolean) => void;
}

type Props = OwnProps & WrappedFieldProps;

const RadioGroup = ({
  input: {
    value,
    onChange,
  },
  meta: {
    touched,
    error,
  },
  radioItems,
  groupClassName = '',
  onClickOnLabel,
}: Props) => {
  const renderRadioItem = (item: any, index: number) => (
    <RadioGroupItem
      key={index}
      item={item}
      value={value}
      onChange={onChange}
      onClickOnLabel={onClickOnLabel}
    />
  );

  const hasError = touched && error && (error.length > 0);

  return (
    <div className='w-100'>
      <div className={`${groupClassName}`}>
        {radioItems.map(renderRadioItem)}
      </div>
      <div>
        {
          hasError &&
          <div className='redux-form__error--field'>
            {error.map((e: string, index: number) => <div key={index}>{e}</div>)}
          </div>
        }
      </div>
    </div>
  );
};

export default RadioGroup;
