export const arraysAreSameById = <T extends { id: number; }>(oldData: T[], newData: T[]): boolean => {
  if (!oldData || !newData) {
    return false;
  }

  const size = oldData.length;
  if (size !== newData.length) {
    return false;
  }

  for (let i = 0; i < size; i++) {
    if (oldData[i].id !== newData[i].id) {
      return false;
    }
  }

  return true;
};

export const arraysAreEqual = <T>(a: T[], b: T[]): boolean => {
  if (a === b) return true;
  if (a === null || a === undefined || b === null || b === undefined) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
};

export const groupBy = <T>(array: readonly T[], propertyName: keyof T): Record<string, T[]> => {
  const dict = {} as Record<string, T[]>;

  for (const item of array) {
    const key = item[propertyName] as unknown as string;
    if (!dict[key]) {
      dict[key] = [];
    }
    dict[key].push(item);
  }

  return dict;
};

export const findDuplicatesInArray = <T extends string | number | symbol>(array: T[]): T[] => {
  const counter = {} as Record<T, number>;
  const duplicates: T[] = [];

  for (const item of array) {
    if (!counter[item]) {
      counter[item] = 0;
    }

    counter[item] += 1;
  }

  for (const [
    key,
    value,
  ] of Object.entries<number>(counter)) {
    if (value >= 2) {
      duplicates.push(key as T);
    }
  }

  return duplicates;
};

export const getUniqueListByKey = <T>(arr: readonly T[], key: keyof T): T[] => [
  ...new Map(arr.map((item) => [
    item[key],
    item,
  ])).values(),
];
