import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import {
  getDiscountTypeOptions,
  getCouponReasonOptions,
  getCouponStatusOptions,
} from '@constants/options/options';
import { couponForm } from '@constants/reduxForms';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { dropdownSizeL } from '@constants/values';
import {
  fetchCoupons,
  fetchCouponCreators,
  createCoupon,
} from '@redux/coupons/actions';
import { mapToOptionsList } from '@util/mappingHelper';
import { materialSwal } from '@util/componentHelper';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import {
  parseDateTimeNumeric,
  parseDateDashes,
} from '@util/dateHandler';
import DiscountField from '@sharedComponents/Display/DiscountField';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import CouponsDetails from './CouponsDetails/CouponsDetails';
import CouponsAddModal from './CouponsModals/CouponsAddModal';
import RedemptionCount from './CouponsDetails/RedemptionCount';

const CouponsTable = Table();

const validOptions = getCouponStatusOptions();
const discountOptions = getDiscountTypeOptions();
const reasonOptions = getCouponReasonOptions();

class Coupons extends Component {
  state = {
    sortOrder: '',
    sortByState: '',
    discountTypeFilter: '',
    reasonFilter: '',
    createdByFilter: '',
    validFilter: '',
    searchInput: '',
    selectedCoupon: null,
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
    showAddCouponModal: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchCouponCreators());
  }

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrder);

    this.setState({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }, this.search);
  };

  handleDiscountTypeFilter = (value) => {
    this.setState({
      discountTypeFilter: value,
    }, this.search);
  };

  handleReasonFilter = (value) => {
    this.setState({
      reasonFilter: value,
    }, this.search);
  };

  handleCreatedByFilter = (value) => {
    this.setState({
      createdByFilter: value,
    }, this.search);
  };

  handleValidFilter = (value) => {
    this.setState({
      validFilter: value,
    }, this.search);
  };

  filterTable = () => {
    const { searchInput } = this.state;

    const hasChanged = this.searchInput.value !== searchInput;

    if (!hasChanged) return;

    this.setState({
      searchInput: this.searchInput.value,
    }, this.search);
  };

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  search = () => {
    const { dispatch } = this.props;

    const {
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
      discountTypeFilter,
      reasonFilter,
      createdByFilter,
      validFilter,
      searchInput,
    } = this.state;

    this.unselectCoupon();

    dispatch(fetchCoupons(
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
      discountTypeFilter,
      reasonFilter,
      createdByFilter,
      validFilter,
      searchInput
    ));
  };

  getColumns = () => {
    const columns = [
      {
        Header: 'Code',
        accessor: 'code',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value && <div className={`coupon__code--${cellProps.original.isActive ? 'active' : 'expired'}`}>{cellProps.value}</div>,
      },
      {
        Header: 'Description',
        accessor: 'description',
        minWidth: 60,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        minWidth: 40,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Discount',
        accessor: 'discountAmount',
        minWidth: 35,
        Cell: (cellProps) => cellProps.value && (
          <b>
            <DiscountField
              type={cellProps.original.discountType}
              amount={cellProps.value}
            />
          </b>
        ),
      },
      {
        Header: <HeaderCell text={'Created On'} />,
        accessor: 'createdAt',
        sortable: true,
        minWidth: 70,
        Cell: (cellProps) => (
          <div className='table__cell--size-l'>
            {
              cellProps.value && (
                <div>
                  <div>{parseDateTimeNumeric(cellProps.value)}</div>
                  <div className='table__cell--details'>{cellProps.original.createdBy ? cellProps.original.createdBy : ''}</div>
                </div>
              )
            }
          </div>
        ),
      },
      {
        Header: <HeaderCell text={'Valid to'} />,
        accessor: 'validTo',
        sortable: true,
        minWidth: 45,
        Cell: (cellProps) => cellProps.value && parseDateDashes(cellProps.value),
      },
      {
        Header: 'Redemption Count',
        accessor: 'redemptionCount',
        minWidth: 40,
        Cell: (cellProps) => (
          <RedemptionCount
            redemptionCount={cellProps.value}
            redemptionLimit={cellProps.original.redemptionLimit}
            size={'small'}
          />
        ),
      },
    ];

    return columns;
  };

  selectCoupon = (selectedCoupon) => {
    this.setState({ selectedCoupon });
  };

  unselectCoupon = () => {
    this.setState({ selectedCoupon: null });
  };

  isRowActive = (couponId) => {
    const { selectedCoupon } = this.state;

    return couponId === selectedCoupon.id;
  };

  resetAllFilters = () => {
    this.setState({
      discountTypeFilter: '',
      reasonFilter: '',
      createdByFilter: '',
      validFilter: '',
      searchInput: '',
    }, this.search);
  };

  openAddCouponModal = () => {
    this.setState({ showAddCouponModal: true });
  };

  closeAddCouponModal = () => {
    const { dispatch } = this.props;
    dispatch(reset(couponForm));
    this.setState({ showAddCouponModal: false });
  };

  addCoupon = async (addCouponForm) => {
    const { dispatch } = this.props;
    const res = await dispatch(createCoupon(addCouponForm));
    if (res) {
      materialSwal('Success', res.message, 'success');
      dispatch(fetchCoupons());
      this.closeAddCouponModal();
    }
  };

  getTrProps = (state, rowInfo) => {
    const { selectedCoupon } = this.state;

    if (!rowInfo) {
      return {};
    }

    return {
      onClick: () => {
        this.selectCoupon(rowInfo.original);
      },
      className: `text-left cursor-pointer
        ${selectedCoupon && this.isRowActive(rowInfo.original.id)
        ? 'is-active coupons-table__row--is-active'
        : ''}`,
    };
  };

  render() {
    const {
      queue,
      totalPages,
      couponCreators,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    const {
      selectedCoupon,
      showAddCouponModal,
      discountTypeFilter,
      reasonFilter,
      createdByFilter,
      validFilter,
    } = this.state;

    const createdByOptions = mapToOptionsList({
      list: couponCreators,
      emptyOption: {
        name: 'Created by',
      },
    });

    return (
      <div className='container'>
        <CouponsAddModal
          isOpen={showAddCouponModal}
          closeModal={this.closeAddCouponModal}
          onSubmit={this.addCoupon}
        />
        <div className='flex'>
          <div className='table-options w-100'>
            <div className='flex'>
              <SearchFilter
                search={this.onSearch}
                clearSearch={this.clearSearch}
              />
              <Dropdown
                options={validOptions}
                defaultValue={validFilter}
                onChange={this.handleValidFilter}
                classes={'margin-left coupons-table__filter'}
              />
              <Dropdown
                options={discountOptions}
                defaultValue={discountTypeFilter}
                onChange={this.handleDiscountTypeFilter}
                classes={'margin-left coupons-table__filter'}
              />
              <Dropdown
                options={reasonOptions}
                defaultValue={reasonFilter}
                onChange={this.handleReasonFilter}
                classes={'margin-left coupons-table__filter'}
              />
              <Dropdown
                options={createdByOptions}
                defaultValue={createdByFilter}
                onChange={this.handleCreatedByFilter}
                classes={'margin-left coupons-table__filter'}
                size={dropdownSizeL}
              />
              <div
                className='coupons-table__btn--reset ml-20'
                onClick={this.resetAllFilters}
              >
                Reset all
              </div>
            </div>
            <div className='flex'>
              <Button
                type={'primary'}
                text={'Add New Code'}
                onClick={this.openAddCouponModal}
              />
            </div>
          </div>
        </div>

        <div className='master-detail'>
          <div className='w-100'>
            <CouponsTable
              data={queue}
              columns={this.getColumns()}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              totalPages={totalPages}
              onFetchData={this.fetchData}
              getTrProps={this.getTrProps}
            />
          </div>
          {
            selectedCoupon &&
            <CouponsDetails
              coupon={selectedCoupon}
              closeDetails={this.unselectCoupon}
            />
          }
        </div>
      </div>
    );
  }
}

Coupons.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  couponCreators: PropTypes.arrayOf(PropTypes.string).isRequired,
  restrictions: PropTypes.array,
};

const mapStateToProps = ({ coupons }) => ({
  totalPages: coupons.couponsList.totalPages,
  hasPreviousPage: coupons.couponsList.hasPreviousPage,
  hasNextPage: coupons.couponsList.hasNextPage,
  pageNumber: coupons.couponsList.pageNumber,
  queue: coupons.couponsList.queue,
  pageSize: coupons.couponsList.pageSize,
  couponCreators: coupons.couponCreators,
});

export default connect(mapStateToProps)(Coupons);
