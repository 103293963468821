import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import VendorForm from '../VendorForm';
import { vendorForm } from '@constants/reduxForms';

const VendorEditModal = React.memo(({
  isOpen,
  onSubmit,
  initialValues,
  change,
  closeModal,
}) => (
  <Modal
    title={'Edit Vendor'}
    isOpen={isOpen}
    modalHeight={'xl'}
    modalWidth={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Save'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={vendorForm}
      />
    )}
  >
    <VendorForm
      onSubmit={onSubmit}
      disabled={{ code: true }}
      initialValues={initialValues}
      change={change}
    />
  </Modal>
));

VendorEditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  change: PropTypes.func,
};

export default VendorEditModal;
