import React, { useCallback } from 'react';
import { PriorityQueueDto } from '@api/fulfillment/models';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  taskDetails: PriorityQueueDto;
  openModal: (taskDetails: PriorityQueueDto) => void;
}

type Props = OwnProps;

const ViewPriorityTaskModalButton = React.memo<Props>(({
  openModal,
  taskDetails,
}) => {
  const update = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openModal(taskDetails);
  }, [
    openModal,
    taskDetails,
  ]);

  return (
    <button
      onClick={update}
      className='priority-button__note'
      disabled={taskDetails.flag === null}
    >
      {
        taskDetails.flag?.attachmentUrl &&
        <Icon materialIcon={'attach_file'} />
      }
      Note
    </button>
  );
});

export default ViewPriorityTaskModalButton;
