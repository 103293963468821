import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import iconEye from '@assets/icon_hidden.svg';
import iconInvisible from '@assets/icon_eye_invisible.svg';
import { formatDollarAmount } from '@util/numberHelpers';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import ItemInventoryProductVendorQuantityInfo from '@sharedComponents/ItemInfo/ItemInventoryProductVendorQuantityInfo';

class ProductsDetails extends PureComponent {
  getProductVendorQuantityTooltipInfo = (productVendorQuantity, isPrimary = false) => (
    <ItemInventoryProductVendorQuantityInfo
      productVendorQuantity={productVendorQuantity}
      isPrimary={isPrimary}
    />
  );

  render() {
    const {
      coloredStyle,
      style,
      styleProducts,
      productVendorQuantities,
      openEditProductModal,
      productInventory,
      productSkuInfo,
    } = this.props;

    let productsMapped = null;
    const products = styleProducts.filter((x) => x.coloredStyleId === coloredStyle.id);

    if (products) {
      productsMapped = products.map((p) => {
        const productVendorQuantitiesForSku = productVendorQuantities[p.sku] || [];
        const productVendorQuantity = productVendorQuantitiesForSku.find((x) => x.vendorId === style.vendorId);
        const productVendorQuantitiesNotPrimary = productVendorQuantitiesForSku
          .filter((x) => x.vendorId !== style.vendorId);

        const inventory = productInventory.find((pi) => pi.sku === p.sku);
        const onHand = inventory ? inventory.qtyOnHand : 0;
        const allocated = inventory ? inventory.qtyAllocated : 0;
        const backOrdered = inventory ? inventory.qtyBackOrdered : 0;
        const freeForward = inventory ? inventory.freeForward : 0;
        const availableOnHand = inventory ? inventory.availableOnHand : 0;

        const skuInfo = productSkuInfo.find((pi) => pi.sku === p.sku);
        const currentCost = skuInfo ? skuInfo.currentCost : '-';
        const standardCost = skuInfo ? skuInfo.standardCost : '-';

        const qtyVendorMinimum = p.qtyVendorMinimum || coloredStyle.qtyVendorMinimum || style.qtyVendorMinimum || 0;

        return (
          <div
            key={p.sku}
            className='sku-details__row'
          >
            <div className='sku-details__column--info xl flex'>{p.sku || '-'}</div>
            <div className='sku-details__column--info s sku-details__status'>
              {
                p.disabled || coloredStyle.disabled || style.disabled
                  ? (
                    <MaterialTooltip
                      tooltipText={(
                        <>
                          {
                            p.disabled &&
                            <div className='pre-line'>SKU is disabled</div>
                          }
                          {
                            style.disabled &&
                            <div className='pre-line'>Style is disabled</div>
                          }
                          {
                            coloredStyle.disabled &&
                            <div className='pre-line'>Colored style is disabled</div>
                          }
                        </>
                      )}
                      placement={'top'}
                      contentClasses={'align__center'}
                    >
                      <i
                        className='sku-details__hidden pointer'
                        style={{ backgroundImage: `url("${iconEye}")` }}
                      />
                    </MaterialTooltip>
                  )
                  : (
                    <i
                      className='sku-details__hidden'
                      style={{ backgroundImage: `url("${iconInvisible}")` }}
                    />
                  )
              }
              {
                p.discontinued || coloredStyle.discontinued || style.discontinued
                  ? (
                    <MaterialTooltip
                      tooltipText={(
                        <>
                          {
                            p.discontinued &&
                            <div className='pre-line'>SKU is discontinued</div>
                          }
                          {
                            style.discontinued &&
                            <div className='pre-line'>Style is discontinued</div>
                          }
                          {
                            coloredStyle.discontinued &&
                            <div className='pre-line'>Colored style is discontinued</div>
                          }
                        </>
                      )}
                      placement={'top'}
                    >
                      <Icon
                        materialIcon={'block'}
                        classes={'active bold'}
                      />
                    </MaterialTooltip>
                  )
                  : (
                    <Icon
                      materialIcon={'block'}
                      classes={'disabled'}
                    />
                  )
              }
              {
                p.alwaysAvailableVendorInventory
                || coloredStyle.alwaysAvailableVendorInventory
                || style.alwaysAvailableVendorInventory
                  ? (
                    <MaterialTooltip
                      tooltipText={(
                        <>
                          {
                            p.alwaysAvailableVendorInventory &&
                            <div className='pre-line'>SKU has always availabe vendor inventory</div>
                          }
                          {
                            style.alwaysAvailableVendorInventory &&
                            <div className='pre-line'>Style has always availabe vendor inventory</div>
                          }
                          {
                            coloredStyle.alwaysAvailableVendorInventory &&
                            <div className='pre-line'>Colored style has always availabe vendor inventory</div>
                          }
                        </>
                      )}
                      placement={'top'}
                    >
                      <Icon
                        materialIcon={'inbox'}
                        classes={'always-active'}
                      />
                    </MaterialTooltip>
                  )
                  : (
                    p.availableVendorInventory || p.availableFreeForwardInventory
                      ? (
                        <Icon
                          materialIcon={'inbox'}
                          classes={'disabled'}
                        />
                      )
                      : (
                        <MaterialTooltip
                          tooltipText={'SKU disabled - Low vendor and inventory quantities'}
                          placement={'top'}
                        >
                          <Icon
                            materialIcon={'inbox'}
                            classes={'active'}
                          />
                        </MaterialTooltip>
                      )
                  )
              }
            </div>
            <div className='sku-details__column--info xs'>{p.size || '-'}</div>
            <div className='sku-details__column--info s'>{formatDollarAmount(p.basePrice)}</div>

            <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
              <div className='sku-details__column--info s'>{formatDollarAmount(standardCost)}</div>
            </PermissionHandler>

            <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
              <div className='sku-details__column--info m'>{formatDollarAmount(currentCost)}</div>
            </PermissionHandler>

            <div className='sku-details__column--info s'>{p.weight ? `${p.weight} lbs` : '-'}</div>
            <div className='sku-details__column--info m'>{p.decorationSize || '-'}</div>
            <div className='sku-details__column--info s'>{p.vendorId || '-'}</div>
            <MaterialTooltip
              tooltipText={(
                <>
                  <div className='pre-line mb-10'>
                    {`Minimum Vendor Quantity: ${qtyVendorMinimum}`}
                  </div>
                  {this.getProductVendorQuantityTooltipInfo(productVendorQuantity, true)}
                  {
                    productVendorQuantitiesNotPrimary.map(this.getProductVendorQuantityTooltipInfo)
                  }
                </>
              )}
              placement={'top'}
              contentClasses={`sku-details__column--info m cursor-pointer ${productVendorQuantity && productVendorQuantity.quantity < qtyVendorMinimum ? 'warning' : ''}`}
            >
              {(productVendorQuantity ? productVendorQuantity.quantity : '-')}
            </MaterialTooltip>
            <MaterialTooltip
              tooltipText={(
                <>
                  <div className='pre-line'>{`On Hand: ${onHand}`}</div>
                  <div className='pre-line'>{`Allocated: ${allocated}`}</div>
                  <div className='pre-line'>{`Back Ordered: ${backOrdered}`}</div>
                  <div className='pre-line' />
                  <div className='pre-line'>{`Available On Hand: ${availableOnHand}`}</div>
                  <div className='pre-line'>{`Free Forward: ${freeForward}`}</div>
                </>
              )}
              placement={'top'}
              contentClasses={`sku-details__column--info s cursor-pointer ${freeForward < 1 ? 'warning' : ''}`}
            >
              {freeForward}
            </MaterialTooltip>
            <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
              <div className='sku-details__column--info xs'>
                <div
                  className='sku-details__edit'
                  onClick={openEditProductModal.bind(null, p)}
                >
                  <Icon materialIcon={'edit'} />
                </div>
              </div>
            </PermissionHandler>
          </div>
        );
      });
    }

    return (
      <div className='sku-details__container'>
        <div className='sku-details__header'>
          <div className='sku-details__column--title xl'>SKU</div>
          <div className='sku-details__column--title s'>Status</div>
          <div className='sku-details__column--title xs'>Size</div>
          <div className='sku-details__column--title s'>Unit Price</div>

          <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
            <div className='sku-details__column--title s'>Std. Cost</div>
          </PermissionHandler>

          <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
            <div className='sku-details__column--title m'>Current Cost</div>
          </PermissionHandler>

          <div className='sku-details__column--title s'>Weight</div>
          <div className='sku-details__column--title m'>Dec. Size</div>
          <div className='sku-details__column--title s'>Vendor</div>
          <div className='sku-details__column--title m'>Vendor Qty</div>
          <div className='sku-details__column--title s'>Inventory</div>

          <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
            <div className='sku-details__column--title xs'>Actions</div>
          </PermissionHandler>
        </div>
        {productsMapped}
      </div>
    );
  }
}

ProductsDetails.propTypes = {
  styleProducts: PropTypes.arrayOf(PropTypes.object),
  coloredStyle: PropTypes.object.isRequired,
  productVendorQuantities: PropTypes.object.isRequired,
  openEditProductModal: PropTypes.func.isRequired,
  productInventory: PropTypes.arrayOf(PropTypes.object),
  productSkuInfo: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  productVendorQuantities: productCatalog.productVendorQuantities.vendorsBySku,
});

export default connect(mapStateToProps)(ProductsDetails);
