import React from 'react';
import PropTypes from 'prop-types';
import FormError from '@sharedComponents/Form/FormError';
import Icon from '@sharedComponents/Icons/Icon';
import RbiUpcFormInput from './ProductVendorMappingsFieldArrayItemContent/RbiUpcFormInput';
import VendorIdFormInput from './ProductVendorMappingsFieldArrayItemContent/VendorIdFormInput';
import EsiUpcFormInput from './ProductVendorMappingsFieldArrayItemContent/EsiUpcFormInput';
import VendorItemNumberFormInput from './ProductVendorMappingsFieldArrayItemContent/VendorItemNumberFormInput';

const ProductVendorMappingsFieldArrayItem = React.memo(({
  meta: { error },
  input: { name },
  disabled = {},
  removeProductVendor,
}) => (
  <div className='flex'>
    <div className='redux-form__column--size-m ml-20'>
      <VendorIdFormInput
        memberName={name}
        disabled={disabled.vendorId}
      />
    </div>
    <div className='redux-form__column--size-m ml-20'>
      <RbiUpcFormInput
        memberName={name}
        disabled={disabled.rbiUpc}
      />
    </div>
    <div className='redux-form__column--size-m ml-20'>
      <EsiUpcFormInput
        memberName={name}
        disabled={disabled.esiUpcNumber}
      />
    </div>
    <div className='redux-form__column--size-m  ml-20'>
      <VendorItemNumberFormInput
        memberName={name}
        disabled={disabled.vendorItemNumber}
      />
    </div>
    <Icon
      materialIcon={'cancel'}
      onClick={removeProductVendor}
      classes={'catalog-details__remove align__center'}
    />

    <FormError
      error={error}
      enableMultiple={true}
      classes={'lockerManagerEdit__custom-items--decoration__error'}
    />
  </div>
));

ProductVendorMappingsFieldArrayItem.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.shape({
    vendorId: PropTypes.string,
    rbiUpc: PropTypes.string,
    esiUpcNumber: PropTypes.string,
    vendorItemNumber: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.array,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  removeProductVendor: PropTypes.func.isRequired,
};

export default ProductVendorMappingsFieldArrayItem;
