import React from 'react';
import PropTypes from 'prop-types';

const ListCell = ({ items }) => {
  if (items) {
    return <div>{items.join(', ')}</div>;
  }

  return <div>-</div>;
};

ListCell.propTypes = {
  items: PropTypes.array,
};

export default ListCell;
