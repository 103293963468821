import { buildQueryString } from '@util/stringHelpers';

export const root = 'homefield/api' as const;

/** Styles */
export const styles = `${root}/styles` as const;
export const style = (styleId: number): string => `${styles}/${styleId}`;
export const bulkUpdateStyle = `${styles}.bulk` as const;
export const styleStatus = `${styles}.status` as const;
export const styleVendor = `${styles}.change-primary-vendor` as const;

export const stylesTable = (
  pageNumber: number,
  pageSize: number,
  brandId: number[] | null = null,
  categoryId: number[] | null = null,
  prdOnly: boolean,
  age: string[] | null = null,
  gender: string[] | null = null,
  sportId: number[] | null = null,
  color: string[] | null = null,
  size: string[] | null = null,
  recommended: boolean | null = null,
  availableForNewStores: boolean | null = null,
  sortBy: string | null = null,
  sortDirection: string | null = null,
  search: string | null = null,
  customizable: boolean | null = null,
  priceMin = null,
  priceMax = null,
  coloredStyleStatus = null,
  vendorQuantityMin = null,
  websiteVisibility = null,
  vendorCode = null,
  decorationMethod = null
): string => `${styles}${buildQueryString({
  pageNumber,
  pageSize,
  brandId,
  categoryId,
  prdOnly,
  age,
  gender,
  sportId,
  color,
  size,
  recommended,
  availableForNewStores,
  sortBy,
  sortDirection,
  search,
  customizable,
  priceMin,
  priceMax,
  coloredStyleStatus,
  vendorQuantityMin,
  websiteVisibility,
  vendorCode,
  decorationMethod,
}, true)}`;

export const coloredStylesForStyle = (
  styleId: number,
  brandId: number | null = null,
  categoryId: number | null = null,
  sportId: number | null = null,
  decorationMethod: string | null = null,
  color: string | null = null,
  size: string | null = null,
  search: string | null = null,
  customizable: boolean | null = null,
  status: string | null = null
): string => `${style(styleId)}/colored-styles${buildQueryString({
  brandId,
  categoryId,
  color,
  decorationMethod,
  search,
  sportId,
  size,
  customizable,
  status,
}, true)}`;

export const sportsForStyle = (styleId: number): string => `${style(styleId)}/sports`;
export const productsForStyle = (styleId: number): string => `${style(styleId)}/products`;
export const productVendorQuantitiesForStyle = (styleId: number): string => `${style(styleId)}/product-vendor-quantities`;
export const productInventoryForStyle = (styleId: number): string => `${style(styleId)}/product-inventory`;
export const productSkuInfoForStyle = (styleId: number): string => `${style(styleId)}/product-sku-info`;
export const layoutsForStyle = (styleId: number): string => `${style(styleId)}/layouts`;
export const syncStyle = (styleId: number): string => `${styles}.sync/${styleId}`;

/** Colored Styles */
export const coloredStyles = `${root}/colored-styles`;
export const coloredStyle = (coloredStyleId: number): string => `${coloredStyles}/${coloredStyleId}`;
export const coloredStyleStatus = `${coloredStyles}.status`;
export const productsForColoredStyle = (coloredStyleId: number): string => `${coloredStyle(coloredStyleId)}/products`;
export const productInventoryForColoredStyle = (coloredStyleId: number): string => `${coloredStyle(coloredStyleId)}/product-inventory`;
export const productVendorQuantitiesForColoredStyle = (coloredStyleId: number): string => `${coloredStyle(coloredStyleId)}/product-vendor-quantities`;

export const coloredStylesTable = (
  pageNumber: number,
  pageSize: number,
  brandId: number[] | null = null,
  categoryId: number[] | null = null,
  color: string[] | null = null,
  size: string[] | null = null,
  decorationMethod: string | null = null,
  personalizationDecorationLocation: string | null = null,
  search: string | null = null,
  sortBy: string | null = null,
  sortDirection: string | null = null,
  customizable: boolean | null = null,
  recommended: boolean | null = null,
  status: string | null = null
): string => `${coloredStyles}${buildQueryString({
  pageNumber,
  pageSize,
  brandId,
  categoryId,
  color,
  size,
  decorationMethod,
  personalizationDecorationLocation,
  search,
  sortBy,
  sortDirection,
  customizable,
  recommended,
  status,
}, true)}`;

export const updateGridPreview = (coloredStyleId: number): string => `${coloredStyles}.grid-preview/${coloredStyleId}`;
export const syncColoredStyle = (coloredStyleId: number): string => `${coloredStyles}.sync/${coloredStyleId}`;
export const syncColoredStyleProducts = (coloredStyleId: number): string => `${coloredStyles}.sync/${coloredStyleId}/products`;

/** Products */
export const products = `${root}/products` as const;
export const product = (sku: string): string => `${products}/${sku}`;

/** Product Vendors */
export const productVendorMappings = (
  sku: string,
  vendorId: string
): string => `${root}/product-vendor-mappings${buildQueryString({
  sku,
  vendorId,
})}`;

/** DTG */
export const dtgConfigurationRoot = `${root}/dtg-configurations` as const;
export const dtgEnvironmentRoot = `${dtgConfigurationRoot}/environments` as const;
export const dtgColoredStyleRoot = `${dtgConfigurationRoot}/colored-style-configurations` as const;
export const dtgEnvironmentsPaged = (
  pageNumber: number,
  pageSize: number,
  search: string = ''
): string => `${dtgEnvironmentRoot}${buildQueryString({
  pageNumber,
  pageSize,
  search,
})}`;
export const dtgEnvironmentOptions = `${dtgEnvironmentRoot}.simple` as const;
export const dtgEnvironments = dtgEnvironmentRoot;
export const dtgEnvironment = (id: string): string => `${dtgEnvironmentRoot}/${parseInt(id)}`;
export const dtgColoredStyle = (options: Record<string, any> = {}): string => `${dtgColoredStyleRoot}${buildQueryString(options, true)}`;
export const dtgArtworkSettings = (options: Record<string, any> = {}): string => `${dtgConfigurationRoot}/artwork-configurations${typeof options === 'number' ? `/${options}` : ''}${buildQueryString(options, true)}`;
export const dtgPersonalizationSettings = (options: Record<string, any> = {}): string => `${dtgConfigurationRoot}/personalization-configurations${typeof options === 'number' ? `/${options}` : ''}${buildQueryString(options, true)}`;
export const dtgColoredStyleConfigurationBySku = (coloredStyleId: number): string => `${dtgConfigurationRoot}/colored-style-configuration/${coloredStyleId}`;

export const dtgColoredStyleConfigurationByManyIds = (coloredStyleIds: number[]): string => `${dtgConfigurationRoot}/colored-style-configurations/${buildQueryString({
  pageNumber: 1,
  pageSize: coloredStyleIds.length || 1,
  coloredStyleId: coloredStyleIds,
}, true)}`;

export const dtgPalletOptions = `${dtgConfigurationRoot}/pallets` as const;
export const dtgPretreatmentBatchOptions = `${dtgConfigurationRoot}/pretreatment-batches` as const;
export const dtgTabletProfileRoot = `${dtgConfigurationRoot}/tablet-profiles` as const;
export const dtgTabletProfiles = dtgTabletProfileRoot;

export const dtgTabletProfilesPaged = (
  pageNumber: number,
  pageSize: number,
  search: string = ''
): string => `${dtgTabletProfileRoot}${buildQueryString({
  pageNumber,
  pageSize,
  search,
})}`;

export const dtgTabletProfile = (id: string): string => `${dtgTabletProfileRoot}/${parseInt(id)}`;
export const dtgTabletProfileOptions = `${dtgTabletProfileRoot}.simple` as const;

export default {
  style,
  styleStatus,
  styleVendor,
  stylesTable,
  coloredStylesTable,
  coloredStylesForStyle,
  coloredStyleStatus,
  sportsForStyle,
  productsForStyle,
  layoutsForStyle,
  productInventoryForStyle,
  productSkuInfoForStyle,
  bulkUpdateStyle,
  coloredStyle,
  productsForColoredStyle,
  productInventoryForColoredStyle,
  product,
  productVendorQuantitiesForStyle,
  productVendorQuantitiesForColoredStyle,
  productVendorMappings,
  updateGridPreview,
  syncStyle,
  syncColoredStyle,
  syncColoredStyleProducts,
  dtgEnvironmentsPaged,
  dtgEnvironments,
  dtgEnvironment,
  dtgEnvironmentOptions,
  dtgTabletProfileOptions,
  dtgColoredStyle,
  dtgArtworkSettings,
  dtgPersonalizationSettings,
  dtgTabletProfile,
  dtgTabletProfiles,
  dtgTabletProfilesPaged,
  dtgColoredStyleConfigurationBySku,
  dtgPalletOptions,
  dtgPretreatmentBatchOptions,
  dtgColoredStyleConfigurationByManyIds,
};
