import React from 'react';
import Button from '@sharedComponents/Buttons/Button';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { useGetDtgPrepressJob } from '@hooks/queries/fulfillment-api/useGetDtgPrepressJob';
import { dtgPrepressJobEnum } from '@constants/enums/dtgPrepressEnums';
import Icon from '@sharedComponents/Icons/Icon';
import LinkButton from '@sharedComponents/Buttons/LinkButton';

interface OwnProps {
  taskId?: Nullable<number>;
  iconButton?: boolean;
  orderItemDecorationId: number;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  files?: any[];
  previewUrl?: string;
}

type Props = OwnProps;

const RegenerateDtgImageButton = React.memo<Props>(({
  taskId = null,
  iconButton = false,
  orderItemDecorationId = null,
  onClick,
  files = [],
  previewUrl = undefined,
}) => {
  const { data: dtgPrepressJob } = useGetDtgPrepressJob({
    artworkTaskId: taskId,
    orderItemDecorationId,
    start: files.length <= 0 || !previewUrl,
  });

  const linkClass = files.length > 0 ? 'link' : 'disabled';

  if (iconButton) {
    return (
      <div className={`regenerate-image-link__${linkClass}`}>
        {
          files.length > 0
            ? (
              <LinkButton
                onClick={onClick}
                disabled={dtgPrepressJob?.status !== dtgPrepressJobEnum.Done}
                text={'Regenerate'}
                icon={<Icon materialIcon={'refresh'} />}
              />
            )
            : (
              <MaterialTooltip
                tooltipText={'Image generation precedure in progress'}
                placement={'top'}
              >
                <span>
                  <Icon materialIcon={'refresh'} />
                    Regenerate
                </span>
              </MaterialTooltip>
            )
        }
      </div>
    );
  }

  return (
    <div>
      <MaterialTooltip
        tooltipText={'Image generation precedure in progress'}
        placement={'top'}
      >
        <span>
          <Button
            type={'secondary'}
            text={'image generation in progress...'}
            disabled={!previewUrl}
            onClick={undefined}
          />
        </span>
      </MaterialTooltip>
    </div>
  );
});

export default RegenerateDtgImageButton;
