import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import { colorBrightnessEnum } from '@constants/enums/decorationEnums';
import { modalStyle } from '@constants/values';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import Icon from '@sharedComponents/Icons/Icon';
import ColorList from './ColorList';

class LogoDetailsModal extends PureComponent {
  state = {
    hoveredColor: null,
  };

  setHoveredColor = (color) => {
    this.setState(() => ({ hoveredColor: color }));
  };

  render() {
    const {
      isOpen,
      closeModal,
      logo,
      hasPrevious,
      hasNext,
      slideToPrevious,
      slideToNext,
    } = this.props;

    const { hoveredColor } = this.state;

    if (!logo) {
      return (
        <Modal
          isOpen={false}
          onRequestClose={closeModal}
          style={modalStyle}
        />
      );
    }

    const approvedLightColors = logo.floodColors.filter((c) => c.brightness === colorBrightnessEnum.Light);
    const approvedDarkColors = logo.floodColors.filter((c) => c.brightness === colorBrightnessEnum.Dark);

    const decorations = logo.decorationMethods.map((method, index) => (
      <span
        key={index}
        className='logo-bank__used--decoration'
      >
        {method}
      </span>
    ));

    return (
      <Modal
        title={'Logo Preview'}
        modalSize={'xl'}
        isOpen={isOpen}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            cancelBtnText={'Close'}
            onClose={closeModal}
            displayConfirmBtn={false}
          />
        )}
      >
        <div>
          <div className='align__center--horizontal'>
            <div className='logo-bank__preview-modal--arrow left'>
              {
                hasPrevious(logo) &&
                <Icon
                  materialIcon={'chevron_left'}
                  onClick={slideToPrevious}
                />
              }
            </div>
            <div
              className='color-detection__logo transparent logo-bank__upload-modal--logo preview'
              style={hoveredColor
                ? {
                  backgroundImage: 'none',
                  backgroundColor: hoveredColor,
                }
                : {}
              }
            >
              <div
                className='color-detection__logo'
                style={{ backgroundImage: `url("${`${lockerMgrS3Logos}/${logo.image}`}")` }}
              >
                {
                  decorations.length > 0 &&
                  <div className='logo-bank__used--decorations logo-bank__upload-modal--logo__decoration-methods'>
                    {decorations}
                  </div>
                }
              </div>
            </div>
            <div className='logo-bank__preview-modal--arrow right'>
              {
                hasNext(logo) &&
                <Icon
                  materialIcon={'chevron_right'}
                  onClick={slideToNext}
                />
              }
            </div>
          </div>

          <div className='mb-30'>
            <ColorList
              classes={'artwork-colors'}
              colors={logo.colorSelections}
              displayProp={'displayName'}
            />
          </div>

          <div className='align__center--horizontal'>
            <div className='logo-bank__upload-modal--colors preview'>
              <div className='mr-20 w-50'>
                <div className='logo-bank__upload-modal--title'>
                  <div>Approved Light Colors</div>
                </div>
                <div>
                  {
                    approvedLightColors.length > 0
                      ? (
                        <ColorList
                          colors={approvedLightColors}
                          setHoveredColor={this.setHoveredColor}
                          displayProp={'code'}
                        />
                      )
                      : <div>No colors selected</div>
                  }
                </div>
              </div>

              <div className='w-50'>
                <div className='logo-bank__upload-modal--title'>
                  <div>Approved Dark Colors</div>
                </div>
                <div>
                  {
                    approvedDarkColors.length > 0
                      ? (
                        <ColorList
                          colors={approvedDarkColors}
                          setHoveredColor={this.setHoveredColor}
                          displayProp={'code'}
                        />
                      )
                      : <div>No colors selected</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

LogoDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  logo: PropTypes.object,
  hasPrevious: PropTypes.func.isRequired,
  hasNext: PropTypes.func.isRequired,
  slideToPrevious: PropTypes.func.isRequired,
  slideToNext: PropTypes.func.isRequired,
};

export default LogoDetailsModal;
