import React from 'react';
import { RosterTeamViewModel } from '@api/squadlockerServices/models';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  removeRoster: (roster: RosterTeamViewModel) => void;
  closeModal: () => void;
  roster: RosterTeamViewModel;
}

type Props = OwnProps;

const RemoveRosterModal = React.memo<Props>(({
  isOpen,
  removeRoster,
  closeModal,
  roster,
}) => {
  if (!roster || !isOpen) {
    return null;
  }

  return (
    <Modal
      title={'Remove Item'}
      modalSize={'m'}
      isOpen={isOpen}
      closeModal={closeModal}
      modalClass={'lockerManager__delete-modal'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Yes, Remove'}
          cancelBtnText={'Cancel'}
          onConfirm={removeRoster.bind(null, roster)}
          onClose={closeModal}
        />
      )}
    >
      <div className='lockerManager__delete-modal--content'>
        <h3>Are you sure you want to remove roster {roster.teamName}?</h3>
      </div>
    </Modal>
  );
});

export default RemoveRosterModal;
