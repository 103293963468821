import React from 'react';
import {
  UseFormRegister,
  UseFormRegisterReturn,
  FieldError,
  FieldValues,
} from 'react-hook-form';

interface OwnProps<TForm extends FieldValues = any> {
  id?: string;
  type: React.HTMLInputTypeAttribute | 'textarea';
  initialValue?: string | number;
  error?: FieldError;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  register: UseFormRegister<TForm>;
}

type Props<TForm extends FieldValues = any> = OwnProps<TForm> & UseFormRegisterReturn;

const FormInput = <TForm extends FieldValues = any>({
  name = '',
  register,
  error,
  id = '',
  className = 'w-100',
  placeholder = '',
  required = false,
  disabled = false,
  autoFocus = false,
  initialValue = '',
  type = 'text',
}: Props<TForm>) => {
  const hasError = error;

  if (type === 'textarea') {
    return (
      <div className='text-field w-200'>
        <textarea
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          autoFocus={autoFocus}
          defaultValue={initialValue}
          id={id}
          className={`redux-form__input ${className} ${hasError ? 'error' : ''}`}
          {...register(name as any, { required })}
        />
        {
          hasError &&
          <div className='redux-form__error--field'>
            {error.message}
          </div>
        }
      </div>
    );
  }

  return (
    <div className='text-field w-100'>
      <input
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        autoFocus={autoFocus}
        defaultValue={initialValue}
        id={id}
        className={`redux-form__input ${className} ${hasError ? 'error' : ''}`}
        {...register(name as any, { required })}
        type={type}
      />
      {
        hasError &&
        <div className='redux-form__error--field'>
          {error.message}
        </div>
      }
    </div>
  );
};

export default React.memo(FormInput) as typeof FormInput;
