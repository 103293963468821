import React from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  Field,
} from 'redux-form';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import { generalOptions } from '@util/optionsMap';
import { brightnessOptions } from '@constants/options/optionsValues';
import { colorForm } from '@constants/reduxForms';
import { validateColor } from '@redux/colors/validations';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';

const brightnessOptionsJsx = generalOptions(brightnessOptions);

const ColorForm = React.memo(({
  handleSubmit,
  change,
}) => (
  <form
    className='redux-form'
    onSubmit={handleSubmit}
    id={colorForm}
  >
    <div>
      <div className='redux-form__section pb-5'>
        <div className='redux-form__row'>
          <div className='redux-form__column--size-m'>
            <label className='redux-form__label'>
              Code
              <span className='required'>*</span>
            </label>
            <Field
              name={'code'}
              placeholder={'Enter Code'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-m ml-20'>
            <label className='redux-form__label'>
              DTG Code
              <span className='required'>*</span>
            </label>
            <Field
              name={'dtgCode'}
              placeholder={'Enter DTG Code'}
              component={Input}
              type={'text'}
            />
          </div>
        </div>
        <div className='redux-form__row'>
          <div className='redux-form__column--size-m'>
            <label className='redux-form__label'>
              CMYK
              <span className='required'>*</span>
            </label>
            <Field
              name={'cmykValue'}
              placeholder={'Enter Cmyk'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-m ml-20'>
            <label className='redux-form__label'>
              DTG CMYK
              <span className='required'>*</span>
            </label>
            <Field
              name={'dtgCmykValue'}
              placeholder={'Enter DTG Cmyk'}
              component={Input}
              type={'text'}
            />
          </div>
        </div>
        <div className='redux-form__row'>
          <div className='redux-form__column--size-m'>
            <label className='redux-form__label'>
              HEX
            </label>
            <Field
              name={'hexValue'}
              placeholder={'Enter hex'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-m ml-20'>
            <label className='redux-form__label'>
              Brightness
              <span className='required'>*</span>
            </label>
            <Field
              name={'brightness'}
              component={Select}
            >
              {brightnessOptionsJsx}
            </Field>
          </div>
        </div>
        <div className='redux-form__row'>
          <div className='redux-form__column--size-m'>
            <label className='redux-form__label'>
              Thread
            </label>
            <Field
              name={'threadValue'}
              placeholder={'Enter thread'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-m ml-20'>
            <label className='redux-form__label'>
              DisplayName
            </label>
            <Field
              name={'displayName'}
              placeholder={'Enter display name'}
              component={Input}
              type={'text'}
            />
          </div>
        </div>
        <div className='redux-form__row justify__start'>
          <div className='redux-form__column--size-s mt-30'>
            <Field
              name={'isArtwork'}
              text={'Artwork Color'}
              component={ReduxFormCheckbox}
              change={change}
            />
          </div>
          <div className='redux-form__column--size-s mt-30'>
            <Field
              name={'isFlood'}
              text={'Flood Color'}
              component={ReduxFormCheckbox}
              change={change}
            />
          </div>
        </div>
      </div>
    </div>
  </form>
));

ColorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default (reduxForm({
  form: colorForm,
  validate: validateColor,
  shouldError: () => true,
})(ColorForm));
