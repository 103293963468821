import React, { useCallback } from 'react';
import { mapToOptionsList } from '@util/mappingHelper';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

interface OwnProps {
  selectSize: (newSize: string, item: any) => void;
  selectedItem: any;
  sizes: string[];
  disabled?: boolean;
}

type Props = OwnProps;

const OrderItemSizesDropdown = React.memo(({
  selectSize,
  selectedItem,
  sizes,
  disabled = false,
}: Props) => {
  const handleSizesChange = useCallback((newSize: string) => {
    selectSize(newSize, selectedItem);
  }, [
    selectedItem,
    selectSize,
  ]);

  const options = mapToOptionsList<string, string>({
    list: sizes,
    emptyOption: {
      name: 'Select Size*',
      disabled: true,
    },
  });

  return (
    <Dropdown<string>
      options={options}
      onChange={handleSizesChange}
      classes={'margin-left new-order__add-items__sizes-dropdown'}
      disabled={disabled}
    />
  );
});

export default OrderItemSizesDropdown;
