/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import type {
  VariationDtoApiResponse,
  ApiResponse,
  GetHomefieldApiVariationsIdParams,
  GetHomefieldApiVariationsUuidParams,
  VariationDtoPagedList,
  GetHomefieldApiVariationsParams
} from './models'
import { ordersApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiVariationsId = <TData = VariationDtoApiResponse>(
    id: number,
    params?: GetHomefieldApiVariationsIdParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/variations/${id}`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVariationsIdQueryKey = (id: number,
    params?: GetHomefieldApiVariationsIdParams,) => [`/homefield/api/variations/${id}`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVariationsId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVariationsId, VariationDtoApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 id: number,
    params?: GetHomefieldApiVariationsIdParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVariationsIdQueryKey(id,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVariationsId<TQueryFnData>(id,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVariationsUuid = <TData = VariationDtoApiResponse>(
    uuid: string,
    params?: GetHomefieldApiVariationsUuidParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/variations/${uuid}`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVariationsUuidQueryKey = (uuid: string,
    params?: GetHomefieldApiVariationsUuidParams,) => [`/homefield/api/variations/${uuid}`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVariationsUuid = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVariationsUuid, VariationDtoApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 uuid: string,
    params?: GetHomefieldApiVariationsUuidParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVariationsUuidQueryKey(uuid,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVariationsUuid<TQueryFnData>(uuid,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiVariations = <TData = VariationDtoPagedList>(
    params?: GetHomefieldApiVariationsParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/variations`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiVariationsQueryKey = (params?: GetHomefieldApiVariationsParams,) => [`/homefield/api/variations`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiVariations = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiVariations, VariationDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiVariationsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiVariationsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiVariations<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

