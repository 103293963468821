import React, { useCallback } from 'react';
import { OrderManagementTempItem } from '@redux/orderManagement/models';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

interface OwnProps {
  item: OrderManagementTempItem;
  openChangeQuantityModal: (item: OrderManagementTempItem) => void;
  openDuplicateItemModal: (item: OrderManagementTempItem) => void;
  openRemoveItemModal: (item: OrderManagementTempItem) => void;
}

type Props = OwnProps;

const OrderItemActions = React.memo(({
  item,
  openChangeQuantityModal,
  openDuplicateItemModal,
  openRemoveItemModal,
}: Props) => {
  const handleOpenChangeQuantityModal = useCallback(() => {
    openChangeQuantityModal(item);
  }, [
    openChangeQuantityModal,
    item,
  ]);

  const handleOpenDuplicateItemModal = useCallback(() => {
    openDuplicateItemModal(item);
  }, [
    openDuplicateItemModal,
    item,
  ]);

  const handleOpenRemoveItemModal = useCallback(() => {
    openRemoveItemModal(item);
  }, [
    openRemoveItemModal,
    item,
  ]);

  return (
    <div>
      <ShowMoreActionsButton
        actions={[
          {
            text: 'Change Quantity',
            action: handleOpenChangeQuantityModal,
          },
          {
            text: 'Duplicate Item',
            action: handleOpenDuplicateItemModal,
          },
          {
            text: 'Remove Item',
            action: handleOpenRemoveItemModal,
            isDangerous: true,
          },
        ]}
      />
    </div>
  );
});

export default OrderItemActions;
