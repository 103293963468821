import React from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

const PrintSheetItemPersonalizationIndicator = React.memo(({
  color,
  outlineColor,
}) => {
  let outlineElement = '';
  if (outlineColor && outlineColor.hexValue) {
    outlineElement = (
      <div
        className='color--outline'
        style={{ borderColor: outlineColor && outlineColor.hexValue }}
      />
    );
  }

  const tooltipText = (
    <div className='flex__column'>
      <span>Fill: {color && color.code || '-'}</span>
      {outlineColor && <span>Outline: {outlineColor.code || '-'}</span>}
    </div>
  );

  return (
    <MaterialTooltip
      tooltipText={tooltipText}
      placement={'right'}
    >
      <div
        className='color ml-10'
        style={{ backgroundColor: color && color.hexValue }}
      >
        {outlineElement}
      </div>
    </MaterialTooltip>
  );
});

PrintSheetItemPersonalizationIndicator.propTypes = {
  color: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
  }),
  outlineColor: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
  }),
};

export default PrintSheetItemPersonalizationIndicator;
