import React from 'react';

interface OwnProps {
  colorName: string;
  displayName?: boolean;
  size?: 'small' | 'xs';
  className?: string;
  isSelected?: boolean;
  hideEmptySwatch?: boolean;
  isDisabled?: boolean;
  hexValue?: string;
}

type Props = OwnProps;

const ColorDisplay = React.memo<Props>(({
  colorName,
  displayName = true,
  size = '',
  className = '',
  isSelected = false,
  hideEmptySwatch = false,
  isDisabled = false,
  hexValue = '',
}) => {
  const colorClass = `color-swatch-item__color ${size} ${isSelected ? 'is-active' : ''} ${isDisabled ? 'is-disabled' : ''}`;

  return (
    <span className={`color-swatch-item ${size} ${className}`}>
      {
        hideEmptySwatch
          ? (hexValue &&
              <span
                className={colorClass}
                style={{ backgroundColor: hexValue }}
              />
          )
          : (
            <span
              className={colorClass}
              style={hexValue ? { backgroundColor: hexValue } : {}}
            />
          )
      }
      <span className='ml-5'>{displayName ? colorName : '' }</span>
    </span>
  );
});

export default ColorDisplay;
