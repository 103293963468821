import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  name: string;
  materialIcon: string;
  linkUrl: string;
  currentLocation: string;
}

type Props = OwnProps;

const DrawerPanelMaterial = React.memo(({
  name,
  materialIcon,
  linkUrl,
  currentLocation,
}: Props) => (
  <li data-toggle='body'>
    <Link
      to={linkUrl}
      className={`drawer-material ${linkUrl === currentLocation ? 'is-active' : ''}`}
    >
      <span className='margin margin--right-m'>
        <Icon materialIcon={materialIcon} />
      </span>
      {name}
    </Link>
  </li>
));

export default DrawerPanelMaterial;
