import { emailRegex } from '@constants/common';
import { emailMaxLength } from '@constants/values';
import { isDefined } from '@util/valueHelpers';

export const validateRoster = (values) => {
  const errors = {};

  const rosters = values.rosters || [];
  for (let i = 0; i < rosters.length; i++) {
    const roster = rosters[i];
    const member = `rosters[${i}]`;

    if (!isDefined(roster.teamName)) {
      errors[`${member}.teamName`] = ['Roster team name is required.'];
    }

    if (!isDefined(roster.disabled)) {
      errors[`${member}.disabled`] = ['Roster status is required.'];
    }
  }

  return errors;
};

export const validateTeamMember = (values) => {
  const errors = {};

  if (!isDefined(values.lastName)) {
    errors['teamMember.lastName'] = ['Last name is required.'];
  }

  if (!isDefined(values.number)) {
    errors['teamMember.number'] = ['Number is required.'];
  }

  if (!isDefined(values.email)) {
    errors['teamMember.email'] = ['Email is required.'];
  } else if (!values.email.match(emailRegex)) {
    errors['teamMember.email'] = ['E-mail is not in correct form.'];
  } else if (values.email.length > emailMaxLength) {
    errors['teamMember.email'] = [`Email should not be greater than ${emailMaxLength} characters.`];
  }

  return errors;
};

export const validateNotifications = (values) => {
  const errors = {};

  if (!isDefined(values.startDate)) {
    errors.startDate = ['Send date is required.'];
  }

  if (values.startDate && values.orderingDeadline && new Date(values.startDate) > new Date(values.orderingDeadline)) {
    errors.orderingDeadline = ['Ordering deadline must be after start date.'];
  }

  if (!isDefined(values.templateId)) {
    errors.templateId = ['Template is required.'];
  }

  if (!isDefined(values.senderNameType)) {
    errors.senderNameType = ['Sender name is required.'];
  }

  if (!isDefined(values.linkDestination)) {
    errors.linkDestination = ['Link destination is required.'];
  }

  /*
   * This will only be validated if the custom name is chosen as a sender name type
   * because redux-form ignores validation on unmounted components
   */
  if (!isDefined(values.customSenderName)) {
    errors.customSenderName = ['Custom sender name is required.'];
  }

  return errors;
};
