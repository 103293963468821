import { Color } from '@material-ui/core';
import React from 'react';
import { logoDetailColorNumber } from '@constants/values';

interface OwnProps {
  colors: Color[];
  swatchInfo: JSX.Element;
  text: string;
}

type Props = OwnProps;

const LogoColors = ({
  colors,
  swatchInfo,
  text,
}: Props) => {
  const number = colors.length - logoDetailColorNumber;

  return (
    <div className={'logo-bank__used--details--colors--tooltip'}>
      <div className={'logo-bank__used--details--colors--tooltip--values'}>
        <div className={'logo-bank__used--details--colors--tooltip--text'}>{text}</div>
        <div>
          {swatchInfo}
        </div>
      </div>
      {(colors.length > logoDetailColorNumber) && `+${number} more`}
    </div>
  );
};

export default React.memo(LogoColors);
