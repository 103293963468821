import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { validateProductionAssemblyFlagItem } from '@redux/productionAssembly/validations';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import { productionAssemblyFlagItemForm } from '@constants/reduxForms';
import {
  getFlagReasonOptions,
  getFlagReasons,
} from '@util/flagHelper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import MarkItemAsFlaggedModalLogoDetails from '../../WeedingAndMasking/Flagging/MarkItemAsFlaggedModalLogoDetails';
import MarkItemAsFlaggedModalPersonalizationDetails from '../../WeedingAndMasking/Flagging/MarkItemAsFlaggedModalPersonalizationDetails';

class ProductionAssemblyFlaggingModal extends PureComponent {
  state = {
    selectedOption: this.props.initialValues.flagType,
  };

  handleOptionChange = (changeEvent) => {
    const {
      item,
      change,
    } = this.props;
    const flagType = changeEvent.target.value;

    this.setState({ selectedOption: flagType });
    const reasons = getFlagReasons(flagType, item.decorationMethod);

    change('reason', reasons[0].reason);
  };

  render() {
    const {
      item,
      closeModal,
      orderNumber,
      colorsDictionary,
      isOpen,
      handleSubmit,
    } = this.props;

    const { selectedOption } = this.state;

    return (
      <Modal
        title={'Flag Item'}
        modalHeight={'l'}
        modalWidth={'m'}
        isPadded={true}
        isOpen={isOpen}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Flag'}
            cancelBtnText={'Cancel'}
            onClose={closeModal}
            isDangerousAction={true}
            formSubmission={true}
            formId={productionAssemblyFlagItemForm}
          />
        )}
      >
        {
          isOpen &&
          <form
            className='flagging__form'
            onSubmit={handleSubmit}
            id={productionAssemblyFlagItemForm}
          >
            <Field
              name={'flagType'}
              component={RadioGroup}
              groupClassName={'flagging__form-radio-group'}
              onChange={this.handleOptionChange}
              radioItems={[
                {
                  value: flagTypeEnum.Reprint,
                  disabled: item.decorationMethod !== decorationMethodEnum.HAG,
                  label: 'Send to Reprint',
                  className: 'ml-n10',
                },
                {
                  value: flagTypeEnum.Rework,
                  disabled: !item.previewUrl,
                  label: 'Send to Rework',
                },
                {
                  value: flagTypeEnum.WrongArtwork,
                  label: 'Send to Artwork Change',
                },
              ]}
            />

            <div className='flagging__form-group'>
              <Field
                name={'reason'}
                component={Select}
              >
                {getFlagReasonOptions(selectedOption, item.decorationMethod)}
              </Field>
            </div>
            <div className='flagging__form-group'>
              <div className='text-field flex'>
                <Field
                  name={'note'}
                  component={Input}
                  type={'text'}
                  placeholder={'Enter note*'}
                />
              </div>
            </div>
            {
              item &&
              (item.logoUrl
                ? (
                  <MarkItemAsFlaggedModalLogoDetails
                    previewUrl={item.previewUrl || item.logoUrl}
                    indicator={item.indicator}
                    orderNumber={orderNumber}
                    size={item.size || item.decorationLocation}
                    type={item.jobType}
                    barcode={item.barcode}
                    cutColor={item.color || item.colorId}
                  />
                )
                : (
                  <MarkItemAsFlaggedModalPersonalizationDetails
                    personalizationNumber={item.number}
                    personalizationText={item.text}
                    personalizationColor={colorsDictionary[item.colorId]}
                    orderNumber={orderNumber}
                    size={item.decorationLocation}
                  />
                )
              )
            }
          </form>
        }
      </Modal>
    );
  }
}

ProductionAssemblyFlaggingModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  item: PropTypes.object,
  orderNumber: PropTypes.number,
  orderId: PropTypes.number,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

export default (reduxForm({
  form: productionAssemblyFlagItemForm,
  validate: validateProductionAssemblyFlagItem,
  enableReinitialize: true,
})(ProductionAssemblyFlaggingModal));
