import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactImageMagnify from 'react-image-magnify';
import { transparentBackgoundOption } from '@constants/values';
import { deepMerge } from '@util/deepMerge';
import { getImageMagnifyDefaultOptions } from '@util/imageMagnify';
import Icon from '@sharedComponents/Icons/Icon';

class ImagePreview extends Component {
  constructor(props) {
    super(props);

    this.proportion = 1;
    this.state = {
      loaded: !!this.props.fileDimensions,
    };
  }

  componentDidMount() {
    const { fileDimensions } = this.props;
    if (!fileDimensions) {
      this._updateProportion();
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.filePath && this.props.filePath !== oldProps.filePath) {
      this._updateProportion(this.props.filePath);
    }
  }

  /*
   * For the Image() API, setting img.src is asynchronous and tied
   * to the onload event. If we attempt to set the proportion of the
   * image synchronously, we wind up plugging in values that have not
   * been determined yet -- so, it defaults to 1. Solution: set the src
   * inside a Promise, and resolve with the Image object.
   */
  _createImageMock = (filePath) => (
    new Promise((resolve, reject) => {
      const imgMock = new Image();
      imgMock.src = filePath;
      imgMock.onload = () => resolve(imgMock);
      imgMock.onerror = (event) => reject(event);
    })
  );

  _updateProportion = (file = this.props.filePath) => {
    this._createImageMock(file)
      .then((imgMock) => {
        this.proportion = imgMock.naturalWidth / imgMock.naturalHeight || 1;
        this.setState({ loaded: true });
      })
      .catch((err) => console.error(err));
  };

  render() {
    const {
      filePath,
      backgroundOption,
      options = {},
      hoveredColor,
    } = this.props;
    const { loaded } = this.state;

    const defaultStyle = {
      backgroundColor: hoveredColor || backgroundOption.background,
    };

    const transparentStyle = {
      background: backgroundOption.background,
    };

    const selectStyle = () => {
      if (!!hoveredColor) {
        return defaultStyle;
      } else if (backgroundOption.code === transparentBackgoundOption.code) {
        return transparentStyle;
      }

      return defaultStyle;
    };

    const ImageMagnifyDefaultOptions = getImageMagnifyDefaultOptions(filePath, this.proportion);
    ImageMagnifyDefaultOptions.enlargedImageContainerStyle = {
      ...selectStyle(),
      border: 0,
    };

    const ImageMagnifyOptions = deepMerge(ImageMagnifyDefaultOptions, options);

    if (!loaded) {
      return (
        <div className='magnify-image-container'>
          <span>
            <Icon
              fontAwesomeIcon={'spinner'}
              classes={'fa-spin'}
            />
            Loading...
          </span>
        </div>
      );
    }

    return (
      <div
        className='logo-review__task__preview__image-wrapper'
        style={{ ...selectStyle() }}
      >
        <div
          className='magnify-image-container'
          style={{ ...selectStyle() }}
        >
          <ReactImageMagnify {...ImageMagnifyOptions} />
        </div>
      </div>
    );
  }
}

ImagePreview.propTypes = {
  filePath: PropTypes.string.isRequired,
  backgroundOption: PropTypes.shape({
    code: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  hoveredColor: PropTypes.string,
  fileDimensions: PropTypes.object,
  options: PropTypes.object,
};

export default ImagePreview;
