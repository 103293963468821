import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Expander from '@sharedComponents/Buttons/Expander';
import Button from '@sharedComponents/Buttons/Button';
import ProductionAssemblyPrintQueue from './ProductionAssemblyPrintQueue';
import ProductionAssemblyPrintQueueTab from './ProductionAssemblyPrintQueueTab';
import ProductionAssemblyInProgressTab from './ProductionAssemblyInProgressTab';
import Tabs from '@sharedComponents/Tabs/Tabs';
import { connect } from 'react-redux';
import { getDtgTranmissionDecorations } from '@redux/productionAssembly/actions';
import { printQueueGroupByOptions } from '@constants/common';

const printQueueTabEnum = {
  PrintQueue: 'Print Queue',
  InProgress: 'In Progress',
};

class ProductionAssemblyPrintQueueDrawer extends PureComponent {
  state = {
    isExpanded: false,
    selectedTab: printQueueTabEnum.PrintQueue,
    dtgTransmissionDecorationsPageNumber: this.props.dtgTransmissionDecorationsPageNumber,
    dtgTransmissionDecorationsPageSize: this.props.dtgTransmissionDecorationsPageSize,
    selectedStation: '',
    selectedGroupByValue: printQueueGroupByOptions[0].value,
    disabledAfterSendingToPrint: false,
  };

  componentDidMount() {
    this.fetchDtgTransmissionDecorationsData();
  }

  fetchDtgTransmissionDecorationsData = (pageNumber, pageSize) => {
    const { dispatch } = this.props;

    this.setState(() => ({
      dtgTransmissionDecorationsPageNumber: pageNumber,
      dtgTransmissionDecorationsPageSize: pageSize,
    }));

    dispatch(getDtgTranmissionDecorations(
      pageNumber,
      pageSize
    ));
  };

  toggleExpanded = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  disableSendButtons = () => {
    this.setState(() => ({ disabledAfterSendingToPrint: true }));
  };

  getTabs = () => {
    const {
      printQueue,
      dtgStations,
      onRemoveItemFromPrintQueue,
      onRemoveItemGroupFromPrintQueue,
      onPrintQueueItemQuantityUpdate,
      onClearPrintQueue,
      dtgTransmissionDecorationsQueue,
      dtgTransmissionDecorationsTotalPages,
      dtgTransmissionDecorationsHasPreviousPage,
      dtgTransmissionDecorationsHasNextPage,
      dtgTransmissionDecorationsPageNumber,
      dtgTransmissionDecorationsPageSize,
      dtgTransmissionDecorationsTotalCount,
      dtgAnyActiveTransmissions,
    } = this.props;
    const {
      selectedStation,
      selectedGroupByValue,
      disabledAfterSendingToPrint,
    } = this.state;
    const printQueueLength = this.getPrintQueueTotalQuantity();

    const tabs = [
      {
        title: `Print Queue (${printQueueLength})`,
        name: printQueueTabEnum.PrintQueue,
        content: (
          <ProductionAssemblyPrintQueueTab
            printQueue={printQueue}
            onRemoveItemFromPrintQueue={onRemoveItemFromPrintQueue}
            onRemoveItemGroupFromPrintQueue={onRemoveItemGroupFromPrintQueue}
            onPrintQueueItemQuantityUpdate={onPrintQueueItemQuantityUpdate}
            onClearPrintQueue={onClearPrintQueue}
            dtgStations={dtgStations}
            totalQuantity={printQueueLength}
            refreshInProgressTab={this.fetchDtgTransmissionDecorationsData}
            selectedStation={selectedStation}
            onStationChange={this.selectStation}
            selectedGroupByValue={selectedGroupByValue}
            onGroupByOptionChange={this.selectGroupByValue}
            dtgAnyActiveTransmissions={dtgAnyActiveTransmissions}
            disableSendButtons={this.disableSendButtons}
            disabledAfterSendingToPrint={disabledAfterSendingToPrint}
          />
        ),
      },
      {
        title: `In Progress (${dtgTransmissionDecorationsTotalCount})`,
        name: printQueueTabEnum.InProgress,
        content: (
          <ProductionAssemblyInProgressTab
            queue={dtgTransmissionDecorationsQueue}
            totalPages={dtgTransmissionDecorationsTotalPages}
            hasPreviousPage={dtgTransmissionDecorationsHasPreviousPage}
            hasNextPage={dtgTransmissionDecorationsHasNextPage}
            pageNumber={dtgTransmissionDecorationsPageNumber}
            pageSize={dtgTransmissionDecorationsPageSize}
            fetchData={this.fetchDtgTransmissionDecorationsData}
          />
        ),
      },
    ];

    return tabs;
  };

  selectTab = (selectedTab) => {
    this.setState(() => ({ selectedTab }));
  };

  getPrintQueueTotalQuantity = () => {
    const { printQueue } = this.props;
    const totalQuantity = !!printQueue
      ? printQueue.reduce((total, current) => (total + (current.quantity || 0)), 0)
      : 0;

    return totalQuantity;
  };

  clearQueue = () => {
    const { onClearPrintQueue } = this.props;

    onClearPrintQueue();
  };

  selectGroupByValue = (selectedGroupByValue) => {
    this.setState(() => ({ selectedGroupByValue }));
  };

  selectStation = (selectedStation) => {
    this.setState(() => ({ selectedStation }));
  };

  render() {
    const {
      dtgStations,
      onRemoveItemFromPrintQueue,
      onRemoveItemGroupFromPrintQueue,
      onPrintQueueItemQuantityUpdate,
      dtgTransmissionDecorationsTotalCount,
      onClearPrintQueue,
      printQueue,
      dtgAnyActiveTransmissions,
    } = this.props;

    const {
      isExpanded,
      selectedTab,
      selectedStation,
      selectedGroupByValue,
      disabledAfterSendingToPrint,
    } = this.state;

    const totalQuantity = this.getPrintQueueTotalQuantity();

    return (
      <>
        {
          isExpanded &&
          <div
            className='order-assembly__print-queue-overlay'
            onClick={this.toggleExpanded}
          />
        }

        <div className={`order-assembly__print-queue-container ${isExpanded ? 'order-assembly__print-queue-container--expanded' : ''}`}>
          <div className='order-assembly__print-queue-header'>
            <div className='container'>
              <div className='flex h-100 align__center justify__space-between'>
                <div className='flex'>
                  <div className='order-assembly__print-queue-header-counter'>
                    <b>{totalQuantity} Items</b> Queued for Print
                  </div>
                  {
                    selectedTab === printQueueTabEnum.PrintQueue &&
                    <Button
                      type={'secondary'}
                      text={'Clear Queue'}
                      onClick={this.clearQueue}
                      classes={'uppercase button--size-s'}
                    />
                  }
                </div>
                <Expander
                  isExpanded={isExpanded}
                  toggle={this.toggleExpanded}
                  collapseText={'Close'}
                  expandText={'Open'}
                  classes={'order-assembly__print-queue-expander'}
                />
              </div>
            </div>
          </div>
          {
            dtgTransmissionDecorationsTotalCount > 0 ? (
              <div className='order-assembly__print-queue-list-container'>
                <Tabs
                  tabs={this.getTabs()}
                  selectedTab={selectedTab}
                  selectTab={this.selectTab}
                  containerClasses={''}
                />
              </div>
            )
              : (
                <div className='order-assembly__print-queue-list-container'>
                  <ProductionAssemblyPrintQueue
                    printQueue={printQueue}
                    onRemoveItemFromPrintQueue={onRemoveItemFromPrintQueue}
                    onRemoveItemGroupFromPrintQueue={onRemoveItemGroupFromPrintQueue}
                    onPrintQueueItemQuantityUpdate={onPrintQueueItemQuantityUpdate}
                    onClearPrintQueue={onClearPrintQueue}
                    dtgStations={dtgStations}
                    totalQuantity={totalQuantity}
                    refreshInProgressTab={this.fetchDtgTransmissionDecorationsData}
                    selectedStation={selectedStation}
                    onStationChange={this.selectStation}
                    selectedGroupByValue={selectedGroupByValue}
                    onGroupByOptionChange={this.selectGroupByValue}
                    dtgAnyActiveTransmissions={dtgAnyActiveTransmissions}
                    disableSendButtons={this.disableSendButtons}
                    disabledAfterSendingToPrint={disabledAfterSendingToPrint}
                  />
                </div>
              )
          }
        </div>
      </>
    );
  }
}

ProductionAssemblyPrintQueueDrawer.propTypes = {
  printQueue: PropTypes.array,
  onClearPrintQueue: PropTypes.func.isRequired,
  onRemoveItemFromPrintQueue: PropTypes.func.isRequired,
  onRemoveItemGroupFromPrintQueue: PropTypes.func.isRequired,
  onPrintQueueItemQuantityUpdate: PropTypes.func.isRequired,
  dtgStations: PropTypes.array.isRequired,
  dtgTransmissionDecorationsQueue: PropTypes.arrayOf(PropTypes.object).isRequired,
  dtgTransmissionDecorationsPageNumber: PropTypes.number.isRequired,
  dtgTransmissionDecorationsPageSize: PropTypes.number.isRequired,
  dtgTransmissionDecorationsHasPreviousPage: PropTypes.bool,
  dtgTransmissionDecorationsHasNextPage: PropTypes.bool,
  dtgTransmissionDecorationsTotalPages: PropTypes.number.isRequired,
  dtgTransmissionDecorationsTotalCount: PropTypes.number.isRequired,
  dtgAnyActiveTransmissions: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ productionAssembly }) => ({
  dtgTransmissionDecorationsQueue: productionAssembly.dtgTransmissionDecorationsQueue.queue,
  dtgTransmissionDecorationsPageNumber: productionAssembly.dtgTransmissionDecorationsQueue.pageNumber,
  dtgTransmissionDecorationsPageSize: productionAssembly.dtgTransmissionDecorationsQueue.pageSize,
  dtgTransmissionDecorationsHasPreviousPage: productionAssembly.dtgTransmissionDecorationsQueue.hasPreviousPage,
  dtgTransmissionDecorationsHasNextPage: productionAssembly.dtgTransmissionDecorationsQueue.hasNextPage,
  dtgTransmissionDecorationsTotalPages: productionAssembly.dtgTransmissionDecorationsQueue.totalPages,
  dtgTransmissionDecorationsTotalCount: productionAssembly.dtgTransmissionDecorationsQueue.totalCount,
});

export default connect(mapStateToProps)(ProductionAssemblyPrintQueueDrawer);
