import React from 'react';
import { applicationSettingsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const ApplicationSettingsLink = React.memo(() => (
  <MenuTile
    title={'Application Settings'}
    url={applicationSettingsUrl}
    icon={'settings_applications'}
  />
));

export default ApplicationSettingsLink;
