import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import Icon from '@sharedComponents/Icons/Icon';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import { QualityAssuranceCodeDto } from '@api/fulfillment/models';
import EditQualityAssuranceCodeModal from './EditQualityAssuranceCodeModal';
import DeleteQualityAssuranceCodeModal from './DeleteQualityAssuranceCodeModal';

interface OwnProps {
  item: QualityAssuranceCodeDto;
  refetch: () => unknown;
}

type Props = OwnProps;

export const QualityAssuranceCodesActionsColumn: React.FC<Props> = React.memo(({
  item, refetch,
}) => {
  const [
    extended,
    setExtended,
  ] = useState(false);
  const [
    openEdit,
    setOpenEdit,
  ] = useState(false);
  const [
    openDelete,
    setOpenDelete,
  ] = useState(false);

  const handleClose = useCallback(() => setExtended(false), []);
  const handleToggle = useCallback(() => setExtended((x) => !x), []);
  const handleEdit = useCallback((e: React.SyntheticEvent) => {
    e?.preventDefault?.();
    setExtended(false);
    setOpenEdit(true);
  }, []);
  const handleDelete = useCallback((e: React.SyntheticEvent) => {
    e?.preventDefault?.();
    setExtended(false);
    setOpenDelete(true);
  }, []);

  const handleEditClose = useCallback(() => setOpenEdit(false), []);
  const handleEditAfter = useCallback(() => {
    setOpenEdit(false);
    refetch();
  }, [refetch]);

  const handleDeleteClose = useCallback(() => setOpenDelete(false), []);
  const handleDeleteAfter = useCallback(() => {
    setOpenDelete(false);
    refetch();
  }, [refetch]);

  const actionsMenu = useMemo(() => {
    const className = 'lockerManager__item-list colors__action-item-list is-active';

    if (!extended) return null;

    return (
      <ul className={className}>
        <li>
          <button
            onClick={handleEdit}
            type='button'
          >
            Edit
          </button>
        </li>
        <li className='lockerManager__item-list--border' />
        <li>
          <button
            onClick={handleDelete}
            type='button'
            className='red'
          >
            Delete
          </button>
        </li>
      </ul>
    );
  }, [
    extended,
    handleDelete,
    handleEdit,
  ]);

  return (
    <>
      <OutsideClickWrapper onClick={handleClose}>
        <div className='colors__actions-container'>
          <button
            className='colors__actions-btn'
            onClick={handleToggle}
          >
            <Icon
              materialIcon={'more_vert'}
              classes={'button'}
            />
          </button>
          {actionsMenu}
        </div>
      </OutsideClickWrapper>
      <EditQualityAssuranceCodeModal
        isOpen={openEdit}
        closeModal={handleEditClose}
        onEdit={handleEditAfter}
        item={item}
      />
      <DeleteQualityAssuranceCodeModal
        isOpen={openDelete}
        closeModal={handleDeleteClose}
        onDelete={handleDeleteAfter}
        item={item}
      />
    </>
  );
});

export default QualityAssuranceCodesActionsColumn;
