import React from 'react';
import {
  productionAssemblyEmbReviewByOrderUrl,
  productionAssemblyEmbReviewByBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import OrderSearchByDecorationMethod from '@sharedComponents/Production/Search/OrderSearchByDecorationMethod';

const ProductionAssemblyEmbSearch = React.memo(() => (
  <OrderSearchByDecorationMethod
    decorationMethod={decorationMethodEnum.EMB}
    reviewByOrderUrl={productionAssemblyEmbReviewByOrderUrl}
    reviewByBarcodeUrl={productionAssemblyEmbReviewByBarcodeUrl}
  />
));

export default ProductionAssemblyEmbSearch;
