import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  formValueSelector,
  reduxForm,
} from 'redux-form';
import { uploadFile } from '@APICalls/support/actions';
import { uploadFile as uploadFileUrl } from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { catalogApi } from '../../../sqdApis';
import { editStyleForm } from '@constants/reduxForms';
import { dragAndDropMessage } from '@constants/values';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { validateLayouts } from '@redux/layouts/actions';
import { validateStyle } from '@redux/productCatalog/validations';
import {
  roleMapping,
  checkPermissions,
} from '@util/roleCheck';
import Tabs from '@sharedComponents/Tabs/Tabs';
import FormError from '@sharedComponents/Form/FormError';
import BasicInfoTabContent from './BasicInfoTabContent';
import DecorationsAndLayoutsTabContent from './DecorationsAndLayoutsTabContent';
import SportsTabContent from './SportsTabContent';

const acceptedFileType = '.pdf';
const defaultMessage = dragAndDropMessage('pdf');

const styleFormTabEnum = {
  BasicInfo: 'BasicInfo',
  DecorationsAndLayouts: 'DecorationsAndLayouts',
  Sports: 'Sports',
};

class StyleForm extends PureComponent {
  state = {
    sizeChartUrl: this.props.initialValues ? this.props.initialValues.sizeChartUrl : '',
    vendorChanged: false,
    messagePdfFile: {
      type: 'default',
      body: defaultMessage,
    },
    loadingPdfFile: false,
    selectedTab: checkPermissions(this.props.roles, PermissionsEnum.ProductCatalogEdit)
      ? styleFormTabEnum.BasicInfo
      : styleFormTabEnum.DecorationsAndLayouts,
  };

  onKeyPress = (e) => {
    if (e.key === keyNameEnum.Enter) {
      e.preventDefault();
    }
  };

  onDropSizeChartPdf = async (acceptedFiles, rejectedFiles) => {
    const { change } = this.props;

    if (rejectedFiles.length > 0) {
      this.setState(() => ({
        messagePdfFile: {
          type: 'reject',
          body: `${acceptedFileType} files required.`,
        },
      }));
    } else {
      this.setState(() => ({
        messagePdfFile: {
          body: `File ${acceptedFiles[0].name} has been attached.`,
          type: 'default',
        },
        loadingPdfFile: true,
      }), async () => {
        const res = await uploadFile(acceptedFiles[0], catalogApi, uploadFileUrl);

        if (res) {
          change('sizeChartUrl', res);
        }

        this.setState(() => ({ loadingPdfFile: false }));
      });
    }
  };

  removeSizeChartPdf = () => {
    const { change } = this.props;

    this.setState(() => ({
      sizeChartUrl: null,
      messagePdfFile: {
        type: 'default',
        body: `${defaultMessage}`,
      },
    }));

    change('sizeChartUrl', null);
  };

  changeVendor = async (vendor) => {
    const {
      change,
      initialValues,
    } = this.props;

    const vendorChanged = vendor?.code !== initialValues?.vendorId;

    this.setState(() => ({
      selectedVendor: vendor,
      vendorChanged,
    }));
    await change('vendorId', vendor?.code);
  };

  selectTab = (selectedTab) => {
    this.setState(() => ({ selectedTab }));
  };

  getTabs = () => {
    const {
      disabled = {},
      sampleImage,
      decorationMethod,
      customizable,
      currentDefaultLogo,
      logoLocations,
      defaultPersonalizations,
      customOnlyPersonalizations,
      vendorId,
      layoutsInputList,
      inventoryAlwaysAvailable,
      change,
    } = this.props;

    const {
      loadingPdfFile,
      messagePdfFile,
      sizeChartUrl,
      vendorChanged,
    } = this.state;

    const tabs = [
      {
        title: 'Basic Information',
        name: styleFormTabEnum.BasicInfo,
        permissions: [PermissionsEnum.ProductCatalogEdit],
        content: (
          <BasicInfoTabContent
            disabled={disabled}
            sampleImage={sampleImage}
            vendorId={vendorId}
            vendorChanged={vendorChanged}
            changeVendor={this.changeVendor}
            change={change}
            loadingPdfFile={loadingPdfFile}
            messagePdfFile={messagePdfFile}
            sizeChartUrl={sizeChartUrl}
            removeSizeChartPdf={this.removeSizeChartPdf}
            onDropSizeChartPdf={this.onDropSizeChartPdf}
            inventoryAlwaysAvailable={inventoryAlwaysAvailable}
          />
        ),
      },
      {
        title: 'Decorations & Layouts',
        name: styleFormTabEnum.DecorationsAndLayouts,
        content: (
          <DecorationsAndLayoutsTabContent
            disabled={disabled}
            decorationMethod={decorationMethod}
            customizable={customizable}
            currentDefaultLogo={currentDefaultLogo}
            logoLocations={logoLocations}
            defaultPersonalizations={defaultPersonalizations}
            customOnlyPersonalizations={customOnlyPersonalizations}
            layoutsInputList={layoutsInputList}
            changeDefaultLogo={this.changeDefaultLogo}
            change={change}
          />
        ),
      },
      {
        title: 'Sports',
        name: styleFormTabEnum.Sports,
        permissions: [PermissionsEnum.ProductCatalogEdit],
        content: <SportsTabContent />,
      },
    ];

    return tabs;
  };

  render() {
    const {
      handleSubmit,
      error,
    } = this.props;

    const { selectedTab } = this.state;

    return (
      <form
        className='redux-form w-100'
        onSubmit={handleSubmit}
        onKeyPress={this.onKeyPress}
        id={editStyleForm}
      >
        <div>
          <Tabs
            tabs={this.getTabs()}
            selectedTab={selectedTab}
            selectTab={this.selectTab}
            classes={'mb-40'}
          />
          <FormError error={error} />
        </div>
      </form>
    );
  }
}

StyleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  style: PropTypes.object,
  error: PropTypes.string,
  disabled: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  sampleImage: PropTypes.string,
  decorationMethod: PropTypes.string,
  customizable: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  currentDefaultLogo: PropTypes.object,
  logoLocations: PropTypes.arrayOf(PropTypes.object),
  customOnlyPersonalizations: PropTypes.arrayOf(PropTypes.object),
  defaultPersonalizations: PropTypes.arrayOf(PropTypes.object),
  layoutsInputList: PropTypes.arrayOf(PropTypes.object).isRequired,
  vendorId: PropTypes.string,
  inventoryAlwaysAvailable: PropTypes.bool,
  change: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

const selector = formValueSelector(editStyleForm);
export default connect((state) => ({
  decorationMethod: selector(state, 'decorationMethod'),
  customizable: selector(state, 'customizable'),
  currentDefaultLogo: selector(state, 'defaultLogoLocation'),
  logoLocations: selector(state, 'customOnlyLogos'),
  customOnlyPersonalizations: selector(state, 'customOnlyPersonalizations'),
  defaultPersonalizations: selector(state, 'defaultPersonalizations'),
  layoutsInputList: selector(state, 'layoutsInputList') || [],
  vendorId: selector(state, 'vendorId'),
  inventoryAlwaysAvailable: selector(state, 'alwaysAvailableVendorInventory'),
  roles: roleMapping(state.oidc),
}))(reduxForm({
  form: editStyleForm,
  enableReinitialize: true,
  validate: validateStyle,
  onChange: (values, dispatch, props, previousValues) => {
    if (values.layoutsInputList !== previousValues.layoutsInputList
      || values.defaultLogoLocation !== previousValues.defaultLogoLocation
      || values.customOnlyLogos !== previousValues.customOnlyLogos
      || values.defaultPersonalizations !== previousValues.defaultPersonalizations
      || values.customOnlyPersonalizations !== previousValues.customOnlyPersonalizations) {
      dispatch(validateLayouts(
        values.layoutsInputList,
        values.defaultLogoLocation,
        values.customOnlyLogos,
        values.defaultPersonalizations,
        values.customOnlyPersonalizations
      ));
    }
  },
})(StyleForm));
