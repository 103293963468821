import { fulfillmentApi } from '../../sqdApis';
import { makeApiCallWithErrorModal } from '@util/apiHelper';
import llSchedulingUrls from '@constants/sqdApiUrls/llSchedulingUrls';

export const editQueueDailyCapacity = async (queueId, dailyCapacity) => {
  const call = fulfillmentApi.put(llSchedulingUrls.updateQueueCapacity, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      schedulingQueueId: queueId,
      capacity: dailyCapacity.dailyCapacity,
    }),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
