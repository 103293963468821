import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dtgColoredStyleForm } from '@constants/reduxForms';
import {
  getSingleDtgColoredStyle,
  getDtgEnvironmentOptions,
  getDtgTabletProfileOptions,
  getDtgPalletOptions,
  getDtgPretreatmentBatchOptions,
} from '@redux/productCatalog/actions';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import DtgColoredStyleForm from './DtgColoredStyleForm';

class EditDtgColoredStyleModal extends PureComponent {
  componentDidMount() {
    const {
      dispatch,
      coloredStyleId,
    } = this.props;
    dispatch(getDtgEnvironmentOptions());
    dispatch(getDtgPalletOptions());
    dispatch(getDtgPretreatmentBatchOptions());
    dispatch(getDtgTabletProfileOptions());
    dispatch(getSingleDtgColoredStyle(coloredStyleId));
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      coloredStyleId,
    } = this.props;
    const oldColoredStyleId = prevProps.coloredStyleId;

    if (coloredStyleId !== oldColoredStyleId) {
      dispatch(getSingleDtgColoredStyle(coloredStyleId));
    }
  }

  render() {
    const {
      modalIsOpen,
      dtgEnvironmentOptions,
      dtgTabletProfileOptions,
      dtgPalletOptions,
      dtgPretreatmentBatchOptions,
      dtgColoredStyle,
      handleEdit,
      handleCloseModal,
    } = this.props;

    return (
      <Modal
        title={'Edit Colored Style'}
        modalSize={'xl'}
        isOpen={modalIsOpen}
        closeModal={handleCloseModal}
        buttons={(
          <ModalButtons
            onClose={handleCloseModal}
            formSubmission={true}
            confirmBtnText={'Save'}
            formId={dtgColoredStyleForm}
          />
        )}
      >
        <DtgColoredStyleForm
          initialValues={dtgColoredStyle}
          dtgEnvironmentOptions={dtgEnvironmentOptions}
          dtgPalletOptions={dtgPalletOptions}
          dtgPretreatmentBatchOptions={dtgPretreatmentBatchOptions}
          dtgTabletProfileOptions={dtgTabletProfileOptions}
          onSubmit={handleEdit}
        />
      </Modal>
    );
  }
}

EditDtgColoredStyleModal.propTypes = {
  coloredStyleId: PropTypes.number.isRequired,
  dtgColoredStyle: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  dtgEnvironmentOptions: productCatalog.dtgEnvironmentOptions,
  dtgPalletOptions: productCatalog.dtgPalletOptions,
  dtgPretreatmentBatchOptions: productCatalog.dtgPretreatmentBatchOptions,
  dtgTabletProfileOptions: productCatalog.dtgTabletProfileOptions,
  dtgColoredStyle: productCatalog.dtgColoredStyle,
});

export default connect(mapStateToProps)(EditDtgColoredStyleModal);
