/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type VoucherOrderPaymentStatusEnum = 'Undefined' | 'Pending' | 'Paid';


export const VoucherOrderPaymentStatusEnum = {
  Undefined: 'Undefined' as VoucherOrderPaymentStatusEnum,
  Pending: 'Pending' as VoucherOrderPaymentStatusEnum,
  Paid: 'Paid' as VoucherOrderPaymentStatusEnum,
};
