import llSchedulingUrls from '@constants/sqdApiUrls/llSchedulingUrls';
import { materialSwal } from '@util/componentHelper';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import { fulfillmentApi } from '../../sqdApis';
import * as actionTypes from './types';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';

const readyStatuses = {
  statuses: [orderItemStatusEnum.AwaitingDispatchForPicking],
};

const inProgressStatuses = {
  statuses: [
    orderItemStatusEnum.InPicking,
    orderItemStatusEnum.CheckedIn,
    orderItemStatusEnum.Assembled,
    orderItemStatusEnum.PreTreated,
    orderItemStatusEnum.ProductionCompleted,
    orderItemStatusEnum.Waterproofed,
    orderItemStatusEnum.QualityChecked,
  ],
};

export const updateSchedulingQueues = (data) => ({
  type: actionTypes.UPDATE_SCHEDULING_QUEUES,
  data,
});

export const updateSchedulingQueue = (data) => ({
  type: actionTypes.UPDATE_SCHEDULING_QUEUE,
  data,
});

export const updateReadySchedulingQueueItems = (data) => ({
  type: actionTypes.UPDATE_READY_SCHEDULING_QUEUE_ITEMS,
  data,
});

export const updateInProgressSchedulingQueueItems = (data) => ({
  type: actionTypes.UPDATE_IN_PROGRESS_SCHEDULING_QUEUE_ITEMS,
  data,
});

export const schedulingQueueItemsSentToPicking = (data) => ({
  type: actionTypes.DISPATCH_SCHEDULING_QUEUE_ITEMS_TO_PICKING,
  data,
});

export const getSchedulingQueueItemTooltip = (id, data) => ({
  type: actionTypes.GET_SCHEDULING_QUEUE_TOOLTIP,
  id,
  data,
});

export const getSchedulingQueueItemInProgressTooltip = (orderNumber, data) => ({
  type: actionTypes.GET_SCHEDULING_QUEUE_IN_PROGRESS_TOOLTIP,
  id: orderNumber,
  data,
});

export const fetchSchedulingQueues = () => async (dispatch) => {
  const call = fulfillmentApi.get(llSchedulingUrls.queues, { handleBlockingLoading: false });
  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateSchedulingQueues(response));
  }
};

export const fetchSchedulingQueue = (queueId) => async (dispatch) => {
  const call = fulfillmentApi.get(llSchedulingUrls.queueId(queueId), { handleBlockingLoading: false });
  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateSchedulingQueue(response));
  }
};

export const fetchReadySchedulingQueueItems = (queueId) => async (dispatch) => {
  const call = fulfillmentApi.get(
    llSchedulingUrls.getSchedulingQueueItems(queueId, readyStatuses),
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateReadySchedulingQueueItems(res));
  }
};

export const fetchInProgressSchedulingQueueItems = (queueId) => async (dispatch) => {
  const call = fulfillmentApi.get(
    llSchedulingUrls.getSchedulingQueueItems(queueId, inProgressStatuses),
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateInProgressSchedulingQueueItems(res));
  }
};

export const dispatchQueueItemsToPicking = (queueItems, queueId) => async (dispatch) => {
  const body = {
    ...queueItems,
  };
  const call = fulfillmentApi.put(llSchedulingUrls.dispatchQueueItemsForPicking(queueId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const res = await makeApiCallWithErrorModal(call);
  if (res?.success) {
    materialSwal('Success', res.message, 'success');
    dispatch(fetchReadySchedulingQueueItems(queueId));
    dispatch(fetchSchedulingQueue(queueId));
  }
};

export const fetchSchedulingQueueTooltip = (queueItemId) => async (dispatch) => {
  const call = fulfillmentApi.get(llSchedulingUrls.getSchedulingQueueItemTooltip(queueItemId),
    { handleBlockingLoading: false });
  const response = await makeApiCall(call);

  if (response) {
    dispatch(getSchedulingQueueItemTooltip(queueItemId, response));
  }
};

export const fetchSchedulingQueueInProgressTooltip = (orderNumber) => async (dispatch) => {
  const call = fulfillmentApi.get(llSchedulingUrls.getSchedulingQueueItemInProgressTooltip(orderNumber),
    { handleBlockingLoading: false });

  const response = await makeApiCall(call);
  if (response) {
    dispatch(getSchedulingQueueItemInProgressTooltip(orderNumber, response));
  }
};
