import { catalogApi } from '../../sqdApis';
import inventoryUrls from '@constants/sqdApiUrls/onHandInventoryUrls';
import * as actionTypes from './types';
import { makeApiCall } from '@util/apiHelper';

export const updateInventoryItems = (data) => ({
  type: actionTypes.UPDATE_INVENTORY,
  items: data.items,
  tableState: data.tableState,
});

export const fetchInventoryItems = (params = {}) => async (dispatch) => {
  const pageNumber = params.pageNumber || 1;
  const pageSize = params.pageSize || 10;
  const brands = params.brands || '';
  const colors = params.colors || '';
  const sizes = params.sizes || '';
  const ages = params.ages || '';
  const genders = params.genders || '';
  const categories = params.categories || '';
  const search = params.search || '';

  const call = catalogApi.get(
    inventoryUrls.onHandInventory(
      pageNumber,
      pageSize,
      brands,
      colors,
      sizes,
      ages,
      genders,
      categories,
      search
    ),
    { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);
  if (res) {
    const {
      pageNumber: page,
      pageSize: pageLength,
      totalPages,
      totalCount,
      hasPreviousPage,
      hasNextPage,
      items,
    } = res;

    dispatch(updateInventoryItems({
      items,
      tableState: {
        pageNumber: page,
        pageSize: pageLength,
        totalCount,
        totalPages,
        hasPreviousPage,
        hasNextPage,
      },
    }));
  }
};
