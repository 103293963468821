import React, {
  useCallback,
  useEffect,
} from 'react';
import { QueriesVariationDto } from '@api/orders/models';
import { useGetHomefieldApiVariationsId } from '@api/orders/variations';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import {
  OrderManagementTempItem,
  OrderManagementTempItemPersonalization,
} from '@redux/orderManagement/models';
import Icon from '@sharedComponents/Icons/Icon';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import Tag from '@sharedComponents/Display/Tag';
import Image from '@sharedComponents/Display/Image';
import OrderManagementDetailsItemLabels from '../../../OrderManagement/OrderManagementDetailsItemLabels';
import OrderItemActions from './OrderItemActions';
import OrderItemExpand from './OrderItemExpand';

interface OwnProps {
  item: OrderManagementTempItem;
  isCollapsed: boolean;
  openItemInfoModal: (item: OrderManagementTempItem) => void;
  openPreviewModal: (imageUrl: string) => void;
  openChangeQuantityModal: (item: OrderManagementTempItem) => void;
  openDuplicateItemModal: (item: OrderManagementTempItem) => void;
  openRemoveItemModal: (item: OrderManagementTempItem) => void;
  openAddPersonalizationModal: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  openEditPersonalizationModal: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  removePersonalization: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  toggleCollapseItem: (id: symbol) => void;
  colorsDictionary: any;
  updateItemVariation: (itemId: symbol, variation: QueriesVariationDto) => void;
}

type Props = OwnProps;

const OrderItem = React.memo(({
  item,
  isCollapsed,
  openItemInfoModal,
  openPreviewModal,
  openChangeQuantityModal,
  openDuplicateItemModal,
  openRemoveItemModal,
  openAddPersonalizationModal,
  openEditPersonalizationModal,
  toggleCollapseItem,
  colorsDictionary,
  removePersonalization,
  updateItemVariation,
}: Props) => {
  const {
    data: variation,
    isLoading: isLoadingVariation,
    isError: isErrorVariation,
    refetch: refetchVariation,
  } = useGetHomefieldApiVariationsId(item.variationId, {});

  useEffect(() => {
    if (!item.itemId) {
      refetchVariation();
    }
  }, [
    item,
    refetchVariation,
  ]);

  useEffect(() => {
    if (!item.itemId && !isLoadingVariation && !isErrorVariation && variation) {
      updateItemVariation(item.id, variation as QueriesVariationDto);
    }
  }, [
    isLoadingVariation,
    isErrorVariation,
    variation,
    item,
    updateItemVariation,
  ]);

  const openInfoModal = useCallback(() => {
    openItemInfoModal(item);
  }, [
    item,
    openItemInfoModal,
  ]);

  const changeExtend = useCallback(() => {
    toggleCollapseItem(item.id);
  }, [
    item,
    toggleCollapseItem,
  ]);

  let artworkFilesExtended = !isCollapsed;
  let enableExpanding = true;

  if (item?.logos && item.logos.length === 0 &&
    item.personalizations && item.personalizations.length === 0) {
    artworkFilesExtended = false;
    enableExpanding = false;
  }

  return (
    <div className='order__item-container'>
      <div className='order__item'>
        <div className='order__item-column align__center'>
          <Image
            url={item.image}
            openInModal={openInfoModal}
            showImageInModal={false}
          />
        </div>
        <div className='order__item-column'>
          <div className='order__item--sku-info'>
            <div className='flex'>
              <span>{item.sku}</span>
              <OrderManagementDetailsItemLabels item={item} />
            </div>
          </div>

          <div className='order__item--title'>
            <span className='order__item--style-name'>{item.name}</span>
          </div>
          <ul className='order__item-tags'>
            <li>
              <Tag value={`x${item.quantity}`} />
            </li>
            <li>
              <Tag
                label={'Size'}
                value={item.size}
              />
            </li>
            <li>
              <Tag
                label={'Color'}
                value={item.colorGroup}
              />
            </li>
          </ul>
        </div>

        <PermissionHandler
          permissions={PermissionsEnum.OrderManagementEdit}
          fallback={(
            <div className='order__item-buttons'>
              <div onClick={changeExtend}>
                <Icon materialIcon={enableExpanding ? (artworkFilesExtended ? 'arrow_drop_up' : 'arrow_drop_down') : ''} />
              </div>
            </div>
          )}
        >
          <div className='order__item-buttons'>
            <OrderItemActions
              item={item}
              openChangeQuantityModal={openChangeQuantityModal}
              openDuplicateItemModal={openDuplicateItemModal}
              openRemoveItemModal={openRemoveItemModal}
            />
            <div onClick={changeExtend}>
              <Icon materialIcon={enableExpanding ? (artworkFilesExtended ? 'arrow_drop_up' : 'arrow_drop_down') : ''} />
            </div>
          </div>
        </PermissionHandler>
      </div>
      {
        artworkFilesExtended &&
        <OrderItemExpand
          item={item}
          openImageModal={openPreviewModal}
          addPersonalization={openAddPersonalizationModal}
          editPersonalization={openEditPersonalizationModal}
          removePersonalization={removePersonalization}
          colorsDictionary={colorsDictionary}
        />
      }
    </div>
  );
});

export default OrderItem;
