import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getProductBySku,
  getColors,
  getDecorationLocations,
  getColoredStyle,
} from '@redux/productCatalog/actions';
import { fetchTeamColors } from '@redux/support/actions';
import { getLockerItem } from '@redux/lockerManager/actions';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import Tab from '@sharedComponents/Tabs/Tab';
import ItemInfoProductInformation from './ItemInfoProductInformation';
import ItemSetupInformation from './ItemSetupInformation';
import ItemInfoMockupsInformation from './ItemInfoMockupsInformation';
import ItemInventoryStatusInfo from './ItemInventoryStatusInfo';

const itemInfoTabEnum = {
  ProductCatalog: 'ProductCatalog',
  ItemSetup: 'ItemSetup',
  Mockups: 'Mockups',
  InventoryStatus: 'InventoryStatus',
};

class ItemInfo extends PureComponent {
  state = {
    selectedTab: itemInfoTabEnum.ProductCatalog,
    imageModalIsOpen: false,
    image: '',
  };

  componentDidMount() {
    const {
      dispatch,
      sku,
      lockerItemId,
      coloredStyleItemId,
    } = this.props;

    if (sku) {
      dispatch(getProductBySku(sku));
    }
    dispatch(getColors());
    dispatch(getDecorationLocations());
    dispatch(fetchTeamColors());

    if (lockerItemId) {
      dispatch(getLockerItem(lockerItemId));
    }

    if (coloredStyleItemId) {
      dispatch(getColoredStyle(coloredStyleItemId));
    }
  }

  openModalWithImage = (image) => {
    this.setState({
      imageModalIsOpen: true,
      image,
    });
  };

  closeModalWithImage = () => {
    this.setState({
      imageModalIsOpen: false,
      image: '',
    });
  };

  selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  render() {
    const {
      product,
      colorsDictionary,
      lockerItem,
      lockerItemId,
      colors,
      allDecorationLocations,
      images,
      coloredStyle,
      sku,
      teamColorsByCode,
    } = this.props;

    const {
      image,
      imageModalIsOpen,
      selectedTab,
    } = this.state;

    return (
      <div>
        <ImagePreviewModal
          modalIsOpen={imageModalIsOpen}
          closeModal={this.closeModalWithImage}
          imageUrl={image}
        />
        <div className='tabs mb-15 pr-5'>
          {
            <Tab
              key={itemInfoTabEnum.ProductCatalog}
              title={'Style'}
              isActive={selectedTab === itemInfoTabEnum.ProductCatalog}
              onSelectTab={this.selectTab.bind(null, itemInfoTabEnum.ProductCatalog)}
            />
          }
          {
            lockerItemId &&
            <Tab
              key={itemInfoTabEnum.ItemSetup}
              title={'Item Setup'}
              isActive={selectedTab === itemInfoTabEnum.ItemSetup}
              onSelectTab={this.selectTab.bind(null, itemInfoTabEnum.ItemSetup)}
            />
          }
          {
            <Tab
              key={itemInfoTabEnum.InventoryStatus}
              title={'Inventory'}
              isActive={selectedTab === itemInfoTabEnum.InventoryStatus}
              onSelectTab={this.selectTab.bind(null, itemInfoTabEnum.InventoryStatus)}
            />
          }
          {
            images !== undefined && images.length > 0 &&
            <Tab
              key={itemInfoTabEnum.Mockups}
              title={'Mockups'}
              isActive={selectedTab === itemInfoTabEnum.Mockups}
              onSelectTab={this.selectTab.bind(null, itemInfoTabEnum.Mockups)}
            />
          }
        </div>

        <div>
          {
            (sku || coloredStyle) && selectedTab === itemInfoTabEnum.ProductCatalog &&
            <ItemInfoProductInformation
              product={product}
              coloredStyle={coloredStyle}
              hasSku={!!sku}
              colors={colors}
              colorsDictionary={colorsDictionary}
              openModalWithImage={this.openModalWithImage}
              decorationLocations={allDecorationLocations}
              teamColorsByCode={teamColorsByCode}
            />
          }

          {
            selectedTab === itemInfoTabEnum.ItemSetup && lockerItem &&
            <ItemSetupInformation
              lockerItem={lockerItem}
              openModalWithImage={this.openModalWithImage}
              decorationLocations={allDecorationLocations}
            />
          }

          {
            selectedTab === itemInfoTabEnum.InventoryStatus &&
            <ItemInventoryStatusInfo
              product={product}
              coloredStyleItem={coloredStyle}
              hasSku={!!sku}
            />
          }

          {
            selectedTab === itemInfoTabEnum.Mockups &&
            <ItemInfoMockupsInformation
              openModalWithImage={this.openModalWithImage}
              images={images}
            />
          }
        </div>
      </div>
    );
  }
}

ItemInfo.propTypes = {
  sku: PropTypes.string,
  product: PropTypes.object,
  coloredStyleItemId: PropTypes.number,
  lockerItemId: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  lockerItem: PropTypes.object,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  allDecorationLocations: PropTypes.array.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
  coloredStyle: PropTypes.object,
  teamColorsByCode: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.string,
  })).isRequired,
};

const mapStateToProps = ({
  productCatalog,
  lockerManager,
  support,
}) => ({
  product: productCatalog.product,
  colorsDictionary: productCatalog.colorsDictionary,
  colors: productCatalog.colors,
  lockerItem: lockerManager.lockerItem,
  allDecorationLocations: productCatalog.decorationLocations,
  coloredStyle: productCatalog.coloredStyleInfo,
  teamColorsByCode: support.teamColorsByCode,
});

export default connect(mapStateToProps)(ItemInfo);
