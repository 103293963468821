import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const CategoryDeleteModal = React.memo(({
  isOpen,
  closeModal,
  category,
  deleteCategory,
}) => (
  <Modal
    title={'Delete Category'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteCategory.bind(null, category.id)}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to remove category <i>{category.name}</i> from the list?</h3>
  </Modal>
));

CategoryDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  deleteCategory: PropTypes.func.isRequired,
};

export default CategoryDeleteModal;
