import React from 'react';
import PermissionHandler from '@components/shared/Authorization/PermissionHandler';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import BulkAddItemLink from '@components/MyApps/BulkAddItemLink';
import BulkAddStyleLink from '@components/MyApps/BulkAddStyleLink';
import { useFlags } from 'launchdarkly-react-client-sdk';

const BulkActions = React.memo(() => {
  const { hfHideBulkAddStyle } = useFlags();

  return (
    <div className='my-apps-tray w-100'>
      <PermissionHandler permissions={PermissionsEnum.BulkAddItemVisit}>
        <BulkAddItemLink />
      </PermissionHandler>
      {
        !hfHideBulkAddStyle &&
        <>
          <PermissionHandler permissions={PermissionsEnum.BulkAddStyleVisit}>
            <BulkAddStyleLink />
          </PermissionHandler>
        </>
      }
    </div>
  );
});

export default BulkActions;
