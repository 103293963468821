import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';

/** @type {React.FC<{ current: number; total: number; }>} */
const ProgressBarCell = React.memo(({
  current,
  total,
}) => (
  <div className='progress-bar-cell'>
    <div>{current}/{total}</div>
    <div>
      <ProgressBar
        className='progress-bar-cell__bar progress-bar-cell__bar--small'
        variant={total !== 0 && current === total ? 'success' : 'warning'}
        now={total === 0 ? 0 : current / total * 100}
      />
    </div>
  </div>
));

ProgressBarCell.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default ProgressBarCell;
