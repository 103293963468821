import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { toggleButtonStateEnum } from '@constants/enums/commonEnums';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ToggleButton from '@sharedComponents/Buttons/ToggleButton';
import Icon from '@sharedComponents/Icons/Icon';

class LockerManagerCLCModal extends PureComponent {
  state = {
    enabled: this.props.locker.enableMultipleLogos,
  };

  componentDidUpdate(prevProps) {
    const { locker } = this.props;

    if (!prevProps.locker || (prevProps.locker !== locker)) {
      this.initializeState();
    }
  }

  initializeState = () => {
    const { locker } = this.props;
    this.setState({ enabled: locker.enableMultipleLogos });
  };

  onClose = () => {
    const { closeModal } = this.props;
    this.initializeState();
    closeModal();
  };

  enableCLC = () => {
    const {
      enableCLC,
      locker,
    } = this.props;

    enableCLC(locker.id);
  };

  onChangeToggleButton = () => {
    this.setState((prevState) => ({
      enabled: !prevState.enabled,
    }));
  };

  render() {
    const { isOpen } = this.props;
    const { enabled } = this.state;

    return (
      <Modal
        title={'Edit CLC'}
        modalHeight={'l'}
        modalWidth={'s'}
        isOpen={isOpen}
        closeModal={this.onClose}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onConfirm={this.enableCLC}
            onClose={this.onClose}
            confirmBtnDisabled={!enabled}
          />
        )}
      >
        <h3 className='modal__description'>
          <div className='modal__warning mb-40'>
            <Icon materialIcon={'warning'} />
            <span className='warning-text'>
              Notice: By enabling CLC for this locker, you won’t be able to disable it later.
            </span>
          </div>
          <div className='lockerManagerEdit__clc-modal__action'>
            <span className='lockerManagerEdit__clc-modal__action--text mr-20'>Enable CLC:</span>
            <div>
              <ToggleButton
                name={'enableCLC'}
                enabled={enabled}
                onChange={this.onChangeToggleButton}
              />
            </div>
            <span
              className={`lockerManagerEdit__clc-modal__action--state ${enabled ? 'active' : ''}`}
              data-test='clc-state'
            >
              {enabled ? toggleButtonStateEnum.ON : toggleButtonStateEnum.OFF}
            </span>
          </div>
        </h3>
      </Modal>
    );
  }
}

LockerManagerCLCModal.propTypes = {
  locker: PropTypes.shape({
    id: PropTypes.number,
    enableMultipleLogos: PropTypes.bool,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  enableCLC: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default LockerManagerCLCModal;
