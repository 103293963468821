import React, {
  useCallback, useEffect, useState,
} from 'react';
import { stopSubmit } from 'redux-form';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { shippingAddressForm } from '@constants/reduxForms';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ShippingAddressForm from './ShippingAddressForm';
import { RootState } from '@redux/index/reducers';
import {
  AccountingActionTypeEnum,
  OrderDetailsDto,
} from '@api/fulfillment/models';
import {
  OrderPricingDetailsDto,
  OrderDetailsDto as CoreOrderDetails,
  ValidateAddressQuery,
} from '@api/orders/models';
import { usePutHomefieldApiOrdersvalidateaddress } from '@api/orders/orders';
import { ShippingAddressFormData } from '@models/forms/OrderManagement/ShippingAddressFormData';
import { useSimpleConfirmationModal } from '@hooks/shared/Modal/useSimpleConfirmationModal';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  order: OrderDetailsDto;
  initialValues: {
    method: string | undefined;
    name: string | undefined;
    email: string | undefined;
    addressLine1: string | undefined;
    addressLine2: string | null | undefined;
    city: string | null | undefined;
    state: string | null | undefined;
    country: string | null | undefined;
    zip: string | null | undefined;
    poBox: boolean | null | undefined;
    phoneNumber: string | null | undefined;
    actionType: AccountingActionTypeEnum;
    pricePaid: number | null | undefined;
  };
  onSubmit: (form: ShippingAddressFormData) => void;
  coreOrderPricingDetails: OrderPricingDetailsDto | undefined;
  coreOrder: Nullable<CoreOrderDetails>;
}

const mapStateToProps = ({ support }: RootState) => ({
  shippingOptions: support.shippingOptions,
});

const mapDispatchToProps = {
  stopSubmit,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const UpdateShippingInfoModal = React.memo<Props>(({
  shippingOptions,
  isOpen,
  closeModal,
  order,
  initialValues,
  onSubmit,
  coreOrderPricingDetails,
  coreOrder,
}) => {
  const [
    sentAddressVerificationRequest,
    setSentAddressVerificationRequest,
  ] = useState<boolean>(false);
  const [
    orderForm,
    setOrderForm,
  ] = useState<Nullable<ShippingAddressFormData>>(null);

  const {
    data: addressValidationResponse,
    mutateAsync: validateAddress,
  } = usePutHomefieldApiOrdersvalidateaddress(
    { request: { isBlockingRequest: true } }
  );

  const {
    openModal: openChooseToContinueModal,
    closeModal: closeChooseToContinueModal,
    isOpen: chooseToContinueModalIsOpen,
    ConfirmationModal: ChooseToContinueModal,
  } = useSimpleConfirmationModal();

  useEffect(() => {
    if (sentAddressVerificationRequest && !!addressValidationResponse) {
      if (addressValidationResponse?.success && addressValidationResponse.message === 'Success') {
        onSubmit(orderForm!);
        setSentAddressVerificationRequest(false);
      } else if (addressValidationResponse?.success && addressValidationResponse.message !== 'Success') {
        openChooseToContinueModal();
      }
    }
  }, [addressValidationResponse]);

  const validateAddressInput = useCallback((shippingOrderForm: ShippingAddressFormData) => {
    const data = {
      addressLine1: shippingOrderForm.addressLine1,
      addressLine2: shippingOrderForm.addressLine2,
      city: shippingOrderForm.city,
      state: shippingOrderForm.state,
      country: shippingOrderForm.country,
      zipCode: shippingOrderForm.zip,
    } as ValidateAddressQuery;

    setOrderForm(shippingOrderForm);
    validateAddress({ data });
    setSentAddressVerificationRequest(true);
  }, [validateAddress]);

  const handleCloseModal = useCallback(async () => {
    closeModal();
    stopSubmit(shippingAddressForm, {});
  }, [closeModal]);

  const submit = useCallback(() => {
    onSubmit(orderForm!);
    closeChooseToContinueModal();
  }, [
    onSubmit,
    orderForm,
  ]);

  return (
    <div>
      <ChooseToContinueModal
        isOpen={chooseToContinueModalIsOpen}
        closeModal={closeChooseToContinueModal}
        title={'Do you want to continue?'}
        cancelBtnText={'Cancel'}
        confirm={submit}
        confirmationBody={(
          <span>
            {addressValidationResponse?.message}
          </span>
        )}
      />
      <Modal
        isOpen={isOpen}
        closeModal={handleCloseModal}
        title={'Edit Shipping Information'}
        modalHeight={'xl'}
        modalWidth={'xl'}
        buttons={(
          <ModalButtons
            onClose={handleCloseModal}
            confirmBtnText={'Save'}
            formSubmission={true}
            formId={shippingAddressForm}
          />
        )}
      >
        <div className='order__personalization-modal'>
          <ShippingAddressForm
            onSubmit={validateAddressInput}
            initialValues={initialValues}
            shippingOptions={shippingOptions}
            order={order}
            coreOrderPricingDetails={coreOrderPricingDetails}
            coreOrder={coreOrder}
          />
        </div>
      </Modal>
    </div>
  );
});

export default connector(UpdateShippingInfoModal);
