import React from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { ColorDto } from '@api/productCatalog/models';
import { productionAssemblyUnFlagItemForm } from '@constants/reduxForms';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import { FlaggingFormData } from '@models/forms/Production/FlaggingFormData';
import {
  Task,
  LogoTask,
  PersonalizationTask,
} from '@models/Production/ProductionAssembly/Tasks';
import MarkItemAsFlaggedModalLogoDetails from '@components/WeedingAndMasking/Flagging/MarkItemAsFlaggedModalLogoDetails';
import MarkItemAsFlaggedModalPersonalizationDetails from '@components/WeedingAndMasking/Flagging/MarkItemAsFlaggedModalPersonalizationDetails';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import RadioGroup from '@sharedComponents/Form/RadioGroup';

interface OwnProps {
  isOpen: boolean;
  onSubmit: (form: FlaggingFormData) => Promise<void>;
  closeModal: () => void;
  task: Nullable<Task>;
  orderNumber: number;
  colorsDictionary: Record<string, ColorDto>;
}

type FormProps = OwnProps;

export type Props = FormProps & InjectedFormProps<FlaggingFormData, FormProps, string[]>;

const UnflagItemModal = React.memo<Props>(({
  task,
  closeModal,
  orderNumber,
  colorsDictionary,
  isOpen,
  handleSubmit,
}) => {
  if (!isOpen || !task) return null;

  return (
    <Modal
      title={'Unflag Item'}
      modalHeight={'l'}
      modalWidth={'m'}
      isOpen={isOpen}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Unflag'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          isDangerousAction={true}
          formSubmission={true}
          formId={productionAssemblyUnFlagItemForm}
        />
      )}
    >
      <form
        className='flagging__form'
        onSubmit={handleSubmit}
        id={productionAssemblyUnFlagItemForm}
      >
        <Field
          name={'flagType'}
          component={RadioGroup}
          groupClassName={'flagging__form-radio-group'}
          radioItems={[
            {
              value: flagTypeEnum.Reprint,
              disabled: true,
              label: 'Send to Reprint',
              className: 'ml-n10',
            },
            {
              value: flagTypeEnum.Rework,
              disabled: true,
              label: 'Send to Rework',
            },
            {
              value: flagTypeEnum.WrongArtwork,
              disabled: true,
              label: 'Send to Artwork Change',
            },
          ]}
        />

        <div className='flagging__form-group'>
          <Field
            name={'reason'}
            component={Select as any}
            disabled={true}
          >
            <option value={task?.flag?.reason ?? undefined}>
              {task?.flag?.reason}
            </option>
          </Field>
        </div>
        <div className='flagging__form-group'>
          <div className='text-field flex'>
            <Field
              name={'note'}
              component={Input}
              type={'text'}
              disabled={true}
            />
          </div>
        </div>
        {
          (task as LogoTask).logoUrl
            ? (
              <MarkItemAsFlaggedModalLogoDetails
                orderNumber={orderNumber}
                previewUrl={(task as LogoTask).previewUrl || (task as LogoTask).logoUrl!}
                size={task.decorationLocation ?? undefined}
                barcode={task.barcode ?? undefined}
                decorationIds={task.ids}
              />
            )
            : (
              <MarkItemAsFlaggedModalPersonalizationDetails
                orderNumber={orderNumber}
                personalizationNumber={(task as PersonalizationTask).number ?? undefined}
                personalizationText={(task as PersonalizationTask).text ?? undefined}
                personalizationColor={colorsDictionary[(task as PersonalizationTask).colorId!]}
                size={task.decorationLocation ?? undefined}
              />
            )
        }
      </form>
    </Modal>
  );
});

export default (reduxForm<FlaggingFormData, FormProps, string[]>({
  form: productionAssemblyUnFlagItemForm,
  shouldError: () => true,
  enableReinitialize: true,
})(UnflagItemModal));
