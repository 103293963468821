import React from 'react';
import LinkButton from '@sharedComponents/Buttons/LinkButton';

interface OwnProps<T> {
  selectedItems: T[];
  clearAll: () => void;
  bulkActions: Nullable<JSX.Element>;
  additionalInfo?: any;
  isAlwaysActive?: boolean;
  selectWholeTableEnabled?: boolean;
  selectWholeTable?: () => void;
  isTableSelected?: boolean;
  isPageSelected?: boolean;
  tableName?: string;
  totalCount?: number;
}

type Props<T> = OwnProps<T>;

const TableBulkActions = <T, >({
  selectedItems = [],
  clearAll,
  bulkActions = null,
  additionalInfo = null,
  isAlwaysActive = false,
  selectWholeTableEnabled = false,
  selectWholeTable = undefined,
  isTableSelected = false,
  isPageSelected = false,
  tableName = 'Table',
  totalCount = 0,
}: Props<T>) => {
  const additionalInfoText = additionalInfo ? additionalInfo(selectedItems) : '';

  return (
    <div className={`actions-row ${isAlwaysActive || selectedItems.length > 0 ? 'is-active' : ''}`}>
      <div className='actions-row__actions'>
        {bulkActions}
      </div>
      <div className='actions-row__info'>
        <span>{isTableSelected ? totalCount : selectedItems.length} items selected</span>
        <span>{additionalInfoText}</span>
        {
          selectWholeTableEnabled && selectWholeTable && isPageSelected && !isTableSelected &&
          <LinkButton
            text={`Select All ${totalCount} Items in ${tableName}`}
            onClick={selectWholeTable}
            classes={'m-l-12'}
          />
        }
        <LinkButton
          text={'Deselect All'}
          onClick={clearAll}
          classes={'m-l-12'}
        />
      </div>
    </div>
  );
};

export default React.memo(TableBulkActions) as typeof TableBulkActions;
