/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  DtgEnvironmentDtoPagedList,
  GetHomefieldApiDtgconfigurationsEnvironmentsParams,
  ApiResponse,
  CreateEnvironmentCommand,
  DtgEnvironmentSimpleDto,
  UpdateEnvironmentCommand,
  DtgTabletProfileDtoPagedList,
  GetHomefieldApiDtgconfigurationsTabletprofilesParams,
  CreateTabletProfileCommand,
  DtgTabletProfileSimpleDto,
  UpdateTabletProfileCommand,
  DtgPalletDtoPagedList,
  DtgPretreatmentBatchDtoPagedList,
  DtgConfigurationColoredStyleDtoPagedList,
  GetHomefieldApiDtgconfigurationsColoredstyleconfigurationsParams,
  UpsertDtgColoredStyleConfigurationCommand,
  ColoredStyleDetailDtoPagedList,
  GetHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissingParams,
  DtgConfigurationColoredStyleDto,
  DtgConfigurationDecorationLocationArtworkSizeDto,
  UpsertDtgArtworkConfigurationCommand,
  DtgConfigurationDecorationLocationPersonalizationSizeDto,
  UpsertDtgPersonalizationConfigurationCommand,
  DtgConfigurationProductArtworkDto,
  GetHomefieldApiDtgconfigurationsProductartworkconfigurationsParams,
  DtgConfigurationProductPersonalizationDto,
  GetHomefieldApiDtgconfigurationsProductpersonalizationconfigurationsParams
} from './models'
import { catalogApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiDtgconfigurationsEnvironments = <TData = DtgEnvironmentDtoPagedList>(
    params?: GetHomefieldApiDtgconfigurationsEnvironmentsParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/environments`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsEnvironmentsQueryKey = (params?: GetHomefieldApiDtgconfigurationsEnvironmentsParams,) => [`/homefield/api/dtg-configurations/environments`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiDtgconfigurationsEnvironments = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsEnvironments, DtgEnvironmentDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiDtgconfigurationsEnvironmentsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsEnvironmentsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsEnvironments<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiDtgconfigurationsEnvironments = <TData = ApiResponse>(
    createEnvironmentCommand: CreateEnvironmentCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/environments`, method: 'post',
      data: createEnvironmentCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiDtgconfigurationsEnvironments = <TData = AsyncReturnType<typeof postHomefieldApiDtgconfigurationsEnvironments,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateEnvironmentCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateEnvironmentCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiDtgconfigurationsEnvironments<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDtgconfigurationsEnvironmentssimple = <TData = DtgEnvironmentSimpleDto[]>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/environments.simple`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsEnvironmentssimpleQueryKey = () => [`/homefield/api/dtg-configurations/environments.simple`]

    
export const useGetHomefieldApiDtgconfigurationsEnvironmentssimple = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsEnvironmentssimple, DtgEnvironmentSimpleDto[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsEnvironmentssimpleQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsEnvironmentssimple<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiDtgconfigurationsEnvironmentsId = <TData = ApiResponse>(
    id: number,
    updateEnvironmentCommand: UpdateEnvironmentCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/environments/${id}`, method: 'put',
      data: updateEnvironmentCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiDtgconfigurationsEnvironmentsId = <TData = AsyncReturnType<typeof putHomefieldApiDtgconfigurationsEnvironmentsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: UpdateEnvironmentCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: UpdateEnvironmentCommand}, TContext>((props) => {
        const {id,data} = props || {};

        return  putHomefieldApiDtgconfigurationsEnvironmentsId<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiDtgconfigurationsEnvironmentsId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/environments/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiDtgconfigurationsEnvironmentsId = <TData = AsyncReturnType<typeof deleteHomefieldApiDtgconfigurationsEnvironmentsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiDtgconfigurationsEnvironmentsId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDtgconfigurationsTabletprofiles = <TData = DtgTabletProfileDtoPagedList>(
    params?: GetHomefieldApiDtgconfigurationsTabletprofilesParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/tablet-profiles`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsTabletprofilesQueryKey = (params?: GetHomefieldApiDtgconfigurationsTabletprofilesParams,) => [`/homefield/api/dtg-configurations/tablet-profiles`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiDtgconfigurationsTabletprofiles = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsTabletprofiles, DtgTabletProfileDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiDtgconfigurationsTabletprofilesParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsTabletprofilesQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsTabletprofiles<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiDtgconfigurationsTabletprofiles = <TData = ApiResponse>(
    createTabletProfileCommand: CreateTabletProfileCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/tablet-profiles`, method: 'post',
      data: createTabletProfileCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiDtgconfigurationsTabletprofiles = <TData = AsyncReturnType<typeof postHomefieldApiDtgconfigurationsTabletprofiles,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateTabletProfileCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateTabletProfileCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiDtgconfigurationsTabletprofiles<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDtgconfigurationsTabletprofilessimple = <TData = DtgTabletProfileSimpleDto[]>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/tablet-profiles.simple`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsTabletprofilessimpleQueryKey = () => [`/homefield/api/dtg-configurations/tablet-profiles.simple`]

    
export const useGetHomefieldApiDtgconfigurationsTabletprofilessimple = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsTabletprofilessimple, DtgTabletProfileSimpleDto[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsTabletprofilessimpleQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsTabletprofilessimple<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiDtgconfigurationsTabletprofilesId = <TData = ApiResponse>(
    id: number,
    updateTabletProfileCommand: UpdateTabletProfileCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/tablet-profiles/${id}`, method: 'put',
      data: updateTabletProfileCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiDtgconfigurationsTabletprofilesId = <TData = AsyncReturnType<typeof putHomefieldApiDtgconfigurationsTabletprofilesId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: UpdateTabletProfileCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: UpdateTabletProfileCommand}, TContext>((props) => {
        const {id,data} = props || {};

        return  putHomefieldApiDtgconfigurationsTabletprofilesId<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiDtgconfigurationsTabletprofilesId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/tablet-profiles/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiDtgconfigurationsTabletprofilesId = <TData = AsyncReturnType<typeof deleteHomefieldApiDtgconfigurationsTabletprofilesId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiDtgconfigurationsTabletprofilesId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDtgconfigurationsPallets = <TData = DtgPalletDtoPagedList>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/pallets`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsPalletsQueryKey = () => [`/homefield/api/dtg-configurations/pallets`]

    
export const useGetHomefieldApiDtgconfigurationsPallets = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsPallets, DtgPalletDtoPagedList>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsPalletsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsPallets<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiDtgconfigurationsPretreatmentbatches = <TData = DtgPretreatmentBatchDtoPagedList>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/pretreatment-batches`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsPretreatmentbatchesQueryKey = () => [`/homefield/api/dtg-configurations/pretreatment-batches`]

    
export const useGetHomefieldApiDtgconfigurationsPretreatmentbatches = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsPretreatmentbatches, DtgPretreatmentBatchDtoPagedList>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsPretreatmentbatchesQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsPretreatmentbatches<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiDtgconfigurationsColoredstyleconfigurations = <TData = DtgConfigurationColoredStyleDtoPagedList>(
    params?: GetHomefieldApiDtgconfigurationsColoredstyleconfigurationsParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/colored-style-configurations`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsColoredstyleconfigurationsQueryKey = (params?: GetHomefieldApiDtgconfigurationsColoredstyleconfigurationsParams,) => [`/homefield/api/dtg-configurations/colored-style-configurations`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiDtgconfigurationsColoredstyleconfigurations = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsColoredstyleconfigurations, DtgConfigurationColoredStyleDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiDtgconfigurationsColoredstyleconfigurationsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsColoredstyleconfigurationsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsColoredstyleconfigurations<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiDtgconfigurationsColoredstyleconfigurations = <TData = ApiResponse>(
    upsertDtgColoredStyleConfigurationCommand: UpsertDtgColoredStyleConfigurationCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/colored-style-configurations`, method: 'post',
      data: upsertDtgColoredStyleConfigurationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiDtgconfigurationsColoredstyleconfigurations = <TData = AsyncReturnType<typeof postHomefieldApiDtgconfigurationsColoredstyleconfigurations,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpsertDtgColoredStyleConfigurationCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpsertDtgColoredStyleConfigurationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiDtgconfigurationsColoredstyleconfigurations<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissing = <TData = ColoredStyleDetailDtoPagedList>(
    params?: GetHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissingParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/colored-style-configurations.missing`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissingQueryKey = (params?: GetHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissingParams,) => [`/homefield/api/dtg-configurations/colored-style-configurations.missing`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissing = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissing, ColoredStyleDetailDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissingParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissingQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsColoredstyleconfigurationsmissing<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiDtgconfigurationsColoredstyleconfigurationColoredStyleId = <TData = DtgConfigurationColoredStyleDto>(
    coloredStyleId: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/colored-style-configuration/${coloredStyleId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsColoredstyleconfigurationColoredStyleIdQueryKey = (coloredStyleId: number,) => [`/homefield/api/dtg-configurations/colored-style-configuration/${coloredStyleId}`]

    
export const useGetHomefieldApiDtgconfigurationsColoredstyleconfigurationColoredStyleId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsColoredstyleconfigurationColoredStyleId, DtgConfigurationColoredStyleDto>, TError = ApiResponse, TData = TQueryFnData>(
 coloredStyleId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsColoredstyleconfigurationColoredStyleIdQueryKey(coloredStyleId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsColoredstyleconfigurationColoredStyleId<TQueryFnData>(coloredStyleId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiDtgconfigurationsArtworkconfigurations = <TData = DtgConfigurationDecorationLocationArtworkSizeDto[]>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/artwork-configurations`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsArtworkconfigurationsQueryKey = () => [`/homefield/api/dtg-configurations/artwork-configurations`]

    
export const useGetHomefieldApiDtgconfigurationsArtworkconfigurations = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsArtworkconfigurations, DtgConfigurationDecorationLocationArtworkSizeDto[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsArtworkconfigurationsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsArtworkconfigurations<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiDtgconfigurationsArtworkconfigurations = <TData = ApiResponse>(
    upsertDtgArtworkConfigurationCommand: UpsertDtgArtworkConfigurationCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/artwork-configurations`, method: 'post',
      data: upsertDtgArtworkConfigurationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiDtgconfigurationsArtworkconfigurations = <TData = AsyncReturnType<typeof postHomefieldApiDtgconfigurationsArtworkconfigurations,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpsertDtgArtworkConfigurationCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpsertDtgArtworkConfigurationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiDtgconfigurationsArtworkconfigurations<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiDtgconfigurationsArtworkconfigurationsId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/artwork-configurations/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiDtgconfigurationsArtworkconfigurationsId = <TData = AsyncReturnType<typeof deleteHomefieldApiDtgconfigurationsArtworkconfigurationsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiDtgconfigurationsArtworkconfigurationsId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDtgconfigurationsPersonalizationconfigurations = <TData = DtgConfigurationDecorationLocationPersonalizationSizeDto[]>(
    
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/personalization-configurations`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsPersonalizationconfigurationsQueryKey = () => [`/homefield/api/dtg-configurations/personalization-configurations`]

    
export const useGetHomefieldApiDtgconfigurationsPersonalizationconfigurations = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsPersonalizationconfigurations, DtgConfigurationDecorationLocationPersonalizationSizeDto[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsPersonalizationconfigurationsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsPersonalizationconfigurations<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiDtgconfigurationsPersonalizationconfigurations = <TData = ApiResponse>(
    upsertDtgPersonalizationConfigurationCommand: UpsertDtgPersonalizationConfigurationCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/personalization-configurations`, method: 'post',
      data: upsertDtgPersonalizationConfigurationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiDtgconfigurationsPersonalizationconfigurations = <TData = AsyncReturnType<typeof postHomefieldApiDtgconfigurationsPersonalizationconfigurations,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpsertDtgPersonalizationConfigurationCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpsertDtgPersonalizationConfigurationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiDtgconfigurationsPersonalizationconfigurations<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiDtgconfigurationsPersonalizationconfigurationsId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/personalization-configurations/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiDtgconfigurationsPersonalizationconfigurationsId = <TData = AsyncReturnType<typeof deleteHomefieldApiDtgconfigurationsPersonalizationconfigurationsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiDtgconfigurationsPersonalizationconfigurationsId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDtgconfigurationsProductartworkconfigurations = <TData = DtgConfigurationProductArtworkDto>(
    params?: GetHomefieldApiDtgconfigurationsProductartworkconfigurationsParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/product-artwork-configurations`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsProductartworkconfigurationsQueryKey = (params?: GetHomefieldApiDtgconfigurationsProductartworkconfigurationsParams,) => [`/homefield/api/dtg-configurations/product-artwork-configurations`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiDtgconfigurationsProductartworkconfigurations = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsProductartworkconfigurations, DtgConfigurationProductArtworkDto>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiDtgconfigurationsProductartworkconfigurationsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsProductartworkconfigurationsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsProductartworkconfigurations<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiDtgconfigurationsProductpersonalizationconfigurations = <TData = DtgConfigurationProductPersonalizationDto>(
    params?: GetHomefieldApiDtgconfigurationsProductpersonalizationconfigurationsParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/dtg-configurations/product-personalization-configurations`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDtgconfigurationsProductpersonalizationconfigurationsQueryKey = (params?: GetHomefieldApiDtgconfigurationsProductpersonalizationconfigurationsParams,) => [`/homefield/api/dtg-configurations/product-personalization-configurations`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiDtgconfigurationsProductpersonalizationconfigurations = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDtgconfigurationsProductpersonalizationconfigurations, DtgConfigurationProductPersonalizationDto>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiDtgconfigurationsProductpersonalizationconfigurationsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDtgconfigurationsProductpersonalizationconfigurationsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDtgconfigurationsProductpersonalizationconfigurations<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

