import { HagPrintRoomJobPriorityItemDto } from '@api/fulfillment/models';
import React, {
  useCallback,
  useState,
} from 'react';
import PriorityNoteModal from './PriorityNoteModal';

interface OwnProps {
  item: HagPrintRoomJobPriorityItemDto;
  orderNumber: number;
  shipDate: string;
  className?: string;
}

type Props = OwnProps;

const PriorityNoteButton = React.memo<Props>(({
  item,
  orderNumber,
  shipDate,
  className,
}) => {
  const [
    isOpen,
    setOpen,
  ] = useState(false);

  const handleOpen = useCallback((e) => {
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!item) {
    return null;
  }

  return (
    <>
      <button
        onClick={handleOpen}
        disabled={false}
        className={className ?? 'priority-button__note'}
      >
        Note
      </button>
      <PriorityNoteModal
        item={item}
        orderNumber={orderNumber}
        shipDate={shipDate}
        onClose={handleClose}
        isOpen={isOpen}
      />
    </>
  );
});

export default PriorityNoteButton;
