import React, { PureComponent } from 'react';
import {
  multiSelectDropdownDelay,
  dropdownSizeM,
} from '@constants/values';
import {
  PrimitiveType,
  ObjectType,
} from '@customTypes/option';
import { ColorItem } from '@models/common/ColorItem';
import { DisableableItem } from '@models/common/DisableableItem';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import MultiSelectDropdownItem from './MultiSelectDropdownItemPrimitive';
import MultiSelectDropdownItemObject from './MultiSelectDropdownItemObject';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';

const forceCastToPrimitive = (item: any): item is PrimitiveType => true;

interface OwnPropsForObjects<T extends ObjectType & DisableableItem, ValueKey extends keyof T> {
  readonly objects: T[];
  selectedObjects?: T[ValueKey][];
  defaultValue?: T[ValueKey];
  updateCallback: (value: T[ValueKey][]) => void;
  itemText?: string;
  selectedText?: string;
  notSelectedText?: string;
  textKey: keyof T;
  valueKey: ValueKey;
  size?: string;
  classNames?: string;
  color?: boolean;
  disabled?: boolean;
  disabledTooltipText?: string;
  disabledItemTooltipText?: string;
}

interface OwnPropsForPrimitives<T extends PrimitiveType> {
  readonly objects: readonly T[];
  selectedObjects?: T[];
  defaultValue?: T;
  updateCallback: (value: T[]) => void;
  itemText?: string;
  selectedText?: string;
  notSelectedText?: string;
  size?: string;
  classNames?: string;
  color?: boolean;
  disabled?: boolean;
  disabledTooltipText?: string;
  disabledItemTooltipText?: string;
}

type Props<T, K extends keyof T>
  = T extends ObjectType
    ? OwnPropsForObjects<T, K>
    : (T extends PrimitiveType ? OwnPropsForPrimitives<T> : never);

interface StateForObjects<T extends ObjectType, ValueKey extends keyof T> {
  selectedItems: T[ValueKey][];
  dropIsActive: boolean;
  throttledCall: Nullable<ReturnType<typeof setTimeout>>;
}

interface StateForPrimitives<T extends PrimitiveType> {
  selectedItems: T[];
  dropIsActive: boolean;
  throttledCall: Nullable<ReturnType<typeof setTimeout>>;
}

type State<T, K extends keyof T>
  = T extends ObjectType
    ? StateForObjects<T, K>
    : (T extends PrimitiveType ? StateForPrimitives<T> : never);

class MultiSelectDropdown<T, K extends keyof T = any> extends PureComponent<Props<T, K>, State<T, K>> {
  state: State<T, K> = {
    selectedItems: (this.props.selectedObjects ?? []),
    dropIsActive: false,
    throttledCall: null,
  } as State<T, K>;

  componentDidUpdate(prevProps: Props<T, K>): void {
    const { selectedObjects } = this.props;

    if (!!selectedObjects && prevProps.selectedObjects !== selectedObjects) {
      this.setState(() => ({ selectedItems: selectedObjects } as State<T, K>));
    }
  }

  refresh = (): void => {
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    const newThrottle = setTimeout(this.callUpdate, multiSelectDropdownDelay);

    this.setState(() => ({ throttledCall: newThrottle }));
  };

  callUpdate = (): void => {
    const {
      throttledCall,
      selectedItems,
    } = this.state;
    const { updateCallback } = this.props;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    updateCallback(selectedItems as any);
  };

  itemOnClick = (item: T | T[K], isSelected: boolean): void => {
    const { selectedItems } = this.state;

    if (isSelected) {
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(newSelectedItems.indexOf(item), 1);

      this.setState(() => ({
        selectedItems: newSelectedItems,
      }), this.refresh);
    } else {
      const newSelectedItems = [
        ...selectedItems,
        item,
      ];

      this.setState(() => ({
        selectedItems: newSelectedItems,
      }), this.refresh);
    }
  };

  isSelected = (item: T): boolean => {
    const { valueKey } = this.props as unknown as OwnPropsForObjects<T, K>;

    if (!valueKey && forceCastToPrimitive(item)) {
      const { selectedItems } = this.state as unknown as StateForPrimitives<typeof item>;

      return selectedItems.includes(item);
    }

    const { selectedItems } = this.state as unknown as StateForObjects<T, K>;

    return selectedItems.includes((item)[valueKey || ('value' as K)]);
  };

  clearFilter = (): void => {
    const { updateCallback } = this.props;
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    this.setState(() => ({
      selectedItems: [],
      throttledCall: null,
    }));

    updateCallback([]);
  };

  dropChange = (): void => {
    const { dropIsActive } = this.state;
    this.setState(() => ({ dropIsActive: !dropIsActive }));
  };

  closeDrop = (): void => {
    this.setState(() => ({ dropIsActive: false }));
  };

  render() {
    const {
      objects,
      itemText,
      selectedText,
      notSelectedText,
      size,
      classNames,
      color,
      disabled = false,
      disabledTooltipText,
      disabledItemTooltipText,
    } = this.props;

    const {
      selectedItems,
      dropIsActive,
    } = this.state;

    const {
      valueKey,
      textKey,
    } = this.props as unknown as OwnPropsForObjects<T, K>;

    const options: JSX.Element[] = objects.map((item, index) => {
      if (!valueKey && forceCastToPrimitive(item)) {
        return (
          <MultiSelectDropdownItem<typeof item>
            key={index}
            value={item}
            text={item}
            isSelected={this.isSelected(item)}
            onClick={this.itemOnClick}
            color={(color && item) as (T & ColorItem)}
          />
        );
      }

      return (
        <MultiSelectDropdownItemObject<T, K>
          key={index}
          value={(item)[valueKey || 'value' as keyof T] as T[K]}
          text={(item)[textKey || 'name' as keyof T] as string}
          isSelected={this.isSelected(item)}
          onClick={this.itemOnClick}
          color={(color && item) as (T & ColorItem)}
          disabled={(item as DisableableItem).disabled}
          disabledTooltipText={disabledItemTooltipText}
        />
      );
    });

    const selectText = selectedItems.length > 0
      ? `${itemText ?? selectedText} (${selectedItems.length}/${objects.length})`
      : `${notSelectedText ?? `All ${itemText}`} (${objects.length})`;

    const dropdown = (
      <OutsideClickWrapper onClick={this.closeDrop}>
        <div
          style={{ minWidth: size ?? dropdownSizeM }}
          className={`custom-dropdown ${classNames}`}
        >
          <div
            style={{ minWidth: size ?? dropdownSizeM }}
            onClick={!disabled ? this.dropChange : undefined}
            className={`custom-dropdown__select ${disabled ? 'select-field__container--disabled' : ''}`}
          >
            {selectText}
            <div className='custom-dropdown__arrow' />
          </div>
          <div
            style={{ minWidth: size ?? dropdownSizeM }}
            className={`custom-dropdown__options ${dropIsActive ? 'is-active' : ''}`}
          >
            <div className='custom-dropdown__options--actions'>
              <a
                className='button clear'
                onClick={this.clearFilter}
              >
                Clear
              </a>
            </div>
            <ul className='custom-dropdown__options--list custom-scrollbar'>
              {options}
            </ul>
          </div>
        </div>
      </OutsideClickWrapper>
    );

    if (disabled && !!disabledTooltipText) {
      return (
        <MaterialTooltip
          tooltipText={disabledTooltipText}
          placement='bottom'
        >
          {dropdown}
        </MaterialTooltip>
      );
    }

    return dropdown;
  }
}

export default MultiSelectDropdown;
