import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  tabSizesEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { getVendorOrderSubmitStatusOptions } from '@constants/options/options';
import { fetchVendorsFilter } from '@redux/vendorOrders/actions';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import Tabs from '@sharedComponents/Tabs/Tabs';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import NotScheduledVendorOrdersTab from './VendorOrderTabs/NotScheduledVendorOrdersTab';
import ScheduledVendorOrdersTab from './VendorOrderTabs/ScheduledVendorOrdersTab';

const vendorOrderSubmitStatusOptions = getVendorOrderSubmitStatusOptions();

const vendorOrdersTabEnum = {
  NotScheduledOrders: 'NotScheduledOrders',
  ScheduledOrders: 'ScheduledOrders',
};

class VendorOrders extends PureComponent {
  state = {
    searchInput: '',
    vendorsFilter: [],
    statusFilter: '',
    selectedTab: vendorOrdersTabEnum.NotScheduledOrders,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchVendorsFilter());
  }

  getTabs = (searchInput, vendorsFilter, statusFilter) => {
    const tabs = [
      {
        title: 'Not Scheduled',
        name: vendorOrdersTabEnum.NotScheduledOrders,
        content: (
          <NotScheduledVendorOrdersTab
            searchInput={searchInput}
            vendorsFilter={vendorsFilter}
          />
        ),
      },
      {
        title: 'Submitted',
        name: vendorOrdersTabEnum.ScheduledOrders,
        content: (
          <ScheduledVendorOrdersTab
            searchInput={searchInput}
            vendorsFilter={vendorsFilter}
            statusFilter={statusFilter}
          />
        ),
      },
    ];

    return tabs;
  };

  selectTab = (selectedTab) => {
    this.setState({
      selectedTab,
      searchInput: '',
      statusFilter: '',
    });
  };

  clearSearch = () => {
    this.setState({ searchInput: '' });
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({ searchInput }));
  };

  filterByVendors = (vendors) => {
    if (!vendors) {
      return;
    }

    this.setState({ vendorsFilter: vendors });
  };

  filterByStatus = (value) => {
    this.setState({ statusFilter: value });
  };

  render() {
    const {
      selectedTab,
      searchInput,
      vendorsFilter,
      statusFilter,
    } = this.state;

    const { vendors } = this.props;

    return (
      <div className='container'>
        <div className='filter-groups'>
          <SearchFilter
            search={this.onSearch}
            clearSearch={this.clearSearch}
          />
          {
            selectedTab === vendorOrdersTabEnum.ScheduledOrders &&
            <Dropdown
              options={vendorOrderSubmitStatusOptions}
              onChange={this.filterByStatus}
              defaultValue={statusFilter}
            />
          }
          <MultiSelectDropdown
            objects={vendors}
            itemText={'vendors'}
            updateCallback={this.filterByVendors}
            textKey={'name'}
            valueKey={'code'}
            classNames={'margin-left'}
          />
        </div>

        <Tabs
          tabs={this.getTabs(searchInput, vendorsFilter, statusFilter)}
          selectedTab={selectedTab}
          selectTab={this.selectTab}
          size={tabSizesEnum.Large}
        />
      </div>
    );
  }
}

VendorOrders.propTypes = {
  vendors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

const mapStateToProps = ({ productCatalog }) => ({
  vendors: productCatalog.vendors,
});

export default connect(mapStateToProps)(VendorOrders);
