import React from 'react';
import PropTypes from 'prop-types';
import Personalization from './Personalization';

const OrderManagementDetailsItemExpandPersonalization = React.memo(({
  personalizations,
  colorsDictionary,
}) => {
  const items = personalizations.map((item, index) => (
    <div
      key={index}
      className='order__files-item'
    >
      <Personalization
        personalization={item}
        colorsDictionary={colorsDictionary}
      />
    </div>
  ));

  return (
    <li className='flex'>
      {items}
    </li>
  );
});

OrderManagementDetailsItemExpandPersonalization.propTypes = {
  personalizations: PropTypes.array,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

export default OrderManagementDetailsItemExpandPersonalization;
