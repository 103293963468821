import React from 'react';
import { ColorDto } from '@api/productCatalog/models';
import TextInfo from './TextInfo';

interface OwnProps {
  personalizationNumber: string | null | undefined;
  personalizationText: string | null | undefined;
  personalizationColor: ColorDto | undefined;
  orderNumber: number;
  decorationId: number | undefined;
  size?: string;
}

type Props = OwnProps;

const MarkItemAsFlaggedModalPersonalizationDetails = React.memo<Props>(({
  personalizationText,
  personalizationNumber,
  personalizationColor,
  orderNumber,
  decorationId,
  size,
}) => (
  <div className='flagging__details'>
    <div className='flagging__details-logo'>
      <TextInfo
        personalizationText={personalizationText}
        personalizationNumber={personalizationNumber}
        personalizationColor={personalizationColor}
      />
    </div>
    <div className='flagging__details-info'>
      <div className='flagging__details-info-column'>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Order ID
          </div>
          <div className='flagging__details-info-content'>
            {`O${orderNumber}`}
          </div>
        </div>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Decoration ID
          </div>
          <div className='flagging__details-info-content'>
            {`O${orderNumber}-${decorationId}`}
          </div>
        </div>
      </div>
      <div className='flagging__details-info-column'>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Size
          </div>
          <div className='flagging__details-info-content'>
            {size}
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default MarkItemAsFlaggedModalPersonalizationDetails;
