import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ArtworkTaskItemsContainer from '../ArtworkFileTasks/ArtworkTaskItems/ArtworkTaskItemsContainer';
import { createArtworkTasks } from '@APICalls/artwork/actions';
import { materialSwal } from '@util/componentHelper';

class LockerManagerCreateArtworkTasksModal extends PureComponent {
  state = {
    checkedTasks: [],
  };

  createTasks = async () => {
    const {
      product,
      tasks,
      refresh,
    } = this.props;

    const { checkedTasks } = this.state;

    const filteredTasks = tasks.filter((task) => !checkedTasks.includes(task.key));

    const res = await createArtworkTasks(filteredTasks);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refresh(product);
    }
  };

  toggleCreateTask = (key) => {
    const { checkedTasks } = this.state;

    if (checkedTasks.includes(key)) {
      this.setState({
        checkedTasks: checkedTasks.filter((k) => k !== key),
      });
    } else {
      this.setState({
        checkedTasks: [
          ...checkedTasks,
          key,
        ],
      });
    }
  };

  isConfirmBtnDisabled = () => {
    const { tasks } = this.props;
    const { checkedTasks } = this.state;

    const existingTasks = tasks.filter((t) => t.isExistingTask);

    return (tasks.length - (checkedTasks.length + existingTasks.length)) === 0;
  };

  closeModal = () => {
    const { closeModal } = this.props;

    this.setState({ checkedTasks: [] });

    closeModal();
  };

  render() {
    const {
      isOpen,
      tasks,
    } = this.props;

    const { checkedTasks } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        title={'Create Artwork Tasks'}
        modalSize={'xl'}
        closeModal={this.closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Create'}
            cancelBtnText={'Cancel'}
            onConfirm={this.createTasks}
            onClose={this.closeModal}
            confirmBtnDisabled={this.isConfirmBtnDisabled()}
          />
        )}
      >
        {
          tasks && tasks.length > 0
            ? (
              <ArtworkTaskItemsContainer
                tasks={tasks.items}
                isCreatingTasks={true}
                toggleCreateTask={this.toggleCreateTask}
                checkedTasks={checkedTasks}
              />
            )
            : <div>No relevant Product data to show</div>
        }
      </Modal>
    );
  }
}

LockerManagerCreateArtworkTasksModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  lockerId: PropTypes.number.isRequired,
  product: PropTypes.object,
  tasks: PropTypes.arrayOf(PropTypes.object),
  messages: PropTypes.array,
};

const mapStateToProps = ({
  artworkFileTasks,
  alert,
}) => ({
  tasks: artworkFileTasks.artworkFileTasks.items,
  messages: alert.messages,
});

export default connect(mapStateToProps)(LockerManagerCreateArtworkTasksModal);
