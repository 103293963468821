import React from 'react';
import PropTypes from 'prop-types';
import { downloadFile } from '@util/componentHelper';
import Icon from '@sharedComponents/Icons/Icon';

const ArtworkTaskFile = React.memo(({
  fileName,
  fileUrl,
  keyProp,
}) => (
  <a
    href={fileUrl}
    key={keyProp}
    className='uploads__item'
    onClick={downloadFile(fileUrl, fileName)}
  >
    <Icon materialIcon={'attachment'} />
    <span>{fileName}</span>
  </a>
));

ArtworkTaskFile.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  keyProp: PropTypes.number,
};

export default ArtworkTaskFile;
