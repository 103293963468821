import React, {
  useCallback, useMemo,
} from 'react';
import { usePutHomefieldApiSupportQualityassurancecodesCode } from '@api/fulfillment/support';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { QualityAssuranceCodeDto } from '@api/fulfillment/models';
import { materialSwal } from '@util/componentHelper';
import FormInput from '@components/shared/Form/FormInput';
import QualityAssuranceCodeForm, { QualityAssuranceCodeFormModel } from './QualityAssuranceCodeForm';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onEdit: () => void;
  item: QualityAssuranceCodeDto;
}

type Props = OwnProps;

const EditQualityAssuranceCodeModal = React.memo(({
  isOpen,
  closeModal,
  onEdit,
  item,
}: Props) => {
  const formId = `edit-qa-code-${item.code}`;
  const { mutateAsync: editCodeAsync } = usePutHomefieldApiSupportQualityassurancecodesCode();
  const defaultValues = useMemo<QualityAssuranceCodeFormModel>(() => ({
    code: item.code ?? '',
    name: item.name ?? '',
    owner: item.owner ?? '',
    scenarios: item.scenarios ?? '',
  }), [
    item.code,
    item.name,
    item.owner,
    item.scenarios,
  ]);

  const handleEditCode = useCallback(
    async (command: QualityAssuranceCodeFormModel) => {
      const variables = {
        code: command.code,
        data: command,
      };

      const res = await editCodeAsync(variables);

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        closeModal();
        onEdit();
      } else {
        materialSwal('Failure', res?.message ?? 'Failure occurred while adding code', 'error');
      }
    },
    [
      closeModal,
      onEdit,
      editCodeAsync,
    ]
  );

  return (
    <Modal
      title={`Edit ${item.code}`}
      isOpen={isOpen}
      modalHeight='xl'
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText='Save'
          cancelBtnText='Cancel'
          onClose={closeModal}
          formSubmission={true}
          formId={formId}
        />
      )}
    >
      <QualityAssuranceCodeForm
        needsReset={!isOpen}
        formId={formId}
        defaultValues={defaultValues}
        onSubmit={handleEditCode}
      />
    </Modal>
  );
});

export default EditQualityAssuranceCodeModal;
