import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import iconEye from '@assets/icon_hidden.svg';
import {
  bulkUpdateStyle,
  bulkDeleteStyles,
} from '@APICalls/productCatalog/actions';
import {
  pageSizeOptionsBigTable,
  noneBulkAction,
} from '@constants/values';
import {
  filterTypes,
  sortDirectionEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { discountTypeEnum } from '@constants/enums/couponEnums';
import { styleUrl } from '@constants/clientUrls/clientUrls';
import { TableEnum } from '@constants/enums/tableEnums';
import * as productCatalogActions from '@redux/productCatalog/actions';
import * as supportActions from '@redux/support/actions';
import { materialSwal } from '@util/componentHelper';
import {
  formatDollarAmount,
  parseNumber,
} from '@util/numberHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import {
  getSelectableTableRowProps,
  isSelectedItem,
  selectAllItems,
  selectItem,
} from '@util/selectionHelpers';
import memoizeOne from '@util/memoHelper';
import CheckCircleIcon from '@sharedComponents/Icons/CheckCircleIcon';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import TableBulkActions from '@sharedComponents/Table/TableComponents/TableBulkActions';
import SelectCell from '@sharedComponents/Table/TableCells/SelectCell';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import Table from '@sharedComponents/Table/Table';
import Icon from '@sharedComponents/Icons/Icon';
import BulkActionSelector from '@sharedComponents/Table/TableComponents/BulkActionSelector';
import ProductCatalogQuickView from './ProductCatalogQuickView/ProductCatalogQuickView';
import DeleteStylesModal from './ProductCatalogModals/DeleteStylesModal';
import EditStyleDiscountModal from './ProductCatalogModals/EditStyleDiscountModal';
import ProductCatalogFilters from './ProductCatalogFilters';

const StylesTable = Table();

const bulkActionsEnum = {
  toggleWebsiteEnabled: 'toggleWebsiteEnabled',
  toggleNewStoresAvailability: 'toggleNewStoresAvailability',
  toggleRecommended: 'toggleRecommended',
  setDiscount: 'setDiscount',
  deleteStyles: 'deleteStyles',
  toggleCustomizable: 'toggleCustomizable',
};

const mapStateToProps = ({
  productCatalog,
  tableManager,
}) => ({
  totalPages: productCatalog.styleQueue.totalPages,
  hasPreviousPage: productCatalog.styleQueue.hasPreviousPage,
  hasNextPage: productCatalog.styleQueue.hasNextPage,
  queue: productCatalog.styleQueue.queue,
  initialPageNumber: tableManager.productCatalog.pageNumber,
  initialPageSize: tableManager.productCatalog.pageSize,
  initialSortColumn: tableManager.productCatalog.sortColumn,
  initialSortDirection: tableManager.productCatalog.sortDirection,
  initialSearchInput: tableManager.productCatalog.searchInput,
  initialBrandsFilter: tableManager.productCatalog.brandsFilter,
  initialCategoriesFilter: tableManager.productCatalog.categoriesFilter,
  initialAgesFilter: tableManager.productCatalog.agesFilter,
  initialGendersFilter: tableManager.productCatalog.gendersFilter,
  initialSportsFilter: tableManager.productCatalog.sportsFilter,
  initialColorsFilter: tableManager.productCatalog.colorsFilter,
  initialSizesFilter: tableManager.productCatalog.sizesFilter,
  initialRecommendedFilter: tableManager.productCatalog.recommendedFilter,
  initialAvailableForNewStoresFilter: tableManager.productCatalog.availableForNewStoresFilter,
  initialCustomizableFilter: tableManager.productCatalog.customizableFilter,
  initialPriceMinFilter: tableManager.productCatalog.priceMinFilter,
  initialPriceMaxFilter: tableManager.productCatalog.priceMaxFilter,
  initialVendorQuantityMinFilter: tableManager.productCatalog.vendorQuantityMinFilter,
  initialWebsiteVisibiltiyFilter: tableManager.productCatalog.websiteVisibiltiyFilter,
  initialVendorNamesFilter: tableManager.productCatalog.vendorNamesFilter,
  initialDecorationMethodsFilter: tableManager.productCatalog.decorationMethodsFilter,
});

const mapDispatchToProps = {
  fetchStyleTableWithState: productCatalogActions.fetchStyleTableWithState,
  getBrands: productCatalogActions.getBrands,
  getCategories: productCatalogActions.getCategories,
  getSports: productCatalogActions.getSports,
  getAges: productCatalogActions.getAges,
  getGenders: productCatalogActions.getGenders,
  getColorGroups: productCatalogActions.getColorGroups,
  getSizes: productCatalogActions.getSizes,
  getAllVendors: productCatalogActions.getAllVendors,
  getDecorationMethods: productCatalogActions.getDecorationMethods,
  fetchTeamColors: supportActions.fetchTeamColors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

class ProductCatalog extends Component {
  state = {
    selectedStyle: null,
    selectedItems: [],
    selectAll: false,
    selectedBulkAction: noneBulkAction.value,
    deleteModalIsOpen: false,
    discountModalIsOpen: false,

    pageNumber: this.props.initialPageNumber,
    pageSize: this.props.initialPageSize,
    sortColumn: this.props.initialSortColumn,
    sortDirection: this.props.initialSortDirection,
    brandsFilter: this.props.initialBrandsFilter,
    vendorNamesFilter: this.props.initialVendorNamesFilter,
    vendorQuantityFilter: this.props.initialVendorQuantityMinFilter,
    websiteVisibilityFilter: this.props.initialWebsiteVisibilityFilter,
    decorationMethodsFilter: this.props.initialDecorationMethodsFilter,
    categoriesFilter: this.props.initialCategoriesFilter,
    agesFilter: this.props.initialAgesFilter,
    gendersFilter: this.props.initialGendersFilter,
    sportsFilter: this.props.initialSportsFilter,
    colorsFilter: this.props.initialColorsFilter,
    sizesFilter: this.props.initialSizesFilter,
    recommendedFilter: this.props.initialRecommendedFilter,
    availableForNewStoresFilter: this.props.initialAvailableForNewStoresFilter,
    searchInput: this.props.initialSearchInput,
    customizableFilter: this.props.initialCustomizableFilter,
    priceMinFilter: this.props.initialPriceMinFilter,
    priceMaxFilter: this.props.initialPriceMaxFilter,
  };

  componentDidMount() {
    const {
      getBrands,
      getCategories,
      getAges,
      getGenders,
      getSports,
      getColorGroups,
      getSizes,
      getAllVendors,
      getDecorationMethods,
      fetchTeamColors,
    } = this.props;

    getBrands();
    getCategories();
    getAges();
    getGenders();
    getSports();
    getColorGroups();
    getSizes();
    fetchTeamColors();
    getAllVendors();
    getDecorationMethods();
  }

  getBulkActionOptions = () => {
    const bulkOptions = [
      {
        key: 0,
        value: bulkActionsEnum.toggleWebsiteEnabled,
        name: 'Toggle Website Enabled',
      },
      {
        key: 1,
        value: bulkActionsEnum.toggleNewStoresAvailability,
        name: 'Toggle New Stores Availability',
      },
      {
        key: 2,
        value: bulkActionsEnum.toggleRecommended,
        name: 'Toggle Recommended',
      },
      {
        key: 3,
        value: bulkActionsEnum.toggleCustomizable,
        name: 'Toggle Customizable',
      },
      {
        key: 4,
        value: bulkActionsEnum.setDiscount,
        name: 'Set Discount',
      },
      {
        key: 5,
        value: bulkActionsEnum.deleteStyles,
        name: 'Delete Styles',
      },
    ];

    return bulkOptions;
  };

  closeDeleteStyleModal = () => {
    this.setState(() => ({ deleteModalIsOpen: false }));
  };

  closeDiscountStyleModal = () => {
    this.setState(() => ({ discountModalIsOpen: false }));
  };

  setDiscountValue = async (discount, type) => {
    if (type === discountTypeEnum.DollarAmount) {
      await this.bulkToggle(false, false, false, false, discount);
    } else {
      await this.bulkToggle(false, false, false, false, null, discount);
    }

    this.closeDiscountStyleModal();
  };

  deleteStyles = async () => {
    const { selectedItems } = this.state;
    const selectedStyleIds = selectedItems.map((i) => i.id);

    const res = await bulkDeleteStyles(selectedStyleIds);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.clearSelection();
      this.refreshData();
      this.setState(() => ({
        deleteModalIsOpen: false,
        selectedBulkAction: noneBulkAction.value,
      }));
    }
  };

  selectItem = (item, isSelected) => {
    const { queue } = this.props;

    const {
      selectedItems,
      selectAll,
    } = this.state;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectItem(queue, selectedItems, selectAll, item, isSelected, 'id');

    this.setState(() => ({
      selectedItems: newSelectedItems,
      selectAll: newSelectAll,
    }));
  };

  selectAllItems = () => {
    const { queue } = this.props;

    const {
      selectAll,
      selectedItems,
    } = this.state;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectAllItems(queue, selectedItems, selectAll, 'id');

    this.setState(() => ({
      selectedItems: newSelectedItems,
      selectAll: newSelectAll,
    }));
  };

  clearSelection = () => {
    this.setState(() => ({
      selectedItems: [],
      selectAll: false,
    }));
  };

  updateSelectedBulkAction = (selectedBulkAction) => {
    this.setState(() => ({
      selectedBulkAction,
    }), async () => this.initiateBulkAction());
  };

  initiateBulkAction = async () => {
    const { selectedBulkAction } = this.state;

    if (selectedBulkAction === noneBulkAction.value) {
      return;
    }

    if (selectedBulkAction === bulkActionsEnum.setDiscount) {
      this.setState(() => ({
        discountModalIsOpen: true,
        selectedBulkAction: noneBulkAction.value,
      }));
    } else if (selectedBulkAction === bulkActionsEnum.deleteStyles) {
      this.setState(() => ({
        deleteModalIsOpen: true,
        selectedBulkAction: noneBulkAction.value,
      }));
    } else {
      await this.bulkToggle(
        selectedBulkAction === bulkActionsEnum.toggleWebsiteEnabled,
        selectedBulkAction === bulkActionsEnum.toggleNewStoresAvailability,
        selectedBulkAction === bulkActionsEnum.toggleRecommended,
        selectedBulkAction === bulkActionsEnum.toggleCustomizable
      );
    }
  };

  bulkToggle = async (
    websiteEnabled,
    newStoresAvailability,
    recommended,
    customizable,
    discount,
    discountPercentage
  ) => {
    const { selectedItems } = this.state;
    const selectedStyleIds = selectedItems.map((i) => i.id);

    const res = await bulkUpdateStyle(
      selectedStyleIds,
      websiteEnabled,
      newStoresAvailability,
      recommended,
      customizable,
      parseNumber(discount),
      parseNumber(discountPercentage)
    );

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.clearSelection();
      this.refreshData();
      this.setState(() => ({ selectedBulkAction: noneBulkAction.value }));
    }
  };

  selectStyle = (selectedStyle) => {
    this.setState(() => ({ selectedStyle }));
  };

  unselectStyle = () => {
    this.setState(() => ({ selectedStyle: null }));
  };

  fetchData = (state, instance) => {
    const {
      sortColumn,
      sortDirection,
    } = this.state;

    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortColumn, sortDirection);

    this.setState(() => ({
      pageNumber: newPageNumber + 1,
      pageSize: newPageSize,
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    }), this.refreshData);
  };

  refreshData = async () => {
    const { fetchStyleTableWithState } = this.props;
    const {
      pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      searchInput,
      availableForNewStoresFilter,
      recommendedFilter,
      customizableFilter,
      brandsFilter,
      vendorNamesFilter,
      vendorQuantityMinFilter,
      websiteVisibilityFilter,
      decorationMethodsFilter,
      categoriesFilter,
      agesFilter,
      gendersFilter,
      sportsFilter,
      colorsFilter,
      sizesFilter,
      priceMinFilter,
      priceMaxFilter,
    } = this.state;

    return fetchStyleTableWithState(
      pageNumber,
      pageSize,
      brandsFilter,
      vendorNamesFilter,
      vendorQuantityMinFilter,
      websiteVisibilityFilter,
      decorationMethodsFilter,
      categoriesFilter,
      agesFilter,
      gendersFilter,
      sportsFilter,
      colorsFilter,
      sizesFilter,
      recommendedFilter ? true : null,
      availableForNewStoresFilter ? true : null,
      sortColumn,
      sortDirection,
      searchInput,
      customizableFilter ? true : null,
      priceMinFilter,
      priceMaxFilter
    );
  };

  filterTable = (items, type) => {
    switch (type) {
      case filterTypes.brands:
        return this.setState(() => ({ brandsFilter: items }), this.refreshData);
      case filterTypes.categories:
        return this.setState(() => ({ categoriesFilter: items }), this.refreshData);
      case filterTypes.ages:
        return this.setState(() => ({ agesFilter: items }), this.refreshData);
      case filterTypes.genders:
        return this.setState(() => ({ gendersFilter: items }), this.refreshData);
      case filterTypes.sports:
        return this.setState(() => ({ sportsFilter: items }), this.refreshData);
      case filterTypes.colors:
        return this.setState(() => ({ colorsFilter: items }), this.refreshData);
      case filterTypes.sizes:
        return this.setState(() => ({ sizesFilter: items }), this.refreshData);
      case filterTypes.quantity:
        return this.setState(() => ({ vendorQuantityMinFilter: items }), this.refreshData);
      case filterTypes.visibility:
        return this.setState(() => ({ websiteVisibilityFilter: items }), this.refreshData);
      case filterTypes.vendors:
        return this.setState(() => ({ vendorNamesFilter: items }), this.refreshData);
      case filterTypes.decorationMethods:
        return this.setState(() => ({ decorationMethodsFilter: items }), this.refreshData);
    }
  };

  recommendedChange = () => {
    this.setState((prevState) => ({
      recommendedFilter: !prevState.recommendedFilter,
    }), this.refreshData);
  };

  availableForNewStoresChange = () => {
    this.setState((prevState) => ({
      availableForNewStoresFilter: !prevState.availableForNewStoresFilter,
    }), this.refreshData);
  };

  customOnlyChange = () => {
    this.setState((prevState) => ({
      customizableFilter: !prevState.customizableFilter,
    }), this.refreshData);
  };

  clearSearch = () => {
    this.setState(() => ({
      searchInput: '',
    }), this.refreshData);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const search = e.target.value;

    this.setState(() => ({
      searchInput: search,
    }), this.refreshData);
  };

  searchPriceValues = (minValue, maxValue) => {
    this.setState(() => ({
      priceMinFilter: minValue,
      priceMaxFilter: maxValue,
    }), this.refreshData);
  };

  getColumns = () => {
    const {
      selectedStyle,
      selectAll,
      selectedItems,
    } = this.state;

    let columns = [
      {
        Header: () => (
          <SelectAllCell
            isSelected={selectAll}
            selectAllItems={this.selectAllItems}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <SelectCell
            select={this.selectItem}
            item={cellProps.value}
            isSelected={isSelectedItem(selectedItems, cellProps.value, 'id')}
          />
        ),
      },
      {
        Header: 'Parent SKU',
        accessor: 'code',
        sortable: false,
        minWidth: 40,
      },
      {
        Header: 'Style Name',
        accessor: '',
        sortable: false,
        minWidth: 100,
        Cell: (cellProps) => (
          <div className='product-catalog__style-name'>
            <Link to={styleUrl(cellProps.value.id)}>
              {cellProps.value.name}
            </Link>
            <div className='product-catalog__style-icons'>
              {
                cellProps.value.disabled &&
                <MaterialTooltip
                  tooltipText={<div className='pre-line'>Style is disabled</div>}
                  placement={'top'}
                  contentClasses={'align__center'}
                >
                  <i
                    className='product-catalog__hidden'
                    style={{ backgroundImage: `url("${iconEye}")` }}
                  />
                </MaterialTooltip>
              }
              {
                cellProps.value.discontinued &&
                <MaterialTooltip
                  tooltipText={<div className='pre-line'>Style is discontinued</div>}
                  placement={'top'}
                  contentClasses={'align__center'}
                >
                  <Icon
                    materialIcon={'block'}
                    classes={'active'}
                  />
                </MaterialTooltip>
              }
            </div>
          </div>
        ),
      },
      {
        Header: <HeaderCell text={'Brand'} />,
        accessor: 'brand.name',
        id: 'brand',
        sortable: true,
        minWidth: 45,
      },
      {
        Header: 'Category',
        accessor: 'category.name',
        sortable: false,
        minWidth: 50,
      },
    ];

    if (!selectedStyle) {
      columns = [
        ...columns,
        {
          Header: 'Age',
          accessor: 'age',
          sortable: false,
          minWidth: 25,
        },
        {
          Header: 'Gender',
          accessor: 'gender',
          sortable: false,
          minWidth: 30,
        },
        {
          Header: 'Discount',
          accessor: 'discount',
          sortable: false,
          minWidth: 30,
          Cell: (cellProps) => <div className='product-catalog__icon-center'>{cellProps.value ? formatDollarAmount(cellProps.value) : '-'}</div>,
        },
        {
          Header: 'Recommended',
          accessor: 'recommended',
          sortable: false,
          minWidth: 45,
          Cell: (cellProps) => <div className='product-catalog__icon-center'>{cellProps.value ? <CheckCircleIcon /> : '-'}</div>,
        },
        {
          Header: 'Custom',
          accessor: 'customizable',
          sortable: false,
          minWidth: 30,
          Cell: (cellProps) => <div className='product-catalog__icon-center'>{cellProps.value ? <CheckCircleIcon /> : '-'}</div>,
        },
        {
          Header: 'New Stores',
          accessor: 'availableForNewStores',
          sortable: false,
          minWidth: 40,
          Cell: (cellProps) => <div className='product-catalog__icon-center'>{cellProps.value ? <CheckCircleIcon /> : '-'}</div>,
        },
      ];
    }

    return columns;
  };

  onSelectStyle = (style) => {
    this.clearSelection();
    this.selectStyle(style);
  };

  getTrProps = (state, rowInfo) => {
    const { selectedStyle } = this.state;

    return getSelectableTableRowProps(this.onSelectStyle, rowInfo, selectedStyle, 'id');
  };

  getFiltersToPreserve = memoizeOne((
    searchInput,
    availableForNewStoresFilter,
    recommendedFilter,
    customizableFilter,
    brandsFilter,
    vendorNamesFilter,
    vendorQuantityFilter,
    websiteVisibilityFilter,
    decorationMethodsFilter,
    categoriesFilter,
    agesFilter,
    gendersFilter,
    sportsFilter,
    colorsFilter,
    sizesFilter,
    priceMinFilter,
    priceMaxFilter
  ) => ({
    searchInput,
    availableForNewStoresFilter,
    recommendedFilter,
    customizableFilter,
    brandsFilter,
    vendorNamesFilter,
    vendorQuantityFilter,
    websiteVisibilityFilter,
    decorationMethodsFilter,
    categoriesFilter,
    agesFilter,
    gendersFilter,
    sportsFilter,
    colorsFilter,
    sizesFilter,
    priceMinFilter,
    priceMaxFilter,
  }));

  render() {
    const {
      queue,
      pageSize,
      search,
      totalPages,
      hasNextPage,
      hasPreviousPage,
      initialPageNumber,
      initialPageSize,
      initialSortColumn,
      initialSortDirection,
    } = this.props;

    const {
      selectedStyle,
      deleteModalIsOpen,
      discountModalIsOpen,
      selectedItems,
      selectedBulkAction,
      searchInput,
      availableForNewStoresFilter,
      recommendedFilter,
      customizableFilter,
      brandsFilter,
      vendorNamesFilter,
      vendorQuantityFilter,
      websiteVisibilityFilter,
      decorationMethodsFilter,
      categoriesFilter,
      agesFilter,
      gendersFilter,
      sportsFilter,
      colorsFilter,
      sizesFilter,
      priceMinFilter,
      priceMaxFilter,
    } = this.state;

    const wrappedColumns = [
      {
        Header: (
          <TableBulkActions
            selectedItems={selectedItems}
            clearAll={this.clearSelection}
            bulkActions={(
              <BulkActionSelector
                selectedBulkAction={selectedBulkAction}
                onSelectBulkAction={this.updateSelectedBulkAction}
                options={this.getBulkActionOptions()}
              />
            )}
          />
        ),
        columns: this.getColumns(),
      },
    ];

    return (
      <div className='container'>
        <DeleteStylesModal
          isOpen={deleteModalIsOpen}
          styles={selectedItems}
          closeModal={this.closeDeleteStyleModal}
          deleteStyles={this.deleteStyles}
        />
        <EditStyleDiscountModal
          isOpen={discountModalIsOpen}
          closeModal={this.closeDiscountStyleModal}
          setDiscount={this.setDiscountValue}
        />
        <div className='table-options w-100'>
          <ProductCatalogFilters
            search={search}
            filterKey={this.filterKey}
            clearSearch={this.clearSearch}
            filter={this.filterTable}
            searchPriceValues={this.searchPriceValues}
            recommendedChecked={recommendedFilter}
            recommendedChange={this.recommendedChange}
            availableForNewStoresChecked={availableForNewStoresFilter}
            availableForNewStoresChange={this.availableForNewStoresChange}
            customOnlyChecked={!!customizableFilter}
            customOnlyChange={this.customOnlyChange}
          />
        </div>
        <div className='master-detail'>
          <div className='w-100'>
            <div className='sheet'>
              <StylesTable
                data={queue}
                columns={wrappedColumns}
                totalPages={totalPages}
                onFetchData={this.fetchData}
                showPagination={hasNextPage || hasPreviousPage}
                defaultPageSize={pageSize}
                pageSizeOptions={pageSizeOptionsBigTable}
                isBulkActionsMode={selectedItems && selectedItems.length > 0}
                getTrProps={this.getTrProps}
                tableId={TableEnum.productCatalog}
                preserveState={true}
                sortDirEnum={sortDirectionEnum}
                initialPageNumber={initialPageNumber}
                initialPageSize={initialPageSize}
                initialSortColumn={initialSortColumn}
                initialSortDirection={initialSortDirection}
                filtersToPreserve={this.getFiltersToPreserve(
                  searchInput,
                  availableForNewStoresFilter,
                  recommendedFilter,
                  customizableFilter,
                  brandsFilter,
                  vendorNamesFilter,
                  vendorQuantityFilter,
                  websiteVisibilityFilter,
                  decorationMethodsFilter,
                  categoriesFilter,
                  agesFilter,
                  gendersFilter,
                  sportsFilter,
                  colorsFilter,
                  sizesFilter,
                  priceMinFilter,
                  priceMaxFilter
                )}
              />
            </div>
          </div>
          {
            selectedStyle &&
            <ProductCatalogQuickView
              closeDetails={this.unselectStyle}
              styleId={selectedStyle.id}
            />
          }
        </div>
      </div>
    );
  }
}

ProductCatalog.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialBrands: PropTypes.array,
  initialCategories: PropTypes.array,
  initialAges: PropTypes.array,
  initialGenders: PropTypes.array,
  initialSports: PropTypes.array,
  initialColors: PropTypes.array,
  initialSizes: PropTypes.array,
  initialVendorQuantity: PropTypes.string,
  initialWebsiteVisibility: PropTypes.string,
  initialVendorNames: PropTypes.array,
  initialDecorationMethods: PropTypes.array,
  recommended: PropTypes.string,
  availableForNewStores: PropTypes.string,
  customizable: PropTypes.bool,
  priceMin: PropTypes.string,
  priceMax: PropTypes.string,
  search: PropTypes.string,
  sortDirection: PropTypes.string,
  sortColumn: PropTypes.string,
};

export default connector(ProductCatalog);
