import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import DecorationLocationArtworkSizeForm from '../DecorationLocationArtworkSizeForm';
import { decorationLocationArtworkSizeForm } from '@constants/reduxForms';

const DecorationLocationArtworkSizeAddModal = (props) => {
  const {
    isOpen,
    addDecorationLocationArtworkSize,
    initialValues,
    decorationLocations,
    closeModal,
  } = props;

  return (
    <Modal
      title={'Add Artwork Size'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={decorationLocationArtworkSizeForm}
        />
      )}
    >
      <DecorationLocationArtworkSizeForm
        onSubmit={addDecorationLocationArtworkSize}
        decorationLocations={decorationLocations}
        initialValues={initialValues}
      />
    </Modal>
  );
};

DecorationLocationArtworkSizeAddModal.propTypes = {
  addDecorationLocationArtworkSize: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DecorationLocationArtworkSizeAddModal;
