import React, {
  useState,
  useCallback,
} from 'react';
import { CollectionItemViewModel } from '@api/squadlockerServices/models';
import RefundPrice from '@sharedComponents/Display/RefundPrice';
import Image from '@sharedComponents/Display/Image';
import Tag from '@sharedComponents/Display/Tag';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';
import OrderManagementDetailsItemLabels from '@components/OrderManagement/OrderManagementDetailsItemLabels';

interface OwnProps {
  item: CollectionItemViewModel;
}

type Props = OwnProps;

const ItemRow = React.memo<Props>(({ item }) => {
  const [
    itemInfoModalIsOpen,
    setItemInfoModalIsOpen,
  ] = useState<boolean>(false);

  const openItemInfoModal = useCallback(() => {
    setItemInfoModalIsOpen(true);
  }, []);

  const closeItemInfoModal = useCallback(() => {
    setItemInfoModalIsOpen(false);
  }, []);

  return (
    <div className='order__item'>
      <ItemInfoModal
        isOpen={itemInfoModalIsOpen}
        closeModal={closeItemInfoModal}
        coloredStyleItemId={item.coloredStyleId}
        lockerItemId={item.isLockerItem ? item.id : null}
      />
      <div className='align__center'>
        <div className='order__item-column align__center ml-10 mr-10'>
          <Image
            url={item.cachedImage}
            openInModal={openItemInfoModal}
            showImageInModal={false}
          />
        </div>
      </div>
      <div className='order__item-column'>
        <div className='align__center space-between'>
          <span className='order__item--sku-info'>
            {item.code || '-'}
            <OrderManagementDetailsItemLabels item={item} />
          </span>
        </div>
        <span className='order__item--style-name'>{item.name || ''}</span>
        <ul className='order__item-tags'>
          <li>
            <Tag value={'x1'} />
          </li>
          <li>
            <Tag
              label={'Color'}
              value={item.color}
            />
          </li>
          <li>
            <Tag
              label={'Price'}
              value={(
                <RefundPrice
                  paid={item.price}
                />
              )}
            />
          </li>
        </ul>
      </div>
    </div>
  );
});

export default ItemRow;
