import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import {
  clearPrintSheetArtworks,
  fetchPrintSheetArtworks,
} from '@redux/printFiles/actions';
import {
  fetchSheetDetails,
  rejectPrintSheet,
} from '@redux/printRoomArt/actions';
import {
  materialSwal,
  swalChoose,
  navigateToPage,
} from '@util/componentHelper';
import { createOptionsList } from '@util/optionsMap';
import { printFilesUrl } from '@constants/clientUrls/clientUrls';
import PrintSheetRejectTable from './PrintSheetRejectTable';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class PrintSheetRejectModal extends Component {
  state = {
    selection: [],
    selectionNotes: {},
    disabled: true,
    selectAll: false,
  };

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(clearPrintSheetArtworks());
  }

  updateFailReason = (artworkTaskId, value) => {
    const { selectionNotes } = this.state;

    const artworkTaskNote = selectionNotes[artworkTaskId];
    artworkTaskNote.reason = value;
    selectionNotes[artworkTaskId] = artworkTaskNote;

    this.setState({ selectionNotes });
  };

  updateFailNote = (artworkTaskId, value) => {
    const { selectionNotes } = this.state;

    const artworkTaskNote = selectionNotes[artworkTaskId];
    artworkTaskNote.notes = value;
    selectionNotes[artworkTaskId] = artworkTaskNote;

    this.setState({ selectionNotes });
  };

  rejectItem = (sheetId, artworkTaskReworks, callback) => {
    const callbackSwal = async (rejectSheet) => {
      const res = await rejectPrintSheet(artworkTaskReworks, rejectSheet, sheetId);
      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        callback();
      }
    };

    const text = {
      title: '',
      text: 'Do you want too keep non affected orders or re-queue them?',
      confirm: 'Re-queue orders',
      cancel: 'Keep orders',
    };

    swalChoose(text)
      .then((result) => {
        if (result) {
          if (result.value) {
            callbackSwal(true);
          } else if (result.dismiss && result.dismiss === 'cancel') {
            callbackSwal(false);
          }
        }
      });
  };

  rejectFileOnClick = () => {
    const { selectionNotes } = this.state;
    const {
      sheetId,
      closeModal,
      dispatch,
    } = this.props;

    if (!this.checkFilled()) {
      materialSwal('Reasons not filled', '', 'warning');
    } else {
      this.rejectItem(
        sheetId,
        Object.values(selectionNotes),
        async () => {
          const res = await dispatch(fetchSheetDetails(sheetId));
          if (!res) {
            navigateToPage(printFilesUrl);
          }
          closeModal();
        }
      );
    }
  };

  checkFilled = () => {
    const { selectionNotes } = this.state;
    const values = Object.values(selectionNotes);

    if (!values) return false;

    let filled = true;
    values.forEach((element) => {
      if (!element.reason && !element.notes) {
        filled = false;
      }
    });

    return filled;
  };

  toggleSelection = (artworkTaskId, isSelected) => {
    const {
      selection,
      selectAll,
      selectionNotes,
    } = this.state;
    const { queue } = this.props;

    if (isSelected) {
      delete selectionNotes[artworkTaskId];
      this.setState({
        selection: selection.filter((p) => p !== artworkTaskId),
        selectionNotes,
        selectAll: selection.length <= 1 ? false : selectAll,
      });
    } else {
      selection.push(artworkTaskId);
      selectionNotes[artworkTaskId] = { artworkTaskId };
      this.setState({
        selection,
        selectAll: selection.length === queue.length ? true : selectAll,
        selectionNotes,
      });
    }
  };

  selectAllItems = () => {
    const { queue } = this.props;
    const {
      selectAll,
      selection,
      selectionNotes,
    } = this.state;

    if (!selectAll) {
      const newSelectedItems = [...selection];

      for (const item of queue) {
        if (!selection.includes(item.artworkTaskId)) {
          newSelectedItems.push(item.artworkTaskId);
          selectionNotes[item.artworkTaskId] = { artworkTaskId: item.artworkTaskId };
        }
      }

      this.setState({
        selection: newSelectedItems,
        selectAll: true,
        selectionNotes,
      });
    } else {
      this.setState({
        selection: [],
        selectAll: false,
        selectionNotes: {},
      });
    }
  };

  isSelected = (key) => {
    const { selection } = this.state;

    return selection.includes(key);
  };

  fetchData = () => {
    const {
      dispatch,
      sheetId,
    } = this.props;

    dispatch(fetchPrintSheetArtworks(sheetId));
  };

  render() {
    const {
      queue,
      failReasons,
      closeModal,
      isOpen,
    } = this.props;
    const { selectAll } = this.state;

    const failReasonsOptions = failReasons && createOptionsList({
      list: failReasons,
      key: 'id',
      value: 'reason',
      name: 'reason',
      emptyOption: {
        disabled: true,
        name: 'Select fail reason',
      },
    });

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        modalSize={'xl'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            onConfirm={this.rejectFileOnClick}
            confirmBtnText={'Reject'}
            isDangerousAction={true}
          />
        )}
      >
        {
          isOpen &&
          <div className='align align--center'>
            <PrintSheetRejectTable
              data={queue}
              onFetchData={this.fetchData}
              toggleSelection={this.toggleSelection}
              toggleSelectAll={this.selectAllItems}
              isSelected={this.isSelected}
              updateFailNote={this.updateFailNote}
              updateFailReason={this.updateFailReason}
              failReasons={failReasonsOptions}
              selectAll={selectAll}
            />
          </div>
        }
      </Modal>
    );
  }
}

PrintSheetRejectModal.propTypes = {
  queue: PropTypes.array.isRequired,
  failReasons: PropTypes.arrayOf(PropTypes.object),
  sheetId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  artworkFileTasks,
  printFiles,
}) => ({
  failReasons: artworkFileTasks.failReasons,
  queue: printFiles.printSheetArtworkTask,
});

export default withRouter(connect(mapStateToProps)(PrintSheetRejectModal));
