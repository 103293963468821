import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const SportDeleteModal = React.memo(({
  isOpen,
  closeModal,
  sport,
  deleteSport,
}) => (
  <Modal
    title={'Delete Sport'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteSport.bind(null, sport.id)}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to remove sport <i>{sport.name}</i> from the list?</h3>
  </Modal>
));

SportDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  sport: PropTypes.object.isRequired,
  deleteSport: PropTypes.func.isRequired,
};

export default SportDeleteModal;
