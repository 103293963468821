import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { submitStatusEnum } from '@constants/enums/orderEnums';
import Button from '@sharedComponents/Buttons/Button';
import OrderManagementDetailsOrderSubmitResultsModal from './OrderManagementDetails/OrderManagementDetailsModals/OrderManagementDetailsOrderSubmitResultsModal';
import InfoField from '@components/shared/Display/InfoField';
import { ServiceTypeEnum } from '@api/fulfillment/models';

class OrderManagementSOPAndCustomerNumber extends PureComponent {
  renderSOP = () => {
    const {
      order,
      openModal,
    } = this.props;
    switch (order.accountingSubmitStatus) {
      case submitStatusEnum.Done:
        return order.gpDocumentId;
      case submitStatusEnum.Pending:
        return 'Pending...';
      case submitStatusEnum.NotScheduled:
        return 'Not Scheduled';
      case submitStatusEnum.Failed:
        return (
          <div className='flex'>
            <div className='order__failed-text'>Failed</div>
            <Button
              type={'danger'}
              text={'Details'}
              onClick={openModal}
              size={'s'}
              classes={'button--size-s ml-20'}
            />
          </div>
        );
      default:
        return null;
    }
  };

  renderCustomerNumber = () => {
    const { order } = this.props;

    if (order.accountingId && !order.gpCustomerId) {
      return '-';
    }

    return order.gpCustomerId;
  };

  render() {
    const {
      order,
      isSubmitResultsModalOpen,
      closeModal,
      fetchOrder,
    } = this.props;

    return (
      <div className='flex'>
        <div className='info__field order__shipping__column--short'>
          {
            isSubmitResultsModalOpen &&
            <OrderManagementDetailsOrderSubmitResultsModal
              order={order}
              isOpen={isSubmitResultsModalOpen}
              closeModal={closeModal}
              serviceType={ServiceTypeEnum.GreatPlains}
              fetchOrder={fetchOrder}
            />
          }
          <InfoField
            label='SOP Number'
            value={this.renderSOP()}
          />
        </div>
        <InfoField
          label={order.organizationId ? 'Organization ID' : 'LCK Number'}
          value={this.renderCustomerNumber()}
        />
      </div>
    );
  }
}

OrderManagementSOPAndCustomerNumber.propTypes = {
  order: PropTypes.object.isRequired,
  isSubmitResultsModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default OrderManagementSOPAndCustomerNumber;
