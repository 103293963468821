import React, { useCallback } from 'react';

interface OwnProps {
  id: number;
  nextStatus: string;
  handleStatusUpdate: (id: number, nextStatus: string) => void;
  linkText: string;
}

type Props = OwnProps;

const UpdatePrintFileStatus = React.memo<Props>(({
  id,
  nextStatus,
  handleStatusUpdate,
  linkText,
}) => {
  const update = useCallback((e) => {
    e.preventDefault();
    handleStatusUpdate(id, nextStatus);
  }, [
    id,
    nextStatus,
    handleStatusUpdate,
  ]);

  return (
    <button
      onClick={update}
      className='button button--color-gossamer button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase'
    >
      {linkText}
    </button>
  );
});

export default UpdatePrintFileStatus;
