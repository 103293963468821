import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import VendorForm from '../VendorForm';
import { vendorForm } from '@constants/reduxForms';

const VendorAddModal = React.memo(({
  isOpen,
  onSubmit,
  initialValues,
  change,
  closeModal,
}) => (
  <Modal
    title={'Add Vendor'}
    isOpen={isOpen}
    modalHeight={'xl'}
    modalWidth={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Add'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={vendorForm}
      />
    )}
  >
    <VendorForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      change={change}
    />
  </Modal>
));

VendorAddModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  change: PropTypes.func,
  initialValues: PropTypes.object,
};

export default VendorAddModal;
