import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
} from 'react';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import {
  markOrderItemCheckedIn,
  markOrderItemQualityChecked,
  markOrderItemPreTreated,
  unmarkOrderItemCheckedIn,
  unmarkOrderItemQualityChecked,
  unmarkOrderItemPreTreated,
  markOrderItemWaterproofed,
  unMarkOrderItemWaterproofed,
  updateOrderItemsBarcodes,
} from '@APICalls/productionAssembly/actions';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { scanOrderItemsBarcodesForm } from '@constants/reduxForms';
import { useGetProductBySku } from '@hooks/queries/products-catalog-api/useGetProductBySku';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import SimpleWarningModal from '@sharedComponents/Modal/SimpleWarningModal';
import AddButton from '@sharedComponents/Display/AddButton';
import EditButton from '@sharedComponents/Display/EditButton';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import Icon from '@sharedComponents/Icons/Icon';
import Image from '@sharedComponents/Display/Image';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';
import ProductionAssemblyAddBarcodesModal from './Modals/ProductionAssemblyAddBarcodesModal';
import ProductionAssemblyEditBarcodesModal from './Modals/ProductionAssemblyEditBarcodesModal';
import ProductionAssemblyReviewItemSettings from './ProductionAssemblyReviewItemSettings';
import ProductionAssemblyReviewItemTasks from './ProductionAssemblyReviewItemTasks';
import ProductionAssemblyReviewItemHistory from './ProductionAssemblyReviewItemHistory';
import ProductAssemblyReviewItemArrivalStatus from './ProductAssemblyReviewItemArrivalStatus';

const checkInNotCheckedStatuses = [
  orderItemStatusEnum.Initial,
  orderItemStatusEnum.OnHold,
  orderItemStatusEnum.AwaitingDispatchForPicking,
  orderItemStatusEnum.InPicking,
  orderItemStatusEnum.Picked,
];

const checkInEnabledStatuses = [
  orderItemStatusEnum.InPicking,
  orderItemStatusEnum.Picked,
  orderItemStatusEnum.CheckedIn,
  orderItemStatusEnum.Assembled,
];

const pretreatCheckedStatuses = [
  orderItemStatusEnum.Assembled,
  orderItemStatusEnum.CheckedIn,
];

const pretreatEnabledStatuses = [
  orderItemStatusEnum.CheckedIn,
  orderItemStatusEnum.Assembled,
  orderItemStatusEnum.PreTreated,
];

const waterproofedCheckedStatuses = [
  orderItemStatusEnum.Waterproofed,
  orderItemStatusEnum.QualityChecked,
  orderItemStatusEnum.Shipped,
];

const waterproofedEnabledStatuses = [
  orderItemStatusEnum.ProductionCompleted,
  orderItemStatusEnum.Waterproofed,
];

const qcEnabledForNotWaterproofedStatuses = [
  orderItemStatusEnum.ProductionCompleted,
  orderItemStatusEnum.QualityChecked,
];

const qcEnabledForWaterproofedStatuses = [
  orderItemStatusEnum.Waterproofed,
  orderItemStatusEnum.QualityChecked,
];

const ProductionAssemblyReviewItem = (props) => {
  const {
    showImage,
    dtgColoredStyleConfigDict,
    onAddToPrintQueue,
    dispatch,
    item,
    checksDisabled,
    orderId,
    orderNumber,
    selectAllButtonOnClick,
    orderItems,
    isStatusHistoryShown,
    refresh,
    highlighted,
  } = props;

  const [
    itemImage,
    setItemImage,
  ] = useState('');
  const [
    showImages,
    setShowImages,
  ] = useState(true);
  const [
    itemInfoModalIsOpen,
    setItemInfoModalIsOpen,
  ] = useState(false);
  const [
    imagePreviewModalIsOpen,
    setImagePreviewModalIsOpen,
  ] = useState(false);
  const [
    printQueueWarningModalIsOpen,
    setPrintQueueWarningModalIsOpen,
  ] = useState(false);
  const [
    addBarcodesModalIsOpened,
    setaddBarcodesModalIsOpened,
  ] = useState(false);
  const [
    editBarcodesModalIsOpened,
    setEditBarcodesModalIsOpened,
  ] = useState(false);
  const [
    checkInButtonClicked,
    setCheckInButtonClicked,
  ] = useState(false);

  const { data: product } = useGetProductBySku(item.sku, { query: { enabled: true } });

  useEffect(() => {
    findImage(item);
  }, [item]);

  const openItemInfoModal = () => {
    setItemInfoModalIsOpen(true);
  };

  const closeItemInfoModal = () => {
    setItemInfoModalIsOpen(false);
  };

  const closeImagePreviewModal = () => {
    setImagePreviewModalIsOpen(false);
  };

  const findImage = () => {
    let images = [];
    images.push.apply(images, [
      item.imageUrlFront,
      item.imageUrlBack,
      item.imageUrlLeft,
      item.imageUrlRight,
    ]);

    images = images.filter(Boolean);
    if (images.length === 1) {
      setItemImage(images[0]);
      setShowImages(false);
    } else {
      setItemImage(product?.coloredStyle.imageUrlFront);
      setShowImages(true);
    }
  };

  const openProductImage = (image) => {
    showImage(image);
  };

  const selectWaterproofCheckedButton = async () => {
    const itemStatus = item.status?.status;
    const model = {
      ids: item.ids,
      orderNumber,
    };

    if (itemStatus === orderItemStatusEnum.ProductionCompleted) {
      await markOrderItemWaterproofed(orderNumber, model);
      refresh();
    } else if (itemStatus === orderItemStatusEnum.Waterproofed) {
      await unMarkOrderItemWaterproofed(orderNumber, model);
      refresh();
    }
  };

  const selectQualityCheckedButton = async () => {
    const itemStatus = item.status?.status;
    const model = {
      ids: item.ids,
    };

    if ((itemStatus === orderItemStatusEnum.ProductionCompleted && !item.waterproofedStepRequired)
      || (itemStatus === orderItemStatusEnum.Waterproofed && item.waterproofedStepRequired)) {
      await markOrderItemQualityChecked(orderNumber, model);
      refresh();
    } else if (itemStatus === orderItemStatusEnum.QualityChecked) {
      await unmarkOrderItemQualityChecked(orderNumber, model);
      refresh();
    }
  };

  const isDtgItem = () => item.logos.some((x) => x.decorationMethod === decorationMethodEnum.DTG);

  const selectCheckedInButton = async () => {
    const itemStatus = item.status?.status;
    const model = {
      ids: item.ids,
    };

    if (itemStatus === orderItemStatusEnum.InPicking
      || itemStatus === orderItemStatusEnum.Picked) {
      let hasBarcodes = true;
      for (let i = 0; i < item.ids.length; i++) {
        const id = item.ids[i];
        const { itemBarcodes } = orderItems.find((oi) => oi.itemBarcodes.some((ib) => ib.orderItemId === id));
        const { barcode } = itemBarcodes.find((ib) => ib.orderItemId === id);
        if (!barcode) {
          hasBarcodes = false;
          break;
        }
      }

      if (!hasBarcodes && isDtgItem()) {
        setCheckInButtonClicked(true);
        setaddBarcodesModalIsOpened(true);
      } else {
        await markOrderItemCheckedIn(orderNumber, model);
        refresh();
      }
    } else if (itemStatus === orderItemStatusEnum.CheckedIn || itemStatus === orderItemStatusEnum.Assembled) {
      await unmarkOrderItemCheckedIn(orderNumber, model);
      refresh();
    }
  };

  const selectPreTreatedButton = async () => {
    const itemStatus = item.status?.status;
    const model = {
      ids: item.ids,
    };

    if (itemStatus === orderItemStatusEnum.CheckedIn || itemStatus === orderItemStatusEnum.Assembled) {
      await markOrderItemPreTreated(orderNumber, model);
    } else if (itemStatus === orderItemStatusEnum.PreTreated) {
      await unmarkOrderItemPreTreated(orderNumber, model);
    }

    refresh();
  };

  const addToPrintQueue = (itemParam) => {
    const dtgSetting = product && dtgColoredStyleConfigDict[product.coloredStyleId];
    const tabletProfile = dtgSetting?.dtgTabletProfile;

    const itemWithProductDetails = {
      ...itemParam,
      decorationIds: [...itemParam.decorationIds],
    };

    if (product) {
      itemWithProductDetails.colorGroup = product.coloredStyle?.colorGroup;
      itemWithProductDetails.categoryName = product.coloredStyle?.style?.category?.name;
    }

    if (tabletProfile) {
      itemWithProductDetails.dtgTabletProfile = tabletProfile;
    } else {
      setPrintQueueWarningModalIsOpen(true);

      return;
    }
    itemWithProductDetails.orderNumber = orderNumber;

    onAddToPrintQueue(itemWithProductDetails);
  };

  const closePrintQueueWarningModal = () => {
    setPrintQueueWarningModalIsOpen(false);
  };

  const openAddBarcodesModal = () => {
    setaddBarcodesModalIsOpened(true);
  };

  const closeAddBarcodesModal = () => {
    dispatch(reset(scanOrderItemsBarcodesForm));

    setaddBarcodesModalIsOpened(false);
    setCheckInButtonClicked(false);
  };

  const openEditBarcodesModal = () => {
    setEditBarcodesModalIsOpened(true);
    setCheckInButtonClicked(false);
  };

  const closeEditBarcodesModal = () => {
    setEditBarcodesModalIsOpened(false);
  };

  const updateBarcodes = async (form) => {
    const success = await updateOrderItemsBarcodes(orderNumber, form);

    if (success) {
      closeAddBarcodesModal();
      closeEditBarcodesModal();
      refresh();
    }

    if (checkInButtonClicked) {
      const model = {
        ids: item.ids,
      };
      await markOrderItemCheckedIn(orderNumber, model);
      setCheckInButtonClicked(false);
    }

    if (success || checkInButtonClicked) {
      refresh();
    }
  };

  const dtgSetting = product && dtgColoredStyleConfigDict[product.coloredStyleId];

  const itemStatus = item.status?.status;
  let isDisabled = 'is-disabled';
  const orderItemsCompleted = orderItems
    .filter((i) => i.status.status === orderItemStatusEnum.ProductionCompleted
      || i.status.status === orderItemStatusEnum.QualityChecked);
  if (orderItemsCompleted.length <= orderItems.length && !checksDisabled) {
    isDisabled = '';
  }

  const allQualityChecked = orderItems.filter((i) => !(i.status.status === orderItemStatusEnum.ProductionCompleted));
  if (allQualityChecked.length === orderItems.length) {
    isDisabled = 'is-disabled';
  }

  const isDtg = isDtgItem();

  const checkInChecked = !checkInNotCheckedStatuses.includes(itemStatus);

  const checkInDisabled = checksDisabled
    || !checkInEnabledStatuses.includes(itemStatus);

  const pretreatChecked = checkInChecked
    && !pretreatCheckedStatuses.includes(itemStatus);

  const pretreatDisabled = checksDisabled
    || !pretreatEnabledStatuses.includes(itemStatus);

  const waterproofedChecked = waterproofedCheckedStatuses.includes(itemStatus);

  const waterproofedDisabled = checksDisabled
    || !waterproofedEnabledStatuses.includes(itemStatus);

  const qcDisabledForNotWaterproofed = !qcEnabledForNotWaterproofedStatuses.includes(itemStatus);

  const qcDisabledForWaterproofed = !qcEnabledForWaterproofedStatuses.includes(itemStatus);

  const qcDisabled = checksDisabled
    || (!item.waterproofedStepRequired && qcDisabledForNotWaterproofed)
    || (item.waterproofedStepRequired && qcDisabledForWaterproofed);

  const firstDtgDec = item.logos.find((x) => x.decorationMethod === decorationMethodEnum.DTG);
  const pretreat = firstDtgDec?.pretreatmentPercent;

  const itemBarcodesNonEmpty = item.itemBarcodes.filter((ib) => !!ib.barcode);
  const itemCartLocationsNonEmpty = item.cartLocations.filter(
    (cl, index, self) => cl.cartLocation && index === self.findIndex((x) => x.cartLocation === cl.cartLocation)
  );

  return (
    <div className={`order-assembly order-assembly__item ${highlighted ? 'order-assembly__item--highlighted' : ''}`}>
      <SimpleWarningModal
        isOpen={printQueueWarningModalIsOpen}
        closeModal={closePrintQueueWarningModal}
        confirm={closePrintQueueWarningModal}
        warningMessage={'Can\'t add item to print queue, colored style does not have tablet profile attached'}
      />

      <ProductionAssemblyAddBarcodesModal
        isOpen={addBarcodesModalIsOpened}
        closeModal={closeAddBarcodesModal}
        onSubmit={updateBarcodes}
        itemBarcodes={item.itemBarcodes}
      />

      <ProductionAssemblyEditBarcodesModal
        isOpen={editBarcodesModalIsOpened}
        closeModal={closeEditBarcodesModal}
        onSubmit={updateBarcodes}
        itemBarcodes={item.itemBarcodes}
      />

      <ItemInfoModal
        isOpen={itemInfoModalIsOpen}
        closeModal={closeItemInfoModal}
        sku={item?.sku}
        lockerItemId={item?.lockerItemId}
        images={item && (item.custom
          ? [
            item.imageUrlFront,
            item.imageUrlBack,
            item.imageUrlLeft,
            item.imageUrlRight,
          ].filter(Boolean)
          : [item.imageUrlFront].filter(Boolean))}
      />
      <ImagePreviewModal
        modalIsOpen={imagePreviewModalIsOpen}
        closeModal={closeImagePreviewModal}
        imageUrl={itemImage}
      />
      <div className='order-assembly__item--info'>
        <div className='details'>
          <Image
            url={itemImage}
            classes={'details__logo'}
            openInModal={openItemInfoModal}
            showImageInModal={false}
          />
          <div className='details__product'>
            <div className='details__product--title'>
              {
                item.ids.length === 1
                  ? <span className='identifier'>{`ID# ${item.ids[0]}`}</span>
                  : (
                    <div className='details__product--title--id'>
                      <span className='identifier'>IDs</span>
                      <MaterialTooltip
                        tooltipText={item.ids.join(', ')}
                        placement={'top'}
                      >
                        <Icon materialIcon={'info'} />
                      </MaterialTooltip>
                    </div>
                  )
              }
              <div className='border' />
              <span>{item.sku}</span>
              <div className='border' />
              <span className='details__product--title--name'>
                <span>{product?.coloredStyle.style.name}</span>
                <LabelsLegendDisplay
                  labels={[
                    {
                      isActive: item.custom,
                      text: 'Custom',
                      shortText: 'C',
                      type: 'status-blue',
                    },
                    {
                      isActive: item.requiredItem,
                      text: 'Required',
                      shortText: 'R',
                      type: 'electric-indigo',
                    },
                  ]}
                />

              </span>
              <div className='border' />
              <span>{item.status?.label}</span>
            </div>

            <div className='details__product--minor'>
              <span className='flex'>
                <ProductAssemblyReviewItemArrivalStatus item={item} />
              </span>
              <span className='details__product--label'>Size: {product?.size}</span>
              <span className='details__product--label'>Color: {product?.coloredStyle.colorGroup}</span>
              <span className='details__product--label'>Brand: {product?.coloredStyle.style.brand.name}</span>
              <span className='details__product--label'>Quantity: {item.quantity}</span>
              {pretreat && <span className='details__product--label'>Pretreat: {pretreat}%</span>}
              {
                (item.logos?.some((l) => l.decorationMethod === decorationMethodEnum.DTG)
                  || item.personalizations?.some((l) => l.decorationMethod === decorationMethodEnum.DTG)) &&
                <>
                  {
                    itemBarcodesNonEmpty.length > 1
                      ? (
                        <MaterialTooltip
                          tooltipText={(
                            <div>
                              {
                                itemBarcodesNonEmpty.map((ib) => (
                                  <div key={ib.orderItemId}>{ib.barcode}</div>
                                ))
                              }
                            </div>
                          )}
                          contentClasses={'flex'}
                          interactive={true}
                          placement={'top'}
                        >
                          <span className='details__product--label'>Barcodes: {itemBarcodesNonEmpty.length}</span>
                        </MaterialTooltip>
                      )
                      : itemBarcodesNonEmpty.length === 1
                        ? (
                          <span className='details__product--label'>Barcode: {itemBarcodesNonEmpty[0].barcode}</span>
                        )
                        : null
                  }
                  {
                    itemBarcodesNonEmpty.length > 0
                      ? (
                        <EditButton
                          text={'Edit Barcode(s)'}
                          classes={'ml-0 align__center'}
                          onClick={openEditBarcodesModal.bind(null, item.ids)}
                        />
                      )
                      : (
                        <AddButton
                          text={'Add Barcode(s)'}
                          classes={'align__center'}
                          onClick={openAddBarcodesModal.bind(null, item.ids)}
                        />
                      )
                  }
                </>
              }
              {
                itemCartLocationsNonEmpty.length > 1
                  ? (
                    <MaterialTooltip
                      tooltipText={(
                        <div>
                          {
                            itemCartLocationsNonEmpty.map((cl) => (
                              <div key={cl.orderItemId}>{cl.cartLocation}</div>
                            ))
                          }
                        </div>
                      )}
                      contentClasses={'flex'}
                      interactive={true}
                      placement={'top'}
                    >
                      <span className='details__product--label'>Cart Locations: {itemCartLocationsNonEmpty.length}</span>
                    </MaterialTooltip>
                  )
                  : itemCartLocationsNonEmpty.length === 1
                    ? (
                      <span className='details__product--label'>Cart Location: {itemCartLocationsNonEmpty[0].cartLocation}</span>
                    )
                    : null
              }
            </div>

          </div>
        </div>
        <div>
          <div className='actions'>
            <MaterialTooltip
              tooltipText={itemStatus === orderItemStatusEnum.InPicking
                || itemStatus === orderItemStatusEnum.Initial
                ? '' : 'Check In'
              }
              placement={'right'}
            >
              <CheckButton
                disabled={checkInDisabled}
                text={itemStatus === orderItemStatusEnum.InPicking || itemStatus === orderItemStatusEnum.Initial ? 'Check In' : ''}
                checked={checkInChecked}
                onClick={selectCheckedInButton}
              />
            </MaterialTooltip>
            {
              isDtg &&
              <MaterialTooltip
                tooltipText={itemStatus !== orderItemStatusEnum.Assembled
                  || itemStatus === orderItemStatusEnum.PreTreated
                  ? 'Pre-Treated' : ''
                }
                placement={'right'}
              >
                <CheckButton
                  disabled={pretreatDisabled}
                  text={itemStatus === orderItemStatusEnum.CheckedIn || itemStatus === orderItemStatusEnum.Assembled && isDtg ? 'Pre-Treated' : ''}
                  checked={pretreatChecked}
                  onClick={selectPreTreatedButton}
                />
              </MaterialTooltip>
            }
            {
              item.waterproofedStepRequired &&
              <MaterialTooltip
                tooltipText={itemStatus !== orderItemStatusEnum.ProductionCompleted ? 'Waterproofed' : ''}
                placement={'right'}
              >
                <CheckButton
                  disabled={waterproofedDisabled}
                  text={itemStatus === orderItemStatusEnum.ProductionCompleted ? 'Waterproofed' : ''}
                  checked={waterproofedChecked}
                  onClick={selectWaterproofCheckedButton}
                />
              </MaterialTooltip>
            }

            <MaterialTooltip
              tooltipText={itemStatus !== orderItemStatusEnum.ProductionCompleted ? 'Quality Checked' : ''}
              placement={'right'}
            >
              <CheckButton
                disabled={qcDisabled}
                text={!qcDisabled ? 'Quality Checked' : ''}
                checked={itemStatus === orderItemStatusEnum.QualityChecked}
                onClick={selectQualityCheckedButton}
              />
            </MaterialTooltip>
            <div
              className={`order-assembly__link ${isDisabled}`}
              onClick={isDisabled === '' ? selectAllButtonOnClick : undefined}
            >
              Select All
            </div>
          </div>
        </div>
      </div>
      {
        showImages &&
        <>
          <hr />
          <div className='order-assembly__item--images'>
            {
              item.imageUrlFront &&
              <div className='order-assembly__item--images__item'>
                <ImagePreviewCell
                  imageUrl={item.imageUrlFront}
                  openModalWithUrl={openProductImage}
                  height={'80px'}
                  width={'80px'}
                  transparent={false}
                />
                <div className='location'>Front</div>
              </div>
            }
            {
              item.imageUrlRight &&
              <div className='order-assembly__item--images__item'>
                <ImagePreviewCell
                  imageUrl={item.imageUrlRight}
                  openModalWithUrl={openProductImage}
                  height={'80px'}
                  width={'80px'}
                  transparent={false}
                />
                <div className='location'>Side Right</div>
              </div>
            }
            {
              item.imageUrlLeft &&
              <div className='order-assembly__item--images__item'>
                <ImagePreviewCell
                  imageUrl={item.imageUrlLeft}
                  openModalWithUrl={openProductImage}
                  height={'80px'}
                  width={'80px'}
                  transparent={false}
                />
                <div className='location'>Side Left</div>
              </div>
            }
            {
              item.imageUrlBack &&
              <div className='order-assembly__item--images__item'>
                <ImagePreviewCell
                  imageUrl={item.imageUrlBack}
                  openModalWithUrl={openProductImage}
                  height={'80px'}
                  width={'80px'}
                  transparent={false}
                />
                <div className='location'>Back</div>
              </div>
            }
          </div>
        </>
      }
      <hr className='mb-0' />
      <div>
        <ProductionAssemblyReviewItemTasks
          item={item}
          tasks={[
            ...item.groupedLogos,
            ...item.personalizations,
          ]}
          logos={item.logos}
          checksDisabled={checksDisabled}
          orderNumber={orderNumber}
          orderId={orderId}
          itemStatus={itemStatus}
          refresh={refresh}
          onAddToPrintQueue={addToPrintQueue}
        />
      </div>
      {dtgSetting &&
        <ProductionAssemblyReviewItemSettings
          dtgSetting={dtgSetting}
          checkedIn={checkInChecked}
          pretreated={pretreatChecked}
          statusHistoryOpened={isStatusHistoryShown}
        />
      }
      {isStatusHistoryShown && <ProductionAssemblyReviewItemHistory item={item} />}
    </div>
  );
};

const mapStateToProps = ({ productionAssembly }) => ({
  dtgColoredStyleConfigDict: productionAssembly.dtgColoredStyleConfigDict,
  productsDict: productionAssembly.productsDict,
});

ProductionAssemblyReviewItem.propTypes = {
  item: PropTypes.object.isRequired,
  orderId: PropTypes.number.isRequired,
  orderNumber: PropTypes.number.isRequired,
  checksDisabled: PropTypes.bool,
  showImage: PropTypes.func.isRequired,
  order: PropTypes.object,
  selectAllButtonOnClick: PropTypes.func.isRequired,
  orderItems: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  isStatusHistoryShown: PropTypes.bool.isRequired,
  onAddToPrintQueue: PropTypes.func.isRequired,
  dtgColoredStyleConfigDict: PropTypes.object,
  productsDict: PropTypes.object.isRequired,
  highlighted: PropTypes.bool,
};

export default connect(mapStateToProps)(ProductionAssemblyReviewItem);
