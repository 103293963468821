import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import {
  reprintReasons,
  hagReworkReasons,
  embReworkReasons,
  dtgReworkReasons,
  wrongArtworkReasons,
} from '@constants/reasons';
import { createOptionsList } from './optionsMap';

export const getFlagReasons = (flagType, decorationMethod) => {
  if (flagType === flagTypeEnum.Reprint) {
    return reprintReasons;
  } else if (flagType === flagTypeEnum.WrongArtwork) {
    return wrongArtworkReasons;
  } else if (flagType === flagTypeEnum.Rework) {
    if (decorationMethod === decorationMethodEnum.EMB) {
      return embReworkReasons;
    } else if (decorationMethod === decorationMethodEnum.HAG) {
      return hagReworkReasons;
    } else if (decorationMethod === decorationMethodEnum.DTG) {
      return dtgReworkReasons;
    }
  }

  return [];
};

export const getFlagReasonOptions = (flagType, decorationMethod) => (
  createOptionsList({
    list: getFlagReasons(flagType, decorationMethod),
    key: 'id',
    value: 'reason',
    name: 'reason',
  })
);
