import React from 'react';

interface OwnProps {
  type?: 'primary' | 'secondary';
  text: string;
  onClick: ((e: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
  disabled?: boolean;
  classes?: string;
}

type Props = OwnProps;

const TextButton = React.memo<Props>(({
  type = 'primary',
  text,
  onClick,
  disabled = false,
  classes = '',
}) => (
  <button
    type='button'
    className={`text-button text-button--${type} ${classes}`}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
));

export default TextButton;
