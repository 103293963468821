import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import DtgPersonalizationSettingsForm from '../DtgPersonalizationSettingsForm';
import { dtgSettingsForm } from '@constants/reduxForms';

const DtgPersonalizationSettingsEditModal = React.memo((props) => {
  const {
    isOpen,
    initialValues,
    closeModal,
    onSubmit,
    decorationLocations,
  } = props;

  return (
    <Modal
      title={'Edit DTG Personalization Setting'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Update'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={dtgSettingsForm}
        />
      )}
    >
      <DtgPersonalizationSettingsForm
        decorationLocations={decorationLocations}
        onSubmit={onSubmit}
        initialValues={initialValues}
        formMode={'edit'}
      />
    </Modal>
  );
});

DtgPersonalizationSettingsEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  decorationLocations: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default DtgPersonalizationSettingsEditModal;
