export const organizationTypeEnum = {
  Default: 'Organization Type',
  SportsOrganization: 'Sports Organization',
  School: 'School',
  BusinessOrCompany: 'Business/Company',
  CommunityOrOther: 'Community/Other',
} as const;

export const LockerTypeEnum = {
  Temporary: 0,
  Persistent: 1,
  AutoLaunched: 2,
  TemporaryOptIn: 3,
  PersistentOptIn: 4,
  BulkStore: 10,
  PartnerCreated: 20,
  Promotional: 99,
  _displayNames: {
    2: 'Auto Launched',
    3: 'Temporary Opt In',
    4: 'Persistent Opt In',
    10: 'Bulk Store',
    20: 'Partner Created',
  },
};
