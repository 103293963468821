import React, { useState } from 'react';
import Label from '@sharedComponents/Display/Label';
import { getQueryClientInstance } from '@util/queryClientHelper';
import { OrderDetailsDto } from '@api/fulfillment/models';

const queryClient = getQueryClientInstance();

const OrderManagementHeaderTitle = React.memo(() => {
  const [
    order,
    setOrder,
  ] = useState<Nullable<OrderDetailsDto>>(null);

  queryClient.getQueryCache().subscribe((queryCacheNotifyEvent) => {
    if ((queryCacheNotifyEvent?.type === 'queryUpdated'
      || queryCacheNotifyEvent?.type === 'queryAdded')
      && queryCacheNotifyEvent?.query?.queryKey === 'ffOrder') {
      const data = queryClient.getQueryData('ffOrder');
      setOrder(data as OrderDetailsDto);
    }
  });

  if (!order || !order?.orderNumber) {
    return <span className='header__bar-content' />;
  }

  const allItemsAreVFI = order?.items?.every((i) => i.vendorFulfilledItem);

  let orderStatus: string | JSX.Element = '';
  if (order?.rush !== null) {
    orderStatus = (
      <Label
        text={'Rushed'}
        type={'status-blue'}
      />
    );
  }
  if (order?.paused !== null) {
    orderStatus = (
      <Label
        text={'Paused'}
        type={'status-orange'}
      />
    );
  }
  if (order?.canceled !== null) {
    orderStatus = (
      <Label
        text={'Canceled'}
        type={'status-darkred'}
      />
    );
  }

  return (
    <div className='header__bar-content'>
      <div className='header__bar-title align__center'>
        <span>Order O{order?.orderNumber}</span>
        <span>
          {
            allItemsAreVFI &&
            <Label
              text={'VFI'}
              type={'lynch'}
            />
          }
        </span>
        <span className='orderManagement__status'>
          {orderStatus}
        </span>
      </div>
    </div>
  );
});

export default OrderManagementHeaderTitle;
