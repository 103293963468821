import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import iconEye from '@assets/icon_eye_green.svg';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import InfoField from '@sharedComponents/Display/InfoField';

class DecorationLocationInfoField extends PureComponent {
  getDecorationLocationText = (location) => {
    const { decorationLocations } = this.props;
    const decorationLocation = decorationLocations.find((dl) => dl.id === location.locationId);

    return decorationLocation && `${decorationLocation.code} - ${decorationLocation.label}`;
  };

  render() {
    const {
      styleLocations,
      label,
    } = this.props;

    const mappedLocations = styleLocations && styleLocations.map((l) => this.getDecorationLocationText(l));

    return (
      <InfoField
        label={label}
        value={
          mappedLocations && mappedLocations.length > 0
            ? (
              <div className='product-catalog__details--icon'>
                <span>Yes</span>
                <MaterialTooltip
                  tooltipText={<div className='pre-line'>{mappedLocations.join('\n')}</div>}
                  placement={'bottom'}
                  contentClasses={'align__center'}
                >
                  <i
                    className='product-catalog__icon-eye'
                    style={{ backgroundImage: `url("${iconEye}")` }}
                  />
                </MaterialTooltip>
              </div>
            )
            : <span>No</span>
        }
      />
    );
  }
}

DecorationLocationInfoField.propTypes = {
  label: PropTypes.string.isRequired,
  styleLocations: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    locationId: PropTypes.number.isRequired,
    custom: PropTypes.bool.isRequired,
  })),
  decorationLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
};

export default DecorationLocationInfoField;
