import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  usePostHomefieldApiOrdermanagementOrdersplace, usePutHomefieldApiOrdermanagementOrderscalculatepricing,
} from '@api/orders/orders';
import {
  PlaceHomefieldOrderCommand,
  CommandsItem,
  CommandsPersonalization,
  CouponTypeEnum,
} from '@api/orders/models';
import {
  orderSearchUrl,
  orderDetailsUrl,
  orderDetailsNewOrderAddItems,
} from '@constants/clientUrls/clientUrls';
import { RootState } from '@redux/index/reducers';
import * as orderManagementActions from '@redux/orderManagement/actions';
import {
  materialSwal,
  navigateToPage,
} from '@util/componentHelper';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import Icon from '@sharedComponents/Icons/Icon';
import Button from '@sharedComponents/Buttons/Button';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import NewOrderSidebar from './NewOrderSidebar';
import NewOrderDetails from './NewOrderDetails';
import LinkButton from '@sharedComponents/Buttons/LinkButton';
import {
  useRedeemCoupon,
  useUnredeemCoupon,
} from '@api/squadlockerServices/coupons';

const mapStateToProps = ({ orderManagement }: RootState) => ({
  order: orderManagement.tempOrder,
  items: orderManagement.tempItems,
});

const mapDispatchToProps = {
  discardTempOrder: orderManagementActions.discardTempOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const OrderManagementNewOrder = React.memo(({
  order,
  items,
  discardTempOrder,
}: Props) => {
  const [
    placeOrderModalIsOpen,
    setPlaceOrderModalIsOpen,
  ] = useState<boolean>(false);
  const [
    goBackToOrderManagementModalIsOpen,
    setGoBackToOrderManagementModalIsOpen,
  ] = useState<boolean>(false);
  const [
    couponCode,
    setCouponCode,
  ] = useState<Nullable<string>>(null);
  const [
    couponType,
    setCouponType,
  ] = useState<Nullable<string>>(null);
  const [
    couponValue,
    setCouponValue,
  ] = useState<Nullable<number>>(null);
  const [
    couponAppliesToShipping,
    setCouponAppliesToShipping,
  ] = useState<Nullable<boolean>>(null);

  const { mutateAsync: placeOrder } = usePostHomefieldApiOrdermanagementOrdersplace();

  const {
    data: redeemCouponResponse,
    mutateAsync: redeemCoupon,
  } = useRedeemCoupon();

  const {
    data: orderPricingDetails,
    mutateAsync: fetchOrderPricingDetails,
  } = usePutHomefieldApiOrdermanagementOrderscalculatepricing();

  const { mutateAsync: unredeemCoupon } = useUnredeemCoupon();

  const openPlaceOrderModal = useCallback(() => {
    if (!order?.paymentIntentId
      && (orderPricingDetails?.result?.pricePaid ?? 0) > 0) {
      materialSwal(
        'Please apply a coupon to cover the charges of the order.',
        'Additional payment methods can be linked in GP after the order is placed.'
      );
    } else {
      setPlaceOrderModalIsOpen(true);
    }
  }, [
    order,
    orderPricingDetails,
  ]);

  const closePlaceOrderModal = useCallback(() => {
    setPlaceOrderModalIsOpen(false);
  }, []);

  const openBackToOrderManagementModal = useCallback(() => {
    setGoBackToOrderManagementModalIsOpen(true);
  }, []);

  const closeBackToOrderManagementModal = useCallback(() => {
    setGoBackToOrderManagementModalIsOpen(false);
  }, []);

  const goBackToOrderManagement = useCallback(() => {
    navigateToPage(orderSearchUrl);
    discardTempOrder();
  }, [discardTempOrder]);

  const couponCodeSet = useCallback((code: Nullable<string>) => {
    setCouponCode(code);
  }, []);
  const couponTypeSet = useCallback((type: Nullable<string>) => {
    setCouponType(type);
  }, []);
  const couponValueSet = useCallback((value: Nullable<number>) => {
    setCouponValue(value);
  }, []);
  const couponAppliesToShippingSet = useCallback((applies: Nullable<boolean>) => {
    setCouponAppliesToShipping(applies);
  }, []);

  const placeNewOrder = useCallback(async () => {
    const newOrder: PlaceHomefieldOrderCommand = {
      organizationId: order?.organizationId,
      salesChannelId: order?.lockerId,
      shippingInfo: {
        name: order?.name,
        addressLine1: order?.addressLine1,
        addressLine2: order?.addressLine2,
        city: order?.city,
        state: order?.state,
        zip: order?.zip,
        country: order?.country,
        poBox: order?.poBox,
        email: order?.email,
        method: order?.method,
        phoneNumber: order?.phoneNumber,
      },
      shipDate: order?.shipDate,
      customerShipDate: order?.customerShipDate,
      stripeCustomerId: order?.stripeCustomerId,
      stripeChargeId: order?.stripeChargeId,
      shippingBasePrice: order?.shippingBasePrice,
      items: [] as CommandsItem[],
      prdOrder: order?.prdOrder,
      couponCode,
      couponType: couponType as CouponTypeEnum ?? undefined,
      couponValue: couponValue ?? undefined,
      couponAppliesToShipping: couponAppliesToShipping ?? undefined,
    };

    for (const item of items) {
      const newItem = {
        variationId: item.variationId,
        sku: item.sku,
        quantity: item.quantity,
        pricePaid: item.price,
        personalizations: [] as CommandsPersonalization[],
      };

      for (const personalization of item.personalizations) {
        if (personalization.id && (personalization.name || personalization.number)) {
          newItem.personalizations.push({
            id: personalization.id,
            name: personalization.name,
            number: personalization.number,
            unitPrice: personalization.unitPrice,
          });
        }
      }

      newOrder.items?.push(newItem);
    }

    const variables = { data: newOrder };
    try {
      const res = await placeOrder(variables);

      if (res?.success) {
        materialSwal('Success', res.message, 'success');

        closePlaceOrderModal();

        const orderNumber = res.result!.id!;
        navigateToPage(orderDetailsUrl(orderNumber));
      }
    } catch (err) {
      closePlaceOrderModal();
      unredeemCoupon({ couponCode: couponCode ?? '' });
    }
  }, [
    order,
    couponCode,
    couponType,
    couponValue,
    couponAppliesToShipping,
    items,
    placeOrder,
    closePlaceOrderModal,
    unredeemCoupon,
  ]);

  const redeemCouponAndPlaceOrder = useCallback(async () => {
    if (couponCode) {
      redeemCoupon({ couponCode });
    } else {
      placeNewOrder();
    }
  }, [
    couponCode,
    placeNewOrder,
    redeemCoupon,
  ]);

  useEffect(() => {
    if (redeemCouponResponse?.success) {
      placeNewOrder();
    } else if (redeemCouponResponse) {
      materialSwal('Coupon is currently unredeemable. Please remove it and try again');
    }
  }, [redeemCouponResponse]);

  if (!order) {
    return (
      <div>Order not created.</div>
    );
  }

  return (
    <div className='container'>
      <SimpleConfirmationModal
        isOpen={placeOrderModalIsOpen}
        closeModal={closePlaceOrderModal}
        title={'Place Order'}
        confirm={redeemCouponAndPlaceOrder}
        confirmationBody={'Are you sure you want to place this order?'}
        modalWidth={'s'}
      />
      <SimpleConfirmationModal
        isOpen={goBackToOrderManagementModalIsOpen}
        confirm={goBackToOrderManagement}
        closeModal={closeBackToOrderManagementModal}
        title={'Discard Changes'}
        confirmationBody={'Are you sure you want to discard this order and go back to Order Management?'}
        isDangerousAction={true}
      />
      <div className='order'>
        <div className='order__side-wrapper'>
          <div className='navigation mb-10'>
            <LinkButton
              text={(
                <>
                  <Icon materialIcon={'keyboard_arrow_left'} />
                  <span>Order Management</span>
                </>
              )}
              onClick={openBackToOrderManagementModal}
              classes={'order__back font-weight-normal'}
            />
          </div>
          <NewOrderSidebar order={order} />
        </div>
        <div className='order__main-wrapper'>
          <div className='align__right mb-10'>
            {
              (items && items.length > 0) &&
              <ButtonLink
                type={'secondary'}
                text={'Add Item(s)'}
                linkTo={orderDetailsNewOrderAddItems}
                classes={'mr-10'}
              />
            }
            <Button
              type={'primary'}
              text={'Place Order'}
              disabled={!items || !items.length}
              onClick={openPlaceOrderModal}
            />
          </div>
          <NewOrderDetails
            couponCode={couponCode}
            couponType={couponType}
            couponValue={couponValue}
            couponAppliesToShipping={couponAppliesToShipping}
            setCouponCode={couponCodeSet}
            setCouponType={couponTypeSet}
            setCouponValue={couponValueSet}
            setCouponAppliesToShipping={couponAppliesToShippingSet}
            orderPricingDetails={orderPricingDetails}
            fetchOrderPricingDetails={fetchOrderPricingDetails}
          />
        </div>
      </div>
    </div>
  );
});

export default connector(OrderManagementNewOrder);
