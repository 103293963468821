import React from 'react';
import { RosterTeamMemberViewModel } from '@api/squadlockerServices/models';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  members: RosterTeamMemberViewModel[];
  deleteMembers: () => void;
  closeModal: () => void;
}

type Props = OwnProps;

const DeleteMembersModal = React.memo<Props>(({
  isOpen,
  members,
  deleteMembers,
  closeModal,
}) => (
  <Modal
    isOpen={isOpen}
    title={'Delete Item(s)'}
    modalSize={'l'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal custom-scrollbar'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteMembers}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <div className='lockerManager__delete-modal--content'>
      <h3>Are you sure you want to remove the { members.length > 1 ? 'members' : 'member' } listed below?</h3>
      <ul style={{ listStyle: 'disc' }}>
        {
          members.map((m) => (
            <li key={m.id}>
              <strong>{m.firstName} {m.lastName}, #{m.number}</strong>
            </li>
          ))
        }
      </ul>
    </div>
  </Modal>
));

export default DeleteMembersModal;
