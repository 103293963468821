/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type VoucherNotificationLinkDestinationEnum = 'Undefined' | 'OrganizationLandingPage' | 'Storefront' | 'CollectionLandingPage';


export const VoucherNotificationLinkDestinationEnum = {
  Undefined: 'Undefined' as VoucherNotificationLinkDestinationEnum,
  OrganizationLandingPage: 'OrganizationLandingPage' as VoucherNotificationLinkDestinationEnum,
  Storefront: 'Storefront' as VoucherNotificationLinkDestinationEnum,
  CollectionLandingPage: 'CollectionLandingPage' as VoucherNotificationLinkDestinationEnum,
};
