import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { StripeChargeInfoDto } from '@api/orders/models';
import { useGetHomefieldApiFinancialsStripechargeinfo } from '@api/orders/financials';
import { openInNewTab } from '@util/componentHelper';
import SearchInputWithCallback from '@sharedComponents/Inputs/SearchInputWithCallback';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Spinner from '@sharedComponents/Display/Spinner';

interface OwnProps {
  onPaymentIntentIdVerified: (paymentIntentId: string, data: StripeChargeInfoDto) => void;
  paymentIntentId?: string;
  setPaymentIdVerification: (verification: boolean) => void;
}

type Props = OwnProps;

const PaymentIntentIdSearch = React.memo(({
  onPaymentIntentIdVerified,
  paymentIntentId,
  setPaymentIdVerification,
}: Props) => {
  const [
    paymentIntentIdInput,
    setPaymentIntentIdInput,
  ] = useState<string | undefined>(paymentIntentId);

  const {
    data: stripeChargeInfo,
    isLoading: isLoadingStripeChargeInfo,
    isError: isErrorStripeChargeInfo,
  } = useGetHomefieldApiFinancialsStripechargeinfo(
    { paymentIntentId: paymentIntentIdInput },
    { query: { enabled: !!paymentIntentIdInput } }
  );

  const handlePaymentLinkVerified = useCallback((data: StripeChargeInfoDto) => {
    onPaymentIntentIdVerified(paymentIntentIdInput!, data);
  }, [
    onPaymentIntentIdVerified,
    paymentIntentIdInput,
  ]);

  useEffect(() => {
    const paymentIntentData = stripeChargeInfo;
    if (!isLoadingStripeChargeInfo && !isErrorStripeChargeInfo && paymentIntentData) {
      handlePaymentLinkVerified(paymentIntentData);
    }
  }, [
    stripeChargeInfo,
    isLoadingStripeChargeInfo,
    isErrorStripeChargeInfo,
    handlePaymentLinkVerified,
  ]);

  const validatePaymentLink = useCallback((searchInput: string) => {
    setPaymentIntentIdInput(searchInput);
    setPaymentIdVerification(false);
  }, []);

  const openPaymentLink = useCallback(() => {
    if (!stripeChargeInfo?.url) return;
    openInNewTab(stripeChargeInfo.url);
  }, [stripeChargeInfo]);

  let paymentIntentIdVerified = false;

  let paymentIntentIdData: StripeChargeInfoDto | undefined;

  if (!isErrorStripeChargeInfo && !isLoadingStripeChargeInfo && stripeChargeInfo) {
    paymentIntentIdVerified = true;
    setPaymentIdVerification(true);
    paymentIntentIdData = stripeChargeInfo;
  }

  return (
    <div className='redux-form__column--size-xl'>
      <label className='redux-form__label'>
        External Payment ID<span className='required'>*</span>
      </label>
      <div className='align__center'>
        <div className='w-50 mr-80'>
          <SearchInputWithCallback
            placeholder={'Enter Payment Intent ID'}
            initialValue={paymentIntentId}
            callback={validatePaymentLink}
          />
        </div>
        <div className='w-50 mr-80'>
          {
            paymentIntentIdVerified &&
            <div className='new-order__payment-intent-id--verified'>
              <span>Payment link verified</span>
              <MaterialTooltip
                tooltipText={(
                  <div >
                    <div>{`${paymentIntentIdData?.description || ''}`}</div>
                    <div>(Click to open in new tab)</div>
                  </div>
                )}
                placement={'top'}
              >
                <Icon
                  materialIcon={'info_outline'}
                  onClick={openPaymentLink}
                />
              </MaterialTooltip>
            </div>
          }
          {
            isLoadingStripeChargeInfo &&
            <Spinner
              size={'30px'}
            />
          }
          {
            isErrorStripeChargeInfo &&
            <div className='new-order__payment-intent-id--failed'>
              <span>Payment link not found</span>
            </div>
          }
        </div>
      </div>
    </div>
  );
});

export default PaymentIntentIdSearch;
