import React from 'react';
import { ColorDto } from '@api/productCatalog/models';

interface OwnProps {
  personalizationNumber?: string;
  personalizationText?: string;
  personalizationColor: ColorDto;
}

type Props = OwnProps;

const TextInfo = React.memo<Props>(({
  personalizationText,
  personalizationNumber,
  personalizationColor,
}) => (
  <span className='sheet__list-table-personalization'>
    <span className='text'>
      {`${personalizationText ?? ''} ${personalizationNumber ?? ''}`}
    </span>
    <span
      className='color'
      style={{ backgroundColor: personalizationColor?.hexValue ?? undefined }}
    />
  </span>
));

export default TextInfo;
