import {
  DecorationMethodEnum,
  OrderItemStatusEnum,
} from '@api/fulfillment/models';

export const checkInNotCheckedStatuses = [
  OrderItemStatusEnum.Initial,
  OrderItemStatusEnum.OnHold,
  OrderItemStatusEnum.AwaitingDispatchForPicking,
  OrderItemStatusEnum.InPicking,
  OrderItemStatusEnum.Picked,
] as const;

export const pretreatCheckedStatuses = [
  OrderItemStatusEnum.Assembled,
  OrderItemStatusEnum.CheckedIn,
];

export const checkInEnabledStatuses = [
  OrderItemStatusEnum.Picked,
  OrderItemStatusEnum.CheckedIn,
  OrderItemStatusEnum.Assembled,
] as const;

export const pretreatEnabledStatuses = [
  OrderItemStatusEnum.CheckedIn,
  OrderItemStatusEnum.Assembled,
  OrderItemStatusEnum.PreTreated,
] as const;

export const waterproofedCheckedStatuses = [
  OrderItemStatusEnum.Waterproofed,
  OrderItemStatusEnum.QualityChecked,
  OrderItemStatusEnum.Shipped,
] as const;

export const waterproofedEnabledStatuses = [
  OrderItemStatusEnum.ProductionCompleted,
  OrderItemStatusEnum.Waterproofed,
] as const;

export const reprintUnavailableMethodsArr = [
  DecorationMethodEnum.EMB,
  DecorationMethodEnum.DTG,
] as const;
