import React, { Component } from 'react';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import ColorsLink from '../MyApps/ColorsLink';
import SportsLink from '../MyApps/SportsLink';
import CategoriesLink from '../MyApps/CategoriesLink';
import ParentCategoriesLink from '../MyApps/ParentCategoriesLink';
import DecorationLocationsLink from '../MyApps/DecorationLocationsLink';
import DecorationLocationArtworkSizesLink from '../MyApps/DecorationLocationArtworkSizesLink';
import BrandsLink from '../MyApps/BrandsLink';
import VendorsLink from '../MyApps/VendorsLink';
import LayoutsLink from '../MyApps/LayoutsLink';
import DtgEntitiesLink from '../MyApps/DtgEntitiesLink';
import QualityAssuranceCodesLink from '../MyApps/QualityAssuranceCodesLink';

class LookupEntities extends Component {
  render() {
    return (
      <div className='my-apps-tray w-100' >
        <SportsLink />
        <CategoriesLink />
        <ParentCategoriesLink />
        <BrandsLink />

        <PermissionHandler permissions={PermissionsEnum.LookupEntitiesColorsVisit}>
          <ColorsLink />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.LookupEntitiesDecorationLocationsVisit}>
          <DecorationLocationsLink />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.LookupEntitiesDecorationLocationArtworkSizesVisit}>
          <DecorationLocationArtworkSizesLink />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.LookupEntitiesVendorsVisit}>
          <VendorsLink />
        </PermissionHandler>

        <LayoutsLink />

        <DtgEntitiesLink />

        <QualityAssuranceCodesLink />
      </div>
    );
  }
}

export default LookupEntities;
