import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
} from 'react-grid-dnd';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Icon from '@sharedComponents/Icons/Icon';

const numberOfBoxesPerRow = 3;
const rowHeight = 55;

class CategoriesReorderModal extends Component {
  state = {
    categories: this.props.categories,
  };

  componentDidUpdate(prevProps) {
    const {
      categories,
      isOpen,
    } = this.props;

    if (!prevProps.isOpen && isOpen) {
      this.setState({ categories });
    }
  }

  onDragEnd = (sourceId, sourceIndex, targetIndex) => {
    const categories = [...this.state.categories];

    if (sourceIndex === undefined || targetIndex === undefined) {
      return;
    }

    const [removedCategory] = categories.splice(sourceIndex, 1);
    categories.splice(targetIndex, 0, removedCategory);

    this.setState({ categories });
  };

  renderDraggableCategory = (category) => (
    <div className='categories__draggable-item mr-20'>
      <Icon
        materialIcon={'drag_indicator'}
        classes={'mr-20'}
      />
      <span className='categories__draggable-item--info'>
        <b className='mr-5'>{category.sortOrder}</b>
        -
        <span className='ml-5'>{category.name}</span>
      </span>
    </div>
  );

  render() {
    const {
      isOpen,
      closeModal,
      save,
    } = this.props;
    const { categories } = this.state;

    return (
      <Modal
        title={'Edit Sort Order'}
        isOpen={isOpen}
        modalSize={'l'}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onConfirm={save.bind(null, categories)}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div className='mb-10'>Sort order</div>
        <GridContextProvider
          onChange={this.onDragEnd}
        >
          <GridDropZone
            id='categories'
            boxesPerRow={numberOfBoxesPerRow}
            rowHeight={rowHeight}
            style={{ height: '400px' }}
          >
            {
              categories.map((category) => (
                <GridItem key={category.id}>
                  {this.renderDraggableCategory(category)}
                </GridItem>
              ))
            }
          </GridDropZone>
        </GridContextProvider>
      </Modal>
    );
  }
}

CategoriesReorderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default CategoriesReorderModal;
