import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from '@util/componentHelper';
import { infoTextTimeout } from '@constants/values';
import Icon from '@sharedComponents/Icons/Icon';
import Tab from '@sharedComponents/Tabs/Tab';

const accountingSubmitTabEnum = {
  Results: 'Results',
  Data: 'Data',
};

class AccountingSubmitResults extends Component {
  state = {
    selectedTab: accountingSubmitTabEnum.Results,
    copyText: 'Copy to clipboard',
  };

  selectTab = (selectedTab) => {
    this.setState({
      selectedTab,
      copyText: 'Copy to clipboard',
    });
  };

  onCopyToClipboard = (e) => {
    copyToClipboard(e, 'dataToCopy');

    this.setState({
      copyText: 'Copied!',
    }, () => setTimeout(() => {
      this.setState({ copyText: 'Copy to clipboard' });
    }, infoTextTimeout));
  };

  render() {
    const {
      data,
      label,
      itemDataProperty,
    } = this.props;

    const {
      selectedTab,
      copyText,
    } = this.state;

    let showData = null;

    if (selectedTab === accountingSubmitTabEnum.Results) {
      showData = data.result;
    }

    if (selectedTab === accountingSubmitTabEnum.Data) {
      showData = data[itemDataProperty];
    }

    try {
      showData = JSON.stringify(JSON.parse(showData), null, '\t');
    } catch (e) {
      // Do nothing
    }

    return (
      <div className='order__container-details'>
        <div className='order__container-tabs'>
          <div className='w-40'>
            <div className='tabs'>
              <Tab
                title={'Result'}
                isActive={selectedTab === accountingSubmitTabEnum.Results}
                onSelectTab={this.selectTab.bind(null, accountingSubmitTabEnum.Results)}
              />
              <Tab
                title={`${label} Data`}
                isActive={selectedTab === accountingSubmitTabEnum.Data}
                onSelectTab={this.selectTab.bind(null, accountingSubmitTabEnum.Data)}
              />
            </div>
          </div>
          <div
            className='order__copy'
            onClick={this.onCopyToClipboard}
          >
            <Icon materialIcon={'content_copy'} />
            <div className='order__copy-text'>{copyText}</div>
          </div>
        </div>
        <div className='order__container-data custom-scrollbar custom-scrollbar--horizontal'>
          <div
            className='order__value'
            id='dataToCopy'
          >
            {showData}
          </div>
        </div>
      </div>
    );
  }
}

AccountingSubmitResults.propTypes = {
  data: PropTypes.object.isRequired,
  itemDataProperty: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default AccountingSubmitResults;
