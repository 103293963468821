/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import type {
  OrganizationDetailDto,
  ApiResponse,
  OrganizationDtoPagedList,
  GetHomefieldApiOrganizationsParams,
  SalesChannelDtoPagedList,
  GetHomefieldApiOrganizationsIdSaleschannelsParams
} from './models'
import { ordersApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiOrganizationsId = <TData = OrganizationDetailDto>(
    id: number,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/organizations/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrganizationsIdQueryKey = (id: number,) => [`/homefield/api/organizations/${id}`]

    
export const useGetHomefieldApiOrganizationsId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrganizationsId, OrganizationDetailDto>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrganizationsIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrganizationsId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrganizationsUuid = <TData = OrganizationDetailDto>(
    uuid: string,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/organizations/${uuid}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrganizationsUuidQueryKey = (uuid: string,) => [`/homefield/api/organizations/${uuid}`]

    
export const useGetHomefieldApiOrganizationsUuid = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrganizationsUuid, OrganizationDetailDto>, TError = ApiResponse, TData = TQueryFnData>(
 uuid: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrganizationsUuidQueryKey(uuid);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrganizationsUuid<TQueryFnData>(uuid, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrganizations = <TData = OrganizationDtoPagedList>(
    params?: GetHomefieldApiOrganizationsParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/organizations`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrganizationsQueryKey = (params?: GetHomefieldApiOrganizationsParams,) => [`/homefield/api/organizations`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrganizations = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrganizations, OrganizationDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiOrganizationsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrganizationsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrganizations<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrganizationsIdSaleschannels = <TData = SalesChannelDtoPagedList>(
    id: number,
    params?: GetHomefieldApiOrganizationsIdSaleschannelsParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/organizations/${id}/sales-channels`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrganizationsIdSaleschannelsQueryKey = (id: number,
    params?: GetHomefieldApiOrganizationsIdSaleschannelsParams,) => [`/homefield/api/organizations/${id}/sales-channels`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrganizationsIdSaleschannels = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrganizationsIdSaleschannels, SalesChannelDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 id: number,
    params?: GetHomefieldApiOrganizationsIdSaleschannelsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrganizationsIdSaleschannelsQueryKey(id,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrganizationsIdSaleschannels<TQueryFnData>(id,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

