import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
  totalPages,
  pageNumber,
  selectPage,
  range,
}) => {
  const pages = Array.from(new Array(totalPages), (v, i) => i + 1);
  const isDisabledPrevious = pageNumber > 1 ? '' : 'is-disabled';
  const isDisabledNext = (totalPages > 1 && pageNumber < totalPages) ? '' : 'is-disabled';
  let begin = 0;
  let end = range;

  if (totalPages < range) {
    end = totalPages;
  } else if (pageNumber > Math.ceil(range / 2)) {
    begin = pageNumber - Math.ceil(range / 2);
    end = pageNumber + Math.floor(range / 2);
  } else if (pageNumber >= totalPages - Math.floor(range / 2)) {
    begin = totalPages - range;
    end = totalPages + 1;
  }

  const options = pages.slice(begin, end).map((page, key) => {
    const isSelected = page === pageNumber ? 'is-selected' : '';

    return (
      <div
        onClick={selectPage.bind(null, page)}
        className={`add-product__link ${isSelected}`}
        key={key}
      >
        {page}
      </div>
    );
  });

  return (
    <div className='add-product__paging'>
      <div
        className={`add-product__link ${isDisabledPrevious}`}
        onClick={pageNumber > 1 ? selectPage.bind(null, pageNumber - 1) : undefined}
      >
        Previous
      </div>
      <div className='add-product__paging-pages'>
        {options}
      </div>
      <div
        className={`add-product__link ${isDisabledNext}`}
        onClick={(totalPages > 1 && pageNumber < totalPages) ? selectPage.bind(null, pageNumber + 1) : undefined}
      >
        Next
      </div>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  selectPage: PropTypes.func.isRequired,
  range: PropTypes.number.isRequired,
};

export default Pagination;
