import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';

class LockerManagerDetailsTableLogoBank extends Component {
  state = {
    isSelected: false,
  };

  selectLogo = () => {
    const {
      logo,
      deselectLogo,
      selectedLogos,
    } = this.props;

    this.setState((prevState) => {
      if (prevState.isSelected) {
        deselectLogo(logo);

        return {
          isSelected: false,
        };
      }
      selectedLogos(logo);

      return {
        isSelected: true,
      };
    });
  };

  render() {
    const { logo } = this.props;
    const { isSelected } = this.state;

    const selectedLogo = `logo-bank__wrapper ${isSelected && 'selected'}`;

    return (
      <div
        className={selectedLogo}
        onClick={this.selectLogo}
      >
        <div className='logo-bank__logo-container'>
          <img
            className='logo-bank__logo-container--logo'
            src={`${lockerMgrS3Logos}/${logo.image}`}
          />
        </div>
      </div>
    );
  }
}

LockerManagerDetailsTableLogoBank.propTypes = {
  logo: PropTypes.shape({
    image: PropTypes.string,
  }).isRequired,
  selectedLogos: PropTypes.func.isRequired,
  deselectLogo: PropTypes.func.isRequired,
};

export default LockerManagerDetailsTableLogoBank;
