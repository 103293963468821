import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SelectIcon from '@sharedComponents/Icons/SelectIcon';
import Expander from '@sharedComponents/Buttons/Expander';
import QuickViewOrderItem from './QuickViewOrderItem';

class QuickViewOrderItemGroup extends PureComponent {
  state = {
    isExpanded: true,
    isSelected: false,
  };

  toggleExpanded = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  toggleSelectAllItemsInGroup = () => {
    const {
      items,
      updateSelectedItems,
    } = this.props;

    const { isSelected } = this.state;

    const newIsSelected = !isSelected;
    const newSelectedItems = newIsSelected ? items : [];

    this.setState(() => ({ isSelected: newIsSelected }));

    updateSelectedItems(newSelectedItems);
  };

  selectItem = (item) => {
    const { updateSelectedItems } = this.props;

    const newSelectedItems = this.toggleItemSelection(item);
    updateSelectedItems(newSelectedItems);
  };

  toggleItemSelection = (item) => {
    const { selectedItems } = this.props;

    if (this.isSelected(item)) {
      return selectedItems.filter((i) => i.ids.toString() !== item.ids.toString());
    }

    return [
      ...selectedItems,
      item,
    ];
  };

  isSelected = (item) => {
    const { selectedItems } = this.props;

    return selectedItems.find((i) => i.ids.toString() === item.ids.toString()) !== undefined;
  };

  render() {
    const {
      items,
      title,
      groupSelectable,
      itemsSelectable,
    } = this.props;

    const {
      isSelected,
      isExpanded,
    } = this.state;

    const itemsCount = items.map((item) => item.ids).flat().length;

    if (itemsCount === 0) return null;

    const expanderText = `${itemsCount} ${itemsCount === 1 ? 'item' : 'items'}`;

    return (
      <div>
        <div className='expandable-group__header'>
          <div className='align__center'>
            {
              groupSelectable &&
              <SelectIcon
                select={this.toggleSelectAllItemsInGroup}
                item={title}
                isSelected={isSelected}
              />
            }
            <div className='expandable-group__header--title ml-5'>{title}</div>
          </div>
          <Expander
            isExpanded={isExpanded}
            toggle={this.toggleExpanded}
            collapseText={expanderText}
            expandText={expanderText}
            classes={'expandable-group__expander'}
          />
        </div>
        {
          isExpanded && items.map((item) => (
            <QuickViewOrderItem
              key={item.ids.toString()}
              item={item}
              itemsSelectable={itemsSelectable}
              isSelected={this.isSelected(item)}
              selectItem={this.selectItem}
            />
          ))}
      </div>
    );
  }
}

QuickViewOrderItemGroup.propTypes = {
  title: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupSelectable: PropTypes.bool,
  itemsSelectable: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  updateSelectedItems: PropTypes.func,
};

QuickViewOrderItemGroup.defaultProps = {
  groupSelectable: false,
  itemsSelectable: false,
  selectedItems: [],
};

export default QuickViewOrderItemGroup;
