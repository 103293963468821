import React from 'react';
import { discountTypes } from '@constants/common';
import { discountTypeEnum } from '@constants/enums/couponEnums';
import { formatNumber } from '@util/numberHelpers';

interface OwnProps {
  type: (typeof discountTypeEnum)[keyof typeof discountTypeEnum];
  amount: number;
  emphasizeUnitOfMeasure?: boolean;
}

type Props = OwnProps;

const DiscountField = React.memo<Props>(({
  type,
  amount,
  emphasizeUnitOfMeasure,
}) => {
  const discountType = discountTypes.find((d) => d.key === type)!;
  const unitOfMeasureClass = emphasizeUnitOfMeasure ? 'coupon__info--green' : '';

  if (type === discountTypeEnum.DollarAmount) {
    return (
      <div>
        <span className={unitOfMeasureClass}>{discountType.unitOfMeasure}</span>
        <span>{formatNumber(amount / 100)}</span>
      </div>
    );
  }

  return (
    <div>
      <span>{amount}</span>
      <span className={unitOfMeasureClass}>{discountType.unitOfMeasure}</span>
    </div>
  );
});

export default DiscountField;
