import React from 'react';
import { backorderManagementUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const BackorderManagementLink = React.memo(() => (
  <MenuTile
    title={'Backorder Management'}
    url={backorderManagementUrl}
    icon={'email'}
  />
));

export default BackorderManagementLink;
