import React from 'react';
import PropTypes from 'prop-types';
import NoteTop from './ArtworkTaskNoteTop';
import NoteDescription from './ArtworkTaskNoteDescription';
import NoteFailDescription from './ArtworkTaskNoteFailDescription';
import NoteBottom from './ArtworkTaskNoteBottom';

const ArtworkTaskNote = React.memo(({
  note,
  lockerId,
  loggedInUser,
  taskId,
}) => {
  const fail = note.failReason
    ? <NoteFailDescription failReason={note.failReason} />
    : '';
  const bottom = note.fileName
    ? (
      <NoteBottom
        fileName={note.fileName}
        fileUrl={note.attachmentUrl}
      />
    )
    : '';

  return (
    <li className='note'>
      <NoteTop
        id={note.id}
        lockerId={lockerId}
        createdBy={note.createdBy}
        loggedInUser={loggedInUser}
        date={note.created}
        user={note.createdByName}
        taskId={taskId}
      />
      {fail}
      <NoteDescription text={note.content} />
      {bottom}
    </li>

  );
});

ArtworkTaskNote.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    createdByName: PropTypes.string.isRequired,
    content: PropTypes.string,
    fileName: PropTypes.string,
    attachmentUrl: PropTypes.string,
    failReason: PropTypes.string,
  }).isRequired,
  loggedInUser: PropTypes.string,
  lockerId: PropTypes.number.isRequired,
  taskId: PropTypes.number.isRequired,
};

export default ArtworkTaskNote;
