import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
} from 'redux-form';
import PropTypes from 'prop-types';
import {
  rosterNotificationsSenderNameTypeEnum,
  rosterNotificationsLinkDestinationEnum,
} from '@constants/enums/rosterEnums';
import { rosterNotificationsForm } from '@constants/reduxForms';
import {
  rosterNotificationsSenderNameTypes,
  rosterNotificationsLinkDestinations,
} from '@constants/options/optionsValues';
import { validateNotifications } from '@redux/rosters/validations';
import { fetchEmailTemplates } from '@redux/emailCampaigns/actions';
import { mapToOptionsList } from '@util/mappingHelper';
import { parseDateNumeric } from '@util/dateHandler';
import Input from '@sharedComponents/Form/Input';
import FormError from '@sharedComponents/Form/FormError';
import DatePicker from '@sharedComponents/Form/DatePicker';
import FormDropdown from '@sharedComponents/Form/FormDropdown';

class RosterNotificationsForm extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchEmailTemplates());
  }

  render() {
    const {
      handleSubmit,
      error,
      currentSenderNameType,
      templates,
      change,
      collectionLandingPageLinkDestinationDisabled,
      initialValues,
      hasOrganization,
    } = this.props;

    const senderNameTypeOptions = rosterNotificationsSenderNameTypes.map((option) => {
      if (option.value === rosterNotificationsSenderNameTypeEnum.OrganizationName) {
        return ({
          ...option,
          disabled: !hasOrganization,
        });
      }

      return option;
    });

    const rosterNotificationsLinkDestinationsOptions = rosterNotificationsLinkDestinations.map((option) => {
      if (option.value === rosterNotificationsLinkDestinationEnum.CollectionLandingPage) {
        return ({
          ...option,
          disabled: option.disabled || collectionLandingPageLinkDestinationDisabled,
        });
      }

      if (option.value === rosterNotificationsLinkDestinationEnum.OrganizationLandingPage) {
        return ({
          ...option,
          disabled: !hasOrganization,
        });
      }

      return option;
    });

    const linkDestinationOptions = mapToOptionsList({
      list: rosterNotificationsLinkDestinationsOptions,
      key: 'key',
      value: 'value',
      name: 'name',
      emptyOption: {
        name: 'Choose Link Destination',
        disabled: true,
      },
    });

    const templateOptions = mapToOptionsList({
      list: templates,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: 'Choose Template',
        disabled: true,
      },
    });

    return (
      <form
        className='redux-form'
        id={rosterNotificationsForm}
        onSubmit={handleSubmit}
      >
        <div className='roster-notifications__form mb-100'>
          <div className='redux-form__row-aligned'>
            <div className='redux-form__column--size-m mr-80'>
              <label className='redux-form__label'>
                Send Date<span className='required'>*</span>
              </label>
              <Field
                name={'startDate'}
                placeholder={'Choose Send Date'}
                component={DatePicker}
              />
            </div>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Ordering Deadline
              </label>
              <div>{initialValues.orderingDeadline ? parseDateNumeric(initialValues.orderingDeadline) : 'None'}</div>
            </div>
          </div>

          <div className='redux-form__row-aligned'>
            <div className='redux-form__column--size-m mr-80'>
              <label className='redux-form__label'>
                Email Template<span className='required'>*</span>
              </label>
              <Field
                name={'templateId'}
                component={FormDropdown}
                options={templateOptions}
                change={change}
                classes={'roster-notifications__form__templates-dropdown'}
              />
            </div>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Link Destination<span className='required'>*</span>
              </label>
              <Field
                name={'linkDestination'}
                component={FormDropdown}
                options={linkDestinationOptions}
                change={change}
              />
            </div>
          </div>

          <div className='redux-form__row-aligned'>
            <div className='redux-form__column--size-xl'>
              <label className='redux-form__label'>
                Sender Name<span className='required'>*</span>
              </label>
              <Field
                name={'senderNameType'}
                component={FormDropdown}
                options={senderNameTypeOptions}
                change={change}
              />
            </div>
          </div>

          {
            currentSenderNameType === rosterNotificationsSenderNameTypeEnum.CustomName &&
            <div className='redux-form__row-aligned'>
              <div className='redux-form__column--size-xl'>
                <label className='redux-form__label'>
                  Custom Sender Name<span className='required'>*</span>
                </label>
                <Field
                  name={'customSenderName'}
                  placeholder={'Enter Custom Sender Name'}
                  component={Input}
                  type={'text'}
                />
              </div>
            </div>
          }

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

RosterNotificationsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    startDate: PropTypes.string,
    orderingDeadline: PropTypes.string,
    senderNameType: PropTypes.string,
    linkDestination: PropTypes.string,
    customSenderName: PropTypes.string,
    templateId: PropTypes.number,
    rosterIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  currentSenderNameType: PropTypes.string,
  templates: PropTypes.array,
  collectionLandingPageLinkDestinationDisabled: PropTypes.bool.isRequired,
  hasOrganization: PropTypes.bool.isRequired,
};

const selector = formValueSelector(rosterNotificationsForm);
const ConnectedRosterNotificationsForm = connect((state) => ({
  currentSenderNameType: selector(state, 'senderNameType'),
}))(RosterNotificationsForm);

const mapStateToProps = ({ emailCampaigns }) => ({
  templates: emailCampaigns.emailTemplates,
});

export default connect(mapStateToProps)(reduxForm({
  form: rosterNotificationsForm,
  enableReinitialize: true,
  validate: validateNotifications,
})(ConnectedRosterNotificationsForm));
