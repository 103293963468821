import React from 'react';
import LogoInfo from './LogoInfo';

interface OwnProps {
  previewUrl: string;
  orderNumber: number;
  size?: string;
  decorationId?: number;
}

type Props = OwnProps;

const MarkItemAsFlaggedModalLogoDetails = React.memo<Props>(({
  previewUrl,
  orderNumber,
  size,
  decorationId,
}) => (
  <div className='flagging__details'>
    <div className='flagging__details-logo'>
      <LogoInfo
        logoUrl={previewUrl}
      />
    </div>
    <div className='flagging__details-info'>
      <div className='flagging__details-info-column'>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Order ID
          </div>
          <div className='flagging__details-info-content'>
            {`O${orderNumber}`}
          </div>
        </div>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Decoration ID
          </div>
          <div className='flagging__details-info-content'>
            {`O${orderNumber}-${decorationId}`}
          </div>
        </div>
      </div>
      <div className='flagging__details-info-column'>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Size
          </div>
          <div className='flagging__details-info-content'>
            {size}
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default MarkItemAsFlaggedModalLogoDetails;
