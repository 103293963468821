import {
  isDefined,
  isNumber,
} from '@util/valueHelpers';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';

export const validateCustomItem = (values) => {
  const errors = {};
  if (!isDefined(values.price)) {
    errors.price = ['Price is required.'];
  } else if (!isNumber(values.price)) {
    errors.price = ['Price must be a number.'];
  } else if (values.price <= 0) {
    errors.price = ['Price must be a positive number.'];
  }

  if (values.fundraisingAmount < 0) {
    errors.fundraisingAmount = ['Fundraising amount must be positive number.'];
  }

  if (isDefined(values.customName) && values.customName.length > 100) {
    errors.customName = ['Custom name exceeds the character limit of 100.'];
  }

  const decorationsArrayErrors = [];
  values.decorations.forEach((decoration, decorationIndex) => {
    const decorationErrors = {};
    if ((!decoration.decorationWidth && decoration.decorationHeight)
          || (!decoration.decorationHeight && decoration.decorationWidth)) {
      if (!decoration.decorationWidth && decoration.type !== decorationTypeEnum.Personalization) {
        decorationErrors.decorationWidth = ['Width is required.'];
        decorationsArrayErrors[decorationIndex] = decorationErrors.decorationWidth;
      } else if (decoration.decorationWidth <= 0 && decoration.type !== decorationTypeEnum.Personalization) {
        decorationErrors.decorationWidth = ['Width should be a positive number.'];
        decorationsArrayErrors[decorationIndex] = decorationErrors.decorationWidth;
      }

      if (!decoration.decorationHeight) {
        decorationErrors.decorationHeight = ['Height is required.'];
        decorationsArrayErrors[decorationIndex] = decorationErrors.decorationHeight;
      } else if (decoration.decorationHeight <= 0) {
        decorationErrors.decorationHeight = ['Height should be a positive number.'];
        decorationsArrayErrors[decorationIndex] = decorationErrors.decorationHeight;
      }
    }
  });

  if (decorationsArrayErrors.length) {
    errors.decorations = decorationsArrayErrors;
  }

  return errors;
};
