import React from 'react';

interface OwnProps {
  children: JSX.Element;
  classes?: string;
  noPadding?: boolean;
  dataTest?: string;
}

type Props = OwnProps;

const SectionContainer = React.memo<Props>(({
  children,
  classes = '',
  noPadding = false,
  dataTest,
}) => (
  <div
    data-test={dataTest}
    className={`section-container ${noPadding ? 'no-padding' : ''} ${classes}`}
  >
    {children}
  </div>
));

export default SectionContainer;
