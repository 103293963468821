import * as actionTypes from './types';
import { PagedList } from '@models/common/PagedList';
import {
  AvailableSkusForVendorDesignId,
  OrderItemDetailsDictionary,
} from './models';

export interface VendorOrdersState {
  notScheduledOrdersQueue: PagedList<any>;
  scheduledOrdersQueue: PagedList<any>;
  notScheduledOrderItems: any[];
  vendorOrderItems: any[];
  orderItemDetailsDictionary: OrderItemDetailsDictionary;
  vendorOrderSubmitResults: any[];
  availableSkusForVendorDesignId: AvailableSkusForVendorDesignId;
}

export const initialState: VendorOrdersState = {
  notScheduledOrdersQueue: {
    totalPages: 1,
    pageNumber: 1,
    pageSize: 10,
    queue: [],
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  },
  scheduledOrdersQueue: {
    totalPages: 1,
    pageNumber: 1,
    pageSize: 10,
    queue: [],
    totalCount: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  },
  notScheduledOrderItems: [],
  vendorOrderItems: [],
  orderItemDetailsDictionary: {},
  vendorOrderSubmitResults: [],
  availableSkusForVendorDesignId: {
    skus: [],
    vendorDesignId: null,
  },
};

export default function vendorOrders(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_NOT_SCHEDULED_ORDERS_TABLE:
      return {
        ...state,
        notScheduledOrdersQueue: action.data,
      };
    case actionTypes.UPDATE_SCHEDULED_ORDERS_TABLE:
      return {
        ...state,
        scheduledOrdersQueue: action.data,
      };
    case actionTypes.UPDATE_NOT_SCHEDULED_ORDER_ITEMS:
      return {
        ...state,
        notScheduledOrderItems: action.data,
      };
    case actionTypes.UPDATE_ORDER_ITEMS_DETAILS_DICTIONARY:
      return {
        ...state,
        orderItemDetailsDictionary: action.data,
      };
    case actionTypes.UPDATE_VENDOR_ORDER_ITEMS:
      return {
        ...state,
        vendorOrderItems: action.data,
      };
    case actionTypes.UPDATE_VENDOR_ORDER_SUBMIT_RESULTS:
      return {
        ...state,
        vendorOrderSubmitResults: action.data,
      };
    case actionTypes.UPDATE_AVAILABLE_SKUS_FOR_VENDOR_DESIGN_ID:
      const availableSkusList = (action.data.skus || [])
        .map((skuOption: any) => skuOption.skuCandidates)
        .flat();

      const availableSkus = [...new Set(availableSkusList)];

      return {
        ...state,
        availableSkusForVendorDesignId: {
          skus: availableSkus,
          vendorDesignId: action.data.vendorDesignId,
        },
      };
    default:
      return state;
  }
}
