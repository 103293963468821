import { OrderManagementTempItemLogo } from '@redux/orderManagement/models';
import React, { useCallback } from 'react';

interface OwnProps {
  file: OrderManagementTempItemLogo;
  openModal: (imageUrl: string) => void;
}

type Props = OwnProps;

const OrderManagementDetailsItemExpandFile = React.memo(({
  file,
  openModal,
}: Props) => {
  const handleOpenImageModal = useCallback(() => {
    openModal(file.logoUrl!);
  }, [
    openModal,
    file,
  ]);

  return (
    <div className='order__files-file order__files-item'>
      <button
        onClick={handleOpenImageModal}
        type='button'
        className='order__files-artwork'
      >
        <span
          className='order__files-artwork-thumbnail'
          style={{ backgroundImage: `url("${file.logoUrl}")` }}
        />
        <div className='order__files-artwork-thumbnail-title sheet__thumbnails-title'>
          <span>{file.decorationMethod}</span>
          <span>{file.decorationLocation}</span>
        </div>
      </button>
    </div>
  );
});

export default OrderManagementDetailsItemExpandFile;
