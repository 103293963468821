import React from 'react';

interface OwnProps {
  sku: string;
  name: string;
  showLabels?: boolean;
}

type Props = OwnProps;

const ItemDescriptionCell = React.memo(({
  sku = '',
  name = '',
}: Props) => (
  <div>
    <div className='align__center'>
      <h3 className='lockerManager__code'>{sku || '-'}</h3>
    </div>
    <span className='lockerManager__description'>{name || ''}</span>
  </div>
));

export default ItemDescriptionCell;
