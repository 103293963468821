const ID = 'ORGANIZATIONS' as const;

export const UPDATE_ORGANIZATIONS_TABLE = `${ID}/UPDATE_ORGANIZATIONS_TABLE` as const;
export const UPDATE_ORGANIZATION = `${ID}/UPDATE_ORGANIZATION` as const;
export const UPDATE_ORGANIZATION_GROUPS = `${ID}/UPDATE_ORGANIZATION_GROUPS` as const;
export const UPDATE_ORGANIZATION_GROUP_LOCKERS = `${ID}/UPDATE_ORGANIZATION_GROUP_LOCKERS` as const;
export const UPDATE_ORGANIZATION_LOCKERS = `${ID}/UPDATE_ORGANIZATION_LOCKERS` as const;
export const UPDATE_ORGANIZATION_TOTALS = `${ID}/UPDATE_ORGANIZATION_TOTALS` as const;
export const UPDATE_FEATURED_LOCKERS = `${ID}/UPDATE_FEATURED_LOCKERS` as const;
export const UPDATE_ORGANIZATION_SUGGESTIONS = `${ID}/UPDATE_ORGANIZATION_SUGGESTIONS` as const;
export const UPDATE_LOCKER_SUGGESTIONS = `${ID}/UPDATE_LOCKER_SUGGESTIONS` as const;

export default ID;
