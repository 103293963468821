import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchOrdersList } from '@redux/weedingAndMaskingRoom/actions';
import WeedingAndMaskingOrderSwitch from './WeedingAndMaskingOrderSwitch';

class WeedingAndMaskingHeader extends PureComponent {
  componentDidUpdate(oldProps) {
    const {
      sheetId,
      dispatch,
    } = this.props;

    if (oldProps.orders.length === 0 || sheetId !== oldProps.sheetId) {
      dispatch(fetchOrdersList(sheetId));
    }
  }

  getCurrentAndSurroundingOrders = () => {
    const {
      orderNumber,
      orders,
    } = this.props;

    const index = orders.findIndex((order) => Number(orderNumber) === order.orderNumber);

    const previousOrder = index - 1 < 0 ? orders[orders.length - 1] : orders[index - 1];
    const nextOrder = index + 1 >= orders.length ? orders[0] : orders[index + 1];

    return {
      previousOrderNumber: previousOrder.orderNumber,
      currentOrder: orders[index],
      nextOrderNumber: nextOrder.orderNumber,
    };
  };

  render() {
    const {
      sheetId,
      orders,
    } = this.props;

    if (sheetId && orders.length !== 0) {
      const ordersBlock = this.getCurrentAndSurroundingOrders();
      const { currentOrder } = ordersBlock;

      return (
        <div className='header__bar-content'>
          <h2 className='header__bar-title'>Sheet S{sheetId} - Print Assembly</h2>
          {
            currentOrder &&
            <WeedingAndMaskingOrderSwitch
              currentOrder={currentOrder}
              previousOrderUrl={`/print-assembly/${sheetId}/order/${ordersBlock.previousOrderNumber}`}
              nextOrderUrl={`/print-assembly/${sheetId}/order/${ordersBlock.nextOrderNumber}`}
            />
          }
        </div>
      );
    }

    return null;
  }
}

WeedingAndMaskingHeader.propTypes = {
  sheetId: PropTypes.number,
  orderNumber: PropTypes.number,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.number.isRequired,
      lockerId: PropTypes.number.isRequired,
      lockerTeamName: PropTypes.string.isRequired,
      organizationId: PropTypes.number,
      organizationName: PropTypes.string,
      shipDate: PropTypes.string,
    })
  ),
};

const mapStateToProps = ({ weedingAndMaskingRoom }) => ({
  sheetId: weedingAndMaskingRoom.sheetId,
  orderNumber: weedingAndMaskingRoom.orderNumber,
  orders: weedingAndMaskingRoom.ordersList,
});

export default withRouter(connect(mapStateToProps)(WeedingAndMaskingHeader));
