import React from 'react';
import { organizationsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const OrganizationsLink = React.memo(() => (
  <MenuTile
    title={'Organizations'}
    url={organizationsUrl}
    icon={'business'}
  />
));

export default OrganizationsLink;
