import React from 'react';
import PropTypes from 'prop-types';

const ArtworkTaskNoteDescription = React.memo(({ text }) => (
  <div className='note__content'>
    {text}
  </div>
));

ArtworkTaskNoteDescription.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ArtworkTaskNoteDescription;
