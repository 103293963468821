import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  decorationLocationEnum,
  decorationTypeEnum,
  decorationMethodEnum,
} from '@constants/enums/decorationEnums';
import ListCell from '@sharedComponents/Table/TableCells/ListCell';
import ShowMoreTooltip from '@sharedComponents/Display/ShowMoreTooltip';

const customItemDecorationKeys = {
  Location: 'decorationLocation',
  Method: 'decorationMethod',
};

const itemDecorationKeys = {
  Location: 'decoration_location',
  Method: 'decoration_method',
};

class ItemLocationAndSizeCell extends PureComponent {
  getDecorationDimensions = (items, item, locationKey, methodKey, width, height) => {
    const {
      allDecorationLocations,
      decorationLocationArtworkSizes,
    } = this.props;

    if (width && height) {
      items.push(this.getLocationAndSizeString(item[locationKey], width, height));

      return;
    }

    const decoration = allDecorationLocations && allDecorationLocations
      .find((location) => location.code === item[locationKey]);

    if (decoration) {
      const decorationDimensions = Object.keys(decorationLocationArtworkSizes).length > 0 &&
        decorationLocationArtworkSizes[decoration.id][item[methodKey]];

      const decorationDimension = decorationDimensions &&
        (decorationDimensions.Undefined || decorationDimensions.Regular);

      if (decorationDimension) {
        items.push(
          this.getLocationAndSizeString(
            decoration.code,
            width || decorationDimension.width,
            height || decorationDimension.height
          )
        );
      } else if (item[methodKey] === decorationMethodEnum.SUB) {
        items.push(item[locationKey]);
      }
    } else if (item[locationKey] !== decorationLocationEnum.NA) {
      items.push(item[locationKey]);
    }
  };

  getLocationAndSizeString = (location, width, height) => `${location} (${width}'' x ${height}'')`;

  render() {
    const { item } = this.props;
    const items = [];

    if (item.isLockerItem) {
      const itemDecorations = item.decorations
        .filter((decoration) => decoration.type === decorationTypeEnum.Logo);

      for (const itemDecoration of itemDecorations) {
        this.getDecorationDimensions(
          items,
          itemDecoration,
          customItemDecorationKeys.Location,
          customItemDecorationKeys.Method,
          itemDecoration.decorationWidth,
          itemDecoration.decorationHeight
        );
      }
    } else {
      this.getDecorationDimensions(
        items,
        item,
        itemDecorationKeys.Location,
        itemDecorationKeys.Method
      );
    }

    if (items.length > 1) {
      return (
        <div className='product-catalog__details--icon'>
          <span>Multiple</span>
          <ShowMoreTooltip
            tooltipText={<div className='pre-line'>{items.join('\n')}</div>}
          />
        </div>
      );
    }

    return items.length > 0
      ? <ListCell items={items} />
      : '-';
  }
}

ItemLocationAndSizeCell.propTypes = {
  item: PropTypes.object.isRequired,
  decorationLocationArtworkSizes: PropTypes.object.isRequired,
  allDecorationLocations: PropTypes.array.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  decorationLocationArtworkSizes: productCatalog.decorationLocationArtworkSizes,
  allDecorationLocations: productCatalog.decorationLocations,
});

export default connect(mapStateToProps)(ItemLocationAndSizeCell);
