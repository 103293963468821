import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { Column } from 'react-table-6';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { defaultPageSizeBigTable } from '@constants/values';
import { SortDirectionShort } from '@customTypes/table';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import Table from '@sharedComponents/Table/Table';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import TableFilters from '@components/shared/Display/TableFilters/TableFilters';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import {
  SportViewModel,
  VoucherCreationLockerViewModel as Locker,
} from '@api/squadlockerServices/models';
import { useGetVoucherCreationLockers } from '@api/squadlockerServices/vouchers';
import { useGetAllSports } from '@api/squadlockerServices/sports';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';

const LockersTable = Table<Locker>();

interface OwnProps {
  selectedLockers: Locker[];
  updateSelectedLockers: (lockers: Locker[]) => void;
}

type Props = OwnProps;

const LockersAndOrganizationsStep = React.memo<Props>(({
  selectedLockers,
  updateSelectedLockers,
}) => {
  const [
    pageNumber,
    setPageNumber,
  ] = useState<number>(1);
  const [
    pageSize,
    setPageSize,
  ] = useState<number>(defaultPageSizeBigTable);
  const [
    sortColumn,
    setSortColumn,
  ] = useState<string>('id');
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionShort>(sortDirectionShortEnum.Desc);
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>('');
  const [
    selectedSports,
    setSelectedSports,
  ] = useState<number[] | undefined>(undefined);

  const [
    isPageSelected,
    setIsPageSelected,
  ] = useState<boolean>(false);

  const getSearchInput = (): string => {
    if (selectedLockers.length) {
      return selectedLockers[0].organizationId
        ? searchInput
        : selectedLockers[0].id.toString();
    }

    return searchInput;
  };

  const {
    data: lockersData,
    refetch: fetchLockers,
  } = useGetVoucherCreationLockers({
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    filter: getSearchInput(),
    sportId: selectedSports,
    organizationId: selectedLockers.length > 0 && selectedLockers[0].organizationId
      ? selectedLockers[0].organizationId
      : undefined,
  });

  const {
    data: sportsList,
    refetch: fetchSports,
  } = useGetAllSports();

  const updateSelection = useCallback((
    newSelectedItems: Locker[],
    newIsPageSelected: boolean
  ) => {
    updateSelectedLockers(newSelectedItems);
    setIsPageSelected(newIsPageSelected);
    setPageNumber(1);
  }, [updateSelectedLockers]);

  const fetchData = useCallback((state: any, instance: any) => {
    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortColumn, sortDirection);

    setPageNumber(newPageNumber + 1);
    setPageSize(newPageSize);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
  }, [
    sortColumn,
    sortDirection,
  ]);

  useEffect(() => {
    fetchLockers();
  }, [
    fetchLockers,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    searchInput,
    selectedLockers,
    selectedSports,
  ]);

  useEffect(() => {
    fetchSports();
  }, [fetchSports]);

  const search = useCallback((value) => {
    setSearchInput(value);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchInput('');
  }, []);

  const filterKey = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    search(e.target.value);
  }, [search]);

  const onSportSelected = useCallback((value: number[]) => {
    setSelectedSports(value.length ? value : undefined);
  }, []);

  const columns = useMemo((): Array<Column<Locker>> => ([
    {
      Header: <HeaderCell text={'Locker Name'} />,
      accessor: 'id',
      sortable: true,
      minWidth: 50,
      Cell: (cellProps) => <span>L{cellProps.value} - {cellProps.original.name}</span>,
    },
    {
      Header: 'Organizations',
      accessor: 'organizationId',
      sortable: false,
      minWidth: 65,
      Cell: (cellProps) => cellProps.value ? <span>ORG{cellProps.value} - {cellProps.original.organizationName}</span> : '-',
    },
    {
      Header: 'Sport',
      accessor: 'sportName',
      sortable: false,
      minWidth: 65,
    },
  ]
  ), []);

  const selectingPageDisabled = useCallback(() => {
    const organizationLockerSelected = selectedLockers.length && !!selectedLockers[0].organizationId;

    return !organizationLockerSelected;
  }, [selectedLockers]);

  return (
    <div>
      <div className='container'>
        <div className='flex'>
          <TableFilters
            leftGroup={(
              <>
                <SearchFilter
                  initialValue={searchInput}
                  search={filterKey}
                  clearSearch={clearSearch}
                  placeholder={'Locker, Org Id or Name'}
                />
                {
                  sportsList?.length &&
                  <MultiSelectDropdown<SportViewModel, 'id'>
                    objects={sportsList!}
                    itemText={'sports'}
                    updateCallback={onSportSelected}
                    textKey={'name'}
                    valueKey={'id'}
                    classNames={'margin-left'}
                  />
                }
              </>
            )}
          />
        </div>
      </div>
      <div className='w-100'>
        <div className='sheet'>
          <LockersTable
            data={lockersData?.items || []}
            columns={columns}
            showPagination={lockersData?.hasNextPage || lockersData?.hasPreviousPage}
            totalPages={lockersData?.totalPages}
            onFetchData={fetchData}
            tableName={'Lockers'}
            selectable={true}
            selectPredicateOrKey={'id'}
            updateSelection={updateSelection}
            selectedData={selectedLockers}
            isPageSelected={isPageSelected}
            isSelectingPageDisabled={selectingPageDisabled}
          />
        </div>
      </div>
    </div>
  );
});

export default LockersAndOrganizationsStep;
