import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Column } from 'react-table-6';
import {
  CollectionItemViewModel,
  RosterTeamMemberViewModel,
} from '@api/squadlockerServices/models';
import {
  deleteTeamMembers,
  editTeamMember,
  addTeamMember,
} from '@APICalls/rosters/actions';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { rosterDetailsBulkActionsEnum } from '@constants/enums/bulkActionsEnum';
import { RosterMemberFormData } from '@models/forms/RosterManager/RosterMemberFormData';
import { materialSwal } from '@util/componentHelper';
import { findDuplicatesInArray } from '@util/arrayHelper';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import DownloadFileLink from '@sharedComponents/Navigation/DownloadFileLink';
import WarningIconWithTooltip from '@sharedComponents/Icons/WarningIconWithTooltip';
import TooltipCell from '@sharedComponents/Table/TableCells/TooltipCell';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import DeleteMembersModal from '../RosterManagerModals/DeleteMembersModal';
import RosterMembersActionsColumn from '../RosterMembersActionsColumn';
import TeamMemberEditModal from '../RosterManagerModals/TeamMemberEditModal';
import TeamMemberAddModal from '../RosterManagerModals/TeamMemberAddModal';
import RosterTeamMemberQuickView from './RosterTeamMemberQuickView';

const TeamMembersTable = Table<RosterTeamMemberViewModel>();

interface OwnProps {
  rosterId: number;
  itemsToOrderCount: number;
  downloadCSVFile: () => void;
  openReimportRosterFromCSVModal: () => void;
  hasActiveVouchers: boolean;
  refreshTable: () => void;
  rosterMembers: RosterTeamMemberViewModel[];
  collectionRequired: boolean;
  collectionItems: CollectionItemViewModel[];
}

type Props = OwnProps;

const RosterTeamMembersTable = React.memo<Props>(({
  rosterId,
  itemsToOrderCount,
  downloadCSVFile,
  openReimportRosterFromCSVModal,
  hasActiveVouchers,
  refreshTable,
  rosterMembers,
  collectionRequired,
  collectionItems,
}) => {
  const [
    filteredRosterMembers,
    setFilteredRosterMembers,
  ] = useState<RosterTeamMemberViewModel[]>(rosterMembers);
  const [
    includeOnlyIncompleteOrders,
    setIncludeOnlyIncompleteOrders,
  ] = useState<boolean>(false);
  const [
    duplicateLastNames,
    setDuplicateLastNames,
  ] = useState<string[]>(
    findDuplicatesInArray(rosterMembers.filter((r) => r.lastName).map((r) => r.lastName))
  );
  const [
    duplicateNumbers,
    setDuplicateNumbers,
  ] = useState<string[]>(
    findDuplicatesInArray(rosterMembers.filter((r) => r.number).map((r) => r.number))
  );

  const [
    isPageSelected,
    setIsPageSelected,
  ] = useState<boolean>(false);
  const [
    selectedRosterTeamMember,
    setSelectedRosterTeamMember,
  ] = useState<Nullable<{
    firstName: string;
    rosterTeamId: number;
  } & Partial<RosterTeamMemberViewModel>>>(null);
  const [
    selectedRosterTeamMembers,
    setSelectedRosterTeamMembers,
  ] = useState<RosterTeamMemberViewModel[]>([]);

  const [
    addTeamMemberModalIsOpen,
    setAddTeamMemberModalIsOpen,
  ] = useState<boolean>(false);
  const [
    editTeamMemberModalIsOpen,
    setEditTeamMemberModalIsOpen,
  ] = useState<boolean>(false);
  const [
    deleteMembersModalIsOpen,
    setDeleteMembersModalIsOpen,
  ] = useState<boolean>(false);

  const getDuplicateLastNames = useCallback((): string[] => (
    findDuplicatesInArray(rosterMembers.filter((r) => r.lastName).map((r) => r.lastName))
  ), [rosterMembers]);

  const getDuplicateNumbers = useCallback((): string[] => (
    findDuplicatesInArray(rosterMembers.filter((r) => r.number).map((r) => r.number))
  ), [rosterMembers]);

  const filterRosterMembers = useCallback((searchInput?: string): RosterTeamMemberViewModel[] => {
    const search = (searchInput || '').toLowerCase();

    if (search === '') {
      return rosterMembers;
    }

    return rosterMembers.filter((member) => (
      (member.firstName || '-').toLowerCase().includes(search)
      || (member.lastName || '-').toLowerCase().includes(search)
      || (member.number || '-').toLowerCase().includes(search)
      || (member.role || '-').toLowerCase().includes(search)
    ));
  }, [rosterMembers]);

  useEffect(() => {
    setDuplicateLastNames(getDuplicateLastNames());
    setDuplicateNumbers(getDuplicateNumbers());
    setFilteredRosterMembers(filterRosterMembers());
  }, [
    rosterMembers,
    filterRosterMembers,
    getDuplicateLastNames,
    getDuplicateNumbers,
  ]);

  const toggleIncludeOnlyIncompleteOrders = useCallback(() => {
    setIncludeOnlyIncompleteOrders(!includeOnlyIncompleteOrders);
  }, [includeOnlyIncompleteOrders]);

  const clearSearch = useCallback(() => {
    setFilteredRosterMembers(filterRosterMembers(''));
  }, [filterRosterMembers]);

  const onSearch = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    setFilteredRosterMembers(filterRosterMembers(searchInput));
  }, [filterRosterMembers]);

  const selectRosterTeamMember = useCallback((newSelectedRosterTeamMember: RosterTeamMemberViewModel) => {
    setSelectedRosterTeamMember(newSelectedRosterTeamMember);
  }, []);

  const unselectRosterTeamMember = useCallback(() => {
    setSelectedRosterTeamMember(null);
  }, []);

  const updateSelection = useCallback((newSelectedItems: RosterTeamMemberViewModel[], newIsPageSelected: boolean) => {
    setSelectedRosterTeamMembers(newSelectedItems);
    setIsPageSelected(newIsPageSelected);
  }, []);

  const clearSelection = useCallback(() => {
    updateSelection([], false);
  }, [updateSelection]);

  const openAddTeamMemberModal = useCallback(() => {
    setAddTeamMemberModalIsOpen(true);
    setSelectedRosterTeamMember({
      firstName: '',
      rosterTeamId: rosterId,
    });
  }, [rosterId]);

  const closeAddTeamMemberModal = useCallback(() => {
    setAddTeamMemberModalIsOpen(false);
    setSelectedRosterTeamMember(null);
  }, []);

  const openEditTeamMemberModal = useCallback((teamMember: RosterTeamMemberViewModel) => {
    setEditTeamMemberModalIsOpen(true);
    setSelectedRosterTeamMember(teamMember);
  }, []);

  const closeEditTeamMemberModal = useCallback(() => {
    setEditTeamMemberModalIsOpen(false);
    setSelectedRosterTeamMember(null);
  }, []);

  const openDeleteMembersModal = useCallback((teamMember: RosterTeamMemberViewModel) => {
    setDeleteMembersModalIsOpen(true);
    setSelectedRosterTeamMembers([teamMember]);
  }, []);

  const closeDeleteMembersModal = useCallback(() => {
    setDeleteMembersModalIsOpen(false);
    setSelectedRosterTeamMember(null);
  }, []);

  const onAddTeamMember = useCallback(async (teamMemberForm: RosterMemberFormData) => {
    const res = await addTeamMember(teamMemberForm);
    refreshTable();
    materialSwal('Success', res.message, 'success');
    closeAddTeamMemberModal();
  }, [
    refreshTable,
    closeAddTeamMemberModal,
  ]);

  const onEditTeamMember = useCallback(async (teamMemberForm: RosterMemberFormData) => {
    const res = await editTeamMember(teamMemberForm);
    refreshTable();
    materialSwal('Success', res.message, 'success');
    closeEditTeamMemberModal();
  }, [
    refreshTable,
    closeEditTeamMemberModal,
  ]);

  const deleteMembers = useCallback(async () => {
    const res = await deleteTeamMembers(selectedRosterTeamMembers, rosterId);
    if (res?.success) {
      refreshTable();
      closeDeleteMembersModal();
      clearSelection();
      materialSwal('Success', res.message, 'success');
    }
  }, [
    refreshTable,
    closeDeleteMembersModal,
    clearSelection,
    rosterId,
    selectedRosterTeamMembers,
  ]);

  const onBulkDeleteMembers = useCallback(() => {
    setDeleteMembersModalIsOpen(true);
  }, []);

  const onSelectBulkAction = useCallback(async (selectedBulkAction) => {
    switch (selectedBulkAction) {
      case rosterDetailsBulkActionsEnum.deleteMembers:
        onBulkDeleteMembers();
        break;
    }
  }, [onBulkDeleteMembers]);

  const getBulkActions = () => {
    const bulkActions = [
      {
        key: 1,
        value: rosterDetailsBulkActionsEnum.deleteMembers,
        name: 'Delete Member(s)',
      },
    ];

    return bulkActions;
  };

  const getColumns = useCallback((): Array<Column<RosterTeamMemberViewModel>> => {
    let columns: Array<Column<RosterTeamMemberViewModel>> = [
      {
        Header: 'Full Name',
        accessor: '',
        minWidth: 40,
        Cell: (cellProps) => (
          <div className='flex'>
            {
              (cellProps.original.firstName && cellProps.original.lastName)
                ? <span><b>{cellProps.original.lastName}, {cellProps.original.firstName}</b></span>
                : <span><b>{cellProps.original.lastName || cellProps.original.firstName}</b></span>
            }
            {
              (cellProps.original.lastName && duplicateLastNames.includes(cellProps.original.lastName)) &&
              <WarningIconWithTooltip
                tooltipText={'Duplicate last name detected'}
                classes={'ml-5'}
              />
            }
          </div>
        ),
      },
      {
        Header: 'Number',
        accessor: 'number',
        minWidth: 10,
        Cell: (cellProps) => cellProps.value && (
          <div className='flex'>
            <span><b>{cellProps.value}</b></span>
            {
              duplicateNumbers.includes(cellProps.value) &&
              <WarningIconWithTooltip
                tooltipText={'Duplicate number detected'}
                classes={'ml-5'}
              />
            }
          </div>
        ),
      },
      {
        Header: 'Role',
        accessor: 'role',
        minWidth: 20,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Items Ordered',
        accessor: 'orderedItemsCount',
        minWidth: 20,
        Cell: (cellProps) => (
          <span
            className={(itemsToOrderCount !== 0 && (cellProps.value || 0) === itemsToOrderCount)
              ? 'roster__items-ordered-count--all'
              : ''}
          >
            {cellProps.value || 0}/{itemsToOrderCount}
          </span>
        ),
      },
    ];

    if (!selectedRosterTeamMember) {
      columns = [
        ...columns,
        {
          Header: 'E-mail(s)',
          accessor: 'email',
          minWidth: 30,
          sortable: true,
          Cell: (cellProps) => {
            const items = (cellProps.value && cellProps.original.emailAdditional)
              ? [cellProps.original.emailAdditional[0]]
              : [];

            return (
              cellProps.value &&
              <div>
                <span>
                  <b>
                    {cellProps.value}
                  </b>
                </span>
                {
                  items.length > 0 &&
                  <TooltipCell
                    items={items}
                    displayName={'more'}
                  />
                }
              </div>
            );
          },
        },
        {
          Header: 'Phone(s)',
          accessor: 'phone',
          minWidth: 20,
          sortable: true,
          Cell: (cellProps) => {
            let items = cellProps.original.phoneAdditional
              ? [cellProps.original.phoneAdditional[0]]
              : [];

            let primaryItem;

            if (cellProps.value) {
              primaryItem = cellProps.value;
            } else if (items.length === 1) {
              primaryItem = items[0];
              items = [];
            }

            return (
              <div>
                {
                  primaryItem &&
                  <span>
                    <b>
                      {primaryItem}
                    </b>
                  </span>
                }
                {
                  items.length > 0 &&
                  <TooltipCell
                    items={items}
                    displayName={'more'}
                  />
                }
              </div>
            );
          },
        },
      ];
    }

    columns = [
      ...columns,
      {
        Header: '',
        width: 50,
        resizable: false,
        accessor: '',
        style: { overflow: 'visible' },
        Cell: (cellProps) => (
          <RosterMembersActionsColumn
            rosterMember={cellProps.value}
            onRosterMemberEdit={openEditTeamMemberModal}
            onRosterMemberDelete={openDeleteMembersModal}
            hasActiveVouchers={hasActiveVouchers}
          />
        ),
      },
    ];

    return columns;
  }, [
    duplicateLastNames,
    duplicateNumbers,
    itemsToOrderCount,
    openDeleteMembersModal,
    openEditTeamMemberModal,
    selectedRosterTeamMember,
    hasActiveVouchers,
  ]);

  const getTrProps = useCallback((state, rowInfo) => (
    getSelectableTableRowProps(selectRosterTeamMember, rowInfo, selectedRosterTeamMember as RosterTeamMemberViewModel, 'id')
  ), [
    selectRosterTeamMember,
    selectedRosterTeamMember,
  ]);

  const rosterMembersQueue = (includeOnlyIncompleteOrders && itemsToOrderCount !== 0)
    ? filteredRosterMembers.filter((member) => (member.orderedItemsCount || 0) < itemsToOrderCount)
    : filteredRosterMembers;

  const bulkActions = useMemo(getBulkActions, [getBulkActions]);
  const columns = useMemo(getColumns, [getColumns]);

  return (
    <div className='container'>
      <DeleteMembersModal
        isOpen={deleteMembersModalIsOpen}
        closeModal={closeDeleteMembersModal}
        members={selectedRosterTeamMembers}
        deleteMembers={deleteMembers}
      />
      <TeamMemberEditModal
        isOpen={editTeamMemberModalIsOpen}
        closeModal={closeEditTeamMemberModal}
        onSubmit={onEditTeamMember}
        initialValues={selectedRosterTeamMember as RosterTeamMemberViewModel}
      />
      <TeamMemberAddModal
        isOpen={addTeamMemberModalIsOpen}
        closeModal={closeAddTeamMemberModal}
        onSubmit={onAddTeamMember}
        initialValues={selectedRosterTeamMember}
      />
      <div className='navigation mb-5'>
        <div className='align__center'>
          <SearchFilter
            search={onSearch}
            clearSearch={clearSearch}
          />
          <MaterialCheckbox
            text={'Only Incomplete Orders'}
            checked={includeOnlyIncompleteOrders}
            onClick={toggleIncludeOnlyIncompleteOrders}
            classes={'ml-10 mb-5'}
          />
        </div>
        <div className='align__center'>
          {
            rosterId &&
            <DownloadFileLink
              text={'Download CSV'}
              downloadFile={downloadCSVFile}
            />
          }
          <Button
            type={'secondary'}
            text={'Re-import CSV'}
            classes={'mr-20'}
            onClick={openReimportRosterFromCSVModal}
            disabled={hasActiveVouchers}
            tooltipText={hasActiveVouchers ? 'Cannot reimport roster team data because the roster team has an active voucher associated with it' : undefined}
          />
          <Button
            type={'primary'}
            text={'Add Member'}
            onClick={openAddTeamMemberModal}
            disabled={hasActiveVouchers}
            tooltipText={hasActiveVouchers ? 'Cannot add a roster team member because the roster team has an active voucher associated with it' : undefined}
          />
        </div>
      </div>
      <div className='master-detail'>
        <div className='w-100'>
          <TeamMembersTable
            data={rosterMembersQueue}
            columns={columns}
            showPagination={false}
            selectable={true}
            selectPredicateOrKey={'id'}
            updateSelection={updateSelection}
            selectedData={selectedRosterTeamMembers}
            isPageSelected={isPageSelected}
            bulkActionsList={bulkActions}
            onSelectBulkAction={onSelectBulkAction}
            getTrProps={getTrProps}
          />
        </div>
        {
          selectedRosterTeamMember &&
          <RosterTeamMemberQuickView
            rosterTeamMember={selectedRosterTeamMember as RosterTeamMemberViewModel}
            collectionItems={collectionItems}
            collectionRequired={collectionRequired}
            itemsToOrderCount={itemsToOrderCount}
            closeDetails={unselectRosterTeamMember}
          />
        }
      </div>
    </div>
  );
});

export default RosterTeamMembersTable;
