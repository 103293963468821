import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { linearProgressBarDelay } from '@constants/values';

const ColorLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  barColorPrimary: {
    backgroundColor: '#029487',
  },
  root: {
    height: 3,
    margin: theme.spacing(0),
  },
}))(LinearProgress);

interface OwnProps {
  isLoadingActive: boolean;
}

type Props = OwnProps;

interface State {
  isLoading: boolean;
  throttledCall: Nullable<ReturnType<typeof setTimeout>>;
}

class LinearProgressBar extends Component<Props, State> {
  state: State = {
    isLoading: this.props.isLoadingActive,
    throttledCall: null,
  };

  componentDidUpdate(prevProps: Props): void {
    const { isLoadingActive } = this.props;
    const {
      isLoading,
      throttledCall,
    } = this.state;

    if (!prevProps.isLoadingActive && isLoadingActive && !isLoading) {
      if (throttledCall) {
        clearTimeout(throttledCall);
      }

      this.setState(() => ({
        isLoading: true,
        throttledCall: null,
      }));
    } else if (prevProps.isLoadingActive && !isLoadingActive && isLoading) {
      if (throttledCall) {
        clearTimeout(throttledCall);
      }

      const newThrottle = setTimeout(() => {
        this.setState(() => ({
          isLoading: false,
          throttledCall: null,
        }));
      }, linearProgressBarDelay);

      this.setState(() => ({ throttledCall: newThrottle }));
    }
  }

  render(): JSX.Element {
    const { isLoading } = this.state;

    return (
      <div className='loading-progress-bar'>
        {
          isLoading &&
          <ColorLinearProgress />
        }
      </div>
    );
  }
}

export default LinearProgressBar;
