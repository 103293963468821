import React from 'react';
import PropTypes from 'prop-types';
import { translateCorrection } from '@constants/common';
import { requestTasksEnum } from '@constants/enums/logoReviewEnums';

const RequestTasks = ({
  requestTasks,
  onChangeRequestTasks,
}) => {
  const activeBtnClass = 'logo-review__task__options-toggle-btn--active';

  const logoEditButtons = (
    <div className='logo-review__task__options__request-tasks-group'>
      <div className='logo-review__task__options__request-tasks-title'>General Edits</div>

      <div>
        <button
          onClick={onChangeRequestTasks.bind(null, requestTasksEnum.backgroundRemoval)}
          className={`logo-review__task__options-toggle-btn ${requestTasks.backgroundRemoval ? activeBtnClass : ''}`}
        >
          {translateCorrection.backgroundRemoval}
        </button>

        <button
          onClick={onChangeRequestTasks.bind(null, requestTasksEnum.vectorization)}
          className={`logo-review__task__options-toggle-btn ${requestTasks.vectorization ? activeBtnClass : ''}`}
        >
          {translateCorrection.vectorization}
        </button>
      </div>
    </div>
  );

  const otherButtons = (
    <div className='logo-review__task__options__request-tasks-group'>
      <div className='logo-review__task__options__request-tasks-title'>Logo Artwork Edits</div>

      <div>
        <button
          onClick={onChangeRequestTasks.bind(null, requestTasksEnum.gradient)}
          className={`logo-review__task__options-toggle-btn ${requestTasks.gradient ? activeBtnClass : ''}`}
        >
          {translateCorrection.gradient}
        </button>

        <button
          onClick={onChangeRequestTasks.bind(null, requestTasksEnum.reachOutNeeded)}
          className={`logo-review__task__options-toggle-btn ${requestTasks.reachOutNeeded ? activeBtnClass : ''}`}
        >
          {translateCorrection.reachOutNeeded}
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <div className='logo-review__task__options-title mb-20'>
        Request Tasks
      </div>
      {logoEditButtons}
      {otherButtons}
    </div>
  );
};

RequestTasks.propTypes = {
  requestTasks: PropTypes.shape({
    reachOutNeeded: PropTypes.bool,
    vectorization: PropTypes.bool,
    backgroundRemoval: PropTypes.bool,
    lightVersionRequested: PropTypes.bool,
    darkVersionRequested: PropTypes.bool,
    gradient: PropTypes.bool,
  }).isRequired,
  onChangeRequestTasks: PropTypes.func.isRequired,
};

export default RequestTasks;
