import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@sharedComponents/Buttons/Button';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

class FundraisingPayoutsQuickViewActions extends PureComponent {
  state = {
    confirmationModalIsOpen: false,
  };

  openConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: true }));
  };

  closeConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: false }));
  };

  handleModalConfirm = () => {
    this.props.cancelPayout();
    this.closeConfirmationModal();
  };

  render() {
    const { payoutId } = this.props;
    const { confirmationModalIsOpen } = this.state;

    return (
      <>
        <div className='tableView__details--footer'>
          <div className='tableView__details--footer-buttons'>
            <Button
              type={'secondary'}
              text={'Cancel Request'}
              onClick={this.openConfirmationModal}
              classes={'w-100'}
            />
          </div>
        </div>

        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.handleModalConfirm}
          closeModal={this.closeConfirmationModal}
          title={'Cancel Payout'}
          confirmationBody={`Are you sure you want to cancel payout ${payoutId}?`}
        />
      </>
    );
  }
}

FundraisingPayoutsQuickViewActions.propTypes = {
  payoutId: PropTypes.number.isRequired,
  cancelPayout: PropTypes.func.isRequired,
};

export default FundraisingPayoutsQuickViewActions;
