import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { ObjectType } from '@customTypes/option';
import SelectIcon from '@sharedComponents/Icons/SelectIcon';
import Expander from '@sharedComponents/Buttons/Expander';

export interface ExpandableGroupItemProps<T extends ObjectType = ObjectType> {
  item: T;
  itemsSelectable?: boolean;
  isSelected?: boolean;
  selectItem?: (item: T) => void;
}

interface OwnProps<T extends ObjectType = ObjectType> {
  items: T[];
  title: string | JSX.Element;
  expandableGroupItemComponent: React.FC<ExpandableGroupItemProps<T>>;
  identifier?: keyof T;
  groupSelectable?: boolean;
  itemsSelectable?: boolean;
  allExpanded?: boolean;
  selectedItems?: T[];
  updateSelectedItems?: (selectedItems: T[]) => void;
  classes?: string;
  headerClasses?: string;
  headerTitleClasses?: string;
  uppercaseHeaderTitle?: boolean;
}

type Props<T extends ObjectType = ObjectType> = OwnProps<T>;

const ExpandableGroup = <T extends ObjectType = ObjectType>({
  items,
  title,
  identifier = 'id',
  expandableGroupItemComponent: ExpandableGroupItemComponent,
  groupSelectable = false,
  itemsSelectable = false,
  allExpanded = true,
  selectedItems = [],
  updateSelectedItems,
  classes = '',
  headerClasses = '',
  headerTitleClasses = '',
  uppercaseHeaderTitle = false,
}: Props<T>) => {
  const [
    isExpanded,
    setIsExpanded,
  ] = useState<boolean>(allExpanded);
  const [
    isSelected,
    setIsSelected,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsExpanded(allExpanded);
  }, [allExpanded]);

  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const toggleSelectAllItemsInGroup = useCallback(() => {
    const newIsSelected = !isSelected;
    const newSelectedItems = newIsSelected ? items : [];

    setIsSelected(newIsSelected);
    updateSelectedItems?.(newSelectedItems);
  }, [
    items,
    isSelected,
    updateSelectedItems,
  ]);

  const isItemSelected = useCallback((item: T) => (
    selectedItems.find((i) => i[identifier] === item[identifier]) !== undefined
  ), [
    selectedItems,
    identifier,
  ]);

  const toggleItemSelection = useCallback((item: T) => {
    if (isItemSelected(item)) {
      return selectedItems.filter((i) => i[identifier] !== item[identifier]);
    }

    return [
      ...selectedItems,
      item,
    ];
  }, [
    isItemSelected,
    selectedItems,
    identifier,
  ]);

  const selectItem = useCallback((item: T) => {
    const newSelectedItems = toggleItemSelection(item);
    updateSelectedItems?.(newSelectedItems);
  }, [
    toggleItemSelection,
    updateSelectedItems,
  ]);

  const itemsCount = items.map((item) => item[identifier]).flat().length;

  if (itemsCount === 0) return null;

  const expanderText = `${itemsCount} ${itemsCount === 1 ? 'item' : 'items'}`;

  return (
    <div className={classes}>
      <div className={`expandable-group__header ${headerClasses}`}>
        <div className='align__center'>
          {
            groupSelectable &&
            <SelectIcon
              select={toggleSelectAllItemsInGroup}
              item={title}
              isSelected={isSelected}
            />
          }
          <div className={`expandable-group__header--title ml-5 ${uppercaseHeaderTitle ? 'uppercase' : ''} ${headerTitleClasses}`}>{title}</div>
        </div>
        <Expander
          isExpanded={isExpanded}
          toggle={toggleExpanded}
          collapseText={expanderText}
          expandText={expanderText}
          classes={'expandable-group__expander'}
        />
      </div>
      {
        isExpanded && items.map((item) => (
          <ExpandableGroupItemComponent
            key={item[identifier]}
            item={item}
            itemsSelectable={itemsSelectable}
            isSelected={isItemSelected(item)}
            selectItem={selectItem}
          />
        ))
      }
    </div>
  );
};

export default React.memo(ExpandableGroup) as typeof ExpandableGroup;
