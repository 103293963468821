import React from 'react';
import { VoucherOrderDetailDto } from '@api/financialServices/models';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import VoucherOrdersQuickViewHeader from './VoucherOrdersQuickViewContent/VoucherOrdersQuickViewHeader';
import VoucherOrdersQuickViewInfo from './VoucherOrdersQuickViewContent/VoucherOrdersQuickViewInfo';

interface OwnProps {
  voucherOrder: Nullable<VoucherOrderDetailDto>;
  openVoucherOrderEditModal: () => void;
  closeDetails: () => void;
}

type Props = OwnProps;

const VoucherOrdersQuickView = React.memo<Props>(({
  voucherOrder,
  openVoucherOrderEditModal,
  closeDetails,
}) => {
  if (!voucherOrder) return null;

  return (
    <TableQuickViewContainer
      dataTest={'voucher-orders-quick'}
    >
      <>
        <VoucherOrdersQuickViewHeader
          voucherOrder={voucherOrder}
          openVoucherOrderEditModal={openVoucherOrderEditModal}
          closeDetails={closeDetails}
        />
        <VoucherOrdersQuickViewInfo voucherOrder={voucherOrder} />
      </>
    </TableQuickViewContainer>
  );
});

export default VoucherOrdersQuickView;
