import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { PriorityQueueDto } from '@api/fulfillment/models';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import { PriorityDetailsModalTaskDetails } from '@models/PrintRoom/PriorityDetailsModalTaskDetails';
import { lookupPrintSheet } from '@redux/printRoomArt/actions';
import * as printFilesActions from '@redux/printFiles/actions';
import * as productCatalogActions from '@redux/productCatalog/actions';
import { RootState } from '@redux/index/reducers';
import LogoPreviewModal from '@sharedComponents/Modal/LogoPreviewModal';
import ClaimedByFilter from './PrintRoomFilters/ClaimedByFilter';
import ExpressProductionFilter from './PrintRoomFilters/ExpressProductionFilter';
import PrintRoomTable from './PrintRoomTable';
import PriorityDetailsModal from './Modals/PriorityDetailsModal/PriorityDetailsModal';

const mapStateToProps = ({
  printFiles,
  productCatalog,
  tableManager,
}: RootState) => ({
  printSheetUsers: printFiles.printSheetUsers as any[],
  colorsDictionary: productCatalog.colorsDictionary,
  initialSelectedTab: tableManager.printRoom.selectedTab,
  initialClaimedByFilter: tableManager.printRoom.claimedByFilter,
  initialExpressProductionFilter: tableManager.printRoom.expressProductionFilter,
});

const mapDispatchToProps = {
  fetchPrintingUsers: printFilesActions.fetchPrintingUsers,
  getLogoCounts: printFilesActions.getLogoCounts,
  getColors: productCatalogActions.getColors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

const PrintRoom = React.memo<Props>(({
  getColors,
  getLogoCounts,
  fetchPrintingUsers,
  printSheetUsers,
  colorsDictionary,
  initialSelectedTab,
  initialClaimedByFilter,
  initialExpressProductionFilter,
}) => {
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<(typeof printStatusEnum)[keyof typeof printStatusEnum]>(initialSelectedTab);
  const [
    claimedByFilter,
    setClaimedByFilter,
  ] = useState<string>(initialClaimedByFilter);
  const [
    expressProductionFilter,
    setExpressProductionFilter,
  ] = useState<Nullable<boolean>>(initialExpressProductionFilter);

  const [
    priorityLogoModalIsOpen,
    setPriorityLogoModalIsOpen,
  ] = useState<boolean>(false);
  const [
    priorityLogoImage,
    setPriorityLogoImage,
  ] = useState<Nullable<string>>(null);
  const [
    priorityDetailsModal,
    setPriorityDetailsModal,
  ] = useState<{
    isOpen: boolean;
    task: PriorityDetailsModalTaskDetails;
  }>({
    isOpen: false,
    task: {
      previewUrl: null,
      orderNumber: null,
      quantity: null,
      type: null,
      shipDate: null,
      barcode: null,
      reasonForPriority: null,
      user: null,
      note: null,
      attachment: null,
      dateCreated: null,
    },
  });

  useEffect(() => {
    getColors();
    getLogoCounts();
  }, [
    getColors,
    getLogoCounts,
  ]);

  const filterKey = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput: string = e.target.value;
    lookupPrintSheet(searchInput);
  }, []);

  const resetFilters = useCallback(() => {
    setClaimedByFilter('');
    setExpressProductionFilter(null);
  }, []);

  const selectTab = useCallback(async (newSelectedTab: (typeof printStatusEnum)[keyof typeof printStatusEnum]) => {
    setSelectedTab(newSelectedTab);
    resetFilters();

    if (newSelectedTab === printStatusEnum.Printing) {
      fetchPrintingUsers();
    }
  }, [
    fetchPrintingUsers,
    resetFilters,
  ]);

  const claimedByFilterAction = useCallback((value: string) => {
    setClaimedByFilter(value);
  }, []);

  const expressProductionFilterAction = useCallback((value: boolean | null) => {
    setExpressProductionFilter(value);
  }, []);

  const openPriorityLogoModal = useCallback((imgUrl: string) => {
    setPriorityLogoImage(imgUrl);
    setPriorityLogoModalIsOpen(true);
  }, []);

  const closePriorityLogoModal = useCallback(() => {
    setPriorityLogoImage(null);
    setPriorityLogoModalIsOpen(false);
  }, []);

  const openPriorityDetailsModal = useCallback((task: PriorityQueueDto) => {
    const {
      orderNumber,
      shipDate,
      barcode,
      quantity,
      previewUrl,
      flag,
    } = task;

    setPriorityDetailsModal({
      isOpen: true,
      task: {
        previewUrl: previewUrl ?? null,
        orderNumber: orderNumber ?? null,
        quantity: quantity ?? null,
        type: 'Print',
        shipDate: shipDate ?? null,
        barcode: barcode ?? null,
        reasonForPriority: flag?.reason ?? null,
        user: flag?.user ?? null,
        note: flag?.note ?? null,
        attachment: flag?.attachmentUrl ?? null,
        dateCreated: flag?.dateCreated ?? null,
      },
    });
  }, []);

  const closePriorityDetailsModal = useCallback(() => {
    setPriorityDetailsModal({
      isOpen: false,
      task: {
        previewUrl: null,
        orderNumber: null,
        quantity: null,
        type: null,
        shipDate: null,
        barcode: null,
        reasonForPriority: null,
        user: null,
        note: null,
        attachment: null,
        dateCreated: null,
      },
    });
  }, []);

  return (
    <div className='container'>
      <LogoPreviewModal
        modalIsOpen={priorityLogoModalIsOpen}
        imageUrl={priorityLogoImage ?? undefined}
        closeModal={closePriorityLogoModal}
      />
      <PriorityDetailsModal
        task={priorityDetailsModal.task}
        closeModal={closePriorityDetailsModal}
        isOpen={priorityDetailsModal.isOpen}
      />
      <div className='sheet'>
        <h1>DEPRECATED, DO NOT USE!</h1>
        <div className='print__search'>
          <div className='print__info-filter'>
            {
              selectedTab === printStatusEnum.Printing &&
              <ClaimedByFilter
                filter={claimedByFilterAction}
                users={printSheetUsers}
                defaultValue={initialClaimedByFilter}
              />
            }
          </div>
          <div className='print__info-filter'>
            {
              selectedTab === printStatusEnum.PrintReady &&
              <ExpressProductionFilter
                filter={expressProductionFilterAction}
                defaultValue={initialExpressProductionFilter}
              />
            }
          </div>
          <div className='margin-left'>
            <div className='text-field'>
              <input
                type='text'
                placeholder='Find print sheet'
                className='has-icon--right'
                onKeyDown={filterKey}
              />
              <i className='material-icons text-field__icon--right'>arrow_forward</i>
            </div>
          </div>
        </div>
        <PrintRoomTable
          selectTab={selectTab}
          selectedTab={selectedTab}
          claimedByFilter={claimedByFilter}
          expressProductionFilter={expressProductionFilter}
          openPriorityLogoModal={openPriorityLogoModal}
          openPriorityDetailsModal={openPriorityDetailsModal}
          colorsDictionary={colorsDictionary}
        />
      </div>
    </div>
  );
});

export default connector(withRouter(PrintRoom));
