import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parseDateTimeNumeric } from '@util/dateHandler';
import PrintSheetHeaderSteps from './PrintSheetHeaderSteps';

const PrintSheetWithStatusHeader = React.memo(({ sheet }) => {
  if (sheet) {
    return (
      <div className='header__bar-content'>
        <div className='header__bar-content-block'>
          <h2 className='header__bar-title'>
            Sheet S{sheet.id}
          </h2>
        </div>
        {
          sheet.claimedBy || sheet.claimedOn
            ? (
              <div className='header__bar-content-block'>
                <p>Printed By: {sheet.claimedBy}</p>
                <p>Printed On: {parseDateTimeNumeric(sheet.claimedOn)}</p>
              </div>
            )
            : null
        }
        <PrintSheetHeaderSteps steps={sheet} />
      </div>
    );
  }

  return <div />;
});

PrintSheetWithStatusHeader.propTypes = {
  sheet: PropTypes.shape({
    id: PropTypes.number,
    claimedBy: PropTypes.string,
    claimedOn: PropTypes.string,
  }),
};

const mapStateToProps = ({ printRoomArt }) => ({
  sheet: printRoomArt.sheet,
});

export default connect(mapStateToProps)(PrintSheetWithStatusHeader);
