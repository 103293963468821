import { OrderStatusEnum } from '@api/fulfillment/models';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';

export const taskEnumToStatus = (enumStatus: string): string => {
  const lowerEnum = enumStatus.toLowerCase();
  switch (lowerEnum) {
    case 'assigned':
      return 'in_progress';
    case 'inqc':
      return 'qc';
    case 'completed':
      return 'production';
    default:
      return lowerEnum;
  }
};

export const taskStatusToEnum = (status: string): string => {
  const lowerStatus = status.toLowerCase();
  switch (lowerStatus) {
    case 'in_progress':
      return 'assigned';
    case 'qc':
      return 'inqc';
    case 'production':
      return 'completed';
    default:
      return lowerStatus;
  }
};

export const orderItemStatusToString = (
  status: (typeof orderItemStatusEnum)[keyof typeof orderItemStatusEnum]
): string => {
  switch (status) {
    case orderItemStatusEnum.Initial:
      return 'Initial';
    case orderItemStatusEnum.OnHold:
      return 'OnHold';
    case orderItemStatusEnum.AwaitingDispatchForPicking:
      return 'Awaiting Dispatch For Picking';
    case orderItemStatusEnum.InPicking:
      return 'In Picking';
    case orderItemStatusEnum.CheckedIn:
      return 'Checked In';
    case orderItemStatusEnum.PreTreated:
      return 'Pre Treated';
    case orderItemStatusEnum.Assembled:
      return 'Assembled';
    case orderItemStatusEnum.Decorated:
      return 'Decorated';
    case orderItemStatusEnum.ProductionCompleted:
      return 'Production Completed';
    case orderItemStatusEnum.Waterproofed:
      return 'Waterproofed';
    case orderItemStatusEnum.QualityChecked:
      return 'Quality Checked';
    case orderItemStatusEnum.Shipped:
      return 'Shipped';
    default:
      return '';
  }
};

export const orderStatusToString = (
  status: (typeof OrderStatusEnum)[keyof typeof OrderStatusEnum]
): string => {
  switch (status) {
    case OrderStatusEnum.Initial:
      return 'Initial';
    case OrderStatusEnum.ArtworkTasksProcessing:
      return 'Artwork Tasks Processing';
    case OrderStatusEnum.ArtworkTasksCompleted:
      return 'Artwork Tasks Completed';
    case OrderStatusEnum.OnHold:
      return 'OnHold';
    case OrderStatusEnum.AwaitingDispatchForPicking:
      return 'Awaiting Dispatch For Picking';
    case OrderStatusEnum.PartialyInPicking:
      return 'Partialy in picking';
    case OrderStatusEnum.InPicking:
      return 'In Picking';
    case OrderStatusEnum.Picked:
      return 'Picked';
    case OrderStatusEnum.Assembled:
      return 'Assembled';
    case OrderStatusEnum.ProductionCompleted:
      return 'Production Completed';
    case OrderStatusEnum.ShippingReady:
      return 'Shipping Ready';
    case OrderStatusEnum.Shipped:
      return 'Shipped';
    default:
      return '';
  }
};
