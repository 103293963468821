import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import { materialSwal } from '@util/componentHelper';
import { getDtgStations } from '@redux/support/actions';
import {
  addDtgStation,
  editDtgStation,
  deleteDtgStation,
} from '@APICalls/dtg/actions';
import DtgStationsEditModal from './DtgStationsModals/DtgStationsEditModal';
import DtgStationsAddModal from './DtgStationsModals/DtgStationsAddModal';
import DtgStationsDeleteModal from './DtgStationsModals/DtgStationsDeleteModal';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

const DtgStationsTable = Table();

class DtgStations extends PureComponent {
  state = {
    editDtgStationModalIsOpen: false,
    addDtgStationModalIsOpen: false,
    deleteDtgStationModalIsOpen: false,
    selectedStation: { },
  };

  componentDidMount() {
    this.fetchDtgStations();
  }

  onAddStation = async (data) => {
    const result = await addDtgStation(data);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgStations();
    }
  };

  onEditStation = async (data) => {
    const { id } = this.state.selectedStation;

    if (!id) {
      materialSwal('Error', 'ID is empty', 'error');

      return;
    }

    const result = await editDtgStation(data);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgStations();
    }
  };

  onDeleteStation = async (id) => {
    const result = await deleteDtgStation(id);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgStations();
    }
  };

  fetchDtgStations = () => {
    const { dispatch } = this.props;
    dispatch(getDtgStations());
  };

  closeModal = () => {
    this.setState(() => ({
      editDtgStationModalIsOpen: false,
      addDtgStationModalIsOpen: false,
      deleteDtgStationModalIsOpen: false,
      selectedStation: { },
    }));
  };

  openEditModal = (selectedStation) => {
    this.setState(() => ({
      editDtgStationModalIsOpen: true,
      selectedStation,
    }));
  };

  openAddModal = () => {
    this.setState(() => ({
      addDtgStationModalIsOpen: true,
      selectedStation: { },
    }));
  };

  openDeleteModal = (selectedStation) => {
    this.setState(() => ({
      deleteDtgStationModalIsOpen: true,
      selectedStation,
    }));
  };

  getColumns = () => [
    {
      Header: 'Station Identifier',
      accessor: 'stationIdentifier',
      minWidth: 70,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Station Display Name',
      accessor: 'stationDisplayName',
      minWidth: 70,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Actions',
      accessor: '',
      resizable: false,
      minWidth: 40,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <ShowMoreActionsButton
          actions={[
            {
              text: 'Edit',
              action: this.openEditModal.bind(null, cellProps.value),
              isVisible: true,
            },
            {
              text: 'Delete',
              action: this.openDeleteModal.bind(null, cellProps.value),
              isDangerous: true,
              isVisible: true,
            },
          ]}
        />
      ),
    },
  ];

  render() {
    const { dtgStations } = this.props;
    const {
      editDtgStationModalIsOpen,
      addDtgStationModalIsOpen,
      deleteDtgStationModalIsOpen,
      selectedStation,
    } = this.state;

    return (
      <div className='container'>
        <DtgStationsAddModal
          isOpen={addDtgStationModalIsOpen}
          closeModal={this.closeModal}
          onSubmit={this.onAddStation}
        />
        <DtgStationsEditModal
          isOpen={editDtgStationModalIsOpen}
          closeModal={this.closeModal}
          onSubmit={this.onEditStation}
          initialValues={selectedStation}
        />
        <DtgStationsDeleteModal
          isOpen={deleteDtgStationModalIsOpen}
          closeModal={this.closeModal}
          selectedStation={selectedStation}
          onDelete={this.onDeleteStation}
        />
        <div className='table-options w-100'>
          <div className='flex' />
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add New Setting'}
              onClick={this.openAddModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>
        <br />
        <div className='sheet'>
          <DtgStationsTable
            data={dtgStations}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

DtgStations.propTypes = {
  dtgStations: PropTypes.array.isRequired,
};

const mapStateToProps = ({ support }) => ({
  dtgStations: support.dtgStations,
});

export default connect(mapStateToProps)(DtgStations);
