import React from 'react';
import { logoReviewUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const LogoReviewLink = React.memo(() => (
  <MenuTile
    title={'Logo Review'}
    url={logoReviewUrl}
    icon={'eye'}
    isMaterialIcon={false}
  />
));

export default LogoReviewLink;
