import React, {
  useCallback,
  useMemo,
} from 'react';
import { QueryObserverResult } from 'react-query';
import {
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkcheckedin as
  useUnmarkOrderItemCheckedIn,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin as
  useMarkOrderItemCheckedIn,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkpretreated as
  useMarkOrderItemPreTreated,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkpretreated as
  useUnmarkOrderItemPreTreated,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkqualitychecked as
  useMarkOrderItemQualityChecked,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkqualitychecked as
  useUnmarkOrderItemQualityChecked,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkwaterproofed as
  useMarkOrderItemWaterproofed,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkwaterproofed as
  useUnmarkOrderItemWaterproofed,
} from '@api/fulfillment/production-assembly-new';
import {
  OrderItemStatusEnum,
  ProductionAssemblyItemGroupDto,
  ProductionAssemblyOrderHeaderDto,
} from '@api/fulfillment/models';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import { DecorationMethodEnum } from '@api/productCatalog/models';
import {
  checkInNotCheckedStatuses,
  checkInEnabledStatuses,
  pretreatCheckedStatuses,
  pretreatEnabledStatuses,
  waterproofedCheckedStatuses,
  waterproofedEnabledStatuses,
} from '@constants/production';
import Button from '@sharedComponents/Buttons/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  isAssemblyDisabled?: boolean;
  orderNumber: number;
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
  openAddBarcodesModal: (checkInButtonIsClicked: boolean) => void;
  openBackorderedModal: () => void;
}

type Props = OwnProps;

const ReviewItemActions = React.memo<Props>(({
  item,
  isAssemblyDisabled,
  orderNumber,
  refreshOrderItemGroups,
  refreshOrder,
  openAddBarcodesModal,
  openBackorderedModal,
}) => {
  const {
    lineLevel1, hfBarcodeNotRequiredForDtg,
  } = useFlags();

  const { mutateAsync: markOrderItemCheckedIn }
    = useMarkOrderItemCheckedIn();

  const { mutateAsync: unmarkOrderItemCheckedIn }
    = useUnmarkOrderItemCheckedIn();

  const { mutateAsync: markOrderItemPreTreated }
    = useMarkOrderItemPreTreated();

  const { mutateAsync: unmarkOrderItemPreTreated }
    = useUnmarkOrderItemPreTreated();

  const { mutateAsync: markOrderItemWaterproofed }
    = useMarkOrderItemWaterproofed();

  const { mutateAsync: unMarkOrderItemWaterproofed }
    = useUnmarkOrderItemWaterproofed();

  const { mutateAsync: markOrderItemQualityChecked }
    = useMarkOrderItemQualityChecked();

  const { mutateAsync: unmarkOrderItemQualityChecked }
    = useUnmarkOrderItemQualityChecked();

  const qualityCheckEnabled = useMemo(() => {
    if (item.status?.status === OrderItemStatusEnum.QualityChecked) {
      return true;
    }

    let canQCItem = false;
    if (item.decorationMethod === DecorationMethodEnum.Undefined) {
      canQCItem = item.status?.status === OrderItemStatusEnum.Assembled
      || item.status?.status === OrderItemStatusEnum.ProductionCompleted;
    } else if (item.waterproofStepRequired) {
      canQCItem = item.status?.status === OrderItemStatusEnum.Waterproofed;
    } else {
      canQCItem = item.status?.status === OrderItemStatusEnum.ProductionCompleted;
    }

    return canQCItem && !isAssemblyDisabled;
  }, [
    item,
    isAssemblyDisabled,
  ]);

  const selectCheckedInButton = useCallback(async () => {
    const itemStatus = item.status?.status;
    const model = {
      orderNumber,
      orderItemIds: item.orderItemIds!,
    };

    if (itemStatus === orderItemStatusEnum.InPicking
      || itemStatus === orderItemStatusEnum.Picked) {
      let hasBarcodes = true;
      for (const orderItemId of item.orderItemIds!) {
        const barcode: string | undefined = item.orderItemIdsWithBarcode![orderItemId];

        if (!barcode) {
          hasBarcodes = false;
          break;
        }
      }

      const isDtg = item.decorationMethod === DecorationMethodEnum.DTG;

      if (!hasBarcodes && (
        (!lineLevel1 && isDtg && !hfBarcodeNotRequiredForDtg)
          || (lineLevel1 && isDtg && !hfBarcodeNotRequiredForDtg)
          || (lineLevel1 && !isDtg))) {
        openAddBarcodesModal(true);
      } else {
        await markOrderItemCheckedIn({
          orderNumber,
          data: model,
        });

        refreshOrderItemGroups();
        refreshOrder();
      }
    } else if (itemStatus === orderItemStatusEnum.CheckedIn || itemStatus === orderItemStatusEnum.Assembled) {
      await unmarkOrderItemCheckedIn({
        orderNumber,
        data: model,
      });

      refreshOrderItemGroups();
      refreshOrder();
    }
  }, [
    refreshOrderItemGroups,
    refreshOrder,
    orderNumber,
    item,
    markOrderItemCheckedIn,
    unmarkOrderItemCheckedIn,
    openAddBarcodesModal,
    lineLevel1,
    hfBarcodeNotRequiredForDtg,
  ]);

  const selectPreTreatedButton = useCallback(async () => {
    const itemStatus = item.status?.status;
    const model = {
      orderNumber,
      orderItemIds: item.orderItemIds!,
    };

    try {
      if (itemStatus === orderItemStatusEnum.CheckedIn || itemStatus === orderItemStatusEnum.Assembled) {
        await markOrderItemPreTreated({
          orderNumber,
          data: model,
        });
      } else if (itemStatus === orderItemStatusEnum.PreTreated) {
        await unmarkOrderItemPreTreated({
          orderNumber,
          data: model,
        });
      }
    } catch (err) { }

    refreshOrderItemGroups();
    refreshOrder();
  }, [
    item,
    orderNumber,
    refreshOrderItemGroups,
    refreshOrder,
    markOrderItemPreTreated,
    unmarkOrderItemPreTreated,
  ]);

  const selectWaterproofCheckedButton = useCallback(async () => {
    const itemStatus = item.status?.status;
    const model = {
      orderItemIds: item.orderItemIds!,
      orderNumber,
    };

    try {
      if (itemStatus === orderItemStatusEnum.ProductionCompleted) {
        await markOrderItemWaterproofed({
          orderNumber,
          data: model,
        });
        refreshOrderItemGroups();
        refreshOrder();
      } else if (itemStatus === orderItemStatusEnum.Waterproofed) {
        await unMarkOrderItemWaterproofed({
          orderNumber,
          data: model,
        });
        refreshOrderItemGroups();
        refreshOrder();
      }
    } catch (err) {}
  }, [
    item,
    orderNumber,
    refreshOrderItemGroups,
    refreshOrder,
    markOrderItemWaterproofed,
    unMarkOrderItemWaterproofed,
  ]);

  const selectQualityCheckedButton = useCallback(async () => {
    const model = {
      orderNumber,
      orderItemIds: item.orderItemIds!,
    };

    if (qualityCheckEnabled && item.status?.status !== OrderItemStatusEnum.QualityChecked) {
      await markOrderItemQualityChecked({
        orderNumber,
        data: model,
      });
      refreshOrderItemGroups();
      refreshOrder();
    } else if (item.status?.status === OrderItemStatusEnum.QualityChecked) {
      await unmarkOrderItemQualityChecked({
        orderNumber,
        data: model,
      });
      refreshOrderItemGroups();
      refreshOrder();
    }
  }, [
    refreshOrderItemGroups,
    refreshOrder,
    orderNumber,
    item,
    markOrderItemQualityChecked,
    unmarkOrderItemQualityChecked,
    qualityCheckEnabled,
  ]);

  if (!item) return null;

  const itemStatus = item.status?.status;

  const checkInChecked = !checkInNotCheckedStatuses.includes(itemStatus as typeof checkInNotCheckedStatuses[number]);

  const checkInDisabled = isAssemblyDisabled
    || !checkInEnabledStatuses.includes(itemStatus as typeof checkInEnabledStatuses[number]);

  const pretreatChecked = checkInChecked
    && !pretreatCheckedStatuses.includes(itemStatus as typeof pretreatCheckedStatuses[number]);

  const pretreatDisabled = isAssemblyDisabled
    || !pretreatEnabledStatuses.includes(itemStatus as typeof pretreatEnabledStatuses[number]);

  const waterproofedChecked =
    waterproofedCheckedStatuses.includes(itemStatus as typeof waterproofedCheckedStatuses[number]);

  const waterproofedDisabled = isAssemblyDisabled
    || !waterproofedEnabledStatuses.includes(itemStatus as typeof waterproofedEnabledStatuses[number]);

  const qualityCheckChecked = item.status?.status === OrderItemStatusEnum.QualityChecked;

  const returnOnHoldEnabled = !!itemStatus && [
    OrderItemStatusEnum.AwaitingDispatchForPicking,
    OrderItemStatusEnum.InPicking,
    OrderItemStatusEnum.Picked,
    OrderItemStatusEnum.CheckedIn,
    OrderItemStatusEnum.Assembled,
    OrderItemStatusEnum.PreTreated,
    OrderItemStatusEnum.ProductionCompleted,
    OrderItemStatusEnum.Waterproofed,
    OrderItemStatusEnum.QualityChecked,
  ].includes(itemStatus);

  return (
    <div className='actions actions--fixed-min-width'>
      <MaterialTooltip
        tooltipText={'Put on hold for a backorder'}
        placement={'right'}
      >
        <Button
          type={'danger'}
          disabled={!returnOnHoldEnabled}
          text={'Backordered'}
          onClick={openBackorderedModal}
        />
      </MaterialTooltip>
      <MaterialTooltip
        tooltipText={itemStatus === orderItemStatusEnum.InPicking
          || itemStatus === orderItemStatusEnum.Initial
          ? '' : 'Check In'
        }
        placement={'right'}
      >
        <CheckButton
          disabled={checkInDisabled}
          text={'Check In'}
          checked={checkInChecked}
          onClick={selectCheckedInButton}
        />
      </MaterialTooltip>
      {
        item?.decorationMethod === DecorationMethodEnum.DTG &&
        <MaterialTooltip
          tooltipText={
            (itemStatus === orderItemStatusEnum.PreTreated
            || itemStatus !== orderItemStatusEnum.Assembled)
              ? 'Pre-Treated' : ''
          }
          placement={'right'}
        >
          <CheckButton
            disabled={pretreatDisabled}
            text={'Pre-Treated'}
            checked={pretreatChecked}
            onClick={selectPreTreatedButton}
          />
        </MaterialTooltip>
      }
      {
        item.waterproofStepRequired &&
        <MaterialTooltip
          tooltipText={itemStatus !== orderItemStatusEnum.ProductionCompleted ? 'Waterproofed' : ''}
          placement={'right'}
        >
          <CheckButton
            disabled={waterproofedDisabled}
            text={'Waterproofed'}
            checked={waterproofedChecked}
            onClick={selectWaterproofCheckedButton}
          />
        </MaterialTooltip>
      }
      <MaterialTooltip
        tooltipText={item.status?.status === OrderItemStatusEnum.InPicking
          || item.status?.status === OrderItemStatusEnum.Initial
          ? '' : 'Quality Check'
        }
        placement={'right'}
      >
        <CheckButton
          disabled={!qualityCheckEnabled}
          text={'Quality Check'}
          checked={qualityCheckChecked}
          onClick={selectQualityCheckedButton}
        />
      </MaterialTooltip>
    </div>
  );
});

export default ReviewItemActions;
