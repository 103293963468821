import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { scanItemsForm } from '@constants/reduxForms';
import ScanItemsForm from './ScanItemsForm';

const ScanItemsModal = React.memo(({
  isOpen,
  closeModal,
  onSubmit,
}) => (
  <div>
    <Modal
      title={'Scan Items'}
      modalSize={'l'}
      isOpen={isOpen}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={scanItemsForm}
        />
      )}
    >
      <ScanItemsForm
        onSubmit={onSubmit}
      />
    </Modal>
  </div>
));

ScanItemsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScanItemsModal;
