import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const ParentCategoryDeleteModal = React.memo(({
  isOpen,
  closeModal,
  parentCategory,
  deleteParentCategory,
}) => (
  <Modal
    title={'Delete Parent Category'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteParentCategory.bind(null, parentCategory.id)}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to remove parent category <i>{parentCategory.name}</i> from the list?</h3>
  </Modal>
));

ParentCategoryDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  parentCategory: PropTypes.object.isRequired,
  deleteParentCategory: PropTypes.func.isRequired,
};

export default ParentCategoryDeleteModal;
