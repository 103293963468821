import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { keyNameEnum } from '@constants/enums/commonEnums';
import userManager from '@util/userManager';
import Button from '@sharedComponents/Buttons/Button';

class LoginPage extends Component {
  componentDidMount() {
    const inputs = document.getElementById('login-page').getElementsByTagName('input');
    for (const input of inputs) {
      input.addEventListener('keypress', (event) => {
        if (event.key === keyNameEnum.Enter) {
          document.getElementById('login-page').getElementsByTagName('button')[0].click();
        }
      });
    }
  }

  onLoginButtonClick = (e) => {
    e.preventDefault();

    sessionStorage.setItem('userLoading', true);
    userManager.signinRedirect();
  };

  render() {
    return (
      <div id='login-page'>
        <div className='login-container'>
          <header className='mb-10'>
            <h2>Login with Identity</h2>
          </header>
          <div className='button-container'>
            <Button
              type={'primary'}
              text={'Login'}
              onClick={this.onLoginButtonClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  loginUser: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = ({ account }) => ({
  account,
});

export default connect(mapStateToProps)(LoginPage);
