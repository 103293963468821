import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { fetchOrganizationGroupLockers } from '@redux/organizations/actions';
import SelectIcon from '@sharedComponents/Icons/SelectIcon';
import RemoveOrganizationGroupModal from './OrganizationGroupsModals/RemoveOrganizationGroupModal';
import LockerCard from './LockerCard';

class OrganizationGroup extends Component {
  state = {
    removeGroupModalIsOpen: false,
  };

  componentDidMount() {
    const {
      group, dispatch,
    } = this.props;
    dispatch(fetchOrganizationGroupLockers(group.id));
  }

  openRemoveGroupModal = () => {
    this.setState({ removeGroupModalIsOpen: true });
  };

  closeRemoveGroupModal = () => {
    this.setState({ removeGroupModalIsOpen: false });
  };

  lockerGroup = () => {
    const {
      group,
      groupLockers,
    } = this.props;

    return groupLockers[group.id] || [];
  };

  onDragEnd = async (result) => {
    const {
      group,
      reorderGroupedLockers,
    } = this.props;
    const lockerGroup = this.lockerGroup();

    if (!result.destination || !result.source) return;

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    if (lockerGroup && lockerGroup.length > 0) {
      // Take a copy of the group
      const lockers = [...lockerGroup];

      // Remove the concerned locker from its old spot, and insert it in its new spot
      const [removed] = lockers.splice(startIndex, 1);
      lockers.splice(endIndex, 0, removed);
      // Set orderPlace
      lockers.forEach((locker, i) => locker.orderPlace = i + 1);

      // Save
      reorderGroupedLockers(group, lockers);
    }
  };

  render() {
    const {
      group,
      setAsFeatured,
      deleteGroup,
      openEditGroupModal,
    } = this.props;

    const { removeGroupModalIsOpen } = this.state;

    const lockers = this.lockerGroup();

    return (
      <div className='mb-40'>
        <RemoveOrganizationGroupModal
          isOpen={removeGroupModalIsOpen}
          closeModal={this.closeRemoveGroupModal}
          deleteGroup={deleteGroup}
          group={group}
        />
        <div className='title-separator'>
          <div
            onClick={setAsFeatured.bind(null, group)}
            className={`organization-group__label mr-10 ${group.featured ? 'featured' : ''}`}
          >
            <SelectIcon isSelected={group.featured} />&nbsp;Featured
          </div>
          <hr />
          <div className='title-separator--text big'>{group.name} ({lockers.length})</div>
          <hr />
          <div
            onClick={this.openRemoveGroupModal}
            className='organization-group__icon--remove ml-10'
          />
          <div
            onClick={openEditGroupModal.bind(null, group, lockers)}
            className='organization-group__icon--edit ml-10'
          />
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable
            droppableId='droppable'
            direction='horizontal'
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                className='organization-group__logos'
                {...provided.droppableProps}
              >
                {
                  lockers.map((locker, index) => (
                    <Draggable
                      key={locker.id}
                      draggableId={`${locker.id}`}
                      index={index}
                      isDragDisabled={!locker}
                    >
                      {
                        (draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <LockerCard
                              key={locker.id}
                              locker={locker}
                            />
                          </div>
                        )
                      }
                    </Draggable>
                  ))
                }
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

OrganizationGroup.propTypes = {
  group: PropTypes.object.isRequired,
  groupLockers: PropTypes.object.isRequired,
  setAsFeatured: PropTypes.func.isRequired,
  openEditGroupModal: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  reorderGroupedLockers: PropTypes.func.isRequired,
};

const mapStateToProps = ({ organizations }) => ({
  groupLockers: organizations.groupLockers,
});

export default connect(mapStateToProps)(OrganizationGroup);
