import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchOrganizationLockers } from '@redux/organizations/actions';
import { organizationLockersSortColumnsEnum } from '@constants/enums/organizationEnums';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import Input from '@sharedComponents/Form/Input';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';
import FormError from '@sharedComponents/Form/FormError';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import LockerRow from '../LockerRow';
import SortColumnTitle from '../SortColumnTitle';

class OrganizationGroupForm extends Component {
  constructor(props) {
    super(props);
    const {
      initialValues,
      change,
    } = this.props;

    this.state = {
      lockers: initialValues ? initialValues.lockers : [],
      searchInput: '',
      sortColumn: '',
      sortDirection: '',
    };

    if (!initialValues) {
      change('featured', false);
      change('lockers', []);
    }

    change('newLockerList', initialValues ? initialValues.lockers : []);
  }

  toggleSelectLocker = (locker) => {
    const { lockers } = this.state;
    const { change } = this.props;

    let newLockerList;
    if (this.isSelected(locker)) {
      newLockerList = lockers.filter((l) => l.id !== locker.id);
    } else {
      newLockerList = [
        ...lockers,
        locker,
      ];
    }

    this.setState({ lockers: newLockerList });
    change('newLockerList', newLockerList);
  };

  isSelected = (locker) => {
    const { lockers } = this.state;

    return lockers.find((l) => l.id === locker.id) !== undefined;
  };

  selectAll = () => {
    const { lockers } = this.state;
    const {
      allLockers,
      change,
    } = this.props;

    let allSelected = true;
    for (const locker of allLockers) {
      if (!this.isSelected(locker)) {
        allSelected = false;
        break;
      }
    }

    if (allSelected) {
      const lockerIdsToUnselect = allLockers.map((l) => l.id);
      const remainingLockers = lockers.filter((l) => !lockerIdsToUnselect.includes(l.id));
      this.setState({ lockers: remainingLockers });
      change('newLockerList', remainingLockers);
    } else {
      const selectedLockerIds = lockers.map((l) => l.id);
      const lockersToSelect = allLockers.filter((l) => !selectedLockerIds.includes(l.id));
      const newLockerList = [
        ...lockers,
        ...lockersToSelect,
      ];
      this.setState({ lockers: newLockerList });
      change('newLockerList', newLockerList);
    }
  };

  onFilterLockers = async (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const {
      organizationId,
      dispatch,
    } = this.props;

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), () => dispatch(fetchOrganizationLockers(organizationId, searchInput)));
  };

  sort = async (selectedSortColumn) => {
    const {
      searchInput,
      sortColumn,
      sortDirection,
    } = this.state;

    const newSortColumn = selectedSortColumn;
    let newSortDirection = sortDirectionShortEnum.Asc;

    if (sortColumn === selectedSortColumn) {
      // Toggle sort direction if needed
      if (sortDirection === sortDirectionShortEnum.Asc) {
        newSortDirection = sortDirectionShortEnum.Desc;
      }
    }

    this.setState({
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    });

    const {
      organizationId,
      dispatch,
    } = this.props;

    await dispatch(fetchOrganizationLockers(organizationId, searchInput, newSortColumn, newSortDirection));
  };

  render() {
    const {
      onSubmit,
      error,
      disabled = {},
      change,
      allLockers,
      formId,
    } = this.props;

    const {
      sortColumn,
      sortDirection,
    } = this.state;

    return (
      <form
        className='redux-form'
        onSubmit={onSubmit}
        id={formId}
      >
        <div>
          <div className='redux-form__section'>
            <div className='redux-form__row mb-20'>
              <div className='w-60'>
                <label className='redux-form__label'>
                  Group Name
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'name'}
                  placeholder={'Enter Group Name'}
                  component={Input}
                  type={'text'}
                  disabled={disabled.name}
                />
              </div>
              <div className='w-40 ml-20 organization-group__checkbox'>
                <Field
                  name={'featured'}
                  text={'Group is featured'}
                  component={ReduxFormCheckbox}
                  disabled={disabled.featured}
                  change={change}
                />
              </div>
            </div>

            {
              allLockers && allLockers.length > 0
                ? (
                  <div>
                    <div className='tableView__details--header--title mb-30'>
                      <span>Stores in Organization</span>
                    </div>
                    <SearchFilter
                      search={this.onFilterLockers}
                      placeholder={'Enter Store ID or Name'}
                      classes={'mb-20'}
                    />
                    <div className='organization__lockers-list__item title'>
                      <div className='organization__lockers-list__item--logo'>Logo</div>
                      <div className='align--center ml-30 w-20'>
                        <SortColumnTitle
                          text={'ID'}
                          columnName={organizationLockersSortColumnsEnum.id}
                          sortColumn={sortColumn}
                          sortDirection={sortDirection}
                          sort={this.sort.bind(null, organizationLockersSortColumnsEnum.id)}
                        />
                      </div>
                      <div className='align--center w-20'>
                        <SortColumnTitle
                          text={'Store Name'}
                          columnName={organizationLockersSortColumnsEnum.teamName}
                          sortColumn={sortColumn}
                          sortDirection={sortDirection}
                          sort={this.sort.bind(null, organizationLockersSortColumnsEnum.teamName)}
                        />
                      </div>
                      <div className='align--right ml-40 w-30'>Include in Group&nbsp;
                        <a
                          className='link'
                          onClick={this.selectAll}
                        >
                          (All)
                        </a>
                      </div>
                    </div>
                    <div className='organization__lockers-list custom-scrollbar'>
                      {
                        allLockers.map((locker) => (
                          <LockerRow
                            key={locker.id}
                            locker={locker}
                            onCheck={this.toggleSelectLocker}
                            checked={this.isSelected(locker)}
                          />
                        ))
                      }
                    </div>
                  </div>
                )
                : <div>No stores available</div>
            }

          </div>

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

OrganizationGroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.object,
  change: PropTypes.func,
  organizationId: PropTypes.number.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    featured: PropTypes.bool.isRequired,
    lockers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        teamName: PropTypes.string.isRequired,
        logoUrl: PropTypes.string,
      })
    ).isRequired,
  }),
  allLockers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      teamName: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
    })
  ).isRequired,
  formId: PropTypes.string,
};

const mapStateToProps = ({ organizations }) => ({
  allLockers: organizations.organizationLockers,
});

export default connect(mapStateToProps)(OrganizationGroupForm);
