import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class RemoveOrganizationGroupModal extends Component {
  deleteOnClick = () => {
    const {
      group,
      deleteGroup,
    } = this.props;

    deleteGroup(group);
  };

  render() {
    const {
      group,
      isOpen,
      closeModal,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title={'Delete locker group'}
        modalSize={'l'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            onConfirm={this.deleteOnClick}
            confirmBtnText={'Delete'}
            isDangerousAction={true}
          />
        )}
      >
        <div className='modal__description'>
          Are you sure you want to delete locker group "<b>{group.name}</b>" from the system?
        </div>
      </Modal>
    );
  }
}

RemoveOrganizationGroupModal.propTypes = {
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RemoveOrganizationGroupModal;
