import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import Modal from '@sharedComponents/Modal/Modal';
import Button from '@sharedComponents/Buttons/Button';
import ReviewedButton from '../ReviewedButton';
import LogoReviewImage from './LogoReviewImage';
import LogoReviewNotes from './LogoReviewNotes';
import RequestTasks from './RequestTasks';
import DecorationMethods from './DecorationMethods';
import LockerLogos from './LockerLogos';
import LogoUsage from './LogoUsage';
import ApprovedColors from './ApprovedColors';

class LogoForReview extends Component {
  state = {
    isModalWithImageOpen: false,
    logoImage: null,
    hoveredColor: null,
  };

  openModalWithImage = (logoImage) => {
    this.setState(() => ({
      isModalWithImageOpen: true,
      logoImage,
    }));
  };

  closeModalWithImage = () => {
    this.setState(() => ({
      isModalWithImageOpen: false,
      logoImage: null,
    }));
  };

  changeHoveredColor = (color) => {
    this.setState(() => ({ hoveredColor: color }));
  };

  render() {
    const {
      isModalWithImageOpen,
      logoImage,
      hoveredColor,
    } = this.state;

    const {
      logo,
      locker,
      note,
      allowedDecorationMethods,
      requestTasks,
      onChangeDecorationMethods,
      onChangeRequestTasks,
      onChangeColors,
      onSelectAllColorsFromList,
      onUnselectAllColorsFromList,
      onNoteUpdate,
      onReviewBtnClick,
      cancelReviewForLogo,
      needsArtworkEdits,
      allLightColors,
      allDarkColors,
      approvedLightColors,
      approvedDarkColors,
    } = this.props;

    return (
      <div className='logo-review__task-container mt-20 mb-20'>
        <Modal
          isOpen={isModalWithImageOpen}
          closeModal={this.closeModalWithImage}
          modalSize={'xl'}
        >
          <div
            className='lockerManagerEdit__image'
            style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logoImage}")` }}
          />
        </Modal>

        <div className='logo-review__task__details'>
          <div className='logo-activity-image logo-review__task__preview'>
            <LogoReviewImage
              logo={logo}
              lockerId={locker.id}
              hoveredColor={hoveredColor}
            />
            <div className='mt-30'>
              <ApprovedColors
                allLightColors={allLightColors}
                allDarkColors={allDarkColors}
                approvedLightColors={approvedLightColors}
                approvedDarkColors={approvedDarkColors}
                onChangeColors={onChangeColors}
                onSelectAllColorsFromList={onSelectAllColorsFromList}
                onUnselectAllColorsFromList={onUnselectAllColorsFromList}
                changeHoveredColor={this.changeHoveredColor}
              />
            </div>
          </div>

          <div className='logo-review__task__options'>
            <div className='mb-20'>
              <LogoUsage logo={logo} />
            </div>
            <div className='mb-20'>
              <DecorationMethods
                allowedDecorationMethods={allowedDecorationMethods}
                onChangeDecorationMethods={onChangeDecorationMethods}
                needsArtworkEdits={needsArtworkEdits}
              />
            </div>
            <div className='mb-20'>
              <RequestTasks
                requestTasks={requestTasks}
                onChangeRequestTasks={onChangeRequestTasks}
              />
            </div>
          </div>
        </div>
        <div className='logo-review__task__footer'>
          <hr className='w-100' />

          <div className='flex mb-10'>
            <div className='w-50'>
              <LockerLogos
                locker={locker}
                openModalWithImage={this.openModalWithImage}
              />
            </div>
            <div className='w-50 pl-20'>
              <LogoReviewNotes
                note={note}
                updateNote={onNoteUpdate}
              />
            </div>
          </div>

          <hr className='w-100' />

          <div className='navigation mt-20 mb-20'>
            <Button
              type={'secondary'}
              text={'Cancel Review of This Logo'}
              onClick={cancelReviewForLogo.bind(null, logo)}
            />
            <ReviewedButton
              reviewed={false}
              onReviewBtnClick={onReviewBtnClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

LogoForReview.propTypes = {
  logo: PropTypes.shape({
    id: PropTypes.number,
    lockerId: PropTypes.number,
    logoUrl: PropTypes.string,
    reviewStartedAt: PropTypes.string,
    ordersCount: PropTypes.number,
  }).isRequired,
  locker: PropTypes.shape({
    id: PropTypes.number,
    teamName: PropTypes.string,
    lockerUrl: PropTypes.string,
    lockerManagerId: PropTypes.number,
    lockerManagerName: PropTypes.string,
    partnerName: PropTypes.string,
    organizationId: PropTypes.number,
    organizationName: PropTypes.string,
    logos: PropTypes.arrayOf(PropTypes.object),
  }),
  note: PropTypes.string,
  allowedDecorationMethods: PropTypes.object.isRequired,
  requestTasks: PropTypes.object.isRequired,
  onChangeDecorationMethods: PropTypes.func.isRequired,
  onChangeRequestTasks: PropTypes.func.isRequired,
  onChangeColors: PropTypes.func.isRequired,
  onSelectAllColorsFromList: PropTypes.func.isRequired,
  onUnselectAllColorsFromList: PropTypes.func.isRequired,
  onNoteUpdate: PropTypes.func.isRequired,
  onReviewBtnClick: PropTypes.func.isRequired,
  cancelReviewForLogo: PropTypes.func.isRequired,
  needsArtworkEdits: PropTypes.bool.isRequired,
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  approvedLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  approvedDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default LogoForReview;
