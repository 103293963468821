import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateLogo } from '@APICalls/logos/actions';
import { rolesEnum } from '@constants/enums/commonEnums';
import {
  selectionTypeEnum,
  decorationMethodEnum,
} from '@constants/enums/decorationEnums';
import { dispatchAfterSubmit } from '@redux/artworkFileTasks/actions';
import { mapToSwatchColorFields } from '@util/mappingHelper';
import { materialSwal } from '@util/componentHelper';
import { filterArtTeamColors } from '@util/colorHelper';
import { roleMapping } from '@util/roleCheck';
import ColorDetectionEditSwatches from '@sharedComponents/ColorDetectionEditSwatches/ColorDetectionEditSwatches';
import Modal from '@sharedComponents/Modal/Modal';
import Icon from '@sharedComponents/Icons/Icon';

class ColorDetectionSwatchesModal extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  updateLogoColors = async (newColors) => {
    const {
      dispatch,
      refreshLogo,
      colors,
      logo,
      task: {
        id, locker: { id: lockerId },
      },
    } = this.props;
    const customColors = [];
    const colorList = [];

    for (const color of newColors) {
      const selectedColor = colors.find((c) => c.code === color);
      if (selectedColor) {
        colorList.push(selectedColor.id);
      } else {
        customColors.push(color);
      }
    }

    const res = await updateLogo(logo.id, selectionTypeEnum.InternalSelection, colorList, customColors);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }
    await dispatch(dispatchAfterSubmit(lockerId, id));
    refreshLogo(logo.id);
    this.closeModal();
  };

  renderEditColorsModal = () => {
    const {
      colors,
      artworkColors,
      logo,
      task: {
        decorationMethod,
        logoUrl,
      },
    } = this.props;
    const { modalIsOpen } = this.state;

    const logoColors = filterArtTeamColors(logo && logo.selectedColors || []).map((c) => c.code);

    return (
      <Modal
        isOpen={modalIsOpen}
        closeModal={this.closeModal}
        title={'Edit Logo'}
        modalHeight={'xl'}
        modalWidth={'l'}
        isPadded={true}
      >
        <ColorDetectionEditSwatches
          cancel={this.closeModal}
          saveLogoColors={this.updateLogoColors}
          decorationMethod={[decorationMethod]}
          logoColors={logoColors}
          logoUrl={logoUrl}
          showLogo={true}
          allowCustomColors={true}
          colors={colors}
          artworkColors={artworkColors}
          allDecorationsActive={false}
          showDecorationCheckbox={false}
        />
      </Modal>
    );
  };

  renderLogoColors = () => {
    const {
      logo,
      colors,
      task: { decorationMethod },
    } = this.props;

    const logoArtTeamColors = filterArtTeamColors(logo && logo.selectedColors || []);
    const logoColors = [];
    logoArtTeamColors.forEach((logoColor) => {
      const color = colors.find((c) => c.code === logoColor.code);
      logoColors.push(color ? color : logoColor);
    });

    if (logoColors.length > 0) {
      return (
        <div className='color-swatches'>
          {mapToSwatchColorFields(logoColors, decorationMethod, false, true)}
        </div>
      );
    }
  };

  render() {
    const {
      roles,
      logo,
    } = this.props;

    const editColorsButton = (!roles.includes(rolesEnum.ProductionArtworkVendor) && logo)
      ? (
        <div className='flex align-end'>
          <button
            className='btn-borderless btn-borderless--green'
            onClick={this.openModal}
          >
            <span>
              <Icon materialIcon={'create'} />
              Edit colors
            </span>
          </button>
        </div>
      )
      : null;

    return (
      <div>
        {this.renderEditColorsModal()}
        {this.renderLogoColors()}
        {editColorsButton}
      </div>
    );
  }
}

ColorDetectionSwatchesModal.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    locker: PropTypes.object.isRequired,
    logoId: PropTypes.number.isRequired,
    decorationMethod: PropTypes.oneOf([
      decorationMethodEnum.HAG,
      decorationMethodEnum.EMB,
      decorationMethodEnum.DTG,
      decorationMethodEnum.DIP,
    ]),
    logoUrl: PropTypes.string,
  }).isRequired,
  roles: PropTypes.array.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  artworkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  logo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    selectedColors: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      selectionType: PropTypes.number,
      id: PropTypes.number,
      hexValue: PropTypes.string,
    })),
    artworkApproved: PropTypes.bool.isRequired,
    decorationMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
    deletedAt: PropTypes.string,
  }),
  refreshLogo: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  oidc,
  productCatalog,
}) => ({
  roles: roleMapping(oidc),
  colors: productCatalog.colors,
  artworkColors: productCatalog.artworkColors,
});

export default connect(mapStateToProps)(ColorDetectionSwatchesModal);
