export const validateDecorationLocation = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = ['Code is required.'];
  }

  if (!values.label) {
    errors.label = ['Label is required.'];
  }

  return errors;
};
