import React from 'react';
import iconNeedle from '@assets/icon_needle.svg';
import { productionAssemblyEmbUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from '@components/MyApps/MenuTile';

const ProductionAssemblyEmbLink = React.memo(() => (
  <MenuTile
    title={'EMB'}
    url={productionAssemblyEmbUrl}
    icon={iconNeedle}
    isCustomIcon={true}
  />
));

export default ProductionAssemblyEmbLink;
