import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  text?: string;
  classes?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dataTest?: string;
  disabled?: boolean;
  tooltipText?: string;
}

type Props = OwnProps;

const EditButton = React.memo<Props>(({
  text = 'Edit',
  classes = '',
  onClick,
  dataTest = undefined,
  disabled = false,
  tooltipText,
}) => (
  tooltipText
    ? (
      <MaterialTooltip
        tooltipText={tooltipText}
        placement='bottom'
      >
        <button
          className={`lockerManagerEdit__details-header--edit edit-btn ${classes} ${disabled ? 'disabled' : ''}`}
          onClick={onClick}
        >
          {
            dataTest
              ? (
                <Icon
                  materialIcon={'edit'}
                  dataTest={dataTest}
                />
              )
              : (
                <Icon materialIcon={'edit'} />
              )
          }
          <span>{text}</span>
        </button>
      </MaterialTooltip>
    )
    : (
      <button
        className={`lockerManagerEdit__details-header--edit edit-btn ${classes}`}
        onClick={onClick}
        disabled={disabled}
      >
        {
          dataTest
            ? (
              <Icon
                materialIcon={'edit'}
                dataTest={dataTest}
              />
            )
            : (
              <Icon materialIcon={'edit'} />
            )
        }
        <span>{text}</span>
      </button>
    )
));

export default EditButton;
