import React from 'react';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { WrappedFieldProps } from 'redux-form';

interface OwnProps {
  text?: string;
  disabled?: boolean;
  className?: string;
  change: (name: string, hasValue: boolean) => void;
  tooltipText?: string;
  showLockIcon?: boolean;
}

type Props = OwnProps & WrappedFieldProps;

const ReduxFormCheckbox = (props: Props) => {
  const {
    text = '',
    disabled = false,
    className = '',
    meta: {
      touched = false,
      error = '',
    },
    change,
    tooltipText = '',
    showLockIcon = false,
  } = props;

  const inputProps = props.input || {};
  const hasError = touched && error && (error.length > 0);

  const checkbox = (
    <div>
      <MaterialCheckbox
        text={text}
        onClick={change.bind(null, inputProps.name, !inputProps.value)}
        checked={inputProps.value}
        disabled={disabled}
        showLockIcon={showLockIcon}
        classes={`redux-form__checkbox ${className}`}
      />
      {
        hasError &&
        <div className='redux-form__error--field'>
          {error.map((e: string, index: number) => <div key={index}>{e}</div>)}
        </div>
      }
    </div>
  );

  if (tooltipText) {
    return (
      <MaterialTooltip
        tooltipText={tooltipText}
        placement={'top'}
      >
        {checkbox}
      </MaterialTooltip>
    );
  }

  return checkbox;
};

export default ReduxFormCheckbox;
