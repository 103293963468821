import { rolesEnum } from '@constants/enums/commonEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';

const devPermissions = Object.values(PermissionsEnum);

const rules = {
  [rolesEnum.Developer]: devPermissions,
  [rolesEnum.SLMerchandisingRep]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.LookupEntitiesVisit,
    PermissionsEnum.LookupEntitiesColorsVisit,
    PermissionsEnum.LookupEntitiesDecorationLocationArtworkSizesVisit,
    PermissionsEnum.LookupEntitiesDecorationLocationsVisit,
    PermissionsEnum.LookupEntitiesVendorsVisit,
    PermissionsEnum.SkuSetupVisit,
    PermissionsEnum.MerchandisingEffortsOptIn,
    PermissionsEnum.ProductCatalogEdit,
    PermissionsEnum.ProductCatalogRestrictedEdit,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.ProductCatalogSyncDataVisit,
    PermissionsEnum.BulkActionsVisit,
    PermissionsEnum.BulkAddItemVisit,
    PermissionsEnum.MerchandisingEdit,
    PermissionsEnum.MerchandisingVisit,
    PermissionsEnum.MerchandisingOptInOut,
    PermissionsEnum.ReportsVisit,
  ],
  [rolesEnum.SLMerchandisingLite]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductCatalogRestrictedEdit,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.MerchandisingEffortsOptIn,
    PermissionsEnum.ReportsVisit,
  ],
  [rolesEnum.SLCustomerExperience]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.BackorderManagementVisit,
    PermissionsEnum.OrderManagementVisit,
    PermissionsEnum.OrderManagementEdit,
    PermissionsEnum.OnHandInventoryVisit,
    PermissionsEnum.CouponsVisit,
    PermissionsEnum.FundraisingPayoutsVisit,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.VendorOrdersVisit,
    PermissionsEnum.MerchandisingOptInOut,
    PermissionsEnum.ReportsVisit,
  ],
  [rolesEnum.SLLockerManager]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.FundraisingPayoutsVisit,
    PermissionsEnum.AdminsVisit,
    PermissionsEnum.OrganizationsVisit,
    PermissionsEnum.CouponsVisit,
    PermissionsEnum.OnHandInventoryVisit,
    PermissionsEnum.LockerManagerVisit,
    PermissionsEnum.LockerManagerEditCustomItems,
    PermissionsEnum.LockerManagerEditPartner,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.ReportsVisit,
    PermissionsEnum.RosterManagerVisit,
    PermissionsEnum.VoucherOrdersVisit,
  ],
  [rolesEnum.SLLockerManagerExternal]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.LockerManagerVisit,
    PermissionsEnum.OrganizationsVisit,
    PermissionsEnum.AdminsVisit,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.RosterManagerVisit,
  ],
  [rolesEnum.SLSalesManager]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.OrganizationsVisit,
    PermissionsEnum.LockerManagerVisit,
    PermissionsEnum.LockerManagerEditShipping,
    PermissionsEnum.LockerManagerEditDiscount,
    PermissionsEnum.LockerManagerEditCustomItems,
    PermissionsEnum.LockerManagerEditPartner,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.ReportsVisit,
    PermissionsEnum.RosterManagerVisit,
  ],
  [rolesEnum.SLProductionScheduler]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.SchedulingVisit,
    PermissionsEnum.OrderManagementVisit,
    PermissionsEnum.OrderManagementEdit,
    PermissionsEnum.OrderManagementEditRushOrder,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.OrderItemStatusEdit,
    PermissionsEnum.ProductionOverviewVisit,
    PermissionsEnum.ProductionVisit,
    PermissionsEnum.ReportsVisit,
  ],
  [rolesEnum.SLOrderLookup]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.SchedulingVisit,
    PermissionsEnum.ProductionOverviewVisit,
    PermissionsEnum.ProductionVisit,
    PermissionsEnum.OrderManagementVisit,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.ReportsVisit,
    PermissionsEnum.VendorOrdersVisit,
  ],
  [rolesEnum.SLOrderAssembly]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductionVisit,
    PermissionsEnum.OrderAssemblyVisit,
    PermissionsEnum.ProductCatalogVisit,
  ],
  [rolesEnum.SLOrderProduction]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductionVisit,
    PermissionsEnum.ProductionOverviewVisit,
    PermissionsEnum.OrderAssemblyVisit,
    PermissionsEnum.QualityCheckVisit,
    PermissionsEnum.ProductionAssemblyHagVisit,
    PermissionsEnum.ProductionAssemblyEmbVisit,
    PermissionsEnum.ProductionAssemblyDtgVisit,
    PermissionsEnum.ProductCatalogVisit,
  ],
  [rolesEnum.SLQualityControl]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductionVisit,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.QualityCheckVisit,
  ],
  [rolesEnum.SlProductionAssemblyHagOperator]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductionVisit,
    PermissionsEnum.ProductionAssemblyHagVisit,
  ],
  [rolesEnum.SlProductionAssemblyEmbOperator]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductionVisit,
    PermissionsEnum.ProductionAssemblyEmbVisit,
  ],
  [rolesEnum.SlProductionAssemblyDtgOperator]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductionVisit,
    PermissionsEnum.ProductionAssemblyDtgVisit,
  ],
  [rolesEnum.SLPrintRoom]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.PrintRoomVisit,
    PermissionsEnum.ProductCatalogVisit,
  ],
  [rolesEnum.SlProductionArt]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductionArtworkVisit,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.ReportsVisit,
  ],
  [rolesEnum.ProductionArtworkVendor]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.ProductionArtworkVisit,
    PermissionsEnum.ProductCatalogVisit,
  ],
  [rolesEnum.SlGraphicServices]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.LogoReviewVisit,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.ReportsVisit,
  ],
  [rolesEnum.SLAccountingAccess]: [
    PermissionsEnum.AnyUserVisit,
    PermissionsEnum.OrderManagementVisit,
    PermissionsEnum.OrderManagementSubmitOrder,
    PermissionsEnum.OrderManagementEdit,
    PermissionsEnum.OrderManagementEditCancelOrder,
    PermissionsEnum.OrderManagementEditRefundOrder,
    PermissionsEnum.OrderManagementEditChargeOrder,
    PermissionsEnum.ProductCatalogVisit,
    PermissionsEnum.ReportsVisit,
  ],
} as const;

export default rules;
