import React from 'react';
import {
  VoucherOrderDetailDto,
  VoucherOrderPaymentStatusEnum,
} from '@api/financialServices/models';
import VoucherOrderStatusLabel from '@components/VoucherOrders/Shared/VoucherOrderStatusLabel';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import EditButton from '@components/shared/Display/EditButton';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  openVoucherOrderEditModal: () => void;
  closeDetails: () => void;
}

type Props = OwnProps;

const VoucherOrdersQuickViewHeader = React.memo<Props>(({
  voucherOrder,
  openVoucherOrderEditModal,
  closeDetails,
}) => (
  <TableQuickViewHeader
    title={(
      <div className='align__center'>
        <span>Voucher V{voucherOrder.id!}</span>
        <VoucherOrderStatusLabel voucherOrder={voucherOrder} />
        {
          voucherOrder.paymentStatus !== VoucherOrderPaymentStatusEnum.Paid &&
          <EditButton
            onClick={openVoucherOrderEditModal}
          />
        }
      </div>
    )}
    onClose={closeDetails}
    noPadding={true}
  />
));

export default VoucherOrdersQuickViewHeader;
