import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stopSubmit } from 'redux-form';
import { rostersForm } from '@constants/reduxForms';
import { materialSwal } from '@util/componentHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import RostersForm from '../RostersForm';

class AddRostersFromCSV extends PureComponent {
  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(rostersForm, {}));
  };

  addRosters = async (form) => {
    const { addRosters } = this.props;

    if (!form.rosters || form.rosters.length === 0) {
      materialSwal('Error', 'No roster files attached', 'error');
    } else {
      await addRosters(form);
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        title={'Add a New Roster'}
        modalSize={'l'}
        isOpen={isOpen}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onClose={this.handleCloseModal}
            formSubmission={true}
            formId={rostersForm}
          />
        )}
      >
        <RostersForm
          onSubmit={this.addRosters}
        />
      </Modal>
    );
  }
}

AddRostersFromCSV.propTypes = {
  addRosters: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect()(AddRostersFromCSV);
