import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { minInputLengthForSuggestions } from '@constants/values';
import Chip from '@sharedComponents/Inputs/Chips/Chip';
import Icon from '@sharedComponents/Icons/Icon';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';

class ChipsAutocompleteInput extends PureComponent {
  state = {
    isInputActive: false,
    suggestionsVisible: false,
    searchInput: '',
    throttledCall: null,
  };

  componentWillUnmount() {
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    this.setState({ throttledCall: null });
  }

  onInputFocus = () => {
    const { searchInput } = this.state;
    this.setState({
      isInputActive: true,
      suggestionsVisible: searchInput.length > minInputLengthForSuggestions,
    });
  };

  onInputBlur = () => {
    this.setState({
      isInputActive: false,
      suggestionsVisible: false,
    });
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState({ searchInput: newSearchInput });

    const { throttledCall } = this.state;
    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    if (newSearchInput.length < minInputLengthForSuggestions) {
      this.setState({
        suggestionsVisible: false,
        throttledCall: null,
      });

      // Reset selected item if input field is empty
      const { selectItem } = this.props;
      if (newSearchInput.length === 0) {
        selectItem(null);
      }

      return;
    }

    const newThrottle = setTimeout(this.updateSuggestions, 300);
    this.setState({
      throttledCall: newThrottle,
      suggestionsVisible: false,
    });
  };

  updateSuggestions = async () => {
    const { fetchSuggestions } = this.props;

    const {
      throttledCall,
      searchInput,
    } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    await fetchSuggestions(searchInput);

    this.setState({ suggestionsVisible: true });
  };

  alreadySelected = (suggestion) => {
    const {
      compareBy,
      selectedItems,
    } = this.props;

    return selectedItems.length > 0
      && selectedItems.find((i) => i && i[compareBy] === suggestion[compareBy]);
  };

  onClickOnSuggestion = (item) => {
    const { selectItem } = this.props;

    selectItem(item);
    this.searchInput.value = '';

    this.setState({
      isInputActive: false,
      suggestionsVisible: false,
      searchInput: '',
    });
  };

  renderChip = (item) => {
    const {
      compareBy,
      displayChipText,
      onClickOnChip,
    } = this.props;

    return item ? (
      <Chip
        key={item[compareBy]}
        text={displayChipText(item)}
        onClick={onClickOnChip.bind(null, item)}
        hasIcon={true}
      />
    )
      : '';
  };

  render() {
    const {
      suggestions,
      displaySuggestionText,
      selectedItems,
      placeholder,
      compareBy,
      size = 'xl',
      classes,
    } = this.props;

    const {
      suggestionsVisible,
      searchInput,
      isInputActive,
    } = this.state;

    return (
      <div className={`chips-search has-icon ${isInputActive ? 'active' : ''} ${classes}`}>
        <div>
          <Icon materialIcon={'search'} />
        </div>
        <div className='chips'>
          {selectedItems.map((item) => this.renderChip(item))}
          <div className='text-field mb-0'>
            <OutsideClickWrapper onClick={this.onInputBlur}>
              <>
                <input
                  className='w-100'
                  type='text'
                  placeholder={selectedItems.length === 0 ? placeholder : ''}
                  onChange={this.onInputChange}
                  onFocus={this.onInputFocus}
                  ref={(r) => this.searchInput = r}
                />
                {
                  suggestionsVisible &&
                  <div className={`suggestions suggestions__dropdown-fixed suggestions--size-${size} custom-scrollbar`}>
                    {
                      suggestions.map((suggestion) => (
                        <div
                          key={suggestion[compareBy]}
                          className={`suggestions__row ${this.alreadySelected(suggestion) ? 'disabled' : ''}`}
                          onClick={this.onClickOnSuggestion.bind(null, suggestion)}
                        >
                          {displaySuggestionText(suggestion, searchInput)}
                        </div>
                      ))
                    }
                  </div>
                }
              </>
            </OutsideClickWrapper>
          </div>
        </div>
      </div>
    );
  }
}

ChipsAutocompleteInput.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.object),
  fetchSuggestions: PropTypes.func.isRequired,
  displaySuggestionText: PropTypes.func.isRequired,
  displayChipText: PropTypes.func.isRequired,
  onClickOnChip: PropTypes.func.isRequired,
  selectedItems: PropTypes.array,
  selectItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  compareBy: PropTypes.string,
  size: PropTypes.string,
  classes: PropTypes.string,
};

ChipsAutocompleteInput.defaultProps = {
  placeholder: 'Search',
  compareBy: 'id',
  size: 'l',
};

export default ChipsAutocompleteInput;
