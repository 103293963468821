import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { submitStatusEnum } from '@constants/enums/orderEnums';
import Table from '@sharedComponents/Table/Table';
import Button from '@sharedComponents/Buttons/Button';
import SubmitResults from './SubmitResults';

const ItemsTable = Table();

class SubmitTable extends PureComponent {
  renderTableSubComponent = (cell) => {
    const {
      label,
      itemDataProperty,
    } = this.props;

    return (
      <SubmitResults
        data={cell.original}
        label={label}
        itemDataProperty={itemDataProperty}
      />
    );
  };

  render() {
    const {
      item,
      submitResults,
      label,
      submitEnabled,
      submitAction,
      columns,
      titleInfoFields,
      itemDoneStatusDisplayProperty,
      itemSubmitStatusProperty,
    } = this.props;

    return (
      <div className='order__results'>
        <div className='order__failed'>
          {
            titleInfoFields.map((infoField) => (
              <div
                key={infoField.label}
                className='flex info__field mr-20'
              >
                <div className='info__label'>{infoField.label}:</div>
                <div className='order__status-text'>
                  {infoField.value}
                </div>
              </div>
            ))
          }
          <div className='flex info__field'>
            <div className='info__label'>Status:</div>
            <div className='order__status-text'>
              {
                item[itemSubmitStatusProperty] === submitStatusEnum.Failed
                  ? 'Failed'
                  : item[itemSubmitStatusProperty] === submitStatusEnum.Done
                    ? item[itemDoneStatusDisplayProperty]
                    : item[itemSubmitStatusProperty]
              }
            </div>
          </div>
          {
            submitEnabled && item[itemSubmitStatusProperty] !== submitStatusEnum.Done &&
            <Button
              type={'primary'}
              text={submitResults.length > 0 ? `Resubmit ${label || ''}` : `Submit ${label || ''}`}
              onClick={submitAction}
              size={'s'}
              classes={'button--size-s ml-20'}
            />
          }
        </div>
        <ItemsTable
          data={submitResults}
          columns={columns}
          showPagination={false}
          defaultPageSize={-1}
          subComponent={this.renderTableSubComponent}
          customProps={{ collapseOnDataChange: false }}
        />
      </div>
    );
  }
}

SubmitTable.propTypes = {
  item: PropTypes.object.isRequired,
  submitResults: PropTypes.array.isRequired,
  itemDataProperty: PropTypes.string.isRequired,
  itemDoneStatusDisplayProperty: PropTypes.string.isRequired,
  itemSubmitStatusProperty: PropTypes.string,
  submitEnabled: PropTypes.bool,
  submitAction: PropTypes.func.isRequired,
  label: PropTypes.string,
  titleInfoFields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
  })),
  columns: PropTypes.array,
};

SubmitTable.defaultProps = {
  submitEnabled: true,
  itemSubmitStatusProperty: 'submitStatus',
  titleInfoFields: [],
};

export default SubmitTable;
