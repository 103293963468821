import React from 'react';

interface OwnProps {
  isDisabled: boolean;
}

type Props = OwnProps;

const ActivityStatus = React.memo(({ isDisabled }: Props) => (
  isDisabled
    ? <span className='status-label red'>Disabled</span>
    : <span className='status-label green'>Active</span>
));

export default ActivityStatus;
