export const devices = 'homefield/api/devices' as const;

export const removeItemAssociationFromDevice = (itemCode: string): string => `${devices}/device-item-mappings/${itemCode}`;
export const associateItemsToDevice = `${devices}/device-item-mappings` as const;

export default {
  removeItemAssociationFromDevice,
  devices,
  associateItemsToDevice,
};
