import React from 'react';
import PropTypes from 'prop-types';
import { parseDateNumeric } from '@util/dateHandler';

const LogoBankHistoryChangeLogItem = React.memo(({ logItem }) => (
  <li className='history-item'>
    <div className='history-item__details'>
      <span className='history-item__timestamp'>
        {parseDateNumeric(logItem.date)}
      </span>
      <span className='history_author'>
        {logItem.user}
      </span>
    </div>
    <div className='history-item__content'>
      {logItem.message}
    </div>
  </li>
));

LogoBankHistoryChangeLogItem.propTypes = {
  logItem: PropTypes.object.isRequired,
};

export default LogoBankHistoryChangeLogItem;
