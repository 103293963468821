const ID = 'VENDOR_ORDERS' as const;

export const UPDATE_NOT_SCHEDULED_ORDERS_TABLE = `${ID}/UPDATE_NOT_SCHEDULED_ORDERS_TABLE` as const;
export const UPDATE_SCHEDULED_ORDERS_TABLE = `${ID}/UPDATE_SCHEDULED_ORDERS_TABLE` as const;
export const UPDATE_NOT_SCHEDULED_ORDER_ITEMS = `${ID}/UPDATE_NOT_SCHEDULED_ORDER_ITEMS` as const;
export const UPDATE_VENDOR_ORDER_ITEMS = `${ID}/UPDATE_VENDOR_ORDER_ITEMS` as const;
export const UPDATE_ORDER_ITEMS_DETAILS_DICTIONARY = `${ID}/UPDATE_ORDER_ITEMS_DETAILS_DICTIONARY` as const;
export const UPDATE_VENDOR_ORDER_SUBMIT_RESULTS = `${ID}/UPDATE_VENDOR_ORDER_SUBMIT_RESULTS` as const;
export const UPDATE_AVAILABLE_SKUS_FOR_VENDOR_DESIGN_ID = `${ID}/UPDATE_AVAILABLE_SKUS_FOR_VENDOR_DESIGN_ID` as const;

export default ID;
