import moment from 'moment-timezone';
import { PersonalizationTypeEnum } from '@api/productCatalog/models';
import { emailRegex } from '@constants/common';
import {
  stringWithoutSpaces,
  personalizationWithSpacesMaxLength,
  personalizationWithoutSpacesMaxLength,
  defaultZipCodeLength,
  onlyNumberRegex,
  defaultPhoneNumberLength,
  emailMaxLength,
  locationNameRegex,
  canadianZipRegex,
  defaultCanadianZipCodeLength,
  usPhoneNumberRegex,
} from '@constants/values';
import { CustomOrderFormData } from '@models/forms/OrderManagement/CustomOrderFormData';
import { PersonalizationFormData } from '@models/forms/OrderManagement/PersonalizationFormData';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import { isDefined } from '@util/valueHelpers';

export const validateShipmentData = (values: any) => {
  const errors = {} as any;

  if (!values.shipDate) {
    errors.shipDate = ['Valid ship date is required.'];
  }

  return errors;
};

export const validateShippingInfo = (values: any) => {
  const errors = {} as any;

  if (!values.name) {
    errors.name = ['Name is required.'];
  }

  if (values.name?.length > 64) {
    errors.name = ['Name cannot be greater than 64 characters.'];
  }

  if (!isDefined(values.email)) {
    errors.email = ['E-mail is required.'];
  } else if (!values.email.match(emailRegex)) {
    errors.email = ['E-mail is not in correct form.'];
  } else if (values.email.length > emailMaxLength) {
    errors.email = [`Email should not be greater than ${emailMaxLength} characters.`];
  }

  if (!values.addressLine1) {
    errors.addressLine1 = ['Address (address line 1) is required.'];
  }

  if (values.addressLine1?.length > 61) {
    errors.addressLine1 = ['Address (address line 1) cannot be greater than 61 characters.'];
  }

  if (values.addressLine2 && values.addressLine2.length > 61) {
    errors.addressLine1 = ['Address (address line 2) cannot be greater than 61 characters.'];
  }

  if (!values.city) {
    errors.city = ['City is required.'];
  }

  if (values.city?.length > 35) {
    errors.city = ['City cannot be greater than 35 characters.'];
  }

  if (!values.state) {
    errors.state = ['State is required.'];
  }

  if (values.state?.length > 29) {
    errors.state = ['State cannot be greater than 29 characters.'];
  }

  if (!isDefined(values.zip)) {
    errors.zip = ['Zip code is required.'];
  } else if (values.zip.length < defaultZipCodeLength) {
    errors.zip = [`Zip code should be ${defaultZipCodeLength} characters long for US and ${defaultCanadianZipCodeLength} characters long for Canada.`];
  } else if (values.zip.length === defaultZipCodeLength && !values.zip.match(onlyNumberRegex)) {
    errors.zip = ['US zip code should contain only digits.'];
  } else if (values.zip.length === defaultCanadianZipCodeLength && !values.zip.match(canadianZipRegex)) {
    errors.zip = ['Canadian zip code is not in propper format.'];
  } else if (values.zip.length > defaultCanadianZipCodeLength) {
    errors.zip = [`Zip code should be ${defaultZipCodeLength} characters long for US and ${defaultCanadianZipCodeLength} characters long for Canada.`];
  }

  if (!isDefined(values.phoneNumber)) {
    errors.phoneNumber = ['Phone number is required.'];
  } else if (values.phoneNumber?.replaceAll(/[\D]+/g, '').length !== defaultPhoneNumberLength) {
    errors.phoneNumber = [`Phone number should be exactly ${defaultPhoneNumberLength} digits long.`];
  } else if (!values.phoneNumber.match(usPhoneNumberRegex)) {
    errors.phoneNumber = ['Phone number is not in correct format.'];
  }

  if (!values.method) {
    errors.method = ['Shipping method is required.'];
  }

  if (values.paymentType === accountingActionTypeEnum.ShippingCharge) {
    if (!values.chargeAmount) {
      errors.paymentType = ['Charge amount is required when Charge option selected.'];
    } else if (values.chargeAmount <= 0) {
      errors.paymentType = ['Charge amount should be a positive number.'];
    }
  }

  if (values.paymentType === accountingActionTypeEnum.ShippingRefund) {
    if (!values.refundAmount) {
      errors.paymentType = ['Refund amount is required when Refund option selected.'];
    } else if (values.refundAmount <= 0) {
      errors.paymentType = ['Refund amount should be a positive number.'];
    }
  }

  return errors;
};

export const validateOrderCharge = (values: any) => {
  const errors = {} as any;

  if (!values.reason) {
    errors.reason = ['Reason is required.'];
  }

  if (!values.amount) {
    errors.amount = ['Amount is required.'];
  }

  if (values.amount <= 0) {
    errors.amount = ['Amount should be a positive number.'];
  }

  return errors;
};

export const validateOrderArtworkChange = (values: any) => {
  const errors = {} as any;

  if (!values.decorationLocation) {
    errors.decorationLocation = ['Logo location is required.'];
  }

  if (!values.logoId) {
    errors.logoId = ['Logo image is required.'];
  }

  return errors;
};

export const validateOrderItemPersonalization = (values: any) => {
  const errors = {} as any;
  const maxLength = (values.text || '').match(stringWithoutSpaces)
    ? personalizationWithoutSpacesMaxLength
    : personalizationWithSpacesMaxLength;

  if (!values.text && !values.number) {
    const message = 'Both text and number can not be empty.';
    errors.text = [message];
    errors.number = [message];
  }

  if (values.text && (values.text.length > maxLength)) {
    errors.text = [`Name should be exactly ${personalizationWithSpacesMaxLength} characters long including spaces or ${personalizationWithoutSpacesMaxLength} characters long without spaces.`];
  }

  if (values.number && values.number.length > 3) {
    errors.number = ['Number has to be less than 3 numbers long.'];
  }

  if (!values.decorationLocation) {
    errors.decorationLocation = ['Decoration location is required.'];
  }

  if (!values.colorId) {
    errors.colorId = ['Personalization color is required.'];
  }

  if (!isDefined(values.personalizationBasePrice)) {
    errors.personalizationBasePrice = ['Personalization base price is required.'];
  }

  if (values.personalizationBasePrice < 0) {
    errors.personalizationBasePrice = ['Personalization base price should be a positive number'];
  }

  return errors;
};

export const validateOrderRefund = (values: any) => {
  const errors = {} as any;

  if (!values.reason) {
    errors.reason = ['Reason is required.'];
  }

  if (values.actionType === accountingActionTypeEnum.OrderPartialRefund) {
    if (!values.amount) {
      errors.actionType = ['Refund Amount is required when Refund option selected.'];
    } else if (values.amount <= 0) {
      errors.actionType = ['Refund Amount should be a positive number.'];
    }
  }

  return errors;
};

export const validateOrderItemRefund = (values: any) => {
  const errors = {} as any;

  if (!values.reason) {
    errors.reason = ['Reason is required.'];
  }

  if (!values.quantity) {
    errors.quantity = ['Quantity is required.'];
  }

  if (values.actionType === accountingActionTypeEnum.OrderItemPartialRefund) {
    if (!values.amount) {
      errors.actionType = ['Refund Amount is required when partial refund selected.'];
    } else if (values.amount <= 0) {
      errors.actionType = ['Refund Amount should be a positive number.'];
    }
  }

  return errors;
};

export const validateOrderItemRemovePersonalization = (values: any) => {
  const errors = {} as any;

  if (values.paymentType === accountingActionTypeEnum.PersonalizationRefund) {
    if (!isDefined(values.refundAmount)) {
      errors.paymentType = ['Refund Amount is required when Refund option selected.'];
    } else if (values.refundAmount <= 0) {
      errors.paymentType = ['Refund Amount should be a positive number.'];
    }
  }

  return errors;
};

export const validateOrderItemReplaceBySku = (values: any) => {
  const errors = {} as any;

  if (!values.sku) {
    errors.sku = ['Product SKU is required.'];
  }

  if (!values.basePrice) {
    errors.basePrice = ['Product base price is required.'];
  }

  return errors;
};

export const validateOrderItemAddBySku = (values: any) => {
  const errors = {} as any;

  /*
   * TODO: check if this check is needed because accountingActionTypeEnum.PartialCharge doesn't exist
   * if (values.paymentType === accountingActionTypeEnum.PartialCharge) {
   * if (!values.chargeAmount) {
   *  errors.paymentType = ['Charge amount is required when Charge option selected.'];
   * } else if (values.chargeAmount <= 0) {
   *  errors.paymentType = ['Charge amount should be a positive number.'];
   * }
   * }
   */

  if (!values.sku) {
    errors.sku = ['Product SKU is required.'];
  }

  return errors;
};

export const validateCreateCustomOrder = (
  values: CustomOrderFormData
): { [key in keyof CustomOrderFormData]?: string[] } => {
  const errors: { [key in keyof CustomOrderFormData]?: string[] } = {};

  if (!isDefined(values.lockerId)) {
    errors.lockerId = ['Locker is required.'];
  }

  if (!isDefined(values.organizationId)) {
    errors.organizationId = ['Organization is required.'];
  }

  if (!isDefined(values.shipDate)) {
    errors.shipDate = ['Ship date is required.'];
  }

  if (!isDefined(values.customerShipDate)) {
    errors.customerShipDate = ['Customer ship date is required.'];
  }

  if (values.customerShipDate && values.shipDate
    && !(moment(values.customerShipDate).isSameOrAfter(moment(values.shipDate)))) {
    errors.customerShipDate = ['Customer ship date must be the same or after the ship date.'];
  }

  if (!isDefined(values.name)) {
    errors.name = ['Name is required.'];
  }

  if (!isDefined(values.email)) {
    errors.email = ['E-mail is required.'];
  } else if (!values.email.match(emailRegex)) {
    errors.email = ['E-mail is not in correct form.'];
  } else if (values.email.length > emailMaxLength) {
    errors.email = [`Email should not be greater than ${emailMaxLength} characters.`];
  }

  if (!isDefined(values.addressLine1)) {
    errors.addressLine1 = ['Address is required.'];
  }

  if (!isDefined(values.city)) {
    errors.city = ['City is required.'];
  } else if (!values.city.match(locationNameRegex)) {
    errors.city = ['City should only contain words.'];
  }

  if (!isDefined(values.state)) {
    errors.state = ['State is required.'];
  } else if (!values.state.match(locationNameRegex)) {
    errors.state = ['State should only contain words.'];
  }

  if (isDefined(values.country) && !values.country.match(locationNameRegex)) {
    errors.country = ['Country should only contain words.'];
  }

  if (!isDefined(values.zip)) {
    errors.zip = ['Zip code is required.'];
  } else if (values.zip.length < defaultZipCodeLength) {
    errors.zip = [`Zip code should be ${defaultZipCodeLength} characters long for US and ${defaultCanadianZipCodeLength} characters long for Canada.`];
  } else if (values.zip.length === defaultZipCodeLength && !values.zip.match(onlyNumberRegex)) {
    errors.zip = ['US zip code should contain only digits.'];
  } else if (values.zip.length === defaultCanadianZipCodeLength && !values.zip.match(canadianZipRegex)) {
    errors.zip = ['Canadian zip code is not in propper format.'];
  } else if (values.zip.length > defaultCanadianZipCodeLength) {
    errors.zip = [`Zip code should be ${defaultZipCodeLength} characters long for US and ${defaultCanadianZipCodeLength} characters long for Canada.`];
  }

  if (!isDefined(values.phoneNumber)) {
    errors.phoneNumber = ['Phone number is required.'];
  } else if (!values.phoneNumber.match(onlyNumberRegex)) {
    errors.phoneNumber = ['Phone number should contain only digits.'];
  } else if (values.phoneNumber.length !== defaultPhoneNumberLength) {
    errors.phoneNumber = [`Phone number should be exactly ${defaultPhoneNumberLength} characters long.`];
  }

  if (!isDefined(values.method)) {
    errors.method = ['Shipping method is required.'];
  }

  return errors;
};

export const validatePersonalization = (
  values: PersonalizationFormData,
  props: any
): { [key in keyof PersonalizationFormData]?: string[] } => {
  const errors: { [key in keyof PersonalizationFormData]?: string[] } = {};

  if (!isDefined(values.name) && !isDefined(values.number)) {
    const message = 'Both text and number can not be empty.';
    errors.name = [message];
    errors.number = [message];
  }

  const nameRequired = props.personalization.required
    && (props.personalization.personalizationType === PersonalizationTypeEnum.Name
    || props.personalization.personalizationType === PersonalizationTypeEnum.NameAndNumber);

  const numberRequired = props.personalization.required
    && (props.personalization.personalizationType === PersonalizationTypeEnum.Number
    || props.personalization.personalizationType === PersonalizationTypeEnum.NameAndNumber);

  if (nameRequired && !isDefined(values.name)) {
    errors.name = ['Name is required.'];
  }

  if (numberRequired && !isDefined(values.number)) {
    errors.number = ['Number is required.'];
  }

  const maxLength = (values.name || '').match(stringWithoutSpaces)
    ? personalizationWithoutSpacesMaxLength
    : personalizationWithSpacesMaxLength;

  if (values.name && (values.name.length > maxLength)) {
    errors.name = [`Name should be exactly ${personalizationWithSpacesMaxLength} characters long including spaces or ${personalizationWithoutSpacesMaxLength} characters long without spaces.`];
  }

  if (values.number && values.number.length > 3) {
    errors.number = ['Number has to be less than 3 numbers long.'];
  }

  return errors;
};
