import React from 'react';
import {
  productionAssemblyHagUrl,
  productionAssemblyHagReviewByOrderUrl,
  productionAssemblyHagReviewByBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import ProductionAssemblyReview from '@components/Production/Shared/ProductionAssemblyReview/ProductionAssemblyReview';

const ProductionAssemblyHag = React.memo(() => (
  <ProductionAssemblyReview
    decorationMethod={decorationMethodEnum.HAG}
    reviewByOrderUrl={productionAssemblyHagReviewByOrderUrl}
    reviewByBarcodeUrl={productionAssemblyHagReviewByBarcodeUrl}
    productionAssemblyUrl={productionAssemblyHagUrl}
  />
));

export default ProductionAssemblyHag;
