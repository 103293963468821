import React from 'react';
import PropTypes from 'prop-types';
import { pathToImage } from '@util/indicatorHelper';

const LogoCell = React.memo(({
  logoUrl,
  indicator,
  openModal,
}) => {
  const indicatorPath = pathToImage(indicator);

  return (
    <div>
      <a
        className='sheet__list-table-thumbnail cursor-pointer'
        onClick={openModal.bind(null, logoUrl)}
        style={{ backgroundImage: `url("${logoUrl}")` }}
      />
      <span className='margin margin--right-s'>
        <img
          className='indicator'
          style={{ height: '20px' }}
          src={indicatorPath}
        />
      </span>
    </div>
  );
});

LogoCell.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  indicator: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default LogoCell;
