import React from 'react';
import { TabData } from '@models/common/TabData';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';

interface OwnProps{
  title: TabData['title'];
  onSelectTab: (e: React.MouseEvent) => void;
  permissions: TabData['permissions'];
  isActive: boolean;
  classes?: string;
}

type Props = OwnProps;

const Tab = React.memo<Props>(({
  title,
  isActive,
  onSelectTab,
  classes = '',
  permissions = [],
}) => (
  permissions && permissions.length > 0
    ? (
      <PermissionHandler permissions={permissions}>
        <div
          className={`tab-item ${classes} ${isActive ? 'is-active' : ''}`}
          onClick={onSelectTab}
        >
          {title}
        </div>
      </PermissionHandler>
    )
    : (
      <div
        className={`tab-item ${classes} ${isActive ? 'is-active' : ''}`}
        onClick={onSelectTab}
      >
        {title}
      </div>
    )
));

export default Tab;
