import React from 'react';
import {
  CollectionItemViewModel,
  RosterTeamMemberViewModel,
} from '@api/squadlockerServices/models';
import { RosterMemberOrderItemDto } from '@api/fulfillment/models';
import Icon from '@sharedComponents/Icons/Icon';
import ItemsList from '../../RosterManagerModals/CollectionDetailsModalContent/ItemsList';

interface OwnProps {
  rosterTeamMember: RosterTeamMemberViewModel;
  requiredCollectionItems: CollectionItemViewModel[];
  rosterMemberOrderItems: RosterMemberOrderItemDto[];
}

type Props = OwnProps;

const UnorderedItemsTab = React.memo<Props>(({
  rosterTeamMember,
  rosterMemberOrderItems,
  requiredCollectionItems,
}) => {
  if (!rosterTeamMember) {
    return null;
  }

  const orderedLockerItemIds = [...new Set(rosterMemberOrderItems.map((item) => item.lockerItemId))];

  if (requiredCollectionItems.length === 0) {
    return (
      <div className='roster-team-members__quick-view__tab__text'>
        No required items to order
      </div>
    );
  }

  if (orderedLockerItemIds.length === requiredCollectionItems.length) {
    return (
      <div className='roster-team-members__quick-view__tab__text'>
        The roster member has ordered all required items
      </div>
    );
  }

  const unorderedItems = requiredCollectionItems.filter((item) => !orderedLockerItemIds.includes(item.itemId));

  return (
    <div>
      <div className='roster-team-members__quick-view__tab__info'>
        <Icon materialIcon={'info_outline'} />
        <span>
          Items below have been marked as required for the roster but have not yet been ordered by this member.
        </span>
      </div>
      <ItemsList
        items={unorderedItems}
      />
    </div>
  );
});

export default UnorderedItemsTab;
