import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  reorderCollections,
  deleteCollection,
  deleteCollections,
} from '@APICalls/collections/actions';
import { collectionDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { fetchCollections } from '@redux/collections/actions';
import { materialSwal } from '@util/componentHelper';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import ReorderCollectionsModal from '@components/Collections/CollectionModals/ReorderCollectionsModal';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import ActivityStatus from '@sharedComponents/Display/ActivityStatus';
import Button from '@sharedComponents/Buttons/Button';
import DeleteCollectionModal from '../../LockerManagerModals/DeleteCollectionModal';
import DeleteCollectionsModal from '../../LockerManagerModals/DeleteCollectionsModal';
import CollectionActionColumn from './CollectionsTabContent/CollectionActionColumn';

const CollectionsTable = Table();

const bulkActionsEnum = {
  deleteCollections: 'deleteCollections',
};

const bulkActions = [
  {
    key: 1,
    value: bulkActionsEnum.deleteCollections,
    name: 'Remove Collections',
  },
];

class CollectionsTab extends PureComponent {
  state = {
    sortOrder: '',
    sortByState: '',
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
    searchInput: '',
    associateAdminModalIsOpen: false,
    selectedCollection: null,
    selectedCollections: [],
    isPageSelected: false,
    deleteCollectionModalIsOpen: false,
    deleteCollectionsModalIsOpen: false,
    reorderCollectionsModalIsOpen: false,
  };

  componentDidUpdate(oldProps) {
    const { lockerId } = this.props;

    if (lockerId !== oldProps.lockerId && oldProps.lockerId !== null) {
      this.search();
    }
  }

  openDeleteCollectionModal = (collection) => {
    this.setState({
      selectedCollection: collection,
      deleteCollectionModalIsOpen: true,
    });
  };

  closeDeleteCollectionModal = () => {
    this.setState({
      selectedCollection: null,
      deleteCollectionModalIsOpen: false,
    });
  };

  openDeleteCollectionsModal = () => {
    this.setState({ deleteCollectionsModalIsOpen: true });
  };

  closeDeleteCollectionsModal = () => {
    this.setState({ deleteCollectionsModalIsOpen: false });
  };

  openReorderCollectionsModal = () => {
    this.setState({ reorderCollectionsModalIsOpen: true });
  };

  closeReorderCollectionsModal = () => {
    this.setState({ reorderCollectionsModalIsOpen: false });
  };

  deleteCollection = async (collection) => {
    const res = await deleteCollection(collection.id);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.search();
      this.closeDeleteCollectionModal();
    }
  };

  deleteCollections = async (collections) => {
    const res = await deleteCollections(collections);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.search();
      this.updateSelection([], false);
      this.closeDeleteCollectionsModal();
    }
  };

  saveCollectionsOrder = async (newCollections, oldCollections) => {
    const { lockerId } = this.props;

    this.closeReorderCollectionsModal();

    const collectionsToUpdate = [];

    for (let i = 0; i < newCollections.length; i++) {
      const collection = newCollections[i];
      const correctOrderPlace = i + 1;

      if (collection.id !== oldCollections[i].id || collection.orderPlace !== correctOrderPlace) {
        collection.orderPlace = correctOrderPlace;
        collectionsToUpdate.push(collection);
      }
    }

    const res = await reorderCollections(lockerId, collectionsToUpdate);
    if (res?.success) {
      this.search();
    }
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrder);

    this.setState({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }, this.search);
  };

  search = () => {
    const {
      dispatch,
      lockerId,
    } = this.props;

    const {
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
      searchInput,
    } = this.state;

    dispatch(fetchCollections(
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
      searchInput,
      lockerId
    ));
  };

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  getColumns = () => {
    const columns = [
      {
        Header: <HeaderCell text={'Collection Name'} />,
        accessor: 'name',
        minWidth: 70,
        sortable: true,
        Cell: (cellProps) => cellProps.value ? <Link to={collectionDetailsUrl(cellProps.original.id)}>{cellProps.value}</Link> : '',
      },
      {
        Header: <HeaderCell text={'Items'} />,
        accessor: 'itemsCount',
        minWidth: 20,
        Cell: (cellProps) => cellProps.value ? <span>{cellProps.value}</span> : '',
      },
      {
        Header: 'Status',
        accessor: 'disabled',
        minWidth: 20,
        Cell: (cellProps) => <ActivityStatus isDisabled={cellProps.value} />,
      },
      {
        Header: '',
        width: 60,
        resizable: false,
        accessor: '',
        style: { overflow: 'visible' },
        Cell: (cellProps) => (
          <CollectionActionColumn
            collection={cellProps.value}
            openDeleteModal={this.openDeleteCollectionModal}
          />
        ),
      },
    ];

    return columns;
  };

  onSelectBulkAction = (selectedBulkAction) => {
    switch (selectedBulkAction) {
      case bulkActionsEnum.deleteCollections:
        this.onBulkDeleteCollections();
        break;
    }
  };

  onBulkDeleteCollections = () => {
    this.openDeleteCollectionsModal();
  };

  updateSelection = (newSelectedItems, newIsPageSelected) => {
    this.setState({
      selectedCollections: newSelectedItems,
      isPageSelected: newIsPageSelected,
    });
  };

  render() {
    const {
      selectedCollection,
      selectedCollections,
      isPageSelected,
      deleteCollectionModalIsOpen,
      deleteCollectionsModalIsOpen,
      reorderCollectionsModalIsOpen,
    } = this.state;

    const {
      lockerId,
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    return (
      <div>
        <DeleteCollectionModal
          collection={selectedCollection}
          closeModal={this.closeDeleteCollectionModal}
          deleteCollection={this.deleteCollection}
          isOpen={deleteCollectionModalIsOpen}
        />
        <DeleteCollectionsModal
          isOpen={deleteCollectionsModalIsOpen}
          collections={selectedCollections}
          deleteCollections={this.deleteCollections}
          closeModal={this.closeDeleteCollectionsModal}
        />
        <ReorderCollectionsModal
          isOpen={reorderCollectionsModalIsOpen}
          lockerId={lockerId}
          save={this.saveCollectionsOrder}
          closeModal={this.closeReorderCollectionsModal}
        />

        <div className='lockerManagerEdit__filters'>
          <div className='lockerManagerEdit__filters-fields'>
            <SearchFilter
              search={this.filterKey}
              clearSearch={this.clearSearch}
            />
          </div>

          <Button
            type={'secondary'}
            text={'Reorder Collections'}
            onClick={this.openReorderCollectionsModal}
            classes={'colors__add-btn'}
          />
        </div>

        <div className='sheet'>
          <CollectionsTable
            data={queue}
            columns={this.getColumns()}
            showPagination={hasNextPage || hasPreviousPage}
            totalPages={totalPages}
            onFetchData={this.fetchData}
            selectable={true}
            selectPredicateOrKey={'id'}
            updateSelection={this.updateSelection}
            selectedData={selectedCollections}
            isPageSelected={isPageSelected}
            bulkActionsList={bulkActions}
            onSelectBulkAction={this.onSelectBulkAction}
          />
        </div>
      </div>
    );
  }
}

CollectionsTab.propTypes = {
  totalPages: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  lockerId: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

const mapStateToProps = ({ collections }) => ({
  totalPages: collections.collectionsQueue.totalPages,
  queue: collections.collectionsQueue.queue,
  hasPreviousPage: collections.collectionsQueue.hasPreviousPage,
  hasNextPage: collections.collectionsQueue.hasNextPage,
  pageNumber: collections.collectionsQueue.pageNumber,
  pageSize: collections.collectionsQueue.pageSize,
});

export default connect(mapStateToProps)(CollectionsTab);
