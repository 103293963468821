import * as actionTypes from './types';

const initialState = {
  locker: {
    lck: null,
    xid: null,
    lockerName: null,
    status: null,
  },
  artworkFiles: [],
  sheet: {
    id: null,
    barcode: null,
    status: null,
    items: [],
    statusHistory: [],
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_PRINT_ROOM_ART:
      return {
        locker: action.payload.locker,
        artworkFiles: action.payload.artworkFiles,
      };
    case actionTypes.UPDATE_SHEET_DETAILS:
      return {
        sheet: action.payload,
      };
    default:
      return state;
  }
}
