import * as actionTypes from './types';
import { VoucherOrderCreationSteps } from '@constants/enums/voucherEnums';
import { VouchersAction } from './actions';

export interface VouchersState {
  voucherOrderCreationCurrentStep: VoucherOrderCreationSteps;
}

const initialState: VouchersState = {
  voucherOrderCreationCurrentStep: VoucherOrderCreationSteps.LockersAndOrganizations,
};

export default function(state = initialState, action: VouchersAction) {
  switch (action.type) {
    case actionTypes.UPDATE_VOUCHER_ORDER_CREATION_STEP:
      return {
        ...state,
        voucherOrderCreationCurrentStep: action.payload,
      };
    default:
      return state;
  }
}
