import { fulfillmentApi } from '../../sqdApis';
import logoUrls from '@constants/sqdApiUrls/logoUrls';
import { uploadFile as uploadFileUrl } from '@constants/sqdApiUrls/supportUrls';
import { uploadFile } from '@APICalls/support/actions';
import { makeApiCallWithErrorModal } from '@util/apiHelper';
import * as taskUrls from '@constants/sqdApiUrls/taskUrls';

export const uploadTaskFiles = async (taskId, inputFiles, fileType) => {
  const filesUploadPromises = [];

  if (inputFiles) {
    for (const f of inputFiles) {
      filesUploadPromises.push(uploadFile(f, fulfillmentApi, uploadFileUrl));
    }
  }

  const responseFilePaths = await Promise.all(filesUploadPromises);

  const call = fulfillmentApi.post(logoUrls.serviceWorkerFileUpload(), {
    body: JSON.stringify({
      taskId,
      filePaths: responseFilePaths,
      fileType,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const archiveTasksBulk = async (taskIds, note = '', file) => {
  const formData = new FormData();
  formData.append('content', note);
  formData.append('ids', taskIds);

  if (file) {
    for (const f of file) {
      formData.append('file[]', f, f.name);
    }
  }

  const call = fulfillmentApi.put(taskUrls.archive, {
    body: formData,
  });
  const result = await makeApiCallWithErrorModal(call);

  return result;
};

export const unarchiveTasksBulk = async (ids) => {
  const call = fulfillmentApi.put(taskUrls.unarchive, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ids }),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const createArtworkTasks = async (items) => {
  const call = fulfillmentApi.post(taskUrls.taskCreate, {
    body: JSON.stringify({
      items,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
