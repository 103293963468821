import React, {
  useCallback,
  useState,
} from 'react';
import { ProductionAssemblyOrderHeaderDto } from '@api/fulfillment/models';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import Expander from '@sharedComponents/Buttons/Expander';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';
import Link from '@sharedComponents/Navigation/Link';
import OrderHeaderDetails from './OrderHeaderDetails';

interface OwnProps {
  order: ProductionAssemblyOrderHeaderDto | undefined;
  isRequired: boolean;
}

type Props = OwnProps;

const OrderHeader = React.memo<Props>(({
  order,
  isRequired,
}) => {
  const [
    isExpanded,
    setIsExpanded,
  ] = useState(true);

  const changeExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  if (!order) return null;

  return (
    <div className='order-assembly'>
      <div className='order-assembly__header--info'>
        <div className='flex__row align__center'>
          ORDER O{order.orderNumber}
          <Link
            url={orderDetailsUrl(order.orderNumber!)}
            openInNewTab={true}
            classes={'link order-assembly__item--info__icon'}
            text={(
              <i
                title='Order Management'
                className='material-icons order-assembly__header--link'
              >
                launch
              </i>
            )}
          />
          <LabelsLegendDisplay
            labels={[
              {
                isActive: false, // Order.isRushed,
                text: 'Rushed',
                shortText: 'R',
                type: 'status-blue',
              },
              {
                isActive: false, // Order.isPaused,
                text: 'Paused',
                shortText: 'P',
                type: 'status-orange',
              },
              {
                isActive: false, // Order.isCanceled,
                text: 'Canceled',
                shortText: 'C',
                type: 'status-darkred',
              },
              {
                isActive: isRequired,
                text: 'Required',
                shortText: 'R',
                type: 'electric-indigo',
              },
            ]}
          />
        </div>
        <Expander
          isExpanded={isExpanded}
          toggle={changeExpand}
        />
      </div>
      {
        isExpanded &&
        <OrderHeaderDetails
          order={order}
        />
      }
    </div>
  );
});

export default OrderHeader;
