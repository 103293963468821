import React from 'react';
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { StaticContext } from 'react-router';
import {
  backorderManagementUrl,
  orderSearchUrl,
} from '@constants/clientUrls/clientUrls';
import BackLink from '@sharedComponents/Navigation/BackLink';

type LocationState = {
  prevPath?: string;
};

type Props = RouteComponentProps<Record<string, never>, StaticContext, LocationState>;

const OrderManagementBack = React.memo<Props>(({ location }) => {
  let breadcrumbText: string = 'Order Management';
  let breadcrumbUrl: string = orderSearchUrl;

  if (location?.state) {
    const { prevPath } = location.state;
    if (prevPath?.includes(backorderManagementUrl)) {
      breadcrumbText = 'Backorder Management';
      breadcrumbUrl = prevPath;
    }
  }

  return (
    <div className='header__bar-title'>
      <BackLink
        url={breadcrumbUrl}
        text={breadcrumbText}
      />
    </div>
  );
});

export default withRouter(OrderManagementBack);
