import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submitPayoutToAccounting } from '@APICalls/fundraisingPayouts/actions';
import {
  getAccountingSubmitResults,
  getPayoutById,
} from '@redux/fundraisingPayouts/actions';
import { materialSwal } from '@util/componentHelper';
import { parseDateTimeNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import SubmitTable from '@sharedComponents/SubmitResults/SubmitTable';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

class FundraisingPayoutsAccountingSubmitModal extends PureComponent {
  state = {
    confirmationModalIsOpen: false,
  };

  componentDidMount() {
    const {
      payout,
      dispatch,
    } = this.props;

    dispatch(getAccountingSubmitResults(payout.id));
  }

  openConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: true }));
  };

  closeConfirmationModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: false }));
  };

  handleModalConfirm = async () => {
    const {
      payout,
      dispatch,
      closeModal,
    } = this.props;

    const res = await submitPayoutToAccounting(payout.id);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }

    dispatch(getPayoutById(payout.id));
    dispatch(getAccountingSubmitResults(payout.id));

    this.closeConfirmationModal();
    closeModal();
  };

  getColumns = () => [
    {
      Header: 'Date Created',
      accessor: 'created',
      minWidth: 140,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.original.created)}</span>,
    },
    {
      Header: 'Elapsed Time',
      accessor: 'elapsedTime',
      minWidth: 90,
      Cell: (cellProps) => <span>{cellProps.original.elapsedTime || 0} ms</span>,
    },
    {
      Header: 'Message',
      accessor: 'message',
      minWidth: 150,
    },
    {
      Header: 'Status Code',
      accessor: 'statusCode',
    },
    {
      Header: 'User',
      accessor: 'userId',
      Cell: (cellProps) => <span>{cellProps.original.userId || '-'}</span>,
    },
    {
      expander: true,
      Header: 'Details',
      accessor: '',
      width: 200,
      Expander: ({ isExpanded }) => (
        <div>
          {
            isExpanded
              ? (
                <div className='flex button align__center'>
                  <div className='info__label'>Collapse Details</div>
                  <Icon materialIcon={'arrow_drop_up'} />
                </div>
              )
              : (
                <div className='flex button align__center'>
                  <div className='info__label'>Expand Details</div>
                  <Icon materialIcon={'arrow_drop_down'} />
                </div>
              )
          }
        </div>
      ),
    },
  ];

  render() {
    const {
      payout,
      isOpen,
      closeModal,
      accountingSubmitResults,
    } = this.props;

    const { confirmationModalIsOpen } = this.state;

    return (
      <>
        <Modal
          isOpen={isOpen}
          closeModal={closeModal}
          title={`Payout ${payout.id} Accounting Submit Results`}
          modalSize={'xl'}
          buttons={(
            <ModalButtons
              onClose={closeModal}
              cancelBtnText={'Close'}
              displayConfirmBtn={false}
            />
          )}
        >
          <SubmitTable
            label={'Payout'}
            item={payout}
            submitAction={this.openConfirmationModal}
            submitResults={accountingSubmitResults}
            itemDataProperty={'payoutData'}
            itemDoneStatusDisplayProperty={'gpDocumentId'}
            itemSubmitStatusProperty={'accountingSubmitStatus'}
            columns={this.getColumns()}
          />
        </Modal>

        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.handleModalConfirm}
          closeModal={this.closeConfirmationModal}
          title={'Submit Payout'}
          confirmationBody={`Are you sure you want to submit payout ${payout.id} to accounting?`}
          modalStyleProps={{ overlay: { zIndex: 101 } }}
        />
      </>
    );
  }
}

FundraisingPayoutsAccountingSubmitModal.propTypes = {
  payout: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  accountingSubmitResults: PropTypes.array.isRequired,
};

const mapStateToProps = ({ fundraisingPayouts }) => ({
  accountingSubmitResults: fundraisingPayouts.accountingSubmitResults,
});

export default connect(mapStateToProps)(FundraisingPayoutsAccountingSubmitModal);
