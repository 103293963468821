import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { uploadTaskFiles } from '@APICalls/artwork/actions';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import {
  artworkTaskStatusEnum,
  artworkFileTypeEnum,
} from '@constants/enums/artworkEnums';
import {
  submitTaskForQc,
  dispatchAfterSubmit,
  fetchPreviewUrl,
  clearPreview,
  archiveTask,
  unarchiveTask,
} from '@redux/artworkFileTasks/actions';
import {
  fetchLogo,
  fetchLogoColoredStyleColors,
} from '@redux/logos/actions';
import { materialSwal } from '@util/componentHelper';
import { roleMapping } from '@util/roleCheck';
import Button from '@sharedComponents/Buttons/Button';
import Modal from '@sharedComponents/Modal/Modal';
import Icon from '@sharedComponents/Icons/Icon';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ImagePreviewContainer from '../ImagePreviewContainer';
import ArtworkTaskDetailsNoteLogContainer from './ArtworkTaskDetailsNote/ArtworkTaskDetailsNoteLogContainer';
import ArtworkTaskNoteUploader from './ArtworkTaskDetailsNote/ArtworkTaskNoteUploader';
import ArtworkTaskDetailsInfo from './ArtworkTaskDetailsInfo';
import ArtworkTaskFailNote from './ArtworkTaskFailNote';
import ArtworkTaskUpload from './ArtworkTaskUpload';
import ArtworkTaskStorageImage from './ArtworkTaskStorageImage';
import ArtworkTaskUploadedFiles from './ArtworkTaskUploadedFiles';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

class ArtworkTaskDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.hagFiles = {
      eps: {
        previewFile: null,
        uploaded: false,
      },
    };

    this.embFiles = {
      emb: {
        previewFile: null,
        uploaded: false,
      },
      dst: {
        previewFile: null,
        uploaded: false,
      },
      pdf: {
        previewFile: null,
        uploaded: false,
      },
    };

    this.defaultState = (theProps) => {
      const { task } = theProps;

      return {
        files: task.decorationMethod === decorationMethodEnum.HAG
          ? this.hagFiles
          : this.embFiles,
        submitted: false,
        previewState: null,
        archiveModalIsOpen: false,
        archiveNote: null,
        attachedFile: [],
        confirmationModalIsOpen: false,
      };
    };

    this.state = this.defaultState(props);
  }

  componentDidMount() {
    const {
      dispatch,
      task,
    } = this.props;

    if (task && task.logoId) {
      this.refreshLogo(task.logoId);
      dispatch(fetchLogoColoredStyleColors(task.locker.id, task.logoId));
    }

    if (this.shouldFetchPreview(task)) {
      const previewState = dispatch(fetchPreviewUrl(task.id));
      this.updatePreviewState(previewState);
    }
  }

  componentDidUpdate(oldProps) {
    const oldTask = oldProps.task;
    const {
      task,
      dispatch,
      previewInfo,
    } = this.props;
    const {
      submitted,
      previewState,
    } = this.state;

    if (oldProps.task !== task) {
      if (oldProps.task.logoId !== task.logoId
        && task.logoId) {
        this.refreshLogo(task.logoId);
        dispatch(fetchLogoColoredStyleColors(task.locker.id, task.logoId));
      }
    }

    if (oldTask.id !== task.id || submitted) {
      if (previewInfo.previewUrl) {
        dispatch(clearPreview());
      }

      if (previewState && previewState.delayedFetch) {
        clearTimeout(previewState.delayedFetch);
      }

      if (this.shouldFetchPreview(task)) {
        const nextPreviewState = dispatch(fetchPreviewUrl(task.id));
        this.setState(() => ({
          previewState: nextPreviewState,
          submitted: false,
        }));
      }
    }
  }

  componentWillUnmount() {
    const { previewState } = this.state;

    if (previewState && previewState.delayedFetch) {
      clearTimeout(previewState.delayedFetch);
    }
  }

  openAssignModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: true }));
  };

  closeAssignModal = () => {
    this.setState(() => ({ confirmationModalIsOpen: false }));
  };

  updatePreviewState = (previewState) => {
    this.setState(() => ({ previewState }));
  };

  refreshLogo = (logoId) => {
    const { dispatch } = this.props;
    dispatch(fetchLogo(logoId));
  };

  shouldFetchPreview(task) {
    if (!task.previewUrl
      && task.status
      && task.status.status !== artworkTaskStatusEnum.Unassigned
      && task.artworkFiles
      && task.artworkFiles.length > 0) {
      return task.decorationMethod !== decorationMethodEnum.EMB
        || task.decorationMethod === decorationMethodEnum.EMB &&
        task.artworkFiles.some((file) => file.fileName && file.fileName.split('.').pop() === 'pdf');
    }

    return false;
  }

  submitForQc = () => {
    this.setState(() => ({ confirmationModalIsOpen: true }));

    const {
      task,
      dispatch,
    } = this.props;

    dispatch(submitTaskForQc(task.locker.id, task.id, (response) => {
      if (response && response.success) {
        materialSwal('Success', response.message, 'success');
      }
    }));

    this.closeAssignModal();
  };

  handleUploadTaskFiles = (inputFiles, fileType) => {
    const {
      task,
      dispatch,
    } = this.props;
    const { previewState } = this.state;

    if (previewState && previewState.delayedFetch) {
      clearTimeout(previewState.delayedFetch);
    }

    this.setState(() => ({
      submitted: true,
    }), async () => {
      const res = await uploadTaskFiles(task.id, inputFiles, fileType);
      if (res?.success) {
        dispatch(dispatchAfterSubmit(task.locker.id, task.id));
      }
    });
  };

  handleStandardUploadTaskFiles =
    (inputFiles) => this.handleUploadTaskFiles(inputFiles, null);

  handleStrippedUploadTaskFiles =
    (inputFiles) => this.handleUploadTaskFiles(inputFiles, artworkFileTypeEnum.HagStrippedEps);

  clearFileFromState = () => {
    this.setState(() => ({ attachedFile: null }));
  };

  addFileToState = (file) => {
    this.setState(() => ({ attachedFile: file }));
  };

  openArchiveModal = () => {
    this.setState(() => ({
      archiveModalIsOpen: true,
    }));
  };

  closeArchiveModal = () => {
    if (this.archivationReason && this.archivationReason.value) {
      this.archivationReason.value = '';
    }
    this.setState(() => ({
      archiveModalIsOpen: false,
      attachedFile: null,
    }));
  };

  archiveOnClick = () => {
    const {
      dispatch,
      task,
    } = this.props;

    const { attachedFile } = this.state;

    const res = dispatch(archiveTask(task.locker.id, task.id, this.archivationReason.value, attachedFile));
    if (res) {
      this.closeArchiveModal();
    }
  };

  unarchiveOnClick = () => {
    const {
      dispatch,
      task,
    } = this.props;
    dispatch(unarchiveTask(task.locker.id, task.id));
  };

  render() {
    const {
      task,
      previewInfo,
      logo,
      lockerInfo,
      logoColoredStyleColors,
      colors,
      flags,
    } = this.props;

    const {
      archiveModalIsOpen,
      confirmationModalIsOpen,
    } = this.state;

    let artworkTaskUploadRender = <div />;
    let artworkTaskSubmitToQcRender = <div />;
    let artworkTaskFailNoteRender = <div />;
    let artworkTaskStrippedUploadRender = null;

    if ([
      artworkTaskStatusEnum.Assigned,
      artworkTaskStatusEnum.Rework,
      artworkTaskStatusEnum.Priority,
      artworkTaskStatusEnum.ProductionRework,
    ].includes(task.status && task.status.status)
    ) {
      artworkTaskUploadRender = (
        <ArtworkTaskUpload
          taskType={task.decorationMethod}
          taskId={task.id}
          uploadTaskFiles={this.handleStandardUploadTaskFiles}
        />
      );

      artworkTaskSubmitToQcRender = (
        <Button
          type={'primary'}
          text={'Submit to QC'}
          onClick={this.openAssignModal}
          disabled={!task.readyForQC}
          classes={'ml-10'}
        />
      );
    }

    if (task.status && task.status.status === artworkTaskStatusEnum.InQC && flags.strippedFileForHag) {
      artworkTaskStrippedUploadRender = (
        <ArtworkTaskUpload
          taskType={task.decorationMethod}
          taskId={task.id}
          uploadTaskFiles={this.handleStrippedUploadTaskFiles}
        />
      );
    }

    if (task.status && task.status.status === artworkTaskStatusEnum.Unassigned) {
      artworkTaskUploadRender = 'Task must be in progress to upload files.';
    }

    const needsStrippedFile = Boolean(flags.strippedFileForHag &&
      (task.status.status === artworkTaskStatusEnum.InQC || task.status.status === artworkTaskStatusEnum.Completed) &&
      task.decorationMethod === decorationMethodEnum.HAG);

    const hagMissingStripped = needsStrippedFile &&
      // In Case preview fails check either for preview and file name
      !task.strippedPreviewUrl &&
      !task.artworkFiles?.some(
        (file) => file.fileName.toUpperCase().startsWith('DTF_')
      );

    if (task.status
      && (task.status.status === artworkTaskStatusEnum.InQC
        || task.status.status === artworkTaskStatusEnum.Completed)
    ) {
      artworkTaskFailNoteRender = (
        <ArtworkTaskFailNote
          disableSendToPrint={hagMissingStripped}
          lockerId={task.locker.id}
        />
      );
    }

    let archivationButton = '';
    if (!task.artworkTaskArchivation) {
      archivationButton = (
        <Button
          type={'secondary'}
          text={'Archive'}
          onClick={this.openArchiveModal}
          icon={(
            <Icon
              materialIcon={'archive'}
              classes={'material-icons-outlined'}
            />
          )}
        />
      );
    } else {
      archivationButton = (
        <Button
          type={'secondary'}
          text={'Unarchive'}
          onClick={this.unarchiveOnClick}
          icon={(
            <Icon
              materialIcon={'unarchive'}
              classes={'material-icons-outlined'}
            />
          )}
        />
      );
    }
    const uploadedFiles = task.artworkFiles && task.artworkFiles.length > 0
      ? <ArtworkTaskUploadedFiles files={task.artworkFiles} />
      : <div />;

    const lowerContainer = (
      <div className='artwork-details__container'>
        <div className='artwork-details__title'>
          {
            task.artworkFiles && task.artworkFiles.length > 0
              ? 'UPLOADED FILES'
              : 'UPLOAD FILES'
          }
        </div>
        <div className='uploads'>
          {uploadedFiles}
          {artworkTaskUploadRender}
          {
            artworkTaskStrippedUploadRender &&
            <div>
              <div className='artwork-details__title'>
                Manually Override Stripped Files
              </div>
              {artworkTaskStrippedUploadRender}
            </div>
          }
        </div>
      </div>
    );

    let upperContainer = (
      <ArtworkTaskStorageImage
        previewUrl={task.previewUrl}
        isCutLogo={task.isCutFile}
        cutColor={task.cutFileColor}
        decorationMethod={task.decorationMethod}
        imageHeight={task.fileHeight}
        imageWidth={task.fileWidth}
        swatchColors={task.fileSwatchColors}
        taskType={task.decorationMethod}
        lockerId={task.locker.id}
        taskId={task.id}
        status={task.status && task.status.status}
        stitchCount={task.stitchCount}
        colors={colors}
        isBackgroundSelectable={task.decorationMethod === decorationMethodEnum.HAG}
      />
    );

    const stripPreview = !hagMissingStripped
      ? (
        <ArtworkTaskStorageImage
          previewUrl={task.strippedPreviewUrl}
          isCutLogo={task.isCutFile}
          cutColor={task.cutFileColor}
          decorationMethod={task.decorationMethod}
          imageHeight={task.strippedFileHeight}
          imageWidth={task.strippedFileWidth}
          swatchColors={task.strippedFileSwatchColors}
          taskType={task.decorationMethod}
          lockerId={task.locker.id}
          taskId={task.id}
          status={task.status && task.status.status}
          stitchCount={task.stitchCount}
          colors={colors}
          disableCutFileRender={true}
          title={'Strip Preview'}
          isBackgroundSelectable={task.decorationMethod === decorationMethodEnum.HAG}
        />
      )
      : (
        <div className='warning-message'>
          <Icon materialIcon={'warning'} />
          <span>
            No Stripped File Uploaded!!!
          </span>
        </div>
      );

    if (previewInfo && (previewInfo.previewUrl || previewInfo.processing || previewInfo.failed)) {
      upperContainer = (
        <ArtworkTaskStorageImage
          previewUrl={previewInfo.previewUrl}
          isCutLogo={task.isCutFile}
          cutColor={task.cutFileColor}
          message={previewInfo.message}
          failed={previewInfo.failed}
          processing={previewInfo.processing}
          imageHeight={previewInfo.height}
          imageWidth={previewInfo.width}
          swatchColors={previewInfo.swatchColors}
          taskType={task.decorationMethod}
          decorationMethod={task.decorationMethod}
          lockerId={task.locker.id}
          taskId={task.id}
          status={task.status && task.status.status}
          stitchCount={task.stitchCount}
          colors={colors}
          isBackgroundSelectable={task.decorationMethod === decorationMethodEnum.HAG}
        />
      );
    }

    return (
      <>
        <Modal
          title={'Archive Artwork'}
          modalHeight={'l'}
          modalWidth={'m'}
          isOpen={archiveModalIsOpen}
          closeModal={this.closeArchiveModal}
          buttons={(
            <ModalButtons
              confirmBtnText={'Confirm'}
              cancelBtnText={'Cancel'}
              onConfirm={this.archiveOnClick}
              onClose={this.closeArchiveModal}
            />
          )}
        >
          <>
            <div className='p-r-16'>
              <div className='warning-message'>
                <Icon materialIcon={'warning'} />
                <span>
                  The Artwork task won't be on further incoming orders while it's archived
                </span>
              </div>
              <div className='text-field'>
                <textarea
                  placeholder='Add Note*'
                  className='w-100'
                  ref={(r) => this.archivationReason = r}
                  id='note-field'
                />
              </div>
              <div className='upload-field--horizontal'>
                <ArtworkTaskNoteUploader
                  attachFile={this.addFileToState}
                  removeAttachedFile={this.clearFileFromState}
                />
              </div>
            </div>
          </>
        </Modal>
        <div className='artwork-preview'>
          <ArtworkTaskDetailsInfo
            task={task}
            logoColoredStyleColors={logoColoredStyleColors}
            productionNote={lockerInfo && lockerInfo.productionNote}
          />
          <div className='divider' />
          <div className='artwork-preview__wrapper'>
            <ImagePreviewContainer
              filePath={(logo && logo.imageUrl) || task.logoUrl}
              task={task}
              logo={logo}
              refreshLogo={this.refreshLogo}
            />
            {upperContainer}
            {needsStrippedFile && stripPreview}
          </div>
          <div className='divider' />
          <div className='card-container'>
            <div className='artwork-details'>
              <ArtworkTaskDetailsNoteLogContainer task={task} />
              {lowerContainer}
            </div>
          </div>
        </div>
        <div className='flex align-end'>
          {archivationButton}
          {artworkTaskSubmitToQcRender}
          {artworkTaskFailNoteRender}
        </div>

        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={this.submitForQc}
          closeModal={this.closeAssignModal}
          title={'Submit Task'}
          confirmationBody={'Are you sure you want to submit this task to QC?'}
        />
      </>
    );
  }
}

ArtworkTaskDetails.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    logoId: PropTypes.number.isRequired,
    logoUrl: PropTypes.string.isRequired,
    decorationMethod: PropTypes.string.isRequired,
    decorationSizeHeight: PropTypes.number.isRequired,
    decorationSizeWidth: PropTypes.number.isRequired,
    notes: PropTypes.array,
    artworkFiles: PropTypes.array,
    status: PropTypes.object.isRequired,
    readyForQC: PropTypes.bool.isRequired,
    locker: PropTypes.object.isRequired,
    previewUrl: PropTypes.string,
    isCutFile: PropTypes.bool,
    cutFileColor: PropTypes.string,
    fileHeight: PropTypes.number,
    fileWidth: PropTypes.number,
    statusOn: PropTypes.string,
    statusBy: PropTypes.string,
    assignedOn: PropTypes.string,
    assignedBy: PropTypes.string,
    assignedToName: PropTypes.string,
    productionReadyOn: PropTypes.string,
    fileSwatchColors: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    artworkTaskArchivation: PropTypes.shape({
      created: PropTypes.string.isRequired,
      user: PropTypes.string.isRequired,
    }),
    stitchCount: PropTypes.number,
  }).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  previewInfo: PropTypes.object,
  logo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    selectedColors: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      selectionType: PropTypes.number,
      id: PropTypes.number,
      hexValue: PropTypes.string,
    })),
    artworkApproved: PropTypes.bool.isRequired,
    decorationMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
    deletedAt: PropTypes.string,
  }),
  lockerInfo: PropTypes.object,
  logoColoredStyleColors: PropTypes.array,
  lockerId: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

const mapStateToProps = ({
  oidc,
  artworkFileTasks,
  logos,
  productCatalog,
}) => ({
  roles: roleMapping(oidc),
  previewInfo: artworkFileTasks.previewGenerator,
  logo: logos.logo,
  logoColoredStyleColors: logos.logoColoredStyleColors,
  colors: productCatalog.colors,
});

export default withLDConsumer()(connect(mapStateToProps)(ArtworkTaskDetails));
