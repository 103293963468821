import React from 'react';
import Expander from '@sharedComponents/Buttons/Expander';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import SearchResults from '@sharedComponents/Display/SearchResults';

interface OwnProps {
  onSearchInputChange: (e: any) => void;
  resetSearchOrderItemsInput: boolean;
  onResetSearchOrderItemsInput: () => void;
  collapseAll: boolean;
  collapseOrExpandItems: () => void;
  isFilteringItems: boolean;
  filteredItemsQuantity: number;
  clearSearch: () => void;
}

type Props = OwnProps;

const VoucherDetailsListHeader = React.memo<Props>(({
  onSearchInputChange,
  resetSearchOrderItemsInput,
  onResetSearchOrderItemsInput,
  collapseAll,
  collapseOrExpandItems,
  isFilteringItems,
  filteredItemsQuantity,
  clearSearch,
}) => (
  <div>
    <div className='order__search-container'>
      <SearchFilter
        onChange={onSearchInputChange}
        placeholder={'Search'}
        resetValue={resetSearchOrderItemsInput}
        onResetValue={onResetSearchOrderItemsInput}
      />
      <Expander
        isExpanded={!collapseAll}
        toggle={collapseOrExpandItems}
        collapseText={'Collapse All'}
        expandText={'Expand All'}
        hideIcon={true}
        classes={'ml-20'}
      />
    </div>
    <div>
      <SearchResults
        isFilteringItems={isFilteringItems}
        filteredItemsQuantity={filteredItemsQuantity}
        clearSearch={clearSearch}
      />
    </div>
  </div>
));

export default VoucherDetailsListHeader;
