import React from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

const OrderManagementPersonalizationColorList = (props) => {
  const {
    meta: {
      touched, error,
    },
    productColors,
    selectedColorId,
    selectColor,
  } = props;

  const hasError = touched && error && (error.length > 0);

  return (
    <div>
      <label className='redux-form__label'>
        Color
      </label>
      <div className='flex'>
        {
          productColors?.map((c) => (
            <MaterialTooltip
              key={c.id}
              tooltipText={c.code}
              placement={'bottom'}
            >
              <button
                onClick={selectColor.bind(null, c.id)}
                className='flex button--link'
                type='button'
              >
                <span
                  className={`color-swatch-item__color size--small mr-5 order__personalization-color-container ${selectedColorId === c.id ? 'selected' : ''}`}
                  style={{ backgroundColor: c.hexValue }}
                />
              </button>
            </MaterialTooltip>
          ))
        }
      </div>
      {
        hasError &&
        <div className='redux-form__error--field mt-10'>
          {error.map((e, index) => <div key={index}>{e}</div>)}
        </div>
      }
    </div>
  );
};

OrderManagementPersonalizationColorList.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.array,
  }),
  input: PropTypes.object,
  productColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  selectedColorId: PropTypes.number,
  selectColor: PropTypes.func.isRequired,
};

export default OrderManagementPersonalizationColorList;
