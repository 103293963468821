import React from 'react';
import PropTypes from 'prop-types';
import { pathToImage } from '@util/indicatorHelper';

const PrintSheetItemIndicator = React.memo(({ indicator }) => {
  if (!indicator) {
    return <div />;
  }

  const indicatorPath = pathToImage(indicator);

  return (
    <span className='margin margin--right-s'>
      <img
        className='sheet__title-indicator'
        src={indicatorPath}
      />
    </span>
  );
});

PrintSheetItemIndicator.propTypes = {
  indicator: PropTypes.string,
};

export default PrintSheetItemIndicator;
