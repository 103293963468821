import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { muiTheme } from '@constants/values';

const DatePicker = React.memo(({
  placeholder = '',
  disabled,
  meta: {
    touched = false,
    error = '',
  },
  minDate = undefined,
  input,
  classes = '',
}) => {
  const inputProps = input || {};
  const hasError = touched && error && (error.length > 0);
  inputProps.value = inputProps.value || null;

  return (
    <div className={`text-field w-100 redux-form__date-picker ${disabled ? 'disabled' : ''} ${classes}`}>
      <ThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            format='MM/DD/YYYY'
            placeholder={placeholder}
            minDate={minDate}
            disabled={disabled}
            {...inputProps}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>

      {
        hasError &&
        <div className='redux-form__error--field'>
          {error.map((e, index) => <div key={index}>{e}</div>)}
        </div>
      }
    </div>
  );
});

DatePicker.propTypes = {
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.array,
  }),
  input: PropTypes.object,
  minDate: PropTypes.object,
  classes: PropTypes.string,
};

export default DatePicker;
