import React, {
  Key,
  PureComponent,
} from 'react';
import { minInputLengthForSuggestions } from '@constants/values';
import { ObjectWithId } from '@models/common/ObjectWithId';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import FormError from '@sharedComponents/Form/FormError';

interface OwnProps<T extends Partial<ObjectWithId> = any> {
  suggestions: T[];
  fetchSuggestions: (searchInput: string) => void;
  displayItem: (selectedItem: T, searchInput?: string) => string;
  displaySuggestionText: (selectedItem: T, searchInput: string) => string;
  selectedItem: Nullable<T>;
  placeholder?: string;
  compareBy?: keyof T;
  size?: string;
  classes?: string;
  hasSearchIcon?: boolean;
  error?: any;
  disabled?: boolean;
  keyValue?: number;
  isDropdownFixed?: boolean;
  selectItem: (selectedItem: any) => void;
}

type Props<T extends Partial<ObjectWithId> = any> = OwnProps<T>;

interface State {
  isInputActive: boolean;
  suggestionsVisible: boolean;
  searchInput: string;
  throttledCall: Nullable<ReturnType<typeof setTimeout>>;
}

class AutocompleteInput<T extends Partial<ObjectWithId> = any> extends PureComponent<Props<T>, State> {
  state = {
    isInputActive: false,
    suggestionsVisible: false,
    searchInput: '',
    throttledCall: null,
  } as State;

  searchInput: any;

  componentWillUnmount() {
    const { throttledCall } = this.state;
    if (throttledCall) {
      clearTimeout(throttledCall);
    }
    this.setState(() => ({ throttledCall: null }));
  }

  onInputFocus = () => {
    const { searchInput } = this.state;
    this.setState(() => ({
      isInputActive: true,
      suggestionsVisible: searchInput.length > minInputLengthForSuggestions,
    }));
  };

  onInputBlur = () => {
    this.setState(() => ({
      isInputActive: false,
      suggestionsVisible: false,
    }));
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState(() => ({ searchInput: newSearchInput }));
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    if (newSearchInput.length < minInputLengthForSuggestions) {
      this.setState(() => ({
        suggestionsVisible: false,
        throttledCall: null,
      }));

      // Reset selected item if input field is empty
      const { selectItem } = this.props;
      if (newSearchInput.length === 0) {
        selectItem(null);
      }

      return;
    }

    const newThrottle = setTimeout(this.updateSuggestions, 300);
    this.setState(() => ({
      throttledCall: newThrottle,
      suggestionsVisible: false,
    }));
  };

  updateSuggestions = async () => {
    const { fetchSuggestions } = this.props;
    const {
      throttledCall,
      searchInput,
    } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    await fetchSuggestions(searchInput);
    this.setState(() => ({ suggestionsVisible: true }));
  };

  onClickOnSuggestion = (item: T) => {
    const {
      selectItem,
      displayItem,
    } = this.props;

    const searchInput = this.searchInput.value;

    selectItem(item);
    this.searchInput.value = displayItem(item, searchInput);

    this.setState(() => ({
      isInputActive: false,
      suggestionsVisible: false,
      searchInput,
    }));
  };

  render() {
    const {
      suggestions,
      displayItem,
      displaySuggestionText,
      selectedItem,
      placeholder = 'Search',
      compareBy = 'id',
      size = 'xl',
      classes = '',
      hasSearchIcon = false,
      error = null,
      disabled = false,
      keyValue = 0,
      isDropdownFixed = false,
    } = this.props;

    const {
      suggestionsVisible,
      searchInput,
      isInputActive,
    } = this.state;

    const inputContent = (
      <div className='w-100'>
        <div className='text-field mb-0'>
          <OutsideClickWrapper onClick={this.onInputBlur}>
            <>
              <input
                className={`w-100 ${hasSearchIcon ? 'no-border-bottom' : ''}`}
                type='text'
                placeholder={!selectedItem ? placeholder : ''}
                onChange={this.onInputChange}
                onFocus={this.onInputFocus}
                defaultValue={selectedItem ? displayItem(selectedItem, searchInput) : undefined}
                ref={(r) => this.searchInput = r}
                disabled={disabled}
                // eslint-disable-next-line
                // keyValue is to force re-rendering of component in case defaultValue is externally changed after initial render.
                key={`autocompleteInput:${keyValue}`}
              />
              {
                suggestionsVisible &&
                <div className={`suggestions ${isDropdownFixed ? 'suggestions__dropdown-fixed' : ''} suggestions--size-${size} custom-scrollbar`}>
                  {
                    suggestions?.map((suggestion) => (
                      <div
                        key={suggestion[compareBy] as Key}
                        className={`suggestions__row ${(selectedItem !== null && suggestion[compareBy] === selectedItem[compareBy]) ? 'disabled' : ''}`}
                        onClick={this.onClickOnSuggestion.bind(null, suggestion)}
                      >
                        {displaySuggestionText(suggestion, searchInput)}
                      </div>
                    ))
                  }
                </div>
              }
            </>
          </OutsideClickWrapper>
        </div>
        <FormError
          error={error}
          enableMultiple={true}
          classes={'lockerManagerEdit__custom-items--decoration__error'}
        />
      </div>
    );

    if (hasSearchIcon) {
      return (
        <div className={`chips-search has-icon ${isInputActive ? 'active' : ''} ${classes}`}>
          <div className='mb-5'>
            <Icon materialIcon={'search'} />
          </div>
          {inputContent}
        </div>
      );
    }

    return inputContent;
  }
}

export default AutocompleteInput;
