import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Column } from 'react-table-6';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { ArtworkTableByStatusDto } from '@api/fulfillment/models';
import {
  rolesEnum,
  keyNameEnum,
  sortDirectionEnum,
} from '@constants/enums/commonEnums';
import { assignmentStatusEnum } from '@constants/enums/artworkEnums';
import {
  defaultPageSizeSmallTable,
  pageSizeOptionsSmallTable,
} from '@constants/values';
import { TableEnum } from '@constants/enums/tableEnums';
import { SortDirectionLong } from '@customTypes/table';
import { Assignee } from '@models/ProductionArtwork/Assignee';
import * as artworkActions from '@redux/artwork/actions';
import { lookupLockerByLockerId } from '@redux/artworkFileTasks/actions';
import { RootState } from '@redux/index/reducers';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { roleMapping } from '@util/roleCheck';
import { materialSwal } from '@util/componentHelper';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { jobLink } from '@util/customLink';
import DateWithOldDateCell from '@sharedComponents/Table/TableCells/DateWithOldDateCell';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Icon from '@sharedComponents/Icons/Icon';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import Table from '@sharedComponents/Table/Table';
import TableBulkActions from '@sharedComponents/Table/TableComponents/TableBulkActions';
import ProductionArtworkTabs from './ProductionArtworkTableContent/Tabs/ProductionArtworkTabs';
import AssigneeFilter from './ProductionArtworkTableContent/TableFilters/AssigneeFilter';
import ParentLockerFilter from './ProductionArtworkTableContent/TableFilters/ParentLockerFilter';
import AssignmentMenu from './ProductionArtworkTableContent/TableFilters/AssignmentMenu';
import DecorationFilter from './ProductionArtworkTableContent/TableFilters/DecorationFilter';
import PartnerFilter from './ProductionArtworkTableContent/TableFilters/PartnerFilter';

type ArtworkTableByStatusItem = ArtworkTableByStatusDto & { uid: number; };

const TasksTable = Table<ArtworkTableByStatusItem>();

const filterByAssignmentStatuses = [
  assignmentStatusEnum.InProgress,
  assignmentStatusEnum.QC,
  assignmentStatusEnum.Rework,
] as const;

interface OwnProps {
  tasksStatus: string;
}

const mapStateToProps = ({
  artwork,
  oidc,
  tableManager,
}: RootState) => ({
  queue: artwork.artworkDetails as ArtworkTableByStatusItem[],
  totalPages: artwork.tableState.totalPages,
  hasNextPage: artwork.tableState.hasNextPage,
  hasPreviousPage: artwork.tableState.hasPreviousPage,
  totalCount: artwork.tableState.totalCount,
  assignees: artwork.assignees as Assignee[],
  selectedAssignee: artwork.selectedAssignee as Assignee,
  roles: roleMapping(oidc),
  initialPageNumber: tableManager.productionArtwork.pageNumber,
  initialPageSize: tableManager.productionArtwork.pageSize,
  initialSortColumn: tableManager.productionArtwork.sortColumn,
  initialSortDirection: tableManager.productionArtwork.sortDirection,
  initialDecorationMethodFilter: tableManager.productionArtwork.decorationMethodFilter,
  initialResourceIdFilter: tableManager.productionArtwork.resourceIdFilter,
  initialPartnerNameFilter: tableManager.productionArtwork.partnerNameFilter,
  initialHasParentLockerFilter: tableManager.productionArtwork.hasParentLockerFilter,
});

const mapDispatchToProps = {
  updateArtworkTable: artworkActions.updateArtworkTable,
  fetchAssignees: artworkActions.fetchAssignees,
  assignTasks: artworkActions.assignTasks,
  updateSelectedAssignee: artworkActions.updateSelectedAssignee,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const TaskTable = React.memo<Props>(({
  queue,
  totalPages,
  hasNextPage,
  hasPreviousPage,
  totalCount,
  assignees,
  selectedAssignee,
  roles,
  tasksStatus,
  updateArtworkTable,
  fetchAssignees,
  assignTasks,
  updateSelectedAssignee,
  initialPageNumber,
  initialPageSize,
  initialSortColumn,
  initialSortDirection,
  initialDecorationMethodFilter,
  initialResourceIdFilter,
  initialPartnerNameFilter,
  initialHasParentLockerFilter,
}) => {
  const [
    confirmationModalIsOpen,
    setConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    confirmationModalBodyContent,
    setConfirmationModalBodyContent,
  ] = useState<string>('');

  const [
    selectedItems,
    setSelectedItems,
  ] = useState<ArtworkTableByStatusItem[]>([]);
  const [
    isPageSelected,
    setIsPageSelected,
  ] = useState<boolean>(false);

  const [
    pageNumber,
    setPageNumber,
  ] = useState<number>(initialPageNumber);
  const [
    pageSize,
    setPageSize,
  ] = useState<number>(initialPageSize);
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionLong>(initialSortDirection as SortDirectionLong);
  const [
    sortColumn,
    setSortColumn,
  ] = useState<string>(initialSortColumn);
  const [
    decorationMethodFilter,
    setDecorationMethodFilter,
  ] = useState<string>(initialDecorationMethodFilter);
  const [
    resourceIdFilter,
    setResourceIdFilter,
  ] = useState<string>(initialResourceIdFilter);
  const [
    partnerNameFilter,
    setPartnerNameFilter,
  ] = useState<string>(initialPartnerNameFilter);
  const [
    hasParentLockerFilter,
    setHasParentLockerFilter,
  ] = useState<string>(initialHasParentLockerFilter);

  useEffect(() => {
    fetchAssignees();
  }, [fetchAssignees]);

  const updateSelection = useCallback((newSelectedItems: ArtworkTableByStatusItem[], newIsPageSelected: boolean) => {
    setSelectedItems(newSelectedItems);
    setIsPageSelected(newIsPageSelected);
  }, []);

  const clearSelection = useCallback(() => {
    updateSelection([], false);
  }, [updateSelection]);

  const resetTable = useCallback(() => {
    setPageNumber(1);
    setPageSize(defaultPageSizeSmallTable);
    setSortColumn('shipDate');
    setSortDirection(sortDirectionEnum.Asc);
    setDecorationMethodFilter('');
    setResourceIdFilter('');
    setPartnerNameFilter('');
    setHasParentLockerFilter('');
  }, []);

  useEffect(() => {
    clearSelection();

    return () => {
      clearSelection();
    };
  }, [
    tasksStatus,
    queue,
    clearSelection,
  ]);

  const fetchData = useCallback((state: any, instance: any) => {
    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortColumn, sortDirection);

    setPageNumber(newPageNumber + 1);
    setPageSize(newPageSize);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
  }, [
    sortColumn,
    sortDirection,
  ]);

  const refreshData = useCallback(() => {
    const updatedState = {
      pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      decorationMethodFilter,
      resourceIdFilter,
      partnerNameFilter,
      hasParentLockerFilter,
    };

    updateArtworkTable(tasksStatus, updatedState);
  }, [
    updateArtworkTable,
    tasksStatus,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    decorationMethodFilter,
    resourceIdFilter,
    partnerNameFilter,
    hasParentLockerFilter,
  ]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const openAssignModal = useCallback(() => {
    if (selectedItems.length === 0) {
      materialSwal('Select some orders to assign.');

      return;
    }

    const selectedItemUids: number[] = selectedItems.map((item) => item.uid);

    const taskIds: number[] = selectedItemUids
      .map((uid) => queue.find((order) => order.uid === uid)?.taskIds ?? [])
      .reduce((a: number[], b: number[]) => a.concat(b), []);

    const tasksListDisplay: string = taskIds.map((task: number) => `F${task}`).join(', ');

    setConfirmationModalBodyContent(`Are you sure you want to assign ${tasksListDisplay} to ${selectedAssignee.displayName}?`);
    setConfirmationModalIsOpen(true);
  }, [
    selectedItems,
    queue,
    selectedAssignee,
  ]);

  const closeAssignModal = useCallback(() => {
    setConfirmationModalBodyContent('');
    setConfirmationModalIsOpen(false);
  }, []);

  const handleAssignModalConfirm = useCallback(async () => {
    const selectedItemUids: number[] = selectedItems.map((item) => item.uid);

    const taskIds: number[] = selectedItemUids
      .map((uid) => queue.find((order) => order.uid === uid)?.taskIds ?? [])
      .reduce((a: number[], b: number[]) => a.concat(b), []);

    const assignmentData = {
      resourceId: selectedAssignee.id,
      taskIds,
    };

    const res = await assignTasks(assignmentData, tasksStatus);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }

    closeAssignModal();
  }, [
    queue,
    selectedAssignee,
    tasksStatus,
    selectedItems,
    assignTasks,
    closeAssignModal,
  ]);

  const jobLinkCreator = (id: number, status: string) => {
    const creator = jobLink('artwork-file-tasks');

    return creator(id, status);
  };

  const filterKey = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInputValue: string = e.target.value;

    lookupLockerByLockerId(searchInputValue);
  }, []);

  const getStandardColumns = useCallback((): Array<Column<any>> => [
    {
      accessor: '',
      Header: <HeaderCell text={'Locker ID'} />,
      minWidth: 50,
      id: 'lockerId',
      sortable: true,
      Cell: (cellProps) => (
        <span>
          <div className='mt-10 flex'>
            {jobLinkCreator(cellProps.value.lockerId, cellProps.value.status)}
            <LabelsLegendDisplay
              labels={[
                {
                  isActive: cellProps.value.hasRequiredItems,
                  text: 'Required',
                  shortText: 'R',
                  type: 'electric-indigo',
                },
              ]}
            />
          </div>
        </span>
      ),
    },
    {
      accessor: 'taskCount',
      Header: <HeaderCell text={'Tasks'} />,
      minWidth: 40,
      sortable: true,
    },
    {
      accessor: 'lockerTeamName',
      Header: 'Locker Name',
      sortable: false,
    },
    {
      accessor: 'lockerPartnerName',
      Header: 'Partner Name',
      minWidth: 60,
      sortable: false,
    },
    {
      accessor: 'decorationMethod',
      Header: 'Type',
      minWidth: 40,
      sortable: false,
    },
    {
      accessor: '',
      id: 'shipDate',
      Header: <HeaderCell text={'Ship Date'} />,
      minWidth: 100,
      sortable: true,
      Cell: (cellProps) => (
        <DateWithOldDateCell
          oldDate={cellProps.value.oldShipDate}
          date={cellProps.value.shipDate}
          isExpressProduction={cellProps.value.isExpressProduction}
        />
      ),
    },
  ], []);

  const getUnnasignedColumns = useCallback((): Array<Column<any>> => [
    ...getStandardColumns(),
    {
      accessor: 'parentLockerId',
      Header: 'Parent Locker Number',
      sortable: false,
      Cell: (cellProps) => cellProps.value && `L${cellProps.value}`,
    },
  ], [getStandardColumns]);

  const getAssignedAndReworkColumns = useCallback((): Array<Column<any>> => [
    ...getStandardColumns(),
    {
      accessor: 'assignedTo',
      Header: 'Assigned To',
      sortable: false,
    },
    {
      accessor: 'assignedOn',
      Header: <HeaderCell text={'Assigned On'} />,
      minWidth: 75,
      sortable: true,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
  ], [getStandardColumns]);

  const getReadyForQCColumns = useCallback((): Array<Column<any>> => [
    ...getStandardColumns(),
    {
      accessor: 'assignedTo',
      Header: 'Assigned To',
      sortable: false,
      width: 150,
    },
    {
      accessor: 'sentToQcBy',
      Header: 'Sent to QC by',
      sortable: false,
      width: 150,
    },
    {
      accessor: 'sentToQcOn',
      Header: 'QC Date',
      minWidth: 75,
      sortable: false,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
  ], [getStandardColumns]);

  const getProductionColumns = useCallback((): Array<Column<any>> => [
    ...getStandardColumns(),
    {
      accessor: 'sentToProductionOn',
      Header: 'Sent to Production',
      minWidth: 75,
      sortable: false,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
    {
      accessor: 'productionReadyOn',
      Header: 'Production Ready',
      minWidth: 75,
      sortable: false,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
  ], [getStandardColumns]);

  const getPriorityColumns = useCallback((): Array<Column<any>> => [
    ...getStandardColumns(),
    {
      accessor: '',
      Header: 'Reason for Priority',
      minWidth: 75,
      sortable: false,
      Cell: (cellProps) => cellProps.value.reworkReason ? cellProps.value.reworkReason : cellProps.value.rushReason,
    },
  ], [getStandardColumns]);

  const getColumns = useCallback((): Array<Column<any>> => {
    let columns = [];
    switch (tasksStatus) {
      case assignmentStatusEnum.InProgress:
        columns = getAssignedAndReworkColumns();
        break;
      case assignmentStatusEnum.Rework:
        columns = getAssignedAndReworkColumns();
        break;
      case assignmentStatusEnum.QC:
        columns = getReadyForQCColumns();
        break;
      case assignmentStatusEnum.Production:
        columns = getProductionColumns();
        break;
      case assignmentStatusEnum.Priority:
        columns = getPriorityColumns();
        break;
      default:
        columns = getUnnasignedColumns();
        break;
    }

    return columns;
  }, [
    tasksStatus,
    getAssignedAndReworkColumns,
    getReadyForQCColumns,
    getProductionColumns,
    getPriorityColumns,
    getUnnasignedColumns,
  ]);

  const setAssignee = useCallback((value) => {
    const assignee = assignees.find((a) => a.id === value);

    updateSelectedAssignee(assignee);
  }, [
    assignees,
    updateSelectedAssignee,
  ]);

  const getWrappedColumns = useCallback((columns) => {
    const showAssignmentMenu: boolean = (
      tasksStatus === assignmentStatusEnum.Unassigned || tasksStatus === assignmentStatusEnum.InProgress
    ) && !roles.includes(rolesEnum.ProductionArtworkVendor);

    const assignmentMenu: JSX.Element = showAssignmentMenu
      ? (
        <div id='assignment-select-container'>
          <AssignmentMenu
            assignees={assignees}
            setAssignee={setAssignee}
            handleAssign={openAssignModal}
            selectedAssignee={selectedAssignee}
          />
        </div>
      )
      : <div />;

    const wrappedColumns = [
      {
        Header: (
          <TableBulkActions
            selectedItems={selectedItems}
            clearAll={clearSelection}
            bulkActions={assignmentMenu}
          />
        ),
        columns,
      },
    ];

    return wrappedColumns;
  }, [
    tasksStatus,
    assignees,
    selectedAssignee,
    openAssignModal,
    selectedItems,
    clearSelection,
    roles,
    setAssignee,
  ]);

  const filterByDecorationType = useCallback((value: string) => {
    setDecorationMethodFilter(value);

    clearSelection();
  }, [clearSelection]);

  const filterByHasParentLocker = useCallback((value) => {
    setHasParentLockerFilter(value);

    clearSelection();
  }, [clearSelection]);

  const filterByPartnerName = useCallback((value: string) => {
    setPartnerNameFilter(value);

    clearSelection();
  }, [clearSelection]);

  const filterByAssignee = useCallback((value: string) => {
    setResourceIdFilter(value);

    clearSelection();
  }, [clearSelection]);

  const assigneeFilter = !roles.includes(rolesEnum.ProductionArtworkVendor) &&
    filterByAssignmentStatuses.includes(tasksStatus as typeof filterByAssignmentStatuses[number])
    ? (
      <AssigneeFilter
        status={tasksStatus}
        filter={filterByAssignee}
        assignees={assignees}
      />
    )
    : <div />;

  const parentLockerFilter = useMemo(() => (
    tasksStatus === assignmentStatusEnum.Unassigned
      ? <ParentLockerFilter filter={filterByHasParentLocker} />
      : null
  ), [
    tasksStatus,
    filterByHasParentLocker,
  ]);

  const columns = useMemo(() => (
    getWrappedColumns(getColumns())
  ), [
    getColumns,
    getWrappedColumns,
  ]);

  const filtersToPreserve = useMemo(() => ({
    partnerNameFilter,
    decorationMethodFilter,
    parentLockerFilter,
    hasParentLockerFilter,
    resourceIdFilter,
    selectedTab: tasksStatus,
  }), [
    partnerNameFilter,
    decorationMethodFilter,
    parentLockerFilter,
    hasParentLockerFilter,
    resourceIdFilter,
    tasksStatus,
  ]);

  if (!tasksStatus) return null;

  return (
    <>
      <SimpleConfirmationModal
        isOpen={confirmationModalIsOpen}
        confirm={handleAssignModalConfirm}
        closeModal={closeAssignModal}
        title={'Assign Tasks'}
        confirmationBody={confirmationModalBodyContent}
      />
      <div className='print'>
        <div className='art-vectorization-results'>
          <div className='table-options w-100'>
            <div className='filter-groups'>
              <PartnerFilter
                filter={filterByPartnerName}
                initialValue={initialPartnerNameFilter}
              />
              <DecorationFilter filter={filterByDecorationType} />
              {assigneeFilter}
              {parentLockerFilter}
            </div>
            <div className='text-field'>
              <input
                type='text'
                className='has-icon--right'
                placeholder='Jump to locker'
                onKeyDown={filterKey}
              />
              <Icon
                materialIcon={'arrow_forward'}
                classes={'text-field__icon--right'}
              />
            </div>
          </div>
          <div className='sheet'>
            <div className='sheet__list'>
              <ProductionArtworkTabs resetTable={resetTable} />
              <div className='w-100'>
                <TasksTable
                  data={queue}
                  columns={columns}
                  defaultPageSize={defaultPageSizeSmallTable}
                  pageSizeOptions={pageSizeOptionsSmallTable}
                  onFetchData={fetchData}
                  totalPages={totalPages}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  selectable={true}
                  selectPredicateOrKey={'uid'}
                  updateSelection={updateSelection}
                  selectedData={selectedItems}
                  isPageSelected={isPageSelected}
                  totalCount={totalCount}
                  isBulkActionsMode={selectedItems?.length > 0}
                  tableId={TableEnum.productionArtwork}
                  preserveState={true}
                  sortDirEnum={sortDirectionEnum}
                  initialPageNumber={initialPageNumber}
                  initialPageSize={initialPageSize}
                  initialSortColumn={initialSortColumn}
                  initialSortDirection={initialSortDirection}
                  filtersToPreserve={filtersToPreserve}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default connector(TaskTable);
