import { materialSwal } from '../componentHelper';

export const onLockerEditSuccess = (response) => {
  materialSwal('Success', response.message, 'success');
};

export const onLockerEditError = (error) => {
  let errorMessage = '';
  if (error.result && Array.isArray(error.result)) {
    for (let i = 0; i < error.result.length; i++) {
      errorMessage += error.result[i];
    }
  }

  materialSwal('Error', errorMessage || error.message || 'Something went wrong', 'error');
};
