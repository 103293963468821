import React from 'react';
import { fundraisingPayoutsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const FundraisingPayoutsLink = React.memo(() => (
  <MenuTile
    title={'Fundraising Payouts'}
    url={fundraisingPayoutsUrl}
    icon={'account_balance'}
  />
));

export default FundraisingPayoutsLink;
