import * as actionTypes from './types';

export const beginRequest = () => ({
  type: actionTypes.BEGIN_REQUEST,
});

export const endRequest = () => ({
  type: actionTypes.END_REQUEST,
});

export const beginBlockingRequest = () => ({
  type: actionTypes.BEGIN_BLOCKING_REQUEST,
});

export const endBlockingRequest = () => ({
  type: actionTypes.END_BLOCKING_REQUEST,
});
