import React from 'react';

interface OwnProps {
  imageUrl: string | null | undefined;
}

type Props = OwnProps;

const PrintRoomOrderRejectImageCell = React.memo<Props>(({ imageUrl }) => {
  if (!imageUrl) {
    return null;
  }

  return (
    <a className='details__item-thumbnail button image-preview-cell details__item-thumbnail--color-transparent'>
      <i
        className='details__item-image'
        style={{ backgroundImage: `url("${imageUrl}")` }}
      />
    </a>
  );
});

export default PrintRoomOrderRejectImageCell;
