import React from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  adminsUrl,
  adminDetailsBaseUrl,
  advancedOptionsUrl,
  applicationSettingsUrl,
  serviceManagerUrl,
  artVectorizationUrl,
  artworkTasksUrl,
  backorderManagementUrl,
  collectionDetailsBaseUrl,
  reorderCollectionItemsPath,
  couponsUrl,
  fundraisingPayoutsUrl,
  llSchedulingUrl,
  llSchedulingDetailsUrl,
  lockerManagerUrl,
  logoBankUrl,
  lockerDetailsBaseUrl,
  logoReviewUrl,
  lookupEntitiesUrl,
  brandsUrl,
  categoriesUrl,
  decorationLocationsUrl,
  decorationLocationArtworkSizesUrl,
  colorsUrl,
  sportsUrl,
  orderDetailsNewOrder,
  onHandInventoryUrl,
  orderDetailsNewOrderAddItems,
  orderDetailsBaseUrl,
  orderSearchUrl,
  organizationsUrl,
  organizationDetailsBaseUrl,
  organizationGroupsBaseUrl,
  parentCategoriesUrl,
  printFilesUrl,
  printAssemblyUrl,
  printSheetBaseUrl,
  productionUrl,
  orderAssemblyUrl,
  productionAssemblyUrl,
  productionAssemblyHagUrl,
  productionAssemblyEmbUrl,
  productionAssemblyDtgUrl,
  productCatalogUrl,
  rosterManagerUrl,
  rosterDetailsBaseUrl,
  skuSetupUrl,
  layoutsUrl,
  vendorsUrl,
  vendorOrdersUrl,
  dtgEnvironmentsUrl,
  dtgArtworkSettingsUrl,
  dtgPersonalizationSettingsUrl,
  dtgEntitiesUrl,
  dtgStationsUrl,
  dtgTabletProfilesUrl,
  qualityCheckUrl,
  warehouseSchedulingUrl,
  voucherOrdersUrl,
  addVoucherOrderUrl,
  voucherOrderDetailsBaseUrl,
  productionOverviewUrl,
  bulkActionsUrl,
  bulkAddItemUrl,
  bulkAddStyleUrl,
  printRoomOrderUrl,
  printRoomOrderBaseUrl,
  qualityAssuranceCodesUrl,
  batchWarehouseSchedulingUrl,
  merchandisingUrl,
  reportsUrl,
  orderItemFilesUrl,
} from '@constants/clientUrls/clientUrls';
import { RootState } from '@redux/index/reducers';
import TextHeaderTitle from './TextHeaderTitle';
import ArtworkFileTasksDetails from './ArtworkFileTasksHeader/ArtworkFileTasksDetails';
import OrderManagementSidebarSteps from './OrderManagementHeader/OrderManagementSidebarSteps';
import OrderManagementHeaderTitle from './OrderManagementHeader/OrderManagementHeaderTitle';
import PrintSheetWithStatusHeader from './PrintSheetHeader/PrintSheetWithStatusHeader';
import WeedingAndMaskingHeader from './WeedingAndMaskingHeader/WeedingAndMaskingHeader';
import OrderManagementTable from './OrderManagementHeader/OrderManagementTable';
import OrderManagementAddNewOrderHeaderTitle from './OrderManagementHeader/OrderManagementAddNewOrderHeaderTitle';
import LockerManagerDetailsHeader from './LockerManagerHeader/LockerManagerDetailsHeader';
import OrganizationsManagePublicPageHeader from './OrganizationsHeader/OrganizationsManagePublicPageHeader';
import CollectionDetailsHeader from './CollectionsHeader/CollectionDetailsHeader';
import ReorderCollectionItemsHeader from './CollectionsHeader/ReorderCollectionItemsHeader';
import VoucherOrderCreationHeaderSteps from './VouchersHeader/VoucherOrderCreationHeaderSteps';
import VoucherOrderDetailsHeader from './VouchersHeader/VoucherOrderDetailsHeader';
import { useFlags } from 'launchdarkly-react-client-sdk';
import StatusHeaderTitle from './StatusHeaderTitle';

interface OwnProps {
  location?: string;
  schedulingQueue: any;
}

const mapStateToProps = ({ llScheduling }: RootState) => ({
  schedulingQueue: llScheduling.schedulingQueue,
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const HeaderContents = React.memo(({
  location,
  schedulingQueue,
}: Props) => {
  const { hfHideOldProduction } = useFlags();

  if (location?.includes(adminsUrl)) {
    return (
      <TextHeaderTitle text={'Admin Manager'} />
    );
  }

  if (location?.includes(adminDetailsBaseUrl)) {
    return (
      <TextHeaderTitle text={'Admin Manager'} />
    );
  }

  if (location?.includes(advancedOptionsUrl)) {
    return (
      <TextHeaderTitle text={'Advanced Options'} />
    );
  }

  if (location?.includes(orderDetailsNewOrder)) {
    return (
      <div className='bar__long'>
        <div className='bar__order flex'>
          <OrderManagementAddNewOrderHeaderTitle />
          <OrderManagementSidebarSteps isTempOrder={true} />
        </div>
      </div>
    );
  }

  if (location?.includes(orderDetailsNewOrderAddItems)) {
    return (
      <TextHeaderTitle text={'Add Items to Order'} />
    );
  }

  if (location?.includes(applicationSettingsUrl)) {
    return (
      <TextHeaderTitle text={'Application Settings'} />
    );
  }

  if (location?.includes(artVectorizationUrl)) {
    return (
      <TextHeaderTitle text={'Production Artwork'} />
    );
  }

  if (location?.includes(artworkTasksUrl)) {
    return (
      <div className='bar__long'>
        <ArtworkFileTasksDetails />
      </div>
    );
  }

  if (location?.includes(backorderManagementUrl)) {
    return (
      <TextHeaderTitle text={'Backorder Management'} />
    );
  }

  if (location?.includes(brandsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Brands'} />
    );
  }

  if (location?.includes(collectionDetailsBaseUrl) && location?.includes(reorderCollectionItemsPath)) {
    return (
      <ReorderCollectionItemsHeader />
    );
  }

  if (location?.includes(collectionDetailsBaseUrl)) {
    return (
      <CollectionDetailsHeader />
    );
  }

  if (location?.includes(couponsUrl)) {
    return (
      <TextHeaderTitle text={'Coupon Codes'} />
    );
  }

  if (location?.includes(categoriesUrl)) {
    return (
      <TextHeaderTitle text={'Manage Categories'} />
    );
  }

  if (location?.includes(parentCategoriesUrl)) {
    return (
      <TextHeaderTitle text={'Manage Parent Categories'} />
    );
  }

  if (location?.includes(decorationLocationsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Decoration Locations'} />
    );
  }

  if (location?.includes(decorationLocationArtworkSizesUrl)) {
    return (
      <TextHeaderTitle text={'Manage Artwork Sizes'} />
    );
  }

  if (location?.includes(colorsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Colors'} />
    );
  }

  if (location?.includes(layoutsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Layouts'} />
    );
  }

  if (location?.includes(vendorsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Vendors'} />
    );
  }

  if (location?.includes(fundraisingPayoutsUrl)) {
    return (
      <TextHeaderTitle text={'Fundraising Payouts'} />
    );
  }

  if (location?.includes(logoReviewUrl)) {
    return (
      <TextHeaderTitle text={'Logo Review'} />
    );
  }

  if (location?.includes(lockerDetailsBaseUrl) || location?.includes(logoBankUrl)) {
    return <LockerManagerDetailsHeader />;
  }

  if (location?.includes(lockerManagerUrl)) {
    return (
      <TextHeaderTitle text={'Locker Manager'} />
    );
  }

  if (location?.includes(lookupEntitiesUrl)) {
    return (
      <TextHeaderTitle text={'Lookup Entities'} />
    );
  }

  if (location?.includes(merchandisingUrl)) {
    return (
      <TextHeaderTitle text={'Merchandising'} />
    );
  }

  if (location?.includes(onHandInventoryUrl)) {
    return (
      <TextHeaderTitle text={'On-Hand Inventory'} />
    );
  }

  if (location?.includes(productCatalogUrl)) {
    return (
      <TextHeaderTitle text={'Product Catalog'} />
    );
  }

  if (location?.includes(printFilesUrl)) {
    return (
      <TextHeaderTitle text={'Print Room'} />
    );
  }

  if (location?.includes(rosterManagerUrl)) {
    return (
      <TextHeaderTitle text={'Roster Manager'} />
    );
  }

  if (location?.includes(rosterDetailsBaseUrl)) {
    return (
      <TextHeaderTitle text={'Roster Details'} />
    );
  }

  if (location?.includes(orderDetailsBaseUrl)) {
    return (
      <div className='bar__long'>
        <div className='bar__order flex'>
          <OrderManagementHeaderTitle />
          <OrderManagementSidebarSteps />
        </div>
      </div>
    );
  }

  if (location?.includes(orderSearchUrl)) {
    return <OrderManagementTable />;
  }

  if (location?.includes(organizationsUrl)) {
    return (
      <TextHeaderTitle text={'Organizations'} />
    );
  }

  if (location?.includes(organizationGroupsBaseUrl)) {
    return (
      <OrganizationsManagePublicPageHeader />
    );
  }

  if (location?.includes(organizationDetailsBaseUrl)) {
    return (
      <TextHeaderTitle text={'Organization Details'} />
    );
  }

  if (location?.includes(printSheetBaseUrl)) {
    return (
      <PrintSheetWithStatusHeader />
    );
  }

  if (location?.includes(printRoomOrderBaseUrl)) {
    return (
      <StatusHeaderTitle />
    );
  }

  if (location?.includes(printAssemblyUrl)) {
    return <WeedingAndMaskingHeader />;
  }

  if (location?.includes(productCatalogUrl)) {
    return (
      <TextHeaderTitle text={'Product Catalog'} />
    );
  }

  if (location?.includes(productionAssemblyUrl) && !hfHideOldProduction) {
    return (
      <TextHeaderTitle text={'Production'} />
    );
  }

  if (location?.includes(orderAssemblyUrl)) {
    return (
      <TextHeaderTitle text={'Order Assembly'} />
    );
  }

  if (location?.includes(qualityCheckUrl)) {
    return (
      <TextHeaderTitle text={'Quality Check'} />
    );
  }

  if (location?.includes(productionAssemblyHagUrl)) {
    return (
      <TextHeaderTitle text={'HAG Production Assembly'} />
    );
  }

  if (location?.includes(productionAssemblyDtgUrl)) {
    return (
      <TextHeaderTitle text={'DTG Production Assembly'} />
    );
  }

  if (location?.includes(productionAssemblyEmbUrl)) {
    return (
      <TextHeaderTitle text={'EMB Production Assembly'} />
    );
  }

  if (location?.includes(productionOverviewUrl)) {
    return (
      <TextHeaderTitle text={'Production Overview'} />
    );
  }

  if (location?.includes(productionUrl)) {
    return (
      <TextHeaderTitle text={'Production'} />
    );
  }

  if (location?.includes(llSchedulingDetailsUrl)) {
    return (
      <TextHeaderTitle text={`${schedulingQueue.decorationMethod !== null ? schedulingQueue.decorationMethod : 'No decoration'} Scheduling`} />
    );
  }

  if (location?.includes(llSchedulingUrl)) {
    return (
      <TextHeaderTitle text={'LL Scheduling'} />
    );
  }

  if (location?.includes(serviceManagerUrl)) {
    return (
      <TextHeaderTitle text={'Service Manager'} />
    );
  }

  if (location?.includes(sportsUrl)) {
    return (
      <TextHeaderTitle text={'Manage Sports'} />
    );
  }

  if (location?.includes(skuSetupUrl)) {
    return (
      <TextHeaderTitle text={'SKU Setup'} />
    );
  }

  if (location?.includes(vendorOrdersUrl)) {
    return (
      <TextHeaderTitle text={'Vendor Order Management'} />
    );
  }

  if (location?.includes(dtgEnvironmentsUrl)) {
    return (
      <TextHeaderTitle text={'DTG Environment Settings'} />
    );
  }

  if (location?.includes(dtgArtworkSettingsUrl)) {
    return (
      <TextHeaderTitle text={'DTG Artwork Settings'} />
    );
  }

  if (location?.includes(dtgPersonalizationSettingsUrl)) {
    return (
      <TextHeaderTitle text={'DTG Personalization Settings'} />
    );
  }

  if (location?.includes(dtgStationsUrl)) {
    return (
      <TextHeaderTitle text={'DTG Stations'} />
    );
  }

  if (location?.includes(dtgTabletProfilesUrl)) {
    return (
      <TextHeaderTitle text={'DTG Tablet Profiles'} />
    );
  }

  if (location?.includes(dtgEntitiesUrl)) {
    return (
      <TextHeaderTitle text={'DTG Printer'} />
    );
  }

  if (location?.includes(warehouseSchedulingUrl)) {
    return (
      <TextHeaderTitle text={'Scheduling 2.0'} />
    );
  }

  if (location?.includes(addVoucherOrderUrl)) {
    return (
      <div className='bar__long'>
        <div className='bar__order flex'>
          <TextHeaderTitle text={'Add Voucher Order'} />
          <VoucherOrderCreationHeaderSteps />
        </div>
      </div>
    );
  }

  if (location?.includes(voucherOrderDetailsBaseUrl)) {
    return (
      <VoucherOrderDetailsHeader location={location!} />
    );
  }

  if (location?.includes(voucherOrdersUrl)) {
    return (
      <TextHeaderTitle text={'Voucher Orders'} />
    );
  }

  if (location?.includes(bulkAddItemUrl)) {
    return (
      <TextHeaderTitle text={'Add Replacement Item'} />
    );
  }

  if (location?.includes(bulkActionsUrl)) {
    return (
      <TextHeaderTitle text={'Bulk Actions'} />
    );
  }

  if (location?.includes(bulkAddStyleUrl)) {
    return (
      <TextHeaderTitle text={'Add Style to All Stores'} />
    );
  }

  if (location?.includes(qualityAssuranceCodesUrl)) {
    return (
      <TextHeaderTitle text={'Quality Assurance Codes'} />
    );
  }

  if (location?.includes(batchWarehouseSchedulingUrl)) {
    return (
      <StatusHeaderTitle />
    );
  }

  if (location?.includes(reportsUrl)) {
    return (
      <TextHeaderTitle text={'Reports'} />
    );
  }

  if (location?.includes(orderItemFilesUrl)) {
    return (
      <TextHeaderTitle text={'Order Item Files'} />
    );
  }

  return null;
});

export default connector(HeaderContents);
