import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';
import {
  addColor,
  editColor,
  deleteColor,
} from '@APICalls/color/actions';
import { colorForm } from '@constants/reduxForms';
import { brightnessOptions } from '@constants/options/optionsValues';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { getColors } from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import ColorAddModal from './ColorModals/ColorAddModal';
import ColorEditModal from './ColorModals/ColorEditModal';
import ColorDeleteModal from './ColorModals/ColorDeleteModal';
import ColorsActionsColumn from './ColorsActionsColumn';

const ColorsTable = Table();

class Colors extends PureComponent {
  state = {
    addColorModalIsOpened: false,
    editColorModalIsOpened: false,
    deleteColorsModalIsOpened: false,
    selectedColor: null,
    filteredData: this.props.queue,
  };

  componentDidMount() {
    this.fetchColors();
  }

  componentDidUpdate(oldProps) {
    const { queue } = this.props;

    if (oldProps.queue !== queue) {
      this.setState(() => ({ filteredData: this.filterData() }));
    }
  }

  getColumns = () => [
    {
      Header: 'ID',
      accessor: 'id',
      minWidth: 40,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Code',
      accessor: 'code',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Display Name',
      accessor: 'displayName',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'DTG Code',
      accessor: 'dtgCode',
      minWidth: 120,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Brightness',
      accessor: 'brightness',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Cmyk',
      accessor: 'cmykValue',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'DTG Cmyk',
      accessor: 'dtgCmykValue',
      minWidth: 120,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Thread',
      accessor: 'threadValue',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Hex',
      accessor: 'hexValue',
      minWidth: 100,
      Cell: (cellProps) => (
        cellProps.value &&
        <div className='color-swatch-item size--small'>
          <span
            className='color-swatch-item__color size--small mr-5'
            style={{ backgroundColor: cellProps.value }}
          />
          <span>{cellProps.value}</span>
        </div>
      ),
    },
    {
      Header: '',
      accessor: '',
      minWidth: 40,
      resizable: false,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <ColorsActionsColumn
          color={cellProps.value}
          onColorEdit={this.openEditColorModal}
          onColorDelete={this.openDeleteColorsModal}
        />
      ),
    },
  ];

  fetchColors = () => {
    const { dispatch } = this.props;

    dispatch(getColors());
  };

  filterData = (searchInput) => {
    const search = (searchInput || '').toLowerCase();
    const { queue } = this.props;

    if (search === '') {
      return queue;
    }

    return queue.filter((color) => color.code.toLowerCase().includes(search));
  };

  clearSearch = () => {
    this.setState(() => ({ filteredData: this.filterData('') }));
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({ filteredData: this.filterData(searchInput) }));
  };

  addColor = async (form) => {
    const result = await addColor(form);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeAddColorModal();
      this.fetchColors();
    }
  };

  editColor = async (form) => {
    const result = await editColor(form);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeEditColorModal();
      this.fetchColors();
    }
  };

  deleteColor = async (colorId) => {
    const result = await deleteColor(colorId);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteColorsModal();
      this.fetchColors();
    }
  };

  openAddColorModal = () => {
    this.setState(() => ({ addColorModalIsOpened: true }));
  };

  closeAddColorModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(colorForm));
    this.setState(() => ({
      addColorModalIsOpened: false,
      selectedColor: null,
    }));
  };

  openEditColorModal = (color) => {
    this.setState(() => ({
      selectedColor: color,
      editColorModalIsOpened: true,
    }));
  };

  closeEditColorModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(colorForm));
    this.setState(() => ({
      editColorModalIsOpened: false,
      selectedColor: null,
    }));
  };

  openDeleteColorsModal = (color) => {
    this.setState(() => ({
      selectedColor: color,
      deleteColorsModalIsOpened: true,
    }));
  };

  closeDeleteColorsModal = () => {
    this.setState(() => ({
      deleteColorsModalIsOpened: false,
      selectedColor: null,
    }));
  };

  render() {
    const {
      selectedColor,
      addColorModalIsOpened,
      editColorModalIsOpened,
      deleteColorsModalIsOpened,
      filteredData,
    } = this.state;
    const colorInitialValue = !selectedColor
      ? { brightness: brightnessOptions[0].value }
      : selectedColor;

    return (
      <div className='container'>
        <ColorAddModal
          isOpen={addColorModalIsOpened}
          closeModal={this.closeAddColorModal}
          onSubmit={this.addColor}
          initialValues={colorInitialValue}
        />

        <ColorEditModal
          isOpen={editColorModalIsOpened}
          closeModal={this.closeEditColorModal}
          onSubmit={this.editColor}
          initialValues={colorInitialValue}
        />

        <ColorDeleteModal
          isOpen={deleteColorsModalIsOpened}
          closeModal={this.closeDeleteColorsModal}
          color={colorInitialValue}
          deleteColor={this.deleteColor}
        />

        <div className='flex'>
          <div className='table-options w-100'>
            <div className='flex'>
              <SearchFilter
                search={this.onSearch}
                clearSearch={this.clearSearch}
              />
            </div>
            <div className='flex'>
              <Button
                type={'primary'}
                text={'Add Color'}
                onClick={this.openAddColorModal}
                classes={'colors__add-btn'}
              />
            </div>
          </div>
        </div>
        <div className='sheet'>
          <ColorsTable
            data={filteredData}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}

Colors.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string.isRequired,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.colors,
});

export default connect(mapStateToProps)(Colors);
