import React, { PureComponent } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  Link,
  RouteComponentProps,
} from 'react-router-dom';
import { Column } from 'react-table-6';
import { reset } from 'redux-form';
import { SubmitStatusEnum } from '@api/fulfillment/models';
import { SortDirectionLong } from '@customTypes/table';
import {
  orderDetailsUrl,
  orderDetailsNewOrder,
} from '@constants/clientUrls/clientUrls';
import { FilterOrdersOptionsEnum } from '@constants/enums/orderEnums';
import { DateRangeEnum } from '@constants/enums/dateRangeEnum';
import {
  sortDirectionEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { TableEnum } from '@constants/enums/tableEnums';
import { getOrderFilterOptions } from '@constants/options/options';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import {
  pageSizeOptionsSmallTable,
  defaultPageSizeSmallTable,
  dropdownSizeM,
} from '@constants/values';
import { createCustomOrderForm } from '@constants/reduxForms';
import { featureFlags } from '@constants/common';
import { Option } from '@models/common/Option';
import { CustomOrderFormData } from '@models/forms/OrderManagement/CustomOrderFormData';
import * as orderManagementActions from '@redux/orderManagement/actions';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import { RootState } from '@redux/index/reducers';
import { formatDollarAmount } from '@util/numberHelpers';
import { parseDateNumeric } from '@util/dateHandler';
import { extractParameterFromPath } from '@util/stringHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { navigateToPage } from '@util/componentHelper';
import memoizeOne from '@util/memoHelper';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';
import Button from '@sharedComponents/Buttons/Button';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import OrderManagementQuickView from './OrderManagementQuickView/OrderManagementQuickView';
import CreateCustomOrderModal from './OrderManagementModals/CreateCustomOrderModal';
import DateRangeFilter from './DateRangeFilter';
import { mapToOptionsList } from '@util/mappingHelper';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

const OrdersTable = Table<any>();

const filterOrdersOptions: Option<string>[] = getOrderFilterOptions();

interface OwnProps {
  orderNumber: number;
}

const mapStateToProps = ({
  oidc,
  orderManagement,
  tableManager,
  lockerManager,
}: RootState) => ({
  initialPageNumber: tableManager.orderManagement.pageNumber,
  initialPageSize: tableManager.orderManagement.pageSize,
  initialSortColumn: tableManager.orderManagement.sortColumn,
  initialSortDirection: tableManager.orderManagement.sortDirection,
  initialSearchInput: tableManager.orderManagement.searchInput,
  initialLockerIdsFilter: tableManager.orderManagement.lockerIdsFilter,
  initialOnlyPrdOrdersFilter: tableManager.orderManagement.onlyPrdOrdersFilter,
  initialDateRangeFilter: tableManager.orderManagement.dateRangeFilter,
  initialAccountingSubmitStatusFilter: tableManager.orderManagement.accountingSubmitStatusFilter,
  initialUserIdFilter: tableManager.orderManagement.userIdFilter,
  initialOnlyRequiredItemOrdersFilter: tableManager.orderManagement.onlyRequiredItemOrdersFilter,
  totalPages: orderManagement.currentQueue.totalPages,
  hasPreviousPage: orderManagement.currentQueue.hasPreviousPage,
  hasNextPage: orderManagement.currentQueue.hasNextPage,
  queue: orderManagement.currentQueue.queue,
  user: oidc.user,
  initialPartnerFilter: tableManager.orderManagement.partnerFilter,
  partners: lockerManager.partners,
});

const mapDispatchToProps = {
  fetchOrderTableStateUpdate: orderManagementActions.fetchOrderTableStateUpdate,
  saveTempOrder: orderManagementActions.saveTempOrder,
  resetForm: reset,
  fetchPartners: lockerManagerActions.fetchPartners,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector> & RouteComponentProps;

interface State {
  sortColumn: string;
  sortDirection: SortDirectionLong;
  searchInput: string;
  lockerIdsFilter: string;
  dateRangeFilter: keyof DateRangeEnum;
  accountingSubmitStatusFilter: SubmitStatusEnum | undefined;
  userIdFilter: string;
  onlyPrdOrdersFilter: boolean;
  onlyRequiredItemOrdersFilter: boolean;

  selectedOrder: Nullable<number>;
  showCreateCustomOrderModal: boolean;
  partnerFilter?: string | undefined;
}

class OrderManagement extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.intializePartnerData();
    this.state = {
      sortDirection: this.props.initialSortColumn as SortDirectionLong,
      sortColumn: this.props.initialSortDirection,
      searchInput: this.initializeSearchInput(),
      lockerIdsFilter: this.initializeLockerIdsFilter(),
      dateRangeFilter: this.props.initialDateRangeFilter,
      accountingSubmitStatusFilter: this.props.initialAccountingSubmitStatusFilter,
      userIdFilter: this.props.initialUserIdFilter,
      onlyPrdOrdersFilter: this.props.initialOnlyPrdOrdersFilter,
      onlyRequiredItemOrdersFilter: this.props.initialOnlyRequiredItemOrdersFilter,
      selectedOrder: null,
      showCreateCustomOrderModal: false,
      partnerFilter: this.props.initialPartnerFilter,
    };
  }

  initializeSearchInput = () => {
    const { initialSearchInput } = this.props;

    if (!!initialSearchInput) {
      return initialSearchInput;
    }

    const filter = extractParameterFromPath(this.props, 'filter') as string;

    return filter;
  };

  initializeLockerIdsFilter = () => {
    const { initialLockerIdsFilter } = this.props;

    if (!!initialLockerIdsFilter) {
      return initialLockerIdsFilter;
    }

    const lockerIds = extractParameterFromPath(this.props, 'lockerIds') as string;

    return lockerIds;
  };

  intializePartnerData = () => {
    this.props.fetchPartners();
  };

  openCreateCustomOrderModal = () => {
    this.setState(() => ({ showCreateCustomOrderModal: true }));
  };

  closeCreateCustomOrderModal = () => {
    const { resetForm } = this.props;
    resetForm(createCustomOrderForm);
    this.setState(() => ({ showCreateCustomOrderModal: false }));
  };

  selectOrder = (orderNumber: number) => {
    this.setState(() => ({ selectedOrder: orderNumber }));
  };

  unselectOrder = () => {
    this.setState(() => ({ selectedOrder: null }));
  };

  getColumns = (selectedOrder: any) => {
    let columns: Array<Column<any>> = [
      {
        Header: <HeaderCell text={'Received on'} />,
        accessor: 'datePlaced',
        sortable: true,
        minWidth: 65,
        Cell: (cellProps) => <span>{parseDateNumeric(cellProps.value)}</span>,
      },
      {
        Header: 'Order ID',
        accessor: '',
        sortable: false,
        minWidth: 50,
        Cell: (cellProps) => (
          <div className='flex'>
            <Link to={orderDetailsUrl(cellProps.value.orderNumber)}>O{cellProps.value.orderNumber}</Link>
            <LabelsLegendDisplay
              labels={[
                {
                  isActive: cellProps.value.hasRequiredItems,
                  text: 'Required',
                  shortText: 'R',
                  type: 'electric-indigo',
                },
              ]}
            />
          </div>
        ),
      },
      {
        Header: 'Single Locker & Organization',
        accessor: '',
        sortable: false,
        minWidth: 120,
        className: 'text-bold',
        Cell: (cellProps) => {
          const {
            lockerId,
            teamName,
            lockers,
            organizationId,
            organizationName,
          } = cellProps.value;

          return (
            <div className='ordermanagement__details--cell'>
              {
                organizationId
                  ? (
                    <div>
                      <div>ORG{organizationId} - {organizationName}</div>
                      <MaterialTooltip
                        tooltipText={(
                          <div className='pre-line'>
                            {lockers.map((locker: any) => `L${locker.lockerId} - ${locker.teamName}`).join('\n')}
                          </div>
                        )}
                        placement={'top'}
                      >
                        <div className='table__cell--details'>{`${lockers.length} ${lockers.length === 1 ? 'Locker' : 'Lockers'}`}</div>
                      </MaterialTooltip>
                    </div>
                  )
                  : `L${lockerId} - ${teamName}`
              }
            </div>
          );
        },
      },
    ];
    if (!selectedOrder) {
      columns = [
        ...columns,
        {
          Header: 'Items',
          accessor: 'itemsNumber',
          sortable: false,
          minWidth: 35,
        },
        {
          Header: 'Contact & E-mail',
          accessor: 'shippingInfo',
          sortable: false,
          minWidth: 120,
          Cell: (cellProps) => (
            <div>
              <div>{cellProps.value.name}</div>
              <div>{cellProps.value.email}</div>
            </div>
          ),
        },
        {
          Header: 'Coupon Code',
          accessor: 'couponCode',
          sortable: false,
          minWidth: 80,
        },
      ];
    }
    columns = [
      ...columns,
      {
        Header: 'Ship Date',
        accessor: 'shipDate',
        sortable: false,
        minWidth: 75,
        Cell: (cellProps) => <span>{parseDateNumeric(cellProps.value)}</span>,
      },
      {
        Header: <HeaderCell text={'Order Total'} />,
        accessor: 'pricePaid',
        className: 'text-right',
        sortable: true,
        minWidth: 55,
        Cell: (cellProps) => formatDollarAmount(cellProps.value),
      },
    ];

    return columns;
  };

  onFetchData = (state: any, instance: any) => {
    this.fetchData(state, instance);
  };

  fetchData = (state: any, instance: any) => {
    const {
      sortColumn,
      sortDirection,
    } = this.state;

    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortColumn, sortDirection);

    this.setState(() => ({
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    }), () => this.refreshData({
      pageNumber: newPageNumber + 1,
      pageSize: newPageSize,
    }));
  };

  refreshData = async (optionsDict: Record<string, any> = {}) => {
    const { fetchOrderTableStateUpdate } = this.props;

    const {
      sortColumn,
      sortDirection,
      searchInput,
      lockerIdsFilter,
      dateRangeFilter,
      accountingSubmitStatusFilter,
      userIdFilter,
      onlyPrdOrdersFilter,
      onlyRequiredItemOrdersFilter,
      partnerFilter,
    } = this.state;

    const args: Record<string, any> = {
      ...this.props,
      ...optionsDict,
    };

    return fetchOrderTableStateUpdate(
      args.pageNumber,
      args.pageSize,
      sortColumn,
      sortDirection,
      searchInput,
      accountingSubmitStatusFilter,
      dateRangeFilter,
      lockerIdsFilter,
      userIdFilter,
      onlyRequiredItemOrdersFilter,
      onlyPrdOrdersFilter,
      partnerFilter
    );
  };

  search = (value: string) => {
    this.setState(() => ({
      searchInput: value,
    }), this.refreshData);
  };

  filterByDateRange = (value: any) => {
    this.setState(() => ({
      dateRangeFilter: value,
    }), this.refreshData);
  };

  partnerChange = (value: any) => {
    this.setState(() => ({
      partnerFilter: value,
    }), this.refreshData);
  };

  filterOrders = (selectedFilters: string[]) => {
    const { user } = this.props;

    const newFilters: Pick<State, 'accountingSubmitStatusFilter' | 'userIdFilter'> = {
      accountingSubmitStatusFilter: undefined,
      userIdFilter: '',
    };

    for (const selectedFilter of selectedFilters) {
      if (selectedFilter === SubmitStatusEnum.Failed) {
        newFilters.accountingSubmitStatusFilter = selectedFilter;
      }

      if (selectedFilter === FilterOrdersOptionsEnum.OnlyOrdersSubmittedByMe) {
        const userId: string = user.profile.sub;
        newFilters.userIdFilter = userId;
      }
    }

    this.setState(() => (newFilters), this.refreshData);
  };

  clearSearch = () => {
    this.search('');
  };

  filterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.search((e.target as any).value);
  };

  isSelected = (key: number) => {
    const { selectedOrder } = this.state;

    return selectedOrder && key === selectedOrder;
  };

  createOrder = async (createOrderForm: CustomOrderFormData) => {
    const { saveTempOrder } = this.props;

    saveTempOrder(createOrderForm);
    this.closeCreateCustomOrderModal();
    navigateToPage(orderDetailsNewOrder);
  };

  getTrProps = (state: any, rowInfo: any) => {
    const { selectedOrder } = this.state;

    if (!rowInfo) {
      return {};
    }

    return {
      onClick: () => this.selectOrder(rowInfo.original.orderNumber),
      className: `text-left cursor-pointer ${selectedOrder && this.isSelected(rowInfo.original.orderNumber) && 'is-active'}`,
    };
  };

  getSelectedBooleanFilters = (): string[] => {
    const {
      accountingSubmitStatusFilter,
      userIdFilter,
    } = this.state;

    const selectedBooleanFilters: string[] = [];

    if (!!accountingSubmitStatusFilter) {
      selectedBooleanFilters.push(SubmitStatusEnum.Failed);
    }

    if (!!userIdFilter) {
      selectedBooleanFilters.push(FilterOrdersOptionsEnum.OnlyOrdersSubmittedByMe);
    }

    return selectedBooleanFilters;
  };

  getFiltersToPreserve = memoizeOne((
    searchInput,
    accountingSubmitStatusFilter,
    dateRangeFilter,
    lockerIdsFilter,
    userIdFilter,
    onlyRequiredItemOrdersFilter,
    onlyPrdOrdersFilter
  ) => ({
    searchInput,
    accountingSubmitStatusFilter,
    dateRangeFilter,
    lockerIdsFilter,
    userIdFilter,
    onlyRequiredItemOrdersFilter,
    onlyPrdOrdersFilter,
  }));

  render() {
    const {
      queue,
      totalPages,
      initialSearchInput,
      hasNextPage,
      hasPreviousPage,
      initialPageNumber,
      initialPageSize,
      initialSortColumn,
      initialSortDirection,
      initialDateRangeFilter,
    } = this.props;

    const {
      selectedOrder,
      showCreateCustomOrderModal,
      searchInput,
      lockerIdsFilter,
      dateRangeFilter,
      accountingSubmitStatusFilter,
      userIdFilter,
      onlyPrdOrdersFilter,
      onlyRequiredItemOrdersFilter,
    } = this.state;

    const partnerOptions = mapToOptionsList({
      list: this.props.partners,
      key: 'name',
      value: 'name',
      name: 'name',
      emptyOption: {
        name: `All partners (${this.props.partners.length})`,
      },
    });

    const selectedBooleanFilters = this.getSelectedBooleanFilters();

    return (
      <div className='container master-detail'>
        <CreateCustomOrderModal
          isOpen={showCreateCustomOrderModal}
          closeModal={this.closeCreateCustomOrderModal}
          createOrder={this.createOrder}
        />
        <div className='w-100'>
          <div className='sheet'>
            <div className='filter-groups'>
              <div className='table-options w-100'>
                <div className='flex'>
                  <SearchFilter
                    initialValue={initialSearchInput}
                    search={this.filterKey}
                    clearSearch={this.clearSearch}
                  />
                  <DateRangeFilter
                    filter={this.filterByDateRange}
                    selected={dateRangeFilter}
                    defaultValue={initialDateRangeFilter}
                  />
                  <PermissionHandler permissions={PermissionsEnum.OrderManagementSubmitOrder}>
                    <MultiSelectDropdown<Option<string>, 'value'>
                      selectedObjects={selectedBooleanFilters}
                      objects={filterOrdersOptions}
                      selectedText={'Filter orders'}
                      notSelectedText={'Show all orders'}
                      itemText={'filter orders'}
                      updateCallback={this.filterOrders}
                      textKey={'name'}
                      valueKey={'value'}
                      classNames={'margin-left'}
                      size={dropdownSizeM}
                    />
                  </PermissionHandler>
                  <Dropdown
                    options={partnerOptions}
                    onChange={this.partnerChange}
                    classes={'margin-left'}
                  />
                </div>
                <div className='flex'>
                  {
                    featureFlags.createOrderEnabled &&
                    <Button
                      type={'primary'}
                      text={'Create Order'}
                      onClick={this.openCreateCustomOrderModal}
                    />
                  }
                </div>
              </div>
            </div>
            <OrdersTable
              columns={this.getColumns(selectedOrder)}
              data={queue}
              totalPages={totalPages}
              onFetchData={this.onFetchData}
              defaultPageSize={defaultPageSizeSmallTable}
              pageSizeOptions={pageSizeOptionsSmallTable}
              showPagination={hasNextPage || hasPreviousPage}
              getTrProps={this.getTrProps}
              tableId={TableEnum.orderManagement}
              preserveState={true}
              sortDirEnum={sortDirectionEnum}
              initialPageNumber={initialPageNumber}
              initialPageSize={initialPageSize}
              initialSortColumn={initialSortColumn}
              initialSortDirection={initialSortDirection}
              filtersToPreserve={this.getFiltersToPreserve(
                searchInput,
                accountingSubmitStatusFilter,
                dateRangeFilter,
                lockerIdsFilter,
                userIdFilter,
                onlyRequiredItemOrdersFilter,
                onlyPrdOrdersFilter
              )}
            />
          </div>
        </div>
        {
          selectedOrder &&
          <OrderManagementQuickView
            closeDetails={this.unselectOrder}
            orderNumber={selectedOrder}
          />
        }
      </div>
    );
  }
}

export default connector(OrderManagement);
