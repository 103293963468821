import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  stopSubmit,
  reset,
} from 'redux-form';
import { connect } from 'react-redux';
import { editOrganizationGroupForm } from '@constants/reduxForms';
import { validateOrganizationGroup } from '@redux/organizations/validations';
import OrganizationGroupForm from './OrganizationGroupForm';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class EditOrganizationGroupModal extends Component {
  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(editOrganizationGroupForm, {}));
    dispatch(reset(editOrganizationGroupForm));
  };

  render() {
    const {
      isOpen,
      handleSubmit,
      error,
      initialValues,
      organizationId,
      change,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={this.handleCloseModal}
        title={'Edit Store Group'}
        modalHeight={'xl'}
        modalWidth={'l'}
        buttons={(
          <ModalButtons
            onClose={this.handleCloseModal}
            formSubmission={true}
            confirmBtnText={'Save'}
            formId={editOrganizationGroupForm}
          />
        )}
      >
        <OrganizationGroupForm
          onSubmit={handleSubmit}
          change={change}
          error={error}
          initialValues={initialValues}
          organizationId={organizationId}
          formId={editOrganizationGroupForm}
        />
      </Modal>
    );
  }
}

EditOrganizationGroupModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    featured: PropTypes.bool,
    lockers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        teamName: PropTypes.string.isRequired,
        logoUrl: PropTypes.string,
      })
    ),
  }).isRequired,
  error: PropTypes.string,
  change: PropTypes.func,
};

export default connect()(reduxForm({
  form: editOrganizationGroupForm,
  enableReinitialize: true,
  validate: validateOrganizationGroup,
})(EditOrganizationGroupModal));
