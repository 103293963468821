/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type DecorationMethodEnum = 'Undefined' | 'HAG' | 'EMB' | 'SUB' | 'DTG' | 'DIP';


export const DecorationMethodEnum = {
  Undefined: 'Undefined' as DecorationMethodEnum,
  HAG: 'HAG' as DecorationMethodEnum,
  EMB: 'EMB' as DecorationMethodEnum,
  SUB: 'SUB' as DecorationMethodEnum,
  DTG: 'DTG' as DecorationMethodEnum,
  DIP: 'DIP' as DecorationMethodEnum,
};
