import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  updateColoredStyle,
  updateProduct,
  deleteColoredStyle,
  updateProductVendor,
  deleteProductVendor,
  createProductVendor,
} from '@APICalls/productCatalog/actions';
import { editDtgColoredStyle } from '@APICalls/dtg/actions';
import {
  emptyImageTextEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { modalStyle } from '@constants/values';
import {
  editColoredStyleForm,
  editProductForm,
  dtgColoredStyleForm,
} from '@constants/reduxForms';
import {
  productCatalogUrl,
  syncStyleUrl,
} from '@constants/clientUrls/clientUrls';
import {
  getStyleById,
  getColoredStylesForStyle,
  getSportsForStyle,
  getLayoutsForStyle,
  getProductsForStyle,
  updateDisplayedColoredStyles,
  getDecorationLocations,
  getProductVendorQuantities,
  getProductVendorMappingsForSku,
  getProductBySku,
  getProductInventoryForStyle,
  getProductSkuInfoForStyle,
  getColors,
} from '@redux/productCatalog/actions';
import { fetchTeamColors } from '@redux/support/actions';
import {
  roleMapping,
  checkPermissions,
} from '@util/roleCheck';
import { materialSwal } from '@util/componentHelper';
import { extractParameterFromPath } from '@util/stringHelpers';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import Table from '@sharedComponents/Table/Table';
import BackLink from '@sharedComponents/Navigation/BackLink';
import Icon from '@sharedComponents/Icons/Icon';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';
import EditProductModal from '../ProductCatalogModals/EditProductModal/EditProductModal';
import EditColoredStyleModal from '../ProductCatalogModals/EditColoredStyleModal/EditColoredStyleModal';
import EditDtgColoredStyleModal from '../ProductCatalogModals/EditDtgColoredStyleModal/EditDtgColoredStyleModal';
import ColoredStyleNameCell from './ColoredStyleNameCell';
import ProductCatalogDetailsHeader from './Header';
import ProductsDetails from './ProductsDetails';

const ColoredStylesTable = Table();

class ProductCatalogDetails extends PureComponent {
  state = {
    selectAll: false,
    selectedItems: [],
    imageModalIsOpen: false,
    imageUrl: '',
    search: '',
    expanded: {},
    expandAll: false,
    editColoredStyleModalIsOpen: false,
    selectedColoredStyle: null,
    editProductModalIsOpen: false,
    selectedProduct: null,
    editProductInitialValues: null,
    editDtgColoredStyleModalIsOpen: false,
    deleteColoredStyleConfirmationModalIsOpen: false,
    selectedStyle: null,
  };

  openDeleteColoredStyleConfirmationModal = (selectedStyle) => {
    this.setState(() => ({
      selectedStyle,
      deleteColoredStyleConfirmationModalIsOpen: true,
    }));
  };

  closeDeleteColoredStyleConfirmationModal = () => {
    this.setState(() => ({
      selectedStyle: null,
      deleteColoredStyleConfirmationModalIsOpen: false,
    }));
  };

  handleDeleteColoredStyleModalConfirm = async () => {
    const { selectedStyle } = this.state;

    const res = await deleteColoredStyle(selectedStyle.id);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.refreshColoredStyles();
    }

    this.closeDeleteColoredStyleConfirmationModal();
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getDecorationLocations());
    dispatch(getColors());
    dispatch(fetchTeamColors());

    this.refreshStyle();
  }

  componentDidUpdate(prevProps) {
    const { productVendorsForSku } = this.props;
    const { editProductInitialValues } = this.state;
    const styleId = extractParameterFromPath(this.props, 'styleId', 'number');
    const oldStyleId = extractParameterFromPath(prevProps, 'styleId', 'number');

    if (styleId !== oldStyleId) {
      this.refreshStyle();
    }

    if (productVendorsForSku && prevProps.productVendorsForSku !== productVendorsForSku) {
      editProductInitialValues.productVendors = productVendorsForSku;
      this.setState(() => ({
        editProductInitialValues,
      }));
    }
  }

  refresh = () => {
    const { dispatch } = this.props;
    const styleId = extractParameterFromPath(this.props, 'styleId', 'number');

    dispatch(getStyleById(styleId));
    dispatch(getSportsForStyle(styleId));
    dispatch(getLayoutsForStyle(styleId));
  };

  refreshStyle = () => {
    const { dispatch } = this.props;
    const styleId = extractParameterFromPath(this.props, 'styleId', 'number');

    this.refresh();
    dispatch(getColoredStylesForStyle(styleId));
    dispatch(getProductVendorQuantities(styleId));
    dispatch(getProductsForStyle(styleId));
    dispatch(getProductInventoryForStyle(styleId));
    dispatch(getProductSkuInfoForStyle(styleId));
  };

  refreshColoredStyles = () => {
    const { dispatch } = this.props;
    const styleId = extractParameterFromPath(this.props, 'styleId', 'number');

    dispatch(getColoredStylesForStyle(styleId));
    dispatch(getProductVendorQuantities(styleId));
    dispatch(getProductsForStyle(styleId));
  };

  refreshProducts = () => {
    const { dispatch } = this.props;
    const styleId = extractParameterFromPath(this.props, 'styleId', 'number');

    dispatch(getProductVendorQuantities(styleId));
    dispatch(getProductsForStyle(styleId));
  };

  openEditColoredStyleModal = (coloredStyle) => {
    this.setState(() => ({
      editColoredStyleModalIsOpen: true,
      selectedColoredStyle: coloredStyle,
    }));
  };

  openEditDtgColoredStyleModal = (coloredStyle) => {
    this.setState(() => ({
      editDtgColoredStyleModalIsOpen: true,
      selectedColoredStyle: coloredStyle,
    }));
  };

  editColoredStyle = async (coloredStyleForm) => {
    const res = await updateColoredStyle(coloredStyleForm);
    if (res.success) {
      materialSwal('Success', res.message, 'success');
      this.closeEditColoredStyleModal();
      this.refreshColoredStyles();
    }
  };

  onEditDtgColoredStyle = (coloredStyleId) => async (form) => {
    const res = await editDtgColoredStyle({
      ...form,
      coloredStyleId,
    });
    if (res.success) {
      materialSwal('Success', res.message, 'success');
      this.closeEditDtgColoredStyleModal();
      this.refreshColoredStyles();
    }
  };

  closeEditColoredStyleModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(editColoredStyleForm));
    this.setState(() => ({
      editColoredStyleModalIsOpen: false,
      selectedColoredStyle: null,
    }));
  };

  closeEditDtgColoredStyleModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(dtgColoredStyleForm));
    this.setState(() => ({
      editDtgColoredStyleModalIsOpen: false,
      selectedColoredStyle: null,
    }));
  };

  openEditProductModal = async (selectedProduct) => {
    const { dispatch } = this.props;

    await dispatch(getProductBySku(selectedProduct.sku));

    const { product } = this.props;

    this.setState(() => ({
      editProductModalIsOpen: true,
      selectedProduct: product,
      editProductInitialValues: { ...product },
    }), () => dispatch(getProductVendorMappingsForSku(product.sku)));
  };

  editProduct = async (productForm) => {
    const { productVendorsForSku } = this.props;

    const productResponse = await updateProduct(productForm);
    if (productResponse && productResponse.success) {
      const vendorsResponse = await this.editProductVendors(
        productForm.sku,
        productForm.productVendors,
        productVendorsForSku
      );

      if (vendorsResponse) {
        materialSwal('Success', productResponse.message, 'success');
        this.closeEditProductModal();
        this.refreshProducts();
      }
    }
  };

  productVendorHasChanged = (originalVendor, vendor) => (
    originalVendor.rbiUpc !== vendor.rbiUpc
    || originalVendor.esiUpcNumber !== vendor.esiUpcNumber
    || originalVendor.vendorItemNumber !== vendor.vendorItemNumber
  );

  editProductVendors = async (sku, productVendors, originalProductVendors) => {
    const allVendors = [...productVendors];
    const allOriginalVendors = [...originalProductVendors];

    for (const vendor of allVendors) {
      vendor.sku = sku;
      const originalVendor = allOriginalVendors.find((ov) => ov.sku === vendor.sku && ov.vendorId === vendor.vendorId);
      if (originalVendor) {
        if (this.productVendorHasChanged(originalVendor, vendor)) {
          const vendorUpdateResponse = await updateProductVendor(vendor);
          if (!vendorUpdateResponse || !vendorUpdateResponse.success) {
            return false;
          }
        }
      } else {
        const vendorCreateResponse = await createProductVendor(vendor);
        if (!vendorCreateResponse || !vendorCreateResponse.success) {
          return false;
        }
      }
    }

    for (const originalVendor of allOriginalVendors) {
      originalVendor.sku = sku;
      const newVendor = allVendors.find((v) => v.sku === originalVendor.sku && v.vendorId === originalVendor.vendorId);
      if (!newVendor) {
        const vendorDeleteResponse = await deleteProductVendor(originalVendor.sku, originalVendor.vendorId);
        if (!vendorDeleteResponse || !vendorDeleteResponse.success) {
          return false;
        }
      }
    }

    return true;
  };

  closeEditProductModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(editProductForm));
    this.setState(() => ({
      editProductModalIsOpen: false,
      selectedProduct: null,
    }));
  };

  openModalWithImage = (imageUrl) => {
    this.setState(() => ({
      imageModalIsOpen: true,
      imageUrl,
    }));
  };

  closeImageModal = () => {
    this.setState(() => ({
      imageModalIsOpen: false,
      imageUrl: '',
    }));
  };

  isSelected = (item) => {
    const { selectedItems } = this.state;

    return selectedItems.find((i) => i.id === item.id) !== undefined;
  };

  selectItem = (item, isSelected) => {
    const {
      selectedItems,
      selectAll,
    } = this.state;
    const { coloredStyles } = this.props;

    if (isSelected) {
      this.setState(() => ({
        selectedItems: selectedItems.filter((p) => p.id !== item.id),
      }));
    } else {
      this.setState(() => ({
        selectedItems: [
          ...selectedItems,
          item,
        ],
        selectAll: selectedItems.length === coloredStyles.length ? true : selectAll,
      }));
    }
  };

  selectAllItems = () => {
    const { coloredStyles } = this.props;
    const { selectAll } = this.state;

    if (!selectAll) {
      this.setState(() => ({
        selectedItems: coloredStyles,
        selectAll: true,
      }));
    } else {
      this.setState(() => ({
        selectedItems: [],
        selectAll: false,
      }));
    }
  };

  toggleExpandAllButton = (state) => {
    const { displayedColoredStyles } = this.props;
    const { expanded } = this.state;

    const newExpanded = { ...expanded };
    for (let i = 0; i < displayedColoredStyles.length; i++) {
      newExpanded[i] = state;
    }

    this.setState((prevState) => ({
      expanded: newExpanded,
      expandAll: !prevState.expandAll,
    }));
  };

  clearSearch = () => {
    this.filterBySku('');
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.filterBySku(e.target.value);
  };

  filterBySku = (sku) => {
    const {
      styleProducts,
      coloredStyles,
      dispatch,
    } = this.props;

    if (!sku || sku === '') {
      dispatch(updateDisplayedColoredStyles(coloredStyles));
      this.setState(() => ({
        search: '',
        expanded: {},
        expandAll: false,
      }));

      return;
    }

    const targetProducts = styleProducts
      .filter((p) => p.sku.toLowerCase().indexOf(sku.toLowerCase()) !== -1)
      .map((p) => p.coloredStyleId);

    if (targetProducts && targetProducts.length > 0) {
      const targetColoredStyles = coloredStyles.filter((cs) => targetProducts.includes(cs.id));

      if (targetColoredStyles && targetColoredStyles.length > 0) {
        dispatch(updateDisplayedColoredStyles(targetColoredStyles));

        const expanded = {};
        for (let i = 0; i < targetColoredStyles.length; i++) {
          expanded[i] = true;
        }
        this.setState(() => ({
          search: sku,
          expanded,
          expandAll: true,
        }));
      }
    }
  };

  changeExpanded = (index) => {
    const { displayedColoredStyles } = this.props;
    const { expanded } = this.state;

    const newExpanded = { ...expanded };
    if (newExpanded[index]) {
      newExpanded[index] = !newExpanded[index];
    } else {
      newExpanded[index] = true;
    }

    const countExpanded = Object.values(newExpanded).filter((x) => x === true).length;
    const countCollapsed = Object.values(newExpanded).filter((x) => x === false).length;
    if (countExpanded === displayedColoredStyles.length
      || countCollapsed === displayedColoredStyles.length) {
      this.setState((prevState) => ({
        expandAll: !prevState.expandAll,
      }));
    }

    this.setState(() => ({ expanded: newExpanded }));
  };

  getColumns = () => {
    const {
      styleProducts,
      roles,
      colorsDictionary,
      style,
      colors,
      teamColorsByCode,
    } = this.props;

    const { expandAll } = this.state;

    let columns = [
      {
        Header: 'Front, back & side images',
        accessor: '',
        width: 320,
        Cell: (cellProps) => (
          <div className='catalog-details__images'>
            <ImagePreviewCell
              imageUrl={cellProps.value.imageUrlFront}
              openModalWithUrl={this.openModalWithImage}
              noImageText={emptyImageTextEnum.Front}
            />
            <ImagePreviewCell
              imageUrl={cellProps.value.imageUrlBack}
              openModalWithUrl={this.openModalWithImage}
              noImageText={emptyImageTextEnum.Back}
            />
            <ImagePreviewCell
              imageUrl={cellProps.value.imageUrlSide}
              openModalWithUrl={this.openModalWithImage}
              noImageText={emptyImageTextEnum.Side}
            />
          </div>
        ),
      },
      {
        Header: 'Color Group & Code',
        accessor: '',
        width: 220,
        className: 'text-bold',
        Cell: (cellProps) => (
          <ColoredStyleNameCell
            coloredStyle={cellProps.value}
            styleProducts={styleProducts}
            style={style}
            teamColorsByCode={teamColorsByCode}
          />
        ),
      },
      {
        Header: 'Color A',
        accessor: 'colorA',
        width: 170,
        Cell: (cellProps) => cellProps.value ? <span>{cellProps.value}</span> : '-',
      },
      {
        Header: 'Color B',
        accessor: 'colorB',
        width: 170,
        Cell: (cellProps) => cellProps.value ? <span>{cellProps.value}</span> : '-',
      },
      {
        Header: 'Flood color',
        accessor: 'floodColorId',
        width: 170,
        Cell: (cellProps) => {
          const color = colorsDictionary[cellProps.value];

          return color
            ? (
              <ColorDisplay
                colorName={color.code}
                displayName={true}
                size={'size--small'}
                hideEmptySwatch={true}
                hexValue={color.hexValue}
              />
            )
            : '-';
        },
      },
      {
        Header: 'Personalization colors',
        accessor: 'personalizationColorIds',
        Cell: (cellProps) => {
          const personalizationColors = colors.filter((color) => cellProps.value.includes(color.id));

          return personalizationColors.length > 0
            ? (
              <div className='flex__wrap'>
                {
                  personalizationColors.map((color, index) => (
                    <div
                      className='mr-10'
                      key={index}
                    >
                      <ColorDisplay
                        colorName={color.code}
                        displayName={true}
                        size={'size--small'}
                        hideEmptySwatch={true}
                        hexValue={color.hexValue}
                      />
                    </div>
                  ))
                }
              </div>
            )
            : '-';
        },
      },
    ];

    if (checkPermissions(roles, PermissionsEnum.ProductCatalogRestrictedEdit)) {
      columns = [
        ...columns,
        {
          Header: '',
          width: 40,
          resizable: false,
          accessor: '',
          style: { overflow: 'visible' },
          Cell: (cellProps) => (
            <ShowMoreActionsButton
              actions={[
                {
                  text: 'Edit Colored Style',
                  action: this.openEditColoredStyleModal.bind(null, cellProps.original),
                  hasBottomBorder: true,
                  isVisible: true,
                },
                ...(style.decorationMethod === decorationMethodEnum.DTG
                  ? [
                    {
                      text: 'Edit DTG Settings',
                      action: this.openEditDtgColoredStyleModal.bind(null, cellProps.original),
                      hasBottomBorder: true,
                      isVisible: true,
                    },
                  ]
                  : []
                ),
                {
                  text: 'Remove Colored Style',
                  action: this.openDeleteColoredStyleConfirmationModal.bind(null, cellProps.original),
                  isDangerous: true,
                  isVisible: true,
                },
              ]}
            />
          ),
        },
      ];
    }

    columns = [
      ...columns,
      {
        expander: true,
        Header: () => (
          expandAll
            ? (
              <div
                onClick={this.toggleExpandAllButton.bind(null, false)}
                className='catalog-details__expander'
              >
                <span className='button'>SKUs</span>
                <Icon
                  materialIcon={'arrow_drop_up'}
                  classes={'button'}
                />
              </div>
            )
            : (
              <div
                onClick={this.toggleExpandAllButton.bind(null, true)}
                className='catalog-details__expander'
              >
                <span className='button'>SKUs</span>
                <Icon
                  materialIcon={'arrow_drop_down'}
                  classes={'button'}
                />
              </div>
            )
        ),
        width: 90,
        Expander: ({
          isExpanded,
          ...rest
        }) => {
          const countSkus = styleProducts.filter((p) => p.coloredStyleId === rest.original.id).length;

          return (
            isExpanded
              ? (
                <div className='catalog-details__expander'>
                  <span className='button'>({countSkus})</span>
                  <Icon
                    materialIcon={'arrow_drop_up'}
                    classes={'button'}
                  />
                </div>
              )
              : (
                <div className='catalog-details__expander'>
                  <span className='button'>({countSkus})</span>
                  <Icon
                    materialIcon={'arrow_drop_down'}
                    classes={'button'}
                  />
                </div>
              )
          );
        },
      },
    ];

    return columns;
  };

  getSubComponent = (cell) => {
    const {
      style,
      styleProducts,
      productInventoryForStyle,
      productSkuInfoForStyle,
    } = this.props;

    return (
      <ProductsDetails
        styleProducts={styleProducts}
        coloredStyle={cell.original}
        style={style}
        openEditProductModal={this.openEditProductModal}
        productInventory={productInventoryForStyle}
        productSkuInfo={productSkuInfoForStyle}
      />
    );
  };

  onExpandedChange = (newExpanded, index) => {
    this.changeExpanded(index);
  };

  render() {
    const {
      style,
      coloredStyles,
      styleSports,
      styleProducts,
      displayedColoredStyles,
      decorationLocations,
      styleLayouts,
    } = this.props;

    const {
      imageModalIsOpen,
      imageUrl,
      search,
      expanded,
      editColoredStyleModalIsOpen,
      selectedColoredStyle,
      editProductModalIsOpen,
      editDtgColoredStyleModalIsOpen,
      deleteColoredStyleConfirmationModalIsOpen,
      selectedStyle,
      editProductInitialValues,
    } = this.state;

    const columns = this.getColumns();

    return (
      <>
        <SimpleConfirmationModal
          isOpen={deleteColoredStyleConfirmationModalIsOpen}
          confirm={this.handleDeleteColoredStyleModalConfirm}
          closeModal={this.closeDeleteColoredStyleConfirmationModal}
          title={'Delete Colored Style'}
          confirmationBody={`Are you sure you want to delete colored style "${selectedStyle && selectedStyle.colorGroup && selectedStyle.colorGroup.toUpperCase()}"?`}
        />
        <div className='container'>
          <ImagePreviewModal
            modalIsOpen={imageModalIsOpen}
            closeModal={this.closeImageModal}
            imageUrl={imageUrl}
          />

          <EditColoredStyleModal
            modalIsOpen={editColoredStyleModalIsOpen}
            closeModal={this.closeEditColoredStyleModal}
            onSubmit={this.editColoredStyle}
            initialValues={{
              ...selectedColoredStyle,
              style,
            }}
          />
          {
            style.decorationMethod === decorationMethodEnum.DTG && selectedColoredStyle &&
            <EditDtgColoredStyleModal
              modalIsOpen={editDtgColoredStyleModalIsOpen}
              handleCloseModal={this.closeEditDtgColoredStyleModal}
              handleEdit={this.onEditDtgColoredStyle(selectedColoredStyle.id)}
              coloredStyleId={selectedColoredStyle.id}
            />
          }

          <EditProductModal
            modalIsOpen={editProductModalIsOpen}
            closeModal={this.closeEditProductModal}
            initialValues={editProductInitialValues}
            onSubmit={this.editProduct}
            modalStyle={modalStyle}
            deleteProduct={this.openDelete}
          />

          <div className='navigation'>
            <BackLink
              text={'Product Catalog'}
              url={productCatalogUrl}
            />

            <PermissionHandler permissions={PermissionsEnum.ProductCatalogSyncDataVisit}>
              <ButtonLink
                type={'secondary'}
                text={'Sync Data'}
                linkTo={syncStyleUrl(style.id)}
              />
            </PermissionHandler>

          </div>
          <ProductCatalogDetailsHeader
            style={style}
            coloredStyles={coloredStyles}
            products={styleProducts}
            styleSports={styleSports}
            refresh={this.refreshStyle}
            decorationLocations={decorationLocations}
            styleLayouts={styleLayouts}
          />
          <div className='product-catalog__select-filters'>
            <SearchFilter
              initialValue={search || ''}
              search={this.filterKey}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='sheet'>
            <ColoredStylesTable
              columns={columns}
              data={displayedColoredStyles}
              showPagination={false}
              defaultPageSize={-1}
              subComponent={this.getSubComponent}
              customProps={{
                expanded,
                onExpandedChange: this.onExpandedChange,
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

ProductCatalogDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      styleId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  style: PropTypes.object.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object),
  displayedColoredStyles: PropTypes.arrayOf(PropTypes.object),
  styleSports: PropTypes.arrayOf(PropTypes.object),
  styleLayouts: PropTypes.arrayOf(PropTypes.object),
  styleProducts: PropTypes.arrayOf(PropTypes.object),
  decorationLocations: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.array,
  product: PropTypes.object.isRequired,
  productInventoryForStyle: PropTypes.arrayOf(PropTypes.object),
  productSkuInfoForStyle: PropTypes.arrayOf(PropTypes.object),
  productVendorsForSku: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  teamColorsByCode: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({
  productCatalog,
  oidc,
  support,
}) => ({
  style: productCatalog.style,
  coloredStyles: productCatalog.coloredStyles,
  displayedColoredStyles: productCatalog.displayedColoredStyles,
  styleSports: productCatalog.styleSports,
  styleLayouts: productCatalog.styleLayouts,
  styleProducts: productCatalog.styleProducts,
  decorationLocations: productCatalog.decorationLocations,
  roles: roleMapping(oidc),
  product: productCatalog.product,
  productInventoryForStyle: productCatalog.productInventoryForStyle,
  productSkuInfoForStyle: productCatalog.productSkuInfoForStyle,
  productVendorsForSku: productCatalog.productVendorMappingsForSku,
  colors: productCatalog.colors,
  colorsDictionary: productCatalog.colorsDictionary,
  teamColorsByCode: support.teamColorsByCode,
});

export default connect(mapStateToProps)(ProductCatalogDetails);
