import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

class ShowMoreText extends PureComponent {
  state = {
    expanded: false,
    truncated: false,
  };

  handleTruncate = (isTruncated) => {
    const { truncated } = this.state;

    if (isTruncated !== truncated) {
      this.setState({
        truncated: isTruncated,
      });
    }
  };

  toggleLines = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };

  render() {
    const {
      anchorClass,
      lines,
      moreLabel,
      lessLabel,
      children,
    } = this.props;

    const {
      truncated,
      expanded,
    } = this.state;

    return (
      <div>
        <Truncate
          lines={!expanded && lines}
          ellipsis={(
            <span>...
              <a
                href='#'
                className={anchorClass}
                onClick={this.toggleLines}
              >
                {moreLabel || 'Show more'}
              </a>
            </span>
          )}
          onTruncate={this.handleTruncate}
        >
          {children}
        </Truncate>
        {
          !truncated && expanded &&
          <span>
            <a
              href='#'
              className={anchorClass}
              onClick={this.toggleLines}
            >
              {lessLabel || 'Show less'}
            </a>
          </span>
        }
      </div>
    );
  }
}

ShowMoreText.propTypes = {
  lines: PropTypes.number.isRequired,
  moreLabel: PropTypes.string,
  lessLabel: PropTypes.string,
  anchorClass: PropTypes.string,
  children: PropTypes.node,
};

export default ShowMoreText;
