import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchVendorSuggestions } from '@redux/productCatalog/actions';
import PrimaryVendorFormInput from './PrimaryVendorFormInput';
import ProductVendorMappingsFieldArray from './ProductVendorMappingsFieldArray';

class ProductVendors extends PureComponent {
  fetchVendorSuggestions = (searchInput) => {
    const { dispatch } = this.props;

    dispatch(fetchVendorSuggestions(searchInput));
  };

  render() {
    const {
      selectedPrimaryVendor,
      vendors,
      disabled,
      change,
      inventoryAlwaysAvailable,
    } = this.props;

    return (
      <div>
        <PrimaryVendorFormInput
          selectedPrimaryVendor={selectedPrimaryVendor}
          change={change}
          disabled={disabled}
          inventoryAlwaysAvailable={inventoryAlwaysAvailable}
          fetchVendorSuggestions={this.fetchVendorSuggestions}
        />
        <ProductVendorMappingsFieldArray
          vendors={vendors}
          disabled={disabled}
        />
      </div>
    );
  }
}

ProductVendors.propTypes = {
  selectedPrimaryVendor: PropTypes.object,
  vendors: PropTypes.arrayOf(PropTypes.object).isRequired,
  change: PropTypes.func.isRequired,
  disabled: PropTypes.shape({
    vendorId: PropTypes.string,
    rbiUpc: PropTypes.string,
    esiUpcNumber: PropTypes.string,
    vendorItemNumber: PropTypes.string,
  }).isRequired,
  inventoryAlwaysAvailable: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  vendors: state.productCatalog.vendorsQueue.queue,
});

export default connect(mapStateToProps)(ProductVendors);
