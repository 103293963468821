import React from 'react';
import {
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  artVectorizationTabUrl,
  artVectorizationUnassignedUrl,
} from '@constants/clientUrls/clientUrls';
import { RootState } from '@redux/index/reducers';
import ProductionArtworkTable from './ProductionArtworkTable';
import { assignmentStatusEnum } from '@constants/enums/artworkEnums';

const getTabUrl = (tabStatus: (typeof assignmentStatusEnum[keyof typeof assignmentStatusEnum])): string => (`${artVectorizationTabUrl(tabStatus)}/`);

interface RouteProps {
  status: string;
}

const mapStateToProps = ({ tableManager }: RootState) => ({
  initialSelectedTab: tableManager.productionArtwork.selectedTab,
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps<RouteProps>;

const ProductionArtwork = React.memo<Props>(({
  match: { params: { status } },
  initialSelectedTab,
}) => {
  const tabUrl: string = initialSelectedTab ? getTabUrl(initialSelectedTab) : artVectorizationUnassignedUrl;

  return (
    <div className='container'>
      <div
        className='art-vectorization'
        data-test='production-artwork'
      >
        {
          status
            ? (
              <ProductionArtworkTable
                tasksStatus={status}
              />
            )
            : <Redirect to={{ pathname: tabUrl }} />}
      </div>
    </div>
  );
});

export default connector(ProductionArtwork);
