import React, { PureComponent } from 'react';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  value: number;
  min?: number;
  max?: number;
  name?: string;
  classes?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, error: boolean) => void;
}

type Props = OwnProps;

interface State {
  tooltipOpened: boolean;
  error: boolean;
  errorMessage: string;
}

class InputSpinner extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const error = this.hasError(this.props.value);
    this.state = {
      tooltipOpened: false,
      error,
      errorMessage: error ? this.getErrorMessage() : '',
    } as State;
  }

  hasError = (value: number): boolean => {
    const {
      max,
      min,
    } = this.props;

    const error = !!(min && (value < min) || max && (max < value));

    return error;
  };

  getErrorMessage = (): string => {
    const {
      min,
      max,
    } = this.props;

    return `Value should be between ${min} and ${max}`;
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange } = this.props;
    const error = this.hasError(Number(e.target.value));
    const errorMessage = error ? this.getErrorMessage() : '';

    this.setState(() => ({
      error,
      tooltipOpened: error,
      errorMessage,
    }));

    onChange(e, error);
  };

  onFocus = (): void => {
    const { error } = this.state;

    this.setState(() => ({ tooltipOpened: error }));
  };

  onBlur = (): void => {
    this.setState(() => ({ tooltipOpened: false }));
  };

  render() {
    const {
      value,
      min,
      max,
      name = '',
      classes = '',
      type = 'number',
      disabled = false,
      placeholder = '',
    } = this.props;
    const {
      tooltipOpened,
      error,
      errorMessage,
    } = this.state;

    return (
      <MaterialTooltip
        tooltipText={errorMessage}
        placement={'top'}
        open={tooltipOpened}
      >
        <input
          className={`${classes} ${error ? 'with-error' : ''}`}
          type={type}
          name={name}
          onChange={this.onChange}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          min={min}
          max={max}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      </MaterialTooltip>
    );
  }
}

export default InputSpinner;
