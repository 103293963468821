import { capitalsRegex } from '@constants/values';

export const getComparableStyleObjects = (styleFromProductCatalog = {}, styleFromSlServicesApi = {}) => {
  const catalogStyleObj = Object.keys(styleFromProductCatalog)
    .sort()
    .filter((key) => productCatalogStyleProps.includes(key))
    .reduce((acc, key) => ({
      ...acc,
      [parseKeyProp(key)]: getValue(key, styleFromProductCatalog),
    }), {});

  const slServicesStyleObj = Object.keys(styleFromSlServicesApi)
    .sort()
    .filter((key) => slServicesApiStyleProps.includes(key))
    .reduce((acc, key) => ({
      ...acc,
      [key]: getValue(key, styleFromSlServicesApi),
    }), {});

  return {
    catalogStyleObj,
    slServicesStyleObj,
  };
};

export const getComparableColoredStyleObjects = (csFromProductCatalog = {}, csFromSlServicesApi = {}) => {
  const catalogColoredStyleObj = Object.keys(csFromProductCatalog)
    .sort()
    .filter((key) => productCatalogColoredStyleProps.includes(key))
    .reduce((acc, key) => ({
      ...acc,
      [parseKeyProp(key)]: getValue(key, csFromProductCatalog),
    }), {});

  const slServicesColoredStyleObj = Object.keys(csFromSlServicesApi)
    .sort()
    .filter((key) => slServicesApiColoredStyleProps.includes(key))
    .reduce((acc, key) => ({
      ...acc,
      [key]: getValue(key, csFromSlServicesApi),
    }), {});

  return {
    catalogColoredStyleObj,
    slServicesColoredStyleObj,
  };
};

export const getComparableProductsLists = (productsFromProductCatalog = {}, productsFromSlServicesApi = {}) => {
  const skusFromProductCatalog = productsFromProductCatalog.map((p) => p.sku).sort();
  const skusFromSlServicesApi = productsFromSlServicesApi.map((p) => p.sku).sort();

  return {
    skusFromProductCatalog,
    skusFromSlServicesApi,
  };
};

const getValue = (key, object) => {
  if (key === 'personalizationLocations') {
    return object[key].map((value) => value.location);
  }

  if (key === 'personalizationColors') {
    return object[key].filter((value) => !value.custom).map((value) => value.floodColorId);
  }

  return object[key];
};

/* Parse camel case property to readable word (for example: vendorId --> vendor Id)  */
const parseKeyProp = (key) => key.replace(capitalsRegex, ' $1').trim();

/* Properties to compare */
const productCatalogStyleProps = [
  'id',
  'code',
  'name',
  'description',
  'age',
  'gender',
  'categoryId',
  'brandId',
  'vendorId',
  'discount',
  'recommended',
  'availableForNewStores',
  'sizeChartUrl',
  'disabled',
  'discontinued',
  'vendorFulfilledItem',
  'taxCode',
  'personalizationLocations',
];

const slServicesApiStyleProps = [
  'id',
  'code',
  'name',
  'description',
  'age',
  'gender',
  'category_id',
  'brand_id',
  'vendor_id',
  'personalizable',
  'personalization_locations',
  'discount_amount',
  'recommended',
  'available_for_new_stores',
  'size_chart_pdf',
  'discontinued',
  'vendor_fulfilled_item',
  'tax_code',
];

const productCatalogColoredStyleProps = [
  'id',
  'styleId',
  'colorGroup',
  'colorA',
  'colorB',
  'floodColorId',
  'imageUrlFront',
  'imageUrlBack',
  'imageUrlSide',
  'imageUrlGrid',
  'disabled',
  'discontinued',
  'personalizationColors',
];

const slServicesApiColoredStyleProps = [
  'id',
  'style_id',
  'color_group',
  'color_a',
  'color_b',
  'flood_color',
  'image_front',
  'image_back',
  'image_side',
  'image_grid',
  'personalization_color_id',
];
