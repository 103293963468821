import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  fetchLockerManagerTable,
  fetchFulfillmentLockersInfo,
} from '@redux/lockerManager/actions';
import { removeLockersFromOrganization } from '@redux/organizations/actions';
import { parseDateNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import { formatDollarAmount } from '@util/numberHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import {
  selectItem,
  selectAllItems,
  isSelectedItem,
} from '@util/selectionHelpers';
import Table from '@sharedComponents/Table/Table';
import TableBulkActions from '@sharedComponents/Table/TableComponents/TableBulkActions';
import SelectCell from '@sharedComponents/Table/TableCells/SelectCell';
import SelectAllCell from '@sharedComponents/Table/TableCells/SelectAllCell';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import Button from '@sharedComponents/Buttons/Button';
import RemoveLockersModal from '../OrganizationModals/RemoveLockersModal';

const LockersTable = Table();

class LockerTab extends Component {
  state = {
    sortOrder: sortDirectionShortEnum.Desc,
    sortByState: 'id',
    selectedLockers: [],
    selectAll: false,
    removeLockersModalIsOpen: false,
    include3MonthSales: false,
    pageNumber: 1,
    pageSize: 10,
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrder);

    this.setState(() => ({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }), () => this.refreshData({
      pageNumber: page + 1,
      pageSize,
    }));
  };

  refreshData = (optionsDict = {}) => {
    const {
      dispatch,
      organizationId,
    } = this.props;

    const {
      sortByState,
      sortOrder,
    } = this.state;

    const args = {
      ...optionsDict,
    };

    return dispatch(fetchLockerManagerTable(
      args.pageNumber,
      args.pageSize,
      '',
      '',
      '',
      false,
      sortByState,
      sortOrder,
      organizationId,
      ''
    ));
  };

  openRemoveLockersModal = () => {
    this.setState(() => ({ removeLockersModalIsOpen: true }));
  };

  closeRemoveLockersModal = () => {
    this.setState(() => ({ removeLockersModalIsOpen: false }));
  };

  removeSelectedLockers = async () => {
    const {
      dispatch,
      organizationId,
    } = this.props;

    const { selectedLockers } = this.state;

    const res = await dispatch(removeLockersFromOrganization(organizationId, selectedLockers));
    if (res?.success) {
      this.refreshData();
      this.closeRemoveLockersModal();
      this.clearSelection();
      materialSwal('Success', 'Lockers were successfully removed from the organization', 'success');
    }
  };

  toggleinclude3MonthSales = () => {
    const { include3MonthSales } = this.state;
    const {
      queue,
      dispatch,
    } = this.props;

    this.setState(() => ({
      include3MonthSales: !include3MonthSales,
    }), () => {
      if (!include3MonthSales && queue && queue.length > 0) {
        dispatch(fetchFulfillmentLockersInfo(queue.map((locker) => locker.id)));
      }
    });
  };

  selectItem = (item, isSelected) => {
    const {
      selectedLockers,
      selectAll,
    } = this.state;

    const { queue } = this.props;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectItem(queue, selectedLockers, selectAll, item, isSelected, 'id');

    this.setState(() => ({
      selectedLockers: newSelectedItems,
      selectAll: newSelectAll,
    }));
  };

  selectAllItems = () => {
    const { queue } = this.props;

    const {
      selectAll,
      selectedLockers,
    } = this.state;

    const {
      newSelectedItems,
      newSelectAll,
    } = selectAllItems(queue, selectedLockers, selectAll, 'id');

    this.setState(() => ({
      selectedLockers: newSelectedItems,
      selectAll: newSelectAll,
    }));
  };

  clearSelection = () => {
    this.setState(() => ({
      selectedLockers: [],
      selectAll: false,
    }));
  };

  getColumns = () => {
    const {
      selectAll,
      include3MonthSales,
      selectedLockers,
    } = this.state;

    const { lockersInfo } = this.props;

    const columns = [
      {
        Header: () => (
          <SelectAllCell
            isSelected={selectAll}
            selectAllItems={this.selectAllItems}
          />
        ),
        width: 60,
        accessor: '',
        Cell: (cellProps) => (
          <SelectCell
            select={this.selectItem}
            item={cellProps.value}
            isSelected={isSelectedItem(selectedLockers, cellProps.value, 'id')}
          />
        ),
      },
      {
        Header: <HeaderCell text={'ID'} />,
        accessor: 'id',
        sortable: true,
        minWidth: 50,
        Cell: (cellProps) => <Link to={lockerDetailsUrl(cellProps.value)}>L{cellProps.value}</Link>,
      },
      {
        Header: <HeaderCell text={'Locker Name'} />,
        accessor: 'team_name',
        minWidth: 120,
        sortable: true,
        className: 'text-bold',
      },
      {
        Header: <HeaderCell text={'Owner'} />,
        accessor: 'email',
        sortable: true,
        minWidth: 120,
      },
      {
        Header: 'Partner Name',
        accessor: 'partner_name',
        sortable: false,
        minWidth: 85,
      },
    ];

    const additionalColumns = [
      {
        Header: 'Sport',
        accessor: 'sport_name',
        sortable: false,
        minWidth: 65,
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        minWidth: 70,
        sortable: false,
        Cell: (cellProps) => cellProps.value && parseDateNumeric(cellProps.value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        minWidth: 65,
      },
    ];

    if (include3MonthSales) {
      additionalColumns.unshift({
        Header: <HeaderCell text={'3 Month Sales'} />,
        accessor: '',
        sortable: true,
        minWidth: 75,
        Cell: (cellProps) => {
          const lockerInfo = lockersInfo && lockersInfo.find((locker) => locker.id === cellProps.original.id);

          return formatDollarAmount(lockerInfo && lockerInfo.sales3Month);
        },
      });
    }

    return [
      ...columns,
      ...additionalColumns,
    ];
  };

  getWrappedColumns = (columns) => {
    const { selectedLockers } = this.state;

    const sendToPicking = (
      <Button
        type={'secondary'}
        text={'Remove Lockers from Organization'}
        onClick={this.openRemoveLockersModal}
      />
    );

    const wrappedColumns = [
      {
        Header: (
          <TableBulkActions
            selectedItems={selectedLockers}
            clearAll={this.clearSelection}
            bulkActions={sendToPicking}
          />
        ),
        columns,
      },
    ];

    return wrappedColumns;
  };

  render() {
    const {
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    const {
      selectedLockers,
      removeLockersModalIsOpen,
      include3MonthSales,
    } = this.state;

    const columns = this.getWrappedColumns(this.getColumns());

    return (
      <div className='sheet'>
        <RemoveLockersModal
          lockers={selectedLockers}
          isOpen={removeLockersModalIsOpen}
          removeLockers={this.removeSelectedLockers}
          closeModal={this.closeRemoveLockersModal}
        />
        <div className='flex pt-10 pb-10'>
          <MaterialCheckbox
            text={'Include 3 Month Sales'}
            checked={include3MonthSales}
            onClick={this.toggleinclude3MonthSales}
          />
        </div>
        <LockersTable
          data={queue}
          columns={columns}
          showPagination={hasNextPage || hasPreviousPage}
          totalPages={totalPages}
          onFetchData={this.fetchData}
          isBulkActionsMode={selectedLockers && selectedLockers.length > 0}
        />
      </div>
    );
  }
}

LockerTab.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationId: PropTypes.number.isRequired,
  lockersInfo: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    totalOrders: PropTypes.number,
    totalSales: PropTypes.number,
    sales3Month: PropTypes.number,
    totalFundraising: PropTypes.number,
  })),
};

const mapStateToProps = ({ lockerManager }) => ({
  totalPages: lockerManager.currentQueue.totalPages,
  hasPreviousPage: lockerManager.currentQueue.hasPreviousPage,
  hasNextPage: lockerManager.currentQueue.hasNextPage,
  queue: lockerManager.currentQueue.queue,
  lockersInfo: lockerManager.lockersInfo,
});

export default connect(mapStateToProps)(LockerTab);
