import { catalogApi } from '../../sqdApis';
import { uploadFile } from '@APICalls/support/actions';
import productCatalogUtilityUrls, { uploadFile as uploadFileUrl } from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
  handleApiError,
} from '@util/apiHelper';
import { mapLayoutErrors } from '@util/componentHelpers/layoutsHelper';

export const addLayout = async (addLayoutForm) => {
  const body = {
    name: addLayoutForm.name,
    categoryId: addLayoutForm.categoryId,
    imageUrlFront: addLayoutForm.imageUrlFront,
    imageUrlBack: addLayoutForm.imageUrlBack,
    imageUrlRight: addLayoutForm.imageUrlRight,
    imageUrlLeft: addLayoutForm.imageUrlLeft,
    logos: addLayoutForm.decorations.filter((d) => d.type === decorationTypeEnum.Logo),
    personalizations: addLayoutForm.decorations.filter((d) => d.type === decorationTypeEnum.Personalization),
    rowHash: addLayoutForm.rowHash,
  };

  if (addLayoutForm.imageUrlFrontAttachment) {
    const imgResponse = await uploadFile(addLayoutForm.imageUrlFrontAttachment, catalogApi, uploadFileUrl);
    body.imageUrlFront = imgResponse;
  }

  if (addLayoutForm.imageUrlBackAttachment) {
    const imgResponse = await uploadFile(addLayoutForm.imageUrlBackAttachment, catalogApi, uploadFileUrl);
    body.imageUrlBack = imgResponse;
  }

  if (addLayoutForm.imageUrlLeftAttachment) {
    const imgResponse = await uploadFile(addLayoutForm.imageUrlLeftAttachment, catalogApi, uploadFileUrl);
    body.imageUrlLeft = imgResponse;
  }

  if (addLayoutForm.imageUrlRightAttachment) {
    const imgResponse = await uploadFile(addLayoutForm.imageUrlRightAttachment, catalogApi, uploadFileUrl);
    body.imageUrlRight = imgResponse;
  }

  const call = catalogApi.post(productCatalogUtilityUrls.layouts, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call, mapLayoutErrors.bind(null, addLayoutForm.decorations));

  return res;
};

export const editLayout = async (editLayoutForm) => {
  const body = {
    id: editLayoutForm.id,
    name: editLayoutForm.name,
    categoryId: editLayoutForm.categoryId,
    imageUrlFront: editLayoutForm.imageUrlFront,
    imageUrlBack: editLayoutForm.imageUrlBack,
    imageUrlRight: editLayoutForm.imageUrlRight,
    imageUrlLeft: editLayoutForm.imageUrlLeft,
    logos: editLayoutForm.decorations.filter((d) => d.type === decorationTypeEnum.Logo),
    personalizations: editLayoutForm.decorations.filter((d) => d.type === decorationTypeEnum.Personalization),
    rowHash: editLayoutForm.rowHash,
  };

  if (editLayoutForm.imageUrlFrontAttachment) {
    const imgResponse = await uploadFile(editLayoutForm.imageUrlFrontAttachment, catalogApi, uploadFileUrl);
    body.imageUrlFront = imgResponse;
  }

  if (editLayoutForm.imageUrlBackAttachment) {
    const imgResponse = await uploadFile(editLayoutForm.imageUrlBackAttachment, catalogApi, uploadFileUrl);
    body.imageUrlBack = imgResponse;
  }

  if (editLayoutForm.imageUrlLeftAttachment) {
    const imgResponse = await uploadFile(editLayoutForm.imageUrlLeftAttachment, catalogApi, uploadFileUrl);
    body.imageUrlLeft = imgResponse;
  }

  if (editLayoutForm.imageUrlRightAttachment) {
    const imgResponse = await uploadFile(editLayoutForm.imageUrlRightAttachment, catalogApi, uploadFileUrl);
    body.imageUrlRight = imgResponse;
  }

  const call = catalogApi.put(productCatalogUtilityUrls.layout(editLayoutForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call, mapLayoutErrors.bind(null, editLayoutForm.decorations));

  return res;
};

export const deleteLayout = async (layoutId) => {
  const call = catalogApi.delete(productCatalogUtilityUrls.layout(layoutId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const deleteLayouts = async (layouts) => {
  const promises = layouts.map(async (layout) => (
    catalogApi.delete(productCatalogUtilityUrls.layout(layout.id))
  ));

  let countSuccess = 0;
  try {
    const call = Promise.all(promises);
    const res = await makeApiCallWithErrorModal(call);

    for (const r of (res || [])) {
      if (r && r.success) {
        countSuccess++;
      }
    }
    if (!res || countSuccess !== res.length) {
      throw new Error((res && res.length > 0 && res[0].message) || 'Some layouts were not deleted');
    }

    return {
      success: true,
      message: 'Layouts were successfully deleted',
    };
  } catch (e) {
    handleApiError(e);
  }
};

export const updateLayoutSports = async (layoutId, sports) => {
  const body = JSON.stringify({
    sports,
  });

  const call = catalogApi.put(productCatalogUtilityUrls.sportsForLayout(layoutId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
