import React from 'react';

interface OwnProps {
  isSelected: boolean;
  selectAllItems: () => void;
  isDisabled?: boolean;
}

type Props = OwnProps;

const SelectAllCell = React.memo(({
  isSelected,
  selectAllItems,
  isDisabled = false,
}: Props) => {
  if (isDisabled) {
    return <i className='material-icons logo-bank__assignment--lock-style'>lock</i>;
  }

  return isSelected
    ? (
      <div
        data-test='select-all-square'
        onClick={selectAllItems}
        className='material-icons button logo-bank__assignment--green-style'
      >
        check_box
      </div>
    )
    : (
      <div
        data-test='select-all-square'
        onClick={selectAllItems}
        className='material-icons button logo-bank__assignment--square-style'
      >
        check_box_outline_blank
      </div>
    );
});

export default SelectAllCell;
