import React, {
  useMemo,
  useEffect,
} from 'react';
import {
  FormState,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { VoucherOrderDetailDto } from '@api/financialServices/models';
import { useGetVoucherCreationAdmins } from '@api/squadlockerServices/vouchers';
import { VoucherCreationAdminViewModel } from '@api/squadlockerServices/models';
import { dropdownSizeL } from '@constants/values';
import { mapToOptionsList } from '@util/mappingHelper';
import InfoField from '@sharedComponents/Display/InfoField';
import FormDateInput from '@sharedComponents/Form/FormDateInput';
import { VoucherOrderFormData } from '../VoucherOrderForm';
import HookFormDropdown from '@sharedComponents/Form/HookFormDropdown';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  register: UseFormRegister<VoucherOrderFormData>;
  setValue: UseFormSetValue<VoucherOrderFormData>;
  errors: FormState<VoucherOrderFormData>['errors'];
}

type Props = OwnProps;

const VoucherOrderSettings = React.memo<Props>(({
  voucherOrder,
  register,
  setValue,
  errors,
}) => {
  const {
    data: adminsData,
    refetch: fetchAdmins,
  } = useGetVoucherCreationAdmins({
    organizationId: voucherOrder.organizationId ?? undefined,
    lockerId: voucherOrder.lockerId ?? undefined,
  });

  useEffect(() => {
    fetchAdmins();
  }, [fetchAdmins]);

  const adminsOptions = useMemo(() => (
    mapToOptionsList<number | '', VoucherCreationAdminViewModel>({
      list: adminsData,
      key: 'id',
      value: 'id',
      name: (admin) => `${admin.firstName} ${admin.lastName}`,
      emptyOption: {
        name: 'Select Admin',
      },
    })
  ), [adminsData]);

  return (
    <>
      <Grid
        item={true}
        xs={12}
        className={'voucherOrders__edit-form__section-title pt-0'}
      >
        <span className='card-title'>Voucher Order Settings</span>
      </Grid>
      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
        className={'mt-10'}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Valid From</span>
                  <span className='required'>*</span>
                </div>
              )}
              value={(
                <FormDateInput<VoucherOrderFormData>
                  id={'startDate'}
                  name={'startDate'}
                  placeholder={'Select Valid From'}
                  initialValue={voucherOrder.startDate ?? null}
                  register={register}
                  setValue={setValue}
                  error={errors.startDate?.message}
                  width={100}
                />
              )}
            />
          </div>
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Valid To</span>
                </div>
              )}
              value={(
                <FormDateInput<VoucherOrderFormData>
                  id={'endDate'}
                  name={'endDate'}
                  placeholder={'Select Valid To'}
                  initialValue={voucherOrder.endDate ?? null}
                  register={register}
                  setValue={setValue}
                  error={errors.endDate?.message}
                  width={100}
                />
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
        className={'mt-10'}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            {
              voucherOrder.organizationId
                ? (
                  <InfoField
                    label={'Organization'}
                    value={`ORG${voucherOrder.organizationId} - ${voucherOrder.organizationName}`}
                  />
                )
                : (
                  <InfoField
                    label={'Locker'}
                    value={`L${voucherOrder.lockerId} - ${voucherOrder.lockerName}`}
                  />
                )
            }
          </div>
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Admin</span>
                  <span className='required'>*</span>
                </div>
              )}
              value={(
                <HookFormDropdown<VoucherOrderFormData, VoucherOrderFormData['lockerManagerId'] | ''>
                  name={'lockerManagerId'}
                  register={register}
                  initialValue={voucherOrder.lockerManagerId}
                  options={adminsOptions}
                  change={setValue}
                  size={dropdownSizeL}
                  error={errors.lockerManagerId?.message}
                />
              )}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
});

export default VoucherOrderSettings;
