import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';

const IconCell = React.memo(({
  productsToAddLogo,
  product,
  selectProduct,
  logoId,
  lockingEnabled = true,
}) => {
  if (lockingEnabled && product.logos.filter((l) => l.id === logoId).length > 0) {
    return (
      <Icon
        materialIcon={'lock'}
        classes={'button logo-bank__assignment--lock'}
      />
    );
  }

  if (productsToAddLogo.filter((p) => p.id === product.id).length > 0) {
    return (
      <Icon
        materialIcon={'check_box'}
        classes={'button logo-bank__assignment--green'}
        onClick={selectProduct.bind(null, product)}
      />
    );
  }

  return (
    <Icon
      materialIcon={'check_box_outline_blank'}
      classes={'button logo-bank__assignment--square'}
      onClick={selectProduct.bind(null, product)}
    />
  );
});

IconCell.propTypes = {
  productsToAddLogo: PropTypes.array,
  product: PropTypes.object,
  selectProduct: PropTypes.func,
  logoId: PropTypes.number,
  lockingEnabled: PropTypes.bool,
};

export default IconCell;
