import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoReviewTasksUrl } from '@constants/clientUrls/clientUrls';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { dropdownSizeL } from '@constants/values';
import {
  fetchTableCounts,
  fetchLogoReviewerTasksTable,
  fetchLogoReviewerTaskFilters,
} from '@redux/logoReview/actions';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { mapToOptionsList } from '@util/mappingHelper';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import Tabs from '@sharedComponents/Tabs/Tabs';
import LogoReviewerTableNotStarted from './LogoReviewerTableNotStarted';
import LogoReviewerTableInReview from './LogoReviewerTableInReview';

const logoReviewerTabEnum = {
  NotStarted: 'NotStarted',
  InReview: 'InReview',
};

class LogoReviewTable extends Component {
  state = {
    sortByState: '',
    sortOrderState: '',
    selectedTab: logoReviewerTabEnum.NotStarted,
    inReview: false,
    reviewer: '',
    partner: '',
    searchInput: '',
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchTableCounts());
    dispatch(fetchLogoReviewerTaskFilters());
  }

  selectTab = (selectedTab) => {
    this.setState({
      selectedTab,
      inReview: selectedTab === logoReviewerTabEnum.InReview,
    });
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrderState,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrderState);

    this.setState({
      sortByState: sortColumn,
      sortOrderState: sortDirection,
    }, () => this.search({
      pageNumber: page + 1,
      pageSize,
    }));
  };

  handlePartnerFilter = (value) => {
    this.setState({
      partner: value,
    }, this.search);
  };

  handleReviewerFilter = (value) => {
    this.setState({
      reviewer: value,
    }, this.search);
  };

  getTabs = () => {
    const {
      notStartedTotalCount,
      inReviewTotalCount,
    } = this.props;

    const tabs = [
      {
        title: `Not Started (${notStartedTotalCount})`,
        name: logoReviewerTabEnum.NotStarted,
        content: (
          <LogoReviewerTableNotStarted
            fetchData={this.fetchData}
            search={this.search}
          />
        ),
      },
      {
        title: `In Review (${inReviewTotalCount})`,
        name: logoReviewerTabEnum.InReview,
        content: (
          <LogoReviewerTableInReview
            fetchData={this.fetchData}
          />
        ),
      },
    ];

    return tabs;
  };

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  search = (optionsDict) => {
    const {
      sortByState,
      sortOrderState,
      inReview,
      reviewer,
      partner,
      searchInput,
    } = this.state;

    const { dispatch } = this.props;

    const args = {
      ...this.props,
      ...optionsDict,
    };

    return dispatch(fetchLogoReviewerTasksTable(
      args.pageNumber,
      args.pageSize,
      sortByState,
      sortOrderState,
      inReview,
      reviewer,
      partner,
      searchInput
    ));
  };

  render() {
    const { selectedTab } = this.state;

    const {
      reviewers,
      partners,
      myLogosInReviewTotalCount,
    } = this.props;

    const reviewerOptions = mapToOptionsList({
      list: reviewers,
      emptyOption: {
        name: 'All reviewers',
      },
    });
    const partnerOptions = mapToOptionsList({
      list: partners,
      emptyOption: {
        name: 'All partners',
      },
    });

    return (
      <div className='sheet'>
        <div className='navigation'>
          <div className='filter-groups'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />

            <Dropdown
              options={partnerOptions}
              classes={'ml-10'}
              onChange={this.handlePartnerFilter}
            />

            {
              selectedTab === logoReviewerTabEnum.InReview &&
              <Dropdown
                options={reviewerOptions}
                classes={'ml-10'}
                onChange={this.handleReviewerFilter}
                size={dropdownSizeL}
              />
            }

          </div>
          <ButtonLink
            type={'primary'}
            text={`My Logos In Review (${myLogosInReviewTotalCount})`}
            linkTo={logoReviewTasksUrl}
          />
        </div>

        <Tabs
          tabs={this.getTabs()}
          selectedTab={selectedTab}
          selectTab={this.selectTab}
        />
      </div>
    );
  }
}

LogoReviewTable.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  reviewers: PropTypes.arrayOf(PropTypes.string).isRequired,
  partners: PropTypes.arrayOf(PropTypes.string).isRequired,
  notStartedTotalCount: PropTypes.number.isRequired,
  inReviewTotalCount: PropTypes.number.isRequired,
  myLogosInReviewTotalCount: PropTypes.number.isRequired,
};

const mapStateToProps = ({ logoReview }) => ({
  pageNumber: logoReview.tasksTable.activePage,
  pageSize: logoReview.tasksTable.itemsPerPage,
  totalCount: logoReview.tasksTable.totalCount,
  reviewers: logoReview.tasksTable.reviewers,
  partners: logoReview.tasksTable.partners,
  notStartedTotalCount: logoReview.tasksTableInfo.totalCount.notStarted,
  inReviewTotalCount: logoReview.tasksTableInfo.totalCount.inReview,
  myLogosInReviewTotalCount: logoReview.tasksTableInfo.totalCount.myLogosInReview,
});

export default connect(mapStateToProps)(LogoReviewTable);
