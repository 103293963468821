import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { connect } from 'react-redux';
import { scanDeviceCodeForm } from '@constants/reduxForms';
import {
  formValueSelector,
  reduxForm,
} from 'redux-form';
import ScanDeviceCodeForm from './ScanDeviceCodeForm';
import { validateDeviceCode } from '@redux/devices/validations';
import { isDefined } from '@util/valueHelpers';

const ScanDeviceCodeModal = ({
  isOpen,
  closeModal,
  openNextModal,
  deviceCode,
}) => (
  <Modal
    title={'Scan Device Code'}
    modalSize={'l'}
    isOpen={isOpen}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        confirmBtnDisabled={!isDefined(deviceCode)}
        onConfirm={openNextModal}
      />
    )}
  >
    <ScanDeviceCodeForm
      openNextModal={openNextModal}
    />
  </Modal>
);

ScanDeviceCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openNextModal: PropTypes.func.isRequired,
  deviceCode: PropTypes.string,
};

const selector = formValueSelector(scanDeviceCodeForm);
export default connect(((state) => ({
  deviceCode: selector(state, 'deviceCode'),
})))(reduxForm({
  form: scanDeviceCodeForm,
  validate: validateDeviceCode,
  enableReinitialize: true,
})(ScanDeviceCodeModal));
