import React from 'react';
import PropTypes from 'prop-types';
import Button from '@sharedComponents/Buttons/Button';

const PersonalizationOptionButton = React.memo(({
  selectedPersonalizationOption,
  personalizationOption,
  title,
  selectPersonalizationOption,
  className = '',
  disabled = false,
}) => (
  <Button
    type={selectedPersonalizationOption === personalizationOption ? 'primary' : 'secondary'}
    text={title}
    onClick={selectPersonalizationOption.bind(null, personalizationOption)}
    disabled={disabled}
    classes={`personalization-types__item ${className}`}
  />
));

PersonalizationOptionButton.propTypes = {
  selectedPersonalizationOption: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  personalizationOption: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  selectPersonalizationOption: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default PersonalizationOptionButton;
