import React from 'react';
import { parseDateTimeNumeric } from '@util/dateHandler';

interface OwnProps {
  user: Nullable<string>;
  note: Nullable<string>;
  dateTime: Nullable<string>;
}

type Props = OwnProps;

const PriorityDetailsNote = React.memo<Props>(({
  user,
  note,
  dateTime,
}) => (
  <div className='flagging__details'>
    <div className='flagging__details-note'>
      <div className='flagging__details-note-header'>
        <h3 className='float-left'>{user}</h3>
        <h3 className='float-right'>{parseDateTimeNumeric(dateTime)}</h3>
      </div>
      <p>{note}</p>
    </div>
  </div>
));

export default PriorityDetailsNote;
