import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ArtworkFileTasksDetails extends PureComponent {
  render() {
    const { detail } = this.props;
    const { locker } = detail;

    if (locker) {
      let { id: lockerId } = locker;

      if (detail) {
        lockerId = detail.locker && detail.locker.id;
      }

      return (
        <>
          <div className='header__bar-content'>
            <h1 className='header__bar-title header__bar-content-block'>
              {
                (lockerId && locker.teamName)
                  ? `Locker L${lockerId} - ${locker.teamName}`
                  : ''
              }
            </h1>
          </div>
        </>
      );
    }

    return <div />;
  }
}

ArtworkFileTasksDetails.propTypes = {
  detail: PropTypes.object,
};

const mapStateToProps = ({ artworkFileTasks }) => ({
  detail: artworkFileTasks.artworkFileTaskDetails,
});

export default connect(mapStateToProps)(ArtworkFileTasksDetails);
