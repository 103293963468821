import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ColorList extends PureComponent {
  onMouseEnter = (color) => {
    const { setHoveredColor } = this.props;

    if (setHoveredColor) {
      setHoveredColor(color);
    }
  };

  onMouseLeave = () => {
    const { setHoveredColor } = this.props;

    if (setHoveredColor) {
      setHoveredColor(null);
    }
  };

  render() {
    const {
      colors,
      displayProp,
      classes = '',
    } = this.props;

    const colorList = (colors || []).map((color, index) => (
      <div
        key={index}
        onMouseEnter={this.onMouseEnter.bind(null, color.hexValue)}
        onMouseLeave={this.onMouseLeave}
        className='color-dropdown__option'
      >
        <div className='color-swatch-item'>
          <div
            className='color-swatch-item__color'
            style={{ backgroundColor: color.hexValue }}
          />
          <span className='color-swatch-item__number'>{color[displayProp] || color.code}</span>
        </div>
      </div>
    ));

    return (
      <div className={`logo-bank__upload-modal--color-list ${classes}`}>
        <div className='color-dropdown__options is-active w-100'>
          <div className='color-dropdown__options--list custom-scrollbar'>
            {colorList}
          </div>
        </div>
      </div>
    );
  }
}

ColorList.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
  })).isRequired,
  setHoveredColor: PropTypes.func,
  classes: PropTypes.string,
  displayProp: PropTypes.string,
};

export default ColorList;
