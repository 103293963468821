import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import { getTrueOrFalseOptions } from '@constants/options/options';
import { vendorForm } from '@constants/reduxForms';
import { validateVendor } from '@redux/vendors/validations';
import { parseNumber } from '@util/numberHelpers';
import Input from '@sharedComponents/Form/Input';
import Icon from '@sharedComponents/Icons/Icon';
import FormError from '@sharedComponents/Form/FormError';
import FormDropdown from '@sharedComponents/Form/FormDropdown';

const trueOrFalseOptions = getTrueOrFalseOptions().map((option) => ({
  ...option,
  value: JSON.parse(option.value),
}));

class VendorForm extends PureComponent {
  addTimeline = (fields) => {
    fields.push({
      description: '',
      timelineDays: 1,
    });
  };

  renderTimeline = (member, index) => (
    <div
      key={index}
      className='flex'
    >
      <div className='redux-form__column--size-m'>
        <label className='redux-form__label'>
          Description
        </label>
        <Field
          name={`${member}.description`}
          placeholder={'Enter Description'}
          component={Input}
          type={'text'}
        />
      </div>

      <div className='redux-form__column--size-m ml-20'>
        <label className='redux-form__label'>
          Timeline (Business Days)
        </label>
        <Field
          name={`${member}.timelineDays`}
          placeholder={'Enter Timeline'}
          component={Input}
          type={'number'}
          parse={parseNumber}
        />
      </div>
    </div>
  );

  renderTimelines = ({
    fields,
    meta: { error },
  }) => (
    <div className='mb-30'>
      <div className='redux-form__column--size-xl'>
        <div className='tableView__details--header--title mb-30'>
          <span>Timeline Information</span>
          <div
            className='lockerManagerEdit__details-header--edit align__center'
            onClick={this.addTimeline.bind(null, fields)}
          >
            <Icon materialIcon={'add'} />
            <span>Add Timeline</span>
          </div>
        </div>
      </div>
      <div className='redux-form__column--size-xl'>
        <div className='ml-15 mr-30 m-b-30'>
          {fields.map(this.renderTimeline)}
        </div>

        <div className='mt-20'>
          <FormError
            error={error}
            enableMultiple={true}
          />
        </div>
      </div>
    </div>
  );

  render() {
    const {
      handleSubmit,
      disabled = {},
      vfiOrderAutoSubmit,
      change,
      initialValues,
    } = this.props;

    const timelines = [];

    return (
      <form
        className='redux-form'
        onSubmit={handleSubmit}
        id={vendorForm}
      >
        <div>
          <div className='tableView__details--header--title mb-10'>
            <span>Basic Information</span>
          </div>
          <div className='redux-form__section pb-5'>
            <div className='flex'>
              <div className='w-100'>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-l'>
                    <label className='redux-form__label'>
                      Name
                      <span className='required'>*</span>
                    </label>
                    <Field
                      name={'name'}
                      placeholder={'Enter Name'}
                      component={Input}
                      type={'text'}
                    />
                  </div>
                  <div className='redux-form__column--size-s ml-20 mr-10'>
                    <label className='redux-form__label'>
                      Code
                      <span className='required'>*</span>
                    </label>
                    <Field
                      name={'code'}
                      placeholder={'Enter Code'}
                      component={Input}
                      type={'text'}
                      disabled={disabled.code}
                    />
                  </div>
                </div>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-xl'>
                    <label className='redux-form__label'>
                      Vendor System URL
                    </label>
                    <Field
                      name={'vendorSystemUrl'}
                      placeholder={'Enter URL'}
                      component={Input}
                    />
                  </div>
                </div>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-m'>
                    <label className='redux-form__label'>
                      Lead Time
                    </label>
                    <Field
                      name={'leadTime'}
                      placeholder={'Enter Lead Time'}
                      component={Input}
                      type={'number'}
                      parse={parseNumber}
                    />
                  </div>
                  <div className='redux-form__column--size-m ml-20'>
                    <label className='redux-form__label'>
                      VFI Max Personalization Name Length
                    </label>
                    <Field
                      name={'vfiMaxPersonalizationNameLength'}
                      placeholder={'Enter Max Character Count'}
                      component={Input}
                      type={'number'}
                      parse={parseNumber}
                    />
                  </div>
                </div>
                <div className='redux-form__row align__left'>
                  <div className='redux-form__column--size-m'>
                    <label className='redux-form__label'>
                      Dropship VFIs
                    </label>
                    <Field
                      name={'dropshipVFIs'}
                      component={FormDropdown}
                      disabled={disabled.dropshipVFIs}
                      initialValue={initialValues.dropshipVFIs}
                      change={change}
                      options={trueOrFalseOptions}
                    />
                  </div>
                  <div className='redux-form__column--size-m ml-20'>
                    <label className='redux-form__label'>
                      VFI Order Auto Submit
                    </label>
                    <Field
                      name={'vfiOrderAutoSubmit'}
                      component={FormDropdown}
                      disabled={disabled.vfiOrderAutoSubmit}
                      initialValue={initialValues.vfiOrderAutoSubmit}
                      change={change}
                      options={trueOrFalseOptions}
                    />
                  </div>
                </div>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-m'>
                    <label className='redux-form__label'>
                      VFI Order Treshold
                    </label>
                    <Field
                      name={'vfiOrderTreshold'}
                      component={Input}
                      placeholder={'0'}
                      disabled={disabled.vfiOrderTreshold || !vfiOrderAutoSubmit}
                      type={'number'}
                      parse={parseNumber}
                    />
                  </div>
                  <div className='redux-form__column--size-m ml-20'>
                    <label className='redux-form__label'>
                      VFI Order Time (Hours)
                    </label>
                    <Field
                      name={'vfiOrderTimeHours'}
                      component={Input}
                      placeholder={'0'}
                      disabled={disabled.vfiOrderTimeHours || !vfiOrderAutoSubmit}
                      type={'number'}
                      parse={parseNumber}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='redux-form__section pb-5'>
            <FieldArray
              name={'timelines'}
              component={this.renderTimelines}
              timelines={timelines}
              rerenderOnEveryChange={true}
            />
          </div>
        </div>
      </form>
    );
  }
}

VendorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.object,
  vfiOrderAutoSubmit: PropTypes.bool,
  change: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    dropshipVFIs: PropTypes.bool.isRequired,
    vfiOrderAutoSubmit: PropTypes.bool.isRequired,
    timelines: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
};

const selector = formValueSelector(vendorForm);
const mapStateToProps = (state) => ({
  vfiOrderAutoSubmit: selector(state, 'vfiOrderAutoSubmit'),
});

export default connect(mapStateToProps)((reduxForm({
  form: vendorForm,
  validate: validateVendor,
  enableReinitialize: true,
  shouldError: () => true,
})(VendorForm)));
