import { OrderItemStatusEnum } from '@api/fulfillment/models';

export const itemNotCheckedInStatuses: string[] = [
  OrderItemStatusEnum.Initial,
  OrderItemStatusEnum.OnHold,
  OrderItemStatusEnum.InPicking,
];

export const itemNotWaterProofedInStatuses: string[] = [
  ...itemNotCheckedInStatuses,
  OrderItemStatusEnum.CheckedIn,
];
