import React, {
  useEffect,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  UpdatePtlWallCommand,
  PtlWallDto,
  PtlWallBinSizeEnum,
} from '@api/fulfillment/models';
import {
  getGetHomefieldApiPtlWallsIdQueryKey,
  usePutHomefieldApiPtlWallsId,
} from '@api/fulfillment/ptl';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { addNewPtlWallForm } from '@constants/reduxForms';
import { materialSwal } from '@util/componentHelper';
import PtlWallForm from './PtlWallForm';
import {
  useForm,
  useFieldArray,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { useQueryClient } from 'react-query';
import Icon from '@sharedComponents/Icons/Icon';
import { ptlWallRowPositionColors } from '@constants/values';

const schema = yup.object({
  name: yup.string().required('Name is required'),
  binSize: yup.string().required('Bin Size is required'),
  numberOfRows: yup.string().required('Number of rows is required'),
  numberOfRowPositions: yup.string().required('Number of positions per row is required'),
  devices: yup.array().of(yup.object().shape({ text: yup.string().required('device barcode is required') })
    .uniqueProperty('text', 'Device barcode must be unique'))
    .required(),
});

export type InitialValues = {
  name?: string;
  binSize?: PtlWallBinSizeEnum;
  numberOfRows: number | string;
  numberOfRowPositions: number | string;
  devices?: string[] | [];
  rowPositions?: number;
  deviceInputs?: number;
};

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  wall: PtlWallDto;
  setUpdatedWallId: Dispatch<SetStateAction<any>>;
}

type Props = OwnProps;

const EditPtlWallModal = React.memo<Props>(({
  isOpen,
  closeModal,
  wall,
  setUpdatedWallId,
}) => {
  const wallRowPositions = wall.rows?.[0].ptlWallRowPositions;
  const { rows } = wall;

  const initialValues: InitialValues = {
    name: wall?.name || '',
    binSize: wall?.binSize,
    numberOfRows: rows?.length || 0,
    numberOfRowPositions: wallRowPositions?.length || 0,
    devices: rows?.map((r: any) => r.deviceId) || [],
    rowPositions: wallRowPositions?.length || 0,
    deviceInputs: rows?.length || 0,
  };

  // Setup react hook form
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => (
      {
        name: initialValues?.name,
        numberOfRowPositions: initialValues?.numberOfRowPositions,
        numberOfRows: initialValues?.numberOfRows,
        devices: initialValues?.devices || [{ text: '' }],
      }
    ), [
      initialValues?.name,
      initialValues?.devices,
      initialValues?.numberOfRowPositions,
      initialValues?.numberOfRows,
    ]),
  });

  const fieldArray = useFieldArray({
    control: methods.control,
    name: 'devices',
  });

  // Set the initial values for the edit form
  useEffect(() => {
    methods.reset({
      name: initialValues?.name,
      numberOfRowPositions: initialValues?.numberOfRowPositions,
      numberOfRows: initialValues?.numberOfRows,
      devices: initialValues?.devices,
    });
  }, [
    wall,
    methods,
  ]);

  const queryClient = useQueryClient();
  const { mutateAsync: updatePtlWall } = usePutHomefieldApiPtlWallsId({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getGetHomefieldApiPtlWallsIdQueryKey(wall?.id ?? 0)
        );
      },
    },
  });

  const numberOfRowPositions = methods.watch('numberOfRowPositions') || 0;
  const numberOfRows = methods.watch('numberOfRows') || 0;

  const deviceInputs = numberOfRows && numberOfRows > 0
    ? Array.from(Array(+numberOfRows).keys())
    : [];
  const rowPositions = numberOfRowPositions && numberOfRowPositions > 0
    ? Array.from(Array(+numberOfRowPositions).keys())
    : [];

  const editPtlWall = useCallback(async (wallParam: UpdatePtlWallCommand) => {
    if (!wallParam.devices) return;
    if (!wall.id) return;

    const variables = {
      id: wall?.id.toString(),
      data: {
        ptlWallId: wall?.id,
        name: wallParam.name,
        binSize: wallParam.binSize,
        numberOfRows: wallParam.numberOfRows,
        numberOfRowPositions: wallParam.numberOfRowPositions,
        devices: wallParam.devices.map((device: any) => device.text),
        colors: rowPositions.map((row, index) => ptlWallRowPositionColors[index]),
      },
    };

    const res = await updatePtlWall(variables);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      methods.reset();
      setUpdatedWallId(wall.id);
      closeModal();
    }
  }, [
    methods,
    updatePtlWall,
    rowPositions,
    setUpdatedWallId,
    wall?.id,
  ]);

  const handleCloseModal = useCallback(
    () => {
      methods.reset();
      closeModal();
    },
    [
      methods,
      wall,
      closeModal,
    ]
  );

  return (
    <Modal
      title={'Edit Wall'}
      isOpen={isOpen}
      modalHeight={'xl'}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Update Wall'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={addNewPtlWallForm}
        />
      )}
    >
      <div>
        <PtlWallForm
          methods={methods}
          deviceInputs={deviceInputs}
          rowPositions={rowPositions}
          onSubmit={editPtlWall}
          rowPositionColors={ptlWallRowPositionColors}
          initialValues={initialValues ?? null}
          fieldArray={fieldArray}
        />
        {
          (numberOfRowPositions < initialValues?.numberOfRowPositions || numberOfRows < initialValues?.numberOfRows) &&
          <div className='warning-message mt-20 ml-20'>
            <Icon materialIcon={'warning'} />
            <span className='warning-text'>
              Be sure to remove any bins from the deleted Row(s)/Row Position(s) on the wall.
            </span>
          </div>
        }
      </div>
    </Modal>
  );
});

export default EditPtlWallModal;
