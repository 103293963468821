import {
  lockerMgrS3Logos,
  lockerMgrS3Products,
  lockerMgrS3GridFiles,
} from '@constants/common';
import { string } from 'prop-types';

// See https://github.com/squadlocker/squadlocker/blob/8af8364d1409973c710bb0ae429315c9336a6060/client/src/utils/text-encoding.js
const encodeTextForLiquidPixels = (value: string): string => {
  value = value.replace(/&/g, 'U0026amp;');
  value = value.replace(/'/g, 'U0027');
  value = value.replace(/\(/g, 'U0026lp;');
  value = value.replace(/\)/g, 'U0026rp;');
  value = value.replace(/\[/g, 'U005B');
  value = value.replace(/\]/g, 'U005D');
  value = value.replace(/\`/g, 'U0060');

  return value;
};

export const validateLpUrl = async (
  designId: string,
  optionId: string,
  itemSKU: string,
  size: string
) => {
  const liquidPixelUrl = `https://squadlocker.liquifire.com/squadlocker?set=designID[${designId}],optionID[${optionId}],prodID[${itemSKU}],sizeID[${size}]&set=playerName[PLAYER NAME],playerNumber[00]&call=url[file:main]&sink=format[pdf]`;
  const response = await fetch(liquidPixelUrl);
  const header = response.headers.get('content-type');
  if (header !== 'application/pdf') {
    return true;
  }

  return false;
};

export const liquidPixelsImageURL = (
  gridPath: string,
  logoPath: string,
  productPath: string,
  alignLogo: string = 'Center',
  scaleLogo: string = '100%'
): Promise<{
  url: string;
  cacheable: boolean;
}> => {
  let allPathsValid = true;
  gridPath = `${lockerMgrS3GridFiles}/${gridPath}`;
  productPath = `${lockerMgrS3Products}/${productPath}`;
  logoPath = `${lockerMgrS3Logos}/${logoPath}`;

  const root = 'https://squadlocker.liquifire.com/squadlocker?set=';

  const grid = `gridPath[${encodeTextForLiquidPixels(gridPath)}]`;
  const product = `productPath[${encodeTextForLiquidPixels(productPath)}]`;
  const logo = `logoPath[${encodeTextForLiquidPixels(logoPath)}]`;
  const logoAlign = `alignLogo[${encodeTextForLiquidPixels(alignLogo)}]`;
  const logoScale = `scaleLogo[${encodeTextForLiquidPixels(scaleLogo)}]`;
  const call = 'url[file:previewTextRasterArtworkText.chain]';

  /*
   * Check and make sure each image path passed as args is valid.
   * if any of them return a 404, we flag allPathsValid as false,
   * so that we know not to cache the LiquidPixels error image
   */
  const testCalls = [
    fetch(gridPath),
    fetch(productPath),
    fetch(logoPath),
  ];

  return Promise.all(testCalls)
    .then((res) => {
      if (res[0].status === 404 || res[1].status === 404 || res[2].status === 404) {
        allPathsValid = false;
      }

      return {
        url: `${root}${grid},${product},${logoAlign},${logoScale},${logo}&call=${call}`,
        cacheable: allPathsValid,
      };
    });
};
