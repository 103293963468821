import React, { PureComponent } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { RootState } from '@redux/index/reducers';
import * as orderManagementActions from '@redux/orderManagement/actions';
import OrderManagementQuickViewHeader from './OrderManagementQuickViewHeader';
import OrderManagementQuickViewInfo from './OrderManagementQuickViewInfo';
import OrderManagementQuickViewActions from './OrderManagementQuickViewActions';

interface OwnProps {
  orderNumber: number;
  closeDetails: () => void;
}

const mapStateToProps = ({ orderManagement }: RootState) => ({
  order: orderManagement.order,
});

const mapDispatchToProps = {
  fetchOrderByOrderNumber: orderManagementActions.fetchOrderByOrderNumber,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

class OrderManagementQuickView extends PureComponent<Props> {
  componentDidMount() {
    const {
      fetchOrderByOrderNumber,
      orderNumber,
    } = this.props;

    fetchOrderByOrderNumber(orderNumber);
  }

  componentDidUpdate(oldProps: Props) {
    const {
      fetchOrderByOrderNumber,
      orderNumber,
    } = this.props;

    if (orderNumber !== oldProps.orderNumber) {
      fetchOrderByOrderNumber(orderNumber);
    }
  }

  fetchOrder = () => {
    const {
      fetchOrderByOrderNumber,
      orderNumber,
    } = this.props;

    fetchOrderByOrderNumber(orderNumber);
  };

  render() {
    const {
      orderNumber,
      closeDetails,
      order,
    } = this.props;

    const isActive = orderNumber ? 'is-active' : '';

    return (
      <div className={`orderManagement__details ${isActive}`}>
        <OrderManagementQuickViewHeader
          order={order}
          closeDetails={closeDetails}
        />
        <div className='orderManagement__containers'>
          <OrderManagementQuickViewInfo
            order={order}
            fetchOrder={this.fetchOrder}
          />
          <OrderManagementQuickViewActions orderNumber={order.orderNumber} />
        </div>
      </div>
    );
  }
}

export default connector(OrderManagementQuickView);
