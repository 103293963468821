/*
 * When passed as the callback for array.sort,
 * this function sorts an array of objects
 * by the specified fieldName in ascending order
 * ex. --> arr.sort(sortByField('shipDate'));
 */
export const sortByField = <T>(fieldName: keyof T) => (a: T, b: T): number => {
  if (a[fieldName] < b[fieldName]) {
    return -1;
  }
  if (b[fieldName] < a[fieldName]) {
    return 1;
  }

  return 0;
};
