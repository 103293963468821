import * as actionTypes from './types';

export const initialState = {
  lockerInfo: {
    id: null,
    dateCreated: null,
    organizationName: null,
    organizationId: null,
    storeName: null,
    markup: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    discounts: null,
    storeLink: null,
    logoUrl: null,
    image: null,
    status: null,
    teamUID: null,
    partnerId: null,
    lockerOwnerId: null,
    logoId: null,
    programName: null,
    programLink: null,
    logosApproved: null,
    enableMultipleLogos: null,
    slug: null,
    emailListId: null,
    freeShipping: false,
    totalOrders: 0,
    totalFundraising: 0,
    sales3Month: 0,
    LCK: null,
    totalSales: 0,
    productionNote: null,
    type: null,
  },
  coloredStyleSelections: [],
  currentQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
  },
  lockerTableState: {
    filter: '',
    statuses: [],
    sport: '',
    partner: '',
    pageNumber: 1,
    pageSize: 10,
    sortOrder: 'desc',
    sortByState: 'id',
    organizationId: '',
    include3MonthSales: false,
    hasOrders: false,
  },
  currentProductQueue: {
    totalPages: 1,
    filter: '',
    pageNumber: 1,
    pageSize: 10,
    queue: [],
    totalCount: 0,
  },
  lockerItemsQueue: {
    totalPages: 1,
    pageNumber: 1,
    pageSize: 10,
    queue: [],
    totalCount: 0,
  },
  managerLogos: [],
  logoBankLogos: [],
  logoBankHistory: [],
  logoBankArchivedLogos: [],
  logoBank: [],
  lockerLogos: [],
  lockerColors: [],
  lockerFloodColors: [],
  allLockerProducts: [],
  lockerManagers: [],
  partners: [],
  managerLockerLogos: [],
  organizations: [],
  availableProducts: {
    pageNumber: 1,
    pageSize: 14,
    totalCount: 0,
    items: [],
    totalPages: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  blankProducts: [],
  sportOptions: [],
  partnerOptions: [],
  logoBankLockerLogos: [],
  lockerStyleColors: {
    allAvailableColors: [],
    colorsByStyle: {},
    selectableColoredStyles: [],
  },
  lockerItem: null,
  lockerChangeLogs: [],
  lockerRosters: [],
  lockerCollections: [],
  bundledCollectionForLockerDictionary: {},
};

export default function logoManager(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_LOCKER:
      return {
        ...state,
        locker: action.locker,
      };
    case actionTypes.UPDATE_LOCKER_INFO:
      return {
        ...state,
        lockerInfo: {
          ...state.lockerInfo,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_FULFILLMENT_LOCKER_INFO:
      return {
        ...state,
        lockerInfo: {
          ...state.lockerInfo,
          LCK: action.payload.gpCustomerId,
          totalOrders: action.payload.totalOrders,
          totalFundraising: action.payload.totalFundraising,
          sales3Month: action.payload.sales3Month,
          totalSales: action.payload.totalSales,
          productionNote: action.payload.productionNote,
        },
      };
    case actionTypes.RESET_FULFILLMENT_LOCKER_INFO:
      return {
        ...state,
        lockerInfo: {
          ...state.lockerInfo,
          LCK: initialState.lockerInfo.LCK,
          totalOrders: initialState.lockerInfo.totalOrders,
          totalFundraising: initialState.lockerInfo.totalFundraising,
          sales3Month: initialState.lockerInfo.sales3Month,
          totalSales: initialState.lockerInfo.totalSales,
          productionNote: initialState.lockerInfo.productionNote,
        },
      };
    case actionTypes.UPDATE_FULFILLMENT_LOCKERS_INFO:
      return {
        ...state,
        lockersInfo: action.payload,
      };
    case actionTypes.UPDATE_CSS_META_INFO:
      return {
        ...state,
        locker: {
          ...state.locker,
          logoIds: action.payload.logoIds,
          colors: action.payload.colors,
        },
      };
    case actionTypes.UPDATE_COLORED_STYLE_SELECTIONS:
      return {
        ...state,
        coloredStyleSelections: action.coloredStyleSelections,
      };
    case actionTypes.UPDATE_CACHED_IMAGE_FOR_CSS:
      const css = state.coloredStyleSelections;
      /*
       * Use for-loop rather than more functional techniques
       * in order to maintain sort order
       */
      for (const item of css) {
        if (item.id === action.payload.id) {
          item.cachedImage = action.payload.imgUrl;
          break;
        }
      }

      return {
        ...state,
        coloredStyleSelections: css,
      };
    case actionTypes.UPDATE_CURRENT_QUEUE:
      return {
        ...state,
        currentQueue: action.payload,
      };
    case actionTypes.CLEAR_CURRENT_QUEUE:
      return {
        ...state,
        currentQueue: initialState.currentQueue,
      };
    case actionTypes.UPDATE_LOCKER_MANAGER_TABLE_STATE:
      return {
        ...state,
        lockerTableState: {
          ...action.payload,
          include3MonthSales: state.lockerTableState.include3MonthSales,
        },
      };
    case actionTypes.UPDATE_LOCKER_MANAGER_3_MONTH_SALES_TABLE_STATE:
      return {
        ...state,
        lockerTableState: {
          ...state.lockerTableState,
          include3MonthSales: action.payload,
        },
      };
    case actionTypes.RESET_LOCKER_MANAGER_TABLE_STATE:
      return {
        ...state,
        lockerTableState: initialState.lockerTableState,
      };
    case actionTypes.UPDATE_CURRENT_PRODUCT_QUEUE:
      return {
        ...state,
        currentProductQueue: action.payload,
      };
    case actionTypes.UPDATE_LOCKER_ITEMS_QUEUE:
      return {
        ...state,
        lockerItemsQueue: action.payload,
      };
    case actionTypes.UPDATE_LOCKER_LOGOS:
      return {
        ...state,
        lockerLogos: action.payload,
      };
    case actionTypes.UPDATE_LOCKER_COLORS:
      return {
        ...state,
        lockerColors: action.payload,
      };
    case actionTypes.UPDATE_LOCKER_STYLE_COLORS:
      return {
        ...state,
        lockerStyleColors: {
          allAvailableColors: action.payload.allAvailableColors,
          colorsByStyle: action.payload.colorsByStyle,
          selectableColoredStyles: action.payload.selectableColoredStyles,
        },
      };
    case actionTypes.UPDATE_LOCKER_FLOOD_COLORS:
      return {
        ...state,
        lockerFloodColors: action.payload,
      };
    case actionTypes.UPDATE_MANAGER_LOGOS:
      return {
        ...state,
        managerLogos: action.payload,
      };
    case actionTypes.UPDATE_LOGO_BANK_LOGOS:
      return {
        ...state,
        logoBankLogos: action.payload,
      };
    case actionTypes.UPDATE_LOGO_BANK_HISTORY:
      return {
        ...state,
        logoBankHistory: action.payload,
      };
    case actionTypes.UPDATE_LOGO_BANK_ARCHIVED_LOGOS:
      return {
        ...state,
        logoBankArchivedLogos: action.payload,
      };
    case actionTypes.UPDATE_ALL_LOCKER_PRODUCTS:
      return {
        ...state,
        allLockerProducts: action.payload,
      };
    case actionTypes.UPDATE_ALL_LOCKER_ROSTERS:
      return {
        ...state,
        lockerRosters: action.data,
      };
    case actionTypes.UPDATE_LOCKER_MANAGERS:
      return {
        ...state,
        lockerManagers: action.payload,
      };
    case actionTypes.UPDATE_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };
    case actionTypes.UPDATE_MANAGER_LOCKER_LOGOS:
      return {
        ...state,
        managerLockerLogos: action.payload,
      };
    case actionTypes.UPDATE_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    case actionTypes.UPDATE_AVAILABLE_PRODUCTS:
      return {
        ...state,
        availableProducts: action.payload,
      };
    case actionTypes.CHANGE_LOGO_APPROVED_STATUS:
      return {
        ...state,
        logoBankLogos: state.logoBankLogos.map(
          (l) => l.id === action.payload.logoId
            ? {
              ...l,
              isApproved: action.payload.isApproved,
            }
            : l
        ),
        logoBankLockerLogos: state.logoBankLockerLogos.map(
          (l) => l.id === action.payload.logoId
            ? {
              ...l,
              isApproved: action.payload.isApproved,
            }
            : l
        ),
      };
    case actionTypes.UPDATE_BLANK_PRODUCTS:
      return {
        ...state,
        blankProducts: action.payload,
      };
    case actionTypes.UPDATE_SPORT_OPTIONS:
      return {
        ...state,
        sportOptions: action.payload,
      };
    case actionTypes.UPDATE_LOCKER_LOGO_BANK_LOGOS:
      return {
        ...state,
        logoBankLockerLogos: action.payload,
      };
    case actionTypes.UPDATE_LOCKER_ITEM:
      return {
        ...state,
        lockerItem: action.payload,
      };
    case actionTypes.UPDATE_LOCKER_CHANGE_LOGS:
      return {
        ...state,
        lockerChangeLogs: action.payload,
      };
    case actionTypes.UPDATE_ALL_LOCKER_COLLECTIONS:
      return {
        ...state,
        lockerCollections: action.data,
      };
    case actionTypes.UPDATE_LOCKER_BUNDLED_COLLECTIONS:
      return {
        ...state,
        bundledCollectionForLockerDictionary: {
          ...state.bundledCollectionForLockerDictionary,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
