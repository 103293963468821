import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FieldArray,
} from 'redux-form';
import ProductVendorMappingsFieldArrayItem from './ProductVendorMappingsFieldArrayItem';
import AddButton from '../../shared/Display/AddButton';

class ProductVendorMappingsFieldArray extends PureComponent {
  createProductVendor = () => ({
    vendorId: null,
    rbiUpc: null,
    esiUpcNumber: null,
    vendorItemNumber: null,
  });

  addProductVendor = (fields) => {
    fields.push(this.createProductVendor());
  };

  removeProductVendor = (fields, index) => {
    fields.remove(index);
  };

  renderProductVendors = ({
    fields,
    meta: { error },
  }) => {
    const { disabled = {} } = this.props;

    return (
      <div className='mb-30'>
        <div className='redux-form__column--size-xl'>
          <div className='tableView__details--header--title mb-30'>
            <span>Vendor Mappings</span>
            <AddButton
              text={'Add new vendor mapping'}
              onClick={this.addProductVendor.bind(null, fields)}
            />
          </div>
        </div>
        <div className='redux-form__column--size-xl'>
          <div className='mr-30 m-b-30'>
            {
              fields.map((member, index) => (
                <Field
                  key={index}
                  name={member}
                  index={index}
                  component={ProductVendorMappingsFieldArrayItem}
                  removeProductVendor={this.removeProductVendor.bind(null, fields, index)}
                  disabled={disabled.productVendors[index]}
                />
              ))
            }
          </div>
        </div>
        {error && <li className='error'>{error}</li>}
      </div>
    );
  };

  render() {
    return (
      <FieldArray
        name={'productVendors'}
        component={this.renderProductVendors}
        rerenderOnEveryChange={true}
      />
    );
  }
}

ProductVendorMappingsFieldArray.propTypes = {
  error: PropTypes.string,
  disabled: PropTypes.object,
  change: PropTypes.func,
};

export default ProductVendorMappingsFieldArray;
