import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';
import { customItemForm } from '@constants/reduxForms';
import { getProductsForStyle } from '@redux/productCatalog/actions';
import { createCustomItem } from '@redux/lockerManager/actions';
import { fetchVendorsFilter } from '@redux/vendorOrders/actions';
import { validateLpUrl } from '@util/liquidPixelsImageURL';
import { materialSwal } from '@util/componentHelper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ColoredStylesTable from '@sharedComponents/CustomItems/ColoredStylesTable/ColoredStylesTable';
import CustomizeItem from '@sharedComponents/CustomItems/CustomizeItem/CustomizeItem';
import InvalidDesignIdModal from './InvalidDesignIdModal';

const customItemsModalTabEnum = {
  ColoredStylesTable: 'ColoredStylesTable',
  CustomizeItem: 'CustomizeItem',
};

class AddCustomItemModal extends PureComponent {
  state = {
    selectedColoredStyle: null,
    selectedTab: customItemsModalTabEnum.ColoredStylesTable,
    initialValues: {
      isCustomItem: true,
      customItemIsHidden: true,
      imageFront: '',
      imageBack: '',
      imageRight: '',
      imageLeft: '',
      discounts: false,
      price: null,
      customName: '',
      vendorDesignId: '',
      decorations: [],
      fundraisingAmount: null,
      personalizationItemType: null,
      requiredItem: false,
      timelineDays: null,
      timelineUuid: null,
      threadColor: null,
      optionId: null,
      designId: null,
      liquidPixelsAutomation: false,
      maxPersonalizationNameLength: null,
      printFiles: [],
    },
    invalidDesignIdModalIsOpen: false,
    changeSkuModalIsOpen: false,
    submissionDisabled: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchVendorsFilter());
  }

  openInvalidDesignIdModal = () => {
    this.setState(() => ({ invalidDesignIdModalIsOpen: true }));
  };

  closeInvalidDesignIdModal = () => {
    this.setState(() => ({ invalidDesignIdModalIsOpen: false }));
  };

  openChangeSkuModal = () => {
    this.setState(() => ({ changeSkuModalIsOpen: true }));
  };

  closeChangeSkuModal = () => {
    this.setState(() => ({ changeSkuModalIsOpen: false }));
  };

  createCustomItem = async (customItem) => {
    let isError = false;
    const { selectedColoredStyle } = this.state;
    const { designId, optionId, liquidPixelsAutomation } = customItem;

    const {
      lockerId,
      dispatch,
      refresh,
    } = this.props;

    if (liquidPixelsAutomation) {
      const { size } = customItem.printFiles[0];
      const itemSKU = selectedColoredStyle.code.split('-')[0];

      isError = await validateLpUrl(designId, optionId, itemSKU, size);
    }

    if (!isError) {
      if (!customItem.imageFront && !customItem.imageBack && !customItem.imageLeft && !customItem.imageRight) {
        customItem.imageFront = selectedColoredStyle.imageUrlFront;
      }
      const res = await createCustomItem(customItem, lockerId, selectedColoredStyle.id);

      if (!customItem.isValidDesignId) {
        this.openInvalidDesignIdModal();
      } else {
        materialSwal('Success', res.message, 'success');
      }

      dispatch(reset(customItemForm));
      refresh();

      this.onCloseModal();
    } else {
      materialSwal('Error', 'Invalid Design or Option ID', 'error');
    }
  };

  onCloseModal = () => {
    this.setState(() => ({
      selectedColoredStyle: null,
      selectedTab: customItemsModalTabEnum.ColoredStylesTable,
      changeSkuModalIsOpen: false,
    }));

    const { closeModal } = this.props;

    closeModal();
  };

  selectColoredStyle = async (coloredStyle) => {
    const { dispatch } = this.props;

    await dispatch(getProductsForStyle(coloredStyle.style.id));

    this.setState(() => ({
      selectedColoredStyle: coloredStyle,
      selectedTab: customItemsModalTabEnum.CustomizeItem,
    }));
  };

  changeColoredStyle = async (coloredStyle) => {
    const { dispatch } = this.props;

    await dispatch(getProductsForStyle(coloredStyle.style.id));

    this.setState(() => ({
      selectedColoredStyle: coloredStyle,
    }));
  };

  changeSubmissionDisabled = (submissionDisabled) => {
    this.setState(() => ({ submissionDisabled }));
  };

  render() {
    const {
      isOpen,
      lockerId,
    } = this.props;

    const {
      selectedTab,
      selectedColoredStyle,
      initialValues,
      invalidDesignIdModalIsOpen,
      changeSkuModalIsOpen,
      submissionDisabled,
    } = this.state;

    return (
      <>
        <InvalidDesignIdModal
          isOpen={invalidDesignIdModalIsOpen}
          closeModal={this.closeInvalidDesignIdModal}
        />
        <Modal
          title={'Create Custom Item'}
          modalHeight={'l'}
          modalWidth={(selectedTab === customItemsModalTabEnum.ColoredStylesTable || changeSkuModalIsOpen) ? 'xl' : 'l'}
          isOpen={isOpen}
          closeModal={this.onCloseModal}
          buttons={(
            <ModalButtons
              displayConfirmBtn={selectedTab === customItemsModalTabEnum.CustomizeItem}
              confirmBtnText={'Save'}
              cancelBtnText={'Cancel'}
              confirmBtnDisabled={submissionDisabled}
              onClose={this.onCloseModal}
              formSubmission={true}
              formId={customItemForm}
            />
          )}
        >
          <div>
            {
              selectedTab === customItemsModalTabEnum.ColoredStylesTable &&
              <ColoredStylesTable
                lockerId={lockerId}
                selectColoredStyle={this.selectColoredStyle}
              />
            }
            {
              selectedTab === customItemsModalTabEnum.CustomizeItem &&
              <CustomizeItem
                lockerId={lockerId}
                selectedColoredStyle={selectedColoredStyle}
                changeColoredStyle={this.changeColoredStyle}
                saveCustomItem={this.createCustomItem}
                initialValues={initialValues}
                changeSkuModalIsOpen={changeSkuModalIsOpen}
                openChangeSkuModal={this.openChangeSkuModal}
                closeChangeSkuModal={this.closeChangeSkuModal}
                changeSubmissionDisabled={this.changeSubmissionDisabled}
                openInvalidDesignIdModal={this.openInvalidDesignIdModal}
                submissionDisabled={submissionDisabled}
                edit={false}
              />
            }
          </div>
        </Modal>
      </>
    );
  }
}

AddCustomItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  lockerId: PropTypes.number.isRequired,
};

export default connect()(AddCustomItemModal);
