import React, { useMemo } from 'react';
import Tabs from '@sharedComponents/Tabs/Tabs';
import { tabSizesEnum } from '@constants/enums/commonEnums';
import { HagPrintRoomCountStatusesDto } from '@api/fulfillment/models/hagPrintRoomCountStatusesDto';
import { PrintRoomTabEnum } from '@api/fulfillment/models';

interface OwnProps {
  logoCounts?: HagPrintRoomCountStatusesDto;
  selectTab: (newSelectedTab: PrintRoomTabEnum) => void;
  selectedTab: PrintRoomTabEnum;
}

type Props = OwnProps;

const PrintRoomNewTableTabs = React.memo<Props>(({
  logoCounts,
  selectTab,
  selectedTab,
}) => {
  const tabs = useMemo(() => ([
    {
      title: <strong>{`Print Queue (${logoCounts?.wholeOrderPrintReady ?? 0})`}</strong>,
      name: PrintRoomTabEnum.PrintReady,
    },
    {
      title: <strong>{`In Print (${logoCounts?.wholeOrderPrinting ?? 0})`}</strong>,
      name: PrintRoomTabEnum.Printing,
    },
    {
      title: <strong>{`Recently Printed (${logoCounts?.wholeOrderPrinted ?? 0})`}</strong>,
      name: PrintRoomTabEnum.Printed,
    },
    {
      title: <strong>{`Priority (${logoCounts?.singleDecorationPrints ?? 0})`}</strong>,
      name: PrintRoomTabEnum.Priority,
    },
  ]
  ), [logoCounts]);

  return (
    <Tabs
      tabs={tabs}
      selectedTab={selectedTab}
      selectTab={selectTab as (newSelectedTab: string) => void}
      size={tabSizesEnum.Large}
    />
  );
});

export default PrintRoomNewTableTabs;
