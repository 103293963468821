import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkInventory } from '@redux/weedingAndMaskingRoom/actions';
import { finalize } from '@APICalls/weedingAndMasking/actions';
import { materialSwal } from '@util/componentHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import Icon from '@sharedComponents/Icons/Icon';
import TextInput from '@sharedComponents/Inputs/TextInput';

class FinalizeModal extends PureComponent {
  state = {
    inventoryName: '',
  };

  componentDidMount() {
    const {
      dispatch,
      orderNumber,
    } = this.props;

    dispatch(checkInventory(orderNumber));
  }

  finalize = async (orderNumber, sheetId, inventory, callback) => {
    const { closeModal } = this.props;

    const res = await finalize(orderNumber, sheetId, inventory);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      closeModal();
      callback();
    }
  };

  finalizeOnClick = () => {
    const {
      orderNumber,
      sheetId,
      callback,
    } = this.props;

    const { inventoryName } = this.state;

    this.finalize(orderNumber, sheetId, inventoryName, callback);
  };

  handleInventoryNameChange = (e) => {
    const inventoryName = e.target.value;
    this.setState(() => ({ inventoryName }));
  };

  render() {
    const {
      orderNumber,
      closeModal,
      inventoryState,
      isOpen,
    } = this.props;

    let { message } = inventoryState;
    let icon = <div />;

    if (inventoryState.result === 'Inventory') {
      message = 'Inventory artwork';
      icon = <Icon materialIcon={'inbox'} />;
    } else if (inventoryState.result === 'Schedule') {
      message = 'Ready for production';
      icon = <Icon materialIcon={'settings'} />;
    }

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        modalSize={'m'}
        title={`Finalize Print Assembly - Order O${orderNumber}`}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            onConfirm={this.finalizeOnClick}
            confirmBtnText={'Finalize'}
          />
        )}
      >
        {
          isOpen &&
          <div>
            <div className='finalize__message'>
              {icon}
              {message}
            </div>
            <TextInput
              placeholder={'Enter Inventory Name (optional)'}
              onChange={this.handleInventoryNameChange}
              inputClasses={'w-100'}
            />
          </div>
        }
      </Modal>
    );
  }
}

FinalizeModal.propTypes = {
  orderNumber: PropTypes.number.isRequired,
  sheetId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  inventoryState: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ weedingAndMaskingRoom }) => ({
  inventoryState: weedingAndMaskingRoom.inventoryState,
});

export default connect(mapStateToProps)(FinalizeModal);
