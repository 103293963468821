import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Paper, ThemeProvider, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import {
  getGetHomefieldApiV2MerchandisingIdQueryKey
  , useDeleteHomefieldApiV2MerchandisingId, useGetHomefieldApiV2MerchandisingId
  , usePatchHomefieldApiV2MerchandisingIdAbort
  , usePatchHomefieldApiV2MerchandisingIdCreated
  , usePatchHomefieldApiV2MerchandisingIdReady
  ,
} from '@api/accounts/merchandising';
import BackLink from '@components/shared/Navigation/BackLink';
import { merchandisingUrl } from '@constants/clientUrls/clientUrls';
import { muiTheme } from '@constants/values';
import ActionButton from '@components/shared/Buttons/ActionButton';
import EditMerchandisingEffortDialog from './EditMerchandisingEffortDialog';
import InfoField from '@components/shared/Display/InfoField';
import EditButton from '@components/shared/Display/EditButton';
import AddMerchandisingSourceDialog from './AddMerchandisingSourceDialog';
import AddMerchandisingTargetDialog from './AddMerchandisingTargetDialog';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import PermissionHandler from '@components/shared/Authorization/PermissionHandler';
import { checkPermissions, roleMapping } from '@util/roleCheck';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '@redux/index/reducers';
import { useQueryClient } from 'react-query';
import { MerchandisingEffortStatus } from '@api/accounts/models';
import SimpleConfirmationModal from '@components/shared/Modal/SimpleConfirmationModal';
import { navigateToPage } from '@util/componentHelper';

const mapStateToProps = ({ oidc }: RootState) => ({
  roles: roleMapping(oidc),
});

const connector = connect(mapStateToProps);

type MerchParams = {
  effortId: string;
};

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  title: {
    fontWeight: 'normal',
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    width: '90rem',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

type Props = ConnectedProps<typeof connector>;

const MerchandisingEffortDetails = ({ roles }: Props) => {
  const classes = useStyles();
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false);
  const [addSourceModalIsOpen, setAddSourceModalIsOpen] = useState<boolean>(false);
  const [addTargetModalIsOpen, setAddTargetModalIsOpen] = useState<boolean>(false);
  const [isAbortModalOpen, setIsAbortModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const { effortId } = useParams<MerchParams>();
  const effortAsInt = parseInt(effortId, 10);

  const {
    data: effort,
    refetch,
  } = useGetHomefieldApiV2MerchandisingId(parseInt(effortId, 10), { query: { enabled: true } });

  const openAddTargetModal = useCallback(() => {
    setAddTargetModalIsOpen(true);
  }, [setAddTargetModalIsOpen]);

  const closeAddTargetModal = useCallback(() => {
    setAddTargetModalIsOpen(false);
  }, [setAddTargetModalIsOpen]);

  const openAddSourceModal = useCallback(() => {
    setAddSourceModalIsOpen(true);
  }, [setAddSourceModalIsOpen]);

  const closeAddSourceModal = useCallback(() => {
    setAddSourceModalIsOpen(false);
  }, [setAddSourceModalIsOpen]);

  const openEditModal = useCallback(() => {
    setEditModalIsOpen(true);
  }, [setEditModalIsOpen]);

  const closeEditModal = useCallback(() => {
    setEditModalIsOpen(false);
  }, [setEditModalIsOpen]);

  const handleFormSubmit = useCallback(() => {
    refetch();
  }, [refetch]);

  const openAbortModal = useCallback(() => {
    setIsAbortModalOpen(true);
  }, [setIsAbortModalOpen]);

  const closeAbortModal = useCallback(() => {
    setIsAbortModalOpen(false);
  }, [setIsAbortModalOpen]);

  const openDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, [setIsDeleteModalOpen]);

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, [setIsDeleteModalOpen]);

  const queryClient = useQueryClient();
  const { mutate: markAsReady } = usePatchHomefieldApiV2MerchandisingIdReady(
    {
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetHomefieldApiV2MerchandisingIdQueryKey(parseInt(effortId, 10)));
        },
      },
    }
  );
  const { mutate: markAsCreated } = usePatchHomefieldApiV2MerchandisingIdCreated(
    {
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetHomefieldApiV2MerchandisingIdQueryKey(parseInt(effortId, 10)));
        },
      },
    }
  );
  const { mutate: markAsAborted } = usePatchHomefieldApiV2MerchandisingIdAbort(
    {
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetHomefieldApiV2MerchandisingIdQueryKey(parseInt(effortId, 10)));
        },
      },
    }
  );
  const { mutate: deleteEffort } = useDeleteHomefieldApiV2MerchandisingId(
    {
      mutation: {
        onSuccess: () => {
          navigateToPage(merchandisingUrl);
        },
      },
    }
  );

  const handleAbort = useCallback(() => {
    markAsAborted({ id: effortAsInt });
    closeAbortModal();
  }, [markAsAborted, effortAsInt, closeAbortModal]);

  const handleDelete = useCallback(() => deleteEffort({ id: effortAsInt }), [deleteEffort, effortAsInt]);

  return (
    <ThemeProvider theme={muiTheme}>
      <Box>
        <div className='navigation'>
          <BackLink
            text={'Merchandising Efforts'}
            url={merchandisingUrl}
          />
          <div className='flex'>
            <ActionButton
              text={'Merchandising Actions'}
              classes={'ml-10'}
              actions={[
                {
                  text: 'Add Source',
                  icon: 'source',
                  action: openAddSourceModal,
                  permissions: PermissionsEnum.MerchandisingEdit,
                  isVisible: effort?.result?.status === MerchandisingEffortStatus.Created
                    || effort?.result?.status === MerchandisingEffortStatus.Ready,
                },
                {
                  text: 'Add Targets',
                  icon: 'adjust',
                  action: openAddTargetModal,
                  permissions: PermissionsEnum.MerchandisingEdit,
                  isVisible: effort?.result?.status === MerchandisingEffortStatus.Created
                    || effort?.result?.status === MerchandisingEffortStatus.Ready,
                },
                {
                  text: 'Delete',
                  icon: 'delete',
                  action: openDeleteModal,
                  permissions: PermissionsEnum.MerchandisingEdit,
                  isVisible: effort?.result?.status === MerchandisingEffortStatus.Created
                    || effort?.result?.status === MerchandisingEffortStatus.Ready,
                },
                {
                  text: 'Mark as Ready',
                  icon: 'check',
                  action: () => markAsReady({ id: effortAsInt }),
                  permissions: PermissionsEnum.MerchandisingEdit,
                  isVisible: effort?.result?.status === MerchandisingEffortStatus.Created,
                },
                {
                  text: 'Mark as Not Ready',
                  icon: 'close',
                  action: () => markAsCreated({ id: effortAsInt }),
                  permissions: PermissionsEnum.MerchandisingEdit,
                  isVisible: effort?.result?.status === MerchandisingEffortStatus.Ready,
                },
                {
                  text: 'Abort Effort',
                  icon: 'dangerous',
                  action: openAbortModal,
                  permissions: PermissionsEnum.MerchandisingEdit,
                  isVisible: effort?.result?.status === MerchandisingEffortStatus.Loaded,
                },
              ]}
              disabled={checkPermissions(roles, PermissionsEnum.MerchandisingEdit) === false}
            />
          </div>
        </div>
        <SimpleConfirmationModal
          isOpen={isAbortModalOpen}
          confirm={handleAbort}
          closeModal={closeAbortModal}
          isDangerousAction={true}
          title={'Abort Merchandising Effort'}
          confirmationBody={'Are you sure you want to abort this merchandising effort? This will queue the effort to have all items removed during next scheduled job.'}
        />
        <SimpleConfirmationModal
          isOpen={isDeleteModalOpen}
          confirm={handleDelete}
          closeModal={closeDeleteModal}
          title={'Delete Merchandising Effort'}
          confirmationBody={'Are you sure you want to delete this merchandising effort?'}
        />
        <EditMerchandisingEffortDialog
          isOpen={editModalIsOpen}
          closeModal={closeEditModal}
          onSubmit={handleFormSubmit}
          item={effort?.result}
        />
        <AddMerchandisingSourceDialog
          isOpen={addSourceModalIsOpen}
          closeModal={closeAddSourceModal}
          onSubmit={handleFormSubmit}
          item={effort?.result}
        />
        <AddMerchandisingTargetDialog
          isOpen={addTargetModalIsOpen}
          closeModal={closeAddTargetModal}
          onSubmit={handleFormSubmit}
          item={effort?.result}
        />
        <Paper className={classes.paper}>
          <div className={'lockerManagerEdit__details-header--info'}>
            <span>{effort?.result?.name}</span>
            <PermissionHandler permissions={PermissionsEnum.MerchandisingEdit}>
              <EditButton
                onClick={openEditModal}
                dataTest={'effort-edit'}
              />
            </PermissionHandler>
          </div>
          <Divider className={classes.divider} />
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={3}>
              <InfoField
                label={'Start Date'}
                value={moment(effort?.result?.startDate).format('MM/DD/YYYY')}
              />
              <InfoField
                label={'End Date'}
                value={effort?.result?.endDate ? moment(effort?.result?.endDate).format('MM/DD/YYYY') : ''}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <InfoField
                label={'Type'}
                value={effort?.result?.type}
              />
              <InfoField
                label={'Status'}
                value={effort?.result?.status}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <InfoField
                label={'Product Count'}
                value={effort?.result?.sourceProductCount}
              />
              <InfoField
                label={'Target Count'}
                value={effort?.result?.targetCount}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </ThemeProvider >
  );
};

export default connector(MerchandisingEffortDetails);
