import {
  lockerDetailsUrl,
  organizationDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import React from 'react';
import { Link } from 'react-router-dom';

interface OwnProps {
  lockerId?: number | null;
  organizationId?: number | null;
}

type Props = OwnProps;

const LockerOrganizationHeaderCard = React.memo<Props>(({
  lockerId,
  organizationId,
}) => {
  let locker: string | JSX.Element = '';
  if (lockerId) {
    locker = (
      <Link
        to={lockerDetailsUrl(lockerId!)}
        className='button button--link button--underline'
        target='_blank'
      >
        {`L${lockerId}`}
      </Link>
    );
  }

  let organization: string | JSX.Element = '';
  if (organizationId) {
    organization = (
      <Link
        to={organizationDetailsUrl(organizationId!)}
        className='button button--link button--underline'
        target='_blank'
      >
        {`ORG${organizationId}`}
      </Link>
    );
  }

  return (
    <div className='sheet__files-item item-locker-organization-card' >
      <div className='sheet__files-title'>
        <div className='sheet__files-title-left flex' >
          Locker/Organization Id
        </div>
      </div>
      <ul className='sheet__files-list'>
        <li>
          <div className='flex'>
            {locker}
            {organization}
          </div>
        </li>
      </ul>
    </div>
  );
});

export default LockerOrganizationHeaderCard;
