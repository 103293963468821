import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Expander from '@sharedComponents/Buttons/Expander';
import LogoBankHistoryChangeLog from './LogoBankHistoryChangeLog';

class LogoBankHistory extends PureComponent {
  state = {
    isExpanded: false,
  };

  changeExpand = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  render() {
    const { isExpanded } = this.state;
    const { log } = this.props;

    return (
      <div className='logo-bank__container logo-bank__container-half'>
        <div className='header'>
          <span>
            History
          </span>
          <Expander
            isExpanded={isExpanded}
            toggle={this.changeExpand}
          />
        </div>
        {isExpanded && <LogoBankHistoryChangeLog log={log} />}
      </div>
    );
  }
}

LogoBankHistory.propTypes = {
  log: PropTypes.array.isRequired,
};

export default LogoBankHistory;
