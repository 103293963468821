import React from 'react';
import PropTypes from 'prop-types';
import Label from '@sharedComponents/Display/Label';
import Card from '@sharedComponents/Display/Card';

const CollectionItemCard = React.memo(({ item }) => (
  <Card
    title={item.sku}
    subtitle={item.name}
    imageUrl={item.cachedImage}
    labels={item.isCustomItem
      ? (
        <Label
          text={'Custom'}
          type={'status-blue'}
        />
      )
      : null
    }
    classes={'cursor-pointer'}
  />
));

CollectionItemCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CollectionItemCard;
