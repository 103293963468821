import React from 'react';
import { fileExtensionFromUrl } from '@util/stringHelpers';
import { downloadFile } from '@util/componentHelper';
import { imgURLRootStem } from '@constants/common';

interface OwnProps {
  files: string[];
  fileNameDict?: Record<string, string>;
}

type Props = OwnProps;

const AttachedFiles = React.memo<Props>(({
  files,
  fileNameDict = {},
}) => {
  const fileUrl = (file: string) => file.substring(0, 7) === 'http://' || file.substring(0, 8) === 'https://' ? file : (imgURLRootStem + '/' + file);
  const fileName = (file: string) => fileNameDict[file] || file.substring(file.lastIndexOf('/') + 1);

  const renderFile = (file: string, i: number) => (
    i === files.length - 1
      ? (
        <span key={i}>
          <a
            onClick={downloadFile(fileUrl(file), fileName(file))}
            href={fileUrl(file)}
          >{`${i + 1}${fileExtensionFromUrl(file)}`}
          </a>
        </span>
      )
      : (
        <span key={i}>
          <a
            onClick={downloadFile(fileUrl(file), fileName(file))}
            href={fileUrl(file)}
          >{`${i + 1}${fileExtensionFromUrl(file)}`}
          </a> /
        </span>
      )
  );

  if ((files || []).length > 0) {
    return <>{files.map(renderFile)}</>;
  }

  return <span>No files available</span>;
});

export default AttachedFiles;
