import React, { useCallback } from 'react';
import { emptyImageTextEnum } from '@constants/enums/commonEnums';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  openModalWithUrl?: (imageUrl: string) => void;
  imageUrl: string | undefined;
  openInfoModal?: boolean;
  sku?: string;
  noImageText?: string;
  height?: string;
  width?: string;
  transparent?: boolean;
  setLargeImageOnClick?: (img: string) => void;
}

type Props = OwnProps;

const ImagePreviewCell = React.memo(({
  openModalWithUrl,
  openInfoModal = false,
  imageUrl,
  sku,
  noImageText = emptyImageTextEnum.NoImage,
  height = '80px',
  width = '80px',
  transparent = true,
  setLargeImageOnClick,
}: Props) => {
  const handleOpenModalWithUrl = useCallback(() => {
    const propReturned = sku ?? imageUrl;
    if (!openModalWithUrl) return;
    openModalWithUrl(propReturned!);
  }, [
    openModalWithUrl,
    sku,
    imageUrl,
  ]);

  const onImageClick = useCallback(() => {
    if (openModalWithUrl) {
      handleOpenModalWithUrl();
    }

    if (setLargeImageOnClick) {
      setLargeImageOnClick(imageUrl!);
    }
  }, [
    handleOpenModalWithUrl,
    imageUrl,
    openModalWithUrl,
    setLargeImageOnClick,
  ]);

  if (!imageUrl) {
    return (
      <div
        onClick={openInfoModal ? handleOpenModalWithUrl : undefined}
        style={{
          height,
          width,
        }}
        className='missing-image-overlay'
      >
        <div className='missing-text'>
          {noImageText}
          {
            openInfoModal &&
            <div className='details__item-overlay'>
              <Icon
                materialIcon={'search'}
                classes={'details__item-preview-icon'}
              />
            </div>
          }
        </div>
      </div>
    );
  }

  return (
    <a
      onClick={onImageClick}
      style={{
        height,
        width,
      }}
      className={`details__item-thumbnail ${transparent && 'details__item-thumbnail--color-transparent'}`}
    >
      <div
        className='details__item-image'
        style={{ backgroundImage: `url("${imageUrl}")` }}
      >
        {
          openModalWithUrl &&
          <div className='details__item-overlay'>
            <Icon
              materialIcon={'search'}
              classes={'details__item-preview-icon'}
            />
          </div>
        }
      </div>
    </a>
  );
});

export default ImagePreviewCell;
