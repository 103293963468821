import React, {
  useMemo,
  useCallback,
  useState,
} from 'react';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import {
  VoucherCollectionDetailDto,
  VoucherInstanceDto,
  VoucherInstanceStatusEnum,
} from '@api/financialServices/models';
import DataGrid from '@components/shared/DataGrid/DataGrid';
import InfoField from '@sharedComponents/Display/InfoField';
import { formatDollarAmount } from '@util/numberHelpers';
import { keyNameEnum } from '@constants/enums/commonEnums';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';

type Voucher = VoucherInstanceDto & {
  id: number;
  lockerId: number;
};

const groupByColumns = [
  'lockerId',
  'voucherCollectionId',
] as (keyof Voucher)[];

interface OwnProps {
  isOpen: boolean;
  voucherOrderCollections: VoucherCollectionDetailDto[] | undefined;
  closeModal: () => void;
  onSubmit: (selectedIds: number[]) => void;
}

type Props = OwnProps;

const VouchersSelectForCancellationModal = React.memo<Props>(({
  isOpen,
  closeModal,
  voucherOrderCollections,
  onSubmit,
}) => {
  const [
    selectedVoucherIds,
    setSelectedVoucherIds,
  ] = useState<number[]>([]);
  const [
    search,
    setSearch,
  ] = useState<string>('');

  const onSearch = useCallback((e: React.KeyboardEvent) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const newSearchInput = (e.target as HTMLInputElement).value;
    setSearch(newSearchInput);
  }, []);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const dataGridColumns = useMemo(() => [
    {
      name: 'Locker Id',
      key: 'lockerId' as keyof Voucher,
      sortable: false,
      selectionColumn: false,
      groupByDisabled: false,
      visible: false,
      groupTitleTemplate: (row: Voucher): string => {
        const collection = voucherOrderCollections?.find((x) => x.lockerId === row.lockerId);

        return `${collection?.lockerName}`;
      },
    },
    {
      name: 'VoucherCollectionId',
      key: 'voucherCollectionId' as keyof Voucher,
      sortable: false,
      selectionColumn: false,
      groupByDisabled: false,
      visible: false,
      groupTitleTemplate: (row: Voucher): string => {
        const collection = voucherOrderCollections?.find((x) => x.id === row.voucherCollectionId);

        return `${collection?.rosterTeamName} (${collection?.collectionName})`;
      },
    },
    {
      name: 'Full Name',
      key: '' as keyof Voucher,
      sortable: false,
      selectionColumn: false,
      groupByDisabled: true,
      width: 50,
      visible: false,
      template: (row: Voucher) => (`${row.firstName} ${row.lastName}`),
    },
    {
      name: 'Price',
      key: 'amountLeftForRefund' as keyof Voucher,
      sortable: false,
      selectionColumn: false,
      groupByDisabled: true,
      width: 43,
      visible: false,
      template: (row: Voucher) => ((row.amountLeftForRefund ?? 0) > 0
        ? `${formatDollarAmount(row.amountLeftForRefund)}`
        : ''),
    },
  ], [voucherOrderCollections]);

  const groupLayerProperties = useMemo(() => (
    [
      {
        number: 1,
        alwaysExpanded: false,
        height: 60,
        showCount: false,
      },
      {
        number: 2,
        alwaysExpanded: true,
        height: 50,
        showCount: false,
      },
    ]
  ), []);

  const rows = useMemo(() => {
    let vouchers = [] as Voucher[];
    voucherOrderCollections?.forEach((collection) => {
      const collectionVouchers = collection!.voucherInstances!
        .filter((x) => !x.cancellationId
          && x.status !== VoucherInstanceStatusEnum.Redeemed
          && (x.lastName?.toUpperCase()?.includes(search.toUpperCase())
            || x.firstName?.toUpperCase().includes(search.toUpperCase())))
        .map((x) => ({
          lockerId: collection.lockerId,
          ...x,
        }) as Voucher);

      vouchers = [
        ...vouchers,
        ...collectionVouchers,
      ];
    });

    return vouchers;
  }, [
    voucherOrderCollections,
    search,
  ]);

  const onSelectionChange = useCallback((ids: number[]) => {
    setSelectedVoucherIds(ids);
  }, []);

  const confirm = useCallback(() => {
    onSubmit(selectedVoucherIds);
    setSelectedVoucherIds([]);
  }, [
    onSubmit,
    selectedVoucherIds,
  ]);

  const selectedVouchersTotalAmount = useMemo(() => {
    const selectedVouchers = rows.filter((v) => selectedVoucherIds.includes(v.id));

    return selectedVouchers.reduce((total, v) => total + v.amountLeftForRefund!, 0);
  }, [
    selectedVoucherIds,
    rows,
  ]);

  if (!voucherOrderCollections || !voucherOrderCollections.length) {
    return null;
  }

  return (
    <Modal
      title={'Select Voucher(s) for Cancellation'}
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'l'}
      modalWidth={'l'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Confirm'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          onConfirm={confirm}
        />
      )}
    >
      <div>
        <div>
          <SearchFilter
            search={onSearch}
            clearSearch={clearSearch}
            initialValue={''}
          />
        </div>
        <DataGrid<Voucher, 'id'>
          rows={rows}
          columns={dataGridColumns}

          selectable={true}
          selectionKey={'id'}
          onSelectionChange={onSelectionChange}
          selectedIds={selectedVoucherIds}

          groupLayersCount={2}
          groupLayersProperties={groupLayerProperties}
          groupByColumns={groupByColumns}
          defaultGroupingOnly={true}
          footer={(
            <div className='flex'>
              <InfoField
                label={'Total Vouchers Selected'}
                value={`${selectedVoucherIds.length}/${rows.length}`}
                fieldClass={'ml-15 w-50'}
              />
              {
                selectedVouchersTotalAmount > 0 &&
                <InfoField
                  label={'Total Refund Amount'}
                  value={formatDollarAmount(selectedVouchersTotalAmount)}
                  fieldClass={'ml-15'}
                />
              }
            </div>
          )}
        />
      </div>
    </Modal>
  );
});

export default VouchersSelectForCancellationModal;
