import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';
import { useGetHomefieldApiProductionassemblynewActivitytracker } from '@api/fulfillment/production-assembly-new';
import { useGetHomefieldApiOrderassemblyActivitytracker } from '@api/fulfillment/order-assembly';
import { useGetHomefieldApiQualitycheckActivitytracker } from '@api/fulfillment/quality-check';
import { DecorationMethodEnum } from '@api/productCatalog/models';
import { dropdownSizeS } from '@constants/values';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import { RootState } from '@redux/index/reducers';
import { mapToOptionsList } from '@util/mappingHelper';
import Spinner from '@sharedComponents/Display/Spinner';
import InfoField from '@sharedComponents/Display/InfoField';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

enum ActivityTrackerTimePeriodEnum {
  Today = 'Today',
  ThisWeek = 'ThisWeek',
}

const options = mapToOptionsList<string, any>({
  list: [
    {
      code: ActivityTrackerTimePeriodEnum.Today.toString(),
      name: 'Today',
    },
    {
      code: ActivityTrackerTimePeriodEnum.ThisWeek.toString(),
      name: 'This week',
    },
  ],
  key: 'code',
  value: 'code',
  name: 'name',
});

interface OwnProps {
  orderItems: ProductionAssemblyItemGroupDto[] | undefined;
  decorationMethod?: DecorationMethodEnum;
  query: typeof useGetHomefieldApiProductionassemblynewActivitytracker
  | typeof useGetHomefieldApiOrderassemblyActivitytracker
  | typeof useGetHomefieldApiQualitycheckActivitytracker;
  itemsLabel: string;
  unitsLabel: string;
}

const mapStateToProps = ({ oidc }: RootState) => ({
  oidc: oidc.user.profile.sub,
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const ActivityTracker = React.memo(({
  decorationMethod,
  oidc,
  orderItems,
  itemsLabel,
  unitsLabel,
  query,
}: Props) => {
  const [
    startDateUtc,
    setStartDateUtc,
  ] = useState(dateRangeEnum.today.minDatePlaced);
  const [
    endDateUtc,
    setEndDateUtc,
  ] = useState(dateRangeEnum.today.maxDatePlaced);

  const {
    data: activityTracker,
    refetch: fetchActivityTracker,
  } = query(
    {
      userId: oidc,
      startDateUtc: startDateUtc?.toISOString(),
      endDateUtc: endDateUtc?.toISOString(),
      decorationMethod,
    }
  );

  useEffect(() => {
    if (!orderItems) return;

    fetchActivityTracker();
  }, [
    orderItems,
    fetchActivityTracker,
  ]);

  useEffect(() => {
    fetchActivityTracker();
  }, [
    startDateUtc,
    endDateUtc,
    fetchActivityTracker,
  ]);

  const changeTimePeriod = useCallback((value) => {
    if (value === ActivityTrackerTimePeriodEnum.Today) {
      setStartDateUtc(dateRangeEnum.today.minDatePlaced);
      setEndDateUtc(dateRangeEnum.today.maxDatePlaced);
    } else if (value === ActivityTrackerTimePeriodEnum.ThisWeek) {
      setStartDateUtc(dateRangeEnum.thisWeek.minDatePlaced);
      setEndDateUtc(dateRangeEnum.thisWeek.maxDatePlaced);
    }
  }, []);

  return (
    <div className='activity-tracker__header'>
      <Dropdown
        defaultValue={ActivityTrackerTimePeriodEnum.Today.toString()}
        options={options}
        onChange={changeTimePeriod}
        size={`${dropdownSizeS}px`}
      />
      {
        !activityTracker
          ? <Spinner />
          : (
            <div className='justify__space-between'>
              <div className='justify__space-between mr-20'>
                <InfoField
                  label={itemsLabel}
                  value={activityTracker?.itemsCount}
                />
              </div>
              <div className='justify__space-between mr-20'>
                <InfoField
                  label={unitsLabel}
                  value={activityTracker?.unitsCount}
                />
              </div>
            </div>
          )}
    </div>
  );
});

export default connector(ActivityTracker);
