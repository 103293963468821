import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import Chip from '@sharedComponents/Inputs/Chips/Chip';
import EditPersonalizationColorsItem from './EditPersonalizationColorsItem';

class EditPersonalizationColorsInput extends Component {
  state = {
    isInputActive: false,
    showColorsList: false,
    searchInput: '',
    filteredColors: this.props.allColors,
  };

  componentDidUpdate(prevProps, prevState) {
    const { allColors } = this.props;
    const { searchInput } = this.state;

    if (searchInput !== prevState.searchInput) {
      if (searchInput.length > 0) {
        this.setState(() => ({
          filteredColors: allColors.filter((c) => c.code.toLowerCase().includes(searchInput.toLowerCase())),
        }));
      } else {
        this.setState(() => ({ filteredColors: allColors }));
      }
    }
  }

  onInputFocus = () => {
    this.setState(() => ({
      isInputActive: true,
      showColorsList: true,
    }));
  };

  onInputBlur = () => {
    this.searchInput.value = '';
    this.setState(() => ({
      isInputActive: false,
      showColorsList: false,
      searchInput: '',
    }));
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState(() => ({
      searchInput: newSearchInput,
      showColorsList: true,
    }));
  };

  addColorToList = (color) => {
    const { fields } = this.props;

    if (fields.getAll().find((c) => c === color)) return;

    fields.push(color);
  };

  removeColorFromList = (index) => {
    const { fields } = this.props;

    fields.remove(index);
  };

  renderColorItem = (color, index) => (
    <Chip
      key={color}
      text={color}
      onClick={this.removeColorFromList.bind(null, index)}
      hasIcon={true}
    />
  );

  render() {
    const {
      fields,
      allColors,
      disabled,
    } = this.props;

    const {
      isInputActive,
      showColorsList,
      filteredColors,
    } = this.state;

    const initializedFields = fields.getAll() || [];
    const mappedFields = [];

    for (const field of initializedFields) {
      const floodColor = allColors.find((c) => c.id === field);
      if (floodColor) {
        mappedFields.push(floodColor.code);
      }
    }

    return (
      <div className='w-100'>
        <label className='redux-form__label'>
          Additional Custom Personalization Colors
        </label>
        <div className={`chips-search has-icon ${isInputActive ? 'active' : ''}`}>
          <div>
            <Icon materialIcon={'search'} />
          </div>
          <div className='chips'>
            {mappedFields.map((color, index) => this.renderColorItem(color, index))}
            <div className='text-field mb-0'>
              <OutsideClickWrapper onClick={this.onInputBlur}>
                <>
                  <input
                    type='text'
                    placeholder={initializedFields.length === 0 ? 'Enter personalization color' : undefined}
                    onChange={this.onInputChange}
                    onFocus={this.onInputFocus}
                    ref={(r) => this.searchInput = r}
                  />
                  {
                    showColorsList &&
                    <div className='suggestions suggestions__dropdown suggestions--size-l custom-scrollbar'>
                      {
                        filteredColors.map((color) => (
                          <EditPersonalizationColorsItem
                            key={color.id}
                            color={color}
                            colors={initializedFields}
                            addColor={this.addColorToList}
                            isDisabled={(disabled || []).includes(color.id)}
                          />
                        ))
                      }
                    </div>
                  }
                </>
              </OutsideClickWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditPersonalizationColorsInput.propTypes = {
  allColors: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.object.isRequired,
  disabled: PropTypes.array,
};

export default EditPersonalizationColorsInput;
