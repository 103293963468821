import React from 'react';
import { Link } from 'react-router-dom';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  lockerId: number;
  teamName: string;
  onCopyToClipboard: (lockerId: number, e: React.MouseEvent) => void;
  copyText?: string;
}

type Props = OwnProps;

const LockerHeader = React.memo(({
  lockerId,
  teamName,
  onCopyToClipboard,
  copyText,
}: Props) => (
  <div>
    <div className='order__items--locker'>
      <div className='align__center'>
        <Link
          className='link'
          target='_blank'
          to={lockerDetailsUrl(lockerId)}
        >
          L<span id={`dataToCopy-${lockerId}`}>{lockerId}</span> - {teamName}
        </Link>
        <Icon
          materialIcon={'content_copy'}
          onClick={onCopyToClipboard.bind(null, lockerId)}
        />
        <span className='copy-text'>{copyText}</span>
      </div>
    </div>
  </div>
));

export default LockerHeader;
