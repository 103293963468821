import React from 'react';
import { Link } from 'react-router-dom';
import { PrintQueueDto } from '@api/fulfillment/models';
import rushIcon from '@assets/icon_rush.svg';
import expressIcon from '@assets/icon_express-production.svg';
import { printSheetUrl } from '@constants/clientUrls/clientUrls';
import { parseDateNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  item: PrintQueueDto;
}

type Props = OwnProps;

const IdWithIconsCell = React.memo<Props>(({ item }) => {
  const warning = item.hasWarningMessages && (
    <i className='material-icons print__warning'>warning</i>
  );

  let rush: string | JSX.Element = '';
  if (item.rushShipDate) {
    rush = (
      <MaterialTooltip
        tooltipText={parseDateNumeric(item.rushShipDate)}
        placement={'top'}
      >
        <Icon
          iconUrl={rushIcon}
          width={22}
          height={15}
          marginTop={2}
        />
      </MaterialTooltip>
    );
  }

  let expressProduction: string | JSX.Element = '';
  if (item.expressProduction) {
    expressProduction = (
      <Icon
        width={22}
        height={14}
        marginLeft={5}
        marginTop={3}
        iconUrl={expressIcon}
      />
    );
  }

  return (
    <div className='flex'>
      <Link to={printSheetUrl(item.id!)}>{item.id}</Link>
      {rush}
      {expressProduction}
      {warning}
    </div>
  );
});

export default IdWithIconsCell;
