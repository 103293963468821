import React, { useEffect } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { discountTypes } from '@constants/common';
import {
  parseDateTimeNumeric,
  daysBetween,
  parseDateUtc,
  getTodaysDate,
} from '@util/dateHandler';
import DiscountField from '@sharedComponents/Display/DiscountField';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import InfoField from '@sharedComponents/Display/InfoField';
import { RootState } from '@redux/index/reducers';
import * as orderManagementActions from '@redux/orderManagement/actions';
import RedemptionCount from '@components/Coupons/CouponsDetails/RedemptionCount';

interface OwnProps {
  couponCode: Nullable<string>;
  isOpen: boolean;
  closeModal: () => void;
  removeCoupon: () => void;
}

const mapStateToProps = ({ orderManagement }: RootState) => ({
  couponDetails: orderManagement.orderCouponDetails,
});

const mapDispatchToProps = {
  fetchCouponDetailsForOrder: orderManagementActions.fetchCouponDetailsForOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const CouponDetailsModal = React.memo<Props>(({
  couponDetails,
  couponCode,
  isOpen,
  closeModal,
  removeCoupon,
  fetchCouponDetailsForOrder,
}) => {
  useEffect(() => {
    if (isOpen) {
      const areOldCouponDetails = !couponDetails || couponDetails.code !== couponCode;

      if (couponCode && areOldCouponDetails) {
        fetchCouponDetailsForOrder(couponCode);
      }
    }
  }, [
    isOpen,
    couponCode,
  ]);

  const todaysDate = getTodaysDate();
  const discountType = discountTypes.find((d) => d.key === couponDetails?.discountType);

  return (
    <div>
      {
        !!couponDetails && isOpen &&
        <Modal
          title={'Coupon Details'}
          modalSize={'m'}
          isOpen={isOpen}
          closeModal={closeModal}
          buttons={(
            <ModalButtons
              displayConfirmBtn={true}
              confirmBtnText={'Cancel Coupon'}
              cancelBtnText={'Close'}
              onClose={closeModal}
              onConfirm={removeCoupon}
            />
          )}
        >
          {
            <div className='tableView__details--content'>
              <div className='tableView__details--content-container'>
                <InfoField
                  label={'Coupon Code'}
                  value={couponDetails.code}
                />
                <InfoField
                  label={'Description'}
                  value={couponDetails.description}
                />
                <div className='flex'>
                  <InfoField
                    fieldClass={'tableView__details__column--short'}
                    label={'Discount Type'}
                    value={discountType?.name}
                  />
                  <InfoField
                    fieldClass={'tableView__details__column--short'}
                    label={'Discount'}
                    value={(
                      <DiscountField
                        type={couponDetails.discountType}
                        amount={couponDetails.discountAmount}
                      />
                    )}
                  />
                  <InfoField
                    fieldClass={'tableView__details__column--short'}
                    label={'Reason'}
                    value={couponDetails.reason}
                  />
                </div>
              </div>

              <div className='tableView__details--content-container'>
                <div className='flex'>
                  <InfoField
                    fieldClass={'tableView__details__column--short'}
                    label={'Valid From'}
                    value={couponDetails.validFrom && parseDateUtc(couponDetails.validFrom)}
                  />
                  <InfoField
                    fieldClass={'tableView__details__column--short'}
                    label={'Valid To'}
                    value={couponDetails.validTo && parseDateUtc(couponDetails.validTo)}
                  />
                  <InfoField
                    fieldClass={'tableView__details__column--short'}
                    label={'Time Left'}
                    value={(
                      <div>
                        <div>{(couponDetails.validTo && couponDetails.isActive) ? `${daysBetween(todaysDate, couponDetails.validTo)} days` : '-'}</div>
                        {
                          couponDetails.validTo && couponDetails.isActive &&
                        <div>
                          <ProgressBar
                            className='progress-bar-cell--inverted__bar progress-bar-cell__bar--small'
                            variant='warning'
                            now={daysBetween(couponDetails.validFrom, todaysDate)
                              / daysBetween(couponDetails.validFrom, couponDetails.validTo) * 100
                            }
                          />
                        </div>
                        }
                      </div>
                    )}
                  />
                </div>
                <InfoField
                  label={'Redemption Count'}
                  value={(
                    <RedemptionCount
                      redemptionLimit={couponDetails.redemptionLimit}
                      redemptionCount={couponDetails.redemptionCount}
                    />
                  )}
                />
                <InfoField
                  label={'Apply To Shipping'}
                  fieldClass={'tableView__details__column--short'}
                  value={couponDetails.applyToShipping ? 'Yes' : 'No'}
                />
                <InfoField
                  label={'Created On'}
                  value={couponDetails.createdAt ? `${parseDateTimeNumeric(couponDetails.createdAt)} ${couponDetails.createdBy ? `(${couponDetails.createdBy})` : ''}` : '-'}
                />
                <InfoField
                  label={'Last Updated On'}
                  value={couponDetails.updatedAt ? `${parseDateTimeNumeric(couponDetails.updatedAt)} ${couponDetails.updatedBy ? `(${couponDetails.updatedBy})` : ''}` : '-'}
                />
              </div>
            </div>
          }
        </Modal>
      }
      {
        !couponCode && isOpen &&
        <Modal
          title={'Coupon Details'}
          modalHeight={'xl'}
          modalWidth={'m'}
          isOpen={isOpen}
          closeModal={closeModal}
          buttons={(
            <ModalButtons
              displayConfirmBtn={false}
              cancelBtnText={'Close'}
              onClose={closeModal}
            />
          )}
        >
          <div>Coupon not found.</div>
        </Modal>
      }
    </div>
  );
});

export default connector(CouponDetailsModal);
