export const validateDailyCapacity = (values) => {
  const errors = {};

  if (!values.dailyCapacity) {
    errors.dailyCapacity = ['Daily capacity is required.'];
  }

  if (values.dailyCapacity < 0) {
    errors.dailyCapacity = ['Daily capacity must be a positive number.'];
  }

  return errors;
};
