import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

class AdminActionColumn extends PureComponent {
  render() {
    const {
      admin,
      openDeleteModal,
      setAsLockerOwner,
    } = this.props;

    return (
      <ShowMoreActionsButton
        actions={[
          {
            text: 'Set as Locker Owner',
            action: setAsLockerOwner.bind(null, admin),
            isDisabled: admin.isLockerOwner,
            isVisible: true,
          },
          {
            text: 'Remove Admin',
            action: openDeleteModal.bind(null, admin),
            isDisabled: admin.isLockerOwner,
            isDangerous: true,
            isVisible: true,
          },
        ]}
      />
    );
  }
}

AdminActionColumn.propTypes = {
  admin: PropTypes.object.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  setAsLockerOwner: PropTypes.func.isRequired,
};

export default AdminActionColumn;
