import { buildQueryString } from '@util/stringHelpers';

export const printRoom = 'homefield/api/printroom' as const;
export const printQueueUrl = `${printRoom}/print-queue` as const;

export const getPrintData = (
  status: string,
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: string,
  claimedBy: string,
  expressProduction: string | null
): string => `${printQueueUrl}${buildQueryString({
  status,
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  claimedBy,
  expressProduction,
})}`;

export const getPrintDataPriorityQueue = (
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: string,
  claimedBy: string
): string => `${printQueueUrl}/priority-queue${buildQueryString({
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  claimedBy,
})}`;

export const sheets = `${printQueueUrl}` as const;
export const sheet = (id: number): string => `${sheets}/${id}`;
export const sheetAction = (id: number, action: string = ''): string => action ? `${sheets}.${action}/${id}` : sheet(id);
export const sheetsAction = (action: string = ''): string => action ? `${sheets}.${action}` : sheets;
export const searchSheet = (search: string): string => `${sheetsAction('search')}?query=${search}`;

export const inventory = `${printRoom}/inventory` as const;
export const inventoryOrder = `${inventory}/orders` as const;

export const userByStatus = (status: string): string => `${printQueueUrl}/users?status=${status}`;
export const printingUsers = userByStatus('Printing');

export const printSheetDetails = (id: number): string => `${printQueueUrl}/${id}`;
export const markSheetStatus = (id: number): string => printSheetDetails(id);

export const printSheetReworkTasks = (id: number): string => `${printSheetDetails(id)}/artworks`;
export const rejectPrintQueue = sheetsAction('rework-artwork');

export const printLabels = (id: number): string => `${sheet(id)}/labels`;

export default {
  printRoom,
  printQueueUrl,
  getPrintData,
  getPrintDataPriorityQueue,
  userByStatus,
  printingUsers,
  printSheetReworkTasks,
  printSheetDetails,
  markSheetStatus,
  sheet,
  inventoryOrder,
  searchSheet,
  rejectPrintQueue,
  printLabels,
};
