import React from 'react';
import { parseDateTimeNumeric } from '@util/dateHandler';

interface OwnProps {
  dateTime?: string;
  user?: string | null;
  message?: string | null;
  customClassName?: string;
}

type Props = OwnProps;

const ChangeLogItem = React.memo<Props>(({
  dateTime,
  user,
  message,
  customClassName,
}) => (
  <div className={`order__log-change-item ${customClassName}`}>
    <div className='order__log-change-item--user'>
      <div>
        <strong>{parseDateTimeNumeric(dateTime)}</strong>
      </div>
      {
        user && <div>{user}</div>
      }
    </div>
    <div className='order__log-change-item--message'>
      {message}
    </div>
  </div>
));

export default ChangeLogItem;
