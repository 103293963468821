import React, { useCallback } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  Path,
  PathValue,
  UnpackNestedValue,
} from 'react-hook-form';
import { Option } from '@models/common/Option';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

interface OwnProps<TFormData, T extends string | number | boolean> {
  name: Path<TFormData>;
  register: UseFormRegister<TFormData>;
  readonly options: readonly Option<T>[];
  change: UseFormSetValue<TFormData>;
  initialValue?: T | undefined;
  disabled?: boolean;
  classes?: string;
  size?: string;
  optionsClasses?: string;
  error?: any;
}

type Props<TFormData, T extends string | number | boolean> = OwnProps<TFormData, T>;

const FormDropdown = <TFormData, T extends string | number | boolean>({
  register,
  name,
  disabled = false,
  error,
  classes = '',
  size = '',
  options = [],
  initialValue,
  optionsClasses = '',
  change,
}: Props<TFormData, T>) => {
  const onChange = useCallback((value: T) => {
    change(name, value as UnpackNestedValue<PathValue<TFormData, Path<TFormData>>>);
  }, [
    name,
    change,
  ]);

  return (
    <div className={`select-field redux-form__select redux-form__dropdown ${classes} ${disabled ? 'disabled' : ''}`}>
      <Dropdown<T>
        options={options}
        defaultValue={initialValue}
        classes={'mb-0'}
        optionsClasses={optionsClasses}
        size={size}
        disabled={disabled}
        {...register(name)}
        onChange={onChange}
      />
      <div className='select-field__arrow' />
      {
        error &&
        <div className='redux-form__error--field'>
          {error.message}
        </div>
      }
    </div>
  );
};

export default React.memo(FormDropdown) as typeof FormDropdown;
