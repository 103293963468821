export const twoColorPersonalizationEnabled: boolean
  = JSON.parse(process.env.REACT_APP_TWO_COLOR_PERSONALIZATION_ENABLED!);

export const personalizationPriceUpdateEnabled: boolean
= JSON.parse(process.env.REACT_APP_PERSONALIZATION_PRICE_UPDATE_ENABLED!);

export const optionalPersonalizationEnabled: boolean
  = JSON.parse(process.env.REACT_APP_OPTIONAL_PERSONALIZATION_ENABLED!);

export const maxVfiPersonalizationNameLengthUpdateEnabled: boolean
  = JSON.parse(process.env.REACT_APP_MAX_VFI_PERSONALIZATION_NAME_LENGTH_UPDATE_ENABLED!);

export const twoColorNumberEnabled: boolean = JSON.parse(process.env.REACT_APP_TWO_COLOR_NUMBER_ENABLED!);
export const twoColorNameEnabled: boolean = JSON.parse(process.env.REACT_APP_TWO_COLOR_NAME_ENABLED!);
export const twoColorBothEnabled: boolean = JSON.parse(process.env.REACT_APP_TWO_COLOR_BOTH_ENABLED!);
export const orderRefundEnabled: boolean = JSON.parse(process.env.REACT_APP_ORDER_REFUND_ENABLED!);
export const lineLevelSchedulingEnabled: boolean = JSON.parse(process.env.REACT_APP_LINE_LEVEL_SCHEDULING_ENABLED!);
export const orderApplyCouponEnabled: boolean = JSON.parse(process.env.REACT_APP_ORDER_APPLY_COUPON_ENABLED!);
export const orderItemRefundEnabled: boolean = JSON.parse(process.env.REACT_APP_ORDER_ITEM_REFUND_ENABLED!);
export const shippingPriceUpdateEnabled: boolean = JSON.parse(process.env.REACT_APP_SHIPPING_PRICE_UPDATE_ENABLED!);
export const orderItemChargeAddEnabled: boolean = JSON.parse(process.env.REACT_APP_ORDER_ITEM_CHARGE_ADD_ENABLED!);
export const rosterNotificationsEnabled: boolean = JSON.parse(process.env.REACT_APP_ROSTER_NOTIFICATIONS_ENABLED!);
export const maxItemCodeLength: number = JSON.parse(process.env.REACT_APP_MAX_ITEM_CODE_LENGTH!);
export const itemBarcodeLength: number = JSON.parse(process.env.REACT_APP_ITEM_BARCODE_LENTH!);
export const createOrderEnabled: boolean = JSON.parse(process.env.REACT_APP_CREATE_ORDER_ENABLED!);
export const markAllDecoratedBarcode: string = process.env.REACT_APP_MARK_ALL_DECORATED_BARCODE!;
export const activityTrackerEnabled: boolean = JSON.parse(process.env.REACT_APP_ACTIVITY_TRACKER_ENABLED!);

export default {
  twoColorPersonalizationEnabled,
  twoColorNumberEnabled,
  twoColorNameEnabled,
  twoColorBothEnabled,
  optionalPersonalizationEnabled,
  orderRefundEnabled,
  orderApplyCouponEnabled,
  orderItemRefundEnabled,
  lineLevelSchedulingEnabled,
  personalizationPriceUpdateEnabled,
  shippingPriceUpdateEnabled,
  orderItemChargeAddEnabled,
  maxVfiPersonalizationNameLengthUpdateEnabled,
  rosterNotificationsEnabled,
  maxItemCodeLength,
  itemBarcodeLength,
  createOrderEnabled,
  markAllDecoratedBarcode,
  activityTrackerEnabled,
};
