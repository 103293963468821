import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  logoBankUrl,
  artVectorizationUrl,
} from '@constants/clientUrls/clientUrls';
import BackLink from '@sharedComponents/Navigation/BackLink';

const ArtworkFileTasksBack = React.memo(({ location }) => {
  let breadcrumbText = 'Production Artwork';
  let breadcrumbUrl = artVectorizationUrl;

  if (location && location.state) {
    const { prevPath } = location.state;
    if (prevPath && prevPath.includes(logoBankUrl)) {
      breadcrumbText = 'Logo Bank';
      breadcrumbUrl = prevPath;
    }
  }

  return (
    <div className='header__bar-title'>
      <BackLink
        url={breadcrumbUrl}
        text={breadcrumbText}
      />
    </div>
  );
});

ArtworkFileTasksBack.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(ArtworkFileTasksBack);
