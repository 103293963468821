import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Input from '@sharedComponents/Form/Input';
import FormError from '@sharedComponents/Form/FormError';
import { applicationSettingsForm } from '@constants/reduxForms';

class ApplicationSettingsForm extends Component {
  render() {
    const {
      onSubmit,
      error,
    } = this.props;

    return (
      <form
        className='redux-form'
        onSubmit={onSubmit}
        id={applicationSettingsForm}
      >
        <div>
          <div>
            <div className='tableView__details--header--title mb-30'>
              <span>Worker Count</span>
            </div>
            <div className='ml-15'>
              <div className='redux-form__row'>
                <div className='redux-form__column--size-l'>
                  <label className='redux-form__label'>
                    Value
                    <span className='required'>*</span>
                  </label>
                  <Field
                    name={'workerCount.value'}
                    placeholder={'Enter the number of Workers'}
                    component={Input}
                    type={'number'}
                    parse={Number}
                  />
                </div>
              </div>
            </div>
            <div className='tableView__details--header--title mb-30'>
              <span>Scheduling Terminal</span>
            </div>
            <div className='ml-15'>
              <div className='redux-form__row'>
                <div className='redux-form__column--size-l'>
                  <label className='redux-form__label'>
                    Value
                    <span className='required'>*</span>
                  </label>
                  <Field
                    name={'schedulingTerminalId.value'}
                    placeholder={'Enter the terminal for the Scheduling pick printer'}
                    component={Input}
                    type={'text'}
                  />
                </div>
              </div>
            </div>
            <div className='tableView__details--header--title mb-30'>
              <span>Artwork Terminal</span>
            </div>
            <div className='ml-15'>
              <div className='redux-form__row'>
                <div className='redux-form__column--size-l'>
                  <label className='redux-form__label'>
                    Value
                    <span className='required'>*</span>
                  </label>
                  <Field
                    name={'artworkTerminalId.value'}
                    placeholder={'Enter the terminal for the Artwork pick printer'}
                    component={Input}
                    type={'text'}
                  />
                </div>
              </div>
            </div>
          </div>

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

ApplicationSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
  change: PropTypes.func,
};

export default ApplicationSettingsForm;
