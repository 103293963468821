import React from 'react';
import { parseDateNumeric } from '@util/dateHandler';

interface OwnProps {
  shipDate: string;
  isCanceled: boolean;
  isPaused: boolean;
  isRushed: boolean;
  isExpressProduction: boolean;
  isArchived: boolean;
  isExistingTask: boolean;
}

type Props = OwnProps;

const ArtworkTaskDetailsShipDate = React.memo<Props>(({
  shipDate,
  isCanceled,
  isPaused,
  isRushed,
  isExpressProduction,
  isArchived,
  isExistingTask,
}) => {
  let text = '-';
  let decoration = 'shipping-date';

  if (shipDate) {
    text = `Ship by ${parseDateNumeric(shipDate)}`;

    if (isCanceled) {
      text = 'Canceled';
      decoration = 'cancelled';
    } else if (isArchived) {
      text = 'Archived';
      decoration = 'archived';
    } else if (isPaused) {
      text = 'Paused';
      decoration = 'paused';
    } else if (isRushed) {
      text = `Rushed: ${parseDateNumeric(shipDate)}`;
      decoration = 'rushed';
    } else if (isExpressProduction) {
      text = `Express Production: ${parseDateNumeric(shipDate)}`;
      decoration = 'rushed';
    }
  }

  return (
    <div className={`task__message task__message--${decoration} ${isExistingTask ? '' : 'visibility-hidden'}`}>
      <span>{text}</span>
    </div>
  );
});

export default ArtworkTaskDetailsShipDate;
