import { useQuery } from 'react-query';
import { fulfillmentApi } from '../../../sqdApis';
import { makeApiCall } from 'util/apiHelper';
import dtgPrintingUrls from 'constants/sqdApiUrls/dtgPrintingUrls';
import { mapErrors } from 'util/mappingHelper';

export const useGetDtgPrepressJob = ({
  artworkTaskId,
  orderItemDecorationId,
  start,
}) => useQuery([
  'dtg-prepress-job',
  artworkTaskId,
  orderItemDecorationId,
], () => {
  const dtgPrepressJob = getDtgPrepressJob({
    artworkTaskId,
    orderItemDecorationId,
  });

  return dtgPrepressJob;
}, {
  enabled: start,
  refetchInterval: start ? 30000 : false,
  refetchOnWindowFocus: false,
});

export const getDtgPrepressJob = async ({
  artworkTaskId = null,
  orderItemDecorationId = null,
}) => {
  const call = fulfillmentApi.get(dtgPrintingUrls.dtgPrepressJob({
    artworkTaskId,
    orderItemDecorationId,
  }), {
    handleBlockingLoading: false,
    headers: {
      'Content-type': 'application/json',
    },
  });

  const res = await makeApiCall(call, mapErrors);

  return res;
};
