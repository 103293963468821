import React, {
  useCallback,
  useEffect,
} from 'react';
import { stopSubmit } from 'redux-form';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { useGetHomefieldApiColoredstylesId } from '@api/productCatalog/colored-styles';
import { useGetSettings } from '@api/squadlockerServices/utility';
import { personalizationForm } from '@constants/reduxForms';
import { PersonalizationFormData } from '@models/forms/OrderManagement/PersonalizationFormData';
import {
  OrderManagementTempItem,
  OrderManagementTempItemPersonalization,
} from '@redux/orderManagement/models';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import PersonalizationForm from '../OrderManagementForms/PersonalizationForm';

interface OwnProps {
  isOpen: boolean;
  item: Nullable<OrderManagementTempItem>;
  personalization: Nullable<OrderManagementTempItemPersonalization>;
  editPersonalization: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization,
    unitPrice: number,
    name?: string,
    number?: string,
  ) => void;
  closeModal: () => void;
}

const mapDispatchToProps = {
  stopFormSubmit: stopSubmit,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditPersonalizationModal = React.memo(({
  isOpen,
  closeModal,
  item,
  personalization,
  editPersonalization,
  stopFormSubmit,
}: Props) => {
  const {
    data: coloredStyle,
    isLoading: isLoadingColoredStyle,
    isError: isErrorColoredStyle,
  } = useGetHomefieldApiColoredstylesId(
    item ? item.coloredStyleId : 0,
    { query: { enabled: (!!item?.coloredStyleId) } }
  );

  const {
    data: personalizationSettings,
    refetch: fetchPersonalizationSettings,
  } = useGetSettings();

  useEffect(() => {
    fetchPersonalizationSettings();
  }, [fetchPersonalizationSettings]);

  const handleCloseModal = useCallback(async () => {
    closeModal();
    await stopFormSubmit(personalizationForm, {});
  }, [
    stopFormSubmit,
    closeModal,
  ]);

  const handleEditPersonalization = useCallback((form: PersonalizationFormData) => {
    editPersonalization(item!, personalization!, form.unitPrice, form.name, form.number);
  }, [
    editPersonalization,
    item,
    personalization,
  ]);

  if (!item || !personalization || !isOpen || isLoadingColoredStyle || isErrorColoredStyle || !coloredStyle) {
    return null;
  }

  return (
    <Modal
      title={'Edit Personalization'}
      isOpen={isOpen}
      modalHeight={'l'}
      modalWidth={'m'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Edit'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={personalizationForm}
        />
      )}
    >
      <PersonalizationForm
        item={item}
        personalization={personalization}
        onSubmit={handleEditPersonalization}
        initialValues={personalization}
        defaultPersonalizationPrices={personalizationSettings?.defaultPersonalizationPrices}
      />
    </Modal>
  );
});

export default connector(EditPersonalizationModal);
