import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MultipleQueuesGridCard from './MultipleQueuesGridCard';
import SingleQueueGridCard from './SingleQueueGridCard';
import { fetchSchedulingQueues } from '@redux/llScheduling/actions';
import GridItemCustomContainer from './GridItemCustomContainer';
import { groupBy } from '@util/arrayHelper';

const LLScheduling = React.memo(({
  dispatch,
  schedulingQueues,
}) => {
  useEffect(() => {
    dispatch(fetchSchedulingQueues());
  }, []);

  const queuesGroupedByDecorationMethod = groupBy(schedulingQueues, 'decorationMethod');

  const keys = Object.keys(queuesGroupedByDecorationMethod);

  return (
    <div className='container'>
      <div className='ll-scheduling'>
        <Grid
          container={true}
          direction='column'
          spacing={3}
        >
          <Grid
            item={true}
            mt={4}
          >
            {
              keys.map((key) => queuesGroupedByDecorationMethod[key].length > 1
                ? (
                  <GridItemCustomContainer
                    key={key}
                    queueName={key}
                  >
                    {
                      queuesGroupedByDecorationMethod[key].map((queue) => (
                        <MultipleQueuesGridCard
                          key={queue.id}
                          queue={queue}
                        />
                      ))
                    }
                  </GridItemCustomContainer>
                )
                : (
                  <SingleQueueGridCard
                    key={key}
                    queue={queuesGroupedByDecorationMethod[key][0]}
                  />
                ))}
          </Grid>
        </Grid>
      </div>
    </div >
  );
});

LLScheduling.propTypes = {
  schedulingQueues: PropTypes.array.isRequired,
};

const mapStateToProps = ({ llScheduling }) => ({
  schedulingQueues: llScheduling.schedulingQueues,
});

export default connect(mapStateToProps)(LLScheduling);
