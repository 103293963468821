export const fulfillmentRoot = `${process.env.REACT_APP_API_ROOT}`;
export const homefieldRoot = `${fulfillmentRoot}/homefield`;
export const identityRoot = process.env.REACT_APP_IDENTITY_ROOT!;
export const apiOnboardingRoot = process.env.REACT_APP_API_ONBOARDING_ROOT!;
export const slServicesRoot = process.env.REACT_APP_SQUADLOCKER_SERVICES_API!;
export const storefrontRoot = process.env.REACT_APP_STOREFRONT_ROOT_URL!;
export const activeAdminRoot = `${process.env.REACT_APP_STOREFRONT_ROOT_URL}/admin`;
export const productCatalogRoot = process.env.REACT_APP_PRODUCT_CATALOG_API!;
export const financialServicesRoot = process.env.REACT_APP_FINANCIAL_SERVICES!;
export const ordersApiRoot = `${process.env.REACT_APP_ORDERS_API}`;
export const accountsApiRoot = `${process.env.REACT_APP_ACCOUNTS_API}`;
export const reportEngineApiRoot = `${process.env.REACT_APP_REPORT_ENGINE_API}`;
