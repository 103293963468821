/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  UseMutationOptions
} from 'react-query'
import type {
  ApiOk,
  ApiBadRequest,
  ApiNotFound,
  ApiInternalServerError,
  EditColoredStyleSelectionEssentialParams,
  ApiOkNumber
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const editColoredStyleSelectionEssential = <TData = ApiOk>(
    id: number,
    params?: EditColoredStyleSelectionEssentialParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/colored_style_selections/${id}/change-essential`, method: 'put',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEditColoredStyleSelectionEssential = <TData = AsyncReturnType<typeof editColoredStyleSelectionEssential,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;params?: EditColoredStyleSelectionEssentialParams}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;params?: EditColoredStyleSelectionEssentialParams}, TContext>((props) => {
        const {id,params} = props || {};

        return  editColoredStyleSelectionEssential<TData>(id,params,requestOptions)
      }, mutationOptions)
    }
    export const refreshCachedImageForProduct = <TData = ApiOkNumber>(
    productId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/colored_style_selections/${productId}/refresh-cached-image`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRefreshCachedImageForProduct = <TData = AsyncReturnType<typeof refreshCachedImageForProduct,ApiOkNumber>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{productId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {productId: number}, TContext>((props) => {
        const {productId} = props || {};

        return  refreshCachedImageForProduct<TData>(productId,requestOptions)
      }, mutationOptions)
    }
    export const undecorate = <TData = ApiOk>(
    productId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/colored_style_selections/${productId}/undecorate`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUndecorate = <TData = AsyncReturnType<typeof undecorate,ApiOk>,
    TError = ApiBadRequest | ApiNotFound | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{productId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {productId: number}, TContext>((props) => {
        const {productId} = props || {};

        return  undecorate<TData>(productId,requestOptions)
      }, mutationOptions)
    }
    