import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  filterTypes,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { dropdownSizeS } from '@constants/values';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';

class InventoryFilterPanel extends PureComponent {
  handleSearch = (e) => {
    const { search } = this.props;

    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    search(e.target.value);
  };

  clearSearch = () => {
    const { search } = this.props;

    search('');
  };

  handleCategories = (items) => {
    const { filter } = this.props;
    filter(items, filterTypes.categories);
  };

  handleBrands = (items) => {
    const { filter } = this.props;
    filter(items, filterTypes.brands);
  };

  handleAges = (items) => {
    const { filter } = this.props;
    filter(items, filterTypes.ages);
  };

  handleGenders = (items) => {
    const { filter } = this.props;
    filter(items, filterTypes.genders);
  };

  handleSizes = (items) => {
    const { filter } = this.props;
    filter(items, filterTypes.sizes);
  };

  handleColors = (items) => {
    const { filter } = this.props;
    filter(items, filterTypes.colors);
  };

  render() {
    const {
      sizes,
      brands,
      categories,
      colors,
      ages,
      genders,
    } = this.props;

    return (
      <div className='flex inventory__table-header'>
        <div className='inventory__table-search'>
          <SearchFilter
            search={this.handleSearch}
            placeholder={'Search (SKU or Name)'}
            clearSearch={this.clearSearch}
          />
        </div>
        <div className='inventory__table-filter'>
          <MultiSelectDropdown
            objects={categories}
            itemText={'categories'}
            updateCallback={this.handleCategories}
            textKey={'name'}
            valueKey={'id'}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={brands}
            itemText={'brands'}
            updateCallback={this.handleBrands}
            textKey={'name'}
            valueKey={'id'}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={colors}
            itemText={'color groups'}
            updateCallback={this.handleColors}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={sizes}
            itemText={'sizes'}
            updateCallback={this.handleSizes}
            size={dropdownSizeS}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={ages}
            itemText={'ages'}
            updateCallback={this.handleAges}
            size={dropdownSizeS}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={genders}
            itemText={'genders'}
            updateCallback={this.handleGenders}
            classNames={'margin-left'}
          />
        </div>
      </div>
    );
  }
}

InventoryFilterPanel.propTypes = {
  search: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  ages: PropTypes.arrayOf(PropTypes.string).isRequired,
  genders: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InventoryFilterPanel;
