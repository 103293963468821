import { parseDateNumeric } from '@util/dateHandler';
import React from 'react';

interface OwnProps {
  header: string;
  value?: string | number | null;
}

type Props = OwnProps;

const DateHeaderCard = React.memo<Props>(({
  header,
  value,
}) => (
  <div className='sheet__files-item item-date-card' >
    <div className='sheet__files-title'>
      <div className='sheet__files-title-left flex'>
        {header}
      </div>
    </div>
    <ul className='sheet__files-list'>
      <li>
        <div className='flex'>
          {parseDateNumeric(value)}
        </div>
      </li>
    </ul>
  </div>
));

export default DateHeaderCard;
