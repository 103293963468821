const ID = 'ORDER_ASSEMBLY' as const;

export const UPDATE_ORDER = `${ID}/UPDATE_ORDER` as const;
export const UPDATE_ORDER_ITEMS_STATUSES = `${ID}/UPDATE_ORDER_ITEMS_STATUSES` as const;
export const UPDATE_ORDER_LINE_ITEMS_STATUSES = `${ID}/UPDATE_ORDER_LINE_ITEMS_STATUSES` as const;
export const UPDATE_ORDER_LINE_ITEMS_FLAGS = `${ID}/UPDATE_ORDER_LINE_ITEMS_FLAGS` as const;
export const CLEAR_LOGO_COLORS = `${ID}/CLEAR_LOGO_COLORS` as const;
export const UPDATE_LOGO_COLORS = `${ID}/UPDATE_LOGO_COLORS` as const;
export const UPDATE_DTG_STATIONS = `${ID}/UPDATE_DTG_STATIONS` as const;
export const UPDATE_TRANSMISSIONS_COUNT = `${ID}/UPDATE_TRANSMISSIONS_COUNT` as const;
export const UPDATE_PRINT_QUEUE = `${ID}/UPDATE_PRINT_QUEUE` as const;
export const UPDATE_DTG_TRANSMISSION_DECORATIONS = `${ID}/UPDATE_DTG_TRANSMISSION_DECORATIONS` as const;
export const UPDATE_DTG_CONFIGURATION_COLORED_STYLE = `${ID}/UPDATE_DTG_CONFIGURATION_COLORED_STYLE` as const;
export const UPDATE_PRODUCTS_DICTIONARY = `${ID}/UPDATE_PRODUCTS_DICTIONARY` as const;

export default ID;
