import React from 'react';
import PropTypes from 'prop-types';

const ArtworkTaskStitchCount = React.memo(({ stitchCount }) => (
  <div className='artwork-preview__stitch-count'>
    <span className='artwork-preview__stitch-count__label'>Stitch count</span>
    <span className='artwork-preview__stitch-count__value'>{stitchCount}</span>
  </div>
));

ArtworkTaskStitchCount.propTypes = {
  stitchCount: PropTypes.number,
};

export default ArtworkTaskStitchCount;
