/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  QAFailReasonDto,
  ApplicationSettingsDto,
  ApiResponse,
  EditApplicationSettingsCommand,
  StringApiResponse,
  PostHomefieldApiSupportFilesuploadBody,
  PostHomefieldApiSupportFilesuploadcloudBody,
  QualityAssuranceCodeDto,
  GetHomefieldApiSupportQualityassurancecodesParams,
  CreateQualityAssuranceCodeCommand,
  EditQualityAssuranceCodeCommand
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiSupportQafailreasons = <TData = QAFailReasonDto[]>(
    
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/qafailreasons`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiSupportQafailreasonsQueryKey = () => [`/homefield/api/support/qafailreasons`]

    
export const useGetHomefieldApiSupportQafailreasons = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiSupportQafailreasons, QAFailReasonDto[]>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiSupportQafailreasonsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiSupportQafailreasons<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiSupportVersion = <TData = string>(
    
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/version`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiSupportVersionQueryKey = () => [`/homefield/api/support/version`]

    
export const useGetHomefieldApiSupportVersion = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiSupportVersion, string>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiSupportVersionQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiSupportVersion<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiSupportApplicationsettings = <TData = ApplicationSettingsDto>(
    
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/application-settings`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiSupportApplicationsettingsQueryKey = () => [`/homefield/api/support/application-settings`]

    
export const useGetHomefieldApiSupportApplicationsettings = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiSupportApplicationsettings, ApplicationSettingsDto>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiSupportApplicationsettingsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiSupportApplicationsettings<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiSupportApplicationsettings = <TData = ApiResponse>(
    editApplicationSettingsCommand: EditApplicationSettingsCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/application-settings`, method: 'put',
      data: editApplicationSettingsCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiSupportApplicationsettings = <TData = AsyncReturnType<typeof putHomefieldApiSupportApplicationsettings,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: EditApplicationSettingsCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: EditApplicationSettingsCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiSupportApplicationsettings<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiSupportFilesupload = <TData = StringApiResponse>(
    postHomefieldApiSupportFilesuploadBody: PostHomefieldApiSupportFilesuploadBody,
 options?: SecondParameter<typeof fulfillmentApi>) => {const formData = new FormData();
if(postHomefieldApiSupportFilesuploadBody.file) {
 formData.append('file', postHomefieldApiSupportFilesuploadBody.file)
 }

      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/files.upload`, method: 'post',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiSupportFilesupload = <TData = AsyncReturnType<typeof postHomefieldApiSupportFilesupload,StringApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PostHomefieldApiSupportFilesuploadBody}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PostHomefieldApiSupportFilesuploadBody}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiSupportFilesupload<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiSupportFilesuploadcloud = <TData = StringApiResponse>(
    postHomefieldApiSupportFilesuploadcloudBody: PostHomefieldApiSupportFilesuploadcloudBody,
 options?: SecondParameter<typeof fulfillmentApi>) => {const formData = new FormData();
if(postHomefieldApiSupportFilesuploadcloudBody.file) {
 formData.append('file', postHomefieldApiSupportFilesuploadcloudBody.file)
 }

      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/files.upload-cloud`, method: 'post',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiSupportFilesuploadcloud = <TData = AsyncReturnType<typeof postHomefieldApiSupportFilesuploadcloud,StringApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PostHomefieldApiSupportFilesuploadcloudBody}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PostHomefieldApiSupportFilesuploadcloudBody}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiSupportFilesuploadcloud<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiSupportQualityassurancecodes = <TData = QualityAssuranceCodeDto[]>(
    params?: GetHomefieldApiSupportQualityassurancecodesParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/quality-assurance-codes`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiSupportQualityassurancecodesQueryKey = (params?: GetHomefieldApiSupportQualityassurancecodesParams,) => [`/homefield/api/support/quality-assurance-codes`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiSupportQualityassurancecodes = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiSupportQualityassurancecodes, QualityAssuranceCodeDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiSupportQualityassurancecodesParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiSupportQualityassurancecodesQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiSupportQualityassurancecodes<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiSupportQualityassurancecodes = <TData = ApiResponse>(
    createQualityAssuranceCodeCommand: CreateQualityAssuranceCodeCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/quality-assurance-codes`, method: 'post',
      data: createQualityAssuranceCodeCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiSupportQualityassurancecodes = <TData = AsyncReturnType<typeof postHomefieldApiSupportQualityassurancecodes,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateQualityAssuranceCodeCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateQualityAssuranceCodeCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiSupportQualityassurancecodes<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiSupportQualityassurancecodesCode = <TData = ApiResponse>(
    code: string,
    editQualityAssuranceCodeCommand: EditQualityAssuranceCodeCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/quality-assurance-codes/${code}`, method: 'put',
      data: editQualityAssuranceCodeCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiSupportQualityassurancecodesCode = <TData = AsyncReturnType<typeof putHomefieldApiSupportQualityassurancecodesCode,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{code: string;data: EditQualityAssuranceCodeCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {code: string;data: EditQualityAssuranceCodeCommand}, TContext>((props) => {
        const {code,data} = props || {};

        return  putHomefieldApiSupportQualityassurancecodesCode<TData>(code,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiSupportQualityassurancecodesCode = <TData = ApiResponse>(
    code: string,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/support/quality-assurance-codes/${code}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiSupportQualityassurancecodesCode = <TData = AsyncReturnType<typeof deleteHomefieldApiSupportQualityassurancecodesCode,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{code: string}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {code: string}, TContext>((props) => {
        const {code} = props || {};

        return  deleteHomefieldApiSupportQualityassurancecodesCode<TData>(code,requestOptions)
      }, mutationOptions)
    }
    