import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import { brandForm } from '@constants/reduxForms';
import { validateBrand } from '@redux/brands/validations';
import BrandForm from '../BrandForm';

const BrandEditModal = (props) => {
  const {
    isOpen,
    handleSubmit,
    initialValues,
    change,
    closeModal,
  } = props;

  return (
    <Modal
      title={'Edit Brand'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Edit'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={brandForm}
        />
      )}
    >
      <BrandForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        change={change}
      />
    </Modal>
  );
};

BrandEditModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  change: PropTypes.func,
};

export default (reduxForm({
  form: brandForm,
  validate: validateBrand,
  enableReinitialize: true,
})(BrandEditModal));
