import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

class ActionsTooltip extends Component {
  render() {
    const {
      placement,
      actions,
      children,
      isOpen,
      close,
    } = this.props;
    const interactiveTooltipClass = 'interactive-tooltip';

    return (
      <OutsideClickWrapper onClick={close}>
        <MaterialTooltip
          tooltipText={actions}
          placement={placement}
          open={isOpen}
          tooltipClassName={interactiveTooltipClass}
          interactive={true}
        >
          {children}
        </MaterialTooltip>
      </OutsideClickWrapper>
    );
  }
}

ActionsTooltip.propTypes = {
  placement: PropTypes.string,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  actions: PropTypes.object,
  children: PropTypes.node,
};

export default ActionsTooltip;
