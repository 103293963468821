import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { materialSwal } from '@util/componentHelper';
import { toggleItemSelection } from '@util/selectionHelpers';
import { addProductsToLocker } from '@APICalls/lockerManager/actions';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import LockerManagerAddProducts from './LockerManagerAddProducts';

class LockerManagerAddProductsModal extends PureComponent {
  state = {
    selectedProducts: [],
  };

  addProduct = async () => {
    const {
      lockerId,
      closeModal,
      refresh,
    } = this.props;

    const { selectedProducts } = this.state;

    const csIds = selectedProducts.map((p) => p.id);
    const res = await addProductsToLocker(lockerId, csIds);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      refresh();
      closeModal();
    }
  };

  selectProduct = (product) => {
    const { selectedProducts } = this.state;

    this.setState({
      selectedProducts: toggleItemSelection(selectedProducts, product, 'id'),
    });
  };

  unselectProduct = (product) => {
    const { selectedProducts } = this.state;

    this.setState({
      selectedProducts: selectedProducts.filter((p) => p.id !== product.id),
    });
  };

  removeAllProducts = () => {
    this.setState({ selectedProducts: [] });
  };

  render() {
    const {
      isOpen,
      closeModal,
      refresh,
      lockerId,
    } = this.props;

    const { selectedProducts } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        title={'Add Product to Locker'}
        modalSize={'xl'}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Add products'}
            cancelBtnText={'Cancel'}
            onConfirm={this.addProduct}
            onClose={closeModal}
            confirmBtnDisabled={!selectedProducts || (selectedProducts && selectedProducts.length < 1)}
          />
        )}
      >
        <LockerManagerAddProducts
          lockerId={lockerId}
          refresh={refresh}
          removeAllProducts={this.removeAllProducts}
          unselectProduct={this.unselectProduct}
          selectProduct={this.selectProduct}
          selectedProducts={selectedProducts}
        />
      </Modal>
    );
  }
}

LockerManagerAddProductsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  lockerId: PropTypes.number.isRequired,
};

export default LockerManagerAddProductsModal;
