import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  swalAlert,
  navigateToPage,
} from '@util/componentHelper';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { searchOrder } from '@APICalls/productionAssembly/actions';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import {
  productionAssemblyDetailsUrl,
  productionAssemblyDetailsBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import Button from '@sharedComponents/Buttons/Button';

class ProductionAssemblySearch extends Component {
  state = {
    searchInput: '',
  };

  updateSearchInput = (e) => {
    const searchInput = e.target.value;
    this.setState(() => ({ searchInput }));
  };

  runSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  lookupOrder = async (searchInput) => {
    const orderParsed = searchInput.toLowerCase().startsWith('o')
      ? searchInput.substring(1)
      : searchInput;

    const res = await searchOrder(orderParsed);

    if (res) {
      if (res.items.some((i) => i.barcode === searchInput)) {
        navigateToPage(productionAssemblyDetailsBarcodeUrl(res.orderNumber, searchInput));
      } else {
        navigateToPage(productionAssemblyDetailsUrl(res.orderNumber));
      }
    }
  };

  search = () => {
    const { searchInput } = this.state;

    if (!searchInput) {
      swalAlert('Please fill in an Order Number first.');
    } else {
      this.lookupOrder(searchInput);
    }
  };

  render() {
    return (
      <div className='container--small'>
        <h1>DEPRECATED, DO NOT USE!</h1>
        <div className='order-assembly extra-padding mt-20'>
          <div className='header'>
            Enter order ID or scan barcode to begin
          </div>
          <SearchFilter
            search={this.runSearch}
            onChange={this.updateSearchInput}
            placeholder={'Order ID or Barcode'}
            inputClasses={'w-100'}
          />
          <div className='align__right'>
            <Button
              type={'primary'}
              text={'Submit'}
              onClick={this.search}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(ProductionAssemblySearch));
