import React from 'react';
import FilterGroup from './FilterGroup';

interface OwnProps {
  leftGroup?: JSX.Element | JSX.Element[];
  rightGroup?: JSX.Element | JSX.Element[];
  classes?: string;
}

type Props = OwnProps;

const TableFilters = React.memo<Props>(({
  leftGroup,
  rightGroup,
  classes = '',
}) => {
  if (!leftGroup && rightGroup) {
    return (
      <div className={`table-options w-100 align__right ${classes}`}>
        <FilterGroup>{rightGroup}</FilterGroup>
      </div>
    );
  }

  if (leftGroup && !rightGroup) {
    return (
      <div className={`table-options w-100 align__left ${classes}`}>
        <FilterGroup>{leftGroup}</FilterGroup>
      </div>
    );
  }

  return (
    <div className={`table-options w-100 ${classes}`}>
      <FilterGroup>{leftGroup}</FilterGroup>
      <FilterGroup>{rightGroup}</FilterGroup>
    </div>
  );
});

export default TableFilters;
