export const formatPrice = (value: number) => `$${(Math.round(value) / 100).toFixed(2)}`;

export const formatNumber = (
  number: number | string | undefined | null,
  digits = 2
): string => parseFloat((number ?? 0) as string).toFixed(digits);

export const formatDollarAmount = (
  number: number | string | undefined | null,
  digits = 2
) => `$${formatNumber(number, digits)}`;

export const formatPercentageAmount = (number: number) => `${Number(number || 0)}%`;

export const formatDecorationLocationSize = (width: number, height: number) => {
  const widthRound = Math.round(width * 100) / 100;
  const heightRound = Math.round(height * 100) / 100;

  return `${widthRound}'' x ${heightRound}''`;
};

export const formatDecorationLocationHeight = (height: number) => {
  const heightRound = Math.round(height * 100) / 100;

  return `H: ${heightRound}''`;
};

export const toLocaleString = (
  number: number | undefined
): string | undefined => number
  ? number.toLocaleString(navigator.language, { maximumFractionDigits: 2 })
  : undefined;

export const centsToDollars = (
  amount: number | undefined
): number | undefined => amount ? (((amount as number).toFixed(2) as unknown as number) / 100.0) : undefined;

export const toCents = (
  amount: number | undefined
): Nullable<number> => parseInt(
  parseFloat(((amount ?? 0) * 100) as unknown as string)
    .toFixed(0)
);

export const parseNumber = (value: string): Nullable<number> => isNaN(parseFloat(value)) ? null : parseFloat(value);

export const parseInt = (
  value: number | string
): Nullable<number> => parseNumber(value as string) === null ? null : Math.floor(parseNumber(value as string)!);

export const roundFloat = (value: number): number => Math.round(value * 100) / 100;

export const sumTwo = (total: number, current: number): number => total + current;
export const sum = (array: number[] | undefined): number => array?.reduce<number>(sumTwo, 0) ?? 0;
