import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { stopSubmit } from 'redux-form';
import { rosterNotificationsForm } from '@constants/reduxForms';
import { fetchBundledCollectionForLockers } from '@redux/lockerManager/actions';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import RosterNotificationsForm from '../RosterNotificationsForm';

class SendNotificationsModal extends PureComponent {
  componentDidMount() {
    const {
      rosters,
      dispatch,
    } = this.props;

    if (!rosters.length) {
      return;
    }

    const lockerIds = rosters.map((r) => r.lockerId).filter(Boolean);

    if (lockerIds.length) {
      dispatch(fetchBundledCollectionForLockers(lockerIds));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      rosters,
      dispatch,
    } = this.props;

    if (prevProps.rosters !== rosters && rosters.length) {
      const lockerIds = rosters.map((r) => r.lockerId).filter(Boolean);

      if (lockerIds.length) {
        dispatch(fetchBundledCollectionForLockers(lockerIds));
      }
    }
  }

  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(rosterNotificationsForm, {}));
  };

  isCollectionLandingPageLinkDestinationDisabled = () => {
    const {
      rosters,
      bundledCollectionForLockerDictionary,
    } = this.props;

    let collectionLandingPageLinkDestinationDisabled = false;
    for (const roster of rosters) {
      if (!roster.lockerId) {
        collectionLandingPageLinkDestinationDisabled = true;
        break;
      }

      const bundledCollection = bundledCollectionForLockerDictionary[roster.lockerId];
      if (!bundledCollection) {
        collectionLandingPageLinkDestinationDisabled = true;
        break;
      }
    }

    return collectionLandingPageLinkDestinationDisabled;
  };

  render() {
    const {
      isOpen,
      onSubmit,
      rosters,
    } = this.props;

    const initialValues = {
      rosterIds: rosters.map((r) => r.id),
      orderingDeadline: rosters.length && rosters[0].orderingDeadline,
    };

    const haveOrg = rosters.every((roster) => roster.organizationId !== null);

    return (
      <Modal
        title={'Roster Notifications'}
        modalHeight={'xl'}
        modalWidth={'m'}
        isOpen={isOpen}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onClose={this.handleCloseModal}
            formSubmission={true}
            formId={rosterNotificationsForm}
          />
        )}
      >
        <RosterNotificationsForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          collectionLandingPageLinkDestinationDisabled={this.isCollectionLandingPageLinkDestinationDisabled()}
          hasOrganization={haveOrg}
        />
      </Modal>
    );
  }
}

SendNotificationsModal.propTypes = {
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  rosters: PropTypes.arrayOf(PropTypes.object),
  bundledCollectionForLockerDictionary: PropTypes.object.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  bundledCollectionForLockerDictionary: lockerManager.bundledCollectionForLockerDictionary,
});

export default connect(mapStateToProps)(SendNotificationsModal);
