import React, { useCallback } from 'react';
import { CollectionDetailsViewModel } from '@api/squadlockerServices/models';
import { getLockerItemBulkActions } from '@constants/options/bulkActions';
import LockerItemsTable from '@sharedComponents/LockerItems/LockerItemsTable';

interface OwnProps {
  collection: CollectionDetailsViewModel;
}

type Props = OwnProps;

const CollectionItems = React.memo<Props>(({ collection }) => {
  const getBulkActions = useCallback((selectedItems, refreshingImages) => (
    getLockerItemBulkActions(selectedItems, false, !refreshingImages, true)
  ), []);

  return (
    <div>
      {
        collection?.id && collection?.lockerId &&
        <LockerItemsTable
          collectionId={collection.id}
          lockerId={collection.lockerId}
          getBulkActions={getBulkActions}
        />
      }
    </div>
  );
});

export default CollectionItems;
