import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const OrganizationsManagePublicPageHeader = React.memo(({ organization }) => (
  <div className='bar__long'>
    <div className='header__bar-content'>
      <h2 className='header__bar-title'>
        {
          (organization && organization.name)
            ? `Manage Public Page - ${organization.name}`
            : 'Manage Public Page'
        }
      </h2>
    </div>
  </div>
));

OrganizationsManagePublicPageHeader.propTypes = {
  organization: PropTypes.object.isRequired,
};

const mapStateToProps = ({ organizations }) => ({
  organization: organizations.organization,
});

export default connect(mapStateToProps)(OrganizationsManagePublicPageHeader);
