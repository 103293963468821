import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  VoucherCreationAdminViewModel as Admin,
  VoucherCreationLockerViewModel as Locker,
} from '@api/squadlockerServices/models';
import {
  VoucherNotificationLinkDestinationEnum,
  VoucherNotificationSenderNameEnum,
  VoucherOrderPreviewDto,
} from '@api/financialServices/models';
import NotificationSettings from './ConfigurationStepContent/NotificationSettings';
import VoucherOrderSettings from './ConfigurationStepContent/VoucherOrderSettings';
import DiscountSettings from './ConfigurationStepContent/DiscountSettings';

interface OwnProps {
  selectedOrganizationId: number | undefined;
  selectedLockers: Locker[];
  validFromDate: Nullable<string>;
  validToDate: Nullable<string>;
  selectedAdminId: Nullable<number>;
  sendDate: Nullable<string>;
  linkDestination: Nullable<VoucherNotificationLinkDestinationEnum>;
  senderNameType: Nullable<VoucherNotificationSenderNameEnum>;
  customSenderName: Nullable<string>;
  discountPercent: number;
  updateValidFromDate: (value: Nullable<string>) => void;
  updateValidToDate: (value: Nullable<string>) => void;
  updateSelectedAdmin: (value: Nullable<Admin>) => void;
  updateSendDate: (value: Nullable<string>) => void;
  updateLinkDestination: (value: Nullable<VoucherNotificationLinkDestinationEnum>) => void;
  updateSenderNameType: (value: Nullable<VoucherNotificationSenderNameEnum>) => void;
  updateCustomSenderName: (value: Nullable<string>) => void;
  updateDiscountPercent: (value: Nullable<number>) => void;
  validToDateError: Nullable<string>;
  sendDateError: Nullable<string>;
  customSenderNameError: Nullable<string>;
  discountPercentError: Nullable<string>;
  voucherOrderPreviewData?: VoucherOrderPreviewDto;
}

type Props = OwnProps;

const ConfigurationStep = React.memo<Props>(({
  selectedOrganizationId,
  selectedLockers,
  validFromDate,
  validToDate,
  sendDate,
  selectedAdminId,
  linkDestination,
  senderNameType,
  customSenderName,
  discountPercent,
  updateValidFromDate,
  updateValidToDate,
  updateSelectedAdmin,
  updateSendDate,
  updateLinkDestination,
  updateSenderNameType,
  updateCustomSenderName,
  updateDiscountPercent,
  validToDateError,
  sendDateError,
  customSenderNameError,
  discountPercentError,
  voucherOrderPreviewData,
}) => (
  <div className='container'>
    <div className='card w-100'>
      <Grid
        container={true}
        xs={12}
        className={'pb-10'}
        spacing={1}
      >
        <Grid
          container={true}
          item={true}
          xs={6}
        >
          <Grid
            container={true}
            item={true}
            xs={12}
            className={'voucherOrders__edit-form__section'}
          >
            <VoucherOrderSettings
              selectedLockers={selectedLockers}
              validFromDate={validFromDate}
              validToDate={validToDate}
              selectedAdminId={selectedAdminId}
              updateValidFromDate={updateValidFromDate}
              updateValidToDate={updateValidToDate}
              updateSelectedAdmin={updateSelectedAdmin}
              validToDateError={validToDateError}
            />
          </Grid>
          <Grid
            container={true}
            item={true}
            xs={12}
            className={'voucherOrders__edit-form__section'}
          >
            <NotificationSettings
              selectedOrganizationId={selectedOrganizationId}
              sendDate={sendDate}
              linkDestination={linkDestination}
              senderNameType={senderNameType}
              customSenderName={customSenderName}
              updateSendDate={updateSendDate}
              updateLinkDestination={updateLinkDestination}
              updateSenderNameType={updateSenderNameType}
              updateCustomSenderName={updateCustomSenderName}
              sendDateError={sendDateError}
              customSenderNameError={customSenderNameError}
            />
          </Grid>
        </Grid>

        <Grid
          container={true}
          item={true}
          xs={12}
          md={6}
        >
          <Grid
            container={true}
            item={true}
            xs={6}
            md={12}
            className={'voucherOrders__edit-form__section'}
          >
            <DiscountSettings
              discountPercent={discountPercent}
              updateDiscountPercent={updateDiscountPercent}
              discountPercentError={discountPercentError}
              voucherOrderPreviewData={voucherOrderPreviewData}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  </div>
));

export default ConfigurationStep;
