import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@sharedComponents/Icons/Icon';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';

class DecorationLocationArtworkSizesActionsColumn extends Component {
  state = {
    actionsExtended: false,
  };

  closeActions = () => {
    this.setState({ actionsExtended: false });
  };

  toggleActionsMenu = () => {
    this.setState((prevState) => ({
      actionsExtended: !prevState.actionsExtended,
    }));
  };

  handleEditClick = (decorationLocationArtworkSize) => {
    const { onArtworkSizeEdit } = this.props;

    this.closeActions();
    onArtworkSizeEdit(decorationLocationArtworkSize);
  };

  handleDeleteClick = (decorationLocationArtworkSize) => {
    const { onArtworkSizeDelete } = this.props;

    this.closeActions();
    onArtworkSizeDelete(decorationLocationArtworkSize);
  };

  getActionsMenu = (decorationLocationArtworkSize) => {
    const { actionsExtended } = this.state;

    const className = classNames(
      'lockerManager__item-list colors__action-item-list',
      { 'is-active': actionsExtended }
    );

    if (actionsExtended) {
      return (
        <ul className={className}>
          <li>
            <button
              onClick={this.handleEditClick.bind(null, decorationLocationArtworkSize)}
              type='button'
            >
              Edit
            </button>
          </li>
          <li className='lockerManager__item-list--border' />
          <li>
            <button
              onClick={this.handleDeleteClick.bind(this, decorationLocationArtworkSize)}
              type='button'
              className='red'
            >
              Delete
            </button>
          </li>
        </ul>
      );
    }

    return null;
  };

  render() {
    const { artworkSize } = this.props;

    return (
      <OutsideClickWrapper onClick={this.closeActions}>
        <div className='colors__actions-container'>
          <button
            className='colors__actions-btn'
            onClick={this.toggleActionsMenu}
          >
            <Icon
              materialIcon={'more_vert'}
              classes={'button'}
            />
          </button>
          {this.getActionsMenu(artworkSize)}
        </div>
      </OutsideClickWrapper>
    );
  }
}

DecorationLocationArtworkSizesActionsColumn.propTypes = {
  artworkSize: PropTypes.object.isRequired,
  onArtworkSizeEdit: PropTypes.func.isRequired,
  onArtworkSizeDelete: PropTypes.func.isRequired,
};

export default DecorationLocationArtworkSizesActionsColumn;
