import React from 'react';
import { ObjectType } from '@customTypes/option';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';

interface ObjectWithName extends ObjectType {
  name: string;
  value: string | number | boolean;
}

interface OwnPropsForObjects<T extends ObjectWithName, ValueKey extends keyof T> {
  readonly options: T[];
  selected?: T[ValueKey][];
  defaultValue?: T[ValueKey];
  filter: (value: T[ValueKey][]) => void;
}

type Props = OwnPropsForObjects<ObjectWithName, 'value'>;

const StatusFilter = React.memo<Props>(({
  filter,
  options,
  selected,
}) => (
  <div className='select-field margin-left'>
    <MultiSelectDropdown<ObjectWithName, 'value'>
      selectedObjects={selected}
      objects={options}
      itemText={'statuses'}
      updateCallback={filter}
      textKey={'name'}
      valueKey={'value'}
    />
    <div className='select-field__arrow' />
  </div>
));

export default StatusFilter;
