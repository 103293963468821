import React from 'react';
import { formatDollarAmount } from '@util/numberHelpers';

interface OwnProps {
  paid: number;
  refund?: number;
  charge?: number;
}

type Props = OwnProps;

const RefundPrice = React.memo(({
  paid,
  refund,
  charge,
}: Props) => {
  const refundValue = refund ?? 0;
  const chargeValue = charge ?? 0;

  if (refundValue === 0 && chargeValue === 0) {
    return <span>{formatDollarAmount(paid)}</span>;
  }

  const initialPrice = paid - chargeValue + refundValue;

  if (initialPrice === paid) {
    return <span>{formatDollarAmount(paid)}</span>;
  }

  return (
    <div>
      <span className='text-green'>{`${formatDollarAmount(paid)} `}</span>
      <span className='line-through'>{`(${formatDollarAmount(initialPrice)})`}</span>
    </div>
  );
});

export default RefundPrice;
