import React from 'react';
import { Controller } from 'react-hook-form';
import FormError from './FormError';
import { TextField } from '@material-ui/core';

const HookformInput = ({ id, name, control, placeholder, error, required, type, autoFocus = false, className = '', label = '' }) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required: required ? 'This field is required' : false,
      valueAsNumber: type === 'number',
    }}
    // eslint-disable-next-line react/jsx-no-bind
    render={({ field: { ref, value, ...rest } }) => (
      <div>

        <TextField
          style={{ width: '100%' }}
          id={id}
          label={label}
          placeholder={placeholder}
          ref={ref}
          required={required}
          autoFocus={autoFocus}
          type={type}
          value={value ?? ''}
          className={`${error ? 'error' : ''}`}
          {...rest}
          variant='standard'
        />{
          error &&
          <FormError
            error={error}
            fieldError={true}
          />
        }
      </div>
    )}
  />
);
export default HookformInput;
