import { catalogApi } from '../../sqdApis';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { mapDecorationLocationError } from '@util/componentHelpers/decorationLocationsHelper';

export const addDecorationLocation = async (addDecorationLocationForm) => {
  const call = catalogApi.post(productCatalogUtilityUrls.decorationLocations(), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(addDecorationLocationForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapDecorationLocationError);

  return res;
};

export const editDecorationLocation = async (editDecorationLocationForm) => {
  const call = catalogApi.put(productCatalogUtilityUrls.decorationLocation(editDecorationLocationForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(editDecorationLocationForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapDecorationLocationError);

  return res;
};

export const deleteDecorationLocation = async (decorationLocationId) => {
  const call = catalogApi.delete(productCatalogUtilityUrls.decorationLocation(decorationLocationId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
