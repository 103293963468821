interface WebsiteVisibilityEnumValue {
  value: keyof WebsiteVisibilityEnum;
  title: string;
  visibility: string;
}

export interface WebsiteVisibilityEnum {
  all: WebsiteVisibilityEnumValue;
  enabled: WebsiteVisibilityEnumValue;
  disabled: WebsiteVisibilityEnumValue;
}

export const websiteVisibilityEnum: Readonly<WebsiteVisibilityEnum> = {
  all: {
    value: 'all',
    title: 'Website Visibility All',
    visibility: '',
  },
  enabled: {
    value: 'enabled',
    title: 'Enabled Website Visibility',
    visibility: 'true',
  },
  disabled: {
    value: 'disabled',
    title: 'Disabled Website Visibility',
    visibility: 'false',
  },
};
