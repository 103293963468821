import React from 'react';
import { PrepressJobOrderIndicatorEnum } from '@api/fulfillment/models';

interface OwnProps {
  logoUrl: string;
  indicator?: PrepressJobOrderIndicatorEnum;
}

type Props = OwnProps;

const LogoInfo = React.memo<Props>(({ logoUrl }) => (
  <div>
    <i
      className='flagging__details-logo-thumbnail'
      style={{ backgroundImage: `url("${logoUrl}")` }}
    />
  </div>
));

export default LogoInfo;
