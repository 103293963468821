import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface OwnProps {
  url: string;
  text: string | JSX.Element;
  classes?: string;
  openInNewTab?: boolean;
  style?: React.CSSProperties;
}

type Props = OwnProps;

const Link = React.memo(({
  url,
  text,
  classes = '',
  style = {},
  openInNewTab = false,
}: Props) => (
  openInNewTab
    ? (
      <ReactRouterLink
        to={url}
        className={classes || 'link'}
        style={style}
        target={'_blank'}
      >
        {text}
      </ReactRouterLink>
    )
    : (
      <ReactRouterLink
        to={url}
        className={classes || 'link'}
        style={style}
      >
        {text}
      </ReactRouterLink>
    )
));

export default Link;
