import React from 'react';
import { reportsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const ReportsLink = () => (
  <MenuTile
    title={'Reports'}
    url={reportsUrl}
    icon={'summarize'}
  />
);

export default ReportsLink;
