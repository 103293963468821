import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import BarcodeReader from 'react-barcode-reader';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { PtlWallRowDto } from '@api/fulfillment/models';
import {
  getGetHomefieldApiPtlWallsIdQueryKey,
  usePostHomefieldApiPtlWallsIdBinsassociate,
  usePostHomefieldApiPtlItemsreturn,
  useGetHomefieldApiPtlWalls,
  useDeleteHomefieldApiPtlWallsId,
  useGetHomefieldApiPtlWallsId,
} from '@api/fulfillment/ptl';
import { RootState } from '@redux/index/reducers';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import { mapToOptionsList } from '@util/mappingHelper';
import PtlWallRow from './PtlWallRow';
import AddNewPtlWallModal from './AddNewPtlWallModal';
import EditPtlWallModal from './EditPtlWallModal';
import { materialSwal } from '@util/componentHelper';
import { useQueryClient } from 'react-query';
import EmptyPtlWall from './EmptyPtlWall';
import { Option } from '@models/common/Option';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import Button from '@sharedComponents/Buttons/Button';
import RemoveBinOrItemFromPtlWallModal from './RemoveBinOrItemFromPtlWallModal';
import {
  DEV,
  SYC,
} from '@constants/common';

const mapStateTopProps = ({ oidc }: RootState) => ({
  user: oidc.user,
});

const connector = connect(mapStateTopProps);

type Props = ConnectedProps<typeof connector>;

const PtlWall = React.memo<Props>(({ user }) => {
  const [
    selectedWallId,
    setSelectedWallId,
  ] = useState<number | undefined>(undefined);
  const [
    addPtlWallModalIsOpen,
    setAddPtlWallModalIsOpen,
  ] = useState<boolean>(false);
  const [
    editPtlWallModalIsOpen,
    setEditPtlWallModalIsOpen,
  ] = useState<boolean>(false);
  const [
    confirmDeleteWallModalIsOpen,
    setConfirmDeleteWallModalIsOpen,
  ] = useState<boolean>(false);
  const [
    updatedWallId,
    setUpdatedWallId,
  ] = useState(undefined);
  const [
    removeBinOrItemFromRowPositionModalIsOpen,
    setRemoveBinOrItemFromRowPositionModalIsOpen,
  ] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { data: walls } = useGetHomefieldApiPtlWalls({}, { query: { enabled: true } });

  const wallSelected = selectedWallId !== undefined;
  const { data: wall } = useGetHomefieldApiPtlWallsId(selectedWallId ?? 0, { query: { enabled: wallSelected } });

  const { mutateAsync: deleteWallAsync } = useDeleteHomefieldApiPtlWallsId({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  });
  const { mutateAsync: associateBinAndItemsToRowPositionAsync } = usePostHomefieldApiPtlWallsIdBinsassociate({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getGetHomefieldApiPtlWallsIdQueryKey(selectedWallId ?? 0)
        );
      },
    },
  });
  const { mutateAsync: returnOrderItemToBinAsync } = usePostHomefieldApiPtlItemsreturn({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getGetHomefieldApiPtlWallsIdQueryKey(selectedWallId ?? 0)
        );
      },
    },
  });

  useEffect(() => {
    if (!walls) return;
    if (walls) {
      if (updatedWallId) {
        setSelectedWallId(updatedWallId);
      } else {
        setSelectedWallId(walls[0]?.id);
      }
    }
  }, [
    walls,
    updatedWallId,
  ]);

  const options = mapToOptionsList<number, any>({
    list: walls,
    key: 'id',
    name: 'name',
    value: 'id',
  }) as Option<number>[];

  const updateSelection = useCallback((selection: number): void => {
    setSelectedWallId(selection);
  }, []);

  const openAddNewPtlWallModal = useCallback(() => {
    setAddPtlWallModalIsOpen(true);
  }, []);

  const closeAddNewPtlWallModal = useCallback(() => {
    setAddPtlWallModalIsOpen(false);
  }, []);

  const openEditPtlWallModal = useCallback(() => {
    setEditPtlWallModalIsOpen(true);
  }, []);

  const closeEditPtlWallModal = useCallback(() => {
    setEditPtlWallModalIsOpen(false);
  }, []);

  const closeConfirmDeleteWallModal = useCallback(() => {
    setConfirmDeleteWallModalIsOpen(false);
  }, []);

  const openConfirmDeleteWallModal = useCallback(() => {
    setConfirmDeleteWallModalIsOpen(true);
  }, []);

  const openRemoveBinOrItemFromRowPositionModal = useCallback(() => {
    setRemoveBinOrItemFromRowPositionModalIsOpen(true);
  }, []);

  const closeRemoveBinOrItemFromRowPositionModal = useCallback(() => {
    setRemoveBinOrItemFromRowPositionModalIsOpen(false);
  }, []);

  const deleteWall = useCallback(
    async () => {
      if (!selectedWallId || !wall?.id) return;
      const res = await deleteWallAsync({ id: wall?.id });

      if (res?.success) {
        materialSwal(res.message!);
        setUpdatedWallId(undefined);
        setConfirmDeleteWallModalIsOpen(false);
      }
    },
    [
      selectedWallId,
      wall?.id,
      deleteWallAsync,
    ]
  );

  const handleScan = useCallback(async (data: string) => {
    if (data.toUpperCase().startsWith(SYC) || data.toUpperCase().startsWith(DEV)) {
      const variables = {
        id: selectedWallId || 0,
        params: {
          binID: data,
          username: user?.profile?.name,
        },
      };

      await associateBinAndItemsToRowPositionAsync(variables);

      return;
    }

    const variables = {
      params: {
        itemBarcode: data,
        username: user?.profile?.name,
      },
    };

    await returnOrderItemToBinAsync(variables);
  }, [
    associateBinAndItemsToRowPositionAsync,
    returnOrderItemToBinAsync,
    selectedWallId,
    user?.profile?.name,
  ]);

  const renderRows = (row: PtlWallRowDto) => (
    <div key={row.id}>
      <PtlWallRow
        row={row}
      />
    </div>
  );

  return (
    <div className='container master-detail'>
      <AddNewPtlWallModal
        isOpen={addPtlWallModalIsOpen}
        closeModal={closeAddNewPtlWallModal}
        setUpdatedWallId={setUpdatedWallId}
      />
      <RemoveBinOrItemFromPtlWallModal
        closeModal={closeRemoveBinOrItemFromRowPositionModal}
        isOpen={removeBinOrItemFromRowPositionModalIsOpen}
        selectedWallId={selectedWallId}
      />
      {
        wall &&
          <EditPtlWallModal
            wall={wall}
            isOpen={editPtlWallModalIsOpen}
            closeModal={closeEditPtlWallModal}
            setUpdatedWallId={setUpdatedWallId}
          />
      }
      <SimpleConfirmationModal
        confirm={deleteWall}
        title={'Delete Wall'}
        isDangerousAction={true}
        closeModal={closeConfirmDeleteWallModal}
        isOpen={confirmDeleteWallModalIsOpen}
        confirmationBody={(
          <div>
            Confirming this action <strong>{wall?.name + ' '}</strong>
            will be permanently deleted. Are you sure you want to continue?
          </div>
        )}
      />
      {
        !walls?.length
          ? <EmptyPtlWall openAddNewPtlWallModal={openAddNewPtlWallModal} />
          : (
            <div className='w-100'>
              <div className='sheet'>
                <div className='filter-groups'>
                  <div className='table-options w-100'>
                    <div className='flex'>
                      <Dropdown
                        options={options}
                        defaultValue={wall?.id}
                        onChange={updateSelection}
                      />
                      <div className='ml-20'>
                        <Button
                          type={'secondary'}
                          text={'Edit Wall'}
                          onClick={openEditPtlWallModal}
                        />
                      </div>
                      <div className='ml-20'>
                        <Button
                          type={'danger'}
                          text={'Delete Wall'}
                          disabled={!(wall?.status === 'HasVacancies')}
                          onClick={openConfirmDeleteWallModal}
                        />
                      </div>
                      <div className='ml-20'>
                        <Button
                          type={'danger'}
                          text={'Remove Bin or Item'}
                          onClick={openRemoveBinOrItemFromRowPositionModal}
                        />
                      </div>
                    </div>
                    <div className='flex'>
                      <Button
                        type={'primary'}
                        text={'Add New Wall'}
                        onClick={openAddNewPtlWallModal}
                      />
                    </div>
                  </div>
                </div>
                {
                  !removeBinOrItemFromRowPositionModalIsOpen &&
                    <BarcodeReader
                      onScan={handleScan}
                    />
                }
                {wall?.rows?.map(renderRows)}
              </div>
            </div>
          )}
    </div>
  );
});

export default connector(PtlWall);
