import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';

const OpenNewTabLink = React.memo(({
  url,
  text,
  icon = 'launch',
}) => (
  <div className='align__center mr-20'>
    <div className='storeLogo__link lockerManagerEdit__details-header--link'>
      <a
        className='store-link uppercase'
        target='_blank'
        href={url}
        rel='noreferrer'
      >
        <Icon materialIcon={icon} />
        {text}
      </a>
    </div>
  </div>
));

OpenNewTabLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default OpenNewTabLink;
