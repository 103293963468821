import * as actionTypes from './types';

const initialState = {
  messages: [], // Object with text and type
};

export default function alert(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return {
        messages: state.messages.concat(action.message),
      };
    case actionTypes.UPDATE_MESSAGES:
      return {
        messages: action.messages,
      };
    default:
      return state;
  }
}
