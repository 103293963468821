import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';
import TableQuickViewTitle from './TableQuickViewTitle';

interface OwnProps {
  title: string | JSX.Element;
  onClose?: (e: React.MouseEvent<HTMLDivElement>) => void;
  noPadding?: boolean;
}

type Props = OwnProps;

const TableQuickViewHeader = React.memo<Props>(({
  title,
  onClose,
  noPadding = false,
}) => (
  <div className={`tableView__details--header ${noPadding ? 'no-padding' : ''}`}>
    <TableQuickViewTitle title={title} />
    {
      onClose &&
      <div
        className='tableView__details--header--close'
        onClick={onClose}
      >
        <Icon materialIcon={'close'} />
        <span className='uppercase'>Close</span>
      </div>
    }
  </div>
));

export default TableQuickViewHeader;
