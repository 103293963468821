import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { personalizationTypeEnum } from '@constants/enums/decorationEnums';
import { customItemStatusEnum } from '@constants/enums/lockerEnums';
import {
  formatDollarAmount,
  centsToDollars,
  formatDecorationLocationSize,
} from '@util/numberHelpers';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import InfoField from '@sharedComponents/Display/InfoField';
import Tag from '@sharedComponents/Display/Tag';

class ItemSetupInformation extends PureComponent {
  mapDecoration = (decoration) => {
    const { decorationLocations } = this.props;

    const location = decorationLocations.find((dl) => dl.code === decoration.decorationLocation);

    return {
      ...decoration,
      ...location,
    };
  };

  render() {
    const {
      lockerItem = {},
      openModalWithImage,
    } = this.props;

    lockerItem.logos = lockerItem.logos
      .map(this.mapDecoration);
    lockerItem.personalizations = lockerItem.personalizations
      .map(this.mapDecoration);

    return (
      <div className='orderManagement__order-item-modal--container orderManagement__modal-delimiter'>
        <div className='flex'>
          <InfoField
            label={'Images'}
            value={(
              <div className='flex mt-5'>
                <div className='mr-10'>
                  <ImagePreviewCell
                    imageUrl={lockerItem.imageFront}
                    openModalWithUrl={openModalWithImage.bind(null, lockerItem.imageFront)}
                    height={'100px'}
                    width={'100px'}
                  />
                </div>
                <div className='mr-10'>
                  <ImagePreviewCell
                    imageUrl={lockerItem.imageLeft}
                    openModalWithUrl={openModalWithImage.bind(null, lockerItem.imageLeft)}
                    height={'100px'}
                    width={'100px'}
                  />
                </div>
                <div className='mr-10'>
                  <ImagePreviewCell
                    imageUrl={lockerItem.imageRight}
                    openModalWithUrl={openModalWithImage.bind(null, lockerItem.imageRight)}
                    height={'100px'}
                    width={'100px'}
                  />
                </div>
                <ImagePreviewCell
                  imageUrl={lockerItem.imageBack}
                  openModalWithUrl={openModalWithImage.bind(null, lockerItem.imageBack)}
                  height={'100px'}
                  width={'100px'}
                />
              </div>
            )}
          />
          <div className='redux-form__container w-100 ml-60'>
            <div className='flex'>
              <div className='redux-form__column--size-m mr-10'>
                <InfoField
                  label={'Custom Name'}
                  value={lockerItem.customName}
                />
                <InfoField
                  label={'Fundraising Amount'}
                  value={formatDollarAmount(centsToDollars(parseInt(lockerItem.fundraisingAmount)))}
                />
                <InfoField
                  label={'Design ID'}
                  value={lockerItem.vendorDesignId}
                />
              </div>
              <div className='redux-form__column--size-m'>
                <InfoField
                  label={'Custom Price'}
                  value={formatDollarAmount(centsToDollars(parseInt(lockerItem.price)))}
                />
                <InfoField
                  label={'Discounts'}
                  value={lockerItem.discounts ? 'Yes' : 'No'}
                />
                <InfoField
                  label={'Selected Timeline'}
                  value={lockerItem.timelineDay}
                />
              </div>
              <div className='redux-form__column--size-m'>
                <InfoField
                  label={'Item is Required'}
                  value={lockerItem.requiredItem ? 'Yes' : 'No'}
                />
                <InfoField
                  label={'Item is Hidden'}
                  value={lockerItem.status === customItemStatusEnum.Hidden ? 'Yes' : 'No'}
                />
                <InfoField
                  label={'Is Custom'}
                  value={lockerItem.isCustomItem ? 'Yes' : 'No'}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex'>
          <div className='redux-form__column--size-m'>
            <div className='redux-form__container mt-20'>
              <div className='tableView__details--content-container no-border p-0 mb-10'>
                <InfoField
                  label={'Locker'}
                  fieldClass={'mb-30'}
                  value={(
                    <div className='align__center order__shipping--copy-icon'>
                      <Link
                        className='link'
                        target='_blank'
                        to={lockerDetailsUrl(lockerItem.lockerId)}
                      >
                          L<span>{lockerItem.lockerId}</span> - {lockerItem.lockerTeamName}
                      </Link>
                    </div>
                  )}
                />
                <div className='tableView__details--header--title pr-30 mb-20'>
                  <span>Logo & Personalization Locations</span>
                </div>
                <div className='flex'>
                  <InfoField
                    label={'Logo locations'}
                    fieldClass={'mr-80'}
                    labelClass={'mb-10'}
                    value={(
                      <div>
                        {
                          lockerItem.logos && lockerItem.logos.length > 0
                            ? (
                              <div>
                                {
                                  lockerItem.logos.map((l) => (
                                    <div key={l.code}>
                                      <div className='layouts-details__location--name mb-5'>{l.code} - {l.label}</div>
                                      <div>
                                        {
                                          (!l.decorationWidth && !l.decorationHeight)
                                            ? (
                                              <Tag
                                                value={'Default'}
                                                classes={'layouts-details__tag mb-20'}
                                              />
                                            )
                                            : (
                                              <Tag
                                                value={`${formatDecorationLocationSize(l.decorationWidth, l.decorationHeight)}`}
                                                classes={'layouts-details__tag mb-20'}
                                              />
                                            )
                                        }
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            )
                            : <div>No logo locations</div>
                        }
                      </div>
                    )}
                  />
                  <InfoField
                    label={'Personalization locations'}
                    labelClass={'mb-10'}
                    value={(
                      <div>
                        {
                          lockerItem.personalizations && lockerItem.personalizations.length > 0
                            ? (
                              <div>
                                {
                                  lockerItem.personalizations.map((p) => (
                                    <div key={p.code}>
                                      <div className='layouts-details__location--name mb-5'>{p.code} - {p.label}</div>
                                      <div className='flex'>
                                        {
                                          p.personalizationType === personalizationTypeEnum.Name &&
                                          <Tag
                                            value={'Name'}
                                            classes={'layouts-details__tag mr-5 mb-20'}
                                          />
                                        }
                                        {
                                          p.personalizationType === personalizationTypeEnum.Number &&
                                          <Tag
                                            value={'Number'}
                                            classes={'layouts-details__tag mr-5 mb-20'}
                                          />
                                        }
                                        {
                                          p.personalizationType === personalizationTypeEnum.NameAndNumber &&
                                          <Tag
                                            value={'Name and Number'}
                                            classes={'layouts-details__tag mr-5 mb-20'}
                                          />
                                        }
                                        {
                                          !p.decorationHeight
                                            ? (
                                              <Tag
                                                value={'Default'}
                                                classes={'layouts-details__tag mr-5 mb-20'}
                                              />
                                            )
                                            : (
                                              <Tag
                                                label={'Height'}
                                                value={`${p.decorationHeight}''`}
                                                classes={'layouts-details__tag mr-5 mb-20'}
                                              />
                                            )
                                        }
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            )
                            : <div>No personalization locations</div>
                        }
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

ItemSetupInformation.propTypes = {
  lockerItem: PropTypes.object,
  openModalWithImage: PropTypes.func.isRequired,
  decorationLocations: PropTypes.array,
};

export default ItemSetupInformation;
