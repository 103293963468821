import React, { useCallback } from 'react';
import { keyNameEnum } from '@constants/enums/commonEnums';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  searchOrder: (orderNumber: string) => void;
  jumpToOrderRef?: React.RefObject<HTMLInputElement>;
}

type Props = OwnProps;

const GoToOrderInput = React.memo(({
  searchOrder,
  jumpToOrderRef,
}: Props) => {
  const runSearch = useCallback((e: any) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const orderNumber: string = e.target.value;
    searchOrder(orderNumber);
  }, [searchOrder]);

  return (
    <div className='text-field mb-0'>
      <input
        ref={jumpToOrderRef}
        className={'has-icon--right'}
        name={'OrderNumber'}
        type={'text'}
        placeholder={'Jump to Order'}
        onKeyDown={runSearch}
      />
      <Icon
        materialIcon={'arrow_forward'}
        classes={'text-field__icon--right'}
      />
    </div>
  );
});

export default GoToOrderInput;
