import React from 'react';
import { modalStyle } from '@constants/values';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  confirmationBody: string | JSX.Element;
  confirm: () => void;
  modalStyleProps?: any;
  isDangerousAction?: boolean;
  modalHeight?: string;
  modalWidth?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

type Props = OwnProps;

export type SimpleConfirmationModalProps = Props;

const SimpleConfirmationModal = React.memo(({
  isOpen,
  closeModal,
  title,
  confirmationBody,
  confirm,
  modalStyleProps = {},
  isDangerousAction = false,
  modalHeight = 'l',
  modalWidth = 'm',
  confirmBtnText = 'Confirm',
  cancelBtnText = 'Cancel',
}: Props) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    title={title}
    modalHeight={modalHeight}
    modalWidth={modalWidth}
    buttons={(
      <ModalButtons
        onClose={closeModal}
        onConfirm={confirm}
        confirmBtnText={confirmBtnText}
        cancelBtnText={cancelBtnText}
        isDangerousAction={isDangerousAction}
      />
    )}
    modalStyleProps={{
      ...modalStyle,
      ...modalStyleProps,
      overlay: {
        ...modalStyle.overlay,
        zIndex: 1002,
        ...modalStyleProps.overlay,
      },
      content: {
        ...modalStyle.content,
        zIndex: 1003,
        ...modalStyleProps.content,
      },
    }}
  >
    <h3 className='modal__description'>
      <div>{confirmationBody}</div>
    </h3>
  </Modal>
));

export default SimpleConfirmationModal;
