import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

const ProductionAssemblyReviewItemSettings = React.memo(({
  dtgSetting,
  checkedIn,
  pretreated,
  statusHistoryOpened,
}) => (
  <div>
    {
      dtgSetting && checkedIn && !pretreated &&
        <div>
          <div className='order-assembly__settings'>
            <div className='order-assembly__settings-title'>
              <span>Pre-Treat Settings</span>
            </div>

            <div className='order-assembly__settings--minor'>
              <span className='order-assembly__settings--label'>Percentage: {dtgSetting.pretreatmentPercent}%</span>
              <span className='order-assembly__settings--label'>Press Temp: {dtgSetting.pretreatmentPlatenFahrenheit} F</span>
              <span className='order-assembly__settings--label'>Press PSI: {dtgSetting.pretreatmentPsi}</span>
              <span className='order-assembly__settings--label'>Dwell Time: {dtgSetting.pretreatmentDwellTimeSeconds} sec</span>
            </div>
          </div>
          {statusHistoryOpened && <Divider />}
        </div>
    }
    {
      dtgSetting && pretreated &&
      <div>
        <div className='order-assembly__settings'>
          <div className='order-assembly__settings-title'>
            <span>Post-Print Settings</span>
          </div>

          <div className='order-assembly__settings--minor'>
            <span className='order-assembly__settings--label'>Dryer Temp: {dtgSetting.postprintDryerFahrenheit} F</span>
            <span className='order-assembly__settings--label'>Dryer Process Time: {dtgSetting.postprintProcessTimeMinutes} min</span>
            <span className='order-assembly__settings--label'>Dryer Air Speed: {dtgSetting.postprintAirSpeed}</span>
            <span className='order-assembly__settings--label'>Press Temp: {dtgSetting.postprintPressFahrenheit} F</span>
            <span className='order-assembly__settings--label'>Press PSI: {dtgSetting.postprintPsi}</span>
            <span className='order-assembly__settings--label'>Dwell Time: {dtgSetting.postprintDwellTimeSeconds} sec</span>
          </div>
        </div>
        {statusHistoryOpened && <Divider />}
      </div>
    }
  </div>
));

ProductionAssemblyReviewItemSettings.propTypes = {
  dtgSetting: PropTypes.object.isRequired,
  checkedIn: PropTypes.bool,
  pretreated: PropTypes.bool,
  statusHistoryOpened: PropTypes.bool.isRequired,
};

export default ProductionAssemblyReviewItemSettings;
