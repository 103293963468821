import React, { PureComponent } from 'react';
import LogoDropdownItem from './LogoDropdownItem';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import PropTypes from 'prop-types';

class LogoDropdown extends PureComponent {
  state = {
    selectedLogos: [],
    dropIsActive: false,
    throttledCall: null,
  };

  refresh = () => {
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    const newThrottle = setTimeout(this.callUpdate, 1000);

    this.setState({
      throttledCall: newThrottle,
    });
  };

  callUpdate = () => {
    const {
      throttledCall,
      selectedLogos,
    } = this.state;

    const { updateCallback } = this.props;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }
    updateCallback(selectedLogos);
  };

  itemOnClick = (logoId, isSelected) => {
    const { selectedLogos } = this.state;

    if (isSelected) {
      this.setState({
        selectedLogos: selectedLogos.filter((l) => l !== logoId),
      }, this.refresh);
    } else {
      this.setState({
        selectedLogos: [
          ...selectedLogos,
          logoId,
        ],
      }, this.refresh);
    }
  };

  clearFilter = () => {
    const { updateCallback } = this.props;
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    this.setState({
      selectedLogos: [],
      throttledCall: null,
    });
    updateCallback([]);
  };

  dropChange = () => {
    const { dropIsActive } = this.state;
    this.setState({
      dropIsActive: !dropIsActive,
    });
  };

  closeDrop = () => {
    this.setState({
      dropIsActive: false,
    });
  };

  render() {
    const {
      logos,
      className,
    } = this.props;

    const {
      selectedLogos,
      dropIsActive,
    } = this.state;

    const options = logos.map((logo, index) => (
      <LogoDropdownItem
        key={index}
        logo={logo}
        isSelected={selectedLogos.includes(logo.id)}
        onClick={this.itemOnClick}
      />
    ));

    const selectText = selectedLogos.length > 0
      ? `Selected logos (${selectedLogos.length}/${logos.length})`
      : `All logos (${logos.length})`;

    return (
      <OutsideClickWrapper onClick={this.closeDrop}>
        <div
          style={{ minWidth: 200 }}
          className={`logo-dropdown ${className || ''}`}
        >
          <div
            onClick={this.dropChange}
            className='logo-dropdown__select'
          >
            {selectText}
            <div className='logo-dropdown__arrow' />
          </div>
          <div className={`logo-dropdown__options ${dropIsActive ? 'is-active' : ''}`}>
            <ul className='logo-dropdown__options--list custom-scrollbar'>
              {options}
            </ul>
            <div className='logo-dropdown__options--actions'>
              <a
                className='button clear'
                onClick={this.clearFilter}
              >
                Clear logo filter
              </a>
            </div>
          </div>
        </div>
      </OutsideClickWrapper>
    );
  }
}

LogoDropdown.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  updateCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default LogoDropdown;
