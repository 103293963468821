import React from 'react';
import { parseDateNumeric } from '@util/dateHandler';
import expressIcon from '@assets/icon_express-production.svg';

interface OwnProps {
  oldDate: string;
  date: string;
  isExpressProduction: boolean;
}

type Props = OwnProps;

const DateWithOldDateCell = React.memo<Props>(({
  oldDate,
  date,
  isExpressProduction,
}) => {
  if (oldDate) {
    return (
      <div className='align__center'>
        <div className='date__old'>
          {`(${parseDateNumeric(oldDate)}) `}
        </div>
        <div className='date__rush'>
          {`(${parseDateNumeric(date)}) `}
        </div>
        {
          isExpressProduction &&
          <i
            className='fa'
            style={{
              width: '22px',
              height: '14px',
              marginLeft: '8px',
              backgroundImage: `url("${expressIcon}")`,
            }}
          />
        }
      </div>
    );
  }

  if (date) {
    return (
      <div className='align__center'>
        {parseDateNumeric(date)}
        {
          isExpressProduction &&
          <i
            className='fa'
            style={{
              width: '22px',
              height: '14px',
              marginLeft: '8px',
              backgroundImage: `url("${expressIcon}")`,
            }}
          />
        }
      </div>
    );
  }

  return null;
});

export default DateWithOldDateCell;
