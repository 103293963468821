import { catalogApi } from '../../sqdApis';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { mapCategoryErrors } from '@util/componentHelpers/categoriesHelper';

export const addCategory = async (addCategoryForm) => {
  const call = catalogApi.post(productCatalogUtilityUrls.categories, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(addCategoryForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapCategoryErrors);

  return res;
};

export const editCategory = async (editCategoryForm) => {
  const call = catalogApi.put(productCatalogUtilityUrls.category(editCategoryForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(editCategoryForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapCategoryErrors);

  return res;
};

export const editCategories = async (categories) => {
  const updatePromises = [];

  categories.forEach((category) => {
    updatePromises.push(editCategory(category));
  });

  const res = await Promise.all(updatePromises);

  return res;
};

export const deleteCategory = async (categoryId) => {
  const call = catalogApi.delete(productCatalogUtilityUrls.category(categoryId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
