import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';
import Label from '@sharedComponents/Display/Label';

interface OwnProps{
  order: any;
  closeDetails: () => void;
}

type Props = OwnProps;

const OrderManagementQuickViewHeader = React.memo(({
  closeDetails,
  order,
}: Props) => {
  let orderStatus: string | JSX.Element = '';
  if (order?.orderNumber) {
    if (order.rush !== null) {
      orderStatus = (
        <Label
          text={'Rushed'}
          type={'status-blue'}
        />
      );
    }
    if (order.paused !== null) {
      orderStatus = (
        <Label
          text={'Paused'}
          type={'status-orange'}
        />
      );
    }
    if (order.canceled !== null) {
      orderStatus = (
        <Label
          text={'Canceled'}
          type={'status-darkred'}
        />
      );
    }
  }

  return (
    <div className='orderManagement__details-header'>
      <div className='orderManagement__details-header--title'>
        <div className='orderManagement__details-header--info'>
          O{order.orderNumber}
        </div>
        <div className='orderManagement__status flex'>
          {orderStatus}
          {
            order.items && order.items.some((oi: any) => oi.requiredItem) &&
            <Label
              text={'Required'}
              type={'electric-indigo'}
            />
          }
        </div>
      </div>
      <a
        className='flex flagging__actions-button button'
        onClick={closeDetails}
      >
        <Icon materialIcon={'close'} />
        <span className='uppercase'>Close</span>
      </a>
    </div>
  );
});

export default OrderManagementQuickViewHeader;
