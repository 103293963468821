import React from 'react';
import { HagPrintRoomClaimedStatusDto } from '@api/fulfillment/models';
import { mapToOptionsList } from '@util/mappingHelper';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

interface OwnProps {
  filter: (value: string) => void;
  users?: HagPrintRoomClaimedStatusDto[];
  defaultValue: string;
  emptyText: string;
}

type Props = OwnProps;

const ClaimedByFilter = React.memo<Props>(({
  filter,
  users,
  defaultValue,
  emptyText,
}) => {
  const usersOptions = mapToOptionsList<string, any>({
    list: users,
    key: 'userId',
    value: 'userId',
    name: 'displayName',
    emptyOption: {
      name: emptyText,
    },
  });

  return (
    <div className='filter-group margin-left'>
      <Dropdown<string>
        options={usersOptions}
        onChange={filter}
        defaultValue={defaultValue}
      />
    </div>
  );
});

export default ClaimedByFilter;
