import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { orderSchedulingStatusEnum } from '@constants/enums/orderEnums';
import DataGrid from '@sharedComponents/DataGrid/DataGrid';
import {
  fetchReadySchedulingQueueItems,
  fetchInProgressSchedulingQueueItems,
  dispatchQueueItemsToPicking,
} from '@redux/llScheduling/actions';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import Tabs from '@sharedComponents/Tabs/Tabs';
import {
  parseDateNumeric,
  parseDateTimeNumeric,
} from 'util/dateHandler';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { orderItemStatusToString } from '@util/statusHandler';
import RowComponent from './RowComponent';
import InProgressRowComponent from './InProgressRowComponent';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';

const SchedulingTabs = React.memo(({
  readyItems,
  inProgressItems,
  dispatch,
  schedulingQueue,
}) => {
  const [
    selectedTab,
    setSelectedTab,
  ] = useState(orderSchedulingStatusEnum.AwaitingDispatchForPicking);
  const [
    selectedItemIds,
    setSelectedItemIds,
  ] = useState([]);
  const [
    dispatchOverflow,
    setDispatchOverflow,
  ] = useState(0);
  const [
    overflowTooltipText,
    setOverflowTooltipText,
  ] = useState('');
  const [
    imageModalIsOpen,
    setimageModalIsOpen,
  ] = useState(false);
  const [
    urlImage,
    setUrlImage,
  ] = useState('');

  useEffect(() => {
    const remainingCapacity = schedulingQueue.capacity - schedulingQueue.filledCapacity;
    if (selectedItemIds.length > remainingCapacity) {
      setDispatchOverflow(selectedItemIds.length - remainingCapacity);
      const itemText = selectedItemIds.length - remainingCapacity === 1 ? 'item' : 'items';
      setOverflowTooltipText(`${selectedItemIds.length - remainingCapacity} ${itemText} over remaining daily capacity`);
    } else {
      setDispatchOverflow(0);
      setOverflowTooltipText('');
    }
  }, [schedulingQueue]);

  useEffect(() => {
    if (selectedTab === orderSchedulingStatusEnum.AwaitingDispatchForPicking) {
      dispatch(fetchReadySchedulingQueueItems(schedulingQueue.id));
    }
    if (selectedTab === orderSchedulingStatusEnum.InPicking) {
      dispatch(fetchInProgressSchedulingQueueItems(schedulingQueue.id));
    }
  }, [
    selectedTab,
    schedulingQueue.id,
  ]);

  const selectTab = useCallback((tab) => {
    setSelectedTab(tab);
    setSelectedItemIds([]);
  }, []);

  const openImageModal = useCallback((url) => {
    setUrlImage(url);
    setimageModalIsOpen(true);
  }, []);

  const closeImageModal = useCallback(() => setimageModalIsOpen(false), []);

  const getGroupByColumns = useMemo(() => {
    let groupBy = [];
    switch (schedulingQueue.decorationMethod) {
      case decorationMethodEnum.DTG:
        groupBy = [
          'defaultPalletName',
          'shipDate',
        ];
        break;
      case decorationMethodEnum.HAG:
      case decorationMethodEnum.EMB:
      case null:
      default:
        groupBy = [
          'shipDate',
          'orderNumber',
        ];
    }

    return groupBy;
  }, [schedulingQueue.decorationMethod]);

  const tmpDecorationsTooltipComponent = {
    name: 'Decoration(s)',
    key: 'decorationCount',
    template: (row) => (
      <RowComponent
        row={row}
        schedulingQueue={schedulingQueue}
      />
    ),
    sortable: true,
  };

  const getDefaultColumns = () => {
    const columns = [
      {
        name: 'Image',
        key: 'imageUrl',
        template: (row) => (
          <ImagePreviewCell
            openModalWithUrl={openImageModal}
            imageUrl={row.imageUrl}
            height='50px'
          />
        ),
        groupByDisabled: true,
        sortable: false,
        width: 10,
      },
      {
        name: 'Item Serial #',
        key: 'barcode',
        sortable: true,
      },
      {
        name: 'Ship Date',
        key: 'shipDate',
        sortable: true,
        template: (row) => (
          parseDateNumeric(row.shipDate || row)
        ),
        groupTemplate: (value) => (
          parseDateNumeric(value)
        ),
      },
      {
        name: 'Color',
        key: 'colorGroup',
        sortable: true,
      },
      {
        name: 'Category',
        key: 'categoryName',
        sortable: true,
      },
      {
        name: 'Related',
        key: 'inProgressToTotalRatio',
        template: (row) => (
          <InProgressRowComponent
            row={row}
            schedulingQueue={schedulingQueue}
          />
        ),
        sortable: true,
        groupByDisabled: true,
      },
    ];

    return columns;
  };

  const getDtgColumns = (columns) => {
    columns.splice(2, 0, {
      name: 'Pallet',
      key: 'defaultPalletName',
      sortable: true,
    });

    columns.splice(4, 0, {
      name: 'Brand',
      key: 'brandName',
      sortable: true,
    });

    columns.splice(7, 0, {
      name: 'Size',
      key: 'size',
      sortable: true,
      width: 7,
    }, tmpDecorationsTooltipComponent);

    return columns;
  };

  const getHagColumns = (columns) => {
    columns.splice(2, 0, {
      name: 'Order ID',
      key: 'orderNumber',
      sortable: true,
    });

    columns.splice(5, 0, {
      name: 'Brand',
      key: 'brandName',
      sortable: true,
    });

    columns.splice(7, 0, tmpDecorationsTooltipComponent);

    return columns;
  };

  const getEmbColumns = (columns) => {
    columns.splice(2, 0, {
      name: 'Order ID',
      key: 'orderNumber',
      sortable: true,
    });

    columns.splice(6, 0, tmpDecorationsTooltipComponent);

    return columns;
  };

  const getNoDecorationColumns = (columns) => {
    columns.splice(2, 0, {
      name: 'Order ID',
      key: 'orderNumber',
      sortable: true,
    });

    return columns;
  };

  const dataGridColumns = useMemo(() => {
    let columns = getDefaultColumns();
    switch (schedulingQueue.decorationMethod) {
      case decorationMethodEnum.DTG:
        columns = getDtgColumns(columns);
        break;
      case decorationMethodEnum.HAG:
        columns = getHagColumns(columns);
        break;
      case decorationMethodEnum.EMB:
        columns = getEmbColumns(columns);
        break;
      case null:
        columns = getNoDecorationColumns(columns);
      default:
    }

    return columns;
  }, [schedulingQueue.decorationMethod]);

  const inProgressColumns = useMemo(() => {
    const columns = [...dataGridColumns];

    columns.splice(columns.length - 1, 0, {
      name: 'Operator',
      key: 'operator',
      sortable: false,
      template: (row) => (
        <div>
          <div className='scheduling__data-grid__related-column__cell--in-progress'>
            { `${row.operatorName}` }
          </div>
          <div className='scheduling__data-grid__related-column__cell--total-items'>
            { `${parseDateTimeNumeric(row.operatorTimestamp)}` }
          </div>
        </div>
      ),
      width: 15,
    });

    columns.push({
      name: 'Status',
      key: 'status',
      sortable: true,
      template: (row) => (
        orderItemStatusToString(row.status)
      ),
    });

    return columns;
  }, [schedulingQueue.decorationMethod]);

  const getTabs = () => {
    const tabs = [
      {
        title: `${schedulingQueue ? schedulingQueue.queueName : ''} Ready (${schedulingQueue.awaitingDispatch})`,
        name: orderSchedulingStatusEnum.AwaitingDispatchForPicking,
        content: (
          <div className='master-detail'>
            <DataGrid
              key={1}
              rows={readyItems}
              columns={dataGridColumns}

              selectable={true}
              selectionKey={'id'}
              onSelectionChange={onSelectionChange}
              action={dispatchForPicking}
              actionText={'Dispatch For Picking'}

              groupLayersCount={2}
              groupLayersProperties={[
                {
                  number: 1,
                  alwaysExpanded: false,
                  height: 60,
                  showCount: false,
                },
                {
                  number: 2,
                  alwaysExpanded: false,
                  height: 40,
                  showCount: true,
                },
              ]}
              groupByColumns={getGroupByColumns}
              hasDisabledActionButtonTooltip={true}
              actionButtonDisabled={dispatchOverflow > 0}
              disabledActionButtonTooltipText={overflowTooltipText}
            />
          </div>
        ),
      },
      {
        title: `In Progress (${schedulingQueue.inProgress})`,
        name: orderSchedulingStatusEnum.InPicking,
        content: (
          <div className='master-detail'>
            <DataGrid
              key={2}
              rows={inProgressItems}
              columns={inProgressColumns}

              selectable={false}

              groupLayersCount={2}
              groupLayersProperties={[
                {
                  number: 1,
                  alwaysExpanded: false,
                  height: 60,
                  showCount: false,
                },
                {
                  number: 2,
                  alwaysExpanded: false,
                  height: 40,
                  showCount: true,
                },
              ]}
              groupByColumns={getGroupByColumns}
            />
          </div>
        ),
      },
    ];

    return tabs;
  };

  const dispatchForPicking = useCallback(() => {
    dispatch(dispatchQueueItemsToPicking(
      { schedulingQueueItemIds: selectedItemIds },
      schedulingQueue.id
    ));
  }, [
    schedulingQueue,
    selectedItemIds,
  ]);

  const onSelectionChange = useCallback((selection) => {
    setSelectedItemIds(selection);

    const remainingCapacity = schedulingQueue.capacity - schedulingQueue.filledCapacity;
    if (selection.length > remainingCapacity) {
      setDispatchOverflow(selection.length - remainingCapacity);
      const itemText = selection.length - remainingCapacity === 1 ? 'item' : 'items';
      setOverflowTooltipText(`${selection.length - remainingCapacity} ${itemText} over remaining daily capacity`);
    } else {
      setDispatchOverflow(0);
      setOverflowTooltipText('');
    }
  }, [schedulingQueue]);

  return (
    <div className='container'>
      <ImagePreviewModal
        modalIsOpen={imageModalIsOpen}
        closeModal={closeImageModal}
        imageUrl={urlImage}
      />
      <Tabs
        tabs={getTabs()}
        selectedTab={selectedTab}
        selectTab={selectTab}
      />
    </div>
  );
});

SchedulingTabs.propTypes = {
  readyItems: PropTypes.array.isRequired,
  inProgressItems: PropTypes.array.isRequired,
  readyItemsCount: PropTypes.number.isRequired,
  schedulingQueue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    queueName: PropTypes.string.isRequired,
    capacity: PropTypes.number,
    filledCapacity: PropTypes.number,
    decorationMethod: PropTypes.string,
    awaitingDispatch: PropTypes.number,
    inProgress: PropTypes.number,
  }).isRequired,
  dispatchForPickingCallback: PropTypes.func.isRequired,
  schedulingQueueTooltip: PropTypes.array,
};

const mapStateToProps = ({ llScheduling }) => ({
  readyItems: llScheduling.readyItems,
  itemsCountInStatuses: llScheduling.itemsCountInStatuses,
  schedulingQueueTooltip: llScheduling.schedulingQueueTooltip,
  schedulingQueue: llScheduling.schedulingQueue,
});

export default connect(mapStateToProps)(SchedulingTabs);
