import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactModal from 'react-modal';
import { ProgressBar } from 'react-bootstrap';
import { modalStyle } from '@constants/values';
import { isApiResponse } from '@util/apiHelper';

ReactModal.setAppElement('body');

type OnCompleteCallbackResultType<TReq, TRes> =
{ success: true; request: TReq; response: TRes; }
| { success: false; request: TReq; response: string; };

export interface OnCompleteCallbackArgType<TReq, TRes> {
  successes: number;
  total: number;
  results: OnCompleteCallbackResultType<TReq, TRes>[];
}

interface OwnProps<TReq, TRes> {
  title: string;
  callback: (data: TReq) => Promise<TRes>;
  work: TReq[];
  cancelable?: boolean;
  modalStyleProps?: ReactModal.Styles;
  modalSize?: string;
  modalClass?: string;
  modalHeaderClass?: string;
  enableBodyOverflow?: boolean;
  dataTest?: string;
  modalHeight?: string;
  modalWidth?: string;
  customClasses?: string;
  sleepMs?: number;
  onComplete?: (result: OnCompleteCallbackArgType<TReq, TRes>) => void;
  onCancel?: () => void;
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

type Props<TReq, TRes> = OwnProps<TReq, TRes>;

export type ProgressModalProps<TReq, TRes> = Props<TReq, TRes>;
export type ProgressModalOnCompleteCallbackResultType<TReq, TRes> = OnCompleteCallbackResultType<TReq, TRes>;
export type ProgressModalOnCompleteCallbackArgType<TReq, TRes> = OnCompleteCallbackArgType<TReq, TRes>;

const ProgressModal = <TReq, TRes>({
  title = '',
  modalStyleProps,
  modalSize = 'xl',
  modalClass = '',
  modalHeaderClass = '',
  enableBodyOverflow = false,
  dataTest = 'modal',
  modalHeight,
  modalWidth,
  customClasses = '',
  cancelable = false,
  sleepMs = 0,
  work,
  callback,
  onComplete,
  onCancel,
}: Props<TReq, TRes>) => {
  let overflowStyleProps = {};
  if (enableBodyOverflow) {
    overflowStyleProps = {
      content: {
        ...modalStyle.content,
        overflowX: 'visible',
        overflowY: 'visible',
      },
    };
  }

  // Setting States
  const [
    queuedWork,
    setQueuedWork,
  ] = useState<TReq[]>([]);
  const runNumber = useRef(0);
  const runNumberCount = useRef(0);
  const [
    percent,
    setPercent,
  ] = useState(0);

  // Cancel by changing the current run number
  const handleCancel = useCallback(() => {
    runNumber.current = 0;
    setQueuedWork([]);
    onCancel?.();
  }, [onCancel]);

  // Initialize chain with queued work and current run number
  type InterruptableCallbackChainOptions = {
    thisRunNumber: number;
    queue: TReq[];
  };
  const handleInterruptableCallbackChain = useCallback(async ({
    thisRunNumber, queue,
  }: InterruptableCallbackChainOptions) => {
    const results: OnCompleteCallbackResultType<TReq, TRes>[] = [];
    const total = queue.length;
    let successes = 0;
    let placeOnProgessBar = 0;

    for (const queueItem of queue) {
      if (thisRunNumber !== runNumber.current) return;
      placeOnProgessBar++;
      setPercent(placeOnProgessBar * 100 / queue.length);

      try {
        const response = await callback(queueItem);
        successes++;
        results.push({
          success: true,
          request: queueItem,
          response,
        });
      } catch (err) {
        console.error('Error on:', queueItem, err);

        let response = 'Unknown error has occurred';
        if (err instanceof Error) {
          response = `Exception Thrown: ${err.message}`;
        } else if (typeof err === 'string') {
          response = err || response;
        } else if (isApiResponse(err)) {
          response = err.message ?? response;
        }

        results.push({
          success: false,
          request: queueItem,
          response,
        });
      }
      await sleep(sleepMs);
    }
    if (thisRunNumber !== runNumber.current) return;
    runNumber.current = 0;
    setQueuedWork([]);
    onComplete?.({
      successes,
      total,
      results,
    });
  }, [
    callback,
    onComplete,
    sleepMs,
  ]);

  useEffect(() => {
    if (work.length === 0) {
      runNumber.current = 0;
      setQueuedWork([]);

      return;
    }
    runNumber.current = runNumberCount.current = runNumberCount.current + 1;
    setQueuedWork([...work]);
  }, [work]);

  useEffect(() => {
    setPercent(0);
    if (queuedWork.length === 0) {
      return;
    }
    const thisRunNumber = runNumber.current;
    const queue = [...queuedWork];
    handleInterruptableCallbackChain({
      thisRunNumber,
      queue,
    });
  }, [
    queuedWork,
    callback,
    sleepMs,
    onComplete,
    handleInterruptableCallbackChain,
  ]);

  return (
    <ReactModal
      isOpen={runNumber.current > 0}
      style={{
        ...modalStyle,
        ...overflowStyleProps,
        ...modalStyleProps,
      }}
    >
      <div
        className={
          modalHeight && modalWidth
            ? `modal__content modal__content--height-${modalHeight} modal__content--width-${modalWidth} ${modalClass} ${customClasses}`
            : `modal__content modal__content--size-${modalSize} ${modalClass} ${customClasses}`
        }
        data-test={dataTest}
      >
        <div className={`modal__header ${modalHeaderClass}`}>
          <header>
            <div className='modal__title modal__title--left mb-10'>{title}</div>
          </header>
        </div>

        <div className={`custom-scrollbar modal__children ${enableBodyOverflow ? 'modal__body--overflow' : ''}`}>
          <div>
            <ProgressBar
              variant='warning'
              now={percent}
            />
            <div>
              {Math.round(percent)}%
            </div>
          </div>
        </div>
        {!!cancelable && (
          <div className='modal__footer'>
            <div className='modal__btn-container'>
              <button
                data-test={`${dataTest}-cancel`}
                className={'modal__btn btn-borderless btn-borderless--green button--size-l'}
                onClick={handleCancel}
                type='button'
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </ReactModal>
  );
};

export default React.memo(ProgressModal) as typeof ProgressModal;
