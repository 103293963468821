/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type HagPrintRoomJobStatusEnum = 'Undefined' | 'OnHold' | 'PrintReady' | 'Printing' | 'Printed' | 'Assembling' | 'Assembled';


export const HagPrintRoomJobStatusEnum = {
  Undefined: 'Undefined' as HagPrintRoomJobStatusEnum,
  OnHold: 'OnHold' as HagPrintRoomJobStatusEnum,
  PrintReady: 'PrintReady' as HagPrintRoomJobStatusEnum,
  Printing: 'Printing' as HagPrintRoomJobStatusEnum,
  Printed: 'Printed' as HagPrintRoomJobStatusEnum,
  Assembling: 'Assembling' as HagPrintRoomJobStatusEnum,
  Assembled: 'Assembled' as HagPrintRoomJobStatusEnum,
};
