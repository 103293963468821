/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */

export type PersonalizationTypeEnum = 'NameAndNumber' | 'Name' | 'Number' | 'Optional';


export const PersonalizationTypeEnum = {
  NameAndNumber: 'NameAndNumber' as PersonalizationTypeEnum,
  Name: 'Name' as PersonalizationTypeEnum,
  Number: 'Number' as PersonalizationTypeEnum,
  Optional: 'Optional' as PersonalizationTypeEnum,
};
