import React from 'react';
import PropTypes from 'prop-types';
import icon from '@assets/icon_express-production.svg';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { formatDollarAmount } from '@util/numberHelpers';
import Icon from '@sharedComponents/Icons/Icon';
import ItemSelectionIcon from '@sharedComponents/Icons/ItemSelectionIcon';

const LockerManagerAddProductsProduct = React.memo(({
  product,
  onClickProduct,
  onSelectProduct,
  isSelected,
  coloredStyleProducts,
}) => {
  const availableForExpressProduction = (coloredStyleProducts && coloredStyleProducts.length > 0)
      && coloredStyleProducts[0].availableForExpressProduction;
  const style = product && product.style;
  const priceFormatted = style && style.price && formatDollarAmount(parseInt(style.price));

  let tooltipText = 'Decoration \n Not Available';
  switch (style.decorationMethod) {
    case decorationMethodEnum.HAG:
      tooltipText = 'Item Supports Heat \n Applied Graphics';
      break;
    case decorationMethodEnum.EMB:
      tooltipText = 'Item Supports \n Embroidery';
      break;
    case decorationMethodEnum.SUB:
      tooltipText = 'Item Supports \n Sublimation';
      break;
    case decorationMethodEnum.DTG:
      tooltipText = 'Direct To \n Garment';
      break;
    case decorationMethodEnum.DIP:
      tooltipText = 'Digital Inkjet \n Printing';
      break;
  }

  return (
    <div
      onClick={onSelectProduct.bind(null, product)}
      className={`add-product__available--container ${isSelected && 'isSelected'}`}
    >
      <div className='add-product__available--product'>
        <div className='position-absolute'>
          <div className='tooltip-container'>
            <div className='add-product__available--decorations'>
              <span>
                {
                  style.decorationMethod !== decorationMethodEnum.Undefined
                    ? style.decorationMethod
                    : decorationMethodEnum.NA
                }
              </span>
              <div className='tooltip-text'>
                {tooltipText}
              </div>
            </div>
          </div>
        </div>
        <div
          className='add-product__available--image'
          onClick={onClickProduct.bind(null, product)}
          style={{ backgroundImage: `url("${product.imageUrlFront}")` }}
        >
          <div className='details__item-overlay'>
            <i className='material-icons details__item-preview-icon'>search</i>
          </div>
        </div>
        <ItemSelectionIcon
          isSelected={isSelected}
        />
      </div>
      <div className='add-product__available--info'>
        {
          availableForExpressProduction &&
          <i
            className='fa'
            style={{
              width: '18px',
              height: '18px',
              backgroundImage: `url("${icon}")`,
            }}
          />
        }
        {style.recommended && <Icon materialIcon={'thumb_up'} />}
      </div>
      <span className='add-product__available--title'>
        {style.name}
      </span>
      <span className='add-product__available--color'>
        {product.colorGroup}
      </span>
      <span className='add-product__available--price'>
        {priceFormatted}
      </span>
    </div>
  );
});

LockerManagerAddProductsProduct.propTypes = {
  product: PropTypes.object.isRequired,
  onClickProduct: PropTypes.func.isRequired,
  onSelectProduct: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  coloredStyleProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default LockerManagerAddProductsProduct;
