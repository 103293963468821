import React from 'react';
import { bulkAddItemUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const BulkAddItemLink = React.memo(() => (
  <MenuTile
    title={'Add Replacement Item'}
    url={bulkAddItemUrl}
    isMaterialIcon={false}
    icon={'fa-solid fa-cart-plus'}
  />
));

export default BulkAddItemLink;
