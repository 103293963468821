import React from 'react';

interface OwnProps {
  title: JSX.Element;
  isBold?: boolean;
  classes?: string;
}

type Props = OwnProps;

const ListGroupTitle = React.memo<Props>(({
  title,
  isBold = false,
  classes = '',
}) => (
  <div className={`grouped-list__group--title ${isBold ? 'is-bold' : ''} ${classes}`}>
    {title}
  </div>
));

export default ListGroupTitle;
