import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  syncStyle,
  syncColoredStyle,
  syncColoredStyleProducts,
} from '@APICalls/productCatalog/actions';
import { styleUrl } from '@constants/clientUrls/clientUrls';
import {
  getStyleById,
  getColoredStylesForStyle,
  getProductsForStyle,
  getStyleByIdFromSSAPI,
  getColoredStylesForStyleFromSSAPI,
  getProductsForStyleFromSSAPI,
  getColors,
} from '@redux/productCatalog/actions';
import { extractParameterFromPath } from '@util/stringHelpers';
import { getSampleImage } from '@util/componentHelpers/productCatalogHelper';
import { materialSwal } from '@util/componentHelper';
import Table from '@sharedComponents/Table/Table';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import BackLink from '@sharedComponents/Navigation/BackLink';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import Icon from '@sharedComponents/Icons/Icon';
import SyncStyleColoredStyleDetails from './SyncStyleColoredStyleDetails';
import SyncStyleDetailsHeader from './SyncStyleDetailsHeader';

const ColoredStylesTable = Table();

class SyncStyleDetails extends Component {
  state = {
    imageModalIsOpen: false,
    imageUrl: '',
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getColors());
    this.refresh();
  }

  refresh = () => {
    this.refreshStyle();
    this.refreshColoredStyles();
    this.refreshProducts();
  };

  refreshStyle = () => {
    const { dispatch } = this.props;
    const styleId = extractParameterFromPath(this.props, 'styleId', 'number');

    dispatch(getStyleById(styleId));
    dispatch(getStyleByIdFromSSAPI(styleId));
  };

  refreshColoredStyles = () => {
    const { dispatch } = this.props;
    const styleId = extractParameterFromPath(this.props, 'styleId', 'number');

    dispatch(getColoredStylesForStyle(styleId));
    dispatch(getColoredStylesForStyleFromSSAPI(styleId));
  };

  refreshProducts = () => {
    const { dispatch } = this.props;
    const styleId = extractParameterFromPath(this.props, 'styleId', 'number');

    dispatch(getProductsForStyle(styleId));
    dispatch(getProductsForStyleFromSSAPI(styleId));
  };

  openModalWithImage = (imageUrl) => {
    this.setState(() => ({
      imageModalIsOpen: true,
      imageUrl,
    }));
  };

  closeImageModal = () => {
    this.setState(() => ({
      imageModalIsOpen: false,
      imageUrl: '',
    }));
  };

  syncStyle = async (styleId) => {
    const res = await syncStyle(styleId);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.refreshStyle();
    }
  };

  syncColoredStyle = async (coloredStyleId) => {
    const res = await syncColoredStyle(coloredStyleId);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.refreshColoredStyles();
    }
  };

  syncColoredStyleProducts = async (coloredStyleId) => {
    const res = await syncColoredStyleProducts(coloredStyleId);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.refreshProducts();
    }
  };

  getColumns = () => {
    const {
      colors,
      colorsDictionary,
      coloredStylesFromSSAPI,
      teamColorsByCode,
    } = this.props;

    const columns = [
      {
        Header: 'Color Group & Code',
        accessor: '',
        className: 'text-bold',
        Cell: (cellProps) => {
          const colorGroupColor = teamColorsByCode[cellProps.value.colorGroup];

          return (
            <div>
              <div className='catalog-details__name'>
                <ColorDisplay
                  colorName={cellProps.value.colorGroup}
                  displayName={false}
                  size={'size--small'}
                  hideEmptySwatch={true}
                  hexValue={colorGroupColor && colorGroupColor.hexValue}
                />
                <div className='text-uppercase'>{cellProps.value.colorGroup}</div>
              </div>
              <div className='table__cell--details'>{cellProps.value.code}</div>
            </div>
          );
        },
      },
      {
        Header: 'Flood color',
        accessor: 'floodColorId',
        Cell: (cellProps) => {
          const color = colorsDictionary[cellProps.value];

          return color
            ? (
              <ColorDisplay
                colorName={color.code}
                displayName={true}
                size={'size--small'}
                hideEmptySwatch={true}
                hexValue={color.hexValue}
              />
            )
            : '-';
        },
      },
      {
        Header: 'Personalization colors',
        accessor: 'personalizationColorIds',
        Cell: (cellProps) => {
          const personalizationColors = colors.filter((color) => cellProps.value.includes(color.id));

          return personalizationColors.length > 0
            ? (
              <div className='flex__wrap'>
                {
                  personalizationColors.map((color, index) => (
                    <div
                      className='mr-10'
                      key={index}
                    >
                      <ColorDisplay
                        colorName={color.code}
                        displayName={true}
                        size={'size--small'}
                        hideEmptySwatch={true}
                        hexValue={color.hexValue}
                      />
                    </div>
                  ))
                }
              </div>
            )
            : '-';
        },
      },
      {
        Header: '',
        width: 400,
        Cell: (cellProps) => (
          <div>
            {
              !coloredStylesFromSSAPI.find((cs) => cs.id === cellProps.original.id) &&
              <div className='flex'>
                <Icon
                  materialIcon={'warning'}
                  classes={'redux-form__icon orange mr-5'}
                />
                <div className='sync-details__warning-text'>
                  Colored style missing in SL Services API database
                </div>
              </div>
            }
          </div>
        ),
      },
      {
        expander: true,
        Header: '',
        width: 65,
        Expander: ({ isExpanded }) => (
          <div>
            {
              isExpanded
                ? (
                  <Icon
                    materialIcon={'arrow_drop_up'}
                    classes={'button catalog-details__expander'}
                  />
                )
                : (
                  <Icon
                    materialIcon={'arrow_drop_down'}
                    classes={'button catalog-details__expander'}
                  />
                )
            }
          </div>
        ),
      },
    ];

    return columns;
  };

  getSubComponent = (cell) => {
    const {
      coloredStylesFromSSAPI,
      products,
      productsFromSSAPI,
    } = this.props;

    return (
      <SyncStyleColoredStyleDetails
        coloredStyle={cell.original}
        coloredStyleFromSSAPI={coloredStylesFromSSAPI.find((cs) => cs.id === cell.original.id)}
        products={products.filter((p) => p.coloredStyleId === cell.original.id)}
        productsFromSSAPI={productsFromSSAPI.filter((p) => p.colored_style_id === cell.original.id)}
        syncColoredStyle={this.syncColoredStyle}
        syncColoredStyleProducts={this.syncColoredStyleProducts}
      />
    );
  };

  render() {
    const {
      style,
      coloredStyles,
      styleFromSSAPI,
    } = this.props;

    const {
      imageModalIsOpen,
      imageUrl,
    } = this.state;

    return (
      <div className='container'>
        <ImagePreviewModal
          modalIsOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />
        <div className='navigation'>
          <BackLink
            text={'Style Details'}
            url={styleUrl(style.id)}
          />
        </div>

        <SyncStyleDetailsHeader
          style={style}
          styleFromSSAPI={styleFromSSAPI}
          sampleImage={getSampleImage(coloredStyles)}
          openModalWithImage={this.openModalWithImage}
          syncStyle={this.syncStyle}
        />

        <div className='mb-30'>
          <ColoredStylesTable
            columns={this.getColumns()}
            data={coloredStyles}
            showPagination={false}
            defaultPageSize={-1}
            subComponent={this.getSubComponent}
          />
        </div>
      </div>
    );
  }
}

SyncStyleDetails.propTypes = {
  styleId: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  styleFromSSAPI: PropTypes.object.isRequired,
  coloredStylesFromSSAPI: PropTypes.arrayOf(PropTypes.object),
  productsFromSSAPI: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  teamColorsByCode: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.string,
  })).isRequired,
};

const mapStateToProps = ({
  productCatalog,
  support,
}) => ({
  style: productCatalog.style,
  coloredStyles: productCatalog.coloredStyles,
  products: productCatalog.styleProducts,
  styleFromSSAPI: productCatalog.styleFromSSAPI,
  coloredStylesFromSSAPI: productCatalog.coloredStylesFromSSAPI,
  productsFromSSAPI: productCatalog.styleProductsFromSSAPI,
  colors: productCatalog.colors,
  colorsDictionary: productCatalog.colorsDictionary,
  teamColorsByCode: support.teamColorsByCode,
});

export default connect(mapStateToProps)(SyncStyleDetails);
