/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import type {
  ApiInternalServerError,
  ColoredStylesDecorationMethodsViewModel,
  GetColoredStylesDecorationMethodsParams,
  PagedListViewModelStyleDesignConfigTableRowViewModel,
  GetStylesMissingDesignConfigurationsParams
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getColoredStyleDecorationMethod = <TData = string>(
    id: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/colored-styles/${id}/decorationMethod`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetColoredStyleDecorationMethodQueryKey = (id: number,) => [`/homefield/api/colored-styles/${id}/decorationMethod`]

    
export const useGetColoredStyleDecorationMethod = <TQueryFnData = AsyncReturnType<typeof getColoredStyleDecorationMethod, string>, TError = ApiInternalServerError, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetColoredStyleDecorationMethodQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getColoredStyleDecorationMethod<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getColoredStylesDecorationMethods = <TData = ColoredStylesDecorationMethodsViewModel>(
    params?: GetColoredStylesDecorationMethodsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/colored-styles.getDecorationMethods`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetColoredStylesDecorationMethodsQueryKey = (params?: GetColoredStylesDecorationMethodsParams,) => [`/homefield/api/colored-styles.getDecorationMethods`, ...(params ? [params]: [])]

    
export const useGetColoredStylesDecorationMethods = <TQueryFnData = AsyncReturnType<typeof getColoredStylesDecorationMethods, ColoredStylesDecorationMethodsViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetColoredStylesDecorationMethodsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetColoredStylesDecorationMethodsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getColoredStylesDecorationMethods<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getStylesMissingDesignConfigurations = <TData = PagedListViewModelStyleDesignConfigTableRowViewModel>(
    params?: GetStylesMissingDesignConfigurationsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/colored-styles/missing-design-configs/styles`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetStylesMissingDesignConfigurationsQueryKey = (params?: GetStylesMissingDesignConfigurationsParams,) => [`/homefield/api/colored-styles/missing-design-configs/styles`, ...(params ? [params]: [])]

    
export const useGetStylesMissingDesignConfigurations = <TQueryFnData = AsyncReturnType<typeof getStylesMissingDesignConfigurations, PagedListViewModelStyleDesignConfigTableRowViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetStylesMissingDesignConfigurationsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetStylesMissingDesignConfigurationsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getStylesMissingDesignConfigurations<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

