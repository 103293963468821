import React from 'react';
import PropTypes from 'prop-types';
import { organizationDetailsUrl } from '@constants/clientUrls/clientUrls';
import { parseDateNumeric } from '@util/dateHandler';
import {
  formatDollarAmount,
  formatPercentageAmount,
} from '@util/numberHelpers';
import InfoField from '@sharedComponents/Display/InfoField';
import Link from '@sharedComponents/Navigation/Link';
import StoreLogo from './StoreLogo';
import Box from '@material-ui/core/Box';

const LockerManagerQuickViewInfo = React.memo(({ locker }) => (
  <div className='lockerManager__details-container'>
    <div className='lockerManager__details-info'>
      <div className='lockerManager__details-locker'>
        <div className='lockerManager__details-column--l'>
          <InfoField
            label={'Team Name'}
            value={locker.storeName}
            dataTest={'team-name'}
          />
          <InfoField
            label={'Slug'}
            value={locker.slug}
            dataTest={'slug'}
          />
          <InfoField
            label={'Organization Name'}
            dataTest={'organization-name'}
            value={
              locker.organizationName &&
              <Link
                url={organizationDetailsUrl(locker.organizationId)}
                text={`ORG${locker.organizationId} - ${locker.organizationName}`}
              />
            }
          />
          <Box display='flex'>
            <InfoField
              label={'LCK Number'}
              dataTest={'lck-number'}
              value={locker.LCK}
            />
            <div style={{ marginLeft: 30 }}>
              <InfoField
                label={'Team UID'}
                dataTest={'partner-team-uid'}
                value={locker.teamUID}
              />
            </div>
          </Box>
        </div>
        <div className='lockerManager__details-column--s p-5-tb'>
          <StoreLogo
            lockerId={locker.id}
            logoUrl={locker.logoUrl}
          />
        </div>
      </div>
      <div className='lockerManagerEdit__details-info'>
        <div className='lockerManager__details-column--l'>
          <InfoField
            label={'3 Month Sales'}
            dataTest={'three-month-revenue'}
            value={formatDollarAmount(locker.sales3Month)}
          />
          <InfoField
            label={'Total Fundraising'}
            fieldClass={'mb-0'}
            value={formatDollarAmount(locker.totalFundraising)}
          />
        </div>
        <div className='lockerManager__details-column--l'>
          <InfoField
            label={'Total Sales'}
            dataTest={'total-revenue'}
            value={formatDollarAmount(locker.totalSales)}
          />
          <InfoField
            label={'Discount'}
            dataTest={'discount'}
            value={formatPercentageAmount(locker.discount)}
          />
        </div>
        <div className='lockerManager__details-column--l'>
          <InfoField
            label={'Markup'}
            dataTest={'markup'}
            labelClass={'flex'}
            value={(
              <div className='lockerManagerEdit__details-fundraising'>
                {formatPercentageAmount(locker.markup)}
              </div>
            )}
          />
        </div>
      </div>
    </div>
    <div>
      <hr className='mb-10' />
    </div>
    <div className='lockerManager__details-owner'>
      <div className='lockerManager__details-column--l'>
        <InfoField
          label={'Locker Owner'}
          dataTest={'locker-owner'}
          value={`${locker.firstName} ${locker.lastName}`}
        />
        <InfoField
          label={'E-mail Address'}
          dataTest={'email'}
          value={locker.email}
        />
      </div>
      <div className='lockerManager__details-column--s'>
        <InfoField
          label={'Phone Number'}
          value={locker.phone}
        />
        <InfoField
          label={'Date Created'}
          dataTest={'date-created'}
          value={locker.dateCreated && parseDateNumeric(locker.dateCreated)}
        />
      </div>
    </div>
  </div>
));

LockerManagerQuickViewInfo.propTypes = {
  locker: PropTypes.object.isRequired,
};

export default LockerManagerQuickViewInfo;
