import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import BarcodeReader from 'react-barcode-reader';
import {
  useGetHomefieldApiProductionassemblynewOrdersheaderssearch,
  useGetHomefieldApiProductionassemblynewOrderitemssearchbydecoration,
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkasdecorated,
  useGetHomefieldApiProductionassemblynewActivitytracker,
} from '@api/fulfillment/production-assembly-new';
import { useGetHomefieldApiColors } from '@api/productCatalog/colors';
import {
  ColorDto,
  DecorationMethodEnum,
} from '@api/productCatalog/models';
import {
  ProductionAssemblyItemGroupDto,
  QueriesLogoItemDto,
  QueriesPersonalizationItemDto,
  DecorationStatusEnum,
} from '@api/fulfillment/models';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import {
  productionAssemblyHagUrl,
  productionAssemblyDtgUrl,
  productionAssemblyEmbUrl,
  productionAssemblyHagReviewByOrderUrl,
  productionAssemblyDtgReviewByOrderUrl,
  productionAssemblyEmbReviewByOrderUrl,
  productionAssemblyHagReviewByBarcodeUrl,
  productionAssemblyDtgReviewByBarcodeUrl,
  productionAssemblyEmbReviewByBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import { featureFlags } from '@constants/common';
import * as productionAssemblyActions from '@redux/productionAssembly/actions';
import * as supportActions from '@redux/support/actions';
import * as productCatalogActions from '@redux/productCatalog/actions';
import { RootState } from '@redux/index/reducers';
import {
  swalAlert,
  navigateToPage,
} from '@util/componentHelper';
import { itemNotCheckedInStatuses } from '@util/componentHelpers/productionAssemblyHelper';
import Button from '@sharedComponents/Buttons/Button';
import BackLink from '@sharedComponents/Navigation/BackLink';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import OrderHeader from '@sharedComponents/Production/Review/OrderHeader/OrderHeader';
import ActivityTracker from '@sharedComponents/Production/Review/ActivityTracker';
import ReviewItems from './ReviewItems/ReviewItems';
import GoToOrderInput from '@sharedComponents/Production/Review/GoToOrderInput';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';

interface OwnProps {
  decorationMethod: keyof typeof DecorationMethodEnum;
  productionAssemblyUrl: typeof productionAssemblyHagUrl
  | typeof productionAssemblyDtgUrl
  | typeof productionAssemblyEmbUrl;
  reviewByOrderUrl: typeof productionAssemblyHagReviewByOrderUrl
  | typeof productionAssemblyDtgReviewByOrderUrl
  | typeof productionAssemblyEmbReviewByOrderUrl;
  reviewByBarcodeUrl: typeof productionAssemblyHagReviewByBarcodeUrl
  | typeof productionAssemblyDtgReviewByBarcodeUrl
  | typeof productionAssemblyEmbReviewByBarcodeUrl;
}

interface RouteProps {
  orderNumber: string;
  barcode?: string;
}

const mapStateToProps = ({
  productionAssembly,
  productCatalog,
}: RootState) => ({
  order: productionAssembly.order,
  logoDecorationLocations: productCatalog.logoDecorationLocations,
  personalizationDecorationLocations: productCatalog.personalizationDecorationLocations,
  decorationLocationArtworkSizesList: productCatalog.decorationLocationArtworkSizesList,
  decorationLocationPersonalizationSizesList: productCatalog.decorationLocationPersonalizationSizesList,
});

const mapDispatchToProps = {
  clearLogos: productionAssemblyActions.clearLogos,
  getDecorationLocationArtworkSizes: productCatalogActions.getDecorationLocationArtworkSizes,
  getDecorationLocations: productCatalogActions.getDecorationLocations,
  getDecorationLocationPersonalizationSizes: productCatalogActions.getDecorationLocationPersonalizationSizes,
  fetchShippingOptions: supportActions.fetchShippingOptions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector> & RouteComponentProps<RouteProps>;

const ProductionAssemblyReview = React.memo<Props>(({
  decorationMethod,
  getDecorationLocations,
  getDecorationLocationPersonalizationSizes,
  getDecorationLocationArtworkSizes,
  clearLogos,
  fetchShippingOptions,
  productionAssemblyUrl,
  reviewByOrderUrl,
  reviewByBarcodeUrl,
  logoDecorationLocations,
  personalizationDecorationLocations,
  decorationLocationArtworkSizesList,
  decorationLocationPersonalizationSizesList,
  match: {
    params: {
      orderNumber: orderNumberParam,
      barcode: barcodeParam,
    },
  },
}) => {
  const [
    lookupOrderInput,
    setLookupOrderInput,
  ] = useState('');
  const [
    lookupOrderNumber,
    setLookupOrderNumber,
  ] = useState('');
  const [
    isStatusHistoryShown,
    setIsStatusHistoryShown,
  ] = useState(false);
  const [
    barcodeSearch,
    setBarcodeSearch,
  ] = useState<Nullable<string>>(null);
  const [
    filteredItems,
    setFilteredItems,
  ]
    = useState<ProductionAssemblyItemGroupDto[]>([]);
  const [
    isFilteringItems,
    setIsFilteringItems,
  ] = useState<boolean>(false);
  const [
    colorsDictionary,
    setColorsDictionary,
  ] = useState<Record<string, ColorDto>>({});
  const [
    barcodeEventCode,
    setBarcodeEventCode,
  ] = useState('');

  const barcodeSearchItemRef = useRef<HTMLDivElement>(null);
  const jumpToOrderRef = useRef<HTMLInputElement>(null);

  const {
    data: orderHeader,
    refetch: fetchOrderHeader,
  } = useGetHomefieldApiProductionassemblynewOrdersheaderssearch(
    { searchQuery: orderNumberParam || '' }
  );

  const {
    data: orderItemGroups,
    refetch: fetchOrderItemGroups,
  } = useGetHomefieldApiProductionassemblynewOrderitemssearchbydecoration(
    {
      searchQuery: orderNumberParam || '',
      decorationMethod,
    }
  );

  const {
    data: orderToLookup,
    refetch: searchOrder,
  } = useGetHomefieldApiProductionassemblynewOrdersheaderssearch(
    { searchQuery: lookupOrderNumber },
    { query: { enabled: false } }
  );

  const {
    data: orderItemsToLookup,
    refetch: searchOrderItems,
  } = useGetHomefieldApiProductionassemblynewOrderitemssearchbydecoration(
    {
      searchQuery: lookupOrderNumber || '',
      decorationMethod,
    }
  );

  const {
    isLoading: isLoadingColors,
    isError: isErrorColors,
    data: colors,
  } = useGetHomefieldApiColors({ query: { enabled: true } });

  const { mutateAsync: markOrderItemDecorationDecorated }
  = usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkasdecorated();

  useEffect(() => {
    if (!orderNumberParam) return;

    fetchOrderHeader();
    fetchOrderItemGroups();
  }, [
    orderNumberParam,
    fetchOrderHeader,
    fetchOrderItemGroups,
  ]);

  useEffect(() => {
    if (orderItemGroups?.length) {
      setFilteredItems(orderItemGroups);
    }
  }, [orderItemGroups]);

  useEffect(() => {
    const orderNumber: number | null = orderNumberParam ? Number(orderNumberParam) : null;

    if (!!orderNumber) {
      setIsStatusHistoryShown(false);
    }
  }, [orderNumberParam]);

  useEffect(() => {
    if (isLoadingColors || isErrorColors || !colors?.length) return;

    const newColorsDictionary: Record<string, ColorDto> = {};
    for (const color of (colors || [])) {
      newColorsDictionary[color.id!] = color;
    }
  }, [orderNumberParam]);

  useEffect(() => {
    if (isLoadingColors || isErrorColors || !colors?.length) return;

    const newColorsDictionary: Record<string, ColorDto> = {};
    for (const color of (colors || [])) {
      newColorsDictionary[color.id!] = color;
    }

    setColorsDictionary(newColorsDictionary);
  }, [
    isLoadingColors,
    isErrorColors,
    colors,
  ]);

  const scrollToItem = useCallback(() => {
    const barcodeSearchParam: string | null = barcodeParam ?? null;

    if (!!barcodeSearchParam) {
      setBarcodeSearch(barcodeSearchParam);
    }
  }, [barcodeParam]);

  useEffect(() => {
    if (!barcodeSearch) return;

    if (barcodeSearchItemRef && barcodeSearchItemRef.current) {
      barcodeSearchItemRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [barcodeSearch]);

  useEffect(() => {
    fetchShippingOptions();
    getDecorationLocationPersonalizationSizes();
    getDecorationLocationArtworkSizes();

    getDecorationLocations(decorationTypeEnum.Logo as any);
    getDecorationLocations(decorationTypeEnum.Personalization as any);

    return () => {
      clearLogos();
    };
  }, [
    fetchShippingOptions,
    getDecorationLocationPersonalizationSizes,
    getDecorationLocationArtworkSizes,
    clearLogos,
    getDecorationLocations,
  ]);

  useEffect(() => {
    if (!isStatusHistoryShown) {
      clearLogos();
    }
  }, [
    isStatusHistoryShown,
    clearLogos,
  ]);

  useEffect(() => {
    if (!orderToLookup || !orderItemsToLookup) {
      return;
    }

    const barcodes = orderItemsToLookup.map((i) => Object.values(i.orderItemIdsWithBarcode!)).flat();

    if (barcodes.includes(lookupOrderInput)) {
      navigateToPage(reviewByBarcodeUrl(orderToLookup.orderNumber!, lookupOrderInput));
    } else {
      navigateToPage(reviewByOrderUrl(orderToLookup.orderNumber!));
    }
  }, [
    reviewByBarcodeUrl,
    reviewByOrderUrl,
    orderToLookup,
    orderItemsToLookup,
    lookupOrderInput,
  ]);

  useEffect(() => {
    if (!lookupOrderNumber) return;

    searchOrder();
    searchOrderItems();
  }, [
    lookupOrderNumber,
    searchOrder,
    searchOrderItems,
  ]);

  const lookupOrder = useCallback(async (newSearchInput: string) => {
    const orderParsed = newSearchInput.toLowerCase().startsWith('o')
      ? newSearchInput.substring(1)
      : newSearchInput;

    setLookupOrderInput(newSearchInput);
    setLookupOrderNumber(orderParsed);
  }, []);

  const search = useCallback((orderNumber: string) => {
    if (!orderNumber) {
      swalAlert('Please fill in an Order Number first.');
    } else {
      lookupOrder(orderNumber.toString());
    }
  }, [lookupOrder]);

  const toggleStatusHistory = useCallback(() => {
    setIsStatusHistoryShown(!isStatusHistoryShown);
  }, [isStatusHistoryShown]);

  const filterOrderItems = useCallback((newSearchInputValue) => {
    let newIsFilteringItems;
    let newFilteredItems;

    if (newSearchInputValue === '') {
      newIsFilteringItems = false;
      newFilteredItems = orderItemGroups;
    } else {
      newIsFilteringItems = true;
      newFilteredItems = orderItemGroups?.filter((i) => (
        (i.sku || '').toLowerCase().includes(newSearchInputValue.toLowerCase())
      ));
    }

    setFilteredItems(newFilteredItems || []);
    setIsFilteringItems(newIsFilteringItems);
  }, [orderItemGroups]);

  const handleSearchInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchInputValue = e.target.value.toLowerCase().trim();
    filterOrderItems(newSearchInputValue);
  }, [filterOrderItems]);

  const clearOrderItemsSearch = useCallback(() => {
    filterOrderItems('');
  }, [filterOrderItems]);

  const canDecorateDecoration = useCallback((
    decoration: QueriesLogoItemDto | QueriesPersonalizationItemDto
  ) => (decoration && decoration.status === DecorationStatusEnum.CheckedIn
      // TODO - remove once order assembly page is in use
      || (decorationMethod !== DecorationMethodEnum.HAG
      && decoration.status === DecorationStatusEnum.Initial))
  , [decorationMethod]);

  const decorationsToDecorateIds = useMemo(() => {
    let decorationIds: number[] = [];

    if (!orderItemGroups || !orderHeader || orderHeader.isAssemblyDisabled) {
      return [];
    }

    for (const orderItem of orderItemGroups) {
      if (orderItem.status?.status
        && itemNotCheckedInStatuses.includes(orderItem.status?.status as keyof typeof orderItemStatusEnum)) {
        continue;
      }

      let logoIds: number[] = [];
      let personalizationIds: number[] = [];
      logoIds = (orderItem.logos ?? [])
        .filter(canDecorateDecoration)
        .map((x: QueriesLogoItemDto): number => x.id ?? 0);
      personalizationIds = (orderItem.personalizations ?? [])
        .filter(canDecorateDecoration)
        .map((x: QueriesLogoItemDto): number => x.id ?? 0);

      decorationIds = [
        ...decorationIds,
        ...logoIds,
        ...personalizationIds,
      ];
    }

    return decorationIds;
  }, [
    orderHeader,
    orderItemGroups,
    canDecorateDecoration,
  ]);

  const markAllItemsAsDecorated = useCallback(async () => {
    if (!decorationsToDecorateIds.length) {
      return;
    }

    await markOrderItemDecorationDecorated({
      data: {
        orderNumber: orderHeader?.orderNumber,
        ids: decorationsToDecorateIds,
      },
    });

    fetchOrderItemGroups();
    fetchOrderHeader();
    jumpToOrderRef.current?.focus();
  }, [
    decorationsToDecorateIds,
    orderHeader,
    markOrderItemDecorationDecorated,
    fetchOrderItemGroups,
    fetchOrderHeader,
  ]);

  const handleScan = useCallback(async (data: string) => {
    if (data.toLowerCase() === featureFlags.markAllDecoratedBarcode) {
      await markAllItemsAsDecorated();
    }
  }, [markAllItemsAsDecorated]);

  const handleKeyDown = useCallback(async (e: React.KeyboardEvent) => {
    const newBarcodeEventCode = `${barcodeEventCode}${e.key}`;
    if (newBarcodeEventCode === featureFlags.markAllDecoratedBarcode) {
      await markAllItemsAsDecorated();
      setBarcodeEventCode('');
    } else if (newBarcodeEventCode.length > featureFlags.markAllDecoratedBarcode.length
      || !featureFlags.markAllDecoratedBarcode.startsWith(newBarcodeEventCode)) {
      setBarcodeEventCode(e.key);
    } else {
      setBarcodeEventCode(newBarcodeEventCode);
    }
  }, [
    markAllItemsAsDecorated,
    barcodeEventCode,
  ]);

  const itemsList = isFilteringItems ? filteredItems : orderItemGroups;

  const itemsLabel = decorationMethod === DecorationMethodEnum.EMB ? 'Stitches' : 'Decorations';

  return (
    <>
      <div
        className='container'
        data-test='production-assembly'
      >
        <div className='order-assembly__navigation mb-20'>
          <BackLink
            url={productionAssemblyUrl}
            text={'Back'}
          />
          <div className='flex__row'>
            <GoToOrderInput
              jumpToOrderRef={jumpToOrderRef}
              searchOrder={search}
            />
            <Button
              type={'primary'}
              text={`Mark (${decorationsToDecorateIds.length}) items as decorated`}
              onClick={markAllItemsAsDecorated}
              classes={'ml-20'}
              disabled={!decorationsToDecorateIds.length}
            />
            <BarcodeReader
              onScan={handleScan}
              onKeyDetect={handleKeyDown}
            />
          </div>
        </div>
        <OrderHeader
          order={orderHeader}
          isRequired={(orderItemGroups ?? []).some((oi) => oi.requiredItem) || false}
        />
        {
          featureFlags.activityTrackerEnabled &&
          <ActivityTracker
            orderItems={orderItemGroups}
            decorationMethod={decorationMethod}
            query={useGetHomefieldApiProductionassemblynewActivitytracker}
            itemsLabel={itemsLabel}
            unitsLabel={'Units'}
          />
        }
        <div className='order-assembly__header--row align__center mt-20'>
          <SearchFilter
            onChange={handleSearchInputChange}
            placeholder={'Search'}
            clearSearch={clearOrderItemsSearch}
          />
          <CheckButton
            checked={isStatusHistoryShown}
            onClick={toggleStatusHistory}
            text={'Show Status History on Items'}
            clickableText={true}
            classes={'order-assembly__status-history-checkbox'}
          />
        </div>
        <ReviewItems
          decorationMethod={decorationMethod}
          orderNumber={orderHeader?.orderNumber}
          isAssemblyDisabled={orderHeader?.isAssemblyDisabled}
          items={itemsList}
          barcodeSearch={barcodeParam ?? null}
          logoDecorationLocations={logoDecorationLocations}
          personalizationDecorationLocations={personalizationDecorationLocations}
          decorationLocationArtworkSizesList={decorationLocationArtworkSizesList}
          decorationLocationPersonalizationSizesList={decorationLocationPersonalizationSizesList}
          isStatusHistoryShown={isStatusHistoryShown}
          refreshOrder={fetchOrderHeader}
          refreshOrderItemGroups={fetchOrderItemGroups}
          barcodeSearchItemRef={barcodeSearchItemRef}
          scrollToItem={scrollToItem}
          colorsDictionary={colorsDictionary}
          colors={colors}
        />
      </div>
    </>
  );
});

export default withRouter(connector(ProductionAssemblyReview));
