const ID = 'ARTWORKFILETASKS' as const;

export const UPDATE_ARTWORK_FILE_TASKS = `${ID}/UPDATE_ARTWORK_FILE_TASKS` as const;
export const UPDATE_ARTWORK_FILE_TASK_DETAILS = `${ID}/UPDATE_ARTWORK_FILE_TASK_DETAILS` as const;
export const DELETE_ARTWORK_FILE_TASK = `${ID}/DELETE_ARTWORK_FILE_TASK` as const;
export const CLEAR_ARTWORK_FILE_TASKS = `${ID}/CLEAR_ARTWORK_FILE_TASK` as const;
export const UPDATE_ARTWORK_FILE_TASK_FAIL_REASONS = `${ID}/UPDATE_ARTWORK_FILE_TASK_FAIL_REASONS` as const;
export const UPDATE_ARTWORK_FILE_TASK_NOTES = `${ID}/UPDATE_ARTWORK_FILE_TASK_NOTES` as const;
export const UPDATE_ARTWORK_FILE_TASK_PREVIEW_GENERATION = `${ID}/UPDATE_ARTWORK_FILE_TASK_PREVIEW_GENERATION` as const;
export const CLEAR_ARTWORK_FILE_TASK_PREVIEW_GENERATION = `${ID}/CLEAR_ARTWORK_FILE_TASK_PREVIEW_GENERATION` as const;
export const UPDATE_ARTWORK_FILE_TASK_DECORATIONS = `${ID}/UPDATE_ARTWORK_FILE_TASK_DECORATIONS` as const;
export const UPDATE_ARTWORK_FILE_TASK_SORT = `${ID}/UPDATE_ARTWORK_FILE_TASK_SORT` as const;

export default ID;
