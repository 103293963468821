import React from 'react';
import { useGetHomefieldApiMetaStoresStoreName } from '@api/reportEngine/meta';
import { reportQueryUrl } from '@constants/clientUrls/clientUrls';
import { Link, useParams } from 'react-router-dom';

const ReportsSelections = React.memo(() => {
  const { storeName } = useParams<{ storeName: string; }>();

  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetHomefieldApiMetaStoresStoreName(storeName, {
    query: {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  });

  const reportNames = React.useMemo(() => data?.reportNames ?? [], [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error!!! {error}</div>;
  }

  return (
    <div className='container'>
      <div className='section-container'>
        <h1>Reports for {storeName}</h1><br />
        <div className='rt-table'>
          <div className='rt-tbody'>
            {reportNames.map((reportName) => (
              <div key={reportName} className='rt-tr'>
                <div className='rt-td'>
                  <Link to={reportQueryUrl(storeName, reportName)}>{reportName}</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ReportsSelections;
