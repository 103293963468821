import React from 'react';
import { printRoomUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const PrintRoomNewLink = React.memo(() => (
  <MenuTile
    title={'Print Room 2.0'}
    url={printRoomUrl}
    icon={'file-image-o'}
    isMaterialIcon={false}
  />
));

export default PrintRoomNewLink;
