export const weedingAndMaskingEnum = {
  Initial: 'Initial',
  Done: 'Done',
} as const;

export const imagePreviewStatusEnum = {
  Processing: 'Processing',
  Done: 'Done',
  Failed: 'Failed',
  Undefined: 'Undefined',
} as const;

export const assignmentStatusEnum = {
  Unassigned: 'unassigned',
  InProgress: 'in_progress',
  QC: 'qc',
  Rework: 'rework',
  Production: 'production',
  Priority: 'priority',
} as const;

export const artworkTaskStatusEnum = {
  Assigned: 'Assigned',
  Unassigned: 'Unassigned',
  Rework: 'Rework',
  Priority: 'Priority',
  ProductionRework: 'ProductionRework',
  InQC: 'InQC',
  Completed: 'Completed',
} as const;

export const artworkFileTaskSortOptionsEnum = {
  ShipByDate: 'ShipByDate',
  DecorationMethod: 'DecorationMethod',
  Status: 'Status',
} as const;

export const artworkFileTypeEnum = {
  HagEps: 'HagEps',
  EmbPdf: 'EmbPdf',
  EmbDst: 'EmbDst',
  EmbEmb: 'EmbEmb',
  Png: 'Png',
  HagStrippedEps: 'HagStrippedEps',
} as const;
