import { LogoItem } from '@api/fulfillment/models';
import React, { useCallback } from 'react';

interface OwnProps {
  logo: LogoItem;
  openLogoPreviewModal: (url: string) => void;
}

type Props = OwnProps;

const PrintRoomOrderLogo = React.memo<Props>((
  {
    logo,
    openLogoPreviewModal,
  }
) => {
  const openPreviewModal = useCallback((e) => {
    e.preventDefault();
    openLogoPreviewModal(logo.previewUrl ?? '');
  }, [
    openLogoPreviewModal,
    logo,
  ]);

  return (
    <li>
      <div className='sheet__thumbnails-large-item mt-10 mb-10'>
        <a
          onClick={openPreviewModal}
          className='sheet__thumbnails-large-thumbnail button'
          style={{ backgroundImage: `url("${logo.previewUrl}")` }}
        >
          {
            logo.isCancelled &&
              <div className='sheet__thumbnails-large-thumbnail canceled-thumbnail' />
          }
        </a>
        <span className='sheet__thumbnails-quantity'>{logo.quantity}</span>
        <div className='sheet__thumbnails-title'>
          <div className='sheet__thumbnails-title-location'>
            {logo.decorationLocation}
          </div>
          <div className='sheet__thumbnails-title-size'>
            {logo.decorationWidth}"W x {logo.decorationHeight}"H
          </div>
        </div>
      </div>
    </li>
  );
});

export default PrintRoomOrderLogo;
