import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import CollectionItemsInfoList from '../CollectionItemsInfoList';

class RemoveItemsFromCollection extends PureComponent {
  render() {
    const {
      isOpen,
      collectionId,
      items,
      removeItems,
      closeModal,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        title={'Remove Item(s) from Collection'}
        modalSize={'l'}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Yes, Remove'}
            cancelBtnText={'Cancel'}
            onConfirm={removeItems.bind(null, collectionId, items)}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div className='lockerManager__delete-modal--content'>
          <h3 className='mb-20'>Are you sure you want to remove the following item(s) from this collection?</h3>
          <CollectionItemsInfoList items={items} />
        </div>
      </Modal>
    );
  }
}

RemoveItemsFromCollection.propTypes = {
  collectionId: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeItems: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RemoveItemsFromCollection;
