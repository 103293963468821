import React, { useCallback } from 'react';
import Tag from '@components/shared/Display/Tag';
import MaterialCheckbox from '@components/shared/Inputs/MaterialCheckbox';
import { VariationCatalogProductDetailModel } from '@models/Products/VariationCatalogProductDetailModel';
import { formatDollarAmount } from '@util/numberHelpers';
import { ProductDetailDto } from '@api/productCatalog/models';

interface OwnProps {
  product: Nullable<VariationCatalogProductDetailModel | ProductDetailDto>;
  logoImageUrl: string;
  borderize: boolean;
  isSelected: boolean | undefined;
  pickingEnabled: boolean;
  selectProduct?: (productSku: string, salesChannelId: number, variationId: number) => void;
  orderExistsOnCore: boolean;
}

type Props = OwnProps;

const OrderManagementOrderItemProduct = React.memo<Props>(({
  product,
  logoImageUrl,
  borderize = false,
  isSelected,
  pickingEnabled,
  selectProduct,
  orderExistsOnCore,
}) => {
  const productPreviewImage = {
    backgroundImage: `url("${product?.coloredStyle?.imageUrlFront}")`,
  };
  const styleName = product?.coloredStyle?.style?.name;
  const priceFormatted = product?.coloredStyle?.style &&
    formatDollarAmount(product?.coloredStyle?.style?.price);

  const click = useCallback((e) => {
    e.preventDefault();

    if (selectProduct) {
      selectProduct(
        product!.sku!,
        (product as VariationCatalogProductDetailModel)!.salesChannelId!,
        (product as VariationCatalogProductDetailModel)!.variationId!
      );
    }
  }, [
    product,
    selectProduct,
  ]);

  return (
    <div>
      <label className='redux-form__label'>
        Product SKU {product?.sku}
      </label>
      <div className={`order__item ${borderize ? 'top-border' : 'pt-0 no-border'}`}>
        <div className='order__item-column flex__column justify__center'>
          <div
            className='order__item-thumbnail button'
            style={productPreviewImage}
          />
        </div>
        <div className='order__item-column flex__column justify__center'>
          <span className='order__item--style-name'>{styleName}</span>
          <ul className='order__item-tags'>
            <li>
              <Tag
                label={'Sales Channel ID'}
                value={(product as VariationCatalogProductDetailModel)?.salesChannelId}
              />
            </li>
            <li>
              <Tag
                label={'Size'}
                value={product?.size}
              />
            </li>
            <li>
              <Tag
                label={'Color'}
                value={product?.coloredStyle?.colorA}
              />
            </li>
            {
              orderExistsOnCore &&
              <>
                <li>
                  <Tag
                    label={'Base Price'}
                    value={formatDollarAmount((product as VariationCatalogProductDetailModel)?.basePrice)}
                  />
                </li>
                <li>
                  <Tag
                    label={'Price'}
                    value={formatDollarAmount((product as VariationCatalogProductDetailModel)?.price)}
                  />
                </li>
                <li>
                  <Tag
                    label={'Style Discount Amount'}
                    value={formatDollarAmount((product as VariationCatalogProductDetailModel)?.styleDiscountAmount)}
                  />
                </li>
                <li>
                  <Tag
                    label={'Sales Channel Discount percent'}
                    value={(product as VariationCatalogProductDetailModel)?.salesChannelDiscountPercent + '%'}
                  />
                </li>
                <li>
                  <Tag
                    label={'Fundraising Amount'}
                    value={formatDollarAmount((product as VariationCatalogProductDetailModel)?.fundraisingAmount)}
                  />
                </li>
                <li>
                  <Tag
                    label={'Sales Channel Fundraising Percent'}
                    value={(product as VariationCatalogProductDetailModel)?.salesChannelFundraisingPercent + '%'}
                  />
                </li>
              </>
            }
            {
              !orderExistsOnCore &&
                <li>
                  <Tag
                    label={'Price'}
                    value={priceFormatted}
                  />
                </li>
            }

          </ul>
        </div>
        {
          logoImageUrl &&
          <div className='order-item__logo-container mr-50'>
            <div
              className='order-item__logos--logo'
              style={{ backgroundImage: `url("${logoImageUrl}")` }}
            />
          </div>
        }
        {
          pickingEnabled &&
          <div className='flex__column justify__center'>
            <MaterialCheckbox
              checked={isSelected}
              onClick={click}
              disabled={false}
            />
          </div>
        }
      </div>
    </div>
  );
});

export default OrderManagementOrderItemProduct;
