import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { generalOptions } from '@util/optionsMap';
import { mapToOptionsList } from '@util/mappingHelper';
import InfoField from '@sharedComponents/Display/InfoField';
import Select from '@sharedComponents/Form/Select';

const ThreadColorDropDown = React.memo(({ updateThreadColor }) => {
  const timelineOptionsList = mapToOptionsList({
    list: [
      {
        'color': 'White',
        'name': 'White',
      },
      {
        'color': 'Black',
        'name': 'Black',
      },
    ],
    key: 'color',
    value: 'color',
    name: 'name',
    emptyOption: {
      name: 'Choose thread color',
    },
  });
  const options = generalOptions(timelineOptionsList);

  return (
    <InfoField
      fieldClass={'mb-0'}
      label={'Thread Color'}
      value={(
        <Field
          name={'threadColor'}
          component={Select}
          onChange={updateThreadColor}
        >
          {options}
        </Field>
      )}
    />
  );
});

ThreadColorDropDown.propTypes = {
  updateThreadColor: PropTypes.func.isRequired,
};

export default ThreadColorDropDown;
