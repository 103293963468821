import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown';

class PrintSheetRejectNoteCell extends PureComponent {
  state = {
    reason: '',
    notes: '',
  };

  updateFailNote = (e) => {
    const {
      onFailNoteChange,
      item,
    } = this.props;

    const note = e.target.value;
    this.setState({ notes: note });
    onFailNoteChange(item.artworkTaskId, note);
  };

  updateFailReason = (e) => {
    const {
      onFailReasonChange,
      item,
    } = this.props;

    const reason = e.target.value;
    onFailReasonChange(item.artworkTaskId, reason);
    this.setState({ reason });
  };

  render() {
    const {
      item,
      failReasons,
      isSelected,
    } = this.props;

    const {
      notes,
      reason,
    } = this.state;

    if (!isSelected(item.artworkTaskId)) return <div />;

    return (
      <div className='note-cell'>
        <div className='note-cell-input'>
          <Dropdown
            options={failReasons}
            defaultValue={reason}
            disabled={!failReasons.length}
            classes={'mb-5'}
            onChange={this.updateFailReason}
          />
          <div className='text-field no-padding'>
            <input
              className='w-100'
              value={notes}
              onChange={this.updateFailNote}
              placeholder='Add a note'
            />
          </div>
        </div>
      </div>
    );
  }
}

PrintSheetRejectNoteCell.propTypes = {
  item: PropTypes.object.isRequired,
  failReasons: PropTypes.array,
  onFailNoteChange: PropTypes.func.isRequired,
  onFailReasonChange: PropTypes.func.isRequired,
  failNote: PropTypes.string,
  failReason: PropTypes.string,
  isSelected: PropTypes.func.isRequired,
};

export default PrintSheetRejectNoteCell;
