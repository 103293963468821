/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  DesignViewModel,
  ApiNotFound,
  ApiInternalServerError,
  ApiOkNumber,
  ApiBadRequest,
  CreateDesignRequestModel,
  UpdateDesignLocationRequestModel,
  ApiOkAny
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getDesign = <TData = DesignViewModel>(
    designId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/designs/${designId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetDesignQueryKey = (designId: number,) => [`/homefield/api/designs/${designId}`]

    
export const useGetDesign = <TQueryFnData = AsyncReturnType<typeof getDesign, DesignViewModel>, TError = ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 designId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDesignQueryKey(designId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getDesign<TQueryFnData>(designId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const deleteDesign = <TData = ApiOkNumber>(
    designId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/designs/${designId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteDesign = <TData = AsyncReturnType<typeof deleteDesign,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{designId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {designId: number}, TContext>((props) => {
        const {designId} = props || {};

        return  deleteDesign<TData>(designId,requestOptions)
      }, mutationOptions)
    }
    export const getDesignByColoredStyle = <TData = DesignViewModel>(
    coloredStyleId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/designs/colored-style/${coloredStyleId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetDesignByColoredStyleQueryKey = (coloredStyleId: number,) => [`/homefield/api/designs/colored-style/${coloredStyleId}`]

    
export const useGetDesignByColoredStyle = <TQueryFnData = AsyncReturnType<typeof getDesignByColoredStyle, DesignViewModel>, TError = ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 coloredStyleId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDesignByColoredStyleQueryKey(coloredStyleId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getDesignByColoredStyle<TQueryFnData>(coloredStyleId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getDesignByStyle = <TData = DesignViewModel[]>(
    styleId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/designs/style/${styleId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetDesignByStyleQueryKey = (styleId: number,) => [`/homefield/api/designs/style/${styleId}`]

    
export const useGetDesignByStyle = <TQueryFnData = AsyncReturnType<typeof getDesignByStyle, DesignViewModel[]>, TError = ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 styleId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDesignByStyleQueryKey(styleId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getDesignByStyle<TQueryFnData>(styleId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getDesignBySku = <TData = DesignViewModel[]>(
    sku: string,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/designs/sku/${sku}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetDesignBySkuQueryKey = (sku: string,) => [`/homefield/api/designs/sku/${sku}`]

    
export const useGetDesignBySku = <TQueryFnData = AsyncReturnType<typeof getDesignBySku, DesignViewModel[]>, TError = ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 sku: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDesignBySkuQueryKey(sku);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getDesignBySku<TQueryFnData>(sku, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createDesign = <TData = ApiOkNumber>(
    createDesignRequestModel: CreateDesignRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/designs`, method: 'post',
      data: createDesignRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateDesign = <TData = AsyncReturnType<typeof createDesign,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateDesignRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateDesignRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  createDesign<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const updateDesign = <TData = ApiOkNumber>(
    designLocationId: number,
    updateDesignLocationRequestModel: UpdateDesignLocationRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/designs/location/${designLocationId}`, method: 'put',
      data: updateDesignLocationRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUpdateDesign = <TData = AsyncReturnType<typeof updateDesign,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{designLocationId: number;data: UpdateDesignLocationRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {designLocationId: number;data: UpdateDesignLocationRequestModel}, TContext>((props) => {
        const {designLocationId,data} = props || {};

        return  updateDesign<TData>(designLocationId,data,requestOptions)
      }, mutationOptions)
    }
    export const bulkUpdateDesignByStyleId = <TData = ApiOkAny>(
    styleId: number,
    updateDesignLocationRequestModel: UpdateDesignLocationRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/designs/bulk-update-by-style/${styleId}`, method: 'post',
      data: updateDesignLocationRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useBulkUpdateDesignByStyleId = <TData = AsyncReturnType<typeof bulkUpdateDesignByStyleId,ApiOkAny>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{styleId: number;data: UpdateDesignLocationRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {styleId: number;data: UpdateDesignLocationRequestModel}, TContext>((props) => {
        const {styleId,data} = props || {};

        return  bulkUpdateDesignByStyleId<TData>(styleId,data,requestOptions)
      }, mutationOptions)
    }
    