import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  markAll: () => void;
  tabName: string;
}

type Props = OwnProps;

const MarkAllOrdersAsNotificationSentModal = React.memo<Props>(({
  isOpen,
  closeModal,
  markAll,
  tabName,
}) => (
  <Modal
    title={'Mark All Orders as "Message Sent"'}
    isOpen={isOpen}
    modalSize={'m'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Mark All'}
        cancelBtnText={'Cancel'}
        onConfirm={markAll}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to mark all orders from "{tabName}" as "Message Sent"?</h3>
  </Modal>
));

export default MarkAllOrdersAsNotificationSentModal;
