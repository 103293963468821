import React, { useCallback } from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { HagPrintRoomJobPagedListDto } from '@api/fulfillment/models';

interface OwnProps {
  items: HagPrintRoomJobPagedListDto[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (items: HagPrintRoomJobPagedListDto[]) => void;
}

type Props = OwnProps;

const ExportListModal = React.memo<Props>(({
  items,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleOnSubmit = useCallback(() => {
    onSubmit(items);
    handleCloseModal();
  }, [
    handleCloseModal,
    items,
    onSubmit,
  ]);

  return (
    <Modal
      modalSize={'m'}
      title={'Export List'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Export'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          onConfirm={handleOnSubmit}
        />
      )}
    >
      <label>
            Would you like to export the current view of Recently Printed orders?
      </label>
    </Modal>
  );
});

export default ExportListModal;
