import { PersonalizationItem } from '@api/fulfillment/models';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { Column } from 'react-table-6';
import Table from '@sharedComponents/Table/Table';
import ColorCell from './TableCells/ColorCell';
import { useGetHomefieldApiColors } from '@api/productCatalog/colors';
import { ColorDto } from '@api/productCatalog/models';

interface OwnProps {
  personalizations: PersonalizationItem[] | undefined | null;
}

interface Personalization {
  quantity: number;
  location: string | null | undefined;
  size: string;
  color: ColorDto | undefined;
  outlineColor: ColorDto | undefined;
  personalization: string;
}

type Props = OwnProps;
const ItemsTable = Table<Personalization>();

const PrintRoomOrderPersonalizations =
React.memo<Props>(({ personalizations }) => {
  const [
    personalizationData,
    setPersonalizationData,
  ] = useState<Personalization[]>([]);

  const {
    data: colors,
    refetch: fetchColors,
  } = useGetHomefieldApiColors();

  useEffect(() => {
    fetchColors();
  }, [fetchColors]);

  useEffect(() => {
    const items: Personalization[] = [];
    if (personalizations) {
      for (const personalization of personalizations) {
        const size = `${personalization.decorationWidth ?? 0}"W x ${personalization.decorationHeight ?? 0}"H`;

        const color = colors?.find((x) => x.id === personalization.colorId);
        const outlineColor = colors?.find((x) => x.id === personalization.outlineColorId);

        items.push({
          quantity: personalization.quantity ?? 0,
          location: personalization.decorationLocation,
          size,
          color,
          outlineColor,
          personalization: `${personalization.text ?? ''} ${personalization.number ?? ''}`,
        });
      }
    }

    setPersonalizationData(items);
  }, [
    personalizations,
    colors,
  ]);

  const columns: Array<Column<Personalization>> = useMemo(() => [
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Size',
      accessor: 'size',
    },
    {
      Header: 'Color',
      accessor: 'color',
      minWidth: 50,
      Cell: (cellProps) => {
        const {
          color,
          outlineColor,
        } = cellProps.original;

        return (
          <ColorCell
            color={color}
            outlineColor={outlineColor}
          />
        );
      },
    },
    {
      Header: 'Personalization',
      accessor: 'personalization',
    },
  ], []);

  return (
    <div className='w-100'>
      <ItemsTable
        columns={columns}
        data={personalizationData}
        showPagination={false}
      />
    </div>
  );
});

export default PrintRoomOrderPersonalizations;
