import React, { PureComponent } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { identityRoot } from '@constants/urls/configUrls';
import userManager from '@util/userManager';
import Icon from '@sharedComponents/Icons/Icon';
import { RootState } from '@redux/index/reducers';

const mapStateToProps = ({ oidc }: RootState) => ({
  user: oidc.user,
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

class DrawerLogout extends PureComponent<Props> {
  callLogout = (event: React.MouseEvent): void => {
    event.preventDefault();
    const { user } = this.props;
    const args = {
      // eslint-disable-next-line
      id_token_hint: user.id_token
    };
    sessionStorage.clear();
    userManager.signoutRedirect(args);
  };

  render() {
    const { user = {} } = this.props;
    const visible = !!user;

    if (visible) {
      return (
        <div className='drawer__user'>
          <li data-toggle='body'>
            <a
              className='drawer__user-title'
              href={`${identityRoot}/profile`}
              target='_blank'
              rel='noreferrer'
            >
              {user.profile.name}
            </a>
          </li>
          <span className='drawer__user-email'>
            {user.profile.email}
          </span>
          <li data-toggle='body'>
            <button
              type='button'
              title='Logout'
              className='drawer__user-logout'
              onClick={this.callLogout}
            >
              <Icon materialIcon={'power_settings_new'} />
            </button>
          </li>
        </div>
      );
    }

    return <div />;
  }
}

export default connector(DrawerLogout);
