import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ArtworkTaskAssigneesModal from './ArtworkTaskAssigneesModal';
import ArtworkTaskDetailsNext from './ArtworkTaskDetailsNext';
import ArtworkTaskDetailsPrevious from './ArtworkTaskDetailsPrevious';
import ArtworkTaskDetailsItem from './ArtworkTaskDetailsItem';

class ArtworkTaskDetailsItems extends Component {
  state = {
    modalIsOpen: false,
    modalTask: null,
    checkedTasks: [],
  };

  openModal = (task) => {
    this.setState({
      modalIsOpen: true,
      modalTask: task,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      modalTask: null,
    });
  };

  render() {
    const {
      items,
      handleSelectItem,
      selectedTaskId,
      isCreatingTasks,
      toggleCreateTask,
      checkedTasks,
    } = this.props;

    const {
      modalIsOpen,
      modalTask,
    } = this.state;

    return (
      <div className='mb-10'>
        <ArtworkTaskAssigneesModal
          task={modalTask}
          closeModal={this.closeModal}
          isOpen={modalIsOpen}
        />
        <div className={`${isCreatingTasks ? 'details__list-scroll' : 'details__list'}`}>
          {
            !isCreatingTasks &&
            <ArtworkTaskDetailsPrevious />
          }
          <div className={`${isCreatingTasks ? 'scrollable-content' : 'swiper-container swiper-container-horizontal'}`}>
            <div className={`${isCreatingTasks ? 'scrollable-content-wrapper' : 'swiper-wrapper'}`}>
              {
                items &&
                items.map((task) => (
                  <ArtworkTaskDetailsItem
                    key={task.id || task.key}
                    task={task}
                    handleSelectItem={handleSelectItem}
                    isSelected={selectedTaskId === task.id}
                    openModal={this.openModal}
                    isCreatingTasks={isCreatingTasks}
                    toggleCreateTask={toggleCreateTask}
                    createTaskIsChecked={isCreatingTasks && !checkedTasks.includes(task.id || task.key)}
                  />
                ))
              }
            </div>
          </div>
          {
            !isCreatingTasks &&
            <ArtworkTaskDetailsNext />
          }
        </div>
      </div>
    );
  }
}

ArtworkTaskDetailsItems.propTypes = {
  items: PropTypes.array.isRequired,
  selectedTaskId: PropTypes.number,
  selectedIndex: PropTypes.number,
  size: PropTypes.number.isRequired,
  handleSelectItem: PropTypes.func.isRequired,
  isCreatingTasks: PropTypes.bool,
  toggleCreateTask: PropTypes.func,
  checkedTasks: PropTypes.array,
};

export default ArtworkTaskDetailsItems;
