import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';

class SyncStyleDataTable extends PureComponent {
  renderObject = (data) => (
    Object.keys(data).map((key) => (
      <div
        className='sync-details__table--row'
        key={key}
      >
        <div className='sync-details__table--key'>{`${key}: `}</div>
        <div>{JSON.stringify(data[key])}</div>
      </div>
    ))
  );

  renderArray = (data) => (
    data.map((value, index) => (
      <div
        className='sync-details__table--row'
        key={index}
      >
        <div className='sync-details__table--key'>{value}</div>
      </div>
    ))
  );

  render() {
    const {
      data,
      headerLabel = '',
      shouldWarnWhenNoData = true,
    } = this.props;

    let table = '';

    if (Array.isArray(data)) {
      table = this.renderArray(data);
    } else {
      table = this.renderObject(data);
    }

    return (
      <div className='p-20'>
        <div className='sync-details__table'>
          <div className='sync-details__table--header'>
            {headerLabel}
          </div>
          {
            Object.keys(data).length > 0
              ? table
              : (
                <div>
                  {
                    shouldWarnWhenNoData
                      ? (
                        <div className='flex p-10'>
                          <Icon
                            materialIcon={'warning'}
                            classes={'redux-form__icon orange mr-5'}
                          />
                          <div className='sync-details__warning-text'>
                            Missing Data
                          </div>
                        </div>
                      )
                      : (
                        <div className='sync-details__table--row'>
                          No Data
                        </div>
                      )
                  }
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

SyncStyleDataTable.propTypes = {
  data: PropTypes.object,
  headerLabel: PropTypes.string,
  shouldWarnWhenNoData: PropTypes.bool,
};

export default SyncStyleDataTable;
