import React, { Component } from 'react';
import LogoReviewTable from './LogoReviewTable/LogoReviewTable';

class LogoReview extends Component {
  render() {
    return (
      <div className='container'>
        <div id='logo-review-table'>
          <LogoReviewTable />
        </div>
      </div>
    );
  }
}

export default LogoReview;
