export const restrictionTypeValueEnum = {
  Locker: 0,
  Partner: 1,
  Amount: 2,
} as const;

export const discountTypeEnum = {
  DollarAmount: 0,
  Percentage: 1,
} as const;
