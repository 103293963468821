import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  useGetHomefieldApiVouchersOrdersIdSubmitresults as useGetVoucherOrderSubmitResults,
  usePostHomefieldApiVouchersOrderssubmittoaccounting as useSubmitVendorOrderToAccounting,
} from '@api/financialServices/voucher-homefield';
import {
  VoucherOrderDetailDto,
  VoucherOrderSubmitResultDto,
} from '@api/financialServices/models';
import { materialSwal } from '@util/componentHelper';
import { parseDateTimeNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import SubmitTable from '@sharedComponents/SubmitResults/SubmitTable';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import { Column } from 'react-table-6';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  isOpen: boolean;
  closeModal: () => void;
  refreshVoucherOrder: () => void;
}

type Props = OwnProps;

const VoucherOrderSubmitResultsModal = React.memo<Props>(({
  voucherOrder,
  isOpen,
  closeModal,
  refreshVoucherOrder,
}) => {
  const [
    confirmationModalIsOpen,
    setConfirmationModalIsOpen,
  ] = useState<boolean>(false);

  const {
    data: submitResults,
    refetch: fetchVoucherOrderSubmitResults,
  } = useGetVoucherOrderSubmitResults(voucherOrder.id!);

  const onVendorOrderSubmittedToAccounting = (response: any) => {
    materialSwal('Success', response.message, 'success');
    closeModal();
    refreshVoucherOrder();
  };

  const { mutateAsync: submitVendorOrderToAccounting } = useSubmitVendorOrderToAccounting({
    mutation: {
      onSuccess: onVendorOrderSubmittedToAccounting,
    },
  });

  useEffect(() => {
    fetchVoucherOrderSubmitResults();
  }, [
    fetchVoucherOrderSubmitResults,
    voucherOrder,
  ]);

  const openConfirmationModal = useCallback(() => {
    setConfirmationModalIsOpen(true);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalIsOpen(false);
  }, []);

  const handleModalConfirm = useCallback(async () => {
    await submitVendorOrderToAccounting({
      data: {
        id: voucherOrder.id!,
      },
    });

    fetchVoucherOrderSubmitResults();
    closeConfirmationModal();
  }, [
    submitVendorOrderToAccounting,
    fetchVoucherOrderSubmitResults,
    voucherOrder,
    closeConfirmationModal,
  ]);

  const getColumns = (): Array<Column<VoucherOrderSubmitResultDto>> => [
    {
      Header: 'Date Created',
      accessor: 'dateCreatedUtc',
      minWidth: 140,
      Cell: (cellProps) => <span>{parseDateTimeNumeric(cellProps.original.dateCreatedUtc)}</span>,
    },
    {
      Header: 'Elapsed Time',
      accessor: 'elapsedTime',
      minWidth: 90,
      Cell: (cellProps) => <span>{cellProps.original.elapsedTime || 0} ms</span>,
    },
    {
      Header: 'Message',
      accessor: 'message',
      minWidth: 150,
    },
    {
      Header: 'Status Code',
      accessor: 'statusCode',
    },
    {
      Header: 'User',
      accessor: 'userId',
      Cell: (cellProps) => <span>{cellProps.original.userId || '-'}</span>,
    },
    {
      expander: true,
      Header: 'Details',
      accessor: '',
      width: 200,
      Expander: ({ isExpanded }) => (
        <div>
          {
            isExpanded
              ? (
                <div className='flex button align__center'>
                  <div className='info__label'>Collapse Details</div>
                  <Icon materialIcon={'arrow_drop_up'} />
                </div>
              )
              : (
                <div className='flex button align__center'>
                  <div className='info__label'>Expand Details</div>
                  <Icon materialIcon={'arrow_drop_down'} />
                </div>
              )
          }
        </div>
      ),
    },
  ];

  return (
    <>
      <SimpleConfirmationModal
        isOpen={confirmationModalIsOpen}
        confirm={handleModalConfirm}
        closeModal={closeConfirmationModal}
        title={'Resubmit Voucher Order'}
        confirmationBody={`Are you sure you want to resubmit voucher order with ID ${voucherOrder.id}?`}
        modalStyleProps={{ overlay: { zIndex: 101 } }}
      />
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title={'Submission Results'}
        modalSize={'xl'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            cancelBtnText={'Close'}
            displayConfirmBtn={false}
          />
        )}
      >
        <SubmitTable
          label={'Voucher Order'}
          item={voucherOrder}
          submitEnabled={!voucherOrder.canceledOn}
          submitAction={openConfirmationModal}
          submitResults={submitResults ?? []}
          itemDataProperty={'payload'}
          itemDoneStatusDisplayProperty={'voucherOrderId'}
          columns={getColumns()}
        />
      </Modal>
    </>
  );
});

export default VoucherOrderSubmitResultsModal;
