import React from 'react';
import DropZone, { DropEvent } from 'react-dropzone';

interface OwnProps {
  onDrop: (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => void;
  className?: string;
  accept: string | string[];
  multiple: boolean;
  children: JSX.Element;
}

type Props = OwnProps;

const DropZoneWrapper = React.memo<Props>(({
  onDrop,
  className,
  accept,
  multiple,
  children,
}) => (
  <DropZone
    onDrop={onDrop}
    accept={accept}
    multiple={multiple}
  >
    {
      ({
        getRootProps,
        getInputProps,
      }) => (
        <div
          className={className}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {children}
        </div>
      )
    }
  </DropZone>
));

export default DropZoneWrapper;
