import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchLockerSuggestions } from '@redux/organizations/actions';
import { materialSwal } from '@util/componentHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ChipsAutocompleteInput from '@sharedComponents/Inputs/ChipsAutocompleteInput';
import LockerSuggestion from './LockerSuggestion';

class AssociateLockersModal extends PureComponent {
  state = {
    lockers: [],
  };

  associate = async () => {
    const { associateLockers } = this.props;
    const { lockers } = this.state;

    try {
      await associateLockers(lockers);
      this.onCloseModal();
    } catch (err) {
      materialSwal('Error', err.message, 'error');
    }
  };

  addLockerToList = (locker) => {
    const { lockers } = this.state;

    if (lockers.find((l) => l.id === locker.id)) {
      return;
    }

    this.setState({
      lockers: [
        ...lockers,
        locker,
      ],
    });
  };

  removeLockerFromList = (locker) => {
    this.setState((prevState) => ({
      lockers: prevState.lockers.filter((l) => l.id !== locker.id),
    }));
  };

  onCloseModal = () => {
    const { closeModal } = this.props;

    this.setState({
      lockers: [],
    }, closeModal);
  };

  displayLockerChip = (locker) => (`L${locker.id} - ${locker.teamName}`);

  displayLockerSuggestion = (locker, searchInput) => (
    <LockerSuggestion
      locker={locker}
      searchInput={searchInput}
    />
  );

  fetchLockerSuggestions = (searchInput) => {
    const { dispatch } = this.props;
    dispatch(fetchLockerSuggestions(searchInput));
  };

  render() {
    const {
      isOpen,
      suggestions,
    } = this.props;

    const { lockers } = this.state;

    const isAssociatingEnabled = lockers.length > 0;

    return (
      <Modal
        title={'Associate New Locker(s)'}
        modalHeight={'xl'}
        modalWidth={'m'}
        isOpen={isOpen}
        closeModal={this.onCloseModal}
        enableBodyOverflow={true}
        buttons={(
          <ModalButtons
            confirmBtnText={'Associate'}
            cancelBtnText={'Cancel'}
            onConfirm={this.associate}
            onClose={this.onCloseModal}
            confirmBtnDisabled={!isAssociatingEnabled}
          />
        )}
      >
        <div className='align align--center'>
          <div className='modal__subtitle align-left mb-30'>Search For Lockers To Add</div>
          <div>
            <ChipsAutocompleteInput
              suggestions={suggestions}
              fetchSuggestions={this.fetchLockerSuggestions}
              displaySuggestionText={this.displayLockerSuggestion}
              displayChipText={this.displayLockerChip}
              onClickOnChip={this.removeLockerFromList}
              selectedItems={lockers}
              selectItem={this.addLockerToList}
              placeholder={'Locker ID or Team Name'}
              compareBy={'id'}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

AssociateLockersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  associateLockers: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({ organizations }) => ({
  suggestions: organizations.lockerSuggestions,
});

export default connect(mapStateToProps)(AssociateLockersModal);
