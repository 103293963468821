import React from 'react';
import PropTypes from 'prop-types';
import { fileNameFromUrlWithExtension } from '@util/stringHelpers';

const TypeCell = React.memo(({
  type,
  cutColor,
  files,
  isCutLogo,
}) => {
  let title = type;
  const fileNames = files.map((item, key) => (
    <p key={key}>{fileNameFromUrlWithExtension(item)}</p>
  ));

  if (isCutLogo) {
    title = cutColor ? `Cut - ${cutColor}` : 'Cut';
  }

  return (
    <div>
      <span className='sheet__list-table-title'>{title}</span>
      {fileNames}
    </div>
  );
});

TypeCell.propTypes = {
  type: PropTypes.string.isRequired,
  cutColor: PropTypes.string,
  files: PropTypes.array.isRequired,
  isCutLogo: PropTypes.bool,
};

export default TypeCell;
