import React from 'react';
import { crossSellsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const CrossSellsLink = React.memo(() => (
  <MenuTile
    title={'Cross Sells'}
    url={crossSellsUrl}
    icon={'shop_two'}
  />
));

export default CrossSellsLink;
