import React from 'react';

interface OwnProps {
  children?: JSX.Element | JSX.Element[] | undefined;
}

type Props = OwnProps;

const FilterGroup = React.memo<Props>(({ children }) => (
  <div className='filter-groups'>{children}</div>
));

export default FilterGroup;
