import React from 'react';
import { skuSetupUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const SkuSetupLink = React.memo(() => (
  <MenuTile
    title={'SKU Setup'}
    url={skuSetupUrl}
    icon={'build'}
  />
));

export default SkuSetupLink;
