import React from 'react';

interface OwnProps {
  title: JSX.Element | string;
  classes?: string;
}

type Props = OwnProps;

const SectionTitle = React.memo<Props>(({
  title,
  classes = '',
}) => (
  <div className={`section-header--info ${classes}`}>
    {title}
  </div>
));

export default SectionTitle;
