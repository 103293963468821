import React, {
  useEffect, useState,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchAdminSuggestions } from '@redux/admins/actions';
import { materialSwal } from '@util/componentHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ChipsAutocompleteInput from '@sharedComponents/Inputs/ChipsAutocompleteInput';
import AdminSuggestion from '../LockerManagerDetails/AdminSuggestion';
import MaterialCheckbox from '@components/shared/Inputs/MaterialCheckbox';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AssociateAdminWithLockerModal = (props) => {
  const { claimStorePartnerIds } = useFlags();

  const [
    admins,
    setAdmins,
  ] = useState([]);
  const [
    lockerClaimed,
    setLockerClaimed,
  ] = useState(props.isClaimed);

  useEffect(() => {
    setLockerClaimed(props.isClaimed);
  }, [
    props.isClaimed,
    props.isOpen,
  ]);

  async function associate() {
    const {
      associateAdmins, claimLocker, claimedAt, partnerId,
    } = props;

    try {
      await associateAdmins(admins);

      if (claimStorePartnerIds?.includes(partnerId) && claimedAt === null && lockerClaimed) {
        claimLocker(true);
      }

      onCloseModal();
      materialSwal('Success', 'Admins were successfully assigned', 'success');
    } catch (err) {
      materialSwal('Error', err.message, 'error');
    }
  }

  function addAdminToList(admin) {
    if (admins.find((a) => a.id === admin.id)) {
      return;
    }

    setAdmins((prevAdmins) => ([
      ...prevAdmins,
      admin,
    ]));
  }

  function removeAdminFromList(admin) {
    setAdmins((prevAdmins) => (prevAdmins.filter((l) => l.id !== admin.id)));
  }

  function onCloseModal() {
    const { closeModal } = props;

    setAdmins([]);

    closeModal();
  }

  function handleAdminSuggestions(searchInput) {
    const { dispatch } = props;
    dispatch(fetchAdminSuggestions(searchInput));
  }

  function displayAdminChip(admin) {
    return (
      (admin.firstName || admin.lastName)
        ? `${admin.firstName} ${admin.lastName} (${admin.email})`
        : admin.email
    );
  }

  function displayAdminSuggestion(admin, searchInput) {
    return (
      <AdminSuggestion
        admin={admin}
        searchInput={searchInput}
      />
    );
  }

  function handleClaimedClick() {
    setLockerClaimed((prev) => !prev);
  }

  const {
    isOpen,
    suggestions,
  } = props;

  const isAssociatingEnabled = admins.length > 0;

  return (
    <Modal
      title={'Add Admins to Locker'}
      modalHeight={'l'}
      modalWidth={'m'}
      isOpen={isOpen}
      closeModal={onCloseModal}
      enableBodyOverflow={true}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onConfirm={associate}
          onClose={onCloseModal}
          confirmBtnDisabled={!isAssociatingEnabled}
        />
      )}
    >
      <div className='align align--center'>
        <div className='modal__subtitle align-left mb-30'>Search For Accounts To Add</div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
        }}
        >
          <ChipsAutocompleteInput
            suggestions={suggestions}
            fetchSuggestions={handleAdminSuggestions}
            displaySuggestionText={displayAdminSuggestion}
            displayChipText={displayAdminChip}
            onClickOnChip={removeAdminFromList}
            selectedItems={admins}
            selectItem={addAdminToList}
            placeholder={'Admin E-mail'}
            compareBy={'id'}
          />
          {
            claimStorePartnerIds.includes(props.partnerId) && !props.claimedAt &&
              <div style={{ marginTop: 20 }}>
                <MaterialCheckbox
                  checked={lockerClaimed}
                  onClick={handleClaimedClick}
                  text='Mark Locker Claimed?'
                />
              </div>
          }
        </div>
      </div>
    </Modal>
  );
};

AssociateAdminWithLockerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  associateAdmins: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({
  admins, lockerManager,
}) => ({
  suggestions: admins.adminSuggestions,
  isClaimed: lockerManager.lockerInfo?.claimed,
  claimedAt: lockerManager.lockerInfo?.claimedAt,
  partnerId: lockerManager.lockerInfo?.partnerId,
});

export default connect(mapStateToProps)(AssociateAdminWithLockerModal);
