import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Column } from 'react-table-6';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { TableEnum } from '@constants/enums/tableEnums';
import { adminDetailsUrl } from '@constants/clientUrls/clientUrls';
import { DateRangeEnum } from '@constants/enums/dateRangeEnum';
import { SortDirectionShort } from '@customTypes/table';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import {
  pageSizeOptionsBigTable,
  defaultPageSizeBigTable,
  dropdownSizeL,
} from '@constants/values';
import { Admin } from '@models/Admins/Admin';
import * as adminsActions from '@redux/admins/actions';
import { RootState } from '@redux/index/reducers';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import Link from '@sharedComponents/Navigation/Link';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import MasterDetailContainer from '@sharedComponents/Display/Containers/MasterDetailContainer';
import DateRangeFilter from '@components/OrderManagementTable/DateRangeFilter';
import AdminsQuickView from './AdminsQuickView/AdminsQuickView';

const AdminsTable = Table<any>();

interface AccountExecutive {
  email: string;
  id: number;
}

const mapStateToProps = ({
  admins,
  tableManager,
}: RootState) => ({
  initialPageNumber: tableManager.admins.pageNumber,
  initialPageSize: tableManager.admins.pageSize,
  initialSortColumn: tableManager.admins.sortColumn,
  initialSortDirection: tableManager.admins.sortDirection,
  initialSearchInput: tableManager.admins.searchInput,
  initialDateRangeFilter: tableManager.admins.dateRangeFilter,
  initialAccountExecutivesFilter: tableManager.admins.accountExecutivesFilter,
  totalPages: admins.adminsList.totalPages,
  hasPreviousPage: admins.adminsList.hasPreviousPage,
  hasNextPage: admins.adminsList.hasNextPage,
  queue: admins.adminsList.queue,
  allAccountExecutives: admins.accountExecutives as AccountExecutive[],
});

const mapDispatchToProps = {
  fetchAdmins: adminsActions.fetchAdmins,
  fetchAccountExecutives: adminsActions.fetchAccountExecutives,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Admins = React.memo<Props>(({
  initialPageNumber,
  initialPageSize,
  initialSortColumn,
  initialSortDirection,
  initialSearchInput,
  initialDateRangeFilter,
  initialAccountExecutivesFilter,
  queue,
  totalPages,
  hasNextPage,
  hasPreviousPage,
  allAccountExecutives,
  fetchAccountExecutives,
  fetchAdmins,
}) => {
  const [
    pageNumber,
    setPageNumber,
  ] = useState<number>(initialPageNumber);
  const [
    pageSize,
    setPageSize,
  ] = useState<number>(initialPageSize);
  const [
    sortColumn,
    setSortColumn,
  ] = useState<string>(initialSortColumn);
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionShort>(initialSortDirection as SortDirectionShort);
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>(initialSearchInput);
  const [
    dateRangeFilter,
    setDateRangeFilter,
  ] = useState<keyof DateRangeEnum>(initialDateRangeFilter);
  const [
    accountExecutivesFilter,
    setAccountExecutivesFilter,
  ] = useState<string[]>(initialAccountExecutivesFilter);
  const [
    selectedAdmin,
    setSelectedAdmin,
  ] = useState<Nullable<Admin>>(null);

  useEffect(() => {
    fetchAccountExecutives();
  }, [fetchAccountExecutives]);

  const selectAdmin = useCallback((admin: any) => {
    setSelectedAdmin(admin);
  }, []);

  const unselectAdmin = useCallback(() => {
    setSelectedAdmin(null);
  }, []);

  const search = useCallback(() => {
    unselectAdmin();

    fetchAdmins(
      pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      dateRangeFilter,
      searchInput,
      undefined,
      undefined,
      accountExecutivesFilter
    );
  }, [
    unselectAdmin,
    fetchAdmins,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    dateRangeFilter,
    searchInput,
    accountExecutivesFilter,
  ]);

  useEffect(() => {
    search();
  }, [
    search,
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
  ]);

  const fetchData = useCallback((state: any, instance: any) => {
    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortColumn, sortDirection);

    setPageNumber(newPageNumber + 1);
    setPageSize(newPageSize);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
  }, [
    sortColumn,
    sortDirection,
  ]);

  const filterByDateRange = useCallback((value: string) => {
    setDateRangeFilter(value as keyof DateRangeEnum);
  }, []);

  const filterByAccountExecutives = useCallback((newAccountExecutivesFilter: string[]) => {
    if (newAccountExecutivesFilter) {
      setAccountExecutivesFilter(newAccountExecutivesFilter);
    }
  }, []);

  const clearSearch = useCallback(() => {
    setSearchInput('');
  }, []);

  const onSearch = useCallback((e: any) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const newSearchInput = e.target.value;
    setSearchInput(newSearchInput);
  }, []);

  const getTrProps = useCallback((state, rowInfo) => (
    getSelectableTableRowProps(selectAdmin, rowInfo, selectedAdmin, 'id')
  ), [
    selectAdmin,
    selectedAdmin,
  ]);

  const columns = useMemo(() => {
    let columnsList: Array<Column<any>> = [
      {
        Header: <HeaderCell text={'ID'} />,
        accessor: 'id',
        minWidth: 20,
        sortable: true,
        Cell: (cellProps) => cellProps.value && (
          <Link
            url={adminDetailsUrl(cellProps.value)}
            text={cellProps.value}
          />
        ),
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        minWidth: 40,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        minWidth: 40,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 60,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
    ];

    if (!selectedAdmin) {
      columnsList = [
        ...columnsList,
        {
          Header: 'Phone Number',
          accessor: 'phoneNumber',
          minWidth: 50,
          Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
        },
        {
          Header: 'Role',
          accessor: 'role',
          minWidth: 40,
          Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
        },
        {
          Header: 'Classification',
          accessor: 'classification',
          minWidth: 50,
          Cell: (cellProps) => cellProps && cellProps.value ? <span>{cellProps.value}</span> : 'Unclassified',
        },
        {
          Header: <HeaderCell text={'Account Executive'} />,
          accessor: 'accountManager',
          minWidth: 60,
          Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
        },
      ];
    }

    columnsList = [
      ...columnsList,
      {
        Header: <HeaderCell text={'Signup Date'} />,
        accessor: 'createdAt',
        sortable: true,
        minWidth: 60,
        Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 30,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
    ];

    return columnsList;
  }, [selectedAdmin]);

  const filtersToPreserve = useMemo(() => ({
    searchInput,
    dateRangeFilter,
    accountExecutivesFilter,
  }), [
    searchInput,
    dateRangeFilter,
    accountExecutivesFilter,
  ]);

  return (
    <div className='container'>
      <div className='flex'>
        <div className='table-options w-100'>
          <div className='filter-groups'>
            <SearchFilter
              search={onSearch}
              clearSearch={clearSearch}
              initialValue={initialSearchInput}
            />
            <DateRangeFilter
              filter={filterByDateRange}
              selected={dateRangeFilter}
              defaultValue={initialDateRangeFilter}
            />
            <MultiSelectDropdown<AccountExecutive, 'email'>
              selectedObjects={initialAccountExecutivesFilter}
              objects={allAccountExecutives}
              itemText={'account executives'}
              updateCallback={filterByAccountExecutives}
              textKey={'email'}
              valueKey={'email'}
              size={dropdownSizeL}
              classNames={'margin-left'}
            />
          </div>
        </div>
      </div>

      <MasterDetailContainer
        masterComponent={(
          <AdminsTable
            data={queue}
            columns={columns}
            defaultPageSize={defaultPageSizeBigTable}
            pageSizeOptions={pageSizeOptionsBigTable}
            onFetchData={fetchData}
            totalPages={totalPages}
            getTrProps={getTrProps}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            tableName={'Admins'}
            tableId={TableEnum.admins}
            preserveState={true}
            sortDirEnum={sortDirectionShortEnum}
            initialPageNumber={initialPageNumber}
            initialPageSize={initialPageSize}
            initialSortColumn={initialSortColumn}
            initialSortDirection={initialSortDirection}
            filtersToPreserve={filtersToPreserve}
          />
        )}
        detailComponent={(
          <AdminsQuickView
            admin={selectedAdmin}
            closeDetails={unselectAdmin}
          />
        )}
        showDetailComponent={!!selectedAdmin}
      />
    </div>
  );
});

export default connector(Admins);
