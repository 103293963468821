/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type BatchWarehouseGroupingDateTypeEnum = 'Shipping' | 'Placed';


export const BatchWarehouseGroupingDateTypeEnum = {
  Shipping: 'Shipping' as BatchWarehouseGroupingDateTypeEnum,
  Placed: 'Placed' as BatchWarehouseGroupingDateTypeEnum,
};
