import React from 'react';
import { formatDecorationLocationSize } from '@util/numberHelpers';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { mapToDecorationMethod } from '@util/decorationMethodHelper';
import {
  SSAPIDecorationMethod,
  DecorationMethod,
} from '@customTypes/enum';

interface OwnProps {
  decorationSizeWidth: number;
  decorationSizeHeight: number;
  decorationMethod: string;
  isCutLogo: boolean;
  cutColor: string;
  approvedDecorationMethods: string[] | undefined;
}

type Props = OwnProps;

const ArtworkTaskDetailsItemIcons = React.memo<Props>(({
  decorationSizeWidth,
  decorationSizeHeight,
  decorationMethod,
  isCutLogo,
  cutColor,
  approvedDecorationMethods,
}) => {
  let cutIcon = <div />;

  if (isCutLogo) {
    cutIcon = (
      <span className='artwork-item__btn'>
        <Icon fontAwesomeIcon={'scissors'} />
        {cutColor}
      </span>
    );
  }

  const decorationMethods = (approvedDecorationMethods as SSAPIDecorationMethod[])?.map(mapToDecorationMethod);

  const logoApprovedText = decorationMethods?.length > 0
    ? `Logo is approved for: ${decorationMethods?.join(', ')}`
    : 'Logo is not approved for any decoration method yet';

  return (
    <div className='artwork-item__info'>
      <div className='flex'>
        <span>
          {decorationMethod}
        </span>
        {
          decorationMethods
          && !decorationMethods?.includes(decorationMethod as DecorationMethod | null)
          && (
            <MaterialTooltip
              tooltipText={logoApprovedText}
              placement='bottom'
              contentClasses={'flex'}
            >
              <span className='warning-msg'>
                <Icon
                  materialIcon={'warning'}
                  classes={'ml-5 orange'}
                />
              </span>
            </MaterialTooltip>
          )
        }
      </div>
      {cutIcon}
      <span>
        {formatDecorationLocationSize(decorationSizeWidth, decorationSizeHeight)}
      </span>
    </div>
  );
});

export default ArtworkTaskDetailsItemIcons;
