import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { Column } from 'react-table-6';
import { BackorderDto } from '@api/fulfillment/models';
import {
  markOrdersAsNotificationSent,
  markAllOrdersAsNotificationSent,
} from '@APICalls/backorderManagement/actions';
import {
  pageSizeOptionsBigTable,
  defaultPageSizeBigTable,
} from '@constants/values';
import {
  TableEnum,
  BackorderManagementTabEnum,
} from '@constants/enums/tableEnums';
import { SortDirectionLong } from '@customTypes/table';
import * as backorderManagementActions from '@redux/backorderManagement/actions';
import { RootState } from '@redux/index/reducers';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { materialSwal } from '@util/componentHelper';
import Table from '@sharedComponents/Table/Table';
import BackorderManagementQuickView from '../BackorderManagementQuickView/BackorderManagementQuickView';
import MarkOrdersAsNotificationSentModal from '../BackorderManagementModals/MarkOrdersAsNotificationSentModal';
import MarkAllOrdersAsNotificationSentModal from '../BackorderManagementModals/MarkAllOrdersAsNotificationSentModal';

const OrdersTable = Table<BackorderDto>();

enum BulkActionsEnum {
  MarkOrdersAsNotificationSent = 'MarkOrdersAsNotificationSent',
}

const bulkActions = [
  {
    key: 1,
    value: BulkActionsEnum.MarkOrdersAsNotificationSent,
    name: 'Mark as "Message Sent"',
  },
];

interface OwnProps {
  selectedTab: BackorderManagementTabEnum;
  searchInput: string;
  getColumns: () => Array<Column<BackorderDto>>;
}

const mapStateToProps = ({
  backorderManagement,
  tableManager,
}: RootState) => ({
  orders: backorderManagement.currentQueue.queue as BackorderDto[],
  totalPages: backorderManagement.currentQueue.totalPages,
  totalCount: backorderManagement.currentQueue.totalCount,
  hasPreviousPage: backorderManagement.currentQueue.hasPreviousPage,
  hasNextPage: backorderManagement.currentQueue.hasNextPage,
  initialPageNumber: tableManager.backorderManagement.pageNumber,
  initialPageSize: tableManager.backorderManagement.pageSize,
  initialSortColumn: tableManager.backorderManagement.sortColumn,
  initialSortDirection: tableManager.backorderManagement.sortDirection,
});

const mapDispatchToProps = {
  fetchOrdersTable: backorderManagementActions.fetchOrdersTable,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const PreviouslyNotifiedTab = React.memo<Props>(({
  orders,
  hasNextPage,
  hasPreviousPage,
  totalPages,
  totalCount,
  getColumns,
  fetchOrdersTable,
  searchInput,
  selectedTab,
  initialPageNumber,
  initialPageSize,
  initialSortColumn,
  initialSortDirection,
}) => {
  const [
    pageNumber,
    setPageNumber,
  ] = useState<number>(initialPageNumber);
  const [
    pageSize,
    setPageSize,
  ] = useState<number>(initialPageSize);
  const [
    sortColumn,
    setSortColumn,
  ] = useState<string>(initialSortColumn);
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionLong>(initialSortDirection as SortDirectionLong);

  const [
    selectedOrder,
    setSelectedOrder,
  ] = useState<Nullable<BackorderDto>>(null);
  const [
    selectedOrders,
    setSelectedOrders,
  ] = useState<BackorderDto[]>([]);
  const [
    isPageSelected,
    setIsPageSelected,
  ] = useState<boolean>(false);
  const [
    isTableSelected,
    setIsTableSelected,
  ] = useState<boolean>(false);

  const [
    markOrdersAsNotificationSentModalIsOpen,
    setMarkOrdersAsNotificationSentModalIsOpen,
  ] = useState<boolean>(false);

  const [
    markAllOrdersAsNotificationSentModalIsOpen,
    setMarkAllOrdersAsNotificationSentModalIsOpen,
  ] = useState<boolean>(false);

  const search = useCallback(() => {
    fetchOrdersTable(
      pageNumber,
      pageSize,
      searchInput,
      sortColumn,
      sortDirection,
      true,
      false
    );
  }, [
    fetchOrdersTable,
    pageNumber,
    pageSize,
    searchInput,
    sortColumn,
    sortDirection,
  ]);

  useEffect(() => {
    search();
  }, [
    pageNumber,
    pageSize,
    searchInput,
    sortColumn,
    sortDirection,
    search,
  ]);

  const fetchData = useCallback((state: any, instance: any) => {
    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortColumn, sortDirection);

    setPageNumber(newPageNumber + 1);
    setPageSize(newPageSize);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
  }, [
    sortColumn,
    sortDirection,
  ]);

  const updateSelection = useCallback((
    newSelectedItems: BackorderDto[],
    newIsPageSelected: boolean,
    newIsTableSelected: boolean
  ) => {
    setSelectedOrders(newSelectedItems);
    setIsPageSelected(newIsPageSelected);
    setIsTableSelected(newIsTableSelected);
  }, []);

  const openMarkOrdersAsNotificationSentModal = useCallback(() => {
    setMarkOrdersAsNotificationSentModalIsOpen(true);
  }, []);

  const closeMarkOrdersAsNotificationSentModal = useCallback(() => {
    setMarkOrdersAsNotificationSentModalIsOpen(false);
  }, []);

  const openMarkAllOrdersAsNotificationSentModal = useCallback(() => {
    setMarkAllOrdersAsNotificationSentModalIsOpen(true);
  }, []);

  const closeMarkAllOrdersAsNotificationSentModal = useCallback(() => {
    setMarkAllOrdersAsNotificationSentModalIsOpen(false);
  }, []);

  const onBulkMarkOrdersAsNotificationSent = useCallback(() => {
    if (!isTableSelected) {
      openMarkOrdersAsNotificationSentModal();
    } else {
      openMarkAllOrdersAsNotificationSentModal();
    }
  }, [
    isTableSelected,
    openMarkOrdersAsNotificationSentModal,
    openMarkAllOrdersAsNotificationSentModal,
  ]);

  const onSelectBulkAction = useCallback((selectedBulkAction: string) => {
    switch (selectedBulkAction) {
      case BulkActionsEnum.MarkOrdersAsNotificationSent:
        onBulkMarkOrdersAsNotificationSent();
        break;
    }
  }, [onBulkMarkOrdersAsNotificationSent]);

  const selectOrder = useCallback((newSelectedOrder: BackorderDto) => {
    setSelectedOrder(newSelectedOrder);
  }, []);

  const unselectOrder = useCallback(() => {
    setSelectedOrder(null);
  }, []);

  const handleMarkOrdersAsNotificationSent = useCallback(async () => {
    const res = await markOrdersAsNotificationSent(selectedOrders);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      search();
      closeMarkOrdersAsNotificationSentModal();
      updateSelection([], false, false);
    }
  }, [
    selectedOrders,
    search,
    closeMarkOrdersAsNotificationSentModal,
    updateSelection,
  ]);

  const handleMarkAllOrdersAsNotificationSent = useCallback(async () => {
    const res = await markAllOrdersAsNotificationSent(true, null);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      closeMarkAllOrdersAsNotificationSentModal();
      updateSelection([], false, false);
      search();
    }
  }, [
    search,
    closeMarkAllOrdersAsNotificationSentModal,
    updateSelection,
  ]);

  const getTrProps = useCallback((state: any, rowInfo: any) => (
    getSelectableTableRowProps(selectOrder, rowInfo, selectedOrder, 'orderNumber')
  ), [
    selectOrder,
    selectedOrder,
  ]);

  const filtersToPreserve = useMemo(() => ({
    searchInput,
    selectedTab,
  }), [
    searchInput,
    selectedTab,
  ]);

  return (
    <div className='container'>
      <MarkOrdersAsNotificationSentModal
        isOpen={markOrdersAsNotificationSentModalIsOpen}
        orders={selectedOrders}
        markOrders={handleMarkOrdersAsNotificationSent}
        closeModal={closeMarkOrdersAsNotificationSentModal}
      />
      <MarkAllOrdersAsNotificationSentModal
        isOpen={markAllOrdersAsNotificationSentModalIsOpen}
        markAll={handleMarkAllOrdersAsNotificationSent}
        closeModal={closeMarkAllOrdersAsNotificationSentModal}
        tabName={'Previously Notified'}
      />

      <div className='master-detail'>
        <div className='lockerManager__master'>
          <div className='sheet'>
            <OrdersTable
              data={orders}
              columns={getColumns()}
              onFetchData={fetchData}
              totalPages={totalPages}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              defaultPageSize={defaultPageSizeBigTable}
              pageSizeOptions={pageSizeOptionsBigTable}
              getTrProps={getTrProps}

              tableName={'Previously Notified'}
              selectable={true}
              selectPredicateOrKey={'orderNumber'}
              updateSelection={updateSelection}
              selectedData={selectedOrders}
              isPageSelected={isPageSelected}
              selectWholeTableEnabled={true}
              isTableSelected={isTableSelected}
              totalCount={totalCount}
              bulkActionsList={bulkActions}
              onSelectBulkAction={onSelectBulkAction}

              tableId={TableEnum.backorderManagement}
              preserveState={true}
              sortDirEnum={sortDirectionEnum}
              initialPageNumber={initialPageNumber}
              initialPageSize={initialPageSize}
              initialSortColumn={initialSortColumn}
              initialSortDirection={initialSortDirection}
              filtersToPreserve={filtersToPreserve}
            />
          </div>
        </div>
        {
          selectedOrder &&
          <BackorderManagementQuickView
            order={selectedOrder}
            closeDetails={unselectOrder}
            previouslyNotified={true}
            discontinued={false}
            automatedNotificationsEnabled={false}
            manualNotificationsEnabled={true}
          />
        }
      </div>
    </div>
  );
});

export default connector(PreviouslyNotifiedTab);
