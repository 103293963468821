import React from 'react';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import squadLocker from '@assets/layout/drawer/squadlocker.png';
import {
  adminsUrl,
  advancedOptionsUrl,
  artVectorizationUrl,
  backorderManagementUrl,
  bulkActionsUrl,
  couponsUrl,
  fundraisingPayoutsUrl,
  lockerManagerUrl,
  logoReviewUrl,
  lookupEntitiesUrl,
  onHandInventoryUrl,
  orderSearchUrl,
  organizationsUrl,
  printFilesUrl,
  productionAssemblyUrl,
  productionUrl,
  productCatalogUrl,
  rosterManagerUrl,
  skuSetupUrl,
  vendorOrdersUrl,
  llSchedulingUrl,
  voucherOrdersUrl,
  warehouseSchedulingUrl,
  printRoomUrl,
  merchandisingUrl,
} from '@constants/clientUrls/clientUrls';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import Image from '@sharedComponents/Display/Image';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import DrawerPanel from './DrawerPanel';
import DrawerPanelMaterial from './DrawerPanelMaterial';
import DrawerLogout from './DrawerLogout';
import featureFlags from '@constants/featureFlags';

type Props = RouteComponentProps;

const Drawer = React.memo(({ location }: Props) => {
  if (!location) return null;

  return (
    <div className='drawer'>
      <DrawerLogout />
      <ul className='drawer__list'>
        <PermissionHandler permissions={PermissionsEnum.AdminsVisit}>
          <DrawerPanelMaterial
            name={'Admins'}
            materialIcon={'people'}
            linkUrl={adminsUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.AdvancedOptionsVisit}>
          <DrawerPanelMaterial
            name={'Advanced Options'}
            materialIcon={'settings_applications'}
            linkUrl={advancedOptionsUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.BackorderManagementVisit}>
          <DrawerPanelMaterial
            name={'Backorder Management'}
            materialIcon={'email'}
            linkUrl={backorderManagementUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.BulkActionsVisit}>
          <DrawerPanelMaterial
            name={'Bulk Actions'}
            materialIcon={'upload'}
            linkUrl={bulkActionsUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.CouponsVisit}>
          <DrawerPanelMaterial
            name={'Coupons'}
            materialIcon={'card_giftcard'}
            linkUrl={couponsUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.FundraisingPayoutsVisit}>
          <DrawerPanelMaterial
            name={'Fundraising Payouts'}
            materialIcon={'account_balance'}
            linkUrl={fundraisingPayoutsUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.LockerManagerVisit}>
          <DrawerPanel
            name={'Locker Manager'}
            fontAwesomeClass={'microchip'}
            linkUrl={lockerManagerUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.LogoReviewVisit}>
          <DrawerPanel
            name={'Logo Review'}
            fontAwesomeClass={'eye'}
            linkUrl={logoReviewUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.LookupEntitiesVisit}>
          <DrawerPanelMaterial
            name={'Lookup Entities'}
            materialIcon={'image_search'}
            linkUrl={lookupEntitiesUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.LookupEntitiesVisit}>
          <DrawerPanelMaterial
            name={'Merchandising'}
            materialIcon={'checkroom'}
            linkUrl={merchandisingUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.OnHandInventoryVisit}>
          <DrawerPanel
            name={'On-Hand Inventory'}
            fontAwesomeClass={'list'}
            linkUrl={onHandInventoryUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.OrderManagementVisit}>
          <DrawerPanel
            name={'Order Management'}
            fontAwesomeClass={'search'}
            linkUrl={orderSearchUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.OrganizationsVisit}>
          <DrawerPanelMaterial
            name={'Organizations'}
            materialIcon={'business'}
            linkUrl={organizationsUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.PrintRoomVisit}>
          <DrawerPanel
            name={'Print Room 2.0'}
            fontAwesomeClass={'file-photo-o'}
            linkUrl={printRoomUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.ProductCatalogVisit}>
          <DrawerPanelMaterial
            name={'Product Catalog'}
            materialIcon={'collections_bookmark'}
            linkUrl={productCatalogUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.ProductionArtworkVisit}>
          <DrawerPanelMaterial
            name={'Production Artwork'}
            materialIcon={'palette'}
            linkUrl={artVectorizationUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.ProductionOverviewVisit}>
          <DrawerPanelMaterial
            name={'Production 2.0'}
            materialIcon={'how_to_vote'}
            linkUrl={productionUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.RosterManagerVisit}>
          <DrawerPanelMaterial
            name={'Roster Manager'}
            materialIcon={'format_list_numbered_rtl'}
            linkUrl={rosterManagerUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.SchedulingVisit}>
          <DrawerPanel
            name={'Scheduling 2.0'}
            fontAwesomeClass={'book'}
            linkUrl={warehouseSchedulingUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        {
          false && // TURN OFF FOR NOW, 2023-08-02
          featureFlags.lineLevelSchedulingEnabled &&
          <PermissionHandler permissions={PermissionsEnum.SchedulingVisit}>
            <DrawerPanel
              name={'LL Scheduling'}
              fontAwesomeClass={'book'}
              linkUrl={llSchedulingUrl}
              currentLocation={location?.pathname}
            />
          </PermissionHandler>
        }

        <PermissionHandler permissions={PermissionsEnum.SkuSetupVisit}>
          <DrawerPanelMaterial
            name={'SKU Setup'}
            materialIcon={'build'}
            linkUrl={skuSetupUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>

        <PermissionHandler permissions={PermissionsEnum.VendorOrdersVisit}>
          <DrawerPanelMaterial
            name={'Vendor Order Management'}
            materialIcon={'storefront'}
            linkUrl={vendorOrdersUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>
        <PermissionHandler permissions={PermissionsEnum.VoucherOrdersVisit}>
          <DrawerPanel
            name={'Voucher Orders'}
            fontAwesomeClass={'ticket'}
            linkUrl={voucherOrdersUrl}
            currentLocation={location?.pathname}
          />
        </PermissionHandler>
      </ul>
      <div className='drawer__logo-container'>
        <Image
          url={squadLocker}
          classes={'drawer__logo'}
        />
      </div>
    </div>
  );
});

export default withRouter(Drawer);
