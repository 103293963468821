import React from 'react';
import PropTypes from 'prop-types';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import Icon from '@sharedComponents/Icons/Icon';

const SortColumnTitle = React.memo(({
  text,
  columnName,
  sortColumn,
  sortDirection,
  sort,
  customClass = '',
}) => (
  <div
    className={`flex__row button align__center align__center--horizontal ${customClass}`}
    onClick={sort}
  >
    <span>{text}</span>
    {
      sortColumn === columnName &&
        <span className='margin margin--left-s'>
          {
            sortDirection === sortDirectionShortEnum.Asc
              ? (
                <Icon
                  materialIcon={'arrow_downward'}
                  classes={'sort'}
                />
              )
              : (
                <Icon
                  materialIcon={'arrow_upward'}
                  classes={'sort'}
                />
              )
          }
        </span>
    }
  </div>
));

SortColumnTitle.propTypes = {
  text: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
  sortColumn: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  sort: PropTypes.func.isRequired,
  customClass: PropTypes.string,
};

export default SortColumnTitle;
