import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { reset } from 'redux-form';
import {
  addParentCategory,
  editParentCategory,
  deleteParentCategory,
} from '@APICalls/parentCategory/actions';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { parentCategoryForm } from '@constants/reduxForms';
import { getParentCategories } from '@redux/productCatalog/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import ParentCategoryAddModal from './ParentCategoryModals/ParentCategoryAddModal';
import ParentCategoryEditModal from './ParentCategoryModals/ParentCategoryEditModal';
import ParentCategoryDeleteModal from './ParentCategoryModals/ParentCategoryDeleteModal';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

const ParentCategoriesTable = Table();

class ParentCategories extends PureComponent {
  state = {
    addParentCategoryModalIsOpened: false,
    editParentCategoryModalIsOpened: false,
    deleteParentCategoryModalIsOpened: false,
    selectedParentCategory: null,
    filteredData: this.props.queue,
  };

  componentDidMount() {
    this.fetchParentCategories();
  }

  componentDidUpdate(oldProps) {
    const { queue } = this.props;

    if (oldProps.queue !== queue) {
      this.setState(() => ({ filteredData: this.filterData() }));
    }
  }

  getColumns = () => [
    {
      Header: 'ID',
      accessor: 'id',
      minWidth: 20,
      sortable: true,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Created',
      accessor: 'created',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Updated',
      accessor: 'updated',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
    },
    {
      Header: 'Code',
      accessor: 'code',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Actions',
      accessor: '',
      minWidth: 40,
      resizable: false,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <ShowMoreActionsButton
          actions={[
            {
              text: 'Edit',
              action: this.openEditParentCategoryModal.bind(null, cellProps.value),
              isVisible: true,
            },
            {
              text: 'Delete',
              action: this.openDeleteParentCategoryModal.bind(null, cellProps.value),
              isDangerous: true,
              isVisible: true,
            },
          ]}
        />
      ),
    },
  ];

  fetchParentCategories = () => {
    const { dispatch } = this.props;

    dispatch(getParentCategories());
  };

  filterData = (searchInput) => {
    const search = (searchInput || '').toLowerCase();
    const { queue } = this.props;

    if (search === '') {
      return queue;
    }

    return queue.filter((parentCategory) => parentCategory.name.toLowerCase().includes(search));
  };

  clearSearch = () => {
    this.setState(() => ({ filteredData: this.filterData('') }));
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({ filteredData: this.filterData(searchInput) }));
  };

  addParentCategory = async (form) => {
    const result = await addParentCategory(form);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeAddParentCategoryModal();
      this.fetchParentCategories();
    }
  };

  openAddParentCategoryModal = () => {
    this.setState(() => ({ addParentCategoryModalIsOpened: true }));
  };

  editParentCategory = async (form) => {
    const result = await editParentCategory(form);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeEditParentCategoryModal();
      this.fetchParentCategories();
    }
  };

  deleteParentCategory = async (categoryId) => {
    const result = await deleteParentCategory(categoryId);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteParentCategoryModal();
      this.fetchParentCategories();
    }
  };

  closeAddParentCategoryModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(parentCategoryForm));
    this.setState(() => ({ addParentCategoryModalIsOpened: false }));
  };

  openEditParentCategoryModal = (category) => {
    this.setState(() => ({
      selectedParentCategory: category,
      editParentCategoryModalIsOpened: true,
    }));
  };

  closeEditParentCategoryModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(parentCategoryForm));
    this.setState(() => ({
      editParentCategoryModalIsOpened: false,
      selectedParentCategory: null,
    }));
  };

  openDeleteParentCategoryModal = (category) => {
    this.setState(() => ({
      selectedParentCategory: category,
      deleteParentCategoryModalIsOpened: true,
    }));
  };

  closeDeleteParentCategoryModal = () => {
    this.setState(() => ({
      deleteParentCategoryModalIsOpened: false,
      selectedParentCategory: null,
    }));
  };

  render() {
    const {
      addParentCategoryModalIsOpened,
      editParentCategoryModalIsOpened,
      deleteParentCategoryModalIsOpened,
      selectedParentCategory,
      filteredData,
    } = this.state;
    const initialValues = !selectedParentCategory ? {} : selectedParentCategory;

    return (
      <div className='container'>
        <ParentCategoryAddModal
          isOpen={addParentCategoryModalIsOpened}
          closeModal={this.closeAddParentCategoryModal}
          onSubmit={this.addParentCategory}
        />

        <ParentCategoryEditModal
          isOpen={editParentCategoryModalIsOpened}
          closeModal={this.closeEditParentCategoryModal}
          onSubmit={this.editParentCategory}
          initialValues={initialValues}
        />

        <ParentCategoryDeleteModal
          isOpen={deleteParentCategoryModalIsOpened}
          closeModal={this.closeDeleteParentCategoryModal}
          deleteParentCategory={this.deleteParentCategory}
          parentCategory={initialValues}
        />

        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add Parent Category'}
              onClick={this.openAddParentCategoryModal}
              classes={'flood-colors__add-btn ml-20'}
            />
          </div>
        </div>

        <div className='sheet'>
          <ParentCategoriesTable
            data={filteredData}
            columns={this.getColumns()}
            showPagination={false}
          />
        </div>
      </div>
    );
  }
}
ParentCategories.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  queue: productCatalog.parentCategories,
});

export default connect(mapStateToProps)(ParentCategories);
