import React from 'react';
import {
  productionAssemblyDtgUrl,
  productionAssemblyDtgReviewByOrderUrl,
  productionAssemblyDtgReviewByBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import ProductionAssemblyReview from '@components/Production/Shared/ProductionAssemblyReview/ProductionAssemblyReview';

const ProductionAssemblyDtg = React.memo(() => (
  <ProductionAssemblyReview
    decorationMethod={decorationMethodEnum.DTG}
    reviewByOrderUrl={productionAssemblyDtgReviewByOrderUrl}
    reviewByBarcodeUrl={productionAssemblyDtgReviewByBarcodeUrl}
    productionAssemblyUrl={productionAssemblyDtgUrl}
  />
));

export default ProductionAssemblyDtg;
