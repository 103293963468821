import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  tooltipText: JSX.Element | string;
  classes?: string;
}

type Props = OwnProps;

const WarningIconWithTooltip = React.memo<Props>(({
  tooltipText,
  classes = '',
}) => (
  <div className={`material-icons-container flex ${classes}`}>
    <div className='position-absolute'>
      <div className='tooltip-container'>
        <div className='warning-msg'>
          <Icon
            materialIcon={'warning'}
            classes={'cursor-pointer'}
          />
        </div>
        <div className='tooltip-text'>{tooltipText}</div>
      </div>
    </div>
  </div>
));

export default WarningIconWithTooltip;
