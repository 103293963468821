import React from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import Icon from '@sharedComponents/Icons/Icon';

const LogoCard = React.memo(({
  logo,
  isReviewed,
}) => (
  <div className='logo-review__tasks-submit__logo'>
    <div className='logo-review__tasks-submit__logo--image'>
      <img src={`${lockerMgrS3Logos}/${logo.logoUrl}`} />
    </div>
    <div className='logo-review__tasks-submit__logo--labels'>
      {
        isReviewed && logo.allowedDecorationMethods.EMB &&
        <span className='logo-review__task__details--reviewed__decorations-item'>{decorationMethodEnum.EMB}</span>
      }
      {
        isReviewed && logo.allowedDecorationMethods.HAG &&
        <span className='logo-review__task__details--reviewed__decorations-item'>{decorationMethodEnum.HAG}</span>
      }
      {
        isReviewed && logo.allowedDecorationMethods.DTG &&
        <span className='logo-review__task__details--reviewed__decorations-item'>{decorationMethodEnum.DTG}</span>
      }
      {
        isReviewed && logo.allowedDecorationMethods.DIP &&
        <span className='logo-review__task__details--reviewed__decorations-item'>{decorationMethodEnum.DIP}</span>
      }
      {
        isReviewed && !logo.isApproved &&
        <span className='logo-review__tasks-submit__logo--labels--unapproved'>
          <Icon materialIcon={'warning'} />
        </span>
      }
    </div>
  </div>
));

LogoCard.propTypes = {
  logo: PropTypes.object.isRequired,
  isReviewed: PropTypes.bool.isRequired,
};

export default LogoCard;
