import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { featureFlags } from '@constants/common';
import { getPersonalizationTypeOptions } from '@constants/options/options';
import {
  decorationTypeEnum,
  personalizationItemTypeEnum,
} from '@constants/enums/decorationEnums';
import {
  getLayoutDetails,
  fetchLayoutTable,
} from '@redux/layouts/actions';
import { generalOptions } from '@util/optionsMap';
import InfoField from '@sharedComponents/Display/InfoField';
import LayoutInfo from '@sharedComponents/CustomItems/CustomizeItem/LayoutInfo';
import Select from '@sharedComponents/Form/Select';
import LayoutsSelect from '@sharedComponents/Layouts/LayoutsSelect';

const personalizationTypeOptions = generalOptions(getPersonalizationTypeOptions());

class CustomItemConfigurationSection extends PureComponent {
  fetchLayoutSuggestions = async (searchInput) => {
    const {
      dispatch,
      styleId,
    } = this.props;

    await dispatch(fetchLayoutTable(1, 10, searchInput, null, null, styleId));
  };

  updateLayout = async (layout) => {
    const {
      updateDecorations,
      createLogo,
      createPersonalization,
      allDecorationLocations,
      selectLayout,
    } = this.props;

    const layoutDetails = await getLayoutDetails(layout.id);

    const decorations = [];
    for (const logo of layoutDetails.logos) {
      const location = allDecorationLocations.find((l) => l.id === logo.locationId);
      decorations.push(createLogo(
        location.code,
        logo.decorationHeight,
        logo.decorationWidth
      ));
    }

    for (const personalization of layoutDetails.personalizations) {
      const location = allDecorationLocations.find((l) => l.id === personalization.locationId);
      decorations.push(createPersonalization(
        location.code,
        personalization.personalizationType,
        personalization.personalizationColorType,
        personalization.decorationHeight,
        personalization.required
      ));
    }

    updateDecorations(decorations);
    selectLayout(layoutDetails);
  };

  updatePersonalizationType = (e) => {
    const personalizationType = e.target.value;
    this.updatePersonalizationRequirements(personalizationType);
  };

  updatePersonalizationRequirements = (personalizationType) => {
    const {
      decorations,
      updateDecorations,
    } = this.props;

    const updatedDecorations = [];

    for (const decoration of decorations) {
      if (decoration.type === decorationTypeEnum.Personalization) {
        const newDecoration = { ...decoration };
        if (personalizationType === personalizationItemTypeEnum.Uniform) {
          newDecoration.required = true;
        } else if (personalizationType === personalizationItemTypeEnum.Standard) {
          newDecoration.required = false;
        }
        updatedDecorations.push(newDecoration);
      } else {
        updatedDecorations.push(decoration);
      }
    }

    updateDecorations(updatedDecorations);
  };

  render() {
    const { selectedLayout } = this.props;

    return (
      <div className='lockerManagerEdit__custom-items--details mb-40'>
        <div className='redux-form__row'>
          <div className='redux-form__column--size-m pr-20'>
            <div className='lockerManagerEdit__custom-items--info'>
              <div className='lockerManagerEdit__custom-items--images'>
                <div className='info__field'>
                  <div className='info__label'>Select Location Layout</div>
                  <LayoutsSelect
                    layout={selectedLayout}
                    updateLayout={this.updateLayout}
                    fetchSuggestions={this.fetchLayoutSuggestions}
                  />
                </div>
              </div>
            </div>
          </div>

          {
            featureFlags.optionalPersonalizationEnabled &&
            <div className='redux-form__column--size-m pr-20'>
              <div className='text-field'>
                <InfoField
                  fieldClass={'mb-0'}
                  label={'Personalization Configuration'}
                  value={(
                    <Field
                      name={'personalizationItemType'}
                      component={Select}
                      onChange={this.updatePersonalizationType}
                    >
                      {personalizationTypeOptions}
                    </Field>
                  )}
                />
              </div>
            </div>
          }
        </div>

        {selectedLayout && <LayoutInfo layout={selectedLayout} />}
      </div>
    );
  }
}

CustomItemConfigurationSection.propTypes = {
  change: PropTypes.func,
  styleId: PropTypes.number.isRequired,
  allDecorationLocations: PropTypes.arrayOf(PropTypes.object),
  createLogo: PropTypes.func.isRequired,
  createPersonalization: PropTypes.func.isRequired,
  updateDecorations: PropTypes.func.isRequired,
  decorations: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    decorationMethod: PropTypes.string,
    decorationLocation: PropTypes.string,
    decorationHeight: PropTypes.string,
    personalizationType: PropTypes.string,
    personalizationColorType: PropTypes.string,
    required: PropTypes.bool,
    colorId: PropTypes.number,
    outlineColorId: PropTypes.number,
  })),
  selectedLayout: PropTypes.shape({
    id: PropTypes.number.isRequired,
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    sport: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  selectLayout: PropTypes.func.isRequired,
  unselectLayout: PropTypes.func.isRequired,
};

export default connect()(CustomItemConfigurationSection);
