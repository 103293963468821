import * as actionTypes from './types';
import emailCampaignsUrls from '@constants/sqdApiUrls/emailCampaignsUrls';
import { makeApiCall } from '@util/apiHelper';
import { slServicesApi } from '../../sqdApis';

export const updateEmailTemplates = (data) => ({
  type: actionTypes.UPDATE_EMAIL_TEMPLATES,
  data,
});

export const fetchEmailTemplates = () => async (dispatch) => {
  const call = slServicesApi.get(emailCampaignsUrls.emailTemplates, { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateEmailTemplates(res));
  }
};
