import React from 'react';
import PropTypes from 'prop-types';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { artworkTaskStatusEnum } from '@constants/enums/artworkEnums';
import iconEye from '@assets/icon_hidden.svg';
import ShowMoreTooltip from '@sharedComponents/Display/ShowMoreTooltip';
import Icon from '@sharedComponents/Icons/Icon';

const ArtworkTaskDetailsInfo = React.memo(({
  task,
  productionNote,
  logoColoredStyleColors,
}) => {
  const {
    assignedOn,
    assignedBy,
    assignedToName,
    productionReadyOn,
    artworkTaskArchivation,
    status,
  } = task;

  const assignedTime = assignedOn ? `(${parseDateTimeNumeric(assignedOn)})` : '';
  const sentToQCTime = status && status.created ? `(${parseDateTimeNumeric(status.created)})` : '';
  const showSentToQC = status && status.created && status === artworkTaskStatusEnum.InQC;
  const sentToProduction = productionReadyOn ? `Sent to production: ${parseDateTimeNumeric(productionReadyOn)}` : '';
  const showSentToProduction = productionReadyOn && status === artworkTaskStatusEnum.Rework;
  const archivedOn = artworkTaskArchivation ? parseDateTimeNumeric(artworkTaskArchivation.created) : '';

  return (
    <>
      <div className='status-row'>
        <div>
          {
            assignedBy && (
              <div>
                <span>
                  <strong>Assigned by:</strong>
                </span>
                {` ${assignedBy} ${assignedTime}`}
              </div>
            )
          }
        </div>
        <div>
          {
            status && (
              <div>
                <span className={`task__status--${status.status.toLowerCase()}`}>
                  <strong>{status.label}</strong>
                </span>
                {
                  showSentToQC
                    ? `: ${assignedToName} ${sentToQCTime}`
                    : assignedOn
                      ? `: ${assignedToName} ${assignedTime}` : ''
                }
                <div>
                  {showSentToProduction && `${sentToProduction}`}
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className='status-row'>
        {
          artworkTaskArchivation && (
            <div>
              <span className='task__status--archived'>
                <strong>Archived:</strong>
              </span>
              {` ${artworkTaskArchivation.user} (${archivedOn})`}
            </div>
          )
        }
      </div>
      <div className='status-row'>
        {
          productionNote && (
            <div>
              <span>
                <strong>Production Note</strong>
              </span>
              <br />
              {productionNote}
            </div>
          )
        }
      </div>
      <div className='status-row'>
        {
          logoColoredStyleColors.length > 0 && (
            <div>
              <div className='flex'>
                <span>
                  <strong>Garment Colors:</strong>
                </span>
                <ShowMoreTooltip
                  tooltipText={logoColoredStyleColors.map((color) => ((
                    <div key={color.colorGroup}>
                      {color.colorGroup}
                    </div>
                  )))}
                  placement={'top'}
                >
                  <span>
                    <Icon
                      className='product-catalog__icon-eye'
                      iconUrl={iconEye}
                    />
                  </span>
                </ShowMoreTooltip>
              </div>
              <br />
            </div>
          )
        }
      </div>
    </>
  );
});

ArtworkTaskDetailsInfo.propTypes = {
  task: PropTypes.shape({
    status: PropTypes.shape({
      label: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      created: PropTypes.string,
    }),
    statusOn: PropTypes.string,
    statusBy: PropTypes.string,
    assignedOn: PropTypes.string,
    assignedBy: PropTypes.string,
    assignedToName: PropTypes.string,
    created: PropTypes.string,
    productionReadyOn: PropTypes.string,
    artworkTaskArchivation: PropTypes.shape({
      created: PropTypes.string,
      user: PropTypes.string,
    }),
  }),
  productionNote: PropTypes.string,
  logoColoredStyleColors: PropTypes.array,
};

export default ArtworkTaskDetailsInfo;
