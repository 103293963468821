import React from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import Icon from '@sharedComponents/Icons/Icon';

const LockerLogos = React.memo(({
  locker,
  openModalWithImage,
}) => {
  const lockerLogos = locker.lockerLogos || [];

  return (
    <div className='logo-review__task__locker-logos-container'>
      <div className='logo-review__task__options-title mb-20'>
        Other Logos In This Locker ({lockerLogos.length})
      </div>
      <div className='logo-review__task__logo-container'>
        <div className='logo-review__task__logo-list custom-scrollbar--horizontal'>
          {
            lockerLogos.length === 0
              ? <div>No logos found</div>
              : (
                lockerLogos.map((logo) => (
                  <div
                    key={logo.id}
                    className='logo-review__logo-preview'
                  >
                    <div
                      className='order__item-thumbnail button logoList__logopreview--logo p-0'
                      style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logo.image}")` }}
                    >
                      {
                        logo.image &&
                      <div
                        className='details__item-overlay'
                        onClick={openModalWithImage.bind(null, logo.image)}
                      >
                        <Icon
                          materialIcon={'search'}
                          classes={'details__item-preview-icon'}
                        />
                      </div>
                      }
                    </div>
                  </div>
                ))
              )
          }
        </div>
      </div>
    </div>
  );
});

LockerLogos.propTypes = {
  locker: PropTypes.object.isRequired,
  openModalWithImage: PropTypes.func.isRequired,
};

export default LockerLogos;
