import React from 'react';
import { CollectionDetailsViewModel } from '@api/squadlockerServices/models';
import { parseDateNumeric } from '@util/dateHandler';
import InfoField from '@sharedComponents/Display/InfoField';
import ActivityStatus from '@sharedComponents/Display/ActivityStatus';

interface OwnProps {
  collection: CollectionDetailsViewModel;
}

type Props = OwnProps;

const HeaderDetails = React.memo<Props>(({ collection }) => (
  <div className='lockerManagerEdit__details-container admins__header'>
    <div className='lockerManagerEdit__details-info w-100 mr-40'>
      <div className='lockerManagerEdit__details-column--l'>
        <InfoField
          label={'Status'}
          value={<ActivityStatus isDisabled={collection.disabled} />}
        />
        <InfoField
          label={'Date Created'}
          value={collection.createdAt && parseDateNumeric(collection.createdAt)}
        />
      </div>
    </div>
  </div>
));

export default HeaderDetails;
