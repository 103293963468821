import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import DecorationLocationArtworkSizeForm from '../DecorationLocationArtworkSizeForm';
import { decorationLocationArtworkSizeForm } from '@constants/reduxForms';

const DecorationLocationArtworkSizeEditModal = (props) => {
  const {
    isOpen,
    editDecorationLocationArtworkSize,
    initialValues,
    decorationLocations,
    closeModal,
  } = props;

  return (
    <Modal
      title={'Edit Artwork Size'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Edit'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={decorationLocationArtworkSizeForm}
        />
      )}
    >
      <DecorationLocationArtworkSizeForm
        onSubmit={editDecorationLocationArtworkSize}
        initialValues={initialValues}
        decorationLocations={decorationLocations}
      />
    </Modal>
  );
};

DecorationLocationArtworkSizeEditModal.propTypes = {
  editDecorationLocationArtworkSize: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DecorationLocationArtworkSizeEditModal;
