import React from 'react';
import PropTypes from 'prop-types';
import { formatDollarAmount } from '@util/numberHelpers';
import Icon from '@sharedComponents/Icons/Icon';
import Label from '@sharedComponents/Display/Label';
import Tag from '@sharedComponents/Display/Tag';

const CustomItemHeaderDetailsSection = React.memo(({
  selectedColoredStyle,
  isVFI,
  openChangeSku,
}) => {
  const {
    name,
    code,
    category,
    gender,
    decorationMethod,
    price,
  } = selectedColoredStyle.style;

  return (
    <div className='order__item-container mb-40'>
      <div className='order__item.no-border'>
        <div className='align__center'>
          <div
            className='order__item-thumbnail button logoList__logopreview--logo lockerManager__details-header--image'
            style={{ backgroundImage: `url("${selectedColoredStyle.imageUrlFront}")` }}
          />
          <div className='order__item-column'>
            <div className='flex'>
              <span className='order__item--style-name'>{name}</span>
              {
                isVFI &&
                <Label
                  text={'VFI'}
                  type={'lynch'}
                />
              }
            </div>
            <ul className='order__item-tags'>
              <li>
                <span className='lockerManagerEdit__custom-items--sku align__center'>
                  <span>SKU: {code || ''}</span>
                  <Icon
                    materialIcon={'edit'}
                    onClick={openChangeSku}
                  />
                </span>
              </li>
              <li>
                <Tag
                  label={'Category'}
                  value={category ? category.name : '-'}
                />
              </li>
              <li>
                <Tag
                  label={'Gender'}
                  value={gender || '-'}
                />
              </li>
              <li>
                <Tag
                  label={'Decoration'}
                  value={decorationMethod || '-'}
                />
              </li>
              <li>
                <Tag
                  label={'Base Price'}
                  value={price ? formatDollarAmount(price) : '-'}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

CustomItemHeaderDetailsSection.propTypes = {
  isVFI: PropTypes.bool.isRequired,
  openChangeSku: PropTypes.func.isRequired,
  unhidingItemDisabled: PropTypes.bool.isRequired,
  styleProducts: PropTypes.arrayOf(PropTypes.shape({
    basePrice: PropTypes.number,
  })).isRequired,
  selectedColoredStyle: PropTypes.shape({
    imageUrlFront: PropTypes.string,
    personalizationColors: PropTypes.arrayOf(PropTypes.shape({
      floodColorId: PropTypes.number.isRequired,
      custom: PropTypes.bool.isRequired,
    })),
    style: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      category: PropTypes.shape({
        name: PropTypes.string,
      }),
      gender: PropTypes.string,
      discount: PropTypes.number,
      vendorFulfilledItem: PropTypes.bool,
      decorationMethod: PropTypes.string,
      decorationLocations: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        location: PropTypes.string,
      })),
    }),
  }).isRequired,
};

export default CustomItemHeaderDetailsSection;
