import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class RemoveLogosModal extends PureComponent {
  render() {
    const {
      isOpen,
      removeLogo,
      closeModal,
    } = this.props;

    return (
      <Modal
        title={'Remove Item'}
        modalSize={'m'}
        isOpen={isOpen}
        closeModal={closeModal}
        modalClass={'lockerManager__delete-modal'}
        buttons={(
          <ModalButtons
            confirmBtnText={'Yes, Remove'}
            cancelBtnText={'Cancel'}
            onConfirm={removeLogo}
            onClose={closeModal}
          />
        )}
      >
        <div className='lockerManager__delete-modal--content'>
          <h3>Are you sure you want to remove selected logo from this item?</h3>
        </div>
      </Modal>
    );
  }
}

RemoveLogosModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  removeLogo: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RemoveLogosModal;
