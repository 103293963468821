import React from 'react';
import { WrappedFieldProps } from 'redux-form';

interface OwnProps {
  type: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  callbackRef?: () => null;
}

type Props = OwnProps & WrappedFieldProps;

const Input = (props: Props): JSX.Element => {
  const {
    type,
    placeholder = '',
    required = false,
    disabled = false,
    className = 'w-100',
    autoFocus = false,
    callbackRef,
    meta: {
      touched = false,
      error = '',
    },
  } = props;

  const inputProps = props.input || {};
  const hasError = touched && error && (error.length > 0);

  return (
    <div className='text-field w-100'>
      <input
        type={type}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        className={`redux-form__input ${className} ${hasError ? 'error' : ''}`}
        ref={callbackRef}
        {...inputProps}
        autoFocus={autoFocus}
      />
      {
        hasError &&
        <div className='redux-form__error--field'>
          {error.map((e: string, index: number) => <div key={index}>{e}</div>)}
        </div>
      }
    </div>
  );
};

export default Input;
