import React from 'react';
import { collectionForm } from '@constants/reduxForms';
import { CollectionFormData } from '@models/forms/Collections/CollectionFormData';
import { LockerProduct } from '@models/LockerManager/LockerProduct';
import { groupCollectionItems } from '@util/componentHelpers/collectionsHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import CollectionForm from '../CollectionForm';

interface OwnProps {
  isOpen: boolean;
  addCollection: (collectionForm: CollectionFormData) => void;
  closeModal: () => void;
  lockerId: number;
  items: LockerProduct[];
}

type Props = OwnProps;

const AddCollectionModal = React.memo<Props>(({
  isOpen,
  addCollection,
  closeModal,
  lockerId,
  items = [],
}) => (
  <Modal
    title={'New Collection'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'l'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Save Collection'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={collectionForm}
      />
    )}
  >
    <CollectionForm
      onSubmit={addCollection}
      initialValues={{
        lockerId,
        itemGroups: groupCollectionItems(items),
        required: false,
        bundled: false,
        rosterTeamIds: [],
      }}
    />
  </Modal>
));

export default AddCollectionModal;
