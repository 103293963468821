import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import FormError from './FormError';

const HookformFileInput = ({ id, name, control, placeholder, error, required, autoFocus = false, className = '', label = '' }) => {
  const { field } = useController({
    control,
    name,
  });

  const handleChange = useCallback((e) => {
    field.onChange(e.target.files);
  }, [field]);

  return (
    <div style={{ marginBottom: '1rem' }}>
      <div className='lockerEdit__information-fields--column'>
        {label && <p>{label}</p>}
        <div className='text-field w-100' style={{ marginBottom: 0 }}>
          <input
            style={{ width: '100%' }}
            type='file'
            onChange={handleChange}
            required={required}
            id={id}
            placeholder={placeholder}
            autoFocus={autoFocus}
            className={`redux-form__input ${className} ${error ? 'error' : ''}`}
          />
        </div>
      </div>
      {
        error &&
        <FormError
          error={error}
          fieldError={true}
        />
      }
    </div>
  );
};

export default HookformFileInput;
