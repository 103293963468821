import React, { useCallback } from 'react';
import { ImageDto } from '@api/orders/models';
import {
  OrderManagementTempItem,
  OrderManagementTempItemLogo,
  OrderManagementTempItemPersonalization,
} from '@redux/orderManagement/models';
import OrderManagementDetailsItemExpandImage from '@components/OrderManagement/OrderManagementDetails/OrderManagementDetailsItemExpandImage';
import Personalization from '@components/OrderManagement/OrderManagementDetails/Personalization';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import PersonalizationPlaceholder from './OrderItemsContent/PersonalizationPlaceholder';
import OrderManagementDetailsItemExpandFile from '@components/OrderManagement/OrderManagementDetails/OrderManagementDetailsItemExpandFile';

interface OwnProps {
  item: OrderManagementTempItem;
  openImageModal: (imageUrl: string) => void;
  addPersonalization: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  editPersonalization: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  removePersonalization: (
    item: OrderManagementTempItem,
    personalization: OrderManagementTempItemPersonalization
  ) => void;
  colorsDictionary: any;
}

type Props = OwnProps;

const OrderItemExpand = React.memo(({
  item,
  openImageModal,
  addPersonalization,
  editPersonalization,
  removePersonalization,
  colorsDictionary,
}: Props) => {
  const personalizationOnClick = useCallback((personalization) => {
    editPersonalization(item, personalization);
  }, [
    editPersonalization,
    item,
  ]);

  const renderImage = useCallback((
    image: ImageDto,
    index: number
  ) => (
    <OrderManagementDetailsItemExpandImage
      key={index}
      openModal={openImageModal}
      imageUrl={image.src!}
      imageTitle={image.description!}
    />
  ), [openImageModal]);

  const renderLogo = useCallback((
    logo: OrderManagementTempItemLogo,
    index: number
  ) => (
    <OrderManagementDetailsItemExpandFile
      key={index}
      openModal={openImageModal}
      file={logo}
    />
  ), [openImageModal]);

  const handleRemovePersonalization = useCallback((personalization) => {
    removePersonalization(item, personalization);
  }, [
    removePersonalization,
    item,
  ]);

  const renderPersonalization = useCallback((
    personalization: OrderManagementTempItemPersonalization,
    index: number
  ) => {
    if (personalization.name || personalization.number) {
      return (
        <div
          className='new-order__order-items__personalization-container'
          key={index}
        >
          <MaterialTooltip
            tooltipText={'Click to edit personalisation'}
            placement={'top'}
          >
            <Personalization
              personalization={personalization}
              colorsDictionary={colorsDictionary}
              personalizationOnClick={personalizationOnClick}
              classes={'ml-10'}
              showColorTooltip={false}
            />
          </MaterialTooltip>
          <MaterialTooltip
            tooltipText={'Click to remove'}
            placement={'top'}
          >
            <div
              className='new-order__order-items__icon--remove'
              onClick={() => handleRemovePersonalization(personalization)}
            />
          </MaterialTooltip>
        </div>
      );
    }

    return (
      <PersonalizationPlaceholder
        key={index}
        item={item}
        personalization={personalization}
        addPersonalization={addPersonalization}
      />
    );
  }, [
    item,
    addPersonalization,
    colorsDictionary,
    personalizationOnClick,
    handleRemovePersonalization,
  ]);

  const checkPersonalization = useCallback(
    (p: OrderManagementTempItemPersonalization) => p.name !== undefined || p.number !== undefined,
    []
  );

  const renderAllPersonalizations = useCallback(() => item.custom
      || item.personalizations.find(checkPersonalization) === undefined,
  [
    checkPersonalization,
    item.custom,
    item.personalizations,
  ]);

  return (
    <div className='order__expand new-order__order-items__expand'>
      <div className='order__files'>
        {item.images.map(renderImage)}
        <hr className='vertical decorationList__preview-delimiter' />
        {item.logos?.map(renderLogo)}
        {
          renderAllPersonalizations()
            ? item.personalizations.map(renderPersonalization)
            : item.personalizations.filter(checkPersonalization).map(renderPersonalization)
        }
      </div>
    </div>
  );
});

export default OrderItemExpand;
