import React from 'react';
import PropTypes from 'prop-types';
import PrintSheetItemPersonalizations from './PrintSheetItemPersonalizations';
import PrintSheetItemThumbnails from './PrintSheetItemThumbnails';
import PrintSheetItemIndicator from './PrintSheetItemIndicator';
import PrintSheetItemWeedingAndMasking from './PrintSheetItemWeedingAndMasking';
import { parseDateNumeric } from '@util/dateHandler';
import rushIcon from '@assets/icon_rush.svg';
import expressIcon from '@assets/icon_express-production.svg';

const PrintSheetItem = ({
  sheetItem,
  orderNumber,
  openPreviewModal,
  barcode,
  renderPrintSheet,
  sheetId,
  colorsDictionary,
  colors,
}) => {
  const due = parseDateNumeric(sheetItem.shipDate);
  let personalizations = '';

  if (sheetItem.personalizations && sheetItem.personalizations.length > 0) {
    personalizations = (
      <PrintSheetItemPersonalizations
        orderDeleted={sheetItem.isDeleted}
        personalizations={sheetItem.personalizations}
        colorsDictionary={colorsDictionary}
      />
    );
  }

  let isActive = '';
  if (orderNumber && orderNumber === sheetItem.orderNumber.toString()) {
    isActive = 'is-active';
  }

  const canceled = sheetItem.isDeleted ? 'canceled-text' : '';

  const applyBorderIfPrinting = () => {
    if (renderPrintSheet) {
      return {
        border: '1px solid #57626b',
        padding: '4px',
      };
    }

    return {};
  };

  return (
    <div
      className='sheet__item'
      style={applyBorderIfPrinting()}
    >
      <ul className='sheet__title'>
        <li>
          <PrintSheetItemIndicator indicator={sheetItem.indicator} />
          <span className={canceled}>
            {
              sheetItem.organizationId
                ? `O${sheetItem.orderNumber} - ORG${sheetItem.organizationId}`
                : `O${sheetItem.orderNumber} - L${sheetItem.lockerId}`
            }
          </span>
        </li>
        <li className={`margin-left ${sheetItem.isRushed ? 'is-rushed' : ''}`} >
          {
            sheetItem.isRushed &&
            <i
              className='fa'
              style={{
                width: '18px',
                height: '15px',
                backgroundImage: `url("${rushIcon}")`,
              }}
            />
          }
          {
            sheetItem.isExpressProduction &&
            <i
              className='fa'
              style={{
                width: '22px',
                height: '15px',
                backgroundImage: `url("${expressIcon}")`,
              }}
            />
          }
          Due: {due}
        </li>
      </ul>
      <div className={`sheet__content ${isActive}`}>
        <PrintSheetItemThumbnails
          callForModal={openPreviewModal}
          thumbnails={sheetItem.logoPrintItems}
          cutFiles={sheetItem.logoCutItems}
          barcode={barcode}
          renderPrintSheet={renderPrintSheet}
          orderDeleted={sheetItem.isDeleted}
          colorsDictionary={colorsDictionary}
          colors={colors}
        />
        {personalizations}
      </div>
      <PrintSheetItemWeedingAndMasking
        weedingAndMaskingInfo={sheetItem.printAssemblyDetails}
        orderNumber={sheetItem.orderNumber}
        sheetId={sheetId}
        status={sheetItem.orderPrintStatus}
      />
    </div>
  );
};

PrintSheetItem.propTypes = {
  sheetId: PropTypes.number.isRequired,
  sheetItem: PropTypes.shape({
    shipDate: PropTypes.string.isRequired,
    personalizations: PropTypes.array,
    isDeleted: PropTypes.bool,
    orderNumber: PropTypes.number.isRequired,
    indicator: PropTypes.string.isRequired,
    lockerId: PropTypes.number,
    organizationId: PropTypes.number,
    isRushed: PropTypes.bool,
    isExpressProduction: PropTypes.bool,
    orderPrintStatus: PropTypes.string.isRequired,
    printAssemblyDetails: PropTypes.object.isRequired,
    logoPrintItems: PropTypes.array.isRequired,
    logoCutItems: PropTypes.array.isRequired,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  orderNumber: PropTypes.number,
  openPreviewModal: PropTypes.func.isRequired,
  barcode: PropTypes.string,
  renderPrintSheet: PropTypes.bool,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    hexValue: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    brightness: PropTypes.string.isRequired,
    threadValue: PropTypes.string,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default PrintSheetItem;
