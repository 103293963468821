import React, {
  useCallback,
  useState,
} from 'react';
import {
  minInputLengthForSuggestions, throttleDelay,
} from '@constants/values';
import { discountTypeEnum } from '@constants/enums/couponEnums';
import { findCouponSuggestions } from '@redux/orderManagement/actions';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import InfoField from '@sharedComponents/Display/InfoField';
import Icon from '@sharedComponents/Icons/Icon';
import { couponTypeEnum } from '@constants/enums/orderEnums';
import CouponSuggestion from '@components/OrderManagement/OrderManagementDetails/CouponSuggestion';
import { CouponSuggestionsViewModel } from '@api/squadlockerServices/models';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: (couponCode: string, couponType: string, couponValue: number, couponAppliesToShipping: boolean) => void;
}

type Props = OwnProps;

const AddCouponModal = React.memo<Props>(({
  isOpen,
  closeModal,
  onConfirm,
}) => {
  const [
    couponFound,
    setCouponFound,
  ] = useState<boolean>(false);
  const [
    couponFoundMessage,
    setCouponFoundMessage,
  ] = useState<string>('');
  const [
    couponExtraMessage,
    setCouponExtraMessage,
  ] = useState<string>('');
  const [
    couponAppliesToShippingMessage,
    setCouponAppliesToShippingMessage,
  ] = useState<string>('');
  const [
    showSuggestions,
    setShowSuggestions,
  ] = useState<boolean>(false);
  const [
    isInputActive,
    setIsInputActive,
  ] = useState<boolean>(false);
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>('');
  const [
    throttledCall,
    setThrottledCall,
  ] = useState<any>(null);
  const [
    couponSuggestions,
    setCouponSuggestions,
  ] = useState<Array<CouponSuggestionsViewModel>>([]);
  const [
    coupons,
    setCoupons,
  ] = useState<Array<CouponSuggestionsViewModel>>([]);
  const [
    couponCode,
    setCouponCode,
  ] = useState<Nullable<string>>(null);
  const [
    couponType,
    setCouponType,
  ] = useState<Nullable<string>>(null);
  const [
    couponValue,
    setCouponValue,
  ] = useState<Nullable<number>>(null);
  const [
    couponAppliesToShipping,
    setCouponAppliesToShipping,
  ] = useState<Nullable<boolean>>(null);

  const resetState = useCallback(() => {
    setCouponFound(false);
    setCouponFoundMessage('');
    setCouponExtraMessage('');
    setCouponAppliesToShippingMessage('');
    setIsInputActive(false);
    setShowSuggestions(false);
    setSearchInput('');
    setThrottledCall(null);
    setCouponSuggestions([]);
    setCoupons([]);
    setCouponCode(null);
    setCouponType(null);
    setCouponValue(null);
    setCouponAppliesToShipping(null);
  }, []);

  const onConfirmAction = useCallback(() => {
    onConfirm(couponCode!, couponType!, couponValue!, couponAppliesToShipping!);
    resetState();
  }, [
    couponAppliesToShipping,
    couponCode,
    couponType,
    couponValue,
    onConfirm,
    resetState,
  ]);

  const onInputBlur = useCallback(() => {
    setIsInputActive(false);
    setShowSuggestions(false);
  }, []);

  const selectCoupon = useCallback(async (coupon: CouponSuggestionsViewModel) => {
    if (coupon !== null) {
      let discountValueMessage = '';
      let { discountAmount } = coupon;
      const {
        discountType,
        code,
        applyToShipping,
      } = coupon;

      let couponTypeTemp;
      if (discountType === discountTypeEnum.DollarAmount) {
        discountAmount = discountAmount.toFixed(2) as unknown as number / 100.0;
        discountValueMessage = '$' + discountAmount;
        couponTypeTemp = couponTypeEnum.Amount;
      } else {
        discountValueMessage = `${discountAmount}%`;
        couponTypeTemp = couponTypeEnum.Percent;
      }
      setCouponFound(true);
      setCouponFoundMessage(`Coupon code ${coupon.code} found.`);
      setCouponExtraMessage(`Discount ${discountValueMessage}`);
      setCouponAppliesToShippingMessage(`Coupon ${applyToShipping ? 'will' : 'will not'} be applied to shipping.`);
      setIsInputActive(false);
      setCouponSuggestions([coupon]);
      setSearchInput(coupon.code);
      setCoupons([
        ...coupons,
        coupon,
      ]);
      setCouponValue(discountAmount);
      setCouponType(couponTypeTemp);
      setCouponCode(code);
      setCouponAppliesToShipping(applyToShipping);
    } else {
      setCouponFound(false);
      setCouponFoundMessage('Coupon code not found.');
      setCouponExtraMessage('Try another code.');
      setIsInputActive(true);
      setCoupons([]);
      setCouponValue(0);
    }

    onInputBlur();
  }, [
    coupons,
    onInputBlur,
  ]);

  const onCloseModal = useCallback(() => {
    resetState();
    closeModal();
  }, [
    closeModal,
    resetState,
  ]);

  const updateSuggestions = useCallback(async () => {
    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    const suggestions = await findCouponSuggestions(searchInput);

    setCouponSuggestions(suggestions);
    setShowSuggestions(true);
  }, [
    searchInput,
    throttledCall,
  ]);

  const onInputChange = useCallback((e: any) => {
    const newSearchInput = e.target.value?.trim() ?? '';
    setSearchInput(newSearchInput);

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    if (newSearchInput.length < minInputLengthForSuggestions) {
      setShowSuggestions(false);
      setThrottledCall(null);

      return;
    }

    const newThrottle = setTimeout(updateSuggestions, throttleDelay);
    setThrottledCall(newThrottle);
    setShowSuggestions(false);
  }, [
    throttledCall,
    updateSuggestions,
  ]);

  const onInputFocus = useCallback(() => {
    setIsInputActive(true);
    setShowSuggestions(searchInput.length > minInputLengthForSuggestions);
  }, [searchInput.length]);

  const renderCouponSuggestion = useCallback((coupon: CouponSuggestionsViewModel) => (
    <CouponSuggestion
      key={coupon.id}
      coupon={coupon}
      coupons={coupons}
      searchInput={searchInput}
      selectCoupon={selectCoupon}
    />
  ), [
    coupons,
    searchInput,
    selectCoupon,
  ]);

  return (
    <Modal
      title={'Apply Coupon to Orders'}
      modalHeight={'l'}
      modalWidth={'s'}
      isOpen={isOpen}
      closeModal={onCloseModal}
      enableBodyOverflow={true}
      buttons={(
        <ModalButtons
          confirmBtnText={'Apply'}
          cancelBtnText={'Cancel'}
          onConfirm={onConfirmAction}
          onClose={onCloseModal}
          confirmBtnDisabled={!couponFound}
        />
      )}
    >
      <div>
        {
          isOpen &&
          <div className='align align--center'>
            <div className='modal__subtitle align-left mb-30'>Search For Coupon To Add</div>
            <div>
              <div className={`chips-search has-icon ${isInputActive ? 'active' : ''}`}>
                <div>
                  <Icon materialIcon={'search'} />
                </div>
                <div className='chips'>
                  <div className='text-field mb-0'>
                    <OutsideClickWrapper onClick={onInputBlur}>
                      <>
                        <input
                          type='text'
                          placeholder={couponSuggestions.length === 0 ? 'Coupon Name' : ''}
                          onChange={onInputChange}
                          onFocus={onInputFocus}
                          value={searchInput}
                        />
                        {
                          showSuggestions &&
                          <div className='suggestions suggestions__dropdown-fixed custom-scrollbar'>
                            {
                              couponSuggestions.map(renderCouponSuggestion)
                            }
                          </div>
                        }
                      </>
                    </OutsideClickWrapper>
                  </div>
                </div>
              </div>
            </div>
            <br />
            {
              couponFoundMessage &&
              <InfoField
                value={couponFoundMessage}
                defaultValue={''}
                valueClass={`status-label ${couponFound ? 'green' : 'red'}`}
              />
            }
            {
              couponExtraMessage &&
              <InfoField
                value={couponExtraMessage || ''}
                defaultValue={''}
              />
            }
            {
              couponAppliesToShippingMessage &&
              <InfoField
                value={couponAppliesToShippingMessage || ''}
                defaultValue={''}
              />
            }
          </div>
        }
      </div>
    </Modal>
  );
});

export default AddCouponModal;
