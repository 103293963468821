import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { featureFlags } from '@constants/common';
import FormError from '@sharedComponents/Form/FormError';
import Input from '@sharedComponents/Form/Input';

const ScanItemBarcodeInputs = React.memo(({
  fields,
  meta: { error },
}) => {
  const onItemCodeChange = (index, e) => {
    const valueLength = e.target.value.length;
    if (valueLength === featureFlags.itemBarcodeLength && index < fields.length - 1) {
      e.target.form[index + 1].focus();
    }
  };

  return (
    <div>
      {fields.map((field, index) => (
        <div key={index}>
          <Field
            name={`${field}.barcode`}
            component={Input}
            placeholder={'Scan or Enter an Item Barcode'}
            type={'text'}
            onChange={onItemCodeChange.bind(this, index)}
            autoFocus={index === 0}
          />

          <div className='mt-20'>
            <FormError
              error={error}
              enableMultiple={true}
            />
          </div>
        </div>
      ))}
    </div>
  );
});

ScanItemBarcodeInputs.propTypes = {
  fields: PropTypes.array.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.array,
  }),
  orderItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ScanItemBarcodeInputs;
