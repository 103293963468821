import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  addMultipleRosters,
  editRoster,
  downloadRosterCsvFile,
  deleteRosters,
  toggleDisabledRosters,
  sendRosterNotifications,
} from '@APICalls/rosters/actions';
import { rosterDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { rostersBulkActionsEnum } from '@constants/enums/bulkActionsEnum';
import { TableEnum } from '@constants/enums/tableEnums';
import { getRosterStatusOptions } from '@constants/options/options';
import { getRostersBulkActions } from '@constants/options/bulkActions';
import { fetchRostersTable } from '@redux/rosters/actions';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { materialSwal } from '@util/componentHelper';
import { parseDateNumeric } from '@util/dateHandler';
import memoizeOne from '@util/memoHelper';
import Button from '@sharedComponents/Buttons/Button';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Link from '@sharedComponents/Navigation/Link';
import DownloadFileLink from '@sharedComponents/Navigation/DownloadFileLink';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import Table from '@sharedComponents/Table/Table';
import ActivityStatus from '@sharedComponents/Display/ActivityStatus';
import EditRosterModal from './RosterManagerModals/EditRosterModal';
import AddRostersFromCSV from './RosterManagerModals/AddRostersFromCSV';
import RostersActionsColumn from './RostersActionsColumn';
import ToggleDisabledRosterModal from './RosterManagerModals/ToggleDisabledRosterModal';
import RemoveRostersModal from './RosterManagerModals/RemoveRostersModal';
import SendNotificationsModal from './RosterManagerModals/SendNotificationsModal';

const RosterTeamsTable = Table();

const rosterStatusOptions = getRosterStatusOptions();

class RosterManager extends PureComponent {
  state = {
    pageNumber: this.props.initialPageNumber,
    pageSize: this.props.initialPageSize,
    sortColumn: this.props.initialSortColumn,
    sortDirection: this.props.initialSortDirection,
    searchInput: this.props.initialSearchInput,
    disabledFilter: this.props.initialDisabledFilter,
    selectedRoster: null,
    selectedRosters: [],
    importRosterFromCSVModalIsOpen: false,
    editRosterModalIsOpen: false,
    toggleDisabledRostersModalIsOpen: false,
    deleteRostersModalIsOpen: false,
    sendNotificationsModalIsOpen: false,
  };

  openImportRosterFromCSVModal = () => {
    this.setState(() => ({ importRosterFromCSVModalIsOpen: true }));
  };

  closeImportRosterFromCSVModal = () => {
    this.setState(() => ({ importRosterFromCSVModalIsOpen: false }));
  };

  openEditRosterModal = (selectedRoster) => {
    this.setState(() => ({
      selectedRoster,
      editRosterModalIsOpen: true,
    }));
  };

  closeEditRosterModal = () => {
    this.setState(() => ({
      selectedRoster: null,
      editRosterModalIsOpen: false,
    }));
  };

  openSendNotificationsModal = (roster) => {
    this.setState(() => ({
      selectedRosters: [roster],
      sendNotificationsModalIsOpen: true,
    }));
  };

  closeSendNotificationsModal = () => {
    this.setState(() => ({ sendNotificationsModalIsOpen: false }));
  };

  openDeleteRosterModal = (selectedRoster) => {
    this.setState(() => ({
      selectedRosters: [selectedRoster],
      deleteRostersModalIsOpen: true,
    }));
  };

  addRosters = async (rostersForm) => {
    const res = await addMultipleRosters(rostersForm);
    this.search();
    materialSwal('Success', res.message, 'success');
    this.closeImportRosterFromCSVModal();
  };

  editRoster = async (rosterForm) => {
    const res = await editRoster(rosterForm);
    this.search();
    materialSwal('Success', res.message, 'success');
    this.closeEditRosterModal();
  };

  downloadCSVFileTemplate = () => {
    downloadRosterCsvFile(null, null, null, true);
  };

  clearSearch = () => {
    this.setState(() => ({
      searchInput: '',
    }), this.search);
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  onStatusChange = (value) => {
    this.setState(() => ({
      disabledFilter: value,
    }), this.search);
  };

  fetchData = (state, instance) => {
    const {
      sortColumn,
      sortDirection,
    } = this.state;

    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortColumn, sortDirection);

    this.setState(() => ({
      pageNumber: newPageNumber + 1,
      pageSize: newPageSize,
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    }), this.search);
  };

  search = () => {
    const { dispatch } = this.props;

    const {
      pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      disabledFilter,
      searchInput,
    } = this.state;

    dispatch(fetchRostersTable(
      pageNumber,
      pageSize,
      sortColumn,
      sortDirection,
      disabledFilter,
      searchInput
    ));
  };

  onSelectBulkAction = (selectedBulkAction) => {
    switch (selectedBulkAction) {
      case rostersBulkActionsEnum.deleteRosters:
        this.onBulkDeleteRosters();
        break;
      case rostersBulkActionsEnum.toggleDisabledRosters:
        this.onBulkToggleDisabledRosters();
        break;
      case rostersBulkActionsEnum.sendNotifications:
        this.onBulkSendNotifications();
        break;
    }
  };

  onBulkDeleteRosters = () => {
    this.setState(() => ({ deleteRostersModalIsOpen: true }));
  };

  deleteRosters = async () => {
    const { selectedRosters } = this.state;

    const res = await deleteRosters(selectedRosters);

    if (res?.success) {
      this.search();
      materialSwal('Success', res.message, 'success');
      this.closeDeleteRostersModal();
      this.clearSelection();
    }
  };

  closeDeleteRostersModal = () => {
    this.setState(() => ({ deleteRostersModalIsOpen: false }));
  };

  onBulkToggleDisabledRosters = () => {
    this.setState(() => ({ toggleDisabledRostersModalIsOpen: true }));
  };

  toggleDisabledRosters = async () => {
    const { selectedRosters } = this.state;

    const res = await toggleDisabledRosters(selectedRosters);

    if (res?.success) {
      this.search();
      materialSwal('Success', res.message, 'success');
      this.closetoggleDisabledRostersModal();
      this.clearSelection();
    }
  };

  onBulkSendNotifications = () => {
    this.setState(() => ({ sendNotificationsModalIsOpen: true }));
  };

  sendNotifications = async (rosterNotificationsForm) => {
    const res = await sendRosterNotifications(rosterNotificationsForm);

    if (res) {
      materialSwal('Success', 'Notifications successfully sent', 'success');
    } else {
      materialSwal('Error', 'Something went wrong. Please try again.', 'error');
    }

    this.clearSelection();
    this.search();
    this.closeSendNotificationsModal();
  };

  closetoggleDisabledRostersModal = () => {
    this.setState(() => ({ toggleDisabledRostersModalIsOpen: false }));
  };

  clearSelection = () => {
    this.updateSelection([], false);
  };

  updateSelection = (newSelectedRosters, newIsPageSelected) => {
    this.setState(() => ({
      selectedRosters: newSelectedRosters,
      isPageSelected: newIsPageSelected,
    }));
  };

  openToggleDisabledRosterModal = (roster) => {
    this.setState(() => ({
      selectedRosters: [roster],
      toggleDisabledRostersModalIsOpen: true,
    }));
  };

  getColumns = () => {
    const columns = [
      {
        Header: <HeaderCell text={'Roster Team Name'} />,
        accessor: 'teamName',
        minWidth: 40,
        sortable: true,
        Cell: (cellProps) => cellProps.original.id && (
          <Link
            url={rosterDetailsUrl(cellProps.original.id)}
            text={cellProps.original.teamName}
          />
        ),
      },
      {
        Header: 'Locker ID & Name',
        accessor: 'lockerId',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value && (
          <span>
            L{cellProps.value}{cellProps.original.lockerTeamName ? ` - ${cellProps.original.lockerTeamName}` : ''}
          </span>
        ),
      },
      {
        Header: 'Organization',
        accessor: 'organizationId',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value && (
          <span>
            ORG{cellProps.value}{cellProps.original.organizationName ? ` - ${cellProps.original.organizationName}` : ''}
          </span>
        ),
      },
      {
        Header: 'Ordering Deadline',
        accessor: 'orderingDeadline',
        minWidth: 30,
        Cell: (cellProps) => cellProps.value && <span>{parseDateNumeric(cellProps.value)}</span>,
      },
      {
        Header: 'Members',
        accessor: 'membersCount',
        minWidth: 20,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Status',
        accessor: 'disabled',
        minWidth: 20,
        Cell: (cellProps) => <ActivityStatus isDisabled={cellProps.value} />,
      },
      {
        Header: '',
        accessor: '',
        minWidth: 10,
        resizable: false,
        style: { overflow: 'visible' },
        Cell: (cellProps) => (
          <RostersActionsColumn
            roster={cellProps.value}
            onRosterEdit={this.openEditRosterModal}
            onRosterToggleDisabled={this.openToggleDisabledRosterModal}
            onRosterSendNotifications={this.openSendNotificationsModal}
            onRosterDelete={this.openDeleteRosterModal}
          />
        ),
      },
    ];

    return columns;
  };

  getFiltersToPreserve = memoizeOne((
    searchInput,
    disabledFilter
  ) => ({
    searchInput,
    disabledFilter,
  }));

  render() {
    const {
      selectedRoster,
      selectedRosters,
      editRosterModalIsOpen,
      importRosterFromCSVModalIsOpen,
      disabledFilter,
      searchInput,
      toggleDisabledRostersModalIsOpen,
      deleteRostersModalIsOpen,
      sendNotificationsModalIsOpen,
    } = this.state;

    const {
      queue = [],
      hasNextPage,
      hasPreviousPage,
      totalPages,
      initialPageNumber,
      initialPageSize,
      initialSortColumn,
      initialSortDirection,
      initialSearchInput,
      initialDisabledFilter,
    } = this.props;

    const initialValues = !selectedRoster ? {} : selectedRoster;

    const selectedRostersOrderingDeadlines = [
      ...new Set(
        selectedRosters.map((r) => r.orderingDeadline).filter(Boolean)
      ),
    ];

    const selectedRostersHaveDifferentOrderingDeadline = selectedRostersOrderingDeadlines.length > 1;

    const disabledSendNotifications = selectedRosters.some((r) => r.disabled)
      || selectedRostersHaveDifferentOrderingDeadline;

    const bulkActions = getRostersBulkActions(disabledSendNotifications, false);

    return (
      <div className='container'>
        <AddRostersFromCSV
          isOpen={importRosterFromCSVModalIsOpen}
          closeModal={this.closeImportRosterFromCSVModal}
          addRosters={this.addRosters}
        />
        <EditRosterModal
          isOpen={editRosterModalIsOpen}
          closeModal={this.closeEditRosterModal}
          editRoster={this.editRoster}
          initialValues={initialValues}
        />
        <RemoveRostersModal
          isOpen={deleteRostersModalIsOpen}
          closeModal={this.closeDeleteRostersModal}
          removeRosters={this.deleteRosters}
          rosters={selectedRosters}
        />
        <ToggleDisabledRosterModal
          isOpen={toggleDisabledRostersModalIsOpen}
          closeModal={this.closetoggleDisabledRostersModal}
          toggleDisabledRosters={this.toggleDisabledRosters}
          rosters={selectedRosters}
        />
        <SendNotificationsModal
          onSubmit={this.sendNotifications}
          isOpen={sendNotificationsModalIsOpen}
          closeModal={this.closeSendNotificationsModal}
          rosters={selectedRosters}
        />

        <div className='navigation'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
              initialValue={initialSearchInput}
            />
            <Dropdown
              options={rosterStatusOptions}
              defaultValue={initialDisabledFilter}
              onChange={this.onStatusChange}
              classes={'margin-left coupons-table__filter'}
            />
          </div>

          <div className='align__center mb-20'>
            <DownloadFileLink
              text={'Download CSV Template'}
              downloadFile={this.downloadCSVFileTemplate}
            />
            <Button
              type={'primary'}
              text={'Add Roster'}
              onClick={this.openImportRosterFromCSVModal}
              classes={'ml-10'}
            />
          </div>
        </div>

        <RosterTeamsTable
          data={queue}
          columns={this.getColumns()}
          showPagination={hasNextPage || hasPreviousPage}
          totalPages={totalPages}
          onFetchData={this.fetchData}
          isBulkActionMode={selectedRosters && selectedRosters.length > 0}
          selectable={true}
          selectPredicateOrKey={'id'}
          updateSelection={this.updateSelection}
          selectedData={selectedRosters}
          bulkActionsList={bulkActions}
          onSelectBulkAction={this.onSelectBulkAction}
          tableId={TableEnum.rosterManager}
          preserveState={true}
          sortDirEnum={sortDirectionShortEnum}
          initialPageNumber={initialPageNumber}
          initialPageSize={initialPageSize}
          initialSortColumn={initialSortColumn}
          initialSortDirection={initialSortDirection}
          filtersToPreserve={this.getFiltersToPreserve(
            searchInput,
            disabledFilter
          )}
        />
      </div>
    );
  }
}

RosterManager.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({
  rosters,
  tableManager,
}) => ({
  initialPageNumber: tableManager.rosterManager.pageNumber,
  initialPageSize: tableManager.rosterManager.pageSize,
  initialSortColumn: tableManager.rosterManager.sortColumn,
  initialSortDirection: tableManager.rosterManager.sortDirection,
  initialSearchInput: tableManager.rosterManager.searchInput,
  initialDisabledFilter: tableManager.rosterManager.disabledFilter,
  queue: rosters.rostersQueue.queue,
  hasPreviousPage: rosters.rostersQueue.hasPreviousPage,
  hasNextPage: rosters.rostersQueue.hasNextPage,
  totalPages: rosters.rostersQueue.totalPages,
});

export default connect(mapStateToProps)(RosterManager);
