import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  markCompleted,
  flagQueueItem,
  unflagQueueItem,
} from '@APICalls/weedingAndMasking/actions';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import {
  printAssemblyUnflagItemForm,
  printAssemblyFlagItemForm,
} from '@constants/reduxForms';
import { getFlagReasons } from '@util/flagHelper';
import Button from '@sharedComponents/Buttons/Button';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import LogoOrTextCell from '../Cells/LogoOrTextCell';
import TypeCell from '../Cells/TypeCell';
import SizeCell from '../Cells/SizeCell';
import FlagCell from '../Cells/FlagCell';
import DtfReprintCell from '../Cells/DtfReprintCell';
import PrintAssemblyFlaggingModal from '../Flagging/PrintAssemblyFlaggingModal';
import PrintAssemblyUnflagItemModal from '../Flagging/PrintAssemblyUnflagItemModal';

const ItemsTable = Table();

class ToBeWeededAndMaskedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      flaggingModalIsOpen: false,
      unflaggingModalIsOpen: false,
      columns: this.getColumns(),
    };
  }

  componentDidUpdate(prevProps) {
    const { colorsDictionary } = this.props;

    if (colorsDictionary !== prevProps.colorsDictionary) {
      this.setState({ columns: this.getColumns() });
    }
  }

  getColumns = () => {
    const {
      openLogoPreviewModal,
      colorsDictionary,
    } = this.props;

    const
      columns = [
        {
          Header: 'Logo / Text',
          accessor: '',
          Cell: (cellProps) => (
            <LogoOrTextCell
              item={cellProps.value}
              openModal={openLogoPreviewModal}
              colorsDictionary={colorsDictionary}
            />
          ),
        },
        {
          Header: 'Type',
          accessor: '',
          Cell: (cellProps) => (
            <TypeCell
              type={cellProps.value.jobType}
              cutColor={cellProps.value.cutFileColor}
              isCutLogo={cellProps.value.isCutLogoFile}
              files={cellProps.value.resultUrls}
            />
          ),
        },
        {
          Header: 'Size',
          accessor: '',
          maxWidth: 160,
          Cell: (cellProps) => (
            <SizeCell
              size={cellProps.value.size}
              decorationSize={cellProps.value.decorationSize}
            />
          ),
        },
        {
          Header: 'Flood Color',
          accessor: 'floodColorId',
          maxWidth: 120,
          Cell: (cellProps) => {
            const floodColor = colorsDictionary && colorsDictionary[cellProps.value];

            return floodColor
              ? <span>{floodColor.code}</span>
              : '-';
          },
        },
        {
          Header: <HeaderCell text={'Order ID'} />,
          accessor: 'orderNumber',
          sortable: true,
          maxWidth: 100,
          Cell: (cellProps) => <span>O{cellProps.value}</span>,
        },
        {
          Header: <HeaderCell text={'Barcode'} />,
          accessor: 'barcode',
          sortable: true,
          maxWidth: 200,
        },
        {
          Header: 'DTF',
          accessor: '',
          maxWidth: 150,
          Cell: (cellProps) => (
            <DtfReprintCell
              itemId={cellProps.value.id}
              enabled={true}
              barcode={cellProps.value.barcode}
            />
          ),
        },
        {
          Header: '',
          accessor: '',
          maxWidth: 200,
          Cell: (cellProps) => (
            <div className='flex'>
              <FlagCell
                flagInfo={cellProps.value.flag}
                openFlaggingModal={this.openFlaggingModal}
                openUnflaggingModal={this.openUnflaggingModal}
                item={cellProps.value}
              />
              {
                !cellProps.value.flag &&
                <Button
                  type={'primary'}
                  text={'Done'}
                  onClick={this.markDoneOnClick.bind(null, cellProps.value.id)}
                  classes={'ml-10'}
                />
              }
            </div>
          ),
        },
      ];

    return columns;
  };

  flagQueueItem = async (form) => {
    const { refreshData } = this.props;
    const { item } = this.state;

    const res = await flagQueueItem(
      form.reason,
      form.note,
      form.flagType,
      item.id,
      form.file
    );

    if (res?.success) {
      this.closeFlaggingModal();
      refreshData();
    }
  };

  unflagQueueItem = async (form) => {
    const { refreshData } = this.props;
    const { item } = this.state;

    const {
      reason,
      note,
      flagType,
    } = form;

    const res = await unflagQueueItem(
      item.id,
      reason,
      note,
      flagType
    );

    if (res?.success) {
      refreshData();
      this.closeUnflaggingModal();
    }
  };

  markDoneOnClick = async (id) => {
    const { refreshData } = this.props;

    const res = await markCompleted(id);

    if (res?.success) {
      refreshData(null, true, true);
    }
  };

  openFlaggingModal = (item) => {
    this.setState({
      item,
      flaggingModalIsOpen: true,
    });
  };

  openUnflaggingModal = (item) => {
    this.setState({
      item,
      unflaggingModalIsOpen: true,
    });
  };

  closeFlaggingModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(printAssemblyFlagItemForm));
    this.setState({
      flaggingModalIsOpen: false,
      item: null,
    });
  };

  closeUnflaggingModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(printAssemblyUnflagItemForm));
    this.setState({
      unflaggingModalIsOpen: false,
      item: null,
    });
  };

  render() {
    const {
      data,
      pages,
      fetchData,
      showPagination,
      colorsDictionary,
    } = this.props;

    const {
      columns,
      item,
      flaggingModalIsOpen,
      unflaggingModalIsOpen,
    } = this.state;

    const flagType = flagTypeEnum.Reprint;
    const { reason } = getFlagReasons(flagType, decorationMethodEnum.HAG)[0];

    return (
      <div>
        <PrintAssemblyFlaggingModal
          isOpen={flaggingModalIsOpen}
          item={item}
          closeModal={this.closeFlaggingModal}
          onSubmit={this.flagQueueItem}
          colorsDictionary={colorsDictionary}
          initialValues={{
            flagType,
            reason,
            file: null,
          }}
        />

        <PrintAssemblyUnflagItemModal
          isOpen={unflaggingModalIsOpen}
          item={item}
          closeModal={this.closeUnflaggingModal}
          colorsDictionary={colorsDictionary}
          onSubmit={this.unflagQueueItem}
          initialValues={{
            flagType: item && item.flag && item.flag.type,
            reason: item && item.flag && item.flag.reason,
            note: item && item.flag && item.flag.note,
          }}
        />

        <ItemsTable
          columns={columns}
          data={data}
          pages={pages}
          onFetchData={fetchData}
          showPagination={showPagination}
          classNames={''}
        />
      </div>
    );
  }
}

ToBeWeededAndMaskedTable.propTypes = {
  data: PropTypes.array.isRequired,
  pages: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  showPagination: PropTypes.bool.isRequired,
  openLogoPreviewModal: PropTypes.func.isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  colorsDictionary: productCatalog.colorsDictionary,
});

export default connect(mapStateToProps)(ToBeWeededAndMaskedTable);
