import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { RosterTeamMemberViewModel } from '@api/squadlockerServices/models';
import {
  copiedText,
  emptyText,
  infoTextTimeout,
} from '@constants/values';
import { copyToClipboard } from '@util/componentHelper';
import InfoField from '@sharedComponents/Display/InfoField';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  rosterTeamMember: RosterTeamMemberViewModel;
}

type Props = OwnProps;

const ContactTab = React.memo<Props>(({ rosterTeamMember }) => {
  const [
    copyText,
    setCopyText,
  ] = useState<string>(emptyText);

  useEffect(() => {
    if (copyText !== copiedText) return;

    setTimeout(() => {
      setCopyText(emptyText);
    }, infoTextTimeout);
  }, [copyText]);

  const onCopyToClipboard = useCallback((e) => {
    copyToClipboard(e, 'idToCopy');
    setCopyText(copiedText);
  }, []);

  if (!rosterTeamMember) {
    return null;
  }

  const phoneNumbers = [
    rosterTeamMember.phone,
    rosterTeamMember.phoneAdditional,
  ].filter(Boolean);

  const emails = [
    rosterTeamMember.email,
    rosterTeamMember.emailAdditional,
  ].filter(Boolean);

  return (
    <div className='pt-20'>
      <InfoField
        label={'Phone(s)'}
        value={(
          <div>
            {
              phoneNumbers.length
                ? (
                  phoneNumbers.map((phoneNumber) => (
                    <div key={phoneNumber?.toString()}>{phoneNumber}</div>
                  ))
                )
                : '-'
            }
          </div>
        )}
        fieldClass={'ml-15'}
      />
      <InfoField
        label={'E-mail(s)'}
        value={(
          <div>
            {
              emails.length
                ? (
                  emails.map((email) => (
                    <div
                      key={email?.toString()}
                      className='align__center'
                    >
                      <span
                        id='idToCopy'
                        className='roster-team-members__quick-view__tab__email'
                      >
                        {email}
                      </span>
                      <Icon
                        materialIcon={'content_copy'}
                        onClick={onCopyToClipboard}
                        classes={'roster-team-members__quick-view__tab__copy-icon'}
                      />
                      <span className='roster-team-members__quick-view__tab__copy-text'>
                        {copyText}
                      </span>
                    </div>
                  ))
                )
                : '-'
            }
          </div>
        )}
        fieldClass={'ml-15'}
      />
    </div>
  );
});

export default ContactTab;
