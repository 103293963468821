import * as actionTypes from './types';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import { OrderManagementAction } from './actions';
import {
  OrderManagementOrderItemsPagedList,
  OrderManagementTempOrder,
  OrderManagementTempItem,
} from './models';

export interface OrderManagementState {
  order: any;
  orderCouponDetails: any;
  lockerArtworks: [];
  currentQueue: OrderManagementOrderItemsPagedList;
  orderManagementTableState: any;
  product: any;
  notes: any[];
  accountingSubmitResults: any[];
  shipments: any[];
  changeLogs: any[];
  statusHistory: any[];
  orderFlags: any[];
  tempOrder: Nullable<OrderManagementTempOrder>;
  tempItems: OrderManagementTempItem[];
}

const initialState: OrderManagementState = {
  order: {
    items: [],
  },
  orderCouponDetails: null,
  lockerArtworks: [],
  currentQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
  },
  orderManagementTableState: {
    dateRange: dateRangeEnum.past60Days.value,
    sortOrder: 'descending',
    sortByState: 'datePlaced',
    accountingSubmitStatus: '',
    pageSize: 10,
    filter: '',
    pageNumber: 1,
  },
  product: {
    id: null,
    sku: null,
    name: null,
    brandName: null,
    imageUrlBack: null,
    imageUrlFront: null,
    imageUrlName: null,
    imageUrlSide: null,
    imageGrid: null,
    price: null,
    gender: null,
    size: null,
    isPersonalizationYouthSize: null,
    age: null,
    floodColor: null,
    decorationMethod: null,
    personalizationColorId: null,
    personalizationLocations: [],
  },
  notes: [],
  accountingSubmitResults: [],
  shipments: [],
  changeLogs: [],
  statusHistory: [],
  orderFlags: [],
  tempOrder: null,
  tempItems: [],
};

export default function(state = initialState, action: OrderManagementAction) {
  switch (action.type) {
    case actionTypes.UPDATE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case actionTypes.SEARCH_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case actionTypes.UPDATE_ORDER_ARTWORK:
      return {
        ...state,
        lockerArtworks: action.payload,
      };
    case actionTypes.UPDATE_CURRENT_QUEUE:
      return {
        ...state,
        currentQueue: action.payload,
      };
    case actionTypes.RESET_ORDER_MANAGEMENT_TABLE_STATE:
      return {
        ...state,
        orderManagementTableState: initialState.orderManagementTableState,
      };
    case actionTypes.UPDATE_ORDER_MANAGEMENT_TABLE_STATE:
      return {
        ...state,
        orderManagementTableState: action.payload,
      };
    case actionTypes.UPDATE_SAVE_TEMP_ORDER:
      return {
        ...state,
        tempOrder: action.payload,
      };
    case actionTypes.UPDATE_SAVE_TEMP_ITEMS:
      return {
        ...state,
        tempItems: action.payload,
      };
    case actionTypes.DISCARD_TEMP_ORDER:
      return {
        ...state,
        tempOrder: null,
        tempItems: [],
      };
    case actionTypes.UPDATE_ORDER_NOTES:
      return {
        ...state,
        notes: action.payload,
      };
    case actionTypes.UPDATE_ACCOUNTING_SUBMIT_RESULTS:
      return {
        ...state,
        accountingSubmitResults: action.payload,
      };
    case actionTypes.UPDATE_ORDER_SHIPMENTS:
      return {
        ...state,
        shipments: action.payload,
      };
    case actionTypes.UPDATE_ORDER_CHANGE_LOGS:
      return {
        ...state,
        changeLogs: action.payload,
      };
    case actionTypes.UPDATE_ORDER_STATUS_HISTORY:
      return {
        ...state,
        statusHistory: action.payload,
      };
    case actionTypes.UPDATE_ORDER_FLAGS:
      return {
        ...state,
        orderFlags: action.payload,
      };
    case actionTypes.UPDATE_ORDER_COUPON_DETAILS:
      return {
        ...state,
        orderCouponDetails: action.payload,
      };
    default:
      return state;
  }
}
