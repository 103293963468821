import React from 'react';
import { batchWarehouseSchedulingUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const BatchWarehouseSchedulingLink = React.memo(() => (
  <MenuTile
    title={'Scheduling 3.0 (beta)'}
    url={batchWarehouseSchedulingUrl}
    icon={'book'}
    isMaterialIcon={false}
  />
));

export default BatchWarehouseSchedulingLink;
