/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import type {
  ApiResponse
} from './models'
import { financialApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiSupportVersion = <TData = ApiResponse>(
    
 options?: SecondParameter<typeof financialApi>) => {
      return financialApi<TData>(
      {url: `/homefield/api/support/version`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiSupportVersionQueryKey = () => [`/homefield/api/support/version`]

    
export const useGetHomefieldApiSupportVersion = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiSupportVersion, ApiResponse>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof financialApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiSupportVersionQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiSupportVersion<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

