import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Field,
  reduxForm,
} from 'redux-form';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import { parseNumber } from '@util/numberHelpers';
import { connect } from 'react-redux';
import {
  getDtgPalletSizeOptions,
  getDtgHighlightGeneratorOptions,
} from '@constants/options/options';
import { dtgEnvironmentForm } from '@constants/reduxForms';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import FormError from '@sharedComponents/Form/FormError';
import { generalOptions } from '@util/optionsMap';
import { validateDtgEnvironment } from '@redux/productCatalog/validations';

const DtgEnvironmentForm = React.memo((props) => {
  const {
    error,
    handleSubmit,
  } = props;

  const palletSizeOptions = generalOptions(getDtgPalletSizeOptions());
  const highlightGeneratorOptions = generalOptions(getDtgHighlightGeneratorOptions());

  return (
    <Form
      className='redux-form'
      onSubmit={handleSubmit}
      id={dtgEnvironmentForm}
    >
      <div>
        <GridContainer className='redux-form__section'>
          <Row className='redux-form__row'>
            <Col
              className='redux-form__column'
              md={12}
            >
              <label className='redux-form__label'>
                Name
                <span className='required'>*</span>
              </label>
              <Field
                name={'environmentName'}
                placeholder={'Enter name'}
                component={Input}
                type={'string'}
              />
            </Col>
          </Row>
          <Row className='redux-form__row'>
            <Col
              className='redux-form__column'
              md={12}
            >
              <label className='redux-form__label'>
                Note
              </label>
              <Field
                name={'note'}
                placeholder={'Describe Environment'}
                component={Input}
                type={'string'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className='redux-form__label'>
                Choke Percent
              </label>
              <Field
                name={'chokePercent'}
                placeholder={'Choke %'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={4}>
              <label className='redux-form__label'>
                Underbase Percent
              </label>
              <Field
                name={'underbasePercent'}
                placeholder={'Underbase %'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={4}>
              <label className='redux-form__label'>
                Highlight Percent
              </label>
              <Field
                name={'highlightPercent'}
                placeholder={'Highlight %'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className='redux-form__label'>
                Highlight Generator
              </label>
              <Field
                name={'highlightGenerator'}
                component={Select}
                type={'string'}
              >
                {highlightGeneratorOptions}
              </Field>
            </Col>
            <Col md={4}>
              <label className='redux-form__label'>
                Fuzziness Percent
              </label>
              <Field
                name={'fuzzinessPercent'}
                placeholder={'Fuzziness %'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={4}>
              <label className='redux-form__label'>
                Color Strength Percent
              </label>
              <Field
                name={'colorStrengthPercent'}
                placeholder={'Color Strength %'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className='redux-form__label'>
                Platen Placement X
              </label>
              <Field
                name={'platenPlacementX'}
                placeholder={'Platen Placement X'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={4}>
              <label className='redux-form__label'>
                Platen Placement Y
              </label>
              <Field
                name={'platenPlacementY'}
                placeholder={'Platen Placement Y'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </Col>
            <Col md={4}>
              <label className='redux-form__label'>
                Pallet Size
              </label>
              <Field
                name={'palletSizeName'}
                component={Select}
                type={'string'}
              >
                {palletSizeOptions}
              </Field>
            </Col>
          </Row>
        </GridContainer>
        <FormError error={error} />
      </div>
    </Form>
  );
});

DtgEnvironmentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
};

export default connect()(reduxForm({
  form: dtgEnvironmentForm,
  validate: validateDtgEnvironment,
  enableReinitialize: true,
  shouldError: () => true,
})(DtgEnvironmentForm));
