import React from 'react';
import NoteTop from './NoteTop';
import NoteDescription from './NoteDescription';
import NoteBottom from './NoteBottom';
import { Note as NoteDto } from '@models/OrderManagement/Note';

interface OwnProps {
  note: NoteDto;
  loggedInUser: Nullable<string>;
  orderNumber: number;
}

type Props = OwnProps;

const Note = React.memo<Props>(({
  note,
  loggedInUser,
  orderNumber,
}) => (
  <li className='note'>
    <NoteTop
      id={note.id}
      createdBy={note.createdBy}
      loggedInUser={loggedInUser!}
      date={note.created}
      user={note.createdByName}
      orderNumber={orderNumber}
    />
    <NoteDescription text={note.content!} />
    {
      note.fileName
        ? (
          <NoteBottom
            fileName={note.fileName}
            fileUrl={note.attachmentUrl}
          />
        )
        : ''
    }
  </li>
));

export default Note;
