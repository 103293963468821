import React from 'react';

interface OwnProps {
  orderNumber: number;
  decorationId: number;
}

type Props = OwnProps;

const DecorationCell = React.memo<Props>((
  {
    orderNumber,
    decorationId,
  }
) => (
  <span className='text'>
    {`O${orderNumber ?? 0}-${decorationId ?? 0}`}
  </span>
));

export default DecorationCell;
