export const lockerItemBulkActionsEnum = {
  none: 'none',
  deleteProducts: 'deleteProducts',
  changeEssential: 'changeEssential',
  refreshCachedImage: 'refreshCachedImage',
  hideCustomItems: 'hideCustomItems',
  unhideCustomItems: 'unhideCustomItems',
  addColorsToProducts: 'addColorsToProducts',
  newCollection: 'newCollection',
  addToExistingCollection: 'addToExistingCollection',
  removeItemsFromCollection: 'removeItemsFromCollection',
} as const;

export const rosterDetailsBulkActionsEnum = {
  none: 'none',
  deleteMembers: 'deleteMembers',
} as const;

export const rostersBulkActionsEnum = {
  none: 'none',
  deleteRosters: 'deleteRosters',
  toggleDisabledRosters: 'toggleDisabledRosters',
  sendNotifications: 'sendNotifications',
} as const;
