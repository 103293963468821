import React from 'react';
import { voucherOrdersUrl } from 'constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const VoucherOrdersLink = React.memo(() => (
  <MenuTile
    title={'Voucher Orders'}
    url={voucherOrdersUrl}
    icon={'ticket'}
    isMaterialIcon={false}
  />
));

export default VoucherOrdersLink;
