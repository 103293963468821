import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { decorationMethodsOptions } from '@constants/options/optionsValues';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { dropdownSizeS } from '@constants/values';
import {
  getColoredStyles,
  getBrands,
  getCategories,
} from '@redux/productCatalog/actions';
import { fetchTeamColors } from '@redux/support/actions';
import Pagination from '@sharedComponents/Pagination';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import ColoredStyleCard from './ColoredStyleCard';

class ColoredStylesTable extends Component {
  state = {
    pageNumber: 1,
    pageSize: 14,
    searchInput: '',
    colors: [],
    categories: [],
    brands: [],
    decorations: [],
    lockerColors: this.props.teamColors &&
      this.props.teamColors.filter((c) => (this.props.lockerColorCodes || []).includes(c.code)),
  };

  componentDidMount() {
    const {
      dispatch,
      selectedColoredStyle,
    } = this.props;

    dispatch(fetchTeamColors());
    dispatch(getBrands());
    dispatch(getCategories());

    if (selectedColoredStyle) {
      const styleFilter = selectedColoredStyle.style.code;
      this.setStyleFilter(styleFilter);
    } else {
      this.search();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      teamColors,
      lockerColorCodes,
    } = this.props;

    if (teamColors
      && lockerColorCodes
      && (prevProps.lockerColorCodes !== lockerColorCodes
      || prevProps.teamColors !== teamColors)) {
      const lockerColors = teamColors.filter((c) => lockerColorCodes.includes(c.code));
      this.setState(() => ({ lockerColors }));
    }
  }

  setStyleFilter = (styleFilter) => {
    this.setState({
      searchInput: styleFilter,
    }, this.search);
  };

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  colorChange = (colors) => {
    if (colors) {
      this.setState({ colors }, this.search);
    }
  };

  brandChange = (brands) => {
    if (brands) {
      this.setState({ brands }, this.search);
    }
  };

  categoryChange = (categories) => {
    if (categories) {
      this.setState({ categories }, this.search);
    }
  };

  decorationChange = (decorations) => {
    if (decorations) {
      this.setState({ decorations }, this.search);
    }
  };

  search = () => {
    const { dispatch } = this.props;

    const {
      pageSize,
      colors,
      categories,
      brands,
      decorations,
      searchInput,
    } = this.state;

    dispatch(getColoredStyles(
      1,
      pageSize,
      brands,
      categories,
      colors,
      [],
      decorations,
      [],
      searchInput,
      '',
      '',
      true,
      ''
    ));
  };

  toggleRecommendedItems = () => {
    this.setState((prevState) => ({
      recommended: !prevState.recommended,
    }), this.search);
  };

  selectPage = (page) => {
    this.setState({ pageNumber: page }, () => {
      const { dispatch } = this.props;

      const {
        pageNumber,
        pageSize,
        brands,
        categories,
        colors,
        decorations,
        searchInput,
      } = this.state;

      dispatch(getColoredStyles(
        pageNumber,
        pageSize,
        brands,
        categories,
        colors,
        [],
        decorations,
        [],
        searchInput,
        '',
        '',
        true,
        ''
      ));
    });
  };

  render() {
    const {
      searchInput,
      lockerColors,
    } = this.state;

    const {
      queue,
      allBrands,
      allCategories,
      teamColors,
      totalPages,
      pageNumber,
      selectColoredStyle,
    } = this.props;

    let tableColoredStyles = [];
    if (queue) {
      tableColoredStyles = queue.map((cs) => (
        <ColoredStyleCard
          key={cs.id}
          coloredStyle={cs}
          onClickOnColoredStyle={selectColoredStyle}
        />
      ));
    }

    return (
      <>
        <div className='add-product__table'>
          <div className='space-between'>
            <div className='flex'>
              <MultiSelectDropdown
                objects={allCategories}
                itemText={'categories'}
                updateCallback={this.categoryChange}
                textKey={'name'}
                valueKey={'id'}
                classNames={'margin-right'}
              />
              <MultiSelectDropdown
                objects={allBrands}
                itemText={'brands'}
                updateCallback={this.brandChange}
                textKey={'name'}
                valueKey={'id'}
                classNames={'margin-right'}
              />
              <MultiSelectDropdown
                objects={teamColors}
                itemText={'colors'}
                updateCallback={this.colorChange}
                textKey={'code'}
                valueKey={'code'}
                classNames={'margin-right'}
                color={true}
              />
              <MultiSelectDropdown
                objects={decorationMethodsOptions}
                itemText={'decorations'}
                updateCallback={this.decorationChange}
                textKey={'name'}
                valueKey={'value'}
                size={dropdownSizeS}
                classNames={'margin-right'}
              />
              <MultiSelectDropdown
                objects={lockerColors}
                itemText={'locker colors'}
                updateCallback={this.colorChange}
                textKey={'code'}
                valueKey={'code'}
                classNames={'margin-right'}
                color={true}
              />
            </div>
            <SearchFilter
              initialValue={searchInput}
              search={this.filterKey}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='add-product__table--products custom-scrollbar'>
            {tableColoredStyles}
          </div>
          <div className='add-product__table--paging'>
            <Pagination
              totalPages={totalPages}
              pageNumber={pageNumber}
              selectPage={this.selectPage}
              range={5}
            />
          </div>
        </div>
      </>
    );
  }
}

ColoredStylesTable.propTypes = {
  totalPages: PropTypes.number,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  queue: PropTypes.arrayOf(PropTypes.object),
  lockerId: PropTypes.number.isRequired,
  allCategories: PropTypes.array.isRequired,
  allBrands: PropTypes.array.isRequired,
  selectColoredStyle: PropTypes.func.isRequired,
  lockerColorCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedColoredStyle: PropTypes.object,
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({
  lockerManager,
  productCatalog,
  support,
}) => ({
  totalPages: productCatalog.coloredStylesQueue.totalPages,
  hasPreviousPage: productCatalog.coloredStylesQueue.hasPreviousPage,
  hasNextPage: productCatalog.coloredStylesQueue.hasNextPage,
  pageNumber: productCatalog.coloredStylesQueue.pageNumber,
  pageSize: productCatalog.coloredStylesQueue.pageSize,
  queue: productCatalog.coloredStylesQueue.queue,
  allBrands: productCatalog.brands,
  allCategories: productCatalog.categories,
  lockerColorCodes: lockerManager.lockerColors,
  teamColors: support.teamColors,
});

export default connect(mapStateToProps)(ColoredStylesTable);
