export const getRecipientAddress = (recipient) => {
  let address = [];
  address.push.apply(address, [
    recipient.addressName,
    recipient.addressLine1,
    recipient.addressLine2,
    recipient.city,
    recipient.state,
    recipient.zip,
    recipient.country,
  ]);
  address = address.filter(Boolean);

  if (address.length < 1) {
    return '-';
  }

  return address.join(', ');
};
