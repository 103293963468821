import React, {
  useCallback, useState,
} from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import PrintRoomOrderRejectTable from './PrintRoomOrderRejectTable';
import {
  ArtworkTaskReworkDto,
  LogoItem,
} from '@api/fulfillment/models';
import { printRoomUrl } from '@constants/clientUrls/clientUrls';
import {
  materialSwal, navigateToPage,
} from '@util/componentHelper';
import { HagReworkLogoItems } from './LogoRejectionItem';
import { putHomefieldApiHagPrintroomreworkartwork as reworkArtwork } from '@api/fulfillment/hag';

interface OwnProps {
  orderNumber: number;
  data: LogoItem[];
  isOpen: boolean;
  onClose: () => void;
}

type Props = OwnProps;

const PrintRoomOrderRejectModal = React.memo<Props>(({
  orderNumber,
  data,
  isOpen,
  onClose,
}) => {
  const [
    logos,
    setLogos,
  ] = useState<HagReworkLogoItems[]>([]);
  const [
    selectedItems,
    setSelectedItems,
  ] = useState<HagReworkLogoItems[]>([]);
  const [
    isPageSelected,
    setIsPageSelected,
  ] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    if (!data) {
      return;
    }
    const rejectionLogos: HagReworkLogoItems[] = [];
    for (const logo of data) {
      const rejectionLogo = rejectionLogos.find((x) => x.artworkTaskId === logo.artworkTaskId);
      if (rejectionLogo) {
        rejectionLogo.quantity = (rejectionLogo.quantity ?? 0) + (logo.quantity ?? 0);
        if (!logo.decorationIds) {
          continue;
        }
        for (const decorationId of logo.decorationIds) {
          const decorationIds = rejectionLogo.decorationIds?.find((x) => x === decorationId);
          if (decorationIds === undefined) {
            rejectionLogo?.decorationIds?.push(decorationId);
          }
        }
      } else {
        rejectionLogos.push({
          logoId: logo.logoId,
          previewUrl: logo.previewUrl,
          quantity: logo.quantity,
          decorationLocation: logo.decorationLocation,
          decorationHeight: logo.decorationHeight,
          decorationWidth: logo.decorationWidth,
          decorationIds: logo.decorationIds,
          artworkTaskId: logo.artworkTaskId,
        });
      }
    }
    setLogos(rejectionLogos);
  }, [data]);

  const isSelected = useCallback((key: any) => {
    const logo = selectedItems.find((x) => x.artworkTaskId === key);
    if (!logo) {
      return true;
    }

    return false;
  }, [selectedItems]);

  const updateFailNotes = useCallback((artworkTaskId, value) => {
    const logo = logos.find((x) => x.artworkTaskId === artworkTaskId);
    if (logo) {
      logo.notes = value;
    }
  }, [logos]);

  const updateFailReason = useCallback((artworkTaskId, value) => {
    const logo = logos.find((x) => x.artworkTaskId === artworkTaskId);
    if (logo) {
      logo.reason = value;
    }
  }, [logos]);

  const checkFilled = useCallback((l: HagReworkLogoItems[]) => {
    let filled = true;
    l.forEach((logo) => {
      if (!logo.reason || !logo.notes) {
        filled = false;
      }
    });

    return filled;
  }, []);

  const onCloseModal = useCallback(() => {
    setLogos([]);
    setSelectedItems([]);
    setIsPageSelected(false);
    onClose();
  }, [onClose]);

  const onRejectConfirm = useCallback(async () => {
    if (!checkFilled(selectedItems)) {
      materialSwal('Reasons not filled', '', 'warning');

      return;
    }
    const reworks: ArtworkTaskReworkDto[] = [];
    for (const logo of selectedItems) {
      reworks.push({
        artworkTaskId: Number(logo.artworkTaskId),
        notes: logo.notes,
        reason: logo.reason,
      });
    }
    const res = await reworkArtwork({ artworkTaskReworks: reworks });
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      navigateToPage(printRoomUrl);
    }
    onCloseModal();
  }, [
    onCloseModal,
    checkFilled,
    selectedItems,
  ]);

  const updateSelection = useCallback((
    newSelectedItems: Array<HagReworkLogoItems>,
    newIsPageSelected: boolean
  ) => {
    setSelectedItems(newSelectedItems);
    setIsPageSelected(newIsPageSelected);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onCloseModal}
      modalSize={'xl'}
      buttons={(
        <ModalButtons
          onClose={onCloseModal}
          onConfirm={onRejectConfirm}
          confirmBtnText={'Reject'}
          isDangerousAction={true}
          confirmBtnDisabled={selectedItems.length === 0}
        />
      )}
    >
      <div className='align align--center'>
        <PrintRoomOrderRejectTable
          orderNumber={orderNumber}
          data={logos}
          onFetchData={fetchData}
          isSelected={isSelected}
          updateFailNotes={updateFailNotes}
          updateFailReason={updateFailReason}
          isPageSelected={isPageSelected}
          updateSelection={updateSelection}
          selectedItems={selectedItems}
        />
      </div>
    </Modal>
  );
});

export default PrintRoomOrderRejectModal;
