import React from 'react';

interface OwnProps {
  title: string | JSX.Element;
  classes?: string;
}

type Props = OwnProps;

const TableQuickViewTitle = React.memo<Props>(({
  title,
  classes = '',
}) => (
  <div className={`tableView__details--header--title ${classes}`}>
    {title}
  </div>
));

export default TableQuickViewTitle;
