import React from 'react';
import { mapToOptionsList } from '@util/mappingHelper';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import { PrinterModel } from '@models/PrintRoomNew/PrinterModel';

interface OwnProps {
  filter: (value: string) => void;
  printerNumbers: PrinterModel[];
  defaultValue?: string;
}

type Props = OwnProps;

const PrinterNumberFilter = React.memo<Props>(({
  filter,
  printerNumbers,
  defaultValue,
}) => {
  const usersOptions = mapToOptionsList<string, any>({
    list: printerNumbers,
    key: 'id',
    value: 'id',
    name: 'id',
    emptyOption: {
      name: 'Printer Number (All)',
    },
  });

  return (
    <div className='filter-group margin-left'>
      <Dropdown<string>
        options={usersOptions}
        onChange={filter}
        defaultValue={defaultValue}
      />
    </div>
  );
});

export default PrinterNumberFilter;
