/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  ProductionAssemblyOrderHeaderDto,
  GetHomefieldApiProductionassemblynewOrdersheaderssearchParams,
  ProductionAssemblyItemGroupDto,
  GetHomefieldApiProductionassemblynewOrderitemssearchbydecorationParams,
  GetHomefieldApiProductionassemblynewOrderitemssearchParams,
  ApiResponse,
  MarkOrderItemDecorationsAsDecoratedCommand,
  UnmarkOrderItemDecorationsAsDecoratedCommand,
  OrderItemDecorationsFlagOrderItemDecorationCommand,
  OrderItemDecorationsUnflagOrderItemDecorationCommand,
  OrderItemStatusEnumItemStatusModel,
  DecorationStatusEnumItemStatusModel,
  FlagDto,
  OrderItemMarkOrderItemPreTreatedCommand,
  OrderItemUnmarkOrderItemPreTreatedCommand,
  OrderItemMarkOrderItemWaterproofedCommand,
  UnmarkOrderItemWaterproofedCommand,
  OrderItemMarkOrderItemCheckedInCommand,
  OrderItemUnmarkOrderItemCheckedInCommand,
  OrderItemDecorationsMarkOrderItemDecorationCheckedInCommand,
  OrderItemDecorationsUnmarkOrderItemDecorationCheckedInCommand,
  OrderItemMarkOrderItemQualityCheckedCommand,
  OrderItemUnmarkOrderItemQualityCheckedCommand,
  MarkOrderItemOnHoldCommand,
  OrderItemUpdateOrderItemBarcodeCommand,
  ActivityTrackerCountsDto,
  GetHomefieldApiProductionassemblynewActivitytrackerParams,
  OverrideOrderItemStatusCommand,
  MarkOrderItemDecorationsAsAssembledCommand,
  MarkAllOrderItemDecorationsAsAssembledCommand
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiProductionassemblynewOrdersheaderssearch = <TData = ProductionAssemblyOrderHeaderDto>(
    params?: GetHomefieldApiProductionassemblynewOrdersheaderssearchParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders-headers.search`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblynewOrdersheaderssearchQueryKey = (params?: GetHomefieldApiProductionassemblynewOrdersheaderssearchParams,) => [`/homefield/api/production-assembly-new/orders-headers.search`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiProductionassemblynewOrdersheaderssearch = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblynewOrdersheaderssearch, ProductionAssemblyOrderHeaderDto>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiProductionassemblynewOrdersheaderssearchParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblynewOrdersheaderssearchQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblynewOrdersheaderssearch<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiProductionassemblynewOrderitemssearchbydecoration = <TData = ProductionAssemblyItemGroupDto[]>(
    params?: GetHomefieldApiProductionassemblynewOrderitemssearchbydecorationParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-items.search-by-decoration`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblynewOrderitemssearchbydecorationQueryKey = (params?: GetHomefieldApiProductionassemblynewOrderitemssearchbydecorationParams,) => [`/homefield/api/production-assembly-new/order-items.search-by-decoration`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiProductionassemblynewOrderitemssearchbydecoration = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblynewOrderitemssearchbydecoration, ProductionAssemblyItemGroupDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiProductionassemblynewOrderitemssearchbydecorationParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblynewOrderitemssearchbydecorationQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblynewOrderitemssearchbydecoration<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiProductionassemblynewOrderitemssearch = <TData = ProductionAssemblyItemGroupDto[]>(
    params?: GetHomefieldApiProductionassemblynewOrderitemssearchParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-items.search`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblynewOrderitemssearchQueryKey = (params?: GetHomefieldApiProductionassemblynewOrderitemssearchParams,) => [`/homefield/api/production-assembly-new/order-items.search`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiProductionassemblynewOrderitemssearch = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblynewOrderitemssearch, ProductionAssemblyItemGroupDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiProductionassemblynewOrderitemssearchParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblynewOrderitemssearchQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblynewOrderitemssearch<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiProductionassemblynewOrderitemdecorationsmarkasdecorated = <TData = ApiResponse>(
    markOrderItemDecorationsAsDecoratedCommand: MarkOrderItemDecorationsAsDecoratedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-item-decorations.mark-as-decorated`, method: 'put',
      data: markOrderItemDecorationsAsDecoratedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkasdecorated = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrderitemdecorationsmarkasdecorated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: MarkOrderItemDecorationsAsDecoratedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: MarkOrderItemDecorationsAsDecoratedCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiProductionassemblynewOrderitemdecorationsmarkasdecorated<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrderitemdecorationsunmarkasdecorated = <TData = ApiResponse>(
    unmarkOrderItemDecorationsAsDecoratedCommand: UnmarkOrderItemDecorationsAsDecoratedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-item-decorations.unmark-as-decorated`, method: 'put',
      data: unmarkOrderItemDecorationsAsDecoratedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrderitemdecorationsunmarkasdecorated = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrderitemdecorationsunmarkasdecorated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UnmarkOrderItemDecorationsAsDecoratedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UnmarkOrderItemDecorationsAsDecoratedCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiProductionassemblynewOrderitemdecorationsunmarkasdecorated<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrderitemdecorationsflag = <TData = ApiResponse>(
    orderItemDecorationsFlagOrderItemDecorationCommand: OrderItemDecorationsFlagOrderItemDecorationCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-item-decorations.flag`, method: 'put',
      data: orderItemDecorationsFlagOrderItemDecorationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrderitemdecorationsflag = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrderitemdecorationsflag,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: OrderItemDecorationsFlagOrderItemDecorationCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: OrderItemDecorationsFlagOrderItemDecorationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiProductionassemblynewOrderitemdecorationsflag<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrderitemdecorationsunflag = <TData = ApiResponse>(
    orderItemDecorationsUnflagOrderItemDecorationCommand: OrderItemDecorationsUnflagOrderItemDecorationCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-item-decorations.unflag`, method: 'put',
      data: orderItemDecorationsUnflagOrderItemDecorationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrderitemdecorationsunflag = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrderitemdecorationsunflag,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: OrderItemDecorationsUnflagOrderItemDecorationCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: OrderItemDecorationsUnflagOrderItemDecorationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiProductionassemblynewOrderitemdecorationsunflag<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsStatuses = <TData = OrderItemStatusEnumItemStatusModel[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items/statuses`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsStatusesQueryKey = (orderNumber: number,) => [`/homefield/api/production-assembly-new/orders/${orderNumber}/order-items/statuses`]

    
export const useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsStatuses = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsStatuses, OrderItemStatusEnumItemStatusModel[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsStatusesQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsStatuses<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsStatuses = <TData = DecorationStatusEnumItemStatusModel[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-item-decorations/statuses`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsStatusesQueryKey = (orderNumber: number,) => [`/homefield/api/production-assembly-new/orders/${orderNumber}/order-item-decorations/statuses`]

    
export const useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsStatuses = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsStatuses, DecorationStatusEnumItemStatusModel[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsStatusesQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsStatuses<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsFlags = <TData = FlagDto[]>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-item-decorations/flags`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsFlagsQueryKey = (orderNumber: number,) => [`/homefield/api/production-assembly-new/orders/${orderNumber}/order-item-decorations/flags`]

    
export const useGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsFlags = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsFlags, FlagDto[]>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsFlagsQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsFlags<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkpretreated = <TData = ApiResponse>(
    orderNumber: number,
    orderItemMarkOrderItemPreTreatedCommand: OrderItemMarkOrderItemPreTreatedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.mark-pre-treated`, method: 'put',
      data: orderItemMarkOrderItemPreTreatedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkpretreated = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkpretreated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemMarkOrderItemPreTreatedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemMarkOrderItemPreTreatedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkpretreated<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkpretreated = <TData = ApiResponse>(
    orderNumber: number,
    orderItemUnmarkOrderItemPreTreatedCommand: OrderItemUnmarkOrderItemPreTreatedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.unmark-pre-treated`, method: 'put',
      data: orderItemUnmarkOrderItemPreTreatedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkpretreated = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkpretreated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemUnmarkOrderItemPreTreatedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemUnmarkOrderItemPreTreatedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkpretreated<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkwaterproofed = <TData = ApiResponse>(
    orderNumber: number,
    orderItemMarkOrderItemWaterproofedCommand: OrderItemMarkOrderItemWaterproofedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.mark-waterproofed`, method: 'put',
      data: orderItemMarkOrderItemWaterproofedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkwaterproofed = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkwaterproofed,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemMarkOrderItemWaterproofedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemMarkOrderItemWaterproofedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkwaterproofed<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkwaterproofed = <TData = ApiResponse>(
    orderNumber: number,
    unmarkOrderItemWaterproofedCommand: UnmarkOrderItemWaterproofedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.unmark-waterproofed`, method: 'put',
      data: unmarkOrderItemWaterproofedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkwaterproofed = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkwaterproofed,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: UnmarkOrderItemWaterproofedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: UnmarkOrderItemWaterproofedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkwaterproofed<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin = <TData = ApiResponse>(
    orderNumber: number,
    orderItemMarkOrderItemCheckedInCommand: OrderItemMarkOrderItemCheckedInCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.mark-checked-in`, method: 'put',
      data: orderItemMarkOrderItemCheckedInCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemMarkOrderItemCheckedInCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemMarkOrderItemCheckedInCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkcheckedin = <TData = ApiResponse>(
    orderNumber: number,
    orderItemUnmarkOrderItemCheckedInCommand: OrderItemUnmarkOrderItemCheckedInCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.unmark-checked-in`, method: 'put',
      data: orderItemUnmarkOrderItemCheckedInCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkcheckedin = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkcheckedin,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemUnmarkOrderItemCheckedInCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemUnmarkOrderItemCheckedInCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkcheckedin<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsmarkcheckedin = <TData = ApiResponse>(
    orderNumber: number,
    orderItemDecorationsMarkOrderItemDecorationCheckedInCommand: OrderItemDecorationsMarkOrderItemDecorationCheckedInCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-item-decorations.mark-checked-in`, method: 'put',
      data: orderItemDecorationsMarkOrderItemDecorationCheckedInCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsmarkcheckedin = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsmarkcheckedin,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemDecorationsMarkOrderItemDecorationCheckedInCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemDecorationsMarkOrderItemDecorationCheckedInCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsmarkcheckedin<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsunmarkcheckedin = <TData = ApiResponse>(
    orderNumber: number,
    orderItemDecorationsUnmarkOrderItemDecorationCheckedInCommand: OrderItemDecorationsUnmarkOrderItemDecorationCheckedInCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-item-decorations.unmark-checked-in`, method: 'put',
      data: orderItemDecorationsUnmarkOrderItemDecorationCheckedInCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsunmarkcheckedin = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsunmarkcheckedin,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemDecorationsUnmarkOrderItemDecorationCheckedInCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemDecorationsUnmarkOrderItemDecorationCheckedInCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemdecorationsunmarkcheckedin<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkqualitychecked = <TData = ApiResponse>(
    orderNumber: number,
    orderItemMarkOrderItemQualityCheckedCommand: OrderItemMarkOrderItemQualityCheckedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.mark-quality-checked`, method: 'put',
      data: orderItemMarkOrderItemQualityCheckedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkqualitychecked = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkqualitychecked,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemMarkOrderItemQualityCheckedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemMarkOrderItemQualityCheckedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkqualitychecked<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkqualitychecked = <TData = ApiResponse>(
    orderNumber: number,
    orderItemUnmarkOrderItemQualityCheckedCommand: OrderItemUnmarkOrderItemQualityCheckedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.unmark-quality-checked`, method: 'put',
      data: orderItemUnmarkOrderItemQualityCheckedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkqualitychecked = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkqualitychecked,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemUnmarkOrderItemQualityCheckedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemUnmarkOrderItemQualityCheckedCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkqualitychecked<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkonhold = <TData = ApiResponse>(
    orderNumber: number,
    markOrderItemOnHoldCommand: MarkOrderItemOnHoldCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items.mark-on-hold`, method: 'put',
      data: markOrderItemOnHoldCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkonhold = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkonhold,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: MarkOrderItemOnHoldCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: MarkOrderItemOnHoldCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkonhold<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsBarcodes = <TData = ApiResponse>(
    orderNumber: number,
    orderItemUpdateOrderItemBarcodeCommand: OrderItemUpdateOrderItemBarcodeCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order-items/barcodes`, method: 'put',
      data: orderItemUpdateOrderItemBarcodeCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsBarcodes = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsBarcodes,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number;data: OrderItemUpdateOrderItemBarcodeCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number;data: OrderItemUpdateOrderItemBarcodeCommand}, TContext>((props) => {
        const {orderNumber,data} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsBarcodes<TData>(orderNumber,data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrdersOrderNumberOrdermarkshippingready = <TData = ApiResponse>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/orders/${orderNumber}/order.mark-shipping-ready`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrdermarkshippingready = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrdersOrderNumberOrdermarkshippingready,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{orderNumber: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {orderNumber: number}, TContext>((props) => {
        const {orderNumber} = props || {};

        return  putHomefieldApiProductionassemblynewOrdersOrderNumberOrdermarkshippingready<TData>(orderNumber,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiProductionassemblynewActivitytracker = <TData = ActivityTrackerCountsDto>(
    params?: GetHomefieldApiProductionassemblynewActivitytrackerParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/activity-tracker`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiProductionassemblynewActivitytrackerQueryKey = (params?: GetHomefieldApiProductionassemblynewActivitytrackerParams,) => [`/homefield/api/production-assembly-new/activity-tracker`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiProductionassemblynewActivitytracker = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiProductionassemblynewActivitytracker, ActivityTrackerCountsDto>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiProductionassemblynewActivitytrackerParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiProductionassemblynewActivitytrackerQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiProductionassemblynewActivitytracker<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiProductionassemblynewOrderitemstatusoverride = <TData = ApiResponse>(
    overrideOrderItemStatusCommand: OverrideOrderItemStatusCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-item-status.override`, method: 'put',
      data: overrideOrderItemStatusCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrderitemstatusoverride = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrderitemstatusoverride,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: OverrideOrderItemStatusCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: OverrideOrderItemStatusCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiProductionassemblynewOrderitemstatusoverride<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrderitemdecorationsmarkasassembled = <TData = ApiResponse>(
    markOrderItemDecorationsAsAssembledCommand: MarkOrderItemDecorationsAsAssembledCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-item-decorations.mark-as-assembled`, method: 'put',
      data: markOrderItemDecorationsAsAssembledCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkasassembled = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrderitemdecorationsmarkasassembled,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: MarkOrderItemDecorationsAsAssembledCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: MarkOrderItemDecorationsAsAssembledCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiProductionassemblynewOrderitemdecorationsmarkasassembled<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiProductionassemblynewOrderitemdecorationsmarkallasassembled = <TData = ApiResponse>(
    markAllOrderItemDecorationsAsAssembledCommand: MarkAllOrderItemDecorationsAsAssembledCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/production-assembly-new/order-item-decorations.mark-all-as-assembled`, method: 'put',
      data: markAllOrderItemDecorationsAsAssembledCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkallasassembled = <TData = AsyncReturnType<typeof putHomefieldApiProductionassemblynewOrderitemdecorationsmarkallasassembled,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: MarkAllOrderItemDecorationsAsAssembledCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: MarkAllOrderItemDecorationsAsAssembledCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiProductionassemblynewOrderitemdecorationsmarkallasassembled<TData>(data,requestOptions)
      }, mutationOptions)
    }
    