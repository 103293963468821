import React from 'react';
import PropTypes from 'prop-types';
import ColorListSelect from '@sharedComponents/UploadLogoSwatchColors/ColorListSelect';

const ApprovedColors = ({
  allDarkColors,
  allLightColors,
  approvedLightColors,
  approvedDarkColors,
  onChangeColors,
  onSelectAllColorsFromList,
  onUnselectAllColorsFromList,
  changeHoveredColor,
}) => (
  <div className='logo-review__task__color-container'>
    <div className='mr-20 w-50'>
      <div className='logo-bank__upload-modal--title'>
        <div>Approved Light Colors</div>
        <div>
          <span
            className='logo-bank__upload-modal--title__btn'
            onClick={onSelectAllColorsFromList.bind(null, 'approvedLightColors')}
          >
              All
          </span>
          <span
            className='logo-bank__upload-modal--title__btn'
            onClick={onUnselectAllColorsFromList.bind(null, 'approvedLightColors')}
          >
              None
          </span>
        </div>
      </div>

      <div>
        <ColorListSelect
          allColors={allLightColors}
          selectedColors={approvedLightColors}
          toggleSelectColor={onChangeColors.bind(null, 'approvedLightColors')}
          setHoveredColor={changeHoveredColor}
        />
      </div>
    </div>

    <div className='w-50'>
      <div className='logo-bank__upload-modal--title'>
        <div>Approved Dark Colors</div>
        <div>
          <span
            className='logo-bank__upload-modal--title__btn'
            onClick={onSelectAllColorsFromList.bind(null, 'approvedDarkColors')}
          >
              All
          </span>
          <span
            className='logo-bank__upload-modal--title__btn'
            onClick={onUnselectAllColorsFromList.bind(null, 'approvedDarkColors')}
          >
              None
          </span>
        </div>
      </div>
      <div>
        <ColorListSelect
          allColors={allDarkColors}
          selectedColors={approvedDarkColors}
          toggleSelectColor={onChangeColors.bind(null, 'approvedDarkColors')}
          setHoveredColor={changeHoveredColor}
        />
      </div>
    </div>
  </div>
);

ApprovedColors.propTypes = {
  approvedLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  approvedDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  onChangeColors: PropTypes.func.isRequired,
  onSelectAllColorsFromList: PropTypes.func.isRequired,
  onUnselectAllColorsFromList: PropTypes.func.isRequired,
  changeHoveredColor: PropTypes.func.isRequired,
};

export default ApprovedColors;
