import React, {
  useCallback, useState,
} from 'react';
import { PrintAssemblyTabsEnum } from './PrintAssemblyTabsEnum';
import { Link } from 'react-router-dom';
import { printRoomOrderUrl } from '@constants/clientUrls/clientUrls';
import { decorationIdRegex } from '@constants/common';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { HagPrintAssemblyOrderDetailsDto } from '@api/fulfillment/models';
import FinalizeButton from './Buttons/FinalizeButton';

interface OwnProps {
  orderNumber: number;
  selectedTab: PrintAssemblyTabsEnum;
  onFinalizedClicked: (hagPrintRoomJobId: number | undefined) => void;
  searchByDecorationId: (e: number | undefined) => void;
  onMarkAllAsDone: () => void;
  orderDetails: HagPrintAssemblyOrderDetailsDto | undefined;
}

type Props = OwnProps;

const PrintAssemblyActions = React.memo<Props>((
  {
    orderNumber,
    selectedTab,
    onFinalizedClicked,
    searchByDecorationId,
    onMarkAllAsDone,
    orderDetails,
  }
) => {
  const [
    searchValue,
    setSearchValue,
  ] = useState('');

  const handleSearch = useCallback(() => {
    if (!searchValue || searchValue.length === 0) {
      searchByDecorationId(undefined);

      return;
    }

    const rgxMatch = decorationIdRegex.exec(searchValue);

    const decId = Number(rgxMatch?.groups?.decorationId);
    if (Number.isNaN(decId)) {
      searchByDecorationId(undefined);

      return;
    }

    searchByDecorationId(decId);
  }, [
    searchByDecorationId,
    searchValue,
  ]);

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => setSearchValue(e.target.value ?? ''), []);

  const handleSearchEnterKey: React.KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    handleSearch();
  }, [handleSearch]);

  return (
    <div className='sheet__header'>
      <div className='sheet__header-left'>
        <Link
          to={printRoomOrderUrl(orderNumber)}
          className='button button--link button--underline'
        >
          <span className='margin margin--right-s'>
            <i className='material-icons'>keyboard_arrow_left</i>
          </span>
          {`Order ${orderNumber}`}
        </Link>
      </div>
      {
        selectedTab === PrintAssemblyTabsEnum.Done &&
        <ul className='sheet__header-right'>
          <li>
            {
              orderDetails?.isFinalized
                ? (
                  <div className='button--link'>
                    Finalized
                  </div>
                )
                : (
                  <FinalizeButton
                    hagPrintRoomJobId={orderDetails?.hagPrintRoomJobId}
                    onClick={onFinalizedClicked}
                    disabled={!orderDetails?.canFinalize}
                  />
                )
            }
          </li>
        </ul>
      }

      {
        selectedTab === PrintAssemblyTabsEnum.ToBePrintAssembled &&
        <ul className='sheet__header-right'>
          <li>
            <div className='text-field flex'>
              <input
                className='has-icon--right w-320'
                type='text'
                placeholder='Decoration Id'
                onKeyDown={handleSearchEnterKey}
                value={searchValue}
                onChange={handleSearchChange}
              />
              <i
                className='material-icons text-field__icon--right cursor-pointer'
                onClick={handleSearch}
              >
                arrow_forward
              </i>
            </div>
          </li>
          <li>
            <button
              onClick={onMarkAllAsDone}
              className='button button--color-gossamer button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase'
            >
              Mark All As Done
            </button>
          </li>
        </ul>
      }
    </div>
  );
});

export default PrintAssemblyActions;
