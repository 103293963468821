export const validateApplicationSettings = (values) => {
  const errors = {};

  const {
    workerCount,
    artworkTerminalId,
    schedulingTerminalId,
  } = values;

  if (!workerCount) {
    errors.workerCount = ['WorkerCount is required.'];
  } else {
    const { value } = workerCount;
    const workerCountErrors = {};
    if (!value) {
      workerCountErrors.value = ['The value for worker count is required.'];
      errors.workerCount = workerCountErrors;
    } else if (isNaN(value)) {
      workerCountErrors.value = ['The value for worker count has to be a number.'];
      errors.workerCount = workerCountErrors;
    }
  }

  if (!artworkTerminalId) {
    errors.artworkTerminalId = ['Artwork Terminal is required.'];
  }

  if (!schedulingTerminalId) {
    errors.schedulingTerminalId = ['Scheduling Terminal is required.'];
  }

  return errors;
};

export const validateDtgStations = (values) => {
  const errors = {};
  if (!values.stationIdentifier) {
    errors.stationIdentifier = ['Station Identifier is required'];
  }

  if (!values.stationDisplayName) {
    errors.stationDisplayName = ['Station Display Name is required'];
  }

  return errors;
};

export const validateDtgTabletProfiles = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = ['Name is required'];
  }

  if (values.name && values.name.length > 500) {
    errors.name = ['Name must be less or equal to 500 characters'];
  }

  if (values.note && values.note.length > 4000) {
    errors.note = ['Note must be less or equal to 4000 characters'];
  }

  if (values.passesUnderbase && values.passesUnderbase > 500) {
    errors.passesUnderbase = ['Extra underbase passes must be less or equal to 500'];
  }

  if (values.passesColor && values.passesColor > 500) {
    errors.passesUnderbase = ['Extra color passes must be less or equal to 500'];
  }

  if (values.minutes < 0) {
    errors.minutes = ['Minutes must be positive'];
  }

  if (values.seconds < 0) {
    errors.seconds = ['Seconds must be positive'];
  }

  if (values.miliseconds < 0) {
    errors.miliseconds = ['Miliseconds must be positive'];
  }

  if (values.minutes > 180) {
    errors.minutes = ['Minutes must be less or equal to 180'];
  }

  if (values.seconds >= 60) {
    errors.seconds = ['Seconds must be less than 60'];
  }

  if (values.miliseconds >= 1000) {
    errors.miliseconds = ['Miliseconds must be less than 1000'];
  }

  return errors;
};
