import React from 'react';
import { Link } from 'react-router-dom';

interface OwnProps {
  type: string;
  text: string;
  disabled?: boolean;
  classes?: string;
  linkTo?: string;
  openInNewTab?: boolean;
  linkClasses?: string;
  icon?: Nullable<JSX.Element>;
  size?: string;
}

type Props = OwnProps;

const ButtonLink = React.memo(({
  type,
  text,
  disabled = false,
  classes = '',
  linkTo = '',
  openInNewTab = false,
  linkClasses = '',
  icon = null,
  size = 'm',
}: Props) => {
  let btnTypeClasses = '';

  switch (type) {
    case 'primary':
      btnTypeClasses = 'btn-primary btn-primary--green';
      break;
    case 'secondary':
      btnTypeClasses = 'btn__secondary uppercase';
      break;
    case 'warning':
      btnTypeClasses = 'btn-primary btn-primary--orange';
      break;
    case 'danger':
      btnTypeClasses = 'btn-primary btn-primary--red';
      break;
  }

  const className = `${btnTypeClasses} ${classes} ${icon ? 'align__center' : ''} btn-primary--size-${size}`;

  const button = (
    <button
      type='button'
      className={className}
      disabled={disabled}
    >
      <>
        {
          icon &&
          <span className='mr-10'>
            {icon}
          </span>
        }
        {text}
      </>
    </button>
  );

  if (openInNewTab) {
    return (
      <a
        className={linkClasses}
        href={linkTo}
        target='_blank'
        rel='noreferrer'
      >
        {button}
      </a>
    );
  }

  return (
    <Link
      to={linkTo}
      className={linkClasses}
    >
      {button}
    </Link>
  );
});

export default ButtonLink;
