import React from 'react';
import { formatDollarAmount } from '@util/numberHelpers';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ExpandablePriceTab from '@components/shared/Display/ExpandablePriceTab';
import { VoucherOrderDetailDto } from '@api/financialServices/models';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  voucherOrder: VoucherOrderDetailDto;
}

type Props = OwnProps;

const VoucherFinancialsModal = React.memo<Props>(({
  voucherOrder,
  closeModal,
  isOpen,
}) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    modalSize={'l'}
    title={`Voucher Order V${voucherOrder.id} Financials`}
    buttons={(
      <ModalButtons
        onClose={closeModal}
        displayConfirmBtn={false}
        cancelBtnText={'Close'}
      />
    )}
  >
    <>
      <ExpandablePriceTab
        label={'Vouchers'}
        price={voucherOrder.pricePaid}
        classes={'mt-20'}
        content={(
          <div className='order-financial-details__content'>
            <div className='justify__space-between'>
              <span>Subtotal ({voucherOrder.totalInstanceCount} vouchers)</span>
              <span className='positive-amount'>{formatDollarAmount(voucherOrder.totalAmount)}</span>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Discount</span>
              <span className='negative-amount'>- {formatDollarAmount(voucherOrder.discountAmount)}</span>
            </div>
            <div className='justify__space-between mt-15'>
              <span>Refund</span>
              <span className='refund'>
                - {formatDollarAmount(
                  (voucherOrder.refundAmount ?? 0) + (voucherOrder.voucherInstancesTotalRefundAmount ?? 0)
                )}
              </span>
            </div>
          </div>
        )}
      />

      <div className='mt-40'>
        <div className='order-financial-details__total'>
          <span>Voucher Order Price</span>
          <span>{formatDollarAmount(voucherOrder.pricePaid)}</span>
        </div>
      </div>
    </>
  </Modal>
));

export default VoucherFinancialsModal;
