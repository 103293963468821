import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const DecorationLocationArtworkSizeDeleteModal = React.memo(({
  isOpen,
  closeModal,
  decorationLocationArtworkSize,
  deleteDecorationLocationArtworkSize,
}) => (
  <Modal
    title={'Delete Artwork Size'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteDecorationLocationArtworkSize.bind(null, decorationLocationArtworkSize.id)}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to remove artwork size with:</h3>
    <ul>
      <li>Decoration location code: <i>{decorationLocationArtworkSize.decorationLocationCode}</i></li>
      <li>Decoration method: <i>{decorationLocationArtworkSize.decorationMethod}</i></li>
      <li>Decoration size: <i>{decorationLocationArtworkSize.decorationSize}</i></li>
    </ul>
  </Modal>
));

DecorationLocationArtworkSizeDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  decorationLocationArtworkSize: PropTypes.object.isRequired,
  deleteDecorationLocationArtworkSize: PropTypes.func.isRequired,
};

export default DecorationLocationArtworkSizeDeleteModal;
