/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */

export type MerchandisingEffortStatus = 'Created' | 'Ready' | 'Loading' | 'Loaded' | 'Removing' | 'Removed' | 'Aborted' | 'LoadingError' | 'RemovingError';


export const MerchandisingEffortStatus = {
  Created: 'Created' as MerchandisingEffortStatus,
  Ready: 'Ready' as MerchandisingEffortStatus,
  Loading: 'Loading' as MerchandisingEffortStatus,
  Loaded: 'Loaded' as MerchandisingEffortStatus,
  Removing: 'Removing' as MerchandisingEffortStatus,
  Removed: 'Removed' as MerchandisingEffortStatus,
  Aborted: 'Aborted' as MerchandisingEffortStatus,
  LoadingError: 'LoadingError' as MerchandisingEffortStatus,
  RemovingError: 'RemovingError' as MerchandisingEffortStatus,
};
