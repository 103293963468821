import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getLockerItemBulkActions } from '@constants/options/bulkActions';
import LockerItemsTable from '@sharedComponents/LockerItems/LockerItemsTable';

class ProductsTab extends PureComponent {
  state = {
    selectedItems: [],
    addItemsToExistingCollectionModalIsOpen: false,
  };

  getBulkActions = (selectedItems, refreshingImages) => (
    getLockerItemBulkActions(selectedItems, true, !refreshingImages, false)
  );

  render() {
    const {
      lockerId,
      onCollectionsChange,
    } = this.props;

    return (
      <LockerItemsTable
        lockerId={lockerId}
        getBulkActions={this.getBulkActions}
        onCollectionsChange={onCollectionsChange}
        displayLockerItemsActionBtn={true}
      />
    );
  }
}

ProductsTab.propTypes = {
  lockerId: PropTypes.number.isRequired,
  onCollectionsChange: PropTypes.func.isRequired,
};

export default ProductsTab;
