import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

const DecorationsHeader = React.memo(({
  addLogo,
  addPersonalization,
  personalizationConfiguration,
}) => (
  <div className={'lockerManagerEdit__details-decorations'}>
    <div className='lockerManager__details-decorations--add'>
      <div
        className='lockerManager__details-decorations--edit'
        onClick={addLogo}
      >
        <Icon materialIcon={'add'} />
        <span>Add Logo</span>
      </div>
      {
        !personalizationConfiguration
          ? (
            <div
              className='lockerManager__details-decorations--edit disabled'
            >
              <MaterialTooltip
                tooltipText={'Choose a personalization configuration'}
                placement={'top'}
              >
                <Icon materialIcon={'add'} />
                <span>Add Personalization</span>
              </MaterialTooltip>
            </div>
          )
          : (
            <div
              className='lockerManager__details-decorations--edit'
              onClick={addPersonalization}
            >
              <Icon materialIcon={'add'} />
              <span>Add Personalization</span>
            </div>
          )
      }
    </div>
  </div>
));

DecorationsHeader.propTypes = {
  addLogo: PropTypes.func.isRequired,
  addPersonalization: PropTypes.func.isRequired,
  personalizationConfiguration: PropTypes.string,
};

export default DecorationsHeader;
