import React from 'react';

import { WrappedFieldProps } from 'redux-form';

interface OwnProps {
  required?: boolean;
  disabled?: boolean;
  className?: string;
  children: JSX.Element[];
}

type Props = OwnProps & WrappedFieldProps;

const Select = (props: Props) => {
  const {
    required = false,
    disabled = false,
    meta: {
      touched,
      error,
    },
    children,
    className = '',
  } = props;

  const inputProps = props.input || {};
  const hasError = touched && error && (error.length > 0);

  return (
    <div className={`select-field redux-form__select ${className} ${disabled ? 'disabled' : ''}`}>
      <select
        data-test='select'
        className='has-icon'
        required={required}
        disabled={disabled}
        {...inputProps}
      >
        {children}
      </select>
      <div className='select-field__arrow' />
      {
        hasError &&
        <div className='redux-form__error--field'>
          {(error as string[]).map((e, index) => <div key={index}>{e}</div>)}
        </div>
      }
    </div>
  );
};

export default Select;
