import React from 'react';
import { Field } from 'redux-form';
import { parseNumber } from '@util/numberHelpers';
import InfoField from '@sharedComponents/Display/InfoField';
import Input from '@sharedComponents/Form/Input';

const CustomPriceFormInput = React.memo(({ disabled }) => (
  <InfoField
    fieldClass={'mb-0 w-100 pr-20'}
    label={'Custom Price'}
    value={(
      <div className='redux-form__input-wrapper lockerManager__details-info--input-wrapper'>
        <span className='icon-center'>$</span>
        <Field
          name='price'
          placeholder='00.00'
          component={Input}
          type='number'
          parse={parseNumber}
          className='w-100 has-icon'
          disabled={disabled.price}
          initialValue={0}
        />
      </div>
    )}
  />
));

export default CustomPriceFormInput;
