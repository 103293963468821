import React from 'react';
import PropTypes from 'prop-types';
import {
  mapToSwatchColorIcons,
  mapToSwatchColorIconsSlice,
} from '@util/mappingHelper';
import {
  filterAdminColors,
  filterInternalSelectionColors,
} from '@util/colorHelper';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import LogoColors from '@components/LockerManagerDetails/LogoBank/Logos/DetailedLogo/LogoColors';

const LogoDetails = React.memo(({
  logo,
  colors,
}) => {
  let adminSwatchInfo = <div />;
  let artTeamSwatchInfo = <div />;
  let adminSwatchInfoSlice = <div />;
  let artTeamSwatchInfoSlice = <div />;

  let adminLogoColorSelections = [];
  let artTeamColorSelections = [];

  if (logo.colorSelections) {
    adminLogoColorSelections = filterAdminColors(logo.colorSelections);
    const adminLogoColors = adminLogoColorSelections.map((adminColor) => {
      const color = colors.find((c) => c.code === adminColor.code);

      return color ? color : adminColor;
    });

    adminSwatchInfo = (
      <div className='logo-bank__color-swatches'>
        {mapToSwatchColorIcons(adminLogoColors)}
      </div>
    );

    adminSwatchInfoSlice = (
      <div className='logo-bank__color-swatches'>
        {mapToSwatchColorIconsSlice(adminLogoColors)}
      </div>
    );

    artTeamColorSelections = filterInternalSelectionColors(logo.colorSelections);
    const artTeamColors = artTeamColorSelections.map((artTeamColor) => {
      const color = colors.find((c) => c.code === artTeamColor.code);

      return color ? color : artTeamColor;
    });

    artTeamSwatchInfo = (
      <div className='logo-bank__color-swatches'>
        {mapToSwatchColorIcons(artTeamColors)}
      </div>
    );

    artTeamSwatchInfoSlice = (
      <div className='logo-bank__color-swatches'>
        {mapToSwatchColorIconsSlice(artTeamColors)}
      </div>
    );
  }

  return (
    <div className='logo-bank__used--details'>
      <div className='logo-bank__used--details--info'>
        <MaterialTooltip
          tooltipText={`Used on ${logo.countUsedOnProd} products and ${logo.countUsedOnCustomItems} custom items`}
          placement={'bottom'}
        >
          <span className='cursor-pointer'>Used on Items: <strong>{logo.countUsedOnProd + logo.countUsedOnCustomItems}</strong></span>
        </MaterialTooltip>
        <MaterialTooltip
          tooltipText={`Ordered ${logo.countOrderedProd} products and ${logo.countOrderedCustomItems} custom items`}
          placement={'bottom'}
        >
          <span className='cursor-pointer'>Ordered: <strong>{(logo.countOrderedProd + logo.countOrderedCustomItems) || '-'}</strong></span>
        </MaterialTooltip>
      </div>
      <div className='logo-bank__used--container'>
        {
          adminLogoColorSelections.length > 0 &&
          <div className='logo-bank__used--details--colors justify__end'>
            <span>Admin: </span>
            {adminSwatchInfoSlice}
            <LogoColors
              colors={adminLogoColorSelections}
              swatchInfo={adminSwatchInfo}
              text={'Admin :'}
            />
          </div>
        }
        {
          artTeamColorSelections.length > 0 &&
          <div className='logo-bank__used--details--colors justify__end'>
            <span>Art Team: </span>
            {artTeamSwatchInfoSlice}
            <LogoColors
              colors={artTeamColorSelections}
              swatchInfo={artTeamSwatchInfo}
              text={'Art Team :'}
            />
          </div>
        }
      </div>
    </div>
  );
});

LogoDetails.propTypes = {
  logo: PropTypes.object.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    brightness: PropTypes.string,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default LogoDetails;
