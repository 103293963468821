import React, { useCallback } from 'react';

interface OwnProps {
  id: number;
  onClick: (id: number) => void;
  enabled: boolean;
  label?: string;
  className?: string;
}

type Props = OwnProps;

const DtfPrinterButton = React.memo<Props>(({
  id,
  onClick,
  enabled,
  label,
  className,
}) => {
  const update = useCallback((e) => {
    e.preventDefault();
    onClick(id);
  }, [
    id,
    onClick,
  ]);

  if (!enabled) {
    return null;
  }

  return (
    <button
      onClick={update}
      disabled={!enabled}
      className={className ?? 'button button--color-gossamer button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase'}
    >
      {label ?? 'Print DTF'}
    </button>
  );
});

export default DtfPrinterButton;
