import React from 'react';
import { parseDateNumeric } from '@util/dateHandler';
import LogoInfo from './LogoInfo';
import PriorityDetailsInfoItem from './PriorityDetailsInfoItem';

interface OwnProps {
  previewUrl: Nullable<string>;
  orderNumber: Nullable<number>;
  quantity: Nullable<number>;
  type: Nullable<string>;
  shipDate: Nullable<string>;
  barcode: Nullable<string>;
  reasonForPriority: Nullable<string>;
}

type Props = OwnProps;

const PriorityModalTaskDetails = React.memo<Props>(({
  previewUrl,
  orderNumber,
  quantity,
  type,
  shipDate,
  barcode,
  reasonForPriority,
}) => (
  <div className='flagging__details'>
    <div className='flagging_details-logo'>
      {
        previewUrl &&
        <LogoInfo logoUrl={previewUrl} />
      }
    </div>
    <div className='flagging__details-info'>
      <div className='flagging__details-info-column'>
        <PriorityDetailsInfoItem
          label={'Order ID'}
          content={`O${orderNumber}`}
        />
        <PriorityDetailsInfoItem
          label={'Barcode'}
          content={barcode}
        />
      </div>
      <div className='flagging__details-info-column'>
        <PriorityDetailsInfoItem
          label={'Quantity'}
          content={quantity}
        />
        <PriorityDetailsInfoItem
          label={'Reason for Priority'}
          content={reasonForPriority}
        />
      </div>
      <div className='flagging__details-info-column'>
        <PriorityDetailsInfoItem
          label={'Type'}
          content={type}
        />
      </div>
      <div className='flagging__details-info-column'>
        <PriorityDetailsInfoItem
          label={'Ship Date'}
          content={parseDateNumeric(shipDate)}
        />
      </div>
    </div>
  </div>
));

export default PriorityModalTaskDetails;
