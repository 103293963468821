import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { minInputLengthForSuggestions } from '@constants/values';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

const throttledCallTimeout = 500;

class InputWithValidation extends PureComponent {
  state = {
    searchInput: '',
    throttledCall: null,
  };

  componentWillUnmount() {
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    this.setState({ throttledCall: null });
  }

  isInputInvalid = (isValid) => {
    const { throttledCall } = this.state;
    const { handleInputValidationChange } = this.props;

    const currentlyValidating = !!throttledCall;

    if (!currentlyValidating && handleInputValidationChange) {
      handleInputValidationChange(isValid);
    }

    return currentlyValidating ? false : !isValid;
  };

  validateInput = (newSearchInput) => {
    const { validateInput } = this.props;

    validateInput(newSearchInput);

    this.setState({ throttledCall: null });
  };

  onInputChange = (e) => {
    const {
      minInputLengthForValidation,
      validationTimeout,
    } = this.props;

    const inputValue = e.target.value;
    const newSearchInput = inputValue ? inputValue.trim() : '';
    this.setState({ searchInput: newSearchInput });

    const { throttledCall } = this.state;
    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    if (newSearchInput.length < minInputLengthForValidation) {
      this.setState({ throttledCall: null });

      // Call redux-form onChange implementation
      const inputProps = this.props.input || {};
      inputProps.onChange(e);

      return;
    }

    const newThrottle = setTimeout(this.validateInput.bind(null, newSearchInput), validationTimeout);
    this.setState({ throttledCall: newThrottle });

    // Call redux-form onChange implementation
    const inputProps = this.props.input || {};
    inputProps.onChange(e);
  };

  render() {
    const {
      type,
      placeholder,
      required,
      disabled,
      className,
      meta: {
        touched,
        error,
      },
      isValid,
      invalidWarningMessage,
      invalidTooltipMessage,
    } = this.props;

    const inputProps = this.props.input || {};
    const hasError = touched && error && (error.length > 0);

    return (
      <div className='text-field w-100 redux-form__input--validated'>
        <input
          type={type}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          className={`redux-form__input ${className} ${hasError ? 'error' : ''}`}
          {...inputProps}
          onChange={this.onInputChange}
        />
        {
          this.isInputInvalid(isValid) &&
          (
            invalidTooltipMessage
              ? (
                <MaterialTooltip
                  tooltipText={invalidTooltipMessage}
                  placement={'top'}
                >
                  <div className='warning-message invalid-warning cursor-pointer'>
                    <Icon materialIcon={'warning'} />
                    <span>{invalidWarningMessage}</span>
                  </div>
                </MaterialTooltip>
              )
              : (
                <div className='warning-message invalid-warning'>
                  <Icon materialIcon={'warning'} />
                  <span>{invalidWarningMessage}</span>
                </div>
              )
          )
        }
        {
          hasError &&
          <div className='redux-form__error--field'>
            {error.map((e, index) => <div key={index}>{e}</div>)}
          </div>
        }
      </div>
    );
  }
}

InputWithValidation.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.array,
  }),
  input: PropTypes.object,
  validateInput: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleInputValidationChange: PropTypes.func,
  minInputLengthForValidation: PropTypes.number,
  validationTimeout: PropTypes.number,
  invalidWarningMessage: PropTypes.string,
  invalidTooltipMessage: PropTypes.string,
};

InputWithValidation.defaultProps = {
  type: 'text',
  placeholder: '',
  required: false,
  disabled: false,
  className: 'w-100',
  meta: {
    touched: false,
    error: '',
  },
  minInputLengthForValidation: minInputLengthForSuggestions,
  validationTimeout: throttledCallTimeout,
  invalidWarningMessage: 'Invalid',
  invalidTooltipMessage: '',
};

export default InputWithValidation;
