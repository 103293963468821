import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  stopSubmit,
  reset,
} from 'redux-form';
import { connect } from 'react-redux';
import { validateOrganizationGroup } from '@redux/organizations/validations';
import { addOrganizationGroupForm } from '@constants/reduxForms';
import OrganizationGroupForm from './OrganizationGroupForm';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class AddOrganizationGroupModal extends Component {
  handleCloseModal = async () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    await dispatch(stopSubmit(addOrganizationGroupForm, {}));
    await dispatch(reset(addOrganizationGroupForm));
    closeModal();
  };

  render() {
    const {
      isOpen,
      handleSubmit,
      error,
      organizationId,
      change,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={this.handleCloseModal}
        title={'Add New Store Group'}
        modalHeight={'xl'}
        modalWidth={'l'}
        buttons={(
          <ModalButtons
            onClose={this.handleCloseModal}
            formSubmission={true}
            confirmBtnText={'Save'}
            formId={addOrganizationGroupForm}
          />
        )}
      >
        <OrganizationGroupForm
          onSubmit={handleSubmit}
          change={change}
          error={error}
          organizationId={organizationId}
          formId={addOrganizationGroupForm}
        />
      </Modal>
    );
  }
}

AddOrganizationGroupModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  error: PropTypes.string,
  change: PropTypes.func,
  organizationId: PropTypes.number.isRequired,
};

export default connect()(reduxForm({
  form: addOrganizationGroupForm,
  validate: validateOrganizationGroup,
  enableReinitialize: true,
})(AddOrganizationGroupModal));
