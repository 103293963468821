import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';

class ItemEditOnMouseOver extends PureComponent {
  state = {
    isActionVisible: false,
  };

  showEditIcon = () => {
    this.setState({ isActionVisible: true });
  };

  hideEditIcon = () => {
    this.setState({ isActionVisible: false });
  };

  render() {
    const { children } = this.props;
    const { isActionVisible } = this.state;

    return (
      <div
        className='item-edit__main-container'
        onMouseEnter={this.showEditIcon}
        onMouseLeave={this.hideEditIcon}
      >
        <div>
          {children}
        </div>
        {
          isActionVisible &&
          <div className='item-edit__mouseover-container'>
            <Icon materialIcon={'create'} />
          </div>
        }

      </div>
    );
  }
}

ItemEditOnMouseOver.propTypes = {
  children: PropTypes.node,
};

export default ItemEditOnMouseOver;
