import React, { useCallback } from 'react';

interface OwnProps {
  logoUrl: string;
  openModal: (logoUrl: string) => void;
}

type Props = OwnProps;

const LogoCell = React.memo<Props>(({
  logoUrl,
  openModal,
}) => {
  const handleClickOnLogo = useCallback(() => {
    openModal(logoUrl);
  }, [
    openModal,
    logoUrl,
  ]);

  return (
    <div>
      <a
        className='sheet__list-table-thumbnail cursor-pointer'
        onClick={handleClickOnLogo}
        style={{ backgroundImage: `url("${logoUrl}")` }}
      />
    </div>
  );
});

export default LogoCell;
