import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { editApplicationSettings } from '@APICalls/support/actions';
import { fetchApplicationSettings } from '@redux/support/actions';
import Icon from '@sharedComponents/Icons/Icon';
import ApplicationSetting from './ApplicationSetting';
import ApplicationSettingsEditModal from './ApplicationSettingsEditModal';

class ApplicationSettings extends Component {
  state = {
    applicationSettingsModalIsOpen: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchApplicationSettings());
  }

  openEditModal = () => {
    this.setState({ applicationSettingsModalIsOpen: true });
  };

  closeEditModal = () => {
    this.setState({ applicationSettingsModalIsOpen: false });
  };

  editApplicationSettings = async (form) => {
    const { dispatch } = this.props;
    await editApplicationSettings(form);

    dispatch(fetchApplicationSettings());
    this.closeEditModal();
  };

  render() {
    const { applicationSettings } = this.props;
    const { applicationSettingsModalIsOpen } = this.state;

    return (
      <div className='container'>
        <ApplicationSettingsEditModal
          isOpen={applicationSettingsModalIsOpen}
          closeModal={this.closeEditModal}
          onSubmit={this.editApplicationSettings}
          initialValues={applicationSettings}
        />
        <div className='sheet'>
          <div className='sheet__header'>
            <ul className='sheet__header-right'>
              <li>
                <a
                  onClick={this.openEditModal}
                  className='button button--link'
                >
                  <span className='margin margin--right-s'>
                    <Icon materialIcon={'edit'} />
                  </span>
                  Edit Settings
                </a>
              </li>
            </ul>
          </div>
          <ul className='sheet__items flex'>
            <ApplicationSetting
              setting={applicationSettings.workerCount}
              label={'Worker Count'}
            />
            <ApplicationSetting
              setting={applicationSettings.schedulingTerminalId}
              label={'Scheduling Terminal'}
            />
            <ApplicationSetting
              setting={applicationSettings.artworkTerminalId}
              label={'Artwork Terminal'}
            />
          </ul>
        </div>
      </div>
    );
  }
}

ApplicationSettings.propTypes = {
  applicationSettings: PropTypes.shape({
    workerCount: PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string,
      dateCreatedUtc: PropTypes.string,
      dateEditedUtc: PropTypes.string,
    }),
    schedulingTerminalId: PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string,
      dateCreatedUtc: PropTypes.string,
      dateEditedUtc: PropTypes.string,
    }),
    artworkTerminalId: PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string,
      dateCreatedUtc: PropTypes.string,
      dateEditedUtc: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = ({ support }) => ({
  applicationSettings: support.applicationSettings,
});

export default connect(mapStateToProps)(ApplicationSettings);
