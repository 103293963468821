import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FieldArray,
} from 'redux-form';
import FormError from '@sharedComponents/Form/FormError';
import Decoration from './CustomItemDecorationsSectionContent/Decoration';
import DecorationsHeader from './CustomItemDecorationsSectionContent/DecorationsHeader';

class CustomItemDecorationsSection extends PureComponent {
  addLogo = (fields) => {
    const {
      createLogo,
      unselectLayout,
    } = this.props;

    unselectLayout();
    fields.push(createLogo());
  };

  addPersonalization = (fields) => {
    const {
      createPersonalization,
      unselectLayout,
    } = this.props;

    unselectLayout();
    fields.push(createPersonalization());
  };

  removeDecoration = (fields, index) => {
    const { unselectLayout } = this.props;

    unselectLayout();
    fields.remove(index);
  };

  renderDecoration = (member, index, fields) => {
    const {
      selectedColoredStyle,
      lockerLogos,
      change,
      colorsDictionary,
      allDecorationLocations,
    } = this.props;

    const decorationLocations = [];
    for (const d of selectedColoredStyle.style.decorationLocations) {
      const decoration = allDecorationLocations.find((a) => a.code === d.location);

      if (!decoration) continue;

      decorationLocations.push(({
        id: decoration.id,
        location: d.location,
        type: d.type,
        label: decoration.label,
      }));
    }

    let personalizationColors;
    if (Object.values(colorsDictionary).length > 0) {
      personalizationColors = selectedColoredStyle.personalizationColors
        .map((c) => colorsDictionary[c.floodColorId])
        .filter((c) => c !== undefined);
    }

    const decorations = fields.getAll() || [];
    const selectedDecorationLocations = decorations.map((d) => d.decorationLocation);

    return (
      <Field
        key={index}
        name={member}
        index={index}
        component={Decoration}
        change={change}
        decoration={decorations[index]}
        selectedColoredStyle={selectedColoredStyle}
        createDecoration={this.createDecoration}
        removeDecoration={this.removeDecoration.bind(null, fields)}
        decorationLocations={decorationLocations}
        selectedDecorationLocations={selectedDecorationLocations}
        lockerLogos={lockerLogos}
        personalizationColors={personalizationColors}
      />
    );
  };

  renderDecorations = ({
    fields,
    meta: { error },
    selectedLayout,
    personalizationItemType,
  }) => (
    <div className='mb-30'>
      <div>
        <div className='lockerManagerEdit__custom-items--decorations'>
          {fields.map(this.renderDecoration)}

          <DecorationsHeader
            selectedLayout={selectedLayout}
            addLogo={this.addLogo.bind(null, fields)}
            addPersonalization={this.addPersonalization.bind(null, fields)}
            personalizationConfiguration={personalizationItemType}
          />
        </div>

        <div className='mt-20'>
          <FormError
            error={error}
            enableMultiple={true}
          />
        </div>
      </div>
    </div>
  );

  render() {
    const {
      personalizationItemType,
      selectedLayout,
    } = this.props;

    return (
      <FieldArray
        name={'decorations'}
        component={this.renderDecorations}
        selectedLayout={selectedLayout}
        rerenderOnEveryChange={true}
        personalizationItemType={personalizationItemType}
      />
    );
  }
}

CustomItemDecorationsSection.propTypes = {
  change: PropTypes.func,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })),
  allDecorationLocations: PropTypes.arrayOf(PropTypes.object),
  lockerLogos: PropTypes.arrayOf(PropTypes.object),
  selectedColoredStyle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrlFront: PropTypes.string,
    personalizationColors: PropTypes.arrayOf(PropTypes.shape({
      floodColorId: PropTypes.number.isRequired,
      custom: PropTypes.bool.isRequired,
    })),
    style: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      category: PropTypes.shape({
        name: PropTypes.string,
      }),
      vendorId: PropTypes.string.isRequired,
      gender: PropTypes.string,
      discount: PropTypes.number,
      vendorFulfilledItem: PropTypes.bool,
      decorationMethod: PropTypes.string,
      decorationLocations: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        location: PropTypes.string,
      })),
    }),
  }).isRequired,
  createLogo: PropTypes.func.isRequired,
  createPersonalization: PropTypes.func.isRequired,
  decorations: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    decorationMethod: PropTypes.string,
    decorationLocation: PropTypes.string,
    decorationHeight: PropTypes.string,
    personalizationType: PropTypes.string,
    personalizationColorType: PropTypes.string,
    required: PropTypes.bool,
    colorId: PropTypes.number,
    outlineColorId: PropTypes.number,
  })),
  selectedLayout: PropTypes.shape({
    id: PropTypes.number.isRequired,
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    sport: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string,
    imageUrlFront: PropTypes.string,
    imageUrlBack: PropTypes.string,
    imageUrlRight: PropTypes.string,
    imageUrlLeft: PropTypes.string,
  }),
  selectLayout: PropTypes.func.isRequired,
  unselectLayout: PropTypes.func.isRequired,
  personalizationItemType: PropTypes.string,
};

export default CustomItemDecorationsSection;
