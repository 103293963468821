import React from 'react';
import { Controller } from 'react-hook-form';
import FormError from './FormError';
import MaterialTooltip from '../Tooltips/MaterialTooltip';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

const NewHookformSelect = ({
  id,
  name,
  control,
  placeholder,
  error,
  required,
  options,
  tooltipPlacement = 'top',
  className = '',
  inputClassName = '',
  label = '',
}) => (
  <div className='lockerEdit__information-fields--column'>
    {label &&
      <InputLabel shrink={true} id={id + '-label'}>{label}</InputLabel>
    }
    <Controller
      name={name}
      control={control}
      rules={{ required: required ? 'This field is required' : false }}
      // eslint-disable-next-line react/jsx-no-bind
      render={({ field: { ref, ...rest } }) => (
        <MaterialTooltip
          tooltipText={placeholder || ''}
          placement={tooltipPlacement}
        >
          <Select
            labelId={id + '-label'}
            id={id}
            data-test='select'
            className={`has-icon ${inputClassName}`}
            ref={ref}
            placeholder={placeholder}
            required={required}
            {...rest}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {
            error &&
            <div className='redux-form__error--field'>
              {error.message}
            </div>
          }
        </MaterialTooltip>
      )}
    />
    {
      error &&
      <FormError
        error={error}
        fieldError={true}
      />
    }
  </div >
);

export default NewHookformSelect;
