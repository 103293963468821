import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

interface OwnProps {
  initialValue?: number;
  placeholder?: string;
  classes?: string;
  inputClasses?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
}

type Props = OwnProps;

const NumberInput = React.memo(({
  initialValue,
  placeholder = 'Quantity',
  classes = '',
  inputClasses = '',
  disabled = false,
  onChange,
}: Props) => {
  const [
    value,
    setValue,
  ] = useState<number>(initialValue || 0);

  useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const updateValue = useCallback((e) => {
    const newValue = e.target.value;

    setValue(newValue);
    onChange(newValue);
  }, [onChange]);

  return (
    <div className={`text-field ${classes}`}>
      <input
        className={inputClasses}
        type='number'
        placeholder={placeholder}
        onChange={updateValue}
        value={value || ''}
        disabled={disabled}
        min={1}
      />
    </div>
  );
});

export default NumberInput;
