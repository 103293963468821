import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useHistory, Link } from 'react-router-dom';
import Table from '@sharedComponents/Table/Table';
import Label from '@sharedComponents/Display/Label';
import { BatchWarehouseCategoryEnum, BatchWarehouseGroupingDateTypeEnum, BatchWarehouseOrderGroupItemDto } from '@api/fulfillment/models';
import { useGetHomefieldApiWarehouseschedulingOrdergroups } from '@api/fulfillment/warehouse-scheduling';
import HeaderCell from '@components/shared/Table/TableCells/HeaderCell';
import { parseDateNumeric } from '@util/dateHandler';
import DateInput from '@components/shared/Inputs/DateInput';
import moment from 'moment';
import Dropdown from '@components/shared/Inputs/Dropdowns/Dropdown';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import Icon from '@components/shared/Icons/Icon';
import useHeaderContext from '@components/shared/Contexts/HeaderContext';
import SearchFilter from '@components/shared/Inputs/SearchFilter';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { batchWarehouseSchedulingListUrlWithParams } from '@constants/clientUrls/clientUrls';

const GroupingTable = Table<BatchWarehouseOrderGroupItemDto>();

const BatchWarehouseSchedulingTabs = {
  MainScheduling: 'MainScheduling',
  SchedulingCategories: 'SchedulingCategories',
  PostDispatch: 'PostDispatch',
} as const;

type BatchWarehouseSchedulingTabsType = keyof typeof BatchWarehouseSchedulingTabs;

export const BatchWarehouseScheduling: React.FC = React.memo(() => {
  const history = useHistory();

  const [groupingType, setGroupingType] = useState<BatchWarehouseGroupingDateTypeEnum>(
    BatchWarehouseGroupingDateTypeEnum.Shipping
  );

  const [minDate, setMinDate] = useState<string>(
    moment().add(-14, 'days')
      .format('YYYY-MM-DD')
  );

  const [maxDate, setMaxDate] = useState<string>(
    moment().add(14, 'days')
      .format('YYYY-MM-DD')
  );

  const [activeTab, setActiveTab] = useState<BatchWarehouseSchedulingTabsType>(
    BatchWarehouseSchedulingTabs.MainScheduling
  );

  const { headerMutator } = useHeaderContext();

  const {
    data,
    isFetching,
    refetch,
  } = useGetHomefieldApiWarehouseschedulingOrdergroups({
    minDate,
    maxDate,
    groupingDateType: BatchWarehouseGroupingDateTypeEnum.Shipping,
  }, { query: { enabled: true } });

  type DtoKey = keyof BatchWarehouseOrderGroupItemDto;
  type ColumnCellPropsType<T extends DtoKey | unknown = unknown> = {
    original: BatchWarehouseOrderGroupItemDto;
    value: T extends DtoKey ? BatchWarehouseOrderGroupItemDto[T] : unknown;
  };

  const shippingColumn = useMemo(() => ({
    Header: `${groupingType} Date`,
    accessor: 'groupDate',
    minWidth: 170,
    Cell: (cellProps: ColumnCellPropsType) => {
      const date = parseDateNumeric(cellProps.original.groupDate);
      const allShipped = (cellProps.original?.totalUnits ?? 0) <= (cellProps.original?.shippedUnits ?? 0);
      const dateShipped = allShipped && cellProps.original.lastShipDate ? parseDateNumeric(cellProps.original.lastShipDate) : '';

      return (
        <MaterialTooltip
          tooltipText={allShipped ? `All Shipped on ${dateShipped}` : 'Not All Shipped'}
          placement='bottom'
          contentClasses={'flex'}
        >
          <div>
            {
              allShipped
                ? <Icon materialIcon={'check'} />
                : <Icon materialIcon={'build'} />
            }
            {'  '}
            <span>{date}</span>
          </div>
        </MaterialTooltip>
      );
    },
  }), [groupingType]);

  const orderAndUnitPair = useCallback((name: string, ordersKey: DtoKey, unitsKey: DtoKey, width: number) => {
    const targetDateCb = (cellProps: ColumnCellPropsType) => moment(cellProps.original.groupDate).format('YYYY-MM-DD');

    return {
      Header: name,
      id: '',
      minWidth: width,
      pin: true,
      Cell: (cellProps: ColumnCellPropsType) => (
        <MaterialTooltip
          tooltipText={`${name} Orders / Units for ${parseDateNumeric(cellProps.original.groupDate)}`}
          placement='bottom'
          contentClasses={'flex'}
        >
          <Link to={batchWarehouseSchedulingListUrlWithParams({
            groupingDateType: groupingType,
            maxDate: targetDateCb(cellProps),
            minDate: targetDateCb(cellProps),
            category: ordersKey as BatchWarehouseCategoryEnum,
          })}
          >
            {cellProps.original[ordersKey]} / {cellProps.original[unitsKey]}
          </Link>
        </MaterialTooltip>
      ),
    };
  }, [groupingType]);

  const mainColumnWidth = 250;
  const mainColumns = useMemo(() => ([
    shippingColumn,
    orderAndUnitPair('Total', 'totalOrders', 'totalUnits', mainColumnWidth),
    orderAndUnitPair('Ready', 'readyOrders', 'readyUnits', mainColumnWidth),
    orderAndUnitPair('Partial Ready', 'partialReadyOrders', 'partialReadyUnits', mainColumnWidth),
    orderAndUnitPair('Backordered', 'backorderedOrders', 'backorderedUnits', mainColumnWidth),
    orderAndUnitPair('Needs Print', 'needPrintOrders', 'needPrintUnits', mainColumnWidth),
  ]), [shippingColumn, orderAndUnitPair]);

  const schCategoryColumnWidth = 208;
  const schCategoryColumns = useMemo(() => ([
    shippingColumn,
    orderAndUnitPair('Ready HAG Only', 'readyHagOnlyOrders', 'readyHagOnlyUnits', schCategoryColumnWidth),
    orderAndUnitPair('Ready EMB Only', 'readyEmbOnlyOrders', 'readyEmbOnlyUnits', schCategoryColumnWidth),
    orderAndUnitPair('Ready All Decs', 'readyAllDecOrders', 'readyAllDecUnits', schCategoryColumnWidth),
    orderAndUnitPair('Ready No Decs', 'readyNoDecOrders', 'readyNoDecUnits', schCategoryColumnWidth),
    orderAndUnitPair('Express Production', 'expressProductionOrders', 'expressProductionUnits', schCategoryColumnWidth),
    orderAndUnitPair('Partner', 'partnerOrders', 'partnerUnits', schCategoryColumnWidth),
  ]), [shippingColumn, orderAndUnitPair]);

  const postDispatchColumnWidth = 208;
  const postDispatchColumns = useMemo(() => ([
    shippingColumn,
    orderAndUnitPair('Dispatched', 'dispatchedOrders', 'dispatchedUnits', postDispatchColumnWidth),
    orderAndUnitPair('Picked', 'pickedOrders', 'pickedUnits', postDispatchColumnWidth),
    orderAndUnitPair('Checked In', 'checkedInOrders', 'checkedInUnits', postDispatchColumnWidth),
    orderAndUnitPair('Produced', 'producedOrders', 'producedUnits', postDispatchColumnWidth),
    orderAndUnitPair('Shipped', 'shippedOrders', 'shippedUnits', postDispatchColumnWidth),
    {
      Header: 'Last Ship Date',
      id: '',
      minWidth: postDispatchColumnWidth,
      Cell: (cellProps: ColumnCellPropsType) => (
        cellProps.original.lastShipDate && parseDateNumeric(cellProps.original.lastShipDate)
      ),
    },
  ]), [shippingColumn, orderAndUnitPair]);

  const columns = activeTab === BatchWarehouseSchedulingTabs.MainScheduling
    ? mainColumns
    : activeTab === BatchWarehouseSchedulingTabs.SchedulingCategories
      ? schCategoryColumns
      : activeTab === BatchWarehouseSchedulingTabs.PostDispatch
        ? postDispatchColumns
        : [];

  const handleTabClick = (tab: BatchWarehouseSchedulingTabsType) => () => {
    setActiveTab(tab);
  };

  const handleGroupingType = useCallback((e) => {
    setGroupingType(e.target.value);
  }, []);
  const handleMinDate = useCallback((e) => {
    setMinDate(e.format('YYYY-MM-DD'));
  }, []);

  const handleMaxDate = useCallback((e) => {
    setMaxDate(e.format('YYYY-MM-DD'));
  }, []);

  const handleSearch = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const params = {
      filter: e.currentTarget.value,
      minDate,
      maxDate,
    };

    history.push(batchWarehouseSchedulingListUrlWithParams(params));
  }, []);

  const handleClearSearch = useCallback(() => {
    void 0;
  }, []);

  useEffect(() => {
    headerMutator.setTitle('Scheduling 3.0 (beta)');
  }, [headerMutator]);

  return (
    <div className='container'>
      <div className='scheduling__container'>
        <div className='table-options w-100'>
          <div className='flex'>
            <Dropdown
              options={[
                <option key={1} value={BatchWarehouseGroupingDateTypeEnum.Shipping}>Shipping Date</option>,
                <option key={2} value={BatchWarehouseGroupingDateTypeEnum.Placed}>Placed Date</option>,
              ]}
              onChange={handleGroupingType}
              defaultValue={groupingType}
              classes={'mr-20'}
            />
            <div className='scheduling__datepicker'>
              <DateInput
                value={minDate}
                label={'Min Date'}
                onChange={handleMinDate}
              />
            </div>
            <div className='scheduling__datepicker'>
              <DateInput
                value={maxDate}
                label={'Max Date'}
                onChange={handleMaxDate}
              />
            </div>
          </div>
          <div className='text-field margin-left'>
            <SearchFilter
              search={handleSearch}
              clearSearch={handleClearSearch}
              placeholder={'Search'}
            />
          </div>
        </div>
      </div>
      <ul className='tabs tabs--size-l'>
        <li
          className={`tab-item ${activeTab === BatchWarehouseSchedulingTabs.MainScheduling ? 'is-active' : ''}`}
          onClick={handleTabClick(BatchWarehouseSchedulingTabs.MainScheduling)}
        >
          Main Scheduling
        </li>
        <li
          className={`tab-item ${activeTab === BatchWarehouseSchedulingTabs.SchedulingCategories ? 'is-active' : ''}`}
          onClick={handleTabClick(BatchWarehouseSchedulingTabs.SchedulingCategories)}
        >
          Scheduling Categories
        </li>
        <li
          className={`tab-item ${activeTab === BatchWarehouseSchedulingTabs.PostDispatch ? 'is-active' : ''}`}
          onClick={handleTabClick(BatchWarehouseSchedulingTabs.PostDispatch)}
        >
          After Dispatch
        </li>
      </ul>
      <div className='scheduling__container'>
        <div className='table-options w-200'>
          <div className='text-field margin-left'>
            <GroupingTable
              data={data?.groups ?? []}
              columns={columns}
              onFetchData={refetch}
              showPagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default BatchWarehouseScheduling;
