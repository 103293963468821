import React from 'react';
import { bulkActionsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const BulkActionsLink = React.memo(() => (
  <MenuTile
    title={'Bulk Actions'}
    url={bulkActionsUrl}
    isMaterialIcon={false}
    icon={'fa-solid fa-upload'}
  />
));

export default BulkActionsLink;
