import React from 'react';
import PropTypes from 'prop-types';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import Icon from '@sharedComponents/Icons/Icon';

const ColoredStyleCard = React.memo(({
  coloredStyle,
  onClickOnColoredStyle = () => null,
  className = '',
  showCancelAction = false,
  onCancel = () => null,
}) => {
  const {
    decorationMethod,
    recommended,
    name,
  } = coloredStyle.style || {};

  let tooltipText = '';
  if (decorationMethod === decorationMethodEnum.HAG) {
    tooltipText = 'Item Supports Heat \n Applied Graphics';
  } else if (decorationMethod === decorationMethodEnum.EMB) {
    tooltipText = 'Item Supports \n Embroidery';
  } else {
    tooltipText = 'Decoration \n Not Available';
  }

  return (
    <div
      onClick={onClickOnColoredStyle.bind(null, coloredStyle)}
      className={`add-product__available--container ${className}`}
    >
      <div className='add-product__available--product'>
        <div className='position-absolute'>
          <div className='tooltip-container'>
            <div className='add-product__available--decorations'>
              <span>
                {decorationMethod && decorationMethod !== decorationMethodEnum.Undefined ? decorationMethod : '-'}
              </span>
              <div className='tooltip-text'>
                {tooltipText}
              </div>
            </div>
          </div>
        </div>
        <div
          className='add-product__available--image'
          style={{ backgroundImage: `url("${coloredStyle.imageUrlFront}")` }}
        />
        {
          showCancelAction &&
          <div
            onClick={onCancel.bind(null, coloredStyle)}
            className='organization-group__icon--remove mr-5'
          />
        }
      </div>
      <div className='add-product__available--info'>
        {recommended && <Icon materialIcon={'thumb_up'} />}
      </div>
      <span className='add-product__available--title'>
        {name}
      </span>
      <span className='add-product__available--color'>
        {coloredStyle.colorGroup}
      </span>
      <span className='add-product__available--price'>
        {coloredStyle.code}
      </span>
    </div>
  );
});

ColoredStyleCard.propTypes = {
  coloredStyle: PropTypes.object.isRequired,
  onClickOnColoredStyle: PropTypes.func,
  className: PropTypes.string,
  showCancelAction: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ColoredStyleCard;
