import React, { PureComponent } from 'react';
import {
  Field,
  FieldArray,
  formValueSelector,
} from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyleSuggestions } from '@redux/productCatalog/actions';
import { fetchTeamColors } from '@redux/support/actions';
import { uploadFile } from '@APICalls/support/actions';
import { uploadFile as uploadFileUrl } from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { catalogApi } from '../../sqdApis';
import { getTrueOrFalseOptions } from '@constants/options/options';
import {
  emptyImageTextEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { editColoredStyleForm } from '@constants/reduxForms';
import { dragAndDropMessage } from '@constants/values';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { styleUrl } from '@constants/clientUrls/clientUrls';
import { generalOptions } from '@util/optionsMap';
import { parseNumber } from '@util/numberHelpers';
import { parseStringToBoolean } from '@util/valueHelpers';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import SearchInput from '@sharedComponents/Form/SearchInput/SearchInput';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import FormError from '@sharedComponents/Form/FormError';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import EditColoredStyleImageContainer from './ProductCatalogModals/EditColoredStyleModal/EditColoredStyleImageContainer';
import EditPersonalizationColorsInput from './ProductCatalogModals/EditColoredStyleModal/EditPersonalizationColorsInput';
import EditColoredStyleUploadFile from './ProductCatalogModals/EditColoredStyleModal/EditColoredStyleUploadFile';
import InventoryAlwaysAvailableCheckbox from './StyleForm/InventoryAlwaysAvailableCheckbox';

const defaultMessageGrid = dragAndDropMessage('grid file');
const acceptedFileType = '.lgrd';

class ColoredStyleForm extends PureComponent {
  state = {
    uploadFrontImgIsActive: false,
    uploadBackImgIsActive: false,
    uploadSideImgIsActive: false,
    gridPreviewModalIsOpen: false,
    gridPreviewUrl: '',
    imageUrlFront: this.props.initialValues ? this.props.initialValues.imageUrlFront : '',
    imageUrlSide: this.props.initialValues ? this.props.initialValues.imageUrlSide : '',
    imageUrlBack: this.props.initialValues ? this.props.initialValues.imageUrlBack : '',
    imageUrlGrid: this.props.initialValues ? this.props.initialValues.imageUrlGrid : '',
    loadingGridFile: false,
    displayedStyle: this.props.style,
    messageFrontImg: {
      type: 'default',
      body: dragAndDropMessage(`image ${emptyImageTextEnum.Front}`),
    },
    messageBackImg: {
      type: 'default',
      body: dragAndDropMessage(`image ${emptyImageTextEnum.Back}`),
    },
    messageSideImg: {
      type: 'default',
      body: dragAndDropMessage(`image ${emptyImageTextEnum.Side}`),
    },
    messageGridFile: {
      type: 'default',
      body: defaultMessageGrid,
    },
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchTeamColors());
  }

  onKeyPress = (e) => {
    if (e.key === keyNameEnum.Enter) {
      e.preventDefault();
    }
  };

  getStyleSuggestions = (searchInput) => {
    const { dispatch } = this.props;

    dispatch(getStyleSuggestions(searchInput));
  };

  openUploadFrontImgContainer = () => {
    this.setState(() => ({ uploadFrontImgIsActive: true }));
  };

  openUploadBackImgContainer = () => {
    this.setState(() => ({ uploadBackImgIsActive: true }));
  };

  openUploadSideImgContainer = () => {
    this.setState(() => ({ uploadSideImgIsActive: true }));
  };

  openModalWithGridPreview = (imageUrl) => {
    this.setState(() => ({
      gridPreviewModalIsOpen: true,
      gridPreviewUrl: imageUrl,
    }));
  };

  closeGridPreviewModal = () => {
    this.setState(() => ({
      gridPreviewModalIsOpen: false,
      gridPreviewUrl: '',
    }));
  };

  changeImageFront = (image, message) => {
    this.setState(() => ({
      imageUrlFront: image,
      messageFrontImg: message,
    }));
  };

  changeImageBack = (image, message) => {
    this.setState(() => ({
      imageUrlBack: image,
      messageBackImg: message,
    }));
  };

  changeImageSide = (image, message) => {
    this.setState(() => ({
      imageUrlSide: image,
      messageSideImg: message,
    }));
  };

  onDropGridImg = async (acceptedFiles, rejectedFiles) => {
    const { change } = this.props;

    if (rejectedFiles.length > 0) {
      this.setState(() => ({
        messageGridFile: {
          type: 'reject',
          body: `${acceptedFileType} files required.`,
        },
      }));
    } else {
      this.setState(() => ({
        messageGridFile: {
          body: `File ${acceptedFiles[0].name} has been attached.`,
          type: 'default',
        },
        loadingGridFile: true,
      }), async () => {
        const res = await uploadFile(acceptedFiles[0], catalogApi, uploadFileUrl);

        if (res) {
          change('imageUrlGrid', res);
        }

        this.setState(() => ({ loadingGridFile: false }));
      });
    }
  };

  removeFrontImg = () => {
    const { change } = this.props;

    this.setState(() => ({
      imageUrlFront: null,
      messageFrontImg: {
        type: 'default',
        body: dragAndDropMessage(`image ${emptyImageTextEnum.Front}`),
      },
      uploadFrontImgIsActive: false,
    }));

    change('imageUrlFront', null);
  };

  removeBackImg = () => {
    const { change } = this.props;

    this.setState(() => ({
      imageUrlBack: null,
      messageBackImg: {
        type: 'default',
        body: dragAndDropMessage(`image ${emptyImageTextEnum.Back}`),
      },
      uploadBackImgIsActive: false,
    }));

    change('imageUrlBack', null);
  };

  removeSideImg = () => {
    const { change } = this.props;

    this.setState(() => ({
      imageUrlSide: null,
      messageSideImg: {
        type: 'default',
        body: dragAndDropMessage(`image ${emptyImageTextEnum.Side}`),
      },
      uploadSideImgIsActive: false,
    }));

    change('imageUrlSide', null);
  };

  removeGridFile = () => {
    const { change } = this.props;

    this.setState(() => ({
      imageUrlGrid: null,
      messageGridFile: {
        type: 'default',
        body: `${defaultMessageGrid}`,
      },
    }));

    change('imageUrlGrid', null);
  };

  renderPersonalizationColorsInput = ({
    fields,
    defaultPersonalizationColor,
  }) => {
    const { colors } = this.props;

    return (
      <EditPersonalizationColorsInput
        fields={fields}
        disabled={defaultPersonalizationColor ? [defaultPersonalizationColor] : []}
        allColors={colors}
      />
    );
  };

  changeDefaultPersonalizationColor = async (color) => {
    const { change } = this.props;

    await change('defaultPersonalizationColor', color);
  };

  changeFloodColor = async (color) => {
    const { change } = this.props;

    await change('floodColorId', color);
  };

  changeColorGroup = async (color) => {
    const { change } = this.props;

    await change('colorGroup', color);
  };

  changeStyle = async (style) => {
    const { change } = this.props;

    change('styleId', style.id);

    this.setState(() => ({ displayedStyle: style }));
  };

  displayStyle = (style) => `${style.id}`;

  displayStyleSuggestion = (style) => `${style.id} - ${style.code} - ${style.name}`;

  render() {
    const {
      onSubmit,
      error,
      disabled = {},
      buttons,
      teamColors,
      change,
      customizable,
      customOnlyPersonalizationColors,
      defaultPersonalizationColor,
      colors,
      floodColorId,
      colorGroup,
      styleSuggestions,
      style,
      inventoryAlwaysAvailable,
      updateGridPreview,
      initialValues,
    } = this.props;

    const {
      imageUrlFront,
      imageUrlBack,
      imageUrlSide,
      imageUrlGrid,
      loadingGridFile,
      messageFrontImg,
      messageBackImg,
      messageSideImg,
      messageGridFile,
      uploadFrontImgIsActive,
      uploadBackImgIsActive,
      uploadSideImgIsActive,
      gridPreviewModalIsOpen,
      gridPreviewUrl,
      displayedStyle,
    } = this.state;

    const websiteVisibilityOptions = generalOptions(getTrueOrFalseOptions('Disabled', 'Enabled'));
    const productAvailabilityOptions = generalOptions(getTrueOrFalseOptions('Discontinued', 'Available'));
    const preferredVendorOptions = generalOptions(getTrueOrFalseOptions());

    const dropzoneContentGridFile = loadingGridFile
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${messageGridFile.type}`}>{messageGridFile.body}</label>;

    return (
      <form
        className='redux-form w-100'
        onSubmit={onSubmit}
        onKeyPress={this.onKeyPress}
        id={editColoredStyleForm}
      >
        <div>
          <div className='redux-form__container'>
            <ImagePreviewModal
              modalIsOpen={gridPreviewModalIsOpen}
              closeModal={this.closeGridPreviewModal}
              imageUrl={gridPreviewUrl}
            />

            <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
              <div className='catalog-details__modal--container mb-30'>
                <div className='redux-form__column--size-xl'>
                  <div className='tableView__details--header--title mb-30'>
                    <span>Colored Style Images</span>
                  </div>
                  <div className='redux-form__row-image'>
                    <EditColoredStyleImageContainer
                      imageUrl={imageUrlFront}
                      emptyImageText={emptyImageTextEnum.Front}
                      openUploadContainer={this.openUploadFrontImgContainer}
                      removeImage={this.removeFrontImg}
                    />
                    <EditColoredStyleImageContainer
                      imageUrl={imageUrlBack}
                      emptyImageText={emptyImageTextEnum.Back}
                      openUploadContainer={this.openUploadBackImgContainer}
                      removeImage={this.removeBackImg}
                    />
                    <EditColoredStyleImageContainer
                      imageUrl={imageUrlSide}
                      emptyImageText={emptyImageTextEnum.Side}
                      openUploadContainer={this.openUploadSideImgContainer}
                      removeImage={this.removeSideImg}
                    />
                  </div>
                </div>
              </div>
            </PermissionHandler>

            <EditColoredStyleUploadFile
              message={messageFrontImg}
              change={change}
              accessor={'imageUrlFront'}
              changeImageUrl={this.changeImageFront}
              uploadIsActive={uploadFrontImgIsActive}
            />

            <EditColoredStyleUploadFile
              message={messageBackImg}
              change={change}
              accessor={'imageUrlBack'}
              changeImageUrl={this.changeImageBack}
              uploadIsActive={uploadBackImgIsActive}
            />

            <EditColoredStyleUploadFile
              message={messageSideImg}
              change={change}
              accessor={'imageUrlSide'}
              changeImageUrl={this.changeImageSide}
              uploadIsActive={uploadSideImgIsActive}
            />

            <div className='catalog-details__modal--container'>
              <div className='redux-form__column--size-xl'>
                <div className='tableView__details--header--title mb-30'>
                  <span>Colored Style Attributes</span>
                </div>
                <div className='redux-form__column--size-xl'>
                  <div className='ml-15 mr-30 m-b-30'>

                    <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
                      <div className='redux-form__row'>
                        <div className='redux-form__column--size-s'>
                          <label className='redux-form__label'>
                            Style ID
                            <span className='required'>*</span>
                          </label>
                          <AutocompleteInput
                            suggestions={styleSuggestions}
                            fetchSuggestions={this.getStyleSuggestions}
                            displayItem={this.displayStyle}
                            displaySuggestionText={this.displayStyleSuggestion}
                            selectedItem={style}
                            selectItem={this.changeStyle}
                            placeholder={'Search styles'}
                            hasSearchIcon={true}
                          />
                          <div className='flex mb-10'>
                            <span>{displayedStyle.id} - {displayedStyle.code} - {displayedStyle.name}</span>
                            <a
                              className='sku-setup__edit-action ml-5'
                              href={styleUrl(displayedStyle.id)}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <Icon materialIcon={'launch'} />
                            </a>
                          </div>
                        </div>
                        <div className='redux-form__column--size-s ml-20'>
                          <label className='redux-form__label'>
                            Color Group
                            <span className='required'>*</span>
                          </label>
                          <Field
                            name={'colorGroup'}
                            component={SearchInput}
                            disabled={disabled.colorGroup}
                            allItems={teamColors}
                            disabledItems={teamColors.filter((x) => x.name === colorGroup)}
                            currentItem={colorGroup}
                            changeItem={this.changeColorGroup}
                            searchKey={'name'}
                            displayKey={'name'}
                            placeholderText={'Search color groups'}
                            isFixed={false}
                          />
                        </div>

                        <div className='redux-form__column--size-s ml-20'>
                          <label className='redux-form__label'>
                            Code
                            <span className='required'>*</span>
                          </label>
                          <Field
                            name={'code'}
                            placeholder={'Enter code'}
                            component={Input}
                            type={'text'}
                            disabled={disabled.code}
                          />
                        </div>
                      </div>
                    </PermissionHandler>

                    <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
                      <div className='redux-form__row'>
                        <div className='redux-form__column--size-s'>
                          <label className='redux-form__label'>
                            Color A
                          </label>
                          <Field
                            name={'colorA'}
                            placeholder={'Enter Color A'}
                            component={Input}
                            type={'text'}
                            disabled={disabled.colorA}
                          />
                        </div>

                        <div className='redux-form__column--size-s ml-20'>
                          <label className='redux-form__label'>
                            Color B
                          </label>
                          <Field
                            name={'colorB'}
                            placeholder={'Enter Color B'}
                            component={Input}
                            type={'text'}
                            disabled={disabled.colorB}
                          />
                        </div>

                        <div className='redux-form__column--size-s ml-20'>
                          <label className='redux-form__label'>
                            Flood Color
                          </label>
                          <Field
                            name={'floodColorId'}
                            component={SearchInput}
                            disabled={disabled.floodColorId}
                            allItems={colors}
                            disabledItems={colors.filter((x) => x.id === floodColorId)}
                            currentItem={floodColorId}
                            changeItem={this.changeFloodColor}
                            searchKey={'id'}
                            displayKey={'code'}
                            placeholderText={'Search flood colors'}
                            isFixed={false}
                          />
                        </div>
                      </div>
                    </PermissionHandler>

                    <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
                      <div className='redux-form__row'>
                        <div className='redux-form__column--size-s'>
                          <label className='redux-form__label'>
                            Minimum Vendor Quantity
                          </label>
                          <Field
                            name={'qtyVendorMinimum'}
                            placeholder={'Enter minimum vendor quantity'}
                            component={Input}
                            type={'number'}
                            parse={parseNumber}
                            disabled={disabled.qtyVendorMinimum || inventoryAlwaysAvailable}
                          />
                        </div>
                        <div className='redux-form__column--size-s ml-20'>
                          <label className='redux-form__label'>
                            Default Personalization Color
                          </label>
                          <Field
                            name={'defaultPersonalizationColor'}
                            component={SearchInput}
                            disabled={disabled.defaultPersonalizationColor}
                            allItems={colors}
                            disabledItems={colors.filter((x) => customOnlyPersonalizationColors.includes(x.id))}
                            currentItem={defaultPersonalizationColor}
                            changeItem={this.changeDefaultPersonalizationColor}
                            searchKey={'id'}
                            displayKey={'code'}
                            placeholderText={'Search default personalization colors'}
                            isFixed={false}
                          />
                        </div>
                        <div className='redux-form__column--size-s ml-20'>
                          <div className='w-100 mt-20'>
                            <InventoryAlwaysAvailableCheckbox
                              change={change}
                              disabled={disabled.inventoryAlwaysAvailable}
                            />
                          </div>
                        </div>
                      </div>
                    </PermissionHandler>
                    {
                      customizable &&
                      <div className='redux-form__row'>
                        <div className='redux-form__column--size-xl m-b-30'>
                          <div className='redux-form__row'>
                            <FieldArray
                              name={'customOnlyPersonalizationColors'}
                              component={this.renderPersonalizationColorsInput}
                              defaultPersonalizationColor={defaultPersonalizationColor}
                              rerenderOnEveryChange={true}
                            />
                          </div>
                        </div>
                      </div>
                    }

                    <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
                      <div className='catalog-detail__modal--container mb-30'>
                        <label className='redux-form__label'>
                          Image Grid File
                        </label>
                        {
                          imageUrlGrid
                            ? (
                              <div>
                                <div className='flex-center'>
                                  <ImagePreviewCell
                                    imageUrl={initialValues.imageUrlGridPreview}
                                    openModalWithUrl={this.openModalWithGridPreview}
                                    noImageText={emptyImageTextEnum.Grid}
                                    height={'80px'}
                                    width={'80px'}
                                  />
                                  <Icon materialIcon={'attach_file'} />
                                  <a
                                    href={imageUrlGrid}
                                    className='catalog-details__link'
                                  >
                                    {imageUrlGrid}
                                  </a>
                                  <Icon
                                    materialIcon={'cancel'}
                                    onClick={this.removeGridFile}
                                    classes={'catalog-details__remove'}
                                  />
                                </div>
                                <div className='flex'>
                                  <div
                                    onClick={updateGridPreview.bind(null, initialValues.id)}
                                    className='sku-setup__edit-action mt-5'
                                  >
                                    <div className='sku-setup__link'>
                                      <Icon materialIcon={'refresh'} />
                                      <span>Generate Grid Preview</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                            : (
                              <div className='redux-form__row'>
                                <div className='upload-field--horizontal m-t-16 w-100'>
                                  <div className='upload-field'>
                                    <DropZoneWrapper
                                      onDrop={this.onDropGridImg}
                                      multiple={false}
                                      className={'block-drop-zone'}
                                      accept={acceptedFileType}
                                    >
                                      {dropzoneContentGridFile}
                                    </DropZoneWrapper>
                                  </div>
                                </div>
                              </div>
                            )
                        }
                      </div>
                    </PermissionHandler>

                    <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
                      <div className='redux-form__column--size-l'>
                        <div className='tableView__details--header--title mb-30'>
                          <span>Status</span>
                        </div>
                        <div className='redux-form__column--size-xl'>
                          <div className='ml-15 mr-30 m-b-30'>
                            <div className='redux-form__row'>
                              <div className='redux-form__column--size-m'>
                                <label className='redux-form__label'>
                                  Website Visibility
                                </label>
                                <Field
                                  name={'disabled'}
                                  component={Select}
                                  disabled={disabled.disabled}
                                  parse={parseStringToBoolean}
                                >
                                  {websiteVisibilityOptions}
                                </Field>
                              </div>
                              <div className='redux-form__column--size-m ml-20'>
                                <label className='redux-form__label'>
                                  Product Availability
                                </label>
                                <Field
                                  name={'discontinued'}
                                  component={Select}
                                  disabled={disabled.discontinued}
                                  parse={parseStringToBoolean}
                                >
                                  {productAvailabilityOptions}
                                </Field>
                              </div>
                              <div className='redux-form__column--size-m ml-40'>
                                <label className='redux-form__label'>
                                  MVP
                                </label>
                                <Field
                                  name={'preferredVendor'}
                                  component={Select}
                                  disabled={disabled.preferredVendor}
                                  parse={parseStringToBoolean}
                                >
                                  {preferredVendorOptions}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PermissionHandler>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FormError error={error} />

        </div>
        <div>
          {buttons}
        </div>
      </form>
    );
  }
}

ColoredStyleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.object,
  buttons: PropTypes.element.isRequired,
  initialValues: PropTypes.object.isRequired,
  teamColors: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })),
  change: PropTypes.func,
  customizable: PropTypes.bool.isRequired,
  customOnlyPersonalizationColors: PropTypes.arrayOf(PropTypes.number),
  defaultPersonalizationColor: PropTypes.number,
  floodColorId: PropTypes.number,
  colorGroup: PropTypes.string,
  style: PropTypes.object,
  inventoryAlwaysAvailable: PropTypes.bool,
  styleSuggestions: PropTypes.arrayOf(PropTypes.object),
  updateGridPreview: PropTypes.func.isRequired,
};

const selector = formValueSelector(editColoredStyleForm);
const ConnectedColoredStyleForm = connect((state) => ({
  customOnlyPersonalizationColors: selector(state, 'customOnlyPersonalizationColors'),
  defaultPersonalizationColor: selector(state, 'defaultPersonalizationColor'),
  floodColorId: selector(state, 'floodColorId'),
  colorGroup: selector(state, 'colorGroup'),
  inventoryAlwaysAvailable: selector(state, 'alwaysAvailableVendorInventory'),
  teamColors: state.support.teamColors,
  colors: state.productCatalog.colors,
  styleSuggestions: state.productCatalog.styleSuggestions,
}))(ColoredStyleForm);

export default connect()(ConnectedColoredStyleForm);
