import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  orderStatusEnum,
  orderItemStatusEnum,
} from '@constants/enums/orderEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { parseDateNumeric } from '@util/dateHandler';
import { formatDollarAmount } from '@util/numberHelpers';
import { getOrderSubtotalRefundAmountLeft } from '@util/paymentHelper';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import Tag from '@sharedComponents/Display/Tag';
import Image from '@sharedComponents/Display/Image';
import OrderManagementDetailsItemLabels from '../OrderManagementDetailsItemLabels';
import OrderManagementDetailsItemArrivalStatus from './OrderManagementDetailsItemArrivalStatus';
import OrderManagementDetailsItemActions from './OrderManagementDetailsItemActions';
import OrderManagementDetailsItemFlag from './OrderManagementDetailsItemFlag';
import OrderManagementDetailsItemExpand from './OrderManagementDetailsItemExpand';
import OrderManagementDetailsItemStatus from './OrderManagementDetailsItemStatus';
import PONumberTag from './OrderManagementDetailsItemContent/PONumberTag';
import PricePaidTag from './OrderManagementDetailsItemContent/PricePaidTag';

class OrderManagementDetailsItem extends Component {
  openPreviewModal = () => {
    const {
      item,
      openItemInfoModal,
    } = this.props;

    openItemInfoModal(item);
  };

  openFinancialsModal = () => {
    const {
      item,
      openOrderItemFinancialsModal,
      itemPricingDetails,
    } = this.props;

    openOrderItemFinancialsModal(item, itemPricingDetails);
  };

  changeExtend = () => {
    const {
      artworksExpandedOrCollapsedCallback,
      item,
    } = this.props;

    artworksExpandedOrCollapsedCallback(item.ids);
  };

  addPersonalization = () => {
    const {
      item,
      createPersonalization,
    } = this.props;

    createPersonalization(item);
  };

  renderShipmentNumber = () => {
    const {
      shipmentNumbers,
      shipDates,
    } = this.props;

    const shipmentNumbersList = Array.from(shipmentNumbers);
    shipmentNumbersList.sort();

    if (!shipmentNumbersList || shipmentNumbersList.length === 0) return null;

    let tooltipText;
    if (shipmentNumbersList.length === 1) {
      const shipmentNumber = shipmentNumbersList[0];
      tooltipText = (
        <div>
          <div>Item shipped in package</div>
          <div>#{shipmentNumbersList[0]} on {parseDateNumeric(shipDates[shipmentNumber])}</div>
        </div>
      );
    } else {
      tooltipText = (
        <div>
          <div>Items shipped in packages:</div>
          <div>
            {
              shipmentNumbersList
                .map((s) => `#${s} (on ${parseDateNumeric(shipDates[s])}`)
                .join(', ')
            }
          </div>
        </div>
      );
    }

    return (
      <MaterialTooltip
        tooltipText={tooltipText}
        placement={'top'}
      >
        <div className='order__item-shipment-number'>
          {shipmentNumbersList.join(', ')}
        </div>
      </MaterialTooltip>
    );
  };

  render() {
    const {
      item,
      order,
      openReplaceItemBySkuModal,
      openReplaceItemModal,
      openPreviewModal,
      editPersonalization,
      isCollapsed,
      shipmentNumbersList,
      refundItem,
      changeArtwork,
      orderFlags,
      openRemovePersonalizationModal,
      openChangeDecorationMethodModal,
      colorsDictionary,
      vendorsDictionary,
      itemPricingDetails,
      orderExistsOnCore,
      itemVariation,
    } = this.props;

    let expand = '';

    let artworkFilesExtended = !isCollapsed;
    let enableExpanding = true;

    if (item?.logos?.length === 0 &&
      item.personalizations && item.personalizations.length === 0) {
      artworkFilesExtended = false;
      enableExpanding = false;
    }

    if (artworkFilesExtended) {
      expand = (
        <OrderManagementDetailsItemExpand
          openModal={openPreviewModal}
          item={item}
          colorsDictionary={colorsDictionary}
        />
      );
    }

    return (
      <div className='order__item-container'>
        <div className='order__item'>
          <div className='order__item-column align__center'>
            <Image
              url={item.imageUrl}
              openInModal={this.openPreviewModal}
              showImageInModal={false}
            />
          </div>
          <div className='order__item-column'>
            <div className='order__item--sku-info'>
              <div className='flex'>
                <span>{item.sku}</span>
                <OrderManagementDetailsItemLabels item={item} />
              </div>
              <div className='align__center'>
                <OrderManagementDetailsItemArrivalStatus item={item} />
                <OrderManagementDetailsItemFlag
                  item={item}
                  orderFlags={orderFlags}
                />
              </div>
            </div>

            <div className='order__item--title'>
              <span className='order__item--style-name'>{item.styleName}</span>
              <OrderManagementDetailsItemStatus
                orderStatus={order.status.label}
                item={item}
              />
            </div>
            <ul className='order__item-tags'>
              <li>
                <Tag value={`x${item.quantity}`} />
              </li>
              <li>
                <Tag
                  label={'Size'}
                  value={item.size}
                />
              </li>
              <li>
                <Tag
                  label={'Color'}
                  value={item.colorGroup}
                />
              </li>
              <li>
                <PricePaidTag
                  item={item}
                  openFinancialsModal={this.openFinancialsModal}
                  itemPricingDetails={itemPricingDetails}
                  orderExistsOnCore={orderExistsOnCore}
                />
              </li>
              <li>
                <Tag
                  label={'Raised'}
                  value={formatDollarAmount(item.fundraisingAmount)}
                />
              </li>
              {
                item.vendorFulfilledItem && item.shipDate && (item.shipDate !== order.shipDate) &&
                <li>
                  <Tag
                    label={'Ship Date'}
                    value={parseDateNumeric(item.shipDate)}
                  />
                </li>
              }
              <li>
                <PONumberTag
                  item={item}
                  vendorsDictionary={vendorsDictionary}
                />
              </li>
            </ul>
          </div>

          <PermissionHandler
            permissions={PermissionsEnum.OrderManagementEdit}
            withCondition={
              order.status.label !== orderStatusEnum.Shipped
              && item.status.label !== orderItemStatusEnum.Shipped
              && !(item.canceled !== null || order.canceled !== null)}
            fallback={(
              <div className='order__item-buttons'>
                <div onClick={this.changeExtend}>
                  <Icon materialIcon={enableExpanding ? (artworkFilesExtended ? 'arrow_drop_up' : 'arrow_drop_down') : ''} />
                </div>
              </div>
            )}
          >
            <div className='order__item-buttons'>
              <OrderManagementDetailsItemActions
                order={order}
                openReplaceItemBySkuModal={openReplaceItemBySkuModal}
                openReplaceItemModal={openReplaceItemModal}
                item={item}
                editPersonalization={editPersonalization}
                addPersonalization={this.addPersonalization}
                openRemovePersonalizationModal={openRemovePersonalizationModal}
                openChangeDecorationMethodModal={openChangeDecorationMethodModal}
                refundItem={refundItem}
                changeArtwork={changeArtwork}
                shipmentNumbersList={shipmentNumbersList}
                orderFlags={orderFlags}
                isCanceled={item.canceled !== null || order.canceled !== null}
                isOrderCompleteRefund={order.isCompleteRefund
                  || (getOrderSubtotalRefundAmountLeft(order) <= 0)}
                itemVariation={itemVariation}
                orderExistsOnCore={orderExistsOnCore}
                itemCorePricingDetails={itemPricingDetails}
              />
              <div onClick={this.changeExtend}>
                <Icon materialIcon={enableExpanding ? (artworkFilesExtended ? 'arrow_drop_up' : 'arrow_drop_down') : ''} />
              </div>
            </div>
          </PermissionHandler>

          {this.renderShipmentNumber()}
        </div>
        {expand}
      </div>
    );
  }
}

OrderManagementDetailsItem.propTypes = {
  order: PropTypes.object.isRequired,
  item: PropTypes.object,
  openRemovePersonalizationModal: PropTypes.func.isRequired,
  openReplaceItemBySkuModal: PropTypes.func.isRequired,
  openReplaceItemModal: PropTypes.func.isRequired,
  openPreviewModal: PropTypes.func.isRequired,
  openItemInfoModal: PropTypes.func.isRequired,
  openOrderItemFinancialsModal: PropTypes.func.isRequired,
  openChangeDecorationMethodModal: PropTypes.func.isRequired,
  editPersonalization: PropTypes.func.isRequired,
  createPersonalization: PropTypes.func.isRequired,
  refundItem: PropTypes.func.isRequired,
  changeArtwork: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  artworksExpandedOrCollapsedCallback: PropTypes.func.isRequired,
  shipmentNumbers: PropTypes.object.isRequired,
  shipDates: PropTypes.object.isRequired,
  shipmentNumbersList: PropTypes.array,
  orderFlags: PropTypes.array,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
  vendorsDictionary: PropTypes.object,
  itemPricingDetails: PropTypes.object,
  orderExistsOnCore: PropTypes.bool,
  itemVariation: PropTypes.object,
};

export default OrderManagementDetailsItem;
