import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';

const AssignLogosToItemsTabHeader = React.memo(({
  title,
  isActiveTab,
  selectTab,
  iconChecked,
}) => (
  <div
    className={`tab-item ${isActiveTab ? 'is-active' : ''}`}
    onClick={selectTab}
  >
    {
      iconChecked
        ? (
          <Icon
            materialIcon={'check_circle'}
            classes={'logo-bank__assignment--check'}
          />
        )
        : (
          <Icon
            materialIcon={'panorama_fish_eye'}
            classes={'logo-bank__assignment--circle'}
          />
        )
    }
    <span>{title}</span>
  </div>
));

AssignLogosToItemsTabHeader.propTypes = {
  isActiveTab: PropTypes.bool.isRequired,
  selectTab: PropTypes.func.isRequired,
  iconChecked: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default AssignLogosToItemsTabHeader;
