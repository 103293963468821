import React, {
  useCallback,
  useMemo,
} from 'react';
import { QueryObserverResult } from 'react-query';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import {
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkqualitychecked,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkqualitychecked,
} from '@api/fulfillment/production-assembly-new';
import {
  OrderItemStatusEnum,
  ProductionAssemblyItemGroupDto,
  ProductionAssemblyOrderHeaderDto,
} from '@api/fulfillment/models';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  checksDisabled?: boolean;
  orderNumber: number;
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
}

type Props = OwnProps;

const ReviewItemActions = React.memo<Props>(({
  item,
  checksDisabled,
  orderNumber,
  refreshOrderItemGroups,
  refreshOrder,
}) => {
  const { mutateAsync: markOrderItemQualityChecked }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkqualitychecked();

  const { mutateAsync: unmarkOrderItemQualityChecked }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkqualitychecked();

  const qualityCheckEnabled = useMemo(() => {
    if (item.status?.status === OrderItemStatusEnum.QualityChecked) {
      return true;
    }

    let canQCItem = false;
    if (item.decorationMethod === decorationMethodEnum.Undefined) {
      canQCItem = item.status?.status === OrderItemStatusEnum.Assembled
      || item.status?.status === OrderItemStatusEnum.ProductionCompleted;
    } else if (item.waterproofStepRequired) {
      canQCItem = item.status?.status === OrderItemStatusEnum.Waterproofed;
    } else {
      canQCItem = item.status?.status === OrderItemStatusEnum.ProductionCompleted;
    }

    return canQCItem && !checksDisabled;
  }, [
    item,
    checksDisabled,
  ]);

  const selectQualityCheckedButton = useCallback(async () => {
    const model = {
      orderNumber,
      orderItemIds: item.orderItemIds!,
    };

    if (qualityCheckEnabled && item.status?.status !== OrderItemStatusEnum.QualityChecked) {
      await markOrderItemQualityChecked({
        orderNumber,
        data: model,
      });
      refreshOrderItemGroups();
      refreshOrder();
    } else if (item.status?.status === OrderItemStatusEnum.QualityChecked) {
      await unmarkOrderItemQualityChecked({
        orderNumber,
        data: model,
      });
      refreshOrderItemGroups();
      refreshOrder();
    }
  }, [
    refreshOrderItemGroups,
    refreshOrder,
    orderNumber,
    item,
    markOrderItemQualityChecked,
    unmarkOrderItemQualityChecked,
    qualityCheckEnabled,
  ]);

  if (!item) return null;

  const qualityCheckChecked = item.status?.status === OrderItemStatusEnum.QualityChecked;

  return (
    <div className='actions mr-15'>
      <MaterialTooltip
        tooltipText={item.status?.status === OrderItemStatusEnum.InPicking
          || item.status?.status === OrderItemStatusEnum.Initial
          ? '' : 'Quality Check'
        }
        placement={'right'}
      >
        <CheckButton
          disabled={!qualityCheckEnabled}
          text={'Quality Check'}
          checked={qualityCheckChecked}
          onClick={selectQualityCheckedButton}
        />
      </MaterialTooltip>
    </div>
  );
});

export default ReviewItemActions;
