import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from '@util/arrayHelper';
import ListGroupTitle from '@sharedComponents/Display/ListGroupTitle';
import LayoutSuggestion from '@sharedComponents/Layouts/LayoutsDropdown/LayoutSuggestion';
import { calculateDropdownHeight } from '@util/componentHelpers/dropdownsHelper';

class LayoutsDropdown extends PureComponent {
  state = {
    totalHeight: '',
  };

  saveHeight = (height) => {
    const { totalHeight } = this.state;

    if (!totalHeight) {
      this.setState({ totalHeight: calculateDropdownHeight(height) });
    }
  };

  render() {
    const {
      layouts,
      suggestions,
      addLayoutToList,
      isDropUp,
    } = this.props;

    const { totalHeight } = this.state;

    const layoutsDict = {};
    for (const layout of layouts) {
      if (!layoutsDict[layout.categoryId]) {
        layoutsDict[layout.categoryId] = [];
      }
      layoutsDict[layout.categoryId].push(layout);
    }

    const suggestionsDict = groupBy(suggestions, 'categoryId');

    return (
      <div
        className={`suggestions ${isDropUp ? 'suggestions__dropup' : 'suggestions__dropdown'} catalog-details__layouts-input__suggestions custom-scrollbar`}
        style={{ maxHeight: `${totalHeight}px` }}
      >
        {
          Object.keys(suggestionsDict).map((categoryId) => (
            <div key={categoryId}>
              <ListGroupTitle
                title={suggestionsDict[categoryId][0].category.name}
                isBold={true}
                classes={'catalog-details__layouts-input__suggestions-group--title'}
              />
              {
                suggestionsDict[categoryId].map((layout) => (
                  <LayoutSuggestion
                    key={layout.id}
                    layout={layout}
                    layouts={layouts}
                    addSuggestion={addLayoutToList}
                    saveHeight={this.saveHeight}
                  />
                ))
              }
            </div>
          ))
        }
      </div>
    );
  }
}

LayoutsDropdown.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  layouts: PropTypes.arrayOf(PropTypes.object),
  addLayoutToList: PropTypes.func.isRequired,
  isDropUp: PropTypes.bool,
};

export default LayoutsDropdown;
