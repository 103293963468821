const ID = 'PRODUCT_CATALOG' as const;

export const UPDATE_STYLE_TABLE = `${ID}/UPDATE_STYLE_ITEMS` as const;
export const UPDATE_STYLE_TABLE_STATE = `${ID}/UPDATE_STYLE_TABLE` as const;
export const RESET_STYLE_TABLE_STATE = `${ID}/RESET_STYLE_TABLE` as const;
export const UPDATE_COLORED_STYLES_TABLE = `${ID}/UPDATE_COLORED_STYLES_TABLE` as const;
export const UPDATE_SKU_SETUP_TABLE = `${ID}/UPDATE_SKU_SETUP_TABLE` as const;

export const UPDATE_STYLE = `${ID}/UPDATE_STYLE` as const;
export const UPDATE_COLORED_STYLE = `${ID}/UPDATE_COLORED_STYLE` as const;
export const UPDATE_COLORED_STYLES = `${ID}/UPDATE_COLORED_STYLES` as const;
export const UPDATE_DISPLAYED_COLORED_STYLES = `${ID}/UPDATE_DISPLAYED_COLORED_STYLES` as const;
export const UPDATE_SPORTS_FOR_STYLE = `${ID}/UPDATE_SPORTS_FOR_STYLE` as const;
export const UPDATE_LAYOUTS_FOR_STYLE = `${ID}/UPDATE_LAYOUTS_FOR_STYLE` as const;
export const UPDATE_PRODUCTS_FOR_STYLE = `${ID}/UPDATE_PRODUCTS_FOR_STYLE` as const;
export const UPDATE_PRODUCTS_FOR_COLORED_STYLE = `${ID}/UPDATE_PRODUCTS_FOR_COLORED_STYLE` as const;
export const UPDATE_PRODUCTS_FOR_COLORED_STYLES_DICTIONARY = `${ID}/UPDATE_PRODUCTS_FOR_COLORED_STYLES_DICTIONARY` as const;
export const UPDATE_PRODUCT_INVENTORY_FOR_STYLE = `${ID}/UPDATE_PRODUCT_INVENTORY_FOR_STYLE` as const;
export const UPDATE_PRODUCT_SKU_INFO_FOR_STYLE = `${ID}/UPDATE_PRODUCT_SKU_INFO_FOR_STYLE` as const;
export const UPDATE_PRODUCT_INVENTORY_FOR_COLORED_STYLE = `${ID}/UPDATE_PRODUCT_INVENTORY_FOR_COLORED_STYLE` as const;
export const UPDATE_COLORED_STYLES_FOR_STYLES = `${ID}/UPDATE_COLORED_STYLES_FOR_STYLES` as const;
export const UPDATE_SPORTS_FOR_STYLES = `${ID}/UPDATE_SPORTS_FOR_STYLES` as const;
export const UPDATE_STYLE_SUGGESTIONS = `${ID}/UPDATE_STYLE_SUGGESTIONS` as const;

export const UPDATE_PRODUCT = `${ID}/UPDATE_PRODUCT` as const;
export const UPDATE_ORDER_ITEM_PRODUCT = `${ID}/UPDATE_ORDER_ITEM_PRODUCT` as const;
export const UPDATE_PRODUCT_VENDOR_MAPPINGS_FOR_SKU = `${ID}/UPDATE_PRODUCT_VENDOR_MAPPINGS_FOR_SKU` as const;
export const UPDATE_PRODUCT_VENDOR_QUANTITIES = `${ID}/UPDATE_PRODUCT_VENDOR_QUANTITIES` as const;
export const UPDATE_PRODUCT_VENDOR_QUANTITIES_FOR_COLORED_STYLE = `${ID}/UPDATE_PRODUCT_VENDOR_QUANTITIES_FOR_COLORED_STYLE` as const;

export const UPDATE_BRANDS = `${ID}/UPDATE_BRANDS` as const;
export const UPDATE_VENDOR_NAMES = `${ID}/UPDATE_VENDOR_NAMES` as const;
export const UPDATE_CATEGORIES = `${ID}/UPDATE_CATEGORIES` as const;
export const UPDATE_PARENT_CATEGORIES = `${ID}/UPDATE_PARENT_CATEGORIES` as const;
export const UPDATE_SPORTS = `${ID}/UPDATE_SPORTS` as const;
export const UPDATE_DECORATION_LOCATIONS = `${ID}/UPDATE_DECORATION_LOCATIONS` as const;
export const UPDATE_DECORATION_LOCATION_ARTWORK_SIZES = `${ID}/UPDATE_DECORATION_LOCATION_ARTWORK_SIZES` as const;
export const UPDATE_DECORATION_LOCATION_PERSONALIZATION_SIZES = `${ID}/UPDATE_DECORATION_LOCATION_PERSONALIZATION_SIZES` as const;
export const UPDATE_LOGO_DECORATION_LOCATIONS = `${ID}/UPDATE_LOGO_DECORATION_LOCATIONS` as const;
export const UPDATE_PERSONALIZATION_DECORATION_LOCATIONS = `${ID}/UPDATE_PERSONALIZATION_DECORATION_LOCATIONS` as const;
export const UPDATE_VENDORS = `${ID}/UPDATE_VENDORS` as const;
export const UPDATE_VENDORS_TABLE = `${ID}/UPDATE_VENDORS_TABLE` as const;
export const UPDATE_VENDOR_TIMELINES = `${ID}/UPDATE_VENDOR_TIMELINES` as const;
export const UPDATE_VENDOR = `${ID}/UPDATE_VENDOR` as const;
export const UPDATE_SIZES = `${ID}/UPDATE_AVAILABLE_SIZES` as const;
export const UPDATE_COLOR_GROUPS = `${ID}/UPDATE_COLOR_GROUPS` as const;
export const UPDATE_AGES = `${ID}/UPDATE_AGES` as const;
export const UPDATE_GENDERS = `${ID}/UPDATE_GENDERS` as const;
export const UPDATE_COLORS = `${ID}/UPDATE_COLORS` as const;
export const UPDATE_DECORATION_METHODS = `${ID}/UPDATE_DECORATION_METHODS` as const;

export const UPDATE_STYLE_FROM_SSAPI = `${ID}/UPDATE_STYLE_FROM_SSAPI` as const;
export const UPDATE_COLORED_STYLES_FOR_STYLES_FROM_SSAPI = `${ID}/UPDATE_COLORED_STYLES_FOR_STYLES_FROM_SSAPI` as const;
export const UPDATE_PRODUCTS_FOR_STYLE_FROM_SSAPI = `${ID}/UPDATE_PRODUCTS_FOR_STYLE_FROM_SSAPI` as const;

export const UPDATE_DTG_ENVIRONMENTS = `${ID}/UPDATE_DTG_ENVIRONMENTS` as const;
export const UPDATE_DTG_ENVIRONMENT_OPTIONS = `${ID}/UPDATE_DTG_ENVIRONMENT_OPTIONS` as const;
export const UPDATE_DTG_PALLET_OPTIONS = `${ID}/UPDATE_DTG_PALLET_OPTIONS` as const;
export const UPDATE_DTG_PRETREATMENT_BATCH_OPTIONS = `${ID}/UPDATE_DTG_PRETREATMENT_BATCH_OPTIONS` as const;
export const UPDATE_SINGLE_DTG_COLORED_STYLE = `${ID}/UPDATE_SINGLE_DTG_COLORED_STYLE` as const;
export const UPDATE_DTG_ARTWORK_SETTINGS = `${ID}/UPDATE_DTG_ARTWORK_SETTINGS` as const;
export const UPDATE_DTG_PERSONALIZATION_SETTINGS = `${ID}/UPDATE_DTG_PERSONALIZATION_SETTINGS` as const;
export const UPDATE_DTG_TABLET_PROFILES = `${ID}/UPDATE_DTG_TABLET_PROFILES` as const;
export const UPDATE_DTG_TABLET_PROFILE_OPTIONS = `${ID}/UPDATE_DTG_TABLET_PROFILE_OPTIONS` as const;
export const UPDATE_DTG_CONFIGURATION_COLORED_STYLE = `${ID}/UPDATE_DTG_CONFIGURATION_COLORED_STYLE` as const;
export const UPDATE_PRODUCT_DETAILS_DICTIONARY = `${ID}/UPDATE_PRODUCT_DETAILS_DICTIONARY` as const;

export default ID;
