import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const LayoutDeleteModal = React.memo(({
  isOpen,
  closeModal,
  layout,
  deleteLayout,
}) => (
  <Modal
    title={'Delete Location Layout'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteLayout.bind(null, layout.id)}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <h3>Are you sure you want to remove layout <i>{layout.name}</i> from the list?</h3>
  </Modal>
));

LayoutDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  layout: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  deleteLayout: PropTypes.func.isRequired,
};

export default LayoutDeleteModal;
