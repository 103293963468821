import React from 'react';
import OrderSearch from '@sharedComponents/Production/Search/OrderSearch';
import {
  qualityCheckReviewByBarcodeUrl,
  qualityCheckReviewByOrderUrl,
} from '@constants/clientUrls/clientUrls';

const QualityCheckSearch = React.memo(() => (
  <OrderSearch
    reviewByOrderUrl={qualityCheckReviewByOrderUrl}
    reviewByBarcodeUrl={qualityCheckReviewByBarcodeUrl}
  />
));

export default QualityCheckSearch;
