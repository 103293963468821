import React from 'react';
import PropTypes from 'prop-types';

import { lockerMgrS3Logos } from '@constants/common';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';

const LogoDropdownWithImageItem = React.memo(({
  logo,
  isSelected,
  onClick,
  fileName,
}) => (
  <li
    onClick={onClick.bind(null, logo, isSelected)}
    className={`logo-dropdown__option button ${isSelected ? 'is-active' : ''}`}
  >
    <MaterialTooltip
      tooltipText={fileName}
      placement='left'
      interactive={true}
    >
      <div
        className='original-logo__image-wrapper'
        title={fileName}
      >
        <div
          className='original-logo__image'
          style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logo.image}")` }}
        />
      </div>
    </MaterialTooltip>
  </li>

));

LogoDropdownWithImageItem.propTypes = {
  logo: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default LogoDropdownWithImageItem;
