import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltipEnterDelay } from '@constants/values';
import { TooltipPlacement } from '@customTypes/tooltip';

interface OwnProps {
  tooltipText: React.ReactNode;
  children: JSX.Element;
  placement?: TooltipPlacement;
  open?: boolean;
  interactive?: boolean;
  tooltipClassName?: string;
  contentClasses?: string;
  popperClassNames?: string;
}

type Props = OwnProps;

const MaterialTooltip = React.memo<Props>(({
  tooltipText = '',
  placement = 'bottom',
  children,
  open,
  tooltipClassName,
  interactive,
  contentClasses = '',
  popperClassNames = '',
}) => (
  <Tooltip
    title={tooltipText ?? ''}
    enterDelay={tooltipEnterDelay}
    placement={placement}
    open={open}
    interactive={interactive}
    classes={{
      tooltip: tooltipClassName,
      popper: popperClassNames,
    }}
  >
    <div className={contentClasses}>
      {children}
    </div>
  </Tooltip>
));

export default MaterialTooltip;
