import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  addLayout,
  editLayout,
  deleteLayout,
  deleteLayouts,
  updateLayoutSports,
} from '@APICalls/layouts/actions';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { layoutForm } from '@constants/reduxForms';
import {
  fetchLayoutTable,
  fetchLayoutDetails,
  getSportsForLayout,
} from '@redux/layouts/actions';
import {
  getCategories,
  getSports,
} from '@redux/productCatalog/actions';
import { getPagingParamsFromTable } from '@util/tableHelpers';
import { parseDateTimeNumeric } from '@util/dateHandler';
import { materialSwal } from '@util/componentHelper';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import Table from '@sharedComponents/Table/Table';
import Button from '@sharedComponents/Buttons/Button';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Image from '@sharedComponents/Display/Image';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import TooltipCell from '@sharedComponents/Table/TableCells/TooltipCell';
import LayoutAddModal from './LayoutModals/LayoutAddModal';
import LayoutEditModal from './LayoutModals/LayoutEditModal';
import LayoutDeleteModal from './LayoutModals/LayoutDeleteModal';
import LayoutBulkDeleteModal from './LayoutModals/LayoutBulkDeleteModal';
import LayoutsActionsColumn from './LayoutsActionsColumn';
import LayoutsQuickView from './LayoutsQuickView.jsx/LayoutsQuickView';

const LayoutsTable = Table();

const bulkActionsEnum = {
  deleteLayouts: 'deleteLayouts',
};

const bulkActions = [
  {
    key: 1,
    value: bulkActionsEnum.deleteLayouts,
    name: 'Delete Layout(s)',
  },
];

class Layouts extends PureComponent {
  state = {
    addLayoutModalIsOpened: false,
    editLayoutModalIsOpened: false,
    deleteLayoutModalIsOpened: false,
    bulkDeleteLayoutsModalIsOpen: false,
    prefillAddLayoutModal: false,
    selectedLayout: null,
    selectedItems: [],
    isPageSelected: false,
    searchInput: '',
    categories: [],
    sports: [],
    layoutSports: this.props.layoutSports,
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getCategories());
    dispatch(getSports());
  }

  search = () => {
    const { dispatch } = this.props;

    const {
      pageNumber,
      pageSize,
      searchInput,
      categories,
      sports,
    } = this.state;

    dispatch(fetchLayoutTable(
      pageNumber,
      pageSize,
      searchInput,
      categories,
      sports
    ));
  };

  addLayout = async (form) => {
    const result = await addLayout(form);

    if (result && result.success) {
      if (form.sports.length > 0) {
        await updateLayoutSports(result.result.id, form.sports);
      }
      materialSwal('Success', result.message, 'success');
      this.closeAddLayoutModal();
    }
  };

  fetchData = (state, instance) => {
    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    this.setState({
      pageNumber: page + 1,
      pageSize,
    }, this.search);
  };

  getColumns = () => {
    const { sportsForLayoutsDictionary } = this.props;

    return (
      [
        {
          Header: 'Front, Back & Side Images',
          accessor: '',
          minWidth: 200,
          Cell: (cellProps) => (
            <div className='flex'>
              <Image
                url={cellProps.original.imageUrlFront}
                classes={'layouts-table__image'}
                showNoImage={false}
              />
              <Image
                url={cellProps.original.imageUrlBack}
                classes={'layouts-table__image'}
                showNoImage={false}
              />
              <Image
                url={cellProps.original.imageUrlLeft}
                classes={'layouts-table__image'}
                showNoImage={false}
              />
              <Image
                url={cellProps.original.imageUrlRight}
                classes={'layouts-table__image'}
                showNoImage={false}
              />
            </div>
          ),
        },
        {
          Header: 'ID & Layout Name',
          accessor: 'id',
          minWidth: 100,
          Cell: (cellProps) => cellProps.value && (
            <div>
              <div>LL{cellProps.value}</div>
              <div className='table__cell--details'>{cellProps.original.name ? cellProps.original.name : ''}</div>
            </div>
          ),
        },
        {
          Header: 'Category',
          accessor: 'category',
          minWidth: 80,
          Cell: (cellProps) => cellProps.value && cellProps.value.name && <span>{cellProps.value.name}</span>,
        },
        {
          Header: 'Sports',
          accessor: 'id',
          width: 160,
          resizable: false,
          Cell: (cellProps) => {
            const items = sportsForLayoutsDictionary[cellProps.value] &&
              sportsForLayoutsDictionary[cellProps.value].map((x) => x.name) || [];

            return (
              items.length > 0
                ? (
                  <TooltipCell
                    items={items}
                    displayName={items.length === 1 ? 'Sport' : 'Sports'}
                  />
                )
                : '-'
            );
          },
        },
        {
          Header: 'Created At',
          accessor: 'created',
          minWidth: 100,
          Cell: (cellProps) => cellProps.value && <span>{parseDateTimeNumeric(cellProps.value)}</span>,
        },
        {
          Header: '',
          accessor: '',
          minWidth: 40,
          resizable: false,
          style: { overflow: 'visible' },
          Cell: (cellProps) => (
            <LayoutsActionsColumn
              layout={cellProps.value}
              onLayoutEdit={this.openEditLayoutModal}
              onLayoutDelete={this.openDeleteLayoutModal}
            />
          ),
        },
      ]
    );
  };

  onSelectBulkAction = (selectedBulkAction) => {
    switch (selectedBulkAction) {
      case bulkActionsEnum.deleteLayouts:
        this.openBulkDeleteLayoutsModal();
        break;
    }
  };

  selectLayout = (selectedLayout) => {
    this.setState({ selectedLayout });
  };

  unselectLayout = () => {
    this.setState({ selectedLayout: null });
  };

  getTrProps = (state, rowInfo) => {
    const { selectedLayout } = this.state;

    return getSelectableTableRowProps(this.selectLayout, rowInfo, selectedLayout, 'id');
  };

  clearSearch = () => {
    this.setState({
      searchInput: '',
    }, this.search);
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  onCategoriesChange = (categories) => {
    if (!categories) {
      return;
    }

    this.setState({
      categories,
    }, this.search);
  };

  onSportsChange = (sports) => {
    if (!sports) {
      return;
    }

    this.setState({
      sports,
    }, this.search);
  };

  editLayout = async (form) => {
    const {
      pageNumber,
      pageSize,
    } = this.state;

    const result = await editLayout(form);

    if (result && result.success) {
      await updateLayoutSports(form.id, form.sports);
      materialSwal('Success', result.message, 'success');
      this.closeEditLayoutModal();
      this.search(pageNumber, pageSize);
    }
  };

  deleteLayout = async (layoutId) => {
    const result = await deleteLayout(layoutId);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeDeleteLayoutModal();
      this.search();
    }
  };

  deleteLayouts = async (layouts) => {
    const result = await deleteLayouts(layouts);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeBulkDeleteLayoutsModal();
      this.search();
    }
  };

  openAddLayoutModal = (fromQuickView = false) => {
    this.setState({
      addLayoutModalIsOpened: true,
      prefillAddLayoutModal: fromQuickView,
    });
  };

  closeAddLayoutModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(layoutForm));
    this.setState({
      addLayoutModalIsOpened: false,
      prefillAddLayoutModal: false,
    });
  };

  openEditLayoutModal = (layout, fromQuickView = false) => {
    this.setState({ editLayoutModalIsOpened: true });

    if (!fromQuickView) {
      const { dispatch } = this.props;
      dispatch(getSportsForLayout(layout.id));
      dispatch(fetchLayoutDetails(layout.id));
    }
  };

  closeEditLayoutModal = () => {
    const { dispatch } = this.props;

    dispatch(reset(layoutForm));
    this.setState({ editLayoutModalIsOpened: false });
  };

  openDeleteLayoutModal = (layout) => {
    this.setState({
      deleteLayoutModalIsOpened: true,
      selectedLayout: layout,
    });
  };

  closeDeleteLayoutModal = () => {
    this.setState({
      deleteLayoutModalIsOpened: false,
      selectedLayout: null,
    });
  };

  openBulkDeleteLayoutsModal = () => {
    this.setState({ bulkDeleteLayoutsModalIsOpen: true });
  };

  closeBulkDeleteLayoutsModal = () => {
    this.setState({ bulkDeleteLayoutsModalIsOpen: false });
  };

  updateSelection = (newSelectedItems, newIsPageSelected) => {
    this.setState({
      selectedItems: newSelectedItems,
      isPageSelected: newIsPageSelected,
    });
  };

  render() {
    const {
      addLayoutModalIsOpened,
      editLayoutModalIsOpened,
      deleteLayoutModalIsOpened,
      bulkDeleteLayoutsModalIsOpen,
      categories,
      sports,
      selectedLayout,
      selectedItems,
      isPageSelected,
      prefillAddLayoutModal,
    } = this.state;

    const {
      hasNextPage,
      hasPreviousPage,
      totalPages,
      queue,
      allCategories,
      allSports,
      layoutDetails,
      layoutSports,
    } = this.props;

    return (
      <div className='container'>
        <LayoutAddModal
          isOpen={addLayoutModalIsOpened}
          closeModal={this.closeAddLayoutModal}
          addLayout={this.addLayout}
          prefillModal={prefillAddLayoutModal}
          initialValues={{
            ...layoutDetails,
            sports: layoutSports,
          }}
        />
        <LayoutEditModal
          isOpen={editLayoutModalIsOpened}
          closeModal={this.closeEditLayoutModal}
          editLayout={this.editLayout}
          initialValues={{
            ...layoutDetails,
            sports: layoutSports,
          }}
        />
        <LayoutDeleteModal
          isOpen={deleteLayoutModalIsOpened}
          closeModal={this.closeDeleteLayoutModal}
          deleteLayout={this.deleteLayout}
          layout={selectedLayout || {}}
        />
        <LayoutBulkDeleteModal
          isOpen={bulkDeleteLayoutsModalIsOpen}
          closeModal={this.closeBulkDeleteLayoutsModal}
          deleteLayouts={this.deleteLayouts}
          layouts={selectedItems}
        />

        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
            <MultiSelectDropdown
              objects={allCategories}
              selectedObjects={categories}
              itemText={'categories'}
              updateCallback={this.onCategoriesChange}
              textKey={'name'}
              valueKey={'id'}
              classNames={'margin-left'}
            />
            <MultiSelectDropdown
              objects={allSports}
              selectedObjects={sports}
              itemText={'sports'}
              updateCallback={this.onSportsChange}
              textKey={'name'}
              valueKey={'id'}
              classNames={'margin-left'}
            />
          </div>
          <div className='flex'>
            <Button
              type={'primary'}
              text={'New Layout'}
              onClick={this.openAddLayoutModal.bind(null, false)}
              classes={'colors__add-btn'}
            />
          </div>
        </div>

        <div className='master-detail'>
          <div className='lockerManager__master'>
            <div className='sheet'>
              <LayoutsTable
                data={queue}
                columns={this.getColumns()}
                totalPages={totalPages}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                onFetchData={this.fetchData}
                getTrProps={this.getTrProps}
                tableName={'Layouts'}
                selectable={true}
                selectPredicateOrKey={'id'}
                updateSelection={this.updateSelection}
                selectedData={selectedItems}
                isPageSelected={isPageSelected}
                bulkActionsList={bulkActions}
                onSelectBulkAction={this.onSelectBulkAction}
              />
            </div>
          </div>
          {
            selectedLayout &&
            <LayoutsQuickView
              layout={selectedLayout}
              closeDetails={this.unselectLayout}
              openAddLayoutModal={this.openAddLayoutModal.bind(null, true)}
              openEditLayoutModal={this.openEditLayoutModal.bind(null, selectedLayout, true)}
              openDeleteLayoutModal={this.openDeleteLayoutModal.bind(null, selectedLayout)}
            />
          }
        </div>
      </div>
    );
  }
}

Layouts.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  allCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  })),
  allSports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  })),
  layoutSports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    created: PropTypes.string,
    updated: PropTypes.string,
    deleted: PropTypes.string,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    rowHash: PropTypes.string.isRequired,
  })),
  layoutDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired,
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    sport: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string,
    imageUrlFront: PropTypes.string,
    imageUrlBack: PropTypes.string,
    imageUrlRight: PropTypes.string,
    imageUrlLeft: PropTypes.string,
    logos: PropTypes.arrayOf(PropTypes.object),
    personalizations: PropTypes.arrayOf(PropTypes.object),
  }),
  sportsForLayoutsDictionary: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  layouts,
  productCatalog,
}) => ({
  queue: layouts.layoutsQueue.queue,
  pageNumber: layouts.layoutsQueue.pageNumber,
  pageSize: layouts.layoutsQueue.pageSize,
  hasPreviousPage: layouts.layoutsQueue.hasPreviousPage,
  hasNextPage: layouts.layoutsQueue.hasNextPage,
  totalPages: layouts.layoutsQueue.totalPages,
  layoutDetails: layouts.layoutDetails,
  layoutSports: layouts.layoutSports,
  sportsForLayoutsDictionary: layouts.sportsForLayoutsDictionary,
  allCategories: productCatalog.categories,
  allSports: productCatalog.sports,
});

export default connect(mapStateToProps)(Layouts);
