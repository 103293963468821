import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { restoreLogo } from '@APICalls/lockerManager/actions';
import {
  fetchArchivedLogos,
  fetchManagerLogos,
} from '@redux/lockerManager/actions';
import {
  beginBlockingRequest,
  endBlockingRequest,
} from '@redux/api/actions';
import {
  unarchiveTasks,
  fetchArtworkFileTasks,
} from '@redux/artworkFileTasks/actions';
import { lockerMgrS3Logos } from '@constants/common';
import { artworkTaskStatusEnum } from '@constants/enums/artworkEnums';
import { materialSwal } from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import ArchivedLogoRestoreModal from './ArchivedLogoRestoreModal';

class ArchivedLogo extends PureComponent {
  state = {
    archivedLogoRestoreModalIsOpen: false,
  };

  unArchiveArtworkTasks = async (tasks) => {
    const {
      dispatch,
      lockerId,
    } = this.props;
    const taskIds = [];

    tasks.items.forEach((task) => {
      if (task.status.label === artworkTaskStatusEnum.Completed
        && !!task.artworkTaskArchivation) {
        taskIds.push(task.id);
      }
    });

    let isUnArchivingSuccessful = true;
    if (taskIds.length > 0) {
      const res = await dispatch(unarchiveTasks(lockerId, taskIds));
      isUnArchivingSuccessful = res?.success;
    }

    if (isUnArchivingSuccessful) {
      materialSwal('Success', 'Logo and artwork tasks successfully unarchived!', 'success');
    }
    dispatch(fetchArchivedLogos(lockerId));
    dispatch(fetchManagerLogos(lockerId));
    this.closeArchivedLogoRestoreModal();
    dispatch(endBlockingRequest());
  };

  restoreLogoFromArchive = async () => {
    const {
      logo,
      dispatch,
    } = this.props;

    dispatch(beginBlockingRequest());

    const res = await restoreLogo(logo.id);

    if (res?.success) {
      dispatch(fetchArtworkFileTasks(null, logo.id, this.unArchiveArtworkTasks));
    } else {
      dispatch(endBlockingRequest());
    }
  };

  openArchivedLogoRestoreModal = () => {
    this.setState({ archivedLogoRestoreModalIsOpen: true });
  };

  closeArchivedLogoRestoreModal = () => {
    this.setState({ archivedLogoRestoreModalIsOpen: false });
  };

  render() {
    const { logo } = this.props;
    const { archivedLogoRestoreModalIsOpen } = this.state;
    const backgroundImage = `${lockerMgrS3Logos}/${logo.image}`;

    return (
      <div className='logo-bank__archive--logo'>
        <div
          className='logo-bank__archive--logo--image'
          style={{ backgroundImage: `url("${backgroundImage}")` }}
        />
        <Button
          onClick={this.openArchivedLogoRestoreModal}
          type={'secondary'}
          text={'Restore'}
          classes={'logo-bank__archive--logo--btn'}
        />
        <ArchivedLogoRestoreModal
          isOpen={archivedLogoRestoreModalIsOpen}
          closeModal={this.closeArchivedLogoRestoreModal}
          restoreLogo={this.restoreLogoFromArchive}
          logoImage={backgroundImage}
        />
      </div>
    );
  }
}

ArchivedLogo.propTypes = {
  logo: PropTypes.object.isRequired,
  lockerId: PropTypes.number.isRequired,
};

export default connect()(ArchivedLogo);
