import React from 'react';
import MaterialTooltip from '../Tooltips/MaterialTooltip';
import { TooltipPlacement } from '@customTypes/tooltip';

interface OwnProps {
  label?: string | JSX.Element;
  value?: string | number | boolean | JSX.Element | null | undefined;
  defaultValue?: string | number | boolean | JSX.Element;
  fieldClass?: string;
  labelClass?: string;
  valueClass?: string;
  isSlim?: boolean;
  dataTest?: string;
  tooltip?: React.ReactNode;
  tooltipPlacement?: TooltipPlacement;
  tooltipInteractive?: boolean;
}

type Props = OwnProps;

const InfoField = React.memo(({
  label,
  value,
  defaultValue = '-',
  fieldClass = '',
  labelClass = '',
  valueClass = '',
  isSlim = false,
  dataTest = 'info-field',
  tooltipInteractive,
  tooltip = '',
  tooltipPlacement = 'bottom',
}: Props) => {
  const component = (
    <div
      className={`info__field ${fieldClass}`}
      data-test={dataTest}
    >
      <div
        className={`info__label${isSlim ? '--slim mr-5' : ''} ${labelClass}`}
        data-test={`info__label__${dataTest}`}
      >
        {label}
      </div>
      <div
        className={`info__value${isSlim ? '--slim' : ''} ${valueClass}`}
        data-test={`info__value__${dataTest}`}
      >
        {value || defaultValue}
      </div>
    </div>
  );

  return tooltip ? (
    <MaterialTooltip
      tooltipText={tooltip}
      placement={tooltipPlacement}
      interactive={tooltipInteractive}
    >{component}
    </MaterialTooltip>
  ) : component;
});

export default InfoField;
