import React from 'react';

interface OwnProps {
  name: string;
}

type Props = OwnProps;

const DropdownGroupNameItem = React.memo(({ name }: Props) => (
  <li
    className={'custom-dropdown__option--group-name uppercase'}
  >
    <div>
      {name}
    </div>
  </li>
));

export default DropdownGroupNameItem;
