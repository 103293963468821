import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  decorationTypeEnum,
  personalizationTypeEnum,
  personalizationColorTypeEnum,
} from '@constants/enums/decorationEnums';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import {
  fetchLayoutDetails,
  getSportsForLayout,
} from '@redux/layouts/actions';
import { getDecorationLocations } from '@redux/productCatalog/actions';
import Button from '@sharedComponents/Buttons/Button';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import InfoField from '@sharedComponents/Display/InfoField';
import Tag from '@sharedComponents/Display/Tag';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';

class LayoutsQuickView extends PureComponent {
  componentDidMount() {
    const {
      dispatch,
      layout,
    } = this.props;

    if (layout) {
      dispatch(fetchLayoutDetails(layout.id));
      dispatch(getSportsForLayout(layout.id));
    }

    dispatch(getDecorationLocations(decorationTypeEnum.Logo));
    dispatch(getDecorationLocations(decorationTypeEnum.Personalization));
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      layout,
    } = this.props;

    if (layout && layout !== prevProps.layout) {
      dispatch(fetchLayoutDetails(layout.id));
      dispatch(getSportsForLayout(layout.id));
    }
  }

  mapLocation = (decoration, locations) => {
    const location = locations.find((l) => l.id === decoration.locationId);

    if (location) {
      return {
        ...decoration,
        ...location,
      };
    }

    return null;
  };

  render() {
    const {
      layoutDetails,
      closeDetails,
      logoDecorationLocations,
      personalizationDecorationLocations,
      openAddLayoutModal,
      openEditLayoutModal,
      openDeleteLayoutModal,
      layoutSports,
    } = this.props;

    if (!layoutDetails) {
      return (
        <TableQuickViewContainer
          noPadding={true}
          size={TableQuickViewSizeEnum.Medium}
        >
          <TableQuickViewHeader
            title={'Preview'}
            onClose={closeDetails}
          />
        </TableQuickViewContainer>
      );
    }

    const logoLocations = layoutDetails.logos
      .map((logo) => this.mapLocation(logo, logoDecorationLocations))
      .filter((l) => l !== null);

    const personalizationLocations = layoutDetails.personalizations
      .map((personalization) => this.mapLocation(personalization, personalizationDecorationLocations))
      .filter((l) => l !== null);

    return (
      <TableQuickViewContainer
        noPadding={true}
        size={TableQuickViewSizeEnum.FitContent}
      >
        <TableQuickViewHeader
          title={'Preview'}
          onClose={closeDetails}
        />
        <div className='tableView__details--content h-100'>
          <div className='h-100'>
            <div className='tableView__details--content-container no-border pt-0 mb-20'>
              <InfoField
                label={'Layout Name'}
                value={layoutDetails.name}
                fieldClass={'order__shipping__column--long'}
              />
              <div className='flex'>
                <InfoField
                  label={'Category'}
                  value={layoutDetails.category && layoutDetails.category.name}
                  fieldClass={'order__shipping__column--long w-30 mr-20'}
                />
                <InfoField
                  label={'Sports'}
                  value={layoutSports.map((sport) => sport.name).join(', ')}
                  fieldClass={'order__shipping__column--short w-30'}
                />
              </div>
            </div>
            <div className='tableView__details--content-container no-border p-0 mb-40'>
              <div className='tableView__details--header--title pl-30 pr-30 mb-10'>
                <span>Logo Locations</span>
              </div>
              <hr />
              {
                logoLocations.map((l) => (
                  <div key={l.code}>
                    <div className='pl-30 pr-30'>
                      <div className='layouts-details__location--name mb-10'>{l.code} - {l.label}</div>
                      <div>
                        {
                          (!l.decorationWidth && !l.decorationHeight)
                            ? (
                              <Tag
                                value={'Default'}
                                classes={'layouts-details__tag'}
                              />
                            )
                            : (
                              <Tag
                                value={`${l.decorationWidth}'' x ${l.decorationHeight}''`}
                                classes={'layouts-details__tag'}
                              />
                            )
                        }
                      </div>
                    </div>
                    <hr />
                  </div>
                ))
              }
            </div>
            <div className='tableView__details--content-container no-border p-0'>
              <div className='tableView__details--header--title pl-30 pr-30 mb-10'>
                <span>Personalization Locations</span>
              </div>
              <hr />
              {
                personalizationLocations.map((l) => (
                  <div key={l.code}>
                    <div className='pl-30 pr-30'>
                      <div className='layouts-details__location--name mb-10'>{l.code} - {l.label}</div>
                      <div className='flex'>
                        {
                          l.personalizationType === personalizationTypeEnum.Name &&
                          <Tag
                            value={'Name'}
                            classes={'layouts-details__tag mr-5'}
                          />
                        }
                        {
                          l.personalizationType === personalizationTypeEnum.Number &&
                          <Tag
                            value={'Number'}
                            classes={'layouts-details__tag mr-5'}
                          />
                        }
                        {
                          l.personalizationType === personalizationTypeEnum.NameAndNumber &&
                          <Tag
                            value={'Name and Number'}
                            classes={'layouts-details__tag mr-5'}
                          />
                        }
                        {
                          l.personalizationColorType === personalizationColorTypeEnum.Fill &&
                          <Tag
                            value={'1 Color'}
                            classes={'layouts-details__tag mr-5'}
                          />
                        }
                        {
                          l.personalizationColorType === personalizationTypeEnum.FillAndOutline &&
                          <Tag
                            value={'2 Colors'}
                            classes={'layouts-details__tag mr-5'}
                          />
                        }
                        {
                          !l.decorationHeight
                            ? (
                              <Tag
                                value={'Default'}
                                classes={'layouts-details__tag mr-5'}
                              />
                            )
                            : (
                              <Tag
                                label={'Height'}
                                value={`${l.decorationHeight}''`}
                                classes={'layouts-details__tag mr-5'}
                              />
                            )
                        }
                        {
                          !l.required &&
                          <Tag
                            value={'Optional'}
                            classes={'layouts-details__tag'}
                          />
                        }
                      </div>
                    </div>
                    <hr />
                  </div>
                ))
              }
            </div>
          </div>
          <div className='tableView__details--actions flex layouts-details__footer'>
            <Button
              type={'danger'}
              text={'Delete'}
              onClick={openDeleteLayoutModal}
              classes={'mr-5'}
            />
            <Button
              type={'secondary'}
              text={'Clone'}
              onClick={openAddLayoutModal}
              classes={'mr-5'}
            />
            <Button
              type={'primary'}
              text={'Edit'}
              onClick={openEditLayoutModal}
            />
          </div>
        </div>

      </TableQuickViewContainer>
    );
  }
}

LayoutsQuickView.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  layoutDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    categoryId: PropTypes.number.isRequired,
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    sport: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string,
    imageUrlFront: PropTypes.string,
    imageUrlBack: PropTypes.string,
    imageUrlRight: PropTypes.string,
    imageUrlLeft: PropTypes.string,
    logos: PropTypes.arrayOf(PropTypes.object),
    personalizations: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  closeDetails: PropTypes.func.isRequired,
  openAddLayoutModal: PropTypes.func.isRequired,
  openEditLayoutModal: PropTypes.func.isRequired,
  openDeleteLayoutModal: PropTypes.func.isRequired,
  logoDecorationLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  personalizationDecorationLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  layoutSports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  })),
};

const mapStateToProps = ({
  layouts,
  productCatalog,
}) => ({
  layoutDetails: layouts.layoutDetails,
  logoDecorationLocations: productCatalog.logoDecorationLocations,
  layoutSports: layouts.layoutSports,
  personalizationDecorationLocations: productCatalog.personalizationDecorationLocations,
});

export default connect(mapStateToProps)(LayoutsQuickView);
