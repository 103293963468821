import * as actionTypes from './types';

const initialState = {
  currentRequestCount: 0,
  isLoadingActive: false,
  currentBlockingRequestCount: 0,
  isBlockingLoadingActive: false,
};

export default function api(state = initialState, action) {
  switch (action.type) {
    case actionTypes.BEGIN_REQUEST:
      return {
        ...state,
        currentRequestCount: state.currentRequestCount += 1,
        isLoadingActive: true,
      };
    case actionTypes.END_REQUEST:
      return {
        ...state,
        currentRequestCount: state.currentRequestCount > 0 ? state.currentRequestCount -= 1 : 0,
        isLoadingActive: state.currentRequestCount > 0,
      };
    case actionTypes.BEGIN_BLOCKING_REQUEST:
      return {
        ...state,
        currentBlockingRequestCount: state.currentBlockingRequestCount += 1,
        isBlockingLoadingActive: true,
      };
    case actionTypes.END_BLOCKING_REQUEST:
      return {
        ...state,
        currentBlockingRequestCount: state.currentBlockingRequestCount > 0 ? state.currentBlockingRequestCount -= 1 : 0,
        isBlockingLoadingActive: state.currentBlockingRequestCount > 0,
      };
    default:
      return state;
  }
}
