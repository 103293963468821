/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Accounts Homefield API
 * OpenAPI spec version: 2.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  MerchandisingEffortDtoApiResponse,
  ApiResponse,
  BooleanApiResponse,
  MerchandisingEffortDtoPagedList,
  GetHomefieldApiV2MerchandisingAllParams,
  PostHomefieldApiV2MerchandisingBody,
  PutHomefieldApiV2MerchandisingBody,
  Int32ApiResponse,
  PostHomefieldApiV2MerchandisingAddtargetsBody,
  PostHomefieldApiV2MerchandisingAddsourceBody,
  BulkUpdateMerchandisingOptInCommand
} from './models'
import { accountsApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiV2MerchandisingId = <TData = MerchandisingEffortDtoApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApi>) => {
      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV2MerchandisingIdQueryKey = (id: number,) => [`/homefield/api/v2/merchandising/${id}`]

    
export const useGetHomefieldApiV2MerchandisingId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV2MerchandisingId, MerchandisingEffortDtoApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV2MerchandisingIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV2MerchandisingId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const deleteHomefieldApiV2MerchandisingId = <TData = BooleanApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApi>) => {
      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiV2MerchandisingId = <TData = AsyncReturnType<typeof deleteHomefieldApiV2MerchandisingId,BooleanApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiV2MerchandisingId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiV2MerchandisingAll = <TData = MerchandisingEffortDtoPagedList>(
    params?: GetHomefieldApiV2MerchandisingAllParams,
 options?: SecondParameter<typeof accountsApi>) => {
      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/all`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiV2MerchandisingAllQueryKey = (params?: GetHomefieldApiV2MerchandisingAllParams,) => [`/homefield/api/v2/merchandising/all`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiV2MerchandisingAll = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiV2MerchandisingAll, MerchandisingEffortDtoPagedList>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiV2MerchandisingAllParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof accountsApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiV2MerchandisingAllQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiV2MerchandisingAll<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiV2Merchandising = <TData = MerchandisingEffortDtoApiResponse>(
    postHomefieldApiV2MerchandisingBody: PostHomefieldApiV2MerchandisingBody,
 options?: SecondParameter<typeof accountsApi>) => {const formData = new FormData();
if(postHomefieldApiV2MerchandisingBody.name) {
 formData.append('name', postHomefieldApiV2MerchandisingBody.name)
 }
if(postHomefieldApiV2MerchandisingBody.startDate) {
 formData.append('startDate', postHomefieldApiV2MerchandisingBody.startDate)
 }
if(postHomefieldApiV2MerchandisingBody.endDate) {
 formData.append('endDate', postHomefieldApiV2MerchandisingBody.endDate)
 }
if(postHomefieldApiV2MerchandisingBody.type) {
 formData.append('type', postHomefieldApiV2MerchandisingBody.type)
 }
if(postHomefieldApiV2MerchandisingBody.sourceId) {
 formData.append('sourceId', postHomefieldApiV2MerchandisingBody.sourceId.toString())
 }
if(postHomefieldApiV2MerchandisingBody.createdBy) {
 formData.append('createdBy', postHomefieldApiV2MerchandisingBody.createdBy)
 }
if(postHomefieldApiV2MerchandisingBody.updatedBy) {
 formData.append('updatedBy', postHomefieldApiV2MerchandisingBody.updatedBy)
 }
if(postHomefieldApiV2MerchandisingBody.targetsCsv) {
 formData.append('targetsCsv', postHomefieldApiV2MerchandisingBody.targetsCsv)
 }

      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising`, method: 'post',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiV2Merchandising = <TData = AsyncReturnType<typeof postHomefieldApiV2Merchandising,MerchandisingEffortDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PostHomefieldApiV2MerchandisingBody}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PostHomefieldApiV2MerchandisingBody}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiV2Merchandising<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiV2Merchandising = <TData = MerchandisingEffortDtoApiResponse>(
    putHomefieldApiV2MerchandisingBody: PutHomefieldApiV2MerchandisingBody,
 options?: SecondParameter<typeof accountsApi>) => {const formData = new FormData();
if(putHomefieldApiV2MerchandisingBody.id) {
 formData.append('id', putHomefieldApiV2MerchandisingBody.id.toString())
 }
if(putHomefieldApiV2MerchandisingBody.name) {
 formData.append('name', putHomefieldApiV2MerchandisingBody.name)
 }
if(putHomefieldApiV2MerchandisingBody.startDate) {
 formData.append('startDate', putHomefieldApiV2MerchandisingBody.startDate)
 }
if(putHomefieldApiV2MerchandisingBody.endDate) {
 formData.append('endDate', putHomefieldApiV2MerchandisingBody.endDate)
 }
if(putHomefieldApiV2MerchandisingBody.updatedBy) {
 formData.append('updatedBy', putHomefieldApiV2MerchandisingBody.updatedBy)
 }

      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising`, method: 'put',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiV2Merchandising = <TData = AsyncReturnType<typeof putHomefieldApiV2Merchandising,MerchandisingEffortDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PutHomefieldApiV2MerchandisingBody}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PutHomefieldApiV2MerchandisingBody}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiV2Merchandising<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiV2MerchandisingAddtargets = <TData = Int32ApiResponse>(
    postHomefieldApiV2MerchandisingAddtargetsBody: PostHomefieldApiV2MerchandisingAddtargetsBody,
 options?: SecondParameter<typeof accountsApi>) => {const formData = new FormData();
if(postHomefieldApiV2MerchandisingAddtargetsBody.effortId) {
 formData.append('effortId', postHomefieldApiV2MerchandisingAddtargetsBody.effortId.toString())
 }
if(postHomefieldApiV2MerchandisingAddtargetsBody.targetsCsv) {
 formData.append('targetsCsv', postHomefieldApiV2MerchandisingAddtargetsBody.targetsCsv)
 }

      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/add-targets`, method: 'post',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiV2MerchandisingAddtargets = <TData = AsyncReturnType<typeof postHomefieldApiV2MerchandisingAddtargets,Int32ApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PostHomefieldApiV2MerchandisingAddtargetsBody}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PostHomefieldApiV2MerchandisingAddtargetsBody}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiV2MerchandisingAddtargets<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiV2MerchandisingAddsource = <TData = Int32ApiResponse>(
    postHomefieldApiV2MerchandisingAddsourceBody: PostHomefieldApiV2MerchandisingAddsourceBody,
 options?: SecondParameter<typeof accountsApi>) => {const formData = new FormData();
if(postHomefieldApiV2MerchandisingAddsourceBody.effortId) {
 formData.append('effortId', postHomefieldApiV2MerchandisingAddsourceBody.effortId.toString())
 }
if(postHomefieldApiV2MerchandisingAddsourceBody.sourceId) {
 formData.append('sourceId', postHomefieldApiV2MerchandisingAddsourceBody.sourceId.toString())
 }

      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/add-source`, method: 'post',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiV2MerchandisingAddsource = <TData = AsyncReturnType<typeof postHomefieldApiV2MerchandisingAddsource,Int32ApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PostHomefieldApiV2MerchandisingAddsourceBody}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PostHomefieldApiV2MerchandisingAddsourceBody}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiV2MerchandisingAddsource<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiV2MerchandisingTargetIdToggleoptin = <TData = BooleanApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApi>) => {
      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/target/${id}/toggle-opt-in`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiV2MerchandisingTargetIdToggleoptin = <TData = AsyncReturnType<typeof putHomefieldApiV2MerchandisingTargetIdToggleoptin,BooleanApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  putHomefieldApiV2MerchandisingTargetIdToggleoptin<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiV2MerchandisingTargetUpdateoptin = <TData = BooleanApiResponse>(
    bulkUpdateMerchandisingOptInCommand: BulkUpdateMerchandisingOptInCommand,
 options?: SecondParameter<typeof accountsApi>) => {
      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/target/update-opt-in`, method: 'put',
      data: bulkUpdateMerchandisingOptInCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiV2MerchandisingTargetUpdateoptin = <TData = AsyncReturnType<typeof putHomefieldApiV2MerchandisingTargetUpdateoptin,BooleanApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: BulkUpdateMerchandisingOptInCommand}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: BulkUpdateMerchandisingOptInCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiV2MerchandisingTargetUpdateoptin<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const patchHomefieldApiV2MerchandisingIdAbort = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApi>) => {
      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/${id}/abort`, method: 'patch',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePatchHomefieldApiV2MerchandisingIdAbort = <TData = AsyncReturnType<typeof patchHomefieldApiV2MerchandisingIdAbort,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  patchHomefieldApiV2MerchandisingIdAbort<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const patchHomefieldApiV2MerchandisingIdReady = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApi>) => {
      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/${id}/ready`, method: 'patch',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePatchHomefieldApiV2MerchandisingIdReady = <TData = AsyncReturnType<typeof patchHomefieldApiV2MerchandisingIdReady,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  patchHomefieldApiV2MerchandisingIdReady<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const patchHomefieldApiV2MerchandisingIdCreated = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof accountsApi>) => {
      return accountsApi<TData>(
      {url: `/homefield/api/v2/merchandising/${id}/created`, method: 'patch',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePatchHomefieldApiV2MerchandisingIdCreated = <TData = AsyncReturnType<typeof patchHomefieldApiV2MerchandisingIdCreated,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof accountsApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  patchHomefieldApiV2MerchandisingIdCreated<TData>(id,requestOptions)
      }, mutationOptions)
    }
    