import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const GridItemCustomContainer = React.memo(({
  queueName,
  children,
}) => (
  <Grid item={true}>
    <div className='ll-scheduling__title llScheduling__title--first-title text-uppercase'>
      {queueName} scheduling
    </div>
    <Grid
      container={true}
      direction='row'
      alignItems='center'
      spacing={1}
    >
      {children}
    </Grid>
  </Grid>
));

GridItemCustomContainer.propTypes = {
  queueName: PropTypes.string,
  children: PropTypes.function,
};

export default GridItemCustomContainer;
