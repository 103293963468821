export const coloredStyleSelections = 'colored_style_selections' as const;

export const editEssential = (
  productId: number,
  isCustomItem: boolean
): string => `${coloredStyleSelections}/${productId}/change-essential?isCustomItem=${isCustomItem}`;

export const refreshCachedImage = (productId: number): string => `${coloredStyleSelections}/${productId}/refresh-cached-image`;

export default {
  editEssential,
  refreshCachedImage,
};
