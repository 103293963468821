import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LogoCell from './LogoCell';
import TextCell from './TextCell';
import { connect } from 'react-redux';

class LogoOrTextCell extends PureComponent {
  render() {
    const {
      item,
      openModal,
      colorsDictionary,
    } = this.props;

    if (item.previewUrl) {
      return (
        <LogoCell
          openModal={openModal}
          logoUrl={item.previewUrl}
          indicator={item.indicator}
        />
      );
    }

    return (
      <TextCell
        personalizationColor={colorsDictionary[item.personalizationColorId]}
        personalizationText={item.personalizationText}
        personalizationNumber={item.personalizationNumber}
        personalizationOutlineColor={colorsDictionary[item.personalizationOutlineColorId]}
      />
    );
  }
}

LogoOrTextCell.propTypes = {
  item: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  colorsDictionary: productCatalog.colorsDictionary,
});

export default connect(mapStateToProps)(LogoOrTextCell);
