import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

class CollectionActionColumn extends PureComponent {
  render() {
    const {
      collection,
      openDeleteModal,
    } = this.props;

    return (
      <ShowMoreActionsButton
        actions={[
          {
            text: 'Remove Collection',
            action: openDeleteModal.bind(null, collection),
            isDangerous: true,
            isVisible: true,
          },
        ]}
      />
    );
  }
}

CollectionActionColumn.propTypes = {
  collection: PropTypes.object.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
};

export default CollectionActionColumn;
