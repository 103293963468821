import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class SelectableSwatchColorField extends PureComponent {
  onSelectColor = () => {
    const {
      colorCode,
      handleSelect,
    } = this.props;

    handleSelect(colorCode);
  };

  render() {
    const {
      colorCode,
      isDeleted,
      isAdded,
      colors,
    } = this.props;

    const color = colors.find((c) => c.code === colorCode);

    const className = classNames(
      'color-swatch-item',
      { 'delete': isDeleted },
      { 'update': isAdded }
    );

    return (
      <div
        className={className}
        onClick={this.onSelectColor}
        style={{ cursor: 'pointer' }}
      >
        <div
          className='color-swatch-item__color'
          style={color ? { backgroundColor: color.hexValue } : {}}
        />
        <span className='color-swatch-item__number'>{color && color.displayName || colorCode}</span>
      </div>
    );
  }
}

SelectableSwatchColorField.propTypes = {
  colorCode: PropTypes.string.isRequired,
  isDeleted: PropTypes.bool,
  isAdded: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  method: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    hexValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })),
};

export default SelectableSwatchColorField;
