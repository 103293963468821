/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type SupportQueriesSortByEnum = 'Code' | 'Name';


export const SupportQueriesSortByEnum = {
  Code: 'Code' as SupportQueriesSortByEnum,
  Name: 'Name' as SupportQueriesSortByEnum,
};
