import React from 'react';
import PropTypes from 'prop-types';
import LogoCard from './LogoCard';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';

const SubmitLogoReviewsModal = React.memo(({
  reviewedLogos,
  allLogos,
  submitLogoReviews,
  closeModal,
  isOpen,
}) => {
  const approvedLogos = reviewedLogos.filter((l) => l.isApproved);
  const unapprovedLogos = reviewedLogos.filter((l) => !l.isApproved);
  const logosWithoutReview = allLogos.filter((l) => reviewedLogos.find((a) => a.id === l.id) === undefined);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={'Submit Logo Reviews'}
      modalHeight={'xl'}
      modalWidth={'l'}
      buttons={(
        <ModalButtons
          onClose={closeModal}
          onConfirm={submitLogoReviews.bind(null, reviewedLogos, logosWithoutReview)}
          confirmBtnText={'Continue'}
        />
      )}
    >
      {
        approvedLogos.length > 0 &&
        <div>
          <div className='logo-review__task__options-title mb-20'>
            Approved Logos ({approvedLogos.length})
          </div>
          <div className='logo-review__tasks-submit__logos mb-20'>
            {
              approvedLogos.map((l) => (
                <LogoCard
                  key={l.id}
                  logo={l}
                  isReviewed={true}
                />
              ))
            }
          </div>
        </div>
      }

      {
        unapprovedLogos.length > 0 &&
        <div>
          <div className='logo-review__task__options-title mb-20'>
            Unapproved Logos ({unapprovedLogos.length})
          </div>
          <div className='logo-review__tasks-submit__logos mb-20'>
            {
              unapprovedLogos.map((l) => (
                <LogoCard
                  key={l.id}
                  logo={l}
                  isReviewed={true}
                />
              ))
            }
          </div>
        </div>
      }

      {
        logosWithoutReview.length > 0 &&
        <>
          {(approvedLogos.length > 0 || unapprovedLogos.length > 0) && <hr className='w-100' />}
          <div>
            <div className='logo-review__task__options-title mb-20'>
              Logos without Review ({logosWithoutReview.length})
            </div>
            <div className='logo-review__tasks-submit__text mb-20'>Unreviewed logos will return to Logo Review queue.</div>
            <div className='logo-review__tasks-submit__logos'>
              {
                logosWithoutReview.map((l) => (
                  <LogoCard
                    key={l.id}
                    logo={l}
                    isReviewed={false}
                  />
                ))
              }
            </div>
          </div>
        </>
      }
    </Modal>
  );
});

SubmitLogoReviewsModal.propTypes = {
  reviewedLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
  allLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitLogoReviews: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SubmitLogoReviewsModal;
