import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dragAndDropMessage } from '@constants/values';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';

class ArtworkTaskNoteUploader extends Component {
  state = {
    message: {
      type: 'default',
      body: dragAndDropMessage(),
    },
    loading: false,
  };

  componentDidUpdate(oldProps) {
    const { filesAreAttached } = this.props;

    if (oldProps.filesAreAttached && !filesAreAttached) {
      this.setState((prevState) => ({
        message: {
          type: prevState.message.type,
          body: dragAndDropMessage(),
        },
      }));
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { attachFile } = this.props;
    const destroyFiles = () => {
      for (const file of acceptedFiles.concat(rejectedFiles)) {
        window.URL.revokeObjectURL(file.preview);
      }
    };

    // Set files state in TaskNotes
    this.setState({
      message: {
        body: `Add ${acceptedFiles[0].name} note?`,
        type: 'default',
      },
    });
    attachFile(acceptedFiles);
    destroyFiles();
  };

  onClear = () => {
    this.props.removeAttachedFile();
    this.setState({ message: 'File is removed from note' },
      function() {
        setTimeout(() => {
          this.setState({ message: 'Drop an attachment here' });
        }, 3000);
      });
  };

  render() {
    const {
      loading,
      message,
    } = this.state;

    const dropzoneContent = loading
      ? (
        <label htmlFor='uploadFiles'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label htmlFor='uploadFiles'>{message.body}</label>;

    return (
      <DropZoneWrapper
        onDrop={this.onDrop}
        name={'uploadFiles'}
        className={'upload-field'}
      >
        {dropzoneContent}
      </DropZoneWrapper>
    );
  }
}

ArtworkTaskNoteUploader.propTypes = {
  taskId: PropTypes.number,
  attachFile: PropTypes.func.isRequired,
  removeAttachedFile: PropTypes.func.isRequired,
  filesAreAttached: PropTypes.bool,
};

export default ArtworkTaskNoteUploader;
