export const root = 'homefield/api' as const;
export const users = `${root}/users` as const;

export const resources = `${users}?isHomefieldResource=true` as const;
export const accountExecutives = `${users}?isAccountExecutive=true` as const;

export default {
  resources,
  accountExecutives,
};
