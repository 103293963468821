import React, {
  useCallback,
  useEffect,
} from 'react';
import ArtworkTaskDetailsItemBackground from './ArtworkTaskDetailsItemBackground';
import ArtworkTaskDetailsItemActions from './ArtworkTaskDetailsItemActions';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import Icon from '@sharedComponents/Icons/Icon';
import { ArtworkTask } from '@models/ArtworkPortal/ArtworkTask';
import { useGetLogo } from '@api/squadlockerServices/locker-manager';

interface OwnProps {
  task: ArtworkTask;
  handleSelectItem: (task: ArtworkTask) => void;
  isSelected: boolean;
  openModal: (task: ArtworkTask) => void;
  isCreatingTasks: boolean;
  toggleCreateTask: (taskKey: number) => void;
  createTaskIsChecked: boolean;
}

type Props = OwnProps;

const ArtworkTaskDetailsItem = React.memo<Props>(({
  task,
  handleSelectItem,
  isSelected = false,
  openModal,
  isCreatingTasks = false,
  toggleCreateTask,
  createTaskIsChecked,
}) => {
  const isActive = (!isCreatingTasks && isSelected) ? ' is-selected' : '';

  const {
    logoUrl,
    logoId,
    id,
    decorationSizeWidth,
    decorationSizeHeight,
    decorationMethod,
    status,
    cutFileColor,
    isCutFile,
    assignedToName,
    shipDate,
    isCanceled,
    orders,
    artworkTaskArchivation,
    hasRequiredItems,
    isExistingTask = true,
  } = task;

  const {
    data: logo,
    refetch: fetchLogo,
  } = useGetLogo(logoId ?? 0);

  useEffect(() => {
    if (!logoId) {
      return;
    }

    fetchLogo();
  }, [
    logoId,
    fetchLogo,
  ]);

  const isArchived = !!artworkTaskArchivation;
  let isPaused = false;
  if (orders) {
    isPaused = true;

    for (const order of orders) {
      if (!order.isPaused) {
        isPaused = false;
        break;
      }
    }
  }

  let isRushed = false;
  if (orders) {
    for (const order of orders) {
      if (order.isRushed) {
        isRushed = true;
        break;
      }
    }
  }

  let isExpressProduction = false;
  if (orders) {
    for (const order of orders) {
      if (order.isExpressProduction) {
        isExpressProduction = true;
        break;
      }
    }
  }

  const onItemBackgroundClick = useCallback(() => {
    handleSelectItem(task);
  }, [
    task,
    handleSelectItem,
  ]);

  const onToggleCreateTask = useCallback(() => {
    toggleCreateTask(task.key);
  }, [
    task,
    toggleCreateTask,
  ]);

  const onAssignTask = useCallback(() => {
    openModal(task);
  }, [
    task,
    openModal,
  ]);

  return (
    <div className={`details__item ${isCreatingTasks ? '' : 'swiper-slide'}`}>
      <div className={`artwork-item ${isActive}`}>
        <div className='artwork-item__wrapper'>
          <ArtworkTaskDetailsItemBackground
            onClick={onItemBackgroundClick}
            logoUrl={logoUrl ?? ''}
            id={id!}
            decorationSizeWidth={decorationSizeWidth!}
            decorationSizeHeight={decorationSizeHeight!}
            decorationMethod={decorationMethod!}
            status={(status?.status) ?? ''}
            statusLabel={(status?.label) ?? ''}
            cutColor={cutFileColor ?? ''}
            isCutLogo={isCutFile ?? false}
            shipDate={shipDate ?? ''}
            isPaused={isPaused}
            isCanceled={!!isCanceled}
            isRushed={isRushed}
            isArchived={isArchived}
            isExpressProduction={isExpressProduction}
            hasRequiredItems={!!hasRequiredItems}
            isExistingTask={isExistingTask}
            approvedDecorationMethods={logo?.decoration_methods}
          />
          <ArtworkTaskDetailsItemActions
            resourceName={assignedToName ?? ''}
            openModal={onAssignTask}
            status={(status?.status) ?? ''}
            isCreatingTasks={isCreatingTasks}
          />
        </div>
      </div>
      {
        isCreatingTasks &&
        <div>
          {
            isExistingTask
              ? (
                <div className='warning-message pl-15'>
                  <Icon materialIcon={'warning'} />
                  <span>
                    Artwork task already exists
                  </span>
                </div>
              )
              : (
                <div className='artwork-item__checkbox'>
                  <MaterialCheckbox
                    text={'Create Task'}
                    checked={createTaskIsChecked}
                    onClick={onToggleCreateTask}
                    disabled={false}
                  />
                </div>
              )
          }
        </div>
      }
    </div>
  );
});

export default ArtworkTaskDetailsItem;
