import React from 'react';
import PropTypes from 'prop-types';
import { organizationDetailsUrl } from '@constants/clientUrls/clientUrls';
import { organizationLandingPageEnum } from '@constants/enums/organizationEnums';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import { formatDollarAmount } from '@util/numberHelpers';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import InfoField from '@sharedComponents/Display/InfoField';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import OrganizationLogo from '../OrganizationLogo';

const OrganizationsQuickView = React.memo(({
  organization,
  closeDetails,
  teamColors,
}) => (
  <TableQuickViewContainer
    noPadding={true}
    size={TableQuickViewSizeEnum.Medium}
  >
    <TableQuickViewHeader
      title={<span>{organization.name || '-'}</span>}
      onClose={closeDetails}
    />
    <div className='tableView__details--content-container'>
      <div className='flex'>
        <InfoField
          label={'Decision Maker'}
          value={`${organization.lockerManagerFirstName || ''} ${organization.lockerManagerLastName || ''}`}
          fieldClass={'size-40'}
        />
        <InfoField
          label={'Lockers'}
          value={organization.totalLockers}
          defaultValue={0}
          fieldClass={'size-30 ml-15'}
        />
        <InfoField
          value={organization.logoImage && <OrganizationLogo logoUrl={organization.logoImage} />}
          fieldClass={'size-30 ml-5'}
          defaultValue={''}
        />
      </div>
      <div className='flex'>
        <InfoField
          label={'Organization Colors'}
          fieldClass={'size-40'}
          value={
            organization.colors && organization.colors.length > 0 &&
              teamColors.filter((c) => organization.colors.includes(c.code)).map((c) => (
                <ColorDisplay
                  key={c.id}
                  colorName={c.name}
                  displayName={false}
                  size={'size--small'}
                  hexValue={c.hexValue}
                />
              ))
          }
        />
        <InfoField
          label={'Landing Page'}
          fieldClass={'size-30 ml-10'}
          value={
            organization.landingPageEnabled
              ? <span className='status-label green'>{organizationLandingPageEnum.ON}</span>
              : <span className='status-label red'>{organizationLandingPageEnum.OFF}</span>
          }
        />
      </div>
      <InfoField
        label={'Website URL'}
        value={
          organization.website && (
            <a
              className='link'
              href={organization.website}
              target='_blank'
              rel='noreferrer'
            >
              {organization.website}
            </a>
          )
        }
      />
      <InfoField
        label={'Organization Description'}
        value={organization.description}
      />
      <InfoField
        label={'Total Fundraising'}
        value={formatDollarAmount(organization.totalFundraising)}
      />
    </div>
    <div className='align__bottom'>
      <ButtonLink
        type={'primary'}
        text={'Manage Organization'}
        linkTo={organizationDetailsUrl(organization.id)}
        classes={'w-100'}
        linkClasses={'w-100 p-20'}
      />
    </div>
  </TableQuickViewContainer>
));

OrganizationsQuickView.propTypes = {
  organization: PropTypes.object.isRequired,
  closeDetails: PropTypes.func.isRequired,
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.string,
  })).isRequired,
};

export default OrganizationsQuickView;
