import React, { useCallback } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Option } from '@models/common/Option';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  classes?: string;
  size?: string;
  options: Option[];
  initialValue?: Option['value'];
  optionsClasses?: string;
  change: (name: string, value: any) => void;
  handleChange?: (value: any) => void;
  disabled?: boolean;
  disabledTooltipText?: string;
}

type Props = OwnProps & WrappedFieldProps;

const FormDropdown = React.memo(({
  disabled = false,
  disabledTooltipText,
  meta: {
    touched,
    error,
  },
  classes = '',
  size = '',
  options = [],
  initialValue,
  optionsClasses = '',
  change,
  handleChange,
  input: { name },
}: Props) => {
  const onChange = useCallback((value: any) => {
    change(name, value);
    if (handleChange) {
      handleChange(value);
    }
  }, [
    name,
    change,
    handleChange,
  ]);

  const hasError = touched && error && (error.length > 0);

  if (disabled && !!disabledTooltipText) {
    return (
      <MaterialTooltip
        tooltipText={disabledTooltipText}
        placement='bottom'
      >
        <div className={`select-field redux-form__select redux-form__dropdown ${classes} ${disabled ? 'disabled' : ''}`}>
          <Dropdown
            options={options}
            defaultValue={initialValue}
            onChange={onChange}
            classes={'mb-0'}
            optionsClasses={optionsClasses}
            size={size}
            disabled={disabled}
          />
          <div className='select-field__arrow' />
          {
            hasError &&
            <div className='redux-form__error--field'>
              {error.map((e: string, index: number) => <div key={index}>{e}</div>)}
            </div>
          }
        </div>
      </MaterialTooltip>
    );
  }

  return (
    <div className={`select-field redux-form__select redux-form__dropdown ${classes} ${disabled ? 'disabled' : ''}`}>
      <Dropdown
        options={options}
        defaultValue={initialValue}
        onChange={onChange}
        classes={'mb-0'}
        optionsClasses={optionsClasses}
        size={size}
        disabled={disabled}
      />
      <div className='select-field__arrow' />
      {
        hasError &&
        <div className='redux-form__error--field'>
          {error.map((e: string, index: number) => <div key={index}>{e}</div>)}
        </div>
      }
    </div>
  );
});

export default FormDropdown;
