import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form';
import { decorationTypeEnum } from '@constants/enums/decorationEnums';
import { imageLocationsEnum } from '@constants/enums/commonEnums';
import { layoutForm } from '@constants/reduxForms';
import { validateLayout } from '@redux/layouts/validations';
import { getDecorationLocations } from '@redux/productCatalog/actions';
import { generalApiOptions } from '@util/optionsMap';
import { parseNumber } from '@util/numberHelpers';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import Icon from '@sharedComponents/Icons/Icon';
import FormError from '@sharedComponents/Form/FormError';
import CustomItemImages from '@sharedComponents/CustomItems/CustomItemImages';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import LayoutDecoration from './LayoutDecoration';

const imageNamesMapping = {
  [imageLocationsEnum.Front]: 'imageUrlFront',
  [imageLocationsEnum.Back]: 'imageUrlBack',
  [imageLocationsEnum.Left]: 'imageUrlLeft',
  [imageLocationsEnum.Right]: 'imageUrlRight',
};

class LayoutForm extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getDecorationLocations(decorationTypeEnum.Logo));
    dispatch(getDecorationLocations(decorationTypeEnum.Personalization));
  }

  createLogoLayout = () => ({
    type: decorationTypeEnum.Logo,
    locationId: null,
    decorationHeight: null,
    decorationWidth: null,
  });

  createPersonalizationLayout = () => ({
    type: decorationTypeEnum.Personalization,
    locationId: null,
    required: true,
    personalizationType: null,
    personalizationColorType: null,
    decorationHeight: null,
  });

  changeImage = (imageName, url, file) => {
    const { change } = this.props;
    change(`${imageNamesMapping[imageName]}Attachment`, file);

    if (!file) {
      change(imageNamesMapping[imageName], '');
    }
  };

  onSportsChange = (sports) => {
    const {
      change,
      allSports,
    } = this.props;

    if (!sports) {
      return;
    }

    const layoutSports = allSports.filter((sport) => sports.includes(sport.id));

    change('sports', layoutSports);
  };

  addLogo = (fields) => {
    fields.push(this.createLogoLayout());
  };

  addPersonalization = (fields) => {
    fields.push(this.createPersonalizationLayout());
  };

  removeDecoration = (fields, index) => {
    fields.remove(index);
  };

  renderDecoration = (member, index, fields) => {
    const {
      change,
      logoDecorationLocations,
      personalizationDecorationLocations,
    } = this.props;

    const decorations = fields.getAll() || [];
    const selectedDecorationLocations = decorations.map((d) => d.locationId);

    return (
      <Field
        key={index}
        name={member}
        index={index}
        component={LayoutDecoration}
        change={change}
        decoration={decorations[index]}
        removeDecoration={this.removeDecoration.bind(null, fields)}
        logoDecorationLocations={logoDecorationLocations}
        personalizationDecorationLocations={personalizationDecorationLocations}
        selectedDecorationLocations={selectedDecorationLocations}
        parse={parseNumber}
      />
    );
  };

  renderDecorations = ({
    fields,
    meta: { error },
  }) => (
    <div>
      <div className='lockerManagerEdit__details-header mb-30'>
        <div className='lockerManager__details-header--info'>
          Logo & Personalization
          <div
            className='lockerManagerEdit__details-header--edit'
            onClick={this.addLogo.bind(null, fields)}
          >
            <Icon materialIcon={'add'} />
            <span>Add Logo</span>
          </div>
          <div
            className='lockerManagerEdit__details-header--edit'
            onClick={this.addPersonalization.bind(null, fields)}
          >
            <Icon materialIcon={'add'} />
            <span>Add Personalization</span>
          </div>
        </div>
      </div>
      <div className='lockerManagerEdit__custom-items--decorations'>
        {fields.map(this.renderDecoration)}
      </div>

      <div className='mt-20'>
        <FormError
          error={error}
          enableMultiple={true}
        />
      </div>
    </div>
  );

  render() {
    const {
      handleSubmit,
      disabled = {},
      allCategories,
      allSports,
      imageUrlFront,
      imageUrlBack,
      imageUrlLeft,
      imageUrlRight,
      error,
      sports,
    } = this.props;

    const categoriesOptions = generalApiOptions(allCategories);

    return (
      <form
        className='redux-form'
        onSubmit={handleSubmit}
        id={layoutForm}
      >
        <div>
          <div className='redux-form__section pb-5'>
            <div className='flex'>
              <div className='w-50'>
                <div className='lockerManagerEdit__custom-items--images'>
                  <CustomItemImages
                    imageFront={imageUrlFront}
                    imageBack={imageUrlRight}
                    imageLeft={imageUrlLeft}
                    imageRight={imageUrlBack}
                    changeImage={this.changeImage}
                  />
                </div>
              </div>
              <div className='w-50'>
                <div className='redux-form__row w-100'>
                  <div className='w-100'>
                    <label className='redux-form__label'>
                      Layout Name
                      <span className='required'>*</span>
                    </label>
                    <Field
                      name={'name'}
                      placeholder={'Enter name'}
                      component={Input}
                      type={'text'}
                      disabled={disabled.name}
                    />
                  </div>
                </div>
                <div className='redux-form__row w-100'>
                  <div className='w-50 mr-30'>
                    <label className='redux-form__label'>
                      Select Category
                    </label>
                    <Field
                      name={'categoryId'}
                      component={Select}
                      disabled={disabled.categoryId}
                      parse={parseNumber}
                    >
                      {categoriesOptions}
                    </Field>
                  </div>
                  <div className='w-50'>
                    <label className='redux-form__label'>
                      Select Sport
                    </label>
                    <MultiSelectDropdown
                      objects={allSports}
                      selectedObjects={sports.map((sport) => sport.id)}
                      updateCallback={this.onSportsChange}
                      valueKey={'id'}
                      textKey={'name'}
                      itemText={'sports'}
                      className={'margin-left'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='redux-form__section pb-5'>
            <FieldArray
              name={'decorations'}
              component={this.renderDecorations}
              rerenderOnEveryChange={true}
            />
          </div>

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

LayoutForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.object,
  error: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  logoDecorationLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  personalizationDecorationLocations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  allCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  })),
  allSports: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  })),
  imageUrlFront: PropTypes.string,
  imageUrlBack: PropTypes.string,
  imageUrlLeft: PropTypes.string,
  imageUrlRight: PropTypes.string,
  sports: PropTypes.array,
};

const selector = formValueSelector(layoutForm);
const ConnectedLayoutForm = connect((state) => ({
  imageUrlFront: selector(state, 'imageUrlFront'),
  sports: selector(state, 'sports'),
  imageUrlBack: selector(state, 'imageUrlBack'),
  imageUrlRight: selector(state, 'imageUrlRight'),
  imageUrlLeft: selector(state, 'imageUrlLeft'),
  allCategories: state.productCatalog.categories,
  allSports: state.productCatalog.sports,
}))(LayoutForm);

export default (reduxForm({
  form: layoutForm,
  validate: validateLayout,
  enableReinitialize: true,
  shouldError: () => true,
})(ConnectedLayoutForm));
