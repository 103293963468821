import * as actionTypes from './types';

const initialState = {
  tasksTable: {
    loading: null,
    totalCount: 0,
    pagesCount: 0,
    items: [],
    activePage: 1,
    itemsPerPage: 10,
    hasPreviousPage: null,
    hasNextPage: null,
    reviewers: [],
    partners: [],
  },
  tasksTableInfo: {
    totalCount: {
      notStarted: 0,
      inReview: 0,
      myLogosInReview: 0,
    },
  },
  reviewerTasks: {
    lockers: {},
  },
  reviewedLogos: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_LOGO_REVIEWER_TABLE_INFO:
      return {
        ...state,
        tasksTableInfo: {
          ...state.tasksTableInfo,
          totalCount: {
            ...state.tasksTableInfo.totalCount,
            ...action.payload,
          },
        },
      };
    case actionTypes.UPDATE_TASKS_TABLE:
      return {
        ...state,
        tasksTable: {
          ...state.tasksTable,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_TASK_FILTERS:
      return {
        ...state,
        tasksTable: {
          ...state.tasksTable,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_USER_TASKS:
      return {
        ...state,
        reviewerTasks: {
          ...state.reviewerTasks,
          lockers: action.payload,
        },
      };
    case actionTypes.REMOVE_REVIEWER_TASKS:
      const updatedLockers = {};

      for (const logo of action.payload) {
        const locker = updatedLockers[logo.lockerId] || state.reviewerTasks.lockers[logo.lockerId];

        updatedLockers[logo.lockerId] = {
          ...locker,
          logos: locker.logos.filter((l) => l.id !== logo.id),
        };
      }

      const newLockers = {};
      for (const [
        lockerId,
        locker,
      ] of Object.entries({
          ...state.reviewerTasks.lockers,
          ...updatedLockers,
        })) {
        if (locker.logos.length > 0) {
          newLockers[lockerId] = locker;
        }
      }

      return {
        ...state,
        reviewerTasks: {
          ...state.reviewerTasks,
          lockers: newLockers,
        },
      };
    case actionTypes.UPDATE_REVIEWED_LOGOS:
      const logo = state.reviewedLogos.find((l) => l.id === action.payload.id);
      let newReviewedLogos;

      if (logo !== undefined) {
        newReviewedLogos = state.reviewedLogos.filter((l) => l.id !== action.payload.id);
      } else {
        newReviewedLogos = [
          ...state.reviewedLogos,
          action.payload,
        ];
      }

      return {
        ...state,
        reviewedLogos: newReviewedLogos,
      };
    case actionTypes.CLEAR_REVIEWED_LOGOS:
      return {
        ...state,
        reviewedLogos: [],
      };
    case actionTypes.UPDATE_LOCKER_LOGOS:
      const locker = {
        ...state.reviewerTasks.lockers[action.payload.lockerId],
        lockerLogos: action.payload.lockerLogos,
      };

      return {
        ...state,
        reviewerTasks: {
          ...state.reviewerTasks,
          lockers: {
            ...state.reviewerTasks.lockers,
            [action.payload.lockerId]: locker,
          },
        },
      };
    default:
      return state;
  }
}
