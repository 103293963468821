import { financialApi } from '../../sqdApis';
import fundraisingPayoutsUrls from '@constants/sqdApiUrls/fundraisingPayoutsUrls';
import { makeApiCallWithErrorModal } from '@util/apiHelper';

export const cancelPayout = async (payoutId) => {
  const call = financialApi.put(fundraisingPayoutsUrls.cancelPayout(payoutId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const submitPayoutToAccounting = async (payoutId) => {
  const call = financialApi.put(fundraisingPayoutsUrls.submitPayoutToAccounting(payoutId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
