import React from 'react';
import PropTypes from 'prop-types';
import { highlightText } from '@util/componentHelper';

const AdminSuggestion = React.memo(({
  admin,
  searchInput,
}) => {
  const term = searchInput.toLowerCase();

  let text = (
    <span>
      <b>{admin.firstName} {admin.lastName}</b>
      {
        admin.firstName || admin.lastName
          ? <span>&nbsp;({admin.email})</span>
          : <span>{admin.email}</span>
      }
    </span>
  );

  if (admin.email.toLowerCase().includes(term)) {
    const adminEmailHighlighted = highlightText(admin.email, term);
    text = (
      <span>
        <b>{admin.firstName} {admin.lastName}</b>
        {
          admin.firstName || admin.lastName
            ? <span>&nbsp;({adminEmailHighlighted})</span>
            : <span>{adminEmailHighlighted}</span>
        }
      </span>
    );
  }

  return text;
});

AdminSuggestion.propTypes = {
  admin: PropTypes.object.isRequired,
  searchInput: PropTypes.string.isRequired,
};

export default AdminSuggestion;
