import { LogoItem } from '@api/fulfillment/models';
import React, {
  useEffect, useState,
} from 'react';
import PrintRoomOrderLogo from './PrintRoomOrderLogo';

interface OwnProps {
  logos: LogoItem[] | undefined | null;
  openLogoPreviewModal: (url: string) => void;
}

type Props = OwnProps;

const PrintRoomOrderLogos = React.memo<Props>((
  {
    logos,
    openLogoPreviewModal,
  }
) => {
  const [
    orderLogos,
    setOrderLogos,
  ] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const list: JSX.Element[] = [];

    if (logos) {
      for (const logo of logos) {
        list.push(
          <PrintRoomOrderLogo
            logo={logo}
            openLogoPreviewModal={openLogoPreviewModal}
          />
        );
      }

      setOrderLogos(list);
    }
  }, [
    logos,
    openLogoPreviewModal,
  ]);

  return (
    <div className='sheet__files w-100'>
      <ul className='sheet__thumbnails'>
        {orderLogos}
      </ul>
    </div>
  );
});

export default PrintRoomOrderLogos;
