import React, {
  useState, useCallback,
} from 'react';
import {
  VoucherOrderChangeLogItemDto,
  VoucherOrderDetailDto,
} from '@api/financialServices/models';
import Tabs from '@sharedComponents/Tabs/Tabs';
import VoucherSidebarNotificationsContent from './VoucherSidebarNotificationsContent';
import VoucherSidebarChangelog from './VoucherSidebarChangeLog';
import { VoucherDetailsSidebarTabEnum } from '@constants/enums/voucherEnums';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  voucherOrderChangeLog?: Array<VoucherOrderChangeLogItemDto>;
}

type Props = OwnProps;

const VoucherDetailsSidebarTabs = React.memo<Props>(({
  voucherOrder,
  voucherOrderChangeLog,
}) => {
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<VoucherDetailsSidebarTabEnum>(VoucherDetailsSidebarTabEnum.Notifications);

  const selectTab = useCallback((tab) => {
    setSelectedTab(tab);
  }, []);

  const getTabs = () => {
    const tabs = [
      {
        title: 'Notifications',
        name: VoucherDetailsSidebarTabEnum.Notifications,
        content: (
          <VoucherSidebarNotificationsContent notificationSettings={voucherOrder.notificationSettings} />
        ),
      },
      {
        title: 'Change Log',
        name: VoucherDetailsSidebarTabEnum.ChangeLog,
        content: (
          <VoucherSidebarChangelog voucherOrderChangeLog={voucherOrderChangeLog} />
        ),
      },
    ];

    return tabs;
  };

  return (
    <Tabs
      tabs={getTabs()}
      selectedTab={selectedTab}
      selectTab={selectTab}
    />
  );
});

export default VoucherDetailsSidebarTabs;
