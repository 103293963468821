import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ProductionAssemblyPrintQueueInputSpinner from './ProductionAssemblyPrintQueueInputSpinner';
import Button from '@sharedComponents/Buttons/Button';

class ProductionAssemblyQueuePrintButton extends PureComponent {
  state = {
    quantity: this.props.decoration.ids.length,
    disabled: this.props.disabled,
  };

  updateQuantity = (e, error) => {
    const quantity = Number(e.target.value);

    this.setState(() => ({
      quantity,
      disabled: error,
    }));
  };

  render() {
    const {
      decoration,
      onClick,
    } = this.props;

    const {
      quantity,
      disabled,
    } = this.state;

    return (
      <div className='flex'>
        <Button
          type={'secondary'}
          text={'Queue Print'}
          onClick={onClick.bind(null, decoration, quantity)}
          classes={'uppercase button--size-l order-assembly__add-to-print-queue-button'}
          disabled={disabled}
        />
        <ProductionAssemblyPrintQueueInputSpinner
          quantity={quantity}
          maxQuantity={decoration.ids.length}
          classes={'input-spinner--border-left-none'}
          onChange={this.updateQuantity}
        />
      </div>
    );
  }
}

ProductionAssemblyQueuePrintButton.propTypes = {
  decoration: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ProductionAssemblyQueuePrintButton.defaultProps = {
  disabled: false,
};

export default ProductionAssemblyQueuePrintButton;
