import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { adminsUrl } from '@constants/clientUrls/clientUrls';
import { editAdminForm } from '@constants/reduxForms';
import {
  fetchAdmin,
  updateAdmin,
} from '@redux/admins/actions';
import { fetchLockerManagerTable } from '@redux/lockerManager/actions';
import { fetchOrganizations } from '@redux/organizations/actions';
import { extractParameterFromPath } from '@util/stringHelpers';
import AdminEditModal from '../AdminModals/AdminEditModal';
import BackLink from '@sharedComponents/Navigation/BackLink';
import AdminHeader from './Header';
import OrganizationsTab from './OrganizationsTab';
import LockersTab from './LockersTab';

const adminDetailsTabEnum = {
  Organizations: 'Organizations',
  Lockers: 'Lockers',
};

class AdminDetails extends PureComponent {
  state = {
    selectedTab: adminDetailsTabEnum.Lockers,
    editAdminModalIsOpen: false,
  };

  componentDidMount() {
    const adminId = extractParameterFromPath(this.props, 'adminId', 'number');

    if (adminId !== null) {
      this.refreshData();
    }
  }

  componentDidUpdate(prevProps) {
    const adminId = extractParameterFromPath(this.props, 'adminId', 'number');
    const oldAdminId = extractParameterFromPath(prevProps, 'adminId', 'number');

    if (oldAdminId !== adminId && adminId) {
      this.refreshData();
    }
  }

  refreshData = () => {
    const { dispatch } = this.props;
    const adminId = extractParameterFromPath(this.props, 'adminId', 'number');

    dispatch(fetchAdmin(adminId));
    dispatch(fetchOrganizations(1, 0, '', '', '', '', '', '', adminId));
    dispatch(fetchLockerManagerTable(1, 12, '', '', '', false, '', '', '', adminId));
  };

  editAdmin = async (adminForm) => {
    const { dispatch } = this.props;
    await dispatch(updateAdmin(adminForm));
    await dispatch(fetchAdmin(adminForm.id));
    this.closeEditAdminModal();
  };

  selectTab = (selectedTab) => {
    this.setState({ selectedTab });
  };

  openEditAdminModal = () => {
    this.setState({ editAdminModalIsOpen: true });
  };

  closeEditAdminModal = () => {
    const { dispatch } = this.props;
    dispatch(reset(editAdminForm));
    this.setState({ editAdminModalIsOpen: false });
  };

  render() {
    const {
      selectedTab,
      editAdminModalIsOpen,
    } = this.state;

    const {
      admin,
      totalLockers,
      totalOrganizations,
    } = this.props;

    const adminId = extractParameterFromPath(this.props, 'adminId', 'number');

    let table = '';
    if (selectedTab === adminDetailsTabEnum.Organizations) {
      table = <OrganizationsTab adminId={adminId} />;
    } else if (selectedTab === adminDetailsTabEnum.Lockers) {
      table = <LockersTab adminId={adminId} />;
    }

    return (
      <div className='container'>
        <AdminEditModal
          isOpen={editAdminModalIsOpen}
          closeModal={this.closeEditAdminModal}
          onSubmit={this.editAdmin}
          initialValues={admin}
        />
        <div className='navigation'>
          <BackLink
            text={'Admin List'}
            url={adminsUrl}
          />
        </div>
        <AdminHeader
          admin={admin}
          openEditAdminModal={this.openEditAdminModal}
        />
        <div>
          <ul className='tabs tabs--size-l'>
            <li
              onClick={this.selectTab.bind(null, adminDetailsTabEnum.Lockers)}
              type='button'
              className={`tab-item ${selectedTab === adminDetailsTabEnum.Lockers ? 'is-active' : ''}`}
            >
              Lockers ({totalLockers})
            </li>
            <li
              onClick={this.selectTab.bind(null, adminDetailsTabEnum.Organizations)}
              type='button'
              className={`tab-item ${selectedTab === adminDetailsTabEnum.Organizations ? 'is-active' : ''}`}
            >
              Organizations ({totalOrganizations})
            </li>
          </ul>
          {table}
        </div>
      </div>
    );
  }
}

AdminDetails.propTypes = {
  admin: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    accountManager: PropTypes.string,
    role: PropTypes.string,
    createdAt: PropTypes.string,
    classification: PropTypes.string,
    notes: PropTypes.string,
    organizationType: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      adminId: PropTypes.string.isRequired,
    }).isRequired,
  }),
  totalOrganizations: PropTypes.number,
  totalLockers: PropTypes.number,
};

const mapStateToProps = ({
  admins,
  organizations,
  lockerManager,
}) => ({
  admin: admins.admin,
  totalOrganizations: organizations.organizationsList.totalCount,
  totalLockers: lockerManager.currentQueue.totalCount,
});

export default connect(mapStateToProps)(AdminDetails);
