export const getColorClass = (percent) => {
  let colorClass = '';

  if (percent > 90) {
    colorClass = 'red';

    return colorClass;
  } else if (percent > 50) {
    colorClass = 'orange';

    return colorClass;
  }
  colorClass = 'green';

  return colorClass;
};
