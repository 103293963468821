import React, { useMemo } from 'react';
import { HagPrintRoomJobPriorityItemDto } from '@api/fulfillment/models';
import { ColorDto } from '@api/productCatalog/models';
import LogoCell from './PrintRoomLogoCell';
import TextCell from '@components/WeedingAndMasking/Cells/TextCell';
import { useGetHomefieldApiColors } from '@api/productCatalog/colors';

interface OwnProps {
  item: HagPrintRoomJobPriorityItemDto;
}

type Props = OwnProps;

const PrintRoomPreviewCell = React.memo<Props>(({ item }) => {
  const { data: colors } = useGetHomefieldApiColors();
  const colorsDictionary = useMemo(() => colors?.length
    ? colors.reduce((acc, cur) => {
      if (cur.id && cur.hexValue) {
        acc[cur.id] = cur;
      }

      return acc;
    }, {} as Record<number, ColorDto>)
    : {}, [colors]);

  if (item.previewUrl) {
    return (
      <LogoCell
        logoUrl={item.previewUrl}
      />
    );
  }

  const color = colorsDictionary[item.colorId!] || undefined;
  const outlineColor = colorsDictionary[item.colorId!] || undefined;
  const label = item.personalizationText && item.personalizationNumber
    ? `${item.personalizationText} ${item.personalizationNumber}`
    : (item.personalizationText || item.personalizationNumber);

  return (
    <TextCell
      personalizationColor={color}
      personalizationText={label ?? undefined}
      personalizationOutlineColor={outlineColor}
    />
  );
});

export default PrintRoomPreviewCell;
