import { catalogApi } from '../../sqdApis';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { mapBrandErrors } from '@util/componentHelpers/brandsHelper';

export const addBrand = async (addBrandForm) => {
  const call = catalogApi.post(productCatalogUtilityUrls.brands, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(addBrandForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapBrandErrors);

  return res;
};

export const editBrand = async (editBrandForm) => {
  const call = catalogApi.put(productCatalogUtilityUrls.brand(editBrandForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(editBrandForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapBrandErrors);

  return res;
};

export const deleteBrand = async (brandId) => {
  const call = catalogApi.delete(productCatalogUtilityUrls.brand(brandId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
