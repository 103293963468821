import * as actionTypes from './types';

export const initialState = {
  rostersQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
    pageNumber: 1,
    pageSize: 10,
  },
  rosterDetails: null,
  rosterMembers: [],
  rosterMemberOrderItems: [],
};

export default function rosters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ROSTERS_TABLE:
      return {
        ...state,
        rostersQueue: action.data,
      };
    case actionTypes.UPDATE_ROSTER_DETAILS:
      return {
        ...state,
        rosterDetails: {
          ...action.data,
          collectionId: action.data.collection ? action.data.collection.id : undefined,
        },
      };
    case actionTypes.UPDATE_ROSTER_MEMBERS:
      return {
        ...state,
        rosterMembers: action.data,
      };
    case actionTypes.UPDATE_ROSTER_MEMBER_ORDER_ITEMS:
      return {
        ...state,
        rosterMemberOrderItems: action.data,
      };
    default:
      return state;
  }
}
