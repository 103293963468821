import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

const CheckCircleIcon = React.memo(() => (
  <div className='check-circle'>
    <Icon materialIcon={'check'} />
  </div>
));

export default CheckCircleIcon;
