import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { minInputLengthForSuggestions } from '@constants/values';

import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import LayoutsDropdown from '@sharedComponents/Layouts/LayoutsDropdown/LayoutsDropdown';

class LayoutsSelect extends PureComponent {
  state = {
    isInputActive: false,
    showSuggestions: false,
    searchInput: '',
    throttledCall: null,
  };

  componentWillUnmount() {
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    this.setState({ throttledCall: null });
  }

  onInputFocus = () => {
    const { searchInput } = this.state;
    this.setState({
      isInputActive: true,
      showSuggestions: searchInput.length > minInputLengthForSuggestions,
    });
  };

  onInputBlur = () => {
    this.setState({
      isInputActive: false,
      showSuggestions: false,
    });
  };

  selectLayout = async (selectedLayout) => {
    const {
      layout,
      updateLayout,
    } = this.props;

    if (layout && layout.id === selectedLayout.id) {
      return;
    }

    this.searchInput.value = '';

    await updateLayout(selectedLayout);

    this.setState({
      isInputActive: false,
      showSuggestions: false,
      searchInput: '',
    });
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState({ searchInput: newSearchInput });

    const { throttledCall } = this.state;
    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    if (newSearchInput.length < minInputLengthForSuggestions) {
      this.setState({
        showSuggestions: false,
        throttledCall: null,
      });

      return;
    }

    const newThrottle = setTimeout(this.updateSuggestions, 300);
    this.setState({
      throttledCall: newThrottle,
      showSuggestions: false,
    });
  };

  updateSuggestions = async () => {
    const { fetchSuggestions } = this.props;

    const {
      throttledCall,
      searchInput,
    } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    await fetchSuggestions(searchInput);
    this.setState({ showSuggestions: true });
  };

  render() {
    const {
      layout,
      suggestions,
    } = this.props;

    const {
      isInputActive,
      showSuggestions,
    } = this.state;

    const selectedLayouts = layout ? [layout] : [];

    return (
      <div className='align__center'>
        <div className={`chips-search has-icon w-100 ${isInputActive ? 'active' : ''}`}>
          <div>
            <Icon materialIcon={'search'} />
          </div>
          <div className='chips'>
            <div className='text-field mb-0'>
              <OutsideClickWrapper onClick={this.onInputBlur}>
                <>
                  <input
                    type='text'
                    placeholder={selectedLayouts.length === 0 ? 'Select Location Layout' : layout.name}
                    onChange={this.onInputChange}
                    onFocus={this.onInputFocus}
                    ref={(r) => this.searchInput = r}
                  />
                  {
                    showSuggestions &&
                    <LayoutsDropdown
                      layouts={selectedLayouts}
                      addLayoutToList={this.selectLayout}
                      suggestions={suggestions}
                      suggestionsAreShown={showSuggestions}
                      onInputChange={this.onInputChange}
                      onInputFocus={this.onInputFocus}
                      onInputBlur={this.onInputBlur}
                    />
                  }
                </>
              </OutsideClickWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LayoutsSelect.propTypes = {
  layout: PropTypes.object,
  updateLayout: PropTypes.func.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
};

const mapStateToProps = ({ layouts }) => ({
  suggestions: layouts.layoutsQueue.queue,
});

export default connect(mapStateToProps)(LayoutsSelect);
