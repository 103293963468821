import React from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import expressIcon from '@assets/icon_express-production.svg';
import { ProductionAssemblyOrderHeaderDto } from '@api/fulfillment/models';
import { productionGuideUrl } from '@constants/common';
import { RootState } from '@redux/index/reducers';
import { parseDateNumeric } from '@util/dateHandler';
import { mapShippingCodeToName } from '@util/mappingHelper';
import { orderStatusToString } from '@util/statusHandler';
import InfoField from '@sharedComponents/Display/InfoField';
import Label from '@sharedComponents/Display/Label';
import { shippingAddressFromShippingInfo } from '@util/stringHelpers';

interface OwnProps {
  order: ProductionAssemblyOrderHeaderDto;
}

const mapStateToProps = ({ support }: RootState) => ({
  shippingOptions: support.shippingOptions,
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const OrderHeaderDetails = React.memo<Props>(({
  order,
  shippingOptions,
}) => {
  const shippingMethod = mapShippingCodeToName(order.shippingInfo!.method!, shippingOptions);
  const shippingAddress = shippingAddressFromShippingInfo(order.shippingInfo!);

  return (
    <div className='order-assembly__header'>
      <div className='order-assembly__header--row align__left'>
        <InfoField
          label={'SOP Number'}
          fieldClass={'size-20'}
          value={order.salesOrderNumber || order.gpDocumentId}
        />
        <InfoField
          label={'Status'}
          fieldClass={'size-25'}
          value={order.status?.label}
        />
        <InfoField
          label={'Ship date'}
          fieldClass={'size-15'}
          value={(
            <div>
              {order.shipDate && parseDateNumeric(order.shipDate)}
              {
                order.isExpressProduction &&
                <i
                  className='fa'
                  style={{
                    width: '22',
                    height: '14',
                    marginLeft: '8',
                    backgroundImage: `url(${expressIcon})`,
                  }}
                />
              }
            </div>
          )}
        />
        <InfoField
          label={'Unique Items'}
          fieldClass={'size-15'}
          value={order.uniqueItemsCount}
        />
        <InfoField
          label={'Production Guide'}
          fieldClass={'size-25'}
          value={(
            <a
              href={productionGuideUrl}
              target='_blank'
              className='link'
              rel='noreferrer'
            >
              ProductionGuide.pdf
            </a>
          )}
        />
      </div>
      <div className='order-assembly__header--row align__left'>
        <InfoField
          label={'Shipping Method'}
          fieldClass={'size-20'}
          value={shippingMethod}
        />
        <InfoField
          label={(
            <div className='align__center'>
              <span>Shipping Address</span>
              {
                order.shippingInfo?.poBox &&
                <Label
                  text={'PO Box'}
                  type={'lynch'}
                  classes={'ml-10'}
                />
              }
            </div>
          )}
          fieldClass={'size-30'}
          value={shippingAddress}
        />
        {
          !!order.cartLocations?.length &&
          <InfoField
            label={'Cart Locations'}
            fieldClass={'size-20'}
            value={order.cartLocations.join(', ')}
          />
        }
      </div>
      {
        order.productionNote &&
        <div className='order-assembly__header--row'>
          <InfoField
            label={'Production Note'}
            fieldClass={'size-width'}
            value={order.productionNote}
          />
        </div>
      }
    </div>
  );
});

export default connector(OrderHeaderDetails);
