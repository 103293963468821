import { OrderInventoryStatusEnum } from '@api/fulfillment/models/orderInventoryStatusEnum';
import DateInput from '@components/shared/Inputs/DateInput';
import SearchFilter from '@components/shared/Inputs/SearchFilter';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { OrderTypeEnum } from '@constants/enums/printRoomEnums';
import {
  inventoryStatusOptions, orderTypeOptions, printStatusOptions,
} from '@constants/options/optionsValues';
import {
  parseDateNumeric,
  parseDateTimeNumeric,
} from '@util/dateHandler';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import PrintRoomNewTable from './PrintRoomNewTable';
import StatusFilter from '@components/shared/Inputs/Dropdowns/StatusFilter';
import ClaimedByFilter from './PrintRoomFilters/ClaimedByFilter';
import PrinterNumberFilter from './PrintRoomFilters/PrinterNumberFilter';
import {
  useGetHomefieldApiHagPrintroomclaimedstatus,
  useGetHomefieldApiHagPrintroomorderdetails,
} from '@api/fulfillment/hag';
import {
  HagPrintRoomJobPagedListDto,
  HagPrintRoomJobStatusEnum,
  HagPrintRoomJobTypeEnum,
  PrintRoomTabEnum,
} from '@api/fulfillment/models';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PrinterModel } from '@models/PrintRoomNew/PrinterModel';
import ExportRecentlyPrintedButton from './PrintRoomTableContent/ExportRecentlyPrintedButton';
import {
  convertToCsv,
  downloadFromLink,
  materialSwal,
} from '@util/componentHelper';
import ExportListModal from './PrintRoomTableContent/ExportListModal';
import { parsePrintStatus } from '@util/componentHelpers/printStatusHelper';
import { printRoomOrderUrl } from '@constants/clientUrls/clientUrls';

const connector = connect(null);

type CsvContentModel = {
  order?: number;
  logoCount?: number;
  shipDate?: string | null;
  completedBy?: string | null;
  completedOn?: string | null;
  printerNumber?: number | null;
};

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

const PrintRoomNew = React.memo<Props>(() => {
  const [
    orderNumber,
    setOrderNumber,
  ] = useState<number>();
  const [
    lockerId,
    setLockerId,
  ] = useState<number>();
  const [
    organizationId,
    setOrganizationId,
  ] = useState<number>();
  const [
    inventoryStatus,
    setInventoryStatus,
  ] = useState<OrderInventoryStatusEnum>();
  const [
    fromShipDate,
    setFromShipDate,
  ] = useState<Nullable<Date>>(null);
  const [
    toShipDate,
    setToShipDate,
  ] = useState<Nullable<Date>>(null);
  const [
    isRush,
    setIsRush,
  ] = useState<boolean>();
  const [
    isExpressProduction,
    setIsExpressProduction,
  ] = useState<boolean>();
  const [
    isStandardProduction,
    setIsStandardProduction,
  ] = useState<boolean>();
  const [
    selectedOrderTypes,
    setSelectedOrderTypes,
  ] = useState<OrderTypeEnum[]>([]);
  const [
    claimedByFilter,
    setClaimedByFilter,
  ] = useState<string>();
  const [
    printerNumberFilter,
    setPrintNumberFilter,
  ] = useState<number>();
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<PrintRoomTabEnum>(PrintRoomTabEnum.PrintReady);
  const [
    printerNumbers,
    setPrintNumbers,
  ] = useState<PrinterModel[]>([]);
  const [
    jobStatuses,
    setJobStatuses,
  ] = useState<HagPrintRoomJobStatusEnum[]>([HagPrintRoomJobStatusEnum.PrintReady]);
  const [
    recentlyPrintedItems,
    setPecentlyPrintedItems,
  ] = useState<HagPrintRoomJobPagedListDto[]>([]);
  const [
    isExportModalOpened,
    setIsExportModalOpened,
  ] = useState<boolean>(false);
  const [
    pageNumber,
    setPageNumber,
  ] = useState<number>(1);

  const { printRoomAvailablePrinterNumbers } = useFlags();

  const { refetch: getOrderDetails } = useGetHomefieldApiHagPrintroomorderdetails({
    orderNumber,
  });

  const handleRefetch = useCallback(async () => {
    const res = await getOrderDetails();
    if (res.data) {
      window.location.href = printRoomOrderUrl(orderNumber!);
    } else {
      await materialSwal('Order Search', 'Order ' + orderNumber + ' was not found', 'error');
    }
  }, [
    getOrderDetails,
    orderNumber,
  ]);

  useEffect(() => {
    if (orderNumber) {
      handleRefetch();
    }
  }, [
    orderNumber,
    handleRefetch,
  ]);

  useEffect(() => {
    if (typeof printRoomAvailablePrinterNumbers === 'string') {
      const printers: PrinterModel[] = printRoomAvailablePrinterNumbers
        .split(',')
        .map((x) => parseInt(x.trim()))
        .filter((x) => x > 0)
        .map((id) => ({ id }));

      setPrintNumbers(printers);
    }
  }, [printRoomAvailablePrinterNumbers]);

  const {
    data: printRoomClaimedStatus,
    refetch: fetchPrintRoomClaimedStatus,
  } = useGetHomefieldApiHagPrintroomclaimedstatus({
    statuses: jobStatuses,
    jobType: HagPrintRoomJobTypeEnum.WholeOrder,
    targetMomentInTimeStatus: // Target only users who Printed if printed is requested
      jobStatuses.includes(HagPrintRoomJobStatusEnum.Printed)
        ? HagPrintRoomJobStatusEnum.Printed
        : undefined,
  });

  useEffect(() => {
    if (jobStatuses.length) {
      fetchPrintRoomClaimedStatus();
    }
  }, [
    fetchPrintRoomClaimedStatus,
    jobStatuses,
  ]);

  const clearSearch = useCallback(() => {
    setLockerId(undefined);
    setOrganizationId(undefined);
    setOrderNumber(undefined);
  }, []);

  const onSearch = useCallback(async (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const newSearchInput: string = e.target.value;

    if (newSearchInput.length > 1) {
      const strFirst = newSearchInput.substring(0, 1);
      if (strFirst.toLowerCase() === 'o') {
        if (!isNaN(Number(newSearchInput.substring(1)))) {
          setOrderNumber(Number(newSearchInput.substring(1)));
        }
      }
      if (strFirst.toLowerCase() === 'l') {
        if (!isNaN(Number(newSearchInput.substring(1)))) {
          setLockerId(Number(newSearchInput.substring(1)));
          setPageNumber(1);
        }
      }
      if (newSearchInput.length > 3) {
        const strFirstThree = newSearchInput.substring(0, 3);
        if (strFirstThree.toLowerCase() === 'org') {
          if (!isNaN(Number(newSearchInput.substring(3)))) {
            setOrganizationId(Number(newSearchInput.substring(3)));
            setPageNumber(1);
          }
        }
        if (strFirstThree.toLowerCase() === 'syc') {
          if (!isNaN(Number(newSearchInput.substring(3)))) {
            setOrderNumber(Number(newSearchInput.substring(3)));
            setPageNumber(1);
          }
        }
      }
    }
  }, []);

  const onOrderTypeChange = useCallback((ot) => {
    if (ot.includes(OrderTypeEnum.ExpressProduction)) {
      setIsExpressProduction(true);
    } else {
      setIsExpressProduction(undefined);
    }
    if (ot.includes(OrderTypeEnum.Rush)) {
      setIsRush(true);
    } else {
      setIsRush(undefined);
    }
    if (ot.includes(OrderTypeEnum.StandardProduction)) {
      setIsStandardProduction(true);
    } else {
      setIsStandardProduction(undefined);
    }
    setPageNumber(1);
  }, []);

  const onInventoryStatusChange = useCallback((ois: OrderInventoryStatusEnum) => {
    if (ois === OrderInventoryStatusEnum.Undefined) {
      setInventoryStatus(undefined);
    } else {
      setInventoryStatus(ois);
    }
    setPageNumber(1);
  }, []);

  const handleFromShipDateFilter = useCallback((date) => {
    if (!Number.isNaN(Date.parse(date))) {
      setFromShipDate(date);
    } else {
      setFromShipDate(null);
    }
    setPageNumber(1);
  }, []);

  const handleToShipDateFilter = useCallback((date) => {
    if (!Number.isNaN(Date.parse(date))) {
      setToShipDate(date);
    } else {
      setToShipDate(null);
    }
    setPageNumber(1);
  }, []);

  const onClaimedByChange = useCallback((cb: string) => {
    if (cb !== '') {
      setClaimedByFilter(cb);
    } else {
      setClaimedByFilter(undefined);
    }
    setPageNumber(1);
  }, []);

  const onPrintStatusChange = useCallback((ps: HagPrintRoomJobStatusEnum) => {
    if (ps === HagPrintRoomJobStatusEnum.Undefined) {
      setJobStatuses([
        HagPrintRoomJobStatusEnum.Printed,
        HagPrintRoomJobStatusEnum.Assembling,
        HagPrintRoomJobStatusEnum.Assembled,
      ]);
    } else {
      setJobStatuses([ps]);
    }
    setPageNumber(1);
  }, []);

  const onPrinterNumberChange = useCallback((pn: string) => {
    if (!isNaN(Number(pn))) {
      setPrintNumberFilter(Number(pn));
      setPageNumber(1);
    }
  }, []);

  const placeholderDate = parseDateNumeric(new Date());

  const resetFilters = useCallback(() => {
    clearSearch();
    setIsRush(undefined);
    setIsExpressProduction(undefined);
    setSelectedOrderTypes([]);
    setInventoryStatus(undefined);
    setFromShipDate(null);
    setToShipDate(null);
    setClaimedByFilter(undefined);
    setPrintNumberFilter(undefined);
  }, [clearSearch]);

  const selectTab = useCallback(async (newSelectedTab: PrintRoomTabEnum) => {
    if (newSelectedTab !== selectedTab) {
      resetFilters();
      fetchPrintRoomClaimedStatus();
      switch (newSelectedTab) {
        case PrintRoomTabEnum.PrintReady:
          setJobStatuses([HagPrintRoomJobStatusEnum.PrintReady]);
          break;
        case PrintRoomTabEnum.Printing:
          setJobStatuses([HagPrintRoomJobStatusEnum.Printing]);
          break;
        case PrintRoomTabEnum.Printed:
          setJobStatuses([
            HagPrintRoomJobStatusEnum.Printed,
            HagPrintRoomJobStatusEnum.Assembling,
            HagPrintRoomJobStatusEnum.Assembled,
          ]);
          break;
        case PrintRoomTabEnum.Priority:
          setJobStatuses([
            HagPrintRoomJobStatusEnum.Printing,
            HagPrintRoomJobStatusEnum.PrintReady,
          ]);
          break;
        default:
          setJobStatuses([]);
          break;
      }
    }
    setSelectedTab(newSelectedTab);
  }, [
    fetchPrintRoomClaimedStatus,
    resetFilters,
    selectedTab,
  ]);

  const recentlyPrinted = useCallback(async (items: HagPrintRoomJobPagedListDto[]) => {
    setPecentlyPrintedItems(items);
  }, []);

  const handleExportListModalClose = useCallback(() => setIsExportModalOpened(false), []);

  const handleExportListButtonClicked = useCallback(() => setIsExportModalOpened(true), []);

  const handleExportListModalOnSubmit = useCallback(async (items: HagPrintRoomJobPagedListDto[]) => {
    const rows: CsvContentModel[] = items.map((element) => ({
      order: element.orderNumber,
      logoCount: element.decorationCount,
      shipDate: parseDateNumeric(element.shipDate),
      completedBy: element.printedBy,
      completedOn: parseDateTimeNumeric(element.printedDate),
      printerNumber: element.printerNumber,
      printStatus: parsePrintStatus(element.status),
    }));

    // Create and download CSV file
    downloadFromLink(convertToCsv(rows), `recently-printed-${Date.now()}.csv`, 'text/csv;charset=utf-8');
  }, []);

  const onPageNumberChanged = useCallback((pn: number) => setPageNumber(pn), []);

  return (
    <div className='container'>
      <div className='table-options w-100'>
        <div className='flex'>
          <SearchFilter
            initialValue={''}
            search={onSearch}
            clearSearch={clearSearch}
          />
          {
            (selectedTab === PrintRoomTabEnum.PrintReady
              || selectedTab === PrintRoomTabEnum.Printing) &&
            <StatusFilter
              options={orderTypeOptions}
              filter={onOrderTypeChange}
              selected={selectedOrderTypes}
            />
          }
          {
            selectedTab === PrintRoomTabEnum.PrintReady &&
            <div className='filter-group margin-left'>
              <Dropdown<OrderInventoryStatusEnum>
                options={inventoryStatusOptions}
                onChange={onInventoryStatusChange}
              />
            </div>
          }
          {
            (selectedTab === PrintRoomTabEnum.Printing
              || selectedTab === PrintRoomTabEnum.PrintReady) &&
            <ClaimedByFilter
              filter={onClaimedByChange}
              users={printRoomClaimedStatus}
              defaultValue={''}
              emptyText={'Claimed by (All)'}
            />
          }
          {
            selectedTab === PrintRoomTabEnum.Printed &&
            <ClaimedByFilter
              filter={onClaimedByChange}
              users={printRoomClaimedStatus}
              defaultValue={''}
              emptyText={'Completed by (All)'}
            />
          }
          {
            (selectedTab === PrintRoomTabEnum.Printing
              || selectedTab === PrintRoomTabEnum.Printed) &&
            <PrinterNumberFilter
              filter={onPrinterNumberChange}
              printerNumbers={printerNumbers}
              defaultValue={undefined}
            />
          }
          {
            selectedTab === PrintRoomTabEnum.Printed &&
            <div className='filter-group margin-left'>
              <Dropdown<HagPrintRoomJobStatusEnum>
                options={printStatusOptions}
                onChange={onPrintStatusChange}
              />
            </div>
          }
          <div className='scheduling__datepicker'>
            <DateInput
              value={fromShipDate}
              maxDate={toShipDate ?? '3000-01-01'}
              label={'From ship date'}
              onChange={handleFromShipDateFilter}
              onError={handleFromShipDateFilter}
              placeholder={placeholderDate}
            />
          </div>
          <div className='scheduling__datepicker'>
            <DateInput
              value={toShipDate}
              minDate={fromShipDate ?? '1900-01-01'}
              label={'To ship date'}
              onChange={handleToShipDateFilter}
              onError={handleToShipDateFilter}
              placeholder={placeholderDate}
            />
          </div>
        </div>
        <div className='flex'>
          {
            selectedTab === PrintRoomTabEnum.Printed &&
            <ExportRecentlyPrintedButton
              items={recentlyPrintedItems}
              onClick={handleExportListButtonClicked}
              enabled={recentlyPrintedItems.length > 0}
            />
          }
        </div>
      </div>
      <div className='sheet'>
        <PrintRoomNewTable
          inventoryStatus={inventoryStatus}
          isRush={isRush}
          isExpressProduction={isExpressProduction}
          isStandardProduction={isStandardProduction}
          shipFrom={fromShipDate?.toISOString()}
          shipTo={toShipDate?.toISOString()}
          lockerId={lockerId}
          organizationId={organizationId}
          selectTab={selectTab}
          selectedTab={selectedTab}
          claimedByFilter={claimedByFilter}
          printerNumberFilter={printerNumberFilter}
          recentlyPrinted={recentlyPrinted}
          onPageNumberChanged={onPageNumberChanged}
          pageNumber={pageNumber}
          jobStatuses={jobStatuses}
        />
        <ExportListModal
          items={recentlyPrintedItems}
          isOpen={isExportModalOpened}
          onClose={handleExportListModalClose}
          onSubmit={handleExportListModalOnSubmit}
        />
      </div>
    </div>
  );
});

export default connector(withRouter(PrintRoomNew));
