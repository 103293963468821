import React from 'react';
import CustomItemPrintFilesSection from './CustomizeItemPrintFilesTabContent/CustomItemPrintFilesSection';

interface OwnProps {
  change: () => void;
  updateThreadColor: () => void;
  updateOptionInput: () => void;
  updateDesignInput: () => void;
}

type Props = OwnProps;

const CustomizeItemPrintFilesTab = React.memo(({
  change,
  updateThreadColor,
  updateDesignInput,
  updateOptionInput,
}: Props) => (
  <div className='mt-30'>
    <CustomItemPrintFilesSection
      change={change}
      updateThreadColor={updateThreadColor}
      updateDesignInput={updateDesignInput}
      updateOptionInput={updateOptionInput}
    />
  </div>
));

export default CustomizeItemPrintFilesTab;
