import React from 'react';
import PropTypes from 'prop-types';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';

const LockerManagerQuickViewActions = React.memo(({ lockerId }) => (
  <div>
    <ButtonLink
      type={'primary'}
      text={'Open Locker Details'}
      linkTo={lockerDetailsUrl(lockerId)}
      linkClasses={'w-100'}
      classes={'w-100'}
    />
  </div>
));

LockerManagerQuickViewActions.propTypes = {
  lockerId: PropTypes.number.isRequired,
};

export default LockerManagerQuickViewActions;
