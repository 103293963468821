/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  StringApiResponse,
  ApiResponse,
  PostHomefieldApiUtilityFilesuploadBody
} from './models'
import { ordersApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiUtilityVersion = <TData = unknown>(
    
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/utility/version`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiUtilityVersionQueryKey = () => [`/homefield/api/utility/version`]

    
export const useGetHomefieldApiUtilityVersion = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiUtilityVersion, unknown>, TError = unknown, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiUtilityVersionQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiUtilityVersion<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiUtilityFilesupload = <TData = StringApiResponse>(
    postHomefieldApiUtilityFilesuploadBody: PostHomefieldApiUtilityFilesuploadBody,
 options?: SecondParameter<typeof ordersApi>) => {const formData = new FormData();
if(postHomefieldApiUtilityFilesuploadBody.file) {
 formData.append('file', postHomefieldApiUtilityFilesuploadBody.file)
 }

      return ordersApi<TData>(
      {url: `/homefield/api/utility/files.upload`, method: 'post',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiUtilityFilesupload = <TData = AsyncReturnType<typeof postHomefieldApiUtilityFilesupload,StringApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PostHomefieldApiUtilityFilesuploadBody}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PostHomefieldApiUtilityFilesuploadBody}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiUtilityFilesupload<TData>(data,requestOptions)
      }, mutationOptions)
    }
    