import React from 'react';
import iconIron from '@assets/icon_iron.svg';
import { productionAssemblyHagUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from '@components/MyApps/MenuTile';

const ProductionAssemblyHagLink = React.memo(() => (
  <MenuTile
    title={'HAG'}
    url={productionAssemblyHagUrl}
    icon={iconIron}
    isCustomIcon={true}
  />
));

export default ProductionAssemblyHagLink;
