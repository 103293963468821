import React, { useCallback } from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import BarcodeReader from 'react-barcode-reader';
import {
  getGetHomefieldApiPtlWallsIdQueryKey,
  usePostHomefieldApiPtlBinsremove,
  usePostHomefieldApiPtlItemsremove,
} from '@api/fulfillment/ptl';
import Icon from '@sharedComponents/Icons/Icon';
import { materialSwal } from '@util/componentHelper';
import {
  DEV,
  SYC,
} from '@constants/common';
import { useQueryClient } from 'react-query';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  selectedWallId: number | undefined;
}

type Props = OwnProps;

const RemoveBinOrItemFromPtlWallModal = React.memo<Props>(({
  closeModal,
  isOpen,
  selectedWallId,
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: removeBinFromWall } = usePostHomefieldApiPtlBinsremove({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetHomefieldApiPtlWallsIdQueryKey(selectedWallId ?? 0));
      },
    },
  });
  const { mutateAsync: removeItemFromPtlWallRowPosition } = usePostHomefieldApiPtlItemsremove({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetHomefieldApiPtlWallsIdQueryKey(selectedWallId ?? 0));
      },
    },
  });

  const handleRemoveScan = useCallback(async (data) => {
    if (data.toUpperCase().startsWith(SYC) || data.toUpperCase().startsWith(DEV)) {
      const variables = {
        params: {
          binID: data,
        },
      };

      const res = await removeBinFromWall(variables);

      if (res?.success) {
        materialSwal('Success', res.message!, 'success');
        closeModal();
      }

      return;
    }

    const variables = {
      params: {
        itemBarcode: data,
      },
    };

    const res = await removeItemFromPtlWallRowPosition(variables);

    if (res?.success) {
      materialSwal('Success', res.message!, 'success');
      closeModal();
    }
  }, [
    removeBinFromWall,
    closeModal,
    removeItemFromPtlWallRowPosition,
  ]);

  return (
    <Modal
      title={'Remove Bin or Item'}
      isOpen={isOpen}
      closeModal={closeModal}
      modalSize={'m'}
    >
      <div>
        <div className='flex mb-20 ml-0'>
          <BarcodeReader onScan={handleRemoveScan} />
          <h3>Scan the bin or item you would like to remove from the wall.</h3>
        </div>
        <div className='warning-message ml-0 mb-20'>
          <Icon materialIcon={'warning'} />
          <span className='warning-text'>
              Be sure you want to remove the bin or item before you scan barcode.
          </span>
        </div>
      </div>
    </Modal>
  );
});

export default RemoveBinOrItemFromPtlWallModal;
