import React, { useMemo } from 'react';
import { Assignee } from '@models/ProductionArtwork/Assignee';
import { mapToOptionsList } from '@util/mappingHelper';
import Button from '@sharedComponents/Buttons/Button';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

interface OwnProps {
  assignees: Assignee[];
  setAssignee: (assigneeId: string) => void;
  handleAssign: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectedAssignee: Assignee;
}

type Props = OwnProps;

const AssignmentMenu = React.memo<Props>(({
  assignees,
  setAssignee,
  handleAssign,
  selectedAssignee,
}) => {
  const options = useMemo(() => (
    mapToOptionsList<string, any>({
      list: assignees,
      key: 'id',
      value: 'id',
      name: (assignee) => assignee.displayName || assignee.email,
      emptyOption: {
        name: 'Select a resource',
      },
    })
  ), [assignees]);

  return (
    <div className='actions-row__action'>
      <Dropdown<string>
        options={options}
        onChange={setAssignee}
        classes={'bulk-actions__dropdown'}
      />
      <Button
        type={'secondary'}
        text={'Assign Tasks'}
        onClick={handleAssign}
        disabled={!selectedAssignee}
        classes={'ml-20'}
      />
    </div>
  );
});

export default AssignmentMenu;
