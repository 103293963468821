import React, {
  useCallback, useState,
} from 'react';
import { deleteHomefieldApiSupportQualityassurancecodesCode } from '@api/fulfillment/support';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { QualityAssuranceCodeDto } from '@api/fulfillment/models';
import { materialSwal } from '@util/componentHelper';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onDelete: () => void;
  item: QualityAssuranceCodeDto;
}

type Props = OwnProps;

const DeleteQualityAssuranceCodeModal = React.memo(({
  isOpen,
  closeModal,
  onDelete,
  item,
}: Props) => {
  const [
    typedCode,
    setTypedCode,
  ] = useState('');

  const handleDeleteCode = useCallback(
    async () => {
      const res = await deleteHomefieldApiSupportQualityassurancecodesCode(item.code ?? '');

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        closeModal();
        onDelete();
      } else {
        materialSwal('Failure', res?.message ?? 'Failure occurred while adding code', 'error');
      }
    },
    [
      item.code,
      closeModal,
      onDelete,
    ]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTypedCode(e.target.value);
  }, []);

  const handleClose = useCallback(() => {
    setTypedCode('');
    closeModal();
  }, []);

  const enabled = typedCode?.trim() && item.code?.trim()?.toUpperCase() === typedCode.trim().toUpperCase();

  return (
    <Modal
      title={`Type "${item.code}" Code Here to Delete`}
      isOpen={isOpen}
      modalHeight='m'
      modalWidth='m'
      closeModal={handleClose}
      buttons={(
        <ModalButtons
          confirmBtnText='Delete'
          cancelBtnText='Cancel'
          onClose={handleClose}
          onConfirm={handleDeleteCode}
          confirmBtnDisabled={!enabled}
          confirmBtnClass='btn-borderless--red'
        />
      )}
    >
      <div>
        <div className='text-field w-200'>
          <input
            placeholder='Code'
            required={true}
            className='redux-form__input'
            type='text'
            value={typedCode}
            onChange={handleChange}
          />
        </div>
      </div>
    </Modal>
  );
});

export default DeleteQualityAssuranceCodeModal;
