import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class DeleteCollectionsModal extends PureComponent {
  render() {
    const {
      isOpen,
      collections,
      deleteCollections,
      closeModal,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        title={'Delete Collection(s)'}
        modalSize={'m'}
        closeModal={closeModal}
        modalClass={'lockerManager__delete-modal custom-scrollbar'}
        buttons={(
          <ModalButtons
            confirmBtnText={'Yes, Delete'}
            cancelBtnText={'Cancel'}
            onConfirm={deleteCollections.bind(null, collections)}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div className='lockerManager__delete-modal--content'>
          <h3>Are you sure you want to remove the collections listed below from this locker?</h3>
          <ul style={{ listStyle: 'disc' }}>
            {
              collections.map((c) => (
                <li key={c.id}>
                  <strong>{c.name}</strong>
                </li>
              ))
            }
          </ul>
        </div>
      </Modal>
    );
  }
}

DeleteCollectionsModal.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  deleteCollections: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DeleteCollectionsModal;
