import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  stopSubmit,
} from 'redux-form';
import { connect } from 'react-redux';
import { organizationForm } from '@constants/reduxForms';
import { validateOrganization } from '@redux/organizations/validations';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import OrganizationForm from '../OrganizationForm';

class OrganizationAddModal extends Component {
  handleCloseModal = () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    dispatch(stopSubmit(organizationForm, {}));
  };

  render() {
    const {
      isOpen,
      handleSubmit,
      error,
      change,
    } = this.props;

    return (
      <Modal
        title={'Add Organization'}
        isOpen={isOpen}
        modalSize={'l'}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Add'}
            cancelBtnText={'Cancel'}
            onClose={this.handleCloseModal}
            formSubmission={true}
            formId={organizationForm}
          />
        )}
      >
        <div className='order__personalization-modal'>
          <OrganizationForm
            onSubmit={handleSubmit}
            error={error}
            change={change}
          />
        </div>
      </Modal>
    );
  }
}

OrganizationAddModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  error: PropTypes.string,
  change: PropTypes.func,
};

export default connect()(reduxForm({
  form: organizationForm,
  validate: validateOrganization,
})(OrganizationAddModal));
