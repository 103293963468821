import React from 'react';
import { OrderPricingDetails } from '@api/orders/models';
import { formatDollarAmount } from '@util/numberHelpers';
import {
  OrderManagementTempOrder,
  OrderManagementTempItem,
} from '@redux/orderManagement/models';
import Icon from '@sharedComponents/Icons/Icon';
import RefundPrice from '@sharedComponents/Display/RefundPrice';
import DescriptionField from '@sharedComponents/Display/DescriptionField';
import LinkButton from '@components/shared/Buttons/LinkButton';

interface OwnProps {
  order: Nullable<OrderManagementTempOrder>;
  items: OrderManagementTempItem[];
  orderPricingDetails: OrderPricingDetails | undefined;
  openOrderFinancialsModal: () => void;
  openApplyCouponModal: () => void;
  openCouponDetailsModal: () => void;
  couponCode: Nullable<string>;
}

type Props = OwnProps;

const OrderSummary = React.memo<Props>(({
  items,
  orderPricingDetails,
  openOrderFinancialsModal,
  openApplyCouponModal,
  openCouponDetailsModal,
  order,
  couponCode,
}) => {
  if (!orderPricingDetails) return null;

  let quantity = 0;
  if (items.length > 0) {
    quantity = items
      .map((item) => +item.quantity)
      .reduce((prev: number, next: number) => prev + next, 0);
  }

  let couponCodeMessage: string | JSX.Element = '-';
  if (couponCode) {
    couponCodeMessage = (
      <LinkButton
        text={couponCode}
        onClick={openCouponDetailsModal}
        classes={'font-weight-normal'}
      />
    );
  } else {
    couponCodeMessage = (
      <LinkButton
        text={'Apply Coupon'}
        onClick={openApplyCouponModal}
        classes={'font-weight-normal'}
      />
    );
  }

  return (
    <div className='order__actions'>
      <div className='order__total-price'>
        <DescriptionField
          label={'Total Items'}
          value={quantity}
        />
        <DescriptionField
          label={'Coupon Code'}
          value={couponCodeMessage}
        />
        <DescriptionField
          label={'Discount'}
          value={formatDollarAmount(orderPricingDetails?.tradeDiscount)}
        />
        <DescriptionField
          label={'Charge'}
          value={0}
        />
        <DescriptionField
          label={'Order'}
          value={formatDollarAmount(
            (orderPricingDetails?.subtotal ?? 0)
            + (orderPricingDetails?.taxAmount ?? 0)
            - (orderPricingDetails?.shippingTaxAmount ?? 0)
          )}
        />
        <DescriptionField
          label={'Shipping'}
          value={formatDollarAmount(orderPricingDetails?.shippingPricePaid)}
        />
        <DescriptionField
          label={(
            <span>
              Price Paid
              {
                !!orderPricingDetails &&
                <button
                  className='btn-borderless order__total-price-info-button'
                  onClick={openOrderFinancialsModal}
                >
                  <Icon
                    materialIcon={'info_outline'}
                    classes='order__total-price-info-icon'
                  />
                </button>
              }
            </span>
          )}
          value={(
            <RefundPrice
              paid={orderPricingDetails?.pricePaid || 0}
              refund={0}
            />
          )}
        />
      </div>
    </div>
  );
});

export default OrderSummary;
