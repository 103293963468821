import React from 'react';

export const Status = {
  'Initial': 0,
  'OnHold': 20,
  'AwaitingDispatchForPicking': 23,
  'InPicking': 25,
  'CheckedIn': 40,
  'Assembled': 50,
  'PreTreated': 55,
  'ProductionCompleted': 60,
  'Waterproofed': 65,
  'QualityChecked': 70,
  'Shipped': 100,
};

interface OwnProps {
  status: keyof typeof Status;
}

type Props = OwnProps;

export const StatusComp = React.memo(({ status }: Props) => {
  let color = '';
  let newStatus = '';

  if (Status[status] < Status.InPicking) {
    color = 'red';
    newStatus = 'Not Dispatched';
  } else if (Status[status] >= Status.InPicking && Status[status] <= Status.QualityChecked) {
    color = 'orange';
    newStatus = 'Dispatched';
  } else {
    color = 'green';
    newStatus = 'Ready to ship';
  }

  return (
    <div className={`status-comp status-comp__color__${color} uppercase`} >
      {newStatus}
    </div>
  );
});
