import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  connect, ConnectedProps,
} from 'react-redux';
import {
  withRouter, RouteComponentProps,
} from 'react-router-dom';
import { RootState } from '@redux/index/reducers';
import { productTypeEnum } from '@constants/enums/lockerEnums';
import { collectionDetailsBaseUrl } from '@constants/clientUrls/clientUrls';
import * as adminsActions from '@redux/admins/actions';
import * as rostersActions from '@redux/rosters/actions';
import * as collectionsActions from '@redux/collections/actions';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import Tabs from '@sharedComponents/Tabs/Tabs';
import ProductsTab from './LockerManagerTableTabs/ProductsTab';
import AdminsTab from './LockerManagerTableTabs/AdminsTab';
import CollectionsTab from './LockerManagerTableTabs/CollectionsTab';
import RostersTab from './LockerManagerTableTabs/RostersTab';

const lockerManagerTableTabEnum = {
  Products: 'Products',
  Collections: 'Collections',
  Admins: 'Admins',
  Rosters: 'Rosters',
};

interface State {
  prevPath: string;
}

interface Location {
  pathname: string;
  state: State;
}

interface OwnProps {
  lockerId: Nullable<number>;
  totalAdmins: number;
  totalCollections: number;
  totalProducts: number;
  totalRosters: number;
  location: Location;
}

const mapDispatchToProps = {
  fetchLockerAdmins: adminsActions.fetchLockerAdmins,
  fetchLockerManagerProducts: lockerManagerActions.fetchLockerManagerProducts,
  fetchCollections: collectionsActions.fetchCollections,
  fetchRostersTable: rostersActions.fetchRostersTable,
};

const mapStateToProps = ({
  admins, collections, lockerManager, rosters,
}: RootState) => ({
  totalAdmins: admins.adminsList.totalCount,
  totalCollections: collections.collectionsQueue.totalCount,
  totalProducts: lockerManager.currentProductQueue.totalCount,
  totalRosters: rosters.rostersQueue.totalCount,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector> & RouteComponentProps;

const LockerManagerDetailsTable = React.memo<Props>(({
  lockerId,
  totalAdmins,
  totalCollections,
  totalProducts,
  totalRosters,
  location,
  fetchLockerAdmins,
  fetchLockerManagerProducts,
  fetchCollections,
  fetchRostersTable,
}) => {
  const prevPathIncludesCollectionDetailsUrl = location.state?.prevPath?.includes(collectionDetailsBaseUrl);
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<string>(prevPathIncludesCollectionDetailsUrl
    ? lockerManagerTableTabEnum.Collections
    : lockerManagerTableTabEnum.Products);

  const refreshCollectionsCount = useCallback(() => {
    fetchCollections(1, 0, '', '', '', lockerId?.toString());
  }, [
    fetchCollections,
    lockerId,
  ]);

  const refreshData = useCallback(() => {
    fetchLockerAdmins(lockerId!, 1, 0);
    fetchLockerManagerProducts(1, 0, lockerId?.toString(), '', '', [], '', '', [], productTypeEnum.All);
    fetchRostersTable(1, 0, null, null, null, null, null, lockerId);
    refreshCollectionsCount();
  }, [
    fetchLockerAdmins,
    fetchLockerManagerProducts,
    fetchRostersTable,
    lockerId,
    refreshCollectionsCount,
  ]);

  const selectTab = useCallback((newSelectedTab) => {
    setSelectedTab(newSelectedTab);
  }, []);

  const getTabs = useCallback(() => {
    const tabs = [
      {
        title: `Products (${totalProducts})`,
        name: lockerManagerTableTabEnum.Products,
        content: (
          <ProductsTab
            lockerId={lockerId}
            onCollectionsChange={refreshCollectionsCount}
          />
        ),
      },
      {
        title: `Collections (${totalCollections})`,
        name: lockerManagerTableTabEnum.Collections,
        content: <CollectionsTab lockerId={lockerId!} />,
      },
      {
        title: `Admins (${totalAdmins})`,
        name: lockerManagerTableTabEnum.Admins,
        content: <AdminsTab lockerId={lockerId!} />,
      },
      {
        title: `Rosters (${totalRosters})`,
        name: lockerManagerTableTabEnum.Rosters,
        content: <RostersTab lockerId={lockerId!} />,
      },
    ];

    return tabs;
  }, [
    lockerId,
    refreshCollectionsCount,
    totalAdmins,
    totalCollections,
    totalProducts,
    totalRosters,
  ]);

  useEffect(() => {
    if (lockerId !== null) {
      refreshData();
    }
  }, [
    lockerId,
    refreshData,
  ]);

  return (
    <div className='sheet'>
      <Tabs
        tabs={getTabs()}
        selectedTab={selectedTab}
        selectTab={selectTab}
        classes={'tabs--size-l'}
      />
    </div>
  );
});

export default withRouter(connector(LockerManagerDetailsTable));
