import * as actionTypes from './types';

export const initialState = {
  emailTemplates: [],
};

export default function emailCampaigns(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.data,
      };
    default:
      return state;
  }
}
