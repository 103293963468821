import React, { useCallback } from 'react';
import {
  Field,
  WrappedFieldArrayProps,
} from 'redux-form';
import { featureFlags } from '@constants/common';
import FormError from '@sharedComponents/Form/FormError';
import Input from '@sharedComponents/Form/Input';

type Props = WrappedFieldArrayProps<string>;

const ScanItemBarcodeInputs = React.memo<Props>(({
  fields,
  meta: { error },
}) => {
  const onItemCodeChange = useCallback((index: number, e) => {
    const valueLength = e.target.value.length;
    if (valueLength === featureFlags.itemBarcodeLength && index < fields.length - 1) {
      e.target.form[index + 1].focus();
    }
  }, [fields]);

  return (
    <div>
      {fields.map((field, index) => (
        <div key={index}>
          <Field
            name={`${field}.barcode` as string}
            component={Input}
            placeholder={'Scan or Enter an Item Barcode'}
            type={'text'}
            onChange={onItemCodeChange.bind(this, index)}
            autoFocus={index === 0}
          />

          <div className='mt-20'>
            <FormError
              error={error}
              enableMultiple={true}
            />
          </div>
        </div>
      ))}
    </div>
  );
});

export default ScanItemBarcodeInputs;
