import React from 'react';
import { couponsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const CouponsLink = React.memo(() => (
  <MenuTile
    title={'Coupons'}
    url={couponsUrl}
    icon={'card_giftcard'}
  />
));

export default CouponsLink;
