import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import { getSports } from '@redux/productCatalog/actions';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import EditSportsInput from '../ProductCatalogModals/EditStyleModal/EditSportsInput';

class SportsTabContent extends PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getSports());
  }

  renderSportsInput = ({ fields }) => {
    const { sports } = this.props;

    return (
      <EditSportsInput
        allSports={sports}
        fields={fields}
      />
    );
  };

  render() {
    return (
      <PermissionHandler permissions={PermissionsEnum.ProductCatalogEdit}>
        <>
          <div className='tableView__details--header--title mb-30'>
            <span>Sports</span>
          </div>
          <div className='redux-form__column--size-xl mb-30 ml-10'>
            <div className='redux-form__row'>
              <FieldArray
                name={'sportsInputList'}
                component={this.renderSportsInput}
                rerenderOnEveryChange={true}
              />
            </div>
          </div>
        </>
      </PermissionHandler>
    );
  }
}

SportsTabContent.propTypes = {
  sports: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  sports: productCatalog.sports,
});

export default connect(mapStateToProps)(SportsTabContent);
