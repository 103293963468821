import React from 'react';
import Icon from './Icon';

interface OwnProps {
  isSelected: boolean;
  isDisabled: boolean;
}

type Props = OwnProps;

const ItemSelectionIcon = React.memo<Props>(({
  isSelected,
  isDisabled = false,
}) => (
  isSelected
    ? (
      <Icon
        materialIcon={'check_circle'}
        classes={'select-indicator is-active'}
      />
    )
    : isDisabled
      ? (
        <div className='locked-indicator'>
          <Icon materialIcon={'lock'} />
        </div>
      )
      : (
        <Icon
          materialIcon={'radio_button_unchecked'}
          classes={'select-indicator'}
        />
      )
));

export default ItemSelectionIcon;
