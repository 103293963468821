import React from 'react';

interface OwnProps {
  color: {
    hexValue: string;
    code: string;
  };
}

type Props = OwnProps;

const ColorContainerComponent = React.memo(({ color }: Props) => {
  if (!color) {
    return null;
  }

  return (
    <div className='colors-container'>
      <div
        className='colors-container__color'
        style={{ backgroundColor: color.hexValue }}
      />
      <div>{color.code}</div>
    </div>
  );
});

export default ColorContainerComponent;
