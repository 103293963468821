import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import BackLink from '@sharedComponents/Navigation/BackLink';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import {
  llSchedulingUrl,
  llSchedulingQueueDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import {
  fetchSchedulingQueue,
  fetchSchedulingQueues,
} from '@redux/llScheduling/actions';
import { mapToOptionsGroupingList } from '@util/mappingHelper';
import { useHistory } from 'react-router-dom';
import EditButton from '@sharedComponents/Display/EditButton';
import QueueDailyCapacityEditModal from './QueueDailyCapacityEditModal';
import { editQueueDailyCapacity } from '@APICalls/llScheduling/action';
import { materialSwal } from '@util/componentHelper';
import { getColorClass } from '@util/progressBarHelper';
import { dropdownSizeL } from '@constants/values';

const SchedulingQueueHeader = React.memo(({
  schedulingQueue,
  schedulingQueues,
  dispatch,
  capacityUpdatedCallback,
}) => {
  const history = useHistory();
  const [
    dailyCapacityEditModalIsOpen,
    setDailyCapacityEditModalIsOpen,
  ] = useState(false);

  useEffect(() => {
    dispatch(fetchSchedulingQueue(schedulingQueue.id));
    dispatch(fetchSchedulingQueues());
  }, []);

  const listMethodsOptions = useMemo(() => mapToOptionsGroupingList({
    list: schedulingQueues,
    key: 'id',
    value: 'id',
    name: 'queueName',
    groupsNameIndicator: 'decorationMethod',
    emptyOption: 'No decoration',
    entity: 'Queue',
  }), [schedulingQueues]);

  const handleClick = useCallback((id) => {
    dispatch(fetchSchedulingQueue(id));
    history.push(llSchedulingQueueDetailsUrl(id));
  }, []);

  const updateDailyCapacity = useCallback(async (dc) => {
    const result = await editQueueDailyCapacity(schedulingQueue.id, dc);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      setDailyCapacityEditModalIsOpen(false);
      capacityUpdatedCallback();
    }
  }, [schedulingQueue]);

  const openEditModal = useCallback(() => setDailyCapacityEditModalIsOpen(true), []);
  const closeEditModal = useCallback(() => setDailyCapacityEditModalIsOpen(false), []);

  const percent = schedulingQueue.filledCapacity / schedulingQueue.capacity * 100;
  const colorClass = schedulingQueue && getColorClass(percent);

  return (
    <>
      <QueueDailyCapacityEditModal
        isOpen={dailyCapacityEditModalIsOpen}
        closeModal={closeEditModal}
        onSubmit={updateDailyCapacity}
        initialValues={{ dailyCapacity: schedulingQueue.capacity }}
      />
      <div className='navigation'>
        <BackLink
          text={'LL Scheduling'}
          url={llSchedulingUrl}
        />
      </div>
      {
        schedulingQueue &&
        <div className='ll-scheduling-queue-details__header'>
          {
            schedulingQueues.length > 0
              ? (
                <Dropdown
                  options={listMethodsOptions}
                  onChange={handleClick}
                  defaultValue={schedulingQueue.id}
                  groupsNameIndicator={'decorationMethod'}
                  classes={''}
                  group={true}
                  size={dropdownSizeL}
                />
              )
              : <LinearProgress />
          }
          <div className='ll-scheduling-queue-details__header__body'>
            <div className='flex ll-scheduling-queue-details__header__body--item'>
              <div className='info__field'>
                <div className='info__label'>Awaiting dispatch</div>
                <div className='info__value'>{schedulingQueue.awaitingDispatch}</div>
              </div>
            </div>
            {
              schedulingQueue.decorationMethod !== null &&
                <div className='ll-scheduling__card_dtg__details'>
                  <div className='flex ll-scheduling-queue-details__header__body--item'>
                    <div className='info__field'>
                      <div className='info__label flex'>
                        <span>Daily capacity</span>
                        <span>
                          <EditButton
                            onClick={openEditModal}
                          />
                        </span>
                      </div>
                      <div className='info__value--withProgressBar'>
                        <span className='info__value'>
                          {schedulingQueue.filledCapacity}/{schedulingQueue.capacity}
                        </span>
                        <span className='progress-bar--small'>
                          <LinearProgress
                            variant='determinate'
                            value={schedulingQueue.filledCapacity / schedulingQueue.capacity * 100}
                            classes={{ root: `progress-bar--short scheduling-progress-bar--${colorClass} scheduling-progress-bar--grey` }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
            }
            <div className='flex ll-scheduling-queue-details__header__body--item'>
              <div className='info__field'>
                <div className='info__label'>In Progress</div>
                <div className='info__value'>{schedulingQueue.inProgress}</div>
              </div>
            </div>
            <div className='flex'>
              <div className='info__field'>
                <div className='info__label'>Shipped today</div>
                <div className='info__value'>{schedulingQueue.shippedToday}</div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
});

SchedulingQueueHeader.propTypes = {
  schedulingQueue: PropTypes.object,
  schedulingQueues: PropTypes.array,
  capacityUpdatedCallback: PropTypes.func,
};

const mapStateToProps = ({ llScheduling }) => ({
  schedulingQueue: llScheduling.schedulingQueue,
  schedulingQueues: llScheduling.schedulingQueues,
});

export default connect(mapStateToProps)(SchedulingQueueHeader);
