import React from 'react';
import {
  productionAssemblyDtgReviewByOrderUrl,
  productionAssemblyDtgReviewByBarcodeUrl,
} from '@constants/clientUrls/clientUrls';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import OrderSearchByDecorationMethod from '@sharedComponents/Production/Search/OrderSearchByDecorationMethod';

const ProductionAssemblyDtgSearch = React.memo(() => (
  <OrderSearchByDecorationMethod
    decorationMethod={decorationMethodEnum.DTG}
    reviewByOrderUrl={productionAssemblyDtgReviewByOrderUrl}
    reviewByBarcodeUrl={productionAssemblyDtgReviewByBarcodeUrl}
  />
));

export default ProductionAssemblyDtgSearch;
