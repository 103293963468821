import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from '@util/arrayHelper';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ScheduleOrdersModalOrderGroup from './ScheduleOrdersModalOrderGroup';

class ScheduleOrdersModal extends PureComponent {
  render() {
    const {
      isOpen,
      closeModal,
      orderItemDetailsDictionary,
      vendors,
      scheduleVendorOrders,
    } = this.props;

    const orderItemsToSchedule = [];
    for (const [
      orderNumber,
      items,
    ] of Object.entries(orderItemDetailsDictionary)) {
      for (const item of items) {
        for (const itemId of item.ids) {
          orderItemsToSchedule.push({
            ...item,
            id: itemId,
            ids: undefined,
            orderNumber,
          });
        }
      }
    }

    const itemsByVendor = groupBy(orderItemsToSchedule, 'vendorId');
    const sortedVendorCodes = Object.keys(itemsByVendor).sort();

    const ordersByVendor = [];
    for (const vendorCode of sortedVendorCodes) {
      const vendorItems = itemsByVendor[vendorCode];
      const vendorItemsByOrder = groupBy(vendorItems, 'orderNumber');

      const vendor = vendors.find((x) => x.code === vendorCode);
      ordersByVendor[vendor.name] = vendorItemsByOrder;
    }

    const itemIds = orderItemsToSchedule.map((item) => item.id);

    return (
      <Modal
        title={'Schedule Order(s)'}
        isOpen={isOpen}
        modalSize={'xl'}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Schedule Orders'}
            cancelBtnText={'Cancel'}
            onConfirm={scheduleVendorOrders.bind(null, itemIds)}
            onClose={closeModal}
          />
        )}
      >
        <h3 className='modal__description'>
          {
            Object.entries(ordersByVendor).map(([
              vendorName,
              ordersForVendor,
            ]) => (
              <div key={vendorName}>
                <div className='vendor-orders__schedule-modal--vendor'>
                  <span className='uppercase'>{vendorName}</span>&nbsp;
                  <span>({Object.keys(ordersForVendor).length} {Object.keys(ordersForVendor).length === 1 ? 'order' : 'orders'})</span>
                </div>
                <div className='ml-30'>
                  {
                    Object.entries(ordersForVendor).map(([
                      orderNumber,
                      orderItems,
                    ]) => (
                      <ScheduleOrdersModalOrderGroup
                        key={orderNumber}
                        orderNumber={orderNumber}
                        orderItems={orderItems}
                      />
                    ))
                  }
                </div>
              </div>
            ))
          }
        </h3>
      </Modal>
    );
  }
}

ScheduleOrdersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  scheduleVendorOrders: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(PropTypes.object),
  orderItemDetailsDictionary: PropTypes.object.isRequired,
};

export default ScheduleOrdersModal;
