import React from 'react';
import PropTypes from 'prop-types';
import arrowCollapse from '@assets/arrow-collapse.svg';
import arrowExpand from '@assets/arrow-expand.svg';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';

const PrintSheetItemThumbnailGroup = React.memo(({
  cutGroup,
  cutColor,
  callForModal,
  orderDeleted,
  barcode,
  colors,
}) => {
  const cutGroupItems = cutGroup.map((item, key) => {
    const cancel = (item.isCanceled || orderDeleted)
      ? <div className='sheet__thumbnails-thumbnail canceled-thumbnail' />
      : '';

    const isActive = item.barcodes.indexOf(barcode) >= 0 ? 'is-active' : '';

    let size = null;
    if (item.decorationLocation === 'FF') {
      if (item.decorationSize && item.decorationSize.toLowerCase() === 'youth') {
        size = (
          <MaterialTooltip
            tooltipText={'These logos are smaller than standard'}
            placement={'top'}
            contentClasses={'sheet__thumbnails-size-container'}
          >
            <span className='sheet__thumbnails-size'>
              <i
                className='fa'
                style={{
                  width: '25px',
                  height: '25px',
                  backgroundImage: `url("${arrowCollapse}")`,
                }}
              />
            </span>
          </MaterialTooltip>
        );
      }
      if (item.decorationSize && item.decorationSize.toLowerCase() === 'large') {
        size = (
          <MaterialTooltip
            tooltipText={'These logos are larger than standard'}
            placement={'top'}
            contentClasses={'sheet__thumbnails-size-container'}
          >
            <span className='sheet__thumbnails-size'>
              <i
                className='fa'
                style={{
                  width: '25px',
                  height: '25px',
                  backgroundImage: `url("${arrowExpand}")`,
                }}
              />
            </span>
          </MaterialTooltip>
        );
      }
    }

    return (
      <div
        className={`sheet__thumbnails-item ${isActive}`}
        key={key}
      >
        <a
          onClick={callForModal.bind(null, item.previewUrl)}
          className='sheet__thumbnails-thumbnail button'
          style={{ backgroundImage: `url("${item.previewUrl}")` }}
        >
          {cancel}
        </a>
        <span className='sheet__thumbnails-quantity'>{item.quantity}</span>
        {size}
        <span className='sheet__thumbnails-title'>
          <span className='margin margin--left-xl'>
            <Icon materialIcon={'content_cut'} />
          </span>
          {item.decorationLocation}
        </span>
      </div>
    );
  });

  const color = colors.find((c) => c.code === cutColor);
  const colorInfo = color && (
    <span
      className='sheet__thumbnails-background'
      style={{ background: color.hexValue }}
    >
      <span
        className='sheet__thumbnails-background-text'
        style={{
          color: cutColor === 'White' ? 'Black' : 'White',
          background: color.hexValue,
        }}
      >
        {cutColor}
      </span>
    </span>
  );

  return (
    <li className='sheet__thumbnails-merged'>
      {colorInfo}
      {cutGroupItems}
    </li>
  );
});

PrintSheetItemThumbnailGroup.propTypes = {
  cutGroup: PropTypes.array,
  cutColor: PropTypes.string,
  barcode: PropTypes.string,
  callForModal: PropTypes.func.isRequired,
  orderDeleted: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default PrintSheetItemThumbnailGroup;
