import * as actionTypes from './types';

export const initialState = {
  currentQueue: {
    totalPages: 1,
    pageNumber: 1,
    pageSize: 10,
    queue: [],
    totalCount: 0,
    hasNextPage: null,
    hasPreviousPage: null,
  },
  orderItems: [],
  notificationHistory: [],
};

export default function backorderItems(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ORDERS_TABLE:
      return {
        ...state,
        currentQueue: action.data,
      };
    case actionTypes.UPDATE_ORDER_ITEMS:
      return {
        ...state,
        orderItems: action.data,
      };
    case actionTypes.UPDATE_NOTIFICATION_HISTORY:
      return {
        ...state,
        notificationHistory: action.data,
      };
    default:
      return state;
  }
}
