import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { parseDateNumeric } from '@util/dateHandler';
import Icon from '@sharedComponents/Icons/Icon';

const WeedingAndMaskingOrderSwitch = React.memo(({
  currentOrder,
  nextOrderUrl,
  previousOrderUrl,
}) => {
  const {
    orderNumber,
    lockerId,
    lockerTeamName,
    organizationId,
    organizationName,
    shipDate,
  } = currentOrder;

  return (
    <div className='header__bar-order'>
      <Link
        to={previousOrderUrl}
        className='header__bar-order-arrow'
      >
        <Icon materialIcon={'keyboard_arrow_left'} />
      </Link>
      <div className='header__bar-order-number'>O{orderNumber}</div>
      <dl className='header__bar-order-details'>
        {
          organizationId
            ? (
              <div>
                <dt>Name:</dt>
                <dd>{organizationName}</dd>
                <dt>Organization:</dt>
                <dd>ORG{organizationId}</dd>
              </div>
            )
            : (
              <div>
                <dt>Team:</dt>
                <dd>{lockerTeamName}</dd>
                <dt>Locker:</dt>
                <dd>L{lockerId}</dd>
              </div>
            )
        }
        <dt>Ship by:</dt>
        {shipDate && <dd>{parseDateNumeric(shipDate)}</dd>}
      </dl>
      <Link
        to={nextOrderUrl}
        className='header__bar-order-arrow'
      >
        <Icon materialIcon={'keyboard_arrow_right'} />
      </Link>
    </div>
  );
});

WeedingAndMaskingOrderSwitch.propTypes = {
  currentOrder: PropTypes.shape({
    orderNumber: PropTypes.number.isRequired,
    lockerId: PropTypes.number,
    lockerTeamName: PropTypes.string,
    organizationId: PropTypes.number,
    organizationName: PropTypes.string,
    shipDate: PropTypes.string,
  }),
  previousOrderUrl: PropTypes.string.isRequired,
  nextOrderUrl: PropTypes.string.isRequired,
};

export default WeedingAndMaskingOrderSwitch;
