import React from 'react';
import PropTypes from 'prop-types';
import PrintSheetContentHeader from './PrintSheetContentHeader';
import PrintSheetItems from './PrintSheetItems';
import PrintSheetFileDownload from './PrintSheetFileDownload/PrintSheetFileDownload';

const PrintSheetContent = ({
  sheet,
  orderNumber,
  barcode,
  openModal,
  createPrintableSheet,
  openPreviewModal,
  openRejectModal,
  renderPrintSheet,
  colorsDictionary,
  colors,
}) => (
  <div className='sheet'>
    <PrintSheetContentHeader
      steps={sheet}
      openRejectModal={openRejectModal}
      createPrintableSheet={createPrintableSheet}
    />
    <PrintSheetFileDownload
      sheet={sheet}
    />
    <PrintSheetItems
      sheetId={sheet.id}
      openPreviewModal={openPreviewModal}
      orderNumber={orderNumber}
      barcode={barcode}
      items={sheet.items}
      openModal={openModal}
      renderPrintSheet={renderPrintSheet}
      colorsDictionary={colorsDictionary}
      colors={colors}
    />
  </div>
);

PrintSheetContent.propTypes = {
  openModal: PropTypes.func.isRequired,
  openPreviewModal: PropTypes.func.isRequired,
  openRejectModal: PropTypes.func.isRequired,
  createPrintableSheet: PropTypes.func.isRequired,
  sheet: PropTypes.object,
  orderNumber: PropTypes.number,
  renderPrintSheet: PropTypes.bool.isRequired,
  barcode: PropTypes.string,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    hexValue: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    brightness: PropTypes.string.isRequired,
    threadValue: PropTypes.string,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default PrintSheetContent;
