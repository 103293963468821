export const serviceStatusEnum = {
  Undefined: 'Undefined',
  Started: 'Started',
  Stopping: 'Printing',
  Stopped: 'Done',
} as const;

export const serviceApiLocationEnum = {
  FulfillmentApi: 'Fulfillment API',
  CatalogApi: 'Catalog API',
  GPApi: 'GP API',
  FinancialServicesApi: 'Financial Services API',
} as const;
