import React from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { Role } from '@customTypes/auth';
import { roleMapping } from '@util/roleCheck';
import { rolesEnum } from '@constants/enums/commonEnums';
import AssignedButton from './AssignedButton';
import AssignToButton from './AssignToButton';
import { artworkTaskStatusEnum } from '@constants/enums/artworkEnums';
import { RootState } from '@redux/index/reducers';

interface OwnProps {
  resourceName: string;
  openModal: () => void;
  status: string;
  isCreatingTasks: boolean;
}

const mapStateToProps = ({ oidc }: RootState) => ({
  roles: roleMapping(oidc),
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const ArtworkTaskDetailsItemActions = React.memo<Props>(({
  resourceName,
  openModal,
  status,
  roles,
  isCreatingTasks,
}) => {
  const allowedStatuses = [
    artworkTaskStatusEnum.Unassigned,
    artworkTaskStatusEnum.Assigned,
    artworkTaskStatusEnum.InQC,
    artworkTaskStatusEnum.Rework,
    artworkTaskStatusEnum.Priority,
  ];

  const disabled = status === artworkTaskStatusEnum.Completed
    || !allowedStatuses.includes(status as typeof allowedStatuses[number])
    || isCreatingTasks;

  if (resourceName) {
    return (
      <div className='artwork-item__controls'>
        <AssignedButton
          disabled={disabled}
          resourceName={resourceName}
          openModal={openModal}
        />
      </div>
    );
  }

  if (!roles.includes(rolesEnum.ProductionArtworkVendor as Role)) {
    return (
      <div className={`artwork-item__controls ${isCreatingTasks ? 'visibility-hidden' : ''}`}>
        <AssignToButton openModal={openModal} />
      </div>
    );
  }

  return <div />;
});

export default connect(mapStateToProps)(ArtworkTaskDetailsItemActions);
