import React from 'react';
import ReactDOM from 'react-dom';
import { processSilentRenew } from 'redux-oidc';
import App from './App';
import './styles/main.scss';
import reportWebVitals from './reportWebVitals';

const Index = React.memo(() => {
  document.addEventListener('DOMContentLoaded', () => {
    const toggle = document.querySelectorAll('[data-toggle=body]');
    for (const el of (toggle as any)) {
      el.addEventListener('click', (e: any) => {
        e.stopPropagation();
        el.classList.toggle('is-active');
        document.querySelector('#root')?.classList.add('is-active');
        document.querySelector('#overlay')?.classList.add('is-active');
        document.querySelector(el.getAttribute('data-toggle')).classList.toggle('is-active');
      }, false);
    }

    const toggleOff = document.querySelectorAll('[data-toggle=drawer-off]');
    for (const el of (toggleOff as any)) {
      el.addEventListener('click', () => {
        for (const elToOff of (toggle as any)) {
          elToOff.classList.remove('is-active');
          document.querySelector('#root')?.classList.remove('is-active');
          document.querySelector('#overlay')?.classList.remove('is-active');
          document.querySelector(elToOff.getAttribute('data-toggle')).classList.remove('is-active');
        }
      }, false);
    }

    const tooltip = document.querySelectorAll('[data-toggle=tooltip]');
    for (const el of (tooltip as any)) {
      el.addEventListener('hover', () => {
        el.tooltip();
      }, false);
    }
  });

  return (
    <div
      className='container-fluid'
      id='index-div'
    >
      <div id='popup-container' />
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </div>
  );
});

if (window.location.pathname === '/silent_renew') {
  processSilentRenew();
} else {
  ReactDOM.render(<Index />, document.getElementById('root'));
}

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
