import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  connect, ConnectedProps,
} from 'react-redux';
import { reset } from 'redux-form';
import {
  sendOrderConfirmationMail,
  updateShippingInfo,
  calculateUpdateShippingInfoPrice,
} from '@APICalls/orderManagement/actions';
import { getOrderChangeLogs } from '@redux/orderManagement/actions';
import { shippingAddressFromShippingInfo } from '@util/stringHelpers';
import { mapShippingCodeToName } from '@util/mappingHelper';
import { materialSwal } from '@util/componentHelper';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import { shippingAddressForm } from '@constants/reduxForms';
import InfoField from '@sharedComponents/Display/InfoField';
import Label from '@sharedComponents/Display/Label';
import SendEmailTextButton from '@sharedComponents/Buttons/SendEmailTextButton';
import Button from '@sharedComponents/Buttons/Button';
import UpdateShippingConfirmationModal from './UpdateShippingConfirmationModal';
import SendOrderConfirmationMailModal from './SendOrderConfirmationMailModal';
import { RootState } from '@redux/index/reducers';
import * as orderManagementActions from '@redux/orderManagement/actions';
import UpdateShippingInfoModal from './UpdateShippingInfoModal';
import { OrderDetailsDto } from '@api/fulfillment/models';
import {
  OrderPricingDetails,
  OrderPricingDetailsDto,
  OrderDetailsDto as CoreOrderDetails,
} from '@api/orders/models';
import { ShippingAddressFormData } from '@models/forms/OrderManagement/ShippingAddressFormData';

interface ShippingOption {
  code: string;
  display_name: string; // eslint-disable-line camelcase
}

interface OwnProps {
  order: Nullable<OrderDetailsDto>;
  shippingOptions: Array<ShippingOption>;
  fetchOrder: () => void;
  fetchCoreOrderPricingDetails: () => void;
  coreOrderPricingDetails: Nullable<OrderPricingDetailsDto>;
  orderExistsOnCore: boolean;
  coreOrder: Nullable<CoreOrderDetails>;
}

const mapStateToProps = ({ support }: RootState) => ({
  shippingOptions: support.shippingOptions,
});

const mapDispatchToProps = {
  reset,
  getOrderChangeLogs: orderManagementActions.getOrderChangeLogs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const OrderManagementSidebarShippingInfo = React.memo<Props>(({
  order,
  shippingOptions,
  fetchOrder,
  fetchCoreOrderPricingDetails,
  coreOrderPricingDetails,
  orderExistsOnCore,
  coreOrder,
}) => {
  const [
    updateShippingInfoModalIsOpen,
    setUpdateShippingInfoModalIsOpen,
  ] = useState<boolean>(false);
  const [
    sendConfirmationMailModalIsOpen,
    setSendConfirmationMailModalIsOpen,
  ] = useState<boolean>(false);
  const [
    updateShippingConfirmationModalIsOpen,
    setUpdateShippingConfirmationModalIsOpen,
  ] = useState<boolean>(false);
  const [
    shippingPriceCalculation,
    setShippingPriceCalculation,
    // Cast temporarily to any, remove once proper FF models are recieved via orval
  ] = useState<OrderPricingDetails | any>({});
  const [
    shippingInfoForm,
    setShippingInfoForm,
  ] = useState<Nullable<ShippingAddressFormData>>(null);
  const [
    closeModalCallback,
    setCloseModalCallback,
  ] = useState<boolean>(false);
  const [
    updateShippingCallback,
    setUpdateShippingCallback,
  ] = useState<boolean>(false);

  const openChangeShippingInfoModal = useCallback(() => {
    setUpdateShippingInfoModalIsOpen(true);
  }, []);

  const closeChangeShippingInfoModal = useCallback(() => {
    if (!updateShippingConfirmationModalIsOpen) {
      reset(shippingAddressForm);
    }
    setUpdateShippingInfoModalIsOpen(false);
  }, [updateShippingConfirmationModalIsOpen]);

  const openSendConfirmationMailModal = useCallback(() => {
    setSendConfirmationMailModalIsOpen(true);
  }, []);

  const closeSendConfirmationMailModal = useCallback(() => {
    setSendConfirmationMailModalIsOpen(false);
  }, []);

  const openUpdateShippingInfoConfirmationModal = useCallback(
    async (form: ShippingAddressFormData) => {
      if (form?.actionType !== accountingActionTypeEnum.NoChange) {
        const priceCalculation = await calculateUpdateShippingInfoPrice(order?.orderNumber, form, orderExistsOnCore);

        if (priceCalculation !== undefined) {
          setUpdateShippingConfirmationModalIsOpen(true);
          setShippingPriceCalculation(priceCalculation);
          setShippingInfoForm(form);
          setCloseModalCallback(true);
        }
      } else {
        reset(shippingAddressForm);
        setShippingInfoForm(form);
        setUpdateShippingCallback(true);
      }
    }, [
      order?.orderNumber,
      orderExistsOnCore,
    ]
  );

  const closeUpdateShippingInfoConfirmationModal = useCallback(() => {
    reset(shippingAddressForm);

    setUpdateShippingConfirmationModalIsOpen(false);
    setShippingInfoForm(null);
    setShippingPriceCalculation({});
  }, []);

  const sendConfirmationMail = useCallback(async () => {
    const res = await sendOrderConfirmationMail(order?.orderNumber);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      closeSendConfirmationMailModal();
    }
  }, [
    closeSendConfirmationMailModal,
    order?.orderNumber,
  ]);

  const updateShippingInformation = useCallback(async () => {
    const res = await updateShippingInfo(order?.orderNumber, shippingInfoForm, orderExistsOnCore);
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      fetchOrder();
      if (orderExistsOnCore) {
        fetchCoreOrderPricingDetails();
      }
      getOrderChangeLogs(order!.orderNumber!);
      closeUpdateShippingInfoConfirmationModal();
      closeChangeShippingInfoModal();
    }
  }, [
    closeChangeShippingInfoModal,
    closeUpdateShippingInfoConfirmationModal,
    fetchOrder,
    order,
    shippingInfoForm,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (closeModalCallback) {
      closeChangeShippingInfoModal();
      setCloseModalCallback(false);
    }
  }, [
    closeChangeShippingInfoModal,
    closeModalCallback,
  ]);

  useEffect(() => {
    if (updateShippingCallback) {
      updateShippingInformation();
      setUpdateShippingCallback(false);
    }
  }, [
    updateShippingCallback,
    updateShippingInformation,
  ]);

  const phoneNumber = () => order?.shippingInfo?.phoneNumber ?? '-';

  const shippingAddress = () => shippingAddressFromShippingInfo(order!.shippingInfo!);
  const shippingMethod = () => mapShippingCodeToName(order?.shippingInfo?.method ?? '', shippingOptions);

  const initialValues = useCallback(() => ({
    method: order?.shippingInfo?.method,
    name: order?.shippingInfo?.name,
    email: order?.shippingInfo?.email,
    addressLine1: order?.shippingInfo?.addressLine1,
    addressLine2: order?.shippingInfo?.addressLine2,
    city: order?.shippingInfo?.city,
    state: order?.shippingInfo?.state,
    country: order?.shippingInfo?.country,
    zip: order?.shippingInfo?.zip,
    poBox: order?.shippingInfo?.poBox,
    phoneNumber: order?.shippingInfo?.phoneNumber,
    actionType: accountingActionTypeEnum.NoChange,
    pricePaid: order?.shippingPricePaid,
  }), [order]);

  return (
    <div className='order__shipping-info'>
      <UpdateShippingInfoModal
        isOpen={updateShippingInfoModalIsOpen}
        closeModal={closeChangeShippingInfoModal}
        order={order!}
        initialValues={initialValues()}
        onSubmit={openUpdateShippingInfoConfirmationModal}
        coreOrderPricingDetails={coreOrderPricingDetails!}
        coreOrder={coreOrder}
      />

      <SendOrderConfirmationMailModal
        isOpen={sendConfirmationMailModalIsOpen}
        orderNumber={order!.orderNumber!}
        closeModal={closeSendConfirmationMailModal}
        email={order?.shippingInfo?.email ?? ''}
        sendConfirmationMail={sendConfirmationMail}
        coreOrderPricingDetails={coreOrderPricingDetails!}
      />

      <UpdateShippingConfirmationModal
        isOpen={updateShippingConfirmationModalIsOpen}
        closeModal={closeUpdateShippingInfoConfirmationModal}
        shippingPriceCalculation={shippingPriceCalculation}
        updateShippingInfo={updateShippingInformation}
        form={shippingInfoForm}
        order={order!}
        coreOrderPricingDetails={coreOrderPricingDetails!}
      />

      <div>
        <InfoField
          label={'Name'}
          value={order?.shippingInfo?.name}
        />
        <InfoField
          label={(
            <div className='align__center'>
              <span>E-mail</span>
              <SendEmailTextButton
                text={'Resend Confirmation'}
                onClick={openSendConfirmationMailModal}
              />
            </div>
          )}
          labelClass={'flex'}
          value={order?.shippingInfo?.email}
        />
        <InfoField
          fieldClass={'order__shipping__column--short'}
          label={'Phone'}
          value={phoneNumber()}
        />
        <InfoField
          label={(
            <div className='align__center'>
              <span>Shipping Method</span>
              {
                order?.shippingInfo?.poBox &&
                <Label
                  text={'PO Box'}
                  type={'lynch'}
                  classes={'ml-10'}
                />
              }
            </div>
          )}
          value={shippingMethod()}
        />
        <InfoField
          label={'Shipping Address'}
          value={shippingAddress()}
        />
      </div>

      <div className='order__shipping-footer align__bottom'>
        <div className='tableView__details--footer-buttons w-100'>
          <Button
            type={'secondary'}
            text={'Edit shipping information'}
            onClick={openChangeShippingInfoModal}
            classes={'w-100'}
          />
        </div>
      </div>
    </div>
  );
});

export default connector(OrderManagementSidebarShippingInfo);
