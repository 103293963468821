export const isDefined = (value: unknown): boolean => value !== undefined && value !== null && value !== '';

export const isNumber = (value: unknown): boolean => !!value && !isNaN(Number(value));

export const parseStringToBoolean = (value: string): boolean | undefined => {
  if (typeof value === 'boolean') {
    return value;
  }

  return value === 'true'
    ? true
    : (value === 'false' ? false : undefined);
};
