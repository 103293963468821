import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  connect, ConnectedProps,
} from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { createOptionsList } from '@util/optionsMap';
import { canRefundOrderShipping } from '@util/paymentHelper';
import {
  formatDollarAmount,
  parseNumber,
} from '@util/numberHelpers';
import { featureFlags } from '@constants/common';
import {
  accountingActionTypeEnum,
  shippingMethodEnum,
} from '@constants/enums/orderEnums';
import { shippingAddressForm } from '@constants/reduxForms';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import FormError from '@sharedComponents/Form/FormError';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import { ShippingAddressFormData } from '@models/forms/OrderManagement/ShippingAddressFormData';
import { validateShippingInfo } from '@redux/orderManagement/validations';
import { RootState } from 'store';
import {
  AccountingActionTypeEnum,
  OrderDetailsDto,
} from '@api/fulfillment/models';
import {
  OrderPricingDetailsDto,
  OrderDetailsDto as CoreOrderDetails,
} from '@api/orders/models';
import { useFlags } from 'launchdarkly-react-client-sdk';

const disabledPoShippingMethods = [
  shippingMethodEnum.FDX2D,
  shippingMethodEnum.FDX1D,
  shippingMethodEnum.FEDGN,
  shippingMethodEnum.DROP_EXPRESS,
  shippingMethodEnum.FDXNTF,
] as const;

interface ShippingOption {
  code: string;
  display_name: string; // eslint-disable-line camelcase
}

interface InitialValues {
  method: string | undefined;
  name: string | undefined;
  email: string | undefined;
  addressLine1: string | undefined;
  addressLine2: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  country: string | null | undefined;
  zip: string | null | undefined;
  poBox: boolean | null | undefined;
  phoneNumber: string | null | undefined;
  actionType: AccountingActionTypeEnum;
  pricePaid: number | null | undefined;
}

interface OwnProps {
  shippingOptions: Array<ShippingOption>;
  isOpen: boolean;
  order: OrderDetailsDto;
  handleSubmit: () => void;
  initialValues: Nullable<InitialValues>;
  error: Nullable<string>;
  method: string;
  coreOrderPricingDetails: Nullable<OrderPricingDetailsDto>;
  coreOrder: CoreOrderDetails;
}

const selector = formValueSelector(shippingAddressForm);
const mapStateToProps = (state: RootState) => ({
  poBox: selector(state, 'poBox'),
  actionType: selector(state, 'actionType'),
});

const connector = connect(mapStateToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps & InjectedFormProps<ShippingAddressFormData, FormProps, string[]>;

const ShippingAddressForm = React.memo<Props>(({
  shippingOptions,
  isOpen,
  order,
  handleSubmit,
  initialValues,
  error,
  change,
  poBox,
  actionType,
  method,
  coreOrderPricingDetails,
  coreOrder,
}) => {
  const [
    poBoxDisabled,
    setPoBoxDisabled,
  ] = useState<boolean>(false);

  const { hfEnableChargeForPrdOrders } = useFlags();

  const checkPOBOXDisabledMethods = useCallback(
    (shippingMethod) => disabledPoShippingMethods.includes(shippingMethod),
    []
  );

  const togglePOBox = useCallback(() => {
    change('poBox', !poBox);
  }, [poBox]);

  const onShippingMethodChange = useCallback((e) => {
    const poBoxDisabledCheck = checkPOBOXDisabledMethods(e.target.value);

    if (poBoxDisabledCheck && poBox) {
      togglePOBox();
    }

    setPoBoxDisabled(poBoxDisabledCheck);
  }, [
    checkPOBOXDisabledMethods,
    poBox,
    togglePOBox,
  ]);

  useEffect(() => {
    if (isOpen) {
      setPoBoxDisabled(checkPOBOXDisabledMethods(method));
    }
  }, [isOpen]);

  const renderOptionsList = useCallback(() => {
    if (shippingOptions && initialValues.method) {
      const methodInList = shippingOptions.filter((e) => e.code === initialValues.method);
      if (methodInList.length === 0) {
        shippingOptions.push({
          'code': initialValues.method,
          'display_name': initialValues.method,
        });
      }
    }

    return createOptionsList({
      list: shippingOptions,
      key: 'code',
      value: 'code',
      name: 'display_name',
      emptyOption: {
        name: 'Select Shipping Method',
      },
    });
  }, [
    initialValues.method,
    shippingOptions,
  ]);

  return (
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      id={shippingAddressForm}
    >
      <div>
        <div className='redux-form__section pb-5'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                  Name
                <span className='required'>*</span>
              </label>
              <Field
                name={'name'}
                placeholder={'Enter Name'}
                component={Input}
                type={'text'}
              />
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label has-icon'>
                <span>
                    E-mail
                  <span className='required'>*</span>
                </span>
              </label>
              <Field
                name={'email'}
                placeholder={'Enter e-mail'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                  Address line 1
                <span className='required'>*</span>
              </label>
              <Field
                name={'addressLine1'}
                placeholder={'Enter address line 1'}
                component={Input}
                type={'text'}
              />
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label has-icon'>
                <span>
                    Address line 2
                </span>
              </label>
              <Field
                name={'addressLine2'}
                placeholder={'Enter address line 2'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                  City
                <span className='required'>*</span>
              </label>
              <Field
                name={'city'}
                placeholder={'Enter city'}
                component={Input}
                type={'text'}
              />
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label has-icon'>
                <span>
                    State
                  <span className='required'>*</span>
                </span>
              </label>
              <Field
                name={'state'}
                placeholder={'Enter state'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <div className='redux-form__row align__center'>
                <div className='w-70'>
                  <label className='redux-form__label'>
                      ZIP Code
                    <span className='required'>*</span>
                  </label>
                  <Field
                    name={'zip'}
                    placeholder={'Enter ZIP code'}
                    component={Input}
                    type={'text'}
                  />
                </div>
                <div className='w-30 ml-10'>
                  <MaterialCheckbox
                    text={'PO Box'}
                    checked={poBox}
                    onClick={togglePOBox}
                    disabled={poBoxDisabled}
                  />
                </div>
              </div>
            </div>

            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label has-icon'>
                <span>
                    Country
                </span>
              </label>
              <Field
                name={'country'}
                placeholder={'Enter country'}
                component={Input}
                type={'text'}
              />
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                  Phone number
                <span className='required'>*</span>
              </label>
              <Field
                name={'phoneNumber'}
                placeholder={'Enter phone number'}
                component={Input}
                type={'text'}
              />
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label'>
                  Shipping method
              </label>
              <Field
                name={'method'}
                component={Select as any}
                onChange={onShippingMethodChange}
              >
                {renderOptionsList()}
              </Field>
            </div>
          </div>

          <div className='redux-form__row'>
            {
              featureFlags.shippingPriceUpdateEnabled &&
                <Field
                  name={'actionType'}
                  component={RadioGroup as any}
                  groupClassName={'order__additional-payment-selection w-100 mt-10'}
                  radioItems={[
                    {
                      value: accountingActionTypeEnum.NoChange,
                      label: 'No Change',
                    },
                    {
                      value: accountingActionTypeEnum.ShippingFullRefund,
                      disabled: !canRefundOrderShipping(
                        order!.isCompleteRefund!,
                        coreOrderPricingDetails
                          ? coreOrderPricingDetails.shippingPricePaid!
                          : order!.shippingPricePaid!
                      ),
                      label: `Full Refund (${formatDollarAmount(
                        coreOrderPricingDetails
                          ? coreOrderPricingDetails.shippingPricePaid
                          : order.shippingPricePaid
                      )})`,
                    },
                    {
                      value: accountingActionTypeEnum.ShippingRefund,
                      className: 'with-input mt-30 mr-15',
                      hasInput: true,
                      rootClassName: 'with-input__radio',
                      disabled: !canRefundOrderShipping(
                        order!.isCompleteRefund!,
                        coreOrderPricingDetails
                          ? coreOrderPricingDetails.shippingPricePaid!
                          : order!.shippingPricePaid!
                      ),
                      inputNode: (
                        <div className='with-input__label'>
                          <span>Refund: $</span>
                          <div className='text-field'>
                            <Field
                              name={'refundAmount'}
                              component={Input}
                              type={'number'}
                              parse={parseNumber}
                              placeholder={'Refund Amount'}
                              disabled={actionType !== accountingActionTypeEnum.ShippingRefund}
                            />
                          </div>
                        </div>
                      ),
                    },
                    {
                      value: accountingActionTypeEnum.ShippingCharge,
                      className: 'with-input mt-30 mr-15',
                      hasInput: true,
                      rootClassName: 'with-input__radio',
                      disabled: !hfEnableChargeForPrdOrders && coreOrder?.prdOrder,
                      inputNode: (
                        <div className='with-input__label'>
                          <span>Charge: $</span>
                          <div className='text-field'>
                            <Field
                              name={'chargeAmount'}
                              component={Input}
                              type={'number'}
                              parse={parseNumber}
                              placeholder={'Charge Amount'}
                              disabled={actionType !== accountingActionTypeEnum.ShippingCharge}
                            />
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
            }
          </div>
        </div>

        <FormError error={error!} />
      </div>
    </form>
  );
});

export default connector(reduxForm<ShippingAddressFormData, any, Props>({
  form: shippingAddressForm,
  validate: validateShippingInfo,
  shouldError: () => true,
  enableReinitialize: true,
})(ShippingAddressForm));
