import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';

const InventoryAlwaysAvailableCheckbox = React.memo(({
  change,
  disabled = false,
}) => (
  <div className='info__field'>
    <Field
      name={'alwaysAvailableVendorInventory'}
      text={'Inventory Always Available'}
      component={ReduxFormCheckbox}
      change={change}
      disabled={disabled}
    />
  </div>
));

InventoryAlwaysAvailableCheckbox.propTypes = {
  change: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default InventoryAlwaysAvailableCheckbox;
