import React from 'react';
import { shippingAddressFromShippingInfo } from '@util/stringHelpers';
import { trackingNumberUrl, trackingNumberLabel } from '@constants/common';
import InfoField from '@sharedComponents/Display/InfoField';

interface OwnProps{
  order: any;
  shipments: any[];
}

type Props = OwnProps;

const OrderManagementQuickViewShippingInfo = React.memo<Props>(({
  order,
  shipments,
}) => {
  if (!order || !order.shippingInfo) return null;

  const { shippingInfo } = order;
  const {
    phoneNumber,
    method,
  } = shippingInfo;

  const shippingAddress = shippingAddressFromShippingInfo(shippingInfo);

  return (
    <div className='order__shipping-header'>
      <InfoField
        label={'Phone Number'}
        value={phoneNumber}
      />
      <InfoField
        label={'Shipping Method'}
        value={method}
      />
      <InfoField
        label={'Shipping Address'}
        value={shippingAddress}
      />
      {
        shipments.length > 0 &&
        <InfoField
          label={'Tracking Number(s)'}
          value={(
            <>
              {
                shipments.map((shipment, index) => {
                  const url = trackingNumberUrl(shipment.trackingNumber);

                  if (!url) return shipment.trackingNumber || 'N/A';

                  return (
                    <a
                      key={index}
                      href={url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {trackingNumberLabel(shipment.trackingNumber)}
                    </a>
                  );
                })
              }
            </>
          )}
        />
      }
    </div>
  );
});

export default OrderManagementQuickViewShippingInfo;
