/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  PagedListViewModelAdminsTableRowViewModel,
  ApiInternalServerError,
  GetAdminsParams,
  AdminBaseViewModel,
  ApiBadRequest,
  GetAdminSuggestionsParams,
  AdminViewModel,
  ApiOkNumber,
  UpdateAdminRequestModel,
  PagedListViewModelLockerAdminsTableRowViewModel,
  GetAdminsByLockerIdParams
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAdmins = <TData = PagedListViewModelAdminsTableRowViewModel>(
    params?: GetAdminsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/admins`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAdminsQueryKey = (params?: GetAdminsParams,) => [`/homefield/api/admins`, ...(params ? [params]: [])]

    
export const useGetAdmins = <TQueryFnData = AsyncReturnType<typeof getAdmins, PagedListViewModelAdminsTableRowViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetAdminsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAdmins<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getAdminSuggestions = <TData = AdminBaseViewModel[]>(
    params?: GetAdminSuggestionsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/admins/suggestions`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAdminSuggestionsQueryKey = (params?: GetAdminSuggestionsParams,) => [`/homefield/api/admins/suggestions`, ...(params ? [params]: [])]

    
export const useGetAdminSuggestions = <TQueryFnData = AsyncReturnType<typeof getAdminSuggestions, AdminBaseViewModel[]>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 params?: GetAdminSuggestionsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminSuggestionsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAdminSuggestions<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getAdmin = <TData = AdminViewModel>(
    adminId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/admins/${adminId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAdminQueryKey = (adminId: number,) => [`/homefield/api/admins/${adminId}`]

    
export const useGetAdmin = <TQueryFnData = AsyncReturnType<typeof getAdmin, AdminViewModel>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 adminId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminQueryKey(adminId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAdmin<TQueryFnData>(adminId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const editAdmin = <TData = ApiOkNumber>(
    adminId: number,
    updateAdminRequestModel: UpdateAdminRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/admins/${adminId}`, method: 'put',
      data: updateAdminRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEditAdmin = <TData = AsyncReturnType<typeof editAdmin,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{adminId: number;data: UpdateAdminRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {adminId: number;data: UpdateAdminRequestModel}, TContext>((props) => {
        const {adminId,data} = props || {};

        return  editAdmin<TData>(adminId,data,requestOptions)
      }, mutationOptions)
    }
    export const getAdminsByLockerId = <TData = PagedListViewModelLockerAdminsTableRowViewModel>(
    lockerId: number,
    params?: GetAdminsByLockerIdParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/admins/lockers/${lockerId}`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetAdminsByLockerIdQueryKey = (lockerId: number,
    params?: GetAdminsByLockerIdParams,) => [`/homefield/api/admins/lockers/${lockerId}`, ...(params ? [params]: [])]

    
export const useGetAdminsByLockerId = <TQueryFnData = AsyncReturnType<typeof getAdminsByLockerId, PagedListViewModelLockerAdminsTableRowViewModel>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 lockerId: number,
    params?: GetAdminsByLockerIdParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminsByLockerIdQueryKey(lockerId,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getAdminsByLockerId<TQueryFnData>(lockerId,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

