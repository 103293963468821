import {
  OrderDetailsDto,
  OrderShipmentDto,
} from '@api/fulfillment/models';
import React from 'react';
import OrderManagementShipmentHeader from './OrderManagementShipmentHeader';

interface ShipmentData {
  shipDate: string;
  trackingNumber: string;
  shipmentNumber: number;
  orderItemIds: Array<number>;
}

interface OwnProps {
  order: OrderDetailsDto;
  isOrderPartiallyShipped: boolean;
  itemsLeftToShipCount: number;
  shipments: Nullable<Array<OrderShipmentDto>>;
}

type Props = OwnProps;

const OrderManagementShipmentHeaders = React.memo<Props>(({
  order,
  isOrderPartiallyShipped,
  itemsLeftToShipCount,
  shipments,
}) => {
  const shipmentNumbers = Array.from(new Set(shipments?.map((s) => s.shipmentNumber)));
  shipmentNumbers.sort();

  const shipmentsData = {} as Record<number, ShipmentData>;

  for (const shipment of shipments!) {
    const shipmentData = {
      shipDate: shipment.actualShipDate,
      trackingNumber: shipment.trackingNumber,
      shipmentNumber: shipment.shipmentNumber,
      orderItemIds: shipment.orderItemIds,
    };

    if (!shipmentsData[shipment.shipmentNumber!]) {
      shipmentsData[shipment.shipmentNumber!] = shipmentData as ShipmentData;
    }
  }

  return (
    <div className='order__shipment-headers'>
      {
        shipmentNumbers.map((shipmentNumber) => (
          <OrderManagementShipmentHeader
            key={shipmentNumber}
            order={order}
            isOrderPartiallyShipped={isOrderPartiallyShipped}
            itemsLeftToShipCount={itemsLeftToShipCount}
            shipment={shipmentsData[shipmentNumber!]}
          />
        ))
      }
    </div>
  );
});

export default OrderManagementShipmentHeaders;
