import { buildQueryString } from '@util/stringHelpers';

export const dtgRoot = 'homefield/api/dtg' as const;

export const dtgDecorations = `${dtgRoot}/decorations`;
export const dtgDecorationActions = (action: string = ''): string => action ? `${dtgDecorations}.${action}` : dtgDecorations;
export const dtgDecorationsTable = (pageNumber: number, pageSize: number): string => `${dtgDecorations}${buildQueryString({
  pageNumber,
  pageSize,
}, true)}`;
export const cancelDtgDecorations = dtgDecorationActions('cancel');
export const archiveDtgDecorations = dtgDecorationActions('archive');
export const regenerateImage = dtgDecorationActions('re-generate-image');

export const dtgStations = `${dtgRoot}/stations` as const;
export const dtgStation = (id: number): string => `${dtgStations}/${id}`;

export const dtgTransmissions = `${dtgRoot}/transmissions` as const;
export const dtgTransmissionsPaged = (queryParams: Record<string, any> = {}): string => `${dtgTransmissions}${buildQueryString(queryParams)}`;

export const transmissionsCount = (status: string): string => `${dtgTransmissions}.count${buildQueryString({
  status,
}, true)}`;

export const dtgPrepressJob = (queryParams: Record<string, any> = {}): string => `${dtgRoot}/dtg-prepress/jobs${buildQueryString(queryParams)}`;

export default {
  dtgRoot,
  dtgDecorations,
  dtgDecorationsTable,
  cancelDtgDecorations,
  archiveDtgDecorations,
  dtgStations,
  dtgStation,
  dtgTransmissions,
  dtgTransmissionsPaged,
  transmissionsCount,
  regenerateImage,
  dtgPrepressJob,
};
