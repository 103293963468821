import React from 'react';
import { OrderPricingDetails } from '@api/orders/models';
import {
  OrderManagementTempOrder,
  OrderManagementTempItem,
} from '@redux/orderManagement/models';
import { formatDollarAmount } from '@util/numberHelpers';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ExpandablePriceTab from '@sharedComponents/Display/ExpandablePriceTab';

interface OwnProps {
  isOpen: boolean;
  orderPricingDetails: OrderPricingDetails | undefined;
  order: Nullable<OrderManagementTempOrder>;
  items: OrderManagementTempItem[];
  closeModal: () => void;
}

type Props = OwnProps;

const OrderManagementOrderFinancialsModal = React.memo<Props>(({
  order,
  orderPricingDetails,
  items,
  isOpen,
  closeModal,
}) => {
  if (!orderPricingDetails) return null;

  const {
    subtotalCouponDiscount,
    refundAmount,
    shippingPricePaid,
    shippingPretaxPrice,
    shippingTaxAmount,
    shippingRefundAmount,
    pricePaid,
    subtotal,
    taxAmount,
  } = orderPricingDetails;

  const orderItemsCount = items?.reduce((prev, item) => prev + parseInt(item.quantity as unknown as string), 0) ?? 0;

  const subtotalTaxAmount = taxAmount! - shippingTaxAmount!;
  const totalItemsPrice = subtotal! + subtotalTaxAmount - refundAmount! - subtotalCouponDiscount!;
  const giftCardTotal = order?.giftCards?.reduce((total, card) => total + card.amountRedeemed, 0) ?? 0;

  const _getGcContent = (code: string, amount: number) => (
    <div className='justify__space-between mt-15'>
      <span>{code}</span>
      <span className='refund'>- {formatDollarAmount(amount)}</span>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalSize={'l'}
      isPadded={true}
      title={`Order ${order?.id
        ? `O${order.id}`
        : ''} Financials`}
      buttons={(
        <ModalButtons
          onClose={closeModal}
          displayConfirmBtn={false}
          cancelBtnText={'Close'}
        />
      )}
    >
      <>
        <ExpandablePriceTab
          label={'Items'}
          price={totalItemsPrice}
          content={(
            <div className='order-financial-details__content'>
              <div className='justify__space-between'>
                <span>Subtotal ({`${orderItemsCount} ${orderItemsCount === 1 ? 'item' : 'items'}`}) - inclusive percentage coupon</span>
                <span className='positive-amount'>{formatDollarAmount(subtotal)}</span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Refund</span>
                <span className='refund'>- {formatDollarAmount(refundAmount)}</span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Tax</span>
                <span className='positive-amount'>{formatDollarAmount(subtotalTaxAmount)}</span>
              </div>
            </div>
          )}
        />

        <ExpandablePriceTab
          label={'Shipping'}
          price={shippingPricePaid}
          classes={'mt-20'}
          content={(
            <div className='order-financial-details__content'>
              <div className='justify__space-between'>
                <span>Shipping</span>
                <span className='positive-amount'>{formatDollarAmount(shippingPretaxPrice)}</span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Refund</span>
                <span className='refund'>- {formatDollarAmount(shippingRefundAmount)}</span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Tax</span>
                <span className='positive-amount'>{formatDollarAmount(shippingTaxAmount)}</span>
              </div>
            </div>
          )}
        />

        {giftCardTotal > 0 &&
          <ExpandablePriceTab
            label={'Gift Card(s)'}
            price={giftCardTotal}
            classes={'mt-20'}
            content={(
              <div className='order-financial-details__content'>
                {order?.giftCards?.map((gc) => _getGcContent(gc.code, gc.amountRedeemed))}
              </div>
            )}
          />
        }

        <div className='mt-40'>
          <div className='order-financial-details__total--explanation'>(Subtotal + Shipping + Tax)</div>
          <div className='order-financial-details__total'>
            <span>Price paid</span>
            <span>{formatDollarAmount(pricePaid)}</span>
          </div>
        </div>
      </>
    </Modal>
  );
});

export default OrderManagementOrderFinancialsModal;
