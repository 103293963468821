import React, { useCallback } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { stopSubmit } from 'redux-form';
import { RosterTeamViewModel } from '@api/squadlockerServices/models';
import { rosterForm } from '@constants/reduxForms';
import { RosterFormData } from '@models/forms/RosterFormData';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import RosterForm from '../RosterForm';

interface OwnProps {
  isOpen: boolean;
  editRoster: (rosterForm: RosterFormData) => void;
  closeModal: () => void;
  initialValues: RosterTeamViewModel;
  hasActiveVouchers: boolean;
}

const mapDispatchToProps = {
  stopFormSubmit: stopSubmit,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const EditRosterModal = React.memo<Props>(({
  isOpen,
  editRoster,
  closeModal,
  initialValues,
  stopFormSubmit,
  hasActiveVouchers,
}) => {
  const handleCloseModal = useCallback(() => {
    closeModal();
    stopFormSubmit(rosterForm, {});
  }, [
    closeModal,
    stopFormSubmit,
  ]);

  if (!isOpen) return null;

  return (
    <Modal
      title={'Edit Roster'}
      modalHeight={'l'}
      modalWidth={'m'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Save'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={rosterForm}
        />
      )}
    >
      <RosterForm
        onSubmit={editRoster}
        initialValues={initialValues}
        disabled={{
          lockerId: hasActiveVouchers,
          collectionId: hasActiveVouchers,
          disabled: hasActiveVouchers,
        }}
        hasActiveVouchers={hasActiveVouchers}
      />
    </Modal>
  );
});

export default connector(EditRosterModal);
