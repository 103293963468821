import React from 'react';
import Button from '@sharedComponents/Buttons/Button';

interface OwnProps {
  resourceName: string;
  openModal: () => void;
  disabled: boolean;
}

type Props = OwnProps;

const AssignedButton = React.memo<Props>(({
  resourceName,
  openModal,
  disabled,
}) => (
  <Button
    type={'secondary'}
    text={resourceName}
    onClick={openModal}
    disabled={disabled}
    size={'s'}
    classes={'w-100 no-text-transform task__assign-btn'}
    icon={null}
  />
));

export default AssignedButton;
