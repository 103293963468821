import moment from 'moment';

interface DateRangeEnumValue {
  value: keyof DateRangeEnum;
  title: string;
  minDatePlaced?: Nullable<moment.Moment>;
  maxDatePlaced?: Nullable<moment.Moment>;
  inFuture?: boolean;
}

export interface DateRangeEnum {
  next2Days: DateRangeEnumValue;
  tomorrow: DateRangeEnumValue;
  today: DateRangeEnumValue;
  yesterday: DateRangeEnumValue;
  pastWeek: DateRangeEnumValue;
  thisWeek: DateRangeEnumValue;
  past30Days: DateRangeEnumValue;
  past60Days: DateRangeEnumValue;
  past90Days: DateRangeEnumValue;
  all: DateRangeEnumValue;
}

export const dateRangeEnum: Readonly<DateRangeEnum> = {
  next2Days: {
    value: 'next2Days',
    title: 'Next 2 days',
    minDatePlaced: moment().startOf('day'),
    maxDatePlaced: moment().add(2, 'days')
      .endOf('day'),
    inFuture: true,
  },
  tomorrow: {
    value: 'tomorrow',
    title: 'Tomorrow',
    minDatePlaced: moment().add(1, 'days')
      .startOf('day'),
    maxDatePlaced: moment().add(1, 'days')
      .endOf('day'),
    inFuture: true,
  },
  today: {
    value: 'today',
    title: 'Today',
    minDatePlaced: moment().startOf('day'),
    maxDatePlaced: null,
  },
  yesterday: {
    value: 'yesterday',
    title: 'Yesterday',
    minDatePlaced: moment().subtract(1, 'days')
      .startOf('day'),
    maxDatePlaced: moment().startOf('day'),
  },
  pastWeek: {
    value: 'pastWeek',
    title: 'Past week',
    minDatePlaced: moment().subtract(1, 'weeks')
      .startOf('day'),
    maxDatePlaced: null,
  },
  thisWeek: {
    value: 'thisWeek',
    title: 'This week',
    minDatePlaced: moment().startOf('week'),
    maxDatePlaced: moment().endOf('week'),
  },
  past30Days: {
    value: 'past30Days',
    title: 'Past 30 days',
    minDatePlaced: moment().subtract(30, 'days')
      .startOf('day'),
    maxDatePlaced: null,
  },
  past60Days: {
    value: 'past60Days',
    title: 'Past 60 days',
    minDatePlaced: moment().subtract(60, 'days')
      .startOf('day'),
    maxDatePlaced: null,
  },
  past90Days: {
    value: 'past90Days',
    title: 'Past 90 days',
    minDatePlaced: moment().subtract(90, 'days')
      .startOf('day'),
    maxDatePlaced: null,
  },
  all: {
    value: 'all',
    title: 'All',
    minDatePlaced: null,
    maxDatePlaced: null,
  },
};
