import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { RosterMemberOrderItemDto } from '@api/fulfillment/models';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  copiedText,
  emptyText,
  infoTextTimeout,
} from '@constants/values';
import { copyToClipboard } from '@util/componentHelper';
import { groupBy } from '@util/arrayHelper';
import Link from '@sharedComponents/Navigation/Link';
import Icon from '@sharedComponents/Icons/Icon';
import QuickViewOrderItemGroup from '@sharedComponents/QuickView/QuickViewOrderItemGroup';

interface OwnProps {
  rosterMemberOrderItems: RosterMemberOrderItemDto[];
}

type Props = OwnProps;

const OrderedItemsTab = React.memo<Props>(({ rosterMemberOrderItems }) => {
  const [
    copyText,
    setCopyText,
  ] = useState<string>(emptyText);

  useEffect(() => {
    if (copyText !== copiedText) return;

    setTimeout(() => {
      setCopyText(emptyText);
    }, infoTextTimeout);
  }, [copyText]);

  const onCopyToClipboard = useCallback((e) => {
    copyToClipboard(e, 'idToCopy');
    setCopyText(copiedText);
  }, []);

  const renderItemsByOrder = () => {
    const itemsByOrder = groupBy(rosterMemberOrderItems, 'orderNumber');
    const sortedOrderNumbers = Object.keys(itemsByOrder).sort();

    const itemsGroups = [];
    for (const orderNumber of sortedOrderNumbers) {
      const itemsGroup = itemsByOrder[orderNumber];

      itemsGroups.push(
        <QuickViewOrderItemGroup
          key={orderNumber}
          title={(
            <div className='align__center'>
              <Link
                url={orderDetailsUrl(orderNumber)}
                text={(
                  <span
                    id='idToCopy'
                    className='roster-team-members__quick-view__tab__order-number'
                  >
                    O{orderNumber}
                  </span>
                )}
              />
              <Icon
                materialIcon={'content_copy'}
                onClick={onCopyToClipboard}
                classes={'roster-team-members__quick-view__tab__copy-icon green'}
              />
              <span className='roster-team-members__quick-view__tab__copy-text'>
                {copyText}
              </span>
            </div>
          )}
          items={itemsGroup}
        />
      );
    }

    return itemsGroups;
  };

  if (!rosterMemberOrderItems.length) {
    return (
      <div className='roster-team-members__quick-view__tab__text'>
        <span>
          Member has not ordered
        </span>
      </div>
    );
  }

  return (
    <div className='roster-team-members__quick-view__tab__items custom-scrollbar'>
      {renderItemsByOrder()}
    </div>
  );
});

export default OrderedItemsTab;
