import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { reset } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';
import { useGetCollection } from '@api/squadlockerServices/collections';
import { useGetHomefieldApiVouchersCollectionsCollectionIdOrders as useGetCollectionVoucherOrders } from '@api/financialServices/voucher-homefield';
import { editCollection } from '@APICalls/collections/actions';
import {
  lockerDetailsUrl,
  reorderCollectionItemsUrl,
} from '@constants/clientUrls/clientUrls';
import { editAdminForm } from '@constants/reduxForms';
import { LockerProduct } from '@models/LockerManager/LockerProduct';
import { CollectionFormData } from '@models/forms/Collections/CollectionFormData';
import { RootState } from '@redux/index/reducers';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import { materialSwal } from '@util/componentHelper';
import { hasActiveVouchers } from '@util/componentHelpers/voucherOrdersHelper';
import BackLink from '@sharedComponents/Navigation/BackLink';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import Header from './Header';
import EditCollectionModal from '../CollectionModals/EditCollectionModal';
import CollectionItems from './CollectionItems';

interface RouteProps {
  collectionId: string;
}

const mapStateToProps = ({ lockerManager }: RootState) => ({
  items: lockerManager.currentProductQueue.queue as LockerProduct[],
  tableData: lockerManager.currentProductQueue,
});

const mapDispatchToProps = {
  resetForm: reset,
  fetchLockerManagerProducts: lockerManagerActions.fetchLockerManagerProducts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps<RouteProps>;

const CollectionDetails = React.memo<Props>(({
  match: { params: { collectionId } },
  resetForm,
  items,
  fetchLockerManagerProducts,
  tableData,
}) => {
  const [
    editCollectionModalIsOpen,
    setEditCollectionModalIsOpen,
  ] = useState<boolean>(false);

  const {
    data: collection,
    refetch: fetchCollection,
  } = useGetCollection(parseInt(collectionId!));

  const {
    data: voucherOrders,
    refetch: fetchCollectionVoucherOrders,
  } = useGetCollectionVoucherOrders(parseInt(collectionId!));

  useEffect(() => {
    fetchCollection();
    fetchCollectionVoucherOrders();
  }, [
    fetchCollection,
    fetchCollectionVoucherOrders,
    collectionId,
  ]);

  const openEditCollectionModal = useCallback(() => {
    setEditCollectionModalIsOpen(true);
  }, []);

  const closeEditCollectionModal = useCallback(() => {
    resetForm(editAdminForm);
    setEditCollectionModalIsOpen(false);
  }, [resetForm]);

  const updateCollection = useCallback(async (collectionForm: CollectionFormData) => {
    const res = await editCollection(collectionForm);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }

    const {
      pageNumber, pageSize, sortBy, sortOrder, filter, logos, decorationFilter, productType, colorFilter,
    } = tableData;

    fetchLockerManagerProducts(
      pageNumber,
      pageSize,
      collectionForm.lockerId.toString(),
      sortBy,
      sortOrder,
      colorFilter,
      decorationFilter,
      filter,
      logos,
      productType,
      collectionForm.id.toString()
    );

    fetchCollection();

    closeEditCollectionModal();
  }, [
    fetchCollection,
    closeEditCollectionModal,
    tableData,
    fetchLockerManagerProducts,
  ]);

  if (!collection) return null;

  const hasCollectionActiveVouchers: boolean = hasActiveVouchers(voucherOrders);

  return (
    <div className='container'>
      <EditCollectionModal
        isOpen={editCollectionModalIsOpen}
        closeModal={closeEditCollectionModal}
        editCollection={updateCollection}
        collection={collection}
        items={items}
        hasActiveVouchers={hasCollectionActiveVouchers}
      />
      <div className='navigation'>
        <BackLink
          text={'Collection List'}
          url={lockerDetailsUrl(collection.lockerId)}
        />
        <ButtonLink
          type={'secondary'}
          text={'Reorder Items'}
          linkTo={reorderCollectionItemsUrl(collection.id)}
          linkClasses={'ml-20'}
        />
      </div>
      <Header
        collection={collection}
        openEditCollectionModal={openEditCollectionModal}
      />

      <CollectionItems
        collection={collection}
      />
    </div>
  );
});

export default connector(CollectionDetails);
