import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  lockerMgrS3Logos,
  decorationMethods,
} from '@constants/common';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import { fetchUsedLogos } from '@redux/lockerManager/actions';
import { fetchColors } from '@redux/support/actions';
import ImageModal from '@sharedComponents/Modal/ImageModal';
import BackLink from '@sharedComponents/Navigation/BackLink';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import LogoBankAdminLogos from './LogoBankAdminLogos';
import LogoBankUsedLogos from './LogoBankUsedLogos';
import LogoBankLockerLogos from './LogoBankLockerLogos';

class LogoBankLogos extends PureComponent {
  state = {
    searchInput: '',
    decorationFilter: '',
    modalIsOpen: false,
    logoImage: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchColors());

    this.search();
  }

  clearSearch = () => {
    this.setState(() => ({
      searchInput: '',
    }), this.search);
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      searchInput,
    }), this.search);
  };

  openModalWithImage = (logoImage) => {
    this.setState(() => ({
      modalIsOpen: true,
      logoImage,
    }));
  };

  closeModalWithImage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(() => ({
      modalIsOpen: false,
      logoImage: null,
    }));
  };

  search = () => {
    const {
      lockerId,
      dispatch,
    } = this.props;

    const {
      searchInput,
      decorationFilter,
    } = this.state;

    dispatch(fetchUsedLogos(lockerId, searchInput, decorationFilter));
  };

  decorationChange = (value) => {
    this.setState(() => ({
      decorationFilter: value,
    }), this.search);
  };

  render() {
    const {
      modalIsOpen,
      logoImage,
    } = this.state;

    const {
      logoBankLogos,
      logoBankLockerLogos,
      managerLogos,
      lockerId,
      refreshLogos,
    } = this.props;

    return (
      <div>
        <ImageModal
          isOpen={modalIsOpen}
          closeModal={this.closeModalWithImage}
          imageUrl={`${lockerMgrS3Logos}/${logoImage}`}
          isCompact={true}
        />
        <div className='navigation'>
          <BackLink
            text={`Locker L${lockerId}`}
            url={lockerDetailsUrl(lockerId)}
          />
          <div className='flex align-end'>
            <Dropdown
              options={decorationMethods}
              classes={'margin-left'}
              onChange={this.decorationChange}
            />
            <SearchFilter
              search={this.filterKey}
              classes={'ml-15'}
              clearSearch={this.clearSearch}
            />
          </div>
        </div>
        <LogoBankUsedLogos
          logos={logoBankLogos}
          lockerId={lockerId}
          openModalWithImage={this.openModalWithImage}
          refreshLogos={this.search}
        />
        <LogoBankLockerLogos
          logos={logoBankLockerLogos}
          lockerId={lockerId}
          openModalWithImage={this.openModalWithImage}
          refreshLogos={refreshLogos}
          refreshUsedLogos={this.search}
        />
        <LogoBankAdminLogos
          logos={managerLogos}
          lockerId={lockerId}
          openModalWithImage={this.openModalWithImage}
          refreshLogos={refreshLogos}
        />
      </div>
    );
  }
}

LogoBankLogos.propTypes = {
  managerLogos: PropTypes.array.isRequired,
  logoBankLogos: PropTypes.array.isRequired,
  logoBankLockerLogos: PropTypes.array.isRequired,
  lockerId: PropTypes.number.isRequired,
  refreshLogos: PropTypes.func.isRequired,
};

export default connect()(LogoBankLogos);
