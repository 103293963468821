import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reorderCollectionItems } from '@APICalls/collections/actions';
import { collectionDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  fetchCollection,
  fetchCollectionItems,
} from '@redux/collections/actions';
import { extractParameterFromPath } from '@util/stringHelpers';
import BackLink from '@sharedComponents/Navigation/BackLink';
import CollectionItems from './CollectionItems';

class ReorderCollectionItems extends Component {
  state = {
    addGroupModalIsOpen: false,
    editGroupModalIsOpen: false,
    editFeaturedLockersModalIsOpen: false,
    selectedGroup: null,
    selectedGroupLockers: [],
  };

  componentDidMount() {
    const { dispatch } = this.props;

    const collectionId = this.extractCollectionIdFromPath(this.props);

    if (collectionId !== null) {
      dispatch(fetchCollection(collectionId));
      this.fetchCollectionItems();
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;

    const collectionId = this.extractCollectionIdFromPath(this.props);
    const oldCollectionId = this.extractCollectionIdFromPath(prevProps);

    if (oldCollectionId !== collectionId && collectionId) {
      dispatch(fetchCollection(collectionId));
      this.fetchCollectionItems();
    }
  }

  extractCollectionIdFromPath = (props) => {
    const collectionId = extractParameterFromPath(props, 'collectionId', 'number');

    return collectionId;
  };

  fetchCollectionItems = () => {
    const { dispatch } = this.props;

    const collectionId = this.extractCollectionIdFromPath(this.props);

    dispatch(fetchCollectionItems(collectionId));
  };

  reorderItems = async (reorderedItems) => {
    const { items } = this.props;

    const updatedItems = [];
    for (const item of reorderedItems) {
      const oldItem = items.find((i) => i.id === item.id);
      if (oldItem.orderPlace !== item.orderPlace) {
        updatedItems.push(item);
      }
    }

    if (updatedItems.length > 0) {
      const collectionId = this.extractCollectionIdFromPath(this.props);
      await reorderCollectionItems(collectionId, updatedItems);
      this.fetchCollectionItems();
    }
  };

  render() {
    const {
      collection,
      items,
    } = this.props;

    const collectionId = this.extractCollectionIdFromPath(this.props);

    return (
      <div className='container'>
        <div className='navigation'>
          <BackLink
            text={collection.name}
            url={collectionDetailsUrl(collectionId)}
          />
        </div>

        <div className='mb-50'>
          <CollectionItems
            collectionItems={items}
            reorderItems={this.reorderItems}
          />
        </div>
      </div>
    );
  }
}

ReorderCollectionItems.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
    }).isRequired,
  }),
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    bundled: PropTypes.bool,
    lockerId: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ collections }) => ({
  collection: collections.collection,
  items: collections.collectionItems,
});

export default connect(mapStateToProps)(ReorderCollectionItems);
