import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
} from 'redux-form';
import PropTypes from 'prop-types';
import {
  getAdminRolesOptions,
  getClassificationOptions,
  getAdminOrganizationTypeOptions,
} from '@constants/options/options';
import { editAdminForm } from '@constants/reduxForms';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { generalOptions } from '@util/optionsMap';
import Input from '@sharedComponents/Form/Input';
import Textarea from '@sharedComponents/Form/Textarea';
import Select from '@sharedComponents/Form/Select';
import SearchInput from '@sharedComponents/Form/SearchInput/SearchInput';
import FormError from '@sharedComponents/Form/FormError';

const roleOptions = generalOptions(getAdminRolesOptions(true, 'Choose Role'));
const classificationOptions = generalOptions(getClassificationOptions());
const organizationTypeOptions = generalOptions(getAdminOrganizationTypeOptions());

class AdminForm extends Component {
  onKeyPress = (e) => {
    if (e.key === keyNameEnum.Enter) {
      e.preventDefault();
    }
  };

  changeAccountExecutive = (email) => {
    const { change } = this.props;
    change('accountManager', email);
  };

  render() {
    const {
      onSubmit,
      error,
      disabled = {},
      accountExecutives,
      currentAccountExecutive,
    } = this.props;

    return (
      <form
        className='redux-form'
        onSubmit={onSubmit}
        onKeyPress={this.onKeyPress}
        id={editAdminForm}
      >
        <div>
          <div className='ml-15 mr-10'>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-l'>
                <label className='redux-form__label'>
                  Email
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'email'}
                  placeholder={'Enter E-mail'}
                  component={Input}
                  type={'text'}
                  disabled={disabled.email}
                />
              </div>
            </div>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-m'>
                <label className='redux-form__label'>
                  First Name
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'firstName'}
                  placeholder={'Enter First Name'}
                  component={Input}
                  type={'text'}
                  disabled={disabled.firstName}
                />
              </div>

              <div className='redux-form__column--size-m ml-20'>
                <label className='redux-form__label'>
                  Last Name
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'lastName'}
                  placeholder={'Enter Last Name'}
                  component={Input}
                  type={'text'}
                  disabled={disabled.lastName}
                />
              </div>
            </div>

            <div className='redux-form__row'>
              <div className='redux-form__column--size-m'>
                <label className='redux-form__label'>
                  Phone Number
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'phoneNumber'}
                  placeholder={'Enter Phone Number'}
                  component={Input}
                  type={'text'}
                  disabled={disabled.phoneNumber}
                />
              </div>

              <div className='redux-form__column--size-m ml-20'>
                <label className='redux-form__label'>
                  Role
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'role'}
                  component={Select}
                  disabled={disabled.role}
                >
                  {roleOptions}
                </Field>
              </div>
            </div>

            <div className='redux-form__row mb-10'>
              <div className='redux-form__column--size-l'>
                <label className='redux-form__label'>
                  Account Executive
                </label>
                <Field
                  name={'accountManager'}
                  component={SearchInput}
                  disabled={disabled.accountManager}
                  allItems={accountExecutives}
                  currentItem={currentAccountExecutive}
                  changeItem={this.changeAccountExecutive}
                  searchKey={'email'}
                  displayKey={'email'}
                  placeholderText={'Search account executive'}
                  isFixed={false}
                />
              </div>
              <div className='redux-form__column--size-l ml-20'>
                <label className='redux-form__label'>
                  Classification
                </label>
                <Field
                  name={'classification'}
                  component={Select}
                  disabled={disabled.classification}
                >
                  {classificationOptions}
                </Field>
              </div>
            </div>

            <div className='redux-form__row mb-10'>
              <div className='redux-form__column--size-m'>
                <label className='redux-form__label'>
                  Organization Type
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'organizationType'}
                  component={Select}
                  disabled={disabled.organizationType}
                >
                  {organizationTypeOptions}
                </Field>
              </div>
            </div>

            <div className='redux-form__row'>
              <div className='redux-form__column--size-l'>
                <label className='redux-form__label'>
                  Notes
                </label>
                <Field
                  name={'notes'}
                  placeholder={'Enter Notes'}
                  component={Textarea}
                  type={'text'}
                  disabled={disabled.notes}
                />
              </div>
            </div>
          </div>

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

AdminForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
  disabled: PropTypes.object,
  accountExecutives: PropTypes.array,
  change: PropTypes.func,
  currentAccountExecutive: PropTypes.string,
};

const selector = formValueSelector(editAdminForm);
const ConnectedAdminForm = connect((state) => ({
  currentAccountExecutive: selector(state, 'accountManager'),
}))(AdminForm);

export default ConnectedAdminForm;
