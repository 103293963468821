import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import Chip from '@sharedComponents/Inputs/Chips/Chip';
import EditSportsInputSportItem from './EditSportsInputSportItem';

class EditSportsInput extends PureComponent {
  state = {
    isSportInputActive: false,
    showSportList: false,
    searchInput: '',
    filteredSports: this.props.allSports,
  };

  componentDidUpdate(prevProps, prevState) {
    const { allSports } = this.props;
    const { searchInput } = this.state;

    if (searchInput !== prevState.searchInput) {
      if (searchInput.length > 0) {
        this.setState(() => ({
          filteredSports: allSports.filter((s) => s.name.toLowerCase().includes(searchInput.toLowerCase())),
        }));
      } else {
        this.setState(() => ({ filteredSports: allSports }));
      }
    }
  }

  onInputFocus = () => {
    this.setState(() => ({
      isSportInputActive: true,
      showSportList: true,
    }));
  };

  onInputBlur = () => {
    this.setState(() => ({
      isSportInputActive: false,
      showSportList: false,
    }));
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState(() => ({
      searchInput: newSearchInput,
      showSportList: true,
    }));
  };

  addSportToList = (sport) => {
    const { fields } = this.props;

    if (fields.getAll().find((s) => s.id === sport.id)) return;

    this.searchInput.value = '';

    fields.push(sport);

    this.setState(() => ({
      isSportInputActive: false,
      showSportList: false,
      searchInput: '',
    }));
  };

  removeSportFromList = (index) => {
    const { fields } = this.props;

    fields.remove(index);
  };

  renderSportItem = (sport, index) => (
    <Chip
      key={sport.id}
      text={sport.name}
      onClick={this.removeSportFromList.bind(null, index)}
      hasIcon={true}
    />
  );

  render() {
    const { fields } = this.props;

    const {
      isSportInputActive,
      showSportList,
      filteredSports,
    } = this.state;

    const initializedFields = fields.getAll() || [];

    return (
      <div className='w-100'>
        <label className='redux-form__label'>
          Sports Applied for
        </label>
        <div className={`chips-search has-icon ${isSportInputActive ? 'active' : ''}`}>
          <div>
            <Icon materialIcon={'search'} />
          </div>
          <div className='chips'>
            {initializedFields.map((sport, index) => this.renderSportItem(sport, index))}
            <div className='text-field mb-0'>
              <OutsideClickWrapper onClick={this.onInputBlur}>
                <>
                  <input
                    type='text'
                    placeholder={initializedFields.length === 0 ? 'Enter sport' : undefined}
                    onChange={this.onInputChange}
                    onFocus={this.onInputFocus}
                    ref={(r) => this.searchInput = r}
                  />
                  {
                    showSportList &&
                    <div className='suggestions suggestions__dropdown-fixed suggestions--size-l custom-scrollbar'>
                      {filteredSports.map((sport) => (
                        <EditSportsInputSportItem
                          key={sport.id}
                          sport={sport}
                          sports={initializedFields}
                          addSport={this.addSportToList}
                        />
                      ))}
                    </div>
                  }
                </>
              </OutsideClickWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditSportsInput.propTypes = {
  allSports: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.object.isRequired,
};

export default EditSportsInput;
