import React, { useCallback } from 'react';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import { Task } from '@models/Production/ProductionAssembly/Tasks';
import { itemNotCheckedInStatuses } from '@util/componentHelpers/productionAssemblyHelper';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import { DecorationMethodEnum } from '@api/productCatalog/models';

interface OwnProps<T extends Task = Task> {
  decorationMethod: DecorationMethodEnum;
  task: T;
  taskStatus: keyof typeof orderItemStatusEnum;
  itemStatus: keyof typeof orderItemStatusEnum;
  isAssemblyDisabled?: boolean;
  orderItemDecorationDecoratedOnClick: (task: Task) => void;
}

type Props<T extends Task = Task> = OwnProps<T>;

const DecoratedButton = <T extends Task = Task>({
  decorationMethod,
  task,
  taskStatus,
  itemStatus,
  isAssemblyDisabled,
  orderItemDecorationDecoratedOnClick,
}: Props<T>) => {
  const handleOrderItemDecorationDecoratedOnClick = useCallback(() => {
    orderItemDecorationDecoratedOnClick(task);
  }, [
    orderItemDecorationDecoratedOnClick,
    task,
  ]);

  return (
    <CheckButton
      checked={taskStatus === orderItemStatusEnum.Decorated}
      text={'Decorated'}
      disabled={
        isAssemblyDisabled
          || (decorationMethod === DecorationMethodEnum.HAG
            && (taskStatus === orderItemStatusEnum.Initial))
          || itemStatus === orderItemStatusEnum.Initial
          || itemStatus === orderItemStatusEnum.OnHold
          || itemStatus === orderItemStatusEnum.AwaitingDispatchForPicking
          || itemStatus === orderItemStatusEnum.InPicking
          || itemStatus === orderItemStatusEnum.CheckedIn
          || itemNotCheckedInStatuses.includes(itemStatus)
      }
      onClick={handleOrderItemDecorationDecoratedOnClick}
    />
  );
};

export default React.memo(DecoratedButton) as typeof DecoratedButton;
