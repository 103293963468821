import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { Column } from 'react-table-6';
import { BackorderDto } from '@api/fulfillment/models';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  tabSizesEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { BackorderManagementTabEnum } from '@constants/enums/tableEnums';
import { RootState } from '@redux/index/reducers';
import { parseDateNumeric } from '@util/dateHandler';
import Tabs from '@sharedComponents/Tabs/Tabs';
import Label from '@sharedComponents/Display/Label';
import LinkButton from '@sharedComponents/Buttons/LinkButton';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import NeverNotifiedTab from './BackorderManagementTabs/NeverNotifiedTab';
import PreviouslyNotifiedTab from './BackorderManagementTabs/PreviouslyNotifiedTab';
import DiscontinuedItemsTab from './BackorderManagementTabs/DiscontinuedItemsTab';
import { navigateToPage } from '@util/componentHelper';

const mapStateToProps = ({ tableManager }: RootState) => ({
  initialSearchInput: tableManager.backorderManagement.searchInput,
  initialSelectedTab: tableManager.backorderManagement.selectedTab,
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const BackorderManagement = React.memo<Props>(({
  initialSearchInput,
  initialSelectedTab,
}) => {
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>(initialSearchInput);
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<BackorderManagementTabEnum>(initialSelectedTab);

  const goToOrderDetails = (orderNumber: number) => {
    navigateToPage({
      pathname: orderDetailsUrl(orderNumber),
      state: { prevPath: location.pathname },
    });
  };

  const selectTab = useCallback((newSelectedTab: string) => {
    setSelectedTab(newSelectedTab as BackorderManagementTabEnum);
    setSearchInput('');
  }, []);

  const getColumns = useCallback((): Array<Column<BackorderDto>> => {
    const columns: Array<Column<BackorderDto>> = [
      {
        Header: <HeaderCell text={'Order ID'} />,
        accessor: 'orderNumber',
        minWidth: 60,
        sortable: true,
        Cell: (cellProps) => cellProps.value && (
          <LinkButton
            text={`O${cellProps.value}`}
            onClick={goToOrderDetails.bind(null, cellProps.value)}
          />
        ),
      },
      {
        Header: 'Items Backordered',
        accessor: '',
        minWidth: 50,
        Cell: (cellProps) => {
          const order = cellProps.value as BackorderDto;

          if (order) {
            return (
              <span className='align__center'>
                <span>{order.backorderedItemIds?.length}/{order.totalItems}</span>
                {
                  !!order.backorderedItemIds?.length
                    && order.totalItems
                    && (order.backorderedItemIds?.length === order.totalItems) &&
                    <Label
                      text={'Full'}
                      type={'status-orange'}
                      classes={'ml-10'}
                    />
                }
              </span>
            );
          }
        },
      },
      {
        Header: <HeaderCell text={'Single Locker & Organization'} />,
        accessor: '',
        minWidth: 100,
        sortable: true,
        Cell: (cellProps) => {
          const {
            lockerId,
            lockerTeamName,
            lockers,
            organizationId,
            organizationName,
          } = cellProps.value as BackorderDto;

          return (
            <div className='ordermanagement__details--cell'>
              {
                organizationId
                  ? (
                    <div>
                      <div>ORG{organizationId} - {organizationName}</div>
                      <MaterialTooltip
                        tooltipText={(
                          <div className='pre-line'>
                            {lockers?.map((locker) => `L${locker.lockerId} - ${locker.teamName}`).join('\n')}
                          </div>
                        )}
                        placement={'top'}
                      >
                        <div className='table__cell--details'>{`${lockers?.length} ${lockers?.length === 1 ? 'Locker' : 'Lockers'}`}</div>
                      </MaterialTooltip>
                    </div>
                  )
                  : `L${lockerId} - ${lockerTeamName}`
              }
            </div>
          );
        },
      },
      {
        Header: <HeaderCell text={'Ship Date'} />,
        accessor: 'shipDate',
        minWidth: 75,
        sortable: true,
        Cell: (cellProps) => <span>{parseDateNumeric(cellProps.value)}</span>,
      },
    ];

    return columns;
  }, []);

  const clearSearch = useCallback(() => {
    setSearchInput('');
  }, []);

  const onSearch = useCallback((e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const newSearchInput = e.target.value;
    setSearchInput(newSearchInput);
  }, []);

  const tabs = useMemo(() => ([
    {
      title: 'Never Notified',
      name: BackorderManagementTabEnum.NeverNotified,
      content: (
        <NeverNotifiedTab
          selectedTab={BackorderManagementTabEnum.NeverNotified}
          searchInput={searchInput}
          getColumns={getColumns}
        />
      ),
    },
    {
      title: 'Previously Notified',
      name: BackorderManagementTabEnum.PreviouslyNotified,
      content: (
        <PreviouslyNotifiedTab
          selectedTab={BackorderManagementTabEnum.PreviouslyNotified}
          searchInput={searchInput}
          getColumns={getColumns}
        />
      ),
    },
    {
      title: 'Discontinued Items',
      name: BackorderManagementTabEnum.DiscontinuedItems,
      content: (
        <DiscontinuedItemsTab
          selectedTab={BackorderManagementTabEnum.DiscontinuedItems}
          searchInput={searchInput}
          getColumns={getColumns}
        />
      ),
    },
  ]), [
    getColumns,
    searchInput,
  ]);

  return (
    <div className='container'>
      <div className='filter-groups'>
        <SearchFilter
          search={onSearch}
          clearSearch={clearSearch}
          initialValue={initialSearchInput}
        />
      </div>

      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        selectTab={selectTab}
        size={tabSizesEnum.Large}
      />
    </div>
  );
});

export default connector(BackorderManagement);
