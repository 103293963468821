import React, { PureComponent } from 'react';
import LogoDropdownWithImageItem from './LogoDropdownWithImageItem';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';

class LogoDropdownWithImage extends PureComponent {
  state = {
    selectedLogo: null,
    dropIsActive: false,
  };

  itemOnClick = (logo, isSelected) => {
    const { updateCallback } = this.props;

    if (!isSelected) {
      this.setState({ selectedLogo: logo });

      updateCallback(logo);
    }
    this.closeDrop();
  };

  dropChange = () => {
    const { dropIsActive } = this.state;
    this.setState({ dropIsActive: !dropIsActive });
  };

  closeDrop = () => {
    this.setState({ dropIsActive: false });
  };

  render() {
    const { logos } = this.props;
    const {
      selectedLogo,
      dropIsActive,
    } = this.state;

    const options = logos.map((logo, index) => (
      <LogoDropdownWithImageItem
        logo={logo}
        key={index}
        isSelected={selectedLogo && selectedLogo === logo.id}
        onClick={this.itemOnClick}
      />
    ));

    return (
      <OutsideClickWrapper onClick={this.closeDrop}>
        <div className='image-dropdown'>
          <div
            onClick={this.dropChange}
            className='image-dropdown__select'
          >
            {
              selectedLogo
                ? (
                  <div
                    className='image-dropdown__select--image'
                    style={{ backgroundImage: `url("${lockerMgrS3Logos}/${selectedLogo.image}")` }}
                  />
                )
                : <div className='image-dropdown__select--font'>Click to choose <div className='image-dropdown__select--font--color'>replacement logo</div></div>
            }
          </div>
          <div className={`image-dropdown__options ${dropIsActive ? 'is-active' : ''}`}>
            <ul className='image-dropdown__options--list custom-scrollbar'>
              {options}
            </ul>
          </div>
        </div>
      </OutsideClickWrapper>
    );
  }
}

LogoDropdownWithImage.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  updateCallback: PropTypes.func.isRequired,
};

export default LogoDropdownWithImage;
