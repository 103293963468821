import React, {
  useCallback,
  useState,
} from 'react';
import { navigateToPage } from '@util/componentHelper';
import Icon from '@sharedComponents/Icons/Icon';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

interface OwnProps {
  url: string;
  text: string;
  classes?: string;
  confirmationRequired?: boolean;
  confirmationTitle?: string;
  confirmationText?: string;
}

type Props = OwnProps;

const BackLink = React.memo<Props>(({
  url,
  text,
  classes = '',
  confirmationRequired = false,
  confirmationTitle = '',
  confirmationText = '',
}) => {
  const [
    confirmationModalIsOpen,
    setConfirmationModalIsOpen,
  ] = useState<boolean>(false);

  const goToPage = useCallback(() => {
    navigateToPage({
      pathname: url,
      state: { prevPath: location.pathname },
    });
  }, [url]);

  const goToPageWithConfirmation = useCallback(() => {
    if (confirmationRequired) {
      setConfirmationModalIsOpen(true);
    } else {
      goToPage();
    }
  }, [
    confirmationRequired,
    goToPage,
    setConfirmationModalIsOpen,
  ]);

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalIsOpen(false);
  }, []);

  return (
    <>
      {
        confirmationRequired &&
        <SimpleConfirmationModal
          isOpen={confirmationModalIsOpen}
          confirm={goToPage}
          closeModal={closeConfirmationModal}
          title={confirmationTitle}
          confirmationBody={confirmationText}
        />
      }
      <a
        className={`back-button cursor-pointer ${classes}`}
        onClick={goToPageWithConfirmation}
      >
        <Icon materialIcon={'keyboard_arrow_left'} />
        <span>{text}</span>
      </a>
    </>
  );
});

export default BackLink;
