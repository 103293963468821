import React from 'react';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import { vendorInventoryEnum } from '@constants/enums/vendorInvenotryEnum';

interface OwnProps {
  filter: (value: string) => void;
  selected?: string;
  defaultValue?: string;
  classNames?: string;
}

type Props = OwnProps;

const VendorQuantityFilter = React.memo<Props>(
  ({
    filter,
    selected,
    defaultValue,
    classNames,
  }) => {
    const options = Object.values(vendorInventoryEnum).map((o) => ({
      key: o.value,
      value: o.quantity,
      name: o.title,
    }));

    const selectedRange = options.find((range) => range.value === selected);
    const value = selectedRange ? selectedRange.value : defaultValue;

    return (
      <div className={`${classNames}`}>
        <Dropdown
          options={options}
          onChange={filter}
          defaultValue={value}
          classes={'filter-group'}
        />
      </div>
    );
  }
);

export default VendorQuantityFilter;
