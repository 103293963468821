import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterTypes } from '@constants/enums/commonEnums';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';

class SkuSetupFilters extends PureComponent {
  handleCategories = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.categories);
  };

  handleBrands = (items) => {
    const { filter } = this.props;

    filter(items, filterTypes.brands);
  };

  render() {
    const {
      search,
      filterKey,
      clearSearch,
      categories,
      brands,
      stylesOnlyChange,
      stylesOnlyChecked,
      prdOnly,
      prdOnlyChange,
    } = this.props;

    return (
      <div className='product-catalog__filters'>
        <div className='product-catalog__select-filters'>
          <SearchFilter
            initialValue={search || ''}
            search={filterKey}
            clearSearch={clearSearch}
          />
          <MultiSelectDropdown
            objects={brands}
            itemText={'brands'}
            updateCallback={this.handleBrands}
            textKey={'name'}
            valueKey={'id'}
            classNames={'margin-left'}
          />
          <MultiSelectDropdown
            objects={categories}
            itemText={'categories'}
            updateCallback={this.handleCategories}
            textKey={'name'}
            valueKey={'id'}
            classNames={'margin-left'}
          />
        </div>
        <div className='product-catalog__checkbox-filters'>
          <MaterialCheckbox
            checked={prdOnly}
            onClick={prdOnlyChange}
            text={'Show PRD Only Items'}
          />
          <MaterialCheckbox
            checked={stylesOnlyChecked}
            onClick={stylesOnlyChange}
            text={'Show Parent Items Only'}
          />
        </div>
      </div>
    );
  }
}

SkuSetupFilters.propTypes = {
  search: PropTypes.string.isRequired,
  filterKey: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  stylesOnlyChecked: PropTypes.bool,
  stylesOnlyChange: PropTypes.func.isRequired,
  prdOnly: PropTypes.bool,
  prdOnlyChange: PropTypes.func.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  categories: productCatalog.categories,
  brands: productCatalog.brands,
});

export default connect(mapStateToProps)(SkuSetupFilters);
