import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import LockerCard from './LockerCard';

class OrganizationFeaturedLockers extends Component {
  onDragEnd = async (result) => {
    const {
      featuredLockers,
      reorderFeaturedLockers,
      emptyFeaturedLockerPlaces,
    } = this.props;

    if (!result.destination || !result.source) return;

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    if (featuredLockers && featuredLockers.length > 0) {
      const lockers = [];

      for (let i = 0; i < (emptyFeaturedLockerPlaces.length + featuredLockers.length); i++) {
        const locker = featuredLockers.find((l) => l.orderPlace === (i + 1));
        if (locker) {
          lockers.push(locker);
        } else {
          lockers.push({
            id: 0,
            orderPlace: 0,
          });
        }
      }

      const [removed] = lockers.splice(startIndex, 1);
      lockers.splice(endIndex, 0, removed);
      lockers.forEach((locker, i) => locker.orderPlace = i + 1);
      const reorderedLockers = lockers.filter((l) => l.id);

      reorderFeaturedLockers(reorderedLockers);
    }
  };

  render() {
    const {
      featuredLockers,
      emptyFeaturedLockerPlaces,
    } = this.props;

    const cards = [];
    const emptyPlacesCopy = [...emptyFeaturedLockerPlaces];

    for (let i = 0; i < (emptyFeaturedLockerPlaces.length + featuredLockers.length); i++) {
      const locker = featuredLockers.find((l) => l.orderPlace === (i + 1));

      if (locker) {
        cards.push(locker);
      } else {
        cards.push(emptyPlacesCopy.shift());
      }
    }

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable
          droppableId='droppable'
          direction='horizontal'
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              className='organization-group__logos organization__featured-lockers'
              {...provided.droppableProps}
            >
              {
                cards.map((card, index) => (
                  <Draggable
                    key={(card && card.id) || `e${index}`}
                    draggableId={(card && card.id && card.id.toString()) || `e${index}`}
                    index={index}
                    isDragDisabled={!card || (card && (!card.id || card.id <= 0))}
                  >
                    {
                      (draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className='organization__featured-lockers__card-wrapper'
                        >
                          {
                            card && card.id
                              ? (
                                <>
                                  <LockerCard locker={card} />
                                  <div className='featured-icon' />
                                </>
                              )
                              : card
                          }
                        </div>
                      )
                    }
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

OrganizationFeaturedLockers.propTypes = {
  reorderFeaturedLockers: PropTypes.func.isRequired,
  featuredLockers: PropTypes.arrayOf(PropTypes.object).isRequired,
  emptyFeaturedLockerPlaces: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default OrganizationFeaturedLockers;
