import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  reduxForm,
  stopSubmit,
} from 'redux-form';
import { connect } from 'react-redux';
import { couponForm } from '@constants/reduxForms';
import { validateCoupon } from '@redux/coupons/validations';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import CouponsForm from '../CouponsForm';

class CouponsEditModal extends Component {
  handleCloseModal = async () => {
    const {
      dispatch,
      closeModal,
    } = this.props;

    closeModal();
    await dispatch(stopSubmit(couponForm, {}));
  };

  render() {
    const {
      isOpen,
      handleSubmit,
      error,
      initialValues,
      change,
    } = this.props;

    return (
      <Modal
        title={'Edit Coupon Code'}
        isOpen={isOpen}
        modalSize={'l'}
        closeModal={this.handleCloseModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onClose={this.handleCloseModal}
            formSubmission={true}
            formId={couponForm}
          />
        )}
      >
        <div className='order__personalization-modal'>
          <CouponsForm
            onSubmit={handleSubmit}
            change={change}
            disabled={{ code: true }}
            error={error}
            initialValues={initialValues}
          />
        </div>
      </Modal>
    );
  }
}

CouponsEditModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  error: PropTypes.string,
  change: PropTypes.func,
};

export default connect()(reduxForm({
  form: couponForm,
  enableReinitialize: true,
  validate: validateCoupon,
})(CouponsEditModal));
