import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dtgPrintDirectionOptions } from '@constants/options/optionsValues';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { materialSwal } from '@util/componentHelper';
import { getPagingParamsFromTable } from '@util/tableHelpers';
import { getDtgTabletProfiles } from '@redux/productCatalog/actions';
import { generalOptions } from '@util/optionsMap';
import {
  addDtgTabletProfile,
  editDtgTabletProfile,
  deleteDtgTabletProfile,
} from '@APICalls/dtg/actions';
import {
  defaultPageSizeSmallTable,
  pageSizeOptionsSmallTable,
} from '@constants/values';
import DtgTabletProfilesEditModal from './DtgTabletProfilesModals/DtgTabletProfilesEditModal';
import DtgTabletProfilesAddModal from './DtgTabletProfilesModals/DtgTabletProfilesAddModal';
import DtgTabletProfilesDeleteModal from './DtgTabletProfilesModals/DtgTabletProfilesDeleteModal';
import Button from '@sharedComponents/Buttons/Button';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import Table from '@sharedComponents/Table/Table';

const DtgTabletProfilesTable = Table();

class DtgTabletProfiles extends PureComponent {
  state = {
    pageNumber: 1,
    pageSize: defaultPageSizeSmallTable,
    search: '',
    editDtgTabletProfileModalIsOpen: false,
    addDtgTabletProfileModalIsOpen: false,
    deleteDtgTabletProfileModalIsOpen: false,
    selectedProfile: { },
  };

  componentDidMount() {
    this.fetchDtgTabletProfiles();
  }

  onAddProfile = async (data) => {
    data = this.formatData(data);

    const result = await addDtgTabletProfile(data);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgTabletProfiles();
    }
  };

  onEditProfile = async (data) => {
    const { id } = this.state.selectedProfile;

    if (!id) {
      materialSwal('Error', 'ID is empty', 'error');

      return;
    }
    data = this.formatData(data);

    const result = await editDtgTabletProfile(id, data);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgTabletProfiles();
    }
  };

  onDeleteProfile = async (id) => {
    const result = await deleteDtgTabletProfile(id);
    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.closeModal();
      this.fetchDtgTabletProfiles();
    }
  };

  fetchDtgTabletProfiles = () => {
    const { dispatch } = this.props;
    const {
      pageNumber,
      pageSize,
      search,
    } = this.state;
    dispatch(getDtgTabletProfiles(pageNumber, pageSize, search));
  };

  fetchData = (state, instance) => {
    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    this.setState(() => ({
      pageNumber: page + 1,
      pageSize,
    }), this.fetchDtgTabletProfiles);
  };

  formatData = (data) => {
    const {
      minutes,
      seconds,
      milliseconds,
      ...rest
    } = data;
    const millisecondsInMin = 60000;
    const millisecondsInSec = 1000;
    const millisecondsTotal = minutes * millisecondsInMin + seconds * millisecondsInSec + milliseconds || 0;

    return {
      ...rest,
      waitUnderbaseMilliseconds: millisecondsTotal,
    };
  };

  formatUnderbaseTimeFromMilliseconds = (milliseconds) => {
    const millisecondsInMin = 60000;
    const millisecondsInSec = 1000;

    const min = Math.floor(milliseconds / millisecondsInMin);
    const sec = Math.floor((milliseconds - min * millisecondsInMin) / millisecondsInSec);
    const ms = milliseconds - min * millisecondsInMin - sec * millisecondsInSec;

    const minText = !!min ? `${min} min ` : '';
    const secText = !!sec ? `${sec} sec ` : '';
    const msText = !!ms ? `${ms} ms ` : ' 0 ms ';

    return `${minText}${secText}${msText}`;
  };

  formatPropsForEditForm = (data) => {
    const millisecondsInMin = 60000;
    const millisecondsInSec = 1000;

    const min = Math.floor(data.waitUnderbaseMilliseconds / millisecondsInMin);
    const sec = Math.floor((data.waitUnderbaseMilliseconds - min * millisecondsInMin) / millisecondsInSec);
    const ms = data.waitUnderbaseMilliseconds - min * millisecondsInMin - sec * millisecondsInSec;

    return {
      ...data,
      minutes: min,
      seconds: sec,
      milliseconds: ms,
    };
  };

  getInitialValuesForNewDtgTabletProfile = () => (
    {
      printDirectionUnderbase: dtgPrintDirectionOptions[0].value,
      printDirectionColor: dtgPrintDirectionOptions[0].value,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
      passesColor: 0,
      passesUnderbase: 0,
    }
  );

  clearSearch = () => {
    this.setState(() => ({
      search: '',
    }), this.fetchDtgTabletProfiles);
  };

  onSearch = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const searchInput = e.target.value;

    this.setState(() => ({
      search: searchInput,
    }), this.fetchDtgTabletProfiles);
  };

  closeModal = () => {
    this.setState(() => ({
      editDtgTabletProfileModalIsOpen: false,
      addDtgTabletProfileModalIsOpen: false,
      deleteDtgTabletProfileModalIsOpen: false,
      selectedProfile: { },
    }));
  };

  openEditModal = (selectedProfile) => {
    this.setState(() => ({
      editDtgTabletProfileModalIsOpen: true,
      selectedProfile,
    }));
  };

  openAddModal = () => {
    this.setState(() => ({
      addDtgTabletProfileModalIsOpen: true,
      selectedProfile: { },
    }));
  };

  openDeleteModal = (selectedProfile) => {
    this.setState(() => ({
      deleteDtgTabletProfileModalIsOpen: true,
      selectedProfile,
    }));
  };

  getColumns = () => [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Note',
      accessor: 'note',
      minWidth: 150,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Print Direction Underbase',
      accessor: 'printDirectionUnderbase',
      minWidth: 55,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Print Direction Color',
      accessor: 'printDirectionColor',
      minWidth: 55,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Wait After White Underpass',
      accessor: 'waitUnderbaseMilliseconds',
      minWidth: 80,
      Cell: (cellProps) => cellProps.value && <span>{this.formatUnderbaseTimeFromMilliseconds(cellProps.value)}</span>,
    },
    {
      Header: 'Extra Underbase Passes',
      accessor: 'passesUnderbase',
      minWidth: 60,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Extra Color Passes',
      accessor: 'passesColor',
      minWidth: 45,
      Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
    },
    {
      Header: 'Actions',
      accessor: '',
      resizable: false,
      minWidth: 35,
      style: { overflow: 'visible' },
      Cell: (cellProps) => (
        <ShowMoreActionsButton
          actions={[
            {
              text: 'Edit',
              action: this.openEditModal.bind(null, cellProps.value),
              isVisible: true,
            },
            {
              text: 'Delete',
              action: this.openDeleteModal.bind(null, cellProps.value),
              isDangerous: true,
              isVisible: true,
            },
          ]}
        />
      ),
    },
  ];

  render() {
    const { dtgTabletProfiles } = this.props;
    const {
      editDtgTabletProfileModalIsOpen,
      addDtgTabletProfileModalIsOpen,
      deleteDtgTabletProfileModalIsOpen,
      pageNumber,
      selectedProfile,
    } = this.state;

    const addDtgTabletProfileInitialValues = this.getInitialValuesForNewDtgTabletProfile();
    const selectedProfileForEditForm = this.formatPropsForEditForm(selectedProfile);
    const printDirectionOptions = generalOptions(dtgPrintDirectionOptions);

    return (
      <div className='container'>
        <DtgTabletProfilesAddModal
          isOpen={addDtgTabletProfileModalIsOpen}
          closeModal={this.closeModal}
          onSubmit={this.onAddProfile}
          initialValues={addDtgTabletProfileInitialValues}
          printDirectionOptions={printDirectionOptions}
        />
        <DtgTabletProfilesEditModal
          isOpen={editDtgTabletProfileModalIsOpen}
          closeModal={this.closeModal}
          onSubmit={this.onEditProfile}
          initialValues={selectedProfileForEditForm}
          printDirectionOptions={printDirectionOptions}
        />
        <DtgTabletProfilesDeleteModal
          isOpen={deleteDtgTabletProfileModalIsOpen}
          closeModal={this.closeModal}
          selectedProfile={selectedProfile}
          onDelete={this.onDeleteProfile}
        />
        <div className='table-options w-100'>
          <div className='flex'>
            <SearchFilter
              search={this.onSearch}
              clearSearch={this.clearSearch}
            />
          </div>
          <div className='flex'>
            <Button
              type={'primary'}
              text={'Add New Profile'}
              onClick={this.openAddModal}
              classes={'colors__add-btn'}
            />
          </div>
        </div>
        <br />
        <div className='sheet'>
          <DtgTabletProfilesTable
            data={dtgTabletProfiles.items}
            columns={this.getColumns()}
            defaultPageSize={defaultPageSizeSmallTable}
            pageSizeOptions={pageSizeOptionsSmallTable}
            defaultPage={pageNumber}
            totalPages={dtgTabletProfiles.totalPages}
            hasNextPage={dtgTabletProfiles.hasNextPage}
            hasPreviousPage={dtgTabletProfiles.hasPreviousPage}
            onFetchData={this.fetchData}
            showPagination={dtgTabletProfiles.hasNextPage || dtgTabletProfiles.hasPreviousPage}
          />
        </div>
      </div>
    );
  }
}

DtgTabletProfiles.propTypes = {
  dtgTabletProfiles: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  dtgTabletProfiles: productCatalog.dtgTabletProfiles,
});

export default connect(mapStateToProps)(DtgTabletProfiles);
