import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const DeleteStylesModal = React.memo(({
  styles,
  deleteStyles,
  closeModal,
  isOpen,
}) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    title={'Delete Style(s)'}
    modalHeight={'l'}
    modalWidth={'m'}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, Delete'}
        cancelBtnText={'Cancel'}
        onConfirm={deleteStyles}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <div className='lockerManager__delete-modal--content'>
      <h3>Are you sure you want to delete styles listed below?</h3>
      <ul style={{ listStyle: 'disc' }}>
        {
          styles.map((style) => (
            <li key={style.id}>
              <strong>{style.code}</strong> - {style.name}
            </li>
          ))
        }
      </ul>
    </div>
  </Modal>
));

DeleteStylesModal.propTypes = {
  styles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  deleteStyles: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DeleteStylesModal;
