import React, { FC } from 'react';
import Table from '@sharedComponents/Table/Table';
import Label from '@sharedComponents/Display/Label';
import {
  WarehouseOrderPagedListDto,
  WarehouseOrderPagedListDtoPagedList,
  OrderWarehouseStatusEnum,
} from '@api/fulfillment/models';
import HeaderCell from '@components/shared/Table/TableCells/HeaderCell';
import { parseDateNumeric } from '@util/dateHandler';
import ProgressBarCell from '@components/shared/Table/TableCells/ProgressBarCell';
import {
  decorationMethodDisplayNames,
  DecorationMethodType,
} from '@constants/common';

const OrdersTable = Table<WarehouseOrderPagedListDto>();

type WarehouseSchedulingTableProps = {
  queryData: WarehouseOrderPagedListDtoPagedList | undefined;
  onFetchData: (state: any, instance: any) => void;
  onFocusedOrder: (state: any, instance: any) => void;
  selectedOrders: WarehouseOrderPagedListDto[];
  onSelectAll: () => void;
  onSelect: (ord: WarehouseOrderPagedListDto[]) => void;
  isSelectingItemDisabled: (ord?: WarehouseOrderPagedListDto) => boolean;
  status: OrderWarehouseStatusEnum;
};

export const WarehouseSchedulingTable: FC<WarehouseSchedulingTableProps> = ({
  queryData,
  onFetchData,
  onFocusedOrder,
  selectedOrders,
  onSelectAll,
  onSelect,
  status,
  isSelectingItemDisabled,
}) => {
  const columns = [
    {
      Header: <HeaderCell text={'Order ID'} />,
      accessor: 'orderNumber',
      minWidth: 60,
      sortable: true,
      Cell: (cellProps: any) => (
        <div className='scheduling-table__column'>
          <span>{cellProps.value}</span>
          {
            cellProps.original.decorationMethods?.length
            && cellProps.original.decorationMethods.map((method: DecorationMethodType) => (
              <Label
                key={method}
                text={decorationMethodDisplayNames[method]?.short}
                type={'lynch'}
              />
            ))
          }
        </div>
      ),
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      sortable: true,
      minWidth: 50,
      Cell: (cellProps: any) => cellProps.value && parseDateNumeric(cellProps.value),
    },
    {
      Header: <HeaderCell text={'Customer ID'} />,
      accessor: 'gpCustomerId',
      sortable: true,
      minWidth: 50,
    },
    {
      Header: <HeaderCell text={'Partner Name'} />,
      accessor: (row: any) => (row.orgPartnerName ? row.orgPartnerName : row.partnerName),
      id: 'partnerName',
      sortable: true,
      minWidth: 50,
    },
    {
      Header: 'Inventory Status',
      accessor: 'inventoryStatus',
      minWidth: 60,
      className: 'text-bold',
      Cell: (cellProps: any) => <span>{cellProps.value.label}</span>,
    },
    {
      Header: status === OrderWarehouseStatusEnum.AwaitingInventory
        ? 'Available / Items Awaiting Inventory'
        : 'Inventory / Items in Scheduling',
      minWidth: 70,
      className: 'text-bold',
      Cell: (cellProps: any) => (
        <ProgressBarCell
          current={
            status === OrderWarehouseStatusEnum.AwaitingInventory
              ? cellProps.original.qtyAvailableAwaitingInventory
              : cellProps.original.qtyWarehouseStatus
          }
          total={
            status === OrderWarehouseStatusEnum.AwaitingInventory
              ? cellProps.original.qtyWarehouseStatus
              : cellProps.original.qtyTotalInScheduling
          }
        />
      ),
    },
    {
      Header: 'Moved from "Awaiting Inventory"',
      minWidth: 100,
      className: 'text-bold',
      Cell: (cellProps: any) => cellProps.original.status
        ? (
          <span>
            <b>{parseDateNumeric(cellProps.original.status.created)}</b> {cellProps.original.status.user ? 'by' : ''} {cellProps.original.status.user}
          </span>
        )
        : null,
    },
  ];

  const allAreSelected = !!queryData?.items?.length
    && queryData.items.every((x) => selectedOrders.some((y) => x.orderNumber === y.orderNumber));

  return (
    <OrdersTable
      data={queryData?.items ?? []}
      onFetchData={onFetchData}
      columns={columns}

      hasNextPage={queryData?.hasNextPage}
      hasPreviousPage={queryData?.hasPreviousPage}
      totalPages={queryData?.totalPages}
      getTrProps={onFocusedOrder}
      pageSizeOptions={[
        10,
        25,
        50,
        100,
        200,
        500,
      ]}

      selectable={[
        OrderWarehouseStatusEnum.AwaitingDispatch,
        OrderWarehouseStatusEnum.AwaitingInventory,
      ].includes(status)}
      selectedData={selectedOrders}
      onSelectBulkAction={onSelectAll}
      updateSelection={onSelect}
      selectPredicateOrKey={'orderNumber'}
      isPageSelected={allAreSelected}
      isSelectingItemDisabled={isSelectingItemDisabled}
    />
  );
};

export default WarehouseSchedulingTable;
