import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getLockerTeamTypeOptions } from '@constants/options/options';
import { clcStatusEnum } from '@constants/enums/lockerEnums';
import { toggleButtonStateEnum } from '@constants/enums/commonEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import {
  organizationDetailsUrl,
  orderSearchLockerUrl,
} from '@constants/clientUrls/clientUrls';
import { toggleAutoMerchOptInForLocker, toggleFreeShippingForLocker } from '@redux/lockerManager/actions';
import {
  parseDateNumeric,
  parseDateTimeNumeric,
} from '@util/dateHandler';
import {
  formatDollarAmount,
  formatPercentageAmount,
} from '@util/numberHelpers';
import InfoField from '@sharedComponents/Display/InfoField';
import Link from '@sharedComponents/Navigation/Link';
import ShowMoreText from '@sharedComponents/Display/ShowMoreText';
import ToggleButton from '@sharedComponents/Buttons/ToggleButton';
import Icon from '@sharedComponents/Icons/Icon';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import OpenNewTabLink from '@sharedComponents/Navigation/OpenNewTabLink';
import StoreLogo from '../LockerManagerTable/LockerManagerQuickView/StoreLogo';
import Label from '@components/shared/Display/Label';

const teamTypeOptions = getLockerTeamTypeOptions(true, '-');

// Class LockerManagerHeaderDetails extends Component {
const LockerManagerHeaderDetails = ({
  locker,
  dispatch,
  partners,
  openEditCLCModal,
  orderItemsStatus,
  openLockerChangeLogsModal,
}) => {
  const partner = partners.find((p) => p.id === locker.partnerId);
  const teamTypeOption = teamTypeOptions.find((t) => t.value === locker.teamType);

  const { claimStorePartnerIds } = useFlags();

  function renderPartnerDetail() {
    const isClaimStorePartnerEnabled = claimStorePartnerIds?.length !== 0;
    const isLockerPartnerEnabledForClaimStores = claimStorePartnerIds?.includes(locker.partnerId);

    if (!isClaimStorePartnerEnabled || !isLockerPartnerEnabledForClaimStores
    ) {
      return partner?.name;
    }

    if (isLockerPartnerEnabledForClaimStores) {
      const claimedLabel = (
        <Label
          classes='claimed'
          text={locker.claimed ? 'claimed' : 'unclaimed'}
          type={locker.claimed ? 'active' : 'deleted'}
          dataTest={'claimed-label'}
          tooltipText={locker.claimedAt
            ? `First claimed on: ${parseDateTimeNumeric(locker.claimedAt)}`
            : 'Never claimed'}
          tooltipPlacement={'right'}
        />
      );

      return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          {partner?.name}
          {claimedLabel}
        </div>
      );
    }

    return null;
  }

  function handleToggleFreeShipping() {
    dispatch(toggleFreeShippingForLocker(locker.id, !locker.freeShipping));
  }

  function handleToggleAutoMerchOptIn() {
    dispatch(toggleAutoMerchOptInForLocker(locker.id));
  }

  return (
    <div className='lockerManagerEdit__details-container'>
      <div>
        <div className='lockerManagerEdit__details-info'>
          <div className='lockerManagerEdit__details-column--l sb fixed-width'>
            <InfoField
              label={'Team Name'}
              value={locker.storeName}
              dataTest='team-name'
            />
            <InfoField
              label={'Organization Name'}
              dataTest='organization-name'
              value={
                locker.organizationName &&
                <Link
                  url={organizationDetailsUrl(locker.organizationId)}
                  text={`ORG${locker.organizationId} - ${locker.organizationName}`}
                />
              }
            />
            <InfoField
              label={'Program Name'}
              dataTest='program-name'
              value={locker.programName}
            />
            <InfoField
              label={'Locker Manager'}
              dataTest='locker-manager'
              value={locker.email}
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb fixed-width'>
            <InfoField
              tooltip={'Obsolete'}
              tooltipPlacement={'top'}
              label={<div className='cursor-pointer'>Team Type</div>}
              value={teamTypeOption?.name}
              dataTest='team-type'
            />
            <InfoField
              label={'Team UID'}
              value={locker.teamUID}
              dataTest='team-uuid'
            />
            <InfoField
              label={'Gender'}
              value={locker.genders}
            />
            <InfoField
              label={'Date Created'}
              value={locker.dateCreated && parseDateNumeric(locker.dateCreated)}
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb fixed-width-m'>
            <InfoField
              tooltip={'Editable through Locker Owner\'s page'}
              tooltipPlacement={'top'}
              label={
                <div className='cursor-pointer'>Organization Type</div>
              }
              value={locker.lockerManagerOrganizationType}
            />
            <InfoField
              label={'Partner'}
              value={renderPartnerDetail()}
            />
            <InfoField
              label={'Players'}
              value={locker.playerCount}
            />
            <InfoField
              label={'Discount'}
              value={formatPercentageAmount(locker.discount)}
              dataTest='discount'
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb fixed-width'>
            <InfoField
              label={'Roster Uploaded'}
              value={locker.rosterId ? 'Yes' : 'No'}
            />
            <InfoField
              label={'LCK Number'}
              value={locker.LCK}
              dataTest='lck-number'
            />
            <InfoField
              label={'Ages'}
              value={locker.ages}
              dataTest='ages'
            />
            <InfoField
              label={'Markup'}
              labelClass={'flex'}
              value={formatPercentageAmount(locker.markup)}
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb'>
            <InfoField
              label={'Sport'}
              value={locker.sport}
              dataTest='sport'
            />
            <InfoField
              label={'Free Shipping'}
              dataTest='free-shipping'
              value={(
                <div className='flex-center'>
                  <span className={locker.freeShipping ? 'status-label enabled' : ''}>
                    {locker.freeShipping ? toggleButtonStateEnum.ON : toggleButtonStateEnum.OFF}
                  </span>
                  <div className='ml-10'>
                    <PermissionHandler permissions={PermissionsEnum.LockerManagerEditShipping}>
                      <ToggleButton
                        name={'freeShipping'}
                        enabled={locker.freeShipping}
                        onChange={handleToggleFreeShipping}
                        dataTest='free-shipping-toggle'
                      />
                    </PermissionHandler>
                  </div>
                </div>
              )}
            />
            <InfoField
              label={'CLC'}
              dataTest='clc'
              value={(
                <div className='info__value flex'>
                  <span className={locker.enableMultipleLogos ? 'status-label enabled' : ''}>
                    {locker.enableMultipleLogos ? clcStatusEnum.ON : clcStatusEnum.OFF}
                  </span>
                  {
                    !locker.enableMultipleLogos &&
                    <div
                      className='storeLogo__link lockerManagerEdit__details-header--link'
                      data-test='clc-edit'
                    >
                      <a
                        className='store-link uppercase'
                        target='_blank'
                        onClick={openEditCLCModal}
                      >
                        <Icon materialIcon={'edit'} />
                        Edit
                      </a>
                    </div>
                  }
                </div>
              )}
            />
            <InfoField
              label={(
                <div className='flex'>
                  Last Updated
                  <button
                    className='btn-borderless order__total-price-info-button'
                    onClick={openLockerChangeLogsModal}
                  >
                    <Icon
                      materialIcon={'info_outline'}
                      classes='order__total-price-info-icon'
                    />
                  </button>
                </div>
              )}
              value={locker.updatedAt && parseDateTimeNumeric(locker.updatedAt)}
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb fixed-width flex-space-between'>
            <StoreLogo
              lockerId={locker.id}
              slug={locker.slug}
              logoUrl={locker.logoUrl}
            />
            <InfoField
              label={'SquadLocker\'s Picks'}
              value={(
                <div className='flex-center'>
                  <span className={locker.optInAutomerch ? 'status-label enabled' : ''}>
                    {locker.optInAutomerch ? toggleButtonStateEnum.ON : toggleButtonStateEnum.OFF}
                  </span>
                  <div className='ml-10'>
                    <PermissionHandler permissions={PermissionsEnum.MerchandisingEffortsOptIn}>
                      <ToggleButton
                        name={'optInAutomerch'}
                        enabled={locker.optInAutomerch}
                        onChange={handleToggleAutoMerchOptIn}
                        dataTest='auto-merch-toggle'
                      />
                    </PermissionHandler>
                  </div>
                </div>
              )}
              dataTest='auto-merch'
            />
          </div>
        </div>

        <hr />

        <div className='lockerManagerEdit__details-infoTop'>
          <div className='lockerManagerEdit__details-column--l sb fixed-width-top'>
            <InfoField
              tooltip={'All time order count for locker'}
              tooltipPlacement={'top-start'}
              label={'Total Orders'}
              value={(
                <div className='flex'>
                  <span>{locker.totalOrders || 0}</span>
                  <OpenNewTabLink
                    url={orderSearchLockerUrl(locker.id)}
                    text={'All Orders'}
                  />
                </div>
              )}
            />
          </div>

          <div className='lockerManagerEdit__details-column--l sb fixed-width-top'>
            <InfoField
              tooltip={'Sum of all time revenue for locker'}
              tooltipPlacement={'top-start'}
              label={'Total Sales'}
              value={formatDollarAmount(locker.totalSales)}
            />
          </div>

          <div className='lockerManagerEdit__details-column--l sb fixed-width'>
            <InfoField
              tooltip={'3 month revenue for locker'}
              tooltipPlacement={'top-start'}
              label={'3 Month Sales'}
              value={formatDollarAmount(locker.sales3Month)}
            />
          </div>
        </div>

        <hr />

        <div className='lockerManagerEdit__details-info'>
          <div className='lockerManagerEdit__details-column--l sb fixed-width'>
            <InfoField
              tooltip={'Sum of funds raised by locker for all time'}
              tooltipPlacement={'top-start'}
              label={'Total Fundraising'}
              value={formatDollarAmount(orderItemsStatus.fundraisingAmountTotal)}
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb fixed-width'>
            <InfoField
              tooltip={'Funds raised from orders that HAVEN\'T shipped, not available for payout'}
              tooltipPlacement={'top-start'}
              label={'Pending Fundraising'}
              value={formatDollarAmount(orderItemsStatus.fundraisingAmountProcessing)}
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb'>
            <InfoField
              tooltip={'Funds raised from orders that HAVE shipped, can be paid out'}
              tooltipPlacement={'top-start'}
              label={'Available Fundraising'}
              value={formatDollarAmount(orderItemsStatus.fundraisingAmountAvailable)}
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb fixed-width'>
            <InfoField
              tooltip={'Funds requested for payout, but NOT completed'}
              tooltipPlacement={'top-start'}
              label={'Requested Payout'}
              value={formatDollarAmount(orderItemsStatus.payoutAmountRequested)}
            />
          </div>
          <div className='lockerManagerEdit__details-column--l sb fixed-width'>
            <InfoField
              tooltip={'Funds paid out'}
              tooltipPlacement={'top-start'}
              label={'Completed Payout'}
              value={formatDollarAmount(orderItemsStatus.payoutAmountCompleted)}
            />
          </div>
        </div>
      </div>
      {
        locker.note &&
        <div>
          <hr />
          <InfoField
            label={'General Note'}
            value={(
              <ShowMoreText
                lines={1}
                moreLabel={'Show more'}
                lessLabel={'Show less'}
                anchorClass={'link'}
              >
                {locker.note}
              </ShowMoreText>
            )}
          />
        </div>
      }
      {
        locker.productionNote &&
        <div>
          <hr />
          <InfoField
            label={'Production Note'}
            value={(
              <ShowMoreText
                lines={1}
                moreLabel={'Show more'}
                lessLabel={'Show less'}
                anchorClass={'link'}
              >
                {locker.productionNote}
              </ShowMoreText>
            )}
          />
        </div>
      }
    </div>
  );
};

LockerManagerHeaderDetails.propTypes = {
  locker: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired,
  openEditCLCModal: PropTypes.func.isRequired,
  openLockerChangeLogsModal: PropTypes.func.isRequired,
  orderItemsStatus: PropTypes.shape({
    available: PropTypes.number,
    processing: PropTypes.number,
    requestedProcessing: PropTypes.number,
    requestedCanceled: PropTypes.number,
    completed: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.object),
    fundraisingAmountAvailable: PropTypes.number,
    fundraisingAmountProcessing: PropTypes.number,
    fundraisingAmountTotal: PropTypes.number,
    payoutAmountCanceled: PropTypes.number,
    payoutAmountCompleted: PropTypes.number,
    payoutAmountRequested: PropTypes.number,
  }),
};

export default connect()(LockerManagerHeaderDetails);
