import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { couponTypeEnum } from '@constants/enums/orderEnums';
import {
  getOrderSubtotal,
  getOrderSubtotalTax,
} from '@util/paymentHelper';
import { formatDollarAmount } from '@util/numberHelpers';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import ExpandablePriceTab from '@sharedComponents/Display/ExpandablePriceTab';

class OrderManagementOrderFinancialsModal extends PureComponent {
  onClose = () => {
    const { closeModal } = this.props;

    this.setState({
      itemsTabIsActive: false,
      shippingTabIsActive: false,
    });

    closeModal();
  };

  render() {
    let price;
    const {
      isOpen,
      order,
      coreOrderPricingDetails,
    } = this.props;

    const {
      subtotalCouponDiscount,
      shippingPricePaid,
      shippingPretaxPrice,
      shippingCouponDiscount,
      shippingTaxAmount,
      shippingRefundAmount,
      pricePaid,
      subtotal,
      couponValue,
      couponType,
    } = coreOrderPricingDetails ? coreOrderPricingDetails : order;

    const orderItemsCount = order.items.reduce((total, current) => total + current.quantity, 0);

    const subtotalTax = coreOrderPricingDetails
      ? Object.entries(coreOrderPricingDetails.orderItemsPricingDetails)?.reduce(
        (total, current) => total + current[1].taxAmount,
        0
      )
      : getOrderSubtotalTax(order);

    const subtotalRefundAmount = coreOrderPricingDetails
      ? Object.entries(coreOrderPricingDetails?.orderItemsPricingDetails)?.reduce(
        (total, current) => total + current[1].refundAmount,
        0
      )
      : Object.entries(order?.items)?.reduce(
        (total, current) => total + current[1].refundAmount,
        0
      );

    const orderRefundAmount = coreOrderPricingDetails
      ? coreOrderPricingDetails?.refundAmount - coreOrderPricingDetails?.subtotalTaxRefundAmount
      : order?.tradeDiscount;

    const totalItemsPrice = (subtotal ?? 0)
      - orderRefundAmount
      + (subtotalTax ?? 0)
      - (subtotalCouponDiscount ?? 0);

    const _getGcContent = (paymentHistory, index, calculatedPrice) => {
      let giftCardTotal = 0;
      const paymentHistoryType = paymentHistory.type.toLowerCase();
      let date = new Date(paymentHistory.lastUpdatedAt);
      let formattedDate = date.toLocaleString('en-US', {
        timeZone: 'America/New_York',
        hour12: false,
      });

      return (
        <div>
          <div>
            <div key={index}>
              <div className='justify__space-between mt-15'>
                <span className='refund__sub-heading'>{formattedDate} | Order total</span>
                <span>{formatDollarAmount(calculatedPrice)}</span>
              </div>
            </div>
            {paymentHistory.giftCards?.map((gc, i) => {
              giftCardTotal = gc.amountRedeemed + giftCardTotal;
              if (gc.amountRedeemed < 0 && gc.code !== null) {
                return (
                  <div key={i} className='payment-line'>
                    <div className='justify__space-between'>
                      <span className='refund__sub-heading'>Amount refunded to Gift Card {gc.code}</span>
                      <span className='refund'>{formatDollarAmount(gc.amountRedeemed)}</span>
                    </div>
                  </div>
                );
              }

              return (
                <div key={i} className='payment-line'>
                  <div className='justify__space-between'>
                    <span className='refund__sub-heading'>Amount reedeemed using Gift Card {gc.code}</span>
                    <span>{formatDollarAmount(gc.amountRedeemed)}</span>
                  </div>
                </div>
              );
            })}
            {
              index === 0 &&
              <div>
                <div key={index} className='payment-line'>
                  <div className='justify__space-between'>
                    <span className='refund__sub-heading'>Credit Card</span>
                    <span>{formatDollarAmount(calculatedPrice - giftCardTotal)}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          {
            !paymentHistory?.giftCards.length > 0
            && (paymentHistoryType === 'replaceitemrefund' || paymentHistoryType === 'personalizationrefund'
            || paymentHistoryType === 'orderrefund' || paymentHistoryType === 'orderitemrefund'
            ) && (
              <div key={index} className='payment-line'>
                <div className='justify__space-between'>
                  <span>Refund to Credit Card</span>
                  <span className='refund'>-{formatDollarAmount(paymentHistory.totalAmount)}</span>
                </div>
              </div>
            )
          }
        </div>
      );
    };

    return (
      <Modal
        isOpen={isOpen}
        closeModal={this.onClose}
        modalSize={'l'}
        isPadded={true}
        title={`Order O${order.orderNumber} Financials`}
        buttons={(
          <ModalButtons
            onClose={this.onClose}
            displayConfirmBtn={false}
            cancelBtnText={'Close'}
          />
        )}
      >
        <ExpandablePriceTab
          label={'Items'}
          price={totalItemsPrice}
          content={(
            <div className='order-financial-details__content'>
              <div className='justify__space-between'>
                <span>Subtotal ({`${orderItemsCount} ${orderItemsCount === 1 ? 'item' : 'items'}`}) - inclusive percentage coupon</span>
                <span className='positive-amount'>{formatDollarAmount(subtotal)}</span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Amount Coupon {couponType === couponTypeEnum.Amount ? `(${formatDollarAmount(couponValue)})` : ''}</span>
                <span className='negative-amount'>- {formatDollarAmount(subtotalCouponDiscount)}</span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Refund</span>
                <span className='refund'>- {formatDollarAmount(orderRefundAmount)}
                </span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Tax</span>
                <span className='positive-amount'>{formatDollarAmount(subtotalTax)}</span>
              </div>
            </div>
          )}
        />

        <ExpandablePriceTab
          label={'Shipping'}
          price={shippingPricePaid}
          classes={'mt-20'}
          content={(
            <div className='order-financial-details__content'>
              <div className='justify__space-between'>
                <span>Shipping</span>
                <span className='positive-amount'>{formatDollarAmount(shippingPretaxPrice)}</span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Coupon {couponType === couponTypeEnum.Amount ? `(${formatDollarAmount(couponValue)})` : ''}</span>
                <span className='negative-amount'>- {formatDollarAmount(shippingCouponDiscount)}</span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Refund</span>
                <span className='refund'>- {formatDollarAmount(
                  shippingRefundAmount
                  - (coreOrderPricingDetails?.shippingRefundTaxAmount ?? 0)
                )}
                </span>
              </div>
              <div className='justify__space-between mt-15'>
                <span>Tax</span>
                <span className='positive-amount'>{formatDollarAmount(shippingTaxAmount)}</span>
              </div>
            </div>
          )}
        />
        {order?.giftCards.length > 0 &&
          <ExpandablePriceTab
            label={'PAYMENTS(s)'}
            price={pricePaid}
            classes={'mt-20'}
            content={(
              <div className='order-financial'>
                <div className='order-financial-details__content'>
                  {order?.orderPaymentHistory?.map((gc, index) => {
                    if (index === 0) {
                      price = order?.orderPaymentHistory[index].totalAmount;
                    } else {
                      let arr = order?.orderPaymentHistory[index].giftCards;
                      if (!arr.length) {
                        price = price - order?.orderPaymentHistory[index].totalAmount;
                      }
                      arr.forEach((card) => {
                        price = price + card.amountRedeemed;
                      });
                    }

                    return _getGcContent(gc, index, price);
                  })}
                </div>
              </div>
            )}
          />
        }
        <div className='mt-40'>
          <div className='order-financial-details__total--explanation'>(Subtotal + Shipping + Tax)</div>
          <div className='order-financial-details__total'>
            <span>Price paid</span>
            <span>{formatDollarAmount(pricePaid)}</span>
          </div>
        </div>
      </Modal>
    );
  }
}

OrderManagementOrderFinancialsModal.propTypes = {
  order: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  coreOrderPricingDetails: PropTypes.object,
};

export default OrderManagementOrderFinancialsModal;
