import React from 'react';
import { fileColorFromFilename } from '@util/stringHelpers';

interface OwnProps {
  files?: string[];
}

type Props = OwnProps;

const ParsedNameFiles = React.memo<Props>(({ files }) => {
  const renderFile = (file: string, i: number) => {
    const fileColor = fileColorFromFilename(file);

    return i === (files || []).length - 1
      ? <span key={i}><a href={file}>{fileColor}</a></span>
      : <span key={i}><a href={file}>{fileColor}</a> / </span>;
  };

  if (!files || files.length > 0) {
    return (
      <>{files?.map(renderFile)}</>
    );
  }

  return <span>No files available</span>;
});

export default ParsedNameFiles;
