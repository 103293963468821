import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isSelectedItem } from '@util/selectionHelpers';

class SuggestionsDropdown extends PureComponent {
  onClickOnSuggestion = (suggestion) => {
    const {
      selectedSuggestions,
      compareBy,
      addToList,
    } = this.props;

    const alreadyInList = isSelectedItem(selectedSuggestions, suggestion, compareBy);

    if (!alreadyInList) {
      addToList(suggestion);
    }
  };

  render() {
    const {
      suggestions,
      selectedSuggestions,
      displayName = 'name',
      compareBy = 'id',
      size = 'xl',
    } = this.props;

    return (
      <div className={`suggestions suggestions--size-${size} custom-scrollbar`}>
        {
          suggestions.map((suggestion) => (
            <div
              key={suggestion.code}
              className={`suggestions__row ${isSelectedItem(selectedSuggestions, suggestion, compareBy) ? 'disabled' : ''}`}
              onClick={this.onClickOnSuggestion.bind(null, suggestion)}
            >
              {suggestion[displayName]}
            </div>
          ))
        }
      </div>
    );
  }
}

SuggestionsDropdown.propTypes = {
  compareBy: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  selectedSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  addToList: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default SuggestionsDropdown;
