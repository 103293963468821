import * as actionTypes from './types';

const initialState = {
  /*
   * Possibly remove these -- need to figure out if I need
   * to search through all queues or just the one I'm tabbed into
   */
  currentQueue: {
    hasPreviousPage: false,
    hasNextPage: false,
    pageNumber: 0,
    totalPages: 0,
    sortBy: 'id',
    sortDirection: 'Ascending',
    items: [],
    isFinalized: false,
  },
  sheetId: null,
  orderNumber: null,
  // List of weeding and masking items on a print sheet
  ordersList: [],
  inventoryState: {
    success: false,
    result: '',
  },
  counts: {
    initial: 0,
    done: 0,
  },
};

export default function weedingAndMaskingRoom(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SHEET_AND_ORDER:
      return {
        ...state,
        sheetId: action.payload.sheetId,
        orderNumber: action.payload.orderNumber,
      };
    case actionTypes.UPDATE_CURRENT_QUEUE:
      return {
        ...state,
        currentQueue: action.payload,
      };
    case actionTypes.CLEAR_QUEUE:
      return {
        ...state,
        currentQueue: initialState.currentQueue,
      };
    case actionTypes.UPDATE_ORDERS_LIST:
      return {
        ...state,
        ordersList: action.payload,
      };
    case actionTypes.CLEAR_ORDERS_LIST:
      return {
        ...state,
        ordersList: [],
      };
    case actionTypes.CHECK_INVENTORY:
      return {
        ...state,
        inventoryState: action.payload,
      };
    case actionTypes.UPDATE_COUNTS:
      return {
        ...state,
        counts: action.payload,
      };
    default:
      return state;
  }
}
