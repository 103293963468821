import React from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import { fileNameFromUrl } from '@util/stringHelpers';
import Icon from '@sharedComponents/Icons/Icon';

const LogoDropdownItem = React.memo(({
  logo,
  isSelected,
  onClick,
}) => (
  <li
    onClick={onClick.bind(null, logo.id, isSelected)}
    className={`logo-dropdown__option button ${isSelected ? 'is-active' : ''}`}
  >
    {
      isSelected
        ? <Icon materialIcon={'check_box'} />
        : <Icon materialIcon={'check_box_outline_blank'} />
    }
    <div className='logo-dropdown__option--label'>
      {fileNameFromUrl(logo.image)}
    </div>
    <div
      className='logo-dropdown__option--image'
      style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logo.image}")` }}
    />
  </li>
));

LogoDropdownItem.propTypes = {
  logo: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LogoDropdownItem;
