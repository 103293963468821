export const validateDecorationLocationArtworkSize = (values) => {
  const errors = {};

  if (!values.locationId) {
    errors.locationId = ['Location is required.'];
  }

  if (!values.scalingSize || (!values.width && !values.height)) {
    if (!values.width) {
      errors.width = ['Width is required.'];
    }
    if (values.width <= 0) {
      errors.width = ['Width should be a positive number.'];
    }

    if (!values.height) {
      errors.height = ['Height is required.'];
    }
    if (values.height <= 0) {
      errors.height = ['Height should be a positive number.'];
    }
  }

  return errors;
};
