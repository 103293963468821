import React from 'react';
import MultiSelectDropdown from '@sharedComponents/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';

interface OwnProps {
  lockerIds: number[];
  lockersChange: (lockerIds: number[]) => void;
}

type Props = OwnProps;

const LockersMultiSelect = React.memo(({
  lockerIds,
  lockersChange,
}: Props) => (
  <MultiSelectDropdown<number>
    objects={lockerIds}
    itemText={'lockers'}
    updateCallback={lockersChange}
    classNames={'margin-left'}
  />
));

export default LockersMultiSelect;
