import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  VoucherCollectionDetailDto,
  VoucherInstanceDto,
} from '@api/financialServices/models';
import { usePostHomefieldApiOrdermanagementVoucherinstancesOrders as usePostOrdersForVoucherInstances } from '@api/fulfillment/order-management';
import { infoTextTimeout } from '@constants/values';
import { ExpandableGroupItemProps } from '@sharedComponents/Display/Containers/Group/ExpandableGroup';
import { copyToClipboard } from '@util/componentHelper';
import VoucherInstance from './VouchersTabContent/VoucherInstance';

type Props = ExpandableGroupItemProps<VoucherCollectionDetailDto>;

const VouchersTabExpandableGroupItem = (({ item }: Props) => {
  const [
    copyText,
    setCopyText,
  ] = useState<Record<string, string>>({});

  const [
    orderNumbersForVoucherInstances,
    setOrderNumbersForVoucherInstances,
  ] = useState<Record<string, number>>({});

  const voucherInstanceIds: number[] = useMemo(() => (item.voucherInstances!.map((instance) => instance.id!)), [item]);

  const { mutateAsync: fetchOrdersForVoucherInstances } = usePostOrdersForVoucherInstances({
    request: { isBlockingRequest: false },
  });

  const getOrdersForVoucherInstances = useCallback(async () => {
    const response = await fetchOrdersForVoucherInstances({ data: { voucherIds: voucherInstanceIds } });

    if (response.success) {
      setOrderNumbersForVoucherInstances(response?.result ?? {} as Record<string, number>);
    }
  }, [
    voucherInstanceIds,
    fetchOrdersForVoucherInstances,
  ]);

  useEffect(() => {
    if (!voucherInstanceIds?.length) return;

    getOrdersForVoucherInstances();
  }, [
    voucherInstanceIds,
    getOrdersForVoucherInstances,
  ]);

  useEffect(() => {
    if (Object.keys(copyText).length === 0) return;

    // Remove copy text after the amount of time has passed
    setTimeout(() => {
      setCopyText({});
    }, infoTextTimeout);
  }, [copyText]);

  const onCopyToClipboard = useCallback((instanceId: number, e: React.MouseEvent) => {
    copyToClipboard(e, `codeToCopy-${instanceId}`);

    setCopyText({ [instanceId]: 'Copied!' });
  }, []);

  /*
   * Calculate the maximum instance code length to use it for all instance codes
   * (so that copy icons are aligned without using too much space)
   */
  const maxCodeLength: number = Math.max(...item.voucherInstances!.map((instance) => instance.code!.length));

  const renderVoucherInstance = (instance: VoucherInstanceDto) => (
    <VoucherInstance
      key={instance.id!}
      instance={instance}
      orderNumbersForVoucherInstances={orderNumbersForVoucherInstances!}
      onCopyVoucherCode={onCopyToClipboard}
      maxCodeLength={maxCodeLength}
      copyText={copyText}
    />
  );

  return (
    <div>
      <div className='voucherOrders__details__list__roster-row'>{item.collectionName} ({item.rosterTeamName})</div>
      <div>{item.voucherInstances?.map(renderVoucherInstance)}</div>
    </div>
  );
});

export default React.memo(VouchersTabExpandableGroupItem) as typeof VouchersTabExpandableGroupItem;
