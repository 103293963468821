import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parseDateNumeric } from '@util/dateHandler';
import { trackingNumberUrl, trackingNumberLabel } from '@constants/common';
import { updateOrderShipmentData } from '@redux/orderManagement/actions';
import UpdateShipmentModal from './UpdateShipmentModal';
import Icon from '@sharedComponents/Icons/Icon';
import { reset } from 'redux-form';
import { shipmentDataForm } from '@constants/reduxForms';

class OrderManagementShipmentHeader extends Component {
  state = {
    isUpdateShipmentModalOpen: false,
  };

  updateShipmentInformation = async (form) => {
    const {
      order,
      dispatch,
      shipment,
    } = this.props;

    await dispatch(updateOrderShipmentData(
      order.orderNumber,
      form.shipmentNumber,
      form.shipDate,
      form.trackingNumber,
      shipment.orderItemIds
    ));

    dispatch(reset(shipmentDataForm));
    this.closeChangeShipmentModal();
  };

  openChangeShippmentModal = () => {
    this.setState({ isUpdateShipmentModalOpen: true });
  };

  closeChangeShipmentModal = () => {
    this.setState({ isUpdateShipmentModalOpen: false });
  };

  render() {
    const {
      isOrderPartiallyShipped,
      itemsLeftToShipCount,
      shipment,
    } = this.props;

    const { isUpdateShipmentModalOpen } = this.state;

    const headerColorClass = isOrderPartiallyShipped ? 'order__shipment-header--orange' : 'order__shipment-header--green';
    const shipmentTrackingUrl = trackingNumberUrl(shipment.trackingNumber);

    const initialValues = {
      shipmentNumber: shipment.shipmentNumber,
      shipDate: shipment.shipDate,
      trackingNumber: shipment.trackingNumber,
    };

    return (
      <div
        key={shipment.shipmentNumber}
        className={`order__shipment-header ${headerColorClass}`}
      >
        <UpdateShipmentModal
          isOpen={isUpdateShipmentModalOpen}
          closeModal={this.closeChangeShipmentModal}
          onSubmit={this.updateShipmentInformation}
          initialValues={initialValues}
        />
        <div className='order__shipment-header__info--left'>
          {
            isOrderPartiallyShipped
              ? (
                <div className='order__shipment-header__info-item'>
                  <Icon materialIcon={'hourglass_empty'} />
                  <span className='mr-5'>
                  Partially Shipped:
                  </span>
                  <strong>{parseDateNumeric(shipment.shipDate)}</strong>
                </div>
              )
              : (
                <div className='order__shipment-header__info-item'>
                  <Icon materialIcon={'check_circle'} />
                  <span className='mr-5'>
                  Successfully Shipped:
                  </span>
                  <strong>{parseDateNumeric(shipment.shipDate)}</strong>
                </div>
              )
          }

          <div className='border' />
          <div>Shipment: <strong>{shipment.shipmentNumber}</strong></div>
          {
            isOrderPartiallyShipped &&
            <div className='order__shipment-header__info-item'>
              <div className='border' />
              <div>Items left to ship: <strong>{itemsLeftToShipCount}</strong></div>
            </div>
          }
          <div className='border' />
          <div className='order__shipment-header__info-item'>
            <span className='mr-5'>Tracking number:</span>
            <strong>
              {
                !!shipmentTrackingUrl
                  ? (
                    <a
                      className='order__shipment-link'
                      href={shipmentTrackingUrl}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {trackingNumberLabel(shipment.trackingNumber)}
                    </a>
                  )
                  : shipment.trackingNumber
              }
            </strong>
          </div>
        </div>
        <div
          className='order__shipment-header__info--right'
          onClick={this.openChangeShippmentModal}
        >
          <Icon materialIcon={'edit'} />
          <span>Edit</span>
        </div>
      </div>
    );
  }
}

OrderManagementShipmentHeader.propTypes = {
  order: PropTypes.object.isRequired,
  isOrderPartiallyShipped: PropTypes.bool.isRequired,
  itemsLeftToShipCount: PropTypes.number.isRequired,
  shipment: PropTypes.object.isRequired,
};

export default connect()(OrderManagementShipmentHeader);
