import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { TooltipPlacement } from '@customTypes/tooltip';
import ActionsTooltip from '@sharedComponents/Tooltips/ActionsTooltip';
import Icon from '@sharedComponents/Icons/Icon';
import ShowMoreActionsDropdownAction, { ShowMoreActionsDropdownActionData } from './ShowMoreActionsDropdownAction';

interface OwnProps {
  actions: ShowMoreActionsDropdownActionData[];
  closeOnScroll?: boolean;
  scrollTop?: number;
  classes?: string;
  placement?: TooltipPlacement;
  size?: 's';
}

type Props = OwnProps;

const ShowMoreActionsButton = React.memo<Props>(({
  actions,
  closeOnScroll = false,
  scrollTop,
  classes = '',
  placement = 'left-start',
  size = 's',
}) => {
  const [
    actionsDropdownIsActive,
    setActionsDropdownIsActive,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (closeOnScroll) {
      setActionsDropdownIsActive(false);
    }
  }, [
    closeOnScroll,
    scrollTop,
  ]);

  const toggleActionsDropdown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setActionsDropdownIsActive(!actionsDropdownIsActive);
  }, [actionsDropdownIsActive]);

  const collapseActionsDropdown = useCallback(() => {
    setActionsDropdownIsActive(false);
  }, []);

  const handleClickOnAction = useCallback((e: React.MouseEvent, action: (e: React.MouseEvent) => void) => {
    e.preventDefault();
    e.stopPropagation();

    action(e);
    collapseActionsDropdown();
  }, [collapseActionsDropdown]);

  const getAction = (a: ShowMoreActionsDropdownActionData, index: number) => (
    <ShowMoreActionsDropdownAction
      key={index}
      text={a.text}
      action={(e) => handleClickOnAction(e, a.action)}
      isDangerous={a.isDangerous}
      isDisabled={a.isDisabled}
      isVisible={a.isVisible}
      hasBottomBorder={a.hasBottomBorder}
      className={a.className}
      buttonSize={a.buttonSize}
      permissions={a.permissions}
      tooltipText={a.tooltipText}
    />
  );

  const getActions = () => (
    <ul className={`actions-tooltip__actions-list ${classes}`}>
      {actions.map(getAction)}
    </ul>
  );

  if (actions.length === 0 || actions.every((action) => action.isVisible === false)) {
    return null;
  }

  return (
    <div className={`lockerManager__action-button ${size === 's' ? 'size-s' : ''}`}>
      <ActionsTooltip
        actions={getActions()}
        placement={placement}
        isOpen={actionsDropdownIsActive}
        close={collapseActionsDropdown}
      >
        <Icon
          materialIcon={'more_vert'}
          onClick={toggleActionsDropdown}
          classes={'button'}
        />
      </ActionsTooltip>
    </div>
  );
});

export default ShowMoreActionsButton;
