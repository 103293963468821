import React from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

const OneOrMultipleCell = ({
  items,
  multiLabel,
}) => {
  if (!items || !items.length) {
    return null;
  }

  if (items.length === 1) {
    return <span>{items[0]}</span>;
  }

  return (
    <MaterialTooltip
      placement={'top'}
      tooltipText={items.join(', ')}
    >
      <span className='cursor-pointer'>
        {multiLabel}
      </span>
    </MaterialTooltip>
  );
};

OneOrMultipleCell.propTypes = {
  items: PropTypes.array,
  multiLabel: PropTypes.string.isRequired,
};

export default OneOrMultipleCell;
