import React, { useCallback } from 'react';
import { ProgressBar } from 'react-bootstrap';
import {
  OrderWarehouseStatusEnum,
  WarehouseOrderPagedListDto,
  OrderItemStatusEnum,
} from '@api/fulfillment/models';
import { useGetHomefieldApiWarehouseschedulingOrdersOrderNumber } from '@api/fulfillment/warehouse-scheduling';
import { decorationMethodDisplayNames } from '@constants/common';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import Button from '@sharedComponents/Buttons/Button';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';
import Label from '@sharedComponents/Display/Label';
import Link from '@sharedComponents/Navigation/Link';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import WarehouseSchedulingDetailsItem from './WarehouseSchedulingDetailsItem';

type OwnProps = {
  order: WarehouseOrderPagedListDto;
  onClose: () => void;
  onPrintNow: (order: WarehouseOrderPagedListDto) => void;
  onSendToDispatch: (order: WarehouseOrderPagedListDto) => void;
  orderWarehouseStatus: OrderWarehouseStatusEnum;
};

type Props = OwnProps;

const Header = TableQuickViewHeader as React.FC<{ title: any; onClose: any; }>;

export const WarehouseSchedulingDetails = React.memo<Props>(({
  order,
  onClose,
  onPrintNow,
  onSendToDispatch,
  orderWarehouseStatus,
}) => {
  const { data: orderItems } = useGetHomefieldApiWarehouseschedulingOrdersOrderNumber(order.orderNumber!,
    { query: { enabled: true } });

  const clickOnPrintNow = useCallback(() => onPrintNow(order), [
    order,
    onPrintNow,
  ]);

  const clickOnSendToDispatch = useCallback(() => onSendToDispatch(order), [
    order,
    onSendToDispatch,
  ]);

  const schedulingStatuses = [
    OrderItemStatusEnum.OnHold,
    OrderItemStatusEnum.AwaitingDispatchForPicking,
    OrderItemStatusEnum.InPicking,
  ];

  let schedulingStatus: OrderItemStatusEnum | null = null;
  if (orderWarehouseStatus === OrderWarehouseStatusEnum.AwaitingInventory) {
    schedulingStatus = OrderItemStatusEnum.OnHold;
  }
  if (orderWarehouseStatus === OrderWarehouseStatusEnum.AwaitingDispatch) {
    schedulingStatus = OrderItemStatusEnum.AwaitingDispatchForPicking;
  }
  if (orderWarehouseStatus === OrderWarehouseStatusEnum.InPicking) {
    schedulingStatus = OrderItemStatusEnum.InPicking;
  }

  const allItems = (orderItems?.items ?? []).filter(
    (item) => item?.status && schedulingStatuses.includes(item.status)
  );

  const schedulingItems = allItems.filter((item) => item.status === schedulingStatus);
  const secondaryItems = allItems.filter((item) => item.status !== schedulingStatus);

  const itemTotal = allItems.length || order.qtyTotalInScheduling || 0;
  const anyAvailable = orderItems?.items?.some(
    (item) => item?.status === OrderItemStatusEnum.OnHold && !!item?.isInventoryAvailable
  );

  return (
    <TableQuickViewContainer
      noPadding={true}
      size={TableQuickViewSizeEnum.Medium}
      classes={'scheduling__details'}
    >
      <>
        <Header
          title={(
            <>
              <span>
                <Link
                  openInNewTab={true}
                  url={orderDetailsUrl(order.orderNumber!)}
                  text={`Order O${order.orderNumber}`}
                />
              </span>
              {
                order.decorationMethods?.map((d) => (
                  decorationMethodDisplayNames[d] &&
                  <Label
                    key={d}
                    text={decorationMethodDisplayNames[d].short}
                    type={'lynch'}
                  />
                ))
              }
              <LabelsLegendDisplay
                labels={[
                  {
                    isActive: order.hasRequiredItems,
                    text: 'Required',
                    shortText: 'R',
                    type: 'electric-indigo',
                  },
                ]}
              />
            </>
          )}
          onClose={onClose}
        />
        <div className={'tableView__details--content'}>
          {
            !!schedulingItems.length &&
            <div className='scheduling__details--content__items'>
              {
                schedulingItems.map((item) => (
                  <WarehouseSchedulingDetailsItem
                    item={item}
                    key={item.id}
                  />
                ))
              }
              {
                secondaryItems.map((item) => (
                  <WarehouseSchedulingDetailsItem
                    item={item}
                    key={item.id}
                    isSecondary={true}
                  />
                ))
              }
            </div>
          }
          <div className='tableView__details--footer'>
            <div className='scheduling__progress scheduling__progress--available'>
              {
                orderWarehouseStatus === OrderWarehouseStatusEnum.AwaitingInventory
                  ? (
                    <>
                      <div className=' mr-15'>
                        {order.qtyAvailableAwaitingInventory}/{order.qtyWarehouseStatus}({itemTotal})
                      </div>
                      <ProgressBar
                        className='scheduling__progress-bar big'
                        variant={
                          order.qtyWarehouseStatus !== 0
                          && order.qtyAvailableAwaitingInventory === order.qtyWarehouseStatus
                            ? 'success'
                            : 'warning'
                        }
                        now={(order.qtyWarehouseStatus || 0) <= 0
                          ? 0
                          : (order.qtyAvailableAwaitingInventory ?? 0) / (order.qtyWarehouseStatus || 1) * 100}
                      />
                    </>
                  )
                  : (
                    <>
                      <div className=' mr-15'>
                        {order.qtyWarehouseStatus}/{itemTotal}
                      </div>
                      <ProgressBar
                        className='scheduling__progress-bar big'
                        variant={
                          itemTotal !== 0 && order.qtyWarehouseStatus === itemTotal
                            ? 'success'
                            : 'warning'
                        }
                        now={(itemTotal || 0) <= 0
                          ? 0
                          : (order.qtyWarehouseStatus ?? 0) / (itemTotal || 1) * 100}
                      />
                    </>
                  )
              }
            </div>
            {
              orderWarehouseStatus === OrderWarehouseStatusEnum.AwaitingInventory &&
              anyAvailable &&
              <Button
                type={'primary'}
                text={'Disptach Available Items'}
                onClick={clickOnSendToDispatch}
                disabled={!order?.qtyWarehouseStatus}
              />
            }
            {
              orderWarehouseStatus === OrderWarehouseStatusEnum.AwaitingDispatch &&
              <Button
                type={'primary'}
                text={'Dispatch Now'}
                onClick={clickOnPrintNow}
                disabled={!order?.qtyWarehouseStatus}
              />
            }
          </div>
        </div>
      </>
    </TableQuickViewContainer>
  );
});

export default WarehouseSchedulingDetails;
