import { suggestionRowCategories } from '@constants/values';

export const calculateDropdownHeight = (rowHeight) => {
  const maxRows = findSuggestionRowCategory(rowHeight);

  return rowHeight * maxRows;
};

export const findSuggestionRowCategory = (rowHeight) => {
  const category = suggestionRowCategories.find((x) => rowHeight > x.sizeMin && rowHeight <= x.sizeMax);

  return category.maxRows;
};
