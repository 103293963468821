import { catalogApi } from '../../sqdApis';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import {
  mapVendorErrors,
  mapVendorTimelineErrors,
} from '@util/componentHelpers/vendorsHelper';

export const addVendor = async (addVendorForm) => {
  const call = catalogApi.post(productCatalogUtilityUrls.vendors, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(addVendorForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapVendorErrors);

  return res;
};

export const editVendor = async (editVendorForm) => {
  const call = catalogApi.put(productCatalogUtilityUrls.vendor(editVendorForm.code), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(editVendorForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapVendorErrors);

  return res;
};

export const deleteVendor = async (code) => {
  const call = catalogApi.delete(productCatalogUtilityUrls.vendor(code));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const editVendorTimelines = async (vendorCode, vendorTimelines) => {
  const call = catalogApi.put(productCatalogUtilityUrls.vendorTimelines(vendorCode), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ vendorTimelines }),
  });

  const res = await makeApiCallWithSubmissionError(call, mapVendorTimelineErrors);

  return res;
};
