import { HagPrintRoomOrderAssemblyPagedListDto } from '@api/fulfillment/models';
import Button from '@components/shared/Buttons/Button';
import React, { useCallback } from 'react';

interface OwnProps {
  hagPrintRoomJobId: number | undefined;
  onClick: (hagPrintRoomJobId: number | undefined) => void;
  disabled: boolean | undefined;
}

type Props = OwnProps;

const FinalizeButton = React.memo<Props>(({
  hagPrintRoomJobId,
  onClick,
  disabled,
}) => {
  const handleClickEvent = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick(hagPrintRoomJobId);
  }, [
    hagPrintRoomJobId,
    onClick,
  ]);

  return (
    <Button
      type={'primary'}
      onClick={handleClickEvent}
      classes='button button--color-gossamer button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase'
      disabled={disabled}
      text='Finalize'
    />
  );
});

export default FinalizeButton;
