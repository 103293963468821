import React from 'react';
import { RosterTeamViewModel } from '@api/squadlockerServices/models';
import {
  lockerDetailsUrl,
  organizationDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import { parseDateNumeric } from '@util/dateHandler';
import Link from '@sharedComponents/Navigation/Link';
import SeeMoreButton from '@sharedComponents/Buttons/SeeMoreButton';
import InfoField from '@sharedComponents/Display/InfoField';

interface OwnProps {
  roster: RosterTeamViewModel;
  rosterMembersWithRequiredItemsCount: number;
  rosterMembersTotalCount: number;
  openCollectionDetailsModal: () => void;
}

type Props = OwnProps;

const HeaderDetails = React.memo<Props>(({
  roster,
  rosterMembersWithRequiredItemsCount,
  rosterMembersTotalCount,
  openCollectionDetailsModal,
}) => (
  <div className='lockerManagerEdit__details-container'>
    <div className='flex w-100 mr-40'>
      <div className='lockerManagerEdit__details-column--l mr-50'>
        <InfoField
          label={'Locker Name'}
          value={
            (roster.lockerId && roster.lockerTeamName) &&
            <Link
              url={lockerDetailsUrl(roster.lockerId)}
              text={`L${roster.lockerId} - ${roster.lockerTeamName}`}
            />
          }
        />
        <div className='flex'>
          <InfoField
            label={'Locker Manager'}
            value={roster.lockerManagerEmail}
          />
        </div>
      </div>
      <div className='lockerManagerEdit__details-column--l ml-50 mr-50'>
        <InfoField
          label={'Organization Name'}
          value={
            roster.organizationId &&
            <Link
              url={organizationDetailsUrl(roster.organizationId)}
              text={`ORG${roster.organizationId} - ${roster.organizationName}`}
            />
          }
        />
        <InfoField
          label={'Ordering Deadline'}
          value={roster.orderingDeadline ? parseDateNumeric(roster.orderingDeadline) : ''}
        />
      </div>
      <div className='lockerManagerEdit__details-column--l ml-50'>
        <InfoField
          label={'Members With Required Item Orders'}
          value={(
            <div className='align__center'>
              <span
                className={`roster__items-ordered-count${(rosterMembersTotalCount !== 0 && rosterMembersWithRequiredItemsCount === rosterMembersTotalCount) ? '--all' : ''}`}
              >
                {rosterMembersWithRequiredItemsCount}/{rosterMembersTotalCount}
              </span>
              {
                roster.collectionId &&
                <SeeMoreButton
                  text={'View Associated Collection'}
                  onClick={openCollectionDetailsModal}
                />
              }
            </div>
          )}
        />
      </div>
    </div>
  </div>
));

export default HeaderDetails;
