import React from 'react';
import { categoriesUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const CategoriesLink = React.memo(() => (
  <MenuTile
    title={'Categories'}
    url={categoriesUrl}
    icon={'storage'}
  />
));

export default CategoriesLink;
