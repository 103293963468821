import React from 'react';

interface OwnProps {
  masterComponent: JSX.Element;
  detailComponent: JSX.Element;
  showDetailComponent: boolean;
}

type Props = OwnProps;

const MasterDetailContainer = React.memo<Props>(({
  masterComponent,
  detailComponent,
  showDetailComponent,
}) => (
  <div className='master-detail'>
    <div className='w-100'>
      {masterComponent}
    </div>
    {showDetailComponent && detailComponent}
  </div>
));

export default MasterDetailContainer;
