import React from 'react';
import { bulkAddStyleUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const BulkAddStyleLink = React.memo(() => (
  <MenuTile
    title={'Add Style to All Stores'}
    url={bulkAddStyleUrl}
    isMaterialIcon={false}
    icon={'fa-solid fa-cart-plus'}
  />
));

export default BulkAddStyleLink;
