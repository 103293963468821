/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type OrderItemStatusEnum = 'Initial' | 'OnHold' | 'AwaitingDispatchForPicking' | 'InPicking' | 'Picked' | 'CheckedIn' | 'Assembled' | 'PreTreated' | 'ProductionCompleted' | 'Waterproofed' | 'QualityChecked' | 'Shipped';


export const OrderItemStatusEnum = {
  Initial: 'Initial' as OrderItemStatusEnum,
  OnHold: 'OnHold' as OrderItemStatusEnum,
  AwaitingDispatchForPicking: 'AwaitingDispatchForPicking' as OrderItemStatusEnum,
  InPicking: 'InPicking' as OrderItemStatusEnum,
  Picked: 'Picked' as OrderItemStatusEnum,
  CheckedIn: 'CheckedIn' as OrderItemStatusEnum,
  Assembled: 'Assembled' as OrderItemStatusEnum,
  PreTreated: 'PreTreated' as OrderItemStatusEnum,
  ProductionCompleted: 'ProductionCompleted' as OrderItemStatusEnum,
  Waterproofed: 'Waterproofed' as OrderItemStatusEnum,
  QualityChecked: 'QualityChecked' as OrderItemStatusEnum,
  Shipped: 'Shipped' as OrderItemStatusEnum,
};
