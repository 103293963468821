import { HagPrintRoomJobPagedListDto } from '@api/fulfillment/models';
import React, { useCallback } from 'react';
import Button from '@sharedComponents/Buttons/Button';

interface OwnProps {
  items: HagPrintRoomJobPagedListDto[];
  onClick: (items: HagPrintRoomJobPagedListDto[]) => void;
  enabled: boolean;
}

type Props = OwnProps;

const ExportRecentlyPrintedButton = React.memo<Props>(({
  items,
  onClick,
  enabled,
}) => {
  const update = useCallback((e) => {
    e.preventDefault();
    onClick(items);
  }, [
    items,
    onClick,
  ]);

  return (
    <Button
      onClick={update}
      disabled={!enabled}
      text={'Export List'}
      type={'primary'}
    />
  );
});

export default ExportRecentlyPrintedButton;
