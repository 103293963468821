import React from 'react';
import { serviceManagerUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const ServiceManagerLink = React.memo(() => (
  <MenuTile
    title={'Service Manager'}
    url={serviceManagerUrl}
    icon={'settings_input_component'}
  />
));

export default ServiceManagerLink;
