import React from 'react';
import { editVoucherOrderForm } from '@constants/reduxForms';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import VoucherNotificationSettingsForm from '../Forms/VoucherNotificationSettingsForm';
import { VoucherNotificationSettingsFormData } from '@models/forms/Vouchers/VoucherNotificationSettingsFormData';
import { VoucherOrderDetailDto } from '@api/financialServices/models';

interface OwnProps {
  voucherOrder?: VoucherOrderDetailDto;
  isOpen: boolean;
  addVoucherNotificationSettings: (form: VoucherNotificationSettingsFormData) => Promise<void>;
  closeModal: () => void;
}

type Props = OwnProps;

const VoucherNotificationSettingsModal = React.memo<Props>(({
  voucherOrder,
  isOpen,
  addVoucherNotificationSettings,
  closeModal,
}) => {
  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      modalHeight={'xl'}
      modalWidth={'m'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Save'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={editVoucherOrderForm}
        />
      )}
    >
      <div className='pb-200'>
        <VoucherNotificationSettingsForm
          organizationId={voucherOrder?.organizationId}
          onSubmit={addVoucherNotificationSettings}
        />
      </div>
    </Modal>
  );
});

export default VoucherNotificationSettingsModal;
