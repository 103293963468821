import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { DecorationMethodEnum } from '@api/productCatalog/models';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: any;
  decorationMethod: DecorationMethodEnum;
}

type Props = OwnProps;

const OrderManagementChangeDecorationMethodModal = React.memo(({
  isOpen,
  closeModal,
  onSubmit,
  decorationMethod,
}: Props) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    modalSize={'m'}
    title={'Change Decoration Method'}
    buttons={(
      <ModalButtons
        displayConfirmBtn={true}
        confirmBtnText={`Change to ${decorationMethod}`}
        cancelBtnText={'Cancel'}
        onConfirm={onSubmit}
      />
    )}
  >
    <div>
      <p>
          The decoration method will be changed to {decorationMethod} once this action is completed,
          if you wish to continue click "CHANGE TO {decorationMethod}" otherwise "CANCEL".
      </p>
    </div>
  </Modal>
));

export default OrderManagementChangeDecorationMethodModal;
