import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { numberRangeFilterDelay } from '@constants/values';

class NumberRangeFilter extends Component {
  state = {
    minValue: this.props.initialMinValue,
    maxValue: this.props.initialMaxValue,
    throttledCall: false,
  };

  componentDidUpdate(prevProps) {
    const {
      initialMinValue,
      initialMaxValue,
    } = this.props;

    if (prevProps.initialMinValue !== initialMinValue && initialMinValue < initialMaxValue) {
      this.setState({ minValue: initialMinValue });
    }
    if (prevProps.initialMaxValue !== initialMaxValue && initialMaxValue > initialMinValue) {
      this.setState({ maxValue: initialMaxValue });
    }
  }

  refresh = () => {
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    const newThrottle = setTimeout(this.callSearch, numberRangeFilterDelay);

    this.setState({ throttledCall: newThrottle });
  };

  callSearch = () => {
    const {
      throttledCall,
      minValue,
      maxValue,
    } = this.state;

    const { searchValues } = this.props;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    searchValues(minValue, maxValue);

    this.setState({ throttledCall: null });
  };

  updateMinValue = (e) => {
    const minValue = e.target.value;
    this.setState({ minValue });
  };

  updateMaxValue = (e) => {
    const maxValue = e.target.value;
    this.setState(() => ({ maxValue }));
  };

  render() {
    const {
      filterText,
      minValuePlaceholder,
      maxValuePlaceholder,
    } = this.props;

    const {
      minValue,
      maxValue,
    } = this.state;

    return (
      <div className='range-filter__container'>
        <div className='range-filter__text'>
          {filterText || ''}
        </div>
        <div className='range-filter__input'>
          <div className='text-field'>
            <input
              type='number'
              placeholder={minValuePlaceholder || 'From'}
              onChange={this.updateMinValue}
              onKeyDown={this.refresh}
              value={minValue || ''}
            />
          </div>
        </div>
        <div className='range-filter__separator'>-</div>
        <div className='range-filter__input'>
          <div className='text-field'>
            <input
              type='number'
              placeholder={maxValuePlaceholder || 'To'}
              onChange={this.updateMaxValue}
              onKeyDown={this.refresh}
              value={maxValue || ''}
            />
          </div>
        </div>
      </div>
    );
  }
}

NumberRangeFilter.propTypes = {
  filterText: PropTypes.string,
  initialMinValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  initialMaxValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  searchValues: PropTypes.func.isRequired,
  minValuePlaceholder: PropTypes.string,
  maxValuePlaceholder: PropTypes.string,
};

export default NumberRangeFilter;
