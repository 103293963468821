import React from 'react';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';

interface OwnProps{
  orderNumber: number;
}

type Props = OwnProps;

const OrderManagementQuickViewActions = React.memo<Props>(({ orderNumber }) => (
  <div className='orderManagement__details-actions align__center'>
    <ButtonLink
      type={'primary'}
      text={'Manage Order'}
      linkTo={orderDetailsUrl(orderNumber)}
      linkClasses={'w-100'}
      classes={'w-100'}
    />
  </div>
));

export default OrderManagementQuickViewActions;
