import { isNumber } from '@util/valueHelpers';

export const validateVendor = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = ['Code is required.'];
  }

  if (!values.name) {
    errors.name = ['Name is required.'];
  }

  if (values.leadTime) {
    if (!isNumber(values.leadTime)) {
      errors.leadTime = ['Lead Time must be a number.'];
    } else if (values.leadTime <= 0) {
      errors.leadTime = ['Lead Time must be a positive number greater than 0.'];
    }
  }

  if (values.vfiOrderAutoSubmit) {
    if (values.vfiOrderTreshold <= 0) {
      errors.vfiOrderTreshold = ['Treshold must be greater than 0 if auto submission is enabled.'];
    }

    if (values.vfiOrderTimeHours <= 0) {
      errors.vfiOrderTimeHours = ['Time must be greater than 0 if auto submission is enabled.'];
    }
  }

  return errors;
};
