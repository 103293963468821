/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  OrderDetailsDto,
  ApiResponse,
  GetHomefieldApiOrdermanagementOrdersgetdetailsParams,
  OrderPricingDetailsDtoApiResponse,
  GetHomefieldApiOrdermanagementOrdersgetorderpricingdetailsParams,
  IDtoApiResponse,
  PlaceHomefieldOrderCommand,
  SpreadsheetItemDto,
  StringStringIDictionaryApiResponse,
  GetHomefieldApiOrdermanagementOrderitemsParams,
  OrderPricingDetailsApiResponse,
  CalculateOrderPricingDetailsQuery,
  UpdateOrderShippingInfoCommand,
  RefundOrderCommand,
  CalculateUpdateShippingInfoPriceChangeQuery,
  SubmitOrderToAccountingCommand,
  OrderItemPricingDetailsApiResponse,
  CalculatePriceChangeForOrderItemReplaceQuery,
  RefundOrderItemCommand,
  CalculateAddPersonalizationPriceQuery,
  CalculateEditPersonalizationPriceQuery,
  CalculateRemovePersonalizationPriceQuery,
  ApplyCouponCommand,
  CalculateApplyCouponPriceChangeQuery,
  ValidateAddressQuery,
  OrderChangeDto,
  GetHomefieldApiOrdermanagementOrdersgetchangesParams
} from './models'
import { ordersApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiOrdermanagementOrdersgetdetails = <TData = OrderDetailsDto>(
    params?: GetHomefieldApiOrdermanagementOrdersgetdetailsParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.get-details`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersgetdetailsQueryKey = (params?: GetHomefieldApiOrdermanagementOrdersgetdetailsParams,) => [`/homefield/api/ordermanagement/orders.get-details`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrdermanagementOrdersgetdetails = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersgetdetails, OrderDetailsDto>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiOrdermanagementOrdersgetdetailsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersgetdetailsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersgetdetails<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiOrdermanagementOrdersgetorderpricingdetails = <TData = OrderPricingDetailsDtoApiResponse>(
    params?: GetHomefieldApiOrdermanagementOrdersgetorderpricingdetailsParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.get-order-pricing-details`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersgetorderpricingdetailsQueryKey = (params?: GetHomefieldApiOrdermanagementOrdersgetorderpricingdetailsParams,) => [`/homefield/api/ordermanagement/orders.get-order-pricing-details`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrdermanagementOrdersgetorderpricingdetails = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersgetorderpricingdetails, OrderPricingDetailsDtoApiResponse>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiOrdermanagementOrdersgetorderpricingdetailsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersgetorderpricingdetailsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersgetorderpricingdetails<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiOrdermanagementOrdersplace = <TData = IDtoApiResponse>(
    placeHomefieldOrderCommand: PlaceHomefieldOrderCommand,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.place`, method: 'post',
      data: placeHomefieldOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersplace = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersplace,IDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PlaceHomefieldOrderCommand}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PlaceHomefieldOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersplace<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiOrdermanagementOrderitems = <TData = SpreadsheetItemDto[]>(
    params?: GetHomefieldApiOrdermanagementOrderitemsParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/order-items`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrderitemsQueryKey = (params?: GetHomefieldApiOrdermanagementOrderitemsParams,) => [`/homefield/api/ordermanagement/order-items`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrdermanagementOrderitems = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrderitems, SpreadsheetItemDto[]>, TError = StringStringIDictionaryApiResponse | ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiOrdermanagementOrderitemsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrderitemsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrderitems<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiOrdermanagementOrderscalculatepricing = <TData = OrderPricingDetailsApiResponse>(
    calculateOrderPricingDetailsQuery: CalculateOrderPricingDetailsQuery,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.calculate-pricing`, method: 'put',
      data: calculateOrderPricingDetailsQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrderscalculatepricing = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrderscalculatepricing,OrderPricingDetailsApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculateOrderPricingDetailsQuery}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculateOrderPricingDetailsQuery}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrderscalculatepricing<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrdersshippinginfo = <TData = OrderPricingDetailsApiResponse>(
    updateOrderShippingInfoCommand: UpdateOrderShippingInfoCommand,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.shipping-info`, method: 'put',
      data: updateOrderShippingInfoCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersshippinginfo = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersshippinginfo,OrderPricingDetailsApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateOrderShippingInfoCommand}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdateOrderShippingInfoCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrdersshippinginfo<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersrefundwithcancel = <TData = ApiResponse>(
    refundOrderCommand: RefundOrderCommand,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.refund-with-cancel`, method: 'post',
      data: refundOrderCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersrefundwithcancel = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersrefundwithcancel,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RefundOrderCommand}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: RefundOrderCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersrefundwithcancel<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrdersshippinginfopricecalculation = <TData = ApiResponse>(
    calculateUpdateShippingInfoPriceChangeQuery: CalculateUpdateShippingInfoPriceChangeQuery,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.shipping-info-price-calculation`, method: 'put',
      data: calculateUpdateShippingInfoPriceChangeQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrdersshippinginfopricecalculation = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrdersshippinginfopricecalculation,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculateUpdateShippingInfoPriceChangeQuery}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculateUpdateShippingInfoPriceChangeQuery}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrdersshippinginfopricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderssubmittoaccounting = <TData = ApiResponse>(
    submitOrderToAccountingCommand: SubmitOrderToAccountingCommand,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.submit-to-accounting`, method: 'post',
      data: submitOrderToAccountingCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderssubmittoaccounting = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderssubmittoaccounting,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SubmitOrderToAccountingCommand}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: SubmitOrderToAccountingCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderssubmittoaccounting<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrderitemsreplacepricecalculation = <TData = OrderItemPricingDetailsApiResponse>(
    calculatePriceChangeForOrderItemReplaceQuery: CalculatePriceChangeForOrderItemReplaceQuery,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/order-items.replace-price-calculation`, method: 'put',
      data: calculatePriceChangeForOrderItemReplaceQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrderitemsreplacepricecalculation = <TData = AsyncReturnType<typeof putHomefieldApiOrderitemsreplacepricecalculation,OrderItemPricingDetailsApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculatePriceChangeForOrderItemReplaceQuery}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculatePriceChangeForOrderItemReplaceQuery}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrderitemsreplacepricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrdersitemsrefund = <TData = ApiResponse>(
    refundOrderItemCommand: RefundOrderItemCommand,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders-items.refund`, method: 'post',
      data: refundOrderItemCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrdersitemsrefund = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrdersitemsrefund,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: RefundOrderItemCommand}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: RefundOrderItemCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrdersitemsrefund<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrderitemscalculateaddpersonalization = <TData = OrderItemPricingDetailsApiResponse>(
    calculateAddPersonalizationPriceQuery: CalculateAddPersonalizationPriceQuery,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/order-items.calculate-add-personalization`, method: 'put',
      data: calculateAddPersonalizationPriceQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrderitemscalculateaddpersonalization = <TData = AsyncReturnType<typeof putHomefieldApiOrderitemscalculateaddpersonalization,OrderItemPricingDetailsApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculateAddPersonalizationPriceQuery}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculateAddPersonalizationPriceQuery}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrderitemscalculateaddpersonalization<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrderitemscalculateeditpersonalization = <TData = OrderItemPricingDetailsApiResponse>(
    calculateEditPersonalizationPriceQuery: CalculateEditPersonalizationPriceQuery,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/order-items.calculate-edit-personalization`, method: 'put',
      data: calculateEditPersonalizationPriceQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrderitemscalculateeditpersonalization = <TData = AsyncReturnType<typeof putHomefieldApiOrderitemscalculateeditpersonalization,OrderItemPricingDetailsApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculateEditPersonalizationPriceQuery}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculateEditPersonalizationPriceQuery}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrderitemscalculateeditpersonalization<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrderitemscalculateremovepersonalization = <TData = OrderItemPricingDetailsApiResponse>(
    calculateRemovePersonalizationPriceQuery: CalculateRemovePersonalizationPriceQuery,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/order-items.calculate-remove-personalization`, method: 'put',
      data: calculateRemovePersonalizationPriceQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrderitemscalculateremovepersonalization = <TData = AsyncReturnType<typeof putHomefieldApiOrderitemscalculateremovepersonalization,OrderItemPricingDetailsApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculateRemovePersonalizationPriceQuery}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculateRemovePersonalizationPriceQuery}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrderitemscalculateremovepersonalization<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrdermanagementOrderscouponapply = <TData = ApiResponse>(
    applyCouponCommand: ApplyCouponCommand,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.coupon-apply`, method: 'post',
      data: applyCouponCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrdermanagementOrderscouponapply = <TData = AsyncReturnType<typeof postHomefieldApiOrdermanagementOrderscouponapply,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ApplyCouponCommand}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ApplyCouponCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrdermanagementOrderscouponapply<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdermanagementOrderscouponapplypricecalculation = <TData = OrderPricingDetailsDtoApiResponse>(
    calculateApplyCouponPriceChangeQuery: CalculateApplyCouponPriceChangeQuery,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.coupon-apply-price-calculation`, method: 'put',
      data: calculateApplyCouponPriceChangeQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdermanagementOrderscouponapplypricecalculation = <TData = AsyncReturnType<typeof putHomefieldApiOrdermanagementOrderscouponapplypricecalculation,OrderPricingDetailsDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CalculateApplyCouponPriceChangeQuery}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CalculateApplyCouponPriceChangeQuery}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdermanagementOrderscouponapplypricecalculation<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiOrdersvalidateaddress = <TData = ApiResponse>(
    validateAddressQuery: ValidateAddressQuery,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/orders.validate-address`, method: 'put',
      data: validateAddressQuery
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrdersvalidateaddress = <TData = AsyncReturnType<typeof putHomefieldApiOrdersvalidateaddress,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ValidateAddressQuery}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ValidateAddressQuery}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrdersvalidateaddress<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiOrdermanagementOrdersgetchanges = <TData = OrderChangeDto[]>(
    params?: GetHomefieldApiOrdermanagementOrdersgetchangesParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/ordermanagement/orders.get-changes`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrdermanagementOrdersgetchangesQueryKey = (params?: GetHomefieldApiOrdermanagementOrdersgetchangesParams,) => [`/homefield/api/ordermanagement/orders.get-changes`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrdermanagementOrdersgetchanges = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrdermanagementOrdersgetchanges, OrderChangeDto[]>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiOrdermanagementOrdersgetchangesParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof ordersApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrdermanagementOrdersgetchangesQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrdermanagementOrdersgetchanges<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

