import React, { PureComponent } from 'react';
import {
  Link,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import logo from '@assets/layout/header/squadlocker.png';
import { printSheetLabelsUrl } from '@constants/clientUrls/clientUrls';
import Image from '@sharedComponents/Display/Image';
import Icon from '@sharedComponents/Icons/Icon';
import HeaderContents from './HeaderContents';

const headerlessRoutes = [printSheetLabelsUrl];

type Props = RouteComponentProps;

interface State {
  rendered: boolean;
}

class Header extends PureComponent<Props> {
  state: State = {
    rendered: true,
  };

  componentDidUpdate(): void {
    const { location } = this.props;

    for (const route of headerlessRoutes) {
      if (location?.pathname.includes(route)) {
        this.setState({ rendered: false });
      } else {
        this.setState(() => ({ rendered: true }));
      }
    }
  }

  render(): JSX.Element | null {
    const { location } = this.props;
    const { rendered } = this.state;

    if (rendered) {
      return (
        <header
          className='header'
          data-toggle='drawer-off'
        >
          <div className='header__bar'>
            <button
              type='button'
              className='header__bar-menu'
              data-toggle='body'
            >
              <Icon materialIcon={'menu'} />
            </button>
            <h1 className='header__bar-logo-container'>
              <Link to='/'>
                <Image
                  url={logo}
                  classes={'header__bar-logo'}
                />
              </Link>
            </h1>
            <HeaderContents
              location={location?.pathname}
            />
          </div>
        </header>
      );
    }

    return null;
  }
}

export default withRouter(Header);
