import React, { useCallback } from 'react';
import { PriorityQueueDto } from '@api/fulfillment/models';
import { printStatusEnum } from '@constants/enums/printRoomEnums';

interface OwnProps {
  id: number;
  status: PriorityQueueDto['status'];
  markPriorityItemInPrint: (id: number) => void;
  markPriorityItemDone: (id: number) => void;
}

type Props = OwnProps;

const UpdatePriorityItemStatus = React.memo<Props>(({
  id,
  status,
  markPriorityItemInPrint,
  markPriorityItemDone,
}) => {
  const updateStatus = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (status?.status === printStatusEnum.PrepressReady) {
      markPriorityItemInPrint(id);
    } else if (status?.status === printStatusEnum.Printing) {
      markPriorityItemDone(id);
    }
  }, [
    markPriorityItemInPrint,
    markPriorityItemDone,
    status,
    id,
  ]);

  return (
    <div>
      {
        status?.status === printStatusEnum.PrepressReady
          ? (
            <button
              onClick={updateStatus}
              className='button button--color-gossamer button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase'
            >
              Mark in Print
            </button>
          )
          : (
            <div className='priority__done'>
              <span>In Printing...</span>
              <button
                onClick={updateStatus}
                className='priority-button__done'
              >
                Done
              </button>
            </div>
          )
      }
    </div>
  );
});

export default UpdatePriorityItemStatus;
