import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Chip extends PureComponent {
  handleClick = (e) => {
    const { onClick } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  render() {
    const {
      text,
      hasIcon,
      icon,
      classes,
    } = this.props;

    if (!hasIcon) {
      return (
        <div
          className={`chip ${classes}`}
          onClick={this.handleClick}
        >
          {text}
        </div>
      );
    }

    const defaultIcon = (
      <div
        className='cancel-icon'
        onClick={this.handleClick}
      />
    );

    return (
      <div className={`chip has-icon--right ${classes}`}>
        <div
          className='chip--body'
          onClick={this.handleClick}
        >
          <span className='chip--text'>{text}</span>
          {icon || defaultIcon}
        </div>
      </div>
    );
  }
}

Chip.defaultProps = {
  onClick: null,
  hasIcon: false,
  icon: null,
  classes: '',
};

Chip.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
  icon: PropTypes.element,
  classes: PropTypes.string,
};

export default Chip;
