import React, {
  useCallback, useState,
} from 'react';
import { usePostHomefieldApiHagTransmissionssendprintqueue as useHagSendPrintQueuePOST } from '@api/fulfillment/hag';
import DtfPrinterModal from '../../PrintRoom/PrintRoomTableContent/DtfPrinterModal';
import DtfPrinterButton from '../../PrintRoom/PrintRoomTableContent/DtfPrinterButton';
import { materialSwal } from '@util/componentHelper';

interface OwnProps {
  title: string;
  sheetId: number;
  enabled: boolean;
}

type Props = OwnProps;

const PrintSheetFileDtfPrint = React.memo<Props>(({
  title, sheetId, enabled,
}) => {
  const [
    isOpen,
    setIsOpen,
  ] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const { mutateAsync: dtfSendPrintQueue } = useHagSendPrintQueuePOST();
  const handleSendToPrint = useCallback(async (printQueueId: number, printerNumber: number) => {
    const res = await dtfSendPrintQueue({
      data: {
        printQueueId,
        printerNumber,
      },
    });
    if (res?.success) {
      materialSwal('Success', `Print Sheet ${printQueueId} sent to Printer ${printerNumber}. ${res.message}`, 'success');
    } else {
      materialSwal('Failure', `Print Sheet ${printQueueId} failed to send. ${res?.message}`, 'warning');
    }
  }, [dtfSendPrintQueue]);

  if (!enabled) return <div />;

  return (
    <div
      // Ignores this element for printed page
      data-html2canvas-ignore={true}
      className='sheet__files-item item-quantity-card'
    >
      <div className='sheet__files-title'>
        <div className='sheet__files-title-left flex'>
          {title}
        </div>
      </div>
      <ul className='sheet__files-list'>
        <li>
          <DtfPrinterButton
            id={sheetId}
            onClick={openModal}
            enabled={enabled}
          />
        </li>
      </ul>
      <DtfPrinterModal
        title={title}
        jobId={sheetId}
        formId={`dtfPrintModal-${sheetId}`}
        isOpen={isOpen}
        onClose={closeModal}
        onPrint={handleSendToPrint}
      />
    </div>
  );
});

export default PrintSheetFileDtfPrint;
