/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  LockerLogoDto,
  QueriesLockerDto,
  ApiResponse,
  UpdateLockerCommand,
  OrganizationTotalsDto,
  GetHomefieldApiLockersParams
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiLockersLockerIdLogos = <TData = LockerLogoDto[]>(
    lockerId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/lockers/${lockerId}/logos`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiLockersLockerIdLogosQueryKey = (lockerId: number,) => [`/homefield/api/lockers/${lockerId}/logos`]

    
export const useGetHomefieldApiLockersLockerIdLogos = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiLockersLockerIdLogos, LockerLogoDto[]>, TError = unknown, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiLockersLockerIdLogosQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiLockersLockerIdLogos<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiLockersLockerId = <TData = QueriesLockerDto>(
    lockerId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/lockers/${lockerId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiLockersLockerIdQueryKey = (lockerId: number,) => [`/homefield/api/lockers/${lockerId}`]

    
export const useGetHomefieldApiLockersLockerId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiLockersLockerId, QueriesLockerDto>, TError = unknown, TData = TQueryFnData>(
 lockerId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiLockersLockerIdQueryKey(lockerId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiLockersLockerId<TQueryFnData>(lockerId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiLockersLockerId = <TData = ApiResponse>(
    lockerId: number,
    updateLockerCommand: UpdateLockerCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/lockers/${lockerId}`, method: 'put',
      data: updateLockerCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiLockersLockerId = <TData = AsyncReturnType<typeof putHomefieldApiLockersLockerId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{lockerId: number;data: UpdateLockerCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {lockerId: number;data: UpdateLockerCommand}, TContext>((props) => {
        const {lockerId,data} = props || {};

        return  putHomefieldApiLockersLockerId<TData>(lockerId,data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiLockersOrganizationsOrganizationIdTotals = <TData = OrganizationTotalsDto>(
    organizationId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/lockers/organizations/${organizationId}/totals`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiLockersOrganizationsOrganizationIdTotalsQueryKey = (organizationId: number,) => [`/homefield/api/lockers/organizations/${organizationId}/totals`]

    
export const useGetHomefieldApiLockersOrganizationsOrganizationIdTotals = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiLockersOrganizationsOrganizationIdTotals, OrganizationTotalsDto>, TError = unknown, TData = TQueryFnData>(
 organizationId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiLockersOrganizationsOrganizationIdTotalsQueryKey(organizationId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiLockersOrganizationsOrganizationIdTotals<TQueryFnData>(organizationId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiLockers = <TData = QueriesLockerDto[]>(
    params?: GetHomefieldApiLockersParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/lockers`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiLockersQueryKey = (params?: GetHomefieldApiLockersParams,) => [`/homefield/api/lockers`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiLockers = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiLockers, QueriesLockerDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiLockersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiLockersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiLockers<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

