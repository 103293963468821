import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { uploadFile } from '@APICalls/support/actions';
import { uploadFile as uploadFileUrl } from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { catalogApi } from '../../sqdApis';
import { dragAndDropMessage } from '@constants/values';
import Input from '@sharedComponents/Form/Input';
import UploadImage from '@sharedComponents/Upload/UploadImage';
import Button from '@sharedComponents/Buttons/Button';
import { brandForm } from '@constants/reduxForms';

class BrandForm extends Component {
  state = {
    uploadImageIsActive: false,
    message: {
      type: 'default',
      body: dragAndDropMessage('Image'),
    },
    imageUrl: this.props.initialValues ? this.props.initialValues.imageUrl : null,
  };

  openUploadImageContainer = () => {
    this.setState({ uploadImageIsActive: true });
  };

  changeImage = (url, message, image) => {
    const { change } = this.props;

    this.setState({
      message,
    }, async () => {
      if (image) {
        const res = await uploadFile(image, catalogApi, uploadFileUrl);
        if (res) {
          change('imageUrl', res);
        }
      } else {
        change('imageUrl', null);
      }
      this.setState({ imageUrl: url });
    });
  };

  render() {
    const { onSubmit } = this.props;

    const {
      uploadImageIsActive,
      message,
      imageUrl,
    } = this.state;

    const imageMaxWidth = 150;

    return (
      <form
        className='redux-form'
        onSubmit={onSubmit}
        id={brandForm}
      >
        <div>
          <div className='redux-form__section pb-5'>
            <div className='flex'>
              <div className='w-60'>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-xl'>
                    <label className='redux-form__label'>
                      Code
                      <span className='required'>*</span>
                    </label>
                    <Field
                      name={'code'}
                      placeholder={'Enter Code'}
                      component={Input}
                      type={'text'}
                    />
                  </div>
                </div>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-xl'>
                    <label className='redux-form__label'>
                      Name
                      <span className='required'>*</span>
                    </label>
                    <Field
                      name={'name'}
                      placeholder={'Enter name'}
                      component={Input}
                      type={'text'}
                    />
                  </div>
                </div>
              </div>

              <div className='w-40'>
                <div className='brands__brand-form__image-preview'>
                  <div className='brands__brand-form__image-preview__title'>Image</div>
                  <div className='brands__brand-form__image-preview__image-container'>
                    {imageUrl && <img src={imageUrl} />}
                    <div className='brands__brand-form__image-preview__btn-container'>
                      <Button
                        type={'secondary'}
                        text={'Select Image'}
                        onClick={this.openUploadImageContainer}
                        size={'s'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-30'>
              <UploadImage
                message={message}
                changeImageUrl={this.changeImage}
                uploadIsActive={uploadImageIsActive}
                maxWidth={imageMaxWidth}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

BrandForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  change: PropTypes.func,
};

export default BrandForm;
