import { buildQueryString } from '@util/stringHelpers';

export const root = 'homefield/api' as const;

export const brands = `${root}/brands` as const;
export const brand = (brandId: number): string => `${brands}/${brandId}`;

export const vendors = `${root}/vendors` as const;
export const vendorsAll = `${root}/vendors.simple` as const;
export const vendorsTable = (pageNumber: number, pageSize: number, search: string): string => `${vendors}${buildQueryString({
  pageNumber,
  pageSize,
  search,
}, true)}`;
export const vendor = (code: string): string => `${vendors}/${code}`;
export const vendorTimelines = (code: string): string => `${vendor(code)}/timelines`;

export const layouts = `${root}/layouts` as const;
export const layout = (layoutId: number): string => `${layouts}/${layoutId}`;
export const layoutsTable = (
  pageNumber: number,
  pageSize: number,
  search: string,
  categoryId: number,
  sportId: number,
  styleId: number
): string => `${layouts}${buildQueryString({
  pageNumber,
  pageSize,
  search,
  categoryId,
  sportId,
  styleId,
}, true)}`;

export const layoutStyles = `${layouts}/styles` as const;
export const sportsForLayout = (layoutId: number): string => `${layout(layoutId)}/sports`;
export const validateLayoutStyles = `${layoutStyles}.validate` as const;

export const categories = `${root}/categories` as const;
export const category = (categoryId: number): string => `${categories}/${categoryId}`;

export const parentCategories = `${root}/parent-categories` as const;
export const parentCategory = (parentCategoryId: number): string => `${parentCategories}/${parentCategoryId}`;

export const sports = `${root}/sports` as const;
export const sport = (sportId: number): string => `${sports}/${sportId}`;

export const decorationLocations = (type?: string): string => {
  let query = '';
  if (type) {
    query = `?type=${type}`;
  }

  return `${root}/decoration-locations${query}`;
};
export const decorationLocation = (decorationLocationId: number): string => `${decorationLocations()}/${decorationLocationId}`;

export const decorationLocationArtworkSizes = `${root}/decoration-location-artwork-sizes` as const;
export const decorationLocationArtworkSize = (
  decorationLocationArtworkSizeId: number
): string => `${decorationLocationArtworkSizes}/${decorationLocationArtworkSizeId}`;

export const decorationLocationPersonalizationSizes = `${root}/decoration-location-personalization-sizes` as const;

export const uploadFile = `${root}/files.upload` as const;

export const colors = `${root}/colors` as const;
export const color = (colorId: number): string => `${colors}/${colorId}`;

export const colorGroups = `${root}/color-groups` as const;
export const ages = `${root}/ages` as const;
export const genders = `${root}/genders` as const;
export const sizes = `${root}/sizes` as const;

export default {
  brands,
  brand,
  categories,
  category,
  parentCategory,
  parentCategories,
  sports,
  sport,
  decorationLocations,
  decorationLocation,
  decorationLocationArtworkSizes,
  decorationLocationArtworkSize,
  decorationLocationPersonalizationSizes,
  uploadFile,
  colors,
  color,
  colorGroups,
  ages,
  genders,
  sizes,
  vendors,
  vendor,
  vendorsAll,
  vendorsTable,
  vendorTimelines,
  layouts,
  layout,
  layoutsTable,
  layoutStyles,
  sportsForLayout,
  validateLayoutStyles,
};
