import React from 'react';
import PropTypes from 'prop-types';
import OrderManagementDetailsItemExpandFile from './OrderManagementDetailsItemExpandFile';
import OrderManagementDetailsItemExpandPersonalizations from './OrderManagementDetailsItemExpandPersonalizations';
import OrderManagementDetailsItemExpandImage from './OrderManagementDetailsItemExpandImage';

const OrderManagementDetailsItemExpand = React.memo(({
  item,
  openModal,
  colorsDictionary,
}) => {
  const imageFront = item.imageUrlFront
    ? (
      <OrderManagementDetailsItemExpandImage
        openModal={openModal}
        imageUrl={item.imageUrlFront}
        imageTitle={'Front'}
      />
    )
    : null;

  const imageBack = item.imageUrlBack
    ? (
      <OrderManagementDetailsItemExpandImage
        openModal={openModal}
        imageUrl={item.imageUrlBack}
        imageTitle={'Back'}
      />
    )
    : null;

  const imageLeft = item.imageUrlLeft
    ? (
      <OrderManagementDetailsItemExpandImage
        openModal={openModal}
        imageUrl={item.imageUrlLeft}
        imageTitle={'Side Left'}
      />
    )
    : null;

  const imageRight = item.imageUrlRight
    ? (
      <OrderManagementDetailsItemExpandImage
        openModal={openModal}
        imageUrl={item.imageUrlRight}
        imageTitle={'Side Right'}
      />
    )
    : null;

  return (
    <div className='order__expand'>
      <div className='order__files'>
        {imageFront}
        {imageBack}
        {imageLeft}
        {imageRight}

        <hr className='vertical decorationList__preview-delimiter' />

        {
          item.logos.map((file, index) => (
            <OrderManagementDetailsItemExpandFile
              key={index}
              openModal={openModal}
              file={file}
            />
          ))
        }

        <OrderManagementDetailsItemExpandPersonalizations
          personalizations={item.personalizations}
          colorsDictionary={colorsDictionary}
        />
      </div>
    </div>
  );
});

OrderManagementDetailsItemExpand.propTypes = {
  item: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })).isRequired,
};

export default OrderManagementDetailsItemExpand;
