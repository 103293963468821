import React from 'react';
import PropTypes from 'prop-types';
import { fileNameRegex } from '@constants/values';
import Icon from '@sharedComponents/Icons/Icon';

const FileNameLink = React.memo(({
  url = '',
  hasIcon = true,
  iconClasses = '',
}) => (
  <div className='align__center'>
    {
      hasIcon &&
      <Icon
        materialIcon={'attach_file'}
        classes={iconClasses}
      />
    }
    {
      url &&
      <a
        href={url}
        className='catalog-details__link'
      >
        {url.match(fileNameRegex)}
      </a>
    }
  </div>
));

FileNameLink.propTypes = {
  url: PropTypes.string,
  hasIcon: PropTypes.bool,
  iconClasses: PropTypes.string,
};

export default FileNameLink;
