import * as actionTypes from './types';
import { productionAssemblyPrintQueueStorageKey } from '@constants/values';

const printQueueRaw = localStorage.getItem(productionAssemblyPrintQueueStorageKey);

const initialState = {
  order: {
    id: null,
    datePlaced: null,
    shipDate: null,
    oldShipDate: null,
    customerShipDate: null,
    orderNumber: null,
    lockerId: null,
    lockerTeamName: null,
    gpDocumentId: null,
    gpCustomerId: null,
    isCanceled: null,
    isPaused: null,
    isRushed: null,
    status: null,
    statusDate: null,
    statusUser: null,
    shippingInfo: {
      addressLine1: null,
      addressLine2: null,
      city: null,
      country: null,
      email: null,
      method: null,
      name: null,
      phoneNumber: null,
      poBox: null,
      state: null,
      zip: null,
    },
    items: [],
    itemsUniqueCount: null,
  },
  logoColors: {},
  orderItemsStatuses: [],
  orderLineItemsStatuses: [],
  orderLineItemsFlags: [],
  dtgStations: [],
  printQueue: printQueueRaw ? JSON.parse(printQueueRaw) : [],
  dtgTransmissionDecorationsQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
    pageNumber: 1,
    pageSize: 10,
  },
  productsDict: {},
  transmissionsCount: 0,
  dtgColoredStyleConfigDict: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case actionTypes.UPDATE_ORDER_ITEMS_STATUSES:
      return {
        ...state,
        orderItemsStatuses: action.payload,
      };
    case actionTypes.UPDATE_ORDER_LINE_ITEMS_STATUSES:
      return {
        ...state,
        orderLineItemsStatuses: action.payload,
      };
    case actionTypes.UPDATE_ORDER_LINE_ITEMS_FLAGS:
      return {
        ...state,
        orderLineItemsFlags: action.payload,
      };
    case actionTypes.CLEAR_LOGO_COLORS:
      return {
        ...state,
        logoColors: {},
      };
    case actionTypes.UPDATE_LOGO_COLORS:
      const updatedColors = { ...state.logoColors };
      updatedColors[action.payload.logoId] = action.payload;

      return {
        ...state,
        logoColors: updatedColors,
      };
    case actionTypes.UPDATE_DTG_STATIONS:
      return {
        ...state,
        dtgStations: action.payload,
      };
    case actionTypes.UPDATE_PRINT_QUEUE:
      localStorage.setItem(productionAssemblyPrintQueueStorageKey, JSON.stringify(action.payload));

      return {
        ...state,
        printQueue: [...action.payload],
      };
    case actionTypes.UPDATE_DTG_TRANSMISSION_DECORATIONS:
      return {
        ...state,
        dtgTransmissionDecorationsQueue: action.payload,
      };
    case actionTypes.UPDATE_DTG_CONFIGURATION_COLORED_STYLE:
      const { coloredStyleId } = action.data;

      return {
        ...state,
        dtgColoredStyleConfigDict: {
          ...state.dtgColoredStyleConfigDict,
          [coloredStyleId]: action.data,
        },
      };

    case actionTypes.UPDATE_PRODUCTS_DICTIONARY:
      const { sku } = action.data;

      return {
        ...state,
        productsDict: {
          ...state.productsDict,
          [sku]: action.data,
        },
      };
    case actionTypes.UPDATE_TRANSMISSIONS_COUNT:
      return {
        ...state,
        transmissionsCount: action.payload,
      };
    default:
      return state;
  }
}
