import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { ProductDetailDto } from '@api/productCatalog/models';

interface OwnProps {
  itemProduct: ProductDetailDto | undefined;
}

type Props = OwnProps;

const ReviewItemInfoTooltip = React.memo<Props>(({ itemProduct }) => (
  <MaterialTooltip
    tooltipText={(
      <div>
        <div>Size: {itemProduct?.size}</div>
        <div>Color: {itemProduct?.coloredStyle?.colorGroup}</div>
        <div>Brand: {itemProduct?.coloredStyle?.style?.brand?.name}</div>
      </div>
    )}
    placement={'top'}
    contentClasses={'details__product__title--icon'}
  >
    <Icon materialIcon={'info_outline'} />
  </MaterialTooltip>
));

export default ReviewItemInfoTooltip;
