import React, {
  useCallback, useState, useEffect,
} from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import FormInput from '@sharedComponents/Form/FormInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import { useFlags } from 'launchdarkly-react-client-sdk';

const DEFAULT_NUMBER_OF_PRINTERS = Array.from({ length: 10 }, (_, i) => i + 1);

interface OwnProps {
  title: string;
  jobId: number;
  formId: string;
  isOpen: boolean;
  onClose: () => void;
  onPrint: (jobId: number, printerNumber: number) => void;
}

type Props = OwnProps;

const DtfPrinterModal = React.memo<Props>(({
  title,
  jobId,
  formId,
  isOpen,
  onClose,
  onPrint,
}) => {
  const { printRoomAvailablePrinterNumbers } = useFlags();

  const [
    printNumberList,
    setPrintNumberList,
  ] = useState(DEFAULT_NUMBER_OF_PRINTERS);

  useEffect(() => {
    if (typeof printRoomAvailablePrinterNumbers === 'string') {
      const parsedList = printRoomAvailablePrinterNumbers
        .split(',')
        .map((x) => parseInt(x))
        .filter((x) => x > 0);

      if (parsedList.length) {
        setPrintNumberList(parsedList);
      } else {
        setPrintNumberList(DEFAULT_NUMBER_OF_PRINTERS);
      }
    } else {
      setPrintNumberList(DEFAULT_NUMBER_OF_PRINTERS);
    }
  }, [printRoomAvailablePrinterNumbers]);

  const schema = yup.object({
    printerNumber: yup.number()
      .required('Printer Number is required')
      .integer('Printer Number must be an integer')
      .oneOf(printNumberList, `Printer Number must one of: ${printNumberList.join(', ')}`),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      printerNumber: undefined as (number | undefined),
    },
  });

  const handleCloseModal = useCallback(() => {
    methods.reset();
    onClose();
  }, [
    methods,
    onClose,
  ]);

  const onSubmit = useCallback((form: { printerNumber: number; }) => {
    onPrint(jobId, form.printerNumber);
    handleCloseModal();
  }, [
    handleCloseModal,
    jobId,
    onPrint,
  ]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  return (
    <Modal
      modalSize={'s'}
      title={title}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Print'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={formId}
        />
      )}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
      >
        <label className='redux-form__label'>
            Printer Number
          <span className='required'>*</span>
        </label>
        <div>
          <FormInput
            placeholder={'Printer Number'}
            type={'number'}
            register={register}
            error={errors.printerNumber}
            required={true}
            autoFocus={true}
            {...register('printerNumber')}
          />
        </div>
      </form>
    </Modal>
  );
});

export default DtfPrinterModal;
