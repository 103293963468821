import React from 'react';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  item: any;
  orderFlags: any[];
}

type Props = OwnProps;

const OrderManagementDetailsItemFlag = React.memo(({
  item,
  orderFlags,
}: Props) => {
  if (!orderFlags || orderFlags.length === 0) {
    return null;
  }

  const flagsForItem = orderFlags
    .filter((flag) => item.ids.includes(flag.orderItemId) && flag.type === flagTypeEnum.WrongArtwork);

  if (flagsForItem.length === 0) {
    return null;
  }

  return (
    <MaterialTooltip
      tooltipText={'Some or all items are flagged for Artwork Change'}
      placement={'top'}
    >
      <div className='order__item--flags align__center button'>
        <Icon
          materialIcon={'flag'}
          classes={'is-flagged'}
        />
        <span className='order__item-status__value-text'>{flagsForItem.length} / {item.ids.length}</span>
      </div>
    </MaterialTooltip>
  );
});

export default OrderManagementDetailsItemFlag;
