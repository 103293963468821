import React from 'react';
import { vendorsUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const VendorsLink = React.memo(() => (
  <MenuTile
    title={'Vendors'}
    url={vendorsUrl}
    icon={'business'}
  />
));

export default VendorsLink;
