import {
  fulfillmentApi,
  catalogApi,
} from '../../sqdApis';
import productCatalogUrls from '@constants/sqdApiUrls/productCatalogUrls';
import supportUrls from '@constants/sqdApiUrls/supportUrls';
import dtgPrintingUrls from '@constants/sqdApiUrls/dtgPrintingUrls';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
  makeApiCall,
} from '@util/apiHelper';
import { mapErrors } from '@util/mappingHelper';

export const addDtgEnvironment = async (env) => {
  const body = JSON.stringify(env);
  const call = catalogApi.post(productCatalogUrls.dtgEnvironments, {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const editDtgEnvironment = async (id, env) => {
  const body = JSON.stringify(env);
  const call = catalogApi.put(productCatalogUrls.dtgEnvironment(id), {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const deleteDtgEnvironment = async (id) => {
  const call = catalogApi.delete(productCatalogUrls.dtgEnvironment(id));
  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const editDtgColoredStyle = async (config) => {
  const body = JSON.stringify(config);
  const call = catalogApi.post(productCatalogUrls.dtgColoredStyle(), {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const editDtgArtworkSettings = async (config) => {
  const body = JSON.stringify(config);
  const call = catalogApi.post(productCatalogUrls.dtgArtworkSettings(), {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const deleteDtgArtworkSettings = async (id) => {
  const call = catalogApi.delete(productCatalogUrls.dtgArtworkSettings(id));
  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const editDtgPersonalizationSettings = async (config) => {
  const body = JSON.stringify(config);
  const call = catalogApi.post(productCatalogUrls.dtgPersonalizationSettings(), {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const deleteDtgPersonalizationSettings = async (id) => {
  const call = catalogApi.delete(productCatalogUrls.dtgPersonalizationSettings(id));
  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const addDtgStation = async (station) => {
  const body = JSON.stringify(station);
  const call = fulfillmentApi.post(supportUrls.dtgStations, {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call, mapErrors);

  return res;
};

export const editDtgStation = async (station) => {
  const body = JSON.stringify(station);
  const call = fulfillmentApi.put(supportUrls.dtgStations, {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call, mapErrors);

  return res;
};

export const deleteDtgStation = async (id) => {
  const call = fulfillmentApi.delete(supportUrls.dtgStation(id));
  const res = await makeApiCallWithErrorModal(call, mapErrors);

  return res;
};

export const printDtgDecorations = async (stationIdentifier, orderItemDecorationIds) => {
  const body = JSON.stringify({
    stationIdentifier,
    orderItemDecorationIds,
  });

  const call = fulfillmentApi.post(dtgPrintingUrls.dtgTransmissions, {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const cancelDtgTransmissionDecorations = async (ids) => {
  const body = JSON.stringify({
    ids,
  });

  const call = fulfillmentApi.put(dtgPrintingUrls.cancelDtgDecorations, {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const archiveDtgTransmissionDecorations = async () => {
  const call = fulfillmentApi.put(dtgPrintingUrls.archiveDtgDecorations, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ }),
  });

  const res = await makeApiCallWithSubmissionError(call, mapErrors);

  return res;
};

export const addDtgTabletProfile = async (profile) => {
  const body = JSON.stringify(profile);
  const call = catalogApi.post(productCatalogUrls.dtgTabletProfiles, {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call, mapErrors);

  return res;
};

export const editDtgTabletProfile = async (id, profile) => {
  const body = JSON.stringify(profile);
  const call = catalogApi.put(productCatalogUrls.dtgTabletProfile(id), {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call, mapErrors);

  return res;
};

export const deleteDtgTabletProfile = async (id) => {
  const call = catalogApi.delete(productCatalogUrls.dtgTabletProfile(id));
  const res = await makeApiCallWithErrorModal(call, mapErrors);

  return res;
};

export const regenerateImage = async ({
  artworkTaskId = null,
  orderItemDecorationId = null,
}) => {
  const body = JSON.stringify({
    artworkTaskId,
    orderItemDecorationId,
  });

  const call = fulfillmentApi.put(dtgPrintingUrls.regenerateImage, {
    headers: {
      'Content-type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call, mapErrors);

  return res;
};

export const getDtgPrepressJob = async ({
  artworkTaskId = null,
  orderItemDecorationId = null,
}) => {
  const call = fulfillmentApi.get(dtgPrintingUrls.dtgPrepressJob({
    artworkTaskId,
    orderItemDecorationId,
  }), {
    handleBlockingLoading: false,
    headers: {
      'Content-type': 'application/json',
    },
  });

  const res = await makeApiCall(call, mapErrors);

  return res;
};

export default {
  addDtgEnvironment,
  editDtgEnvironment,
  deleteDtgEnvironment,
  editDtgArtworkSettings,
  editDtgPersonalizationSettings,
  addDtgStation,
  editDtgStation,
  deleteDtgStation,
  printDtgDecorations,
  addDtgTabletProfile,
  editDtgTabletProfile,
  deleteDtgTabletProfile,
  regenerateImage,
};
