import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { sortDirectionShortEnum } from '@constants/enums/commonEnums';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import { adminDetailsUrl } from '@constants/clientUrls/clientUrls';
import { fetchAdmins } from '@redux/admins/actions';
import { parseDateTimeNumeric } from '@util/dateHandler';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';

const AdminsTable = Table();

class AdminTab extends Component {
  state = {
    sortOrder: '',
    sortByState: '',
    pageNumber: this.props.pageNumber,
    pageSize: this.props.pageSize,
  };

  componentDidUpdate(oldProps) {
    const { organizationId } = this.props;

    if (organizationId !== oldProps.organizationId && oldProps.organizationId !== null) {
      this.search();
    }
  }

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.state;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrder);

    this.setState(() => ({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }), this.search);
  };

  search = () => {
    const {
      dispatch,
      organizationId,
    } = this.props;

    const {
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
    } = this.state;

    dispatch(fetchAdmins(
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
      dateRangeEnum.all.value,
      '',
      organizationId
    ));
  };

  getColumns = (organizationDecisionMakerId) => {
    const columns = [
      {
        Header: <HeaderCell text={'ID'} />,
        accessor: 'id',
        minWidth: 20,
        sortable: true,
        Cell: (cellProps) => cellProps.value && <Link to={adminDetailsUrl(cellProps.value)}>{cellProps.value}</Link>,
      },
      {
        Header: <HeaderCell text={'Full Name'} />,
        accessor: 'fullName',
        minWidth: 50,
        sortable: true,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 60,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        minWidth: 50,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: 'Permission',
        accessor: 'id',
        minWidth: 40,
        Cell: (cellProps) => cellProps.value === organizationDecisionMakerId ? 'Decision Maker' : 'Locker Admin',
      },
      {
        Header: 'Role',
        accessor: 'role',
        minWidth: 40,
        Cell: (cellProps) => cellProps.value && <span>{cellProps.value}</span>,
      },
      {
        Header: <HeaderCell text={'Signup Date'} />,
        accessor: 'createdAt',
        sortable: true,
        minWidth: 60,
        Cell: (cellProps) => cellProps.value && <div>{parseDateTimeNumeric(cellProps.value)}</div>,
      },
    ];

    return columns;
  };

  render() {
    const {
      queue,
      totalPages,
      hasNextPage,
      hasPreviousPage,
      organizationDecisionMakerId,
    } = this.props;

    return (
      <div className='sheet'>
        <AdminsTable
          data={queue}
          columns={this.getColumns(organizationDecisionMakerId)}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          totalPages={totalPages}
          onFetchData={this.fetchData}
        />
      </div>
    );
  }
}

AdminTab.propTypes = {
  totalPages: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationId: PropTypes.number.isRequired,
  organizationDecisionMakerId: PropTypes.number,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

const mapStateToProps = ({ admins }) => ({
  totalPages: admins.adminsList.totalPages,
  queue: admins.adminsList.queue,
  hasPreviousPage: admins.adminsList.hasPreviousPage,
  hasNextPage: admins.adminsList.hasNextPage,
  pageNumber: admins.adminsList.pageNumber,
  pageSize: admins.adminsList.pageSize,
});

export default connect(mapStateToProps)(AdminTab);
