import React from 'react';
import { PrepressJobOrderIndicatorEnum } from '@api/fulfillment/models';
import { pathToImage } from '@util/indicatorHelper';

interface OwnProps {
  logoUrl: string;
  indicator?: PrepressJobOrderIndicatorEnum;
}

type Props = OwnProps;

const LogoInfo = React.memo<Props>(({
  logoUrl,
  indicator,
}) => {
  const indicatorPath = indicator
    ? (pathToImage(indicator) ?? undefined)
    : undefined;

  return (
    <div>
      <i
        className='flagging__details-logo-thumbnail'
        style={{ backgroundImage: `url("${logoUrl}")` }}
      />
      <span className='margin margin--right-s'>
        <img
          className='indicator'
          style={{ height: '20px' }}
          src={indicatorPath}
        />
      </span>
    </div>
  );
});

export default LogoInfo;
