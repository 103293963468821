import {
  LabelType, LockerStatusLabelType,
} from '@customTypes/label';
import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';

export interface IHeaderStatusLabel {
  readonly text: string;
  readonly color?: LabelType | LockerStatusLabelType;
}
export interface IHeaderStatusItem<T extends string> {
  readonly id: T;
  readonly label: string;
  readonly status: 'COMPLETED' | 'ACTIVE' | 'INACTIVE';
}
export interface IHeaderState<T extends string> {
  readonly title?: string;
  readonly statuses?: readonly IHeaderStatusItem<T>[];
  readonly label?: IHeaderStatusLabel;
}

export type HeaderStatusItemArgType<T extends string> = Omit<IHeaderStatusItem<T>, 'status'>;

export interface IHeaderMutator<T extends string> {
  setTitle(title: string | undefined): void;
  setStatuses(activeId: T, statuses: readonly HeaderStatusItemArgType<T>[]): void;
  setLabel(label: IHeaderStatusLabel | undefined): void;
}

export interface IHeaderContext<T extends string> {
  header: IHeaderState<T>;
  headerMutator: IHeaderMutator<T>;
}

const HeaderContext = createContext<IHeaderContext<string>>(null as never);

export const HeaderContextProvider: React.FC = React.memo((props) => {
  const [
    header,
    setHeader,
  ] = useState<IHeaderState<string>>({});

  const setTitle: IHeaderMutator<string>['setTitle'] = useCallback((title) => {
    setHeader((prev) => ({
      title,
      statuses: prev.statuses,
      label: prev.label,
    }));
  }, []);

  const setStatuses: IHeaderMutator<string>['setStatuses'] = useCallback((activeId, statuses) => {
    setHeader(({
      title,
      label,
    }) => {
      const id = activeId;
      const structuredStatuses: IHeaderStatusItem<string>[] = [];
      let setComplete = statuses.some((s) => s.id === id);

      for (const status of statuses) {
        let sts: 'COMPLETED' | 'ACTIVE' | 'INACTIVE';
        if (status.id === id) {
          setComplete = false;
          sts = 'ACTIVE';
        } else if (setComplete) {
          sts = 'COMPLETED';
        } else {
          sts = 'INACTIVE';
        }

        structuredStatuses.push({
          id: status.id,
          label: status.label,
          status: sts,
        });
      }

      return {
        title,
        statuses: structuredStatuses,
        label,
      };
    });
  }, []);

  const setLabel: IHeaderMutator<string>['setLabel'] = useCallback((label) => {
    setHeader((prev) => ({
      title: prev.title,
      statuses: prev.statuses,
      label,
    }));
  }, []);

  const headerMutator: IHeaderMutator<string> = useMemo(() => ({
    setTitle,
    setStatuses,
    setLabel,
  }), [
    setTitle,
    setStatuses,
    setLabel,
  ]);

  return (
    <HeaderContext.Provider value={{
      header,
      headerMutator,
    }}
    >
      {props.children}
    </HeaderContext.Provider>
  );
});

export const useHeaderContext = <T extends string = never>() => useContext(HeaderContext) as IHeaderContext<T>;

export default useHeaderContext;
