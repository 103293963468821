import React from 'react';

interface OwnProps {
  materialIcon?: Nullable<string>;
  fontAwesomeIcon?: Nullable<string>;
  iconUrl?: Nullable<string>;
  classes?: Nullable<string>;
  onClick?: (e: React.MouseEvent) => void;
  width?: number;
  height?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
  dataTest?: Nullable<string>;
  color?: string;
  backgroundColor?: string;
}

type Props = OwnProps;

const Icon = React.memo(({
  materialIcon = null,
  fontAwesomeIcon = null,
  iconUrl = null,
  classes = '',
  onClick = undefined,
  width = undefined,
  height = undefined,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  dataTest = 'icon',
  color = undefined,
  backgroundColor = undefined,
}: Props) => {
  if (materialIcon) {
    return (
      <i
        className={`material-icons ${classes}`}
        data-test={dataTest}
        onClick={onClick}
        style={{
          color,
          backgroundColor,
        }}
      >
        {materialIcon}
      </i>
    );
  }

  if (fontAwesomeIcon) {
    return (
      <i
        className={`fa fa-${fontAwesomeIcon} ${classes}`}
        onClick={onClick}
        style={{
          color,
          backgroundColor,
        }}
      />
    );
  }

  if (iconUrl) {
    return (
      <div
        className={`image ${classes}`}
        style={{
          backgroundImage: `url("${iconUrl}")`,
          width,
          height,
          marginLeft,
          marginRight,
          marginTop,
          marginBottom,
          color,
          backgroundColor,
        }}
        onClick={onClick}
      />
    );
  }

  return null;
});

export default Icon;
