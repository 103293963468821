import React from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  Field,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { dropdownSizeXs } from '@constants/values';
import { addItemsToExistingCollectionForm } from '@constants/reduxForms';
import { AddItemsToExistingCollectionFormData } from '@models/forms/Collections/AddItemsToExistingCollectionFormData';
import { RootState } from '@redux/index/reducers';
import { mapToOptionsList } from '@util/mappingHelper';
import FormDropdown from '@sharedComponents/Form/FormDropdown';
import CollectionItemsList from './CollectionItemsList';

const mapStateToProps = ({ collections }: RootState) => ({
  lockerCollections: collections.collectionsQueue.queue,
});

const connector = connect(mapStateToProps);

type FormProps = ConnectedProps<typeof connector>;

type Props = FormProps & InjectedFormProps<AddItemsToExistingCollectionFormData, FormProps, string[]>;

const AddItemsToExistingCollectionForm = React.memo<Props>(({
  handleSubmit,
  change,
  lockerCollections,
}) => {
  const collectionOptions = mapToOptionsList({
    list: lockerCollections,
    key: 'id',
    value: 'id',
    name: 'name',
    emptyOption: {
      name: 'Choose a Collection',
    },
  });

  return (
    <form
      className='redux-form'
      id={addItemsToExistingCollectionForm}
      onSubmit={handleSubmit}
    >
      <Field
        name={'collectionId'}
        component={FormDropdown}
        change={change}
        options={collectionOptions}
        size={dropdownSizeXs}
        optionsClasses={'collections-form__dropdown'}
      />
      <CollectionItemsList
        change={change}
      />
    </form>
  );
});

export default connector(reduxForm<AddItemsToExistingCollectionFormData, FormProps, string[]>({
  form: addItemsToExistingCollectionForm,
  enableReinitialize: true,
  shouldError: () => true,
})(AddItemsToExistingCollectionForm));
