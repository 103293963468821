/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  PagedListViewModelRosterTeamTableRowViewModel,
  ApiInternalServerError,
  GetRostersTableParams,
  ApiBadRequest,
  GetCSVParams,
  ApiOkRosterTeamFromCSVViewModelArray,
  ParseCSVFileParams,
  RosterTeamMemberViewModel,
  RosterTeamViewModel,
  ApiOkNumber,
  UpdateRosterTeamRequestModel,
  ApiOkNumberArray,
  CreateMultipleRosterTeamsRequestModel,
  IdsRequestModel,
  ApiOk,
  DeleteTeamMembersRequestModel,
  UpdateRosterTeamMemberRequestModel,
  CreateRosterTeamMemberRequestModel,
  ApiOkEmailCampaignViewModelArray,
  CreateEmailCampaignForRostersRequestModel
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getRostersTable = <TData = PagedListViewModelRosterTeamTableRowViewModel>(
    params?: GetRostersTableParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetRostersTableQueryKey = (params?: GetRostersTableParams,) => [`/homefield/api/rosters`, ...(params ? [params]: [])]

    
export const useGetRostersTable = <TQueryFnData = AsyncReturnType<typeof getRostersTable, PagedListViewModelRosterTeamTableRowViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetRostersTableParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetRostersTableQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getRostersTable<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getCSV = <TData = unknown>(
    params?: GetCSVParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/csv`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetCSVQueryKey = (params?: GetCSVParams,) => [`/homefield/api/rosters/csv`, ...(params ? [params]: [])]

    
export const useGetCSV = <TQueryFnData = AsyncReturnType<typeof getCSV, unknown>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 params?: GetCSVParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCSVQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getCSV<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const parseCSVFile = <TData = ApiOkRosterTeamFromCSVViewModelArray>(
    params?: ParseCSVFileParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/parse-csv`, method: 'post',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useParseCSVFile = <TData = AsyncReturnType<typeof parseCSVFile,ApiOkRosterTeamFromCSVViewModelArray>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params?: ParseCSVFileParams}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {params?: ParseCSVFileParams}, TContext>((props) => {
        const {params} = props || {};

        return  parseCSVFile<TData>(params,requestOptions)
      }, mutationOptions)
    }
    export const getRosterTeamMembers = <TData = RosterTeamMemberViewModel[]>(
    rosterTeamId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/${rosterTeamId}/members`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetRosterTeamMembersQueryKey = (rosterTeamId: number,) => [`/homefield/api/rosters/${rosterTeamId}/members`]

    
export const useGetRosterTeamMembers = <TQueryFnData = AsyncReturnType<typeof getRosterTeamMembers, RosterTeamMemberViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 rosterTeamId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetRosterTeamMembersQueryKey(rosterTeamId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getRosterTeamMembers<TQueryFnData>(rosterTeamId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getRosterTeamMemberDetails = <TData = RosterTeamMemberViewModel>(
    rosterTeamMemberId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/roster-members/${rosterTeamMemberId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetRosterTeamMemberDetailsQueryKey = (rosterTeamMemberId: number,) => [`/homefield/api/rosters/roster-members/${rosterTeamMemberId}`]

    
export const useGetRosterTeamMemberDetails = <TQueryFnData = AsyncReturnType<typeof getRosterTeamMemberDetails, RosterTeamMemberViewModel>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 rosterTeamMemberId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetRosterTeamMemberDetailsQueryKey(rosterTeamMemberId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getRosterTeamMemberDetails<TQueryFnData>(rosterTeamMemberId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getRosterTeamDetails = <TData = RosterTeamViewModel>(
    rosterTeamId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/${rosterTeamId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetRosterTeamDetailsQueryKey = (rosterTeamId: number,) => [`/homefield/api/rosters/${rosterTeamId}`]

    
export const useGetRosterTeamDetails = <TQueryFnData = AsyncReturnType<typeof getRosterTeamDetails, RosterTeamViewModel>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 rosterTeamId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetRosterTeamDetailsQueryKey(rosterTeamId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getRosterTeamDetails<TQueryFnData>(rosterTeamId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const editRosterTeam = <TData = ApiOkNumber>(
    rosterTeamId: number,
    updateRosterTeamRequestModel: UpdateRosterTeamRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/${rosterTeamId}`, method: 'put',
      data: updateRosterTeamRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEditRosterTeam = <TData = AsyncReturnType<typeof editRosterTeam,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{rosterTeamId: number;data: UpdateRosterTeamRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {rosterTeamId: number;data: UpdateRosterTeamRequestModel}, TContext>((props) => {
        const {rosterTeamId,data} = props || {};

        return  editRosterTeam<TData>(rosterTeamId,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteRosterTeam = <TData = ApiOkNumber>(
    rosterTeamId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/${rosterTeamId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteRosterTeam = <TData = AsyncReturnType<typeof deleteRosterTeam,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{rosterTeamId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {rosterTeamId: number}, TContext>((props) => {
        const {rosterTeamId} = props || {};

        return  deleteRosterTeam<TData>(rosterTeamId,requestOptions)
      }, mutationOptions)
    }
    export const createRosterTeams = <TData = ApiOkNumberArray>(
    createMultipleRosterTeamsRequestModel: CreateMultipleRosterTeamsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/list`, method: 'post',
      data: createMultipleRosterTeamsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateRosterTeams = <TData = AsyncReturnType<typeof createRosterTeams,ApiOkNumberArray>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateMultipleRosterTeamsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateMultipleRosterTeamsRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  createRosterTeams<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const reimportRosterTeam = <TData = ApiOkNumber>(
    rosterTeamId: number,
    createMultipleRosterTeamsRequestModel: CreateMultipleRosterTeamsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/${rosterTeamId}/re-import`, method: 'put',
      data: createMultipleRosterTeamsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useReimportRosterTeam = <TData = AsyncReturnType<typeof reimportRosterTeam,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{rosterTeamId: number;data: CreateMultipleRosterTeamsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {rosterTeamId: number;data: CreateMultipleRosterTeamsRequestModel}, TContext>((props) => {
        const {rosterTeamId,data} = props || {};

        return  reimportRosterTeam<TData>(rosterTeamId,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteRosters = <TData = ApiOkNumberArray>(
    idsRequestModel: IdsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/roster-teams.remove`, method: 'put',
      data: idsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteRosters = <TData = AsyncReturnType<typeof deleteRosters,ApiOkNumberArray>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: IdsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: IdsRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  deleteRosters<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const activateOrDisableRosters = <TData = ApiOk>(
    idsRequestModel: IdsRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/roster-teams.toggle-disabled`, method: 'put',
      data: idsRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useActivateOrDisableRosters = <TData = AsyncReturnType<typeof activateOrDisableRosters,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: IdsRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: IdsRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  activateOrDisableRosters<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const deleteTeamMembers = <TData = ApiOkNumber>(
    rosterTeamId: number,
    deleteTeamMembersRequestModel: DeleteTeamMembersRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/${rosterTeamId}/team-members.remove`, method: 'put',
      data: deleteTeamMembersRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteTeamMembers = <TData = AsyncReturnType<typeof deleteTeamMembers,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{rosterTeamId: number;data: DeleteTeamMembersRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {rosterTeamId: number;data: DeleteTeamMembersRequestModel}, TContext>((props) => {
        const {rosterTeamId,data} = props || {};

        return  deleteTeamMembers<TData>(rosterTeamId,data,requestOptions)
      }, mutationOptions)
    }
    export const editTeamMember = <TData = ApiOkNumber>(
    teamMemberId: number,
    updateRosterTeamMemberRequestModel: UpdateRosterTeamMemberRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/team-members/${teamMemberId}`, method: 'put',
      data: updateRosterTeamMemberRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useEditTeamMember = <TData = AsyncReturnType<typeof editTeamMember,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{teamMemberId: number;data: UpdateRosterTeamMemberRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {teamMemberId: number;data: UpdateRosterTeamMemberRequestModel}, TContext>((props) => {
        const {teamMemberId,data} = props || {};

        return  editTeamMember<TData>(teamMemberId,data,requestOptions)
      }, mutationOptions)
    }
    export const addTeamMember = <TData = ApiOkNumber>(
    createRosterTeamMemberRequestModel: CreateRosterTeamMemberRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/team-members`, method: 'post',
      data: createRosterTeamMemberRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useAddTeamMember = <TData = AsyncReturnType<typeof addTeamMember,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateRosterTeamMemberRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateRosterTeamMemberRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  addTeamMember<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const createRosterEmailCampaigns = <TData = ApiOkEmailCampaignViewModelArray>(
    createEmailCampaignForRostersRequestModel: CreateEmailCampaignForRostersRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/rosters/email-campaigns`, method: 'post',
      data: createEmailCampaignForRostersRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateRosterEmailCampaigns = <TData = AsyncReturnType<typeof createRosterEmailCampaigns,ApiOkEmailCampaignViewModelArray>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateEmailCampaignForRostersRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateEmailCampaignForRostersRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  createRosterEmailCampaigns<TData>(data,requestOptions)
      }, mutationOptions)
    }
    