import React from 'react';

interface OwnProps {
  options: Array<any>;
  defaultValue?: Nullable<string | number>;
  onChange?: Nullable<(e: any) => void>;
  classes?: Nullable<string>;
  selectClasses?: Nullable<string>;
  style?: Nullable<any>;
  disabled?: Nullable<boolean>;
  name?: Nullable<string>;
  dataTest?: Nullable<string>;
}

type Props = OwnProps;

const Dropdown = React.memo<Props>(({
  options,
  defaultValue,
  onChange,
  classes = '',
  selectClasses = '',
  style,
  disabled = false,
  name = '',
  dataTest = 'dropdown-select',
}) => (
  <div className={`select-field ${classes}`}>
    <select
      value={defaultValue ?? undefined}
      onChange={onChange ?? undefined}
      name={name ?? undefined}
      className={`has-icon ${selectClasses}`}
      style={style ?? undefined}
      disabled={disabled ?? undefined}
      data-test={dataTest}
    >
      {options}
    </select>
    <div className='select-field__arrow' />
  </div>
));

export default Dropdown;
