import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import Icon from '@sharedComponents/Icons/Icon';
import Modal from '@sharedComponents/Modal/Modal';

const ArtworkTaskOrdersModal = React.memo(({
  isOpen,
  closeModal,
  ordersCount,
  taskDetails,
}) => (
  <Modal
    isOpen={isOpen}
    closeModal={closeModal}
    title={'Orders'}
    modalHeight={'l'}
    modalWidth={'m'}
    customClasses={'mb-30'}
  >
    <div className='flex__column'>
      <div className='flex__wrap'>
        {
          ordersCount > 0 &&
          taskDetails.orders.map((order) => (
            <span
              key={order.orderNumber}
              className='mr-5'
            >
              <Link
                className={order.isFlagged ? 'flagged-item' : 'link'}
                to={orderDetailsUrl(order.orderNumber)}
              >
                {
                  order.isFlagged &&
                    <Icon
                      materialIcon={'flag'}
                      classes={'is-flagged'}
                    />
                }
                <span>{` O${order.orderNumber} `}</span>
              </Link>
            </span>
          ))
        }
      </div>
    </div>
  </Modal>
));

ArtworkTaskOrdersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  ordersCount: PropTypes.number.isRequired,
  taskDetails: PropTypes.shape({
    orders: PropTypes.array,
  }),
};

export default ArtworkTaskOrdersModal;
