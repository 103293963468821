import React, { PureComponent } from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import serverIcon from '@assets/icon_server.svg';
import { printSheetLabelsUrl } from '@constants/clientUrls/clientUrls';
import {
  fulfillmentSwagger,
  catalogSwagger,
  financialSwagger,
  slServicesSwagger,
  ordersSwagger,
} from '@constants/sqdApiUrls/supportUrls';
import * as supportActions from '@redux/support/actions';
import { RootState } from '@redux/index/reducers';
import Button from '@sharedComponents/Buttons/Button';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import packageJson from '../../../package.json';

const footerlessRoutes = [printSheetLabelsUrl];

const mapStateToProps = ({ support }: RootState) => ({
  fulfillmentVersion: support.fulfillmentVersion,
  catalogVersion: support.catalogVersion,
  financialVersion: support.financialVersion,
  slServicesVersion: support.slServicesVersion,
  ordersVersion: support.ordersVersion,
});

const mapDispatchToProps = {
  fetchFulfillmentVersion: supportActions.fetchFulfillmentVersion,
  fetchCatalogVersion: supportActions.fetchCatalogVersion,
  fetchFinancialVersion: supportActions.fetchFinancialVersion,
  fetchSlServicesVersion: supportActions.fetchSlServicesVersion,
  fetchOrdersVersion: supportActions.fetchOrdersVersion,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

interface State {
  rendered: boolean;
  versionsTooltipOpened: boolean;
}

class Footer extends PureComponent<Props, State> {
  state: State = {
    rendered: true,
    versionsTooltipOpened: false,
  };

  componentDidMount() {
    const {
      fetchFulfillmentVersion,
      fetchCatalogVersion,
      fetchFinancialVersion,
      fetchSlServicesVersion,
      fetchOrdersVersion,
    } = this.props;

    fetchFulfillmentVersion();
    fetchCatalogVersion();
    fetchFinancialVersion();
    fetchSlServicesVersion();
    fetchOrdersVersion();
  }

  componentDidUpdate() {
    const { location } = this.props;

    for (const route of footerlessRoutes) {
      if (location.pathname.includes(route)) {
        this.setState({ rendered: false });
      } else {
        this.setState(() => ({ rendered: true }));
      }
    }
  }

  toggleVersionsTooltip = () => {
    this.setState((prevState) => ({
      versionsTooltipOpened: !prevState.versionsTooltipOpened,
    }));
  };

  getServicesList = () => {
    const {
      fulfillmentVersion,
      catalogVersion,
      financialVersion,
      slServicesVersion,
      ordersVersion,
    } = this.props;

    return [
      {
        name: 'Fulfillment',
        version: fulfillmentVersion,
        swaggerLink: fulfillmentSwagger,
      },
      {
        name: 'Catalog',
        version: catalogVersion,
        swaggerLink: catalogSwagger,
      },
      {
        name: 'Financial',
        version: financialVersion,
        swaggerLink: financialSwagger,
      },
      {
        name: 'SL Services API',
        version: slServicesVersion,
        swaggerLink: slServicesSwagger,
      },
      {
        name: 'Orders API',
        version: ordersVersion,
        swaggerLink: ordersSwagger,
      },
    ];
  };

  render() {
    const {
      rendered,
      versionsTooltipOpened,
    } = this.state;

    if (!rendered) {
      return null;
    }

    const services = this.getServicesList();

    return (
      <footer
        className='footer'
        data-toggle='drawer-off'
      >
        <MaterialTooltip
          open={versionsTooltipOpened}
          interactive={true}
          tooltipText={(
            <div>
              {
                services.map((service, i) => (
                  <div key={i}>
                    <a
                      target='_blank'
                      href={service.swaggerLink}
                      className='footer-link m-l-6 text--white'
                      rel='noreferrer'
                    >
                      {service.name} - {service.version}
                    </a>
                  </div>
                ))
              }
            </div>
          )}
        >
          <Button
            icon={(
              <i
                className='material-icons'
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundImage: `url("${serverIcon}")`,
                }}
              />
            )}
            classes={'btn-borderless p-0 footer__version-btn'}
            onClick={this.toggleVersionsTooltip}
            type={undefined}
            text={''}
          />
        </MaterialTooltip>

        <span className='m-l-12'>
          Homefield Client {packageJson.version}
        </span>
      </footer>
    );
  }
}

export default withRouter(connector(Footer));
