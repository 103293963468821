import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyleSuggestions } from '@redux/productCatalog/actions';
import { bulkUpdateColoredStyles } from '@APICalls/productCatalog/actions';
import { materialSwal } from '@util/componentHelper';
import { styleUrl } from '@constants/clientUrls/clientUrls';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import Icon from '@sharedComponents/Icons/Icon';
import SkuSetupColoredStylesList from './SkuSetupColoredStylesList';

class SkuSetupBulkStyleChangeModal extends Component {
  state = {
    selectedStyle: this.props.style,
  };

  getStyleSuggestions = (searchInput) => {
    const { dispatch } = this.props;

    dispatch(getStyleSuggestions(searchInput));
  };

  displayStyle = (style) => `${style.id}`;

  displayStyleSuggestion = (style) => `${style.id} - ${style.code} - ${style.name}`;

  changeStyle = (style) => {
    this.setState({ selectedStyle: style });
  };

  onConfirm = async () => {
    const {
      coloredStyles,
      refresh,
      closeModal,
    } = this.props;
    const { selectedStyle } = this.state;

    const res = await bulkUpdateColoredStyles(coloredStyles, 'styleId', selectedStyle.id);

    if (res) {
      materialSwal('Success', 'Selected colored styles successfully moved to selected style', 'success');
    } else {
      materialSwal('Error', 'Something went wrong. Please try again.', 'error');
    }

    this.setState({ selectedStyle: null });

    refresh();
    closeModal();
  };

  render() {
    const {
      isOpen,
      closeModal,
      coloredStyles,
      style,
      styleSuggestions,
    } = this.props;

    const { selectedStyle } = this.state;

    return (
      <Modal
        title={'Change Style'}
        modalSize={'l'}
        isOpen={isOpen}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Yes, change'}
            cancelBtnText={'Cancel'}
            onConfirm={this.onConfirm}
            confirmBtnDisabled={!selectedStyle || style.id === selectedStyle.id}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div>
          <label className='redux-form__label'>
            Style ID
            <span className='required'>*</span>
          </label>
          <AutocompleteInput
            suggestions={styleSuggestions}
            fetchSuggestions={this.getStyleSuggestions}
            displayItem={this.displayStyle}
            displaySuggestionText={this.displayStyleSuggestion}
            selectedItem={style}
            selectItem={this.changeStyle}
            placeholder={'Search styles'}
            hasSearchIcon={true}
            size={'l'}
            isDropdownFixed={true}
          />
          <div className='flex mb-10'>
            {
              selectedStyle
                ? (
                  <div>
                    <span>{selectedStyle.id} - {selectedStyle.code} - {selectedStyle.name}</span>
                    <a
                      className='sku-setup__edit-action ml-5'
                      href={styleUrl(selectedStyle.id)}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Icon materialIcon={'launch'} />
                    </a>
                  </div>
                )
                : <span>-</span>
            }
          </div>
          <div>
            <div className='sku-setup__modal--text mt-20'>
              {`Are you sure you want to change style for colored ${coloredStyles.length === 1 ? 'style' : 'styles'} listed below?`}
            </div>
            <SkuSetupColoredStylesList
              coloredStyles={coloredStyles}
              styleName={style.name}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

SkuSetupBulkStyleChangeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object.isRequired,
  styleSuggestions: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func.isRequired,
};

const mapStateToProps = ({ productCatalog }) => ({
  styleSuggestions: productCatalog.styleSuggestions,
});

export default connect(mapStateToProps)(SkuSetupBulkStyleChangeModal);
