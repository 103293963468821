import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OutsideClickWrapper extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    const { onClick } = this.props;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      onClick(event);
    }
  };

  render() {
    const {
      className,
      children,
    } = this.props;

    return (
      <div
        ref={this.setWrapperRef}
        className={className}
      >
        {children}
      </div>
    );
  }
}

OutsideClickWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default OutsideClickWrapper;
