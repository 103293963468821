import React from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import ImageActions from '@sharedComponents/ImageActions';
import AssignLogoCard from './AssignLogoCard';

const ChooseLogosTab = React.memo(({
  logos,
  selectedLogos,
  toggleSelectLogo,
  unselectLogo,
  isLogoSelected,
  isSelectingLogosEnabled,
  removeAllLogos,
}) => {
  const imageActions = [
    {
      icon: 'close',
      text: 'Unselect logo',
      action: unselectLogo,
    },
  ];

  return (
    <>
      <div>
        <div className='add-product__table--products custom-scrollbar logo-bank__assignment--logos mb-10'>
          {
            logos.map((logo) => (
              <AssignLogoCard
                key={logo.id}
                logo={logo}
                isSelected={isLogoSelected(logo)}
                isDisabled={!isSelectingLogosEnabled() && !isLogoSelected(logo)}
                toggleSelectLogo={toggleSelectLogo}
              />
            ))
          }
        </div>
        <div className='add-product__selected logo-bank__assignment--selected-logos'>
          <div className='add-product__selected--header'>
            <span>
              {`Selected Logos ${selectedLogos.length}`}
            </span>
            <a onClick={removeAllLogos}>
          Remove All
            </a>
          </div>
          <div className='add-product__selected--items custom-scrollbar--horizontal'>
            {
              selectedLogos.map((logo) => (
                <ImageActions
                  key={logo.id}
                  item={logo}
                  image={`${lockerMgrS3Logos}/${logo.image}`}
                  imageActionsData={imageActions}
                />
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
});

ChooseLogosTab.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
  })),
  selectedLogos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
  })),
  toggleSelectLogo: PropTypes.func.isRequired,
  unselectLogo: PropTypes.func.isRequired,
  isLogoSelected: PropTypes.func.isRequired,
  isSelectingLogosEnabled: PropTypes.func.isRequired,
  removeAllLogos: PropTypes.func.isRequired,
};

export default ChooseLogosTab;
