import {
  VoucherNotificationLinkDestinationEnum,
  VoucherNotificationSenderNameEnum,
  VoucherOrderPaymentStatusEnum,
} from '@api/financialServices/models';
import {
  colorBrightnessEnum,
  personalizationItemTypeEnum,
} from '@constants/enums/decorationEnums';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import { artworkFileTaskSortOptionsEnum } from '@constants/enums/artworkEnums';
import { chargeReasonEnum } from '@constants/enums/orderEnums';
import { organizationTypeEnum } from '@constants/enums/lockerManagerEnums';
import {
  rosterNotificationsSenderNameTypeEnum,
  rosterNotificationsLinkDestinationEnum,
} from '@constants/enums/rosterEnums';
import {
  dtgPalletSizeEnum,
  dtgHighlightGeneratorEnum,
  dtgPrintDirectionEnum,
} from '../enums/dtgEnums';
import {
  MAX_NUMBER_OF_ROWS,
  MAX_NUMBER_OF_ROW_POSITIONS,
} from '@constants/values';
import {
  VoucherNotificationLinkDestinationLabel,
  VoucherNotificationSenderNameLabel,
} from '@constants/labelLookup';
import { PtlWallBinSizeEnum } from '@api/fulfillment/models/ptlWallBinSizeEnum';
import { OrderTypeEnum } from '@constants/enums/printRoomEnums';
import {
  HagPrintRoomJobStatusEnum,
  OrderInventoryStatusEnum, OrderPrintStatusEnum,
} from '@api/fulfillment/models';

export const organizationSizes = [
  {
    key: 1,
    value: '1-10',
    name: '1-10',
  },
  {
    key: 2,
    value: '11-25',
    name: '11-25',
  },
  {
    key: 3,
    value: '26-50',
    name: '26-50',
  },
  {
    key: 4,
    value: '51-100',
    name: '51-100',
  },
  {
    key: 5,
    value: '101-250',
    name: '101-250',
  },
  {
    key: 6,
    value: '251-500',
    name: '251-500',
  },
  {
    key: 7,
    value: '501+',
    name: '501+',
  },
] as const;

export const organizationTypes = [
  {
    key: 1,
    value: 'Adult Rec League',
    name: 'Adult Rec League',
  },
  {
    key: 2,
    value: 'Athletic Association / Booster Club',
    name: 'Athletic Association / Booster Club',
  },
  {
    key: 3,
    value: 'Business',
    name: 'Business',
  },
  {
    key: 4,
    value: 'Camp / Event',
    name: 'Camp / Event',
  },
  {
    key: 5,
    value: 'College',
    name: 'College',
  },
  {
    key: 6,
    value: 'Community Group',
    name: 'Community Group',
  },
  {
    key: 7,
    value: 'Elementary / Middle School',
    name: 'Elementary / Middle School',
  },
  {
    key: 8,
    value: 'Fitness & Training Center',
    name: 'Fitness & Training Center',
  },
  {
    key: 9,
    value: 'Military / Public Safety Organization',
    name: 'Military / Public Safety Organization',
  },
  {
    key: 10,
    value: 'Other',
    name: 'Other',
  },
  {
    key: 11,
    value: 'Prep / Private High School',
    name: 'Prep / Private High School',
  },
  {
    key: 12,
    value: 'Public High School',
    name: 'Public High School',
  },
  {
    key: 13,
    value: 'Sports Medicine',
    name: 'Sports Medicine',
  },
  {
    key: 14,
    value: 'Travel/Club Program',
    name: 'Travel/Club Program',
  },
  {
    key: 15,
    value: 'Youth Sports Organization (YSO)',
    name: 'Youth Sports Organization (YSO)',
  },
] as const;

export const adminRoles = [
  {
    key: 1,
    value: 'board_member',
    name: 'Board Member / Administrator',
  },
  {
    key: 2,
    value: 'athletic_director',
    name: 'Athletic Director',
  },
  {
    key: 3,
    value: 'coach',
    name: 'Coach',
  },
  {
    key: 4,
    value: 'manager',
    name: 'Manager',
  },
  {
    key: 5,
    value: 'parent',
    name: 'Parent',
  },
  {
    key: 6,
    value: 'fan',
    name: 'Fan',
  },
  {
    key: 7,
    value: 'alumni',
    name: 'Alumni',
  },
  {
    key: 8,
    value: 'coordinator',
    name: 'Coordinator',
  },
  {
    key: 9,
    value: 'volunteer',
    name: 'Volunteer',
  },
  {
    key: 10,
    value: 'participant',
    name: 'Participant',
  },
  {
    key: 11,
    value: 'principal',
    name: 'Principal / Vice-Principal',
  },
  {
    key: 12,
    value: 'book_store_coordinator',
    name: 'Book store coordinator',
  },
  {
    key: 13,
    value: 'teacher',
    name: 'Teacher',
  },
  {
    key: 14,
    value: 'business_owner',
    name: 'Business Owner',
  },
  {
    key: 15,
    value: 'player',
    name: 'Player',
  },
  {
    key: 16,
    value: 'youth_league_administrator',
    name: 'Youth League Administrator',
  },
  {
    key: 17,
    value: 'other',
    name: 'Other',
  },
] as const;

export const rosterTeamRoles = [
  {
    key: 1,
    value: '',
    name: '',
  },
  {
    key: 2,
    value: 'Player',
    name: 'Player',
  },
  {
    key: 3,
    value: 'Captain',
    name: 'Captain',
  },
  {
    key: 4,
    value: 'Coach',
    name: 'Coach',
  },
] as const;

export const rosterNotificationsSenderNameTypes = [
  {
    key: 1,
    value: rosterNotificationsSenderNameTypeEnum.OrganizationName,
    name: rosterNotificationsSenderNameTypeEnum.OrganizationName,
  },
  {
    key: 2,
    value: rosterNotificationsSenderNameTypeEnum.StoreName,
    name: rosterNotificationsSenderNameTypeEnum.StoreName,
  },
  {
    key: 3,
    value: rosterNotificationsSenderNameTypeEnum.RosterName,
    name: rosterNotificationsSenderNameTypeEnum.RosterName,
  },
  {
    key: 4,
    value: rosterNotificationsSenderNameTypeEnum.CustomName,
    name: rosterNotificationsSenderNameTypeEnum.CustomName,
  },
] as const;

export const rosterNotificationsLinkDestinations = [
  {
    key: 1,
    value: rosterNotificationsLinkDestinationEnum.OrganizationLandingPage,
    name: 'Organization Landing Page',
  },
  {
    key: 2,
    value: rosterNotificationsLinkDestinationEnum.Storefront,
    name: 'Storefront',
  },
  {
    key: 3,
    value: rosterNotificationsLinkDestinationEnum.CollectionLandingPage,
    name: 'Collection Landing Page',
  },
] as const;

export const voucherNotificationsSenderNameTypes = [
  {
    key: 1,
    value: VoucherNotificationSenderNameEnum.OrganizationName,
    name: VoucherNotificationSenderNameLabel[VoucherNotificationSenderNameEnum.OrganizationName],
  },
  {
    key: 2,
    value: VoucherNotificationSenderNameEnum.StoreName,
    name: VoucherNotificationSenderNameLabel[VoucherNotificationSenderNameEnum.StoreName],
  },
  {
    key: 3,
    value: VoucherNotificationSenderNameEnum.RosterName,
    name: VoucherNotificationSenderNameLabel[VoucherNotificationSenderNameEnum.RosterName],
  },
  {
    key: 4,
    value: VoucherNotificationSenderNameEnum.CustomName,
    name: VoucherNotificationSenderNameLabel[VoucherNotificationSenderNameEnum.CustomName],
  },
] as const;

export const voucherNotificationsLinkDestinations = [
  {
    key: 1,
    value: VoucherNotificationLinkDestinationEnum.OrganizationLandingPage,
    name: VoucherNotificationLinkDestinationLabel[VoucherNotificationLinkDestinationEnum.OrganizationLandingPage],
  },
  {
    key: 2,
    value: VoucherNotificationLinkDestinationEnum.Storefront,
    name: VoucherNotificationLinkDestinationLabel[VoucherNotificationLinkDestinationEnum.Storefront],
  },
  {
    key: 3,
    value: VoucherNotificationLinkDestinationEnum.CollectionLandingPage,
    name: VoucherNotificationLinkDestinationLabel[VoucherNotificationLinkDestinationEnum.CollectionLandingPage],
  },
] as const;

export const classifications = [
  {
    key: 1,
    value: 'Enterprise',
    name: 'Enterprise',
  },
  {
    key: 2,
    value: 'Onboarding',
    name: 'Onboarding',
  },
  {
    key: 3,
    value: 'Disqualified',
    name: 'Disqualified',
  },
  {
    key: 4,
    value: 'Assigned to AE',
    name: 'Assigned to AE',
  },
] as const;

export const playersCount = [
  {
    key: 1,
    value: '1-12',
    name: '1-12',
  },
  {
    key: 2,
    value: '12-25',
    name: '12-25',
  },
  {
    key: 3,
    value: '25-50',
    name: '25-50',
  },
  {
    key: 4,
    value: '50+',
    name: '50+',
  },
] as const;

export const teamGenders = [
  {
    key: 1,
    value: 'Male',
    name: 'Male',
  },
  {
    key: 2,
    value: 'Female',
    name: 'Female',
  },
  {
    key: 3,
    value: 'Coed',
    name: 'Coed',
  },
] as const;

export const lockerTeamTypes = [
  {
    key: 1,
    value: 'youth',
    name: 'Youth',
  },
  {
    key: 2,
    value: 'high school',
    name: 'High School',
  },
  {
    key: 3,
    value: 'prep school',
    name: 'Prep School',
  },
  {
    key: 4,
    value: 'college',
    name: 'College',
  },
  {
    key: 5,
    value: 'adult rec',
    name: 'Adult Rec',
  },
  {
    key: 6,
    value: 'company',
    name: 'Company',
  },
  {
    key: 7,
    value: 'pro sports',
    name: 'Pro Sport',
  },
  {
    key: 8,
    value: 'other',
    name: 'Other',
  },
] as const;

export const productAges = [
  {
    key: 1,
    value: 'Any',
    name: 'Any',
  },
  {
    key: 2,
    value: 'Adult',
    name: 'Adult',
  },
  {
    key: 3,
    value: 'Youth',
    name: 'Youth',
  },
] as const;

export const productGenders = [
  {
    key: 1,
    value: 'M',
    name: 'Male',
  },
  {
    key: 2,
    value: 'F',
    name: 'Female',
  },
  {
    key: 3,
    value: 'Unisex',
    name: 'Unisex',
  },
] as const;

export const decorationMethodsOptions = [
  {
    key: 1,
    value: 'HAG',
    name: 'HAG',
  },
  {
    key: 2,
    value: 'EMB',
    name: 'EMB',
  },
  {
    key: 3,
    value: 'SUB',
    name: 'SUB',
  },
  {
    key: 4,
    value: 'DTG',
    name: 'DTG',
  },
  {
    key: 5,
    value: 'DIP',
    name: 'DIP',
  },
] as const;

export const couponReasons = [
  {
    key: 1,
    value: 'Onboarding',
    name: 'Onboarding',
  },
  {
    key: 2,
    value: 'Sales',
    name: 'Sales',
  },
  {
    key: 3,
    value: 'Customer Experience',
    name: 'Customer Experience',
  },
  {
    key: 4,
    value: 'Marketing',
    name: 'Marketing',
  },
  {
    key: 5,
    value: 'Coupons Sold',
    name: 'Coupons Sold',
  },
  {
    key: 6,
    value: 'Collectives',
    name: 'Collectives',
  },
  {
    key: 7,
    value: 'HR',
    name: 'HR',
  },
  {
    key: 8,
    value: 'Vouchers',
    name: 'Vouchers',
  },
] as const;

export const discountTypes = [
  {
    key: 1,
    value: '0',
    name: 'Dollar amount',
  },
  {
    key: 2,
    value: '1',
    name: 'Percentage',
  },
] as const;

export const couponStatuses = [
  {
    key: 1,
    value: true,
    name: 'Active coupons',
  },
  {
    key: 2,
    value: false,
    name: 'Expired coupons',
  },
] as const;

export const collectionStatuses = [
  {
    key: 1,
    value: false,
    name: 'Active',
  },
  {
    key: 2,
    value: true,
    name: 'Disabled',
  },
] as const;

export const rosterStatuses = [
  {
    key: 1,
    value: false,
    name: 'Active',
  },
  {
    key: 2,
    value: true,
    name: 'Disabled',
  },
] as const;

export const lockerStatuses = [
  {
    key: 1,
    value: 'Draft',
    name: 'Draft',
  },
  {
    key: 2,
    value: 'Unapproved',
    name: 'Unapproved',
  },
  {
    key: 3,
    value: 'Launched',
    name: 'Launched',
  },
  {
    key: 4,
    value: 'Active',
    name: 'Active',
  },
  {
    key: 5,
    value: 'Closed',
    name: 'Closed',
  },
  {
    key: 6,
    value: 'Canceled',
    name: 'Canceled',
  },
  {
    key: 7,
    value: 'Deleted',
    name: 'Deleted',
  },
  {
    key: 8,
    value: 'Undefined',
    name: 'Undefined',
  },
] as const;

export const voucherOrderStatuses = [
  {
    key: 1,
    value: VoucherOrderPaymentStatusEnum.Pending,
    name: 'Unpaid',
  },
  {
    key: 2,
    value: VoucherOrderPaymentStatusEnum.Paid,
    name: 'Paid',
  },
];

export const decorationSizes = [
  {
    key: 1,
    value: 'Youth',
    name: 'Youth',
  },
  {
    key: 2,
    value: 'Regular',
    name: 'Regular',
  },
  {
    key: 3,
    value: 'Large',
    name: 'Large',
  },
] as const;

export const organizationLandingPageTypes = [
  {
    key: 1,
    value: 'Public',
    name: 'Public',
  },
  {
    key: 2,
    value: 'Hidden',
    name: 'Hidden',
  },
] as const;

export const brightnessOptions = [
  {
    key: 1,
    value: 'Dark',
    name: colorBrightnessEnum.Dark,
  },
  {
    key: 2,
    value: 'Light',
    name: colorBrightnessEnum.Light,
  },
] as const;

export const backorderShipDateTypes = [
  {
    key: 0,
    value: dateRangeEnum.all.value,
    name: 'All ship dates',
  },
  {
    key: 1,
    value: dateRangeEnum.tomorrow.value,
    name: 'Ships tomorrow',
  },
  {
    key: 2,
    value: dateRangeEnum.next2Days.value,
    name: 'Ships in next 2 days',
  },
] as const;

export const orderChargeReasonOptions = [
  {
    key: 1,
    value: chargeReasonEnum.AddingPersonalization,
    name: chargeReasonEnum.AddingPersonalization,
  },
  {
    key: 2,
    value: chargeReasonEnum.UpgradingShipping,
    name: chargeReasonEnum.UpgradingShipping,
  },
  {
    key: 3,
    value: chargeReasonEnum.ChangingItem,
    name: chargeReasonEnum.ChangingItem,
  },
  {
    key: 4,
    value: chargeReasonEnum.Other,
    name: chargeReasonEnum.Other,
  },
] as const;

export const artworkFileTasksSortOptions = [
  {
    key: 0,
    value: artworkFileTaskSortOptionsEnum.Status,
    name: 'Sort by Status',
  },
  {
    key: 1,
    value: artworkFileTaskSortOptionsEnum.DecorationMethod,
    name: 'Sort by Decoration Method',
  },
  {
    key: 2,
    value: artworkFileTaskSortOptionsEnum.ShipByDate,
    name: 'Sort by Ship by Date',
  },
] as const;

export const parentLockerOptions = [
  {
    key: 1,
    value: 'withParent',
    name: 'With Parent',
  },
  {
    key: 2,
    value: 'noParent',
    name: 'Without Parent',
  },
] as const;

export const personalizationTypeOptions = [
  {
    key: 1,
    value: personalizationItemTypeEnum.Uniform,
    name: 'Uniform',
  },
  {
    key: 2,
    value: personalizationItemTypeEnum.Standard,
    name: 'Standard',
  },
] as const;

export const expressProductionTypeOptions = [
  {
    key: 1,
    value: true,
    name: 'Express Production',
  },
  {
    key: 2,
    value: false,
    name: 'Not Express Production',
  },
] as const;

export const adminOrganizationTypeOptions = [
  {
    key: 1,
    value: organizationTypeEnum.SportsOrganization,
    name: 'Sports Organization',
  },
  {
    key: 2,
    value: organizationTypeEnum.School,
    name: 'School',
  },
  {
    key: 3,
    value: organizationTypeEnum.BusinessOrCompany,
    name: 'Business / Company',
  },
  {
    key: 4,
    value: organizationTypeEnum.CommunityOrOther,
    name: 'Community / Other',
  },
] as const;

export const dtgPalletSizeOptions = [
  {
    key: 1,
    value: dtgPalletSizeEnum.ExtraLarge,
    name: 'Extra Large (63.00 x 38.60 inch)',
  },
  {
    key: 2,
    value: dtgPalletSizeEnum.ExtraWide,
    name: 'Extra Wide (51.18 x 17.72 inch)',
  },
  {
    key: 3,
    value: dtgPalletSizeEnum.FullSize,
    name: 'Full Size (38.59 x 36.23 inch)',
  },
  {
    key: 4,
    value: dtgPalletSizeEnum.ISOA4,
    name: 'ISO A4 (8.27 x 11.69 inch)',
  },
  {
    key: 5,
    value: dtgPalletSizeEnum.ISOA5,
    name: 'ISO A5 (5.83 x 8.27 inch)',
  },
  {
    key: 6,
    value: dtgPalletSizeEnum.ShirtboardInfant,
    name: 'Shirtboard Infant (6.00 x 6.00 inch)',
  },
  {
    key: 7,
    value: dtgPalletSizeEnum.ShirtboardSleeve,
    name: 'Shirtboard Sleeve (5.00 x 18.00 inch)',
  },
  {
    key: 8,
    value: dtgPalletSizeEnum.ShirtboardStandard,
    name: 'Shirtboard Standard (8.50 x 19.00 inch)',
  },
  {
    key: 9,
    value: dtgPalletSizeEnum.Custom,
    name: 'Custom',
  },
] as const;

export const dtgHighlightGeneratorOptions = [
  {
    key: 1,
    value: dtgHighlightGeneratorEnum.None,
    name: 'None',
  },
  {
    key: 2,
    value: dtgHighlightGeneratorEnum.ChannelBased,
    name: 'Channel based',
  },
  {
    key: 3,
    value: dtgHighlightGeneratorEnum.OnTheFly,
    name: 'On the fly',
  },
  {
    key: 4,
    value: dtgHighlightGeneratorEnum.Combine,
    name: 'Combine',
  },
] as const;

export const dtgPrintDirectionOptions = [
  {
    key: 1,
    value: dtgPrintDirectionEnum.BI,
    name: 'Bidirectional',
  },
  {
    key: 2,
    value: dtgPrintDirectionEnum.LTR,
    name: 'Left to right',
  },
  {
    key: 3,
    value: dtgPrintDirectionEnum.RTL,
    name: 'Right to left',
  },
] as const;

export const ptlWallBinSizeOptions = [
  {
    key: 1,
    value: PtlWallBinSizeEnum.Small,
    name: PtlWallBinSizeEnum.Small,
  },
  {
    key: 2,
    value: PtlWallBinSizeEnum.Medium,
    name: PtlWallBinSizeEnum.Medium,
  },
  {
    key: 3,
    value: PtlWallBinSizeEnum.Large,
    name: PtlWallBinSizeEnum.Large,
  },
] as const;

export const orderTypeOptions = [
  {
    key: 1,
    value: OrderTypeEnum.ExpressProduction,
    name: 'Express Production',
  },
  {
    key: 2,
    value: OrderTypeEnum.Rush,
    name: 'Rush',
  },
  {
    key: 3,
    value: OrderTypeEnum.StandardProduction,
    name: 'Standard Production',
  },
];

export const inventoryStatusOptions = [
  {
    key: 1,
    value: OrderInventoryStatusEnum.Undefined,
    name: 'All inventory statuses',
  },
  {
    key: 2,
    value: OrderInventoryStatusEnum.Available,
    name: 'Available',
  },
  {
    key: 3,
    value: OrderInventoryStatusEnum.Partial,
    name: 'Partial',
  },
  {
    key: 4,
    value: OrderInventoryStatusEnum.NoInventory,
    name: 'No Inventory',
  },
];

export const printStatusOptions = [
  {
    key: 1,
    value: HagPrintRoomJobStatusEnum.Undefined,
    name: 'All Print Statuses',
  },
  {
    key: 2,
    value: HagPrintRoomJobStatusEnum.Printed,
    name: 'Printed',
  },
  {
    key: 3,
    value: HagPrintRoomJobStatusEnum.Assembling,
    name: 'In Print Assembly',
  },
  {
    key: 4,
    value: HagPrintRoomJobStatusEnum.Assembled,
    name: 'Done',
  },
];

const getOneToNNumberOptions = (n: number) => [...Array(n).keys()].map((x) => ({
  key: x + 1,
  value: `${x + 1}`,
  name: `${x + 1}`,
}));

export const numberOfRowsOptions = getOneToNNumberOptions(MAX_NUMBER_OF_ROWS);
export const numberOfRowPositionsOptions = getOneToNNumberOptions(MAX_NUMBER_OF_ROW_POSITIONS);
