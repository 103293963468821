import React, {
  useState,
  useCallback,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { reset } from 'redux-form';
import {
  lockerDetailsUrl,
  organizationDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import { customOrderAssociationEnum } from '@constants/enums/orderEnums';
import { createCustomOrderForm } from '@constants/reduxForms';
import * as orderManagementActions from '@redux/orderManagement/actions';
import { OrderManagementTempOrder } from '@redux/orderManagement/models';
import { parseDateNumeric } from '@util/dateHandler';
import { openInNewTab } from '@util/componentHelper';
import InfoField from '@sharedComponents/Display/InfoField';
import Button from '@sharedComponents/Buttons/Button';
import Link from '@sharedComponents/Navigation/Link';
import EditCustomOrderModal from '../OrderManagementModals/EditCustomOrderModal';
import LinkButton from '@sharedComponents/Buttons/LinkButton';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { CustomOrderFormData } from '@models/forms/OrderManagement/CustomOrderFormData';

interface OwnProps {
  order: OrderManagementTempOrder;
}

const mapDispatchToProps = {
  resetForm: reset,
  saveTempOrder: orderManagementActions.saveTempOrder,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const NewOrderSidebar = React.memo(({
  order,
  resetForm,
  saveTempOrder,
}: Props) => {
  const [
    showEditCustomOrderModal,
    setShowEditCustomOrderModal,
  ] = useState(false);
  const [
    orderForm,
    setOrderForm,
  ] = useState<Nullable<CustomOrderFormData>>(null);

  const openCreateCustomOrderModal = useCallback(() => {
    setShowEditCustomOrderModal(true);
  }, []);

  const closeEditCustomOrderModal = useCallback(() => {
    resetForm(createCustomOrderForm);
    setShowEditCustomOrderModal(false);
  }, [resetForm]);

  const editOrder = useCallback(async () => {
    const res = await saveTempOrder(orderForm!);
    if (res) {
      closeEditCustomOrderModal();
    }
  }, [
    saveTempOrder,
    orderForm,
    closeEditCustomOrderModal,
  ]);

  const openPaymentLink = useCallback(async () => {
    if (!order.paymentLink) return;
    openInNewTab(order.paymentLink);
  }, [order]);

  const setCustomOrderForm = useCallback((form: Nullable<CustomOrderFormData>) => {
    setOrderForm(form);
  }, []);

  const shippingAddress = [
    order.addressLine1,
    order.addressLine2,
    order.city,
    `${order.state} ${order.zip}`.trim(),
    order.country,
  ].filter((el) => !!el).join(', ');

  return (
    <div className='order__sidebar'>
      <EditCustomOrderModal
        isOpen={showEditCustomOrderModal}
        closeModal={closeEditCustomOrderModal}
        editOrder={editOrder}
        initialValues={order}
        setOrderForm={setCustomOrderForm}
      />
      <div className='order__shipping'>
        <div className='order__shipping-header'>
          <div className='new-order__shipping-info-row'>
            <div className='new-order__shipping-info-column'>
              <InfoField
                label={'Received on'}
                value={parseDateNumeric(new Date()) || '-'}
              />
              <InfoField
                label={'SOP Number'}
                value={'-'}
              />
              <InfoField
                label={'Ship Date'}
                value={order.shipDate ? parseDateNumeric(order.shipDate) : '-'}
              />
            </div>
            <div className='new-order__shipping-info-column'>
              <InfoField
                label={order.association === customOrderAssociationEnum.Organization ? 'Organization' : 'Locker'}
                value={
                  order.organizationId
                    ? (
                      <Link
                        url={organizationDetailsUrl(order.organizationId)}
                        text={<span>ORG<span>{order.organizationId}</span> - {order.organizationName}</span>}
                        openInNewTab={true}
                      />
                    )
                    : (
                      <Link
                        url={lockerDetailsUrl(order.lockerId!)}
                        text={<span>L<span>{order.lockerId}</span> - {order.lockerTeamName}</span>}
                        openInNewTab={true}
                      />
                    )
                }
              />
              <InfoField
                label={order.association === customOrderAssociationEnum.Organization ? 'Organization ID' : 'Locker ID'}
                value={order.organizationId ? `ORG${order.organizationId}` : `L${order.lockerId}`}
              />
              <InfoField
                label={'Customer Ship Date'}
                value={order.customerShipDate ? parseDateNumeric(order.customerShipDate) : '-'}
              />
            </div>
          </div>
          {
            order.paymentLink &&
            <div className='new-order__shipping-info-row'>
              <InfoField
                label={'External Payment Link'}
                value={(
                  <div className='new-order__payment-link'>
                    <LinkButton
                      text={(
                        <div>
                          <span>Open in New Tab</span>
                        </div>
                      )}
                      onClick={openPaymentLink}
                      classes={'font-weight-normal'}
                    />
                    <MaterialTooltip
                      tooltipText={`${order.paymentDescription}`}
                      placement={'top'}
                    >
                      <Icon materialIcon={'info_outline'} />
                    </MaterialTooltip>
                  </div>
                )}
              />
            </div>
          }
          <div className='tableView__details--header--title mb-30'>
            <span>Shipping</span>
          </div>
          <div className='new-order__shipping-info-column'>
            <InfoField
              label={'Name'}
              value={order.name}
            />
            <InfoField
              label={'E-mail'}
              value={order.email}
            />
            <InfoField
              label={'Phone'}
              value={order.phoneNumber}
            />
            <InfoField
              label={'Shipping Method'}
              value={order.method}
            />
            <InfoField
              label={'Shipping Address'}
              value={shippingAddress}
            />
          </div>
          <div className='flex'>
            <Button
              type={'secondary'}
              text={'Edit'}
              onClick={openCreateCustomOrderModal}
              classes={'w-100'}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default connector(NewOrderSidebar);
