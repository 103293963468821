import React from 'react';
import PropTypes from 'prop-types';

const PrintSheetItemQuantityCountCard = React.memo(({
  title,
  count,
}) => (
  <div
    // Ignores this element for printed page
    data-html2canvas-ignore={true}
    className='sheet__files-item item-quantity-card'
  >
    <div className='sheet__files-title'>
      <div className='sheet__files-title-left flex'>
        {title}
      </div>
    </div>
    <ul className='sheet__files-list'>
      <li>
        {count}
      </li>
    </ul>
  </div>
));

PrintSheetItemQuantityCountCard.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
};

export default PrintSheetItemQuantityCountCard;
