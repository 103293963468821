import { QueriesVariationDto } from '@api/orders/models';
import { VariationCatalogProductDetailModel } from '@models/Products/VariationCatalogProductDetailModel';

export const variationsToPCProducts =
(variations: Array<QueriesVariationDto>):
  Array<VariationCatalogProductDetailModel> => {
  const products: Array<VariationCatalogProductDetailModel> = [];

  variations?.forEach((variation) => {
    variation.products?.forEach((product) => {
      const newProduct: any = {
        id: 0,
        uuid: '',
        created: '',
        updated: '',
        deleted: '',
        sku: '',
        size: '',
        coloredStyleId: 0,
        description: '',
        weight: 0,
        vendorId: '',
        vendorCode: '',
        qtyVendorMinimum: 0,
        alwaysAvailableVendorInventory: false,
        decorationSize: undefined,
        discontinued: false,
        disabled: false,
        availableVendorInventory: false,
        availableFreeForwardInventory: false,
        availableExpressProduction: false,
        rowVersion: '',
        coloredStyle: {
          imageUrlFront: '',
          floodColorId: 0,
          style: {
            name: '',
            price: 0,
          },
        },
        variationId: 0,
        accountId: undefined,
        salesChannelId: undefined,
        price: 0,
        basePrice: 0,
        fundraisingAmount: 0,
        salesChannelFundraisingPercent: 0,
        salesChannelDiscountPercent: 0,
        styleDiscountAmount: 0,
      };

      newProduct.sku = product.sku;
      newProduct.size = product.size;
      newProduct.coloredStyleId = variation.coloredStyleId;
      newProduct.coloredStyle!.style!.name = variation.item?.name;
      newProduct.coloredStyle!.style!.price = variation.item?.price ?? 0;
      newProduct.coloredStyle!.imageUrlFront = variation.images?.[0]?.src ?? '';
      newProduct.coloredStyle!.colorA = variation.color?.name ?? '-';

      newProduct.variationId = variation.id!;
      newProduct.salesChannelId = variation.item?.salesChannelId
        ? variation.item.salesChannelId
        : undefined;
      newProduct.price = variation.item?.price;
      newProduct.basePrice = variation.item?.pricing?.basePrice;
      newProduct.fundraisingAmount = variation.item?.pricing?.fundraisingAmount;
      newProduct.salesChannelFundraisingPercent = variation.item?.pricing?.salesChannelFundraisingPercent;
      newProduct.salesChannelDiscountPercent = variation.item?.pricing?.salesChannelDiscountPercent;
      newProduct.styleDiscountAmount = variation.item?.pricing?.styleDiscountAmount;
      newProduct.created = variation.created;
      newProduct.updated = variation.updated;
      newProduct.deleted = variation.deleted;

      products.push(newProduct);
    });
  });

  return products;
};
