import React, { useCallback } from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: (item: ProductionAssemblyItemGroupDto) => void;
  item: Nullable<ProductionAssemblyItemGroupDto>;
}

type Props = OwnProps;

const OrderManagementEditPersonalizationPriceConfirmationModal = React.memo<Props>(({
  isOpen,
  closeModal,
  onConfirm,
  item,
}) => {
  const handleConfirm = useCallback(() => {
    if (item) onConfirm(item);
  }, [
    item,
    onConfirm,
  ]);

  if (!item) return null;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'l'}
      modalWidth={'m'}
      isPadded={true}
      title={`Put ${item.sku} On Hold`}
      buttons={(
        <ModalButtons
          confirmBtnText={'I am sure'}
          cancelBtnText={'Cancel'}
          onConfirm={handleConfirm}
          onClose={closeModal}
          confirmBtnDisabled={!item}
        />
      )}
    >
      <div>
        <p>
          Are you sure you want to send <b>{item.quantity}</b> {item.sku}{' '}
          item{(item.quantity === 1 ? '' : 's')} back to Awaiting Inventory?
        </p>
        <p>You must <i>SEPARATELY</i> update the inventory management system for a backorder.</p>
      </div>
    </Modal>
  );
});

export default OrderManagementEditPersonalizationPriceConfirmationModal;
