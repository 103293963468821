import { Dispatch } from 'redux';
import { SubmitStatusEnum } from '@api/financialServices/models';
import { getHomefieldApiOrdermanagementOrders } from '@api/fulfillment/order-management';
import { GetHomefieldApiOrdermanagementOrdersParams } from '@api/fulfillment/models/getHomefieldApiOrdermanagementOrdersParams';
import orderManagementUrls from '@constants/sqdApiUrls/orderManagementUrls';
import lockerUrls from '@constants/sqdApiUrls/lockerUrls';
import couponsUrls from '@constants/sqdApiUrls/couponsUrls';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import { CustomOrderFormData } from '@models/forms/OrderManagement/CustomOrderFormData';
import { mapOrderShipmentErrors } from '@util/componentHelpers/orderManagementHelper';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
  makeApiCallWithSubmissionError,
} from '@util/apiHelper';
import { parseDateFromLocalTimezone } from '@util/dateHandler';
import { mapToDecorationMethod } from '@util/decorationMethodHelper';
import {
  fulfillmentApi,
  slServicesApi,
} from '../../sqdApis';
import * as actionTypes from './types';
import { OrderManagementTempItem } from './models';

type SearchOrderAction = {
  type: typeof actionTypes.SEARCH_ORDER;
  payload: any;
};

export const searchOrder = (orderData: any): SearchOrderAction => ({
  type: actionTypes.SEARCH_ORDER,
  payload: orderData,
});

type UpdateOrderAction = {
  type: typeof actionTypes.UPDATE_ORDER;
  payload: any;
};

export const updateOrder = (orderData: any): UpdateOrderAction => ({
  type: actionTypes.UPDATE_ORDER,
  payload: orderData,
});

type UpdateOrderItemArtworkAction = {
  type: typeof actionTypes.UPDATE_ORDER_ARTWORK;
  payload: any;
};

export const updateOrderItemArtwork = (artworks: any): UpdateOrderItemArtworkAction => ({
  type: actionTypes.UPDATE_ORDER_ARTWORK,
  payload: artworks,
});

type UpdateCurrentQueueAction = {
  type: typeof actionTypes.UPDATE_CURRENT_QUEUE;
  payload: any;
};

export const updateCurrentQueue = (data: any): UpdateCurrentQueueAction => ({
  type: actionTypes.UPDATE_CURRENT_QUEUE,
  payload: data,
});

type UpdateOrderManagementTableStateAction = {
  type: typeof actionTypes.UPDATE_ORDER_MANAGEMENT_TABLE_STATE;
  payload: any;
};

export const updateOrderManagementTableState = (data: any): UpdateOrderManagementTableStateAction => ({
  type: actionTypes.UPDATE_ORDER_MANAGEMENT_TABLE_STATE,
  payload: data,
});

type SaveTempOrderAction = {
  type: typeof actionTypes.UPDATE_SAVE_TEMP_ORDER;
  payload: CustomOrderFormData;
};

export const saveTempOrder = (data: CustomOrderFormData): SaveTempOrderAction => ({
  type: actionTypes.UPDATE_SAVE_TEMP_ORDER,
  payload: data,
});

type SaveTempItemsAction = {
  type: typeof actionTypes.UPDATE_SAVE_TEMP_ITEMS;
  payload: any;
};

export const saveTempItems = (data: OrderManagementTempItem[]): SaveTempItemsAction => ({
  type: actionTypes.UPDATE_SAVE_TEMP_ITEMS,
  payload: data,
});

type DiscardTempOrderAction = {
  type: typeof actionTypes.DISCARD_TEMP_ORDER;
  payload: null;
};

export const discardTempOrder = (): DiscardTempOrderAction => ({
  type: actionTypes.DISCARD_TEMP_ORDER,
  payload: null,
});

type ResetOrderManagementTableStateAction = {
  type: typeof actionTypes.RESET_ORDER_MANAGEMENT_TABLE_STATE;
};

export const resetOrderManagementTableState = (): ResetOrderManagementTableStateAction => ({
  type: actionTypes.RESET_ORDER_MANAGEMENT_TABLE_STATE,
});

type UpdateOrderNotesAction = {
  type: typeof actionTypes.UPDATE_ORDER_NOTES;
  payload: any;
};

export const updateOrderNotes = (data: any): UpdateOrderNotesAction => ({
  type: actionTypes.UPDATE_ORDER_NOTES,
  payload: data,
});

type UpdateAccountingSubmitResultsAction = {
  type: typeof actionTypes.UPDATE_ACCOUNTING_SUBMIT_RESULTS;
  payload: any;
};

export const updateAccountingSubmitResults = (data: any): UpdateAccountingSubmitResultsAction => ({
  type: actionTypes.UPDATE_ACCOUNTING_SUBMIT_RESULTS,
  payload: data,
});

type UpdateOrderShipmentsAction = {
  type: typeof actionTypes.UPDATE_ORDER_SHIPMENTS;
  payload: any;
};

export const updateOrderShipments = (data: any): UpdateOrderShipmentsAction => ({
  type: actionTypes.UPDATE_ORDER_SHIPMENTS,
  payload: data,
});

type UpdateOrderChangeLogsAction = {
  type: typeof actionTypes.UPDATE_ORDER_CHANGE_LOGS;
  payload: any;
};

export const updateOrderChangeLogs = (data: any): UpdateOrderChangeLogsAction => ({
  type: actionTypes.UPDATE_ORDER_CHANGE_LOGS,
  payload: data,
});

type UpdateOrderStatusHistoryAction = {
  type: typeof actionTypes.UPDATE_ORDER_STATUS_HISTORY;
  payload: any;
};

export const updateOrderStatusHistory = (data: any): UpdateOrderStatusHistoryAction => ({
  type: actionTypes.UPDATE_ORDER_STATUS_HISTORY,
  payload: data,
});

type UpdateOrderFlagsAction = {
  type: typeof actionTypes.UPDATE_ORDER_FLAGS;
  payload: any;
};

export const updateOrderFlags = (data: any): UpdateOrderFlagsAction => ({
  type: actionTypes.UPDATE_ORDER_FLAGS,
  payload: data,
});

type UpdateCouponDetailsForOrderAction = {
  type: typeof actionTypes.UPDATE_ORDER_COUPON_DETAILS;
  payload: any;
};

export const updateCouponDetailsForOrder = (data: any): UpdateCouponDetailsForOrderAction => ({
  type: actionTypes.UPDATE_ORDER_COUPON_DETAILS,
  payload: data,
});

export type OrderManagementAction = SearchOrderAction
  | UpdateOrderAction
  | UpdateOrderItemArtworkAction
  | UpdateCurrentQueueAction
  | UpdateOrderManagementTableStateAction
  | SaveTempOrderAction
  | SaveTempItemsAction
  | DiscardTempOrderAction
  | ResetOrderManagementTableStateAction
  | UpdateOrderNotesAction
  | UpdateAccountingSubmitResultsAction
  | UpdateOrderShipmentsAction
  | UpdateOrderChangeLogsAction
  | UpdateOrderStatusHistoryAction
  | UpdateOrderFlagsAction
  | UpdateCouponDetailsForOrderAction;

export const fetchOrder = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(orderManagementUrls.searchOrder(orderNumber), { handleBlockingLoading: false });
  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    return dispatch(searchOrder(res));
  }
};

export const fetchOrderNotes = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(orderManagementUrls.notes(orderNumber), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrderNotes(res));
  }
};

export const fetchOrderFlags = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(orderManagementUrls.orderFlags(orderNumber), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    return dispatch(updateOrderFlags(res));
  }
};

export const deleteNote = (orderNumber: number, noteId: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.delete(orderManagementUrls.note(orderNumber, noteId));
  const res = await makeApiCallWithErrorModal(call);
  if (res?.success) {
    return fetchOrderNotes(orderNumber)(dispatch);
  }

  return res;
};

export const fetchOrderByOrderNumber = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(orderManagementUrls.orderByOrderNumber(orderNumber), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    return dispatch(updateOrder(res));
  }
};

export const getArtworksForLocker = (lockerId: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(lockerUrls.lockerFulfillment.lockerArtworks(lockerId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    const data = res.map((artwork: any) => {
      const mappedDecorationMethods = artwork.decoration_Methods.map((m: any) => mapToDecorationMethod(m));

      return {
        id: artwork.id,
        image: artwork.image,
        originalImage: artwork.original_Image,
        helpText: artwork.help_Text,
        decorationMethods: mappedDecorationMethods,
      };
    });

    return dispatch(updateOrderItemArtwork(data));
  }
};

export const addOrderItems = (orderItem: any, orderNumber: number) => async (dispatch: Dispatch) => {
  const model = {
    orderNumber,
    sku: orderItem.sku,
    logoId: orderItem.logo && orderItem.logo.id,
    quantity: orderItem.quantity,
    decorationLocation: orderItem.decorationLocation,
    decorationMethod: orderItem.decorationMethod,
    paymentType: orderItem.paymentType,
    chargeAmount: orderItem.chargeAmount,
  };

  const call = fulfillmentApi.post(orderManagementUrls.addOrderLineItems, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.success) {
    fetchOrderByOrderNumber(orderNumber)(dispatch);
  }

  return res;
};

export const fetchOrderTable = (
  pageNumber = 1,
  pageSize = 10,
  sortBy?: string,
  sortDirection?: string,
  filter: string | undefined = undefined,
  accountingSubmitStatus?: SubmitStatusEnum | undefined,
  dateRange = dateRangeEnum.past60Days.value,
  lockerIds: string | undefined = undefined,
  userId: string | undefined = undefined,
  partnerName: string | undefined = undefined
) => async (dispatch: Dispatch) => {
  const range = dateRangeEnum[dateRange];

  const params: GetHomefieldApiOrdermanagementOrdersParams = {
    pageNumber,
    pageSize,
    sortBy: (sortBy || 'datePlaced') as GetHomefieldApiOrdermanagementOrdersParams['sortBy'],
    sortDirection: (sortDirection || 'descending') as GetHomefieldApiOrdermanagementOrdersParams['sortDirection'],
    filter,
    accountingSubmitStatus: accountingSubmitStatus as GetHomefieldApiOrdermanagementOrdersParams['accountingSubmitStatus'],
    minDatePlaced: range.minDatePlaced?.toISOString(),
    maxDatePlaced: range.maxDatePlaced?.toISOString(),
    lockerIds,
    userId,
    partnerName,
  };

  const call = getHomefieldApiOrdermanagementOrders(params);
  const data = await makeApiCall(call);

  if (data?.pageNumber !== undefined) {
    dispatch(
      updateCurrentQueue({
        totalPages: data.totalPages,
        hasPreviousPage: data.hasPreviousPage,
        hasNextPage: data.hasNextPage,
        queue: data.items,
      })
    );
  }

  return data;
};

export const fetchOrderTableStateUpdate = (
  pageNumber = 1,
  pageSize = 10,
  sortBy?: string,
  sortDirection?: string,
  filter: string | undefined = undefined,
  accountingSubmitStatus?: SubmitStatusEnum | undefined,
  dateRange = dateRangeEnum.past60Days.value,
  lockerIds: string | undefined = undefined,
  userId: string | undefined = undefined,
  onlyRequiredItemOrders = false,
  onlyPrdOrders = false,
  partnerFilter: string | undefined = undefined
) => async (dispatch: Dispatch) => {
  const res = await fetchOrderTable(
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    filter,
    accountingSubmitStatus,
    dateRange,
    lockerIds,
    userId,
    partnerFilter
  )(dispatch);

  if (res && res.pageNumber !== null && res.pageNumber !== undefined) {
    dispatch(
      updateOrderManagementTableState({
        filter,
        pageNumber: (res.pageNumber !== null && res.pageNumber !== undefined) ? res.pageNumber : pageNumber,
        pageSize,
        dateRange,
        sortOrder: sortDirection,
        sortByState: sortBy,
        accountingSubmitStatus,
        lockerIds,
        userId,
        partnerFilter,
      })
    );
  }
};

export const fetchAccountingSubmitResults = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(orderManagementUrls.accountingSubmitResults(orderNumber), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    await dispatch(updateAccountingSubmitResults(res));
  }
};

export const getOrderShipments = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(orderManagementUrls.shipments(orderNumber), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrderShipments(res));
  }
};

export const updateOrderShipmentData = (
  orderNumber: number,
  shipmentNumber: number,
  actualShipDate: string,
  trackingNumber: string,
  orderItemIds: number[]
) => async (dispatch: Dispatch) => {
  const model = {
    shipmentNumber,
    actualShipDate: parseDateFromLocalTimezone(actualShipDate),
    trackingNumber,
    orderItemIds,
  };
  const call = fulfillmentApi.put(orderManagementUrls.shipments(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });
  const res = await makeApiCallWithSubmissionError(call, mapOrderShipmentErrors);
  if (res) {
    await getOrderShipments(orderNumber)(dispatch);
  }
};

export const getOrderChangeLogs = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(orderManagementUrls.changeLogs(orderNumber), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrderChangeLogs(res));
  }
};

export const getOrderStatusHistory = (orderNumber: number) => async (dispatch: Dispatch) => {
  const call = fulfillmentApi.get(orderManagementUrls.statusHistory(orderNumber), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrderStatusHistory(res));
  }
};

export const updateShippingMethod = (
  orderId: number,
  orderNumber: number,
  shippingMethod: string
) => async (dispatch: Dispatch) => {
  const body = JSON.stringify({
    shippingMethod,
    reason: '',
  });
  const call = fulfillmentApi.put(orderManagementUrls.updateShippingMethod(orderId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);
  if (res.success) {
    await fetchOrderByOrderNumber(orderNumber)(dispatch);
    await getOrderChangeLogs(orderNumber)(dispatch);
  }

  return res;
};

export const updateShippingEmail = (
  orderId: number,
  orderNumber: number,
  email: string
) => async (dispatch: Dispatch) => {
  const body = JSON.stringify({
    shippingInfoEmail: email,
    reason: '',
  });
  const call = fulfillmentApi.put(orderManagementUrls.updateShippingEmail(orderId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  if (res.success) {
    await fetchOrderByOrderNumber(orderNumber)(dispatch);
    await getOrderChangeLogs(orderNumber)(dispatch);
  }

  return res;
};

export const fetchCouponDetailsForOrder = (couponCode = '') => async (dispatch: Dispatch) => {
  const pageNumber = 1;
  const pageSize = 5;
  const sortBy = '';
  const sortOrder = '';
  const discountType = '';
  const reason = '';
  const createdBy = '';
  const valid = null;
  const search = '';

  const call = slServicesApi.get(couponsUrls.coupons(
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    discountType,
    reason,
    createdBy,
    valid,
    search,
    couponCode
  ), { handleBlockingLoading: false });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.items && res.items.length > 0) {
    return dispatch(updateCouponDetailsForOrder(res.items[0]));
  }

  return dispatch(updateCouponDetailsForOrder(null));
};

export const fetchCouponByCode = async (couponCode: string) => {
  const pageNumber = 1;
  const pageSize = 1;
  const sortBy = null;
  const sortOrder = null;
  const discountType = null;
  const reason = null;
  const createdBy = null;
  const valid = null;
  const search = null;
  const call = slServicesApi.get(couponsUrls.coupons(
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    discountType,
    reason,
    createdBy,
    valid,
    search,
    couponCode
  ));
  const res = await makeApiCall(call);
  if (res && res.items && res.items.length > 0) {
    return res.items[0];
  }

  return null;
};

export const findCouponSuggestions = async (couponCode: string) => {
  const call = slServicesApi.get(couponsUrls.couponSuggestions(couponCode), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  return res;
};
