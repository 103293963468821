import React, { useMemo } from 'react';
import {
  FormState,
  UseFormRegister,
  UseFormSetValue,
  Path,
} from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { VoucherNotificationSenderNameEnum } from '@api/financialServices/models/voucherNotificationSenderNameEnum';
import { VoucherNotificationLinkDestinationEnum } from '@api/financialServices/models/voucherNotificationLinkDestinationEnum';
import {
  voucherNotificationsLinkDestinations,
  voucherNotificationsSenderNameTypes,
} from '@constants/options/optionsValues';
import { dropdownSizeL } from '@constants/values';
import { mapToOptionsList } from '@util/mappingHelper';
import InfoField from '@sharedComponents/Display/InfoField';
import FormDateInput from '@sharedComponents/Form/FormDateInput';
import HookFormDropdown from '@sharedComponents/Form/HookFormDropdown';
import FormInput from '@sharedComponents/Form/FormInput';
import InfoIconWithTooltip from '@sharedComponents/Icons/InfoIconWithTooltip';
import { Option } from '@models/common/Option';
import { VoucherNotificationSettingsFormData } from '@models/forms/Vouchers/VoucherNotificationSettingsFormData';

interface OwnProps<TForm extends VoucherNotificationSettingsFormData> {
  register: UseFormRegister<TForm>;
  setValue: UseFormSetValue<TForm>;
  errors: FormState<TForm>['errors'];
  senderName: Nullable<VoucherNotificationSenderNameEnum>;
  initialValues: Nullable<VoucherNotificationSettingsFormData>;
  organizationId?: Nullable<number>;
}

type Props<TForm extends VoucherNotificationSettingsFormData> = OwnProps<TForm>;

const NotificationSettings = <TForm extends VoucherNotificationSettingsFormData>({
  register,
  setValue,
  errors,
  senderName,
  initialValues,
  organizationId,
}: Props<TForm>) => {
  const linkDestinationOptions = useMemo(() => mapToOptionsList<string, any>({
    list: voucherNotificationsLinkDestinations.map((option: Option<VoucherNotificationLinkDestinationEnum>) => ({
      ...option,
      disabled: option.value === VoucherNotificationLinkDestinationEnum.OrganizationLandingPage
        && !organizationId,
    })),
    key: 'key',
    value: 'value',
    name: 'name',
    emptyOption: {
      name: 'Choose Link Destination',
      disabled: true,
    },
  }), [organizationId]);

  const senderNameTypeOptions = useMemo(() => mapToOptionsList<string, any>({
    list: voucherNotificationsSenderNameTypes.map((option: Option<VoucherNotificationSenderNameEnum>) => ({
      ...option,
      disabled: option.value === VoucherNotificationSenderNameEnum.OrganizationName && !organizationId,
    })),
    key: 'key',
    value: 'value',
    name: 'name',
    emptyOption: {
      name: 'Choose Sender Name',
      disabled: true,
    },
  }), [organizationId]);

  return (
    <>
      <Grid
        item={true}
        xs={12}
        className={'voucherOrders__edit-form__section-title'}
      >
        <span className='card-title'>Notification Settings</span>
      </Grid>

      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div className='align__center'>
                  <span>Send Date</span>
                  <span className='required'>*</span>
                  <InfoIconWithTooltip
                    tooltipText={'Send out only if paid'}
                  />
                </div>
              )}
              value={(
                <FormDateInput<TForm>
                  id={'sendDate'}
                  name={'notificationSettings.sendDate' as Path<TForm>}
                  placeholder={'Select Send Date'}
                  initialValue={initialValues?.notificationSettings?.sendDate}
                  register={register}
                  setValue={setValue}
                  error={(errors as FormState<VoucherNotificationSettingsFormData>['errors']).notificationSettings?.sendDate?.message}
                  width={100}
                />
              )}
              labelClass={'mb-5'}
            />
          </div>
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Link Destination</span>
                  <span className='required'>*</span>
                </div>
              )}
              value={(
                <HookFormDropdown<TForm, string>
                  name={'notificationSettings.linkDestination' as Path<TForm>}
                  register={register}
                  initialValue={initialValues?.notificationSettings?.linkDestination ?? undefined}
                  options={linkDestinationOptions}
                  change={setValue}
                  size={dropdownSizeL}
                  error={(errors as FormState<VoucherNotificationSettingsFormData>['errors']).notificationSettings?.linkDestination?.message}
                />
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container={true}
        item={true}
        xs={12}
        spacing={2}
        className={'mt-20'}
      >
        <Grid
          item={true}
          xs={6}
        >
          <div>
            <InfoField
              label={(
                <div>
                  <span>Sender Name</span>
                  <span className='required'>*</span>
                </div>
              )}
              value={(
                <HookFormDropdown<VoucherNotificationSettingsFormData, string>
                  name={'notificationSettings.senderName'}
                  register={register as unknown as UseFormRegister<VoucherNotificationSettingsFormData>}
                  initialValue={initialValues?.notificationSettings?.senderName ?? undefined}
                  options={senderNameTypeOptions}
                  change={setValue as unknown as UseFormSetValue<VoucherNotificationSettingsFormData>}
                  size={dropdownSizeL}
                  error={(errors as FormState<VoucherNotificationSettingsFormData>['errors']).notificationSettings?.senderName?.message}
                />
              )}
            />
          </div>
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          {
            senderName === VoucherNotificationSenderNameEnum.CustomName &&
                <div>
                  <InfoField
                    label={(
                      <div>
                        <span>Custom Sender Name</span>
                        <span className='required'>*</span>
                      </div>
                    )}
                    value={(
                      <FormInput
                        id={'customSenderName'}
                        placeholder={'Enter Custom Sender Name'}
                        type={'text'}
                        initialValue={initialValues?.notificationSettings?.customSenderName ?? undefined}
                        error={(errors as FormState<VoucherNotificationSettingsFormData>['errors']).notificationSettings?.customSenderName}
                        register={register}
                        {...register('notificationSettings.customSenderName' as Path<TForm>)}
                      />
                    )}
                  />
                </div>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationSettings;
