import React from 'react';
import {
  Page, Text, View, Document, StyleSheet,
} from '@react-pdf/renderer';
import { HagPrintRoomOrderDetailsDto } from '@api/fulfillment/models';
import { PrintableViewOrderPages } from './PrintableViewOrderPages';
import { ColorDto } from '@api/productCatalog/models';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

interface OwnProps {
  orders: HagPrintRoomOrderDetailsDto[];
  colors?: ColorDto[];
}

type Props = OwnProps;

export const PrintableViewDocument: React.FC<Props> = ({
  orders, colors,
}) => {
  if (!orders.length) {
    return (
      <Document>
        <Page size='LETTER'>
          <View style={styles.section}>
            <Text>NO ORDER INFORMATION</Text>
          </View>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      {orders.map((order) => (
        <PrintableViewOrderPages
          key={order.orderNumber}
          order={order}
          colors={colors}
        />
      ))}
    </Document>
  );
};

export default PrintableViewDocument;
