import React from 'react';

export const createOptionsList = ({
  list = [],
  key = '',
  value = '',
  name = '',
  emptyOption = {},
}) => {
  const options = list && list.map((item) => (
    <option
      key={key ? item[key] : item}
      value={value ? item[value] : item}
      disabled={item.disabled || false}
    >
      {
        typeof name === 'function'
          ? name(item)
          : (name ? item[name] : item)
      }
    </option>
  ));

  if (emptyOption && emptyOption.name) {
    return [
      <option
        disabled={emptyOption.disabled || false}
        value=''
        key='-1'
      >
        {emptyOption.name}
      </option>,
      ...options,
    ];
  }

  return options;
};

export const assigneesOptions = ({
  assignees,
  emptyOption = {},
}) => {
  const options = assignees.map((account) => {
    const displayString = account.displayName || account.email;

    return (
      <option
        key={account.id}
        value={account.id}
      >
        {displayString}
      </option>
    );
  });

  if (emptyOption && emptyOption.name) {
    return [
      <option
        disabled={emptyOption.disabled || false}
        value=''
        key='-1'
      >
        {emptyOption.name}
      </option>,
      ...options,
    ];
  }

  return options;
};

export const generalOptions = (options) => options.map((option) => (
  <option
    key={option.key}
    value={option.value}
    disabled={option.disabled || false}
  >
    {option.name}
  </option>
));

export const generalApiOptions = (options) => {
  if (!options) {
    return [];
  }

  return options.map((option) => (
    <option
      key={option.id}
      value={option.id}
    >
      {option.name}
    </option>
  ));
};

export const createDecorationLocationOptions = (options, value) => options.map((option) => (
  <option
    key={option.id}
    value={value ? option[value] : option.id}
  >
    {option.code} - {option.label}
  </option>
));
