/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type PrintRoomTabEnum = 'PrintReady' | 'Printing' | 'Printed' | 'Priority';


export const PrintRoomTabEnum = {
  PrintReady: 'PrintReady' as PrintRoomTabEnum,
  Printing: 'Printing' as PrintRoomTabEnum,
  Printed: 'Printed' as PrintRoomTabEnum,
  Priority: 'Priority' as PrintRoomTabEnum,
};
