import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  Field,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import {
  CollectionViewModel,
  RosterTeamBaseViewModel,
} from '@api/squadlockerServices/models';
import { getCollectionStatusOptions } from '@constants/options/options';
import { collectionForm } from '@constants/reduxForms';
import * as lockerManagerActions from '@redux/lockerManager/actions';
import { RootState } from '@redux/index/reducers';
import { parseStringToBoolean } from '@util/valueHelpers';
import Input from '@sharedComponents/Form/Input';
import Tabs from '@sharedComponents/Tabs/Tabs';
import FormDropdown from '@sharedComponents/Form/FormDropdown';
import CollectionFormPropertiesTab from './CollectionFormContent/CollectionFormPropertiesTab';
import CollectionFormItemsTab from './CollectionFormContent/CollectionFormItemsTab';
import { CollectionFormData } from '@models/forms/Collections/CollectionFormData';

const collectionStatusOptions = getCollectionStatusOptions(true, 'Choose Status', true);

enum CollectionDetailsTabEnum {
  Items = 'Items',
  Properties = 'Properties',
}

interface OwnProps {
  disabled?: {
    rosterTeamIds?: boolean;
    disabled?: boolean;
    bundled?: boolean;
    required?: boolean;
  };
  hasActiveVouchers?: boolean;
}

const mapStateToProps = ({ lockerManager }: RootState) => ({
  lockerRosters: lockerManager.lockerRosters as RosterTeamBaseViewModel[],
  lockerCollections: lockerManager.lockerCollections as CollectionViewModel[],
});

const mapDispatchToProps = {
  fetchRostersForLocker: lockerManagerActions.fetchRostersForLocker,
  fetchAllCollectionsForLocker: lockerManagerActions.fetchAllCollectionsForLocker,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps & InjectedFormProps<CollectionFormData, FormProps, string[]>;

const CollectionForm = React.memo<Props>(({
  handleSubmit,
  initialValues,
  disabled = {},
  change,
  lockerRosters,
  lockerCollections,
  fetchRostersForLocker,
  fetchAllCollectionsForLocker,
  hasActiveVouchers = false,
}) => {
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<CollectionDetailsTabEnum>(CollectionDetailsTabEnum.Items);

  useEffect(() => {
    fetchRostersForLocker(initialValues.lockerId);
    fetchAllCollectionsForLocker(initialValues.lockerId);
  }, [
    fetchRostersForLocker,
    fetchAllCollectionsForLocker,
    initialValues.lockerId,
  ]);

  const selectTab = useCallback((newSelectedTab: string) => {
    setSelectedTab(newSelectedTab as CollectionDetailsTabEnum);
  }, []);

  const getTabs = () => {
    const bundledLockerCollectionExists = lockerCollections.some((collection) => (collection.bundled
      && (!initialValues.id || (initialValues.id && collection.id !== initialValues.id))
    ));

    const tabs = [
      {
        title: 'Items',
        name: CollectionDetailsTabEnum.Items,
        content: (
          <CollectionFormItemsTab
            change={change}
          />
        ),
      },
      {
        title: 'Properties',
        name: CollectionDetailsTabEnum.Properties,
        content: (
          <CollectionFormPropertiesTab
            collectionId={initialValues.id!}
            rosterTeamIds={initialValues.rosterTeamIds!}
            rosters={lockerRosters}
            change={change}
            disabled={{
              ...disabled,
              bundled: disabled.bundled ?? bundledLockerCollectionExists,
            }}
            hasActiveVouchers={hasActiveVouchers}
          />
        ),
      },
    ];

    return tabs;
  };

  return (
    <form
      className='redux-form'
      id={collectionForm}
      onSubmit={handleSubmit}
    >
      <div>
        <div className='redux-form__section pb-5'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Collection Name
                <span className='required'>*</span>
              </label>
              <div data-test='collection-name-text-field'>
                <Field
                  name={'name'}
                  placeholder={'Enter a name'}
                  component={Input}
                  type={'text'}
                />
              </div>
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label'>
                Status
                <span className='required'>*</span>
              </label>
              <div data-test='collection-status-select'>
                <Field
                  name={'disabled'}
                  disabled={disabled.disabled}
                  component={FormDropdown}
                  initialValue={initialValues.disabled}
                  change={change}
                  options={collectionStatusOptions}
                  parse={parseStringToBoolean}
                  disabledTooltipText={
                    hasActiveVouchers
                      ? 'Cannot change collection\'s status because the collection has an active voucher associated with it'
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <Tabs
              tabs={getTabs()}
              selectedTab={selectedTab}
              selectTab={selectTab}
            />
          </div>
        </div>
      </div>
    </form>
  );
});

export default connector(reduxForm<CollectionFormData, FormProps, string[]>({
  form: collectionForm,
  enableReinitialize: true,
  shouldError: () => true,
})(CollectionForm));
