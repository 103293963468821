const ID = 'll-scheduling';

export const UPDATE_SCHEDULING_QUEUES = `${ID}/UPDATE_SCHEDULING_QUEUES`;
export const UPDATE_SCHEDULING_QUEUE = `${ID}/UPDATE_SCHEDULING_QUEUE`;
export const UPDATE_READY_SCHEDULING_QUEUE_ITEMS = `${ID}/UPDATE_READY_SCHEDULING_QUEUE_ITEMS`;
export const UPDATE_IN_PROGRESS_SCHEDULING_QUEUE_ITEMS = `${ID}/UPDATE_IN_PROGRESS_SCHEDULING_QUEUE_ITEMS`;
export const DISPATCH_SCHEDULING_QUEUE_ITEMS_TO_PICKING = `${ID}/DISPATCH_SCHEDULING_QUEUE_ITEMS_TO_PICKING`;
export const GET_SCHEDULING_QUEUE_TOOLTIP = `${ID}/GET_SCHEDULING_QUEUE_TOOLTIP`;
export const GET_SCHEDULING_QUEUE_IN_PROGRESS_TOOLTIP = `${ID}/GET_SCHEDULING_QUEUE_IN_PROGRESS_TOOLTIP`;

export default ID;
