import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import { sportForm } from '@constants/reduxForms';
import { validateSport } from '@redux/sports/validations';
import SportForm from '../SportForm';

const SportAddModal = (props) => {
  const {
    isOpen,
    handleSubmit,
    closeModal,
  } = props;

  return (
    <Modal
      title={'Add Sport'}
      isOpen={isOpen}
      modalHeight={'xl'}
      modalWidth={'m'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={sportForm}
        />
      )}
    >
      <SportForm
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

SportAddModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default (reduxForm({
  form: sportForm,
  validate: validateSport,
  shouldError: () => true,
})(SportAddModal));
