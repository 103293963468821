import React from 'react';
import { lookupEntitiesUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const LookupEntitiesLink = React.memo(() => (
  <MenuTile
    title={'Lookup Entities'}
    url={lookupEntitiesUrl}
    icon={'image_search'}
  />
));

export default LookupEntitiesLink;
