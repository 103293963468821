import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import InputSpinner from '@sharedComponents/Inputs/InputSpinner';

interface OwnProps {
  updateQuantity: (newQuantity: number) => void;
  selectedQuantity: number;
  min?: number;
  defaultValue?: number;
  classes?: string;
  disabled?: boolean;
}

type Props = OwnProps;

const QuantityInput = React.memo<Props>(({
  updateQuantity,
  selectedQuantity,
  defaultValue = 1,
  min = 1,
  classes = '',
  disabled = false,
}) => {
  const [
    newQuantity,
    setNewQuantity,
  ] = useState(selectedQuantity);

  useEffect(() => {
    setNewQuantity(selectedQuantity);
  }, [selectedQuantity]);

  const handleChange = useCallback((e) => {
    const newQuantityValue = Number(e.target.value || defaultValue);

    setNewQuantity(newQuantityValue);
    updateQuantity(newQuantityValue);
  }, [
    updateQuantity,
    defaultValue,
  ]);

  return (
    <InputSpinner
      value={newQuantity}
      placeholder={(newQuantity | defaultValue).toString()}
      onChange={handleChange}
      classes={`input-spinner ${classes}`}
      min={min}
      name={'quantity'}
      disabled={disabled}
    />
  );
});

export default QuantityInput;
