import React from 'react';
import PropTypes from 'prop-types';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

const VendorsActionsColumn = React.memo(({
  vendor,
  onVendorDelete,
  onVendorEdit,
}) => (
  <ShowMoreActionsButton
    actions={[
      {
        text: 'Edit Vendor',
        action: onVendorEdit.bind(null, vendor),
        isVisible: true,
      },
      {
        text: 'Delete Vendor',
        action: onVendorDelete.bind(null, vendor),
        isDangerous: true,
        isVisible: true,
      },
    ]}
  />
));

VendorsActionsColumn.propTypes = {
  vendor: PropTypes.object.isRequired,
  onVendorEdit: PropTypes.func.isRequired,
  onVendorDelete: PropTypes.func.isRequired,
};

export default VendorsActionsColumn;
