/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Financials Homefield API
 * OpenAPI spec version: v1
 */

export type VoucherNotificationSenderNameEnum = 'Undefined' | 'OrganizationName' | 'StoreName' | 'RosterName' | 'CustomName';


export const VoucherNotificationSenderNameEnum = {
  Undefined: 'Undefined' as VoucherNotificationSenderNameEnum,
  OrganizationName: 'OrganizationName' as VoucherNotificationSenderNameEnum,
  StoreName: 'StoreName' as VoucherNotificationSenderNameEnum,
  RosterName: 'RosterName' as VoucherNotificationSenderNameEnum,
  CustomName: 'CustomName' as VoucherNotificationSenderNameEnum,
};
