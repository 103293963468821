import * as actionTypes from './types';
import rostersUrls from '@constants/sqdApiUrls/rostersUrls';
import { makeApiCall } from '@util/apiHelper';
import {
  fulfillmentApi,
  slServicesApi,
} from '../../sqdApis';

export const updateRostersTable = (data) => ({
  type: actionTypes.UPDATE_ROSTERS_TABLE,
  data,
});

export const updateRosterDetails = (data) => ({
  type: actionTypes.UPDATE_ROSTER_DETAILS,
  data,
});

export const updateRosterMembers = (data) => ({
  type: actionTypes.UPDATE_ROSTER_MEMBERS,
  data,
});

export const updateRosterMemberOrderItems = (data) => ({
  type: actionTypes.UPDATE_ROSTER_MEMBER_ORDER_ITEMS,
  data,
});

export const fetchRostersTable = (
  pageNumber = 1,
  pageSize = 10,
  sortColumn = null,
  sortDirection = null,
  disabled = null,
  search = null,
  organizationId = null,
  lockerId
) => async (dispatch) => {
  const call = slServicesApi.get(rostersUrls.rostersTable(
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    disabled,
    search,
    organizationId,
    lockerId ?? null
  ), { handleBlockingLoading: false });

  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateRostersTable({
      totalPages: res.totalPages,
      hasPreviousPage: res.hasPreviousPage,
      hasNextPage: res.hasNextPage,
      pageNumber: res.pageNumber,
      pageSize: res.pageSize,
      queue: res.items,
      totalCount: res.totalCount,
    }));
  }
};

export const fetchRosterDetails = (rosterId) => async (dispatch) => {
  const call = slServicesApi.get(rostersUrls.rosterTeam(rosterId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateRosterDetails(res));
  }
};

export const fetchRosterMembers = (rosterId) => async (dispatch) => {
  const call = slServicesApi.get(rostersUrls.rosterTeamMembers(rosterId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateRosterMembers(res));
  }
};

export const fetchRosterMemberOrderItems = (rosterMemberId, requiredItems = true) => async (dispatch) => {
  const call = fulfillmentApi.get(
    rostersUrls.rosterMemberOrderItems(rosterMemberId, requiredItems),
    { handleBlockingLoading: false }
  );

  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateRosterMemberOrderItems(res));
  }
};
