import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class EditSportsInputSportItem extends PureComponent {
  state = {
    alreadyInList: !!this.props.sports.find((s) => s.id === this.props.sport.id),
  };

  componentDidUpdate(prevProps) {
    const {
      sports,
      sport,
    } = this.props;

    if (sports !== prevProps.sports || sport !== prevProps.sport) {
      this.setState(() => ({
        alreadyInList: !!sports.find((s) => s.id === sport.id),
      }));
    }
  }

  onClickOnSport = (e) => {
    const {
      sport,
      addSport,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    addSport(sport);
  };

  render() {
    const { sport } = this.props;
    const { alreadyInList } = this.state;

    return (
      <div
        className={`suggestions__row ${alreadyInList ? 'disabled' : ''}`}
        onClick={this.onClickOnSport}
      >
        {sport.name}
      </div>
    );
  }
}

EditSportsInputSportItem.propTypes = {
  sport: PropTypes.object.isRequired,
  sports: PropTypes.arrayOf(PropTypes.object).isRequired,
  addSport: PropTypes.func.isRequired,
};

export default EditSportsInputSportItem;
