import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';

import { postHomefieldApiV2MerchandisingAddtargets } from '@api/accounts/merchandising';
import { UseFormReturn, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '@util/yupValidationHelper';
import HookformInput from '@components/shared/Form/HookformInput';
import Modal from '@components/shared/Modal/Modal';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import { MerchandisingEffortDto } from '@api/accounts/models';
import { materialSwal } from '@util/componentHelper';
import HookformFileInput from '@components/shared/Form/HookformFileInput';

export interface AddMerchandisingTargetFormData {
  targetsCsv?: FileList;
}

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  item?: MerchandisingEffortDto;
}

const AddMerchandisingTargetDialog = ({ isOpen, closeModal, onSubmit, item }: OwnProps) => {
  const schema = yup.object({
    targetsCsv: yup.mixed().test('file-exists', 'Targets are required', (targetsCsv: FileList | undefined) => {
      const len = targetsCsv?.length ?? 0;

      return len > 0;
    }),
  });

  const formMethods: UseFormReturn<AddMerchandisingTargetFormData> = useForm<AddMerchandisingTargetFormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      targetsCsv: undefined,
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = formMethods;

  const handleCloseModal = useCallback(() => {
    setValue('targetsCsv', undefined);
    reset(undefined);
    closeModal();
  }, [closeModal, reset, setValue]);

  const handleFormSubmit = useCallback(() => {
    handleSubmit(async (data) => {
      try {
        const response = await postHomefieldApiV2MerchandisingAddtargets({
          effortId: item?.id,
          targetsCsv: data.targetsCsv?.item(0) as Blob,
        });
        onSubmit();
        handleCloseModal();
        materialSwal('Success', `${response.result} Targets were added.`, 'success');
      } catch (error) {
        console.error('error', error);
      }
    })();
  }, [handleCloseModal, handleSubmit, item?.id, onSubmit]);

  return (
    <Modal
      title={`Add Merchandising Targets (${item?.name})`}
      modalSize={'l'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onClose={handleCloseModal}
          formSubmission={true}
          formId={'add-merchandising-target-form'}
        />
      )}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate={true} id='add-merchandising-target-form'>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={11}>
            <HookformFileInput
              id='targets'
              name='targetsCsv'
              placeholder='Upload Targets'
              label='Targets'
              autoFocus={true}
              error={errors?.targetsCsv?.message}
              required={true}
              control={control}
            />
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default AddMerchandisingTargetDialog;
