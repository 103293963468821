import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';

class ColorSwatchDropdownItem extends PureComponent {
  onSelectColor = () => {
    const {
      color,
      handleSelect,
    } = this.props;

    handleSelect(color);
  };

  render() {
    const {
      isSelected,
      color,
      multiselect = true,
      hexValue,
      colorDisplayName,
    } = this.props;

    let checkbox = null;

    if (multiselect) {
      checkbox = isSelected
        ? <Icon materialIcon={'check_box'} />
        : <Icon materialIcon={'check_box_outline_blank'} />;
    }

    return (
      <li
        onClick={this.onSelectColor}
        className={`color-dropdown__option button ${isSelected ? 'is-active' : ''}`}
      >
        {checkbox}
        <div
          className='color-swatch-item'
          onClick={this.onSelectColor}
        >
          <div
            className='color-swatch-item__color'
            style={hexValue ? { backgroundColor: hexValue } : {}}
          />
          <span className='color-swatch-item__number'>{colorDisplayName || color}</span>
        </div>
      </li>
    );
  }
}

ColorSwatchDropdownItem.propTypes = {
  color: PropTypes.string.isRequired,
  colorDisplayName: PropTypes.string.isRequired,
  hexValue: PropTypes.string,
  multiselect: PropTypes.bool,
  isSelected: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
};

export default ColorSwatchDropdownItem;
