import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  name: string;
  fontAwesomeClass: string;
  linkUrl: string;
  currentLocation: string;
}

type Props = OwnProps;

const DrawerPanel = React.memo(({
  name,
  fontAwesomeClass,
  linkUrl,
  currentLocation,
}: Props) => (
  <li data-toggle='body'>
    <Link
      to={linkUrl}
      className={linkUrl === currentLocation ? 'is-active' : ''}
    >
      <span className='margin margin--right-m'>
        <Icon
          fontAwesomeIcon={fontAwesomeClass}
          classes={'fa-fw'}
        />
      </span>
      {name}
    </Link>
  </li>
));

export default DrawerPanel;
