import sqdLockerUrls from '@constants/sqdApiUrls/lockerUrls';
import { logoReplacement } from '@constants/sqdApiUrls/logoUrls';
import { slServicesApi } from '../../sqdApis';
import { makeApiCallWithErrorModal } from '@util/apiHelper';

export const updateLogo = async (
  logoId,
  selectionType,
  artworkColorIds,
  customColors,
  artworkApproved,
  decorationMethods,
  floodColorIds
) => {
  const body = JSON.stringify({
    artworkColorIds: artworkColorIds ?? undefined,
    customColors: customColors ?? undefined,
    artworkApproved: artworkApproved ?? undefined,
    decorationMethods: decorationMethods ?? undefined,
    floodColorIds: floodColorIds ?? undefined,
  });

  const call = slServicesApi.put(sqdLockerUrls.logo(logoId, selectionType), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateLogoImage = async (logoId, attachedFile) => {
  // Multipart form
  const body = new FormData();

  if (attachedFile) {
    for (const f of attachedFile) {
      body.append('file', f, f.name);
    }
  }

  const call = slServicesApi.put(logoReplacement(logoId), {
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
