import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleReviewLogo } from '@redux/logoReview/actions';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { translateCorrection } from '@constants/common';
import { stickyHeaderSpace } from '@constants/values';
import ReviewedLogo from './ReviewedLogo/ReviewedLogo';
import LogoForReview from './LogoForReview/LogoForReview';

class LogoReviewLogo extends Component {
  state = {
    logoIsReviewed: false,
    allowedDecorationMethods: {
      HAG: false,
      EMB: false,
      DTG: false,
      DIP: true,
    },
    requestTasks: {
      reachOutNeeded: false,
      vectorization: false,
      backgroundRemoval: false,
      gradient: false,
    },
    colors: {
      approvedLightColors: this.props.allLightColors,
      approvedDarkColors: this.props.allDarkColors,
    },
    note: null,
  };

  onChangeDecorationMethods = (name) => {
    const { allowedDecorationMethods } = this.state;
    const newAllowedDecorationMethods = { ...allowedDecorationMethods };

    newAllowedDecorationMethods[name] = !newAllowedDecorationMethods[name];

    if (name === decorationMethodEnum.HAG && newAllowedDecorationMethods[name]) {
      newAllowedDecorationMethods[decorationMethodEnum.DTG] = true;
    }

    this.setState(() => ({ allowedDecorationMethods: newAllowedDecorationMethods }));
  };

  onChangeRequestTasks = (name) => {
    const {
      requestTasks,
      allowedDecorationMethods,
    } = this.state;

    let newAllowedDecorationMethods = { ...allowedDecorationMethods };
    const newRequestTasks = {
      ...requestTasks,
      [name]: !requestTasks[name],
    };

    if (newRequestTasks[name] && (name === 'reachOutNeeded' || name === 'gradient')) {
      newAllowedDecorationMethods = {
        HAG: false,
        EMB: false,
        DTG: false,
        DIP: false,
      };
    }

    this.setState(() => ({
      requestTasks: newRequestTasks,
      allowedDecorationMethods: newAllowedDecorationMethods,
    }));
  };

  onNoteUpdate = (noteText) => {
    this.setState(() => ({ note: noteText }));
  };

  onChangeColors = (listName, color) => {
    const { colors } = this.state;

    const isSelected = colors[listName].find((c) => c.id === color.id);

    if (isSelected) {
      colors[listName] = colors[listName].filter((c) => c.id !== color.id);
    } else {
      colors[listName] = [
        ...colors[listName],
        color,
      ];
    }

    this.setState(() => ({ colors }));
  };

  onSelectAllColorsFromList = (listName) => {
    const {
      allLightColors, allDarkColors,
    } = this.props;
    const { colors } = this.state;

    const allColors = {
      approvedLightColors: allLightColors,
      approvedDarkColors: allDarkColors,
    };

    colors[listName] = [...allColors[listName]];

    this.setState(() => ({ colors }));
  };

  onUnselectAllColorsFromList = (listName) => {
    const { colors } = this.state;

    colors[listName] = [];

    this.setState(() => ({ colors }));
  };

  onReviewBtnClick = async () => {
    const {
      logoIsReviewed,
      requestTasks,
      allowedDecorationMethods,
      colors,
      note,
    } = this.state;

    const {
      dispatch,
      logo,
    } = this.props;

    const logoData = {
      ...logo,
      isApproved: !this.logoIsUnapproved(),
      requestTasks,
      allowedDecorationMethods,
      colors,
      note,
    };

    await dispatch(toggleReviewLogo(logoData));

    if (!logoIsReviewed) {
      /* eslint-disable-next-line react/no-find-dom-node */
      window.scrollTo(0, ReactDOM.findDOMNode(this).offsetTop - stickyHeaderSpace);
    }

    this.setState(() => ({ logoIsReviewed: !logoIsReviewed }));
  };

  getRequestTasks = () => {
    const {
      requestTasks: {
        reachOutNeeded,
        vectorization,
        backgroundRemoval,
        gradient,
      },
    } = this.state;

    const requestTasks = [];
    if (reachOutNeeded) requestTasks.push(translateCorrection.reachOutNeeded);
    if (vectorization) requestTasks.push(translateCorrection.vectorization);
    if (backgroundRemoval) requestTasks.push(translateCorrection.backgroundRemoval);
    if (gradient) requestTasks.push(translateCorrection.gradient);

    return requestTasks;
  };

  getAllowedDecorationMethods = () => {
    const {
      allowedDecorationMethods: {
        HAG,
        EMB,
        DTG,
        DIP,
      },
    } = this.state;

    const allowedDecorationMethods = [];

    if (EMB) allowedDecorationMethods.push(decorationMethodEnum.EMB);
    if (HAG) allowedDecorationMethods.push(decorationMethodEnum.HAG);
    if (DTG) allowedDecorationMethods.push(decorationMethodEnum.DTG);
    if (DIP) allowedDecorationMethods.push(decorationMethodEnum.DIP);

    return allowedDecorationMethods;
  };

  logoNeedsArtworkEdits = () => {
    const { requestTasks } = this.state;

    return (requestTasks.reachOutNeeded || requestTasks.gradient);
  };

  logoIsUnapproved = () => {
    const {
      allowedDecorationMethods: {
        HAG,
        EMB,
        DTG,
        DIP,
      },
    } = this.state;

    return (!HAG && !EMB && !DTG && !DIP);
  };

  render() {
    const {
      logo,
      locker,
      cancelReviewForLogo,
      allLightColors,
      allDarkColors,
    } = this.props;

    const {
      logoIsReviewed,
      allowedDecorationMethods,
      requestTasks,
      colors: {
        approvedLightColors,
        approvedDarkColors,
      },
      note,
    } = this.state;

    if (logoIsReviewed) {
      return (
        <ReviewedLogo
          logo={logo}
          requestTasks={this.getRequestTasks()}
          allowedDecorationMethods={this.getAllowedDecorationMethods()}
          onReviewBtnClick={this.onReviewBtnClick}
          unapproved={this.logoIsUnapproved()}
        />
      );
    }

    return (
      <LogoForReview
        logo={logo}
        locker={locker}
        allLightColors={allLightColors}
        allDarkColors={allDarkColors}
        approvedLightColors={approvedLightColors}
        approvedDarkColors={approvedDarkColors}
        note={note}
        allowedDecorationMethods={allowedDecorationMethods}
        requestTasks={requestTasks}
        onChangeDecorationMethods={this.onChangeDecorationMethods}
        onChangeRequestTasks={this.onChangeRequestTasks}
        onChangeColors={this.onChangeColors}
        onSelectAllColorsFromList={this.onSelectAllColorsFromList}
        onUnselectAllColorsFromList={this.onUnselectAllColorsFromList}
        onNoteUpdate={this.onNoteUpdate}
        onReviewBtnClick={this.onReviewBtnClick}
        cancelReviewForLogo={cancelReviewForLogo}
        needsArtworkEdits={this.logoNeedsArtworkEdits()}
      />
    );
  }
}

LogoReviewLogo.propTypes = {
  logo: PropTypes.shape({
    id: PropTypes.number,
    lockerId: PropTypes.number,
    logoUrl: PropTypes.string,
    reviewStartedAt: PropTypes.string,
    ordersCount: PropTypes.number,
  }).isRequired,
  locker: PropTypes.shape({
    id: PropTypes.number,
    teamName: PropTypes.string,
    lockerUrl: PropTypes.string,
    lockerManagerId: PropTypes.number,
    lockerManagerName: PropTypes.string,
    partnerName: PropTypes.string,
    organizationId: PropTypes.number,
    organizationName: PropTypes.string,
    logos: PropTypes.arrayOf(PropTypes.object),
  }),
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  cancelReviewForLogo: PropTypes.func.isRequired,
};

export default connect()(LogoReviewLogo);
