import { buildQueryString } from '@util/stringHelpers';

export const organizationsUrl = 'homefield/api/organizations' as const;

export const organizations = (
  pageNumber: number,
  pageSize: number,
  sortColumn: string | null = null,
  sortDirection: string | null = null,
  orgType: string | null = null,
  size: string | null = null,
  search: string | null = null,
  landingPageEnabled: boolean | null = null,
  lockerManagerId: number | null = null,
  accountExecutives: string[] = []
): string => `${organizationsUrl}/${buildQueryString({
  pageNumber,
  pageSize,
  sortColumn,
  sortDirection,
  orgType,
  size: size && encodeURIComponent(size),
  search,
  landingPageEnabled,
  lockerManagerId,
  accountExecutives,
})}`;

export const organization = (organizationId: number): string => `${organizationsUrl}/${organizationId}`;
export const organizationLockers = (organizationId: number): string => `${organization(organizationId)}/lockers`;
export const organizationLockersSearch = (
  organizationId: number,
  filter: string | null = null,
  sortColumn: string | null = null,
  sortDirection: string | null = null
): string => `${organization(organizationId)}/lockers/${buildQueryString({
  filter,
  sortColumn,
  sortDirection,
})}`;

export const organizationSuggestions = (filter: string, limit: number): string => `${organizationsUrl}/suggestions${buildQueryString({
  filter: encodeURIComponent(filter),
  limit,
})}`;

export const organizationFeaturedLockers = (organizationId: number): string => `${organization(organizationId)}/featured-lockers`;
export const removeOrganizationLockers = (organizationId: number): string => `${organizationLockers(organizationId)}.remove`;

export const addOrganization = `${organizationsUrl}/` as const;
export const uploadLogo = `${organizationsUrl}/logo-upload` as const;

export const organizationGroups = (organizationId: number): string => `${organizationsUrl}/${organizationId}/groups`;

export const organizationGroupUrl = 'homefield/api/organizations/groups' as const;
export const organizationGroup = (groupId: number): string => `${organizationGroupUrl}/${groupId}`;
export const organizationGroupLockers = (groupId: number): string => `${organizationGroup(groupId)}/lockers`;
export const removeOrganizationGroupLockers = (groupId: number): string => `${organizationGroup(groupId)}/lockers.remove`;
export const setOrganizationGroupFeatured = (groupId: number): string => `${organizationGroupUrl}.featured/${groupId}`;

export default {
  organizations,
  organization,
  addOrganization,
  organizationLockers,
  organizationFeaturedLockers,
  organizationLockersSearch,
  organizationSuggestions,
  removeOrganizationLockers,
  uploadLogo,
  organizationGroups,
  organizationGroup,
  organizationGroupLockers,
  removeOrganizationGroupLockers,
  setOrganizationGroupFeatured,
};
