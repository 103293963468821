export const couponForm = 'couponForm' as const;
export const organizationForm = 'organizationForm' as const;
export const editAdminForm = 'editAdminForm' as const;
export const shippingAddressForm = 'shippingAddressForm' as const;
export const editStyleForm = 'editStyleForm' as const;
export const editColoredStyleForm = 'editColoredStyleForm' as const;
export const editProductForm = 'editProductForm' as const;
export const addOrganizationGroupForm = 'addOrganizationGroupForm' as const;
export const editOrganizationGroupForm = 'editOrganizationGroupForm' as const;
export const createCustomOrderForm = 'createCustomOrderForm' as const;
export const personalizationForm = 'personalizationForm' as const;
export const customItemForm = 'customItemForm' as const;
export const shipmentDataForm = 'shipmentDataForm' as const;
export const applicationSettingsForm = 'applicationSettingsForm' as const;
export const colorForm = 'colorForm' as const;
export const sportForm = 'sportForm' as const;
export const categoryForm = 'categoryForm' as const;
export const parentCategoryForm = 'parentCategoryForm' as const;
export const decorationLocationForm = 'decorationLocationForm' as const;
export const decorationLocationArtworkSizeForm = 'decorationLocationArtworkSizeForm' as const;
export const brandForm = 'brandForm' as const;
export const orderArtworkForm = 'orderArtworkForm' as const;
export const orderItemPersonalizationForm = 'orderItemPersonalizationForm' as const;
export const orderManagementTeamOrganizerForm = 'orderManagementTeamOrganizerForm' as const;
export const vendorForm = 'vendorForm' as const;
export const refundOrderForm = 'refundOrderForm' as const;
export const sendRefundConfirmationForm = 'sendRefundConfirmationForm' as const;
export const layoutForm = 'layoutForm' as const;
export const refundOrderItemForm = 'refundOrderItemForm' as const;
export const rosterForm = 'rosterForm' as const;
export const teamMemberForm = 'teamMemberForm' as const;
export const rosterNotificationsForm = 'rosterNotificationsForm' as const;
export const rostersForm = 'rostersForm' as const;
export const productionAssemblyFlagItemForm = 'productionAssemblyFlagItemForm' as const;
export const productionAssemblyUnFlagItemForm = 'productionAssemblyUnFlagItemForm' as const;
export const schedulingQueueDailyCapacityEditForm = 'schedulingQueueDailyCapacityEditForm' as const;
export const orderItemRemovePersonalizationForm = 'orderItemRemovePersonalizationForm' as const;
export const replaceItemBySkuForm = 'replaceItemBySkuForm' as const;
export const addItemBySkuForm = 'addItemBySkuForm' as const;
export const collectionForm = 'collectionForm' as const;
export const addItemsToExistingCollectionForm = 'addItemsToExistingCollectionForm' as const;
export const printAssemblyFlagItemForm = 'printAssemblyFlagItemForm' as const;
export const printAssemblyUnflagItemForm = 'printAssemblyUnflagItemForm' as const;
export const addNewPtlWallForm = 'addNewPtlWallForm' as const;
export const editPtlWallForm = 'editPtlWallForm' as const;
export const dtgEnvironmentForm = 'dtgEnvironmentForm' as const;
export const dtgColoredStyleForm = 'dtgColoredStyleForm' as const;
export const dtgSettingsForm = 'dtgSettingsForm' as const;
export const dtgStationsForm = 'dtgStationsForm' as const;
export const dtgTabletProfilesForm = 'dtgTabletProfilesForm' as const;
export const scanDeviceCodeForm = 'scanDeviceCodeForm' as const;
export const scanItemsForm = 'scanItemsForm' as const;
export const productionAssemblyPrintQueueSendToPrintForm = 'productionAssemblyPrintQueueSendToPrintForm' as const;
export const scanOrderItemsBarcodesForm = 'scanOrderItemsBarcodesForm' as const;
export const editVoucherOrderForm = 'editVoucherOrderForm' as const;
export const cancelVoucherOrderWithRefundForm = 'cancelVoucherOrderWithRefundForm' as const;
export const bulkAddItemForm = 'bulkAddItemForm' as const;
export const editOrderItemStatusForm = 'editOrderItemStatusForm' as const;
export const bulkStyleCreationForm = 'bulkStyleCreationForm' as const;
