import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  adminDetailsUrl,
  lockerDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import { parseDateNumeric } from '@util/dateHandler';
import Table from '@sharedComponents/Table/Table';

const LogosInReviewTable = Table();

class LogoReviewerTableInReview extends Component {
  getColumns = () => {
    const columns = [
      {
        Header: 'Locker ID',
        accessor: 'lockerId',
        minWidth: 50,
        Cell: (cellProps) => <Link to={lockerDetailsUrl(cellProps.value)}>L{cellProps.value}</Link>,
      },
      {
        Header: 'Team Name',
        accessor: 'teamName',
        minWidth: 100,
      },
      {
        Header: 'Locker Manager',
        accessor: '',
        minWidth: 100,
        Cell: (cellProps) => (
          <Link to={adminDetailsUrl(cellProps.value.lockerManagerId)}>{cellProps.value.lockerManagerName}</Link>
        ),
      },
      {
        Header: 'Partner',
        accessor: 'partnerName',
        minWidth: 60,
      },
      {
        Header: 'Logos',
        accessor: 'logosCount',
        minWidth: 40,
        sortable: true,
      },
      {
        Header: 'Orders',
        accessor: 'ordersCount',
        minWidth: 40,
        sortable: true,
      },
      {
        Header: 'Review Started',
        accessor: 'reviewStartedAt',
        minWidth: 80,
        Cell: (cellProps) => cellProps.value && parseDateNumeric(cellProps.value),
      },
      {
        Header: 'Reviewer',
        accessor: 'reviewer',
        minWidth: 100,
      },
    ];

    return columns;
  };

  render() {
    const {
      queue,
      totalPages,
      pageSize,
      fetchData,
      hasNextPage,
      hasPreviousPage,
    } = this.props;

    return (
      <div className='sheet'>
        <LogosInReviewTable
          data={queue}
          columns={this.getColumns()}
          defaultPageSize={pageSize}
          showPagination={hasNextPage || hasPreviousPage}
          totalPages={totalPages}
          onFetchData={fetchData}
        />
      </div>
    );
  }
}

LogoReviewerTableInReview.propTypes = {
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  logoReview,
  oidc,
}) => ({
  queue: logoReview.tasksTable.items,
  totalPages: logoReview.tasksTable.pagesCount,
  pageNumber: logoReview.tasksTable.activePage,
  pageSize: logoReview.tasksTable.itemsPerPage,
  totalCount: logoReview.tasksTable.totalCount,
  hasNextPage: logoReview.tasksTable.hasNextPage,
  hasPreviousPage: logoReview.tasksTable.hasPreviousPage,
  profile: oidc.user.profile,
  designers: logoReview.tasksTable.designers,
  partners: logoReview.tasksTable.partners,
});

export default connect(mapStateToProps)(LogoReviewerTableInReview);
