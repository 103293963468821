import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { Task } from '@models/Production/OrderAssembly/Tasks';

interface OwnProps {
  task: Task;
}

type Props = OwnProps;

const ReviewItemBarcodesTooltip = React.memo<Props>(({ task }) => {
  const barcodes = (task.barcodes.length > 1 ? task.barcodes : [task.barcode]).filter(Boolean) as string[];

  const renderBarcode = (barcode: string, index: number) => (<div key={index}>{barcode}</div>);

  if (!barcodes.length) return null;

  return (
    <MaterialTooltip
      tooltipText={(
        <div>
          {barcodes.map(renderBarcode)}
        </div>
      )}
      placement={'top'}
      contentClasses={'details__product__title--icon'}
    >
      <Icon materialIcon={'info_outline'} />
    </MaterialTooltip>
  );
});

export default ReviewItemBarcodesTooltip;
