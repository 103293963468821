/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import type {
  PagedListViewModelVoucherCreationLockerViewModel,
  ApiInternalServerError,
  GetVoucherCreationLockersParams,
  VoucherCreationRosterViewModel,
  GetVoucherCreationRostersParams,
  VoucherCreationAdminViewModel,
  ApiBadRequest,
  GetVoucherCreationAdminsParams
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getVoucherCreationLockers = <TData = PagedListViewModelVoucherCreationLockerViewModel>(
    params?: GetVoucherCreationLockersParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/vouchers/lockers`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetVoucherCreationLockersQueryKey = (params?: GetVoucherCreationLockersParams,) => [`/homefield/api/vouchers/lockers`, ...(params ? [params]: [])]

    
export const useGetVoucherCreationLockers = <TQueryFnData = AsyncReturnType<typeof getVoucherCreationLockers, PagedListViewModelVoucherCreationLockerViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetVoucherCreationLockersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetVoucherCreationLockersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getVoucherCreationLockers<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getVoucherCreationRosters = <TData = VoucherCreationRosterViewModel[]>(
    params?: GetVoucherCreationRostersParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/vouchers/rosters`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetVoucherCreationRostersQueryKey = (params?: GetVoucherCreationRostersParams,) => [`/homefield/api/vouchers/rosters`, ...(params ? [params]: [])]

    
export const useGetVoucherCreationRosters = <TQueryFnData = AsyncReturnType<typeof getVoucherCreationRosters, VoucherCreationRosterViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetVoucherCreationRostersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetVoucherCreationRostersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getVoucherCreationRosters<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getVoucherCreationAdmins = <TData = VoucherCreationAdminViewModel[]>(
    params?: GetVoucherCreationAdminsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/vouchers/admins`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetVoucherCreationAdminsQueryKey = (params?: GetVoucherCreationAdminsParams,) => [`/homefield/api/vouchers/admins`, ...(params ? [params]: [])]

    
export const useGetVoucherCreationAdmins = <TQueryFnData = AsyncReturnType<typeof getVoucherCreationAdmins, VoucherCreationAdminViewModel[]>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 params?: GetVoucherCreationAdminsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetVoucherCreationAdminsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getVoucherCreationAdmins<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

