import React from 'react';
import PropTypes from 'prop-types';

const ImageBackgroundSwatch = React.memo(({
  selectedBackground,
  updateBackground,
  imageBackgroundOptions,
}) => {
  const active = (backgroundCode) => (
    backgroundCode === selectedBackground.code ? 'is-active' : ''
  );

  return (
    <div className='bg-color-picker'>
      {
        imageBackgroundOptions.map((option) => (
          <button
            key={option.code}
            onClick={updateBackground.bind(this, option)}
            type='button'
            className={`bg-color-picker__item ${active(option.code)}`}
          >
            <div style={{ background: option.background }} />
          </button>
        ))
      }
    </div>
  );
});

ImageBackgroundSwatch.propTypes = {
  selectedBackground: PropTypes.shape({
    code: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
  }).isRequired,
  updateBackground: PropTypes.func.isRequired,
  imageBackgroundOptions: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  })),
};

export default ImageBackgroundSwatch;
