export const reprintReasons = [
  {
    id: 1,
    reason: 'Damaged during masking',
  },
  {
    id: 2,
    reason: 'Damaged during weeding',
  },
  {
    id: 3,
    reason: 'Shortage',
  },
  {
    id: 4,
    reason: 'Difficult to weed',
  },
  {
    id: 5,
    reason: 'Bad cuts',
  },
  {
    id: 6,
    reason: 'Bad prints',
  },
  {
    id: 7,
    reason: 'Wrong color',
  },
  {
    id: 8,
    reason: 'Wrong size',
  },
  {
    id: 9,
    reason: 'Wrong personalization color',
  },
  {
    id: 10,
    reason: 'Incorrect Logo',
  },
  {
    id: 11,
    reason: 'Damaged in production',
  },
  {
    id: 12,
    reason: 'Damaged by operator',
  },
  {
    id: 13,
    reason: 'Vendor damaged',
  },
] as const;

export const hagReworkReasons = [
  {
    id: 1,
    reason: 'Missing cutline',
  },
  {
    id: 2,
    reason: 'Incorrect flood',
  },
  {
    id: 3,
    reason: 'Missing artwork details',
  },
  {
    id: 4,
    reason: 'Wrong art',
  },
  {
    id: 5,
    reason: 'Wrong size',
  },
  {
    id: 6,
    reason: 'Logo needs to be flooded',
  },
] as const;

export const wrongArtworkReasons = [
  {
    id: 1,
    reason: 'Wrong Artwork',
  },
] as const;

export const embReworkReasons = [
  {
    id: 1,
    reason: 'Color variance',
  },
  {
    id: 2,
    reason: 'Incorrect stitch count',
  },
  {
    id: 3,
    reason: 'Missing logo elements',
  },
  {
    id: 4,
    reason: 'Poor digitization',
  },
  {
    id: 5,
    reason: 'Incorrect sizing',
  },
  {
    id: 6,
    reason: 'Incorrect flooding',
  },
  {
    id: 7,
    reason: 'Misc',
  },
] as const;

export const dtgReworkReasons = [
  {
    id: 1,
    reason: 'Color variance',
  },
  {
    id: 2,
    reason: 'Missing logo elements',
  },
  {
    id: 3,
    reason: 'Poor digitization',
  },
  {
    id: 4,
    reason: 'Incorrect sizing',
  },
  {
    id: 5,
    reason: 'Misc',
  },
] as const;
