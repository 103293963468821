export const orderStatusValueEnum = {
  Initial: 0,
  ArtworkTasksProcessing: 5,
  ArtworkTasksCompleted: 20,
  OnHold: 30,
  AwaitingDispatchForPicking: 32,
  PartialyInPicking: 34,
  InPicking: 35,
  Picked: 45,
  Assembled: 50,
  ProductionCompleted: 60,
  ShippingReady: 90,
  Shipped: 100,
} as const;

export const orderItemStatusValueEnum = {
  Initial: 0,
  OnHold: 20,
  AwaitingDispatchForPicking: 23,
  InPicking: 25,
  Picked: 30,
  CheckedIn: 40,
  Assembled: 50,
  ProductionCompleted: 60,
  Waterproofed: 65,
  QualityChecked: 70,
  Shipped: 100,
} as const;

export const orderStatusEnum = {
  Initial: 'Initial',
  ArtworkTasksProcessing: 'ArtworkTasksProcessing',
  ArtworkTasksCompleted: 'ArtworkTasksCompleted',
  OnHold: 'OnHold',
  AwaitingDispatchForPicking: 'AwaitingDispatchForPicking',
  PartialyInPicking: 'PartialyInPicking',
  InPicking: 'InPicking',
  Picked: 'Picked',
  Assembled: 'Assembled',
  ProductionCompleted: 'ProductionCompleted',
  ShippingReady: 'ShippingReady',
  Shipped: 'Shipped',
} as const;

export const itemStatusEnum = {
  OnHold: 'Awaiting Inventory',
  AwaitingDispatchForPicking: 'Awaiting dispatch for picking',
  InPicking: 'In Picking',
  Picked: 'Picked',
  CheckedIn: 'Item Checked in',
  PreTreated: 'Item Pre-Treated',
  Assembled: 'Assembled',
  ProductionCompleted: 'Production Completed',
  Waterproofed: 'Item Waterproofed',
  QualityChecked: 'Quality Checked',
  Shipped: 'Shipped',
} as const;

export const lineItemStatusEnum = {
  CheckedIn: 'Line item Checked in',
  Decorated: 'Line item Decorated',
  Flagged: 'Decoration flagged',
  Unflagged: 'Decoration unflagged',
} as const;

export const orderItemStatusEnum = {
  Initial: 'Initial',
  OnHold: 'OnHold',
  AwaitingDispatchForPicking: 'AwaitingDispatchForPicking',
  InPicking: 'InPicking',
  Picked: 'Picked',
  CheckedIn: 'CheckedIn',
  PreTreated: 'PreTreated',
  Assembled: 'Assembled',
  Decorated: 'Decorated',
  ProductionCompleted: 'ProductionCompleted',
  Waterproofed: 'Waterproofed',
  QualityChecked: 'QualityChecked',
  Shipped: 'Shipped',
} as const;

export const flagStatusEnum = {
  Flagged: 'Flagged',
  Unflagged: 'Unflagged',
} as const;

export const submitStatusEnum = {
  Done: 'Done',
  Pending: 'Pending',
  NotScheduled: 'NotScheduled',
  Failed: 'Failed',
} as const;

export const inventoryStatusEnum = {
  Available: 'Available',
  NotAvailable: 'Not Available',
  NoData: 'Unknown',
} as const;

export const orderSchedulingStatusEnum = {
  OnHold: 'OnHold',
  AwaitingDispatchForPicking: 'AwaitingDispatchForPicking',
  InPicking: 'InPicking',
} as const;

export const accountingActionTypeEnum = {
  NoChange: 'NoChange',
  OrderPartialRefund: 'OrderPartialRefund',
  OrderFullRefund: 'OrderFullRefund',
  OrderItemPartialRefund: 'OrderItemPartialRefund',
  OrderItemFullRefund: 'OrderItemFullRefund',
  ShippingFullRefund: 'ShippingFullRefund',
  ShippingRefund: 'ShippingRefund',
  ShippingCharge: 'ShippingCharge',
  PersonalizationRefund: 'PersonalizationRefund',
  PersonalizationCharge: 'PersonalizationCharge',
  OrderItemAdd: 'OrderItemAdd',
  Refund: 'Refund',
  Charge: 'Charge',
} as const;

export const chargeReasonEnum = {
  AddingPersonalization: 'Adding Personalization',
  UpgradingShipping: 'Upgrading Shipping',
  ChangingItem: 'Changing Item',
  Other: 'Other',
} as const;

export const couponTypeEnum = {
  Amount: 'Amount',
  Percent: 'Percent',
} as const;

export const vendorOrderStatusEnum = {
  Initial: 'Initial',
  SubmitReady: 'SubmitReady',
  Submitted: 'Submitted',
  Done: 'Done',
} as const;

export const shippingMethodEnum = {
  FDX2D: 'FDX2D',
  FDX1D: 'FDX1D',
  FEDGN: 'FEDGN',
  DROP_EXPRESS: 'DROP_EXPRESS',
  FDXNTF: 'FDXNTF',
} as const;

export const customOrderAssociationEnum = {
  Locker: 'Locker',
  Organization: 'Organization',
} as const;

export enum FilterOrdersOptionsEnum {
  OnlyRequiredItemOrders = 'OnlyRequiredItemOrders',
  OnlyPRDOrders = 'OnlyPRDOrders',
  OnlyOrdersSubmittedByMe = 'OnlyOrdersSubmittedByMe',
}
