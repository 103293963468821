import { undecorate } from '@api/squadlockerServices/colored-style-selections';
import FormInput from '@components/shared/Form/FormInput';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@sharedComponents/Icons/Icon';
import yup from '@util/yupValidationHelper';
import React, {
  useCallback,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

interface OwnProps {
  orderNumber: number | undefined;
  notes: string | undefined | null;
  onUpdateNotes: (orderNumber: number | undefined, notes: string | undefined | null) => void;
}

type Props = OwnProps;

const NotesHeaderCard = React.memo<Props>(({
  orderNumber,
  notes,
  onUpdateNotes,
}) => {
  const schema = yup.object({
    notes: yup.string().required(),
  });

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      notes: undefined as (string | undefined | null),
    },
  });

  const onSubmit = useCallback((form: { notes: string; }) => {
    formMethods.reset();
    onUpdateNotes(orderNumber, form.notes);
  }, [
    onUpdateNotes,
    orderNumber,
    formMethods,
  ]);

  const onDelete = useCallback(() => {
    onUpdateNotes(orderNumber, undefined);
  }, [
    onUpdateNotes,
    orderNumber,
  ]);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  return (
    <div className='sheet__files-item item-notes-card' >
      <div className='sheet__files-title'>
        <div className='sheet__files-title-left flex'>
          Notes
        </div>
      </div>
      <ul className='sheet__files-list'>
        <li>
          <div>
            {
              notes
                ? (
                  <>
                    {
                      notes.length > 60
                        ? (
                          <>
                            <MaterialTooltip
                              tooltipText={notes}
                              placement={'top'}
                            >
                              <span>
                                {notes.slice(0, 59)}...
                                <Icon
                                  materialIcon={'delete'}
                                  classes={'margin-left button'}
                                  onClick={onDelete}
                                />
                              </span>
                            </MaterialTooltip>
                          </>
                        )
                        : (
                          <>
                            {notes}
                            <Icon
                              materialIcon={'delete'}
                              classes={'margin-left button'}
                              onClick={onDelete}
                            />
                          </>
                        )
                    }
                  </>
                )
                : (
                  <>
                    <form
                      id='dtf-printer-modal-form_sheet'
                      className='flex'
                    >
                      <FormInput
                        placeholder={'Order Print Notes'}
                        type={'string'}
                        register={register}
                        error={errors.notes}
                        autoFocus={false}
                        {...register('notes')}
                      />
                      <button
                        onClick={handleSubmit(onSubmit)}
                        className={'button button--color-gossamer button--size-m button--style-default'
                        + ' button--radius-square button--shadow-m button--transform-uppercase ml-10'}
                        style={{ width: '125px' }}
                      >
                        Add Notes
                      </button>
                    </form>
                  </>
                )
            }
          </div>
        </li>
      </ul>
    </div>
  );
});

export default NotesHeaderCard;
