import React from 'react';
import { tabSizesEnum } from '@constants/enums/commonEnums';
import { TabData } from '@models/common/TabData';
import Tab from './Tab';

interface OwnProps{
  tabs: TabData[];
  selectedTab: TabData['name'];
  selectTab: (tabName: TabData['name']) => void;
  size?: (typeof tabSizesEnum)[keyof typeof tabSizesEnum];
  classes?: string;
  tabClass?: string;
  containerClasses?: string;
  noMargin?: boolean;
}

type Props = OwnProps;

const Tabs = React.memo<Props>(({
  tabs,
  selectedTab,
  selectTab,
  size = tabSizesEnum.Medium,
  classes = '',
  tabClass = '',
  containerClasses = '',
  noMargin = false,
}) => (
  <div className={containerClasses}>
    <div className={`tabs ${(size === tabSizesEnum.Large) ? 'tabs--size-l' : ''} ${noMargin ? 'no-margin' : ''} ${classes}`}>
      {
        tabs.map((tab) => (
          <Tab
            key={tab.name}
            title={tab.title}
            isActive={selectedTab === tab.name}
            onSelectTab={selectTab.bind(null, tab.name)}
            classes={tabClass}
            permissions={tab.permissions}
          />
        ))
      }

    </div>
    {tabs.find((tab) => tab.name === selectedTab)?.content}
  </div>
));

export default Tabs;
