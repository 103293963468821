import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  phoneNumberInputRegex,
  phoneNumberInputMaxLength,
} from '@constants/values';

class PhoneNumberInput extends PureComponent {
  formatPhoneNumberInput = (input) => {
    if (!input) return '';

    input = input.replace(phoneNumberInputRegex, '');

    let split = 4;
    const chunk = [];

    for (let i = 0, len = input.length; i < len; i += split) {
      split = (i >= 6 && i <= 10) ? 4 : 3;
      chunk.push(input.substr(i, split));
    }

    return chunk.join('-').toUpperCase();
  };

  render() {
    const {
      type,
      placeholder = '',
      required = false,
      disabled = false,
      className = 'w-100',
      meta: {
        touched = false,
        error = '',
      },
    } = this.props;

    const inputProps = this.props.input || {};
    const hasError = touched && error && (error.length > 0);

    return (
      <div className='text-field w-100'>
        <input
          type={type}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          maxLength={phoneNumberInputMaxLength}
          className={`redux-form__input ${className} ${hasError ? 'error' : ''}`}
          {...inputProps}
          value={this.formatPhoneNumberInput(inputProps.value)}
        />
        {
          hasError &&
          <div className='redux-form__error--field'>
            {error.map((e, index) => <div key={index}>{e}</div>)}
          </div>
        }
      </div>
    );
  }
}

PhoneNumberInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.array,
  }),
  input: PropTypes.object,
};

export default PhoneNumberInput;
