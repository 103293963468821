import { ProductAgeEnum } from '@api/productCatalog/models';
import { LockerProduct } from '@models/LockerManager/LockerProduct';
import { groupBy } from '@util/arrayHelper';

export const groupCollectionItems = (items: LockerProduct[]): {
  itemToDisplay: LockerProduct;
  items: LockerProduct[];
  quantity: number;
}[] => {
  const groupedItems: {
    itemToDisplay: LockerProduct;
    items: LockerProduct[];
    quantity: number;
  }[] = [];

  const itemsByColoredStyleCode: Record<string, LockerProduct[]> = groupBy(items, 'colored_style_code');

  for (const [
    coloredStyleCode,
    collectionItems,
  ] of Object.entries(itemsByColoredStyleCode)) {
    // If there are multiple items, pick the one that is not youth
    const itemToDisplay = collectionItems.find((item) => item.age !== ProductAgeEnum.Youth) ?? collectionItems[0];

    const currentCollection = itemToDisplay.collectionsList?.find((collection) => collection.id === collection.id);
    const quantity = currentCollection?.itemQuantity ?? 1;

    groupedItems.push({
      itemToDisplay,
      items: collectionItems,
      quantity: Number.isFinite(quantity) ? quantity : 1,
    });
  }

  return groupedItems;
};
