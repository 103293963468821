import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

const ArtworkTaskDetailsPrevious = React.memo(() => (
  <button
    type='button'
    className='details__arrow details__arrow--previous'
  >
    <Icon materialIcon={'chevron_left'} />
  </button>
));

export default ArtworkTaskDetailsPrevious;
