import React from 'react';
import Button from '@sharedComponents/Buttons/Button';

interface OwnProps {
  openModal: () => void;
}

type Props = OwnProps;

const AssignToButton = React.memo<Props>(({ openModal }) => (
  <Button
    type={'primary'}
    text={'Assign Task to...'}
    onClick={openModal}
    size={'s'}
    classes={'w-100'}
    icon={null}
  />
));

export default AssignToButton;
