import React from 'react';
import { VoucherCollectionDetailDto } from '@api/financialServices/models';
import { ExpandableGroupItemProps } from '@components/shared/Display/Containers/Group/ExpandableGroup';
import RefundPrice from '@sharedComponents/Display/RefundPrice';

type Props = ExpandableGroupItemProps<VoucherCollectionDetailDto>;

const CollectionsTabExpandableGroupItem = (({ item }: Props) => (
  <div className='voucherOrders__details__list__collection-row align__center justify__space-between'>
    <div>{item.collectionName} ({item.rosterTeamName})</div>
    <RefundPrice
      paid={item.pricePaid!}
      refund={item.totalAmount! - item.pricePaid!}
    />
  </div>
));

export default React.memo(CollectionsTabExpandableGroupItem) as typeof CollectionsTabExpandableGroupItem;
