import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { schedulingQueueDailyCapacityEditForm } from '@constants/reduxForms';
import { validateDailyCapacity } from '@redux/llScheduling/validations';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import Input from '@sharedComponents/Form/Input';

const QueueDailyCapacityEditModal = React.memo(({
  isOpen,
  handleSubmit,
  closeModal,
}) => (
  <Modal
    title={'Edit daily capacity'}
    modalSize={'l'}
    isOpen={isOpen}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Save'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={schedulingQueueDailyCapacityEditForm}
      />
    )}
  >
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      id={schedulingQueueDailyCapacityEditForm}
    >
      <div>
        <div className='ml-15 mr-10'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-l'>
              <label className='redux-form__label'>
                Daily capacity
              </label>
              <Field
                name={'dailyCapacity'}
                placeholder={'Enter number of daily items'}
                component={Input}
                type={'number'}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </Modal>
));

QueueDailyCapacityEditModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default (reduxForm({
  form: schedulingQueueDailyCapacityEditForm,
  validate: validateDailyCapacity,
  enableReinitialize: true,
  shouldError: () => true,
})(QueueDailyCapacityEditModal));
