import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
} from 'redux-form';
import PropTypes from 'prop-types';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { teamMemberForm } from '@constants/reduxForms';
import { rosterTeamRoles } from '@constants/options/optionsValues';
import { validateTeamMember } from '@redux/rosters/validations';
import { generalOptions } from '@util/optionsMap';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import FormError from '@sharedComponents/Form/FormError';
import PhoneNumberInput from '@sharedComponents/Form/PhoneNumberInput';

class TeamMemberForm extends PureComponent {
  onKeyPress = (e) => {
    if (e.key === keyNameEnum.Enter) {
      e.preventDefault();
    }
  };

  render() {
    const {
      handleSubmit,
      error,
      buttons,
    } = this.props;

    const roles = generalOptions(rosterTeamRoles);

    return (
      <form
        className='redux-form'
        id={teamMemberForm}
        onSubmit={handleSubmit}
        onKeyPress={this.onKeyPress}
      >
        <div className='roster-team-members__form'>
          <div className='ml-15 mr-10'>

            <div className='redux-form__row'>
              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  First Name
                </label>
                <Field
                  name={'firstName'}
                  placeholder={'Enter First Name'}
                  component={Input}
                  type={'text'}
                />
              </div>

              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Last Name<span className='required'>*</span>
                </label>
                <Field
                  name={'lastName'}
                  placeholder={'Enter Last Name'}
                  component={Input}
                  type={'text'}
                />
              </div>

              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Number<span className='required'>*</span>
                </label>
                <Field
                  name={'number'}
                  placeholder={'Enter Number'}
                  component={Input}
                  type={'number'}
                />
              </div>
            </div>

            <div className='redux-form__row'>
              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Role
                </label>
                <Field
                  name={'role'}
                  component={Select}
                >
                  {roles}
                </Field>
              </div>

              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Email<span className='required'>*</span>
                </label>
                <Field
                  name={'email'}
                  placeholder={'Enter Email'}
                  component={Input}
                  type={'text'}
                />
              </div>

              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Phone
                </label>
                <Field
                  name={'phone'}
                  placeholder={'Enter Phone'}
                  component={PhoneNumberInput}
                  type={'tel'}
                />
              </div>
            </div>

            <div className='redux-form__row'>
              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Size
                </label>
                <Field
                  name={'size'}
                  placeholder={'Enter Size'}
                  component={Input}
                  type={'text'}
                />
              </div>

              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Additional Email
                </label>
                <Field
                  name={'emailAdditional'}
                  placeholder={'Enter Additional Email'}
                  component={Input}
                  type={'text'}
                />
              </div>

              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Additional Phone
                </label>
                <Field
                  name={'phoneAdditional[0]'}
                  placeholder={'Enter Additional Phone'}
                  component={PhoneNumberInput}
                  type={'tel'}
                />
              </div>
            </div>

          </div>

          <FormError error={error} />
        </div>
        <div>
          {buttons}
        </div>
      </form>
    );
  }
}

TeamMemberForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  buttons: PropTypes.element.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
  disabled: PropTypes.object,
  change: PropTypes.func,
  lockers: PropTypes.arrayOf(PropTypes.object).isRequired,
  formErrors: PropTypes.object.isRequired,
};

const mapStateToProps = ({ organizations }) => ({
  lockers: organizations.lockerSuggestions,
});

export default connect(mapStateToProps)(reduxForm({
  form: teamMemberForm,
  enableReinitialize: true,
  validate: validateTeamMember,
})(TeamMemberForm));
