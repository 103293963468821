import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { highlightText } from '@util/componentHelper';

class CouponSuggestion extends Component {
  onClickOnSuggestion = (e) => {
    const {
      coupon,
      selectCoupon,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    selectCoupon(coupon);
  };

  render() {
    const {
      coupon,
      coupons,
      searchInput,
    } = this.props;

    const term = searchInput.toLowerCase();
    const alreadyInList = !!coupons.find((l) => l.id === coupon.id);

    let text = (
      <span>
        <b>{coupon.code}</b>
      </span>
    );

    if (coupon.code.toLowerCase().includes(term)) {
      const couponCodeHighlighted = highlightText(coupon.code, term);
      text = (
        <span>
          <b>{couponCodeHighlighted}</b>
        </span>
      );
    }

    return (
      <div
        className={`suggestions__row ${alreadyInList ? 'disabled' : ''}`}
        onClick={this.onClickOnSuggestion}
      >
        {text}
      </div>
    );
  }
}

CouponSuggestion.propTypes = {
  coupon: PropTypes.object.isRequired,
  coupons: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchInput: PropTypes.string.isRequired,
  selectCoupon: PropTypes.func.isRequired,
};

export default CouponSuggestion;
