import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import ColorSwatchDropdownItem from '@sharedComponents/ColorDetectionEditSwatches/ColorSwatchDropdownItem';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';

class ColorSelect extends PureComponent {
  state = {
    dropIsActive: false,
  };

  toggleDropdown = () => {
    this.setState({ dropIsActive: !this.state.dropIsActive });
  };

  closeDropdown = () => {
    this.setState({ dropIsActive: false });
  };

  handleSelect = (color) => {
    const { selectColor } = this.props;

    selectColor(color);
    this.closeDropdown();
  };

  render() {
    const { dropIsActive } = this.state;
    const {
      allColors = [],
      selectedColor,
      text = 'Pick Color',
      valueProperty = 'code',
      displayNameProperty = 'displayName',
    } = this.props;

    const options = allColors
      .map((c) => (
        <ColorSwatchDropdownItem
          key={c.id}
          color={c[valueProperty]}
          colorDisplayName={c[displayNameProperty]}
          hexValue={c.hexValue}
          handleSelect={this.handleSelect.bind(null, c)}
          isSelected={selectedColor && selectedColor.id === c.id}
          multiselect={false}
        />
      ));

    return (
      <OutsideClickWrapper onClick={this.closeDropdown}>
        <div className='color-dropdown__select pt-0'>
          <div onClick={this.toggleDropdown}>
            {
              selectedColor
                ? (
                  <ColorDisplay
                    key={selectedColor.id}
                    colorName={selectedColor[valueProperty]}
                    className={'p-0'}
                    hexValue={selectedColor.hexValue}
                  />
                )
                : text
            }
            <div className='color-dropdown__arrow' />
          </div>
          <div className={`color-dropdown__options ${dropIsActive ? 'is-active' : ''}`}>
            <ul className='color-dropdown__options--list custom-scrollbar'>
              {options}
            </ul>
          </div>
        </div>
      </OutsideClickWrapper>
    );
  }
}

ColorSelect.propTypes = {
  allColors: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string,
    name: PropTypes.string.isRequired,
  })).isRequired,
  selectedColor: PropTypes.PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
  }),
  selectColor: PropTypes.func.isRequired,
  text: PropTypes.node,
  displayProperty: PropTypes.string,
  valueProperty: PropTypes.string,
  displayNameProperty: PropTypes.string,
};

export default ColorSelect;
