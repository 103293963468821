import React from 'react';
import iconEye from '@assets/icon_eye_green.svg';

interface OwnProps {
  text: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

type Props = OwnProps;

const SeeMoreButton = React.memo<Props>(({
  onClick,
  text,
}) => (
  <div className='align__center mr-20'>
    <div className='storeLogo__link lockerManagerEdit__details-header--link'>
      <a
        className='store-link uppercase'
        target='_blank'
        onClick={onClick}
      >
        <i
          className='product-catalog__icon-eye mr-5'
          style={{ backgroundImage: `url("${iconEye}")` }}
        />
        {text}
      </a>
    </div>
  </div>
));

export default SeeMoreButton;
