import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'intersection-observer';
import { logoReviewUrl } from '@constants/clientUrls/clientUrls';
import {
  stickyHeaderInitialHeight,
  scrollDirections,
} from '@constants/values';
import {
  fetchLogoReviewerUserTasks,
  cancelLogoReviews,
  submitReviewedLogos,
} from '@redux/logoReview/actions';
import { fetchColors } from '@redux/support/actions';
import {
  materialSwal,
  navigateToPage,
} from '@util/componentHelper';
import Button from '@sharedComponents/Buttons/Button';
import BackLink from '@sharedComponents/Navigation/BackLink';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import LogoReviewLocker from './LogoReviewTask/LogoReviewLocker';
import SubmitLogoReviewsModal from './SubmitLogoReviewsModal/SubmitLogoReviewsModal';

class LogoReviewTasks extends Component {
  state = {
    tasksErrors: [],
    scroll: {
      direction: null,
      lastScrollPosition: 0,
    },
    submitLogoReviewsModalIsOpen: false,
    stickyHeaderStyle: { top: stickyHeaderInitialHeight },
    logoReviewCancelationModalIsOpen: false,
    logo: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    window.addEventListener('scroll', this.handleScroll);

    dispatch(fetchLogoReviewerUserTasks());
    dispatch(fetchColors());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  openLogoReviewCancelationModal = (logo) => {
    this.setState(() => ({
      logo,
      logoReviewCancelationModalIsOpen: true,
    }));
  };

  closeLogoReviewCancelationModal = () => {
    this.setState(() => ({
      logo: null,
      logoReviewCancelationModalIsOpen: false,
    }));
  };

  handleLogoReviewCancelationModalConfirm = async () => {
    const { dispatch } = this.props;
    const { logo } = this.state;

    const res = await dispatch(cancelLogoReviews([logo]));
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
    }

    this.closeLogoReviewCancelationModal();
  };

  handleScroll = () => {
    const distFromTop = stickyHeaderInitialHeight - window.scrollY;

    const stickyHeaderStyle = {
      top: distFromTop > 0 ? distFromTop : 0,
    };

    this.setState(() => ({ stickyHeaderStyle }));
    this.onScrollChange();
  };

  openSubmitReviewModal = () => {
    this.setState(() => ({ submitLogoReviewsModalIsOpen: true }));
  };

  closeSubmitReviewModal = () => {
    this.setState(() => ({ submitLogoReviewsModalIsOpen: false }));
  };

  submitLogoReviews = async (reviewedLogos, logosWithoutReview) => {
    const { dispatch } = this.props;

    if (reviewedLogos.length > 0) {
      const res = await dispatch(submitReviewedLogos(reviewedLogos));
      if (res?.success) {
        materialSwal('Success', res.message, 'success');
      } else {
        return;
      }
    }

    if (logosWithoutReview.length > 0) {
      await dispatch(cancelLogoReviews(logosWithoutReview));
    }

    this.closeSubmitReviewModal();
  };

  onScrollChange = () => {
    const { scroll } = this.state;
    const newScroll = { ...scroll };

    const pageOffset = window.pageYOffset;

    if (scroll.lastScrollPosition > pageOffset) {
      newScroll.direction = scrollDirections.up;
      newScroll.lastScrollPosition = pageOffset;

      this.setState(() => ({ scroll: newScroll }));
    } else if (scroll.lastScrollPosition < pageOffset) {
      newScroll.direction = scrollDirections.down;
      newScroll.lastScrollPosition = pageOffset;

      this.setState(() => ({ scroll: newScroll }));
    }
  };

  handleClickBack = () => {
    navigateToPage(logoReviewUrl());
  };

  render() {
    const {
      lockers,
      allLightColors,
      allDarkColors,
      reviewedLogos,
    } = this.props;

    const {
      stickyHeaderStyle,
      submitLogoReviewsModalIsOpen,
      logoReviewCancelationModalIsOpen,
      logo,
    } = this.state;

    let lockerTasks = '';

    if (lockers) {
      lockerTasks = lockers.map((l) => (
        <LogoReviewLocker
          key={l.id}
          locker={l}
          stickyHeaderStyle={stickyHeaderStyle}
          openSubmitReviewModal={this.openSubmitReviewModal}
          cancelReviewForLogo={this.openLogoReviewCancelationModal}
          allLightColors={allLightColors}
          allDarkColors={allDarkColors}
        />
      ));
    }

    return (
      <>
        <div className='container'>
          <SubmitLogoReviewsModal
            isOpen={submitLogoReviewsModalIsOpen}
            closeModal={this.closeSubmitReviewModal}
            reviewedLogos={reviewedLogos}
            allLogos={lockers.map((l) => l.logos).flat()}
            submitLogoReviews={this.submitLogoReviews}
          />
          <div className='logo-review-tasks-page'>
            <div className='navigation mb-20'>
              <BackLink
                text={'Back to List'}
                url={logoReviewUrl}
              />
              <Button
                type={'primary'}
                text={'Submit Reviews'}
                classes={'logo-review__task__header-info__review-btn'}
                onClick={this.openSubmitReviewModal}
                disabled={lockers && lockers.length === 0}
              />
            </div>

            {
              lockers && lockers.length === 0 &&
              <div className='logo-review__tasks--empty'>
                <div className='logo-review__tasks--empty-text'>
                  No you don’t have any logos assigned for review. Please go back to Logo Review list and assign logos.
                </div>
                <div>
                  <ButtonLink
                    type={'primary'}
                    text={'Back to Logo Review'}
                    linkTo={logoReviewUrl}
                  />
                </div>
              </div>
            }

            {lockerTasks}
          </div>
        </div>

        <SimpleConfirmationModal
          isOpen={logoReviewCancelationModalIsOpen}
          confirm={this.handleLogoReviewCancelationModalConfirm}
          closeModal={this.closeLogoReviewCancelationModal}
          title={'Cancel Logo Review'}
          confirmationBody={`Are you sure you want to cancel the review for the logo ${logo ? `with ID ${logo.id}?` : '?'}`}
        />
      </>
    );
  }
}

LogoReviewTasks.propTypes = {
  lockers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    lockerId: PropTypes.number,
    lockerUrl: PropTypes.string,
    lockerManagerEmail: PropTypes.string,
    lockerManagerName: PropTypes.string,
    partnerName: PropTypes.string,
    teamName: PropTypes.string,
    activities: PropTypes.array,
  })).isRequired,
  reviewedLogos: PropTypes.arrayOf(PropTypes.object),
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

const mapStateToProps = ({
  logoReview,
  support,
}) => ({
  lockers: Object.values(logoReview.reviewerTasks.lockers),
  reviewedLogos: logoReview.reviewedLogos,
  allLightColors: support.lightFloodColors,
  allDarkColors: support.darkFloodColors,
});

export default connect(mapStateToProps)(LogoReviewTasks);
