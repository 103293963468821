import React from 'react';
import PropTypes from 'prop-types';
import { highlightText } from '@util/componentHelper';

const LockerSuggestion = React.memo(({
  locker,
  searchInput,
}) => {
  const term = searchInput.toLowerCase();

  const lockerIdString = `L${locker.id}`;
  let text = (
    <span>
      <b>{lockerIdString}</b> - {locker.teamName}
    </span>
  );

  if (lockerIdString.toLowerCase().includes(term)) {
    const lockerIdHighlighted = highlightText(lockerIdString, term);
    text = (
      <span>
        <b>{lockerIdHighlighted}</b> - {locker.teamName}
      </span>
    );
  } else if (locker.teamName.toLowerCase().includes(term)) {
    const teamNameHighlighted = highlightText(locker.teamName, term);
    text = (
      <span>
        <b>{lockerIdString}</b> - {teamNameHighlighted}
      </span>
    );
  }

  return text;
});

LockerSuggestion.propTypes = {
  locker: PropTypes.shape({
    id: PropTypes.number.isRequired,
    teamName: PropTypes.string.isRequired,
  }).isRequired,
  searchInput: PropTypes.string.isRequired,
};

export default LockerSuggestion;
