import vendorOrdersUrls from '@constants/sqdApiUrls/vendorOrdersUrls';
import { makeApiCallWithErrorModal } from '@util/apiHelper';
import { fulfillmentApi } from '../../sqdApis';

export const scheduleOrders = async (orderItemIds) => {
  const body = JSON.stringify({ orderItemIds });

  const call = fulfillmentApi.post(vendorOrdersUrls.vendorOrders, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const resubmitVendorOrder = async (vendorOrderId) => {
  const call = fulfillmentApi.put(vendorOrdersUrls.resubmitVendorOrder(vendorOrderId), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const cancelVendorOrder = async (vendorOrderId) => {
  const call = fulfillmentApi.put(vendorOrdersUrls.cancelVendorOrder(vendorOrderId), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};
