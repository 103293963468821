import React from 'react';
import PropTypes from 'prop-types';
import FileUploader from '@sharedComponents/Upload/FileUploader';

const allowedCsvExtensions = '.csv';

const RosterCSVUploader = React.memo(({ addNewRoster }) => (
  <div className='w-100'>
    <FileUploader
      addFile={addNewRoster}
      addBtnText={'Attach File'}
      addBtnType={'secondary'}
      acceptedFileTypes={allowedCsvExtensions}
    />
  </div>
));

RosterCSVUploader.propTypes = {
  addNewRoster: PropTypes.func.isRequired,
};

export default RosterCSVUploader;
