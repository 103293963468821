import {
  discountTypeEnum,
  restrictionTypeValueEnum,
} from '@constants/enums/couponEnums';
import { isNumber } from '@util/valueHelpers';

export const validateCoupon = (values) => {
  const errors = {};
  if (!values.code) {
    errors.code = ['Coupon code is required.'];
  }

  if (!values.description) {
    errors.description = ['Description is required.'];
  }

  if (!values.reason) {
    errors.reason = ['Reason is required.'];
  }

  if (!values.discountType && values.discountType !== 0) {
    errors.discountType = ['Discount type is required.'];
  }

  if (!values.discountAmount) {
    errors.discountAmount = ['Discount amount is required.'];
  } else if (!isNumber(values.discountAmount)) {
    errors.discountAmount = ['Discount amount must be a number.'];
  } else if (values.discountAmount <= 0) {
    errors.discountAmount = ['Discount amount must be a positive number.'];
  } else if (values.discountType === discountTypeEnum.Percentage && values.discountAmount > 100) {
    errors.discountAmount = ['Discount amount cannot be over 100%.'];
  }

  if (!values.validFrom) {
    errors.validFrom = ['Valid from date is required.'];
  }

  if (!values.validTo) {
    errors.validTo = ['Valid to date is required.'];
  }

  if (values.validFrom && values.validTo && new Date(values.validTo) < new Date(values.validFrom)) {
    errors.validTo = ['Valid to date must be after valid from date.'];
  }

  if (!values.quantity) {
    errors.quantity = ['Quantity is required.'];
  } else if (!isNumber(values.quantity)) {
    errors.quantity = ['Quantity must be a number.'];
  } else if (values.quantity <= 0) {
    errors.quantity = ['Quantity must be a positive number.'];
  }

  if (values.redemptionLimit && !isNumber(values.redemptionLimit)) {
    errors.redemptionLimit = ['Redemption limit must be a number.'];
  }

  if (values.restrictions) {
    const { restrictions } = values;
    if (restrictions && restrictions.length > 0) {
      const restrictionsErrors = [];
      for (const restriction of restrictions) {
        const amountErrors = [];
        if (restriction.restrictionType === restrictionTypeValueEnum.Amount) {
          if (!restriction.value || (parseInt(restriction.value) || 0) <= 0) {
            amountErrors.push({ value: 'Amount should be greater than 0' });
          }
        }
        if (amountErrors.length > 0) {
          restrictionsErrors[restrictionTypeValueEnum.Amount] = amountErrors;
        }
      }
      if (restrictionsErrors.length > 0) {
        errors.restrictions = restrictionsErrors;
      }
    }
  }

  return errors;
};
