import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatDollarAmount } from '@util/numberHelpers';
import Icon from '@sharedComponents/Icons/Icon';
import Label from '@sharedComponents/Display/Label';
import { isDefined } from '@util/valueHelpers';

class OrderManagementExpandablePriceTab extends PureComponent {
  state = {
    contentIsExpanded: false,
  };

  changeExpand = () => {
    this.setState((prevState) => ({
      contentIsExpanded: !prevState.contentIsExpanded,
    }));
  };

  render() {
    const {
      label,
      price = null,
      subLabel = '',
      classes = '',
      content = null,
      disableExpand = false,
    } = this.props;

    const { contentIsExpanded } = this.state;

    return (
      <div>
        <div
          className={`order-financial-details__tab ${!disableExpand ? 'clickable' : ''} ${classes}`}
          onClick={!disableExpand && this.changeExpand}
        >
          <div className='flex'>
            <div className='order-financial-details__tab--text'>
              <span className='text-uppercase'>{label}</span>{` ${subLabel}`}
            </div>
            {
              !disableExpand &&
              <Icon materialIcon={contentIsExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
            }
          </div>
          {
            isDefined(price) &&
            <Label
              text={formatDollarAmount(price)}
              type={'draft'}
            />
          }
        </div>
        {(disableExpand || contentIsExpanded) && content}
      </div>
    );
  }
}

OrderManagementExpandablePriceTab.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.number,
  subLabel: PropTypes.string,
  classes: PropTypes.string,
  content: PropTypes.node,
  disableExpand: PropTypes.bool,
};

export default OrderManagementExpandablePriceTab;
