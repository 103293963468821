/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type OrderWarehouseStatusEnum = 'AwaitingInventory' | 'AwaitingDispatch' | 'InPicking';


export const OrderWarehouseStatusEnum = {
  AwaitingInventory: 'AwaitingInventory' as OrderWarehouseStatusEnum,
  AwaitingDispatch: 'AwaitingDispatch' as OrderWarehouseStatusEnum,
  InPicking: 'InPicking' as OrderWarehouseStatusEnum,
};
