import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  url?: Nullable<string>;
  classes?: Nullable<string>;
  openInModal?: Nullable<() => void>;
  showNoImage?: Nullable<boolean>;
  showImageInModal?: Nullable<boolean>;
}

type Props = OwnProps;

const Image = React.memo(({
  url = null,
  classes = '',
  openInModal = null,
  showNoImage = true,
  showImageInModal = true,
}: Props) => {
  if (!showNoImage && !url) {
    return null;
  }

  if (openInModal) {
    return (
      <div
        className={`image order__item-thumbnail button ${classes}`}
        style={{ backgroundImage: `url("${url}")` }}
      >
        {
          url &&
          <div
            onClick={showImageInModal ? openInModal.bind(null, url) : openInModal}
            className='details__item-overlay'
          >
            <Icon
              materialIcon={'search'}
              classes={'details__item-preview-icon'}
            />
          </div>
        }
      </div>
    );
  }

  return (
    <div
      className={`image ${classes}`}
      style={{ backgroundImage: `url("${url}")` }}
    />
  );
});

export default Image;
