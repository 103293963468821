import React from 'react';

interface OwnProps {
  error: string | string[];
  enableMultiple?: boolean;
  fieldError?: boolean;
  classes?: string;
}

type Props = OwnProps;

const FormError = React.memo(({
  error,
  enableMultiple = false,
  fieldError = false,
  classes = '',
}: Props): Nullable<JSX.Element> => {
  if (!error) return null;

  if (enableMultiple && error.length && Array.isArray(error)) {
    const errorArray = error as string[];

    return (
      <>
        {
          (errorArray).map((e, index) => (
            <div
              key={index}
              className={`redux-form__error${fieldError ? '--field' : ''} ${classes}`}
            >
              {e}
            </div>
          ))
        }
      </>
    );
  }

  const errorString = error as string;

  return <div className={`redux-form__error${fieldError ? '--field' : ''} ${classes}`}>{errorString}</div>;
});

export default FormError;
