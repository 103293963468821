import React from 'react';
import { orderItemFilesUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const OrderItemFilesLink = () => (
  <MenuTile
    title={'Order Item Files'}
    url={orderItemFilesUrl}
    icon={'picture_as_pdf'}
  />
);

export default OrderItemFilesLink;
