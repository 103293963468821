import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  InjectedFormProps,
} from 'redux-form';
import { StripeChargeInfoDto } from '@api/orders/models';
import { customOrderAssociationEnum } from '@constants/enums/orderEnums';
import { createCustomOrderForm } from '@constants/reduxForms';
import { CustomOrderFormData } from '@models/forms/OrderManagement/CustomOrderFormData';
import { validateCreateCustomOrder } from '@redux/orderManagement/validations';
import * as supportActions from '@redux/support/actions';
import * as organizationsActions from '@redux/organizations/actions';
import { RootState } from '@redux/index/reducers';
import { mapToOptionsList } from '@util/mappingHelper';
import Input from '@sharedComponents/Form/Input';
import FormError from '@sharedComponents/Form/FormError';
import DatePicker from '@sharedComponents/Form/DatePicker';
import FormDropdown from '@sharedComponents/Form/FormDropdown';
import RadioGroup from '@sharedComponents/Form/RadioGroup';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';
import PaymentIntentIdSearch from './CustomOrderFormContent/PaymentIntentIdSearch';
import moment from 'moment';
import { roleMapping } from '@util/roleCheck';
import { rolesEnum } from '@constants/enums/commonEnums';

interface OwnProps {
  syncLockersForNewOrder?: (organizationId?: number, lockerId?: number) => void;
  setPaymentIdVerification: (verification: boolean) => void;
}

const selector = formValueSelector(createCustomOrderForm);
const mapStateToProps = (state: RootState) => ({
  shippingOptions: state.support.shippingOptions,
  lockerSuggestions: state.organizations.lockerSuggestions,
  organizationSuggestions: state.organizations.organizationSuggestions,
  organizationId: selector(state, 'organizationId') as CustomOrderFormData['organizationId'],
  lockerId: selector(state, 'lockerId') as CustomOrderFormData['lockerId'],
  association: selector(state, 'association') as CustomOrderFormData['association'],
  method: selector(state, 'method') as CustomOrderFormData['method'],
  paymentIntentId: selector(state, 'paymentIntentId') as CustomOrderFormData['paymentIntentId'],
  prd: selector(state, 'prdOrder') as CustomOrderFormData['prdOrder'],
  shipDate: selector(state, 'shipDate') as CustomOrderFormData['shipDate'],
  customerShipDate: selector(state, 'customerShipDate') as CustomOrderFormData['customerShipDate'],
  roles: roleMapping(state.oidc),
});

const mapDispatchToProps = {
  fetchShippingOptions: supportActions.fetchShippingOptions,
  fetchLockerSuggestions: organizationsActions.fetchLockerSuggestions,
  fetchOrganizationSuggestions:
    organizationsActions.fetchOrganizationSuggestions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps & InjectedFormProps<CustomOrderFormData, FormProps, string[]>;

const displayLocker = (locker: any) => locker ? `L${locker.id} - ${locker.teamName}` : '';
const displayLockerSuggestion = (locker: any) => `L${locker.id} - ${locker.teamName}`;

const displayOrganization = (organization: any) => organization ? `ORG${organization.id} - ${organization.name}` : '';
const displayOrganizationSuggestion = (organization: any) => `ORG${organization.id} - ${organization.name}`;

const CustomOrderForm = React.memo(({
  handleSubmit,
  error,
  association,
  organizationId,
  lockerId,
  syncLockersForNewOrder,
  shippingOptions,
  change,
  lockerSuggestions,
  organizationSuggestions,
  fetchShippingOptions,
  fetchLockerSuggestions,
  fetchOrganizationSuggestions,
  initialValues,
  method,
  paymentIntentId,
  setPaymentIdVerification,
  shipDate,
  customerShipDate,
  roles,
}: Props) => {
  const [
    selectedLocker,
    setSelectedLocker,
  ] = useState(
    initialValues.lockerId
      ? {
        id: initialValues.lockerId,
        teamName: initialValues.lockerTeamName,
      }
      : null
  );
  const [
    selectedOrganization,
    setSelectedOrganization,
  ] = useState(
    initialValues.organizationId
      ? {
        id: initialValues.organizationId,
        name: initialValues.organizationName,
      }
      : null
  );

  const userIsSalesSupportRepresentative = useMemo(
    () => roles?.includes(rolesEnum.SLLockerManager)
    , [roles]
  );

  useEffect(() => {
    change('prdOrder', !userIsSalesSupportRepresentative);
  }, [userIsSalesSupportRepresentative]);

  useEffect(() => {
    fetchShippingOptions();
  }, [fetchShippingOptions]);

  useEffect(() => {
    if (!syncLockersForNewOrder) return;
    if (!organizationId && !lockerId) return;

    syncLockersForNewOrder(organizationId, lockerId);
  }, [
    organizationId,
    lockerId,
    syncLockersForNewOrder,
  ]);

  const onChangeAssociation = useCallback((e) => {
    const radioButtonOption = e.target.value;

    if (radioButtonOption === customOrderAssociationEnum.Locker) {
      change('organizationId', null);
      change('organizationName', null);
      setSelectedOrganization(null);
    } else if (
      radioButtonOption === customOrderAssociationEnum.Organization
    ) {
      change('lockerId', null);
      change('lockerTeamName', null);
      setSelectedLocker(null);
    }
  },
  [change]);

  const onClickOnAssociation = useCallback(
    (radioButtonOption: string) => {
      change('association', radioButtonOption);

      if (radioButtonOption === customOrderAssociationEnum.Locker) {
        change('organizationId', null);
        change('organizationName', null);
        setSelectedOrganization(null);
      } else if (
        radioButtonOption === customOrderAssociationEnum.Organization
      ) {
        change('lockerId', null);
        change('lockerTeamName', null);
        setSelectedLocker(null);
      }
    },
    [change]
  );

  const getLockerSuggestions = useCallback(
    (searchInput) => {
      fetchLockerSuggestions(searchInput);
    },
    [fetchLockerSuggestions]
  );

  const changeLocker = useCallback(
    (locker) => {
      change('lockerId', locker ? locker.id : null);
      change('lockerTeamName', locker ? locker.teamName : null);
      setSelectedLocker(locker);
    },
    [change]
  );

  const getOrganizationSuggestions = useCallback(
    (searchInput) => {
      fetchOrganizationSuggestions(searchInput);
    },
    [fetchOrganizationSuggestions]
  );

  const changeOrganization = useCallback(
    (organization) => {
      change('organizationId', organization ? organization.id : null);
      change('organizationName', organization ? organization.name : null);
      setSelectedOrganization(organization);
    },
    [change]
  );

  const onPaymentIntentIdVerified = useCallback(
    (
      paymentIntentIdValue: string,
      data: StripeChargeInfoDto
    ) => {
      change('name', data.customer?.name);
      change('email', data.customer?.email);
      change('addressLine1', data.customer?.address?.line1);
      change('addressLine2', data.customer?.address?.line2);
      change('city', data.customer?.address?.city);
      change('state', data.customer?.address?.state);
      change('zip', data.customer?.address?.postalCode);
      change('country', data.customer?.address?.country);
      change('phoneNumber', data.customer?.phone);
      change('stripeCustomerId', data.customer?.customerId);
      change('stripeChargeId', data.chargeId);
      change('paymentIntentId', paymentIntentIdValue);
      change('paymentDescription', data.description);
      change('paymentLink', data.url);
      setPaymentIdVerification(true);
    },
    []
  );

  const changeShippingPrice = useCallback(
    (value) => {
      let flag = false;
      for (const option of shippingOptions) {
        if (!flag && option.code === value) {
          flag = true;
          change('shippingBasePrice', `${parseFloat(option.price) / 100}`);
          break;
        }
      }
      if (!flag) {
        change('shippingBasePrice', '');
      }
    },
    [shippingOptions]
  );

  const optionsList = useMemo(
    () => mapToOptionsList<string, typeof shippingOptions>({
      list: shippingOptions,
      key: 'code',
      value: 'code',
      name: 'display_name',
      emptyOption: {
        name: 'Select Shipping Method',
      },
    }),
    [shippingOptions]
  );

  useEffect(() => {
    if (shipDate && !moment.isMoment(shipDate)) {
      change('shipDate', moment(shipDate));
    }
  }, [shipDate]);

  useEffect(() => {
    if (customerShipDate && !moment.isMoment(customerShipDate)) {
      change('customerShipDate', moment(customerShipDate));
    }
  }, [customerShipDate]);

  return (
    <form
      className='redux-form'
      id={createCustomOrderForm}
      onSubmit={handleSubmit}
    >
      <div className='roster-notifications__form mb-100'>
        <div className='create-custom-order--choice-box--row'>
          <div className='create-custom-order--choice-box--content'>
            <Field
              name={'association'}
              component={RadioGroup as any}
              radioItems={[
                {
                  value: customOrderAssociationEnum.Locker as string,
                  label: 'Associate with Single Locker',
                },
                {
                  value: customOrderAssociationEnum.Organization as string,
                  label: 'Associate with Organization',
                  className: 'create-custom-order--choice-box--item',
                },
              ]}
              groupClassName={'flex'}
              onChange={onChangeAssociation}
              onClickOnLabel={onClickOnAssociation}
            />
          </div>
        </div>
        <div className='redux-form__row-aligned'>
          {
            association === customOrderAssociationEnum.Locker && (
              <div className='redux-form__column--size-xl'>
                <label className='redux-form__label'>
                  Locker<span className='required'>*</span>
                </label>
                <AutocompleteInput
                  suggestions={lockerSuggestions}
                  fetchSuggestions={getLockerSuggestions}
                  displayItem={displayLocker}
                  displaySuggestionText={displayLockerSuggestion}
                  selectedItem={selectedLocker}
                  selectItem={changeLocker}
                  placeholder={'Search lockers'}
                  hasSearchIcon={true}
                />
              </div>
            )
          }
          {
            association === customOrderAssociationEnum.Organization && (
              <div className='redux-form__column--size-xl'>
                <label className='redux-form__label'>
                  Organization<span className='required'>*</span>
                </label>
                <AutocompleteInput
                  suggestions={organizationSuggestions}
                  fetchSuggestions={getOrganizationSuggestions}
                  displayItem={displayOrganization}
                  displaySuggestionText={displayOrganizationSuggestion}
                  selectedItem={selectedOrganization}
                  selectItem={changeOrganization}
                  placeholder={'Search organizations'}
                  hasSearchIcon={true}
                />
              </div>
            )
          }
          {
            !userIsSalesSupportRepresentative &&
            <div className='redux-form__column--size-s mr-30'>
              <div className='info__field'>
                <Field
                  name={'prdOrder'}
                  text={'This is a PRD order'}
                  component={ReduxFormCheckbox}
                  change={change}
                  disabled={true}
                />
              </div>
            </div>
          }
        </div>

        <div className='redux-form__row-aligned mr-10 mt-20'>
          <div className='redux-form__column--size-m mr-80'>
            <label className='redux-form__label'>
              Ship Date<span className='required'>*</span>
            </label>
            <Field
              name={'shipDate'}
              placeholder={'Choose Date'}
              component={DatePicker}
            />
          </div>
          <div className='redux-form__column--size-m mr-80'>
            <label className='redux-form__label'>
              Customer Ship Date<span className='required'>*</span>
            </label>
            <Field
              name={'customerShipDate'}
              placeholder={'Choose Date'}
              component={DatePicker}
            />
          </div>
        </div>

        <div className='redux-form__row-aligned mr-10 mt-20'>
          <PaymentIntentIdSearch
            onPaymentIntentIdVerified={onPaymentIntentIdVerified}
            paymentIntentId={paymentIntentId}
            setPaymentIdVerification={setPaymentIdVerification}
          />
        </div>

        <div className='tableView__details--header--title mt-30 mb-30'>
          <span>Shipping</span>
        </div>
        <div className='redux-form__row-aligned mr-10'>
          <div className='redux-form__column--size-xl mr-80'>
            <label className='redux-form__label'>
              Name<span className='required'>*</span>
            </label>
            <Field
              name={'name'}
              placeholder={'Enter Name'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-xl'>
            <label className='redux-form__label'>
              E-mail<span className='required'>*</span>
            </label>
            <Field
              name={'email'}
              placeholder={'Enter E-mail'}
              component={Input}
              type={'text'}
            />
          </div>
        </div>
        <div className='redux-form__row-aligned mr-10'>
          <div className='redux-form__column--size-xl mr-80'>
            <label className='redux-form__label'>
              Address Line 1<span className='required'>*</span>
            </label>
            <Field
              name={'addressLine1'}
              placeholder={'Enter Address'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-xl'>
            <label className='redux-form__label'>Address Line 2</label>
            <Field
              name={'addressLine2'}
              placeholder={'Enter Address'}
              component={Input}
              type={'text'}
            />
          </div>
        </div>
        <div className='redux-form__row-aligned mr-10'>
          <div className='redux-form__column--size-xl mr-80'>
            <label className='redux-form__label'>
              City<span className='required'>*</span>
            </label>
            <Field
              name={'city'}
              placeholder={'Enter City'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-xl'>
            <label className='redux-form__label'>
              State<span className='required'>*</span>
            </label>
            <Field
              name={'state'}
              placeholder={'Enter State'}
              component={Input}
              type={'text'}
            />
          </div>
        </div>
        <div className='redux-form__row-aligned mr-10'>
          <div className='redux-form__column--size-s'>
            <label className='redux-form__label'>
              ZIP code<span className='required'>*</span>
            </label>
            <Field
              name={'zip'}
              placeholder={'Enter ZIP code'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-s create-custom-order--checkbox-po-box'>
            <div className='info__field mb-0 ml-10'>
              <Field
                name={'poBox'}
                text={'PO Box'}
                component={ReduxFormCheckbox}
                change={change}
              />
            </div>
          </div>
          <div className='redux-form__column--size-xl'>
            <label className='redux-form__label'>Country</label>
            <Field
              name={'country'}
              placeholder={'Enter Country'}
              component={Input}
              type={'text'}
            />
          </div>
        </div>
        <div className='redux-form__row-aligned mr-10'>
          <div className='redux-form__column--size-m mr-80'>
            <label className='redux-form__label'>
              Phone<span className='required'>*</span>
            </label>
            <Field
              name={'phoneNumber'}
              placeholder={'Enter Phone Number'}
              component={Input}
              type={'text'}
            />
          </div>
          <div className='redux-form__column--size-m'>
            <label className='redux-form__label'>
              Shipping Method<span className='required'>*</span>
            </label>
            <Field
              name={'method'}
              component={FormDropdown}
              initialValue={method}
              options={optionsList}
              change={change}
              handleChange={changeShippingPrice}
              classes={
                'roster-notifications__form__templates-dropdown new-order__sizes-dropdown'
              }
            />
          </div>
        </div>
        <div className='redux-form__column--size-m'>
          <label className='redux-form__label'>
            Shipping Price
          </label>
          <Field
            name={'shippingBasePrice'}
            placeholder={'Enter Shipping Price'}
            component={Input}
            change={change}
            type={'text'}
          />
        </div>
        <FormError error={error} />
      </div>
    </form>
  );
});

export default connector(
  reduxForm<CustomOrderFormData, FormProps, string[]>({
    form: createCustomOrderForm,
    enableReinitialize: true,
    validate: validateCreateCustomOrder as any,
  })(CustomOrderForm)
);
