import React from 'react';

interface OwnProps {
  isFilteringItems: boolean;
  filteredItemsQuantity: number;
  clearSearch: () => void;
}

type Props = OwnProps;

const SearchResults = React.memo(({
  isFilteringItems,
  filteredItemsQuantity,
  clearSearch,
}: Props) => (
  <div className='order__expander'>
    {
      isFilteringItems &&
      <div className='order__items-results-found'>
        <div>{filteredItemsQuantity} {filteredItemsQuantity === 1 ? 'result' : 'results'} found.</div>
        <div
          className='link'
          onClick={clearSearch}
        >
          Clear search
        </div>
      </div>
    }
  </div>
));

export default SearchResults;
