import { VoucherOrderCreationSteps } from '@constants/enums/voucherEnums';
import * as actionTypes from './types';

type UpdateVoucherOrderCreationStepAction = {
  type: typeof actionTypes.UPDATE_VOUCHER_ORDER_CREATION_STEP;
  payload: VoucherOrderCreationSteps;
};

export const updateCurrentVoucherOrderCreationStep = (
  step: VoucherOrderCreationSteps
): UpdateVoucherOrderCreationStepAction => ({
  type: actionTypes.UPDATE_VOUCHER_ORDER_CREATION_STEP,
  payload: step,
});

export const resetCurrentVoucherOrderCreationStep = (): UpdateVoucherOrderCreationStepAction => ({
  type: actionTypes.UPDATE_VOUCHER_ORDER_CREATION_STEP,
  payload: VoucherOrderCreationSteps.LockersAndOrganizations,
});

export type VouchersAction = UpdateVoucherOrderCreationStepAction;
