import Modal from '@components/shared/Modal/Modal';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import React, { useCallback } from 'react';

interface OwnProps {
  orderNumber: number;
  hagPrintRoomJobId: number | undefined;
  handleSubmit: (hagPrintRoomJobId: number | undefined) => void;
  handleClose: () => void;
  isOpen: boolean;
}

type Props = OwnProps;

const PrintAssemblyFinalizeModal = React.memo<Props>(({
  orderNumber,
  hagPrintRoomJobId,
  handleSubmit,
  handleClose,
  isOpen,
}) => {
  const handleConfirm = useCallback(() => {
    handleSubmit(hagPrintRoomJobId);
  }, [
    hagPrintRoomJobId,
    handleSubmit,
  ]);

  return (
    <Modal
      title={`Finalize Print Assembly - Order O${orderNumber}`}
      isOpen={isOpen}
      closeModal={handleClose}
      modalSize={'l'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Confirm'}
          cancelBtnText={'Cancel'}
          onConfirm={handleConfirm}
          onClose={handleClose}
        />
      )}
    >
      <span>Are you sure you want to finalize this order?</span>
    </Modal>
  );
});

export default PrintAssemblyFinalizeModal;
