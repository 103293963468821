import { saveAs } from 'file-saver';
import rostersUrls from '@constants/sqdApiUrls/rostersUrls';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import { slServicesApi } from '../../sqdApis';

export const parseRosterFromCSV = async (csvFile) => {
  const formData = new FormData();

  formData.append('file', csvFile, csvFile.name);

  const call = slServicesApi.post(rostersUrls.parseRosterCSVFile, {
    body: formData,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const addMultipleRosters = async (addRostersForm) => {
  const body = {
    rosters: addRostersForm.rosters.map((roster) => ({
      teamName: roster.teamName ?? undefined,
      disabled: roster.disabled ?? undefined,
      lockerId: roster.lockerId ?? undefined,
      organizationId: roster.organizationId ?? undefined,
      members: roster.members.map((member) => ({
        firstName: member.firstName ?? undefined,
        lastName: member.lastName ?? undefined,
        number: member.number ?? undefined,
        phoneNumber: member.phoneNumber ?? undefined,
        role: member.role ?? undefined,
        size: member.size ?? undefined,
        division: member.division ?? undefined,
        email: member.email ?? undefined,
        emailAdditional: member.emailAdditional || [],
        phone: member.phone ?? undefined,
        phoneAdditional: member.phoneAdditional || [],
      })),
    })),
  };

  const call = slServicesApi.post(rostersUrls.rostersList, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const reimportRoster = async (rosterTeamId, addRostersForm) => {
  const body = {
    rosters: addRostersForm.rosters.map((roster) => ({
      teamName: roster.teamName ?? undefined,
      disabled: roster.disabled ?? undefined,
      lockerId: roster.lockerId ?? undefined,
      organizationId: roster.organizationId ?? undefined,
      members: roster.members.map((member) => ({
        firstName: member.firstName ?? undefined,
        lastName: member.lastName ?? undefined,
        number: member.number ?? undefined,
        phoneNumber: member.phoneNumber ?? undefined,
        role: member.role ?? undefined,
        size: member.size ?? undefined,
        division: member.division ?? undefined,
        email: member.email ?? undefined,
        emailAdditional: member.emailAdditional || [],
        phone: member.phone ?? undefined,
        phoneAdditional: member.phoneAdditional || [],
      })),
    })),
  };

  const call = slServicesApi.put(rostersUrls.reimportRoster(rosterTeamId), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const editRoster = async (editRosterForm) => {
  const body = {
    id: editRosterForm.id,
    teamName: editRosterForm.teamName ?? undefined,
    disabled: editRosterForm.disabled ?? undefined,
    lockerId: editRosterForm.lockerId ?? undefined,
    orderingDeadline: editRosterForm.orderingDeadline ?? undefined,
    collectionId: editRosterForm.collectionId ?? undefined,
  };

  const call = slServicesApi.put(rostersUrls.rosterTeam(editRosterForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const deleteRoster = async (rosterId) => {
  const call = slServicesApi.delete(rostersUrls.rosterTeam(rosterId));

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const downloadRosterCsvFile = async (rosterTeamId, lockerId, organizationId, empty = false) => {
  const call = slServicesApi.get(rostersUrls.rosterCSVFile(rosterTeamId, lockerId, organizationId, empty), {
    headers: {
      'Content-type': 'text/csv',
    },
    handleBlockingLoading: false,
  });

  const res = await makeApiCallWithErrorModal(call);
  const fileContent = res.file;

  const blob = new Blob([fileContent], { type: 'text/csv;charset=utf-8' });

  let fileName;
  if (empty) {
    fileName = 'rosters-template.csv';
  } else if (rosterTeamId) {
    fileName = `R${rosterTeamId}-rosters.csv`;
  } else if (lockerId) {
    fileName = `L${lockerId}-rosters.csv`;
  } else {
    fileName = `ORG${organizationId}-rosters.csv`;
  }

  saveAs(blob, fileName);
};

export const deleteTeamMembers = async (items, rosterTeamId) => {
  const body = JSON.stringify({
    teamMembers: items.map((i) => ({
      id: i.id,
    })),
  });
  const call = slServicesApi.put(rostersUrls.removeTeamMembers(rosterTeamId), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const editTeamMember = async (teamMemberForm) => {
  const body = {
    id: teamMemberForm.id,
    rosterTeamId: teamMemberForm.rosterTeamId,
    firstName: teamMemberForm.firstName ?? undefined,
    lastName: teamMemberForm.lastName ?? undefined,
    number: teamMemberForm.number ?? undefined,
    size: teamMemberForm.size ?? undefined,
    role: teamMemberForm.role ?? undefined,
    email: teamMemberForm.email ?? undefined,
    emailAdditional: teamMemberForm.emailAdditional ?? undefined,
    phone: teamMemberForm.phone ?? undefined,
    phoneAdditional: teamMemberForm.phoneAdditional ?? undefined,
  };

  const call = slServicesApi.put(rostersUrls.teamMember(teamMemberForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const addTeamMember = async (teamMemberForm) => {
  const body = {
    rosterTeamId: teamMemberForm.rosterTeamId,
    firstName: teamMemberForm.firstName ?? undefined,
    lastName: teamMemberForm.lastName ?? undefined,
    number: teamMemberForm.number ?? undefined,
    size: teamMemberForm.size ?? undefined,
    role: teamMemberForm.role ?? undefined,
    email: teamMemberForm.email ?? undefined,
    emailAdditional: teamMemberForm.emailAdditional ?? undefined,
    phone: teamMemberForm.phone ?? undefined,
    phoneAdditional: teamMemberForm.phoneAdditional ?? undefined,
  };

  const call = slServicesApi.post(rostersUrls.teamMembers, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};

export const deleteRosters = async (rosters) => {
  const body = JSON.stringify({
    ids: rosters.map((i) => i.id),
  });

  const call = slServicesApi.put(rostersUrls.removeRosters, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const toggleDisabledRosters = async (rosters) => {
  const body = JSON.stringify({
    ids: rosters.map((i) => i.id),
  });

  const call = slServicesApi.put(rostersUrls.toggleDisabledRosters, {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const sendRosterNotifications = async (rosterNotificationsForm) => {
  const body = {
    rosterIds: rosterNotificationsForm.rosterIds,
    startDate: rosterNotificationsForm.startDate,
    senderNameType: rosterNotificationsForm.senderNameType,
    linkDestination: rosterNotificationsForm.linkDestination,
    customSenderName: rosterNotificationsForm.customSenderName ?? undefined,
    orderingDeadline: rosterNotificationsForm.orderingDeadline ?? undefined,
    templateId: rosterNotificationsForm.templateId,
    subject: rosterNotificationsForm.subject ?? undefined,
  };

  const call = slServicesApi.post(rostersUrls.rosterNotifications, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const res = await makeApiCallWithSubmissionError(call);

  return res;
};
