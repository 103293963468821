import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const RemoveRostersModal = React.memo(({
  isOpen,
  removeRosters,
  closeModal,
  rosters,
}) => {
  if (!rosters) {
    return null;
  }

  return (
    <Modal
      title={'Delete Roster(s)'}
      modalSize={'l'}
      isOpen={isOpen}
      closeModal={closeModal}
      modalClass={'lockerManager__delete-modal'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Yes, Delete'}
          cancelBtnText={'Cancel'}
          onConfirm={removeRosters.bind(null, rosters)}
          onClose={closeModal}
        />
      )}
    >
      <div className='lockerManager__delete-modal--content'>
        <h3>Are you sure you want to delete the { rosters.length > 1 ? 'roster teams' : 'roster team' } listed below?</h3>
        <ul style={{ listStyle: 'disc' }}>
          {
            rosters.map((r) => (
              <li key={r.id}>
                <strong>{r.teamName}</strong>
              </li>
            ))
          }
        </ul>
      </div>
    </Modal>
  );
});

RemoveRostersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  removeRosters: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  rosters: PropTypes.arrayOf(PropTypes.shape({
    teamName: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    lockerId: PropTypes.number,
    lockerTeamName: PropTypes.string,
    organizationId: PropTypes.number,
    organizationName: PropTypes.string,
    lockerManagerId: PropTypes.string,
  })).isRequired,
};

export default RemoveRostersModal;
