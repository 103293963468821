import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class RemoveLockersModal extends Component {
  onClickRemoveLockers = () => {
    const { removeLockers } = this.props;
    removeLockers();
  };

  render() {
    const {
      isOpen,
      closeModal,
      lockers,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title={'Remove Lockers from Organization'}
        modalHeight={'xl'}
        modalWidth={'m'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            onConfirm={this.onClickRemoveLockers}
            confirmBtnText={'Remove'}
            isDangerousAction={true}
          />
        )}
      >
        <h3 className='modal__description'>
          <div>Are you sure you would like to remove these lockers from the organization?</div>
          <ul>
            {
              lockers.map((locker) => (
                <li key={locker.id} >
                  <b>L{locker.id} - {locker.team_name}</b>
                </li>
              ))
            }
          </ul>
        </h3>
      </Modal>
    );
  }
}

RemoveLockersModal.propTypes = {
  lockers: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  removeLockers: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default RemoveLockersModal;
