import { PagedList } from '@models/common/PagedList';
import * as actionTypes from './types';
import { Variation } from './models';

export interface CoreState {
  variationsQueue: PagedList<Variation>;
}

const initialState: CoreState = {
  variationsQueue: {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
  },
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.UPDATE_VARIATIONS_QUEUE:
      return {
        ...state,
        variationsQueue: action.payload,
      };
    default:
      return state;
  }
}
