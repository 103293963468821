import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dragAndDropMessage } from '@constants/values';
import {
  getOrganizationSizeOptions,
  getOrganizationTypeOptions,
  getOrganizationLandingPageOptions,
} from '@constants/options/options';
import { organizationForm } from '@constants/reduxForms';
import { fetchAccountExecutives } from '@redux/admins/actions';
import { generalOptions } from '@util/optionsMap';
import {
  toggleArrayMember,
  toggleObjectArrayMemeber,
} from '@util/componentHelper';
import { parseStringToBoolean } from '@util/valueHelpers';
import ColorsDropdown from '@sharedComponents/Inputs/Dropdowns/ColorsDropdown';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import Input from '@sharedComponents/Form/Input';
import Textarea from '@sharedComponents/Form/Textarea';
import Select from '@sharedComponents/Form/Select';
import SearchInput from '@sharedComponents/Form/SearchInput/SearchInput';
import Icon from '@sharedComponents/Icons/Icon';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import FormError from '@sharedComponents/Form/FormError';

class OrganizationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadLogoIsActive: false,
      attachedFile: null,
      loading: false,
      message: {
        type: 'default',
        body: dragAndDropMessage(),
      },
      logoUrlInitial: props.initialValues ? props.initialValues.logoImage : '',
      logoUrl: props.initialValues ? props.initialValues.logoImage : '',
      colors: props.initialValues ? props.initialValues.colors : [],
      selectedTeamColors: props.initialValues && props.teamColors
        ? props.teamColors.filter((c) => (props.initialValues.colors || []).includes(c.code))
        : [],
      accountManager: props.initialValues ? props.initialValues.accountManager : '',
    };

    if (!props.initialValues) {
      props.change('logoImage', '');
      props.change('colors', []);
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAccountExecutives());
  }

  componentDidUpdate(prevProps) {
    const { teamColors } = this.props;
    const { colors } = this.state;

    if (prevProps.teamColors !== teamColors && colors && colors.length) {
      const selectedTeamColors = teamColors.filter((c) => colors.includes(c.code));
      this.setState(() => ({ selectedTeamColors }));
    }
  }

  changeAccountExecutive = (email) => {
    const { change } = this.props;
    change('accountManager', email);
    this.setState(() => ({ accountManager: email }));
  };

  uploadContainerOnClick = () => {
    this.setState(() => ({ uploadLogoIsActive: true }));
  };

  onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    const file = acceptedFiles[0];

    reader.onloadend = () => {
      const { result } = reader;
      this.setState(() => ({ logoUrl: result }));
    };
    reader.readAsDataURL(file);

    this.setState(() => ({
      message: {
        body: `File ${acceptedFiles[0].name} has been attached.`,
        type: 'default',
      },
      attachedFile: acceptedFiles,
    }));

    const { change } = this.props;
    change('attachedFile', acceptedFiles);
  };

  toggleSelectColor = (color) => {
    const {
      colors,
      selectedTeamColors,
    } = this.state;
    const {
      change,
      teamColors,
    } = this.props;
    const teamColor = teamColors.find((c) => c.code === color);

    const newColors = toggleArrayMember(colors, color);
    const newTeamColors = toggleObjectArrayMemeber(selectedTeamColors, teamColor, 'code');

    this.setState(() => ({
      colors: newColors,
      selectedTeamColors: newTeamColors,
    }));
    change('colors', newColors);
  };

  render() {
    const {
      error,
      disabled = {},
      onSubmit,
      teamColors,
      accountExecutives,
    } = this.props;

    const {
      logoUrlInitial,
      logoUrl,
      uploadLogoIsActive,
      loading,
      message,
      colors,
      selectedTeamColors,
      accountManager,
    } = this.state;

    const dropzoneContent = loading
      ? (
        <label className='button'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label className={`button message-${message.type}`}>{message.body}</label>;

    const typeOptions = generalOptions(getOrganizationTypeOptions(true, 'Type'));
    const sizeOptions = generalOptions(getOrganizationSizeOptions(true, 'Choose Size'));
    const landingPageOptions = generalOptions(getOrganizationLandingPageOptions());

    const colorDropdownText = selectedTeamColors && selectedTeamColors.length > 0
      ? (
        <div>
          {
            selectedTeamColors.map((c) => (
              <ColorDisplay
                key={c.id}
                colorName={c.name}
                hexValue={c.hexValue}
              />
            ))
          }
        </div>
      )
      : <span>Choose 3 Colors</span>;

    return (
      <form
        className='redux-form'
        onSubmit={onSubmit}
        id={organizationForm}
      >
        <div>
          <div className='flex'>
            <div className='w-60'>
              <div className='tableView__details--header--title mb-30'>
                <span>Basic Information</span>
              </div>
              <div className='ml-15'>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-l'>
                    <label className='redux-form__label'>
                      Organization Name
                      <span className='required'>*</span>
                    </label>
                    <Field
                      name={'name'}
                      placeholder={'Enter Name'}
                      component={Input}
                      type={'text'}
                      disabled={disabled.name}
                    />
                  </div>

                  <div className='redux-form__column--size-s ml-20'>
                    <label className='redux-form__label'>
                      Landing Page
                    </label>
                    <Field
                      name={'landingPageEnabled'}
                      component={Select}
                      disabled={disabled.landingPageEnabled}
                      parse={parseStringToBoolean}
                    >
                      {landingPageOptions}
                    </Field>
                  </div>
                </div>

                <div className='redux-form__column--size-l'>
                  <label className='redux-form__label'>
                    Decision Maker
                    <span className='required'>*</span>
                  </label>
                  <Field
                    name={'lockerManagerEmail'}
                    placeholder={'Add Using E-mail'}
                    component={Input}
                    type={'text'}
                    disabled={disabled.lockerManagerEmail}
                  />
                </div>

                <div className='redux-form__row'>
                  <div className='redux-form__column--size-l'>
                    <label className='redux-form__label'>
                      Type
                      <span className='required'>*</span>
                    </label>
                    <Field
                      name={'orgType'}
                      component={Select}
                      disabled={disabled.orgType}
                    >
                      {typeOptions}
                    </Field>
                  </div>

                  <div className='redux-form__column--size-s ml-20'>
                    <label className='redux-form__label'>
                      Size
                    </label>
                    <Field
                      name={'size'}
                      component={Select}
                      disabled={disabled.size}
                    >
                      {sizeOptions}
                    </Field>
                  </div>
                </div>

                <div className='redux-form__column--size-xl mb-15'>
                  <label className='redux-form__label'>
                    Account Executive
                  </label>
                  <Field
                    name={'accountManager'}
                    component={SearchInput}
                    disabled={disabled.accountManager}
                    allItems={accountExecutives}
                    currentItem={accountManager}
                    changeItem={this.changeAccountExecutive}
                    searchKey={'email'}
                    displayKey={'email'}
                    placeholderText={'Search account executive'}
                    isFixed={false}
                  />
                </div>

                <div className='redux-form__column--size-xl'>
                  <label className='redux-form__label'>
                    Website
                  </label>
                  <Field
                    name={'website'}
                    placeholder={'Enter website URL'}
                    component={Input}
                    type={'text'}
                    disabled={disabled.website}
                  />
                </div>

                <div className='redux-form__column--size-xl'>
                  <label className='redux-form__label'>
                    Description
                  </label>
                  <Field
                    name={'description'}
                    placeholder={'Enter Organization Description'}
                    component={Textarea}
                    type={'text'}
                    disabled={disabled.description}
                  />
                </div>
              </div>
            </div>
            <div className='w-40 pl-20'>
              <div className='tableView__details--header--title mb-30 pl-40'>
                <span>Organization Logo</span>
              </div>
              <div>
                <div className='lockerEdit__information-logo'>
                  <div className='organization__logo'>
                    <img
                      className='organization__logo--image'
                      src={logoUrl}
                    />
                  </div>
                  <div className='lockerEdit__information-logo--actions'>
                    {
                      logoUrlInitial
                        ? (
                          <a
                            className='lockerManagerEdit__details-header--edit'
                            onClick={this.uploadContainerOnClick}
                          >
                            <Icon materialIcon={'add_photo_alternate'} />
                            <span className='uppercase'>Reupload</span>
                          </a>
                        )
                        : (
                          <a
                            className='lockerManagerEdit__details-header--edit'
                            onClick={this.uploadContainerOnClick}
                          >
                            <Icon materialIcon={'swap_horiz'} />
                            <span className='uppercase'>Upload</span>
                          </a>
                        )}
                  </div>
                </div>

                <div className='redux-form__column--size-xl organization__color-dropdown'>
                  <label className='redux-form__label'>
                    Organization Colors
                  </label>
                  <div className='select-field'>
                    <ColorsDropdown
                      allColors={teamColors}
                      selectedColors={colors}
                      toggleSelectColor={this.toggleSelectColor}
                      text={colorDropdownText}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`p-l-16 p-r-16 lockerEdit__information-change-logos ${uploadLogoIsActive && 'is-active'}`}>
            <div className='upload-field--horizontal m-t-16 w-100'>
              <div className='upload-field '>
                <DropZoneWrapper
                  onDrop={this.onDrop}
                  multiple={false}
                  className={'block-drop-zone'}
                >
                  {dropzoneContent}
                </DropZoneWrapper>
              </div>
            </div>
          </div>

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

OrganizationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
  disabled: PropTypes.object,
  change: PropTypes.func,
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.string,
  })).isRequired,
  accountExecutives: PropTypes.array,
};

const mapStateToProps = ({
  support,
  admins,
}) => ({
  teamColors: support.teamColors,
  accountExecutives: admins.accountExecutives,
});

export default connect(mapStateToProps)(OrganizationForm);
