import React from 'react';
import Button from '@sharedComponents/Buttons/Button';

interface OwnProps {
  isCustomItem?: boolean;
  title?: string | JSX.Element;
  image?: string;
  openUploadImgContainer: (e: React.MouseEvent) => void;
  removeImage: (e: React.MouseEvent) => void;
}

type Props = OwnProps;

const CustomItemImage = React.memo<Props>(({
  isCustomItem = true,
  title = '',
  image,
  openUploadImgContainer,
  removeImage,
}) => (
  <div className='lockerManagerEdit__custom-items--image'>
    <div className='lockerManagerEdit__custom-items--image__title'>{title}</div>
    <div className='lockerManagerEdit__custom-items--image__content'>
      {image && <img src={image} />}
      {
        isCustomItem &&
        <div className='btn-container'>
          <Button
            type={'secondary'}
            text={image ? 'Remove' : 'Upload'}
            onClick={image ? removeImage : openUploadImgContainer}
            size={'s'}
          />
        </div>
      }
    </div>
  </div>
));

export default CustomItemImage;
