import React from 'react';
import PropTypes from 'prop-types';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import Modal from '@sharedComponents/Modal/Modal';

const DtgEnvironmentViewModal = React.memo((props) => {
  /* eslint react/no-multi-comp: "off" */
  const displayItem = (item, prefix) => {
    if (typeof item === 'string' || typeof item === 'number') {
      return <b>{item}{prefix}</b>;
    }

    return <i>N/A</i>;
  };

  const {
    isOpen,
    closeModal,
    dtgEnvironment,
  } = props;

  const {
    environmentName,
    note,
    chokePercent,
    underbasePercent,
    highlightPercent,
    highlightGenerator,
    fuzzinessPercent,
    colorStrengthPercent,
    colorBoosterPercent,
    platenPlacementX,
    platenPlacementY,
    palletSizeName,
  } = dtgEnvironment || {};

  return (
    <Modal
      title={environmentName}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
    >
      {
        dtgEnvironment &&
        <GridContainer>
          <Row>
            <Col md={4}>Choke: {displayItem(chokePercent, '%')}</Col>
            <Col md={4}>Underbase: {displayItem(underbasePercent, '%')}</Col>
          </Row>
          <Row>
            <Col md={4}>Color Strength: {displayItem(colorStrengthPercent, '%')}</Col>
            <Col md={4}>Color Booster: {displayItem(colorBoosterPercent, '%')}</Col>
          </Row>
          <Row>
            <Col md={4}>Highlight: {displayItem(highlightPercent, '%')}</Col>
            <Col md={8}>Highlight Generator: {displayItem(highlightGenerator, '')}</Col>
          </Row>
          <Row>
            <Col md={4}>Fuzziness: {displayItem(fuzzinessPercent, '%')}</Col>
            <Col md={8}>Platen Placement (X,Y): {displayItem(platenPlacementX, '')}, {displayItem(platenPlacementY, '')}</Col>
          </Row>
          <Row>
            <Col md={8}>Pallet Size Name: {displayItem(palletSizeName, '')}</Col>
          </Row>
          {
            note &&
            <Row>
              <Col md={12}>{' '}</Col>
            </Row>
          }
          {
            note &&
            <Row>
              <Col md={2}>Note:</Col>
              <Col md={10}><i>{note}</i></Col>
            </Row>
          }
        </GridContainer>
      }
    </Modal>
  );
});

DtgEnvironmentViewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  dtgEnvironment: PropTypes.object,
};

export default DtgEnvironmentViewModal;
