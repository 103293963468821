import React from 'react';
import { adminDetailsUrl } from '@constants/clientUrls/clientUrls';
import { TableQuickViewSizeEnum } from '@constants/enums/tableEnums';
import { Admin } from '@models/Admins/Admin';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import InfoField from '@sharedComponents/Display/InfoField';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';

interface OwnProps {
  admin: Nullable<Admin>;
  closeDetails: () => void;
}

type Props = OwnProps;

const AdminsQuickView = React.memo<Props>(({
  admin,
  closeDetails,
}) => {
  if (!admin) return null;

  return (
    <TableQuickViewContainer
      noPadding={true}
      size={TableQuickViewSizeEnum.Medium}
    >
      <>
        <TableQuickViewHeader
          title={<span>{admin.fullName || '-'}</span>}
          onClose={closeDetails}
        />
        <div className='tableView__details--content-container'>
          <div className='flex'>
            <InfoField
              label={'Phone Number'}
              value={admin.phoneNumber}
              fieldClass={'size-30'}
            />
            <InfoField
              label={'E-mail address'}
              value={admin.email}
              fieldClass={'ml-15'}
            />
          </div>
          <div className='flex'>
            <InfoField
              label={'Lockers'}
              value={admin.totalLockers}
              defaultValue={0}
              fieldClass={'size-30'}
            />
            <InfoField
              label={'Classification'}
              value={admin.classification}
              defaultValue={'Unclassified'}
              fieldClass={'size-30 ml-15'}
            />
            <InfoField
              label={'Organizations'}
              value={admin.totalOrganizations}
              defaultValue={0}
              fieldClass={'ml-15'}
            />
          </div>
          <InfoField
            label={'Notes'}
            value={admin.notes}
          />
        </div>
        <div className='align__bottom'>
          <ButtonLink
            type={'primary'}
            text={'Manage Admin'}
            linkTo={adminDetailsUrl(admin.id)}
            classes={'w-100'}
            linkClasses={'w-100 p-20'}
          />
        </div>
      </>
    </TableQuickViewContainer>
  );
});

export default AdminsQuickView;
