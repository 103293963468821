import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@sharedComponents/Icons/Icon';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import SkuSetupColoredStylesList from './SkuSetupColoredStylesList';

const SkuSetupStatusChangeModal = React.memo(({
  isOpen,
  closeModal,
  changeStatus,
  coloredStyles,
  disabledColoredStyles = [],
  status,
  styleName,
}) => (
  <Modal
    title={'Change Status'}
    modalSize={'l'}
    isOpen={isOpen}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Yes, change'}
        cancelBtnText={'Cancel'}
        onConfirm={changeStatus.bind(null, coloredStyles.map((cs) => cs.id), status)}
        displayConfirmBtn={coloredStyles.length > 0}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <div>
      {
        coloredStyles.length > 0 &&
        <div>
          <div className='sku-setup__modal--text bold'>
            {`Are you sure you want to move selected colored ${coloredStyles.length === 1 ? 'style' : 'styles'} to the status ${status}?`}
          </div>
          <SkuSetupColoredStylesList
            coloredStyles={coloredStyles}
            styleName={styleName}
          />
        </div>
      }

      {
        disabledColoredStyles.length > 0 &&
        <div>
          <div className='flex'>
            <Icon
              materialIcon={'warning'}
              classes={'redux-form__icon orange mr-5 mt-5'}
            />
            <div className='sku-setup__modal--text warning'>
              {`Colored ${disabledColoredStyles.length === 1 ? 'style' : 'styles'} listed below won't be moved to the status ${status}. Please finish the setup first.`}
            </div>
          </div>
          <SkuSetupColoredStylesList
            coloredStyles={disabledColoredStyles}
            styleName={styleName}
          />
        </div>
      }
    </div>
  </Modal>
));

SkuSetupStatusChangeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabledColoredStyles: PropTypes.arrayOf(PropTypes.object),
  changeStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  styleName: PropTypes.string.isRequired,
};

export default SkuSetupStatusChangeModal;
