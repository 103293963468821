/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  OrderItemFileJobDtoPagedList,
  GetHomefieldApiOrderitemfilesJobsParams,
  ApiResponse,
  UpdateFileJobCommand,
  UploadItemFilesCommand
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiOrderitemfilesJobs = <TData = OrderItemFileJobDtoPagedList>(
    params?: GetHomefieldApiOrderitemfilesJobsParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/order-item-files/jobs`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiOrderitemfilesJobsQueryKey = (params?: GetHomefieldApiOrderitemfilesJobsParams,) => [`/homefield/api/order-item-files/jobs`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiOrderitemfilesJobs = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiOrderitemfilesJobs, OrderItemFileJobDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiOrderitemfilesJobsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiOrderitemfilesJobsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiOrderitemfilesJobs<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiOrderitemfilesJobs = <TData = ApiResponse>(
    updateFileJobCommand: UpdateFileJobCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/order-item-files/jobs`, method: 'put',
      data: updateFileJobCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiOrderitemfilesJobs = <TData = AsyncReturnType<typeof putHomefieldApiOrderitemfilesJobs,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdateFileJobCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdateFileJobCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiOrderitemfilesJobs<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiOrderitemfilesFiles = <TData = ApiResponse>(
    uploadItemFilesCommand: UploadItemFilesCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/order-item-files/files`, method: 'post',
      data: uploadItemFilesCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiOrderitemfilesFiles = <TData = AsyncReturnType<typeof postHomefieldApiOrderitemfilesFiles,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UploadItemFilesCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UploadItemFilesCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiOrderitemfilesFiles<TData>(data,requestOptions)
      }, mutationOptions)
    }
    