import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class EditDecorationLocationsItem extends PureComponent {
  onClickOnDecoration = (e) => {
    const {
      decorationLocation,
      decorationLocations,
      isDisabled,
      addDecoration,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!this.isAlreadyInList(decorationLocations, decorationLocation, isDisabled)) {
      addDecoration(decorationLocation);
    }
  };

  isAlreadyInList = (decorationLocations, decorationLocation, isDisabled) => !!(
    decorationLocations.find((l) => l.locationId === decorationLocation.id) || isDisabled
  );

  render() {
    const {
      decorationLocation,
      decorationLocations,
      isDisabled,
    } = this.props;

    const alreadyInList = this.isAlreadyInList(decorationLocations, decorationLocation, isDisabled);

    return (
      <div
        className={`suggestions__row ${alreadyInList ? 'disabled' : ''}`}
        onClick={this.onClickOnDecoration}
      >
        {decorationLocation.code}
      </div>
    );
  }
}

EditDecorationLocationsItem.propTypes = {
  decorationLocation: PropTypes.object.isRequired,
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  addDecoration: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default EditDecorationLocationsItem;
