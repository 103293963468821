import React from 'react';

interface OwnProps {
  header: string;
  value?: string | number | null;
}

type Props = OwnProps;

const CommonHeaderCard = React.memo<Props>(({
  header,
  value,
}) => (
  <div className='sheet__files-item item-quantity-card' >
    <div className='sheet__files-title'>
      <div className='sheet__files-title-left flex'>
        {header}
      </div>
    </div>
    <ul className='sheet__files-list'>
      <li>
        <div className='flex'>
          {value}
        </div>
      </li>
    </ul>
  </div>
));

export default CommonHeaderCard;
