import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchInputItem extends Component {
  state = {
    disabled: (
      this.props.searchKey === ''
        ? this.props.item
        : this.props.item[this.props.searchKey || 'value']
    ) === this.props.currentValue || this.props.isDisabled,
  };

  componentDidUpdate(prevProps) {
    const {
      item,
      currentValue,
      searchKey,
      isDisabled,
    } = this.props;

    if (currentValue !== prevProps.currentValue) {
      const itemValue = searchKey === '' ? item : item[searchKey || 'value'];
      this.setState({ disabled: itemValue === currentValue || isDisabled });
    }
  }

  onClickOnItem = (e) => {
    const {
      item,
      addItem,
      searchKey,
      isDisabled,
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!isDisabled) {
      const itemValue = searchKey === '' ? item : item[searchKey || 'value'];
      addItem(itemValue);
    }
  };

  render() {
    const {
      item,
      displayKey,
    } = this.props;

    const { disabled } = this.state;

    return (
      <div
        className={`suggestions__row ${disabled ? 'disabled' : ''}`}
        onClick={this.onClickOnItem}
      >
        {displayKey === '' ? item : item[displayKey || 'name']}
      </div>
    );
  }
}

SearchInputItem.propTypes = {
  item: PropTypes.any.isRequired,
  displayKey: PropTypes.string,
  searchKey: PropTypes.string,
  addItem: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
};

export default SearchInputItem;
