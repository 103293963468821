import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ColorListItem extends PureComponent {
  onSelectColor = () => {
    const {
      color,
      handleSelect,
    } = this.props;

    handleSelect(color);
  };

  render() {
    const {
      isSelected,
      color,
      setHoveredColor,
      isDarkColor = false,
    } = this.props;

    return (
      <li
        onMouseEnter={setHoveredColor.bind(null, color.hexValue)}
        onMouseLeave={setHoveredColor.bind(null, null)}
        className={`color-dropdown__option button ${isSelected ? 'is-active' : ''}`}
      >
        <i
          className='material-icons'
          onClick={this.onSelectColor}
        >{isSelected ? 'check_box' : 'check_box_outline_blank'}
        </i>
        <div
          className={`color-swatch-item ${isDarkColor ? 'dark' : ''}`}
          style={{ backgroundColor: color.hexValue }}
          onClick={this.onSelectColor}
        >
          {color.code}
        </div>
      </li>
    );
  }
}

ColorListItem.propTypes = {
  isSelected: PropTypes.bool,
  color: PropTypes.shape({
    code: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
  setHoveredColor: PropTypes.func.isRequired,
  isDarkColor: PropTypes.bool,
};

export default ColorListItem;
