import React from 'react';
import PropTypes from 'prop-types';
import { storefrontRoot } from '@constants/urls/configUrls';
import Icon from '@sharedComponents/Icons/Icon';

const StoreLogo = React.memo(({
  logoUrl,
  lockerId,
  slug,
}) => (
  <div className='storeLogo'>
    <div className='storeLogo__logo'>
      <img src={logoUrl} />
    </div>
    <div className='storeLogo__link'>
      <a
        className='store-link'
        target='_blank'
        href={`${storefrontRoot}/#/lockers/${slug || lockerId}`}
        rel='noreferrer'
      >
        <Icon materialIcon={'launch'} />
        STORE
      </a>
    </div>
  </div>
));

StoreLogo.propTypes = {
  lockerId: PropTypes.number,
  logoUrl: PropTypes.string,
  slug: PropTypes.string,
};

export default StoreLogo;
