import React from 'react';
import PropTypes from 'prop-types';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import iconEye from '@assets/icon_hidden.svg';
import iconGray from '@assets/icon_eye_gray.svg';
import Icon from '@sharedComponents/Icons/Icon';

const StyleStatusIcons = React.memo(({
  style,
  coloredStyles,
  products,
}) => {
  const disabledColoredStyles = coloredStyles.filter((x) => x.disabled);
  const discontinuedColoredStyles = coloredStyles.filter((x) => x.discontinued);
  const disabledProducts = products.filter((x) => x.disabled);
  const discontinuedProducts = products.filter((x) => x.discontinued);

  const anyProductDisabled = disabledProducts.length > 0 ? true : false;
  const anyProductDiscontinued = discontinuedProducts.length > 0 ? true : false;
  const allProductsDisabled = disabledProducts.length === products.length ? true : false;
  const allProductsDiscontinued = discontinuedProducts.length === products.length ? true : false;

  const anyColoredStyleDisabled = disabledColoredStyles.length > 0 ? true : false;
  const anyColoredStyleDiscontinued = discontinuedColoredStyles.length > 0 ? true : false;
  const allColoredStylesDisabled = disabledColoredStyles.length === products.length ? true : false;
  const allColoredStylesDiscontinued = discontinuedColoredStyles.length === products.length ? true : false;

  const showDisabledIcon = style.disabled
    || (disabledColoredStyles.length > 0 && (anyColoredStyleDisabled || allColoredStylesDisabled))
    || (disabledProducts.length > 0 && (anyProductDisabled || allProductsDisabled));
  const showDiscontinuedIcon = style.discontinued
    || (discontinuedColoredStyles.length > 0 && (anyColoredStyleDiscontinued || allColoredStylesDiscontinued))
    || (discontinuedProducts.length > 0 && (anyProductDiscontinued || allProductsDiscontinued));

  return (
    <div className='product-catalog__style-icons'>
      {
        showDisabledIcon &&
        <MaterialTooltip
          tooltipText={(
            <>
              {
                style.disabled &&
                <div className='pre-line'>Style is disabled</div>
              }
              {
                (allColoredStylesDisabled || anyColoredStyleDisabled) &&
                <div className='pre-line'>{`${!allColoredStylesDisabled ? 'Some' : ''} Colored styles are disabled`}</div>
              }
              {
                (allProductsDisabled || anyProductDisabled) &&
                <div className='pre-line'>{`${!allProductsDisabled ? 'Some' : ''} SKUs are disabled`}</div>
              }
            </>
          )}
          placement={'top'}
          contentClasses={'align__center'}
        >
          <i
            className='product-catalog__hidden'
            style={{ backgroundImage: `url("${(style.disabled || allColoredStylesDisabled || allProductsDisabled) ? iconEye : iconGray}")` }}
          />
        </MaterialTooltip>
      }
      {
        showDiscontinuedIcon &&
        <MaterialTooltip
          tooltipText={(
            <>
              {
                style.discontinued &&
                <div className='pre-line'>Style is discontinued</div>
              }
              {
                (allColoredStylesDiscontinued || anyColoredStyleDiscontinued) &&
                <div className='pre-line'>{`${!allColoredStylesDiscontinued ? 'Some' : ''} Colored styles are discontinued`}</div>
              }
              {
                (allProductsDiscontinued || anyProductDiscontinued) &&
                <div className='pre-line'>{`${!allProductsDiscontinued ? 'Some' : ''} SKUs are discontinued`}</div>
              }
            </>
          )}
          placement={'top'}
          contentClasses={'align__center'}
        >
          <Icon
            materialIcon={'block'}
            classes={`${(style.discontinued || allColoredStylesDiscontinued || allProductsDiscontinued) ? 'active' : 'disabled'}`}
          />
        </MaterialTooltip>
      }
    </div>
  );
});

StyleStatusIcons.propTypes = {
  style: PropTypes.object.isRequired,
  coloredStyles: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
};

export default StyleStatusIcons;
