import React from 'react';
import { CollectionItemViewModel } from '@api/squadlockerServices/models/collectionItemViewModel';
import ItemRow from './ItemRow';

interface OwnProps {
  items: CollectionItemViewModel[];
}

type Props = OwnProps;

const ItemsList = React.memo<Props>(({ items }) => (
  <div className='roster-collection__items'>
    {
      items.map((item) => (
        <ItemRow
          key={item.id}
          item={item}
        />
      ))
    }
  </div>
));

export default ItemsList;
