import React from 'react';
import PropTypes from 'prop-types';
import { parseDateNumeric } from '@util/dateHandler';

const ArtworkTaskDetailsNoteChangeLogItem = React.memo(({
  logItem,
  index,
}) => {
  const itemDate = parseDateNumeric(logItem.date);

  return (
    <li
      key={index}
      className='history-item'
    >
      <div className='history-item__details'>
        <span className='history-item__timestamp'>
          {itemDate}
        </span>
        <span className='history-item__author'>
          {logItem.user}
        </span>
      </div>
      <div className='history-item__content'>
        {logItem.message}
      </div>
    </li>
  );
});

ArtworkTaskDetailsNoteChangeLogItem.propTypes = {
  logItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ArtworkTaskDetailsNoteChangeLogItem;
