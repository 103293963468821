import productionAssemblyUrls from '@constants/sqdApiUrls/productionAssemblyUrls';
import { fulfillmentApi } from '../../sqdApis';
import { makeApiCallWithErrorModal } from '@util/apiHelper';

export const markOrderShippingReady = async (orderNumber) => {
  const call = fulfillmentApi.put(productionAssemblyUrls.orderShippingReady(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markOrderItemCheckedIn = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.put(productionAssemblyUrls.orderItemsCheckedIn(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unmarkOrderItemCheckedIn = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.delete(productionAssemblyUrls.orderItemsCheckedIn(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markOrderItemPreTreated = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.put(productionAssemblyUrls.orderItemsPreTreated(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unmarkOrderItemPreTreated = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.delete(productionAssemblyUrls.orderItemsPreTreated(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markOrderItemWaterproofed = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.put(productionAssemblyUrls.orderItemsMarkWaterproofed(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unMarkOrderItemWaterproofed = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.put(productionAssemblyUrls.orderItemsUnMarkWaterproofed(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markOrderItemQualityChecked = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.put(productionAssemblyUrls.orderItemsQualityChecked(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unmarkOrderItemQualityChecked = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.delete(productionAssemblyUrls.orderItemsQualityChecked(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markOrderLineItemCheckedIn = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.put(productionAssemblyUrls.orderLineItemsCheckedIn(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unmarkOrderLineItemCheckedIn = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.delete(productionAssemblyUrls.orderLineItemsCheckedIn(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const markOrderLineItemDecorated = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.put(productionAssemblyUrls.orderLineItemsDecorated(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unmarkOrderLineItemDecorated = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.delete(productionAssemblyUrls.orderLineItemsDecorated(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const flagOrderLineItem = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.put(productionAssemblyUrls.flagOrderLineItems(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const unflagOrderLineItem = async (orderNumber, model) => {
  const body = JSON.stringify(model);
  const call = fulfillmentApi.delete(productionAssemblyUrls.unflagOrderLineItems(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const searchOrder = async (searchQuery) => {
  const call = fulfillmentApi.get(productionAssemblyUrls.searchOrder(searchQuery), {
    handleBlockingLoading: false,
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const updateOrderItemBarcode = async (orderNumber, orderItemId, barcode) => {
  const body = JSON.stringify({
    orderNumber,
    orderItemId,
    barcode,
  });

  const call = fulfillmentApi.put(productionAssemblyUrls.updateOrderItemBarcode(orderNumber), {
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  return makeApiCallWithErrorModal(call);
};

export const updateOrderItemsBarcodes = async (orderNumber, form) => {
  const promises = [];
  let countSuccess = 0;

  for (const itemBarcodeAssociation of form.itemBarcodes) {
    promises.push(updateOrderItemBarcode(
      orderNumber,
      itemBarcodeAssociation.orderItemId,
      itemBarcodeAssociation.barcode
    ));
  }

  const res = await Promise.all(promises);
  for (const r of res) {
    if (r && r.success) {
      countSuccess++;
    }
  }

  return countSuccess === promises.length;
};
