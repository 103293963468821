import React from 'react';
import { Option } from '@models/common/Option';

interface OwnProps<T extends string | number | boolean | null = string | number | boolean> {
  value: Option<T>['value'];
  name?: string;
  onClick: (value: Option<T>['value']) => void;
  disabled?: boolean;
  listItemClasses?: string;
}

type Props<T extends string | number | boolean | null = string | number | boolean> = OwnProps<T>;

const DropdownListItem = <T extends string | number | boolean | null = string | number | boolean>({
  value,
  name,
  onClick,
  disabled = false,
  listItemClasses = '',
}: Props<T>) => (
    <li
      onClick={!disabled ? onClick.bind(null, value) : undefined}
      className={`custom-dropdown__option button ${listItemClasses} ${disabled ? 'disabled' : ''}`}
    >
      <div>
        {name}
      </div>
    </li>
  );

export default React.memo(DropdownListItem) as typeof DropdownListItem;
