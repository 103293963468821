import * as actionTypes from './types';
import { fulfillmentApi } from '../../sqdApis';
import { printDataDefaultSorts } from '@constants/common';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import printRoomUrls from '@constants/sqdApiUrls/printRoomUrls';
import { makeApiCall } from '@util/apiHelper';

export const updateCurrentQueue = (data) => ({
  type: actionTypes.UPDATE_CURRENT_QUEUE,
  payload: data,
});

export const updatePrintSheetUsers = (data) => ({
  type: actionTypes.GET_PRINT_SHEET_USERS,
  payload: data,
});

export const clearPrintFiles = () => ({
  type: actionTypes.CLEAR_PRINT_FILES,
});

export const updateLogoCounts = (data) => ({
  type: actionTypes.UPDATE_LOGO_COUNTS,
  payload: data,
});
export const clearNonessentialQueryParams = () => ({
  type: actionTypes.CLEAR_NONESSENTIAL_QUERY_PARAMS,
});

// Currently affects only print sheets
export const updatePrintSheetArtworks = (data) => ({
  type: actionTypes.UPDATE_PRINT_SHEET_ARTWORKS,
  payload: data,
});

export const clearPrintSheetArtworks = () => ({
  type: actionTypes.CLEAR_PRINT_SHEET_ARTWORKS,
});

export const fetchPrintData = (
  status,
  pageNumber = 1,
  pageSize = 10,
  sortBy = '',
  sortDirection = '',
  claimedBy = '',
  expressProduction = null
) => async (dispatch) => {
  let sortOrder = sortBy;
  if (status && sortBy === '' && (printDataDefaultSorts.hasOwnProperty(status))) {
    sortOrder = printDataDefaultSorts[status];
  }

  let url = printRoomUrls.getPrintData(status, pageNumber, pageSize, sortBy, sortDirection, claimedBy ? claimedBy : '', expressProduction);
  if (status === printStatusEnum.Priority) {
    url = printRoomUrls.getPrintDataPriorityQueue(pageNumber, pageSize, sortBy, sortDirection, claimedBy ? claimedBy : '', expressProduction);
  }

  const call = fulfillmentApi.get(url, { handleBlockingLoading: false });
  const data = await makeApiCall(call);

  if (data) {
    return dispatch(
      updateCurrentQueue({
        queueType: status, // Possibly redundancy -- remove printStatuses coercion?
        totalPages: data.totalPages,
        totalCount: data.totalCount,
        sortBy: sortOrder,
        sortDirection,
        logoCount: data.logoCount,
        hasPreviousPage: data.hasPreviousPage,
        hasNextPage: data.hasNextPage,
        pageNumber: data.pageNumber,
        queue: data.items,
        claimedBy,
        pageSize,
      })
    );
  }
};

const getLogoCountsForStatus = async (status, counts) => {
  const call = fulfillmentApi.get(
    printRoomUrls.getPrintData(status, 1, 1, '', '', ''),
    { handleBlockingLoading: false }
  );

  const data = await makeApiCall(call);

  if (data && data.logoCount) {
    counts[status] = data.logoCount;
  } else {
    counts[status] = 0;
  }

  return data;
};

const getPriorityLogoCounts = async (counts) => {
  const call = fulfillmentApi.get(
    printRoomUrls.getPrintDataPriorityQueue(printStatusEnum.Priority, 1, 1, '', '', ''),
    { handleBlockingLoading: false }
  );

  const data = await makeApiCall(call);

  if (data && data.totalCount) {
    counts.priority = data.totalCount;
  } else {
    counts.priority = 0;
  }

  return data;
};

export const getLogoCounts = () => async (dispatch) => {
  const promises = [];
  const counts = {};

  promises.push(getLogoCountsForStatus('printReady', counts));
  promises.push(getLogoCountsForStatus('printing', counts));
  promises.push(getLogoCountsForStatus('done', counts));
  promises.push(getPriorityLogoCounts(counts));

  await Promise.all(promises);
  dispatch(updateLogoCounts(counts));
};

// Currently affects only print sheets
export const fetchPrintSheetArtworks = (sheetId) => async (dispatch) => {
  const call = fulfillmentApi.get(printRoomUrls.printSheetReworkTasks(sheetId), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updatePrintSheetArtworks(res));
  }
};

export const fetchPrintingUsers = () => async (dispatch) => {
  const call = fulfillmentApi.get(printRoomUrls.printingUsers, { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updatePrintSheetUsers(res));
  }
};
