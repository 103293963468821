import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  sortDirectionShortEnum,
  keyNameEnum,
} from '@constants/enums/commonEnums';
import { TableEnum } from '@constants/enums/tableEnums';
import { lockerStatuses } from '@constants/options/optionsValues';
import {
  fetchLockerManagerTableStateUpdate,
  fetchSportOptions,
  fetchPartners,
  fetchFulfillmentLockersInfo,
  updateLockerManager3MonthSalesTableState,
  clearCurrentQueue,
} from '@redux/lockerManager/actions';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
} from '@util/tableHelpers';
import { parseDateNumeric } from '@util/dateHandler';
import { formatDollarAmount } from '@util/numberHelpers';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import { mapToOptionsList } from '@util/mappingHelper';
import memoizeOne from '@util/memoHelper';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import SearchFilter from '@sharedComponents/Inputs/SearchFilter';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import StatusFilter from '@sharedComponents/Inputs/Dropdowns/StatusFilter';
import LockerManagerQuickView from './LockerManagerQuickView/LockerManagerQuickView';

const LockersTable = Table();

class LockerManager extends Component {
  state = {
    pageNumber: this.props.initialPageNumber,
    pageSize: this.props.initialPageSize,
    sortByState: this.props.initialSortColumn,
    sortOrder: this.props.initialSortDirection,
    searchInput: this.props.initialSearchInput,
    statusesFilter: this.props.initialStatusesFilter,
    sportFilter: this.props.initialSportFilter,
    hasOrdersFilter: this.props.initialHasOrdersFilter,
    selectedLocker: null,
    partnerFilter: this.props.initialPartnerFilter,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchSportOptions());
    dispatch(fetchPartners());
  }

  componentDidUpdate(prevProps) {
    const {
      queue,
      include3MonthSales,
      dispatch,
    } = this.props;

    if (queue && queue.length > 0 && prevProps.queue !== queue && include3MonthSales) {
      dispatch(fetchFulfillmentLockersInfo(queue.map((locker) => locker.id)));
    }
  }

  selectLocker = (selectedLocker) => {
    this.setState({ selectedLocker });
  };

  unselectLocker = () => {
    this.setState({ selectedLocker: null });
  };

  fetchData = (state, instance) => {
    const {
      sortByState,
      sortOrder,
    } = this.props;

    const {
      page,
      pageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn,
      sortDirection,
    } = getSortParamsFromTable(instance, sortDirectionShortEnum, sortByState, sortOrder);

    this.setState(() => ({
      pageNumber: page + 1,
      pageSize,
      sortByState: sortColumn,
      sortOrder: sortDirection,
    }), this.refreshData);
  };

  refreshData = () => {
    const { dispatch } = this.props;

    const {
      pageNumber,
      pageSize,
      sortByState,
      sortOrder,
      searchInput,
      statusesFilter,
      sportFilter,
      hasOrdersFilter,
      partnerFilter,
    } = this.state;

    if (searchInput?.trim() === '') {
      dispatch(clearCurrentQueue());

      return;
    }

    return dispatch(fetchLockerManagerTableStateUpdate(
      pageNumber,
      pageSize,
      searchInput,
      statusesFilter,
      sportFilter,
      hasOrdersFilter,
      sortByState,
      sortOrder,
      '',
      '',
      partnerFilter
    ));
  };

  statusChange = (statuses) => {
    this.setState(() => ({
      statusesFilter: statuses,
    }), this.refreshData);
  };

  sportChange = (value) => {
    value = value.trim();
    this.setState(() => ({
      sportFilter: value,
    }), this.refreshData);
  };

  partnerChange = (value) => {
    this.setState(() => ({
      partnerFilter: value,
    }), this.refreshData);
  };

  search = (value) => {
    this.setState(() => ({
      searchInput: value,
    }), () => {
      this.refreshData();
    });
  };

  clearSearch = () => {
    this.search('');
  };

  filterKey = (e) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    this.search(e.target.value);
  };

  toggleHasOrders = () => {
    this.setState((prevProps) => ({
      hasOrdersFilter: !prevProps.hasOrdersFilter,
    }), this.refreshData);
  };

  toggleInclude3MonthSales = () => {
    const {
      queue,
      dispatch,
      include3MonthSales,
    } = this.props;

    dispatch(updateLockerManager3MonthSalesTableState(!include3MonthSales));
    if (!include3MonthSales && queue && queue.length > 0) {
      dispatch(fetchFulfillmentLockersInfo(queue.map((locker) => locker.id)));
    }
  };

  getColumns = () => {
    const { selectedLocker } = this.state;

    const {
      lockersInfo,
      include3MonthSales,
    } = this.props;

    let columns = [
      {
        Header: <HeaderCell text={'ID'} />,
        accessor: 'id',
        sortable: true,
        minWidth: 50,
        Cell: (cellProps) => <Link to={lockerDetailsUrl(cellProps.value)}>L{cellProps.value}</Link>,
      },
      {
        Header: <HeaderCell text={'Locker Name'} />,
        accessor: 'team_name',
        minWidth: 120,
        sortable: true,
        className: 'text-bold',
        Cell: (cellProps) => (
          <div className='table__cell--size-l'>
            {
              cellProps.value &&
              <div>
                <div>{cellProps.value}</div>
                <div className='table__cell--details'>{cellProps.original.slug ? cellProps.original.slug : <span>&nbsp;</span>}</div>
              </div>
            }
          </div>
        ),
      },
      {
        Header: <HeaderCell text={'Locker Owner Email'} />,
        accessor: 'email',
        sortable: true,
        minWidth: 120,
      },
      {
        Header: 'Partner Name',
        accessor: 'partner_name',
        sortable: false,
        minWidth: 85,
      },
    ];

    if (!selectedLocker) {
      columns = [
        ...columns,
        {
          Header: 'Date Created',
          accessor: 'created_at',
          minWidth: 70,
          sortable: false,
          Cell: (cellProps) => cellProps.value && parseDateNumeric(cellProps.value),
        },
      ];

      if (include3MonthSales) {
        columns = [
          ...columns,
          {
            Header: <HeaderCell text={'3 Month Sales'} />,
            accessor: '',
            sortable: true,
            minWidth: 75,
            Cell: (cellProps) => {
              const lockerInfo = lockersInfo && lockersInfo.find((locker) => locker.id === cellProps.original.id);

              return formatDollarAmount(lockerInfo && lockerInfo.sales3Month);
            },
          },
        ];
      }

      columns = [
        ...columns,
        {
          Header: 'Sport',
          accessor: 'sport_name',
          sortable: false,
          minWidth: 65,
        },
        {
          Header: 'Status',
          accessor: 'status',
          sortable: false,
          minWidth: 65,
        },
      ];
    }

    return columns;
  };

  getTrProps = (state, rowInfo) => {
    const { selectedLocker } = this.state;

    return getSelectableTableRowProps(this.selectLocker, rowInfo, selectedLocker, 'id');
  };

  getFiltersToPreserve = memoizeOne((
    searchInput,
    statusesFilter,
    sportFilter,
    hasOrdersFilter
  ) => ({
    searchInput,
    statusesFilter,
    sportFilter,
    hasOrdersFilter,
  }));

  render() {
    const {
      queue,
      totalPages,
      sports,
      partners,
      hasNextPage,
      hasPreviousPage,
      include3MonthSales,
      initialPageNumber,
      initialPageSize,
      initialSortColumn,
      initialSortDirection,
      initialSearchInput,
      initialSportFilter,
      initialPartnerFilter,
    } = this.props;

    const {
      selectedLocker,
      searchInput,
      statusesFilter,
      sportFilter,
      hasOrdersFilter,
    } = this.state;

    const columns = this.getColumns();

    const sportOptions = mapToOptionsList({
      list: sports,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: `All sports (${sports.length})`,
      },
    });

    const partnerOptions = mapToOptionsList({
      list: partners,
      key: 'id',
      value: 'id',
      name: 'name',
      emptyOption: {
        name: `All partners (${partners.length})`,
      },
    });

    return (
      <div
        className='container'
        data-test='locker-manager'
      >
        <div className='flex'>
          <SearchFilter
            initialValue={initialSearchInput}
            search={this.filterKey}
            clearSearch={this.clearSearch}
          />
          <StatusFilter
            filter={this.statusChange}
            selected={statusesFilter}
            options={lockerStatuses}
          />
          <Dropdown
            options={sportOptions}
            onChange={this.sportChange}
            classes={'margin-left'}
            defaultValue={initialSportFilter}
          />
          <Dropdown
            options={partnerOptions}
            onChange={this.partnerChange}
            classes={'margin-left'}
            defaultValue={initialPartnerFilter}
          />
          <MaterialCheckbox
            disabled={false}
            text={'Only Lockers with Orders'}
            checked={hasOrdersFilter}
            onClick={this.toggleHasOrders}
          />
          {
            !selectedLocker &&
            <MaterialCheckbox
              text={'Include 3 Month Sales'}
              checked={include3MonthSales}
              onClick={this.toggleInclude3MonthSales}
              classes={'ml-20'}
            />
          }
        </div>

        <div className='master-detail'>
          <div className='w-100'>
            <div className='sheet'>
              <LockersTable
                data={queue}
                onFetchData={this.fetchData}
                columns={columns}
                getTrProps={this.getTrProps}
                totalPages={totalPages}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                narrow={true}
                dataTest={'locker-manager-table'}
                tableId={TableEnum.lockerManager}
                preserveState={true}
                sortDirEnum={sortDirectionShortEnum}
                initialPageNumber={initialPageNumber}
                initialPageSize={initialPageSize}
                initialSortColumn={initialSortColumn}
                initialSortDirection={initialSortDirection}
                filtersToPreserve={this.getFiltersToPreserve(
                  searchInput,
                  statusesFilter,
                  sportFilter,
                  hasOrdersFilter
                )}
              />
            </div>
          </div>
          {
            selectedLocker &&
            <LockerManagerQuickView
              closeDetails={this.unselectLocker}
              lockerId={selectedLocker.id}
            />
          }
        </div>
      </div>
    );
  }
}

LockerManager.propTypes = {
  totalPages: PropTypes.number.isRequired,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  queue: PropTypes.arrayOf(PropTypes.object).isRequired,
  sports: PropTypes.array.isRequired,
  partners: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  sortOrder: PropTypes.string.isRequired,
  sortByState: PropTypes.string.isRequired,
  sport: PropTypes.string,
  partner: PropTypes.string,
  include3MonthSales: PropTypes.bool.isRequired,
  hasOrders: PropTypes.bool.isRequired,
  lockersInfo: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    totalOrders: PropTypes.number,
    totalSales: PropTypes.number,
    sales3Month: PropTypes.number,
    totalFundraising: PropTypes.number,
  })),
};

const mapStateToProps = ({
  lockerManager,
  tableManager,
}) => ({
  initialPageNumber: tableManager.lockerManager.pageNumber,
  initialPageSize: tableManager.lockerManager.pageSize,
  initialSortColumn: tableManager.lockerManager.sortColumn,
  initialSortDirection: tableManager.lockerManager.sortDirection,
  initialSearchInput: tableManager.lockerManager.searchInput,
  initialStatusesFilter: tableManager.lockerManager.statusesFilter,
  initialSportFilter: tableManager.lockerManager.sportFilter,
  initialPartnerFilter: tableManager.lockerManager.partnerFilter,
  initialHasOrdersFilter: tableManager.lockerManager.hasOrdersFilter,
  queue: lockerManager.currentQueue.queue,
  totalPages: lockerManager.currentQueue.totalPages,
  hasPreviousPage: lockerManager.currentQueue.hasPreviousPage,
  hasNextPage: lockerManager.currentQueue.hasNextPage,
  include3MonthSales: lockerManager.lockerTableState.include3MonthSales,
  sports: lockerManager.sportOptions,
  partners: lockerManager.partners,
  lockersInfo: lockerManager.lockersInfo,
});

export default connect(mapStateToProps)(LockerManager);
