import React from 'react';
import PropTypes from 'prop-types';
import { categoryForm } from '@constants/reduxForms';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import CategoryForm from '../CategoryForm';

const CategoryAddModal = (props) => {
  const {
    isOpen,
    onSubmit,
    closeModal,
  } = props;

  return (
    <Modal
      title={'Add Category'}
      isOpen={isOpen}
      modalSize={'l'}
      closeModal={closeModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Add'}
          cancelBtnText={'Cancel'}
          onClose={closeModal}
          formSubmission={true}
          formId={categoryForm}
        />
      )}
    >
      <CategoryForm
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

CategoryAddModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default CategoryAddModal;
