import * as actionTypes from './types';

const initialState = {
  collectionsQueue: {
    totalPages: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    queue: [],
    pageNumber: 1,
    pageSize: 10,
  },
  collection: {},
  collectionItems: [],
};

export default function collections(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_COLLECTIONS_TABLE:
      return {
        ...state,
        collectionsQueue: action.data,
      };
    case actionTypes.UPDATE_COLLECTION:
      return {
        ...state,
        collection: {
          ...action.data,
          rosterTeamId: action.data.roster ? action.data.roster.id : undefined,
        },
      };
    case actionTypes.UPDATE_COLLECTION_ITEMS:
      return {
        ...state,
        collectionItems: action.data,
      };
    default:
      return state;
  }
}
