import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { validateDecorationLocationArtworkSize } from '@redux/decorationLocationArtworkSizes/validations';
import {
  generalOptions,
  createDecorationLocationOptions,
} from '@util/optionsMap';
import { parseNumber } from '@util/numberHelpers';
import { parseStringToBoolean } from '@util/valueHelpers';
import {
  getDecorationSizeOptions,
  getDecorationMethodsOptions,
  getTrueOrFalseOptions,
} from '@constants/options/options';
import { decorationLocationArtworkSizeForm } from '@constants/reduxForms';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import FormError from '@sharedComponents/Form/FormError';

const trueOrFalseOptions = generalOptions(getTrueOrFalseOptions());

const DecorationLocationArtworkSizeForm = (props) => {
  const {
    handleSubmit,
    decorationLocations,
    error,
  } = props;

  const sizeOptions = generalOptions(getDecorationSizeOptions(true, 'Default'));
  const methodOptions = generalOptions(getDecorationMethodsOptions(false));
  const decorationLocationOptions = createDecorationLocationOptions(decorationLocations);

  return (
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      id={decorationLocationArtworkSizeForm}
    >
      <div>
        <div className='redux-form__section pb-5'>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-xl'>
              <label className='redux-form__label'>
                Location
                <span className='required'>*</span>
              </label>
              <Field
                name={'decorationLocationId'}
                component={Select}
                type={'number'}
                parse={parseNumber}
              >
                {decorationLocationOptions}
              </Field>
            </div>
          </div>
          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Method
                <span className='required'>*</span>
              </label>
              <Field
                name={'decorationMethod'}
                component={Select}
              >
                {methodOptions}
              </Field>
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label'>
                Size
                <span className='required'>*</span>
              </label>
              <Field
                name={'decorationSize'}
                component={Select}
              >
                {sizeOptions}
              </Field>
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Width
                <span className='required'>*</span>
              </label>
              <Field
                name={'width'}
                placeholder={'Enter width'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </div>
            <div className='redux-form__column--size-m ml-20'>
              <label className='redux-form__label'>
                Height
                <span className='required'>*</span>
              </label>
              <Field
                name={'height'}
                placeholder={'Enter height'}
                component={Input}
                type={'number'}
                parse={parseNumber}
              />
            </div>
          </div>

          <div className='redux-form__row'>
            <div className='redux-form__column--size-m'>
              <label className='redux-form__label'>
                Scaling Size
                <span className='required'>*</span>
              </label>
              <Field
                name={'scalingSize'}
                component={Select}
                parse={parseStringToBoolean}
              >
                {trueOrFalseOptions}
              </Field>
            </div>
          </div>
        </div>

        <FormError error={error} />
      </div>
    </form>
  );
};

DecorationLocationArtworkSizeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default (reduxForm({
  form: decorationLocationArtworkSizeForm,
  validate: validateDecorationLocationArtworkSize,
  enableReinitialize: true,
  shouldError: () => true,
})(DecorationLocationArtworkSizeForm));
