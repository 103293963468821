import * as actionTypes from './types';
import productionAssemblyUrls from '@constants/sqdApiUrls/productionAssemblyUrls';
import logoUrls from '@constants/sqdApiUrls/logoUrls';
import dtgPrintingUrls from '@constants/sqdApiUrls/dtgPrintingUrls';
import productCatalogUrls from '@constants/sqdApiUrls/productCatalogUrls';
import {
  fulfillmentApi,
  slServicesApi,
  catalogApi,
} from '../../sqdApis';
import {
  makeApiCall,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';

export const updateOrder = (data) => ({
  type: actionTypes.UPDATE_ORDER,
  payload: data,
});

export const updateOrderItemsStatuses = (data) => ({
  type: actionTypes.UPDATE_ORDER_ITEMS_STATUSES,
  payload: data,
});

export const updateOrderLineItemsStatuses = (data) => ({
  type: actionTypes.UPDATE_ORDER_LINE_ITEMS_STATUSES,
  payload: data,
});

export const updateOrderLineItemsFlags = (data) => ({
  type: actionTypes.UPDATE_ORDER_LINE_ITEMS_FLAGS,
  payload: data,
});

export const clearLogos = () => ({
  type: actionTypes.CLEAR_LOGO_COLORS,
});

export const updateLogo = (data, logoId) => ({
  type: actionTypes.UPDATE_LOGO_COLORS,
  payload: {
    selectedColors: data,
    logoId,
  },
});

export const updateDtgStations = (data) => ({
  type: actionTypes.UPDATE_DTG_STATIONS,
  payload: data,
});

export const updateTransmissionsCount = (data) => ({
  type: actionTypes.UPDATE_TRANSMISSIONS_COUNT,
  payload: data,
});

export const updatePrintQueue = (data) => ({
  type: actionTypes.UPDATE_PRINT_QUEUE,
  payload: data,
});

export const updateDtgTranmissionDecorations = (data) => ({
  type: actionTypes.UPDATE_DTG_TRANSMISSION_DECORATIONS,
  payload: data,
});

export const updateProductDetailsDictionary = (data) => ({
  type: actionTypes.UPDATE_PRODUCTS_DICTIONARY,
  data,
});

export const updateDtgConfigurationColoredStyle = (data) => ({
  type: actionTypes.UPDATE_DTG_CONFIGURATION_COLORED_STYLE,
  data,
});

export const fetchLogo = (logoId) => async (dispatch) => {
  const call = slServicesApi.get(logoUrls.logo(logoId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);
  if (res) {
    let customColors = [];
    if (res.custom_artwork_colors && res.custom_artwork_colors.length > 0) {
      customColors = res.custom_artwork_colors.map((color) => ({
        code: color,
        displayName: color,
      }));
    }

    const selectedColors = res.selected_colors.map((c) => ({
      code: c.code,
      id: c.id,
      selectionType: c.selection_type,
      hexValue: c.hex_value,
    }));

    const selectedColorsAll = customColors.length > 0
      ? [
        ...selectedColors,
        customColors,
      ].flat()
      : selectedColors;

    return dispatch(updateLogo(selectedColorsAll, logoId));
  }
};

export const fetchOrder = (orderNumber) => async (dispatch) => {
  const call = fulfillmentApi.get(productionAssemblyUrls.searchOrder(orderNumber), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCallWithErrorModal(call);
  if (res) {
    return dispatch(updateOrder(res));
  }
};

export const getOrderItemsStatuses = (orderNumber) => async (dispatch) => {
  const call = fulfillmentApi.get(productionAssemblyUrls.orderItemsStatuses(orderNumber), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrderItemsStatuses(res));
  }
};

export const getOrderLineItemsStatuses = (orderNumber) => async (dispatch) => {
  const call = fulfillmentApi.get(productionAssemblyUrls.orderLineItemsStatuses(orderNumber), {
    handleBlockingLoading: false,
  });
  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrderLineItemsStatuses(res));
  }
};

export const getOrderLineItemsFlags = (orderNumber) => async (dispatch) => {
  const call = fulfillmentApi.get(productionAssemblyUrls.orderLineItemsFlags(orderNumber), {
    handleBlockingLoading: false,
  });

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateOrderLineItemsFlags(res));
  }
};

export const getDtgStations = () => async (dispatch) => {
  const call = fulfillmentApi.get(dtgPrintingUrls.dtgStations, {
    handleBlockingLoading: false,
  });

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgStations(res));
  }
};

export const getTransmissionsCount = (statuses) => async (dispatch) => {
  const call = fulfillmentApi.get(dtgPrintingUrls.transmissionsCount(statuses), {
    handleBlockingLoading: true,
  });

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateTransmissionsCount(res.transmissionsCount));
  }
};

export const getDtgTranmissionDecorations = (
  pageNumber = 1,
  pageSize = 10
) => async (dispatch) => {
  const call = fulfillmentApi.get(dtgPrintingUrls.dtgDecorationsTable(
    pageNumber,
    pageSize
  ), {
    handleBlockingLoading: false,
  });

  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateDtgTranmissionDecorations({
      totalPages: res.totalPages,
      hasPreviousPage: res.hasPreviousPage,
      hasNextPage: res.hasNextPage,
      pageNumber: res.pageNumber,
      pageSize: res.pageSize,
      queue: res.items,
      totalCount: res.totalCount,
    }));
  }
};

export const getProductBySku = (sku) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.product(sku), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateProductDetailsDictionary(res));
  }
};

export const getDtgColoredStyleConfigByColoredStyleId = (coloredStyleId) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.dtgColoredStyleConfigurationBySku(coloredStyleId),
    { handleBlockingLoading: false });

  const res = await makeApiCall(call);
  if (res) {
    dispatch(updateDtgConfigurationColoredStyle(res));
  }
};

export const getDtgColoredStyleConfigByManyIds = (coloredStyleIds) => async (dispatch) => {
  const call = catalogApi.get(productCatalogUrls.dtgColoredStyleConfigurationByManyIds(coloredStyleIds),
    { handleBlockingLoading: false });

  const res = await makeApiCall(call);
  if (res && res.items && res.items.length > 0) {
    for (const config of res.items) {
      dispatch(updateDtgConfigurationColoredStyle(config));
    }
  }
};
