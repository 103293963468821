import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getTodaysDate } from '@util/dateHandler';
import ShipmentDataForm from './ShipmentDataForm';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { shipmentDataForm } from '@constants/reduxForms';

class UpdateShipmentModal extends Component {
  state = {
    startDate: getTodaysDate(),
  };

  handleDateChange = (date) => {
    this.setState({ startDate: date });
  };

  render() {
    const {
      isOpen,
      onSubmit,
      error,
      initialValues,
      closeModal,
    } = this.props;

    const { startDate } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title={'Edit Shippment Information'}
        modalHeight={'l'}
        modalWidth={'s'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            formSubmission={true}
            confirmBtnText={'Update'}
            cancelBtnText={'Cancel'}
            formId={shipmentDataForm}
          />
        )}
      >
        <div className='order__personalization-modal'>
          <ShipmentDataForm
            onSubmit={onSubmit}
            error={error}
            initialValues={initialValues}
            handleDateChange={this.handleDateChange}
            startDate={startDate}
          />
        </div>
      </Modal>
    );
  }
}

UpdateShipmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default UpdateShipmentModal;
