import React from 'react';
import PropTypes from 'prop-types';

const OrganizationLogo = React.memo(({ logoUrl }) => (
  <div className='storeLogo'>
    <div className='storeLogo__logo'>
      <img src={logoUrl} />
    </div>
  </div>
));

OrganizationLogo.propTypes = {
  logoUrl: PropTypes.string,
};

export default OrganizationLogo;
