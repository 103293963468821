import React from 'react';
import { RosterTeamMemberViewModel } from '@api/squadlockerServices/models';
import ShowMoreActionsButton from '@sharedComponents/Buttons/ShowMoreActionsButton';

interface OwnProps {
  rosterMember: RosterTeamMemberViewModel;
  onRosterMemberEdit: (rosterMember: RosterTeamMemberViewModel) => void;
  onRosterMemberDelete: (rosterMember: RosterTeamMemberViewModel) => void;
  hasActiveVouchers: boolean;
}

type Props = OwnProps;

const RosterMembersActionsColumn = React.memo<Props>(({
  rosterMember,
  onRosterMemberEdit,
  onRosterMemberDelete,
  hasActiveVouchers,
}) => (
  <ShowMoreActionsButton
    actions={[
      {
        text: 'Edit',
        action: onRosterMemberEdit.bind(null, rosterMember),
        isVisible: true,
      },
      {
        text: 'Delete',
        action: onRosterMemberDelete.bind(null, rosterMember),
        isDangerous: true,
        isVisible: true,
        isDisabled: hasActiveVouchers,
        tooltipText: hasActiveVouchers
          ? 'Cannot remove a roster team member because the roster team has an active voucher associated with it'
          : undefined,
      },
    ]}
  />
));

export default RosterMembersActionsColumn;
