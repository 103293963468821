import { makeApiCallWithErrorModal } from '@util/apiHelper';
import { fulfillmentApi } from '../../sqdApis';
import supportUrls from '@constants/sqdApiUrls/supportUrls';

export const editApplicationSettings = async (model) => {
  const call = fulfillmentApi.put(supportUrls.applicationSettings, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(model),
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const generateNewTrainingSet = async (date) => {
  const call = fulfillmentApi.get(supportUrls.generateShippingData(date.toISOString()));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const startTraining = async () => {
  const call = fulfillmentApi.post(supportUrls.startTraining);
  const res = await makeApiCallWithErrorModal(call);

  return res;
};

/* Uploads and saves any type of file to disk */
export const uploadFile = async (file, api, url) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file, file.name);
  }

  const call = api.post(url, {
    body: formData,
  });

  const res = await makeApiCallWithErrorModal(call);

  if (res?.result) {
    return res.result;
  }
};
