import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { lockerMgrS3Logos } from '@constants/common';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import ImageModal from '@sharedComponents/Modal/ImageModal';
import Table from '@sharedComponents/Table/Table';
import Icon from '@sharedComponents/Icons/Icon';
import ItemLocationAndSizeCell from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemLocationAndSizeCell';

const ProductsTable = Table();

class AssignLogosToItemsReview extends PureComponent {
  state = {
    imageModalIsOpen: false,
    imageUrl: '',
  };

  openModalWithImage = (imageUrl) => {
    this.setState({
      imageModalIsOpen: true,
      imageUrl,
    });
  };

  closeImageModal = () => {
    this.setState({
      imageModalIsOpen: false,
      imageUrl: '',
    });
  };

  getColumns = () => {
    const columns = [
      {
        Header: 'Image',
        accessor: 'cached_image',
        className: 'no-padding',
        width: 120,
        Cell: (cellProps) => (
          <ImagePreviewCell
            imageUrl={cellProps.value}
            openModalWithUrl={this.openModalWithImage}
          />
        ),
      },
      {
        Header: 'Code & Description',
        accessor: '',
        width: 200,
        Cell: (cellProps) => (
          <div className='logo-bank__assignment--code'>
            <TitleDescriptionCell item={cellProps.value} />
          </div>
        ),
      },
      {
        Header: 'Color',
        accessor: 'color',
        width: 120,
      },
      {
        Header: 'Decoration',
        accessor: 'decoration_method',
        width: 80,
      },
      {
        Header: 'Location & Size',
        accessor: '',
        width: 120,
        Cell: (cellProps) => <ItemLocationAndSizeCell item={cellProps.value} />,
      },
    ];

    return columns;
  };

  render() {
    const {
      imageUrl,
      imageModalIsOpen,
    } = this.state;

    const {
      logos,
      productsToAddLogo,
    } = this.props;

    return (
      <div>
        <ImageModal
          isOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />
        <div className='logo-bank__review-title'>
          <span className='uppercase'>
            Add {logos.length > 1 ? 'Logos' : 'Logo'}:
          </span>
        </div>
        <div className='logo-bank__review-container'>
          <div className='logo-bank__review-logos custom-scrollbar ml-20'>
            {
              logos.map((logo) => (
                <div
                  key ={logo.id}
                  className='logo-bank__review-image small'
                  style={{ backgroundImage: `url("${lockerMgrS3Logos}/${logo.image}")` }}
                />
              ))
            }
          </div>
          <Icon materialIcon={'arrow_forward'} />
          <div className='logo-bank__review-title'>
            <span className='uppercase'>
              To Item(s): {productsToAddLogo.length}
            </span>
          </div>
          <ProductsTable
            data={productsToAddLogo}
            columns={this.getColumns()}
            showPagination={false}
            defaultPageSize={-1}
            classNames={'scroll-table'}
          />
        </div>
      </div>
    );
  }
}

AssignLogosToItemsReview.propTypes = {
  productsToAddLogo: PropTypes.array.isRequired,
  logos: PropTypes.array.isRequired,
};

export default AssignLogosToItemsReview;
