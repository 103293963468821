import * as actionTypes from './types';

const initialState = {
  logo: null,
  logoColoredStyleColors: [],
  logoDecorationMethods: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case actionTypes.FETCH_COLORED_STYLE_COLORS_FOR_LOGO:
      return {
        ...state,
        logoColoredStyleColors: action.payload,
      };
    case actionTypes.UPDATE_LOGO_DECORATION_METHODS:
      return {
        ...state,
        logoDecorationMethods: action.payload,
      };
    default:
      return state;
  }
}
