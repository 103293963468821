import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  text: string;
  downloadFile: (e: React.MouseEvent) => void;
  classes?: string;
}

type Props = OwnProps;

const DownloadFileLink = React.memo<Props>(({
  text,
  downloadFile,
  classes = '',
}) => (
  <div className={`align__center mr-20 ${classes}`}>
    <div className='storeLogo__link lockerManagerEdit__details-header--link'>
      <a
        className='store-link uppercase'
        onClick={downloadFile}
      >
        <Icon materialIcon={'get_app'} />
        {text}
      </a>
    </div>
  </div>
));

export default DownloadFileLink;
