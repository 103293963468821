import React, {
  useState,
  useEffect,
} from 'react';
import {
  useGetHomefieldApiProductionassemblynewOrdersheaderssearch,
  useGetHomefieldApiProductionassemblynewOrderitemssearch,
} from '@api/fulfillment/production-assembly-new';
import { navigateToPage } from '@util/componentHelper';
import OrderSearchInput from './OrderSearchInput';

interface OwnProps {
  reviewByOrderUrl: (orderNumber: number) => string;
  reviewByBarcodeUrl: (orderNumber: number, barcode: string) => string;
}

type Props = OwnProps;

const OrderSearch = React.memo<Props>(({
  reviewByOrderUrl,
  reviewByBarcodeUrl,
}) => {
  const [
    searchInput,
    setSearchInput,
  ] = useState<string>('');
  const [
    lookupOrderNumber,
    setLookupOrderNumber,
  ] = useState<string>('');

  const {
    data: order,
    refetch: fetchOrder,
  } = useGetHomefieldApiProductionassemblynewOrdersheaderssearch(
    { searchQuery: lookupOrderNumber },
    {
      request: { showErrorModal: true },
    }
  );

  const {
    data: orderItemGroups,
    refetch: fetchOrderItemGroups,
  } = useGetHomefieldApiProductionassemblynewOrderitemssearch(
    { searchQuery: lookupOrderNumber },
    {
      request: { showErrorModal: true },
    }
  );

  useEffect(() => {
    if (order && orderItemGroups) {
      const barcodes = orderItemGroups.map((i) => Object.values(i.orderItemIdsWithBarcode!)).flat();

      if (barcodes.includes(searchInput)) {
        navigateToPage(reviewByBarcodeUrl(order.orderNumber!, searchInput));
      } else {
        navigateToPage(reviewByOrderUrl(order.orderNumber!));
      }
    }
  }, [
    order,
    searchInput,
    orderItemGroups,
    reviewByBarcodeUrl,
    reviewByOrderUrl,
  ]);

  useEffect(() => {
    if (!lookupOrderNumber) return;

    fetchOrder();
    fetchOrderItemGroups();
  }, [
    lookupOrderNumber,
    fetchOrder,
    fetchOrderItemGroups,
  ]);

  return (
    <OrderSearchInput
      searchInput={searchInput}
      updateSearchInput={setSearchInput}
      updateLookupOrderNumber={setLookupOrderNumber}
    />
  );
});

export default OrderSearch;
