import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactImageMagnify from 'react-image-magnify';
import {
  regenerateImage,
  getDtgPrepressJob,
} from '@APICalls/dtg/actions';
import {
  transparentBackgoundOption,
  whiteBackgroundOption,
  imageBackgroundOptions,
} from '@constants/values';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import { artworkTaskStatusEnum } from '@constants/enums/artworkEnums';
import { dispatchAfterSubmit } from '@redux/artworkFileTasks/actions';
import { mapToSwatchColorFields } from '@util/mappingHelper';
import { getImageMagnifyDefaultOptions } from '@util/imageMagnify';
import { materialSwal } from '@util/componentHelper';
import Icon from '@sharedComponents/Icons/Icon';
import Button from '@sharedComponents/Buttons/Button';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import RegenerateDtgImageButton from '@sharedComponents/Buttons/RegenerateDtgImageButton';
import ArtworkTaskCut from './ArtworkTaskCut';
import ArtworkTaskStitchCount from './ArtworkTaskStitchCount';
import ImageBackgroundSwatch from '../ImageBackgroundSwatch';

const defaultBackground = imageBackgroundOptions[0];

class ArtworkTaskStorageImage extends Component {
  state = {
    imagePath: null,
    dimensions: null,
    isOpen: false,
    dtgPrepressJob: {},
    selectedBackground: defaultBackground,
  };

  setDefaultBackground = () => {
    this.setState(() => ({ selectedBackground: defaultBackground }));
  };

  updateBackground = (nextBackground, e) => {
    const { selectedBackground } = this.state;

    e.preventDefault();
    if (nextBackground !== selectedBackground) {
      this.setState(() => ({ selectedBackground: nextBackground }));
    }
  };

  updateDimension = (height, width, imagePath) => {
    this.setState(() => ({
      imagePath,
      dimensions: {
        height,
        width,
      },
      proportion: width / height || 1,
    }));
  };

  getImageDimensions = (imagePath) => {
    const generateImageEl = (filePath) => new Promise((resolve, reject) => {
      const img = new Image();

      img.src = filePath;
      img.onload = () => resolve(img);
      img.onerror = (event) => reject(event);
    });

    return generateImageEl(imagePath)
      .then((img) => ({
        imagePath,
        dimensions: {
          height: Number(img.naturalHeight / 300).toFixed(2),
          width: Number(img.naturalWidth / 300).toFixed(2),
        },
        proportion: img.naturalWidth / img.naturalHeight || 1,
      }))
      .catch((err) => {
        console.error(err);

        return ({
          imagePath: null,
          dimensions: null,
        });
      });
  };

  componentDidMount() {
    const {
      previewUrl,
      imageWidth,
      imageHeight,
    } = this.props;

    this.getDtgPrepressJob();

    if (!previewUrl) return;

    if (imageHeight && imageWidth) {
      this.updateDimension(imageHeight, imageWidth, previewUrl);
    } else {
      this.getImageDimensions(previewUrl).then((res) => this.setState(() => (res)));
    }
  }

  componentDidUpdate(oldProps) {
    const {
      previewUrl,
      imageHeight,
      imageWidth,
    } = this.props;

    const {
      imagePath,
      dimensions,
    } = this.state;

    if (previewUrl && previewUrl !== oldProps.previewUrl) {
      this.setDefaultBackground();
      if (imageHeight && imageWidth) {
        this.updateDimension(imageHeight, imageWidth, previewUrl);
      } else {
        this.getImageDimensions(previewUrl).then((res) => this.setState(() => (res)));
      }
    } else if (!previewUrl && (imagePath || dimensions)) {
      this.setState(() => ({
        imagePath: null,
        dimensions: null,
        selectedBackground: defaultBackground,
      }));
    }
  }

  getDtgPrepressJob = async () => {
    const { taskId } = this.props;

    const dtgPrepressJob = await getDtgPrepressJob({ artworkTaskId: taskId });

    this.setState({ dtgPrepressJob });
  };

  regenerateImage = async () => {
    const {
      taskId,
      dispatch,
      lockerId,
    } = this.props;

    const result = await regenerateImage({ artworkTaskId: taskId });

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      dispatch(dispatchAfterSubmit(lockerId, taskId));
      this.closeRegenerateImageModal();
    }
  };

  openRegenerateImageModal = () => {
    this.setState(() => ({
      isOpen: true,
    }));
  };

  closeRegenerateImageModal = () => {
    this.setState(() => ({
      isOpen: false,
    }));
  };

  render() {
    const {
      isCutLogo,
      cutColor,
      processing,
      failed,
      message,
      decorationMethod,
      swatchColors,
      taskType,
      status,
      taskId,
      lockerId,
      stitchCount,
      colors,
      previewUrl,
      disableCutFileRender,
      title,
      isBackgroundSelectable,
    } = this.props;

    const {
      imagePath,
      dimensions,
      proportion,
      isOpen,
      selectedBackground,
    } = this.state;

    if (imagePath) {
      const transparentStyle = decorationMethod === decorationMethodEnum.HAG
        || decorationMethod === decorationMethodEnum.DTG
        ? { background: transparentBackgoundOption.background }
        : { background: whiteBackgroundOption.background };

      const backgroundStyle = isBackgroundSelectable && selectedBackground?.background
        ? { background: selectedBackground.background }
        : transparentStyle;

      const ImageMagnifyDefaultOptions = getImageMagnifyDefaultOptions(imagePath, proportion);
      ImageMagnifyDefaultOptions.enlargedImageContainerStyle = {
        ...backgroundStyle,
        border: 0,
      };

      let cutInfo = <div />;

      if (isCutLogo && cutColor && colors) {
        const cutColorModel = colors.find((c) => c.code === cutColor);
        cutInfo = (
          <div className='color-swatches'>
            {mapToSwatchColorFields([cutColorModel])}
          </div>
        );
      }
      let swatchInfo = <div />;
      if (swatchColors && swatchColors.length && colors) {
        const swatchArray = typeof (swatchColors) === 'string' ? swatchColors.split(',') : swatchColors;

        const swatchColorsInfo = swatchArray.map((swatch) => {
          const color = colors.find((c) => c.code === swatch);

          return color ? color : { code: swatch };
        });

        swatchInfo = (
          <div className='color-swatches'>
            {mapToSwatchColorFields(swatchColorsInfo, decorationMethod, true, false)}
          </div>
        );
      }
      let artworkTaskCutFileRender = '';
      if (!disableCutFileRender
        && status !== artworkTaskStatusEnum.Unassigned
        && status !== artworkTaskStatusEnum.Completed) {
        artworkTaskCutFileRender = (
          <ArtworkTaskCut
            taskId={taskId}
            lockerId={lockerId}
            cut={isCutLogo}
            cutColor={cutColor}
          />
        );
      }

      let cutWarning = <div />;
      if (imagePath && taskType === decorationMethodEnum.HAG) {
        if (isCutLogo && swatchColors) {
          cutWarning = (
            <div className='warning-message'>
              <Icon materialIcon={'warning'} />
              <span>
                File is marked as cut but it contains swatch colors. Is it possible this is not a cut file?
              </span>
            </div>
          );
        }
        if (!isCutLogo && !swatchColors) {
          cutWarning = (
            <div className='warning-message'>
              <Icon materialIcon={'warning'} />
              <span>
                File doesn't contain swatch colors but it isn't marked as cut file. Is it possible this is a cut file?
              </span>
            </div>
          );
        }
      }

      const isDtgAndHasCorrectStatus = (decorationMethod === decorationMethodEnum.DTG
        && [
          artworkTaskStatusEnum.Assigned,
          artworkTaskStatusEnum.Rework,
          artworkTaskStatusEnum.Priority,
          artworkTaskStatusEnum.ProductionRework,
        ].includes(status));

      return (
        <div className='artwork-preview__section'>
          <div className='artwork-preview__title'>{title ?? 'Preview'}</div>
          <div className='artwork-preview__uploaded-image'>
            <div
              className='magnify-image-container'
              style={{
                ...backgroundStyle,
                width: '100%',
              }}
            >
              <ReactImageMagnify {...ImageMagnifyDefaultOptions} />
            </div>
          </div>
          { !!isBackgroundSelectable &&
            <div className='artwork-preview__options'>
              <ImageBackgroundSwatch
                updateBackground={this.updateBackground}
                selectedBackground={selectedBackground}
                imageBackgroundOptions={imageBackgroundOptions}
              />
              {decorationMethod === decorationMethodEnum.HAG && artworkTaskCutFileRender}
            </div>
          }
          {
            <div className='artwork-preview__options'>
              {
                decorationMethod === decorationMethodEnum.HAG &&
                <div className='flex flex-center justify__space-between w-100'>
                  <div className='artwork-preview__size'>
                    <div className='size-width'>
                      <span className='size__label'>Width</span>
                      <span className='size__value'>{dimensions.width} inches</span>
                    </div>
                    <div className='size-height'>
                      <span className='size__label'>Height</span>
                      <span className='size__value'>{dimensions.height} inches</span>
                    </div>
                  </div>
                  {/* If background is not selectable, place cut button here with the sizes */}
                  {!isBackgroundSelectable && artworkTaskCutFileRender}
                </div>
              }
              {
                isDtgAndHasCorrectStatus &&
                <div className='flex flex-center justify__end w-100'>
                  <SimpleConfirmationModal
                    isOpen={isOpen}
                    closeModal={this.closeRegenerateImageModal}
                    title={'Confirm Artwork Regeneration'}
                    confirm={this.regenerateImage}
                    confirmationBody={'This process can take up to 10 minutes please check back'}
                  />
                  <Button
                    type={'secondary'}
                    text={'re-generate artwork'}
                    onClick={this.openRegenerateImageModal}
                  />
                </div>
              }
              {
                stitchCount &&
                <ArtworkTaskStitchCount stitchCount={stitchCount} />
              }
            </div>
          }

          {cutInfo}
          {swatchInfo}
          {cutWarning}
        </div>
      );
    }

    const msgStyle = failed ? 'error' : 'success';
    const isDtgAndHasCorrectStatus = (decorationMethod === decorationMethodEnum.DTG
      && [
        artworkTaskStatusEnum.Assigned,
        artworkTaskStatusEnum.Rework,
        artworkTaskStatusEnum.Priority,
        artworkTaskStatusEnum.ProductionRework,
      ].includes(status));

    if (processing || failed) {
      return (
        <div className='artwork-preview__section'>
          <div className='artwork-preview__title'>{title ?? 'Preview'}</div>
          <div className='artwork-preview__uploaded-image'>
            <div className={`status-container ${msgStyle}`}>
              <Icon
                fontAwesomeIcon={failed ? 'times-circle' : 'spinner fa-spin'}
                classes={'fa-4x margin-bottom'}
              />
              <br />
              {message}
            </div>
          </div>
          <div className='artwork-preview__options'>
            {
              stitchCount &&
              <ArtworkTaskStitchCount stitchCount={stitchCount} />
            }
          </div>
        </div>
      );
    }

    return (
      <div className='artwork-preview__section'>
        <div className='artwork-preview__title'>{title ?? 'Preview'}</div>
        <div className='artwork-preview__uploaded-image'>
          <div className='status-container empty'>No artwork has been uploaded yet</div>
        </div>
        <div className='artwork-preview__options'>
          <div className='flex flex-center justify__end w-100'>
            {
              isDtgAndHasCorrectStatus &&
                <RegenerateDtgImageButton
                  taskId={taskId}
                  previewUrl={previewUrl}
                />
            }
          </div>
          {
            stitchCount &&
            <ArtworkTaskStitchCount stitchCount={stitchCount} />
          }
        </div>
      </div>
    );
  }
}

ArtworkTaskStorageImage.propTypes = {
  previewUrl: PropTypes.string,
  isCutLogo: PropTypes.bool,
  cutColor: PropTypes.string,
  processing: PropTypes.bool,
  failed: PropTypes.bool,
  message: PropTypes.string,
  decorationMethod: PropTypes.string,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  swatchColors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  taskType: PropTypes.string,
  status: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  lockerId: PropTypes.number.isRequired,
  stitchCount: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  disableCutFileRender: PropTypes.bool,
  title: PropTypes.string,
  isBackgroundSelectable: PropTypes.bool,
};

export default connect()(ArtworkTaskStorageImage);
