import React from 'react';
import { warehouseSchedulingUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const WarehouseSchedulingLink = React.memo(() => (
  <MenuTile
    title={'Scheduling 2.0'}
    url={warehouseSchedulingUrl}
    icon={'book'}
    isMaterialIcon={false}
  />
));

export default WarehouseSchedulingLink;
