import React from 'react';
import { onHandInventoryUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const OnHandInventoryLink = React.memo(() => (
  <MenuTile
    title={'On-Hand Inventory'}
    url={onHandInventoryUrl}
    icon={'list'}
    isMaterialIcon={false}
  />
));

export default OnHandInventoryLink;
