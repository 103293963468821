import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  multiSelectDropdownDelay,
  dropdownSizeM,
} from '@constants/values';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import MultiSelectDropdownItemPrimitive from './MultiSelectDropdownItemPrimitive';

class MultiSelectSearchDropdown extends Component {
  state = {
    selectedItems: this.props.selectedItems || [],
    throttledCall: false,
    filteredList: this.props.items,
    searchInput: '',
    dropIsActive: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      items,
      displayKey,
    } = this.props;

    const { searchInput } = this.state;

    if (searchInput !== prevState.searchInput) {
      if (searchInput.length > 0) {
        const filteredItems = items.filter((x) => (displayKey === '' ? x : x[displayKey || 'name']).toLowerCase().includes(searchInput.toLowerCase()));
        this.setState({
          filteredList: filteredItems,
        });
      } else {
        this.setState({
          filteredList: items,
        });
      }
    }

    if (prevProps.items !== items) {
      this.searchInput.value = '';
      this.setState({
        filteredList: items,
        searchInput: '',
      });
    }
  }

  refresh = () => {
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    const newThrottle = setTimeout(this.callUpdate, multiSelectDropdownDelay);

    this.setState({
      throttledCall: newThrottle,
    });
  };

  callUpdate = () => {
    const {
      throttledCall, selectedItems,
    } = this.state;
    const { updateCallback } = this.props;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    updateCallback(selectedItems);
  };

  itemOnClick = (item, isSelected) => {
    if (isSelected) {
      const { selectedItems } = this.state;

      selectedItems.splice(selectedItems.indexOf(item), 1);
      this.setState({ selectedItems }, this.refresh);
    } else {
      const { selectedItems } = this.state;

      selectedItems.push(item);
      this.setState({ selectedItems }, this.refresh);
    }
  };

  onInputChange = () => {
    const newSearchInput = this.searchInput.value ? this.searchInput.value.trim() : '';
    this.setState({
      searchInput: newSearchInput,
    });
  };

  clearFilter = () => {
    const { updateCallback } = this.props;
    const { throttledCall } = this.state;

    if (throttledCall) {
      clearTimeout(throttledCall);
    }

    this.searchInput.value = '';
    this.setState({
      selectedItems: [],
      throttledCall: null,
      searchInput: '',
    });

    updateCallback([]);
  };

  isSelected = (item) => {
    const { selectedItems } = this.state;
    const { valueKey } = this.props;

    return selectedItems.includes(valueKey === '' ? item : item[valueKey || 'value']);
  };

  dropChange = () => {
    const { dropIsActive } = this.state;
    if (dropIsActive) {
      this.searchInput.value = '';
      this.setState({
        searchInput: '',
      });
    }
    this.setState({
      dropIsActive: !dropIsActive,
    });
  };

  closeDrop = () => {
    this.searchInput.value = '';
    this.setState({
      dropIsActive: false,
      searchInput: '',
    });
  };

  render() {
    const {
      placeholderText,
      displayKey,
      valueKey,
      color,
      size,
      classNames,
      itemText,
      selectedItems,
      items,
    } = this.props;

    const {
      filteredList,
      dropIsActive,
    } = this.state;

    const selectText = selectedItems && selectedItems.length > 0
      ? `${itemText} (${selectedItems.length}/${items.length})`
      : `All ${itemText} (${items.length})`;

    return (
      <div>
        <div className={`text-field mb-0 ${classNames}`}>
          <OutsideClickWrapper onClick={this.closeDrop}>
            <div
              style={{ minWidth: size || dropdownSizeM }}
              className={`custom-dropdown ${classNames}`}
            >
              <div
                style={{ minWidth: size || dropdownSizeM }}
                onClick={this.dropChange}
                className='custom-dropdown__select'
              >
                {selectText}
                <div className='custom-dropdown__arrow' />
              </div>
              <div
                style={{
                  minWidth: size || dropdownSizeM,
                  top: 55,
                }}
                className={`custom-dropdown__options ${dropIsActive ? 'is-active' : ''}`}
              >
                <div className='custom-search-dropdown__options'>
                  <input
                    type='text'
                    placeholder={placeholderText || 'Search'}
                    onChange={this.onInputChange}
                    ref={(r) => this.searchInput = r}
                    className='w-100'
                  />
                  <div className='custom-dropdown__options--actions'>
                    <a
                      className='button clear'
                      onClick={this.clearFilter}
                    >
                      Clear
                    </a>
                  </div>
                </div>
                <ul className='custom-dropdown__options--list custom-scrollbar'>
                  {
                    filteredList.map((item, index) => (
                      <MultiSelectDropdownItemPrimitive
                        key={index}
                        value={valueKey === '' ? item : item[valueKey || 'value']}
                        text={displayKey === '' ? item : item[displayKey || 'name']}
                        isSelected={this.isSelected(item)}
                        onClick={this.itemOnClick}
                        color={color && item}
                      />
                    ))
                  }
                </ul>
              </div>
            </div>
          </OutsideClickWrapper>
        </div>
      </div>
    );
  }
}

MultiSelectSearchDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array,
  search: PropTypes.any,
  updateCallback: PropTypes.func.isRequired,
  displayKey: PropTypes.string,
  valueKey: PropTypes.string,
  placeholderText: PropTypes.string,
  color: PropTypes.bool,
  size: PropTypes.number,
  classNames: PropTypes.string,
  itemText: PropTypes.string,
};

export default MultiSelectSearchDropdown;
