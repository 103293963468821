import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import DtgStationsForm from '../DtgStationsForm';
import { dtgStationsForm } from '@constants/reduxForms';

const DtgStationsEditModal = React.memo(({
  isOpen,
  initialValues,
  closeModal,
  onSubmit,
}) => (
  <Modal
    title={'Edit DTG Stations'}
    isOpen={isOpen}
    modalSize={'l'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Update'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={dtgStationsForm}
      />
    )}
  >
    <DtgStationsForm
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  </Modal>
));

DtgStationsEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default DtgStationsEditModal;
