import * as actionTypes from './types';

export const addMessage = (message) => ({
  type: actionTypes.ADD_MESSAGE,
  message,
});

export const updateMessages = (messages) => ({
  type: actionTypes.UPDATE_MESSAGES,
  messages,
});
