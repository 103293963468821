import React from 'react';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';
import { getExpressProductionTypeOptions } from '@constants/options/options';

const expressProductionTypeOptions = getExpressProductionTypeOptions();

interface OwnProps {
  filter: (value: boolean | null) => void;
  defaultValue: Nullable<boolean>;
}

type Props = OwnProps;

const ExpressProductionFilter = React.memo<Props>(({
  filter,
  defaultValue,
}) => (
  <div className='filter-group'>
    <Dropdown<boolean | null>
      options={expressProductionTypeOptions}
      onChange={filter}
      defaultValue={defaultValue}
    />
  </div>
));

export default ExpressProductionFilter;
