import React from 'react';
import PropTypes from 'prop-types';
import PrintSheetFileDownloadItem from './PrintSheetFileDownloadItem';

const PrintSheetFileDownloadCard = ({
  jobs, title,
}) => {
  if (!jobs || jobs.length === 0) {
    return <div />;
  }

  const items = jobs.map((job, key) => (
    <PrintSheetFileDownloadItem
      key={key}
      job={job}
      keyId={key}
    />
  ));

  return (
    <div
      // Ignores this element for printed page
      data-html2canvas-ignore={true}
      className='sheet__files-item item-file-card'
    >
      <div className='sheet__files-title'>
        <div className='sheet__files-title-left flex'>
          {title}
        </div>
      </div>
      <ul className='sheet__files-list'>
        {items}
      </ul>
    </div>
  );
};

PrintSheetFileDownloadCard.propTypes = {
  jobs: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default PrintSheetFileDownloadCard;
