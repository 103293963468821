import { buildQueryString } from '@util/stringHelpers';

export const logoReviewer = 'homefield/api/logo-reviewer' as const;

export const tasks = `${logoReviewer}/tasks` as const;

export const tasksTable = (
  pageNumber: number,
  pageSize: number,
  sortColumn: string,
  sortDirection: string,
  inReview: boolean,
  reviewer: string,
  partner: string,
  searchInput: string
): string => `${tasks}${buildQueryString({
  pageNumber,
  pageSize,
  sortColumn,
  sortDirection,
  inReview,
  reviewer,
  partner,
  searchInput,
})}`;

export const taskFilters = `${tasks}/filters` as const;
export const taskCounts = `${tasks}/logo-counts` as const;
export const sendToReview = `${tasks}.send-to-review` as const;
export const cancelLogoReviews = `${tasks}.cancel-review` as const;
export const cancelReviewForLogo = (logoId: number): string => `${tasks}/${logoId}.cancel-review`;

export const reviewerTasks = `${logoReviewer}/reviewer-tasks` as const;
export const submitReviewedTasks = `${reviewerTasks}.submit` as const;

export default {
  tasks,
  tasksTable,
  taskCounts,
  taskFilters,
  sendToReview,
  cancelLogoReviews,
  cancelReviewForLogo,
  reviewerTasks,
  submitReviewedTasks,
};
