import React, {
  useState,
  useCallback,
} from 'react';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import Icon from '@sharedComponents/Icons/Icon';
import DropdownAction, { DropdownActionModel } from './DropdownAction';

interface OwnProps {
  text: string;
  disabled: boolean;
  classes: string;
  dangerous?: boolean;
  actions: DropdownActionModel[];
}

type Props = OwnProps;

const ActionButton = React.memo<Props>(({
  text,
  disabled,
  dangerous = false,
  classes,
  actions,
}: Props) => {
  const [
    actionsDropdownIsActive,
    setActionsDropdownIsActive,
  ] = useState<boolean>(false);

  const toggleActionsDropdown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setActionsDropdownIsActive(!actionsDropdownIsActive);
  }, [actionsDropdownIsActive]);

  const collapseActionsDropdown = useCallback(() => {
    setActionsDropdownIsActive(false);
  }, []);

  const handleClickOnAction = useCallback((
    e: React.MouseEvent,
    action: ((e: React.MouseEvent) => void) | undefined,
    linkTo: Nullable<string> | undefined
  ) => {
    if (!linkTo) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (action) {
      action(e);
    }

    collapseActionsDropdown();
  }, [collapseActionsDropdown]);

  const mapToAction = (a: DropdownActionModel, index: number) => (
    <DropdownAction
      key={index}
      item={a}
      onClick={handleClickOnAction}
    />
  );

  const allActionsAreHidden = actions.every((a) => a.isVisible === false);

  return (
    <OutsideClickWrapper onClick={collapseActionsDropdown}>
      <div className='lockerManager__action-button'>
        <button
          type='button'
          className={`btn-primary ${dangerous ? 'btn-primary--red' : 'btn-primary--green'} has-icon ${classes}`}
          onClick={toggleActionsDropdown}
          disabled={disabled || allActionsAreHidden}
        >
          <span>{text}</span>
          <Icon materialIcon={'arrow_drop_down'} />
        </button>
        {
          actionsDropdownIsActive &&
          <ul className='lockerManager__item-list is-active right-2'>
            {
              actions.map(mapToAction)
            }
          </ul>
        }
      </div>
    </OutsideClickWrapper>
  );
});

export default ActionButton;
