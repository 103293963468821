import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { QueryObserverResult } from 'react-query';
import { reset } from 'redux-form';
import { Column } from 'react-table-6';
import {
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsflag,
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsunflag,
} from '@api/fulfillment/production-assembly-new';
import {
  ColorDto,
  DecorationMethodEnum,
} from '@api/productCatalog/models';
import {
  ProductionAssemblyItemGroupDto,
  DecorationFlagTypeEnum,
  ProductionAssemblyOrderHeaderDto,
  DecorationStatusEnum,
} from '@api/fulfillment/models';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import {
  productionAssemblyUnFlagItemForm,
  productionAssemblyFlagItemForm,
} from '@constants/reduxForms';
import {
  Task,
  LogoTask,
  PersonalizationTask,
} from '@models/Production/OrderAssembly/Tasks';
import { FlaggingFormData } from '@models/forms/Production/FlaggingFormData';
import { materialSwal } from '@util/componentHelper';
import { getFlagReasons } from '@util/flagHelper';
import {
  getHashCodeForLogoTask,
  getHashCodeForPersonalizationTask,
} from '@util/mappingHelper';
import FlagCell from '@components/WeedingAndMasking/Cells/FlagCell';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import Table from '@sharedComponents/Table/Table';
import LogoOrTextCell from '@sharedComponents/Production/Cells/LogoOrTextCell';
import FlaggingModal from '@sharedComponents/Production/Modals/FlaggingModal';
import UnflagItemModal from '@sharedComponents/Production/Modals/UnflagItemModal';
import DecorationLocationAndSizeCell from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemsTasksContent/DecorationLocationAndSizeCell';
import ReviewItemBarcodesTooltip from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemBarcodesTooltip';
import Icon from '@sharedComponents/Icons/Icon';
import { reprintUnavailableMethodsArr } from '@constants/production';

const TasksTable = Table<Task>();

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  logoTasks: LogoTask[];
  personalizationTasks: PersonalizationTask[];
  orderNumber: number;
  checksDisabled?: boolean;
  itemStatus: keyof typeof orderItemStatusEnum;
  colorsDictionary: Record<string, ColorDto>;
  colors: ColorDto[] | undefined;
  itemBarcodes: {
    orderItemId: number;
    barcode?: string;
  }[];
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
}

const mapDispatchToProps = {
  resetForm: reset,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const ReviewItemTasks = React.memo(({
  logoTasks,
  personalizationTasks,
  orderNumber,
  colorsDictionary,
  colors,
  resetForm,
  refreshOrderItemGroups,
  item,
  checksDisabled,
  itemStatus,
}: Props) => {
  const [
    logoImagePreviewIsOpen,
    setLogoImagePreviewIsOpen,
  ] = useState(false);
  const [
    logoImagePreviewImageUrl,
    setLogoImagePreviewImageUrl,
  ] = useState<Nullable<string>>(null);
  const [
    flaggingModalIsOpen,
    setFlaggingModalIsOpen,
  ] = useState(false);
  const [
    unflaggingModalIsOpen,
    setUnflaggingModalIsOpen,
  ] = useState(false);
  const [
    selectedTask,
    setSelectedTask,
  ] = useState<Nullable<Task>>(null);

  const { mutateAsync: flagOrderLineItem }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsflag();

  const { mutateAsync: unflagOrderLineItem }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsunflag();

  const openLogoPreviewModal = useCallback((imageUrl: string) => {
    setLogoImagePreviewIsOpen(true);
    setLogoImagePreviewImageUrl(imageUrl);
  }, []);

  const closeLogoPreviewModal = useCallback(() => {
    setLogoImagePreviewIsOpen(false);
    setLogoImagePreviewImageUrl(null);
  }, []);

  const openFlaggingModal = useCallback((task: Task) => {
    setFlaggingModalIsOpen(true);
    setSelectedTask(task);
  }, []);

  const openUnflaggingModal = useCallback((task: Task) => {
    setUnflaggingModalIsOpen(true);
    setSelectedTask(task);
  }, []);

  const closeFlaggingModal = useCallback(() => {
    resetForm(productionAssemblyFlagItemForm);

    setFlaggingModalIsOpen(false);
    setSelectedTask(null);
  }, [resetForm]);

  const closeUnflaggingModal = useCallback(() => {
    resetForm(productionAssemblyUnFlagItemForm);

    setUnflaggingModalIsOpen(false);
    setSelectedTask(null);
  }, [resetForm]);

  const getGroupedItemColumns = (): Array<Column<Task>> => [
    {
      expander: true,
      Header: '',
      width: 50,
      Expander: (cellProps) => {
        const task: Task = cellProps.original;

        if (task.ids.length <= 1) return null;

        return (
          <div>
            {
              cellProps.isExpanded
                ? (
                  <Icon
                    materialIcon={'arrow_drop_up'}
                    classes={'button'}
                  />
                )
                : (
                  <Icon
                    materialIcon={'arrow_drop_down'}
                    classes={'button'}
                  />
                )
            }
          </div>
        );
      },
    },
    {
      Header: 'Item',
      accessor: '',
      width: 400,
      Cell: (cellProps) => {
        const task: Task = cellProps.value;

        return (
          <span>
            <LogoOrTextCell
              task={task}
              openModal={openLogoPreviewModal}
              showThreadColorValue={item.decorationMethod === DecorationMethodEnum.EMB}
              colorsDictionary={colorsDictionary}
              colors={colors}
              decorationMethod={item.decorationMethod as DecorationMethodEnum}
              regenerateButtonVisible={false}
            />
          </span>
        );
      },
    },
    {
      Header: 'Location & Size',
      accessor: '',
      minWidth: 450,
      Cell: (cellProps) => {
        const task: Task = cellProps.value;

        return (
          <div className='align__center'>
            {
              item.decorationMethod === DecorationMethodEnum.HAG &&
              <div className='order-assembly__item__tasks__info-icon mr-10'>
                <ReviewItemBarcodesTooltip task={task} />
              </div>
            }
            <DecorationLocationAndSizeCell task={task} />
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: '',
      width: 100,
      Cell: (cellProps) => {
        const task: Task = cellProps.value;

        return (
          <div className='flex justify__end align__center ml-15'>
            {
              (task.ids?.length === 1
              && !(reprintUnavailableMethodsArr.includes(
                item.decorationMethod as (typeof reprintUnavailableMethodsArr[number])
              )
              && ((task as PersonalizationTask).text || (task as PersonalizationTask).number))) &&
              <FlagCell
                item={task}
                openFlaggingModal={openFlaggingModal}
                openUnflaggingModal={openUnflaggingModal}
                flagInfo={task.flag}
                disabled={checksDisabled || itemStatus === orderItemStatusEnum.QualityChecked}
              />
            }
          </div>
        );
      },
    },
  ];

  const getArtworkItemColumns = useCallback((): Array<Column<Task>> => (
    [
      {
        Header: 'Item',
        accessor: '',
        width: 400,
        Cell: (cellProps) => {
          const task: Task = cellProps.value;

          return (
            <div>
              {
                item.decorationMethod === DecorationMethodEnum.HAG &&
                <div className='order-assembly__item__tasks__info-icon mr-10'>
                  <ReviewItemBarcodesTooltip task={task} />
                </div>
              }
              <LogoOrTextCell
                task={task}
                openModal={openLogoPreviewModal}
                showThreadColorValue={item.decorationMethod === DecorationMethodEnum.EMB}
                colorsDictionary={colorsDictionary}
                colors={colors}
                decorationMethod={item.decorationMethod as DecorationMethodEnum}
                regenerateButtonVisible={true}
              />
            </div>
          );
        },
      },
      {
        Header: 'Location & Size',
        accessor: 'decorationLocation',
        width: 450,
      },
      {
        Header: '',
        accessor: '',
        width: 100,
        Cell: (cellProps) => {
          const task: Task = cellProps.value;

          return (
            <div className='align__center justify__end'>
              <div className='ml-15'>
                {
                  !(
                    reprintUnavailableMethodsArr.includes(
                      item.decorationMethod as typeof reprintUnavailableMethodsArr[number]
                    )
                    && ((task as PersonalizationTask).text || (task as PersonalizationTask).number)
                  ) &&
                  <FlagCell
                    item={task}
                    openFlaggingModal={openFlaggingModal}
                    openUnflaggingModal={openUnflaggingModal}
                    flagInfo={task.flag}
                    disabled={checksDisabled || itemStatus === orderItemStatusEnum.QualityChecked}
                  />
                }
              </div>
            </div>
          );
        },
      },
    ]
  ), [
    checksDisabled,
    item,
    itemStatus,
    openFlaggingModal,
    openUnflaggingModal,
    openLogoPreviewModal,
    colorsDictionary,
    colors,
  ]);

  const getTrProps = useCallback((state: any, rowInfo: any) => {
    if (!rowInfo) {
      return {};
    }

    const { status } = (rowInfo.original as Task);

    return {
      className: `${status === orderItemStatusEnum.Decorated ? 'is-active--light' : ''}`,
    };
  }, []);

  const getArtworkItemsTrProps = useCallback((state: any, rowInfo: any) => {
    if (!rowInfo) {
      return {};
    }
    const { status } = (rowInfo.original as Task);

    return {
      className: `${status === orderItemStatusEnum.Decorated ? 'is-active--light' : ''}`,
    };
  }, []);

  const flagItem = useCallback(async (form: FlaggingFormData) => {
    const {
      reason,
      note,
      flagType,
    } = form;

    try {
      const res = await flagOrderLineItem({
        data: {
          orderNumber,
          reason,
          note,
          flagType,
          ids: (selectedTask!.ids.length > 0 ? selectedTask!.ids : [selectedTask!.id!]),
        },
      });

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        refreshOrderItemGroups();
        closeFlaggingModal();
      }
    } catch (err) {}
  }, [
    closeFlaggingModal,
    refreshOrderItemGroups,
    selectedTask,
    orderNumber,
    flagOrderLineItem,
  ]);

  const unFlagItem = useCallback(async () => {
    try {
      const res = await unflagOrderLineItem({
        data: {
          orderNumber,
          ids: (selectedTask!.ids.length > 0 ? selectedTask!.ids : [selectedTask!.id!]),
        },
      });

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        refreshOrderItemGroups();
        closeUnflaggingModal();
      }
    } catch (err) {}
  }, [
    closeUnflaggingModal,
    refreshOrderItemGroups,
    orderNumber,
    selectedTask,
    unflagOrderLineItem,
  ]);

  const flagType = selectedTask
    && reprintUnavailableMethodsArr.includes(item.decorationMethod as typeof reprintUnavailableMethodsArr[number])
    ? DecorationFlagTypeEnum.Rework
    : DecorationFlagTypeEnum.Reprint;

  const reason = (selectedTask && getFlagReasons(flagType, item.decorationMethod)[0].reason) ?? undefined;

  const [
    groupedTasks,
    groupedLogosByHash,
    groupedPersonalizationsByHash,
  ] = useMemo((
  ): [Task[], Record<string, LogoTask[]>, Record<string, PersonalizationTask[]>] => {
    const groupedLogos: Record<string, LogoTask[]> = {};
    const groupedPersonalizations: Record<string, PersonalizationTask[]> = {};

    const groupedTasksList: Task[] = [];

    for (const logo of logoTasks) {
      const hash = getHashCodeForLogoTask(logo);

      if (!groupedLogos[hash]) {
        groupedLogos[hash] = [];
      }

      groupedLogos[hash].push(logo);
    }

    for (const personalization of personalizationTasks) {
      const hash = getHashCodeForPersonalizationTask(personalization);

      if (!groupedPersonalizations[hash]) {
        groupedPersonalizations[hash] = [];
      }

      groupedPersonalizations[hash].push(personalization);
    }

    for (const hash of Object.keys(groupedLogos)) {
      const logos = groupedLogos[hash];
      const groupedLogo = { ...logos[0] };
      groupedLogo.ids = logos.map((logo) => logo.id!);
      groupedLogo.barcodes = logos.map((logo) => logo.barcode!);

      const notCheckedInStatus = logos.find(
        (logo) => logo.status !== DecorationStatusEnum.CheckedIn
      )?.status as (DecorationStatusEnum);
      groupedLogo.groupStatus = notCheckedInStatus || DecorationStatusEnum.CheckedIn;

      groupedTasksList.push(groupedLogo);
    }

    for (const hash of Object.keys(groupedPersonalizations)) {
      const personalizations = groupedPersonalizations[hash];
      const groupedPersonalization = { ...personalizations[0] };
      groupedPersonalization.ids = personalizations.map((personalization) => personalization.id!);
      groupedPersonalization.barcodes = personalizations.map((personalization) => personalization.barcode!);

      const notCheckedInStatus = personalizations.find(
        (personalization) => personalization.status !== DecorationStatusEnum.CheckedIn
      )?.status as (DecorationStatusEnum);
      groupedPersonalization.groupStatus = notCheckedInStatus || DecorationStatusEnum.CheckedIn;

      groupedTasksList.push(groupedPersonalization);
    }

    return [
      groupedTasksList,
      groupedLogos,
      groupedPersonalizations,
    ];
  }, [
    logoTasks,
    personalizationTasks,
  ]);

  const getSubComponent = useCallback((cell: any) => {
    const task: Task = cell.original;

    const isLogo: boolean = !!(task as LogoTask).logoId;
    const hash = isLogo ? getHashCodeForLogoTask(task) : getHashCodeForPersonalizationTask(task);
    const tasks = isLogo ? groupedLogosByHash[hash] : groupedPersonalizationsByHash[hash];

    return (
      <TasksTable
        columns={getArtworkItemColumns()}
        classNames={''}
        data={tasks}
        showPagination={false}
        getTrProps={getArtworkItemsTrProps}
        customProps={{ TheadComponent: () => null }}
      />
    );
  }, [
    getArtworkItemColumns,
    getArtworkItemsTrProps,
    groupedLogosByHash,
    groupedPersonalizationsByHash,
  ]);

  return (
    <>
      <ImagePreviewModal
        modalIsOpen={logoImagePreviewIsOpen}
        closeModal={closeLogoPreviewModal}
        imageUrl={logoImagePreviewImageUrl ?? undefined}
      />
      <FlaggingModal
        isOpen={flaggingModalIsOpen}
        closeModal={closeFlaggingModal}
        onSubmit={flagItem}
        task={selectedTask}
        orderNumber={orderNumber}
        colorsDictionary={colorsDictionary}
        decorationMethod={item.decorationMethod as DecorationMethodEnum}
        initialValues={{
          flagType,
          reason,
        }}
      />
      <UnflagItemModal
        isOpen={unflaggingModalIsOpen}
        closeModal={closeUnflaggingModal}
        onSubmit={unFlagItem}
        task={selectedTask}
        orderNumber={orderNumber}
        colorsDictionary={colorsDictionary}
        initialValues={{
          flagType: selectedTask?.flag?.type,
          reason: selectedTask?.flag?.reason ?? undefined,
          note: selectedTask?.flag?.note ?? undefined,
        }}
      />
      <TasksTable
        columns={getGroupedItemColumns()}
        data={groupedTasks}
        getTrProps={getTrProps}
        showPagination={false}
        classNames={''}
        subComponent={getSubComponent}
        customProps={{ TheadComponent: () => null }}
      />
    </>
  );
});

export default connector(ReviewItemTasks);
