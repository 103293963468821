import { TableEnum } from '@constants/enums/tableEnums';
import * as actionTypes from './types';
import { TableState } from './models';

type UpdateTableStateAction = {
  type: typeof actionTypes.UPDATE_TABLE_STATE;
  payload: {
    tableName: TableEnum;
    tableState: TableState;
  };
};

export const updateTableState = (tableName: TableEnum, tableState: TableState): UpdateTableStateAction => ({
  type: actionTypes.UPDATE_TABLE_STATE,
  payload: {
    tableName,
    tableState,
  },
});

type ResetTableStateAction = {
  type: typeof actionTypes.RESET_TABLE_STATE;
  payload: TableEnum;
};

export const resetTableState = (tableName: TableEnum): ResetTableStateAction => ({
  type: actionTypes.RESET_TABLE_STATE,
  payload: tableName,
});

type UpdateTableFiltersAction = {
  type: typeof actionTypes.UPDATE_TABLE_FILTERS;
  payload: {
    tableName: TableEnum;
    filters: Partial<TableState>;
  };
};

export const updateTableFilters = (tableName: TableEnum, filters: Partial<TableState>): UpdateTableFiltersAction => ({
  type: actionTypes.UPDATE_TABLE_FILTERS,
  payload: {
    tableName,
    filters,
  },
});

export type TableManagerAction = UpdateTableStateAction | ResetTableStateAction | UpdateTableFiltersAction;
