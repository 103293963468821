import React from 'react';
import PropTypes from 'prop-types';
import VendorFulfilledInformationSection from './CustomizeItemPropertiesTabContent/VendorFulfilledInformationSection';
import CustomItemDetailsSection from './CustomizeItemPropertiesTabContent/CustomItemDetailsSection';
import CustomItemImagesSection from './CustomizeItemPropertiesTabContent/CustomItemImagesSection';

const CustomizeItemPropertiesTab = React.memo(({
  isCustomItem,
  imageFront,
  imageBack,
  imageLeft,
  imageRight,
  styleName,
  discount,
  isVFI,
  vendor,
  change,
  updateTimeline,
  vendorTimelines,
  validateVendorDesignId,
  isVendorDesignIdValid,
  handleVendorDesignIdValidationChanges,
  unhidingItemDisabled,
  updateAttachedImage,
  imageFrontAttachmentUrl,
  imageBackAttachmentUrl,
  imageLeftAttachmentUrl,
  imageRightAttachmentUrl,
  disabled = {},
}) => (
  <div>
    <CustomItemImagesSection
      isCustomItem={isCustomItem}
      imageFront={imageFront}
      imageBack={imageBack}
      imageLeft={imageLeft}
      imageRight={imageRight}
      change={change}
      updateAttachedImage={updateAttachedImage}
      imageFrontAttachmentUrl={imageFrontAttachmentUrl}
      imageBackAttachmentUrl={imageBackAttachmentUrl}
      imageLeftAttachmentUrl={imageLeftAttachmentUrl}
      imageRightAttachmentUrl={imageRightAttachmentUrl}
    />
    <div className='lockerManagerEdit__custom-items--details'>
      <CustomItemDetailsSection
        isCustomItem={isCustomItem}
        styleName={styleName}
        discount={discount}
        change={change}
        unhidingItemDisabled={unhidingItemDisabled}
        disabled={disabled}
      />
      {
        isVFI &&
        <>
          <VendorFulfilledInformationSection
            vendor={vendor}
            vendorTimelines={vendorTimelines}
            updateTimeline={updateTimeline}
            handleVendorDesignIdValidationChanges={handleVendorDesignIdValidationChanges}
            validateVendorDesignId={validateVendorDesignId}
            isVendorDesignIdValid={isVendorDesignIdValid}
            change={change}
          />
        </>
      }
    </div>
  </div>
));

CustomizeItemPropertiesTab.propTypes = {
  imageFront: PropTypes.string,
  imageBack: PropTypes.string,
  imageRight: PropTypes.string,
  imageLeft: PropTypes.string,
  isVFI: PropTypes.bool.isRequired,
  vendor: PropTypes.shape({
    vfiMaxPersonalizationNameLength: PropTypes.number,
  }),
  change: PropTypes.func.isRequired,
  styleName: PropTypes.string,
  discount: PropTypes.number,
  updatePersonalizationType: PropTypes.func.isRequired,
  updateTimeline: PropTypes.func.isRequired,
  vendorTimelines: PropTypes.arrayOf(PropTypes.shape({
    vendorId: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    timelineDays: PropTypes.number.isRequired,
  })),
  validateVendorDesignId: PropTypes.func.isRequired,
  isVendorDesignIdValid: PropTypes.bool.isRequired,
  handleVendorDesignIdValidationChanges: PropTypes.func.isRequired,
  unhidingItemDisabled: PropTypes.bool.isRequired,
};

export default CustomizeItemPropertiesTab;
