import * as actionTypes from './types';
import fundraisingPayoutsUrls from '@constants/sqdApiUrls/fundraisingPayoutsUrls';
import sqdLockerUrls from '@constants/sqdApiUrls/lockerUrls';
import { lockerMgrS3Logos } from '@constants/common';
import {
  financialApi,
  slServicesApi,
} from '../../sqdApis';
import { makeApiCall } from '@util/apiHelper';
import { fundraisingPayoutTypeEnum } from '@constants/enums/fundraisingPayoutsEnums';

export const updatePayoutsTable = (data) => ({
  type: actionTypes.UPDATE_PAYOUTS_TABLE,
  data,
});

export const updatePayout = (data) => ({
  type: actionTypes.UPDATE_PAYOUT,
  data,
});

export const updateLockersForPayout = (data) => ({
  type: actionTypes.UPDATE_LOCKERS_FOR_PAYOUT,
  data,
});

export const updateOrderItemsStatus = (data) => ({
  type: actionTypes.UPDATE_ORDER_ITEMS_STATUS,
  data,
});

export const updateAccoutingSubmitResults = (data) => ({
  type: actionTypes.UPDATE_ACCOUNTING_SUBMIT_RESULTS,
  data,
});

export const fetchPayoutsTable = (
  payoutType = fundraisingPayoutTypeEnum.Request,
  pageNumber = 1,
  pageSize = 10,
  status = '',
  sortBy = '',
  sortDirection = '',
  search = '',
  accountingSubmitStatus = ''
) => async (dispatch) => {
  const call = financialApi.get(fundraisingPayoutsUrls.payoutsTable(
    payoutType,
    pageNumber,
    pageSize,
    status,
    sortBy,
    sortDirection,
    search,
    accountingSubmitStatus
  ), { handleBlockingLoading: false });
  const data = await makeApiCall(call);

  if (data) {
    dispatch(updatePayoutsTable({
      totalPages: data.totalPages,
      hasPreviousPage: data.hasPreviousPage,
      hasNextPage: data.hasNextPage,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
      queue: data.items,
      totalCount: data.totalCount,
      totalProcessedPayouts: data.totalProcessedPayouts,
      totalRequestedPayouts: data.totalRequestedPayouts,
    }));
  }

  return data;
};

export const getPayoutById = (payoutId) => async (dispatch) => {
  const call = financialApi.get(fundraisingPayoutsUrls.payout(payoutId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updatePayout(res));
  }
};

export const getLockersForPayout = (payoutId) => async (dispatch) => {
  const call = financialApi.get(fundraisingPayoutsUrls.lockersForPayout(payoutId), { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    const lockers = [];

    for (const locker of res) {
      const lockerCall = slServicesApi.get(sqdLockerUrls.lockerDetails(locker.id), { handleBlockingLoading: false });
      const lockerRes = await makeApiCall(lockerCall);

      if (lockerRes) {
        lockers.push({
          ...locker,
          organizationName: lockerRes.organization_name,
          logoImage: `${lockerMgrS3Logos}/${lockerRes.image}`,
        });
      }
    }

    dispatch(updateLockersForPayout(lockers));
  }
};

export const getOrderItemsStatus = (lockerId) => async (dispatch) => {
  const call = financialApi.get(
    fundraisingPayoutsUrls.orderItemsStatus(
      lockerId
    ), { handleBlockingLoading: false }
  );
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateOrderItemsStatus(res));
  } else {
    // Clear data if failed
    dispatch(updateOrderItemsStatus([]));
  }
};

export const getAccountingSubmitResults = (payoutId) => async (dispatch) => {
  const call = financialApi.get(fundraisingPayoutsUrls.accountingSubmitResults(payoutId),
    { handleBlockingLoading: false });
  const res = await makeApiCall(call);

  if (res) {
    dispatch(updateAccoutingSubmitResults(res));
  }
};
