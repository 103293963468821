import React from 'react';
import PropTypes from 'prop-types';
import productCatalogIcon from '@assets/icon_product-catalog.svg';
import customItemIcon from '@assets/icon_custom-item.svg';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import ActionButton from '@sharedComponents/Buttons/ActionButton';
import PermissionHandler from '@sharedComponents/Authorization/PermissionHandler';
import { lockerManagerAddProductsPageUrl } from '@constants/clientUrls/clientUrls';

const LockerItemsActionButton = React.memo(({
  lockerId,
  openAddCustomItemModal,
  openImportItemModal,
}) => (
  <PermissionHandler
    permissions={PermissionsEnum.LockerManagerEditCustomItems}
    fallback={(
      <ActionButton
        text={'Add Products'}
        actions={[
          {
            text: 'Product Catalog',
            icon: productCatalogIcon,
            isCustomIcon: true,
            iconWidth: '16',
            iconHeight: '14',
            linkTo: lockerManagerAddProductsPageUrl(lockerId),
          },
        ]}
      />
    )}
  >
    <ActionButton
      text={'Add Products'}
      actions={[
        {
          text: 'Product Catalog',
          icon: productCatalogIcon,
          isCustomIcon: true,
          iconWidth: 16,
          iconHeight: 14,
          linkTo: lockerManagerAddProductsPageUrl(lockerId),
        },
        {
          text: 'New Custom Item',
          icon: customItemIcon,
          isCustomIcon: true,
          iconWidth: 17,
          iconHeight: 16,
          action: openAddCustomItemModal,
        },
        {
          text: 'Import Item',
          icon: 'get_app',
          action: openImportItemModal,
        },
      ]}
    />
  </PermissionHandler>
));

LockerItemsActionButton.propTypes = {
  lockerId: PropTypes.number.isRequired,
  openAddCustomItemModal: PropTypes.func.isRequired,
  openImportItemModal: PropTypes.func.isRequired,
};

export default LockerItemsActionButton;
