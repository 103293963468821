import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { discountTypes } from '@constants/common';
import { fetchCouponDetailsForOrder } from '@redux/orderManagement/actions';
import {
  parseDateTimeNumeric,
  daysBetween,
  parseDateUtc,
  getTodaysDate,
} from '@util/dateHandler';
import DiscountField from '@sharedComponents/Display/DiscountField';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import InfoField from '@sharedComponents/Display/InfoField';
import RedemptionCount from '../../../Coupons/CouponsDetails/RedemptionCount';

class OrderManagementCouponDetailsModal extends Component {
  componentDidMount() {
    const {
      dispatch,
      isOpen,
      couponCode,
      couponDetails,
    } = this.props;

    const areOldCouponDetails = !couponDetails || couponDetails.code !== couponCode;

    if (couponCode && isOpen && areOldCouponDetails) {
      dispatch(fetchCouponDetailsForOrder(couponCode));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      isOpen,
      couponCode,
      couponDetails,
    } = this.props;

    const couponCodeChanged = prevProps.couponCode !== couponCode;
    const areOldCouponDetails = !couponDetails || couponDetails.code !== couponCode;

    if (couponCode && isOpen && (couponCodeChanged || areOldCouponDetails)) {
      dispatch(fetchCouponDetailsForOrder(couponCode));
    }
  }

  render() {
    const {
      couponDetails,
      isOpen,
      closeModal,
    } = this.props;

    if (!couponDetails) {
      return (
        <Modal
          title={'Coupon Details'}
          modalHeight={'xl'}
          modalWidth={'m'}
          isOpen={isOpen}
          closeModal={closeModal}
          buttons={(
            <ModalButtons
              displayConfirmBtn={false}
              cancelBtnText={'Close'}
              onClose={closeModal}
            />
          )}
        >
          <div>Coupon not found.</div>
        </Modal>
      );
    }

    const todaysDate = getTodaysDate();
    const discountType = discountTypes.find((d) => d.key === couponDetails.discountType);

    return (
      <Modal
        title={'Coupon Details'}
        modalSize={'m'}
        isOpen={isOpen}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            displayConfirmBtn={false}
            cancelBtnText={'Close'}
            onClose={closeModal}
          />
        )}
      >
        {
          isOpen &&
          <div className='tableView__details--content'>
            <div className='tableView__details--content-container'>
              <InfoField
                label={'Coupon Code'}
                value={couponDetails.code}
              />
              <InfoField
                label={'Description'}
                value={couponDetails.description}
              />
              <div className='flex'>
                <InfoField
                  fieldClass={'tableView__details__column--short'}
                  label={'Discount Type'}
                  value={discountType.name}
                />
                <InfoField
                  fieldClass={'tableView__details__column--short'}
                  label={'Discount'}
                  value={(
                    <DiscountField
                      type={couponDetails.discountType}
                      amount={couponDetails.discountAmount}
                    />
                  )}
                />
                <InfoField
                  fieldClass={'tableView__details__column--short'}
                  label={'Reason'}
                  value={couponDetails.reason}
                />
              </div>
            </div>

            <div className='tableView__details--content-container'>
              <div className='flex'>
                <InfoField
                  fieldClass={'tableView__details__column--short'}
                  label={'Valid From'}
                  value={couponDetails.validFrom && parseDateUtc(couponDetails.validFrom)}
                />
                <InfoField
                  fieldClass={'tableView__details__column--short'}
                  label={'Valid To'}
                  value={couponDetails.validTo && parseDateUtc(couponDetails.validTo)}
                />
                <InfoField
                  fieldClass={'tableView__details__column--short'}
                  label={'Time Left'}
                  value={(
                    <div>
                      <div>{(couponDetails.validTo && couponDetails.isActive) ? `${daysBetween(todaysDate, couponDetails.validTo)} days` : '-'}</div>
                      {
                        couponDetails.validTo && couponDetails.isActive &&
                        <div>
                          <ProgressBar
                            className='progress-bar-cell--inverted__bar progress-bar-cell__bar--small'
                            variant='warning'
                            now={daysBetween(couponDetails.validFrom, todaysDate)
                              / daysBetween(couponDetails.validFrom, couponDetails.validTo) * 100
                            }
                          />
                        </div>
                      }
                    </div>
                  )}
                />
              </div>
              <InfoField
                label={'Redemption Count'}
                value={(
                  <RedemptionCount
                    redemptionLimit={couponDetails.redemptionLimit}
                    redemptionCount={couponDetails.redemptionCount}
                  />
                )}
              />
              <InfoField
                label={'Apply To Shipping'}
                fieldClass={'tableView__details__column--short'}
                value={couponDetails.applyToShipping ? 'Yes' : 'No'}
              />
              <InfoField
                label={'Created On'}
                value={couponDetails.createdAt ? `${parseDateTimeNumeric(couponDetails.createdAt)} ${couponDetails.createdBy ? `(${couponDetails.createdBy})` : ''}` : '-'}
              />
              <InfoField
                label={'Last Updated On'}
                value={couponDetails.updatedAt ? `${parseDateTimeNumeric(couponDetails.updatedAt)} ${couponDetails.updatedBy ? `(${couponDetails.updatedBy})` : ''}` : '-'}
              />
            </div>
          </div>
        }
      </Modal>
    );
  }
}

OrderManagementCouponDetailsModal.propTypes = {
  couponDetails: PropTypes.object,
  couponCode: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ orderManagement }) => ({
  couponDetails: orderManagement.orderCouponDetails,
});

export default connect(mapStateToProps)(OrderManagementCouponDetailsModal);
