import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@sharedComponents/Icons/Icon';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';

class ColorsActionsColumn extends PureComponent {
  state = {
    actionsExtended: false,
  };

  closeActions = () => {
    this.setState(() => ({ actionsExtended: false }));
  };

  toggleActionsMenu = () => {
    this.setState((prevState) => ({
      actionsExtended: !prevState.actionsExtended,
    }));
  };

  handleEditClick = (color) => {
    const { onColorEdit } = this.props;

    this.closeActions();
    onColorEdit(color);
  };

  handleDeleteClick = (color) => {
    const { onColorDelete } = this.props;

    this.closeActions();
    onColorDelete(color);
  };

  getActionsMenu = (color) => {
    const { actionsExtended } = this.state;

    const className = classNames(
      'lockerManager__item-list colors__action-item-list',
      { 'is-active': actionsExtended }
    );

    if (actionsExtended) {
      return (
        <ul className={className}>
          <li>
            <button
              onClick={this.handleEditClick.bind(null, color)}
              type='button'
            >
              Edit
            </button>
          </li>
          <li className='lockerManager__item-list--border' />
          <li>
            <button
              onClick={this.handleDeleteClick.bind(this, color)}
              type='button'
              className='red'
            >
              Delete
            </button>
          </li>
        </ul>
      );
    }

    return null;
  };

  render() {
    const { color } = this.props;

    return (
      <OutsideClickWrapper onClick={this.closeActions}>
        <div className='colors__actions-container'>
          <button
            className='colors__actions-btn'
            onClick={this.toggleActionsMenu}
          >
            <Icon
              materialIcon={'more_vert'}
              classes={'button'}
            />
          </button>
          {this.getActionsMenu(color)}
        </div>
      </OutsideClickWrapper>
    );
  }
}

ColorsActionsColumn.propTypes = {
  color: PropTypes.shape({
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string.isRequired,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  }).isRequired,
  onColorEdit: PropTypes.func.isRequired,
  onColorDelete: PropTypes.func.isRequired,
};

export default ColorsActionsColumn;
