import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  adminDetailsUrl,
  lockerDetailsUrl,
  organizationDetailsUrl,
  logoReviewUrl,
} from '@constants/clientUrls/clientUrls';
import { parseDateTimeNumeric } from '@util/dateHandler';
import Button from '@sharedComponents/Buttons/Button';
import BackLink from '@sharedComponents/Navigation/BackLink';
import HeaderInfoComponent from './HeaderInfoComponent';

class TasksHeader extends Component {
  state = {
    isSticky: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    /* eslint-disable-next-line react/no-find-dom-node */
    const isSticky = Math.abs(ReactDOM.findDOMNode(this).offsetTop - window.scrollY) <= 20;
    this.setState(() => ({ isSticky }));
  };

  render() {
    const { isSticky } = this.state;

    const {
      locker,
      logo,
      openSubmitReviewModal,
      headerStyle,
    } = this.props;

    return (
      <div
        className='logo-review__task__header'
        style={headerStyle}
      >
        <div className='logo-review__task__header-info'>
          <div className='logo-review__task__header-info--container'>
            {
              isSticky &&
              <div className='logo-review__task__header__back-btn-container mr-40'>
                <BackLink
                  text={'Back to List'}
                  url={logoReviewUrl}
                  classes={'logo-review__task__header__back-btn'}
                />
              </div>
            }

            <div className='logo-review__task__header-info__fields'>
              <HeaderInfoComponent
                label={'Locker ID'}
                value={(
                  <Link
                    className='link'
                    to={lockerDetailsUrl(locker.id)}
                  >
                    {locker.id} - {locker.teamName}
                  </Link>
                )}
              >
                <span>
                  <a
                    href={locker.lockerUrl}
                    target='_blank'
                    className='logo-review__task__header-info__field--link ml-10'
                    rel='noreferrer'
                  >
                    (<span className='link uppercase'>Store Link</span>)
                  </a>
                </span>
              </HeaderInfoComponent>

              <HeaderInfoComponent
                label={'Manager'}
                value={
                  locker.lockerManagerName
                    ? (
                      <Link
                        className='link'
                        to={adminDetailsUrl(locker.lockerManagerId)}
                      >
                        {locker.lockerManagerName}
                      </Link>
                    )
                    : 'None'
                }
              />

              <HeaderInfoComponent
                label={'Organization'}
                value={
                  locker.organizationName
                    ? (
                      <Link
                        className='link'
                        to={organizationDetailsUrl(locker.organizationId)}
                      >
                        {locker.organizationName}
                      </Link>
                    )
                    : 'None'
                }
              />

              <HeaderInfoComponent
                label={'Task created'}
                value={parseDateTimeNumeric(logo.reviewStartedAt)}
              />
            </div>
          </div>
          <div>
            {
              isSticky &&
              <Button
                type={'primary'}
                text={'Submit Reviewed'}
                onClick={openSubmitReviewModal}
                classes={'logo-review__task__header-info__review-btn'}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

TasksHeader.propTypes = {
  locker: PropTypes.shape({
    id: PropTypes.number,
    teamName: PropTypes.string,
    lockerUrl: PropTypes.string,
    lockerManagerId: PropTypes.number,
    lockerManagerName: PropTypes.string,
    partnerName: PropTypes.string,
    organizationId: PropTypes.number,
    organizationName: PropTypes.string,
    logos: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  logo: PropTypes.shape({
    id: PropTypes.number,
    lockerId: PropTypes.number,
    logoUrl: PropTypes.string,
    reviewStartedAt: PropTypes.string,
    ordersCount: PropTypes.number,
  }).isRequired,
  openSubmitReviewModal: PropTypes.func.isRequired,
  headerStyle: PropTypes.object.isRequired,
};

export default TasksHeader;
