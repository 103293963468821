import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchLockerInfo,
  fetchFulfillmentLockerInfo,
} from '@redux/lockerManager/actions';
import LockerManagerQuickViewHeader from './LockerManagerQuickViewHeader';
import LockerManagerQuickViewInfo from './LockerManagerQuickViewInfo';
import LockerManagerQuickViewActions from './LockerManagerQuickViewActions';

class LockerManagerQuickView extends PureComponent {
  componentDidMount() {
    const {
      dispatch,
      lockerId,
    } = this.props;

    dispatch(fetchLockerInfo(lockerId));
    dispatch(fetchFulfillmentLockerInfo(lockerId));
  }

  componentDidUpdate(oldProps) {
    const {
      lockerId,
      dispatch,
    } = this.props;

    if (lockerId !== oldProps.lockerId) {
      dispatch(fetchLockerInfo(lockerId));
      dispatch(fetchFulfillmentLockerInfo(lockerId));
    }
  }

  render() {
    const {
      lockerId,
      closeDetails,
      lockerInfo,
    } = this.props;
    const isActive = lockerId ? 'is-active' : '';

    return (
      <div
        className={`lockerManager__details ${isActive} size--fit-content`}
        data-test='locker-quick'
      >
        <LockerManagerQuickViewHeader
          lockerId={lockerId}
          closeDetails={closeDetails}
          status={lockerInfo.status}
        />
        <LockerManagerQuickViewInfo locker={lockerInfo} />
        <LockerManagerQuickViewActions lockerId={lockerId} />
      </div>
    );
  }
}

LockerManagerQuickView.propTypes = {
  lockerId: PropTypes.number.isRequired,
  closeDetails: PropTypes.func.isRequired,
  lockerInfo: PropTypes.object.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  lockerInfo: lockerManager.lockerInfo,
});

export default connect(mapStateToProps)(LockerManagerQuickView);
