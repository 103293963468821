import React from 'react';
import { PriorityDetailsModalTaskDetails } from '@models/PrintRoom/PriorityDetailsModalTaskDetails';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import PriorityModalTaskDetails from './PriorityModalTaskDetails';
import PriorityDetailsNote from './PriorityDetailsNote';

interface OwnProps {
  task: PriorityDetailsModalTaskDetails;
  closeModal: () => void;
  isOpen: boolean;
}

type Props = OwnProps;

const PriorityDetailsModal = React.memo<Props>(({
  task,
  closeModal,
  isOpen,
}) => {
  const {
    previewUrl,
    orderNumber,
    quantity,
    type,
    shipDate,
    barcode,
    reasonForPriority,
    user,
    note,
    dateCreated,
    attachment,
  } = task;

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'xl'}
      modalWidth={'m'}
      title={'Priority Note'}
      buttons={(
        <ModalButtons
          onClose={closeModal}
          displayConfirmBtn={false}
        />
      )}
    >
      <div className='flex'>
        <div>
          <PriorityModalTaskDetails
            previewUrl={previewUrl}
            orderNumber={orderNumber}
            quantity={quantity}
            type={type}
            shipDate={shipDate}
            barcode={barcode}
            reasonForPriority={reasonForPriority}
          />
          <PriorityDetailsNote
            user={user}
            note={note}
            dateTime={dateCreated}
          />
        </div>
        {
          attachment &&
          <div>
            <img
              className='modal_full-image'
              src={attachment}
            />
          </div>
        }
      </div>
    </Modal>
  );
});

export default PriorityDetailsModal;
