import React from 'react';
import PropTypes from 'prop-types';

const HeaderInfoComponent = ({
  label,
  value,
  children,
}) => (
  <div className='logo-review__task__header-info__field'>
    <div className='logo-review__task__header-info__field--label'>
      {label}
      {children}
    </div>
    <div className='logo-review__task__header-info__field--value'>
      {value}
    </div>
  </div>
);

HeaderInfoComponent.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
};

export default HeaderInfoComponent;
