import React from 'react';
import PropTypes from 'prop-types';

const LogoUsage = React.memo(({ logo }) => (
  <div>
    <div className='logo-review__task__options-title mb-20'>
      Logo Usage
    </div>
    <div className='logo-review__task__options-text'>
      This logo is currently being used in <b>{logo.ordersCount || 0}</b> {logo.ordersCount === 1 ? 'order' : 'orders'}.
    </div>
    <hr className='mb-0' />
  </div>
));

LogoUsage.propTypes = {
  logo: PropTypes.shape({
    id: PropTypes.number,
    lockerId: PropTypes.number,
    logoUrl: PropTypes.string,
    reviewStartedAt: PropTypes.string,
    ordersCount: PropTypes.number,
  }).isRequired,
};

export default LogoUsage;
