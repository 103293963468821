import {
  DecorationStatusEnum,
  HagPrintRoomJobStatusEnum,
  HagPrintRoomOrderAssemblyPagedListDto,
  SortDirectionEnum,
} from '@api/fulfillment/models';
import DtfPrinterModal from '@components/PrintRoomNew/PrintRoomTableContent/DtfPrinterModal';
import LogoPreviewModal from '@components/shared/Modal/LogoPreviewModal';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import { SortDirectionLong } from '@customTypes/table';
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  RouteComponentProps,
  useParams,
  withRouter,
} from 'react-router-dom';
import PrintAssemblyActions from './PrintAssemblyActions';
import PrintAssemblyTabs from './PrintAssemblyTabs';
import { PrintAssemblyTabsEnum } from './PrintAssemblyTabsEnum';
import Table from '@sharedComponents/Table/Table';
import {
  defaultPageSizeSmallTable, pageSizeOptionsSmallTable,
} from '@constants/values';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
  Column,
  CellInfo,
} from '@util/tableHelpers';
import HeaderCell from '@components/shared/Table/TableCells/HeaderCell';
import DtfPrinterButton from '@components/PrintRoomNew/PrintRoomTableContent/DtfPrinterButton';
import SizeCell from './Cells/SizeCell';
import LogoOrTextCell from './Cells/LogoOrTextCell';
import { useGetHomefieldApiColors } from '@api/productCatalog/colors';
import {
  useGetHomefieldApiHagPrintroomorderassembly,
  postHomefieldApiHagTransmissionssenddecorationids,
  useGetHomefieldApiHagPrintroomorderassemblycountstatuses,
  putHomefieldApiHagPrintroomprogressjob,
} from '@api/fulfillment/hag';
import { parseDateTimeNumeric } from '@util/dateHandler';
import DecorationCell from './Cells/DecorationCell';
import { FlaggingFormData } from '@models/forms/Production/FlaggingFormData';
import { materialSwal } from '@util/componentHelper';
import {
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsflag,
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkallasassembled,
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkasassembled,
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsunflag,
} from '@api/fulfillment/production-assembly-new';
import PrintAssemblyUnflaggingModal from './Modals/PrintAssemblyUnflaggingItemModal';
import PrintAssemblyFlaggingModal from './Modals/PrintAssemblyFlaggingModal';
import FlagCell from './Cells/FlagCell';
import DoneButton from './Buttons/DoneButton';
import PrintAssemblyFinalizeModal from './Modals/PrintAssemblyFinalizeModal';

const connector = connect(null);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;
type Params = { orderNumber: string; };

const AssemblyTable = Table<HagPrintRoomOrderAssemblyPagedListDto>();

const PrintAssembly = React.memo<Props>(() => {
  const [
    orderNumber,
    setOrderNumber,
  ] = useState<number>(0);
  const [
    decorations,
    setDecorations,
  ] = useState<number[]>([]);
  const [
    decorationId,
    setDecorationId,
  ] = useState<number>();
  const [
    imageUrl,
    setImageUrl,
  ] = useState<string>('');
  const [
    previewModalIsOpen,
    setPreviewModalIsOpen,
  ] = useState<boolean>(false);
  const [
    pageNumber,
    setPageNumber,
  ] = useState<number>(1);
  const [
    logosAndPersonalizations,
    setLogosAndPersonalizations,
  ] = useState<Array<HagPrintRoomOrderAssemblyPagedListDto>>([]);
  const [
    totalPages,
    setTotalPages,
  ] = useState<number>(1);
  const [
    hasNextPage,
    setHasNextPage,
  ] = useState<boolean>(false);
  const [
    hasPreviousPage,
    setHasPreviousPage,
  ] = useState<boolean>(false);
  const [
    pageSize,
    setPageSize,
  ] = useState<number>(0);
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionEnum>(SortDirectionEnum.Ascending);
  const [
    sortColumnString,
    setSortColumnString,
  ] = useState<string>();
  const [
    sortDirectionString,
    setSortDirectionString,
  ] = useState<SortDirectionLong>();
  const [
    selectedTab,
    setSelectedTab,
  ] = useState<PrintAssemblyTabsEnum>(PrintAssemblyTabsEnum.ToBePrintAssembled);
  const [
    decorationStatusEnum,
    setDecorationStatusEnum,
  ] = useState<DecorationStatusEnum>(DecorationStatusEnum.Initial);
  const [
    isFlaggingModalOpened,
    setIsFlaggingModalOpened,
  ] = useState<boolean>(false);
  const [
    isUnflaggingModalOpened,
    setIsUnflaggingModalOpened,
  ] = useState<boolean>(false);
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<HagPrintRoomOrderAssemblyPagedListDto>();
  const [
    isFinalizeModalOpened,
    setIsFinalizeModalOpened,
  ] = useState<boolean>(false);

  const { mutateAsync: flagOrderItemDecoration }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsflag();

  const { mutateAsync: unflagOrderItemDecoration }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsunflag();

  const { mutateAsync: markDecorationAsDone }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkasassembled();

  const { mutateAsync: markAllDecorationsAsDone }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkallasassembled();

  const params = useParams<Params>();

  useEffect(() => {
    if (!Number.isNaN(params.orderNumber)) {
      setOrderNumber(Number(params.orderNumber));
    }
  }, [params]);

  const handleDtfPrintByJobId = useCallback(async (orderItemDecorationIds: number[], printerNumber: number) => {
    const res = await postHomefieldApiHagTransmissionssenddecorationids({
      orderItemDecorationIds,
      printerNumber,
      isPriority: true,
    }, {
      isBlockingRequest: true,
    });

    if (res.success) {
      materialSwal('Print Successful', res.message || 'Print success', 'success');
    } else {
      materialSwal('Print Failures', res.message || 'Error unknown', 'error');
    }
  }, []);

  const openLogoPreviewModal = useCallback((url: string) => {
    setImageUrl(url);
    setPreviewModalIsOpen(true);
  }, []);

  const closeLogoPreviewModal = useCallback(() => {
    setImageUrl('');
    setPreviewModalIsOpen(false);
  }, []);

  const openPrintDtfModal = useCallback((decorId: number[]) => setDecorations(decorId), [setDecorations]);
  const closeDftSheetModel = useCallback(() => setDecorations([]), [setDecorations]);
  const onPageNumberChanged = useCallback((pn: number) => setPageNumber(pn), []);

  const searchByDecorationId = useCallback((decorId: number | undefined) => {
    setDecorationId(decorId);
    setPageNumber(1);
  }, []);

  const {
    data: printRoomOrderAssemblyPagedList,
    refetch: fetchPrintRoomOrderAssembly,
  } = useGetHomefieldApiHagPrintroomorderassembly(
    {
      orderNumber,
      decorationId,
      sortDirection,
      pageSize,
      pageNumber,
      decorationStatusEnum,
    }
  );

  useEffect(() => {
    if (orderNumber !== 0) {
      fetchPrintRoomOrderAssembly();
    }
  }, [
    fetchPrintRoomOrderAssembly,
    orderNumber,
    sortDirection,
    pageSize,
    pageNumber,
    decorationId,
    decorationStatusEnum,
  ]);

  const {
    data: printRoomOrderAssemblyOrderDetails,
    refetch: fetchPrintRoomOrderOrderDetails,
  } = useGetHomefieldApiHagPrintroomorderassemblycountstatuses(
    {
      orderNumber,
    }
  );

  useEffect(() => {
    if (orderNumber !== 0) {
      fetchPrintRoomOrderOrderDetails();
    }
  }, [
    fetchPrintRoomOrderOrderDetails,
    orderNumber,
  ]);

  const {
    data: colors,
    refetch: fetchColors,
  } = useGetHomefieldApiColors();

  useEffect(() => {
    fetchColors();
  }, [fetchColors]);

  useEffect(() => {
    if (printRoomOrderAssemblyPagedList?.items) {
      setLogosAndPersonalizations(printRoomOrderAssemblyPagedList?.items);
    }
    if (printRoomOrderAssemblyPagedList?.totalPages) {
      setTotalPages(printRoomOrderAssemblyPagedList?.totalPages);
    }
    if (printRoomOrderAssemblyPagedList?.hasNextPage) {
      setHasNextPage(printRoomOrderAssemblyPagedList?.hasNextPage);
    }
    if (printRoomOrderAssemblyPagedList?.hasPreviousPage) {
      setHasPreviousPage(printRoomOrderAssemblyPagedList?.hasPreviousPage);
    }
    if (printRoomOrderAssemblyPagedList?.pageNumber) {
      onPageNumberChanged(printRoomOrderAssemblyPagedList?.pageNumber);
    }
    if (printRoomOrderAssemblyPagedList?.pageSize) {
      setPageSize(printRoomOrderAssemblyPagedList?.pageSize);
    }
  }, [
    printRoomOrderAssemblyPagedList,
    onPageNumberChanged,
  ]);

  useEffect(() => {
    if (printRoomOrderAssemblyOrderDetails?.isFinalized && selectedTab !== PrintAssemblyTabsEnum.Done) {
      setDecorationStatusEnum(DecorationStatusEnum.Assembled);
      setSelectedTab(PrintAssemblyTabsEnum.Done);
    }
  }, [
    printRoomOrderAssemblyOrderDetails,
    selectedTab,
  ]);

  const handleMarkAllAsDone = useCallback(async () => {
    const res = await markAllDecorationsAsDone({
      data: {
        orderNumber,
      },
    });

    if (res.success) {
      await materialSwal('Mark All As Done Successful', res.message ?? 'Mark All As Done Success', 'success');
    } else {
      await materialSwal('Mark All As Done Failures', res.message ?? 'Error unknown', 'error');

      return;
    }

    fetchPrintRoomOrderAssembly();
    const orderDetails = await fetchPrintRoomOrderOrderDetails();
    if (orderDetails.data?.canFinalize) {
      setIsFinalizeModalOpened(true);
    }
  }, [
    fetchPrintRoomOrderAssembly,
    fetchPrintRoomOrderOrderDetails,
    orderNumber,
    markAllDecorationsAsDone,
  ]);

  const handleFlaggingClicked = useCallback((item: HagPrintRoomOrderAssemblyPagedListDto) => {
    setSelectedItem(item);
    setIsFlaggingModalOpened(true);
  }, []);

  const closeFlaggingModal = useCallback(() => {
    setSelectedItem(undefined);
    setIsFlaggingModalOpened(false);
  }, []);

  const flaggingConfirmed = useCallback(async (form: FlaggingFormData) => {
    setIsFlaggingModalOpened(false);

    const {
      reason,
      note,
      flagType,
    } = form;

    const res = await flagOrderItemDecoration({
      data: {
        orderNumber,
        reason,
        note,
        flagType,
        ids: [selectedItem!.decorationId!],
      },
    });

    if (res.success) {
      materialSwal('Flag Successful', res.message ?? 'Flag Success', 'success');
    } else {
      materialSwal('Flag Failures', res.message ?? 'Error unknown', 'error');
    }

    setSelectedItem(undefined);
    fetchPrintRoomOrderAssembly();
  }, [
    fetchPrintRoomOrderAssembly,
    flagOrderItemDecoration,
    orderNumber,
    selectedItem,
  ]);

  const handleUnflaggingClicked = useCallback((item: HagPrintRoomOrderAssemblyPagedListDto) => {
    setSelectedItem(item);
    setIsUnflaggingModalOpened(true);
  }, []);

  const closeUnflaggingModal = useCallback(() => {
    setSelectedItem(undefined);
    setIsUnflaggingModalOpened(false);
  }, []);

  const unflaggingConfirmed = useCallback(async (form: FlaggingFormData) => {
    setIsUnflaggingModalOpened(false);

    const res = await unflagOrderItemDecoration({
      data: {
        orderNumber,
        ids: [selectedItem!.decorationId!],
      },
    });

    if (res.success) {
      materialSwal('Unflag Successful', res.message ?? 'Unflag Success', 'success');
    } else {
      materialSwal('Unflag Failures', res.message ?? 'Error unknown', 'error');
    }

    setSelectedItem(undefined);
    fetchPrintRoomOrderAssembly();
  }, [
    fetchPrintRoomOrderAssembly,
    unflagOrderItemDecoration,
    orderNumber,
    selectedItem,
  ]);

  const handleDoneClicked = useCallback(async (item: HagPrintRoomOrderAssemblyPagedListDto) => {
    const res = await markDecorationAsDone({
      data: {
        orderNumber,
        ids: [item!.decorationId!],
      },
    });

    if (res.success) {
      await materialSwal('Done Successful', res.message ?? 'Done Success', 'success');
    } else {
      await materialSwal('Done Failures', res.message ?? 'Error unknown', 'error');

      return;
    }

    fetchPrintRoomOrderAssembly();
    const orderDetails = await fetchPrintRoomOrderOrderDetails();
    if (orderDetails.data?.canFinalize) {
      setIsFinalizeModalOpened(true);
    }
  }, [
    fetchPrintRoomOrderAssembly,
    orderNumber,
    markDecorationAsDone,
    fetchPrintRoomOrderOrderDetails,
  ]);

  const closeFinalizeModal = useCallback(() => {
    setIsFinalizeModalOpened(false);
  }, []);

  const finalizeConfirmed = useCallback(async (hagPrintRoomJobId: number | undefined) => {
    const res = await putHomefieldApiHagPrintroomprogressjob({
      hagPrintRoomJobId,
      toStatus: HagPrintRoomJobStatusEnum.Assembled,
    });

    if (res.success) {
      materialSwal('Order Finalized Successfully', res.message ?? 'Order Finalized', 'success');
    } else {
      materialSwal('Order Finalized Failures', res.message ?? 'Error unknown', 'error');
    }
    setIsFinalizeModalOpened(false);
    fetchPrintRoomOrderAssembly();
    fetchPrintRoomOrderOrderDetails();
  }, [
    fetchPrintRoomOrderOrderDetails,
    fetchPrintRoomOrderAssembly,
  ]);

  const handleFinalizedClicked = useCallback(() => {
    setIsFinalizeModalOpened(true);
  }, []);

  type HagCellInfo<K extends keyof HagPrintRoomOrderAssemblyPagedListDto> =
    CellInfo<HagPrintRoomOrderAssemblyPagedListDto, K>;

  const toBeAssembledColumns: Array<Column<HagPrintRoomOrderAssemblyPagedListDto>> = useMemo(() => [
    {
      Header: 'Text / Logo',
      accessor: '',
      Cell: (cellProps) => (
        <LogoOrTextCell
          logoUrl={cellProps.value.previewUrl}
          personalizationText={cellProps.value.text}
          personalizationNumber={cellProps.value.number}
          personalizationColor={colors?.find((x) => x.id === cellProps.value.colorId)}
          personalizationOutlineColor={colors?.find((x) => x.id === cellProps.value.outlineColorId)}
          openLogoPreviewModal={openLogoPreviewModal}
        />
      ),
    },
    {
      Header: 'Location',
      accessor: 'decorationLocation',
    },
    {
      Header: 'Dimensions',
      accessor: '',
      Cell: (cellProps) => (
        <SizeCell
          width={cellProps.value.decorationWidth?.toString() ?? ''}
          height={cellProps.value.decorationHeight?.toString() ?? ''}
        />
      ),
    },
    {
      Header: <HeaderCell text={'Decoration Id'} />,
      accessor: 'decorationId',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'decorationId'>) => (
        <DecorationCell
          orderNumber={orderNumber}
          decorationId={cellProps.value!}
        />
      ),
    },
    {
      Header: 'DTF',
      accessor: 'decorationId',
      Cell: (cellProps: HagCellInfo<'decorationId'>) => (
        <DtfPrinterButton
          id={cellProps.value!}
          onClick={openPrintDtfModal}
          enabled={true}
          label='Reprint'
        />
      ),
    },
    {
      Header: '',
      accessor: '',
      Cell: (cellProps) => (
        <div className='flex'>
          <FlagCell
            openFlaggingModal={handleFlaggingClicked}
            openUnflaggingModal={handleUnflaggingClicked}
            item={cellProps.value}
          />
          {
            !cellProps.value.flag &&
            <DoneButton
              item={cellProps.value}
              onClick={handleDoneClicked}
            />
          }
        </div>
      ),
    },
  ], [
    colors,
    openLogoPreviewModal,
    openPrintDtfModal,
    handleDoneClicked,
    handleFlaggingClicked,
    handleUnflaggingClicked,
    orderNumber,
  ]);

  const doneColumns: Array<Column<HagPrintRoomOrderAssemblyPagedListDto>> = useMemo(() => [
    {
      Header: 'Text / Logo',
      accessor: '',
      Cell: (cellProps) => (
        <LogoOrTextCell
          logoUrl={cellProps.value.previewUrl!}
          personalizationText={cellProps.value.text!}
          personalizationNumber={cellProps.value.number!}
          personalizationColor={colors?.find((x) => x.id === cellProps.value.colorId)}
          personalizationOutlineColor={colors?.find((x) => x.id === cellProps.value.outlineColorId)}
          openLogoPreviewModal={openLogoPreviewModal}
        />
      ),
    },
    {
      Header: 'Location',
      accessor: 'decorationLocation',
    },
    {
      Header: 'Dimensions',
      accessor: '',
      Cell: (cellProps) => (
        <SizeCell
          width={cellProps.value.decorationWidth?.toString() ?? ''}
          height={cellProps.value.decorationHeight?.toString() ?? ''}
        />
      ),
    },
    {
      Header: <HeaderCell text={'Decoration Id'} />,
      accessor: 'decorationId',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'decorationId'>) => (
        <DecorationCell
          orderNumber={orderNumber}
          decorationId={cellProps.value!}
        />
      ),
    },
    {
      Header: 'Completed On',
      accessor: 'completedOn',
      Cell: (cellProps: HagCellInfo<'completedOn'>) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'DTF',
      accessor: 'decorationId',
      Cell: (cellProps: HagCellInfo<'decorationId'>) => (
        <DtfPrinterButton
          id={cellProps.value!}
          onClick={openPrintDtfModal}
          enabled={true}
          label='Reprint'
        />
      ),
    },
  ], [
    colors,
    openLogoPreviewModal,
    orderNumber,
    openPrintDtfModal,
  ]);

  const getColumns = useCallback((): Array<Column<HagPrintRoomOrderAssemblyPagedListDto>> => {
    switch (selectedTab) {
      case PrintAssemblyTabsEnum.ToBePrintAssembled:
        return toBeAssembledColumns;
      case PrintAssemblyTabsEnum.Done:
        return doneColumns;
      default:
        return [];
    }
  }, [
    toBeAssembledColumns,
    doneColumns,
    selectedTab,
  ]);

  const wrappedColumns = useMemo(() => [
    {
      columns: getColumns(),
    },
  ], [getColumns]);

  const fetchData = useCallback((state, instance) => {
    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortColumnString, sortDirectionString);

    if (newSortDirection.toLowerCase() === 'ascending') {
      setSortDirection(SortDirectionEnum.Ascending);
    }
    if (newSortDirection.toLowerCase() === 'descending') {
      setSortDirection(SortDirectionEnum.Descending);
    }

    setSortColumnString(newSortColumn);
    setSortDirectionString(newSortDirection);
    onPageNumberChanged(newPageNumber + 1);
    setPageSize(newPageSize);
  }, [
    sortColumnString,
    sortDirectionString,
    onPageNumberChanged,
  ]);

  const resetFilters = useCallback(() => {
    setPageSize(0);
    onPageNumberChanged(1);
    setHasNextPage(false);
    setHasPreviousPage(false);
    setTotalPages(1);
  }, [onPageNumberChanged]);

  const selectTab = useCallback((newSelectedTab: PrintAssemblyTabsEnum) => {
    if (newSelectedTab !== selectedTab) {
      if (newSelectedTab === PrintAssemblyTabsEnum.ToBePrintAssembled) {
        setDecorationStatusEnum(DecorationStatusEnum.Initial);
      }
      if (newSelectedTab === PrintAssemblyTabsEnum.Done) {
        setDecorationStatusEnum(DecorationStatusEnum.Assembled);
      }
      setLogosAndPersonalizations([]);
      resetFilters();
      setSelectedTab(newSelectedTab);
    }
  }, [
    selectedTab,
    resetFilters,
  ]);

  return (
    <div className='container' >
      <div className='sheet'>
        <PrintAssemblyActions
          orderNumber={orderNumber}
          selectedTab={selectedTab}
          onFinalizedClicked={handleFinalizedClicked}
          searchByDecorationId={searchByDecorationId}
          onMarkAllAsDone={handleMarkAllAsDone}
          orderDetails={printRoomOrderAssemblyOrderDetails}
        />
        <div className='sheet__list'>
          <div className='w-100'>
            <PrintAssemblyTabs
              orderNumber={orderNumber}
              selectTab={selectTab}
              selectedTab={selectedTab}
              orderDetails={printRoomOrderAssemblyOrderDetails}
            />
            <AssemblyTable
              data={logosAndPersonalizations}
              columns={wrappedColumns}
              defaultPageSize={defaultPageSizeSmallTable}
              pageSizeOptions={pageSizeOptionsSmallTable}
              onFetchData={fetchData}
              totalPages={totalPages}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              sortDirEnum={sortDirectionEnum}
              initialPageNumber={pageNumber}
              initialPageSize={pageSize}
              initialSortColumn={sortColumnString}
              initialSortDirection={sortDirectionString}
            />
          </div>
          <DtfPrinterModal
            title={decorations?.length ? `DTF Reprint O${orderNumber}-${decorations[0]}` : 'DTF Reprint'}
            formId='dtf-printer-modal-form_sheet'
            jobIds={decorations}
            isOpen={decorations.length > 0}
            onClose={closeDftSheetModel}
            onPrint={handleDtfPrintByJobId}
          />
          <LogoPreviewModal
            modalIsOpen={previewModalIsOpen}
            closeModal={closeLogoPreviewModal}
            imageUrl={imageUrl}
          />
          <PrintAssemblyFlaggingModal
            orderNumber={orderNumber}
            item={selectedItem}
            isOpen={isFlaggingModalOpened}
            onCloseModal={closeFlaggingModal}
            onSubmit={flaggingConfirmed}
            colors={colors}
          />
          <PrintAssemblyUnflaggingModal
            orderNumber={orderNumber}
            item={selectedItem}
            isOpen={isUnflaggingModalOpened}
            onCloseModal={closeUnflaggingModal}
            onSubmit={unflaggingConfirmed}
            colors={colors}
          />
          <PrintAssemblyFinalizeModal
            hagPrintRoomJobId={printRoomOrderAssemblyOrderDetails?.hagPrintRoomJobId}
            orderNumber={orderNumber}
            isOpen={isFinalizeModalOpened}
            handleClose={closeFinalizeModal}
            handleSubmit={finalizeConfirmed}
          />
        </div>
      </div>
    </div>
  );
});

export default connector(withRouter(PrintAssembly));
