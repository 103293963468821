import React, {
  useCallback,
  useMemo,
} from 'react';
import { WrappedFieldProps } from 'redux-form';
import { CollectionFormDataItemGroup } from '@models/forms/Collections/CollectionFormDataItemGroup';
import TitleDescriptionCell from '@sharedComponents/LockerItems/LockerItemsTableContent/TitleDescriptionCell';
import ItemImage from '@sharedComponents/LockerItems/LockerItemsTableContent/ItemImage';
import QuantityInput from './CollectionFormContent/QuantityInput';

interface OwnProps {
  item: CollectionFormDataItemGroup;
  change: (name: string, value: number) => void;
}

type Props = OwnProps & WrappedFieldProps;

const CollectionItem = React.memo<Props>(({
  input: { name },
  change,
  item,
}) => {
  const updateQuantity = useCallback((quantity: number) => {
    change(`${name}.quantity`, quantity);
  }, [name]);

  const itemToDisplay = useMemo(() => ({
    ...item.itemToDisplay,
    name: item.items.map((it) => it.name),
  }), [item]);

  const { quantity } = item;

  return (
    <div
      key={itemToDisplay.id}
      className='collections-form__item align__center'
    >
      <div className='flex align__center'>
        <div className='ml-20 mr-40'>
          <ItemImage item={itemToDisplay} />
        </div>
        <div>
          <TitleDescriptionCell item={itemToDisplay} />
        </div>
      </div>
      <QuantityInput
        selectedQuantity={quantity}
        updateQuantity={updateQuantity}
        min={1}
        classes={'mr-10'}
      />
    </div>
  );
});

export default CollectionItem;
