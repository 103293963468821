export const merchandisingEffortStatusEnum = {
  Created: 0,
  Ready: 10,
  Loading: 20,
  Loaded: 30,
  Removing: 40,
  Removed: 50,
  Aborted: 60,
  LoadingError: 99,
  RemovingError: 199,
  _displayNames: {
    0: 'Created',
    10: 'Ready',
    20: 'Loading',
    30: 'Loaded',
    40: 'Removing',
    50: 'Removed',
    60: 'Aborted',
    99: 'Loading Error',
    199: 'Removing Error',
  },
};

export const planogramTypeEnum = {
  Locker: 0,
  // SalesChannel: 10,
  _displayNames: {
    0: 'Locker',
    // 10: 'Sales Channel',
  },
};
