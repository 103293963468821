import {
  VoucherNotificationLinkDestinationEnum,
  VoucherNotificationSenderNameEnum,
} from '@api/financialServices/models';

export const VoucherNotificationLinkDestinationLabel = {
  [VoucherNotificationLinkDestinationEnum.CollectionLandingPage]: 'Collection Landing Page',
  [VoucherNotificationLinkDestinationEnum.OrganizationLandingPage]: 'Organization Landing Page',
  [VoucherNotificationLinkDestinationEnum.Storefront]: 'Storefront',
} as const;

export const VoucherNotificationSenderNameLabel = {
  [VoucherNotificationSenderNameEnum.OrganizationName]: 'Organization Name',
  [VoucherNotificationSenderNameEnum.StoreName]: 'Store Name',
  [VoucherNotificationSenderNameEnum.RosterName]: 'Roster Name',
  [VoucherNotificationSenderNameEnum.CustomName]: 'Custom Name',
} as const;
