import React, { useCallback } from 'react';
import {
  DecorationStatusEnum,
  ProductionAssemblyItemGroupDto,
} from '@api/fulfillment/models';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import { Task } from '@models/Production/OrderAssembly/Tasks';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import { DecorationMethodEnum } from '@api/productCatalog/models';

interface OwnProps<T extends Task = Task> {
  item: ProductionAssemblyItemGroupDto;
  task: T;
  taskStatus: DecorationStatusEnum;
  itemStatus: keyof typeof orderItemStatusEnum;
  isAssemblyDisabled?: boolean;
  orderItemDecorationCheckedInOnClick: (task: Task) => void;
}

type Props<T extends Task = Task> = OwnProps<T>;

const CheckInButton = <T extends Task = Task>({
  item,
  task,
  taskStatus,
  itemStatus,
  isAssemblyDisabled,
  orderItemDecorationCheckedInOnClick,
}: Props<T>) => {
  const handleOrderItemDecorationCheckedInOnClick = useCallback(() => {
    orderItemDecorationCheckedInOnClick(task);
  }, [
    orderItemDecorationCheckedInOnClick,
    task,
  ]);

  if (item.decorationMethod === DecorationMethodEnum.EMB
    || item.decorationMethod === DecorationMethodEnum.DTG) {
    return null;
  }

  return (
    <CheckButton
      checked={
        taskStatus === DecorationStatusEnum.CheckedIn
        || taskStatus === DecorationStatusEnum.Decorated
      }
      disabled={
        isAssemblyDisabled
        || (
          taskStatus !== DecorationStatusEnum.CheckedIn
          && taskStatus !== DecorationStatusEnum.Initial
          && taskStatus !== DecorationStatusEnum.Assembled
        )
        || itemStatus === orderItemStatusEnum.Initial
        || itemStatus === orderItemStatusEnum.OnHold
      }
      onClick={handleOrderItemDecorationCheckedInOnClick}
      text={'Check In'}
    />
  );
};

export default React.memo(CheckInButton) as typeof CheckInButton;
