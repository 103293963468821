import * as actionTypes from './types';

const initialState = {
  artworkFileTasks: {
    totalCount: 0,
    pagesCount: 0,
    items: [],
    activePage: null,
    itemsPerPage: null,
    status: null,
    sortBy: '',
    decorations: [],
  },
  artworkFileTaskDetails: {
    id: null,
    logo: null,
    orderId: null,
    taskType: null,
    artworkFiles: [],
    decorationSize: null,
    decorationMethod: null,
    floodColor: null,
    cutFileColor: null,
    isCutFile: null,
    status: null,
    assignedOn: null,
    assignedBy: null,
    assignedTo: null,
    assignedToName: null,
    assignedQcOn: null,
    assignedQcTo: null,
    shipDate: null,
    isCanceled: null,
    previewUrl: null,
    logoUrl: null,
    resourceId: null,
    notes: [],
  },
  previewGenerator: {
    previewUrl: null,
    processing: false,
    failed: false,
    message: '',
    delayedFetch: null,
  },
  failReasons: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ARTWORK_FILE_TASKS:
      return {
        ...state,
        artworkFileTasks: {
          ...state.artworkFileTasks,
          ...action.payload,
        },
      };
    case actionTypes.UPDATE_ARTWORK_FILE_TASK_DETAILS:
      /*
       * Manually insert a specific status like so: {...action.payload, status: 3 } to test
       * different status codes
       */
      return {
        ...state,
        artworkFileTaskDetails: action.payload,
      };
    case actionTypes.DELETE_ARTWORK_FILE_TASK:
      return {
        ...state,
        artworkFileTasks: {
          ...state.artworkFileTasks,
          items: state.artworkFileTasks.items.filter((item) => item.id !== action.taskId),
          totalCount: state.artworkFileTasks.totalCount - 1,
        },
        artworkFileTaskDetails: initialState.artworkFileTaskDetails,
      };
    case actionTypes.CLEAR_ARTWORK_FILE_TASKS:
      return initialState;
    case actionTypes.UPDATE_ARTWORK_FILE_TASK_FAIL_REASONS:
      return {
        ...state,
        failReasons: action.payload,
      };
    case actionTypes.UPDATE_ARTWORK_FILE_TASK_NOTES:
      return {
        ...state,
        notes: action.payload,
      };
    case actionTypes.UPDATE_ARTWORK_FILE_TASK_PREVIEW_GENERATION:
      return {
        ...state,
        previewGenerator: action.payload,
      };
    case actionTypes.CLEAR_ARTWORK_FILE_TASK_PREVIEW_GENERATION:
      return {
        ...state,
        previewGenerator: initialState.previewGenerator,
      };
    case actionTypes.UPDATE_ARTWORK_FILE_TASK_DECORATIONS:
      return {
        ...state,
        artworkFileTasks: {
          ...state.artworkFileTasks,
          decorations: action.payload,
        },
      };
    case actionTypes.UPDATE_ARTWORK_FILE_TASK_SORT:
      return {
        ...state,
        artworkFileTasks: {
          ...state.artworkFileTasks,
          sortBy: action.payload,
        },
      };
    default:
      return state;
  }
}
