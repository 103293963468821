/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  PtlWallDto,
  ApiResponse,
  GetHomefieldApiPtlWallsParams,
  CreatePtlWallCommand,
  UpdatePtlWallCommand,
  PostHomefieldApiPtlWallsIdBinsassociateParams,
  PostHomefieldApiPtlBinsremoveParams,
  PostHomefieldApiPtlItemsreturnParams,
  PostHomefieldApiPtlItemsremoveParams
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiPtlWalls = <TData = PtlWallDto[]>(
    params?: GetHomefieldApiPtlWallsParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/walls`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPtlWallsQueryKey = (params?: GetHomefieldApiPtlWallsParams,) => [`/homefield/api/ptl/walls`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiPtlWalls = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPtlWalls, PtlWallDto[]>, TError = ApiResponse, TData = TQueryFnData>(
 params?: GetHomefieldApiPtlWallsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPtlWallsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPtlWalls<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiPtlWalls = <TData = ApiResponse>(
    createPtlWallCommand: CreatePtlWallCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/walls`, method: 'post',
      data: createPtlWallCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiPtlWalls = <TData = AsyncReturnType<typeof postHomefieldApiPtlWalls,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreatePtlWallCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreatePtlWallCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiPtlWalls<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiPtlWallsId = <TData = PtlWallDto>(
    id: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/walls/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPtlWallsIdQueryKey = (id: number,) => [`/homefield/api/ptl/walls/${id}`]

    
export const useGetHomefieldApiPtlWallsId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPtlWallsId, PtlWallDto>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPtlWallsIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPtlWallsId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiPtlWallsId = <TData = ApiResponse>(
    id: string,
    updatePtlWallCommand: UpdatePtlWallCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/walls/${id}`, method: 'put',
      data: updatePtlWallCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPtlWallsId = <TData = AsyncReturnType<typeof putHomefieldApiPtlWallsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: string;data: UpdatePtlWallCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: string;data: UpdatePtlWallCommand}, TContext>((props) => {
        const {id,data} = props || {};

        return  putHomefieldApiPtlWallsId<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiPtlWallsId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/walls/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiPtlWallsId = <TData = AsyncReturnType<typeof deleteHomefieldApiPtlWallsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiPtlWallsId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiPtlWallsIdBinsassociate = <TData = ApiResponse>(
    id: number,
    params?: PostHomefieldApiPtlWallsIdBinsassociateParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/walls/${id}/bins.associate`, method: 'post',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiPtlWallsIdBinsassociate = <TData = AsyncReturnType<typeof postHomefieldApiPtlWallsIdBinsassociate,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;params?: PostHomefieldApiPtlWallsIdBinsassociateParams}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;params?: PostHomefieldApiPtlWallsIdBinsassociateParams}, TContext>((props) => {
        const {id,params} = props || {};

        return  postHomefieldApiPtlWallsIdBinsassociate<TData>(id,params,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiPtlBinsremove = <TData = ApiResponse>(
    params?: PostHomefieldApiPtlBinsremoveParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/bins.remove`, method: 'post',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiPtlBinsremove = <TData = AsyncReturnType<typeof postHomefieldApiPtlBinsremove,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params?: PostHomefieldApiPtlBinsremoveParams}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {params?: PostHomefieldApiPtlBinsremoveParams}, TContext>((props) => {
        const {params} = props || {};

        return  postHomefieldApiPtlBinsremove<TData>(params,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiPtlItemsreturn = <TData = ApiResponse>(
    params?: PostHomefieldApiPtlItemsreturnParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/items.return`, method: 'post',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiPtlItemsreturn = <TData = AsyncReturnType<typeof postHomefieldApiPtlItemsreturn,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params?: PostHomefieldApiPtlItemsreturnParams}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {params?: PostHomefieldApiPtlItemsreturnParams}, TContext>((props) => {
        const {params} = props || {};

        return  postHomefieldApiPtlItemsreturn<TData>(params,requestOptions)
      }, mutationOptions)
    }
    export const postHomefieldApiPtlItemsremove = <TData = ApiResponse>(
    params?: PostHomefieldApiPtlItemsremoveParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/ptl/items.remove`, method: 'post',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiPtlItemsremove = <TData = AsyncReturnType<typeof postHomefieldApiPtlItemsremove,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params?: PostHomefieldApiPtlItemsremoveParams}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {params?: PostHomefieldApiPtlItemsremoveParams}, TContext>((props) => {
        const {params} = props || {};

        return  postHomefieldApiPtlItemsremove<TData>(params,requestOptions)
      }, mutationOptions)
    }
    