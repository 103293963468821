import React from 'react';
import { Field } from 'redux-form';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';
import Label from '@sharedComponents/Display/Label';

interface OwnProps {
  name?: string;
  text?: string;
  classes?: string;
  disabled?: boolean;
  change: (name: string, value: any) => void;
  tooltipText?: string;
}

type Props = OwnProps;

const RequiredItemFormCheckbox = React.memo<Props>(({
  name = 'required',
  text = 'Mark as Required',
  change,
  classes = '',
  disabled = false,
  tooltipText,
}) => (
  <div className={`flex ${classes}`}>
    <div className='info__field'>
      <Field
        name={name}
        text={text}
        component={ReduxFormCheckbox}
        change={change}
        disabled={disabled}
        tooltipText={tooltipText}
      />
    </div>
    <div className='mt-10 flex'>
      <div className='info__field'>
        <Label
          text={'R'}
          type={'electric-indigo'}
          classes={'label-tag'}
        />
      </div>
    </div>
  </div>
));

export default RequiredItemFormCheckbox;
