export const validatePrintAssemblyFlagItem = (values) => {
  const errors = {};

  if (!values.reason) {
    errors.reason = ['Reason is required.'];
  }

  if (!values.note) {
    errors.note = ['Note is required.'];
  }

  return errors;
};
