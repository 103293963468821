/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type QueriesSortByEnum = 'ShipDate' | 'OrderNumber' | 'LogoCount' | 'ReceivedOn' | 'PrintingBy' | 'PrintingOn' | 'PrintedOn' | 'PrintedBy' | 'ClaimedBy' | 'PrintStatus';


export const QueriesSortByEnum = {
  ShipDate: 'ShipDate' as QueriesSortByEnum,
  OrderNumber: 'OrderNumber' as QueriesSortByEnum,
  LogoCount: 'LogoCount' as QueriesSortByEnum,
  ReceivedOn: 'ReceivedOn' as QueriesSortByEnum,
  PrintingBy: 'PrintingBy' as QueriesSortByEnum,
  PrintingOn: 'PrintingOn' as QueriesSortByEnum,
  PrintedOn: 'PrintedOn' as QueriesSortByEnum,
  PrintedBy: 'PrintedBy' as QueriesSortByEnum,
  ClaimedBy: 'ClaimedBy' as QueriesSortByEnum,
  PrintStatus: 'PrintStatus' as QueriesSortByEnum,
};
