import React, { useCallback } from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { HagPrintRoomJobPriorityItemDto } from '@api/fulfillment/models';
import {
  parseDateNumeric,
  parseDateTimeNumeric,
} from '@util/dateHandler';
import PrintRoomPreviewCell from './PrintRoomPreview/PrintRoomPreviewCell';

interface OwnProps {
  item: HagPrintRoomJobPriorityItemDto;
  orderNumber: number;
  shipDate: string;
  isOpen: boolean;
  onClose: () => void;
}

type Props = OwnProps;

const InfoItem = (props: { label: string; content: string | number; }) => (
  <div className='flagging__details-info-item'>
    <div className='flagging__details-info-label'>
      {props.label}
    </div>
    <div className='flagging__details-info-content'>
      {props.content}
    </div>
  </div>
);

const PriorityNoteModal = React.memo<Props>(({
  item,
  orderNumber,
  isOpen,
  shipDate,
  onClose,
}) => {
  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      modalHeight={'xl'}
      modalWidth={'m'}
      title={'Priority Note'}
      isOpen={isOpen}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          onClose={handleCloseModal}
          displayConfirmBtn={false}
        />
      )}
    >
      <div className='flex'>
        <div>
          <div className='flagging__details'>
            <div className='flagging_details-logo'>
              <PrintRoomPreviewCell item={item} />
            </div>
            <div className='flagging__details-info'>
              <div className='flagging__details-info-column'>
                <InfoItem
                  label={'Order ID'}
                  content={`O${orderNumber}`}
                />
                <InfoItem
                  label={'Decoration ID'}
                  content={item.orderItemDecorationId ? `O${orderNumber}-${item.orderItemDecorationId}` : ''}
                />
              </div>
              <div className='flagging__details-info-column'>
                <InfoItem
                  label={'Reason for Priority'}
                  content={item.reason ?? ''}
                />
              </div>
              <div className='flagging__details-info-column'>
                <InfoItem
                  label={'Ship Date'}
                  content={parseDateNumeric(shipDate)}
                />
              </div>
            </div>
          </div>
          <div className='flagging__details'>
            <div className='flagging__details-note'>
              <div className='flagging__details-note-header'>
                <h3 className='float-left'>{item.userId}</h3>
                <h3 className='float-right'>{parseDateTimeNumeric(item.reasonCreated)}</h3>
              </div>
              <p>{item.note}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default PriorityNoteModal;
