import { fundraisingPayoutTypeEnum } from '@constants/enums/fundraisingPayoutsEnums';
import { buildQueryString } from '@util/stringHelpers';

export const root = 'homefield/api' as const;

/** Payouts */
export const payouts = `${root}/payouts` as const;
export const payout = (payoutId: number): string => `${payouts}/${payoutId}`;
export const payoutsAction = (payoutId: number, action: string = ''): string => `${action ? `${payouts}.${action}` : payouts}/${payoutId}`;

/** Statuses */
export const lockerStatus = `${root}/lockers.status` as const;

export const payoutsTable = (
  payoutType: keyof typeof fundraisingPayoutTypeEnum,
  pageNumber: number,
  pageSize: number,
  status: string,
  sortBy: string,
  sortDirection: string,
  search: string,
  accountingSubmitStatus: string | null
): string => `${payouts}${buildQueryString({
  payoutType,
  pageNumber,
  pageSize,
  status,
  sortBy,
  sortDirection,
  search,
  accountingSubmitStatus,
}, true)}`;

export const orderItemsForPayouts = (payoutId: number): string => `${payout(payoutId)}/order-items`;
export const ordersForPayout = (payoutId: number): string => `${payout(payoutId)}/orders`;
export const statusesForPayout = (payoutId: number): string => `${payout(payoutId)}/statuses`;
export const lockersForPayout = (payoutId: number): string => `${payout(payoutId)}/lockers`;

export const cancelPayout = (payoutId: number): string => payoutsAction(payoutId, 'cancel');
export const submitPayoutToAccounting = (payoutId: number): string => payoutsAction(payoutId, 'accounting-submit');

export const accountingSubmitResults = (payoutId: number): string => `${payout(payoutId)}/accounting-submit-results`;

/** Order Items */
export const orderItemsStatus = (
  lockerId: number
): string => `${lockerStatus}/${lockerId}`;

export default {
  payout,
  payoutsTable,
  orderItemsForPayouts,
  ordersForPayout,
  statusesForPayout,
  lockersForPayout,
  cancelPayout,
  submitPayoutToAccounting,
  orderItemsStatus,
  accountingSubmitResults,
};
