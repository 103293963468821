import { emailRegex } from '@constants/common';
import {
  defaultPhoneNumberLength,
  emailMaxLength,
  onlyNumberRegex,
} from '@constants/values';
import { isDefined } from '@util/valueHelpers';

export const validateAdmin = (values) => {
  const errors = {};

  const {
    email,
    firstName,
    lastName,
    phoneNumber,
    role,
    organizationType,
  } = values;

  if (!isDefined(email)) {
    errors.email = ['E-mail is required.'];
  } else if (!email.match(emailRegex)) {
    errors.email = ['E-mail is not in correct form.'];
  } else if (email.length > emailMaxLength) {
    errors.email = [`Email should not be greater than ${emailMaxLength} characters.`];
  }

  if (!firstName) {
    errors.firstName = ['First name is required.'];
  }

  if (!lastName) {
    errors.lastName = ['Last name is required.'];
  }

  if (!isDefined(phoneNumber)) {
    errors.phoneNumber = ['Phone number is required.'];
  } else if (!phoneNumber.match(onlyNumberRegex)) {
    errors.phoneNumber = ['Phone number should contain only digits.'];
  } else if (phoneNumber.length !== defaultPhoneNumberLength) {
    errors.phoneNumber = [`Phone number should be exactly ${defaultPhoneNumberLength} characters long.`];
  }

  if (!role) {
    errors.role = ['Role is required.'];
  }

  if (!organizationType) {
    errors.organizationType = ['Organization type is required.'];
  }

  return errors;
};
