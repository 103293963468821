/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  PriorityQueueDtoPagedList,
  GetHomefieldApiPrintroomPrintqueuePriorityqueueParams,
  PrintRoomPagedList,
  GetHomefieldApiPrintroomPrintqueueParams,
  IIdentityUserDto,
  GetHomefieldApiPrintroomPrintqueueUsersParams,
  SheetDetailsDto,
  ApiResponse,
  UpdateSheetStatusCommand,
  PrintQueueSearchResultDto,
  GetHomefieldApiPrintroomPrintqueuesearchParams,
  ReworkPrintQueueCommand,
  ArtworkItemReworkDto,
  PrintAssemblyQueueDtoPagedList,
  GetHomefieldApiPrintroomPrintassemblyPrintqueueIdParams,
  UpdatePrintAssemblyQueueStatusCommand,
  UpdatePrintAssemblyQueueStatusByBarcodeCommand,
  UpdatePrintAssemblyQueueStatusByPrintQueueIdCommand,
  MarkOrderFinalizedCommand,
  PutHomefieldApiPrintroomPrintassemblyqueueflagBody,
  OrderPrintAssemblyDetailsDto,
  AddOrderToArtworkInventoryCommand,
  InventorySearchDto,
  GetHomefieldApiPrintroomInventorysearchParams
} from './models'
import { fulfillmentApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiPrintroomPrintqueuePriorityqueue = <TData = PriorityQueueDtoPagedList>(
    params?: GetHomefieldApiPrintroomPrintqueuePriorityqueueParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue/priority-queue`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintqueuePriorityqueueQueryKey = (params?: GetHomefieldApiPrintroomPrintqueuePriorityqueueParams,) => [`/homefield/api/printroom/print-queue/priority-queue`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiPrintroomPrintqueuePriorityqueue = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintqueuePriorityqueue, PriorityQueueDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiPrintroomPrintqueuePriorityqueueParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintqueuePriorityqueueQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintqueuePriorityqueue<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiPrintroomPrintqueue = <TData = PrintRoomPagedList>(
    params?: GetHomefieldApiPrintroomPrintqueueParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintqueueQueryKey = (params?: GetHomefieldApiPrintroomPrintqueueParams,) => [`/homefield/api/printroom/print-queue`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiPrintroomPrintqueue = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintqueue, PrintRoomPagedList>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiPrintroomPrintqueueParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintqueueQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintqueue<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiPrintroomPrintqueueUsers = <TData = IIdentityUserDto[]>(
    params?: GetHomefieldApiPrintroomPrintqueueUsersParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue/users`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintqueueUsersQueryKey = (params?: GetHomefieldApiPrintroomPrintqueueUsersParams,) => [`/homefield/api/printroom/print-queue/users`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiPrintroomPrintqueueUsers = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintqueueUsers, IIdentityUserDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiPrintroomPrintqueueUsersParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintqueueUsersQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintqueueUsers<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiPrintroomPrintqueuePrintQueueId = <TData = SheetDetailsDto>(
    printQueueId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue/${printQueueId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintqueuePrintQueueIdQueryKey = (printQueueId: number,) => [`/homefield/api/printroom/print-queue/${printQueueId}`]

    
export const useGetHomefieldApiPrintroomPrintqueuePrintQueueId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintqueuePrintQueueId, SheetDetailsDto>, TError = unknown, TData = TQueryFnData>(
 printQueueId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintqueuePrintQueueIdQueryKey(printQueueId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintqueuePrintQueueId<TQueryFnData>(printQueueId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiPrintroomPrintqueuePrintQueueId = <TData = ApiResponse>(
    printQueueId: number,
    updateSheetStatusCommand: UpdateSheetStatusCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue/${printQueueId}`, method: 'put',
      data: updateSheetStatusCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintqueuePrintQueueId = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintqueuePrintQueueId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{printQueueId: number;data: UpdateSheetStatusCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {printQueueId: number;data: UpdateSheetStatusCommand}, TContext>((props) => {
        const {printQueueId,data} = props || {};

        return  putHomefieldApiPrintroomPrintqueuePrintQueueId<TData>(printQueueId,data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiPrintroomPrintqueuesearch = <TData = PrintQueueSearchResultDto>(
    params?: GetHomefieldApiPrintroomPrintqueuesearchParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue.search`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintqueuesearchQueryKey = (params?: GetHomefieldApiPrintroomPrintqueuesearchParams,) => [`/homefield/api/printroom/print-queue.search`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiPrintroomPrintqueuesearch = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintqueuesearch, PrintQueueSearchResultDto>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiPrintroomPrintqueuesearchParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintqueuesearchQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintqueuesearch<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiPrintroomPrintqueuereworkartwork = <TData = ApiResponse>(
    reworkPrintQueueCommand: ReworkPrintQueueCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue.rework-artwork`, method: 'put',
      data: reworkPrintQueueCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintqueuereworkartwork = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintqueuereworkartwork,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: ReworkPrintQueueCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: ReworkPrintQueueCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiPrintroomPrintqueuereworkartwork<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiPrintroomPrintqueuePrintQueueIdArtworks = <TData = ArtworkItemReworkDto[]>(
    printQueueId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue/${printQueueId}/artworks`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintqueuePrintQueueIdArtworksQueryKey = (printQueueId: number,) => [`/homefield/api/printroom/print-queue/${printQueueId}/artworks`]

    
export const useGetHomefieldApiPrintroomPrintqueuePrintQueueIdArtworks = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintqueuePrintQueueIdArtworks, ArtworkItemReworkDto[]>, TError = unknown, TData = TQueryFnData>(
 printQueueId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintqueuePrintQueueIdArtworksQueryKey(printQueueId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintqueuePrintQueueIdArtworks<TQueryFnData>(printQueueId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getHomefieldApiPrintroomPrintqueuePrintQueueIdLabels = <TData = unknown>(
    printQueueId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue/${printQueueId}/labels`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintqueuePrintQueueIdLabelsQueryKey = (printQueueId: number,) => [`/homefield/api/printroom/print-queue/${printQueueId}/labels`]

    
export const useGetHomefieldApiPrintroomPrintqueuePrintQueueIdLabels = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintqueuePrintQueueIdLabels, unknown>, TError = unknown, TData = TQueryFnData>(
 printQueueId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintqueuePrintQueueIdLabelsQueryKey(printQueueId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintqueuePrintQueueIdLabels<TQueryFnData>(printQueueId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiPrintroomPrintqueuestartprintassemblyPrintQueueId = <TData = ApiResponse>(
    printQueueId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue.start-print-assembly/${printQueueId}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintqueuestartprintassemblyPrintQueueId = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintqueuestartprintassemblyPrintQueueId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{printQueueId: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {printQueueId: number}, TContext>((props) => {
        const {printQueueId} = props || {};

        return  putHomefieldApiPrintroomPrintqueuestartprintassemblyPrintQueueId<TData>(printQueueId,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiPrintroomPrintassemblyPrintqueueId = <TData = PrintAssemblyQueueDtoPagedList>(
    id: number,
    params?: GetHomefieldApiPrintroomPrintassemblyPrintqueueIdParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-assembly/print-queue/${id}`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintassemblyPrintqueueIdQueryKey = (id: number,
    params?: GetHomefieldApiPrintroomPrintassemblyPrintqueueIdParams,) => [`/homefield/api/printroom/print-assembly/print-queue/${id}`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiPrintroomPrintassemblyPrintqueueId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintassemblyPrintqueueId, PrintAssemblyQueueDtoPagedList>, TError = unknown, TData = TQueryFnData>(
 id: number,
    params?: GetHomefieldApiPrintroomPrintassemblyPrintqueueIdParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintassemblyPrintqueueIdQueryKey(id,params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintassemblyPrintqueueId<TQueryFnData>(id,params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiPrintroomPrintassemblyqueuestatus = <TData = ApiResponse>(
    updatePrintAssemblyQueueStatusCommand: UpdatePrintAssemblyQueueStatusCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-assembly-queue.status`, method: 'put',
      data: updatePrintAssemblyQueueStatusCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintassemblyqueuestatus = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintassemblyqueuestatus,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdatePrintAssemblyQueueStatusCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdatePrintAssemblyQueueStatusCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiPrintroomPrintassemblyqueuestatus<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiPrintroomPrintassemblyqueuestatusbarcode = <TData = ApiResponse>(
    updatePrintAssemblyQueueStatusByBarcodeCommand: UpdatePrintAssemblyQueueStatusByBarcodeCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-assembly-queue.status-barcode`, method: 'put',
      data: updatePrintAssemblyQueueStatusByBarcodeCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintassemblyqueuestatusbarcode = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintassemblyqueuestatusbarcode,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdatePrintAssemblyQueueStatusByBarcodeCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdatePrintAssemblyQueueStatusByBarcodeCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiPrintroomPrintassemblyqueuestatusbarcode<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiPrintroomPrintassemblyqueuestatusprintqueueid = <TData = ApiResponse>(
    updatePrintAssemblyQueueStatusByPrintQueueIdCommand: UpdatePrintAssemblyQueueStatusByPrintQueueIdCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-assembly-queue.status-print-queue-id`, method: 'put',
      data: updatePrintAssemblyQueueStatusByPrintQueueIdCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintassemblyqueuestatusprintqueueid = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintassemblyqueuestatusprintqueueid,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: UpdatePrintAssemblyQueueStatusByPrintQueueIdCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: UpdatePrintAssemblyQueueStatusByPrintQueueIdCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiPrintroomPrintassemblyqueuestatusprintqueueid<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiPrintroomPrintqueuefinalizeorder = <TData = ApiResponse>(
    markOrderFinalizedCommand: MarkOrderFinalizedCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue.finalize-order`, method: 'put',
      data: markOrderFinalizedCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintqueuefinalizeorder = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintqueuefinalizeorder,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: MarkOrderFinalizedCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: MarkOrderFinalizedCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiPrintroomPrintqueuefinalizeorder<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiPrintroomOrderscheckinventoryOrderNumber = <TData = ApiResponse>(
    orderNumber: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/orders.check-inventory/${orderNumber}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomOrderscheckinventoryOrderNumberQueryKey = (orderNumber: number,) => [`/homefield/api/printroom/orders.check-inventory/${orderNumber}`]

    
export const useGetHomefieldApiPrintroomOrderscheckinventoryOrderNumber = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomOrderscheckinventoryOrderNumber, ApiResponse>, TError = unknown, TData = TQueryFnData>(
 orderNumber: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomOrderscheckinventoryOrderNumberQueryKey(orderNumber);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomOrderscheckinventoryOrderNumber<TQueryFnData>(orderNumber, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiPrintroomPrintassemblyqueueflag = <TData = ApiResponse>(
    putHomefieldApiPrintroomPrintassemblyqueueflagBody: PutHomefieldApiPrintroomPrintassemblyqueueflagBody,
 options?: SecondParameter<typeof fulfillmentApi>) => {const formData = new FormData();
if(putHomefieldApiPrintroomPrintassemblyqueueflagBody.printAssemblyQueueId) {
 formData.append('printAssemblyQueueId', putHomefieldApiPrintroomPrintassemblyqueueflagBody.printAssemblyQueueId.toString())
 }
if(putHomefieldApiPrintroomPrintassemblyqueueflagBody.ids) {
 formData.append('ids', JSON.stringify(putHomefieldApiPrintroomPrintassemblyqueueflagBody.ids))
 }
if(putHomefieldApiPrintroomPrintassemblyqueueflagBody.reason) {
 formData.append('reason', putHomefieldApiPrintroomPrintassemblyqueueflagBody.reason)
 }
if(putHomefieldApiPrintroomPrintassemblyqueueflagBody.note) {
 formData.append('note', putHomefieldApiPrintroomPrintassemblyqueueflagBody.note)
 }
if(putHomefieldApiPrintroomPrintassemblyqueueflagBody.flagType) {
 formData.append('flagType', putHomefieldApiPrintroomPrintassemblyqueueflagBody.flagType)
 }
if(putHomefieldApiPrintroomPrintassemblyqueueflagBody.fileName) {
 formData.append('fileName', putHomefieldApiPrintroomPrintassemblyqueueflagBody.fileName)
 }

      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-assembly-queue.flag`, method: 'put',
       data: formData
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintassemblyqueueflag = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintassemblyqueueflag,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: PutHomefieldApiPrintroomPrintassemblyqueueflagBody}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: PutHomefieldApiPrintroomPrintassemblyqueueflagBody}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiPrintroomPrintassemblyqueueflag<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiPrintroomPrintassemblyqueueunflagPrintAssemblyQueueId = <TData = ApiResponse>(
    printAssemblyQueueId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-assembly-queue.unflag/${printAssemblyQueueId}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintassemblyqueueunflagPrintAssemblyQueueId = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintassemblyqueueunflagPrintAssemblyQueueId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{printAssemblyQueueId: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {printAssemblyQueueId: number}, TContext>((props) => {
        const {printAssemblyQueueId} = props || {};

        return  putHomefieldApiPrintroomPrintassemblyqueueunflagPrintAssemblyQueueId<TData>(printAssemblyQueueId,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiPrintroomPrintassemblyqueueinprintPrintAssemblyQueueId = <TData = ApiResponse>(
    printAssemblyQueueId: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-assembly-queue.in-print/${printAssemblyQueueId}`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomPrintassemblyqueueinprintPrintAssemblyQueueId = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomPrintassemblyqueueinprintPrintAssemblyQueueId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{printAssemblyQueueId: number}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {printAssemblyQueueId: number}, TContext>((props) => {
        const {printAssemblyQueueId} = props || {};

        return  putHomefieldApiPrintroomPrintassemblyqueueinprintPrintAssemblyQueueId<TData>(printAssemblyQueueId,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiPrintroomPrintqueueIdOrders = <TData = OrderPrintAssemblyDetailsDto[]>(
    id: number,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/print-queue/${id}/orders`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomPrintqueueIdOrdersQueryKey = (id: number,) => [`/homefield/api/printroom/print-queue/${id}/orders`]

    
export const useGetHomefieldApiPrintroomPrintqueueIdOrders = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomPrintqueueIdOrders, OrderPrintAssemblyDetailsDto[]>, TError = unknown, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomPrintqueueIdOrdersQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomPrintqueueIdOrders<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiPrintroomInventoryOrders = <TData = ApiResponse>(
    addOrderToArtworkInventoryCommand: AddOrderToArtworkInventoryCommand,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/inventory/orders`, method: 'put',
      data: addOrderToArtworkInventoryCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiPrintroomInventoryOrders = <TData = AsyncReturnType<typeof putHomefieldApiPrintroomInventoryOrders,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: AddOrderToArtworkInventoryCommand}, TContext>, request?: SecondParameter<typeof fulfillmentApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: AddOrderToArtworkInventoryCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiPrintroomInventoryOrders<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiPrintroomInventorysearch = <TData = InventorySearchDto[]>(
    params?: GetHomefieldApiPrintroomInventorysearchParams,
 options?: SecondParameter<typeof fulfillmentApi>) => {
      return fulfillmentApi<TData>(
      {url: `/homefield/api/printroom/inventory.search`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiPrintroomInventorysearchQueryKey = (params?: GetHomefieldApiPrintroomInventorysearchParams,) => [`/homefield/api/printroom/inventory.search`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiPrintroomInventorysearch = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiPrintroomInventorysearch, InventorySearchDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiPrintroomInventorysearchParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof fulfillmentApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiPrintroomInventorysearchQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiPrintroomInventorysearch<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

