import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class LogoReviewNotes extends PureComponent {
  onChangeNote = (e) => {
    const { updateNote } = this.props;
    const newValue = e.target.value;

    updateNote(newValue);
  };

  render() {
    const { note } = this.props;

    return (
      <div>
        <div className='logo-review__task__options-title mb-20'>Item Notes</div>
        <div className='text-field'>
          <textarea
            className='w-100'
            rows='2'
            onChange={this.onChangeNote}
            defaultValue={note}
            placeholder='Write a note...'
          />
        </div>
      </div>
    );
  }
}

LogoReviewNotes.propTypes = {
  updateNote: PropTypes.func.isRequired,
  note: PropTypes.string,
};

export default LogoReviewNotes;
