import React from 'react';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

interface OwnProps{
  filter: (value: string) => void;
  selected: string;
  defaultValue: string;
}

type Props = OwnProps;

const DateRangeFilter = React.memo<Props>(({
  filter,
  selected,
  defaultValue,
}) => {
  const optionsValues = [];
  for (const date of Object.values(dateRangeEnum)) {
    if (date.inFuture) {
      continue;
    }

    optionsValues.push(date);
  }

  const options = optionsValues.map((option) => ({
    key: option.value,
    value: option.value,
    name: option.title,
  }));

  const selectedRange = options.find((range) => range.value === selected);
  const value = selectedRange ? selectedRange.value : defaultValue;

  return (
    <Dropdown
      options={options}
      onChange={filter}
      defaultValue={value}
      classes={'filter-group'}
    />
  );
});

export default DateRangeFilter;
