import React from 'react';
import { PriorityQueueDto } from '@api/fulfillment/models';
import { ColorDto } from '@api/productCatalog/models';
import LogoCell from './LogoCell';
import TextCell from '@components/WeedingAndMasking/Cells/TextCell';

interface OwnProps {
  item: PriorityQueueDto;
  colorsDictionary: Record<string, ColorDto>;
  openModal: (logoUrl: string) => void;
}

type Props = OwnProps;

const LogoOrTextCell = React.memo<Props>(({
  item,
  openModal,
  colorsDictionary,
}) => {
  if (item.previewUrl) {
    return (
      <LogoCell
        openModal={openModal}
        logoUrl={item.previewUrl}
      />
    );
  }

  return (
    <TextCell
      personalizationColor={colorsDictionary[item.personalizationColorId!]}
      personalizationText={item.personalizationLabel ?? undefined}
      personalizationOutlineColor={colorsDictionary[item.outlineColorId!]}
    />
  );
});

export default LogoOrTextCell;
