import React, { useCallback } from 'react';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  personalization: any;
  colorsDictionary: any;
  personalizationOnClick?: (personalization: any) => void;
  classes?: string;
  showColorTooltip?: boolean;
}

type Props = OwnProps;

const Personalization = React.memo(({
  personalization,
  colorsDictionary,
  personalizationOnClick,
  classes = '',
  showColorTooltip = true,
}: Props) => {
  const {
    text,
    name,
    number,
    decorationMethod,
    decorationLocation,
    isCanceled,
  } = personalization;

  const handleClickOnPersonalization = useCallback(() => {
    if (!personalizationOnClick) return;

    personalizationOnClick(personalization);
  }, [
    personalization,
    personalizationOnClick,
  ]);

  const color = personalization.colorId && colorsDictionary[personalization.colorId];
  const outlineColor = personalization.outlineColorId && colorsDictionary[personalization.outlineColorId];

  const content = isCanceled
    ? <p className='canceled-text'>{`${text || name || ''} ${number || ''}`}</p>
    : `${text || name || ''} ${number || ''}`;

  let outlineElement = null;
  if (outlineColor?.hexValue) {
    outlineElement = (
      <span
        className='color--outline'
        style={{ borderColor: outlineColor?.hexValue }}
      />
    );
  }

  const tooltipText = showColorTooltip && (
    <div className='flex__column'>
      <span>Fill: {color?.code || '-'}</span>
      {outlineColor && <span>Outline: {outlineColor.code || '-'}</span>}
    </div>
  );

  return (
    <div
      className={`flex__column w-fit-content ${classes} ${personalizationOnClick ? 'cursor-pointer' : ''}`}
      onClick={handleClickOnPersonalization}
    >
      <div className='order__item-personalization' >
        <div className='text'>{content}</div>
        {
          showColorTooltip
            ? (
              <MaterialTooltip
                tooltipText={tooltipText}
                placement={'right'}
                contentClasses={'color'}
              >
                <div
                  className='w-100 h-100'
                  style={{ backgroundColor: color?.hexValue }}
                >
                  {outlineElement}
                </div>
              </MaterialTooltip>
            )
            : (
              <div
                className='w-100 h-50'
                style={{ backgroundColor: color?.hexValue }}
              >
                {outlineElement}
              </div>
            )
        }

      </div>
      <div className='order__item-personalization-thumbnail sheet__thumbnails-title'>
        <span>{decorationLocation}</span>
        <span>{decorationMethod}</span>
      </div>
    </div>
  );
});

export default Personalization;
