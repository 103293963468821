import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { storefrontRoot } from '@constants/urls/configUrls';
import { orderSearchOrganizationUrl } from '@constants/clientUrls/clientUrls';
import { organizationLandingPageEnum } from '@constants/enums/organizationEnums';
import { parseDateNumeric } from '@util/dateHandler';
import { formatDollarAmount } from '@util/numberHelpers';
import ColorDisplay from '@sharedComponents/Display/ColorDisplay';
import InfoField from '@sharedComponents/Display/InfoField';
import OpenNewTabLink from '@sharedComponents/Navigation/OpenNewTabLink';
import OrganizationLogo from '../OrganizationLogo';
import PermissionHandler from '@components/shared/Authorization/PermissionHandler';
import { toggleButtonStateEnum } from '@constants/enums/commonEnums';
import PermissionsEnum from '@constants/enums/permissionsEnum';
import ToggleButton from '@components/shared/Buttons/ToggleButton';

const HeaderDetails = React.memo(({
  organization,
  organizationTotals,
  organizationLockers,
  teamColors,
  onToggleAutoMerchOptIn,
}) => {
  const renderOrganizationUID = () => {
    if (!organization.partnerOrganizations || organization.partnerOrganizations.length === 0) {
      return null;
    }

    return organization.partnerOrganizations?.map(
      (po) => (
        <div key={po.partnerId}>
          <span>{`${po.partnerName}`}</span> <span>{`(${po.partnerOrgUID})`}</span>
        </div>
      )
    );
  };

  const optInAutomerch = useMemo(() => organizationLockers.every((locker) => locker.optInAutomerch === false),
    [organizationLockers]);

  return (
    <div className='lockerManagerEdit__details-container'>
      <div className='lockerManagerEdit__details-info'>
        {
          organization.logoImage &&
          <div className='lockerManagerEdit__details-column--l organization__header--logo'>
            <div className='mb-20'>
              <OrganizationLogo logoUrl={organization.logoImage} />
            </div>
          </div>
        }
        <div className='lockerManagerEdit__details-column--l mr-30'>
          <InfoField
            label={'Type'}
            value={organization.orgType}
          />
          <InfoField
            label={'Size'}
            value={organization.size}
          />
          <InfoField
            fieldClass='info-flex-column'
            label={'Organization UID'}
            value={renderOrganizationUID()}
          />
        </div>
        <div className='lockerManagerEdit__details-column--l fixed-width mr-30'>
          <InfoField
            label={'Date Created'}
            value={organization.createdAt && parseDateNumeric(organization.createdAt)}
          />
          <InfoField
            label={'Organization Colors'}
            value={(organization.colors && organization.colors.length > 0) &&
              teamColors.filter((c) => organization.colors.includes(c.code)).map((c) => (
                <ColorDisplay
                  key={c.id}
                  colorName={c.name}
                  displayName={false}
                  size={'size--small'}
                  hexValue={c.hexValue}
                />
              ))
            }
          />
          <InfoField
            label={'SquadLocker\'s Picks'}
            value={(
              <div className='flex-center'>
                <span className={!optInAutomerch ? 'status-label enabled' : ''}>
                  {!optInAutomerch ? toggleButtonStateEnum.ON : toggleButtonStateEnum.OFF}
                </span>
                <div className='ml-10'>
                  <PermissionHandler permissions={PermissionsEnum.MerchandisingOptInOut}>
                    <ToggleButton
                      name={'optInAutomerch'}
                      enabled={!optInAutomerch}
                      onChange={onToggleAutoMerchOptIn}
                      dataTest='auto-merch-toggle'
                    />
                  </PermissionHandler>
                </div>
              </div>
            )}
            dataTest='auto-merch'
          />
        </div>
        <div className='lockerManagerEdit__details-column--l fixed-width mr-30'>
          <InfoField
            label={'Decision Maker'}
            value={`${organization.lockerManagerFirstName || ''} ${organization.lockerManagerLastName || ''}`}
          />
          <InfoField
            label={'Website'}
            labelClass={'flex'}
            value={(
              <div className='lockerManagerEdit__details-fundraising'>
                {
                  organization.website
                    ? (
                      <a
                        className='link'
                        href={organization.website}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {organization.website}
                      </a>
                    )
                    : '-'
                }
              </div>
            )}
          />
          <InfoField
            label={'Description'}
            value={organization.description}
          />
        </div>
        <div className='lockerManagerEdit__details-column--l fixed-width mr-30'>
          <InfoField
            label={'Landing Page'}
            value={(
              <div>
                <div>
                  {
                    organization.landingPageEnabled
                      ? <span className='status-label green'>{organizationLandingPageEnum.ON}</span>
                      : <span className='status-label red'>{organizationLandingPageEnum.OFF}</span>
                  }
                </div>
                <div>
                  {
                    organization.landingPageEnabled &&
                    <a href={`${storefrontRoot}/#/organizations/${organization.slug || organization.id}`}>{organization.name}</a>
                  }
                </div>
              </div>
            )}
          />
          <InfoField
            label={'Account Executive'}
            value={organization.accountManager}
          />
        </div>
      </div>

      <hr className='horizontal' />

      <div className='flex'>
        <div className='lockerManagerEdit__details-column--l fixed-width-l mr-50'>
          <InfoField
            tooltip={'Count of all orders for all lockers in organization for all time'}
            tooltipPlacement={'top-start'}
            label={'Total Orders'}
            value={(
              <div className='flex'>
                <span>{organizationTotals.totalOrders}</span>
                <OpenNewTabLink
                  url={orderSearchOrganizationUrl(organization.id, organizationLockers.map((l) => `L${l.id}`).join(','))}
                  text={'All Orders'}
                />
              </div>
            )}
          />
        </div>

        <div className='lockerManagerEdit__details-column--l fixed-width-l mr-50'>
          <InfoField
            tooltip={'Revenue for all lockers in organization for all time'}
            tooltipPlacement={'top-start'}
            label={'Total Revenue'}
            value={formatDollarAmount(organizationTotals.totalRevenue)}
          />
        </div>

        <div className='lockerManagerEdit__details-column--l fixed-width-l mr-50'>
          <InfoField
            tooltip={'Fundraising for all lockers in organization for all time'}
            tooltipPlacement={'top-start'}
            label={'Total Fundraising'}
            value={formatDollarAmount(organizationTotals.totalFundraising)}
          />
        </div>
      </div>
    </div>
  );
});

HeaderDetails.propTypes = {
  organization: PropTypes.object.isRequired,
  organizationTotals: PropTypes.object.isRequired,
  organizationLockers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
};

export default HeaderDetails;
