import React from 'react';
import PropTypes from 'prop-types';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

const DtgPersonalizationSettingsDeleteModal = React.memo(({
  isOpen,
  closeModal,
  selectedSetting,
  onDelete,
}) => (
  <SimpleConfirmationModal
    isOpen={isOpen}
    confirm={onDelete.bind(null, selectedSetting.id)}
    closeModal={closeModal}
    title={'Remove DTG Personalization Setting'}
    confirmationBody={`Are you sure you want to remove the DTG Personalization Setting ${selectedSetting.decorationLocationCode}/${selectedSetting.decorationSize}?`}
  />
));

DtgPersonalizationSettingsDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedSetting: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DtgPersonalizationSettingsDeleteModal;
