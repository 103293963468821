import { HagPrintRoomOrderDetailsDto } from '@api/fulfillment/models';
import { useGetHomefieldApiColors } from '@api/productCatalog/colors';
import Icon from '@sharedComponents/Icons/Icon';
import React, {
  useCallback,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { PrintableViewDownloadLink } from './PrintableView/PrintableViewDownloadLink';
import PrintRoomOrderActionButtons from './PrintRoomOrderActionButtons';

interface OwnProps {
  details: HagPrintRoomOrderDetailsDto | undefined | null;
  refetch: () => Promise<void>;
  openRejectModal: () => void;
  orderNumber: string;
}

type Props = OwnProps;

const PrintRoomOrderActions = React.memo<Props>(({
  refetch, openRejectModal, details, orderNumber,
}) => {
  const btnWrapper: React.FC = useCallback(({ children }) => (
    <li>
      {children}
    </li>
  ), []);

  const detailsArray = useMemo(() => details ? [details] : [], [details]);

  const { data: colors } = useGetHomefieldApiColors({ query: { enabled: true } });

  return (
    <div
      className='sheet__header'
      data-html2canvas-ignore={true}
    >
      <div className='sheet__header-left'>
        <Link
          to='/print-room'
          className='button button--link button--underline'
        >
          <span className='margin margin--right-s'>
            <Icon materialIcon={'keyboard_arrow_left'} />
          </span>
        Print Room
        </Link>
      </div>
      <ul className='sheet__header-right'>
        <li>
          <PrintableViewDownloadLink
            colors={colors}
            orders={detailsArray}
            fileName={details?.orderNumber ? `print-version_O${details.orderNumber}.pdf` : undefined}
          />
        </li>
        <li>
          <a
            onClick={openRejectModal}
            className='button button--link'
          >
            <span className='margin margin--right-s'>
              <Icon materialIcon={'clear'} />
            </span>
          Rework Artwork
          </a>
        </li>
        {!!details && details.hagPrintRoomJobId && details.status &&
          <PrintRoomOrderActionButtons
            hagPrintRoomJobId={details.hagPrintRoomJobId}
            printStatus={details.status}
            itemWrapper={btnWrapper}
            refetch={refetch}
            orderNumber={orderNumber}
          />
        }
      </ul>
    </div>
  );
});

export default PrintRoomOrderActions;
