export enum VoucherOrderCreationSteps {
  LockersAndOrganizations = 1,
  CollectionsAndRosters = 2,
  Configuration = 3,
}

export enum VoucherDetailsSidebarTabEnum {
  Notifications = 'Notifications',
  ChangeLog = 'ChangeLog',
}

export enum VoucherRefundTypeEnum {
  FullRefund = 0,
  PartialRefund = 1,
}
