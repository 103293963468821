import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { lockerMgrS3Logos } from '@constants/common';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';

const LockerRow = React.memo(({
  locker,
  checked,
  onCheck,
  disabled = false,
}) => (
  <div className={`organization__lockers-list__item ${checked ? 'active' : ''}`}>
    <div className='organization__lockers-list__item--logo'>
      <img src={`${lockerMgrS3Logos}/${locker.logoUrl}`} />
    </div>
    <div className='organization__lockers-list__item--column'>
      <Link
        className='link'
        to={lockerDetailsUrl(locker.id)}
      >
        L{locker.id}
      </Link>
    </div>
    <div className='organization__lockers-list__item--column'>{locker.teamName}</div>
    <div className='organization__lockers-list__item--checkbox'>
      <MaterialCheckbox
        text={''}
        checked={checked}
        onClick={onCheck.bind(null, locker)}
        disabled={disabled}
      />
    </div>
  </div>
));

LockerRow.propTypes = {
  locker: PropTypes.shape({
    id: PropTypes.number.isRequired,
    teamName: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
};

export default LockerRow;
