import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { flagTypeEnum } from '@constants/enums/commonEnums';
import Button from '@sharedComponents/Buttons/Button';

class FlagCell extends PureComponent {
  render() {
    const {
      item,
      openFlaggingModal,
      openUnflaggingModal,
      flagInfo,
      disabled,
    } = this.props;

    if (!flagInfo) {
      return (
        <Button
          type={'danger'}
          text={'Flag'}
          onClick={openFlaggingModal.bind(null, item)}
          disabled={disabled}
        />
      );
    }

    let text;
    switch (flagInfo.type) {
      case flagTypeEnum.Rework:
        text = 'In Rework';
        break;
      case flagTypeEnum.Reprint:
        text = 'In Reprint';
        break;
      case flagTypeEnum.WrongArtwork:
        text = 'Change Logo';
        break;
      default:
        text = 'Flagged';
    }

    const userText = flagInfo && flagInfo.user ? <strong className='flagging__actions-user'>{flagInfo.user}</strong> : null;

    return (
      <div>
        <a
          onClick={openUnflaggingModal.bind(null, item)}
          className='flagging__actions-button button'
        >
          <span>
            <div className='flagging__container'><i className='material-icons'>flag</i>{text}</div>
          </span>
        </a>
        {userText}
      </div>
    );
  }
}

FlagCell.propTypes = {
  item: PropTypes.object.isRequired,
  openFlaggingModal: PropTypes.func.isRequired,
  openUnflaggingModal: PropTypes.func.isRequired,
  flagInfo: PropTypes.shape({
    type: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
};

export default FlagCell;
