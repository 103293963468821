import React from 'react';
import PropTypes from 'prop-types';
import { formatDollarAmount } from '@util/numberHelpers';
import InfoField from '@sharedComponents/Display/InfoField';

const DiscountInfoField = React.memo(({ discount }) => (
  <InfoField
    label={'Discount'}
    value={formatDollarAmount(discount)}
  />
));

DiscountInfoField.propTypes = {
  discount: PropTypes.number,
};

export default DiscountInfoField;
