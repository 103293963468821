import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { organizationForm } from '@constants/reduxForms';
import { dateRangeEnum } from '@constants/enums/dateRangeEnum';
import {
  organizationsUrl,
  organizationGroupsUrl,
} from '@constants/clientUrls/clientUrls';
import {
  fetchOrganization,
  fetchOrganizationLockers,
  updateOrganization,
  deleteOrganization,
  associateLockersWithOrganization,
  getOrganizationTotals,
  updateAutoMerchOptIn,
} from '@redux/organizations/actions';
import { fetchLockerManagerTable } from '@redux/lockerManager/actions';
import { fetchAdmins } from '@redux/admins/actions';
import { fetchRostersTable } from '@redux/rosters/actions';
import {
  materialSwal,
  navigateToPage,
} from '@util/componentHelper';
import { extractParameterFromPath } from '@util/stringHelpers';
import BackLink from '@sharedComponents/Navigation/BackLink';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import Icon from '@sharedComponents/Icons/Icon';
import Tabs from '@sharedComponents/Tabs/Tabs';
import OrganizationEditModal from '../OrganizationModals/OrganizationEditModal';
import OrganizationDeleteModal from '../OrganizationModals/OrganizationDeleteModal';
import AssociateLockersModal from '../OrganizationModals/AssociateLockersModal';
import OrganizationDetailsHeader from './Header';
import AdminTab from './AdminTab';
import LockerTab from './LockerTab';
import RostersTab from './RostersTab';
import { fetchTeamColors } from '@redux/support/actions';
import ActionButton from '@components/shared/Buttons/ActionButton';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';
import { clearCacheImagesByOrganization } from '@api/squadlockerServices/logos';

const organizationDetailsTabEnum = {
  Admins: 'Admins',
  Lockers: 'Lockers',
  Rosters: 'Rosters',
};

class OrganizationDetails extends Component {
  state = {
    selectedTab: organizationDetailsTabEnum.Lockers,
    editOrganizationModalIsOpen: false,
    deleteOrganizationModalIsOpen: false,
    associateLockerModalIsOpen: false,
    isRefreshingAllLockers: false,
    refreshImageModalOpen: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath(this.props);

    if (organizationId !== null) {
      this.refreshData();
    }

    dispatch(fetchTeamColors());
  }

  componentDidUpdate(prevProps) {
    const organizationId = this.getOrganizationIdFromPath(this.props);
    const oldOrganizationId = this.getOrganizationIdFromPath(prevProps);

    if (oldOrganizationId !== organizationId && organizationId) {
      this.refreshData();
    }
  }

  getOrganizationIdFromPath = (props) => extractParameterFromPath(props, 'organizationId', 'number');

  refreshData = () => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath(this.props);

    dispatch(fetchOrganization(organizationId));
    dispatch(fetchOrganizationLockers(organizationId));
    dispatch(fetchAdmins(1, 0, '', '', dateRangeEnum.all.value, '', organizationId));
    dispatch(fetchLockerManagerTable(1, 10, '', '', '', false, '', '', organizationId));
    dispatch(fetchRostersTable(1, 0, null, null, null, null, organizationId));
    dispatch(getOrganizationTotals(organizationId));
  };

  editOrganization = async (editOrganizationForm) => {
    const { dispatch } = this.props;
    const res = await dispatch(updateOrganization(editOrganizationForm));

    if (res) {
      dispatch(fetchOrganization(editOrganizationForm.id));
      this.closeEditOrganizationsModal();
    }
  };

  deleteOrganization = async (organization) => {
    const { dispatch } = this.props;
    const res = await dispatch(deleteOrganization(organization.id));

    if (res?.success) {
      navigateToPage(organizationsUrl);
      materialSwal('Success', 'Organization has been successfully deleted.', 'success');
    }
  };

  selectTab = (selectedTab) => {
    this.setState(() => ({ selectedTab }));
  };

  openEditOrganizationsModal = () => {
    this.setState(() => ({ editOrganizationModalIsOpen: true }));
  };

  closeEditOrganizationsModal = () => {
    const { dispatch } = this.props;
    dispatch(reset(organizationForm));
    this.setState(() => ({ editOrganizationModalIsOpen: false }));
  };

  openDeleteOrganizationModal = () => {
    this.setState(() => ({ deleteOrganizationModalIsOpen: true }));
  };

  closeDeleteOrganizationModal = () => {
    this.setState(() => ({ deleteOrganizationModalIsOpen: false }));
  };

  openAssociateLockerModal = () => {
    this.setState(() => ({ associateLockerModalIsOpen: true }));
  };

  closeAssociateLockerModal = () => {
    this.setState(() => ({ associateLockerModalIsOpen: false }));
  };

  openRefreshImageModal = () => {
    if (this.state.isRefreshingAllLockers) return;
    this.setState(() => ({ refreshImageModalOpen: true }));
  };

  closeRefreshImageModal = () => {
    this.setState(() => ({ refreshImageModalOpen: false }));
  };

  refreshAllLockers = async (organizationId) => {
    this.closeRefreshImageModal();
    this.setState(() => ({ isRefreshingAllLockers: true }));

    try {
      await clearCacheImagesByOrganization(organizationId);
    } catch (err) {
      materialSwal('Error', err.message, 'error');
    }

    this.setState(() => ({ isRefreshingAllLockers: false }));
  };
  associateLockers = async (lockers) => {
    const { dispatch } = this.props;
    const organizationId = this.getOrganizationIdFromPath(this.props);

    const res = await dispatch(associateLockersWithOrganization(organizationId, lockers));

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.refreshData();
    }
  };

  handleToggleAutoMerchOptIn = () => {
    const { dispatch, allLockers } = this.props;
    const organizationId = this.getOrganizationIdFromPath(this.props);

    const optIn = allLockers.every((locker) => locker.optInAutomerch === false);
    const lockerIds = allLockers.map((locker) => locker.id);

    dispatch(updateAutoMerchOptIn(organizationId, lockerIds, optIn));
  };

  getTabs = () => {
    const {
      organization,
      totalLockers,
      totalAdmins,
      totalRosters,
    } = this.props;

    const organizationId = this.getOrganizationIdFromPath(this.props);

    const tabs = [
      {
        title: `Lockers (${totalLockers})`,
        name: organizationDetailsTabEnum.Lockers,
        content: <LockerTab organizationId={organizationId} />,
      },
      {
        title: `Admins (${totalAdmins})`,
        name: organizationDetailsTabEnum.Admins,
        content: (
          <AdminTab
            organizationId={organizationId}
            organizationDecisionMakerId={organization.lockerManagerId}
          />
        ),
      },
      {
        title: `Rosters (${totalRosters})`,
        name: organizationDetailsTabEnum.Rosters,
        content: <RostersTab organizationId={organizationId} />,
      },
    ];

    return tabs;
  };

  render() {
    const {
      selectedTab,
      editOrganizationModalIsOpen,
      deleteOrganizationModalIsOpen,
      associateLockerModalIsOpen,
      refreshImageModalOpen,
    } = this.state;

    const {
      organization,
      organizationTotals,
      allLockers,
      teamColors,
    } = this.props;

    const organizationId = this.getOrganizationIdFromPath(this.props);

    return (
      <div className='container'>
        <SimpleConfirmationModal
          isOpen={refreshImageModalOpen}
          confirm={() => this.refreshAllLockers(organizationId)}
          closeModal={this.closeRefreshImageModal}
          title={'Refresh Organization Images'}
          confirmationBody={'Are you sure you want to refresh all images for this organization?'}
        />
        <OrganizationEditModal
          isOpen={editOrganizationModalIsOpen}
          closeModal={this.closeEditOrganizationsModal}
          onSubmit={this.editOrganization}
          initialValues={organization}
        />
        <OrganizationDeleteModal
          isOpen={deleteOrganizationModalIsOpen}
          closeModal={this.closeDeleteOrganizationModal}
          deleteOrganization={this.deleteOrganization}
          organization={organization}
        />
        <AssociateLockersModal
          isOpen={associateLockerModalIsOpen}
          closeModal={this.closeAssociateLockerModal}
          associateLockers={this.associateLockers}
        />
        <div className='navigation'>
          <BackLink
            text={'Organization List'}
            url={organizationsUrl}
          />
          <div className='flex'>
            <div className='lockerManagerEdit__actions'>
              {
                !organization.deleted &&
                <button
                  onClick={this.openDeleteOrganizationModal}
                  className='btn-borderless btn-borderless--red has-icon size--s'
                >
                  <Icon materialIcon={'block'} />
                  <span>Delete organization</span>
                </button>
              }
              <ButtonLink
                type={'secondary'}
                text={'Manage Landing Page'}
                linkTo={organizationGroupsUrl(organizationId)}
                linkClasses={'ml-20'}
              />
            </div>
            <ActionButton
              text='Organization Actions'
              classes='ml-10'
              actions={[
                {
                  text: 'Associate New Locker',
                  icon: 'file_copy',
                  action: this.openAssociateLockerModal,
                },
                {
                  text: 'Refresh Product Images',
                  icon: 'style',
                  action: this.openRefreshImageModal,
                  isVisible: this.isRefreshingAllLockers,
                },
              ]}
            />
          </div>
        </div>
        <OrganizationDetailsHeader
          organization={organization}
          organizationTotals={organizationTotals}
          organizationLockers={allLockers}
          openEditOrganizationsModal={this.openEditOrganizationsModal}
          teamColors={teamColors}
          onToggleAutoMerchOptIn={this.handleToggleAutoMerchOptIn}
        />
        <div>
          <Tabs
            tabs={this.getTabs()}
            selectedTab={selectedTab}
            selectTab={this.selectTab}
            classes={'tabs--size-l'}
          />
        </div>
      </div>
    );
  }
}

OrganizationDetails.propTypes = {
  organization: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
    }).isRequired,
  }),
  totalAdmins: PropTypes.number.isRequired,
  totalLockers: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  totalRosters: PropTypes.number.isRequired,
  allLockers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      teamName: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
    })
  ).isRequired,
  teamColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hexValue: PropTypes.string.isRequired,
    brightness: PropTypes.number,
  })).isRequired,
};

const mapStateToProps = ({
  organizations,
  admins,
  lockerManager,
  rosters,
  support,
}) => ({
  organization: organizations.organization,
  organizationTotals: organizations.organizationTotals,
  totalAdmins: admins.adminsList.totalCount,
  totalLockers: lockerManager.currentQueue.totalCount,
  totalRosters: rosters.rostersQueue.totalCount,
  allLockers: organizations.organizationLockers,
  teamColors: support.teamColors,
});

export default connect(mapStateToProps)(OrganizationDetails);
