import React from 'react';
import Button from '@sharedComponents/Buttons/Button';

interface OwnProps {
  openAddNewPtlWallModal: () => void;
}

type Props = OwnProps;

const EmptyPtlWall = React.memo<Props>(({ openAddNewPtlWallModal }) => (
  <div className='empty-ptl-wall-page'>
    <h3 className='mb-20 font-weight-normal'>There are no active walls available.</h3>
    <div className='flex button-container'>
      <Button
        type={'primary'}
        text={'Add New Wall'}
        onClick={openAddNewPtlWallModal}
      />
    </div>
  </div>
));

export default EmptyPtlWall;
