/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type OrderStatusEnum = 'Initial' | 'ArtworkTasksProcessing' | 'ArtworkTasksCompleted' | 'OnHold' | 'AwaitingDispatchForPicking' | 'PartialyInPicking' | 'InPicking' | 'Picked' | 'Assembled' | 'ProductionCompleted' | 'ShippingReady' | 'Shipped';


export const OrderStatusEnum = {
  Initial: 'Initial' as OrderStatusEnum,
  ArtworkTasksProcessing: 'ArtworkTasksProcessing' as OrderStatusEnum,
  ArtworkTasksCompleted: 'ArtworkTasksCompleted' as OrderStatusEnum,
  OnHold: 'OnHold' as OrderStatusEnum,
  AwaitingDispatchForPicking: 'AwaitingDispatchForPicking' as OrderStatusEnum,
  PartialyInPicking: 'PartialyInPicking' as OrderStatusEnum,
  InPicking: 'InPicking' as OrderStatusEnum,
  Picked: 'Picked' as OrderStatusEnum,
  Assembled: 'Assembled' as OrderStatusEnum,
  ProductionCompleted: 'ProductionCompleted' as OrderStatusEnum,
  ShippingReady: 'ShippingReady' as OrderStatusEnum,
  Shipped: 'Shipped' as OrderStatusEnum,
};
