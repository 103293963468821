import deviceUrls from '@constants/sqdApiUrls/deviceUrls';
import { fulfillmentApi } from '../../sqdApis';
import { makeApiCallWithErrorModal } from '@util/apiHelper';
import { isDefined } from '@util/valueHelpers';

export const getDeviceItemMappings = async () => {
  const call = fulfillmentApi.get(deviceUrls.devices);

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const associateItemsToDevice = async (model) => {
  const deviceAssociations = model.deviceAssociations.filter((item) => isDefined(item.itemCode));
  const body = JSON.stringify({
    deviceAssociations,
  });

  const call = fulfillmentApi.post(deviceUrls.associateItemsToDevice, {
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const res = await makeApiCallWithErrorModal(call);

  return res;
};

export const removeItemAssociation = async (itemCode) => {
  const call = fulfillmentApi.delete(deviceUrls.removeItemAssociationFromDevice(itemCode));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
