import React from 'react';
import PropTypes from 'prop-types';
import { getRecipientAddress } from '@util/componentHelpers/fundraisingPayoutsHelper';
import InfoField from '@sharedComponents/Display/InfoField';

const FundraisingPayoutsRecipientDetails = React.memo(({ recipient }) => {
  if (!recipient) return null;

  const address = getRecipientAddress(recipient);

  return (
    <div className='tableView__details--content-container'>
      <InfoField
        label={'Recipient Name'}
        value={recipient.name}
      />
      <InfoField
        label={'Recipient E-mail'}
        value={recipient.email}
      />
      <InfoField
        label={'Recipient Address'}
        value={address}
      />
      <InfoField
        label={'Vendor ID'}
        value={recipient.gpDocumentId}
      />
    </div>
  );
});

FundraisingPayoutsRecipientDetails.propTypes = {
  recipient: PropTypes.object,
};

export default FundraisingPayoutsRecipientDetails;
