import React from 'react';
import MenuTile from '../MyApps/MenuTile';
import { useGetHomefieldApiMetaStores } from '@api/reportEngine/meta';
import { reportSelectionUrl } from '@constants/clientUrls/clientUrls';

const ReportsMain = React.memo(() => {
  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetHomefieldApiMetaStores({
    query: {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  });

  const stores = React.useMemo(() => data?.stores ?? [], [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error!!! {error}</div>;
  }

  return (
    <div className='my-apps-tray w-100'>
      {stores.map((store) => (
        <MenuTile
          key={store.storeName!}
          title={`${store.storeName} (${store.reportCount ?? 0})`}
          url={reportSelectionUrl(store.storeName!)}
          icon={store.isAvailable ? 'summarize' : 'error'}
        />
      ))}
    </div>
  );
});

export default ReportsMain;
