import { buildQueryString } from '@util/stringHelpers';

export const collections = 'homefield/api/collections' as const;

export const collectionsTable = (
  pageNumber: number,
  pageSize: number,
  sortColumn: string | null = null,
  sortDirection: string | null = null,
  search: string | null = null,
  lockerId: number | null = null
): string => `${collections}${buildQueryString({
  pageNumber,
  pageSize,
  sortColumn,
  sortDirection,
  lockerId,
  search,
})}`;

export const deleteCollections = `${collections}.delete` as const;
export const reorderCollections = `${collections}.reorder` as const;

export const collection = (collectionId: number): string => `${collections}/${collectionId}`;

export const collectionItems = (collectionId: number): string => `${collection(collectionId)}/items`;

export const removeCollectionItems = (collectionId: number): string => `${collectionItems(collectionId)}.remove`;
export const reorderCollectionItems = (collectionId: number): string => `${collectionItems(collectionId)}.reorder`;

export default {
  collections,
  collectionsTable,
  collection,
  reorderCollections,
  deleteCollections,
  collectionItems,
  removeCollectionItems,
  reorderCollectionItems,
};
