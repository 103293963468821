import React, { PureComponent } from 'react';
import { lockerDetailsUrl } from '@constants/clientUrls/clientUrls';
import {
  materialSwal,
  navigateToPage,
} from '@util/componentHelper';
import { extractParameterFromPath } from '@util/stringHelpers';
import { toggleItemSelection } from '@util/selectionHelpers';
import { addProductsToLocker } from '@APICalls/lockerManager/actions';
import { fetchLockerInfo } from '@redux/lockerManager/actions';
import Button from '@sharedComponents/Buttons/Button';
import BackLink from '@sharedComponents/Navigation/BackLink';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';
import LockerManagerAddProducts from './LockerManagerAddProducts';

class LockerManagerAddProductsPage extends PureComponent {
  state = {
    selectedProducts: [],
    itemInfoModalIsOpen: false,
    clickedProduct: {},
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');
    dispatch(fetchLockerInfo(lockerId));
  }

  addProduct = async () => {
    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');
    const { selectedProducts } = this.state;

    const csIds = selectedProducts.map((p) => p.id);
    const res = await addProductsToLocker(lockerId, csIds);
    if (res?.success) {
      await materialSwal('Success', res.message, 'success', lockerDetailsUrl(lockerId));
      navigateToPage(lockerDetailsUrl(lockerId));
    }
  };

  clickProduct = (product, e) => {
    e.stopPropagation();

    this.setState({ clickedProduct: product });
    this.openItemInfoModal();
  };

  selectProduct = (product) => {
    const { selectedProducts } = this.state;

    this.setState({
      selectedProducts: toggleItemSelection(selectedProducts, product, 'id'),
    });
  };

  unselectProduct = (product) => {
    const { selectedProducts } = this.state;

    this.setState({
      selectedProducts: selectedProducts.filter((p) => p.id !== product.id),
    });
  };

  removeAllProducts = () => {
    this.setState({ selectedProducts: [] });
  };

  openItemInfoModal = () => {
    this.setState({ itemInfoModalIsOpen: true });
  };

  closeItemInfoModal = () => {
    this.setState({ itemInfoModalIsOpen: false });
  };

  render() {
    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');

    const {
      selectedProducts,
      clickedProduct,
      itemInfoModalIsOpen,
    } = this.state;

    const selectedProductCount = selectedProducts.length;

    return (
      <div>
        <ItemInfoModal
          isOpen={itemInfoModalIsOpen}
          closeModal={this.closeItemInfoModal}
          coloredStyleItemId={clickedProduct.id}
        />
        <div className='add-products-page__row'>
          <BackLink
            text={`Locker L${lockerId}`}
            url={lockerDetailsUrl(lockerId)}
          />
          <Button
            type={'primary'}
            text={`Add (${selectedProductCount}) selected items`}
            onClick={this.addProduct}
          />
        </div>
        <LockerManagerAddProducts
          lockerId={lockerId}
          removeAllProducts={this.removeAllProducts}
          unselectProduct={this.unselectProduct}
          selectProduct={this.selectProduct}
          clickProduct={this.clickProduct}
          selectedProducts={selectedProducts}
        />
      </div>
    );
  }
}

export default LockerManagerAddProductsPage;
