import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchNotificationHistory } from '@redux/backorderManagement/actions';
import BackorderManagementNotification from './BackorderManagementNotification';

class BackorderManagementNotificationHistory extends PureComponent {
  componentDidMount() {
    const {
      order,
      dispatch,
    } = this.props;

    dispatch(fetchNotificationHistory(order.orderNumber));
  }

  componentDidUpdate(prevProps) {
    const {
      order,
      dispatch,
    } = this.props;

    if (order && prevProps.order !== order) {
      dispatch(fetchNotificationHistory(order.orderNumber));
    }
  }

  render() {
    const {
      notificationHistory,
      order,
    } = this.props;

    return (
      <div className='order__items backorder-management__sidebar__tab-content--notifications custom-scrollbar'>
        {
          notificationHistory.map((notification, index) => (
            <BackorderManagementNotification
              key={index}
              notification={notification}
              totalItems={order.backorderedItemIds.length}
            />
          ))
        }
      </div>
    );
  }
}

BackorderManagementNotificationHistory.propTypes = {
  order: PropTypes.shape({
    orderNumber: PropTypes.number.isRequired,
    backorderedItemIds: PropTypes.number.isRequired,
  }),
  notificationHistory: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.string,
    date: PropTypes.string,
    message: PropTypes.string,
  })).isRequired,
};

const mapStateToProps = ({ backorderManagement }) => ({
  notificationHistory: backorderManagement.notificationHistory,
});

export default connect(mapStateToProps)(BackorderManagementNotificationHistory);
