import { featureFlags } from '@constants/common';
import {
  accountingActionTypeEnum,
  couponTypeEnum,
} from '@constants/enums/orderEnums';
import {
  formatDollarAmount,
  formatPercentageAmount,
  formatNumber,
} from '@util/numberHelpers';

export const canRefundOrder = (
  isOrderCompleteRefund: boolean
): boolean => (featureFlags.orderRefundEnabled && !isOrderCompleteRefund);

export const canRefundOrderItem = (
  isOrderCompleteRefund: boolean,
  isOrderItemCompleteRefund: boolean,
  hasVoucher: boolean = false
): boolean => (
  featureFlags.orderItemRefundEnabled
  && !isOrderCompleteRefund
  && !isOrderItemCompleteRefund
  && !hasVoucher
);

export const canRefundOrderShipping = (isOrderCompleteRefund: boolean, shippingAmount: number): boolean => (
  canRefundOrder(isOrderCompleteRefund) && shippingAmount > 0
);

export const getOrderActionType = (isOrderCompleteRefund: boolean): (keyof typeof accountingActionTypeEnum) => (
  canRefundOrder(isOrderCompleteRefund)
    ? accountingActionTypeEnum.OrderFullRefund
    : accountingActionTypeEnum.NoChange
);

export const getOrderItemActionType = (
  isOrderCompleteRefund: boolean,
  isOrderItemCompleteRefund: boolean,
  hasVoucher: boolean = false
): (keyof typeof accountingActionTypeEnum
  ) => (
  canRefundOrderItem(isOrderCompleteRefund, isOrderItemCompleteRefund, hasVoucher)
    ? accountingActionTypeEnum.OrderItemFullRefund
    : accountingActionTypeEnum.NoChange
);

export const getPersonalizationActionType = (
  isOrderCompleteRefund: boolean,
  isOrderItemCompleteRefund: boolean,
  hasPricePaid: boolean
): (keyof typeof accountingActionTypeEnum
  ) => (
  canRefundOrderItem(isOrderCompleteRefund, isOrderItemCompleteRefund) && hasPricePaid
    ? accountingActionTypeEnum.PersonalizationRefund
    : accountingActionTypeEnum.NoChange
);

export const getOrderCouponValueString = (
  orderCouponType: keyof typeof couponTypeEnum,
  couponValue: number
): string => (
  orderCouponType === couponTypeEnum.Amount ? formatDollarAmount(couponValue) : formatPercentageAmount(couponValue)
);

export const getOrderSubtotal = (
  order: {
    items?: {
      replaced?: string | null;
      taxAmount?: number | null;
      quantity?: number | null;
      pretaxPrice?: number | null;
      shipmentNumber?: number | null;
      pricePaid?: number | null;
    }[] | null;
  },
  unshippedOnly = false,
  pretaxPrice = false
): number => {
  let total = 0;

  order.items?.forEach((item) => {
    if ((!unshippedOnly || (unshippedOnly && !item.shipmentNumber)) && !item.replaced) {
      if (pretaxPrice) {
        total += ((item.pretaxPrice!) * item.quantity!) || 0;
      } else {
        total += ((item.pricePaid!) * item.quantity!) || 0;
      }
    }
  });

  return total;
};

export const getOrderSubtotalRefundAmountLeft = (order: {
  tradeDiscount?: number | null;
  items?: {
    replaced?: string | null;
    taxAmount?: number | null;
    quantity?: number | null;
    pretaxPrice?: number | null;
    shipmentNumber?: number | null;
    pricePaid?: number | null;
  }[] | null;
}): number => {
  let refundAmountLeft = 0;

  refundAmountLeft += getOrderSubtotal(order, false, false);
  refundAmountLeft -= order.tradeDiscount!;

  return parseFloat(formatNumber(refundAmountLeft));
};

export const getOrderSubtotalTax = (order: {
  items: {
    replaced: string;
    taxAmount: number;
    quantity: number;
  }[];
}): number => {
  let taxAmount = 0;

  order.items.forEach((item) => {
    if (!item.replaced) {
      taxAmount += (item.taxAmount) * item.quantity;
    }
  });

  return taxAmount;
};

export const getOrderDiscountAmount = (order: {
  markdown?: number | null;
  markdownTax?: number | null;
  tradeDiscount?: number | null;
  tradeDiscountTax?: number | null;
  shippingRefundAmount?: number | null;
  shippingRefundTaxAmount?: number | null;
  shippingCouponDiscount?: number | null;
  shippingCouponDiscountTax?: number | null;
}): number => {
  const {
    markdown,
    markdownTax,
    tradeDiscount,
    tradeDiscountTax,
    shippingRefundAmount,
    shippingRefundTaxAmount,
    shippingCouponDiscount,
    shippingCouponDiscountTax,
  } = order;

  return markdown! + tradeDiscount! + tradeDiscountTax! + markdownTax! + shippingRefundAmount!
    + shippingRefundTaxAmount! + shippingCouponDiscount! + shippingCouponDiscountTax!;
};

export const getOrderChargeAmount = (order: {
  subtotalChargeAmount?: number | null;
  subtotalChargeTaxAmount?: number | null;
  shippingChargeAmount?: number | null;
  shippingChargeTaxAmount?: number | null;
}): number => {
  const {
    subtotalChargeAmount,
    subtotalChargeTaxAmount,
    shippingChargeAmount,
    shippingChargeTaxAmount,
  } = order;

  return subtotalChargeAmount! + subtotalChargeTaxAmount! + shippingChargeAmount! + shippingChargeTaxAmount!;
};
