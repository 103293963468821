import React from 'react';
import PropTypes from 'prop-types';

const SkuSetupStyleName = React.memo(({
  name,
  code,
  image,
}) => (
  <div className='sku-setup__header-name'>
    <div className='order__item-column align__center mr-10'>
      <div
        className='order__item-thumbnail'
        style={{ backgroundImage: `url("${image}")` }}
      />
    </div>
    {`${code} - ${name}`}
  </div>
));

SkuSetupStyleName.propTypes = {
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default SkuSetupStyleName;
