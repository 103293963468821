import React from 'react';
import { rosterManagerUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const RosterManagerLink = React.memo(() => (
  <MenuTile
    title={'Roster Manager'}
    url={rosterManagerUrl}
    icon={'format_list_numbered_rtl'}
  />
));

export default RosterManagerLink;
