import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TableEnum } from '@constants/enums/tableEnums';
import { sortDirectionEnum } from '@constants/enums/commonEnums';
import {
  defaultPageSizeSmallTable,
  pageSizeOptionsSmallTable,
} from '@constants/values';
import {
  getPagingParamsFromTable,
  getSortParamsFromTable,
  Column,
  CellInfo,
} from '@util/tableHelpers';
import {
  parseDateNumeric,
  parseDateTimeNumeric,
} from '@util/dateHandler';
import HeaderCell from '@sharedComponents/Table/TableCells/HeaderCell';
import Table from '@sharedComponents/Table/Table';
import TableBulkActions from '@sharedComponents/Table/TableComponents/TableBulkActions';
import IdWithIconsCell from '@components/PrintRoomNew/PrintRoomTableContent/TableCells/IdWithIconsCell';
import DtfPrinterButton from '@components/PrintRoomNew/PrintRoomTableContent/DtfPrinterButton';
import PrintRoomNewTableTabs from './PrintRoomTableContent/Tabs/PrintRoomNewTableTabs';
import DtfPrinterModal from '@components/PrintRoomNew/PrintRoomTableContent/DtfPrinterModal';
import { SortDirectionEnum } from '@api/financialServices/models';
import { materialSwal } from '@util/componentHelper';
import {
  useGetHomefieldApiHagPrintroom,
  useGetHomefieldApiHagPrintroomcountstatuses,
  postHomefieldApiHagTransmissionssendprintroomjob as moveToInPrint,
  putHomefieldApiHagPrintroomprogressjob as moveJobToStatus,
  putHomefieldApiHagPrintroomclaimorder as claimJob,
} from '@api/fulfillment/hag';
import {
  ApiResponse,
  HagPrintRoomJobPagedListDto,
  HagPrintRoomJobStatusEnum, HagPrintRoomJobTypeEnum, OrderInventoryStatusEnum, PrintRoomTabEnum, QueriesSortByEnum,
} from '@api/fulfillment/models';
import { SortDirectionLong } from '@customTypes/table';
import ProgressModal, { ProgressModalOnCompleteCallbackArgType } from '@components/shared/Modal/ProgressModal';
import UpdatePrintJobStatus from './PrintRoomTableContent/UpdatePrintJobStatus';
import UpdateBulkPrintJobStatus from './PrintRoomTableContent/UpdateBulkPrintJobStatus';
import UpdateBulkMarkInPrint from './PrintRoomTableContent/UpdateBulkMarkInPrint';
import PrintRoomPreviewCell from './PrintRoomTableContent/PrintRoomPreview/PrintRoomPreviewCell';
import PriorityNoteButton from './PrintRoomTableContent/PriorityNoteButton';
import DownloadBulkPrintableViews from './PrintRoomTableContent/DownloadBulkPrintableViews';
import UpdateBulkClaimedBy from './PrintRoomTableContent/UpdateBulkClaimedBy';
import { parsePrintStatus } from '@util/componentHelpers/printStatusHelper';

const PrintFilesTable = Table<HagPrintRoomJobPagedListDto>();

interface OwnProps {
  inventoryStatus?: OrderInventoryStatusEnum;
  isRush?: boolean;
  isExpressProduction?: boolean;
  isStandardProduction?: boolean;
  lockerId?: number;
  organizationId?: number;
  shipFrom?: string;
  shipTo?: string;
  selectTab: (newSelectedTab: PrintRoomTabEnum) => void;
  selectedTab: PrintRoomTabEnum;
  claimedByFilter?: string;
  printerNumberFilter?: number;
  recentlyPrinted: (items: HagPrintRoomJobPagedListDto[]) => void;
  onPageNumberChanged: (pageNumber: number) => void;
  pageNumber: number;
  jobStatuses: Array<HagPrintRoomJobStatusEnum>;
}

const errorMessage = 'An error occurred when sending to print.' as const;
const elipsis = '...' as const;
type ProgressModalMarkAsPrintingPayloadType = { printJobId: number; printerNumber: number; };
type ProgressModalJobStatusPayloadType = { printJobId: number; toStatus: HagPrintRoomJobStatusEnum; };
type ProgressModalClaimedByPayloadType = { printJobId: number; };
type Props = OwnProps;

const PrintRoomNewTable = React.memo<Props>(({
  inventoryStatus,
  isRush,
  isExpressProduction,
  isStandardProduction,
  lockerId,
  organizationId,
  shipFrom,
  shipTo,
  selectTab,
  selectedTab,
  claimedByFilter,
  printerNumberFilter,
  recentlyPrinted,
  onPageNumberChanged,
  pageNumber,
  jobStatuses,
}) => {
  const [
    selectedItems,
    setSelectedItems,
  ] = useState<Array<HagPrintRoomJobPagedListDto>>([]);
  const [
    dftJobIds,
    setDtfJobIds,
  ] = useState<number[]>([]);
  const [
    jobType,
    setJobType,
  ] = useState<HagPrintRoomJobTypeEnum>(HagPrintRoomJobTypeEnum.WholeOrder);
  const [
    queue,
    setQueue,
  ] = useState<Array<HagPrintRoomJobPagedListDto>>([]);
  const [
    totalPages,
    setTotalPages,
  ] = useState<number>(1);
  const [
    hasNextPage,
    setHasNextPage,
  ] = useState<boolean>(false);
  const [
    hasPreviousPage,
    setHasPreviousPage,
  ] = useState<boolean>(false);
  const [
    totalCount,
    setTotalCount,
  ] = useState<number>(0);
  const [
    pageSize,
    setPageSize,
  ] = useState<number>(0);
  const [
    sortBy,
    setSortBy,
  ] = useState<QueriesSortByEnum>(QueriesSortByEnum.ShipDate);
  const [
    sortDirection,
    setSortDirection,
  ] = useState<SortDirectionEnum>(SortDirectionEnum.Ascending);
  const [
    sortColumnString,
    setSortColumnString,
  ] = useState<string>();
  const [
    sortDirectionString,
    setSortDirectionString,
  ] = useState<SortDirectionLong>();
  const [
    selectedLogosCount,
    setSelectedLogosCount,
  ] = useState<number>(0);
  const [
    isPageSelected,
    setIsPageSelected,
  ] = useState<boolean>(false);
  const [
    markAsPrintingPayload,
    setMarkAsPrintingPayload,
  ] = useState<Array<ProgressModalMarkAsPrintingPayloadType>>([]);
  const [
    jobStatusPayload,
    setJobStatusPayload,
  ] = useState<Array<ProgressModalJobStatusPayloadType>>([]);
  const [
    claimedByPayload,
    setClaimedByPayload,
  ] = useState<Array<ProgressModalClaimedByPayloadType>>([]);

  const { printRoomEnableDtf } = useFlags();

  const {
    data: printReadyOrdersPagedList,
    refetch: fetchPrintReadyOrdersPagedList,
  } = useGetHomefieldApiHagPrintroom({
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    jobType,
    jobStatuses,
    isRush,
    isExpressProduction,
    isStandardProduction,
    inventoryStatus,
    lockerId,
    organizationId,
    shipFrom,
    shipTo,
    claimedBy: claimedByFilter,
    printerNumber: printerNumberFilter,
    printRoomTab: selectedTab,
  });

  const {
    data: allPrintedData,
    refetch: fetchAllPrintedData,
  } = useGetHomefieldApiHagPrintroom({
    sortBy,
    sortDirection,
    jobType,
    jobStatuses,
    isRush,
    isExpressProduction,
    inventoryStatus,
    lockerId,
    organizationId,
    shipFrom,
    shipTo,
    claimedBy: claimedByFilter,
    printerNumber: printerNumberFilter,
    returnAllJobs: true,
    printRoomTab: selectedTab,
  });

  useEffect(() => {
    if (selectedTab === PrintRoomTabEnum.Printed) {
      fetchAllPrintedData();
    }
  }, [
    fetchAllPrintedData,
    selectedTab,
    isExpressProduction,
    isStandardProduction,
    isRush,
    inventoryStatus,
    lockerId,
    organizationId,
    shipFrom,
    shipTo,
    sortDirection,
    sortBy,
    claimedByFilter,
    printerNumberFilter,
    jobStatuses,
  ]);

  useEffect(() => {
    if (allPrintedData?.items) {
      recentlyPrinted(allPrintedData?.items);
    }
  }, [
    allPrintedData,
    recentlyPrinted,
  ]);

  useEffect(() => {
    if (printReadyOrdersPagedList?.items) {
      setQueue(printReadyOrdersPagedList?.items);
    }
    if (printReadyOrdersPagedList?.totalPages) {
      setTotalPages(printReadyOrdersPagedList?.totalPages);
    }
    if (printReadyOrdersPagedList?.totalCount) {
      setTotalCount(printReadyOrdersPagedList?.totalCount);
    }
    if (printReadyOrdersPagedList?.hasNextPage) {
      setHasNextPage(printReadyOrdersPagedList?.hasNextPage);
    }
    if (printReadyOrdersPagedList?.hasPreviousPage) {
      setHasPreviousPage(printReadyOrdersPagedList?.hasPreviousPage);
    }
    if (printReadyOrdersPagedList?.pageNumber) {
      onPageNumberChanged(printReadyOrdersPagedList?.pageNumber);
    }
    if (printReadyOrdersPagedList?.pageSize) {
      setPageSize(printReadyOrdersPagedList?.pageSize);
    }
  }, [
    printReadyOrdersPagedList,
    onPageNumberChanged,
  ]);

  const fetchData = useCallback((state, instance) => {
    const {
      page: newPageNumber,
      pageSize: newPageSize,
    } = getPagingParamsFromTable(instance);

    const {
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    } = getSortParamsFromTable(instance, sortDirectionEnum, sortColumnString, sortDirectionString);

    if (newSortColumn.toLowerCase() === 'orderid') {
      setSortBy(QueriesSortByEnum.OrderNumber);
    }
    if (newSortColumn.toLowerCase() === 'decorationcount') {
      setSortBy(QueriesSortByEnum.LogoCount);
    }
    if (newSortColumn.toLowerCase() === 'receiveddate') {
      setSortBy(QueriesSortByEnum.ReceivedOn);
    }
    if (newSortColumn.toLowerCase() === 'shipdate') {
      setSortBy(QueriesSortByEnum.ShipDate);
    }
    if (newSortColumn.toLowerCase() === 'printingby') {
      setSortBy(QueriesSortByEnum.PrintingBy);
    }
    if (newSortColumn.toLowerCase() === 'printingdate') {
      setSortBy(QueriesSortByEnum.PrintingOn);
    }
    if (newSortColumn.toLowerCase() === 'printeddate') {
      setSortBy(QueriesSortByEnum.PrintedOn);
    }
    if (newSortColumn.toLowerCase() === 'printedby') {
      setSortBy(QueriesSortByEnum.PrintedBy);
    }
    if (newSortColumn.toLowerCase() === 'claimedby') {
      setSortBy(QueriesSortByEnum.ClaimedBy);
    }
    if (newSortColumn.toLowerCase() === 'status') {
      setSortBy(QueriesSortByEnum.PrintStatus);
    }

    if (newSortDirection.toLowerCase() === 'ascending') {
      setSortDirection(SortDirectionEnum.Ascending);
    }
    if (newSortDirection.toLowerCase() === 'descending') {
      setSortDirection(SortDirectionEnum.Descending);
    }

    setSortColumnString(newSortColumn);
    setSortDirectionString(newSortDirection);
    onPageNumberChanged(newPageNumber + 1);
    setPageSize(newPageSize);
  }, [
    sortColumnString,
    sortDirectionString,
    onPageNumberChanged,
  ]);

  useEffect(() => {
    fetchPrintReadyOrdersPagedList();
  }, [
    fetchPrintReadyOrdersPagedList,
    pageNumber,
    pageSize,
    isExpressProduction,
    isStandardProduction,
    isRush,
    inventoryStatus,
    lockerId,
    organizationId,
    shipFrom,
    shipTo,
    sortDirection,
    sortBy,
    selectedTab,
    claimedByFilter,
    printerNumberFilter,
    jobStatuses,
  ]);

  const {
    data: printRoomStatusCounts,
    refetch: fetchPrintRoomStatusCounts,
  } = useGetHomefieldApiHagPrintroomcountstatuses();

  useEffect(() => {
    fetchPrintRoomStatusCounts();
  }, [fetchPrintRoomStatusCounts]);

  const openDftSheetModel = useCallback((jobIds: number[]) => setDtfJobIds(jobIds), [setDtfJobIds]);
  const closeDftSheetModel = useCallback(() => setDtfJobIds([]), [setDtfJobIds]);

  const handleDtfPrintByJobId = useCallback(async (jobIds: number[], printerNumber: number) => {
    const payload: ProgressModalMarkAsPrintingPayloadType[] = [];
    for (const printJobId of jobIds) {
      payload.push({
        printJobId,
        printerNumber,
      });
    }
    setMarkAsPrintingPayload(payload);
  }, []);

  const handleBulkMovePrintsToStatus = useCallback((jobIds: number[], toStatus: HagPrintRoomJobStatusEnum) => {
    const payload: ProgressModalJobStatusPayloadType[] = [];
    for (const printJobId of jobIds) {
      payload.push({
        printJobId,
        toStatus,
      });
    }
    setJobStatusPayload(payload);
  }, []);

  const handleBulkClaimedBy = useCallback((jobIds: number[]) => {
    const payload: ProgressModalClaimedByPayloadType[] = [];
    for (const printJobId of jobIds) {
      payload.push({
        printJobId,
      });
    }
    setClaimedByPayload(payload);
  }, []);

  const onPrintByJobId =
    useCallback((req: { printJobId: number; printerNumber: number; }) => moveToInPrint({
      hagPrintRoomJobId: req.printJobId,
      printerNumber: req.printerNumber,
    }, {
      isBlockingRequest: false,
      showErrorModal: false,
    }), []);

  const onPrintsCompletedOrCanceled = useCallback(
    (res?: ProgressModalOnCompleteCallbackArgType<ProgressModalMarkAsPrintingPayloadType, ApiResponse>) => {
      setMarkAsPrintingPayload([]);
      if (res) {
        if (res.successes < res.total) {
          const errs = [];
          for (const result of res.results) {
            if (!result.success) {
              const msgLen = errorMessage.length - elipsis.length;
              let message = result.response?.substring(0, msgLen);
              if (result.response?.includes(errorMessage)) {
                message = errorMessage;
              } else if (result.response?.length > msgLen) {
                message += elipsis;
              }
              errs.push(`O${result.request}: ${message}`);
            }
          }
          const errMsg = `${res.total - res.successes} out of ${res.total} of the prints failed!\n${errs.join('\n')}`;
          materialSwal('Print Failures', errMsg, 'error');
        } else {
          materialSwal('Print Successful', `${res.total} prints succeeded`, 'success');
        }
      }
      fetchPrintRoomStatusCounts();
      fetchPrintReadyOrdersPagedList();
      setSelectedItems([]);
    }, [
      fetchPrintRoomStatusCounts,
      fetchPrintReadyOrdersPagedList,
    ]
  );

  const getLogoCountMessage = useCallback((): Nullable<string> => {
    if (selectedTab === PrintRoomTabEnum.Priority) {
      return null;
    }

    return ` (${selectedLogosCount} logos)`;
  }, [
    selectedTab,
    selectedLogosCount,
  ]);

  const resetFilters = useCallback(() => {
    setPageSize(0);
    onPageNumberChanged(1);
    setHasNextPage(false);
    setHasPreviousPage(false);
    setTotalPages(1);
    setTotalCount(0);
  }, [onPageNumberChanged]);

  const updateSelection = useCallback((
    newSelectedItems: Array<HagPrintRoomJobPagedListDto>,
    newIsPageSelected: boolean
  ) => {
    setSelectedItems(newSelectedItems);
    setIsPageSelected(newIsPageSelected);

    let newSelectedLogosCount = 0;
    for (const item of newSelectedItems) {
      newSelectedLogosCount += item.decorationCount ?? 0;
    }

    setSelectedLogosCount(newSelectedLogosCount);
  }, []);

  const clearSelection = useCallback(() => {
    updateSelection([], false);
  }, [updateSelection]);

  const handleSelectTab = useCallback((newSelectedTab: PrintRoomTabEnum) => {
    if (newSelectedTab !== selectedTab) {
      if (newSelectedTab === PrintRoomTabEnum.PrintReady) {
        setJobType(HagPrintRoomJobTypeEnum.WholeOrder);
        setSortBy(QueriesSortByEnum.ShipDate);
        setSortDirection(SortDirectionEnum.Ascending);
      }
      if (newSelectedTab === PrintRoomTabEnum.Printing) {
        setJobType(HagPrintRoomJobTypeEnum.WholeOrder);
        setSortBy(QueriesSortByEnum.ShipDate);
        setSortDirection(SortDirectionEnum.Ascending);
      }
      if (newSelectedTab === PrintRoomTabEnum.Printed) {
        setJobType(HagPrintRoomJobTypeEnum.WholeOrder);
        setSortBy(QueriesSortByEnum.PrintedOn);
        setSortDirection(SortDirectionEnum.Descending);
        fetchAllPrintedData();
      }
      if (newSelectedTab === PrintRoomTabEnum.Priority) {
        setJobType(HagPrintRoomJobTypeEnum.SingleDecoration);
        setSortBy(QueriesSortByEnum.ShipDate);
        setSortDirection(SortDirectionEnum.Ascending);
      }
      setQueue([]);
      clearSelection();
      resetFilters();
    }

    selectTab(newSelectedTab);
  }, [
    selectTab,
    selectedTab,
    resetFilters,
    fetchAllPrintedData,
    clearSelection,
  ]);

  const onClaimedByJobId =
    useCallback((req: { printJobId: number; }) => claimJob({
      hagPrintRoomJobId: req.printJobId,
    }, {
      isBlockingRequest: false,
      showErrorModal: false,
    }), []);

  const onClaimedByJobsCompletedOrCanceled = useCallback(
    (res?: ProgressModalOnCompleteCallbackArgType<ProgressModalClaimedByPayloadType, ApiResponse>) => {
      setClaimedByPayload([]);
      if (res) {
        if (res.successes < res.total) {
          const errs = [];
          for (const result of res.results) {
            if (!result.success) {
              const msgLen = errorMessage.length - elipsis.length;
              let message = result.response?.substring(0, msgLen);
              if (result.response?.includes(errorMessage)) {
                message = errorMessage;
              } else if (result.response?.length > msgLen) {
                message += elipsis;
              }
              errs.push(`O${result.request}: ${message}`);
            }
          }
          const errMsg = `${res.total - res.successes} out of ${res.total} of the print jobs failed to be claimed!\n${errs.join('\n')}`;
          materialSwal('Print Status Failures', errMsg, 'error');
        } else {
          materialSwal('Print Job Claimed Successful', `${res.total} print jobs succeeded`, 'success');
        }
      }
      fetchPrintRoomStatusCounts();
      fetchPrintReadyOrdersPagedList();
      setSelectedItems([]);
    }, [
      fetchPrintRoomStatusCounts,
      fetchPrintReadyOrdersPagedList,
    ]
  );

  const onProgressByJobId =
    useCallback((req: { printJobId: number; toStatus: HagPrintRoomJobStatusEnum; }) => moveJobToStatus({
      hagPrintRoomJobId: req.printJobId,
      toStatus: req.toStatus,
      // If setting to assembled here, this is an override
      override: req.toStatus === HagPrintRoomJobStatusEnum.Assembled,
    }, {
      isBlockingRequest: false,
      showErrorModal: false,
    }), []);

  const onProgressJobsCompletedOrCanceled = useCallback(
    (res?: ProgressModalOnCompleteCallbackArgType<ProgressModalJobStatusPayloadType, ApiResponse>) => {
      setJobStatusPayload([]);
      if (res) {
        if (res.successes < res.total) {
          const errs = [];
          for (const result of res.results) {
            if (!result.success) {
              const msgLen = errorMessage.length - elipsis.length;
              let message = result.response?.substring(0, msgLen);
              if (result.response?.includes(errorMessage)) {
                message = errorMessage;
              } else if (result.response?.length > msgLen) {
                message += elipsis;
              }
              errs.push(`O${result.request}: ${message}`);
            }
          }
          const errMsg = `${res.total - res.successes} out of ${res.total} of the print jobs failed to update their status!\n${errs.join('\n')}`;
          materialSwal('Print Status Failures', errMsg, 'error');
        } else {
          materialSwal('Print Job Update Successful', `${res.total} print jobs succeeded`, 'success');
        }
      }
      fetchPrintRoomStatusCounts();
      fetchPrintReadyOrdersPagedList();
      setSelectedItems([]);
    }, [
      fetchPrintRoomStatusCounts,
      fetchPrintReadyOrdersPagedList,
    ]
  );

  const tableActionsHeader = useMemo(() => {
    let bulkAction = null;

    if (selectedTab === PrintRoomTabEnum.PrintReady) {
      bulkAction = (
        <div className='actions-row__action'>
          <UpdateBulkMarkInPrint
            items={selectedItems}
            linkText={'Bulk Print DTF'}
            handleStatusUpdate={openDftSheetModel}
          />
          <UpdateBulkPrintJobStatus
            items={selectedItems}
            linkText='Mark Finalized'
            toStatus={HagPrintRoomJobStatusEnum.Assembled}
            handleStatusUpdate={handleBulkMovePrintsToStatus}
            warnUserTitle='Mark Finalized?'
            warnUserContent={
              'This will remove the selected orders from the Print Queue and move them to Finalized.'
              + ' Are you sure you want to continue?'
            }
          />
          <UpdateBulkClaimedBy
            items={selectedItems}
            linkText='Claim Order(s)'
            handleStatusUpdate={handleBulkClaimedBy}
            warnUserTitle='Claim Orders'
            warnUserContent={
              `Are you sure you want to claim ${selectedItems.length} orders?`
            }
          />
        </div>
      );
    } else if (selectedTab === PrintRoomTabEnum.Printing) {
      bulkAction = (
        <div className='actions-row__action'>
          <UpdateBulkMarkInPrint
            items={selectedItems}
            linkText={'Bulk Print DTF'}
            handleStatusUpdate={openDftSheetModel}
          />
          <UpdateBulkPrintJobStatus
            items={selectedItems}
            linkText={'Bulk Send to Print Queue'}
            toStatus={HagPrintRoomJobStatusEnum.PrintReady}
            handleStatusUpdate={handleBulkMovePrintsToStatus}
          />
          <UpdateBulkPrintJobStatus
            items={selectedItems}
            linkText={'Bulk Mark Printed'}
            toStatus={HagPrintRoomJobStatusEnum.Printed}
            handleStatusUpdate={handleBulkMovePrintsToStatus}
          />
          <DownloadBulkPrintableViews items={selectedItems} />
        </div>
      );
    } else if (selectedTab === PrintRoomTabEnum.Priority) {
      bulkAction = (
        <UpdateBulkMarkInPrint
          items={selectedItems}
          linkText={'Bulk Print DTF'}
          handleStatusUpdate={openDftSheetModel}
        />
      );
    }

    return (
      <TableBulkActions<HagPrintRoomJobPagedListDto>
        selectedItems={selectedItems}
        additionalInfo={getLogoCountMessage}
        bulkActions={bulkAction}
        clearAll={clearSelection}
      />
    );
  }, [
    clearSelection,
    getLogoCountMessage,
    selectedItems,
    selectedTab,
    openDftSheetModel,
    handleBulkMovePrintsToStatus,
    handleBulkClaimedBy,
  ]);

  type HagCellInfo<T extends keyof HagPrintRoomJobPagedListDto> = CellInfo<HagPrintRoomJobPagedListDto, T>;

  const printQueueColumns: Array<Column<HagPrintRoomJobPagedListDto>> = useMemo(() => [
    {
      Header: <HeaderCell text={'Order'} />,
      minWidth: 75,
      sortable: true,
      accessor: '',
      id: 'orderId',
      Cell: (cellProps) => <IdWithIconsCell item={cellProps.value} />,
    },
    {
      Header: <HeaderCell text={'Logo Count'} />,
      accessor: 'decorationCount',
      sortable: true,
      minWidth: 50,
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'shipDate'>) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: <HeaderCell text={'Received On'} />,
      sortable: true,
      accessor: 'receivedDate',
      Cell: (cellProps: HagCellInfo<'receivedDate'>) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Inventory Status',
      accessor: 'inventoryStatus',
      Cell: (cellProps: HagCellInfo<'inventoryStatus'>) => <span>{cellProps.value}</span>,
    },
    {
      Header: <HeaderCell text={'Claimed By'} />,
      sortable: true,
      accessor: 'claimedBy',
      Cell: (cellProps: HagCellInfo<'claimedBy'>) => <span>{cellProps.value}</span>,
    },
    {
      Header: 'Print DTF',
      accessor: '',
      Cell: (cellProps) => {
        const { hagPrintRoomJobId } = cellProps.original;

        return (
          <DtfPrinterButton
            id={hagPrintRoomJobId!}
            onClick={openDftSheetModel}
            enabled={printRoomEnableDtf}
          />
        );
      },
    },
  ], [
    openDftSheetModel,
    printRoomEnableDtf,
  ]);

  const inPrintColumns: Array<Column<HagPrintRoomJobPagedListDto>> = useMemo(() => [
    {
      Header: <HeaderCell text={'Order'} />,
      minWidth: 75,
      sortable: true,
      accessor: '',
      id: 'orderId',
      Cell: (cellProps) => <IdWithIconsCell item={cellProps.value} />,
    },
    {
      Header: <HeaderCell text={'Logo Count'} />,
      accessor: 'decorationCount',
      sortable: true,
      minWidth: 50,
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'shipDate'>) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: <HeaderCell text={'Claimed By'} />,
      accessor: 'printingBy',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'printingBy'>) => <span>{cellProps.value}</span>,
    },
    {
      Header: <HeaderCell text={'Claimed On'} />,
      accessor: 'printingDate',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'printingDate'>) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Printer Number',
      accessor: 'printerNumber',
      Cell: (cellProps: HagCellInfo<'printerNumber'>) => <span>{cellProps.value}</span>,
    },
    {
      Header: 'Print DTF',
      accessor: '',
      Cell: (cellProps) => {
        const { hagPrintRoomJobId } = cellProps.original;

        return (
          <DtfPrinterButton
            id={hagPrintRoomJobId!}
            onClick={openDftSheetModel}
            enabled={printRoomEnableDtf}
          />
        );
      },
    },
    {
      Header: 'Send To Print Queue',
      accessor: '',
      Cell: (cellProps) => {
        const { hagPrintRoomJobId } = cellProps.original;

        return (
          <UpdatePrintJobStatus
            id={hagPrintRoomJobId!}
            linkText={'Send To Print Queue'}
            toStatus={HagPrintRoomJobStatusEnum.PrintReady}
            handleStatusUpdate={handleBulkMovePrintsToStatus}
          />
        );
      },
    },
    {
      Header: 'Mark as Done',
      accessor: '',
      Cell: (cellProps) => {
        const { hagPrintRoomJobId } = cellProps.original;

        return (
          <UpdatePrintJobStatus
            id={hagPrintRoomJobId!}
            linkText={'Mark Done'}
            toStatus={HagPrintRoomJobStatusEnum.Printed}
            handleStatusUpdate={handleBulkMovePrintsToStatus}
          />
        );
      },
    },
  ], [
    openDftSheetModel,
    printRoomEnableDtf,
    handleBulkMovePrintsToStatus,
  ]);

  const recentlyPrintedColumns: Array<Column<HagPrintRoomJobPagedListDto>> = useMemo(() => [
    {
      Header: <HeaderCell text={'Order'} />,
      minWidth: 75,
      sortable: true,
      accessor: '',
      id: 'orderId',
      Cell: (cellProps) => <IdWithIconsCell item={cellProps.value} />,
    },
    {
      Header: <HeaderCell text={'Logo Count'} />,
      accessor: 'decorationCount',
      sortable: true,
      minWidth: 50,
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'shipDate'>) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: <HeaderCell text={'Completed By'} />,
      accessor: 'printedBy',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'printedBy'>) => <span>{cellProps.value}</span>,
    },
    {
      Header: <HeaderCell text={'Completed Date'} />,
      accessor: 'printedDate',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'printedDate'>) => <span>{parseDateTimeNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Printer Number',
      accessor: 'printerNumber',
      Cell: (cellProps: HagCellInfo<'printerNumber'>) => <span>{cellProps.value}</span>,
    },
    {
      Header: <HeaderCell text={'Print Status'} />,
      accessor: 'status',
      sortable: true,
      Cell: (cellProps: HagCellInfo<'status'>) => <span>{parsePrintStatus(cellProps.value)}</span>,
    },
  ], []);

  const priorityColumns: Array<Column<HagPrintRoomJobPagedListDto>> = useMemo(() => [
    {
      Header: 'Artwork / Personalization',
      minWidth: 50,
      accessor: 'priorityItemInfo',
      Cell: (cellProps: HagCellInfo<'priorityItemInfo'>) => (
        <PrintRoomPreviewCell item={cellProps.value!} />
      ),
    },
    {
      Header: 'Order ID',
      accessor: 'orderNumber',
      minWidth: 75,
    },
    {
      Header: 'Decoration ID',
      accessor: '',
      minWidth: 50,
      Cell: (cellProps) => {
        const {
          orderNumber: itemOrderNumber,
          priorityOrderItemDecorationId: itemOrderDecorationId,
        } = cellProps.original;

        if (!itemOrderNumber || !itemOrderDecorationId) {
          return <></>;
        }

        return <span>O{itemOrderNumber}-{itemOrderDecorationId}</span>;
      },
    },
    {
      Header: <HeaderCell text={'Ship Date'} />,
      accessor: 'shipDate',
      minWidth: 50,
      sortable: true,
      Cell: (cellProps: HagCellInfo<'shipDate'>) => <span>{parseDateNumeric(cellProps.value)}</span>,
    },
    {
      Header: 'Claimed By',
      accessor: 'printingBy',
      minWidth: 50,
      Cell: (cellProps: HagCellInfo<'printingBy'>) => <span>{cellProps.value}</span>,
    },
    {
      Header: 'Printer Number',
      accessor: 'printerNumber',
      minWidth: 50,
      Cell: (cellProps: HagCellInfo<'printerNumber'>) => <span>{cellProps.value}</span>,
    },
    {
      Header: 'Reason for Priority',
      accessor: 'priorityItemInfo',
      minWidth: 50,
      Cell: (cellProps: HagCellInfo<'priorityItemInfo'>) => <span>{cellProps.value?.reason}</span>,
    },
    {
      Header: 'Note',
      accessor: '',
      minWidth: 50,
      Cell: (cellProps) => {
        const {
          priorityItemInfo,
          shipDate,
          orderNumber: itemOrderNumber,
        } = cellProps.value;

        return (
          <PriorityNoteButton
            item={priorityItemInfo!}
            orderNumber={itemOrderNumber ?? 0}
            shipDate={shipDate!}
          />
        );
      },
    },
    {
      Header: 'Print DTF',
      accessor: '',
      minWidth: 50,
      Cell: (cellProps) => {
        const { hagPrintRoomJobId } = cellProps.original;

        return (
          <DtfPrinterButton
            id={hagPrintRoomJobId!}
            onClick={openDftSheetModel}
            enabled={printRoomEnableDtf}
          />
        );
      },
    },
    {
      Header: 'Mark as Done',
      accessor: '',
      minWidth: 50,
      Cell: (cellProps) => {
        const {
          hagPrintRoomJobId,
          orderNumber: itemOrderNumber,
          priorityOrderItemDecorationId,
          printerNumber,
        } = cellProps.original;

        return (
          <UpdatePrintJobStatus
            id={hagPrintRoomJobId!}
            linkText={'Mark Done'}
            toStatus={HagPrintRoomJobStatusEnum.Printed}
            handleStatusUpdate={handleBulkMovePrintsToStatus}
            warnUser={
              printerNumber
                ? undefined
                : `Are you sure you want to mark Order ${itemOrderNumber ?? 0} / Dec ${priorityOrderItemDecorationId ?? 0} as done before printing?`
            }
          />
        );
      },
    },
  ], [
    handleBulkMovePrintsToStatus,
    openDftSheetModel,
    printRoomEnableDtf,
  ]);

  const getColumns = useCallback((): Array<Column<HagPrintRoomJobPagedListDto>> => {
    switch (selectedTab) {
      case PrintRoomTabEnum.PrintReady:
        return printQueueColumns;
      case PrintRoomTabEnum.Printing:
        return inPrintColumns;
      case PrintRoomTabEnum.Printed:
        return recentlyPrintedColumns;
      case PrintRoomTabEnum.Priority:
        return priorityColumns;
      default:
        return [];
    }
  }, [
    printQueueColumns,
    inPrintColumns,
    recentlyPrintedColumns,
    priorityColumns,
    selectedTab,
  ]);

  const wrappedColumns = useMemo(() => [
    {
      Header: tableActionsHeader,
      columns: getColumns(),
    },
  ], [
    getColumns,
    tableActionsHeader,
  ]);

  const filtersToPreserve = useMemo(() => ({
    // TODO
  }), []);

  return (
    <div className='sheet__list'>
      <div>
        <PrintRoomNewTableTabs
          logoCounts={printRoomStatusCounts}
          selectTab={handleSelectTab}
          selectedTab={selectedTab}
        />
        <div>
          <div className='sheet'>
            <div className='sheet__list'>
              <div className='w-100'>
                <PrintFilesTable
                  data={queue}
                  columns={wrappedColumns}
                  defaultPageSize={defaultPageSizeSmallTable}
                  pageSizeOptions={pageSizeOptionsSmallTable}
                  onFetchData={fetchData}
                  totalPages={totalPages}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  selectable={true}
                  selectPredicateOrKey={'hagPrintRoomJobId'}
                  isPageSelected={isPageSelected}
                  updateSelection={updateSelection}
                  selectedData={selectedItems}
                  totalCount={totalCount}
                  isBulkActionsMode={selectedItems?.length > 0}
                  tableId={TableEnum.printRoom}
                  preserveState={true}
                  sortDirEnum={sortDirectionEnum}
                  initialPageNumber={pageNumber}
                  initialPageSize={pageSize}
                  initialSortColumn={sortColumnString}
                  initialSortDirection={sortDirectionString}
                  filtersToPreserve={filtersToPreserve}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DtfPrinterModal
        title={'DTF Print'}
        formId='dtf-printer-modal-form_sheet'
        jobIds={dftJobIds}
        isOpen={dftJobIds.length > 0}
        onClose={closeDftSheetModel}
        onPrint={handleDtfPrintByJobId}
      />
      <ProgressModal
        title={'Printing...'}
        callback={onPrintByJobId}
        work={markAsPrintingPayload}
        cancelable={true}
        sleepMs={200}
        onComplete={onPrintsCompletedOrCanceled}
        onCancel={onPrintsCompletedOrCanceled}
      />
      <ProgressModal
        title={'Updating Print Job Status...'}
        callback={onProgressByJobId}
        work={jobStatusPayload}
        cancelable={true}
        sleepMs={200}
        onComplete={onProgressJobsCompletedOrCanceled}
        onCancel={onProgressJobsCompletedOrCanceled}
      />
      <ProgressModal
        title={'Updating Claimed By...'}
        callback={onClaimedByJobId}
        work={claimedByPayload}
        cancelable={true}
        sleepMs={200}
        onComplete={onClaimedByJobsCompletedOrCanceled}
        onCancel={onClaimedByJobsCompletedOrCanceled}
      />
    </div>
  );
});

export default PrintRoomNewTable;
