import React from 'react';
import PropTypes from 'prop-types';
import SimpleConfirmationModal from '@sharedComponents/Modal/SimpleConfirmationModal';

const DtgEnvironmentDeleteModal = React.memo(({
  isOpen,
  closeModal,
  dtgEnvironment,
  onDelete,
}) => (
  <SimpleConfirmationModal
    isOpen={isOpen}
    confirm={onDelete.bind(null, dtgEnvironment.id)}
    closeModal={closeModal}
    title={'Remove DTG Environment'}
    confirmationBody={`Are you sure you want to remove the DTG Environment ${dtgEnvironment.environmentName}?`}
  />
));

DtgEnvironmentDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  dtgEnvironment: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DtgEnvironmentDeleteModal;
