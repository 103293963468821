import React from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '@sharedComponents/Display/SectionTitle';
import VendorTimelineFormDropdown from './VendorFulfilledInformationSectionContent/VendorTimelineFormDropdown';
import VendorDesignIdFormInput from './VendorFulfilledInformationSectionContent/VendorDesignIdFormInput';
import MaxPersonalizationTextLengthFormInput from './VendorFulfilledInformationSectionContent/MaxPersonalizationTextLengthFormInput';

const VendorFulfilledInformationSection = React.memo(({
  vendor,
  vendorTimelines,
  updateTimeline,
  handleVendorDesignIdValidationChanges,
  validateVendorDesignId,
  isVendorDesignIdValid,
}) => (
  <div>
    <SectionTitle
      title={'Vendor Fulfillment Information'}
      classes={'mb-30 mt-15'}
    />

    <div className='redux-form__row'>
      <div className='redux-form__column--size-m pr-20'>
        <VendorTimelineFormDropdown
          vendorTimelines={vendorTimelines}
          updateTimeline={updateTimeline}
        />
      </div>
      <div className='redux-form__column--size-m'>
        <VendorDesignIdFormInput
          isVendorDesignIdValid={isVendorDesignIdValid}
          validateVendorDesignId={validateVendorDesignId}
          handleVendorDesignIdValidationChanges={handleVendorDesignIdValidationChanges}
        />
      </div>
    </div>

    <div className='redux-form__row'>
      <div className='redux-form__column--size-m pr-20'>
        <MaxPersonalizationTextLengthFormInput
          vendorMaxPersonalizationNameLength={vendor.vfiMaxPersonalizationNameLength}
        />
      </div>
    </div>
  </div>
));

VendorFulfilledInformationSection.propTypes = {
  vendor: PropTypes.shape({
    vfiMaxPersonalizationNameLength: PropTypes.number,
  }),
  vendorTimelines: PropTypes.arrayOf(PropTypes.shape({
    vendorId: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    timelineDays: PropTypes.number.isRequired,
  })),
  updateTimeline: PropTypes.func.isRequired,
  validateVendorDesignId: PropTypes.func.isRequired,
  isVendorDesignIdValid: PropTypes.bool.isRequired,
  handleVendorDesignIdValidationChanges: PropTypes.func.isRequired,
};

export default VendorFulfilledInformationSection;
