import React from 'react';
import { VoucherOrderDetailDto } from '@api/financialServices/models';
import RefundPrice from '@sharedComponents/Display/RefundPrice';
import DescriptionField from '@sharedComponents/Display/DescriptionField';
import FooterSummaryContainer from '@sharedComponents/Display/Containers/FooterSummaryContainer';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  openVoucherFinancialsModal: () => void;
}

type Props = OwnProps;

const VoucherDetailsListFooter = React.memo<Props>(({
  voucherOrder,
  openVoucherFinancialsModal,
}) => (
  <FooterSummaryContainer>
    <div className='justify__space-between'>
      <DescriptionField
        label={'Canceled'}
        value={`${voucherOrder.canceledInstanceCount}/${voucherOrder.totalInstanceCount}`}
      />
      <DescriptionField
        label={'Redeemed'}
        value={`${voucherOrder.redeemedInstanceCount}/${voucherOrder.totalInstanceCount}`}
      />
      <DescriptionField
        label={(
          <span className='align__center'>
            Price
            <button
              className='btn-borderless order__total-price-info-button'
              onClick={openVoucherFinancialsModal}
            >
              <Icon
                materialIcon={'info_outline'}
                classes='order__total-price-info-icon'
              />
            </button>
          </span>
        )}
        value={(
          <RefundPrice
            paid={voucherOrder.pricePaid!}
            refund={voucherOrder.totalAmount! - voucherOrder.pricePaid!}
          />
        )}
      />

    </div>
  </FooterSummaryContainer>
));

export default VoucherDetailsListFooter;
