/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type DecorationStatusEnum = 'Initial' | 'Assembled' | 'CheckedIn' | 'Decorated';


export const DecorationStatusEnum = {
  Initial: 'Initial' as DecorationStatusEnum,
  Assembled: 'Assembled' as DecorationStatusEnum,
  CheckedIn: 'CheckedIn' as DecorationStatusEnum,
  Decorated: 'Decorated' as DecorationStatusEnum,
};
