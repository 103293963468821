import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  useGetRosterTeamDetails,
  useGetRosterTeamMembers,
} from '@api/squadlockerServices/rosters';
import { VoucherOrderPaymentStatusEnum } from '@api/financialServices/models';
import { useGetHomefieldApiVouchersRosterteamsRosterTeamIdOrders as useGetRosterVoucherOrders } from '@api/financialServices/voucher-homefield';
import { RosterTeamViewModel } from '@api/squadlockerServices/models';
import {
  useGetAllCollectionItems, useGetCollection,
} from '@api/squadlockerServices/collections';
import {
  editRoster,
  deleteRoster,
  reimportRoster,
  downloadRosterCsvFile,
} from '@APICalls/rosters/actions';
import { rosterManagerUrl } from '@constants/clientUrls/clientUrls';
import { RosterFormData } from '@models/forms/RosterFormData';
import {
  materialSwal,
  navigateToPage,
} from '@util/componentHelper';
import BackLink from '@sharedComponents/Navigation/BackLink';
import EditRosterModal from '../RosterManagerModals/EditRosterModal';
import RemoveRosterModal from '../RosterManagerModals/RemoveRosterModal';
import AddRostersFromCSV from '../RosterManagerModals/AddRostersFromCSV';
import Header from './Header';
import RosterTeamMembersTable from './RosterTeamMembersTable';
import DeleteButton from '../../shared/Display/DeleteButton';
import { hasActiveVouchers } from '@util/componentHelpers/voucherOrdersHelper';
import { getUniqueListByKey } from '@util/arrayHelper';

interface RouteProps {
  rosterId: string;
}

type Props = RouteComponentProps<RouteProps>;

const RosterDetails = React.memo<Props>(({ match: { params: { rosterId } } }) => {
  const [
    editRosterModalIsOpen,
    setEditRosterModalIsOpen,
  ] = useState<boolean>(false);
  const [
    deleteRosterModalIsOpen,
    setDeleteRosterModalIsOpen,
  ] = useState<boolean>(false);
  const [
    reimportRosterFromCSVModalIsOpen,
    setReimportRosterFromCSVModalIsOpen,
  ] = useState<boolean>(false);

  const {
    data: roster,
    refetch: fetchRosterDetails,
  } = useGetRosterTeamDetails(parseInt(rosterId!));

  const {
    data: rosterMembers,
    refetch: fetchRosterMembers,
  } = useGetRosterTeamMembers(parseInt(rosterId!));

  const {
    data: voucherOrders,
    refetch: fetchRosterVoucherOrders,
  } = useGetRosterVoucherOrders(parseInt(rosterId!));

  const {
    data: collectionItems,
    refetch: fetchCollectionItems,
  } = useGetAllCollectionItems((roster ?? {}).collectionId!);

  const {
    data: collection,
    refetch: fetchCollection,
  } = useGetCollection((roster ?? {}).collectionId!);

  useEffect(() => {
    if (!rosterId) return;
    fetchRosterDetails();
    fetchRosterMembers();
    fetchRosterVoucherOrders();
  }, [
    fetchRosterDetails,
    fetchRosterMembers,
    fetchRosterVoucherOrders,
    rosterId,
  ]);

  useEffect(() => {
    if (!roster?.collectionId) return;
    fetchCollection();
    fetchCollectionItems();
  }, [
    fetchCollection,
    fetchCollectionItems,
    roster,
  ]);

  const refreshData = useCallback(() => {
    fetchRosterDetails();
  }, [fetchRosterDetails]);

  const openEditRosterModal = useCallback(() => {
    setEditRosterModalIsOpen(true);
  }, []);

  const closeEditRosterModal = useCallback(() => {
    setEditRosterModalIsOpen(false);
  }, []);

  const openDeleteRosterModal = useCallback(() => {
    setDeleteRosterModalIsOpen(true);
  }, []);

  const closeDeleteRosterModal = useCallback(() => {
    setDeleteRosterModalIsOpen(false);
  }, []);

  const openReimportRosterFromCSVModal = useCallback(() => {
    setReimportRosterFromCSVModalIsOpen(true);
  }, []);

  const closeReimportRosterFromCSVModal = useCallback(() => {
    setReimportRosterFromCSVModalIsOpen(false);
  }, []);

  const updateRoster = useCallback(async (rosterForm: RosterFormData) => {
    const res = await editRoster(rosterForm);
    refreshData();
    materialSwal('Success', res.message, 'success');
    closeEditRosterModal();
  }, [
    refreshData,
    closeEditRosterModal,
  ]);

  const removeRoster = useCallback(async (rosterToRemove: RosterTeamViewModel) => {
    const res = await deleteRoster(rosterToRemove.id);

    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      navigateToPage(rosterManagerUrl);
      closeDeleteRosterModal();
    }
  }, [closeDeleteRosterModal]);

  const downloadCSVFile = useCallback(() => {
    downloadRosterCsvFile(roster!.id);
  }, [roster]);

  const onReimportRoster = useCallback(async (rosterForm) => {
    const res = await reimportRoster(roster!.id, rosterForm);
    refreshData();
    materialSwal('Success', res.message, 'success');
    closeReimportRosterFromCSVModal();
  }, [
    roster,
    refreshData,
    closeReimportRosterFromCSVModal,
  ]);

  const itemsToOrderCount = collection?.required && collectionItems?.length
    ? getUniqueListByKey(collectionItems, 'code')?.length
    : 0;

  if (!roster) return null;

  const hasRosterActiveVouchers: boolean = hasActiveVouchers(voucherOrders);

  return (
    <div className='container'>
      <EditRosterModal
        isOpen={editRosterModalIsOpen}
        closeModal={closeEditRosterModal}
        editRoster={updateRoster}
        initialValues={roster}
        hasActiveVouchers={hasRosterActiveVouchers}
      />
      <RemoveRosterModal
        isOpen={deleteRosterModalIsOpen}
        closeModal={closeDeleteRosterModal}
        removeRoster={removeRoster}
        roster={roster}
      />
      <AddRostersFromCSV
        isOpen={reimportRosterFromCSVModalIsOpen}
        closeModal={closeReimportRosterFromCSVModal}
        addRosters={onReimportRoster}
      />
      <div className='navigation'>
        <BackLink
          text={'Roster Manager'}
          url={rosterManagerUrl}
        />
        <DeleteButton
          text={'Delete roster'}
          onClick={openDeleteRosterModal}
          disabled={hasRosterActiveVouchers}
          tooltipText={hasRosterActiveVouchers ? 'Cannot delete a roster team because it has an active voucher associated with it' : undefined}
        />
      </div>
      <Header
        roster={roster}
        rosterMembers={rosterMembers ?? []}
        collectionItems={collectionItems ?? []}
        openEditRosterModal={openEditRosterModal}
      />
      <RosterTeamMembersTable
        rosterId={roster.id}
        itemsToOrderCount={itemsToOrderCount}
        downloadCSVFile={downloadCSVFile}
        openReimportRosterFromCSVModal={openReimportRosterFromCSVModal}
        hasActiveVouchers={hasRosterActiveVouchers}
        refreshTable={fetchRosterMembers}
        rosterMembers={rosterMembers ?? []}
        collectionRequired={collection?.required ?? false}
        collectionItems={collectionItems ?? []}
      />
    </div>
  );
});

export default RosterDetails;
