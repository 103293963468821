const ID = 'PRINTFILES' as const;

export const UPDATE_CURRENT_QUEUE = `${ID}/UPDATE_CURRENT_QUEUE` as const;
export const CLEAR_PRINT_FILES = `${ID}/CLEAR_PRINT_FILES` as const;
export const GET_PRINT_SHEET_USERS = `${ID}/GET_PRINT_SHEET_USERS` as const;
export const CLEAR_NONESSENTIAL_QUERY_PARAMS = `${ID}/CLEAR_NONESSENTIAL_QUERY_PARAMS` as const;
export const UPDATE_LOGO_COUNTS = `${ID}/UPDATE_LOGO_COUNTS` as const;
export const UPDATE_PRINT_SHEET_ARTWORKS = `${ID}/UPDATE_PRINT_SHEET_ARTWORKS` as const;
export const CLEAR_PRINT_SHEET_ARTWORKS = `${ID}/CLEAR_PRINT_SHEET_ARTWORKS` as const;

export default ID;
