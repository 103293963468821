/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type AccountingActionTypeEnum = 'NoChange' | 'OrderPartialRefund' | 'OrderFullRefund' | 'OrderPartialRefundWithCancel' | 'OrderFullRefundWithCancel' | 'ShippingFullRefund' | 'ShippingRefund' | 'ShippingCharge' | 'OrderItemPartialRefund' | 'OrderItemFullRefund' | 'OrderItemPartialRefundWithCancel' | 'OrderItemFullRefundWithCancel' | 'PersonalizationRefund' | 'PersonalizationCharge' | 'OrderItemAdd' | 'CouponApplied' | 'BasePriceCharge' | 'BasePriceRefund';


export const AccountingActionTypeEnum = {
  NoChange: 'NoChange' as AccountingActionTypeEnum,
  OrderPartialRefund: 'OrderPartialRefund' as AccountingActionTypeEnum,
  OrderFullRefund: 'OrderFullRefund' as AccountingActionTypeEnum,
  OrderPartialRefundWithCancel: 'OrderPartialRefundWithCancel' as AccountingActionTypeEnum,
  OrderFullRefundWithCancel: 'OrderFullRefundWithCancel' as AccountingActionTypeEnum,
  ShippingFullRefund: 'ShippingFullRefund' as AccountingActionTypeEnum,
  ShippingRefund: 'ShippingRefund' as AccountingActionTypeEnum,
  ShippingCharge: 'ShippingCharge' as AccountingActionTypeEnum,
  OrderItemPartialRefund: 'OrderItemPartialRefund' as AccountingActionTypeEnum,
  OrderItemFullRefund: 'OrderItemFullRefund' as AccountingActionTypeEnum,
  OrderItemPartialRefundWithCancel: 'OrderItemPartialRefundWithCancel' as AccountingActionTypeEnum,
  OrderItemFullRefundWithCancel: 'OrderItemFullRefundWithCancel' as AccountingActionTypeEnum,
  PersonalizationRefund: 'PersonalizationRefund' as AccountingActionTypeEnum,
  PersonalizationCharge: 'PersonalizationCharge' as AccountingActionTypeEnum,
  OrderItemAdd: 'OrderItemAdd' as AccountingActionTypeEnum,
  CouponApplied: 'CouponApplied' as AccountingActionTypeEnum,
  BasePriceCharge: 'BasePriceCharge' as AccountingActionTypeEnum,
  BasePriceRefund: 'BasePriceRefund' as AccountingActionTypeEnum,
};
