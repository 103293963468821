import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { printStatusEnum } from '@constants/enums/printRoomEnums';
import { printAssemblyOrderUrl } from '@constants/clientUrls/clientUrls';
import Icon from '@sharedComponents/Icons/Icon';

const PrintSheetItemWeedingAndMasking = React.memo(({
  weedingAndMaskingInfo,
  orderNumber,
  sheetId,
  status,
}) => {
  let content = '';

  if (status === printStatusEnum.PrintAssembly) {
    content = (
      <Link
        to={printAssemblyOrderUrl(sheetId, orderNumber)}
        className='button button--color-gossamer button--size-s button--style-default button--radius-square button--shadow-m button--transform-uppercase'
      >
        {`Print Assembly (${weedingAndMaskingInfo.completed}/${weedingAndMaskingInfo.total})`}
      </Link>
    );
  }

  if (status === printStatusEnum.Done) {
    content = (
      <Link
        to={printAssemblyOrderUrl(sheetId, orderNumber)}
        className='button link'
      >
        {`Print Assembly (${weedingAndMaskingInfo.completed}/${weedingAndMaskingInfo.total})`}
        <Icon fontAwesomeIcon={'check'} />
      </Link>
    );
  }

  const flag = weedingAndMaskingInfo.flagged > 0
    ? (
      <Icon
        materialIcon={'flag'}
        classes={'is-flagged'}
      />
    )
    : '';

  return (
    <div
      className='sheet__weeding'
      data-html2canvas-ignore={true}
    >
      {content}
      {flag}
    </div>
  );
});

PrintSheetItemWeedingAndMasking.propTypes = {
  weedingAndMaskingInfo: PropTypes.object.isRequired,
  orderNumber: PropTypes.number.isRequired,
  sheetId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default PrintSheetItemWeedingAndMasking;
