import React from 'react';
import { productionOverviewUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from '@components/MyApps/MenuTile';

const ProductionOverviewLink = React.memo(() => (
  <MenuTile
    title={'Production Overview'}
    url={productionOverviewUrl}
    icon={'preview'}
  />
));

export default ProductionOverviewLink;
