/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  UseQueryOptions
} from 'react-query'
import type {
  StyleViewModel,
  ApiBadRequest,
  ApiInternalServerError,
  ColoredStyleViewModel,
  ColoredStyleWithStyleViewModel,
  ApiNotFound,
  ProductViewModel
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getStyle = <TData = StyleViewModel>(
    styleId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/styles/${styleId}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetStyleQueryKey = (styleId: number,) => [`/homefield/api/styles/${styleId}`]

    
export const useGetStyle = <TQueryFnData = AsyncReturnType<typeof getStyle, StyleViewModel>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 styleId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetStyleQueryKey(styleId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getStyle<TQueryFnData>(styleId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getColoredStylesForStyle = <TData = ColoredStyleViewModel[]>(
    styleId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/styles/${styleId}/colored-styles`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetColoredStylesForStyleQueryKey = (styleId: number,) => [`/homefield/api/styles/${styleId}/colored-styles`]

    
export const useGetColoredStylesForStyle = <TQueryFnData = AsyncReturnType<typeof getColoredStylesForStyle, ColoredStyleViewModel[]>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 styleId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetColoredStylesForStyleQueryKey(styleId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getColoredStylesForStyle<TQueryFnData>(styleId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getColoredStylesForStyleBySku = <TData = ColoredStyleWithStyleViewModel[]>(
    sku: string,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/styles/sku/${sku}/colored-styles`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetColoredStylesForStyleBySkuQueryKey = (sku: string,) => [`/homefield/api/styles/sku/${sku}/colored-styles`]

    
export const useGetColoredStylesForStyleBySku = <TQueryFnData = AsyncReturnType<typeof getColoredStylesForStyleBySku, ColoredStyleWithStyleViewModel[]>, TError = ApiBadRequest | ApiNotFound | ApiInternalServerError, TData = TQueryFnData>(
 sku: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetColoredStylesForStyleBySkuQueryKey(sku);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getColoredStylesForStyleBySku<TQueryFnData>(sku, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getProductsForStyle = <TData = ProductViewModel[]>(
    styleId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/styles/${styleId}/products`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetProductsForStyleQueryKey = (styleId: number,) => [`/homefield/api/styles/${styleId}/products`]

    
export const useGetProductsForStyle = <TQueryFnData = AsyncReturnType<typeof getProductsForStyle, ProductViewModel[]>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 styleId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsForStyleQueryKey(styleId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getProductsForStyle<TQueryFnData>(styleId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

