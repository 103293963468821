export const weedingAndMaskingRoom = 'homefield/api/printroom' as const;
export const weedingAndMaskingQueue = `${weedingAndMaskingRoom}/print-assembly` as const;
export const printQueueUrl = `${weedingAndMaskingRoom}/print-queue` as const;
export const printAssemblyQueue = `${weedingAndMaskingRoom}/print-assembly-queue` as const;

export const printQueueAction = (action: string = ''): string => `${printQueueUrl}${action ? `.${action}` : ''}`;
export const printQueueWithIdAction = (id: number, action: string = ''): string => action ? `${printQueueUrl}.${action}/${id}` : `${printQueueUrl}/${id}`;
export const printAssemblyQueueAction = (action: string = ''): string => `${printAssemblyQueue}${action ? `.${action}` : ''}`;
export const printAssemblyQueueWithIdAction = (id: number, action: string = ''): string => `${printAssemblyQueueAction(action)}/${id}`;

export const weedingAndMaskingChangeStatus = printAssemblyQueueAction('status');
export const weedingAndMaskingChangeStatusByBarcode = printAssemblyQueueAction('status-barcode');
export const weedingAndMaskingChangeStatusByPrintQueueId = printAssemblyQueueAction('status-print-queue-id');

export const orders = `${weedingAndMaskingRoom}/orders` as const;
export const checkInventory = (orderNumber: number): string => `${orders}.check-inventory/${orderNumber}`;

export const weedingAndMaskingByStatus = (sheetId: number): string => `${weedingAndMaskingQueue}/print-queue/${sheetId}`;

export const finalize = printQueueAction('finalize-order');

export const startWeedingAndMasking = (sheetId: number): string => printQueueWithIdAction(sheetId, 'start-print-assembly');

export const flagQueueItem = printAssemblyQueueAction('flag');
export const unflagQueueItem = (weedMaskQueueId: number): string => printAssemblyQueueWithIdAction(weedMaskQueueId, 'unflag');

export const markQueueItemInPrint = (weedMaskQueueId: number): string => printAssemblyQueueWithIdAction(weedMaskQueueId, 'in-print');

export const weedingAndMaskingOrdersList = (sheetId: number): string => `${printQueueUrl}/${sheetId}/orders`;

export const weedingAndMaskingGetData = (
  sheetId: number,
  status: string,
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: string,
  filterOrderId: number
): string => `${weedingAndMaskingByStatus(sheetId)}?Status=${status}&PageNumber=${pageNumber}&PageSize=${pageSize}&SortBy=${sortBy}&SortDirection=${sortDirection}&orderNumber=${filterOrderId}`;

export default {
  weedingAndMaskingRoom,
  weedingAndMaskingQueue,
  flagQueueItem,
  unflagQueueItem,
  weedingAndMaskingChangeStatus,
  weedingAndMaskingChangeStatusByBarcode,
  weedingAndMaskingChangeStatusByPrintQueueId,
  weedingAndMaskingGetData,
  markQueueItemInPrint,
  startWeedingAndMasking,
  finalize,
  checkInventory,
  weedingAndMaskingOrdersList,
};
