interface VendorInventoryEnumValue {
  value: keyof VendorInventoryEnum;
  title: string;
  quantity: string;
}

export interface VendorInventoryEnum {
  all: VendorInventoryEnumValue;
  size0: VendorInventoryEnumValue;
  size29: VendorInventoryEnumValue;
  size49: VendorInventoryEnumValue;
  size99: VendorInventoryEnumValue;
  size499: VendorInventoryEnumValue;
}

export const vendorInventoryEnum: Readonly<VendorInventoryEnum> = {
  all: {
    value: 'all',
    title: 'Vendor Inventory All',
    quantity: '',
  },
  size0: {
    value: 'size0',
    title: 'Vendor Inventory > 0',
    quantity: '0',
  },
  size29: {
    value: 'size29',
    title: 'Vendor Inventory > 29',
    quantity: '29',
  },
  size49: {
    value: 'size49',
    title: 'Vendor Inventory > 49',
    quantity: '49',
  },
  size99: {
    value: 'size99',
    title: 'Vendor Inventory > 99',
    quantity: '99',
  },
  size499: {
    value: 'size499',
    title: 'Vendor Inventory > 499',
    quantity: '499',
  },
};
