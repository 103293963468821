/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Squadlocker™ Services API
 * provide endpoints to __Squadlocker™__ services
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  PagedListViewModelCouponViewModel,
  ApiInternalServerError,
  GetCouponsParams,
  ApiOk,
  ApiBadRequest,
  CreateCouponRequestModel,
  ApiOkNumber,
  UpdateCouponRequestModel,
  CouponRestrictionViewModel,
  CouponSuggestionsViewModel,
  GetCouponSuggestionsParams
} from './models'
import { slServicesApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getCoupons = <TData = PagedListViewModelCouponViewModel>(
    params?: GetCouponsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetCouponsQueryKey = (params?: GetCouponsParams,) => [`/homefield/api/coupons`, ...(params ? [params]: [])]

    
export const useGetCoupons = <TQueryFnData = AsyncReturnType<typeof getCoupons, PagedListViewModelCouponViewModel>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetCouponsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCouponsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getCoupons<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const createCoupon = <TData = ApiOk>(
    createCouponRequestModel: CreateCouponRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons`, method: 'post',
      data: createCouponRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useCreateCoupon = <TData = AsyncReturnType<typeof createCoupon,ApiOk>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateCouponRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateCouponRequestModel}, TContext>((props) => {
        const {data} = props || {};

        return  createCoupon<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getCouponCreators = <TData = string[]>(
    
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons/creators`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetCouponCreatorsQueryKey = () => [`/homefield/api/coupons/creators`]

    
export const useGetCouponCreators = <TQueryFnData = AsyncReturnType<typeof getCouponCreators, string[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
  options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCouponCreatorsQueryKey();

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getCouponCreators<TQueryFnData>(requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const checkRedeemCoupon = <TData = ApiOkNumber>(
    couponCode: string,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons/${couponCode}/redeem.check`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getCheckRedeemCouponQueryKey = (couponCode: string,) => [`/homefield/api/coupons/${couponCode}/redeem.check`]

    
export const useCheckRedeemCoupon = <TQueryFnData = AsyncReturnType<typeof checkRedeemCoupon, ApiOkNumber>, TError = ApiBadRequest | ApiInternalServerError, TData = TQueryFnData>(
 couponCode: string, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getCheckRedeemCouponQueryKey(couponCode);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => checkRedeemCoupon<TQueryFnData>(couponCode, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const redeemCoupon = <TData = ApiOkNumber>(
    couponCode: string,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons/${couponCode}/redeem`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useRedeemCoupon = <TData = AsyncReturnType<typeof redeemCoupon,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{couponCode: string}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {couponCode: string}, TContext>((props) => {
        const {couponCode} = props || {};

        return  redeemCoupon<TData>(couponCode,requestOptions)
      }, mutationOptions)
    }
    export const unredeemCoupon = <TData = ApiOkNumber>(
    couponCode: string,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons/${couponCode}/unredeem`, method: 'put',
      data: undefined
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUnredeemCoupon = <TData = AsyncReturnType<typeof unredeemCoupon,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{couponCode: string}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {couponCode: string}, TContext>((props) => {
        const {couponCode} = props || {};

        return  unredeemCoupon<TData>(couponCode,requestOptions)
      }, mutationOptions)
    }
    export const updateCoupon = <TData = ApiOkNumber>(
    couponId: number,
    updateCouponRequestModel: UpdateCouponRequestModel,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons/${couponId}`, method: 'put',
      data: updateCouponRequestModel
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useUpdateCoupon = <TData = AsyncReturnType<typeof updateCoupon,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{couponId: number;data: UpdateCouponRequestModel}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {couponId: number;data: UpdateCouponRequestModel}, TContext>((props) => {
        const {couponId,data} = props || {};

        return  updateCoupon<TData>(couponId,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteCoupon = <TData = ApiOkNumber>(
    couponId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons/${couponId}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteCoupon = <TData = AsyncReturnType<typeof deleteCoupon,ApiOkNumber>,
    TError = ApiBadRequest | ApiInternalServerError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{couponId: number}, TContext>, request?: SecondParameter<typeof slServicesApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {couponId: number}, TContext>((props) => {
        const {couponId} = props || {};

        return  deleteCoupon<TData>(couponId,requestOptions)
      }, mutationOptions)
    }
    export const getCouponRestrictions = <TData = CouponRestrictionViewModel[]>(
    couponId: number,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons/${couponId}/restrictions`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetCouponRestrictionsQueryKey = (couponId: number,) => [`/homefield/api/coupons/${couponId}/restrictions`]

    
export const useGetCouponRestrictions = <TQueryFnData = AsyncReturnType<typeof getCouponRestrictions, CouponRestrictionViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 couponId: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCouponRestrictionsQueryKey(couponId);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getCouponRestrictions<TQueryFnData>(couponId, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const getCouponSuggestions = <TData = CouponSuggestionsViewModel[]>(
    params?: GetCouponSuggestionsParams,
 options?: SecondParameter<typeof slServicesApi>) => {
      return slServicesApi<TData>(
      {url: `/homefield/api/coupons/suggestions`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetCouponSuggestionsQueryKey = (params?: GetCouponSuggestionsParams,) => [`/homefield/api/coupons/suggestions`, ...(params ? [params]: [])]

    
export const useGetCouponSuggestions = <TQueryFnData = AsyncReturnType<typeof getCouponSuggestions, CouponSuggestionsViewModel[]>, TError = ApiInternalServerError, TData = TQueryFnData>(
 params?: GetCouponSuggestionsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof slServicesApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetCouponSuggestionsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getCouponSuggestions<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

