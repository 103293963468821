import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { downloadFile } from '@util/componentHelper';
import {
  hyphenateSpacedString,
  fileExtensionFromName,
} from '@util/stringHelpers';
import { formatDecorationLocationSize } from '@util/numberHelpers';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';
import ArtworkTaskImagePreview from './ArtworkTaskDetails/ArtworkTaskImagePreview';
import ColorDetectionSwatchesModal from './ColorDetectionSwatchesModal';
import ImageBackgroundSwatch from './ImageBackgroundSwatch';
import { imageBackgroundOptions } from '@constants/values';

const defaultBackground = imageBackgroundOptions[0];

class ImagePreviewContainer extends Component {
  state = {
    selectedBackground: defaultBackground,
  };

  componentDidUpdate(oldProps, oldState) {
    const { filePath } = this.props;

    if (filePath && oldState.selectedBackground !== defaultBackground && filePath !== oldProps.filePath) {
      this.setDefaultBackground();
    }
  }

  setDefaultBackground = () => {
    this.setState(() => ({ selectedBackground: defaultBackground }));
  };

  updateBackground = (nextBackground, e) => {
    const { selectedBackground } = this.state;

    e.preventDefault();
    if (nextBackground !== selectedBackground) {
      this.setState(() => ({ selectedBackground: nextBackground }));
    }
  };

  render() {
    const {
      filePath,
      fileDimensions,
      task,
      logo,
      refreshLogo,
    } = this.props;
    const { selectedBackground } = this.state;

    const fileExtension = fileExtensionFromName((logo && logo.imageUrl) || task.logoUrl);
    const fileName =
      `L${task.locker.id}_F${task.id}_${task.decorationMethod}_${formatDecorationLocationSize(task.decorationSizeWidth, task.decorationSizeHeight)}${task.floodColor ? `_${hyphenateSpacedString(task.floodColor)}` : ''}${fileExtension}`;

    return (
      <div className='artwork-preview__section'>
        <div className='justify__space-between flex-center'>
          <div className='artwork-preview__title'>
            Original logo
          </div>
          {
            logo && logo.deletedAt && (
              <div className='artwork-preview__warning-message'>
                <Icon materialIcon={'warning'} />
                <span>
                  This Logo is Archived
                </span>
              </div>
            )
          }
        </div>

        <div className='artwork-preview__image-wrapper'>
          <ArtworkTaskImagePreview
            filePath={filePath}
            selectedBackground={selectedBackground}
            fileDimensions={fileDimensions ? fileDimensions : null}
            task={task}
          />
        </div>
        <div className='artwork-preview__options'>
          <ImageBackgroundSwatch
            updateBackground={this.updateBackground}
            selectedBackground={selectedBackground}
            imageBackgroundOptions={imageBackgroundOptions}
          />
          <Button
            type={'secondary'}
            text={'Download'}
            onClick={downloadFile((logo && logo.imageUrl) || task.logoUrl, fileName)}
          />
        </div>
        <ColorDetectionSwatchesModal
          task={task}
          logo={logo}
          refreshLogo={refreshLogo}
        />
      </div>
    );
  }
}

ImagePreviewContainer.propTypes = {
  filePath: PropTypes.string.isRequired,
  fileDimensions: PropTypes.object,
  task: PropTypes.object,
  logo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    selectedColors: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      selectionType: PropTypes.number,
      id: PropTypes.number,
      hexValue: PropTypes.string,
    })),
    artworkApproved: PropTypes.bool.isRequired,
    decorationMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
    deletedAt: PropTypes.string,
  }),
  refreshLogo: PropTypes.func.isRequired,
};

export default ImagePreviewContainer;
