import Modal from '@components/shared/Modal/Modal';
import React, {
  useCallback,
  useState,
} from 'react';

interface OwnProps {
  logoUrl: string;
}

type Props = OwnProps;

const PrintRoomLogoCell = React.memo<Props>(({ logoUrl }) => {
  const [
    isOpen,
    setOpen,
  ] = useState(false);

  const handleClickOnLogo = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div>
      <a
        className='sheet__list-table-thumbnail cursor-pointer'
        onClick={handleClickOnLogo}
        style={{ backgroundImage: `url("${logoUrl}")` }}
      />
      <Modal
        isOpen={isOpen}
        closeModal={handleClose}
        modalSize={'l'}
      >
        <img
          className='modal_full-image'
          src={logoUrl}
        />
      </Modal>
    </div>
  );
});

export default PrintRoomLogoCell;
