import { skuSetupStatusEnum } from '@constants/enums/productCatalogEnums';

export const validateColoredStyleInSetup = (coloredStyle, style) => {
  const errors = [];

  if (style.status === skuSetupStatusEnum.Initial) {
    errors.push(`Style should be in a higher status than ${skuSetupStatusEnum.Initial}. Move style to the next status first.`);
  }

  if (!style.prdOnly && !coloredStyle.imageUrlBack && !coloredStyle.imageUrlFront && !coloredStyle.imageUrlSide) {
    errors.push('Missing at least one image.');
  }

  if (!style.prdOnly && style.logoLocations && style.logoLocations.length > 0) {
    if (!coloredStyle.imageUrlGrid) {
      errors.push('Missing grid file.');
    }

    if (!coloredStyle.floodColorId) {
      errors.push('Missing flood color.');
    }
  }

  if (style.personalizationLocations && style.personalizationLocations.length > 0
    && coloredStyle.personalizationColorIds.length === 0) {
    errors.push('Missing at least one personalization color.');
  }

  return errors;
};

export const validateColoredStyleInQC = (coloredStyle, style) => {
  const errors = validateColoredStyleInSetup(coloredStyle, style);

  if (style.status === skuSetupStatusEnum.QualityCheck) {
    errors.push(`Style should be in a higher status than ${skuSetupStatusEnum.QualityCheck}. Move style to the next status first.`);
  }

  return errors;
};
