import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  decorationTypeEnum,
  personalizationColorTypeEnum,
  personalizationItemTypeEnum,
} from '@constants/enums/decorationEnums';
import CustomItemConfigurationSection from './CustomizeItemDecorationsTabContent/CustomItemConfigurationSection';
import CustomItemDecorationsSection from './CustomizeItemDecorationsTabContent/CustomItemDecorationsSection';

class CustomizeItemDecorationsTab extends PureComponent {
  createLogo = (
    decorationLocation = null,
    decorationHeight = null,
    decorationWidth = null
  ) => {
    const { decorationMethod } = this.props.selectedColoredStyle.style;

    return {
      type: decorationTypeEnum.Logo,
      decorationMethod,
      decorationLocation,
      decorationHeight,
      decorationWidth,
      logoId: null,
      logoImage: null,
    };
  };

  createPersonalization = (
    decorationLocation = null,
    personalizationType = null,
    personalizationColorType = personalizationColorTypeEnum.Fill,
    decorationHeight = null
  ) => {
    const {
      personalizationItemType,
      selectedColoredStyle: { style: { decorationMethod } },
    } = this.props;

    const requiredPersonalization = personalizationItemType === personalizationItemTypeEnum.Uniform;

    return {
      type: decorationTypeEnum.Personalization,
      decorationMethod,
      decorationLocation,
      decorationHeight,
      personalizationType,
      personalizationColorType,
      required: requiredPersonalization,
      colorId: null,
      outlineColorId: null,
    };
  };

  updateDecorations = (decorations = []) => {
    const { change } = this.props;
    change('decorations', decorations);
  };

  render() {
    const {
      change,
      colorsDictionary,
      allDecorationLocations,
      lockerLogos,
      selectedColoredStyle,
      decorations,
      personalizationItemType,
      selectedLayout,
      selectLayout,
      unselectLayout,
    } = this.props;

    return (
      <div className='mt-20'>
        <CustomItemConfigurationSection
          change={change}
          styleId={selectedColoredStyle.style.id}
          allDecorationLocations={allDecorationLocations}
          createLogo={this.createLogo}
          createPersonalization={this.createPersonalization}
          updateDecorations={this.updateDecorations}
          decorations={decorations}
          selectedLayout={selectedLayout}
          selectLayout={selectLayout}
          unselectLayout={unselectLayout}
        />

        <CustomItemDecorationsSection
          change={change}
          colorsDictionary={colorsDictionary}
          allDecorationLocations={allDecorationLocations}
          lockerLogos={lockerLogos}
          selectedColoredStyle={selectedColoredStyle}
          createLogo={this.createLogo}
          createPersonalization={this.createPersonalization}
          decorations={decorations}
          selectedLayout={selectedLayout}
          selectLayout={selectLayout}
          unselectLayout={unselectLayout}
          personalizationItemType={personalizationItemType}
        />
      </div>
    );
  }
}

CustomizeItemDecorationsTab.propTypes = {
  change: PropTypes.func,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
  })),
  allDecorationLocations: PropTypes.arrayOf(PropTypes.object),
  lockerLogos: PropTypes.arrayOf(PropTypes.object),
  selectedColoredStyle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrlFront: PropTypes.string,
    personalizationColors: PropTypes.arrayOf(PropTypes.shape({
      floodColorId: PropTypes.number.isRequired,
      custom: PropTypes.bool.isRequired,
    })),
    style: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      category: PropTypes.shape({
        name: PropTypes.string,
      }),
      vendorId: PropTypes.string.isRequired,
      gender: PropTypes.string,
      discount: PropTypes.number,
      vendorFulfilledItem: PropTypes.bool,
      decorationMethod: PropTypes.string,
      decorationLocations: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        location: PropTypes.string,
      })),
    }),
  }).isRequired,
  decorations: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    decorationMethod: PropTypes.string,
    decorationLocation: PropTypes.string,
    decorationHeight: PropTypes.string,
    personalizationType: PropTypes.string,
    personalizationColorType: PropTypes.string,
    required: PropTypes.bool,
    colorId: PropTypes.number,
    outlineColorId: PropTypes.number,
  })),
  selectedLayout: PropTypes.shape({
    id: PropTypes.number.isRequired,
    category: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    sport: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string,
    imageUrlFront: PropTypes.string,
    imageUrlBack: PropTypes.string,
    imageUrlRight: PropTypes.string,
    imageUrlLeft: PropTypes.string,
  }),
  selectLayout: PropTypes.func.isRequired,
  unselectLayout: PropTypes.func.isRequired,
  personalizationItemType: PropTypes.string,
};

export default CustomizeItemDecorationsTab;
