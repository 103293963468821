import * as actionTypes from './types';

const initialState = {
  fulfillmentServices: [],
  catalogServices: [],
  gpServices: [],
  financialServices: [],
};

export default function advancedOptions(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_FULFILLMENT_SERVICES:
      return {
        ...state,
        fulfillmentServices: action.data,
      };
    case actionTypes.UPDATE_CATALOG_SERVICES:
      return {
        ...state,
        catalogServices: action.data,
      };
    case actionTypes.UPDATE_GP_SERVICES:
      return {
        ...state,
        gpServices: action.data,
      };
    case actionTypes.UPDATE_FINANCIAL_SERVICES:
      return {
        ...state,
        financialServices: action.data,
      };
    default:
      return state;
  }
}
