import React from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  Form,
  Field,
} from 'redux-form';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import { dtgColoredStyleForm } from '@constants/reduxForms';
import {
  parseNumber, parseInt,
} from '@util/numberHelpers';
import Select from '@sharedComponents/Form/Select';
import Input from '@sharedComponents/Form/Input';
import FormError from '@sharedComponents/Form/FormError';
import { generalOptions } from '@util/optionsMap';
import { validateDtgColoredStylesConfig } from '@redux/productCatalog/validations';

const pretreatPercentOptions = generalOptions([
  {
    key: 0,
    name: 'Choose %',
    value: '',
  },
  ...Array.from(Array(21).keys()).map((x) => ({
    key: x + 1,
    name: `${x * 5}%`,
    value: x * 5,
  })),
]);

const DtgColoredStyleForm = React.memo(({
  handleSubmit,
  error,
  dtgEnvironmentOptions,
  dtgPalletOptions,
  dtgPretreatmentBatchOptions,
  dtgTabletProfileOptions,
}) => {
  const envOptions
    = generalOptions([
      {
        id: '',
        environmentName: 'Choose Environment',
      },
      ...(dtgEnvironmentOptions || []),
    ].map((x) => ({
      key: x.id,
      name: x.environmentName,
      value: x.id,
    })));

  const palletOptions
    = generalOptions([
      {
        id: '',
        name: 'Choose Default Pallet',
      },
      ...(dtgPalletOptions || []),
    ].map((x) => ({
      key: x.id,
      name: x.name,
      value: x.id,
    })));

  const pretreatmentBatchOptions
    = generalOptions([
      {
        id: '',
        name: 'Choose Pretreatment Batch',
      },
      ...(dtgPretreatmentBatchOptions || []),
    ].map((x) => ({
      key: x.id,
      name: x.name,
      value: x.id,
    })));

  const tabletOptions
    = generalOptions([
      {
        id: '',
        name: 'Choose Tablet Profile',
      },
      ...(dtgTabletProfileOptions || []),
    ].map((x) => ({
      key: x.id,
      name: x.name,
      value: x.id,
    })));

  return (
    <div className='catalog-details__modal--container'>
      <Form
        className='redux-form'
        onSubmit={handleSubmit}
        id={dtgColoredStyleForm}
      >
        <div>
          <GridContainer className='redux-form__section'>
            {/* Groupings */}
            <Row className='redux-form__row'>
              <Col
                className='redux-form__column'
                md={6}
              >
                <label className='redux-form__label'>
                  Environment
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'dtgEnvironmentId'}
                  placeholder={'Choose Environment'}
                  component={Select}
                  type={'number'}
                  parse={parseNumber}
                >
                  {envOptions}
                </Field>
              </Col>
              <Col
                className='redux-form__column'
                md={6}
              >
                <label className='redux-form__label'>
                  Tablet Profile
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'dtgTabletProfileId'}
                  placeholder={'Choose Tablet Profile'}
                  component={Select}
                  type={'number'}
                  parse={parseNumber}
                >
                  {tabletOptions}
                </Field>
              </Col>
            </Row>
            {/* Pretreat Selects */}
            <Row className='redux-form__row'>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Pretreatment Percent
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'pretreatmentPercent'}
                  placeholder={'Choose %'}
                  component={Select}
                  type={'number'}
                  parse={parseNumber}
                >
                  {pretreatPercentOptions}
                </Field>
              </Col>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Pretreatment Batch
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'dtgPretreatmentBatchId'}
                  placeholder={'Choose Batch'}
                  component={Select}
                  type={'number'}
                  parse={parseNumber}
                >
                  {pretreatmentBatchOptions}
                </Field>
              </Col>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Default pallet
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'dtgDefaultPalletId'}
                  placeholder={'Choose Pallet'}
                  component={Select}
                  type={'number'}
                  parse={parseNumber}
                >
                  {palletOptions}
                </Field>
              </Col>
            </Row>
            {/* Pretreat Inputs */}
            <Row className='redux-form__row'>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Pretreatment Platen °F
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'pretreatmentPlatenFahrenheit'}
                  placeholder={'°F'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Pretreatment PSI
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'pretreatmentPsi'}
                  placeholder={'PSI'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Pretreatment Dwell Time (Seconds)
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'pretreatmentDwellTimeSeconds'}
                  placeholder={'seconds'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
            </Row>
            {/* Postprint Part 1 */}
            <Row className='redux-form__row'>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Postprint Dryer °F
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'postprintDryerFahrenheit'}
                  placeholder={'°F'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Postprint Process Time (Minutes)
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'postprintProcessTimeMinutes'}
                  placeholder={'minutes'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Postprint Air Speed
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'postprintAirSpeed'}
                  placeholder={'speed'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
            </Row>
            {/* Postprint Part 2 */}
            <Row className='redux-form__row'>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Postprint Press °F
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'postprintPressFahrenheit'}
                  placeholder={'°F'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Postprint PSI
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'postprintPsi'}
                  placeholder={'PSI'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
              <Col
                className='redux-form__column'
                md={4}
              >
                <label className='redux-form__label'>
                  Postprint Dwell Time (Seconds)
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'postprintDwellTimeSeconds'}
                  placeholder={'seconds'}
                  component={Input}
                  type={'number'}
                  parse={parseInt}
                />
              </Col>
            </Row>
          </GridContainer>
          <FormError error={error} />
        </div>
      </Form>
    </div>
  );
});

export default connect()(reduxForm({
  form: dtgColoredStyleForm,
  validate: validateDtgColoredStylesConfig,
  enableReinitialize: true,
  shouldError: () => true,
})(DtgColoredStyleForm));
