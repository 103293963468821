import * as actionTypes from './types';
import collectionsUrls from '@constants/sqdApiUrls/collectionsUrls';
import { slServicesApi } from '../../sqdApis';
import { makeApiCall } from '@util/apiHelper';

export const updateCollectionsTable = (data) => ({
  type: actionTypes.UPDATE_COLLECTIONS_TABLE,
  data,
});

export const updateCollectionDetails = (data) => ({
  type: actionTypes.UPDATE_COLLECTION,
  data,
});

export const updateCollectionItems = (data) => ({
  type: actionTypes.UPDATE_COLLECTION_ITEMS,
  data,
});

export const fetchCollections = (
  pageNumber = 1,
  pageSize = 10,
  sortBy = '',
  sortOrder = '',
  search = '',
  lockerId = ''
) => async (dispatch) => {
  const res = slServicesApi.get(collectionsUrls.collectionsTable(
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    search,
    lockerId
  ), { handleBlockingLoading: false });

  const data = await makeApiCall(res);

  if (data) {
    dispatch(updateCollectionsTable({
      totalPages: data.totalPages,
      hasPreviousPage: data.hasPreviousPage,
      hasNextPage: data.hasNextPage,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
      queue: data.items,
      totalCount: data.totalCount,
    }));
  }
};

export const fetchLockerCollectionSuggestions = (filter = '', limit = 10, lockerId) => async (dispatch) => (
  dispatch(fetchCollections(1, limit, '', '', filter, lockerId))
);

export const fetchCollection = (collectionId) => async (dispatch) => {
  const call = slServicesApi.get(collectionsUrls.collection(collectionId), { handleBlockingLoading: false });
  const response = await makeApiCall(call);
  if (response) {
    dispatch(updateCollectionDetails(response));
  }
};

export const fetchCollectionItems = (collectionId) => async (dispatch) => {
  const res = slServicesApi.get(collectionsUrls.collectionItems(collectionId), { handleBlockingLoading: false });

  const data = await makeApiCall(res);

  if (data) {
    dispatch(updateCollectionItems(data));
  }
};
