import React, {
  useCallback,
  useState,
} from 'react';
import { CollectionDetailsViewModel } from '@api/squadlockerServices/models';
import Expander from '@sharedComponents/Buttons/Expander';
import HeaderDetails from './HeaderDetails';
import Label from '@sharedComponents/Display/Label';
import EditButton from '@sharedComponents/Display/EditButton';

interface OwnProps {
  collection: CollectionDetailsViewModel;
  openEditCollectionModal: () => void;
}

type Props = OwnProps;

const Header = React.memo<Props>(({
  collection,
  openEditCollectionModal,
}) => {
  const [
    detailsAreExpanded,
    setDetailsAreExpanded,
  ] = useState<boolean>(false);

  const changeExpand = useCallback(() => {
    setDetailsAreExpanded(!detailsAreExpanded);
  }, [detailsAreExpanded]);

  return (
    <div className='lockerManagerEdit__header'>
      <div className='lockerManagerEdit__details-header'>
        <div className='lockerManagerEdit__details-header--info'>
          {collection.name || '-'}
          <Label
            text={!collection.disabled ? 'Active' : 'Disabled'}
            type={!collection.disabled ? 'active' : 'deleted'}
            classes={'ml-10'}
          />
          <EditButton
            onClick={openEditCollectionModal}
          />
        </div>
        <Expander
          isExpanded={detailsAreExpanded}
          toggle={changeExpand}
        />
      </div>
      {
        detailsAreExpanded &&
        <HeaderDetails collection={collection} />
      }
    </div>
  );
});

export default Header;
