import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import { orderItemPersonalizationForm } from '@constants/reduxForms';
import OrderManagementPersonalizationForm from './OrderManagementPersonalizationForm';
import {
  PersonalizationItemModel,
  OrderItemDetailsDto,
} from '@api/fulfillment/models';
import {
  DecorationLocationDto,
  ProductDetailDto,
} from '@api/productCatalog/models';
import { PersonalizationFormData } from '@models/forms/OrderManagement/PersonalizationFormData';
import { QueriesVariationDto } from '@api/orders/models';

interface Color {
  id: number;
  code: string;
  dtgCode: Nullable<string>;
  cmykValue: string;
  dtgCmykValue: string;
  hexValue: Nullable<string>;
  threadValue: Nullable<string>;
  brightness: string;
  isArtwork: boolean;
  isFlood: boolean;
}

interface OwnProps {
  isOpen: boolean;
  onSubmit: (form: PersonalizationFormData) => void;
  initialValues: {
    decorationLocation: string | null | undefined;
    number: string;
    text: string;
    colorId: number | null | undefined;
    quantity: number | undefined;
    personalizationBasePrice: number | null | undefined;
  } | {
    quantity: number | undefined;
  };
  closeModal: () => void;
  orderItem: OrderItemDetailsDto;
  canRefundSubtotal: boolean;
  personalization: Nullable<PersonalizationItemModel>;
  product: ProductDetailDto;
  decorationLocations: Array<DecorationLocationDto>;
  colors: Array<Color>;
  showQuantityField: boolean;
  canUpdatePersonalizationPrice: boolean;
  orderExistsOnCore: boolean;
  variation: Nullable<QueriesVariationDto>;
  disableCharge: boolean;
}

type Props = OwnProps;

const OrderManagementEditPersonalizationModal = React.memo<Props>(({
  isOpen,
  onSubmit,
  initialValues,
  closeModal,
  orderItem,
  canRefundSubtotal,
  personalization,
  product,
  decorationLocations,
  colors,
  showQuantityField,
  canUpdatePersonalizationPrice,
  orderExistsOnCore,
  variation,
  disableCharge,
}) => (
  <Modal
    title={'Edit Personalization'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Edit'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={orderItemPersonalizationForm}
      />
    )}
  >
    <>
      <OrderManagementPersonalizationForm
        isOpen={isOpen}
        onSubmit={onSubmit}
        initialValues={initialValues}
        orderItem={orderItem}
        canRefundSubtotal={canRefundSubtotal}
        personalization={personalization}
        decorationLocations={decorationLocations}
        product={product}
        colors={colors}
        showQuantityField={showQuantityField}
        canUpdatePersonalizationPrice={canUpdatePersonalizationPrice}
        orderExistsOnCore={orderExistsOnCore}
        variation={variation}
        isEdit={true}
        disableCharge={disableCharge}
      />
    </>
  </Modal>
));

export default OrderManagementEditPersonalizationModal;
