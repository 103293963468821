import React, {
  PureComponent,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container as GridContainer,
  Row,
  Col,
} from 'react-grid-system';
import { materialSwal } from '@util/componentHelper';
import { printDtgDecorations } from '@APICalls/dtg/actions';
import ProductionAssemblyPrintQueueItem from './ProductionAssemblyPrintQueueItem';

class ProductionAssemblyPrintQueue extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      printQueue: props.printQueue,
      printQueueGrouped: this.printQueueGroupBy(props.printQueue),
    };
  }

  componentDidUpdate(prevProps) {
    const { printQueue } = this.props;

    if (prevProps.printQueue !== printQueue) {
      this.setState(() => ({
        printQueue,
        printQueueGrouped: this.printQueueGroupBy(printQueue),
      }));
    }
  }

  printQueueGroupBy = (printQueue) => {
    if (!printQueue
      || (printQueue && printQueue.length === 0)) {
      return null;
    }
    const dataGroupByProfile = this.groupBy(printQueue, 'dtgTabletProfile', 'id');

    const dataGroupBy = {};

    Object.keys(dataGroupByProfile).forEach((key) => {
      if (!dataGroupBy[key]) {
        dataGroupBy[key] = {
          dtgTabletProfile: dataGroupByProfile[key][0].dtgTabletProfile,
          orderItems: [],
        };
      }

      dataGroupBy[key].orderItems = this.groupBy(dataGroupByProfile[key], 'orderNumber', null, 'O');

      let quantityCounter = 0;
      dataGroupByProfile[key].forEach((item) => {
        quantityCounter += item.quantity;
      });
      dataGroupBy[key].totalItemsCount = quantityCounter;
    });

    return dataGroupBy;
  };

  groupBy = (data, propKey, childPropKey, keyPrefix = null) => data.reduce((result, value) => {
    const keyWithoutPrefix = childPropKey ? value[propKey][childPropKey] : value[propKey];
    const key = keyPrefix ? keyPrefix + keyWithoutPrefix : keyWithoutPrefix;

    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(value);

    return result;
  }, { });

  removeItemFromQueue = (item) => {
    const { onRemoveItemFromPrintQueue } = this.props;

    onRemoveItemFromPrintQueue(item.id);
  };

  removeItemGroupFromPrintQueue = (value) => {
    const { onRemoveItemGroupFromPrintQueue } = this.props;

    onRemoveItemGroupFromPrintQueue(value);
  };

  updatePrintQueueItemQuantity = (e, error) => {
    const { onPrintQueueItemQuantityUpdate } = this.props;

    onPrintQueueItemQuantityUpdate(e.target.name, Number(e.target.value), error);
  };

  sendAllToPrint = () => {
    this.openSendToPrintModal();
  };

  sendPrintQueueItemsToPrint = async (data) => {
    const { printQueue } = this.state;

    const { refreshInProgressTab } = this.props;

    let orderItemDecorationIds = [];
    const filteredItems = printQueue.filter((x) => x.dtgTabletProfile.id === data.tabletProfileId);
    for (const item of filteredItems) {
      const { quantity } = item;
      for (const decSet of item.decorationIds) {
        const slicedIds = decSet.slice(0, quantity);
        orderItemDecorationIds = [
          ...orderItemDecorationIds,
          ...slicedIds,
        ];
      }
    }

    const result = await printDtgDecorations(data.stationIdentifier, orderItemDecorationIds);

    if (result && result.success) {
      materialSwal('Success', result.message, 'success');
      this.removeItemGroupFromPrintQueue(data.tabletProfileId);
      refreshInProgressTab();
    }
  };

  clearQueue = () => {
    const { onClearPrintQueue } = this.props;

    onClearPrintQueue();
  };

  onGroupByOptionChange = (value) => {
    const { onGroupByOptionChange } = this.props;
    onGroupByOptionChange(value);
  };

  onStationChange = (selectedStation) => {
    const { onStationChange } = this.props;
    onStationChange(selectedStation);
  };

  render() {
    const {
      dtgStations,
      dtgAnyActiveTransmissions,
      disabledAfterSendingToPrint,
      disableSendButtons,
    } = this.props;

    const { printQueueGrouped } = this.state;

    return (
      <Fragment>
        <div className='container'>
          {
            !!printQueueGrouped
              ? (
                <div className='pb-50'>
                  <GridContainer>
                    <Row
                      className='order-assembly__print-queue-row'
                      align='center'
                    >
                      <Col md={1}>Item</Col>
                      <Col md={1}>SKU</Col>
                      <Col>Size</Col>
                      <Col md={1}>Color</Col>
                      <Col md={1}>Brand</Col>
                      <Col md={2}>Category</Col>
                      <Col md={1}>Location</Col>
                      <Col md={2}>Environment</Col>
                      <Col md={1}>Quantity</Col>
                      <Col
                        md={1}
                        align={'right'}
                      >
                        Action
                      </Col>
                    </Row>
                  </GridContainer>

                  {
                    Object.keys(printQueueGrouped).map((groupKey, i) => (
                      <ProductionAssemblyPrintQueueItem
                        dtgTabletProfile={printQueueGrouped[groupKey].dtgTabletProfile}
                        orderItems={printQueueGrouped[groupKey].orderItems}
                        totalItemsCount={printQueueGrouped[groupKey].totalItemsCount}
                        onRemoveGroup={this.removeItemGroupFromPrintQueue}
                        updatePrintQueueItemQuantity={this.updatePrintQueueItemQuantity}
                        removeItemFromQueue={this.removeItemFromQueue}
                        dtgStations={dtgStations}
                        onSubmit={this.sendPrintQueueItemsToPrint}
                        dtgAnyActiveTransmissions={dtgAnyActiveTransmissions}
                        disabledAfterSendingToPrint={disabledAfterSendingToPrint}
                        disableSendButtons={disableSendButtons}
                        key={i}
                      />
                    ))
                  }
                </div>
              )
              : (
                <div className='flex align__center justify__center w-100 h-100'>
                  <b>Print queue is empty</b>
                </div>
              )
          }
        </div>
      </Fragment>
    );
  }
}

ProductionAssemblyPrintQueue.propTypes = {
  printQueue: PropTypes.arrayOf(PropTypes.object),
  onRemoveItemFromPrintQueue: PropTypes.func.isRequired,
  onRemoveItemGroupFromPrintQueue: PropTypes.func.isRequired,
  onPrintQueueItemQuantityUpdate: PropTypes.func.isRequired,
  onClearPrintQueue: PropTypes.func.isRequired,
  dtgStations: PropTypes.array.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  refreshInProgressTab: PropTypes.func,
  selectedStation: PropTypes.string,
  onStationChange: PropTypes.func.isRequired,
  onGroupByOptionChange: PropTypes.func.isRequired,
  dtgAnyActiveTransmissions: PropTypes.bool.isRequired,
  disableSendButtons: PropTypes.func.isRequired,
  disabledAfterSendingToPrint: PropTypes.bool.isRequired,
};

export default ProductionAssemblyPrintQueue;
