import React from 'react';
import { PrepressJobOrderIndicatorEnum } from '@api/fulfillment/models';
import LogoInfo from './LogoInfo';

interface OwnProps {
  previewUrl: string;
  indicator?: PrepressJobOrderIndicatorEnum;
  orderNumber: number;
  size?: string;
  type?: string;
  barcode?: string;
  cutColor?: string;
  decorationIds?: number[];
}

type Props = OwnProps;

const MarkItemAsFlaggedModalLogoDetails = React.memo<Props>(({
  previewUrl,
  indicator,
  orderNumber,
  size,
  type,
  barcode,
  cutColor,
  decorationIds,
}) => (
  <div className='flagging__details'>
    <div className='flagging__details-logo'>
      <LogoInfo
        logoUrl={previewUrl}
        indicator={indicator}
      />
    </div>
    <div className='flagging__details-info'>
      <div className='flagging__details-info-column'>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Order ID
          </div>
          <div className='flagging__details-info-content'>
            {`O${orderNumber}`}
          </div>
        </div>
        {
          !!barcode &&
          <div className='flagging__details-info-item'>
            <div className='flagging__details-info-label'>
            Barcode
            </div>
            <div className='flagging__details-info-content'>
              {barcode}
            </div>
          </div>
        }
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Decoration ID
          </div>
          <div className='flagging__details-info-content'>
            {
              decorationIds &&
              <ul>
                {decorationIds?.map((decorationId) => <li key={decorationId}>{`O${orderNumber}-${decorationId}`}</li>)}
              </ul>
            }
          </div>
        </div>
      </div>
      <div className='flagging__details-info-column'>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Size
          </div>
          <div className='flagging__details-info-content'>
            {size}
          </div>
        </div>
      </div>
      <div className='flagging__details-info-column'>
        <div className='flagging__details-info-item'>
          <div className='flagging__details-info-label'>
            Type
          </div>
          <div className='flagging__details-info-content'>
            {type}
          </div>
        </div>
        {
          cutColor &&
          <div className='flagging__details-info-item'>
            <div className='flagging__details-info-label'>
              Cut Color
            </div>
            <div className='flagging__details-info-content'>
              {cutColor}
            </div>
          </div>
        }
      </div>
    </div>
  </div>
));

export default MarkItemAsFlaggedModalLogoDetails;
