import React, { useState, useEffect } from 'react';
import { useGetHomefieldApiStylecrosssellsAccessories, useDeleteHomefieldApiStylecrosssellsId } from '@api/productCatalog/style-cross-sells';
import Table from '@sharedComponents/Table/Table';
import { Link } from 'react-router-dom';
import { styleUrl } from '@constants/clientUrls/clientUrls';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';
import iconEye from '@assets/icon_hidden.svg';
import HeaderCell from '@components/shared/Table/TableCells/HeaderCell';
import Button from '@sharedComponents/Buttons/Button';
import CreateCrossSellModal from './CreateCrossSellModal';
import { getStyleCrossSellAccessoriesBulkActions } from '@constants/options/bulkActions';
import { Box } from '@material-ui/core';
import { StyleCrossSellDto } from '@api/productCatalog/models';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ShowMoreActionsButton from '@components/shared/Buttons/ShowMoreActionsButton';
import { useQueryClient } from 'react-query';
import { getPagingParamsFromTable } from '@util/tableHelpers';

const AccessoriesTable = Table();

const CrossSells = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const { data: accessories } = useGetHomefieldApiStylecrosssellsAccessories(
    { pageNumber,
      pageSize },
    { query: { enabled: true } }
  );

  const { items, totalPages, hasPreviousPage, hasNextPage } = accessories || {};

  const { mutateAsync: deleteCrossSellAsync } = useDeleteHomefieldApiStylecrosssellsId();
  const [openCreateCrossSellModal, setOpenCreateCrossSellModal] = useState<boolean>(false);
  const [openDeleteCrossSellsModal, setOpenDeleteCrossSellsModal] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<StyleCrossSellDto[]>([]);
  const [selectedCrossSell, setSelectedCrossSell] = useState<StyleCrossSellDto>();
  const [isPageSelected, setIsPageSelected] = useState<boolean>(false);
  const [openDeleteCrossSellModal, setOpenDeleteCrossSellModal] = useState<boolean>(false);

  const queryClient = useQueryClient();

  function fetchData(state: any, instance: any) {
    const { page: newPage, pageSize: newPageSize } = getPagingParamsFromTable(instance);

    setPageNumber(newPage + 1);
    setPageSize(newPageSize);
  }

  function getColumns() {
    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        sortable: true,
        minWidth: 40,
      },
      {
        Header: 'Parent SKU',
        accessor: 'targetStyle.code',
        sortable: false,
        minWidth: 40,
      },
      {
        Header: 'Source Id',
        accessor: 'sourceStyle.id',
        sortable: false,
        minWidth: 40,
      },
      {
        Header: 'Target Id',
        accessor: 'targetStyle.id',
        sortable: false,
        minWidth: 40,
      },
      {
        Header: 'Style Name',
        accessor: '',
        sortable: false,
        minWidth: 100,
        Cell: (cellProps: any) => (
          <div className='product-catalog__style-name'>
            <Link to={styleUrl(cellProps.value.targetStyle.id)}>
              {cellProps.value.targetStyle.name}
            </Link>
            <div className='product-catalog__style-icons'>
              {
                cellProps.value.targetStyle.disabled &&
                <MaterialTooltip
                  tooltipText={<div className='pre-line'>Style is disabled</div>}
                  placement={'top'}
                  contentClasses={'align__center'}
                >
                  <i
                    className='product-catalog__hidden'
                    style={{ backgroundImage: `url("${iconEye}")` }}
                  />
                </MaterialTooltip>
              }
              {
                cellProps.value.discontinued &&
                <MaterialTooltip
                  tooltipText={<div className='pre-line'>Style is discontinued</div>}
                  placement={'top'}
                  contentClasses={'align__center'}
                >
                  <Icon
                    materialIcon={'block'}
                    classes={'active'}
                  />
                </MaterialTooltip>
              }
            </div>
          </div>
        ),
      },
      {
        Header: <HeaderCell text={'Brand'} />,
        accessor: 'targetStyle.brand.name',
        id: 'brand',
        sortable: true,
        minWidth: 45,
      },
      {
        Header: 'Category',
        accessor: 'targetStyle.category.name',
        sortable: false,
        minWidth: 50,
      },
      {
        Header: '',
        width: 60,
        resizable: false,
        accessor: '',
        style: { overflow: 'visible' },
        Cell: (cellProps: any) => (
          <ShowMoreActionsButton
            actions={[
              {
                text: 'Remove Cross Sell',
                action: openDeleteModal.bind(null, cellProps.value),
                isDangerous: true,
                isVisible: true,
              },
            ]}
          />
        ),
      },
    ];

    return columns;
  }

  function openCreateModal() {
    setOpenCreateCrossSellModal(true);
  }

  function handleCloseCreateModal() {
    setOpenCreateCrossSellModal(false);
  }

  function openDeleteModal(crossSell: StyleCrossSellDto) {
    setOpenDeleteCrossSellModal(true);
    setSelectedCrossSell(crossSell);
  }

  function handleCloseDeleteModal() {
    setOpenDeleteCrossSellModal(false);
  }

  function openBulkDeleteModal() {
    setOpenDeleteCrossSellsModal(true);
  }

  function handleCloseBulkDeleteModal() {
    setOpenDeleteCrossSellsModal(false);
  }

  function updateSelection(newItems: any, newPageIsSelected: boolean) {
    setSelectedItems(newItems);
    setIsPageSelected(newPageIsSelected);
  }

  async function deleteCrossSell() {
    try {
      if (selectedCrossSell && selectedCrossSell.id) {
        await deleteCrossSellAsync({ id: selectedCrossSell.id });
        queryClient.invalidateQueries('/homefield/api/style-cross-sells/accessories');
        setOpenDeleteCrossSellModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function onBulkDeleteStyleCrossSells() {
    try {
      if (selectedItems && selectedItems.length > 0) {
        await Promise.all(selectedItems.map((crossSell) => deleteCrossSellAsync({ id: crossSell.id as number })));
        queryClient.invalidateQueries('/homefield/api/style-cross-sells/accessories');
        setOpenDeleteCrossSellsModal(false);
        setSelectedItems([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onSelectBulkAction(selectedAction: string) {
    switch (selectedAction) {
      case 'deleteCrossSells':
        openBulkDeleteModal();
        break;
    }
  }

  return (
    <div className='container master-detail'>
      <CreateCrossSellModal
        isOpen={openCreateCrossSellModal}
        closeModal={handleCloseCreateModal}
      />
      <Modal
        title='Remove Cross Sell'
        modalHeight='l'
        modalWidth='s'
        modalClass={'lockerManager__delete-modal'}
        isOpen={openDeleteCrossSellModal}
        closeModal={handleCloseDeleteModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Yes, Remove'}
            cancelBtnText={'Cancel'}
            onClose={handleCloseDeleteModal}
            onConfirm={deleteCrossSell}
            confirmBtnDisabled={false}
          />
        )}
      >
        <div className='modal__content'>
          <div className='modal__text'>
            Are you sure you want to remove the selected cross sell?
          </div>
        </div>
      </Modal>
      <Modal
        title='Remove Cross Sells'
        modalHeight='l'
        modalWidth='s'
        modalClass={'lockerManager__delete-modal'}
        isOpen={openDeleteCrossSellsModal}
        closeModal={handleCloseBulkDeleteModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Yes, Remove'}
            cancelBtnText={'Cancel'}
            onClose={handleCloseBulkDeleteModal}
            onConfirm={onBulkDeleteStyleCrossSells}
            confirmBtnDisabled={false}
          />
        )}
      >
        <div className='modal__content'>
          <div className='modal__text'>
            Are you sure you want to remove the selected cross sells?
          </div>
        </div>
      </Modal>
      <div className='w-100'>
        <div className='sheet'>
          <div className='filter-groups'>
            <div className='table-options w-100'>
              <div className='flex' />
              <div className='flex'>
                <Box marginBottom={2}>
                  <Button
                    type={'primary'}
                    text={'Create Cross Sell'}
                    onClick={openCreateModal}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
        <AccessoriesTable
          data={items!}
          columns={getColumns()}
          showPagination={hasNextPage || hasPreviousPage}
          totalPages={totalPages}
          onFetchData={fetchData}
          selectable={true}
          bulkActionsList={getStyleCrossSellAccessoriesBulkActions()}
          onSelectBulkAction={onSelectBulkAction}
          updateSelection={updateSelection}
          selectedData={selectedItems}
          isPageSelected={isPageSelected}
        />
      </div>
    </div>
  );
};

export default CrossSells;
