export const validateParentCategory = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = ['Code is required.'];
  }

  if (!values.name) {
    errors.name = ['Name is required.'];
  }

  return errors;
};
