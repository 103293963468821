/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Catalog Homefield API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
import type {
  DecorationLocationDto,
  GetHomefieldApiDecorationlocationsParams,
  DecorationLocationDtoApiResponse,
  ApiResponse,
  CreateDecorationLocationCommand,
  UpdateDecorationLocationCommand
} from './models'
import { catalogApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getHomefieldApiDecorationlocations = <TData = DecorationLocationDto[]>(
    params?: GetHomefieldApiDecorationlocationsParams,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-locations`, method: 'get',
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDecorationlocationsQueryKey = (params?: GetHomefieldApiDecorationlocationsParams,) => [`/homefield/api/decoration-locations`, ...(params ? [params]: [])]

    
export const useGetHomefieldApiDecorationlocations = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDecorationlocations, DecorationLocationDto[]>, TError = unknown, TData = TQueryFnData>(
 params?: GetHomefieldApiDecorationlocationsParams, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDecorationlocationsQueryKey(params);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDecorationlocations<TQueryFnData>(params, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const postHomefieldApiDecorationlocations = <TData = DecorationLocationDtoApiResponse>(
    createDecorationLocationCommand: CreateDecorationLocationCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-locations`, method: 'post',
      data: createDecorationLocationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiDecorationlocations = <TData = AsyncReturnType<typeof postHomefieldApiDecorationlocations,DecorationLocationDtoApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: CreateDecorationLocationCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: CreateDecorationLocationCommand}, TContext>((props) => {
        const {data} = props || {};

        return  postHomefieldApiDecorationlocations<TData>(data,requestOptions)
      }, mutationOptions)
    }
    export const getHomefieldApiDecorationlocationsId = <TData = DecorationLocationDto>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-locations/${id}`, method: 'get'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  

export const getGetHomefieldApiDecorationlocationsIdQueryKey = (id: number,) => [`/homefield/api/decoration-locations/${id}`]

    
export const useGetHomefieldApiDecorationlocationsId = <TQueryFnData = AsyncReturnType<typeof getHomefieldApiDecorationlocationsId, DecorationLocationDto>, TError = ApiResponse, TData = TQueryFnData>(
 id: number, options?: { query?:UseQueryOptions<TQueryFnData, TError, TData>, request?: SecondParameter<typeof catalogApi>}

  ) => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetHomefieldApiDecorationlocationsIdQueryKey(id);

  const query = useQuery<TQueryFnData, TError, TData>(queryKey, () => getHomefieldApiDecorationlocationsId<TQueryFnData>(id, requestOptions), {  retry: false, enabled: false,  ...queryOptions} )

  return {
    queryKey,
    ...query
  }
}

export const putHomefieldApiDecorationlocationsId = <TData = ApiResponse>(
    id: number,
    updateDecorationLocationCommand: UpdateDecorationLocationCommand,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-locations/${id}`, method: 'put',
      data: updateDecorationLocationCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiDecorationlocationsId = <TData = AsyncReturnType<typeof putHomefieldApiDecorationlocationsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number;data: UpdateDecorationLocationCommand}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number;data: UpdateDecorationLocationCommand}, TContext>((props) => {
        const {id,data} = props || {};

        return  putHomefieldApiDecorationlocationsId<TData>(id,data,requestOptions)
      }, mutationOptions)
    }
    export const deleteHomefieldApiDecorationlocationsId = <TData = ApiResponse>(
    id: number,
 options?: SecondParameter<typeof catalogApi>) => {
      return catalogApi<TData>(
      {url: `/homefield/api/decoration-locations/${id}`, method: 'delete'
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const useDeleteHomefieldApiDecorationlocationsId = <TData = AsyncReturnType<typeof deleteHomefieldApiDecorationlocationsId,ApiResponse>,
    TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{id: number}, TContext>, request?: SecondParameter<typeof catalogApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {id: number}, TContext>((props) => {
        const {id} = props || {};

        return  deleteHomefieldApiDecorationlocationsId<TData>(id,requestOptions)
      }, mutationOptions)
    }
    