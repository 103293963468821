import React from 'react';
import { parentCategoriesUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const ParentCategoriesLink = React.memo(() => (
  <MenuTile
    title={'Parent categories'}
    url={parentCategoriesUrl}
    icon={'storage'}
  />
));

export default ParentCategoriesLink;
