import moment from 'moment-timezone';

const DATE_MAPPINGS = {
  'Jan': 'January',
  'Feb': 'February',
  'Mar': 'March',
  'Apr': 'April',
  'May': 'May',
  'Jun': 'June',
  'Jul': 'July',
  'Aug': 'August',
  'Sep': 'September',
  'Oct': 'October',
  'Nov': 'November',
  'Dec': 'December',
} as const;

export const parseDate = (jsonDate: string | number | Date): string => {
  const arr: string[] = new Date(jsonDate).toDateString()
    .split(' ');

  return `${DATE_MAPPINGS[arr[1] as keyof typeof DATE_MAPPINGS]} ${arr[2]} ${arr[3]}`;
};

export const parseDateNumeric = (date: moment.MomentInput): string => moment(date).isValid() ? moment(date).local()
  .format('L') : '';
export const parseDateUtc = (date: moment.MomentInput): string => moment(date).isValid() ? moment(date).utc()
  .format('L') : '';
export const parseDateTimeNumeric = (date: moment.MomentInput): string => moment(date).isValid() ? moment(date).local()
  .format('L - LT') : '';
export const parseDateTimeUtc = (date: moment.MomentInput): string => moment(date).isValid() ? moment(date).utc()
  .format('L - LT') : '';
export const parseTime = (date: moment.MomentInput): string => moment(date).isValid() ? moment(date).local()
  .format('LT') : '';
export const parseDateDashes = (date: moment.MomentInput): string => moment(date).isValid() ? moment(date)
  .format('L') : '';

/*
 * Takes a Close Date and increments by 10 days, thereby returning Ship Date
 * Then parses in numeric format.
 */
export const formatShipBy = (date: string | number | Date): string => {
  const d = new Date(date);
  d.setDate(d.getDate() + 10);

  return d.toLocaleString();
};

export const daysBetween = (dateFrom: moment.MomentInput, dateTo: moment.MomentInput): number => moment(dateTo).diff(moment(dateFrom), 'days');

export const parseDateFromLocalTimezone = (date: moment.MomentInput): string => moment.parseZone(date).local()
  .format('YYYY-MM-DD');

export const getTodaysDate = (): moment.Moment => moment().tz('America/New_York');
