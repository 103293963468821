export const fundraisingPayoutStatusEnum = {
  Requested: 'Requested',
  Completed: 'Completed',
  Canceled: 'Canceled',
} as const;

export const fundraisingPayoutTypeEnum = {
  Request: 'Request',
  ProcessedPayout: 'ProcessedPayout',
} as const;

export const orderItemPayoutStatusEnum = {
  Processing: 'Processing',
  Available: 'Available',
  Requested: 'Requested',
} as const;
