import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchInventoryItems } from '@redux/onHandInventory/actions';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import ItemInfoModal from '@sharedComponents/ItemInfo/ItemInfoModal';
import Table from '@sharedComponents/Table/Table';
import InventoryFilterPanel from './InventoryFilterPanel';

const InventoryItemsTable = Table();

class OnHandInventoryTable extends Component {
  state = {
    search: '',
    filters: {
      brands: '',
      colors: '',
      sizes: '',
      ages: '',
      genders: '',
      categories: '',
    },
    itemInfoModalIsOpen: false,
    sku: '',
  };

  search = (value) => {
    this.setState({ search: value }, () => {
      this.refreshData();
    });
  };

  filter = (value, type) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [type]: value,
      },
    }, this.refreshData);
  };

  /*
   * This guard clause only exists because we're passing page
   * into ReactTable as a prop. Doing so allows us to keep
   * the table's internal page state up to date with the API
   * response, but the internal state is 0-indexed.
   * It will update everytime the page prop changes, so we need to
   * make this check so that it doesn't reload every time the api responds
   */
  fetchData = (state, instance) => {
    const {
      page,
      pageSize,
    } = instance.state;

    const { tableState } = this.props;

    if (page === tableState.pageNumber - 1) {
      return;
    }

    return this.refreshData(page + 1, pageSize);
  };

  refreshData = (pageNumber, pageSize) => {
    const { dispatch } = this.props;

    const {
      search,
      filters,
    } = this.state;

    const {
      brands,
      colors,
      sizes,
      ages,
      genders,
      categories,
    } = filters;

    return dispatch(fetchInventoryItems({
      pageNumber,
      pageSize,
      brands,
      colors,
      sizes,
      ages,
      genders,
      categories,
      search,
    }));
  };

  getColumns = () => (
    [
      {
        Header: 'Image',
        accessor: 'imageFront',
        className: 'no-padding',
        minWidth: 50,
        Cell: (cellProps) => (
          <div className='item__status'>
            <div className='w-100'>
              <ImagePreviewCell
                imageUrl={cellProps.value}
                openModalWithUrl={this.openItemInfoModal}
                openInfoModal={true}
                sku={cellProps.original.sku}
              />
            </div>
          </div>
        ),
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        minWidth: 60,
      },
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 100,
      },
      {
        Header: 'Category',
        accessor: 'categoryName',
        minWidth: 50,
      },
      {
        Header: 'Brand',
        accessor: 'brandName',
        minWidth: 50,
      },
      {
        Header: 'Color Group',
        accessor: 'color',
        minWidth: 40,
      },
      {
        Header: 'Size',
        accessor: 'size',
        minWidth: 20,
      },
      {
        Header: 'Age',
        accessor: 'age',
        minWidth: 40,
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        minWidth: 30,
      },
      {
        Header: 'On-Hand',
        accessor: 'availableOnHand',
        minWidth: 40,
      },
    ]
  );

  openItemInfoModal = (sku) => {
    this.setState({
      itemInfoModalIsOpen: true,
      sku,
    });
  };

  closeItemInfoModal = () => {
    this.setState({
      itemInfoModalIsOpen: false,
      sku: '',
    });
  };

  render() {
    const {
      items,
      tableState,
      sizes,
      brands,
      categories,
      colorGroups,
      productAges,
      productGenders,
    } = this.props;

    const {
      itemInfoModalIsOpen,
      sku,
    } = this.state;

    const {
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = tableState;

    const columns = this.getColumns();

    return (
      <div className='inventory__table'>
        <ItemInfoModal
          isOpen={itemInfoModalIsOpen}
          closeModal={this.closeItemInfoModal}
          sku={sku}
        />
        <div className='sheet'>
          <InventoryFilterPanel
            search={this.search}
            sizes={sizes}
            filter={this.filter}
            brands={brands}
            categories={categories}
            colors={colorGroups}
            ages={productAges}
            genders={productGenders}
          />
          <InventoryItemsTable
            data={items}
            columns={columns}
            onFetchData={this.fetchData}
            showPagination={hasNextPage || hasPreviousPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    );
  }
}

OnHandInventoryTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    imageFront: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    brandName: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
  })).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  productAges: PropTypes.arrayOf(PropTypes.string).isRequired,
  productGenders: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableState: PropTypes.shape({
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    hasPreviousPage: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    totalCount: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ onHandInventory }) => ({
  tableState: onHandInventory.products.tableState,
});

export default connect(mapStateToProps)(OnHandInventoryTable);
