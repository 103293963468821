import React, { useCallback } from 'react';
import Icon from '@sharedComponents/Icons/Icon';
import { PrimitiveType } from '@customTypes/option';
import { ColorItem } from '@models/common/ColorItem';

interface OwnProps<T extends PrimitiveType> {
  value: T;
  text: T;
  isSelected: boolean;
  onClick: (value: T, isSelected: boolean) => void;
  color?: T & ColorItem;
}

type Props<T extends PrimitiveType> = OwnProps<T>;

const MultiSelectDropdownItemPrimitive = <T extends PrimitiveType>({
  value,
  text,
  isSelected,
  onClick,
  color,
}: Props<T>) => {
  const handleOnClick = useCallback(() => {
    onClick(value, isSelected);
  }, [
    onClick,
    value,
    isSelected,
  ]);

  return (
    <li
      onClick={handleOnClick}
      className={`custom-dropdown__option button ${isSelected ? 'is-active' : ''}`}
    >
      {
        isSelected
          ? <Icon materialIcon={'check_box'} />
          : <Icon materialIcon={'check_box_outline_blank'} />
      }
      {
        color &&
        <div
          className='color-swatch-item__color ml-10'
          style={color ? { backgroundColor: color.hexValue } : {}}
        />
      }
      <div className='custom-dropdown__option--label'>
        {text}
      </div>
    </li>
  );
};

export default React.memo(MultiSelectDropdownItemPrimitive) as typeof MultiSelectDropdownItemPrimitive;
