import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

class OrganizationDeleteModal extends PureComponent {
  deleteOnClick = () => {
    const {
      organization,
      deleteOrganization,
    } = this.props;

    deleteOrganization(organization);
  };

  render() {
    const {
      organization,
      isOpen,
      closeModal,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        modalSize={'l'}
        title={'Delete Organization'}
        buttons={(
          <ModalButtons
            onClose={closeModal}
            onConfirm={this.deleteOnClick}
            confirmBtnText={'Delete'}
            isDangerousAction={true}
          />
        )}
      >
        <div className='modal__description'>
          Are you sure you want to delete organization "<b>{organization.name}</b>" from the system?
          All lockers associated with this organization will continue to exist as standalone stories.
        </div>
      </Modal>
    );
  }
}

OrganizationDeleteModal.propTypes = {
  organization: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default OrganizationDeleteModal;
