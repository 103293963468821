import React from 'react';
import { mapToOptionsList } from '@util/mappingHelper';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import Dropdown from '@sharedComponents/Inputs/Dropdowns/Dropdown/Dropdown';

const options = mapToOptionsList<string, string>({
  list: [
    decorationMethodEnum.EMB,
    decorationMethodEnum.HAG,
    decorationMethodEnum.DTG,
    decorationMethodEnum.DIP,
  ],
  emptyOption: {
    name: 'All decoration methods',
  },
});

interface OwnProps {
  filter: (value: string) => void;
}

type Props = OwnProps;

const DecorationFilter = React.memo<Props>(({ filter }) => (
  <div className='filter-group m-l-12'>
    <Dropdown
      options={options}
      onChange={filter}
    />
  </div>
));

export default DecorationFilter;
