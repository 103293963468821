export const printStatusEnum = {
  Initial: 'Initial',
  PrintReady: 'PrintReady',
  Printing: 'Printing',
  Done: 'Done',
  Priority: 'Priority',
  PrepressReady: 'PrepressReady',
  PrintAssembly: 'PrintAssembly',
} as const;

export const printStepEnum = {
  Printing: 'Printing',
  Printed: 'Printed',
  PrintAssembly: 'PrintAssembly',
} as const;

export type OrderTypeEnum = 'ExpressProduction' | 'Rush' | 'StandardProduction';

export const OrderTypeEnum = {
  ExpressProduction: 'ExpressProduction' as OrderTypeEnum,
  Rush: 'Rush' as OrderTypeEnum,
  StandardProduction: 'StandardProduction' as OrderTypeEnum,
} as const;
