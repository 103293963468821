import React from 'react';
import Image from '@sharedComponents/Display/Image';

interface OwnProps {
  logoUrl: string;
}

type Props = OwnProps;

const ArtworkTaskDetailsImage = React.memo<Props>(({ logoUrl }) => (
  <div className='details__item-thumbnail--color-transparent p-10'>
    <Image
      url={logoUrl}
      classes={'artwork-item__image'}
    />
  </div>
));

export default ArtworkTaskDetailsImage;
