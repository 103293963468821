import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';

const AdminLogoArchiveModal = React.memo(({
  isOpen,
  closeModal,
  logoImage,
  archiveLogo,
}) => (
  <Modal
    title={'Archive Logo'}
    isOpen={isOpen}
    modalHeight={'l'}
    modalWidth={'s'}
    closeModal={closeModal}
    modalClass={'lockerManager__delete-modal'}
    buttons={(
      <ModalButtons
        confirmBtnText={'Archive'}
        cancelBtnText={'Cancel'}
        onConfirm={archiveLogo}
        onClose={closeModal}
        isDangerousAction={true}
      />
    )}
  >
    <div className='flex'>
      <div
        className='logo-bank__manager--archive-logo-image'
        style={{ backgroundImage: `url("${logoImage}")` }}
      />
      <div className='logo-bank__manager--archive-logo-message'>
        Are you sure you would like to archive this logo and all related completed artwork tasks?
      </div>
    </div>
  </Modal>
));

AdminLogoArchiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  logoImage: PropTypes.string.isRequired,
  archiveLogo: PropTypes.func.isRequired,
};

export default AdminLogoArchiveModal;
