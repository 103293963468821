import { buildQueryString } from '@util/stringHelpers';

export const couponsUrl = 'homefield/api/coupons' as const;

export const coupons = (
  pageNumber: number,
  pageSize: number,
  sortColumn: string | null = null,
  sortDirection: string | null = null,
  discountType: string | null = null,
  reason: string | null = null,
  createdBy: string | null = null,
  valid = null,
  search: string | null = null,
  code: string | null = null
): string => `${couponsUrl}${buildQueryString({
  pageNumber,
  pageSize,
  sortColumn,
  sortDirection,
  discountType,
  reason,
  createdBy,
  valid,
  search,
  code,
})}`;

export const addCoupon = `${couponsUrl}/` as const;

export const coupon = (couponIdentefier: number | string): string => `${couponsUrl}/${couponIdentefier}`;

export const couponCreators: string = `${couponsUrl}/creators` as const;

export const couponRestrictions = (couponId: number): string => `${coupon(couponId)}/restrictions`;

export const couponSuggestions = (couponCode: string): string => `${couponsUrl}/suggestions?couponCode=${couponCode}`;

export const redeemCoupon = (couponCode: string): string => `${coupon(couponCode)}/redeem`;

export const unredeemCoupon = (couponCode: string): string => `${coupon(couponCode)}/unredeem`;

export default {
  coupons,
  coupon,
  couponCreators,
  addCoupon,
  couponRestrictions,
  couponSuggestions,
  redeemCoupon,
  unredeemCoupon,
};
