import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  reduxForm,
  InjectedFormProps,
} from 'redux-form';
import { usePostHomefieldApiUtilityFilesupload } from '@api/orders/utility';
import { useGetHomefieldApiOrdermanagementOrderitems } from '@api/orders/orders';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { teamOrganizerTemplateFile } from '@constants/common';
import { orderManagementTeamOrganizerForm } from '@constants/reduxForms';
import { TeamOrganizerFormData } from '@models/forms/TeamOrganizerFormData';
import { RootState } from '@redux/index/reducers';
import DownloadFileLink from '@sharedComponents/Navigation/DownloadFileLink';
import Spinner from '@sharedComponents/Display/Spinner';
import TeamOrganizerUploader from './TeamOrganizerFormContent/TeamOrganizerUploader';
import { downloadFile } from '@util/componentHelper';

interface OwnProps {
  salesChannelId?: number;
  organizationId?: number;
}

const mapStateToProps = (state: RootState) => ({
  formErrors: (state.form[orderManagementTeamOrganizerForm]?.submitErrors) || {},
});

const connector = connect(mapStateToProps);

type FormProps = OwnProps & ConnectedProps<typeof connector>;

type Props = FormProps & InjectedFormProps<TeamOrganizerFormData, FormProps, string[]>;

const TeamOrganizerForm = React.memo<Props>(({
  handleSubmit,
  change,
  salesChannelId,
  organizationId,
}) => {
  const [
    spreadsheetUrl,
    setSpreadsheetUrl,
  ] = useState<string>('');

  const { mutateAsync: uploadFile } = usePostHomefieldApiUtilityFilesupload();

  const {
    data: spreadsheetData,
    isLoading: isLoadingSpreadsheet,
    isError: isErrorSpreadsheet,
    error: errorSpreadsheet,
    refetch: fetchSpreadsheet,
  } = useGetHomefieldApiOrdermanagementOrderitems(
    {
      spreadsheetUrl,
      salesChannelId,
      organizationId,
    }
  );

  useEffect(() => {
    if (!spreadsheetUrl) return;
    fetchSpreadsheet();
  }, [
    fetchSpreadsheet,
    spreadsheetUrl,
  ]);

  useEffect(() => {
    if (isErrorSpreadsheet) {
      return;
    }

    if (!isErrorSpreadsheet && !isLoadingSpreadsheet && spreadsheetData?.length) {
      const items = spreadsheetData;
      change('items', items);
    }
  }, [
    spreadsheetData,
    isLoadingSpreadsheet,
    isErrorSpreadsheet,
    errorSpreadsheet,
  ]);

  const onKeyPress = useCallback((e) => {
    if (e.key === keyNameEnum.Enter) {
      e.preventDefault();
    }
  }, []);

  const uploadItems = useCallback(async (file: Blob) => {
    const res = await uploadFile({ data: { file } });

    if (res?.success && res?.result) {
      const fileUrl: string = res.result;
      setSpreadsheetUrl(fileUrl);
    }
  }, [uploadFile]);

  const renderErrorGroup = (value: [rowNumber: string, errorsList: string[]]) => {
    const [
      rowNumber,
      errorsList,
    ] = value;

    return (
      <>
        {`${errorsList.length} ${errorsList.length > 1 ? 'errors were' : 'error was'} found in the row ${rowNumber}:\n`}
        <div className='ml-20'>
          {errorsList.map(renderError)}
        </div>
      </>
    );
  };

  const renderError = (err: string) => <div key={err}>&bull; {err}</div>;

  const itemsLoaded = !isErrorSpreadsheet && !isLoadingSpreadsheet && spreadsheetData?.length;
  let errorsDict: { [rowNumber: string]: string[]; } = {};

  if (isErrorSpreadsheet) {
    errorsDict = (errorSpreadsheet as any)?.result;
  }

  const hasErrors: boolean = Object.keys(errorsDict).length > 0;

  return (
    <form
      className='redux-form'
      onSubmit={handleSubmit}
      onKeyPress={onKeyPress}
      id={orderManagementTeamOrganizerForm}
    >
      <div>
        <div className='mb-30 mt-15'>
          <div className='mb-30'>
            <DownloadFileLink
              text={'Download CSV Template'}
              downloadFile={downloadFile(teamOrganizerTemplateFile, 'Team_Organizer.xlsx')}
            />
          </div>

          <div className='redux-form__row'>
            <TeamOrganizerUploader uploadFile={uploadItems} />
          </div>
        </div>

        <div className={`${hasErrors ? '' : 'align__center--horizontal'} mb-50`}>
          {
            itemsLoaded &&
            <div className='new-order__team-organizer-modal__upload-status--valid'>
              Attached file is valid
            </div>
          }
          {
            isLoadingSpreadsheet &&
            <Spinner
              size={'30px'}
            />
          }
          {
            hasErrors &&
            <div className='new-order__team-organizer-modal__upload-status--invalid'>
              {
                Object.entries(errorsDict).map(renderErrorGroup)
              }
            </div>
          }
        </div>
      </div>
    </form>
  );
});

export default connector(reduxForm<TeamOrganizerFormData, FormProps, string[]>({
  form: orderManagementTeamOrganizerForm,
  enableReinitialize: true,
})(TeamOrganizerForm));
