export const lockerStatusEnum = {
  Draft: 'Draft',
  Unapproved: 'Unapproved',
  Launched: 'Launched',
  Active: 'Active',
  Closed: 'Closed',
  Canceled: 'Canceled',
  Deleted: 'Deleted',
  Undefined: 'Undefined',
} as const;

export const customItemStatusEnum = {
  Draft: 0,
  Available: 1,
  Hidden: 2,
  Archived: 3,
} as const;

export const productSizeFiltersEnum = {
  Men: 'Men',
  Women: 'Women',
  Youth: 'Youth',
  OSFA: 'OSFA',
} as const;

export const productTypeEnum = {
  All: 'all',
  Custom: 'custom',
  Css: 'css',
} as const;

export const clcStatusEnum = {
  ON: 'Enabled',
  OFF: 'OFF',
} as const;

export const lockerChangeLogPropertyEnum = {
  Markup: 'markup',
} as const;
