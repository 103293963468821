import React from 'react';
import CommonHeaderCard from './HeaderContent/CommonHeaderCard';
import DateHeaderCard from './HeaderContent/DateHeaderCard';
import LockerOrganizationHeaderCard from './HeaderContent/LockerOrganizationHeaderCard';
import NotesHeaderCard from './HeaderContent/NotesHeaderCard';
import OrderHeaderCard from './HeaderContent/OrderHeaderCard';

interface OwnProps {
  orderNumber: number | undefined;
  isExpressProduction: boolean | undefined;
  isRush: boolean | undefined;
  shipDate: string | undefined;
  lockerId: number | undefined | null;
  organizationId: number | undefined | null;
  logoCount: number | undefined | null;
  personalizationCount: number | undefined | null;
  notes: string | undefined | null;
  onUpdateNotes: (orderNumber: number | undefined, notes: string | undefined | null) => void;
}

type Props = OwnProps;

const PrintRoomOrderHeader = React.memo<Props>(({
  orderNumber,
  isExpressProduction,
  isRush,
  shipDate,
  lockerId,
  organizationId,
  logoCount,
  personalizationCount,
  notes,
  onUpdateNotes,
}) => (
  <div className='sheet__files w-100'>
    <OrderHeaderCard
      orderNumber={orderNumber}
      isExpressProduction={isExpressProduction}
      isRush={isRush}
      shipDate={shipDate}
    />
    <LockerOrganizationHeaderCard
      lockerId={lockerId}
      organizationId={organizationId}
    />
    <DateHeaderCard
      header={'Ship Date'}
      value={shipDate}
    />
    <CommonHeaderCard
      header={'Logos'}
      value={logoCount}
    />
    <CommonHeaderCard
      header={'Personalizations'}
      value={personalizationCount}
    />
    <NotesHeaderCard
      orderNumber={orderNumber}
      notes={notes}
      onUpdateNotes={onUpdateNotes}
    />
  </div>
));

export default PrintRoomOrderHeader;
