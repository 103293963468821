import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import {
  lockerMgrS3Logos,
  featureFlags,
} from '@constants/common';
import {
  decorationTypeEnum,
  personalizationTypeEnum,
  personalizationColorTypeEnum,
  personalizationRequirementEnum,
} from '@constants/enums/decorationEnums';
import { customItemForm } from '@constants/reduxForms';
import { createOptionsList } from '@util/optionsMap';
import Select from '@sharedComponents/Form/Select';
import OutsideClickWrapper from '@sharedComponents/OutsideClickWrapper';
import ColorSelect from '@sharedComponents/Inputs/Dropdowns/ColorSelect';
import Button from '@sharedComponents/Buttons/Button';
import PersonalizationOptionButton from '@sharedComponents/CustomItems/PersonalizationOptionButton';
import DecorationDimensions from '@sharedComponents/CustomItems/DecorationDimensions';
import FormError from '@sharedComponents/Form/FormError';
import LogoDropdownWithImageItem from '@sharedComponents/LogoDisplay/LogoDropdown/LogoDropdownWithImageItem';

class Decoration extends PureComponent {
  state = {
    logoDropdownIsActive: false,
    needsUpdate: false,
  };

  componentDidUpdate(prevProps) {
    const {
      selectedColoredStyle,
      decoration,
    } = this.props;

    const {
      type,
      personalizationColorType,
      required,
    } = decoration;

    if (prevProps.selectedColoredStyle !== selectedColoredStyle) {
      this.checkForColoredStyleUpdate();
    }

    if (!featureFlags.twoColorPersonalizationEnabled
      && type === decorationTypeEnum.Personalization
      && personalizationColorType === personalizationColorTypeEnum.FillAndOutline) {
      this.changePersonalizationColorType(personalizationColorTypeEnum.Fill);
    }

    if (!featureFlags.optionalPersonalizationEnabled && type === decorationTypeEnum.Personalization && !required) {
      this.changePersonalizationRequirement(true);
    }
  }

  updateDecorationProperty = (propertyName, propertyValue) => {
    const {
      input: { name },
      change,
    } = this.props;

    change(`${name}.${propertyName}`, propertyValue);
  };

  changeLogoImage = (logo) => {
    this.updateDecorationProperty('logoId', logo.id);
    this.updateDecorationProperty('logoImage', logo.image);
  };

  changePersonalizationColorsCount = (personalizationColorsCount) => {
    this.setState({ personalizationColorsCount });
  };

  changePersonalizationType = (personalizationType) => {
    this.updateDecorationProperty('personalizationType', personalizationType);
  };

  changePersonalizationColorType = (personalizationColorType) => {
    this.updateDecorationProperty('personalizationColorType', personalizationColorType);

    if (personalizationColorType !== personalizationColorTypeEnum.FillAndOutline) {
      this.changeOutlineColor(null);
    }
  };

  changePersonalizationColor = async (color) => {
    this.updateDecorationProperty('colorId', color ? color.id : null);
  };

  changeOutlineColor = (color) => {
    this.updateDecorationProperty('outlineColorId', color && color.id);
  };

  changePersonalizationRequirement = (required) => {
    this.updateDecorationProperty('required', required);
  };

  logoDropdownChange = () => {
    const { logoDropdownIsActive } = this.state;
    this.setState({ logoDropdownIsActive: !logoDropdownIsActive });
  };

  closeLogoDropdown = () => {
    this.setState({ logoDropdownIsActive: false });
  };

  deleteDecoration = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      index,
      removeDecoration,
    } = this.props;

    removeDecoration(index);
  };

  checkForColoredStyleUpdate = () => {
    const {
      decorationValue,
      decorationLocations,
      personalizationColors,
    } = this.props;

    const decorationLocationsForDecoration = decorationLocations
      .filter((d) => d.type === decorationValue.type)
      .map((d) => d.location);

    let needsUpdate = false;

    if (decorationValue.decorationLocation !== null && decorationValue.decorationLocation !== undefined &&
      !decorationLocationsForDecoration.includes(decorationValue.decorationLocation)) {
      this.setNeedsDecorationLocationUpdate();
      needsUpdate = true;
    }

    if (decorationValue.type === decorationTypeEnum.Personalization && personalizationColors) {
      const personalizationColorsForDecoration = personalizationColors.map((c) => c.id);

      if (decorationValue.colorId !== null && decorationValue.colorId !== undefined &&
        !personalizationColorsForDecoration.includes(decorationValue.colorId)) {
        this.setNeedsPersonalizationColorUpdate();
        needsUpdate = true;
      }
    }

    if (needsUpdate) {
      this.setState({ needsUpdate: true });
    }
  };

  setNeedsDecorationLocationUpdate = () => {
    this.updateDecorationProperty('decorationLocation', null);
  };

  setNeedsPersonalizationColorUpdate = () => {
    this.changePersonalizationColor(null);
  };

  removeNeedsUpdate = () => {
    this.setState({ needsUpdate: false });
  };

  render() {
    const {
      logoDropdownIsActive,
      needsUpdate,
    } = this.state;

    const {
      decoration,
      decorationLocations,
      selectedDecorationLocations,
      personalizationColors,
      decorationLocationArtworkSizes,
      lockerLogos,
      change,
      meta: { error },
      input: { name },
    } = this.props;

    const {
      type,
      logoImage,
      personalizationType,
      personalizationColorType,
      colorId,
      outlineColorId,
      decorationLocation,
      required,
      decorationMethod,
    } = decoration;

    const decorationLocationsForDecoration = decorationLocations.filter((d) => d.type === decoration.type);
    const decorationLocationsOptions = createOptionsList({
      list: decorationLocationsForDecoration.map((d) => ({
        ...d,
        name: `${d.location} - ${d.label}`,
        disabled: selectedDecorationLocations.includes(d.location),
      })),
      key: 'location',
      value: 'location',
      name: 'name',
      emptyOption: {
        name: 'Choose location',
      },
    });

    const logosOptions = lockerLogos.map((logo, index) => {
      const parts = logo.image.split('/');
      const fileName = parts[parts.length - 1];

      return (
        <LogoDropdownWithImageItem
          key={index}
          logo={logo}
          isSelected={logo.id === decoration.logoId}
          onClick={this.changeLogoImage}
          fileName={fileName}
        />
      );
    });

    let selectedMainColor = null;
    if ((colorId !== null && colorId !== undefined) && personalizationColors) {
      selectedMainColor = personalizationColors.find((c) => c.id === decoration.colorId);
    }

    let selectedOutlineColor = null;
    if ((outlineColorId !== null && outlineColorId !== undefined) && personalizationColors) {
      selectedOutlineColor = personalizationColors.find((c) => c.id === decoration.outlineColorId);
    }

    const selectedDecorationMethod = (type === decorationTypeEnum.Logo && decorationLocation)
      ? decorationLocationsForDecoration.find((d) => d.location === decorationLocation)
      : null;

    const decorationDimensions = selectedDecorationMethod && decorationLocationArtworkSizes
      && decorationLocationArtworkSizes[selectedDecorationMethod.id][decorationMethod];

    const decorationDimension = decorationDimensions
      && (decorationDimensions.Undefined || decorationDimensions.Regular);

    const decorationDetails = (type === decorationTypeEnum.Logo)
      ? (
        <div
          className='lockerManagerEdit__custom-items--decoration-details'
          onClick={this.logoDropdownChange}
        >
          <div
            className='lockerManagerEdit__custom-items--decoration-details__item__icon--remove'
            onClick={this.deleteDecoration}
          />
          {
            logoImage
              ? (
                <div className='lockerManagerEdit__custom-items--decoration-details__item logo'>
                  <img src={`${lockerMgrS3Logos}/${logoImage}`} />
                </div>
              )
              : (
                <div
                  className='lockerManagerEdit__custom-items--decoration-details__item logo empty'
                >
                  <span>Click to assign logo</span>
                </div>
              )
          }
          <OutsideClickWrapper onClick={this.closeLogoDropdown}>
            <div className={`image-dropdown__options ${logoDropdownIsActive ? 'is-active' : ''}`}>
              <ul className='image-dropdown__options--list custom-scrollbar'>
                {logosOptions}
              </ul>
            </div>
          </OutsideClickWrapper>
        </div>
      )
      : (
        <div className='lockerManagerEdit__custom-items--decoration-details'>
          <div
            className='lockerManagerEdit__custom-items--decoration-details__item__icon--remove'
            onClick={this.deleteDecoration}
          />
          <div className='lockerManagerEdit__custom-items--decoration-details__item personalization'>
            <div className='title'>Personalization Type<span className='required'>*</span></div>
            <div className='personalization-types mb-10'>
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationType}
                personalizationOption={personalizationTypeEnum.Name}
                title={'Name'}
                selectPersonalizationOption={this.changePersonalizationType}
                className='w-33'
                disabled={!featureFlags.twoColorNameEnabled
                  && personalizationColorType === personalizationColorTypeEnum.FillAndOutline
                }
              />
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationType}
                personalizationOption={personalizationTypeEnum.Number}
                title={'Number'}
                selectPersonalizationOption={this.changePersonalizationType}
                className='w-33'
                disabled={!featureFlags.twoColorNumberEnabled
                  && personalizationColorType === personalizationColorTypeEnum.FillAndOutline
                }
              />
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationType}
                personalizationOption={personalizationTypeEnum.Both}
                title={'Both'}
                selectPersonalizationOption={this.changePersonalizationType}
                className='w-33'
                disabled={!featureFlags.twoColorBothEnabled
                  && personalizationColorType === personalizationColorTypeEnum.FillAndOutline
                }
              />
            </div>

            <div className='title'>Personalization Color<span className='required'>*</span></div>
            <div className='personalization-types mb-10'>
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationColorType}
                personalizationOption={personalizationColorTypeEnum.Fill}
                title={'Single Color'}
                className='w-50'
                selectPersonalizationOption={this.changePersonalizationColorType}
              />
              <PersonalizationOptionButton
                selectedPersonalizationOption={personalizationColorType}
                personalizationOption={personalizationColorTypeEnum.FillAndOutline}
                title={'2 Colors'}
                className='w-50'
                selectPersonalizationOption={this.changePersonalizationColorType}
                disabled={!featureFlags.twoColorPersonalizationEnabled
                  || !personalizationType
                  || (!featureFlags.twoColorNumberEnabled
                  && personalizationType === personalizationTypeEnum.Number)
                  || (!featureFlags.twoColorNameEnabled
                  && personalizationType === personalizationTypeEnum.Name)
                  || (!featureFlags.twoColorBothEnabled
                  && personalizationType === personalizationTypeEnum.Both)
                }
              />
            </div>

            <div className='flex'>
              <div className={personalizationColorType === personalizationColorTypeEnum.FillAndOutline ? 'w-50' : 'w-100'}>
                {
                  personalizationColorType === personalizationColorTypeEnum.FillAndOutline &&
                  <div className='title'>Main Color<span className='required'>*</span></div>
                }
                <div className='select-field'>
                  <ColorSelect
                    allColors={personalizationColors}
                    selectedColor={selectedMainColor}
                    selectColor={this.changePersonalizationColor}
                    text={'Pick Color'}
                    valueProperty={'code'}
                    displayProperty={'code'}
                    displayNameProperty={'code'}
                  />
                </div>
              </div>

              {
                (featureFlags.twoColorPersonalizationEnabled
                && personalizationColorType === personalizationColorTypeEnum.FillAndOutline) &&
                <div className='w-50 ml-5'>
                  <div className='title'>Outline Color<span className='required'>*</span></div>
                  <div className='select-field'>
                    <ColorSelect
                      allColors={personalizationColors}
                      selectedColor={selectedOutlineColor}
                      selectColor={this.changeOutlineColor}
                      text={'Pick Color'}
                      valueProperty={'code'}
                      displayProperty={'code'}
                      displayNameProperty={'code'}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      );

    return (
      <div className='lockerManagerEdit__custom-items--decoration'>
        {
          needsUpdate &&
          <div className='lockerManagerEdit__custom-items--decoration__overlay'>
            <Button
              type={'secondary'}
              text={'Update Item'}
              onClick={this.removeNeedsUpdate}
            />
          </div>
        }
        {decorationDetails}
        <div className='lockerManagerEdit__custom-items--decoration__location'>
          <Field
            name={`${name}.decorationLocation`}
            component={Select}
            className={'margin-left'}
          >
            {decorationLocationsOptions}
          </Field>
        </div>

        <DecorationDimensions
          decoration={decoration}
          change={change}
          name={name}
          decorationDimension={decorationDimension}
        />
        {
          (decoration.type === decorationTypeEnum.Personalization) &&
          <div className='lockerManagerEdit__custom-items--decoration-details__item personalization personalization-requirement'>
            <div className='personalization-types mb-10'>
              <PersonalizationOptionButton
                selectedPersonalizationOption={required}
                personalizationOption={personalizationRequirementEnum.Required}
                title={'Required'}
                selectPersonalizationOption={this.changePersonalizationRequirement}
                className='w-50'
                disabled={true}
              />
              <PersonalizationOptionButton
                selectedPersonalizationOption={required}
                personalizationOption={personalizationRequirementEnum.Optional}
                title={'Optional'}
                selectPersonalizationOption={this.changePersonalizationRequirement}
                className='w-50'
                disabled={true}
              />
            </div>
          </div>
        }

        <FormError
          error={error}
          enableMultiple={true}
          classes={'lockerManagerEdit__custom-items--decoration__error'}
        />
      </div>
    );
  }
}

Decoration.propTypes = {
  decoration: PropTypes.object.isRequired,
  decorationValue: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  removeDecoration: PropTypes.func.isRequired,
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDecorationLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  lockerLogos: PropTypes.arrayOf(PropTypes.object).isRequired,
  personalizationColors: PropTypes.arrayOf(PropTypes.object).isRequired,
  decorationLocationArtworkSizes: PropTypes.object.isRequired,
  selectedColoredStyle: PropTypes.object.isRequired,
};

const selector = formValueSelector(customItemForm);
const ConnectedDecoration = connect((state, ownProps) => ({
  decorationValue: selector(state, ownProps.input.name),
  decorationLocationArtworkSizes: state.productCatalog.decorationLocationArtworkSizes,
}))(Decoration);

export default ConnectedDecoration;
