import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  isDisabled?: boolean;
  text?: string;
  classes?: string;
  onClick: () => void;
  dataTest?: string;
}

type Props = OwnProps;

const AddButton = React.memo(({
  text = 'Add',
  classes = '',
  onClick,
  dataTest = undefined,
}: Props) => (
  <button
    className={`lockerManagerEdit__details-header--edit edit-btn align__center ${classes}`}
    onClick={onClick}
    type={'button'}
  >
    {
      dataTest
        ? (
          <Icon
            materialIcon={'add'}
            dataTest={dataTest}
          />
        )
        : (
          <Icon materialIcon={'add'} />
        )
    }
    <span>{text}</span>
  </button>
));

export default AddButton;
