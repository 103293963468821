import React from 'react';
import useHeaderContext from '@sharedComponents/Contexts/HeaderContext';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import Icon from '@sharedComponents/Icons/Icon';
import Label from '@components/shared/Display/Label';

const StatusHeaderTitle = React.memo(() => {
  const { header } = useHeaderContext();

  if (typeof header.title !== 'string') {
    return null;
  }

  return (
    <div className='header__bar-content'>
      <div className='header__bar-title align__center'>
        <span>
          {header.title}
        </span>
        {
          header.label &&
        <Label
          text={header.label.text}
          type={'status-darkred'}
        />
        }
      </div>
      {
        !!header.statuses &&
        <ul className='header__bar-steps'>
          {header.statuses.map((status) => {
            const divClassName =
              status.status === 'COMPLETED' ? 'is-complete'
                : status.status === 'ACTIVE' ? 'is-active'
                  : '';
            const iconName =
              status.status === 'COMPLETED' ? 'check'
                : status.status === 'ACTIVE' ? 'fiber_manual_record'
                  : '';

            return (
              <li key={status.id}>
                <MaterialTooltip
                  tooltipText={''}
                  placement={'bottom'}
                >
                  <div className={divClassName}>
                    <span className='header__bar-steps-circle margin margin--right-s'>
                      {!!iconName && <Icon materialIcon={iconName} />}
                    </span>
                    {status.label}
                  </div>
                </MaterialTooltip>
              </li>
            );
          })}
        </ul>
      }
    </div>
  );
});

export default StatusHeaderTitle;
