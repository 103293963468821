import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  title: string;
  url: string;
  icon: string;
  isMaterialIcon?: boolean;
  isCustomIcon?: boolean;
  dataTest?: string;
}

type Props = OwnProps;

const MenuTile = React.memo<Props>(({
  title,
  url,
  icon,
  isMaterialIcon = true,
  isCustomIcon = false,
  dataTest = 'menu-tile',
}) => {
  let menuIcon = null;

  if (isCustomIcon) {
    menuIcon = (
      <Icon
        iconUrl={icon}
        classes={'menu-icon--custom'}
      />
    );
  } else if (isMaterialIcon) {
    menuIcon = (
      <Icon
        materialIcon={icon}
        classes={'menu-icon--font'}
      />
    );
  } else {
    menuIcon = (
      <Icon
        fontAwesomeIcon={icon}
        classes={'fa2 fa-4x'}
      />
    );
  }

  return (
    <Link to={url}>
      <div
        className='menu-tile'
        data-test={dataTest}
      >
        {menuIcon}
        <div>{title}</div>
      </div>
    </Link>
  );
});

export default MenuTile;
