import React from 'react';
import Grid from '@material-ui/core/Grid';
import { VoucherOrderDetailDto } from '@api/financialServices/models';
import {
  VoucherNotificationLinkDestinationLabel,
  VoucherNotificationSenderNameLabel,
} from '@constants/labelLookup';
import { voucherOrderDetailsUrl } from '@constants/clientUrls/clientUrls';
import { formatDollarAmount } from '@util/numberHelpers';
import { parseDateNumeric } from '@util/dateHandler';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import InfoField from '@sharedComponents/Display/InfoField';
import FooterSummaryContainer from '@sharedComponents/Display/Containers/FooterSummaryContainer';
import TableQuickViewTitle from '@sharedComponents/Table/TableQuickView/TableQuickViewTitle';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
}

type Props = OwnProps;

const VoucherOrdersQuickViewInfo = React.memo<Props>(({ voucherOrder }) => (
  <div className='voucherOrders__quick-view--info'>
    <div>
      <Grid
        container={true}
        spacing={1}
      >
        <Grid
          item={true}
          xs={6}
        >
          <InfoField
            label={voucherOrder.organizationId ? 'Organization Name' : 'Locker Name'}
            dataTest={'organization-name'}
            value={voucherOrder.organizationId ? voucherOrder.organizationName : voucherOrder.lockerName}
          />
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <InfoField
            label={'Admin'}
            dataTest={'admin-name'}
            value={`${voucherOrder.lockerManagerFirstName} ${voucherOrder.lockerManagerLastName}`}
          />
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <InfoField
            label={'Valid From'}
            dataTest={'valid-from'}
            value={voucherOrder.startDate ? parseDateNumeric(voucherOrder.startDate) : '-'}
          />
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <InfoField
            label={'Valid Until'}
            dataTest={'valid-until'}
            value={voucherOrder.endDate ? parseDateNumeric(voucherOrder.endDate) : '-'}
          />
        </Grid>
        <Grid
          item={true}
          xs={12}
        >
          <TableQuickViewTitle
            title={'Notifications'}
            classes={'mb-10'}
          />
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <InfoField
            label={'Send Date'}
            dataTest={'send-date'}
            value={voucherOrder.notificationSettings?.sendDate ? parseDateNumeric(voucherOrder.notificationSettings.sendDate) : '-'}
          />
        </Grid>
        <Grid
          item={true}
          xs={6}
        >
          <InfoField
            label={'Link Destination'}
            dataTest={'link-destination'}
            value={voucherOrder.notificationSettings?.linkDestination &&
              VoucherNotificationLinkDestinationLabel[voucherOrder.notificationSettings.linkDestination]}
          />
        </Grid>
        <Grid
          item={true}
          xs={12}
        >
          <InfoField
            label={'Sender Name'}
            dataTest={'sender-name'}
            value={voucherOrder.notificationSettings?.senderName &&
              VoucherNotificationSenderNameLabel[voucherOrder.notificationSettings?.senderName]}
          />
        </Grid>
        <Grid
          item={true}
          xs={12}
        >
          <InfoField
            label={'Custom Sender Name'}
            dataTest={'custom-sender-name'}
            value={voucherOrder.notificationSettings?.customSenderName}
          />
        </Grid>
      </Grid>
    </div>
    <FooterSummaryContainer
      noPadding={true}
    >
      <>
        <div className='voucherOrders__quick-view--summary'>
          <Grid
            item={true}
            container={true}
            xs={12}
            justify='center'
            alignItems='center'
          >
            <Grid
              item={true}
              container={true}
              xs={4}
              justify='flex-start'
            >
              <dl>
                <dt className={'voucherOrders__info-element--label'}>
                  {'Canceled'}
                </dt>
                <dd className={'voucherOrders__info-element--value'}>
                  {'-'}
                </dd>
              </dl>
            </Grid>
            <Grid
              item={true}
              container={true}
              xs={4}
              justify='center'
            >
              <dl>
                <dt className={'voucherOrders__info-element--label'}>
                  {'Redeemed'}
                </dt>
                <dd className={'voucherOrders__info-element--value'}>
                  {voucherOrder.redeemedInstanceCount}/{voucherOrder.totalInstanceCount}
                </dd>
              </dl>
            </Grid>
            <Grid
              item={true}
              container={true}
              xs={4}
              justify='flex-end'
            >
              <div>
                <div className={'voucherOrders__info-element--label'}>
                  Voucher Price
                </div>
                <div className={'voucherOrders__info-element--value'}>
                  <span className={`${voucherOrder.discountAmount ? 'text-green' : ''}`}>
                    {formatDollarAmount((voucherOrder.totalAmount ?? 0) - (voucherOrder.discountAmount ?? 0))}
                  </span>
                  <span className={`${voucherOrder.discountAmount ? 'line-through' : ''}`}>
                    {voucherOrder.discountAmount ? ` (${formatDollarAmount(voucherOrder.totalAmount)})` : ''}
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <ButtonLink
          type={'primary'}
          text={'View Voucher Order'}
          linkTo={voucherOrderDetailsUrl(voucherOrder.id!)}
          linkClasses={'w-100'}
          classes={'voucherOrders__actions-button w-100 mt-20'}
        />
      </>
    </FooterSummaryContainer>
  </div>
));

export default VoucherOrdersQuickViewInfo;
