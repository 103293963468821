import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ButtonLink from '@sharedComponents/Navigation/ButtonLink';
import { LinearProgress } from '@material-ui/core';
import InfoField from '@sharedComponents/Display/InfoField';
import { llSchedulingQueueDetailsUrl } from '@constants/clientUrls/clientUrls';
import { getColorClass } from '@util/progressBarHelper';

const MultipleQueuesGridCard = React.memo(({ queue }) => {
  const percent = queue.filledCapacity / queue.capacity * 100;
  const colorClass = queue && getColorClass(percent);

  return (
    <Grid
      item={true}
      lg={4}
    >
      <div className='scheduling__card_dtg'>
        <div className='scheduling__card_dtg__title text-uppercase'>
          {queue.queueName}
        </div>
        <div className='flex justify__space-between'>
          <div className='flex'>
            <div className='info__field'>
              <div className='info__label'>Daily capacity</div>
              <div className='info__value--withProgressBar'>
                <span className='info__value'>
                  {queue.filledCapacity}/{queue.capacity}
                </span>
                <span className='progress-bar--small'>
                  <LinearProgress
                    variant='determinate'
                    value={percent}
                    classes={{ root: `scheduling-progress-bar scheduling-progress-bar--${colorClass} scheduling-progress-bar--grey` }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className='flex'>
            <InfoField
              label={'Awaiting dispatch'}
              value={queue.awaitingDispatch === 0 ? '0' : queue.awaitingDispatch}
            />
          </div>
        </div>
        <div className='ll-scheduling__bottom-dtg'>
          <ButtonLink
            type={'primary'}
            text={'Open queue'}
            classes={'w-100'}
            linkClasses={'w-100'}
            linkTo={llSchedulingQueueDetailsUrl(queue.id)}
          />
        </div>
      </div>
    </Grid>
  );
});

MultipleQueuesGridCard.propTypes = {
  queue: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    filledCapacity: PropTypes.number,
    capacity: PropTypes.number,
    awaitingDispatch: PropTypes.number.isRequired,
    decorationMethod: PropTypes.string,
    queueName: PropTypes.string,
  }).isRequired,
};

export default MultipleQueuesGridCard;
