/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type SortDirectionEnum = 'Ascending' | 'Descending';


export const SortDirectionEnum = {
  Ascending: 'Ascending' as SortDirectionEnum,
  Descending: 'Descending' as SortDirectionEnum,
};
