import React from 'react';
import PropTypes from 'prop-types';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ProductionAssemblyPrintQueueSendToPrintForm from '../ProductionAssemblyPrintQueueSendToPrintForm';
import { productionAssemblyPrintQueueSendToPrintForm } from '@constants/reduxForms';

const ProductionAssemblyPrintQueueSendToPrintModal = React.memo(({
  isOpen,
  closeModal,
  onSubmit,
  dtgStations,
  tabletProfile,
  itemsCount,
}) => (
  <Modal
    title={'Print Confirmation'}
    isOpen={isOpen}
    modalSize={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Send to print'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={productionAssemblyPrintQueueSendToPrintForm}
      />
    )}
  >
    <ProductionAssemblyPrintQueueSendToPrintForm
      onSubmit={onSubmit}
      dtgStations={dtgStations}
      tabletProfile={tabletProfile}
      itemsCount={itemsCount}
    />
  </Modal>
));

ProductionAssemblyPrintQueueSendToPrintModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  tabletProfile: PropTypes.object.isRequired,
  itemsCount: PropTypes.number.isRequired,
  dtgStations: PropTypes.array.isRequired,
};

export default ProductionAssemblyPrintQueueSendToPrintModal;
