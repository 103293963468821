import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { mapLayoutFormInitialValues } from '@util/componentHelpers/layoutsHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import LayoutForm from '../LayoutForm';
import { layoutForm } from '@constants/reduxForms';

class LayoutAddModal extends PureComponent {
  render() {
    const {
      isOpen,
      addLayout,
      change,
      closeModal,
      prefillModal,
      initialValues,
    } = this.props;

    return (
      <Modal
        title={'Add Location Layout'}
        isOpen={isOpen}
        modalSize={'xl'}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Add'}
            cancelBtnText={'Cancel'}
            onClose={closeModal}
            formSubmission={true}
            formId={layoutForm}
          />
        )}
      >
        <LayoutForm
          onSubmit={addLayout}
          initialValues={mapLayoutFormInitialValues(prefillModal ? initialValues : null, true)}
          change={change}
        />
      </Modal>
    );
  }
}

LayoutAddModal.propTypes = {
  addLayout: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  change: PropTypes.func,
  prefillModal: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
};

export default LayoutAddModal;
