import { HagPrintRoomJobStatusEnum } from '@api/fulfillment/models';

export const parsePrintStatus = (printStatus: HagPrintRoomJobStatusEnum | undefined) => {
  switch (printStatus) {
    case HagPrintRoomJobStatusEnum.Printed:
      return 'Printed';
    case HagPrintRoomJobStatusEnum.Assembling:
      return 'In Print Assembly';
    case HagPrintRoomJobStatusEnum.Assembled:
      return 'Done';
    default:
      return '';
  }
};
