export const validateLayout = (values) => {
  const errors = {};

  if (!values.id) {
    errors.id = ['Id is required.'];
  }

  if (!values.name) {
    errors.name = ['Name is required.'];
  }

  return errors;
};
