import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import { OrderManagementTempItem } from '@redux/orderManagement/models';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import NumberInput from '@sharedComponents/Inputs/NumberInput';
import ItemDetails from '../OrderManagementNewOrder/ItemDetails';

interface OwnProps {
  isOpen: boolean;
  item: Nullable<OrderManagementTempItem>;
  changeItemQuantity: (item: OrderManagementTempItem, quantity: number) => void;
  closeModal: () => void;
}

type Props = OwnProps;

const ChangeItemQuantityModal = React.memo(({
  isOpen,
  item,
  changeItemQuantity,
  closeModal,
}: Props) => {
  const [
    quantity,
    setQuantity,
  ] = useState<number | undefined>(item?.quantity);

  useEffect(() => {
    setQuantity(item?.quantity as number);
  }, [item]);

  const handleCloseModal = useCallback(async () => {
    closeModal();
  }, [closeModal]);

  const handleChangeItemQuantity = useCallback(() => {
    const newQuantity = quantity;

    changeItemQuantity(item!, newQuantity!);
  }, [
    changeItemQuantity,
    item,
    quantity,
  ]);

  const handleChangeQuantity = useCallback((newQuantity: number) => {
    setQuantity(newQuantity);
  }, []);

  if (!isOpen || !item) {
    return null;
  }

  return (
    <Modal
      title={'Change Quantity'}
      isOpen={isOpen}
      modalWidth={'s'}
      modalHeight={'l'}
      closeModal={handleCloseModal}
      buttons={(
        <ModalButtons
          confirmBtnText={'Save'}
          cancelBtnText={'Cancel'}
          onConfirm={handleChangeItemQuantity}
          onClose={handleCloseModal}
        />
      )}
    >
      <div>
        <ItemDetails item={item} />
        <div className='mt-30'>
          <NumberInput
            initialValue={quantity}
            placeholder={'Quantity'}
            onChange={handleChangeQuantity}
            classes={'w-100 pr-10'}
            inputClasses={'w-100'}
          />
        </div>
      </div>
    </Modal>
  );
});

export default ChangeItemQuantityModal;
