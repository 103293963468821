import React from 'react';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import { TooltipPlacement } from '@customTypes/tooltip';
import {
  LabelType,
  LockerStatusLabelType,
} from '@customTypes/label';

interface OwnProps{
  text: string;
  type?: LabelType | LockerStatusLabelType;
  classes?: string;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
  tooltipClasses?: string;
  tooltipTextClasses?: string;
  dataTest?: string;
}

type Props = OwnProps;

const Label = React.memo(({
  text,
  type = 'status-blue',
  classes = '',
  tooltipText = '',
  tooltipPlacement = 'top',
  tooltipClasses = '',
  tooltipTextClasses = '',
  dataTest = 'label',
}: Props) => (
  tooltipText
    ? (
      <MaterialTooltip
        tooltipText={(
          <div className={tooltipTextClasses}>
            {tooltipText}
          </div>
        )}
        placement={tooltipPlacement}
        tooltipClassName={tooltipClasses}
      >
        <span
          className={`label button uppercase ${type} ${classes}`}
          data-test={dataTest}
        >
          {text}
        </span>
      </MaterialTooltip>
    )
    : (
      <span
        className={`label uppercase ${type} ${classes}`}
        data-test={dataTest}
      >
        {text}
      </span>
    )
));

export default Label;
