import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getSampleImage } from '@util/componentHelpers/productCatalogHelper';
import ProductCatalogColoredStylesList from './ProductCatalogColoredStylesList';
import DecorationLocationInfoField from './DecorationLocationInfoField';
import ImagePreviewCell from '@sharedComponents/Table/TableCells/ImagePreviewCell';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import InfoField from '@sharedComponents/Display/InfoField';

class ProductCatalogQuickViewInfo extends PureComponent {
  state = {
    imageModalIsOpen: false,
    imageUrl: '',
  };

  openModalWithImage = (imageUrl) => {
    this.setState(() => ({
      imageModalIsOpen: true,
      imageUrl,
    }));
  };

  closeImageModal = () => {
    this.setState(() => ({
      imageModalIsOpen: false,
      imageUrl: '',
    }));
  };

  getDecorationLocationText = (location) => {
    const { decorationLocations } = this.props;
    const decorationLocation = decorationLocations.find((dl) => dl.id === location.locationId);

    return decorationLocation && `${decorationLocation.code} - ${decorationLocation.label}`;
  };

  render() {
    const {
      style,
      coloredStyles,
      decorationLocations,
    } = this.props;

    const {
      imageModalIsOpen,
      imageUrl,
    } = this.state;

    const sampleImage = getSampleImage(coloredStyles);

    return (
      <div className='product-catalog__details--container'>
        <ImagePreviewModal
          modalIsOpen={imageModalIsOpen}
          closeModal={this.closeImageModal}
          imageUrl={imageUrl}
        />
        <div className='product-catalog__details--info'>
          <div className='product-catalog__details--description-container'>
            <div className='product-catalog__details--image-container'>
              <ImagePreviewCell
                imageUrl={sampleImage}
                openModalWithUrl={this.openModalWithImage}
                height={'150px'}
                width={'120px'}
              />
            </div>
            <div className='product-catalog__details--style-container'>
              <InfoField
                label={'Style Description'}
                value={style.description}
                valueClass={'product-catalog__details--description'}
              />
            </div>
          </div>
          <div className='flex'>
            <div className='product-catalog__details-column--left'>
              <InfoField
                label={'Decoration'}
                value={style.decorationMethod}
              />
            </div>
            <div className='product-catalog__details-column--center'>
              <DecorationLocationInfoField
                label={'Personalization'}
                styleLocations={style.personalizationLocations}
                decorationLocations={decorationLocations}
              />
            </div>
            <div className='product-catalog__details-column--center'>
              <DecorationLocationInfoField
                label={'Logo'}
                styleLocations={style.logoLocations}
                decorationLocations={decorationLocations}
              />
            </div>
          </div>
          <div className='flex'>
            <div className='product-catalog__details-column--left'>
              <InfoField
                label={'VFI'}
                value={style.vendorFulfilledItem ? 'Yes' : 'No'}
              />
            </div>
            <div className='product-catalog__details-column--center'>
              <InfoField
                label={'Vendor ID'}
                value={style.vendorId}
              />
            </div>
            <div className='product-catalog__details-column--center'>
              <InfoField
                label={'Tax Code'}
                value={style.taxCode}
              />
            </div>
          </div>
        </div>
        <div className='product-catalog__details--items'>
          <div className='info__field'>
            <div className='info__label'>
              Colored Styles
            </div>
          </div>
          <div className='product-catalog__details--list'>
            <ProductCatalogColoredStylesList items={coloredStyles} />
          </div>
        </div>
      </div>
    );
  }
}

ProductCatalogQuickViewInfo.propTypes = {
  style: PropTypes.object.isRequired,
  coloredStyles: PropTypes.array,
  decorationLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProductCatalogQuickViewInfo;
