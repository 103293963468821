import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from '@sharedComponents/Form/Input';

const VendorItemNumberFormInput = React.memo(({
  memberName,
  disabled,
}) => (
  <>
    <label className='redux-form__label'>
      Vendor Item Number
    </label>
    <Field
      name={`${memberName}.vendorItemNumber`}
      placeholder={'Enter vendor item number'}
      component={Input}
      disabled={disabled}
    />
  </>
));

VendorItemNumberFormInput.propTypes = {
  memberName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default VendorItemNumberFormInput;
