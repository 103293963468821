import { catalogApi } from '../../sqdApis';
import {
  makeApiCallWithSubmissionError,
  makeApiCallWithErrorModal,
} from '@util/apiHelper';
import productCatalogUtilityUrls from '@constants/sqdApiUrls/productCatalogUtilityUrls';
import { mapSportErrors } from '@util/componentHelpers/sportsHelper';

export const addSport = async (addSportForm) => {
  const call = catalogApi.post(productCatalogUtilityUrls.sports, {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(addSportForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapSportErrors);

  return res;
};

export const editSport = async (editSportForm) => {
  const call = catalogApi.put(productCatalogUtilityUrls.sport(editSportForm.id), {
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(editSportForm),
  });

  const res = await makeApiCallWithSubmissionError(call, mapSportErrors);

  return res;
};

export const deleteSport = async (sportId) => {
  const call = catalogApi.delete(productCatalogUtilityUrls.sport(sportId));
  const res = await makeApiCallWithErrorModal(call);

  return res;
};
