import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { featureFlags } from '@constants/common';
import { parseNumber } from '@util/numberHelpers';
import InfoField from '@sharedComponents/Display/InfoField';
import Input from '@sharedComponents/Form/Input';

const MaxPersonalizationTextLengthFormInput = React.memo(({ vendorMaxPersonalizationNameLength }) => (
  <InfoField
    fieldClass={'mb-0'}
    label={'Max Personalization Name Length'}
    value={(
      <Field
        name={'maxPersonalizationNameLength'}
        disabled={!featureFlags.maxVfiPersonalizationNameLengthUpdateEnabled}
        placeholder={vendorMaxPersonalizationNameLength || 'Enter Character Number'}
        component={Input}
        type={'number'}
        parse={parseNumber}
        className={'w-100'}
      />
    )}
  />
));

MaxPersonalizationTextLengthFormInput.propTypes = {
  vendorMaxPersonalizationNameLength: PropTypes.number,
};

export default MaxPersonalizationTextLengthFormInput;
