import React from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import { RootState } from '@redux/index/reducers';
import {
  roleMapping,
  checkPermissions,
} from '@util/roleCheck';

interface OwnProps extends RouteProps<string> {
  roles: string[];
  user: any;
  permissions: any;
  component: any;
}

const mapStateToProps = ({ oidc }: RootState) => ({
  user: oidc.user && oidc.user.profile,
  roles: roleMapping(oidc),
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const PrivateRoute = React.memo(({
  roles,
  user,
  permissions,
  component: Component,
  ...options
}: Props) => (
  <Route
    {...options}
    render={(props) => user && (
      checkPermissions(roles, permissions)
        ? (
          <Component
            {...props}
            {...options}
          />
        )
        : <Redirect to='/' />
    )}
  />
));

export default connector(PrivateRoute);
