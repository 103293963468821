import React from 'react';
import PropTypes from 'prop-types';
import Label from '@sharedComponents/Display/Label';

const LockerStatus = React.memo(({ status }) => (
  <Label
    text={status}
    type={status.toLowerCase()}
    dataTest='label-status'
  />
));

LockerStatus.propTypes = {
  status: PropTypes.string,
};

export default LockerStatus;
