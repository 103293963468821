import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchNotScheduledOrderItems } from '@redux/vendorOrders/actions';
import { groupBy } from '@util/arrayHelper';
import TableQuickViewHeader from '@sharedComponents/Table/TableQuickView/TableQuickViewHeader';
import InfoField from '@sharedComponents/Display/InfoField';
import Button from '@sharedComponents/Buttons/Button';
import QuickViewOrderItemGroup from '@sharedComponents/QuickView/QuickViewOrderItemGroup';
import TableQuickViewContainer from '@sharedComponents/Table/TableQuickView/TableQuickViewContainer';
import ScheduleOrdersModal from '../VendorOrdersModals/ScheduleOrdersModal/ScheduleOrdersModal';

class NotScheduledVendorOrdersQuickView extends PureComponent {
  state = {
    selectedItems: [],
    scheduleOrdersModalIsOpen: false,
  };

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(prevProps) {
    const { order } = this.props;

    if (order && prevProps.order !== order) {
      this.fetchItems();
    }
  }

  openScheduleOrdersModal = () => {
    this.setState(() => ({ scheduleOrdersModalIsOpen: true }));
  };

  closeScheduleOrdersModal = () => {
    this.setState(() => ({ scheduleOrdersModalIsOpen: false }));
  };

  fetchItems = () => {
    const {
      order,
      vendorsFilter,
      dispatch,
    } = this.props;

    dispatch(fetchNotScheduledOrderItems(order.orderNumber, vendorsFilter));
  };

  updateSelectedItems = (newSelectedItems) => {
    this.setState(() => ({ selectedItems: newSelectedItems }));
  };

  renderItemsByVendor = () => {
    const {
      items,
      vendors,
    } = this.props;

    const { selectedItems } = this.state;

    const itemsByVendor = groupBy(items, 'vendorId');
    const sortedVendorIds = Object.keys(itemsByVendor).sort();

    const itemsGroups = [];
    for (const vendorId of sortedVendorIds) {
      const vendor = vendors.find((x) => x.id === vendorId);
      const itemsGroup = itemsByVendor[vendorId];
      const poNumbers = [...new Set(itemsGroup.filter((i) => !!i.poNumber).map((i) => i.poNumber))];

      itemsGroups.push(
        <QuickViewOrderItemGroup
          key={vendor && vendor.id}
          title={(
            <div className='align__center'>
              <div>{vendor && vendor.name}</div>
              <div className='expandable-group__header--info ml-10'>
              ({
                  poNumbers.map((poNumber, index) => (
                    <span
                      key={poNumber}
                      className='highlight'
                    >
                      {(index >= 1) ? `, PO#${poNumber}` : `PO#${poNumber}`}
                    </span>
                  ))
                })
              </div>
            </div>
          )}
          items={itemsGroup}
          selectable={true}
          groupSelectable={true}
          itemsSelectable={false}
          selectedItems={selectedItems}
          updateSelectedItems={this.updateSelectedItems}
        />
      );
    }

    return itemsGroups;
  };

  render() {
    const {
      order,
      closeDetails,
      scheduleVendorOrders,
      vendors,
    } = this.props;

    const {
      selectedItems,
      scheduleOrdersModalIsOpen,
    } = this.state;

    return (
      <TableQuickViewContainer
        noPadding={true}
      >
        <ScheduleOrdersModal
          isOpen={scheduleOrdersModalIsOpen}
          closeModal={this.closeScheduleOrdersModal}
          orderItemDetailsDictionary={{ [order.orderNumber]: selectedItems }}
          scheduleVendorOrders={scheduleVendorOrders}
          vendors={vendors}
        />
        <TableQuickViewHeader
          title={<span>O{order.orderNumber}</span>}
          onClose={closeDetails}
        />
        <div className='tableView__details--content'>
          <div className='tableView__details--content-container no-border pt-0'>
            <div className='flex'>
              <InfoField
                label={'Ship To'}
                value={order.shippingInfo && order.shippingInfo.name}
                fieldClass={'order__shipping__column--short'}
              />
              <InfoField
                label={'E-mail'}
                value={order.shippingInfo && order.shippingInfo.email}
                fieldClass={'order__shipping__column--long'}
              />
            </div>
          </div>
          <div className='order__items backorder-management__sidebar__tab-content--notifications custom-scrollbar'>
            {this.renderItemsByVendor()}
          </div>
          <div className='tableView__details--actions'>
            <Button
              type={'primary'}
              text={'Schedule Vendor Order'}
              onClick={this.openScheduleOrdersModal}
              disabled={selectedItems.length === 0}
            />
          </div>
        </div>
      </TableQuickViewContainer>
    );
  }
}

NotScheduledVendorOrdersQuickView.propTypes = {
  order: PropTypes.shape({
    orderNumber: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    vendorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    vfiItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    allItemsCount: PropTypes.number.isRequired,
    shippingInfo: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    lockerId: PropTypes.number.isRequired,
    teamName: PropTypes.string.isRequired,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    sku: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    styleName: PropTypes.string,
    quantity: PropTypes.number,
    size: PropTypes.string,
    floodColor: PropTypes.string,
    pricePaid: PropTypes.number,
    refundAmount: PropTypes.number,
    shipDate: PropTypes.string,
    promisedDate: PropTypes.string,
  })).isRequired,
  closeDetails: PropTypes.func.isRequired,
  scheduleVendorOrders: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(PropTypes.object),
  vendorsFilter: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = ({
  vendorOrders,
  productCatalog,
}) => ({
  items: vendorOrders.notScheduledOrderItems,
  vendors: productCatalog.vendors,
});

export default connect(mapStateToProps)(NotScheduledVendorOrdersQuickView);
