import React, { useState, useMemo, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core';
import { DataGrid, GridColDef, GridRowParams, GridSortModel } from '@material-ui/data-grid';
import { useGetStylesMissingDesignConfigurations } from '@api/squadlockerServices/colored-styles';

const useStyles = makeStyles((theme) => ({
  dataGridRoot: {
    marginTop: theme.spacing(2),
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
  },
}));

type StylesMissingDesignsTableProps = {
  onRowClick: (code: string) => void;
};

const StylesMissingDesignsTable = React.forwardRef((props: StylesMissingDesignsTableProps, ref) => {
  const { onRowClick } = props;

  const classes = useStyles();

  const {
    data,
    isLoading,
    refetch,
  } = useGetStylesMissingDesignConfigurations({
    pageNumber: 1,
    pageSize: 100000,
    sortAsc: true,
    sortColumn: 'id',
  }, { query: { enabled: true } });

  useImperativeHandle(ref, () => ({
    refetchTableData: refetch,
  }));

  const columns: GridColDef[] = useMemo(() => ([
    {
      field: 'id',
      headerName: 'ID',
      description: 'ID',
      type: 'number',
      align: 'center',
      minWidth: 90,
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'Name',
      description: 'Name',
      flex: 1,
    },
    {
      field: 'code',
      headerName: 'Style Code',
      description: 'Style Code',
      flex: 1,
    },
  ]), []);

  function handleRowClick(rowParams: GridRowParams) {
    if (onRowClick) {
      onRowClick(rowParams.getValue(rowParams.id, 'code') as string);
    }
  }

  return (
    <DataGrid
      className={classes.dataGridRoot}
      rows={data?.items ?? []}
      columns={columns}
      rowsPerPageOptions={[10, 50]}
      rowCount={data?.totalCount ?? 0}
      autoHeight={true}
      pagination={true}
      paginationMode='client'
      sortingMode='client'
      loading={isLoading}
      onRowClick={handleRowClick}
    />
  );
});

export default StylesMissingDesignsTable;
