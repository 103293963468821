import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { assignTask } from '@redux/artworkFileTasks/actions';
import { materialSwal } from '@util/componentHelper';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import SearchInput from '@sharedComponents/Form/SearchInput/SearchInput';

class ArtworkTaskAssigneesModal extends PureComponent {
  state = {
    assigneeId: null,
  };

  componentDidUpdate(oldProps) {
    const {
      task,
      isOpen,
    } = this.props;

    if (!oldProps.isOpen && isOpen && task && task.id) {
      this.setState({ assigneeId: task.assignedTo });
    }
  }

  handleCloseModal = () => {
    const { closeModal } = this.props;
    this.setState({
      assigneeId: null,
    }, closeModal);
  };

  changeAssignResource = (id) => {
    this.setState({ assigneeId: id });
  };

  reAssign = async () => {
    const {
      task,
      dispatch,
      decorations,
      art,
    } = this.props;

    const { assigneeId } = this.state;

    const data = {
      resource: {
        id: assigneeId,
      },
      taskIds: [task.id],
    };

    const res = await dispatch(assignTask(data, task.locker.id, task.id));
    if (res?.success) {
      materialSwal('Success', res.message, 'success');
      this.handleCloseModal();
    }
  };

  getActionName = (task) => task && task.assignedTo ? 'Re-assign' : 'Assign';

  render() {
    const {
      task,
      assignees,
      isOpen,
    } = this.props;

    const { assigneeId } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        closeModal={this.handleCloseModal}
        enableBodyOverflow={true}
        title={'Assigned to'}
        modalHeight={'l'}
        modalWidth={'s'}
        buttons={(
          <ModalButtons
            onClose={this.handleCloseModal}
            onConfirm={this.reAssign}
            confirmBtnText={this.getActionName(task)}
            confirmBtnDisabled={task && assigneeId === task.assignedTo}
          />
        )}
      >
        {
          isOpen &&
          <div className='task__input-box'>
            <SearchInput
              allItems={assignees}
              currentItem={assigneeId}
              changeItem={this.changeAssignResource}
              displayKey={'displayName'}
              searchKey={'id'}
              placeholderText={'Search assignees'}
            />
          </div>
        }
      </Modal>
    );
  }
}

ArtworkTaskAssigneesModal.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    assignedTo: PropTypes.string,
    status: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
    locker: PropTypes.object.isRequired,
  }).isRequired,
  assignees: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  decorations: PropTypes.array.isRequired,
  sortBy: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  artwork,
  artworkFileTasks,
}) => ({
  assignees: artwork.assignees,
  sortBy: artworkFileTasks.artworkFileTasks.sortBy,
  decorations: artworkFileTasks.artworkFileTasks.decorations,
});

export default connect(mapStateToProps)(ArtworkTaskAssigneesModal);
