/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type HagPrintRoomJobTypeEnum = 'Undefined' | 'WholeOrder' | 'SingleDecoration';


export const HagPrintRoomJobTypeEnum = {
  Undefined: 'Undefined' as HagPrintRoomJobTypeEnum,
  WholeOrder: 'WholeOrder' as HagPrintRoomJobTypeEnum,
  SingleDecoration: 'SingleDecoration' as HagPrintRoomJobTypeEnum,
};
