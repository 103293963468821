import React from 'react';
import PropTypes from 'prop-types';

const PrintSheetItemPersonalization = React.memo(({
  personalizations,
  keyIndex,
  orderDeleted,
}) => {
  const items = personalizations.map((item, index) => (orderDeleted || item.isCanceled)
    ? (
      <p
        key={index}
        className='canceled-text'
      ><span>{item.quantity}</span>{item.label}
      </p>
    )
    : <p key={index}><span>{item.quantity}</span>{item.label}</p>);

  return (
    <li key={keyIndex}>
      {items}
    </li>
  );
});

PrintSheetItemPersonalization.propTypes = {
  personalizations: PropTypes.array,
  keyIndex: PropTypes.number.isRequired,
  orderDeleted: PropTypes.bool,
};

export default PrintSheetItemPersonalization;
