import {
  CreateLockerStyleRequestModel, LockerStyleCreation, StylesSearchViewModel,
} from '@api/squadlockerServices/models';
import { BulkStyleCreationFormData } from '@models/forms/BulkActions/BulkStyleCreationFormData';
import {
  beginBlockingRequest, endBlockingRequest,
} from '@redux/api/actions';
import {
  downloadFromLink, materialSwal, swalChoose,
} from '@util/componentHelper';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import BulkStyleCreationForm from './BulkStyleCreationForm';
import {
  useCreateStyleInActiveLockers, useGetEffectedLockersForStyle,
} from '@api/squadlockerServices/locker-manager';

const BulkStyleCreation = React.memo(() => {
  const {
    data: bulkStyleCreationResponse,
    mutateAsync: bulkStyleCreationRequest,
  } = useCreateStyleInActiveLockers();

  const [
    selectedStyle,
    setSelectedStyle,
  ] = useState<Nullable<StylesSearchViewModel>>(null);

  const {
    data: effectedLockersCount,
    isFetching: fetchingEffectedLockersCount,
    refetch: fetchEffectedLockersCount,
  } = useGetEffectedLockersForStyle({
    styleId: selectedStyle?.id ?? 0,
  });

  const handleSubmit = useCallback(async (formData: BulkStyleCreationFormData) => {
    const choice = await swalChoose({
      title: 'Confirm Bulk Addition',
      text: `${selectedStyle?.code} ${selectedStyle?.name} will be added 
      to ${effectedLockersCount} lockers. Are you sure you want to proceed?`,
      confirm: 'Confirm',
      cancel: 'Cancel',
    });

    if (choice.value === true) {
      const data = {
        styleId: formData.id,
      } as CreateLockerStyleRequestModel;

      beginBlockingRequest();
      await bulkStyleCreationRequest({ data });
      endBlockingRequest();
    }
  }, [
    bulkStyleCreationRequest,
    effectedLockersCount,
    selectedStyle,
  ]);

  useEffect(() => {
    if (bulkStyleCreationResponse?.success) {
      materialSwal(`Style successfully added to ${bulkStyleCreationResponse.result?.length} lockers.`);
      // Create and download CSV file
      const rows = bulkStyleCreationResponse?.result as unknown as LockerStyleCreation[] ?? [];
      const keys = Object.keys(rows[0] ?? []);
      const csvContent =
      keys.join(',')
      + '\n'
      + rows.map(
        (row) => keys.map((k) => {
          let cell = row[k as keyof LockerStyleCreation] === null
          || row[k as keyof LockerStyleCreation] === undefined
            ? ''
            : row[k as keyof LockerStyleCreation];
          cell = cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }

          return cell;
        }).join(',')
      )
        .join('\n');

      downloadFromLink(csvContent, `locker-info-${Date.now()}.csv`, 'text/csv;charset=utf-8');
    }
  }, [bulkStyleCreationResponse]);

  const changeSelectedStyle = useCallback((style: Nullable<StylesSearchViewModel>) => {
    setSelectedStyle(style);
  }, []);

  return (
    <div className='container master-detail'>
      <BulkStyleCreationForm
        onSubmit={handleSubmit}
        effectedLockersCount={effectedLockersCount}
        fetchingEffectedLockersCount={fetchingEffectedLockersCount}
        setSelectedStyle={changeSelectedStyle}
        fetchEffectedLockersCount={fetchEffectedLockersCount}
        selectedStyle={selectedStyle}
      />
    </div>
  );
});

export default BulkStyleCreation;
