import React from 'react';
import { downloadFile } from '@util/componentHelper';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  fileName: string;
  fileUrl: string;
}

type Props = OwnProps;

const NoteAttachment = React.memo<Props>(({
  fileName,
  fileUrl,
}) => (
  <a
    href={fileUrl}
    className='uploads__item'
    onClick={downloadFile(fileUrl, fileName)}
  >
    <Icon materialIcon={'attachment'} />
    <span>{fileName}</span>
  </a>
));

export default NoteAttachment;
