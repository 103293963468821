import React from 'react';
import {
  VoucherOrderPaymentStatusEnum,
  VoucherOrderDetailDto,
} from '@api/financialServices/models';
import { parseDateNumeric } from '@util/dateHandler';
import { LabelType } from '@customTypes/label';
import Label from '@sharedComponents/Display/Label';

interface OwnProps {
  voucherOrder: VoucherOrderDetailDto;
  classes?: string;
}

type Props = OwnProps;

const VoucherOrderStatusLabel = React.memo<Props>(({
  voucherOrder,
  classes = '',
}) => {
  let labelText: string;
  let labelType: LabelType;
  let tooltipText: string = '';

  if (voucherOrder.cancellationId) {
    labelText = 'Canceled';
    labelType = 'status-darkred';
    tooltipText = `Canceled (${parseDateNumeric(voucherOrder.canceledOn)})`;
  } else {
    switch (voucherOrder.paymentStatus) {
      case VoucherOrderPaymentStatusEnum.Pending:
        labelText = 'Unpaid';
        labelType = 'status-darkred';
        break;
      case VoucherOrderPaymentStatusEnum.Paid:
        labelText = 'Paid';
        labelType = 'gossamer';
        break;
      default:
        labelText = 'Undefined';
        labelType = 'lynch';
    }
  }

  return (
    <Label
      text={labelText}
      type={labelType}
      classes={classes}
      tooltipText={tooltipText}
    />
  );
});

export default VoucherOrderStatusLabel;
