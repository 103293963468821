import React from 'react';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';
import Image from '@sharedComponents/Display/Image';
import { DecorationMethodEnum } from '@api/productCatalog/models';

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  itemImage: string;
  openItemInfoModal: () => void;
  showDecorationMethodLabel?: boolean;
  showSmallerImage?: boolean;
}

type Props = OwnProps;

const ReviewItemImage = React.memo<Props>(({
  item,
  itemImage,
  openItemInfoModal,
  showDecorationMethodLabel = false,
  showSmallerImage = false,
}) => (
  <div className='details__item-logo-container'>
    {
      showDecorationMethodLabel &&
      <span className='details__item-logo--label'>
        {
          (!item.decorationMethod || item.decorationMethod === DecorationMethodEnum.Undefined)
            ? 'No decoration'
            : item.decorationMethod
        }
      </span>
    }
    <Image
      url={itemImage}
      classes={`${showSmallerImage ? 'details__item-logo  details__item-logo--smaller-image' : 'details__item-logo'}`}
      openInModal={openItemInfoModal}
      showImageInModal={true}
    />
  </div>
));

export default ReviewItemImage;
