import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import ArtworkTaskFileUpload from './ArtworkTaskFileUpload';

class ArtworkTaskUpload extends PureComponent {
  render() {
    const {
      taskType,
      taskId,
      uploadTaskFiles,
    } = this.props;

    return (
      <ArtworkTaskFileUpload
        taskType={taskType}
        taskId={taskId}
        uploadTaskFiles={uploadTaskFiles}
      />
    );
  }
}

ArtworkTaskUpload.propTypes = {
  taskType: PropTypes.oneOf([
    decorationMethodEnum.HAG,
    decorationMethodEnum.EMB,
    decorationMethodEnum.DTG,
    decorationMethodEnum.DIP,
  ]).isRequired,
  uploadTaskFiles: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
};

export default ArtworkTaskUpload;
