import React, { Component } from 'react';
import {
  Field,
  reduxForm,
} from 'redux-form';
import PropTypes from 'prop-types';
import Input from '@sharedComponents/Form/Input';
import DatePicker from '@sharedComponents/Form/DatePicker';
import FormError from '@sharedComponents/Form/FormError';
import { shipmentDataForm } from '@constants/reduxForms';
import { connect } from 'react-redux';
import { validateShipmentData } from '@redux/orderManagement/validations';

class ShipmentDataForm extends Component {
  render() {
    const {
      handleSubmit,
      error,
    } = this.props;

    return (
      <form
        className='redux-form'
        onSubmit={handleSubmit}
        id={shipmentDataForm}
      >
        <div>
          <div className='redux-form__section'>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-xl'>
                <label className='redux-form__label'>
                  Actual Ship Date
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'shipDate'}
                  placeholder={'Choose Start Date'}
                  component={DatePicker}
                />
              </div>
            </div>
            <br />
            <div className='redux-form__row'>
              <div className='redux-form__column'>
                <label className='redux-form__label'>
                  Tracking Number
                </label>
                <Field
                  name={'trackingNumber'}
                  placeholder={'Enter tracking number'}
                  component={Input}
                  type={'text'}
                />
              </div>
            </div>
          </div>

          <FormError error={error} />
        </div>
      </form>
    );
  }
}

ShipmentDataForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  error: PropTypes.string,
  change: PropTypes.func,
};

export default connect()(reduxForm({
  form: shipmentDataForm,
  enableReinitialize: true,
  validate: validateShipmentData,
})(ShipmentDataForm));
