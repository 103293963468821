import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TaskHeader from './TaskHeader/TaskHeader';
import LogoReviewLogo from './LogoReviewLogo';
import { fetchLockerLogos } from '@redux/logoReview/actions';

class LogoReviewLocker extends Component {
  componentDidMount() {
    const {
      dispatch,
      locker,
    } = this.props;

    dispatch(fetchLockerLogos(locker.id));
  }

  render() {
    const {
      locker,
      stickyHeaderStyle,
      openSubmitReviewModal,
      cancelReviewForLogo,
      allLightColors,
      allDarkColors,
    } = this.props;

    return (
      <div>
        {
          locker.logos.map((logo) => (
            <div key={logo.id}>
              <TaskHeader
                locker={locker}
                logo={logo}
                openSubmitReviewModal={openSubmitReviewModal}
                headerStyle={stickyHeaderStyle}
              />
              <LogoReviewLogo
                locker={locker}
                logo={logo}
                cancelReviewForLogo={cancelReviewForLogo}
                allLightColors={allLightColors}
                allDarkColors={allDarkColors}
              />
            </div>
          ))
        }
      </div>
    );
  }
}

LogoReviewLocker.propTypes = {
  stickyHeaderStyle: PropTypes.object.isRequired,
  openSubmitReviewModal: PropTypes.func.isRequired,
  cancelReviewForLogo: PropTypes.func.isRequired,
  locker: PropTypes.shape({
    id: PropTypes.number,
    teamName: PropTypes.string,
    lockerUrl: PropTypes.string,
    lockerManagerId: PropTypes.number,
    lockerManagerName: PropTypes.string,
    partnerName: PropTypes.string,
    organizationId: PropTypes.number,
    organizationName: PropTypes.string,
    logos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      lockerId: PropTypes.number,
      logoUrl: PropTypes.string,
      reviewStartedAt: PropTypes.string,
      ordersCount: PropTypes.number,
    })),
  }),
  allLightColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
  allDarkColors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brightnes: PropTypes.number,
    hexValue: PropTypes.string,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default connect()(LogoReviewLocker);
