import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { parseNumber } from '@util/numberHelpers';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import Input from '@sharedComponents/Form/Input';
import InventoryAlwaysAvailableCheckbox from '../StyleForm/InventoryAlwaysAvailableCheckbox';
import Icon from '@sharedComponents/Icons/Icon';

class PrimaryVendorFormInput extends PureComponent {
  changeVendor = async (selectedVendor) => {
    const { change } = this.props;
    change('vendorId', selectedVendor && selectedVendor.code);
  };

  displayVendor = (vendor) => `${vendor.code}`;

  render() {
    const {
      selectedPrimaryVendor,
      vendors,
      disabled,
      inventoryAlwaysAvailable,
      change,
      fetchVendorSuggestions,
    } = this.props;

    return (
      <div className='redux-form__container'>
        <div className='catalog-details__modal--row'>
          <div className='redux-form__column--size-xl'>
            <div className='tableView__details--header--title mb-30'>
              <span>Primary Vendor</span>
            </div>
            <div className='redux-form__column--size-xl'>
              <div className='ml-15 mr-30 m-b-30'>
                <div className='redux-form__row'>
                  <div className='redux-form__column--size-m'>
                    <label className='redux-form__label'>
                      Vendor ID
                    </label>
                    <AutocompleteInput
                      suggestions={vendors}
                      fetchSuggestions={fetchVendorSuggestions}
                      displayItem={this.displayVendor}
                      displaySuggestionText={this.displayVendor}
                      selectedItem={selectedPrimaryVendor}
                      selectItem={this.changeVendor}
                      placeholder={'Search vendors'}
                      disabled={true}
                    />
                  </div>

                  <div className='redux-form__column--size-m ml-20'>
                    <label className='redux-form__label'>
                      Minimum Vendor Quantity
                    </label>
                    <Field
                      name={'qtyVendorMinimum'}
                      placeholder={'Enter minimum vendor quantity'}
                      component={Input}
                      type={'number'}
                      parse={parseNumber}
                      disabled={disabled.qtyVendorMinimum || inventoryAlwaysAvailable}
                    />
                  </div>
                  <div className='redux-form__column--size-s ml-20'>
                    <div className='w-100 mt-20'>
                      <InventoryAlwaysAvailableCheckbox
                        change={change}
                        disabled={disabled.inventoryAlwaysAvailable}
                      />
                    </div>
                  </div>
                </div>
                <div className='warning-msg'>
                  <Icon
                    materialIcon={'warning'}
                    classes={'mr-10'}
                  />
                  <div className='warning-msg--text'>
                  Changing the primary vendor can affect product visibility
                  </div>
                </div>
                <div className='warning-msg'>
                  <Icon
                    materialIcon={'warning'}
                    classes={'mr-10'}
                  />
                  <div className='warning-msg--text'>
                    Changing the primary vendor only allowed on style level
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrimaryVendorFormInput.propTypes = {
  selectedPrimaryVendor: PropTypes.object,
  vendors: PropTypes.arrayOf(PropTypes.object).isRequired,
  change: PropTypes.func.isRequired,
  disabled: PropTypes.shape({
    qtyVendorMinimum: PropTypes.number,
    inventoryAlwaysAvailable: PropTypes.bool,
  }).isRequired,
  fetchVendorSuggestions: PropTypes.func.isRequired,
  inventoryAlwaysAvailable: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  vendors: state.productCatalog.vendorsQueue.queue,
});

export default connect(mapStateToProps)(PrimaryVendorFormInput);
