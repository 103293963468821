import React from 'react';
import PropTypes from 'prop-types';
import iconEye from '@assets/icon_eye_green.svg';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

const TooltipCell = ({
  items,
  displayName,
}) => {
  const isArray = Array.isArray(items);

  return (
    <div className='flex'>
      {`${isArray ? items.length : ''} ${displayName}`}
      <div className='product-catalog__details--icon'>
        {
          items.length > 0 &&
          <MaterialTooltip
            tooltipText={<div className='pre-line'>{isArray ? items.join('\n') : items}</div>}
            placement={'bottom'}
            contentClasses={'align__center'}
          >
            <i
              className='product-catalog__icon-eye'
              style={{ backgroundImage: `url("${iconEye}")` }}
            />
          </MaterialTooltip>
        }
      </div>
    </div>
  );
};

TooltipCell.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  displayName: PropTypes.string.isRequired,
};

export default TooltipCell;
