import React from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { VoucherOrderCreationSteps } from '@constants/enums/voucherEnums';
import { RootState } from '@redux/index/reducers';
import Icon from '@sharedComponents/Icons/Icon';

interface StepOptions {
  title: string;
  icon: JSX.Element | undefined;
  status: string;
}

const VoucherOrderStepName = {
  [VoucherOrderCreationSteps.LockersAndOrganizations]: 'Select locker(s)',
  [VoucherOrderCreationSteps.CollectionsAndRosters]: 'Select roster(s) and collection(s)',
  [VoucherOrderCreationSteps.Configuration]: 'Configure & finalize',
} as const;

const completeIcon = <Icon materialIcon={'check'} />;
const activeIcon = <Icon materialIcon={'fiber_manual_record'} />;

const isCompleteStatus = 'is-complete' as const;
const isActiveStatus = 'is-active' as const;
const isInactive = 'is-complete' as const;

const mapStateToProps = ({ vouchers }: RootState) => ({
  currentStep: vouchers.voucherOrderCreationCurrentStep,
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const VoucherOrderCreationHeaderSteps = React.memo<Props>(({ currentStep }) => {
  const lockersAndOrganizationsStepOptions: StepOptions = {
    title: VoucherOrderStepName[VoucherOrderCreationSteps.LockersAndOrganizations],
    icon: undefined,
    status: isInactive,
  };
  const collectionsAndRostersStepOptions: StepOptions = {
    title: VoucherOrderStepName[VoucherOrderCreationSteps.CollectionsAndRosters],
    icon: undefined,
    status: isInactive,
  };
  const configurationStepOptions: StepOptions = {
    title: VoucherOrderStepName[VoucherOrderCreationSteps.Configuration],
    icon: undefined,
    status: isInactive,
  };

  switch (currentStep) {
    case VoucherOrderCreationSteps.LockersAndOrganizations:
      lockersAndOrganizationsStepOptions.icon = activeIcon;
      lockersAndOrganizationsStepOptions.status = isActiveStatus;
      break;
    case VoucherOrderCreationSteps.CollectionsAndRosters:
      lockersAndOrganizationsStepOptions.icon = completeIcon;
      lockersAndOrganizationsStepOptions.status = isCompleteStatus;
      collectionsAndRostersStepOptions.icon = activeIcon;
      collectionsAndRostersStepOptions.status = isActiveStatus;
      break;
    case VoucherOrderCreationSteps.Configuration:
      lockersAndOrganizationsStepOptions.icon = completeIcon;
      lockersAndOrganizationsStepOptions.status = isCompleteStatus;
      collectionsAndRostersStepOptions.icon = completeIcon;
      collectionsAndRostersStepOptions.status = isCompleteStatus;
      configurationStepOptions.icon = activeIcon;
      configurationStepOptions.status = isActiveStatus;
      break;
  }

  return (
    <div className='order__steps'>
      <ul className='header__bar-steps'>
        <li>
          <div className={lockersAndOrganizationsStepOptions.status}>
            <span className='header__bar-steps-circle margin margin--right-s'>
              {lockersAndOrganizationsStepOptions.icon}
            </span>
            {lockersAndOrganizationsStepOptions.title}
          </div>
        </li>
        <li>
          <div className={collectionsAndRostersStepOptions.status}>
            <span className='header__bar-steps-circle margin margin--right-s'>
              {collectionsAndRostersStepOptions.icon}
            </span>
            {collectionsAndRostersStepOptions.title}
          </div>
        </li>
        <li>
          <div className={configurationStepOptions.status}>
            <span className='header__bar-steps-circle margin margin--right-s'>
              {configurationStepOptions.icon}
            </span>
            {configurationStepOptions.title}
          </div>
        </li>
      </ul>
    </div>
  );
});

export default connector(VoucherOrderCreationHeaderSteps);
