import React from 'react';
import Icon from '@sharedComponents/Icons/Icon';

interface OwnProps {
  text: string;
  onClick: ((e: React.MouseEvent<HTMLDivElement>) => void);
}

type Props = OwnProps;

const SendEmailTextButton = React.memo<Props>(({
  text,
  onClick,
}) => (
  <div
    className='order__sidebar--edit'
    onClick={onClick}
  >
    <Icon materialIcon={'email'} />
    <span className='text-uppercase'>{text}</span>
  </div>
));

export default SendEmailTextButton;
