import { buildQueryString } from '@util/stringHelpers';

export const artwork = 'homefield/api/artwork' as const;

export const artworkLockers = `${artwork}/lockers` as const;
export const artworkLockerTasks = (lockerId: number): string => `${artworkLockers}/${lockerId}/tasks`;

export const tasks = `${artwork}/tasks`;
export const tasksAction = (action: string = ''): string => `${tasks}${action ? `.${action}` : ''}`;
export const task = (id: number): string => `${tasks}/${id}`;

export const taskStatus = tasksAction('status');
export const taskRework = tasksAction('rework');
export const taskAssign = tasksAction('assign');
export const tasksCount = tasksAction('count');
export const taskCutFile = tasksAction('cut-file');
export const taskTriage = tasksAction('triage');
export const taskCreate = tasksAction('create');

export const previewUrl = (taskId: number): string => `${tasks}.preview-status/${taskId}`;
export const taskLogoColors = (id: number): string => `${task(id)}/logo-colors`;

export const lockerTasksByLockerId = (lockerId: number, sortBy: string): string => `${tasks}${buildQueryString({
  lockerId,
  sortBy,
})}`;

export const archive = `${tasks}.archive` as const;
export const unarchive = `${tasks}.un-archive` as const;

export const notes = `${artwork}/notes`;
export const notesForTask = (taskId: number): string => `${artwork}/tasks/${taskId}/notes`;
export const note = (taskId: number, noteId: number): string => `${notesForTask(taskId)}/${noteId}`;

export default {
  task,
  artworkLockers,
  taskRework,
  taskStatus,
  taskLogoColors,
  taskAssign,
  tasksCount,
  previewUrl,
  taskCutFile,
  taskTriage,
  taskCreate,
  artworkLockerTasks,
  lockerTasksByLockerId,
  archive,
  unarchive,
  notes,
  notesForTask,
  note,
};
