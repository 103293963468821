import { HagPrintRoomJobStatusEnum } from '@api/fulfillment/models';
import Modal from '@components/shared/Modal/Modal';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import React, {
  useCallback,
  useState,
} from 'react';

interface OwnProps {
  id: number;
  toStatus: HagPrintRoomJobStatusEnum;
  handleStatusUpdate: (ids: number[], toStatus: HagPrintRoomJobStatusEnum) => void;
  linkText: string;
  warnUser?: string;
}

type Props = OwnProps;

const UpdatePrintJobStatus = React.memo<Props>(({
  id,
  toStatus,
  handleStatusUpdate,
  linkText,
  warnUser,
}) => {
  const [
    isOpen,
    setOpen,
  ] = useState(false);

  const update = useCallback(() => {
    handleStatusUpdate([id], toStatus);
  }, [
    id,
    toStatus,
    handleStatusUpdate,
  ]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    if (warnUser) {
      setOpen(true);
    } else {
      update();
    }
  }, [
    update,
    warnUser,
  ]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <button
        onClick={handleClick}
        className='button button--color-gossamer button--size-m button--style-default button--radius-square button--shadow-m button--transform-uppercase'
      >
        {linkText}
      </button>
      <Modal
        title={'Warning!'}
        isOpen={isOpen}
        closeModal={handleClose}
        modalSize={'l'}
        buttons={(
          <ModalButtons
            confirmBtnText={'Confirm'}
            cancelBtnText={'Cancel'}
            onConfirm={update}
            onClose={handleClose}
          />
        )}
      >
        <span>{warnUser}</span>
      </Modal>
    </>
  );
});

export default UpdatePrintJobStatus;
