import React from 'react';
import { lockerManagerUrl } from '@constants/clientUrls/clientUrls';
import MenuTile from './MenuTile';

const LockerManagerLink = React.memo(() => (
  <MenuTile
    title={'Locker Manager'}
    url={lockerManagerUrl}
    icon={'microchip'}
    isMaterialIcon={false}
    dataTest='locker-manager-link'
  />
));

export default LockerManagerLink;
