export const dtgPalletSizeEnum = {
  ExtraLarge: 'ExtraLarge',
  ExtraWide: 'ExtraWide',
  FullSize: 'FullSize',
  ISOA4: 'ISOA4',
  ISOA5: 'ISOA5',
  ShirtboardInfant: 'ShirtboardInfant',
  ShirtboardSleeve: 'ShirtboardSleeve',
  ShirtboardStandard: 'ShirtboardStandard',
  Custom: 'Custom',
} as const;

export const dtgHighlightGeneratorEnum = {
  None: 'None',
  ChannelBased: 'ChannelBased',
  OnTheFly: 'OnTheFly',
  Combine: 'Combine',
} as const;

export const dtgTranmissisonDecorationEnum = {
  Undefined: 'Undefined',
  Sending: 'Sending',
  Riping: 'Riping',
  Done: 'Done',
  Canceled: 'Canceled',
  Failed: 'Failed',
} as const;

export const dtgPrintDirectionEnum = {
  BI: 'BI',
  LTR: 'LTR',
  RTL: 'RTL',
} as const;
