export const orderManagement = 'homefield/api/ordermanagement' as const;

/* Orders */
export const orders = `${orderManagement}/orders` as const;
export const ordersAction = (action: string = ''): string => action ? `${orders}.${action}` : orders;

export const orderByOrderNumber = (orderNumber: number): string => `${orders}/${orderNumber}`;
export const orderByOrderNumberAction = (
  orderNumber: number,
  action: string = ''
): string => `${action ? ordersAction(action) : orders}/${orderNumber}`;

export const orderById = (orderId: number): string => `${orders}/${orderId}`;
export const updateShippingMethod = (orderId: number): string => `${orderById(orderId)}/update-shipping-method`;
export const updateShippingEmail = (orderId: number): string => `${orderById(orderId)}/update-shipping-info-email`;

export const searchOrder = (orderNumber: number): string => orderByOrderNumber(orderNumber);

export const notes = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/notes`;
export const note = (orderNumber: number, noteId: number): string => `${notes(orderNumber)}/${noteId}`;
export const shipments = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/shipments`;
export const changeLogs = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/change-logs`;
export const statusHistory = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/statuses`;
export const orderItemsForOrder = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/order-items`;
export const accountingSubmitResults = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/accounting-submit-results`;
export const orderFlags = (orderNumber: number): string => `${orderByOrderNumber(orderNumber)}/flags`;

export const submitOrderToAccountingService = (orderNumber: number): string => orderByOrderNumberAction(orderNumber, 'accounting-submit');
export const pauseOrder = (orderNumber: number): string => orderByOrderNumberAction(orderNumber, 'pause');
export const unpauseOrder = (orderNumber: number): string => orderByOrderNumberAction(orderNumber, 'unpause');
export const orderConfirmation = (orderNumber: number): string => orderByOrderNumberAction(orderNumber, 'send-confirmation');

export const changeArtwork = ordersAction('change-artwork');
export const changeDecorationMethod = ordersAction('decoration-method');
export const refundOrderWithCancel = ordersAction('refund-with-cancel');
export const rushOrder = ordersAction('rush');
export const refundOrderItemWithCancel = ordersAction('refund-items-with-cancel');

export const applyCouponToOrder = ordersAction('coupon-apply');
export const applyCouponToOrderPriceCalculation = ordersAction('coupon-apply-price-calculation');

export const personalizationAdd = ordersAction('personalization-add-obsolete');
export const personalizationAddPriceCalculation = ordersAction('personalization-add-price-calculation');
export const personalizationEdit = ordersAction('personalization-edit-obsolete');
export const personalizationEditPriceCalculation = ordersAction('personalization-edit-price-calculation');
export const personalizationRemove = ordersAction('personalization-remove-obsolete');
export const personalizationRemovePriceCalculation = ordersAction('personalization-remove-price-calculation');

export const shippingInfo = ordersAction('shipping-info');
export const shippingInfoPriceCalculation = ordersAction('shipping-info-price-calculation');

/* Order items */
export const orderItems = `${orderManagement}/order-items` as const;
export const orderItemsAction = (action: string = ''): string => action ? `${orderItems}.${action}` : orderItems;

export const orderItemReplace = orderItemsAction('replace');
export const orderItemReplacePriceCalculation = orderItemsAction('replace-price-calculation');

export const addOrderLineItems = ordersAction('add-order-items');

export const validateOrderItemReplace = `${orderItems}.validate-replace`;

export default {
  shipments,
  changeLogs,
  statusHistory,
  updateShippingMethod,
  updateShippingEmail,
  shippingInfo,
  shippingInfoPriceCalculation,
  refundOrderWithCancel,
  refundOrderItemWithCancel,
  orderItemReplace,
  orderItemReplacePriceCalculation,
  accountingSubmitResults,
  submitOrderToAccountingService,
  searchOrder,
  orderById,
  orderFlags,
  orderByOrderNumber,
  orderItemsForOrder,
  changeArtwork,
  changeDecorationMethod,
  personalizationAdd,
  personalizationAddPriceCalculation,
  personalizationEdit,
  personalizationEditPriceCalculation,
  personalizationRemove,
  personalizationRemovePriceCalculation,
  applyCouponToOrder,
  applyCouponToOrderPriceCalculation,
  addOrderLineItems,
  notes,
  note,
  pauseOrder,
  unpauseOrder,
  rushOrder,
  orderConfirmation,
  validateOrderItemReplace,
};
