import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextareaInput extends Component {
  componentDidMount() {
    this.resize();
  }

  componentDidUpdate() {
    this.resize();
  }

  resize = () => {
    const { id = 'textarea' } = this.props;

    const textarea = document.getElementById(id);
    textarea.style.height = 'inherit';
    textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.overflow = 'hidden';
    textarea.style.resize = 'none';
  };

  render() {
    const {
      onChange,
      id = 'textarea',
      placeholder = '',
      disabled = false,
      inputClasses = '',
      value = '',
      input,
    } = this.props;

    const inputProps = input || {};

    return (
      <div className='text-field w-100'>
        <textarea
          rows='1'
          id={id}
          type='text'
          placeholder={placeholder}
          disabled={disabled}
          className={inputClasses}
          value={value}
          onChange={onChange}
          {...inputProps}
        />
      </div>
    );
  }
}

TextareaInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputClasses: PropTypes.string,
  input: PropTypes.object,
  value: PropTypes.string,
};

export default TextareaInput;
