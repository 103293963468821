import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  fetchLockerLogoBankLogos,
  fetchArchivedLogos,
  fetchManagerLogos,
} from '@redux/lockerManager/actions';
import { getDecorationLocationArtworkSizes } from '@redux/productCatalog/actions';
import { extractParameterFromPath } from '@util/stringHelpers';
import LogoBankArchive from './LogoBankArchive';
import LogoBankHistory from './LogoBankHistory/LogoBankHistory';
import LogoBankLogos from './LogoBankLogos';

class LogoBank extends PureComponent {
  componentDidMount() {
    this.refreshLogos();
  }

  componentDidUpdate(prevProps) {
    const {
      logoBankLogos,
      dispatch,
    } = this.props;

    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');

    if (prevProps.logoBankLogos !== logoBankLogos) {
      dispatch(fetchManagerLogos(lockerId));
      dispatch(fetchLockerLogoBankLogos(lockerId));
    }
  }

  refreshLogos = () => {
    const { dispatch } = this.props;
    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');

    dispatch(fetchManagerLogos(lockerId));
    dispatch(fetchArchivedLogos(lockerId));
    dispatch(fetchLockerLogoBankLogos(lockerId));
    dispatch(getDecorationLocationArtworkSizes());
  };

  render() {
    const {
      managerLogos,
      logoBankLogos,
      logoBankHistory,
      logoBankArchivedLogos,
      logoBankLockerLogos,
    } = this.props;

    const lockerId = extractParameterFromPath(this.props, 'lockerId', 'number');

    return (
      <div className='container'>
        <LogoBankLogos
          managerLogos={managerLogos}
          logoBankLogos={logoBankLogos}
          lockerId={lockerId}
          logoBankLockerLogos={logoBankLockerLogos}
          refreshLogos={this.refreshLogos}
        />
        <div className='logo-bank__row'>
          <LogoBankHistory log={logoBankHistory} />
          <LogoBankArchive
            logos={logoBankArchivedLogos}
            lockerId={lockerId}
          />
        </div>
      </div>
    );
  }
}

LogoBank.propTypes = {
  managerLogos: PropTypes.array.isRequired,
  logoBankLogos: PropTypes.array.isRequired,
  logoBankHistory: PropTypes.array.isRequired,
  logoBankLockerLogos: PropTypes.array.isRequired,
  logoBankArchivedLogos: PropTypes.array.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lockerId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  managerLogos: lockerManager.managerLogos,
  logoBankLogos: lockerManager.logoBankLogos,
  logoBankHistory: lockerManager.logoBankHistory,
  logoBankArchivedLogos: lockerManager.logoBankArchivedLogos,
  logoBankLockerLogos: lockerManager.logoBankLockerLogos,
});

export default connect(mapStateToProps)(LogoBank);
