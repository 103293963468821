import { ColorDto } from '@api/productCatalog/models';
import React from 'react';
import LogoCell from './LogoCell';
import TextCell from './TextCell';

interface OwnProps {
  logoUrl: string | null | undefined;
  personalizationText: string | null | undefined;
  personalizationNumber: string | null | undefined;
  personalizationColor: ColorDto | undefined;
  personalizationOutlineColor: ColorDto | undefined;
  openLogoPreviewModal: (url: string) => void;
}

type Props = OwnProps;

const LogoOrTextCell = React.memo<Props>((
  {
    logoUrl,
    personalizationText,
    personalizationNumber,
    personalizationColor,
    personalizationOutlineColor,
    openLogoPreviewModal,
  }
) => {
  if (logoUrl) {
    return (
      <LogoCell
        openLogoPreviewModal={openLogoPreviewModal}
        logoUrl={logoUrl}
      />
    );
  }

  return (
    <TextCell
      personalizationText={personalizationText}
      personalizationNumber={personalizationNumber}
      personalizationColor={personalizationColor}
      personalizationOutlineColor={personalizationOutlineColor}
    />
  );
});

export default LogoOrTextCell;
