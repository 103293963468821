import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import { buildQueryString } from '@util/stringHelpers';

interface Statuses {
  statuses: (keyof typeof orderItemStatusEnum)[];
}

const homefield = 'homefield/api' as const;
const llScheduling = `${homefield}/ll-scheduling` as const;
export const queues = `${llScheduling}/queues` as const;
export const order = `${llScheduling}/order` as const;
export const queueId = (id: number): string => `${queues}/${id}`;
export const updateQueueCapacity = `${queues}.update-capacity` as const;

export const getSchedulingQueueItems = (id: number, statuses: Statuses): string => `${queueId(id)}/items${buildQueryString(statuses, true)}`;
export const dispatchQueueItemsForPicking = (id: number): string => `${queueId(id)}/items.dispatch-for-picking`;

export const getSchedulingQueueItemsCountForStatuses = (
  id: number,
  statuses: Statuses
): string => `${queueId(id)}/items.count${buildQueryString(statuses, true)}`;

export const getSchedulingQueueItemTooltip = (queueItemId: number): string => `${queues}/${queueItemId}/order-item-decorations`;
export const getSchedulingQueueItemInProgressTooltip = (orderNumber: number): string => `${order}/${orderNumber}/order-items`;

export default {
  queues,
  queueId,
  updateQueueCapacity,
  getSchedulingQueueItems,
  dispatchQueueItemsForPicking,
  getSchedulingQueueItemsCountForStatuses,
  getSchedulingQueueItemTooltip,
  getSchedulingQueueItemInProgressTooltip,
};
