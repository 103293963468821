import {
  lockerItemBulkActionsEnum,
  rostersBulkActionsEnum,
} from '@constants/enums/bulkActionsEnum';
import { customItemStatusEnum } from '@constants/enums/lockerEnums';
import { featureFlags } from '@constants/common';
import { Option } from '@models/common/Option';

export const getLockerItemBulkActions = (
  selectedItems: {
    status: number;
    isCustomItem: boolean;
    isLockerItem: boolean;
  }[] = [],
  includeCollectionActions: boolean = true,
  includeRefreshImageActions: boolean = true,
  includeCollectionLevelActions: boolean = false
): Option<string>[] => {
  const bulkActions: Option<string>[] = [
    {
      key: 1,
      value: lockerItemBulkActionsEnum.changeEssential,
      name: 'Change Mark Essential',
    },
  ];

  if (!includeCollectionLevelActions) {
    bulkActions.push({
      key: 2,
      value: lockerItemBulkActionsEnum.deleteProducts,
      name: 'Delete Item(s)',
    });
  }

  if (includeCollectionActions) {
    bulkActions.push({
      key: 3,
      value: lockerItemBulkActionsEnum.newCollection,
      name: 'New Collection',
    });

    bulkActions.push({
      key: 4,
      value: lockerItemBulkActionsEnum.addToExistingCollection,
      name: 'Add to Existing Collection',
    });
  }

  if (includeRefreshImageActions) {
    bulkActions.push({
      key: 5,
      value: lockerItemBulkActionsEnum.refreshCachedImage,
      name: 'Refresh Product Image(s)',
    });
  }

  const onlyCustomItemsSelected = selectedItems.every((i) => i.isLockerItem);

  if (onlyCustomItemsSelected) {
    const someShown = selectedItems.some((i) => i.status !== customItemStatusEnum.Hidden);
    const someHidden = selectedItems.some((i) => i.status === customItemStatusEnum.Hidden);

    if (someShown) {
      bulkActions.push({
        key: 6,
        value: lockerItemBulkActionsEnum.hideCustomItems,
        name: 'Hide All item(s)',
      });
    }

    if (someHidden) {
      bulkActions.push({
        key: 7,
        value: lockerItemBulkActionsEnum.unhideCustomItems,
        name: 'Unhide All item(s)',
      });
    }
  } else if (!includeCollectionLevelActions) {
    const onlyNonCustomItemsSelected = selectedItems.every((i) => !i.isLockerItem);
    if (onlyNonCustomItemsSelected) {
      bulkActions.push({
        key: 8,
        value: lockerItemBulkActionsEnum.addColorsToProducts,
        name: 'Bulk Add Colors',
      });
    }
  }

  if (includeCollectionLevelActions) {
    bulkActions.push({
      key: 9,
      value: lockerItemBulkActionsEnum.removeItemsFromCollection,
      name: 'Remove Item(s) from Collection',
    });
  }

  return bulkActions;
};

export const getRostersBulkActions = (sendNotificationsDisabled: boolean, sendNotificationsHidden: boolean = false) => {
  const bulkActions = [
    {
      key: 1,
      value: rostersBulkActionsEnum.deleteRosters,
      name: 'Delete Roster(s)',
    },
    {
      key: 2,
      value: rostersBulkActionsEnum.toggleDisabledRosters,
      name: 'Activate/Disable Roster(s)',
    },
    ...(featureFlags.rosterNotificationsEnabled && !sendNotificationsHidden
      ? [
        {
          key: 3,
          value: rostersBulkActionsEnum.sendNotifications,
          name: 'Send Notifications',
          disabled: sendNotificationsDisabled,
        },
      ]
      : []
    ),
  ];

  return bulkActions;
};

export const getStyleCrossSellAccessoriesBulkActions = () => {
  const bulkActions = [
    {
      key: 1,
      value: 'deleteCrossSells',
      name: 'Delete Cross Sells Accessories',
    },
  ];

  return bulkActions;
};
