import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { decorationMethodEnum } from '@constants/enums/decorationEnums';
import DropZoneWrapper from '@sharedComponents/Upload/DropZoneWrapper';
import Button from '@sharedComponents/Buttons/Button';
import Icon from '@sharedComponents/Icons/Icon';

const defaultMessage = {
  type: 'default',
  body: 'Drop your file here.',
};

const fileTypesForTaskTypeDict = {
  [decorationMethodEnum.EMB]: [
    'emb',
    'dst',
    'pdf',
  ],
  [decorationMethodEnum.HAG]: ['eps'],
  [decorationMethodEnum.DIP]: ['eps', 'png'],
  [decorationMethodEnum.DTG]: [
    'png',
    'eps',
  ],
};

const acceptedFileTypesDict = {
  [decorationMethodEnum.EMB]: '.emb,.dst,.pdf',
  [decorationMethodEnum.HAG]: '.eps',
  [decorationMethodEnum.DTG]: '.png',
  [decorationMethodEnum.DIP]: '.eps,.png',
};

class ArtworkTaskFileUpload extends Component {
  state = {
    files: [],
    message: defaultMessage,
    loading: false,
    isDragging: false,
  };

  componentDidMount() {
    document.addEventListener('dragover', this.onDragOverScreen);
    document.addEventListener('drop', this.onDragOverScreenStop);
    document.addEventListener('dragleave', this.onDragOverScreenStop);
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', this.onDragOverScreen);
    document.removeEventListener('drop', this.onDragOverScreenStop);
    document.removeEventListener('dragleave', this.onDragOverScreenStop);
  }

  componentDidUpdate(oldProps, oldState) {
    const {
      taskType,
      taskId,
    } = this.props;

    if ((oldState.taskType && taskType !== oldState.taskType) || (oldState.taskId && taskId !== oldState.taskId)) {
      this.setState({
        files: [],
        message: defaultMessage,
        loading: false,
      });
    }
  }

  onDragOverScreen = () => {
    this.setState({ isDragging: true });
  };

  onDragOverScreenStop = () => {
    this.setState({ isDragging: false });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { taskType } = this.props;

    /*
     * See bottom of docs https://react-dropzone.netlify.com/
     * preview blob url is generated, but react-dropzone doesn't manage it
     * we may need to use this later for display purposes.
     * DO THIS BEFORE EXITING THE METHOD
     */
    const destroyFileBlobs = () => {
      for (const file of acceptedFiles.concat(rejectedFiles)) {
        window.URL.revokeObjectURL(file.preview);
      }
    };

    if (acceptedFiles.length > 0) {
      const fileTypes = fileTypesForTaskTypeDict[taskType];
      const bloomFilter = {};
      fileTypes.forEach(function(element) {
        bloomFilter[element] = 0;
      }, this);
      for (const file of acceptedFiles) {
        const splitName = file.name.split('.');
        const extension = splitName[splitName.length - 1]?.toLowerCase();
        if (extension in bloomFilter) {
          if (bloomFilter[extension] === 0) {
            bloomFilter[extension] = 1;
          } else {
            const message = {
              type: 'reject',
              body: `Only upload one file with extension ${extension}.`,
            };
            this.setState({ message });
            destroyFileBlobs();

            return;
          }
        } else {
          const message = {
            type: 'reject',
            body: `${acceptedFileTypesDict[taskType]} files required`,
          };

          this.setState({ message });
          destroyFileBlobs();

          return;
        }
      }

      let messageBody;
      if (acceptedFiles.length === 1) {
        messageBody = (
          <div className='upload-field__file-list-container'>
            <div>Ready to upload ${acceptedFiles[0].name}</div>
          </div>
        );
      } else {
        messageBody = (
          <div className='upload-field__file-list-container'>
            <div>Ready to upload {acceptedFiles.length} files:</div>
            <div className='upload-field__file-list'>
              {
                acceptedFiles.map((file, index) => (
                  <div key={index}>
                    {file.name}
                  </div>
                ))
              }
            </div>
          </div>
        );
      }

      const message = {
        type: 'accept',
        body: messageBody,
      };

      this.setState({
        message,
        files: acceptedFiles,
      });
      destroyFileBlobs();

      return;
    }

    if (rejectedFiles.length > 0) {
      const message = {
        type: 'reject',
        body: `${acceptedFileTypesDict[taskType]} files required`,
      };

      this.setState({ message });
      destroyFileBlobs();
    }
  };

  onClear = () => {
    this.setState({
      message: defaultMessage,
      files: [],
      loading: false,
    });
  };

  onSave = async () => {
    const { files } = this.state;
    const { uploadTaskFiles } = this.props;

    if (files.length > 0) {
      this.setState({ loading: true });
      try {
        await uploadTaskFiles(files);

        this.setState({
          files: [],
          message: defaultMessage,
          loading: false,
        });
      } catch (e) {
        const message = {
          type: 'reject',
          body: 'Failed to upload',
        };

        this.setState({
          files: [],
          message,
          loading: false,
        });
      }
    } else {
      const message = {
        type: 'reject',
        body: 'First, drag a file to upload.',
      };

      this.setState({ message });
    }
  };

  render() {
    const { taskType } = this.props;
    const {
      loading,
      message,
      files,
      isDragging,
    } = this.state;

    const dropzoneContent = loading
      ? (
        <label htmlFor='uploadFiles'>
          <Icon
            fontAwesomeIcon={'spinner'}
            classes={'fa-spin'}
          />
          Uploading...
        </label>
      )
      : <label htmlFor='uploadFiles'>{message.body}</label>;

    const isSaveUploadedFilesDisabled = files.length === 0;

    return (
      <div className='upload-field--horizontal upload-field--big m-t-16'>
        <DropZoneWrapper
          onDrop={this.onDrop}
          name={'uploadFiles'}
          className={`upload-field ${isDragging ? 'upload-field--selected' : ''}`}
          accept={acceptedFileTypesDict[taskType]}
        >
          {dropzoneContent}
        </DropZoneWrapper>
        <div className='upload-field__btn-container'>
          <Button
            type={'primary'}
            text={'Upload'}
            onClick={this.onSave}
            disabled={isSaveUploadedFilesDisabled}
            classes={'upload-field__btn'}
          />
          <Button
            type={'secondary'}
            text={'Clear'}
            onClick={this.onClear}
            classes={'upload-field__btn upload-field__btn--cancel'}
          />
        </div>
      </div>
    );
  }
}

ArtworkTaskFileUpload.propTypes = {
  taskType: PropTypes.oneOf([
    decorationMethodEnum.HAG,
    decorationMethodEnum.EMB,
    decorationMethodEnum.DTG,
    decorationMethodEnum.DIP,
  ]).isRequired,
  uploadTaskFiles: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
};

export default ArtworkTaskFileUpload;
