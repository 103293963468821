import React from 'react';
import PropTypes from 'prop-types';
import ChangeLogItem from '@components/shared/ChangeLog/ChangeLogItem';

const BackorderManagementNotification = React.memo(({
  notification,
  totalItems,
}) => (
  <ChangeLogItem
    customClassName='backorder-management__sidebar__tab-content--notifications__item'
    dateTime={notification.created}
    user={notification.user}
    message={`${notification.orderItemIds.length === totalItems ? 'All items' : 'Specific item'} in order (${notification.orderItemIds.length}})`}
  />
));

BackorderManagementNotification.propTypes = {
  notification: PropTypes.shape({
    user: PropTypes.string,
    created: PropTypes.string.isRequired,
    orderItemIds: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default BackorderManagementNotification;
