import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
} from 'react-grid-dnd';
import CollectionItemCard from './CollectionItemCard';

const numberOfBoxesPerRow = 4;
const rowHeight = 214;

class CollectionItems extends Component {
  onDragEnd = (sourceId, sourceIndex, targetIndex) => {
    const {
      collectionItems,
      reorderItems,
    } = this.props;

    if (sourceIndex === undefined || targetIndex === undefined) {
      return;
    }

    const items = this.sortCollectionItems(collectionItems);

    const [removedCollectionItem] = items.splice(sourceIndex, 1);
    items.splice(targetIndex, 0, removedCollectionItem);

    const reorderedItems = items.map((item, i) => ({
      ...item,
      orderPlace: i + 1,
    }));

    reorderItems(reorderedItems);
  };

  sortCollectionItems = (collectionItems) => {
    const copyArr = [...collectionItems];

    return copyArr.sort((a, b) => {
      if (a.orderPlace < b.orderPlace) {
        return -1;
      }
      if (a.orderPlace > b.orderPlace) {
        return 1;
      }

      // A must be equal to b
      return 0;
    });
  };

  render() {
    const { collectionItems } = this.props;
    const gridHeight = Math.ceil(collectionItems.length / 4) * rowHeight;
    const sortedCollectionItems = this.sortCollectionItems(collectionItems);

    return (
      <div>
        <GridContextProvider
          onChange={this.onDragEnd}
        >
          <GridDropZone
            id='collectionItems'
            boxesPerRow={numberOfBoxesPerRow}
            rowHeight={rowHeight}
            style={{ height: `${gridHeight}px` }}
          >
            {
              sortedCollectionItems.map((item) => (
                <GridItem key={item.id}>
                  <CollectionItemCard item={item} />
                </GridItem>
              ))
            }
          </GridDropZone>
        </GridContextProvider>
      </div>
    );
  }
}

CollectionItems.propTypes = {
  reorderItems: PropTypes.func.isRequired,
  collectionItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CollectionItems;
