import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
} from 'react-grid-dnd';
import { fetchAllCollectionsForLocker } from '@redux/lockerManager/actions';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Icon from '@sharedComponents/Icons/Icon';

const numberOfBoxesPerRow = 3;
const rowHeight = 55;

class ReorderCollectionsModal extends PureComponent {
  state = {
    collections: this.props.collections,
  };

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      isOpen,
      lockerId,
      collections,
    } = this.props;

    if (!prevProps.isOpen && isOpen) {
      dispatch(fetchAllCollectionsForLocker(lockerId));
    }

    if (prevProps.collections !== collections) {
      this.setState({ collections });
    }
  }

  onDragEnd = (sourceId, sourceIndex, targetIndex) => {
    const collections = [...this.state.collections];

    if (sourceIndex === undefined || targetIndex === undefined) {
      return;
    }

    const [removedCollection] = collections.splice(sourceIndex, 1);
    collections.splice(targetIndex, 0, removedCollection);

    this.setState({ collections });
  };

  renderDraggableCollection = (collection) => (
    <div className='categories__draggable-item mr-20'>
      <Icon
        materialIcon={'drag_indicator'}
        classes={'mr-20'}
      />
      <span className='categories__draggable-item--info'>
        <b className='mr-5'>{collection.orderPlace}</b>
        -
        <span className='ml-5'>{collection.name}</span>
      </span>
    </div>
  );

  render() {
    const {
      isOpen,
      closeModal,
      save,
    } = this.props;

    const { collections } = this.state;
    const oldCollections = this.props.collections;

    return (
      <Modal
        title={'Edit Sort Order'}
        isOpen={isOpen}
        modalSize={'l'}
        closeModal={closeModal}
        buttons={(
          <ModalButtons
            confirmBtnText={'Save'}
            cancelBtnText={'Cancel'}
            onConfirm={save.bind(null, collections, oldCollections)}
            onClose={closeModal}
            isDangerousAction={true}
          />
        )}
      >
        <div className='mb-10'>Sort order</div>
        <GridContextProvider
          onChange={this.onDragEnd}
        >
          <GridDropZone
            id='categories'
            boxesPerRow={numberOfBoxesPerRow}
            rowHeight={rowHeight}
            style={{ height: '400px' }}
          >
            {
              collections.map((collection) => (
                <GridItem key={collection.id}>
                  {this.renderDraggableCollection(collection)}
                </GridItem>
              ))
            }
          </GridDropZone>
        </GridContextProvider>
      </Modal>
    );
  }
}

ReorderCollectionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  lockerId: PropTypes.number.isRequired,
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeModal: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

const mapStateToProps = ({ lockerManager }) => ({
  collections: lockerManager.lockerCollections,
});

export default connect(mapStateToProps)(ReorderCollectionsModal);
