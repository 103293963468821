/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Orders Homefield API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  UseMutationOptions
} from 'react-query'
import type {
  DtoICollectionApiResponse,
  ApiResponse,
  PostHomefieldApiIntegrationSsapidatasyncParams,
  SyncOrganizationOrLockerItemsCommand
} from './models'
import { ordersApi } from '../mutator/custom-instance'


type AsyncReturnType<
T extends (...args: any) => Promise<any>,
U = unknown
> = T extends (...args: any) => Promise<infer R> ? (U extends R ? U : R) : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postHomefieldApiIntegrationSsapidatasync = <TData = DtoICollectionApiResponse>(
    params?: PostHomefieldApiIntegrationSsapidatasyncParams,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/integration/ssapi-data.sync`, method: 'post',
      data: undefined,
        params,
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePostHomefieldApiIntegrationSsapidatasync = <TData = AsyncReturnType<typeof postHomefieldApiIntegrationSsapidatasync,DtoICollectionApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{params?: PostHomefieldApiIntegrationSsapidatasyncParams}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {params?: PostHomefieldApiIntegrationSsapidatasyncParams}, TContext>((props) => {
        const {params} = props || {};

        return  postHomefieldApiIntegrationSsapidatasync<TData>(params,requestOptions)
      }, mutationOptions)
    }
    export const putHomefieldApiIntegrationOrganizationslockersitemssync = <TData = DtoICollectionApiResponse>(
    syncOrganizationOrLockerItemsCommand: SyncOrganizationOrLockerItemsCommand,
 options?: SecondParameter<typeof ordersApi>) => {
      return ordersApi<TData>(
      {url: `/homefield/api/integration/organizations-lockers-items.sync`, method: 'put',
      data: syncOrganizationOrLockerItemsCommand
    },
      // eslint-disable-next-line
// @ts-ignore
 options);
    }
  


    export const usePutHomefieldApiIntegrationOrganizationslockersitemssync = <TData = AsyncReturnType<typeof putHomefieldApiIntegrationOrganizationslockersitemssync,DtoICollectionApiResponse>,
    TError = ApiResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{data: SyncOrganizationOrLockerItemsCommand}, TContext>, request?: SecondParameter<typeof ordersApi>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      return useMutation<TData, TError, {data: SyncOrganizationOrLockerItemsCommand}, TContext>((props) => {
        const {data} = props || {};

        return  putHomefieldApiIntegrationOrganizationslockersitemssync<TData>(data,requestOptions)
      }, mutationOptions)
    }
    