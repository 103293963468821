import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import expressIcon from '@assets/icon_express-production.svg';
import {
  organizationDetailsUrl,
  lockerDetailsUrl,
} from '@constants/clientUrls/clientUrls';
import { infoTextTimeout } from '@constants/values';
import {
  parseDateNumeric,
  parseDateTimeNumeric,
} from '@util/dateHandler';
import {
  OrderDetailsDto, OrderItemDetailsDto,
} from '@api/fulfillment/models';
import { groupBy } from '@util/arrayHelper';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import InfoField from '@sharedComponents/Display/InfoField';
import Icon from '@sharedComponents/Icons/Icon';
import OrderManagementSOPAndCustomerNumber from '../OrderManagementSOPAndCustomerNumber';
import { useFlags } from 'launchdarkly-react-client-sdk';
import OrderManagementNSSOAndCustomerNumber from '../OrderManagementNSSOAndCustomerNumber';
import { OrderDetailsDto as CoreOrderDetails } from '@api/orders/models';
import { copyToClipboard } from '@util/componentHelper';

interface OwnProps {
  order: OrderDetailsDto;
  fetchOrder: () => void;
  coreOrder: CoreOrderDetails;
}

type Props = OwnProps;

const OrderManagementSidebarOrderInfo = React.memo<Props>(({
  order, fetchOrder,
}) => {
  const [
    isSubmitResultsModalOpen,
    setIsSubmitResultsModalOpen,
  ] = useState(false);
  const [
    isNSSubmitResultsModalOpen,
    setIsNSSubmitResultsModalOpen,
  ] = useState(false);
  const [
    copyText,
    setCopyText,
  ] = useState('');

  const { displayNetsuiteFields } = useFlags();

  const openOrderSubmitResultsModal = useCallback(() => {
    setIsSubmitResultsModalOpen(true);
  }, []);

  const closeOrderSubmitResultsModal = useCallback(() => {
    setIsSubmitResultsModalOpen(false);
  }, []);

  const openNSOrderSubmitResultsModal = useCallback(() => {
    setIsNSSubmitResultsModalOpen(true);
  }, []);

  const closeNSOrderSubmitResultsModal = useCallback(() => {
    setIsNSSubmitResultsModalOpen(false);
  }, []);

  useEffect(() => {
    // Remove copy text after the amount of time has passed
    setTimeout(() => {
      setCopyText('');
    }, infoTextTimeout);
  }, [copyText]);

  const onCopyToClipboard = useCallback((e) => {
    setCopyText((e.target as HTMLInputElement).value);
    copyToClipboard(e.target.value, 'idToCopy');
  }, []);

  const renderIcon = () => (
    <div className='align__center'>
      <Icon
        materialIcon={'content_copy'}
        onClick={onCopyToClipboard}
      />
      <span className='copy-text'>{copyText}</span>
    </div>
  );

  const renderShipDateInfoIcon = (orderItems: OrderItemDetailsDto[] | null | undefined) => {
    const vfiItems = orderItems?.filter((x) => x.vendorFulfilledItem) ?? [];
    const hasVfiItems = vfiItems.length > 0;

    if (!hasVfiItems) {
      return null;
    }

    const vfiItemShipDatesList = [];
    const vfiItemsByShipDate = groupBy(vfiItems.filter((i) => !!i.shipDate), 'shipDate');

    for (const [
      shipDate,
      itemsByShipDate,
    ] of Object.entries(vfiItemsByShipDate)) {
      const vendorIds = [...new Set(itemsByShipDate.map((i) => i.vendorId))];

      for (const vendorId of vendorIds) {
        vfiItemShipDatesList.push(`${parseDateNumeric(shipDate)} - ${vendorId}`);
      }
    }

    return (
      <MaterialTooltip
        tooltipText={(
          <div className='pre-line'>
            {`VFI Ship Dates:\n${vfiItemShipDatesList.join('\n')}`}
          </div>
        )}
        placement={'bottom'}
      >
        <Icon
          materialIcon={'info_outline'}
          classes='order-financial-details__info-icon mt-5'
        />
      </MaterialTooltip>
    );
  };

  return (
    <div className='order__shipping'>
      <div className='order__shipping-header'>
        <div className='flex'>
          <div className='order__shipping__column--short'>
            <InfoField
              tooltip={order.datePlaced ? <div>{`${parseDateTimeNumeric(order.datePlaced)}`}</div> : ''}
              tooltipPlacement={'bottom'}
              label={'Received on'}
              value={parseDateNumeric(order.datePlaced)}
            />
          </div>
          {
            order.organizationId
              ? (
                <InfoField
                  label={'Organization'}
                  value={(
                    <div className='align__center order__shipping--copy-icon'>
                      <Link
                        className='link'
                        target='_blank'
                        to={organizationDetailsUrl(order.organizationId)}
                      >
                        ORG<span id='idToCopy'>{order.organizationId}</span> - {order.organizationName}
                      </Link>
                      {renderIcon()}
                    </div>
                  )}
                />
              )
              : (
                <InfoField
                  label={'Locker'}
                  value={(
                    <div className='align__center order__shipping--copy-icon'>
                      <Link
                        className='link'
                        target='_blank'
                        to={lockerDetailsUrl(order.lockerId ?? 0)}
                      >
                        L<span id='idToCopy'>{order.lockerId}</span> - {order.lockerTeamName}
                      </Link>
                      {renderIcon()}
                    </div>
                  )}
                />
              )
          }
        </div>
        {/* GP */}
        {
          !displayNetsuiteFields &&
          <OrderManagementSOPAndCustomerNumber
            isSubmitResultsModalOpen={isSubmitResultsModalOpen}
            order={order}
            closeModal={closeOrderSubmitResultsModal}
            openModal={openOrderSubmitResultsModal}
            fetchOrder={fetchOrder}
          />
        }
        {/* NETSUITE */}
        {
          displayNetsuiteFields &&
          <OrderManagementNSSOAndCustomerNumber
            isSubmitResultsModalOpen={isNSSubmitResultsModalOpen}
            order={order}
            closeModal={closeNSOrderSubmitResultsModal}
            openModal={openNSOrderSubmitResultsModal}
            fetchOrder={fetchOrder}
          />
        }
        <div className='flex'>
          <InfoField
            fieldClass={'order__shipping__column--short'}
            label={(
              <div className='flex'>
                Ship Date {renderShipDateInfoIcon(order.items)}
              </div>
            )}
            value={(
              <div>
                {parseDateNumeric(order.shipDate)}
                {
                  order.expressProduction &&
                  <i
                    className='fa'
                    style={{
                      width: '22px',
                      height: '14px',
                      marginLeft: '8px',
                      backgroundImage: `url("${expressIcon}")`,
                    }}
                  />
                }
              </div>
            )}
          />
          <InfoField
            label={'Customer Ship Date'}
            value={parseDateNumeric(order.customerShipDate)}
          />
        </div>
      </div>
    </div>
  );
});

export default OrderManagementSidebarOrderInfo;
