import React from 'react';
import PropTypes from 'prop-types';
import PrintSheetItem from './PrintSheetItem/PrintSheetItem';

const PrintSheetItems = ({
  items,
  openModal,
  orderNumber,
  openPreviewModal,
  barcode,
  renderPrintSheet,
  sheetId,
  colorsDictionary,
  colors,
}) => (
  <ul className='sheet__items'>
    {
      items.map((item, key) => (
        <PrintSheetItem
          sheetId={sheetId}
          openPreviewModal={openPreviewModal}
          orderNumber={orderNumber}
          barcode={barcode}
          sheetItem={item}
          key={key}
          openModal={openModal}
          renderPrintSheet={renderPrintSheet}
          colorsDictionary={colorsDictionary}
          colors={colors}
        />
      ))
    }
  </ul>
);

PrintSheetItems.propTypes = {
  sheetId: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
  orderNumber: PropTypes.number,
  barcode: PropTypes.string,
  items: PropTypes.array,
  openPreviewModal: PropTypes.func.isRequired,
  renderPrintSheet: PropTypes.bool.isRequired,
  colorsDictionary: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    hexValue: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    brightness: PropTypes.string.isRequired,
    threadValue: PropTypes.string,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    dtgCode: PropTypes.string,
    cmykValue: PropTypes.string.isRequired,
    dtgCmykValue: PropTypes.string.isRequired,
    hexValue: PropTypes.string,
    threadValue: PropTypes.string,
    brightness: PropTypes.string.isRequired,
    isArtwork: PropTypes.bool,
    isFlood: PropTypes.bool,
  })).isRequired,
};

export default PrintSheetItems;
