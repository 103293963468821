import React from 'react';
import {
  orderAssemblyReviewByBarcodeUrl,
  orderAssemblyReviewByOrderUrl,
} from '@constants/clientUrls/clientUrls';
import OrderSearch from '@sharedComponents/Production/Search/OrderSearch';

const OrderAssemblySearch = React.memo(() => (
  <OrderSearch
    reviewByOrderUrl={orderAssemblyReviewByOrderUrl}
    reviewByBarcodeUrl={orderAssemblyReviewByBarcodeUrl}
  />
));

export default OrderAssemblySearch;
