export enum TableEnum {
  admins = 'admins',
  organizations = 'organizations',
  orderManagement = 'orderManagement',
  rosterManager = 'rosterManager',
  lockerManager = 'lockerManager',
  productCatalog = 'productCatalog',
  productionArtwork = 'productionArtwork',
  backorderManagement = 'backorderManagement',
  printRoom = 'printRoom',
  voucherOrders = 'voucherOrders',
}

export enum BackorderManagementTabEnum {
  NeverNotified = 'NeverNotified',
  PreviouslyNotified = 'PreviouslyNotified',
  DiscontinuedItems = 'DiscontinuedItems',
}

export enum TableQuickViewSizeEnum {
  Medium = '770',
  Large = '960',
  FitContent = 'fit-content',
}
