import React from 'react';

interface OwnProps {
  label: string;
  content: Nullable<string> | Nullable<number>;
}

type Props = OwnProps;

const PriorityDetailsInfoItem = React.memo<Props>(({
  label,
  content,
}) => (
  <div className='flagging__details-info-item'>
    <div className='flagging__details-info-label'>
      {label}
    </div>
    <div className='flagging__details-info-content'>
      {content}
    </div>
  </div>
));

export default PriorityDetailsInfoItem;
