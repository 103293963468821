import React from 'react';
import LabelsLegendDisplay from '@sharedComponents/Labels/LabelsLegendDisplay';

interface OwnProps {
  id: number;
  hasRequiredItems: boolean;
}

type Props = OwnProps;

const ArtworkTaskDetailsId = React.memo<Props>(({
  id,
  hasRequiredItems = false,
}) => (
  <div className='flex'>
    <span className='task__id'>F{id}</span>
    {
      hasRequiredItems &&
        <div className='align__center'>
          <LabelsLegendDisplay
            labels={[
              {
                isActive: hasRequiredItems,
                text: 'Required',
                shortText: 'R',
                type: 'electric-indigo',
              },
            ]}
          />
        </div>
    }
  </div>
));

export default ArtworkTaskDetailsId;
