import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import {
  getOrderDiscountAmount,
  getOrderChargeAmount,
} from '@util/paymentHelper';
import * as orderManagementActions from '@redux/orderManagement/actions';
import { RootState } from '@redux/index/reducers';
import RefundPrice from '@sharedComponents/Display/RefundPrice';
import InfoField from '@sharedComponents/Display/InfoField';
import DescriptionField from '@sharedComponents/Display/DescriptionField';
import Tabs from '@sharedComponents/Tabs/Tabs';
import OrderManagementQuickViewItemsList from './OrderManagementQuickViewTabs/OrderManagementQuickViewItemsList';
import OrderManagementQuickViewShippingInfo from './OrderManagementQuickViewTabs/OrderManagementQuickViewShippingInfo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import OrderManagementSOPAndCustomerNumber from '@components/OrderManagement/OrderManagementSOPAndCustomerNumber';
import OrderManagementNSSOAndCustomerNumber from '@components/OrderManagement/OrderManagementNSSOAndCustomerNumber';

const orderManagementQuickViewTabEnum = {
  OrderItems: 'OrderItems',
  ShippingInfo: 'ShippingInfo',
};

interface OwnProps {
  order: any;
  fetchOrder: () => void;
}

const mapStateToProps = ({ orderManagement }: RootState) => ({
  shipments: orderManagement.shipments,
});

const mapDispatchToProps = {
  getOrderShipments: orderManagementActions.getOrderShipments,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const OrderManagementQuickViewInfo = React.memo<Props>(({
  order,
  shipments,
  getOrderShipments,
  fetchOrder,
}) => {
  const { displayNetsuiteFields } = useFlags();
  const [
    selectedTab,
    setSelectedTab,
  ] = useState(orderManagementQuickViewTabEnum.OrderItems);
  const [
    isSubmitResultsModalOpen,
    setIsSubmitResultsModalOpen,
  ] = useState(false);
  const [
    isNSSubmitResultsModalOpen,
    setIsNSSubmitResultsModalOpen,
  ] = useState(false);
  useEffect(() => {
    if (order.orderNumber) {
      getOrderShipments(order.orderNumber);
    }
  }, [
    order.orderNumber,
    getOrderShipments,
  ]);

  const selectTab = useCallback((tab: string) => {
    setSelectedTab(tab);
  }, []);

  const openOrderSubmitResultsModal = useCallback(() => {
    setIsSubmitResultsModalOpen(true);
  }, []);

  const closeOrderSubmitResultsModal = useCallback(() => {
    setIsSubmitResultsModalOpen(false);
  }, []);

  const openNSOrderSubmitResultsModal = useCallback(() => {
    setIsNSSubmitResultsModalOpen(true);
  }, []);

  const closeNSOrderSubmitResultsModal = useCallback(() => {
    setIsNSSubmitResultsModalOpen(false);
  }, []);

  const getTabs = () => {
    const { items } = order;

    const totalItems = items.reduce((total: number, current: any) => total + current.quantity, 0);

    const tabs = [
      {
        title: 'Order Items',
        name: orderManagementQuickViewTabEnum.OrderItems,
        content: (
          <div className='orderManagement__details-info-container'>
            <div>
              <OrderManagementQuickViewItemsList
                items={items}
              />
              <div className='orderManagement__details-item-details'>
                <DescriptionField
                  label={'Total Items'}
                  value={totalItems}
                />
                <DescriptionField
                  label={'Coupon Code'}
                  value={order.couponCode}
                />
                <DescriptionField
                  label={'Price Paid'}
                  value={(
                    <RefundPrice
                      paid={order.pricePaid}
                      refund={getOrderDiscountAmount(order)}
                      charge={getOrderChargeAmount(order)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        title: 'Shipping Info',
        name: orderManagementQuickViewTabEnum.ShippingInfo,
        content: (
          <div className='orderManagement__details-info-container'>
            <OrderManagementQuickViewShippingInfo
              order={order}
              shipments={shipments}
            />
          </div>
        ),
      },
    ];

    return tabs;
  };

  return (
    <div className='orderManagement__details-container'>
      <div className='orderManagement__details-column--l'>
        <InfoField
          label={'Order Status'}
          value={order.status?.label}
        />
        {
          !displayNetsuiteFields &&
          <OrderManagementSOPAndCustomerNumber
            order={order}
            isSubmitResultsModalOpen={isSubmitResultsModalOpen}
            closeModal={closeOrderSubmitResultsModal}
            openModal={openOrderSubmitResultsModal}
            fetchOrder={fetchOrder}
          />
        }
        {
          displayNetsuiteFields &&
          <OrderManagementNSSOAndCustomerNumber
            order={order}
            isSubmitResultsModalOpen={isNSSubmitResultsModalOpen}
            closeModal={closeNSOrderSubmitResultsModal}
            openModal={openNSOrderSubmitResultsModal}
            fetchOrder={fetchOrder}
          />
        }
        <div className='flex'>
          <InfoField
            label={'Ship To'}
            value={order.shippingInfo?.name}
            fieldClass={'order__shipping__column--short'}
          />
          <InfoField
            label={'E-mail'}
            value={order.shippingInfo?.email}
            fieldClass={'order__shipping__column--long'}
          />
        </div>
      </div>
      <Tabs
        tabs={getTabs()}
        selectedTab={selectedTab}
        selectTab={selectTab}
      />
    </div>
  );
});

export default connector(OrderManagementQuickViewInfo);
